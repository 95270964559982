import main from './main'
import admission from './admission'
import RoundManager from './RoundManager'
import config from './config'
import InstitutionDetails from './InstitutionDetail'
import basic from './basic'
import scoreManager from './scoreManager'
import division from './division'
import evaluation from './evaluation'
import profile from './profile'
import AcademicYears from './AcademicYears'
import subject from './subject'
import Syllabus from './syllabus'
import exam from './exam'
import assignment from './assignment'
import assessments from './assessments'
import alerts from './alerts'
import classes from './classes'
import AppReg from './RegGeneralData'
import Attendance from './Attendance'
import onlineClass from './onlineClass'
import StudentAffairs from './StudentAffairs'
import form from './form'
import poi from './poi'
import StudentProfile from './StudentProfile'
import cas from './cas'
import agenda from './agenda'
import chat from './chat'
import calendar from './calendar'
import status from './Status'
import teachersGateway from './teachersGateway'
import auth from './auth'
import completeProfileData from './completeProfileData'
import grading from './Grading'
import teachingMaterials from './teachingMaterials'
import canteen from './canteen'
import wallet from './wallet'
import announcement from './announcement'
import inventory from './inventory'
import usersLogs from './usersLogs'
import boundariesAndDescriptors from './boundariesAndDescriptors'
import myDay from './myDay'

export default {
  main,
  admission,
  RoundManager,
  config,
  InstitutionDetails,
  scoreManager,
  basic,
  division,
  evaluation,
  profile,
  AcademicYears,
  subject,
  form,
  exam,
  assignment,
  assessments,
  alerts,
  classes,
  Syllabus,
  Attendance,
  onlineClass,
  StudentAffairs,
  poi,
  StudentProfile,
  cas,
  agenda,
  chat,
  calendar,
  status,
  teachersGateway,
  auth,
  completeProfileData,
  AppReg,
  grading,
  teachingMaterials,
  canteen,
  wallet,
  announcement,
  inventory,
  usersLogs,
  boundariesAndDescriptors,
  myDay
}
