export default {
  name: 'GenericMenuInfo',
  props: ['info'],
  data () {
    return {
      data: null
    }
  },
  created () {
    this.data = this.info
  }
}
