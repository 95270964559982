<template>
  <list-page-basic class="tasks">
    <template v-slot:leftSide>
      <transition-group
        class="list-transition"
        name="list-enter-up"
        tag="ul"
        v-if="assignments.length"
      >
        <li
          class="mb-12"
          v-for="(assignment, i) in assignments"
          :key="assignment.id"
          :style="[{ transitionDelay: i * 0.1 + 's' }]"
        >
          <assignment-card
            :assignment_prop="assignment"
            @refreshData="assignmentsRequest"
          ></assignment-card>
        </li>
      </transition-group>
      <vx-card v-else>
        <div class="text-center">
          <p>
            {{ $t("assignment.no_assignments_found") }}
          </p>
        </div>
      </vx-card>
      <vs-pagination
        :total="totalPages"
        :max="is_touch_device ? 5 : 7"
        v-model="currentPage"
        v-if="totalPages > 1"
      />
    </template>
    <template v-slot:rightSide>
      <sidebar-filter
        ref="sidebarFilter"
        :filters_prop="filters"
        :active_prop="true"
        @refilter="setFilters"
      >
      </sidebar-filter>
    </template>
  </list-page-basic>
</template>
<script>
import ListPageBasic from '@/components/listPageGridSystem/Basic'
import StatusCounter from '@/components/general/StatusCounter'
import SidebarFilter from '@/components/general/RoundFilter.vue'

import assignmentCard from '@/modules/assignment/components/AssignmentCard'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const AssignmentStudentRepository = RepositoryFactory.get(
  'AssignmentsModule',
  'studentAssignments'
)
import { commonDataMixin } from '@/mixins'
export default {
  components: { assignmentCard, ListPageBasic, StatusCounter, SidebarFilter },
  name: 'assignments',
  mixins: [commonDataMixin],
  props: ['params', 'student'],
  data () {
    return {
      assignments: [],
      filters: [
        {
          id: 1,
          name: this.$t('basic.subjects'),
          payload_name: 'division_subject_ids',
          by: []
        },
        {
          id: 3,
          name: this.$t('basic.type'),
          payload_name: 'type',
          singleSeletable: true,
          by: [
            { name: this.$t('assignment.quiz'), checked: false, id: 'quiz' },
            {
              name: this.$t('assignment.homework'),
              checked: false,
              id: 'homework'
            },
            { name: this.$t('assignment.essay'), checked: false, id: 'essay' },
            { name: this.$t('assignment.paper'), checked: false, id: 'paper' },
            { name: this.$t('assignment.test'), checked: false, id: 'test' },
            {
              name: this.$t('assignment.project'),
              checked: false,
              id: 'project'
            }
          ]
        },
        {
          id: 4,
          name: this.$t('assignment.submission'),
          payload_name: 'submission',
          singleSeletable: true,
          by: [
            {
              name: this.$t('assignment.done_but_late'),
              checked: false,
              id: 'done_but_late'
            },
            {
              name: this.$t('assignment.done_on_time'),
              checked: false,
              id: 'done_on_time'
            },
            {
              name: this.$t('assignment.delayed'),
              checked: false,
              id: 'delayed_done'
            }
          ]
        },
        {
          id: 5,
          name: this.$t('assignment.due_date'),
          payload_name: 'due_date',
          singleSeletable: true,
          by: [
            {
              name: this.$t('assignment.upcoming'),
              checked: false,
              id: 'upcoming'
            },
            {
              name: this.$t('assignment.time_to_take_action'),
              checked: false,
              id: 'time_to_take_action'
            },
            {
              name: this.$t('assignment.late'),
              checked: false,
              id: 'late'
            }
          ]
        },
        {
          id: 6,
          name: this.$t('assessments.assessed'),
          payload_name: 'assessed',
          singleSeletable: true,
          by: [
            {
              name: this.$t('basic.all'),
              checked: false,
              id: ''
            },
            {
              name: this.$t('assessments.assessed'),
              checked: false,
              id: 1
            }
          ]
        }
      ],
      topFilter: '',
      sideFilters: {
        type: '',
        division_subject_ids: '',
        submission: '',
        due_date: '',
        assessed: ''
        // assignmentType: [],
        // due_date: []
      },
      /////////////////////////
      // Pagination
      /////////////////////////
      totalPages: 0,
      currentPage: 1,
      countPerPage: 10
    }
  },
  computed: {
    is_student () {
      return this.$acl.check('Student')
    },
    is_parent () {
      return this.$acl.check('Parent')
    },
    is_touch_device () {
      return this.$store.state.is_touch_device
    },
    status () {
      const status = [
        {
          icon: 'EyeIcon',
          value: '',
          name: this.$t('assignment.all_assignments'),
          color: 'blue',
          sub: [
            {
              name: this.$t('assignment.all_tasks'),
              count: this.all_assignments_tasks
            }
          ]
        },
        {
          icon: 'ActivityIcon',
          value: 'need_assess',
          color: 'dark',
          name: this.$t('assignment.need_to_be_assessed'),
          sub: [
            {
              name: this.$t('basic.tasks'),
              count: this.need_assess_tasks
            },
            {
              name: this.$t('basic.students'),
              count: this.need_assess_students
            }
          ]
        },
        {
          icon: 'CheckIcon',
          value: 'late_assessment',
          color: 'warning',
          name: this.$t('assignment.late_assessments'),
          sub: [
            {
              name: this.$t('basic.tasks'),
              count: this.late_assessment_tasks
            },
            {
              name: this.$t('basic.students'),
              count: this.late_assessment_students
            }
          ]
        },
        {
          icon: 'ActivityIcon',
          value: 'full_assess',
          color: 'success',
          name: this.$t('assignment.fully_assessed'),
          sub: [
            {
              name: this.$t('basic.tasks'),
              count: this.full_assess_tasks
            },
            {
              name: this.$t('basic.students'),
              count: this.full_assess_students
            }
          ]
        }
      ]
      return status
    },
    filtered_statues () {
      let filterName = ''
      let filterValue = ''
      switch (this.topFilter) {
      case 'full_assess':
        filterName = 'full_assess'
        filterValue = 1
        break
      case 'late_assessment':
        filterName = 'late_assessment'
        filterValue = 1
        break

      case 'need_assess':
        filterName = 'need_assess'
        filterValue = 1
        break
      default:
        filterName = ''
        filterValue = ''
        break
      }
      const filter = {}
      filter[filterName] = filterValue
      return filter
    }
  },
  watch: {
    student (val) {
      if (this.is_parent) this.getOneStudentAssignments(val.user_id, this.currentPage)
    },
    currentPage (v) {
      this.getAssignments(v)
      if (this.is_parent) this.getOneStudentAssignments(this.student.user_id, v)
    },
    sideFilters: {
      deep: true,
      handler (v) {
        this.getAssignments()
      }
    }
  },
  created () {
    this.getFiltersData()
  },
  mounted () {
    if (this.is_student) this.getAssignments()
    else if (this.is_parent) this.getOneStudentAssignments(this.student.user_id)
  },

  methods: {
    /////////////////////////
    // Loading
    ////////////////////////
    startLoading () {
      this.VSLoading()
    },
    stoptLoading () {
      this.stopVSLoading()
    },

    /////////////////////////
    // API
    ////////////////////////
    assignmentsRequest (endpoint, user_id = null) {
      const params = {
        page: this.currentPage,
        ...this.sideFilters
      }
      //** Show Loader **//
      this.startLoading()
      AssignmentStudentRepository[endpoint](params, user_id)
        .then(res => {
          this.assignments = res.data
          this.setPagination(res)
        })
        .catch(err => {
          this.errMsg(err, this.$t('form.loading_data_error'))
        })
        .finally(() => this.stoptLoading())
    },
    getAssignments (page = null) {
      this.assignmentsRequest('getStudentAssignments', null, page)
    },
    getOneStudentAssignments (user_id, page) {
      this.assignmentsRequest('getOneStudentAssignments', user_id, page)
    },

    //////////////////////////////
    // Filters
    //////////////////////////////
    changeFilter (val) {
      this.topFilter = val
    },
    setFilters (val) {
      val.map(item => {
        this.sideFilters[item.name] = item.checked
      })
    },
    getFiltersData () {
      AssignmentStudentRepository.getDivisionSubject().then(res => {
        res.data.forEach(item => {
          this.filters[0]['by'].push({
            id: item.id,
            name: item.name,
            checked: false
          })
        })
      })
    },

    /////////////////////////
    // Pagination
    /////////////////////////
    setPagination (data) {
      this.totalPages = data.last_page
      this.countPerPage = data.per_page
      this.currentPage = data.current_page
    }
  }
}
</script>
<style lang="scss" src="./assignments.scss" scoped></style>
