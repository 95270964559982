<template>
  <canteen-form :if-edit="true" @formSubmited = "editCanteen"/>
</template>

<script>
import canteenForm from '@/modules/canteen/pages/canteen/components/canteenForm'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const canteenRepository = RepositoryFactory.get(
  'canteenRepository',
  'canteenRepository'
)
export default {
  name: 'create',
  components:{
    canteenForm
  },
  data () {
    return {
      canteenId: this.$route.params.id
    }
  },
  methods: {
    editCanteen (payload) {
      this.VSLoading()
      canteenRepository.editCanteen(this.canteenId, payload).then(() => {
        this.successMsg(this.$t('canteen.editCanteenSuccessfully'))
        this.$router.push({name:'allCanteen'})
        this.stopVSLoading()
      }).catch((err) => {
        this.errMsg(err)
      })
    }
  }
}
</script>

<style scoped>

</style>
