export default {
  name: 'imagePreview',
  data () {
    return {
      modalProps: {
        // width: this.isMobileWebView ? '98%' : '61%',
        height: 'auto',
        minHeight: 450,
        scrollable: true
      },
      modalName: 'imagePreviewModal',
      mimeTypes: {
        png: 'image',
        jpeg: 'image',
        jpg: 'image',
        mp4: 'video',
        pdf: 'pdf',
        docx: 'wordFile',
        doc: 'wordFile',
        xlsx: 'excelFile',
        xls: 'excelFile',
        ppt: 'powerPointFile',
        pptx: 'powerPointFile'

      },
      url: null,
      type: null,
      customEvents: [{ eventName: 'openPreviewModal', callback: this.loadData }]
    }
  },

  created () {

    ////////////////////////////////////////////////////
    //**          Custom Event Listener           ** //
    //////////////////////////////////////////////////
    this.customEvents.forEach(function (customEvent) {
      // eslint-disable-next-line no-undef
      $sharedEvent.listen(customEvent.eventName, customEvent.callback)
    })
  },
  destroyed () {
    ////////////////////////////////////////////////////
    //**      Remove Custom Event Listener        ** //
    //////////////////////////////////////////////////
    this.customEvents.forEach(function (customEvent) {
      // eslint-disable-next-line no-undef
      $sharedEvent.off(customEvent.eventName, customEvent.callback)
    })
  },
  methods: {
    showModal () {
      this.$modal.show(this.modalName)
    },
    hideModal () {
      this.$modal.hide(this.modalName)
    },
    loadData (data) {
      this.type = this.mimeTypes[data.urlType]
      this.url = data.url
      this.showModal()
    },
    toDataURL (requestUrl) {
      const proxyUrl = 'https://cors-anywhere.herokuapp.com/'
      return fetch(requestUrl)
        .then((res) => {
          return res.blob()
        })
        .then(blob => {
          return URL.createObjectURL(blob)
        })
    },
    async downloadFile () {
      this.$vs.loading()
      const a = document.createElement('a')
      a.href = await this.toDataURL(this.url)
      a.download = 'Attachment'
      a.target = '_blank'
      document.body.appendChild(a)
      a.click()
      this.$vs.loading.close()
      document.body.removeChild(a)
    },
    cancel () {
      this.hideModal()
    }
  }

}
