import vSelect from 'vue-select'
import ModuleMixins from '../../mixins'
import {mapGetters} from 'vuex'
import Service from '../../services/poi'
export default {
  name: 'cloneAcademicYear',
  mixins: [ModuleMixins],
  components: {
    'v-select': vSelect
  },
  data () {
    return {
      restService: Service,
      modalName: 'cloneAcademicYear',
      modalProps: {
        width: '600px',
        height: 'auto',
        scrollable: true
      },
      fromAcademicYear: null,
      toAcademicYear: null,
      toAcademicYearOptions:null
    }
  },
  mounted () {
    this.storeSubSubscriberLogic()
  },
  destroyed () {
    /// ** Stop VueX Actions Subscriber to prevent memory leak and Errors **///
    this.storeUnSubscriber()
  },
  methods: {
    storeSubSubscriberLogic () {

      /// ** Interact Modals & other actions throw vuex ** ///
      /// ** this case to listen on vuex action to open modal from other components ** ///
      const self = this
      this.storeUnSubscriber = this.$store.subscribeAction({
        before: (action, state) => {
          // if you need to implement before action
          // it's default behavior
        },
        after: (action, state) => {
          if (action.type === 'manageModal' && action.payload.name === self.modalName) {
            if (action.payload.show === true) {
              this.loadData(action.payload.data)
            } else if (action.payload.show === false) {
              this.cancel()
            }
          }
        },
        error: (action, state, error) => {
          this.cancel()
          console.error(error)
        }
      })
    },
    showModal () {
      this.$modal.show(this.modalName)
    },
    hideModal () {
      this.$modal.hide(this.modalName)
    },
    loadData () {
      this.resetProps()
      this.toAcademicYearOptions = this.futureAcademicYearsOptions
      this.showModal()
    },
    resetProps () {
      this.fromAcademicYear = null
      this.toAcademicYear = null
    },
    canEdit (academicYearId) {
      const isEditable = this.futureAcademicYearsOptions.find(item => item.id === academicYearId)
      if (isEditable) {
        return true
      } else {
        return false
      }
    },
    filterToAcademicYears () {
      this.toAcademicYear =  null
      this.toAcademicYearOptions = this.futureAcademicYearsOptions.filter(item => item.id !== this.fromAcademicYear.id)
    },
    cloneConfirmation () {
      this.$vs.dialog({
        color:'danger',
        title:this.$t('poi.clone_warning'),
        text: this.$t('poi.all_data_on_target_academic_year_will_be_replaced'),
        accept:this.cloneAcademicYear
      })
    },
    async cloneAcademicYear () {
      this.VSLoading()

      const data = {
        from_academic_year: this.fromAcademicYear.id,
        to_academic_year: this.toAcademicYear.id
      }
      await this.restService.cloneAcademicYear(data)
        .then(res => {
          this.savingStoreData(res.data)
        })
        .catch(err => {
          this.errMsg(err.message, this.$t('poi.clone_error'))
        })

      this.stopVSLoading()
    },
    savingStoreData (data) {
      if (this.selectedAcademicYear.id === this.toAcademicYear.id) {

        data = data.map(item => {
          item['isEditable'] = this.canEdit(item.academic_year_id)
          return item
        })

        // ** Replace Current POI with Selected Academic Year POI ** //
        this.$store.dispatch('replacePoi', this.cloneItem(data))
          .then(() => {
            this.successMsg(this.$t('poi.saved_successfully'), this.$t('poi.clone_academic_year'))

            this.cancel()
          })
      } else {
        this.successMsg(this.$t('poi.saved_successfully'), this.$t('poi.clone_academic_year'))

        this.cancel()
      }
    },
    cancel () {
      this.resetProps()
      this.hideModal()
    }
  },
  computed: {
    ...mapGetters({academicYearsOptions: 'getAcademicYearsOptions'}),
    ...mapGetters({selectedAcademicYear: 'getSelectedAcademicYear'}),
    ...mapGetters({futureAcademicYearsOptions: 'getFutureAcademicYearsOptions'})
  }
}
