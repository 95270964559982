<template>
  <div>
    <vx-card class="teacher-form p-3 pt-6 pb-6 md:p-6">
      <!-- Strat Form Add Teacher and Edit Teacher -->
      <form @submit.prevent="formSubmitted">
        <!-- Start Full Name English-->
        <div>
          <!-- Start label full name -->
          <vs-divider class="w-full text-center">
            <label>
              {{ $t('teachersGateway.full_name_en') }}
              <sup class="text-danger">*</sup>
            </label>
          </vs-divider>
          <!-- End label full name -->
          <div class="vx-row mt-5 items-center">
            <!-- Start First name -->
            <div class="vx-col lg:w-1/4 w-full mb-3">
              <vs-input
                v-model="teacher_form.first_name"
                name="First Name En"
                v-validate="{
                  required: true,
                  alpha_spaces: true,
                  regex: /^[a-zA-Z\s]*$/
                }"
                class="w-full"
                :placeholder="`${$t('teachersGateway.first_name')}`"
                type="text" />
              <div class="w-full">
                <span class="text-danger text-sm text-center">{{
                  errors.first('First Name En')
                }}</span>
              </div>
            </div>
            <!-- End First name -->

            <!-- Start Scound name -->
            <div class="vx-col lg:w-1/4 w-full mb-3">
              <vs-input
                type="text"
                v-model="teacher_form.second_name"
                name="Second Name En"
                v-validate="{
                  required: true,
                  alpha_spaces: true,
                  regex: /^[a-zA-Z\s]*$/
                }"
                class="w-full"
                :placeholder="`${$t('teachersGateway.second_name')}`" />
              <div class="w-full">
                <span class="text-danger text-sm text-center">{{
                  errors.first('Second Name En')
                }}</span>
              </div>
            </div>
            <!-- End Scound name -->

            <!-- Start Third name -->
            <div class="vx-col lg:w-1/4 w-full mb-3">
              <vs-input
                type="text"
                v-model="teacher_form.third_name"
                name="Third Name En"
                v-validate="{
                  required: true,
                  alpha_spaces: true,
                  regex: /^[a-zA-Z\s]*$/
                }"
                class="w-full"
                :placeholder="`${$t('teachersGateway.third_name')}`" />
              <div class="w-full">
                <span class="text-danger text-sm text-center">{{
                  errors.first('Third Name En')
                }}</span>
              </div>
            </div>
            <!-- End Third name -->

            <!-- Start fourth name -->
            <div class="vx-col lg:w-1/4 w-full mb-3">
              <vs-input
                type="text"
                v-model="teacher_form.fourth_name"
                name="Fourth Name En"
                v-validate="{
                  required: true,
                  alpha_spaces: true,
                  regex: /^[a-zA-Z\s]*$/
                }"
                class="w-full"
                :placeholder="`${$t('teachersGateway.fourth_name')}`" />
              <div class="w-full">
                <span class="text-danger text-sm text-center">{{
                  errors.first('Fourth Name En')
                }}</span>
              </div>
            </div>
            <!-- End fourth  name -->
          </div>
        </div>
        <!-- End Full Name English-->

        <!-- Start Full Name Arabic-->
        <div>
          <!-- Start label full name Arabic-->
          <vs-divider class="text-center w-full">
            <label>
              {{ $t('teachersGateway.full_name_ar') }}
              <sup class="text-danger">*</sup>
            </label>
          </vs-divider>
          <!-- End label full name Arabic-->
          <div class="vx-row mt-5 items-center">
            <!-- Start First name -->
            <div class="vx-col lg:w-1/4 w-full mb-3">
              <vs-input
                v-model="teacher_form.first_name_ar"
                name="First Name Ar"
                v-validate="{
                  required: true,
                  alpha_spaces: true,
                  regex: /^[\u0600-\u06FF-\s]*$/
                }"
                class="w-full"
                :placeholder="`${$t('teachersGateway.first_name')}`"
                type="text" />
              <div class="w-full">
                <span class="text-danger text-sm text-center">{{
                  errors.first('First Name Ar')
                }}</span>
              </div>
            </div>
            <!-- End First name -->

            <!-- Start Scound name -->
            <div class="vx-col lg:w-1/4 w-full mb-3">
              <vs-input
                type="text"
                v-model="teacher_form.second_name_ar"
                name="Second Name Ar"
                v-validate="{
                  required: true,
                  alpha_spaces: true,
                  regex: /^[\u0600-\u06FF-\s]*$/
                }"
                class="w-full"
                :placeholder="`${$t('teachersGateway.second_name')}`" />
              <div class="w-full">
                <span class="text-danger text-sm text-center">{{
                  errors.first('Second Name Ar')
                }}</span>
              </div>
            </div>
            <!-- End Scound name -->

            <!-- Start Third name -->
            <div class="vx-col lg:w-1/4 w-full mb-3">
              <vs-input
                type="text"
                v-model="teacher_form.third_name_ar"
                name="Third Name Ar"
                v-validate="{
                  required: true,
                  alpha_spaces: true,
                  regex: /^[\u0600-\u06FF-\s]*$/
                }"
                class="w-full"
                :placeholder="`${$t('teachersGateway.third_name')}`" />
              <div class="w-full">
                <span class="text-danger text-sm text-center">{{
                  errors.first('Third Name Ar')
                }}</span>
              </div>
            </div>
            <!-- End Third name -->

            <!-- Start fourth name -->
            <div class="vx-col lg:w-1/4 w-full mb-3">
              <vs-input
                type="text"
                v-model="teacher_form.fourth_name_ar"
                name="Fourth Name Ar"
                v-validate="{
                  required: true,
                  alpha_spaces: true,
                  regex: /^[\u0600-\u06FF-\s]*$/
                }"
                class="w-full"
                :placeholder="`${$t('teachersGateway.fourth_name')}`" />
              <div class="w-full">
                <span class="text-danger text-sm text-center">{{
                  errors.first('Fourth Name Ar')
                }}</span>
              </div>
            </div>
            <!-- End fourth  name -->
          </div>
        </div>
        <!-- End Full Name Arabic-->

        <!-- Start Gender  -->
        <div class="vx-row mt-5 items-center">
          <!-- Start label Gender-->
          <div class="vx-col lg:w-1/5 w-full">
            <label>
              {{ $t('teachersGateway.gender') }}
              <sup class="text-danger">*</sup>
            </label>
          </div>
          <!-- End label full Gender -->

          <!-- Start Gender Select -->
          <div class="vx-col lg:w-3/5 w-full">
            <v-select
              :placeholder="`${$t('teachersGateway.gender')}`"
              class="w-full select-large"
              :clearable="false"
              appendToBody
              v-model="teacher_form.gender"
              v-validate="'required'"
              name="Gender"
              label="name"
              :reduce="(option) => option.name"
              :options="genders">
            </v-select>
            <div class="w-full">
              <span class="text-danger text-sm text-center">{{
                errors.first('Gender')
              }}</span>
            </div>
          </div>
          <!-- End Gender Select -->
        </div>
        <!-- End Gender  -->

        <!-- Start birth date  -->
        <div class="vx-row mt-5 items-center">
          <div class="vx-col lg:w-1/5 w-full">
            <label>
              {{ $t('teachersGateway.birth_date') }}
            </label>
          </div>

          <div class="vx-col lg:w-3/5 w-full">
            <flat-pickr
              :placeholder="`${$t('teachersGateway.birth_date')}`"
              :config="{
                maxDate: this.currentDate
              }"
              v-model="teacher_form.birth_date"
              class="w-full" />
          </div>
        </div>
        <!-- End birth date  -->

        <!-- Start mobile  -->
        <div
          class="vx-row mt-5 items-center"
          v-for="(phone, indexOfPhone) in teacher_form.phone"
          :key="'phone' + indexOfPhone">
          <div class="vx-col lg:w-1/5 w-full">
            <label>
              {{ $t('teachersGateway.mobile') }}
              <sup class="text-danger">*</sup>
            </label>
          </div>

          <div class="vx-col lg:w-3/5 w-full">
            <vx-input-group class="w-full">
              <vs-input
                v-model="teacher_form.phone[indexOfPhone]"
                :name="`Personal Mobile ${indexOfPhone + 1}`"
                v-validate="'required|numeric|length:11'"
                class="w-3/4 vx-col lg:w-2/3"
                :placeholder="`${$t('teachersGateway.mobile')}`"
                type="text"
                @input="check('teacher_form.phone', indexOfPhone)" />
              <template slot="append">
                <div class="append-text btn-addon">
                  <!-- Remove mobile -->
                  <vs-button
                    @click="removePhone(indexOfPhone)"
                    v-if="
                      indexOfPhone ||
                      (!indexOfPhone && teacher_form.phone.length > 1)
                    "
                    color="danger"
                    type="border"
                    icon-pack="feather"
                    icon="icon-minus" />

                  <!-- Add mobile -->
                  <vs-button
                    @click="addPhone(indexOfPhone)"
                    v-if="indexOfPhone == teacher_form.phone.length - 1"
                    color="primary"
                    type="border"
                    icon-pack="feather"
                    icon="icon-plus" />
                </div>
              </template>
            </vx-input-group>
            <div class="w-full">
              <span class="text-danger text-sm text-center">{{
                errors.first(`Personal Mobile ${indexOfPhone + 1}`)
              }}</span>
            </div>
          </div>
        </div>
        <!-- End mobile  -->

        <!-- Start Address  -->
        <div class="vx-row mt-5 items-center">
          <div class="vx-col lg:w-1/5 w-full">
            <label>
              {{ $t('teachersGateway.address') }}
              <sup class="text-danger">*</sup>
            </label>
          </div>

          <div class="vx-col lg:w-3/5 w-full">
            <div class="vx-row">
              <vs-input
                v-model="teacher_form.Address"
                name="Address"
                v-validate="'required'"
                class="w-3/4 vx-col lg:w-2/3"
                :placeholder="`${$t('teachersGateway.address')}`"
                type="text" />
            </div>

            <div class="w-full">
              <span class="text-danger text-sm text-center">{{
                errors.first('Address')
              }}</span>
            </div>
          </div>
        </div>
        <!-- End Address  -->

        <!-- Start landline  -->
        <div class="vx-row mt-5 items-center">
          <div class="vx-col lg:w-1/5 w-full">
            <label>
              {{ $t('teachersGateway.landline') }}
            </label>
          </div>
          <div class="vx-col lg:w-3/5 w-full">
            <div class="vx-row">
              <vs-input
                v-model="teacher_form.landline"
                name="Landline"
                v-validate="'numeric|min:10|max:11'"
                class="w-3/4 vx-col lg:w-2/3"
                :placeholder="`${$t('teachersGateway.landline')}`"
                type="text" />
            </div>
            <div class="w-full">
              <span class="text-danger text-sm text-center">{{
                errors.first('Landline')
              }}</span>
            </div>
          </div>
        </div>
        <!-- End landline  -->

        <!-- Start official phone  -->
        <div class="vx-row mt-5 items-center">
          <!-- Start label landline-->
          <div class="vx-col lg:w-1/5 w-full">
            <label>
              {{ $t('teachersGateway.official_phone') }}
              <sup class="text-danger">*</sup>
            </label>
          </div>
          <!-- End label official phone -->

          <div class="vx-col lg:w-3/5 w-full">
            <div class="vx-row">
              <vs-input
                v-model="teacher_form.official_phone"
                name="Official Phone"
                v-validate="'required|numeric|min:10|max:20'"
                class="w-3/4 vx-col lg:w-2/3"
                :placeholder="`${$t('teachersGateway.official_phone')}`"
                type="text" />
            </div>

            <!-- Start official phone Validathion -->
            <div class="w-full">
              <span class="text-danger text-sm text-center">{{
                errors.first('Official Phone')
              }}</span>
            </div>
            <!-- End official phone Validathion -->
          </div>
        </div>
        <!-- End official phone  -->

        <!-- Start official mail  -->
        <div class="vx-row mt-5 items-center">
          <div class="vx-col lg:w-1/5 w-full">
            <label>
              {{ $t('teachersGateway.official_mail') }}
              <sup class="text-danger">*</sup>
            </label>
          </div>

          <div class="vx-col lg:w-3/5 w-full">
            <div class="vx-row">
              <vs-input
                v-model="teacher_form.official_email"
                name="Official Email"
                v-validate="'required|email'"
                class="w-3/4 vx-col lg:w-2/3"
                :placeholder="`${$t('teachersGateway.official_mail')}`"
                type="text" />
            </div>

            <div class="w-full">
              <span class="text-danger text-sm text-center">{{
                errors.first('Official Email')
              }}</span>
            </div>
          </div>
        </div>
        <!-- End official mail  -->

        <!-- Start Is Homeroom Teacher -->
        <div class="vx-row mt-5 items-center">
          <!-- Start label Homeroom Teacher-->
          <div class="vx-col lg:w-1/5 w-full">
            <label>
              {{ $t('teachersGateway.is_homeroom_teacher') }}
              <sup class="text-danger">*</sup>
            </label>
          </div>
          <!-- End label Homeroom Teacher -->
          <div class="w-full lg:w-3/5 text-center">
            <ul class="vx-row mt-2">
              <li class="vx-col w-1/2 lg:w-1/3">
                <vs-radio
                  v-model="teacher_form.is_homeroom_teacher"
                  :vs-value="1"
                  name="is_homeroom_teacher"
                  >{{ $t('teachersGateway.yes') }}</vs-radio
                >
              </li>
              <li class="vx-col w-1/2 lg:w-1/3">
                <vs-radio
                  v-model="teacher_form.is_homeroom_teacher"
                  :vs-value="0"
                  name="is_homeroom_teacher"
                  >{{ $t('teachersGateway.no') }}</vs-radio
                >
              </li>
            </ul>
          </div>
        </div>
        <!-- End Is Homeroom Teacher -->

        <!-- Start Divisions/Sections  -->
        <div
          class="vx-row mt-5 items-center"
          v-for="(devision, indexOfDevisions) in teacher_form.divisions"
          :key="'e' + indexOfDevisions">
          <!-- Start label Divisions/Sections-->
          <div class="vx-col lg:w-1/5 w-full">
            <label>
              {{ $t('teachersGateway.Divisions_Sections') }}
              <sup class="text-danger">*</sup>
            </label>
          </div>
          <!-- End label full Divisions/Sections -->

          <!-- Start Divisions Select -->
          <div class="vx-col lg:w-1/3 w-full mb-3">
            <v-select
              :placeholder="`${$t('teachersGateway.divisions')}`"
              class="w-full select-large"
              :clearable="false"
              appendToBody
              v-model="devision.division_id"
              label="long_name"
              :reduce="(option) => option.id"
              :options="divisions"
              @input="getSectionsByDivisionsID(indexOfDevisions)">
            </v-select>
            <div class="w-full">
              <span class="text-danger text-sm text-center">{{
                errors.first(`Devision ${indexOfDevisions}`)
              }}</span>
            </div>
          </div>
          <!-- End Divisions Select -->

          <!-- Start Sections Select -->
          <div class="vx-col lg:w-1/5 w-full mb-3">
            <v-select
              :placeholder="`${$t('teachersGateway.sections')}`"
              class="w-full select-large"
              :clearable="false"
              appendToBody
              v-model="devision.section_id"
              label="long_name"
              :disabled="!devision.division_id"
              :reduce="(option) => option.id"
              :options="devision.sections"
              @input="
                getUniqueListBy('teacher_form.divisions', indexOfDevisions)
              ">
            </v-select>
          </div>
          <!-- End Sections Select -->

          <div
            class="vx-col lg:w-1/5 w-full align_buttons lg:text-left text-center mb-3">
            <!-- Remove Divisions/Sections  -->
            <vs-button
              v-if="teacher_form.divisions.length > 1"
              color="danger"
              @click="removeDevision(indexOfDevisions)"
              type="border"
              class="mr-3 inline-block m-0"
              icon-pack="feather"
              icon="icon-minus"></vs-button>
            <!-- Add Divisions/Sections -->
            <vs-button
              @click="addDevision(indexOfDevisions)"
              v-if="indexOfDevisions == teacher_form.divisions.length - 1"
              color="primary"
              type="border"
              icon-pack="feather"
              icon="icon-plus"
              class="inline-block m-0"></vs-button>
          </div>
        </div>
        <!-- End Divisions/Sections  -->

        <!-- Start subjects  -->
        <div class="vx-row mt-5 items-center">
          <div class="vx-col lg:w-1/5 w-full">
            <label>
              {{ $t('teachersGateway.subjects') }}
              <sup class="text-danger">*</sup>
            </label>
          </div>
          <div class="vx-col lg:w-3/5 w-full">
            <v-select
              :placeholder="`${$t('teachersGateway.subjects')}`"
              class="w-full select-large"
              :clearable="false"
              appendToBody
              multiple
              v-model="teacher_form.subject_ids"
              v-validate="'required'"
              name="Subjects"
              label="name"
              :reduce="(option) => option.id"
              :options="subjects">
            </v-select>
            <div class="w-full">
              <span class="text-danger text-sm text-center">{{
                errors.first('Subjects')
              }}</span>
            </div>
          </div>
        </div>
        <!-- End subjects  -->

        <!-- Start Spoken langs  -->
        <div class="vx-row mt-5 items-center">
          <div class="vx-col lg:w-1/5 w-full">
            <label>
              {{ $t('teachersGateway.spoken_langs') }}
              <sup class="text-danger">*</sup>
            </label>
          </div>

          <!-- Start Spoken langs Select -->
          <div class="vx-col lg:w-3/5 w-full">
            <v-select
              multiple
              :placeholder="`${$t('teachersGateway.spoken_langs')}`"
              class="w-full select-large"
              :clearable="false"
              appendToBody
              v-model="teacher_form.spoken_language_ids"
              label="name"
              v-validate="'required'"
              name="language"
              :reduce="(option) => option.id"
              :options="languages">
            </v-select>
            <div class="w-full">
              <span class="text-danger text-sm text-center">{{
                errors.first('language')
              }}</span>
            </div>
          </div>
          <!-- End Spoken langs Select -->
        </div>
        <!-- End Spoken langs  -->

        <!-- Start Image  -->
        <div class="vx-row mt-5 items-center">
          <div class="vx-col lg:w-1/5 w-full">
            <label>
              {{ $t('teachersGateway.image') }}
            </label>
          </div>
          <div class="vx-col lg:w-3/5 w-full">
            <div class="vx-row">
              <vs-input
                @change="updateCurrImg"
                v-model="image"
                name="Image"
                class="w-3/4 vx-col lg:w-2/3"
                type="file"
                accept="image/*;capture=camera" />
            </div>
            <feather-icon
              icon="XIcon"
              svgClasses="h-5 w-5"
              @click="deleteDocument()"
              v-if="imageView || teacher_form.image"
              class="mr-2 remove-teacher-attachment" />
            <img
              v-if="imageView || teacher_form.image"
              :src="edit_id ? teacher_form.image : imageView"
              alt="teacher_Pic" />
          </div>
        </div>
        <!-- End Image  -->

        <!-- Actions -->

        <div class="flex flex-wrap justify-between items-center mt-10">
          <vs-button
            color="warning"
            type="border"
            class="inline-block"
            @click="back()"
            >{{ $t('form.cancel') }}</vs-button
          >
          <vs-button class="inline-block" @click="formSubmitted()">
            {{ $t('form.submit') }}
          </vs-button>
        </div>
      </form>
    </vx-card>
  </div>
</template>

<script>
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const addTeacherRepo = RepositoryFactory.get(
  'teachersGatewayModule',
  'addTeacher'
)
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import {commonDataMixin} from '@/mixins.js'
export default {
  mixins: [commonDataMixin],
  data() {
    return {
      imageView: '',
      image: '',
      file: '',
      teacher_form: this.teacherFormProps,
      genders: [],
      subjects: [],
      divisions: [],
      languages: []
    }
  },

  props: {
    teacherFormProps: {
      type: Object,
      required: true
    },
    edit_id: {
      type: [String, Number],
      default: () => {
        return ''
      }
    }
  },

  components: {
    flatPickr
  },

  methods: {
    formSubmitted() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          if (!this.edit_id) {
            this.addTeacher()
          } else {
            this.editTeacher()
          }
        } else {
          this.$vs.notify({
            color: 'danger',
            title: 'error',
            text: this.$t(
              'teachersGateway.teacher_has_not_been_saved_please_complete_your_data'
            )
          })
        }
      })
    },

    getSectionsByDivisionsID(index) {
      this.getUniqueListBy('teacher_form.divisions', index)
      const divisionId = this.teacher_form.divisions[index].division_id
      const data = {
        division_ids: [divisionId]
      }
      addTeacherRepo.learingStages(data).then((res) => {
        this.teacher_form.divisions[index].sections = res.data
      })
      this.teacher_form.divisions[index].section_id = ''
    },
    // ----- Start upload img ----------
    updateCurrImg(e) {
      this.file = e.target.files[0]
      const data = new FormData()
      data.append('attachments[0]', this.file)
      this.commonUploadFiles(data).then((res) => {
        this.teacher_form.image = res[0].attachment_url
        this.imageView = res[0].attachment_url
        this.successMsg(this.$t('form.image_uploaded_successfully'))
      })
    },
    // ----- End upload img ----------

    // ----- start delete upload img ----------
    deleteDocument() {
      const document = this.edit_id ? this.teacher_form.image : this.imageView
      const data = {
        file_path: document
      }
      this.deleteDocumentFromS3(data)
        .then(() => {
          this.teacher_form.image = ''
          this.imageView = ''
          this.image = ''
        })
        .catch(() => {
          // this.errMsg(this.$t('form.failed_to_delete_documment'))
        })
    },
    // ----- End delete upload img ----------

    // ------- Start addTeacher -----
    addTeacher() {
      addTeacherRepo
        .addTeacher(this.teacher_form)
        .then((res) => {
          this.$router.push('/teachers-gateway')
          this.successMsg(res.status)
        })
        .catch((err) => {
          this.errMsg(err.data.message)
        })
    },
    // ------- End addTeacher -----

    // ------- Start getAllSubjects -----
    getAllSubjects() {
      addTeacherRepo.getAllSubjects().then((res) => {
        this.subjects = res.data
      })
    },
    // ------- End getAllSubjects -----
    getTeacherDivisions() {
      addTeacherRepo.getTeacherDivisions().then((res) => {
        this.divisions = res.data.data
      })
    },

    // -------  Start phone Methods -------
    addPhone() {
      if (this.teacher_form.phone[this.teacher_form.phone.length - 1] !== '') {
        this.teacher_form.phone.push('')
      }
    },
    removePhone(indexOfmobile) {
      this.teacher_form.phone.splice(indexOfmobile, 1)
    },
    //-------  End phone Methods -------

    addDevision(index) {
      if (
        this.teacher_form.divisions[index].division_id !== '' &&
        this.teacher_form.divisions[index].section_id !== ''
      ) {
        this.teacher_form.divisions.push({
          division_id: '',
          section_id: '',
          sections: []
        })
      }
    },
    removeDevision(index) {
      this.teacher_form.divisions.splice(index, 1)
    },

    editTeacher() {
      delete this.teacher_form.school_id
      addTeacherRepo
        .editTeacher(this.edit_id, this.teacher_form)
        .then((res) => {
          this.$router.push('/teachers-gateway')
          this.successMsg(res.status)
        })
        .catch((err) => {
          this.errMsg(err.data.message)
        })
    },
    showSingleTeacher() {
      this.VSLoading()
      addTeacherRepo
        .showSingleTeacher(this.edit_id)
        .then((res) => {
          this.teacher_form = Object.assign({}, res.data)

          this.teacher_form.divisions = []
          res.data.divisions.forEach((item) => {
            const obj = {
              division_id: item.pivot.division_id,
              section_id: item.pivot.section_id
            }
            this.getSectionsByDivisionsIDs(item.pivot.division_id).then(
              (res) => {
                obj.sections = res
              }
            )
            this.teacher_form.divisions.push(obj)
          })
        })
        .finally(() => {
          this.stopVSLoading()
          this.showform = true
        })
    },
    getSectionsByDivisionsIDs(divisionId) {
      return new Promise((resolve) => {
        const data = {
          division_ids: [divisionId]
        }
        addTeacherRepo.learingStages(data).then((res) => {
          resolve(res.data)
        })
      })
    },
    intiallizeDivison() {
      if (this.teacherFormProps.divisions.length === 0) {
        const obj = {division_id: '', section_id: '', sections: []}
        this.teacherFormProps.divisions.push(obj)
      }
    },
    //
    check(array, index) {
      const splitted = array.split('.')
      const arr = this[splitted[0]][splitted[1]]
      const unique = new Set(arr)
      if (unique.size !== arr.length) {
        this[splitted[0]][splitted[1]][index] = ''
        this.errMsg(this.$t('teachersGateway.you_cant_repeat_same_value'))
      }
    },

    getUniqueListBy(array, indexOfDevisions) {
      const splitted = array.split('.')
      const arr = this[splitted[0]][splitted[1]]
      const filteredArr = Array.from(new Set(arr.map(JSON.stringify))).map(
        JSON.parse
      )

      if (filteredArr.length !== arr.length) {
        this[splitted[0]][splitted[1]].splice(indexOfDevisions, 1)
        this.errMsg(this.$t('teachersGateway.you_cant_repeat_same_value'))
      }
    },
    back() {
      this.$router.back()
    }
  },
  mounted() {
    this.intiallizeDivison()
    this.getGenders() // from Mixins
    this.getTeacherDivisions()
    this.getAllSubjects()
    this.getLanguages() // from Mixins
  }
}
</script>
<style lang="scss" scoped>
img {
  max-height: 50%;
  max-width: 50%;
  display: block;
  padding: 5px;
  border: 1px solid #ccc;
  margin: 1em;
}
.remove-teacher-attachment {
  cursor: pointer;
  svg {
    stroke: red;
    stroke-width: 2px;
  }
}

</style>
<style>
.vs__selected{
  max-width: 85%;
}
</style>
