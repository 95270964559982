
<template>
  <div>
    <!-- Form -->
    <exam-form
      :exam_prop="exam"
      @formSubmitted="formsubmittion"
      :edit_mood="editMood"
    ></exam-form>
  </div>
</template>

<script>
// Components
import ExamForm from '@/modules/exam/components/ExamForm'
// API
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const ExamRepo = RepositoryFactory.get('ExamModule', 'Exam')


export default {

  data () {
    return {
      editMood:true,
      //  Exam
      exam:{      }

    }
  },


  methods: {

    formsubmittion () {
      // this.$router.push({name:'exams', params: { examId:1 }})
      this.successMsg(this.$t('exam.exam_has_been_added_successfully'))

    },
    getExam () {
      this.VSLoading()

      ExamRepo.getExam(this.exam_id)
        .then(res => {
          console.log('exam', res)
          this.exam = res.data
          this.exam.questions.forEach(q => {
            if (!q.attachments.length) q.attachments = [{}]
          })
        }).catch(err => {
          console.log('err', err)
        }).finally(() => this.stopVSLoading())
    }

  },
  created () {
    this.getExam()
  },
  computed: {
    exam_id () {
      return this.$route.params.id
    }
  },
  components: {

    ExamForm

  }
}
</script>

<style lang="scss" scoped>
</style>
