import axios from 'axios'

export default {
  //Stduent Assignment
  getStudentAssignments (filters) {
    return axios.post('/student/filter_student_assignments', {
      ...filters
    })
  },
  getOneStudentAssignments (page = null, user_id) {
    return axios.get(
      '/student/assignments', {
        params: {user_id, page}
      }
    )
  },
  createStudentAssignmentAnswer (taskId, data) {
    return axios.post(`/student/assignments/${taskId}/tasks`, data)
  },
  updateStudentAssignmentAnswer (taskId, data) {
    return axios.patch(`/student/assignment-tasks/${taskId}`, data)
  },
  deleteFile (data) {
    return axios.post('/common/delete-file', data)
  },
  deleteAssignmentsAttachments (data) {
    return axios.post('/teachers/assignments/attachment/delete', data)
  },
  deleteAssessmentAttachments (data) {
    return axios.post('/planner/assessments/attachment/delete', data)
  },
  getDivisionSubject () {
    return axios.get('/student/get_student_subjects')
  }
}
