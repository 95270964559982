
<template>
  <vx-card class="p-3 pt-6 pb-6 md:p-6">
    <div class="vx-row mb-6">
      <div class="vx-col sm:w-1/4 w-full">
        <label>
          {{ $t("subject.Name") }}
          <sup class="text-danger">*</sup>
        </label>
      </div>
      <!---------------------------------------------->
      <div class="vx-col sm:w-1/2 w-full">
        <vs-input
          :placeholder="`${$t('subject.Name')}`"
          v-model="Subject.subject_name"
          name="subjectName"
          v-validate="'required'"
          class="selectExample w-full"
        />
        <span
          v-if="issueSubmitted && errors.has('subjectName')"
          class="text-danger text-sm"
          >{{ errors.first("subjectName") | text_formatter }}</span
        >
        <span v-if="nameExist" class="text-danger text-sm">
          {{ $t("subject.this_subject_already_exists") }}
        </span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col sm:w-1/4 w-full">
        <label>
          {{ $t("subject.ChooseDivision") }}
          <sup class="text-danger">*</sup>
        </label>
      </div>
      <div class="vx-col sm:w-1/2 w-full">
        <v-select
          closeOnSelect
          @input="changeDivision"
          :placeholder="`${$t('subject.AllDivisions')}`"
          class="selectExample w-full"
          v-model="Subject.divisions"
          name="Division"
          v-validate="'required'"
          :options="divisions"
          label="long_name"
          :reduce="(long_name) => long_name.id"
        />
        <span
          v-if="issueSubmitted && errors.has('Division')"
          class="text-danger text-sm"
          >{{ errors.first("Division") | text_formatter }}</span
        >
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col sm:w-1/4 w-full">
        <label>
          {{ $t("subject.ChooseGrads") }}
          <sup class="text-danger">*</sup>
        </label>
      </div>
      <div class="vx-col sm:w-1/2 w-full">
        <v-select
          closeOnSelect
          multiple
          :placeholder="`${$t('subject.AllGrades')}`"
          v-model="Subject.grades"
          class="selectExample w-full"
          name="Grade"
          v-validate="'required'"
          :options="grads"
          label="name"
          :reduce="(name) => name.id"
          :disabled="!Subject.divisions"
        />
        <span
          v-if="issueSubmitted && errors.has('Grade')"
          class="text-danger text-sm"
          >{{ errors.first("Grade") | text_formatter }}</span
        >
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col sm:w-1/4 w-full">
        <label>
          {{ $t("subject.isRequired") }}
          <sup class="text-danger">*</sup>
        </label>
      </div>
      <div class="vx-col sm:w-1/2 w-full">
        <vs-radio class="mr-8" v-model="Subject.is_optional" :vs-value="false">{{$t('basic.yes')}}</vs-radio>
        <vs-radio class="mr-8" v-model="Subject.is_optional" :vs-value="true">{{$t('basic.no')}}</vs-radio>
      </div>
    </div>

    <div class="flex flex-wrap justify-between items-center">
      <vs-button
        color="warning"
        @click="$router.push({ name: 'SubjectsList' })"
        type="border"
        >{{ $t("basic.cancel") }}</vs-button
      >

      <vs-button @click="saveSubject">{{ $t("basic.submit") }}</vs-button>
    </div>
  </vx-card>
</template>


<script>

import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const SubjectsRepository = RepositoryFactory.get('AcademicModule', 'Subjects')

export default {
  name: 'Create',
  components:{},
  data () {
    return {
      allGrads:false,
      nameExist: false,
      name: '',
      error: false,
      saveAgain: true,
      issueSubmitted: false,
      subjects: [],
      arrGrads: [],
      schools: [],
      divisions: [],
      grads: [],
      Subject: {
        subject_name: '',
        divisions: null,
        is_optional: false,
        grades: []
      }
    }
  },
  created () {
    this.getDropDownData()
    this.getSubjects()
  },
  methods: {
    saveSubject () {
      this.issueSubmitted = true
      this.$validator.validate().then((valid) => {
        if (valid) {
          if (this.saveAgain == true) {
            SubjectsRepository.create(this.Subject).then(
              (res) => {

                this.$router.push({ name: 'SubjectsList' })
                this.successMsg(this.$t('subject.new_subject_has_been_added_successfully'))

                this.saveAgain = false
              },
              (error) => {
                if (error.data.status == 'exists') {
                  this.errMsg(this.$t('subject.The_subject_name_you_are_trying_to_add_is_already_in_use_for_the_same_division'))

                } else {
                  this.errMsg(this.$t('subject.this_subject_used_in_syllbus_so_couldent_update_subject'), this.$t('basic.not_available'))

                }


              }
            )
          }
        } else {
          this.errMsg(this.$t('subject.subject_has_not_been_saved'))

        }
      })
    },
    //get all subject in school
    getSubjects () {
      SubjectsRepository.getAllSubjects().then(res => {
        this.subjects = res.data
      })
    },
    //get greades of spacific division
    getGrades (id) {
      let Grads = []
      SubjectsRepository.getGrades(id).then((res) => {
        this.grads = []
        this.allGrads = false
        this.grads = res.data
        Grads = res.data
      })
      return Grads
    },
    getDivsGrades (divisions) {
      SubjectsRepository.getDivsGrades(divisions).then((res) => {
        // this.round.grades=res.data;
        this.grads = res.data
      })
    },
    //when selected division is one get all greades related to it else
    //select all division of this grade
    changeDivision (event) {
      const divCount = this.Subject.divisions
      //this.grades=[]
      if (divCount == null) {
        this.getDropDownData()
      } else {
        this.Subject.grades = []
        this.getGrades(this.Subject.divisions)
      }
    },
    getDropDownData () {
      SubjectsRepository.getDropDownData().then((res) => {
        //  this.schools = res.schools
        this.grads = ['empty']
        this.divisions = res.divisions
      })
    }
  },
  watch: {
    allGrads (val) {
      if (val) this.Subject.grades = this.grads
      else this.Subject.grades = []
    }
  }
}
</script>

<style  scoped>
.background-none {
  background-color: transparent !important;
  color: rgb(160, 152, 152);
  cursor: pointer;
}
</style>
