export default {
  automatically_run_the_online_class: 'Automatically run the online class',
  mute_all_students_when_the_online_class_start:
    'Mute all students when the online class start',
  create_the_online_classroom_with_special_access_code:
    'create the online classroom with special access code',
  run_the_online_class_immediately: 'Run the online class immediately',
  clickCopy: 'Click to copy',
  textCopied: 'Text copied successfully!',
  teacher_online_class_link: 'Online class starting link',
  students_online_class_link: 'Join the meeting',
  run_meeting: 'Run the meeting',
  play_Record: 'Play record',
  download_Record: 'Download record',
  onlineClasses: 'online classes',
  todayClasses: 'today Classes',
  ongoingClasses: 'ongoing Classes',
  closedClasses: 'closed Classes',
  quickMeetings: 'quick meetings',
  New_online_class_has_been_added_successfully:'New online class has been added successfully',
  online_Class_has_not_been_saved_this_class_assigned_before:'online Class has not been saved , this class assigned before',
  online_Class_has_not_been_saved: 'online Class has not been saved',
  online_Class_has_not_been_saved_please_complete_your_data: 'online Class has not been saved please complete your data',
  The_time_to_must_be_a_date_after_time_from: 'The time to must be a date after time from',
  online_meeting : 'Online meeting',
  create_quick_meeting: 'Create quick meeting',
  edit_onlineClass: 'Edit an online Class',
  createZoomMeeting: 'Create Zoom Meetings',
  scheduled_meeting  :'Scheduled meeting ',
  co_meeting :'Co meeting',
  scheduled_co_meeting :'Scheduled co meeting',
  recording_meeting :'recording meeting',
  guest_policy:'Guest Policy',
  always_accept:'Always Accept',
  always_deny:'Always Deny',
  ask_moderator:'Ask Moderator',
  daily_agenda: 'Daily Agenda',
  copy_the_join_link: 'copy the join link',
  share_the_record: 'share the record',
  quickMeeting_link: 'quick meeting starting link',
  meeting_isnot_recorded:'the online class  isn\'t recorded',
  not_valid_time_to_perform_this_action:'Not valid time to perform this action',
  waiting:'waiting Room',
  run_the_meeting :'Run the meeting',
  kindly_wait_until_the_meeting_start_time: 'Kindly wait until the meeting start time',
  student_err:'Kindly wait until the teacher start the meeting',
  join_the_meeting:'join the meeting'

}
