<template>
  <div>
    <div v-if="AppNotFound">
      <h1 class="text-center pt-5">
        this Application Not Found To Evaluate It
      </h1>
    </div>
    <div v-else>
      <div class="vx-row">
        <div
          class="vx-col text-danger mb-base text-center md:w-3/3 w-full"
          v-if="errors"
        >
          <ul class="list-group-item text-center">
            <li :key="index" v-for="(error, index) in errorsArr">
              * {{ error }}
            </li>
          </ul>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full mb-base">
          <left-form
            :issueSubmitted="issueSubmitted"
            :evaluation:application.sync="evaluation.application"
            :errors="errors"
          ></left-form>
        </div>
        <!-- HORIZONTAL LAYOUT -->
        <div class="vx-col md:w-2/3 w-full mb-base">
          <right-form
            @saveEvaluation="saveEvaluation"
            :errors="errors"
            :issueSubmitted="issueSubmitted"
            :evaluation.sync="evaluation"
            :evaluation:evaluation_data.sync="evaluation.evaluation_data"
            :evaluation:criteria_ratings.sync="evaluation.criteria_ratings"
            :evaluation:evaluation_notes.sync="evaluation.evaluation_notes"
          ></right-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const EvaluationRepository = RepositoryFactory.get('AdmissionModule', 'evaluations')
import RightForm from './components/right-form'
import LeftForm from './components/left-form'

export default {
  name: 'Create',
  components: {
    RightForm,
    LeftForm
  },
  data () {
    return {
      AppNotFound:false,
      errorsArr:[],
      app_id:null,
      evaluation:{
        application: {
          status: 2,
          id: '1',
          meeting_timestamp: '',
          type: '',
          gate: '',
          building: ''
        },
        evaluation_notes: [
          {
            user_type: 'SMD',
            notes: ['']
          },
          {
            user_type: 'registerer',
            notes: ['']
          },
          {
            user_type: 'assessor',
            notes: ['']
          },
          {
            user_type: 'principal',
            notes: ['']
          },
          {
            user_type: 'director',
            notes: ['']
          }
        ]
      },
      issueSubmitted: false,
      current_locale: this.$store.getters['main/currentLocale']
    }
  },
  created () {
    this.app_id = this.$route.params.id
    this.getData()
  },
  methods: {
    addEmail () {


    },

    removeEmail (index) {
      this.round.contacts.email.splice(index + 1)
    },

    getData () {
      EvaluationRepository.getDetails(this.app_id).then(res => {
      },    (error) => {
        if (error.msg == 'Not found ') this.AppNotFound = true
      })
    },
    saveEvaluation () {
      this.evaluation.application.id = this.app_id
      this.issueSubmitted = true
      this.$validator.validate().then(valid => {
        if (valid) {
          EvaluationRepository
            .createEvaluation(this.evaluation)
            .then(res => {
              this.$router.push({name:'applications-list'})
              this.$vs.notify({
                color: 'success',
                title: 'Success',
                text: 'New evaluation has been added successfully'
              })
            },    (error) => {
              this.errorsArr = []
              this.errorsArr = error.errors
            })


        }
      })
    }
  },
  computed:{
    addedEmails () {
      return this.round.contacts.email.length > 1 ? this.round.contacts.email.slice(1, this.round.contacts.email.length) : []
    }
  }
}
</script>

<style scoped>
.background-none {
  background-color: transparent !important;
}
</style>
