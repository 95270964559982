<template>
  <ListPageWithCreateButton :showCreateBtn="false">
    <template slot="listDataContent">
      <reportCardTable
        :loading="loading"
        list_url_prop="/student-report-cards"
        :headers_prop="headers" />
    </template>
  </ListPageWithCreateButton>
</template>

<script>
import reportCardTable from '@/components/general/datatable/DataTable2.vue'
import {commonDataMixin} from '@/mixins'

export default {
  name: 'all-report-card',
  components: {
    reportCardTable
  },
  props: {},
  mixins: [commonDataMixin],
  data() {
    return {
      headers: [
        {
          minWidth: 200,
          headerName: this.$t('Syllabus.academicYear'),
          field: 'academic_year.name'
        },
        {
          minWidth: 200,
          headerName: this.$t('basic.divisions'),
          field: 'student.division.name'
        },
        {
          minWidth: 200,
          headerName: this.$t('basic.classes'),
          field: 'student.class.name'
        },
        {
          minWidth: 200,
          headerName: this.$t('form.file'),
          title: this.$t('form.file'),
          field: 'transactions',
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            actions_prop: [
              {
                title: this.$t('form.file'),
                icon: 'DownloadIcon',
                //navigate_action: true
                color: 'danger',
                showIf: (data) => data.status !== 'banned',
                actionEmitter: 'download',
                actionEmitterParams: ['media_url']
              }
            ]
          }
        }
      ],
      loading: false
    }
  },
  methods: {},
  created() {
    this.$root.$on('download', (data) => this.downloadFile(data.media_url))
  },
  beforeDestroy() {
    this.$root.$off('download')
  },
  watch: {},
  computed: {}
}
</script>
