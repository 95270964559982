export default {
  EvaluationCard: 'Evaluation Card',
  NewEvaluation: 'New Evaluation',
  OnAssessment: 'On Assessment',
  ScheduleMeeting: 'Schedule a meeting',
  actions: 'Actions',
  building: 'Choose Building',
  type: 'Choose Type',
  gate: 'Choose Gate'


}
