<template>
  <div>
    <vs-card class="relative" v-for="announcement in announcements" :key="announcement.id">

        <vs-dropdown
          class="matiralactionButtons text-capitalize cursor-pointer"
          vs-custom-content
          vs-trigger-click
        >
          <vs-button color="#999" type="flat" icon="more_vert"></vs-button>
          <vs-dropdown-menu>
            <vs-dropdown-item @click="goToShow(announcement)">

              <feather-icon
                icon="EyeIcon"
                svgClasses="text-blue h-4 w-4 mr-1"

              />{{ $t("main.show") }}</vs-dropdown-item
            >
          </vs-dropdown-menu>
        </vs-dropdown>

      <div>
        <h4 class="py-3">{{ announcement.title }}</h4>
        <h5 v-html="announcement.description" class="py-2">
          {{ announcement.description }}
        </h5>
      </div>
    </vs-card>

    <template v-if="announcements.length > 0 && totalPages > 1">
      <vs-pagination
        :total="totalPages"
        :max="countPerPage"
        v-model="currentPage"
        class="mt-6"
      />
    </template>
  </div>
</template>

<script>
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const announcementRepository = RepositoryFactory.get(
  'announcementRepository',
  'announcement'
)
export default {
  data () {
    return {
      announcements: [],
      /////////////////////////
      // Pagination
      /////////////////////////
      totalPages: 0,
      currentPage: 1,
      countPerPage: 10
    }
  },
  methods: {
    setPagination (data) {
      this.totalPages = data.total_pages || data.last_page
      this.countPerPage = data.per_page
      this.currentPage = data.current_page
    },
    listAllAnouuncement () {
      const params = {
        page: this.currentPage
      }
      announcementRepository.ListAllAnnouncement(params).then((res) => {
        this.announcements = res.data.data
        this.setPagination(res.data)
      })
    },
    goToShow (announcement) {
      if (this.checkUserRole('Parent')) {
        this.$router.push({ name: 'show-announcement-parants', params: {id: announcement.id } })

      } else {
        this.$router.push({ name: 'show-announcement-student', params: {id: announcement.id } })

      }
    }
  },
  watch: {
    currentPage () {
      this.listAllAnouuncement()
    }
  },

  mounted () {
    this.listAllAnouuncement()
  }
}
</script>
<style lang="scss" scoped >

.matiralactionButtons {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
