/*=========================================================================================
  File Name: moduleAuthMutations.js
  Description: Auth Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  setLocales(state, payload) {
    state.locales = payload
  },
  // /////////////////////////////////////////////
  // institution LocalesData
  // /////////////////////////////////////////////
  Update_institution_data(state, payload) {
    const name = payload[state.currentLocale]?.long_name,
      short_name = payload[state.currentLocale]?.short_name || null,
      logo = payload.imageURL,
      logoColor = payload.brandColor,
      favicon =
        payload.faviconURL || payload.institution_assets_link + payload.favicon,
      website = payload.website || ''

    if (name) {
      state.institutionName = name
      // document.title = name.charAt(0).toUpperCase() + name.slice(1)
    }
    if (short_name) state.institutionShortName = short_name
    if (short_name) state.institutionLongName = state.institutionName
    if (website) state.institutionLink = website
    if (logo) state.institutionLogo = logo
    if (favicon) {
      state.institutionFavicon = favicon
      document.querySelector("link[rel*='icon']").href = favicon
    }
    if (logoColor) state.themePrimaryColor = logoColor
    state.institutionDriverLink = payload.driver_link
    state.institutionAssetsLink = payload.institution_assets_link
    state.institutionApiRes = payload
  },
  Update_logoImg(state, img) {
    state.institutionLogo = img
  },
  Update_favicon(state, img) {
    state.institutionFavicon = img
  },
  ///////////////////////////////
  // Academic Year
  /////////////////////////////
  setAcademicYearData(state, data) {
    state.currentAcademicYearId = data._id
    state.currentAcademicYearData = data
  }
}
