<template>
  <div>
    <AnnouncementTable
        :headers_prop="headers"
        :list_url_prop="`/announcement/index`"
      >
    </AnnouncementTable>
  </div>
</template>

<script>
import AnnouncementTable from '@/components/general/datatable/DataTable2.vue'
export default {
  components:{
    AnnouncementTable
  },
  props:['showRoute'],
  data () {
    return {
      headers: [
        {
          minWidth: 95,
          headerName: this.$t('basic.ID'),
          field: 'id'
        },
        {
          minWidth: 150,
          headerName: this.$t('announcement.creator_by'),
          field: 'creator_by'
        },
        {
          minWidth: 250,
          headerName: this.$t('announcement.title'),
          field: 'title'
        },
        // {
        //   minWidth: 250,
        //   headerName: this.$t('announcement.launch_date'),
        //   field: 'launch_date'
        // },
        // {
        //   minWidth: 250,
        //   headerName: this.$t('announcement.launch_time'),
        //   field: 'launch_time'
        // }
        {
          minWidth: 140,
          headerName: this.$t('AcademicYears.Actions'),
          field: 'transactions',
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            actions_prop: [
              {
                title: this.$t('main.show'),
                route_name: this.showRoute,
                getParams: (data) => ({ id: data.id }),
                icon: 'Eye3Icon',
                navigate_action: true
              }
            ]
          }
        }


      ]
    }
  }

}
</script>
