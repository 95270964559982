<!-- =========================================================================================
  File Name: App.vue
  Description: Main vue file - APP
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div id="app" :class="vueAppClasses">
    <!-- <img :src="logo" /> -->

    <router-view @setAppClasses="setAppClasses" />
  </div>
</template>

<script>
import themeConfig from '@/../themeConfig.js'

export default {
  data () {
    return {
      vueAppClasses: [],
      logo:''

    }
  },

  watch: {
    '$store.state.theme' (val) {
      this.toggleClassInBody(val)
    },
    '$vs.rtl' (val) {
      document.documentElement.setAttribute('dir', val ? 'rtl' : 'ltr')
    }

  },
  methods: {

    toggleClassInBody (className) {
      if (className === 'dark') {
        if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark')
        document.body.classList.add('theme-dark')
      } else if (className === 'semi-dark') {
        if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark')
        document.body.classList.add('theme-semi-dark')
      } else {
        if (document.body.className.match('theme-dark'))      document.body.classList.remove('theme-dark')
        if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark')
      }
    },
    setAppClasses (classesStr) {
      this.vueAppClasses.push(classesStr)
    },
    handleWindowResize () {
      this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)

      // Set --vh property
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
    },
    handleScroll () {
      this.$store.commit('UPDATE_WINDOW_SCROLL_Y', window.scrollY)
    },
    showLoader (data) {
      this.VSLoading(data || null)
    },
    hideLoader (data) {
      // console.log('hideLoader', data)
      this.stopVSLoading(data || null)
    },
    /////////////////////////////////////////////
    //  Fix Vuesax scollHeight console error
    ///////////////////////////////////////////
    changeHeight () {
      const maxHeightx = this.$refs.content?.scrollHeight

      if (this.maxHeight != '0px') {
        this.maxHeight = ''.concat(maxHeightx, 'px')
      }
    },
    initMaxHeight () {
      const maxHeightx = this.$refs.content?.scrollHeight

      if (this.maxHeight == '0px') {
        this.maxHeight = ''.concat(maxHeightx, 'px')
      } else {
        this.maxHeight = '0px'
      }
    },
    mouseover () {
      if (this.disabled) {
        return
      }

      const maxHeightx = this.$refs.content?.scrollHeight

      if (this.openHover && maxHeightx) {
        this.maxHeight = ''.concat(maxHeightx, 'px')
      }
    },
    /////////////////////////////////////////////
    //  Fix Vuesax vsCustomContent console error
    ///////////////////////////////////////////
    clickx (evt) {
      let _this$$children$filte = this.$children.filter(function (item) {
          return item.hasOwnProperty('dropdownVisible')
        }),
        _this$$children$filte2 = _slicedToArray(_this$$children$filte, 1),
        dropdownMenu = _this$$children$filte2[0]

      if (dropdownMenu) {
        dropdownMenu.vsCustomContent = this.vsCustomContent
        dropdownMenu.vsTriggerClick = this.vsTriggerClick
        dropdownMenu.vsDropRight = this.vsDropRight
      }

      if ((this.vsTriggerClick || this.vsCustomContent) && this.vsDropdownVisible) {
        if (evt.target !== this.$refs.dropdown && evt.target.parentNode !== this.$refs.dropdown && evt.target.parentNode.parentNode !== this.$refs.dropdown) {
          if (!evt.target.closest('.vs-dropdown--menu') && dropdownMenu) {
            dropdownMenu.dropdownVisible = this.vsDropdownVisible = false
            document.removeEventListener('click', this.clickx)
          }
        }
      }
    }
  },
  mounted () {
    this.toggleClassInBody(themeConfig.theme)
    this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)

    const vh = window.innerHeight * 0.01
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`)

    window.addEventListener('resize', this.changeHeight)
    const maxHeightx = this.$refs.content?.scrollHeight

    if (this.open && maxHeightx) {
      this.maxHeight = ''.concat(maxHeightx, 'px')
    }

    // this.$acl.onChange = (newPermission) => {
    //   alert(`acl on change newPermission ${newPermission}`)
    //   this.refreshLayoutComponents()
    // }
  },
  async created () {
    window.$sharedEvent.listen('show-loader', this.showLoader)
    window.$sharedEvent.listen('hide-loader', this.hideLoader)


    const dir = this.$vs.rtl ? 'rtl' : 'ltr'
    document.documentElement.setAttribute('dir', dir)

    window.addEventListener('resize', this.handleWindowResize)
    window.addEventListener('scroll', this.handleScroll)

    window.addEventListener('storage', function (event) {
      if (event.key == 'logout-login-event') {
        window.location.reload()
      }
    })
    // Auth0
    // try       { await this.$auth.renewTokens() } catch (e) { console.error(e) }
    let currentPath = new URL(document.URL).pathname;
    let moduleName = currentPath.split("/")[1];
    moduleName = moduleName.replace("-", " ");
    moduleName = moduleName.charAt(0).toUpperCase() + moduleName.slice(1)
    document.title = "Dashboard | " + moduleName;
  },
  destroyed () {
    window.removeEventListener('resize', this.handleWindowResize)
    window.removeEventListener('scroll', this.handleScroll)
  },
}

</script>

<style>
[class*='loading'] img{
  display: none;
}
</style>

