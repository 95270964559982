<template>
  <item-form @formSubmited = "editItem" :ifEdit="true"/>
</template>

<script>
import itemForm from '@/modules/canteen/pages/item/components/itemForm'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const canteenRepository = RepositoryFactory.get(
  'canteenRepository',
  'canteenRepository'
)
export default {
  name: 'create',
  components:{
    itemForm
  },
  data () {
    return {
    }
  },
  methods: {
    editItem (payload) {
      this.VSLoading()
      canteenRepository.editItem(this.$route.params.id, payload).then(() => {
        this.successMsg(this.$t('canteen.editItemSuccessfully'))
        this.$router.go(-1)
        this.stopVSLoading()
      }).catch((err) => {
        this.errMsg(err)
      })
    }
    /*editCategory (name, canteenId, categoryId) {
      this.VSLoading()
      canteenRepository.editCategory(canteenId, categoryId, name).then(() => {
        this.successMsg(this.$t('canteen.editCategorySuccessfully'))
        this.$router.go(-1)
        this.stopVSLoading()
      }).catch((err) => {
        this.errMsg(err)
      })
    }*/
  }
}
</script>

<style scoped>

</style>
