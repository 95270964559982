<template>
  <div>
    <vx-card class="p-3 pt-6 pb-6 md:p-6">
      <form @submit.prevent="formSubmitted">
        <!-- Divisions -->
        <div class="lg:w-2/3 mb-5 w-full">
          <label class="inline-block mb-1">{{ $t("form.divisions") }}</label>
          <sup class="text-danger">*</sup>
          <v-select
            label="long_name"
            :reduce="(name) => name.id"
            :options="divisions"
            v-model="division_id"
            class="w-full select-large"
            name="Divisions"
            v-validate="'required'"
            multiple
            @input="getGradesByDivision"
          />
          <span class="text-danger text-sm w-full">{{
            errors.first("Divisions")
          }}</span>
        </div>

        <!-- grades -->
        <div class="lg:w-2/3 mb-5 w-full">
          <label class="inline-block mb-1">{{ $t("form.grades") }}</label>
          <sup class="text-danger">*</sup>
          <v-select
            :disabled="division_id.length <= 0"
            label="name"
            :reduce="(name) => name.id"
            :options="grades"
            v-model="grades_id"
            class="w-full select-large"
            name="Grades"
            v-validate="'required'"
            multiple
            @input="getSubjectsGrades"
          />

          <span class="text-danger text-sm w-full">{{
            errors.first("Grades")
          }}</span>
        </div>

        <!-- subjects Greads -->
        <div class="lg:w-2/3 mb-5 w-full">
          <label class="inline-block mb-1">{{ $t("form.subjects") }}</label>
          <sup class="text-danger">*</sup>
          <v-select
            :disabled="division_id.length <= 0 || grades_id.length <= 0"
            label="grade_subject"
            :reduce="(name) => name.id"
            :options="grade_subjects"
            v-model="hodForm.grade_subject_id"
            class="w-full select-large"
            name="subjects"
            v-validate="'required'"
            multiple
          />
          <span class="text-danger text-sm w-full">{{
            errors.first("subjects")
          }}</span>
        </div>

        <!-- Start Is hod Teacher -->
        <div class="vx-row mt-5 items-center">
          <!-- Start label hod Teacher-->
          <div class="vx-col lg:w-1/5 w-full">
            <label>
              {{ $t("teachersGateway.is_HOD_teacher") }}
              <sup class="text-danger">*</sup>
            </label>
          </div>
          <!-- End label hod Teacher -->
          <div class="w-full lg:w-2/5 text-center">
            <ul class="vx-row mt-2">
              <li class="vx-col w-1/2 lg:w-1/3">
                <vs-radio
                  v-model="hodForm.is_HOD_teacher"
                  :vs-value="1"
                  name="is_HOD_teacher"
                  >{{ $t("teachersGateway.yes") }}</vs-radio
                >
              </li>
              <li class="vx-col w-1/2 lg:w-1/3">
                <vs-radio
                  v-model="hodForm.is_HOD_teacher"
                  :vs-value="0"
                  name="is_HOD_teacher"
                  >{{ $t("teachersGateway.no") }}</vs-radio
                >
              </li>
            </ul>
          </div>
        </div>
        <!-- End Is hod Teacher -->

        <!-- Actions -->
        <div class="flex flex-wrap justify-between items-center mt-10">
          <vs-button
            color="warning"
            type="border"
            class="inline-block"
            @click="back()"
            >{{ $t("form.cancel") }}</vs-button
          >
          <vs-button class="inline-block" @click="formSubmitted()">
            {{ $t("form.submit") }}
          </vs-button>
        </div>
      </form>
    </vx-card>
  </div>
</template>

<script>
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const addTeacherRepo = RepositoryFactory.get(
  'teachersGatewayModule',
  'addTeacher'
)
import { commonDataMixin } from '@/mixins.js'
export default {
  mixins: [commonDataMixin],
  props: {
    teacher_id: {
      type: [String, Number],
      default: () => {
        return ''
      }
    }
  },
  data () {
    return {
      hodForm: {
        grade_subject_id: [],
        is_HOD_teacher :''
      },
      grade_subjects: [],
      division_id: [],
      grades: [],
      divisions: [],
      grades_id: []
    }
  },
  methods: {
    createHod () {
      addTeacherRepo
        .createHod(this.teacher_id, this.hodForm)
        .then((res) => {
          this.$router.push('/teachers-gateway')
          this.successMsg(res.status)
        })
        .catch((err) => {
          this.errMsg(err.data.message)
        })
    },
    getGradesByDivision () {
      this.grades_id = ''
      this.hodForm.grade_subject_id = ''
      const payload = {
        division_id: this.division_id
      }
      addTeacherRepo
        .getGradesByDivision(payload)
        .then((res) => {
          this.grades = res.data
        })
        .catch((err) => {
          this.errMsg(err.data.message)
        })
    },
    getSubjectsGrades () {
      this.hodForm.grade_subject_id = ''
      const payload = {
        grades: this.grades_id
      }
      addTeacherRepo
        .getSubjectsGrades(payload)
        .then((res) => {
          this.grade_subjects = res.data
        })
        .catch((err) => {
          this.errMsg(err.data.message)
        })
    },
    formSubmitted () {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.createHod()
        } else {
          this.errMsg(this.$t(
            'teachersGateway.teacher_has_not_been_saved_please_complete_your_data'
          ))
        }
      })
    },
    back () {
      this.$router.back()
    }
  },
  mounted () {
    this.getDivisionsWithoutBranchId() //from mixins
  }
}
</script>
