<template>
  <div>
    <div class="flex flex-wrap justify-between" v-if="Object.values(exam).length">
      <div>
        <div>
          <h3 class="font-bold"> {{exam.name}} <span class="font-normal">- {{exam.subject.name}}</span>
          </h3>
        </div>
        <div class="flex flex-wrap mt-4">
          <span class="flex items-center md:mb-0 mb-2 mr-4">
            <feather-icon icon="UserIcon" svgClasses="h-5 w-5 text-warning stroke-current" />
            <span class="ml-2">{{ $t("basic.by") }} : {{exam.teacher.name}}</span>
          </span>
          <span class="flex items-center md:mb-0 mb-2 mr-4">
            <feather-icon icon="CalendarIcon" svgClasses="h-5 w-5 text-primary stroke-current" />
            <span class="ml-2">{{
              new Date(exam.date).toDateString()
            }}</span>
          </span>
          <vs-chip class="mr-4" transparent color="success"> {{exam.submittedStudentsCount}} {{ $t("basic.answered") }} </vs-chip>
          <vs-chip transparent color="danger" class="mr-4"> {{exam.students.length - exam.submittedStudentsCount}} {{ $t("basic.not_answered") }} </vs-chip>
        </div>
      </div>
      <vs-button class="mb-base mr-0 ml-auto" @click="changeHideIdentitiesStatus" v-if="hideIdentities">{{
        $t("exam.reveal_identities")
      }}</vs-button>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-5">
          <transition-group class="list-transition" name="list-enter-up" tag="ul">
            <li class="mb-16" v-for="(val, i) in students" :key="val.id"
              :style="[{ transitionDelay: i * 0.1 + 's' }]">
              <assess-card :student="val" :exam="exam" :showIdentity="!hideIdentities"></assess-card>
            </li>
          </transition-group>
        </div>
      </div>
    </div>
    <grade-exam @FetchData="getStudents" />
  </div>
</template>
<script>
import AssessCard from './components/assessCard'
import gradeExam from './components/grade-exam/grade-exam.vue'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'

const ExamRepo = RepositoryFactory.get('ExamModule', 'Exam')
export default {
  name: 'assess-exams',
  components: {
    AssessCard,
    gradeExam
  },
  data () {
    return {
      examId: this.$route.params.id,
      students: [],
      exam:{},
      checkExamStatus:{},
      showIdentity: true
    }
  },
  created () {
    this.getStudents()
    this.getExamData()
  },
  computed:{
    hideIdentities () {
      return this.exam.hide_students_identities 
    }
  },
  watch:{
    'checkExamStatus.hasAllSubmittedStudentsAssessed' (val) {
      if (val) this.exam.hide_students_identities = false
    }
  },
  methods: {
    changeHideIdentitiesStatus () {
      if (this.checkExamStatus.hasAllSubmittedStudentsGraded) {
        this.exam.hide_students_identities = false
      } else {
        this.$vs.notify({
          title: 'Validation',
          text: 'Please Grade first All submitted students',
          color: 'danger',
          position: 'bottom-right'
        })
      }
    },
    getStudents () {
      ExamRepo.getStudents(this.examId).then((res) => {
        this.students = res.students_data
        this.checkExamStatus = res.exam_data
      }).catch((err) => {
        console.log('err', err)
      })
    },
    getExamData () {
      ExamRepo.getExamData(this.examId).then((res) => {
        this.exam = res.data
      }).catch((err) => {
        console.log('err', err)
      })
    }  
  }
}

</script>
<style lang="scss">
  .examFilterIcon {
    font-size: 30px;
    cursor: pointer;
  }

</style>
