/*=========================================================================================
  File Name: globalComponents.js
  Description: Here you can register components globally
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue'
import VxCard from './components/vx-card/VxCard.vue'
// import VxList  from './components/vx-list/VxList.vue'
import VxBreadcrumb from './layouts/components/VxBreadcrumb.vue'
import FeatherIcon from './components/FeatherIcon.vue'
import VxInputGroup from './components/vx-input-group/VxInputGroup.vue'
import GenericMenu from './components/generic-menu/generic-menu.vue'
import GenericMenuInfo from './components/generic-menu-info/generic-menu-info.vue'
import SideMenusContainer from './components/side-menus-container/side-menus-container.vue'
import StatusCounter from '@/components/general/StatusCounter.vue'
import GridWrapper from '@/components/general/grid-wrapper/components/container/grid-wrapper.vue'
import FilePreviewer from '@/components/general/file-previewer/components/previewer/previewer.vue'
import singleFilePreviewer from '@/components/general/file-previewer/components/singleFilePreviewer/singleFilePreviewer.vue'
import VSelect from '@/components/VSelect.vue'
import VxTooltip from '@/layouts/components/vx-tooltip/VxTooltip.vue'
import VsPagination from '@/components/Pagination.vue'
import UnderDevelopment from '@/components/underDevelopment.vue'
import GridCard from '@/components/GridCard.vue'
import GridCard4 from '@/components/GridCardLayout4.vue'
import ListPageWithCreateButton from '@/components/listPageGridSystem/withCreateButton'
import ListPageWithCreateButtonSeparateRow from '@/components/listPageGridSystem/withCreateButtonSeparateRow'

//
Vue.component(VxCard.name, VxCard)
Vue.component(GridCard4.name, GridCard4)
// Vue.component(VxList.name, VxList)
Vue.component(VxBreadcrumb.name, VxBreadcrumb)
Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component(VxInputGroup.name, VxInputGroup)
Vue.component(GenericMenu.name, GenericMenu)
Vue.component(GenericMenuInfo.name, GenericMenuInfo)
Vue.component(SideMenusContainer.name, SideMenusContainer)
Vue.component(StatusCounter.name, StatusCounter)
Vue.component(GridWrapper.name, GridWrapper)
Vue.component(FilePreviewer.name, FilePreviewer)
Vue.component(singleFilePreviewer.name, singleFilePreviewer)
Vue.component(VSelect.name, VSelect)
Vue.component(VxTooltip.name, VxTooltip)
Vue.component(VsPagination.name, VsPagination)
Vue.component(UnderDevelopment.name, UnderDevelopment)
Vue.component(GridCard.name, GridCard)
Vue.component(ListPageWithCreateButton.name, ListPageWithCreateButton)
Vue.component(
  ListPageWithCreateButtonSeparateRow.name,
  ListPageWithCreateButtonSeparateRow
)

//
//
// // v-select component
import vSelect from 'vue-select'
//
// // Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  Deselect: {
    render: (createElement) =>
      createElement('feather-icon', {
        props: {
          icon: 'XIcon',
          svgClasses: 'w-4 h-4 mt-1'
        }
      })
  },
  OpenIndicator: {
    render: (createElement) =>
      createElement('feather-icon', {
        props: {
          icon: 'ChevronDownIcon',
          svgClasses: 'w-5 h-5'
        }
      })
  }
})

Vue.component(vSelect)
