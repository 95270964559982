import axios from 'axios'
const resource = '/materials'

export default {
  getTypes () {
    return axios.get(`${resource}/types`)
  },
  getAllSubjects () {
    return  axios.get('/getAllSubjects')
  },
  addMaterial (payload) {
    return  axios.post(`${resource}/save`, payload)
  },
  getTeachingMaterialList (filters) {
    return axios.get(`${resource}`, {
      params: {
        ...filters
      }
    })
  },
  teachersOfSection () {
    return  axios.get('/teachers/teachers-of-section')
  },
  classesOfTeacher () {
    return  axios.get('/classes-of-teacher')
  },
  count () {
    return  axios.get(`${resource}/counter`)
  },
  async view (id) {
    return await axios.get(`${resource}/${id}/view`)
  },

  update (id, payload) {
    return axios.post(`${resource}/${id}/update`, payload)
  },
  status (id, status) {
    return axios.post(`${resource}/${id}/${status}`)
  },
  addShare (id, payload) {
    return axios.post(`${resource}/${id}/share`, payload)
  },
  viewShare (id) {
    return axios.get(`${resource}/${id}/share`)
  }

}
