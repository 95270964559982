
<template>
  <div class="vx-card p-6 pt-6 pb-6 md:pl-16 md:pr-16">
    <div class="vx-row mb-6">
      <div class="vx-col ml-auto mr-0 md:w-1/6 w-full">
        <vs-chip
          :class="`status--${syllabus.online !== 0 ? 'live' : 'not_live'}`"
          style="float: right"
        >
          <span>{{ syllabus.online !== 0 ? "Live" : "Not Live" }}</span>
        </vs-chip>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col md:w-1/4 w-full">
        <strong>
          <span>{{ $t("Syllabus.syllabusCode") }}</span>
        </strong>
      </div>
      <div class="vx-col md:w-3/4 w-full">
        <div>{{ syllabus.code }}</div>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col md:w-1/4 sm:w-full w-full">
        <strong>
          <span>{{ $t("Syllabus.name") }}</span>
        </strong>
      </div>
      <div class="vx-col md:w-3/4 sm:w-full w-full">
        <div>{{ syllabus.divisionName }} / {{ syllabus.gradeName }}</div>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col md:w-1/4 sm:w-full w-full">
        <strong>
          <span>{{ $t("Syllabus.division") }}</span>
        </strong>
      </div>
      <div class="vx-col md:w-3/4 sm:w-full w-full">
        <div>{{ syllabus.divisionName }}</div>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col md:w-1/4 sm:w-full w-full">
        <strong>
          <span>{{ $t("Syllabus.grade") }}</span>
        </strong>
      </div>
      <div class="vx-col md:w-3/4 sm:w-full w-full">
        <div>{{ syllabus.gradeName }}</div>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col md:w-1/4 sm:w-full w-full">
        <strong>
          <span>{{ $t("Syllabus.usedIn") }}</span>
        </strong>
      </div>
      <div class="vx-col md:w-3/4 sm:w-full w-full">
        <span v-if="syllabus.online !== 0" class="text-danger">{{
          $t("syllabus.not_used_in_any_classes")
        }}</span>
        <span v-if="syllabus.online === 0"
          >{{ 7 }} {{ $t("basic.classes") }}</span
        >
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col md:w-1/4 sm:w-full w-full mb-2">
        <strong>
          <span>{{ $t("Syllabus.status") }}</span>
        </strong>
      </div>
      <div class="vx-col md:w-3/4 sm:w-full w-full">
        <vs-chip :class="`status--${syllabus.status.toLowerCase()}`">
          <span>{{ syllabus.status }}</span>
        </vs-chip>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col md:w-1/4 w-full">
        <strong>
          <span>{{ $t("Syllabus.subjects") }}</span>
        </strong>
      </div>

      <div v-if="syllabus.Subjects.length === 0" class="vx-col md:w-3/4 w-full">
        <span class="text-danger">{{
          $t("subject.there_are_no_subjects")
        }}</span>
      </div>
      <div v-if="syllabus.Subjects.length !== 0" class="vx-col md:w-3/4 w-full">
        <span v-for="(subject, index) in syllabus.Subjects" :key="subject.id">{{
          `${subject.name}${
            index === syllabus.Subjects.length - 1 ? "" : " - "
          }`
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col md:w-1/4 w-full">
        <strong>
          <span>{{ $t("Syllabus.terms") }}</span>
        </strong>
      </div>

      <div v-if="syllabus.terms.length === 0" class="vx-col md:w-3/4 w-full">
        <span class="text-danger">{{
            $t("subject.there_are_no_terms")
          }}</span>
      </div>
      <div v-if="syllabus.term_id.length !== 0" class="vx-col md:w-3/4 w-full">
        <span v-for="(terms, index) in syllabus.terms" :key="terms.id">{{
            `${terms.name}${
              index === syllabus.terms.length - 1 ? "" : " - "
            }`
          }}</span>
      </div>
    </div>
    <div class="vx-row" v-if="this.checkUserRole('SND')">
      <div class="vx-col ml-auto mr-0 md:w-1/4 w-full">
        <vs-button
          class="ml-auto mr-0"
          :to="{ name: 'edit-syllabus', params: { id: this.id } }"
          icon-pack="feather"
          color="primary"
          type="filled"
          icon="icon-edit"
          >{{ $t("Syllabus.edit_syllabus") }}</vs-button
        >
      </div>
    </div>
  </div>
</template>


<script>
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const SyllabusRepository = RepositoryFactory.get('AcademicModule', 'syllabus')

export default {
  name: 'Show',
  data () {
    return {
      id: '',

      syllabus: {}
    }
  },
  created () {
    this.id = this.$route.params.id
    this.getSyllabusData(this.id)
  },
  methods: {
    getSyllabusData (id) {
      SyllabusRepository.get(id).then((res) => {
        this.syllabus = res.data
      })
    }
  }
}
</script>

<style  scoped>
.background-none {
  background-color: transparent !important;
  color: rgb(160, 152, 152);
  cursor: pointer;
}
</style>
