
import axios from 'axios'

export default {
  // ---------- Task Actions
  getCordinatoorAgenda (currentPage) {
    return axios.get(`agenda/index?page=${currentPage}`)
  },
  getClasses () {
    return axios.get('agenda/classes-of-section')
  },
  getParentAgenda (currentPage, student_id) {
    return axios.get(`student/agenda/index/${student_id}?page=${currentPage}`)
  },
  getstudentAgenda () {
    return axios.get('student/agenda/index')
  },
  createAgenda (data) {
    return axios.post('agenda/create', data)
  },
  deleteAgenda (data) {
    return axios.post('agenda/delete', data)
  },
  downloadTeacher (id) {
    return axios.get(`agenda/download?id=${id}`, {
      responseType: 'arraybuffer'
    })
  },
  downloadStudent (id) {
    return axios.get(`student/agenda/download?id=${id}`, {
      responseType: 'arraybuffer'
    })
  }
}
