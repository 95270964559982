<template>
  <ListPageWithCreateButton createRoutName="UsersRoleCreate" :createBtnName="$t('config.add_user')">

    <template slot="listDataContent">
      <data-table
      :csv_url="'user/export'"
      :pdf_url="'system-users/pdf'"
      :total="1"
      :list_items="searchResult"
      :headers_prop="headers"
      :general_search_prop="searchContent"
      :side_filters="sideFilters"
      list_url_prop="user/roles" />
    </template>

    <template slot="rightSide">
      <users-filter
        @refilter="setFilters"
        @resetAll="restSideFilters"
        :filters_prop="custom_filter"
        :loading="loadingFilter">
        <template slot="extra_content">
          <div class="w-full mb-10">
            <vs-input
            :label-placeholder="$t('form.search_by_name_email')"
            v-model="searchContent"
            v-validate="{ email: searchContent.includes('@') }"
            class="w-full"
            name="email"
            icon="icon icon-search"
            icon-pack="feather"
            @change="setFilters" />
            <span class="text-sm text-danger" v-if="errors.first('email')">{{
              errors.first('email') | text_formatter
            }}</span>
          </div>
        </template>
      </users-filter>
    </template>
  </ListPageWithCreateButton>
</template>
<script>
import DataTable from '@/components/general/datatable/DataTable2'
import UsersFilter from '@/components/general/RoundFilter'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const UserManagementRepository = RepositoryFactory.get(
  'ConfigurationModule',
  'userManagement',
)
export default {
  name: 'UsersList',
  components: {
    DataTable,
    UsersFilter
  },
  data() {
    return {
      searchResult: null,
      resetFiltesPayload: {
        status: [],
        role: [],
        branch: [],
        general_search: "",
      },
      headers: [
        {
          minWidth: 160,
          headerName: this.$t('basic.ID'),

          field: 'id',
          width: 150,
          headerCheckboxSelectionFilteredOnly: true
        },
        // {
        //   minWidth: 160,
        //   headerName: this.$t('config.school'),
        //   field: 'school',
        //   width: 200
        // },
        // {
        //   minWidth: 160,
        //   headerName: this.$t('config.divisions'),
        //   field: 'divisions',
        //   width: 200
        // },
        // {
        //   minWidth: 160,
        //   headerName: this.$t('config.sections'),
        //   field: 'sections',
        //   width: 200
        // },
        {
          minWidth: 160,
          headerName: this.$t('config.user'),
          field: 'name',
          width: 200
        },
        {
          minWidth: 160,
          headerName: this.$t('config.email'),
          field: 'email',
          width: 200
        },
        {
          minWidth: 160,
          headerName: this.$t('config.role'),
          field: 'role_name',
          width: 200
        },
        {
          minWidth: 160,
          headerName: this.$t('config.status'),
          field: 'status',
          width: 290,
          cellRendererFramework: 'CellRendererStatus',
          cellRendererParams: {
            status_prop: this.status
          }
        },
        {
          minWidth: 160,
          filter: false,
          headerName: this.$t('config.actions'),
          field: 'actions',
          width: 240,
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            actions_prop: [
              {
                title: this.$t('basic.edit'),
                dropDown: false,
                parameter_name: 'id',
                icon: 'Edit3Icon',
                navigate_action: true,
                route_name: 'UserEdit'
              },
              {
                title: this.$t('status.active'),
                parameter_name: 'id',
                icon: 'CheckIcon',
                action: 'active',
                resource: 'user/:id/role/status',
                showIf: (data) => data.status !== 'active'
              },
              {
                title: this.$t('status.suspend'),
                parameter_name: 'id',
                icon: 'SlashIcon',
                action: 'suspended',
                resource: 'user/:id/role/status',
                color: 'warning',
                showIf: (data) =>
                  data.status !== 'suspended' && data.role_name !== 'SMD'
              }
            ]
          }
        }
      ],
      users: [],
      filter_choosen: [],
      rounds: [
        {
          id: 1,
          name: 'Users',
          start: '5 March 2020',
          end: '5 March 2020'
        },
        {
          id: 2,
          name: 'Users',
          start: '5 March 2020',
          end: '5 March 2020'
        }
      ],
      custom_filter: [
        {
          id: 1,
          name: this.$t('basic.status'),
          by: [
            {
              name: "active",
              id: 'active',
              checked: false
            },
            {
              name: "suspended",
              id: 'suspended',
              checked: false
            },
          ]
        }
      ],
      sideFilters: {
        status: [],
        general_search: '',
      },
      searchContent: '',
      loading: false,
      loadingFilter: false
    }
  },

  computed: {
    filters() {
      const custom_filter = [
        {
          id: 4,
          name: this.$t('basic.status'),
          by: []
        },
      ]

      return custom_filter
    },

  },
  created() {
    this.getAllUsers()
  },
  methods: {
    getAllUsers() {
      UserManagementRepository.getAllUserNotHaverRoles('', this.sideFilters).then((res) => {
        this.users = res.users
      })
    },
    restSideFilters() {
      this.sideFilters = this.resetFiltesPayload
      this.side_filter[0].by = [
        {
          name: "active",
          id: 'active',
          checked: false
        },
        {
          name: "suspended",
          id: 'suspended',
          checked: false
        },
      ]
      this.side_filter[1].by = []
      this.side_filter[2].by = []
      this.side_filter[3].by = []
      this.getFilteredStudents()
    },
     setFilters(val) {
      console.log("done button clicked");
      // to enable watcher when typing on the search input
      this.sideFilters["general_search"] = this.searchContent;

      if (Array.isArray(val)) {
        val.map((item) => {
          this.sideFilters[item.name] = item.checked
        });
      }
    },
  },
  watch: {
    searchContent(v) {
      if (!v) this.searchResult = null
    }
  },
  created(){
    this.getFiltersData();
  }
}
</script>
