import usersLogstList from './pages/list.vue'
import showUserLogs from './pages/show.vue'


export default [
  // list
  {
    path: 'users-logs-List',
    name: 'usersLogstList',
    i18n: 'usersLogs.users_logs',
    component: usersLogstList,
    meta: {
      breadcrumb: [
        {
          title: 'basic.dashboard',
          url: 'landing/home'
        },
        {
          title: 'basic.list',
          active: true
        }
      ],
      pageTitle: 'usersLogs.users_logs',
      rule: 'TeacherSND'
    }
  },
  {
    path: 'users-logs-List/show/:id',
    name: 'showUserLogs',
    i18n: 'main.show',
    component: showUserLogs,
    meta: {
      breadcrumb: [
        {
          title: 'basic.dashboard',
          url: 'landing/home'
        },
        {
          title: 'basic.list',
          url: '/system-configurations/users-logs-List'
        },
        {
          title: 'main.show',
          active: true
        }
      ],
      pageTitle: 'main.show',
      rule: 'TeacherSND'
    }
  }

]
