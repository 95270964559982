<template>
  <div id="data-list-list-view" class="data-list-container">
    <!-- Show selected data -->

    <div
      slot="selected"
      class="mb-base flex flex-wrap"
      v-if="show_selected_data && selected.length"
    >
      <vs-chip
        @click="remove_student(selected)"
        v-for="(selected, i) in selected"
        closable
        :key="i"
        class="text-capitalize"
        >{{ selected.name }}</vs-chip
      >
    </div>
    <!-- Table -->
    <vs-table
      v-model="selected"
      :data="users"
      :multiple="multi_select_props"
      :pagination="users.length > 10 ? true : false"
      :max-items="10"
      :search="users.length > 10 ? true : false"
      stripe
      :hoverFlat="true"
      @select="handleSelected"
      @selectCheck="selectAll"
      class="p-0 mt-5"
    >
      <template slot="header">
        <div class="mb-5" :class="`${users.length < 10 ? 'ml-auto mr-0' : ''}`">
          <vs-button
            type="line"
            color="danger"
            @click="resetAll"
            v-if="selected.length > 0"
            >{{ $t("form.reset_all") }}</vs-button
          >
          <vs-button type="line" @click="selectAll">{{
            $t("form.select_all")
          }}</vs-button>
        </div>
      </template>
      <template slot="thead">
        <vs-th v-for="(val, i) in table_head" :key="i" sort-key="val.value">{{
          val.title
        }}</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td
            v-for="(val, i) in table_head"
            :key="i"
            :data="data[indextr][val.value]"
            >{{ data[indextr][val.value] }}</vs-td
          >
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>
<script>
export default {

  name:'datatable',
  props:{
    table_head_prop:{
      type:Array,
      required:true
    },
    users_prop:{
      type:Array,
      required:true
    },
    show_selected_data:{
      type:Boolean
    },
    multi_select_props:{
      type:Boolean
    },
    set_attendees: {
      type: Array,
      required: false
    }
  },
  data () {
    return {
      selected:[],
      table_head:null,
      users:null

    }
  },
  created () {
    this.table_head = this.table_head_prop
    this.users = this.users_prop
  },
  watch: {
    selected () {
      this.$emit('get_selected', this.selected)
    },
    users_prop (val) {
      this.users = val
    },
    set_attendees (val) {
      this.selected = val
    }

  },
  methods:{
    // Remove student
    remove_student (item) {
      this.selected.splice(this.selected.indexOf(item), 1)
    },
    // selectAll
    selectAll () {
      this.selected = Array.from(this.users)
    },
    resetAll () {
      this.selected = []
    },
    handleSelected () {
      // this.$emit('get_selected', this.selected)
    }

  }


}
</script>
<style lang="scss" >
table.vs-table {
  th.td-check {
    .con-td-check {
      visibility: hidden;
    }
  }
}
/* // #data-list-list-view {
//   .vs-con-table {
//     .vs-table--header {
//       display: flex;
//       flex-wrap: wrap;
//       margin-left: 1.5rem;
//       margin-right: 1.5rem;
//       > span {
//         display: flex;
//         flex-grow: 1;
//       }

//       .vs-table--search {
//         padding-top: 0;

//         .vs-table--search-input {
//           padding: 0.9rem 2.5rem;
//           font-size: 1rem;

//           & + i {
//             left: 1rem;
//           }

//           &:focus + i {
//             left: 1rem;
//           }
//         }
//       }
//     }

//     .vs-table {
//       border-collapse: separate;
//       border-spacing: 0 1.3rem;
//       padding: 0 1rem;

//       tr {
//         box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
//         td {
//           padding: 20px;
//           &:first-child {
//             border-top-left-radius: 0.5rem;
//             border-bottom-left-radius: 0.5rem;
//           }
//           &:last-child {
//             border-top-right-radius: 0.5rem;
//             border-bottom-right-radius: 0.5rem;
//           }
//         }
//         td.td-check {
//           padding: 20px !important;
//         }
//       }
//     }

//     .vs-table--thead {
//       th {
//         padding-top: 0;
//         padding-bottom: 0;

//         .vs-table-text {
//           text-transform: uppercase;
//           font-weight: 600;
//         }
//       }
//       th.td-check {
//         padding: 0 15px !important;
//       }
//       tr {
//         background: none;
//         box-shadow: none;
//       }
//     }

//     .vs-table--pagination {
//       justify-content: center;
//     }
//   }

//   .vs-table {
//     border-collapse: separate;
//     border-spacing: 0 1.3rem;
//     padding: 0 1rem;

//     tr {
//       box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
//       td {
//         padding: 20px;
//         &:first-child {
//           border-top-left-radius: 0.5rem;
//           border-bottom-left-radius: 0.5rem;
//         }
//         &:last-child {
//           border-top-right-radius: 0.5rem;
//           border-bottom-right-radius: 0.5rem;
//         }
//       }
//       td.td-check {
//         padding: 20px !important;
//       }
//     }
//   }

//   .vs-table--thead {
//     th {
//       padding-top: 0;
//       padding-bottom: 0;

//       .vs-table-text {
//         text-transform: uppercase;
//         font-weight: 600;
//       }
//     }
//     th.td-check {
//       padding: 0 15px !important;
//     }
//     tr {
//       background: none;
//       box-shadow: none;
//     }
//   }

//   .vs-table--pagination {
//     justify-content: center;
//   }
// } */
</style>

