<template>
  <div>
    <teaching-materials-form :material_formProp="material_form"></teaching-materials-form>
  </div>
</template>
<script>
import TeachingMaterialsForm from './components/teaching-materials-form'

export default {
  components:{
    TeachingMaterialsForm

  },
  created () {

  },
  data () {
    return {
      material_form: {
        name: '',
        subject_id: '',
        types: [],
        files: []
      }

    }
  }
}
</script>
