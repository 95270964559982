import vSelect from 'vue-select'
import ModuleMixins from '../../mixins'
import {mapGetters} from 'vuex'
import Service from '../../services/poi'


export default {
  name: 'createYearGroup',
  mixins: [ModuleMixins],
  components: {
    'v-select': vSelect
  },
  data () {
    return {
      restService: Service,
      modalName: 'createYearGroup',
      modalProps: {
        width: '600px',
        height: 'auto',
        minHeight:350,
        scrollable: true
      },
      groupData: {
        title: '',
        selectedGroup: null,
        selectedAcademicYear: null
      },
      poiGroupDataObj: {},
      editableYearGroup: null,
      yearsGroup: []
    }
  },
  mounted () {
    this.storeSubSubscriberLogic()
  },
  destroyed () {
    /// ** Stop VueX Actions Subscriber to prevent memory leak and Errors **///
    this.storeUnSubscriber()
  },
  methods: {
    storeSubSubscriberLogic () {

      /// ** Interact Modals & other actions throw vuex ** ///
      /// ** this case to listen on vuex action to open modal from other components ** ///
      const self = this
      this.storeUnSubscriber = this.$store.subscribeAction({
        before: (action, state) => {
          // if you need to implement before action
          // it's default behavior
        },
        after: (action, state) => {
          if (action.type === 'manageModal' && action.payload.name === self.modalName) {
            if (action.payload.show === true) {
              this.loadData(action.payload.data)
            } else if (action.payload.show === false) {
              this.cancel()
            }
          }
        },
        error: (action, state, error) => {
          this.cancel()
          console.error(error)
        }
      })
    },
    showModal () {
      this.$modal.show(this.modalName)
    },
    hideModal () {
      this.$modal.hide(this.modalName)
    },
    loadData (data) {
      this.resetProps()
      //** Rebind Data **//
      this.editableYearGroup = data || null
      this.groupData.title = data ? data.name : ''
      this.groupData.selectedGroup = data ? {id: data.grade_id, name: data.grade_name} : null
      this.groupData.selectedAcademicYear = data ? this.academicYearsOptions.find(item => item.id === data.academic_year_id) : null
      this.getFilteredYearsGroup()
      this.showModal()
    },
    resetProps () {
      this.groupData = {
        title: '',
        selectedGroup: null,
        selectedAcademicYear: null
      }
      this.editableYearGroup = null
      this.poiGroupDataObj = null
      this.yearsGroup = []
    },
    canEdit (academicYearId) {
      const isEditable = this.futureAcademicYearsOptions.find(item => item.id === academicYearId)
      if (isEditable) {
        return true
      } else {
        return false
      }
    },
    async getFilteredYearsGroup () {
      if (this.groupData.selectedAcademicYear) {
        await this.restService.getAcdemicYearsGrades(this.groupData.selectedAcademicYear.id)
          .then(res => {
            //** filter yearsGroupOptions to get not selected yearsGroup options only **//
            const yearsGroupOptions = this.cloneItem(this.$store.getters.getYearsGroupOptions)
            const selectedYearsGroup = res.data.map(item => item.grade_id)
            this.yearsGroup = yearsGroupOptions.filter(item => {
              if (!selectedYearsGroup.includes(item.id)) {
                return item
              }
            })
          })
          .catch(err => console.error(err))
      }
    },
    async getSetUpdatedData () {
      //** Get updated Year Group Data To set in Store **//
      await this.restService.getAcademicYearData({academic_year_id: this.editableYearGroup.academic_year_id})
        .then(res => {
          this.savingStoreData(res.data)
        })
        .catch(err => console.error(err))
    },
    savingStoreData (data) {
      //** Case Create **//
      if (!this.editableYearGroup) {
        //** check if academic years group can edit based on current or future dates **//
        data['isEditable'] = this.canEdit(data.academic_year_id)

        this.poiGroupDataObj = data
        this.poiGroupDataObj.academic_year_id = this.groupData.selectedAcademicYear.id
        // ** push New Year Group To Store ** //
        this.$store.dispatch('AddPoiYearsGroup', this.cloneItem(this.poiGroupDataObj))
          .then(() => {
            this.successMsg(this.$t('poi.created_successfully'), this.$t('poi.year_group'))

            this.cancel()
          })
      }
      //** Case Update **//
      else if (this.editableYearGroup) {
        //** check if academic years group can edit based on current or future dates **//
        data = data.map(item => {
          item['isEditable'] = this.canEdit(item.academic_year_id)
          return item
        })

        // ** Set Updated Data To store ** //
        this.$store.dispatch('replacePoi', this.cloneItem(data))
          .then(() => {
            this.successMsg(this.$t('poi.updated_successfully'), this.$t('poi.year_group'))

            this.cancel()
          })
      }
    },
    update (data) {
      this.restService.updateYearGroup(this.editableYearGroup.id, data)
        .then(res => {
          this.getSetUpdatedData()
        })
        .catch(err => {
          console.error(err)
          this.errMsg(err.message)

        })

    },
    save (data) {
      this.restService.createYearGroup(data)
        .then(res => {
          this.savingStoreData(res.data)
        })
        .catch(err => {
          console.error(err)
          this.errMsg(err.message)

        })
    },
    checkSavingData () {
      //** Prevent Create Group When no Group Selected **//
      if (this.groupData.selectedGroup && this.groupData.selectedAcademicYear) {
        //** Set Data To Saving **//
        const data = {
          academic_year_id: this.groupData.selectedAcademicYear.id,
          grade_id: this.groupData.selectedGroup.id,
          name: `${this.groupData.selectedGroup.name  } ${  this.groupData.title}`
          /*name: (this.groupData.title) ? this.groupData.title : this.groupData.selectedGroup.name*/
        }
        //** Case Create New Year Group **//
        if (!this.editableYearGroup) {
          this.save(data)
        }
        //** Case Update Year Group **//
        else if (this.editableYearGroup) {
          this.update(data)
        }

      }
    },
    cancel () {
      this.resetProps()
      this.hideModal()
    }
  },
  computed: {
    ...mapGetters({academicYearsOptions: 'getAcademicYearsOptions'}),
    ...mapGetters({currentAcademicYear: 'getCurrentAcademicYear'}),
    ...mapGetters({futureAcademicYearsOptions: 'getFutureAcademicYearsOptions'})
  }
}
