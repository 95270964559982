export default {
  inventory: 'Inventory',
  reportCard: 'Report Cart',
  addReportCard: 'Add Report Cart',
  finalReportCard: 'Final Report Cart',
  certificate : 'Certificate',
  addInventorySuccessfully: 'Add Inventory Successfully',
  uploadReportCard: 'Upload report card',
  my_report_card: 'My Report Cards',
  uploaded: 'Uploaded',
  areYouSureToDeleteThoseRows: 'Are You Sure To Delete Those Rows'
}
