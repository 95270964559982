import RoundsRepository from './roundRepository'
import ApplicationRepository from  './applicationRepository'
import EvaluationCardRepository from './evaluationCardRepository'
import allStudentsRepository from './allStudentsRepository'
import braincertRepository from './braincertRepository'

export default {
  rounds: RoundsRepository,
  applications: ApplicationRepository,
  evaluations: EvaluationCardRepository,
  allStudents: allStudentsRepository,
  braincert: braincertRepository
}
