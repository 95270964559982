<template>
  <div>
    <teaching-materials-share-form></teaching-materials-share-form>
  </div>
</template>
<script>
import TeachingMaterialsShareForm from './components/share-form'

export default {
  components:{
    TeachingMaterialsShareForm

  },
  created () {

  },
  data () {
    return {

    }
  }
}
</script>
