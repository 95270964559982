export default [

  {
    path: '',
    redirect: 'create-exam'
  },
  {
    path: 'create-exam',
    name: 'create-exam',
    component: () => import('./sub/createExam.vue'),
    children: [
      {
        path: '',
        redirect: 'general-settings'
      },
      {
        path: 'general-settings',
        name: 'general-settings',
        component: () => import('./sub/generalSettings.vue'),
        meta: {
          breadcrumb: [
            { title: 'home', url: '/home' },
            // { title: 'AcademicYears.academic', url: '/academic' },
            {
              title: 'Create Exam',
              url: '/exam'
            },
            {
              title: 'General settings',
              url: '/exam/general-settings',
              active: true
            }
          ],
          pageTitle: 'Create Exam',
          rule:'SNDSMD'
        }
      },
      {
        path: 'attendance-access',
        name: 'attendance-access',
        component: () => import('./sub/attendanceAccess.vue'),
        meta: {
          rule: 'SMD'
        }
      },
      {
        path: 'questions-manager',
        name: 'questions-manager',
        component: () => import('./sub/questionsManager.vue'),
        meta: {
          rule: 'SMD'
        }
      },
      {
        path: 'configuration',
        name: 'configuration',
        component: () => import('./sub/configuration.vue'),
        meta: {

          rule: 'SMD'
        }
      },
      {
        path: 'exam-info',
        name: 'exam-info',
        component: () => import('./sub/examInfo.vue'),
        meta: {
          rule: 'SMD'
        }
      }
    ],
    meta: {
      rule: 'SMD'
    }
  }


]
