<template>
  <div>
    <div class="assesment-task-container__btn">
      <vs-button
        @click="openCreateTaskModal(null)"
        class="ml-auto mr-0 block"
        color="primary"
        type="filled"
      >
        Create Assessment Task
      </vs-button>
    </div>
    <teacher-assessment-task :teacherUnitId="teacherUnitId" v-if="teacherUnitId"></teacher-assessment-task>
    <assessment-task-modal :teacherUnitId="teacherUnitId"></assessment-task-modal>
    <!-- <review-task-modal></review-task-modal> -->
    <preview-task-modal />
  </div>
</template>

<script>
import TeacherAssessmentTask from '../teacher-assessment-task/teacher-assessment-task.vue'
import assessmentTaskModal from '../../modals/create-task/create-assessment-task.vue'
import ReviewTaskModal from '../../modals/review-tasks/review-tasks.vue'
import PreviewTaskModal from '../../modals/preview-task/create-assessment-task.vue'
export default {
  props: ['teacherUnitId'],
  components: {
    TeacherAssessmentTask,
    assessmentTaskModal,
    ReviewTaskModal,
    PreviewTaskModal
  },
  methods: {
    openCreateTaskModal (data) {
      // eslint-disable-next-line no-undef
      $sharedEvent.fire('openCreateTaskModal', data)
    },
    openPreviewTaskModal (data) {
      // eslint-disable-next-line no-undef
      $sharedEvent.fire('openPreviewTaskModal', data)
    }

  }
}
</script>

<style lang="scss" scoped>
</style>
