<template>
  <div v-if="showform">
    <teacher-form
      :teacherFormProps="teacher_form"
      :edit_id="edit_id"
    >
    </teacher-form>
  </div>
</template>
<script>
// import teacherForm from '../../../components/TeacherForm.vue'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const addTeacherRepo = RepositoryFactory.get(
  'teachersGatewayModule',
  'addTeacher'
)

export default {
  name: 'ShowSingleTeacher',
  components: {
    teacherForm:() => import('../../../components/TeacherForm.vue')
  },
  created () {},
  data () {
    return {
      edit_id: this.$route.params.id,
      teacher_form: null,
      showform: false
    }
  },
  mounted () {
    this.showSingleTeacher()
  },
  methods: {
    showSingleTeacher () {
      this.VSLoading()
      addTeacherRepo.showSingleTeacher(this.edit_id).then((res) => {
        this.teacher_form = Object.assign({}, res.data)

        this.teacher_form.divisions = []
        res.data.divisions.forEach((item) => {
          const obj = {
            division_id: item.pivot.division_id,
            section_id: item.pivot.section_id

          }
          this.getSectionsByDivisionsID(item.pivot.division_id).then((res) => {
            obj.sections = res
          })
          this.teacher_form.divisions.push(obj)
        })
      }).finally(() => {
        this.stopVSLoading()
        this.showform = true
      })
    },
    getSectionsByDivisionsID (divisionId) {
      return new Promise((resolve) => {
        const data = {
          division_ids: [divisionId]
        }
        addTeacherRepo.learingStages(data).then((res) => {
          resolve(res.data)
        })

      })
    }
  }
}
</script>
