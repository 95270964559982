<template>
  <userForm></userForm>
</template>
<script>
import userForm from './components/UserForm.vue'
export default {
  name: 'CreateUser',
  components:{userForm}

}
</script>
