<template>
  <div>
    <nav-filter
      class="mb-10"
      :title="$t('form.choose_class')"
      :filters="filters"
      :loading="loadingFilters"
      :collapseAction="true"
      @reFilter="reFilter"
    />

    <!-- Table -->
    <gradeBookTable />
  </div>
</template>
<script>
// Components
import NavFilter from '@/components/general/NavFilter.vue'
import gradeBookTable from '@/modules/grading/components/gradeBookTable/gradeBookTable.vue'
// mixins
import {commonDataMixin} from '@/mixins.js'

export default {
  name: 'gradebook',
  mixins: [commonDataMixin],
  components: {
    NavFilter,
    gradeBookTable
  },
  data () {
    return {
      classes: [],
      loadingClasses: false,
      loadingFilters: true,
      /////////////////////
      //  Filters
      /////////////////////
      filters: [

        {
          id: 2,
          name: this.$t('basic.divisions'),
          payload_name: 'divisions',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 3,
          name: this.$t('basic.sections'),
          payload_name: 'sections',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 4,
          name: this.$t('basic.grades'),
          payload_name: 'grades',
          onChangeFireAction: true,

          by: []
        },

        {
          id: 6,
          name: this.$t('basic.class_groups'),
          payload_name: 'class_groups',
          by: []
        }
      ],
      filtersChoosen: {},
      dates: {}

    }
  },
  computed: {
    selectedClass () {
      return this.$store.getters.getSelectedClass || {}
    }
  },
  methods: {

    /////////////////////////
    // Filter
    /////////////////////////
    reFilter (filters) {
      this.filtersChoosen = filters
      const
        isClassSelected = Boolean(filters.class_groups?.id),
        classNotChanged = isClassSelected && (this.selectedClass.id === filters.class_groups?.id)

      if (!classNotChanged) this.$store.commit('gradingSelectClass', {
        ...filters?.class_groups,
        grade_id: filters?.grades?.id,
        section_id: filters?.sections?.id,
        division_id: filters?.divisions?.id
      })
      else this.$store.commit('gradingSelectClass', null)
    },
    resetFilter (filter) {
      const startIndex = this.filters.findIndex(e => e.payload_name === filter)
      for (let i = startIndex; i < this.filters.length; i++) {
        this.filters[i].by = []
      }
    },

    /////////////////////////
    // APIs
    /////////////////////////
    getFilters (payload = null, targetResult = 'divisions', targetFilter = 'divisions') {
      this.loadingFilters = true
      this.getFilterData(payload).then((res) => {
        res[targetResult].map((el) => {
          const targetFilterIndex = this.filters.findIndex(e => e.payload_name === targetFilter)
          this.filters[targetFilterIndex]['by'].push({
            id: el.id,
            name: el.branch_name || el.name

          })
        })
      })
        .finally(() => {
          this.loadingFilters = false

        })
    },

    getDivision () {
      const data = {
        schools: [this.authUser.school_id]
      }

      this.resetFilter('divisions')
      this.getFilters(data, 'divisions', 'divisions')

    },
    getSections () {
      this.resetFilter('sections')
      const data = {
        schools: [this.authUser.school_id],
        divisions: [this.filtersChoosen.divisions?.id || 0]
      }

      this.getFilters(data, 'sections', 'sections')
    },
    getGrades () {
      this.resetFilter('grades')
      const data = {
        schools: [this.authUser.school_id],
        divisions: [this.filtersChoosen.divisions?.id || 0],
        sections: [this.filtersChoosen.sections?.id || 0]

      }
      this.getFilters(data, 'grades', 'grades')
    },
    getClasses () {
      this.resetFilter('class_groups')

      const data = {
        schools: [this.authUser.school_id],
        divisions: [this.filtersChoosen.divisions?.id || 0],
        grades: [this.filtersChoosen.grades?.id || 0],
        sections: [this.filtersChoosen.sections?.id || 0]
      }
      this.getFilters(data, 'grades_classes', 'class_groups')
    }

  },
  created () {
    this.getDivision()

    this.$root.$on('onChange:divisions', () => { this.getSections() })
    this.$root.$on('onChange:sections', () => { this.getGrades() })
    this.$root.$on('onChange:grades', () => { this.getClasses() })
  },
  beforeDestroy () {
    this.$root.$off('onChange:divisions')
    this.$root.$off('onChange:sections')
    this.$root.$off('onChange:grades')
    // this.$store.commit('gradingSelectClass', null)
  }
}
</script>
