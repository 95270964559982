import axios from 'axios'

export default {
  ListAllAnnouncement (filters) {
    return axios.get('/announcement/index', {
      params: {
        ...filters
      }
    })
  },
  createAnnouncement (payload) {
    return axios.post('/announcement/create', payload)
  },
  showAnnouncement (id) {
    return axios.get(`/announcement/show/${id}`)
  },
  getSectionsByDivisionsIds (payload) {
    return axios.post('/common/divisions/sections', payload)
  },
  getGradesBySecstionsIds (payload) {
    return axios.post('/common/sections/grades', payload)
  },
  getClasses (payload) {
    return axios.post('/common/grades/classes', payload)
  },
  read () {
    return axios.get('/announcement/read')
  },
  announcementForTeacher (payload) {
    return axios.post('institution/announcement-for-teacher', payload)
  }
}

