<template >
  <div>
    <vx-card class="py-6 md:p-6">
      <form
        id="longForm"
        @submit.prevent="submit"
        @keyup.enter="submit"
        class="w-full m-auto"
      >
        <!-- Student info In Snd -->
        <vs-divider :class="gridClasses">
          {{ $t("form.sutudent_info") | text_formatter }}
        </vs-divider>
        <div
          class="vx-row"
          v-for="student in parentData.students"
          :key="student.id"
        >
          <template>
            <!--  userName -->
            <div :class="grid4ColsClasses" class="my-1">
              <label>{{ $t("form.user_name") }} </label>
              <vs-input
                v-model="student.name"
                class="w-full"
                :disabled="true"
              />
            </div>

            <!-- id -->
            <div :class="grid4ColsClasses" class="my-1">
              <label>{{ $t("form.ID") }} </label>
              <vs-input v-model="student.id" class="w-full" :disabled="true" />
            </div>

            <!-- code -->
            <div :class="grid4ColsClasses" class="my-1">
              <label>{{ $t("form.code") }} </label>
              <vs-input
                v-model="student.code"
                class="w-full"
                :disabled="true"
              />
            </div>

            <!-- Email -->
            <div :class="grid4ColsClasses" class="my-1">
              <label>{{ $t("form.email") }}</label>
              <vs-input
                v-model="student.Email"
                class="w-full"
                :disabled="true"
              />
            </div>
          </template>
        </div>

        <!-- ////////////////////////////////
                Full Name
         /////////////////////////////////////-->
        <!-- In English -->
        <vs-divider :class="gridClasses">
          {{ $t("form.fullName") | text_formatter }}
        </vs-divider>
        <div class="vx-row">
          <label :class="gridClasses"
            >{{ $t("form.In_English") }} <sup class="text-danger">*</sup></label
          >
          <!-- First name -->
          <div :class="grid4ColsClasses" class="my-1">
            <!-- <label
              >{{ $t("form.first_name") }}
              <sup class="text-danger">*</sup></label
            > -->
            <vs-input
              v-model="parentData.first_name"
              class="w-full"
              name="first_name"
              :placeholder="$t('form.first_name')"
              v-validate="{
                required: true,
                alpha_spaces: true,
                regex: /^[a-zA-Z\s]*$/,
              }"
            />
            <span class="text-danger text-sm">{{
              errors.first("first_name") | text_formatter
            }}</span>
          </div>
          <!-- Middle name -->
          <div :class="grid4ColsClasses" class="my-1">
            <!-- <label
              >{{ $t("form.middle_name") }}
              <sup class="text-danger">*</sup></label
            > -->
            <vs-input
              v-model="parentData.middle_name"
              name="middle_name"
              class="w-full"
              :placeholder="$t('form.middle_name')"
              v-validate="{
                required: true,
                alpha_spaces: true,
                regex: /^[a-zA-Z\s]*$/,
              }"
            />
            <span class="text-danger text-sm">{{
              errors.first("middle_name") | text_formatter
            }}</span>
          </div>
          <!-- Last name -->
          <div :class="grid4ColsClasses" class="my-1">
            <!-- <label
              >{{ $t("form.last_name") }}<sup class="text-danger">*</sup></label
            > -->
            <vs-input
              v-model="parentData.last_name"
              name="last_name"
              class="w-full"
              :placeholder="$t('form.last_name')"
              v-validate="{
                required: true,
                alpha_spaces: true,
                regex: /^[a-zA-Z\s]*$/,
              }"
            />
            <span class="text-danger text-sm">{{
              errors.first("last_name") | text_formatter
            }}</span>
          </div>
          <!-- Forth  name -->
          <div :class="grid4ColsClasses" class="my-1">
            <!-- <label
              >{{ $t("form.forth_name")
              }}<sup class="text-danger">*</sup></label
            > -->
            <vs-input
              v-model="parentData.forth_name"
              name="forth_name"
              class="w-full"
              :placeholder="$t('form.forth_name')"
              v-validate="{
                required: true,
                alpha_spaces: true,
                regex: /^[a-zA-Z\s]*$/,
              }"
            />
            <span class="text-danger text-sm">{{
              errors.first("forth_name") | text_formatter
            }}</span>
          </div>
        </div>

        <!-- <vs-divider :class="gridClasses">
          {{
            ($t("form.fullName") + " " + $t("form.In_Arabic")) | text_formatter
          }}
        </vs-divider> -->
        <!-- In Arabic -->
        <div class="vx-row">
          <label :class="gridClasses" class="md:"
            >{{ $t("form.In_Arabic") }} <sup class="text-danger">*</sup></label
          >

          <!-- First name -->
          <div class="my-1" :class="grid4ColsClasses">
            <!-- <label
              >{{ $t("form.first_name") }}
              <sup class="text-danger">*</sup></label
            > -->
            <vs-input
              v-model="parentData.arFirst_name"
              class="w-full"
              name="arabic_first_name"
              :placeholder="$t('form.first_name')"
              v-validate="{
                required: true,
                alpha_spaces: true,
                regex: /^[\u0600-\u06FF-\s]*$/,
              }"
            />
            <span class="text-danger text-sm">{{
              errors.first("arabic_first_name") | text_formatter
            }}</span>
          </div>
          <!-- Middle name -->
          <div :class="grid4ColsClasses" class="my-1">
            <!-- <label
              >{{ $t("form.middle_name")
              }}<sup class="text-danger">*</sup></label
            > -->
            <vs-input
              v-model="parentData.arMiddle_name"
              name="arabic_middl_name"
              class="w-full"
              :placeholder="$t('form.middle_name')"
              v-validate="{
                required: true,
                alpha_spaces: true,
                regex: /^[\u0600-\u06FF-\s]*$/,
              }"
            />
            <span class="text-danger text-sm">{{
              errors.first("arabic_middl_name") | text_formatter
            }}</span>
          </div>
          <!-- Last name -->
          <div :class="grid4ColsClasses" class="my-1">
            <!-- <label
              >{{ $t("form.last_name") }}
              <sup class="text-danger">*</sup></label
            > -->
            <vs-input
              v-model="parentData.arLast_name"
              name="arabic_last_name"
              class="w-full"
              :placeholder="$t('form.last_name')"
              v-validate="{
                required: true,
                alpha_spaces: true,
                regex: /^[\u0600-\u06FF-\s]*$/,
              }"
            />
            <span class="text-danger text-sm">{{
              errors.first("arabic_last_name") | text_formatter
            }}</span>
          </div>
          <!-- Forth  name -->
          <div :class="grid4ColsClasses" class="my-1">
            <!-- <label
              >{{ $t("form.forth_name")
              }}<sup class="text-danger">*</sup></label
            > -->
            <vs-input
              v-model="parentData.arForth_name"
              name="arabic_forth_name"
              class="w-full"
              :placeholder="$t('form.forth_name')"
              v-validate="{
                required: true,
                alpha_spaces: true,
                regex: /^[\u0600-\u06FF-\s]*$/,
              }"
            />
            <span class="text-danger text-sm">{{
              errors.first("arabic_forth_name") | text_formatter
            }}</span>
          </div>
        </div>

        <!-- ////////////////////////////////
                     Personal Data
        ///////////////////////////////////// -->
        <vs-divider :class="gridClasses">
          {{ $t("completeProfileData.personal_data") | text_formatter }}
        </vs-divider>
        <div class="vx-row">
          <!--  ID -->
          <div :class="grid2ColsClasses" class="my-1">
            <label>
              {{ $t("form.nationalId") }}
              <!-- <sup class="text-danger">*</sup> -->
            </label>
            <vs-input
              type="text"
              v-model="parentData.national_id"
              class="w-full"
              name="national_ID"
            />
            <span class="text-danger text-sm">{{
              errors.first("national_ID") | text_formatter
            }}</span>
          </div>

          <!-- Birth date -->
          <div :class="grid2ColsClasses" class="my-1">
            <label
              >{{ $t("form.birth_date") }}
              <!-- <sup class="text-danger">*</sup> -->
            </label>
            <flat-pickr
              class="w-full"
              :config="configdatePicker"
              v-model="birth_date"
              name="birthdate"
            />
            <span class="text-danger text-sm">{{
              errors.first("birthdate") | text_formatter
            }}</span>
          </div>

          <!-- Religion -->
          <div :class="grid2ColsClasses" class="my-1">
            <label class
              >{{ $t("form.Religion") }}<sup class="text-danger">*</sup></label
            >
            <v-select
              v-model="parentData.religion_id"
              v-validate="'required'"
              name="religion"
              class="w-full"
              :options="religions"
              label="name"
              :reduce="(name) => name.id"
              :disable="!religions"
            />
            <vs-input
              name="religion"
              class="w-full mt-5"
              v-if="parentData.religion_id < 0"
              v-model="parentData.other_religion"
              v-validate="'required'"
              :placeholder="$t('form.please_enter_or_religion')"
            ></vs-input>

            <span class="text-danger text-sm">{{
              errors.first("religion") | text_formatter
            }}</span>
          </div>

          <!-- nationality -->
          <div :class="grid2ColsClasses" class="my-1">
            <label
              >{{ $t("form.Nationality")
              }}<sup class="text-danger">*</sup></label
            >
            <v-select
              multiple
              :closeOnSelect="false"
              v-model="parentData.nationality"
              :options="nationalities"
              label="name"
              :reduce="(name) => name.id"
              v-validate="'required'"
              name="nationality"
              class="w-full"
              :disable="!nationalities"
              :hideSelectAll="true"
            />

            <span class="text-danger text-sm">{{
              errors.first("nationality") | text_formatter
            }}</span>
          </div>
        </div>
        <!-- ////////////////////////////////
                 communication && address data
        ///////////////////////////////////// -->
        <vs-divider :class="gridClasses">
          {{ $t("completeProfileData.communication_address") | text_formatter }}
        </vs-divider>
        <div class="vx-row">
          <!-- Mobile -->
          <div :class="grid2ColsClasses" class="my-1">
            <label :class="gridClasses">
              {{ $t("form.mobile") }}
              <sup class="text-danger">*</sup>
            </label>
            <div class="vx-row">
              <!-- Key -->
              <div class="vx-col w-1/3">
                <v-select
                  v-model="parentData.mobile.code"
                  name="mobile_code"
                  class="w-full"
                  v-validate="'required'"
                  :options="mobile_keys"
                />

                <span class="text-danger text-sm">
                  {{ errors.first("mobile_code") | text_formatter }}
                </span>
              </div>
              <!-- number -->
              <div class="vx-col w-2/3">
                <vs-input
                  v-model="parentData.mobile.number"
                  class="w-full"
                  name="mobile"
                  v-validate="'required|numeric|length:8'"
                />

                <span class="text-danger text-sm">
                  {{ errors.first("mobile") | text_formatter }}
                </span>
              </div>
            </div>
          </div>

          <!-- Telephone -->
          <div class="my-1" :class="grid2ColsClasses">
            <label :class="gridClasses">
              {{ $t("form.telephone") }}
              <!-- <sup class="text-danger">*</sup> -->
            </label>
            <div class="vx-row">
              <!-- Key -->
              <div class="vx-col w-1/3">
                <v-select
                  v-model="parentData.telephone.code"
                  name="telephone_code"
                  class="w-full"
                  v-validate="'required'"
                  :options="tel_keys"
                />
                <span class="text-danger text-sm">
                  {{ errors.first("telephone_code") | text_formatter }}
                </span>
              </div>
              <!-- number -->
              <div class="vx-col w-2/3">
                <vs-input
                  v-model="parentData.telephone.number"
                  class="w-full"
                  name="telephone"
                  v-validate="'numeric|length:8'"
                />

                <span class="text-danger text-sm">
                  {{ errors.first("telephone") | text_formatter }}
                </span>
              </div>
            </div>
          </div>

          <!-- Country -->
          <div :class="grid2ColsClasses" class="my-1">
            <label>
              {{ $t("form.country") }}
              <!-- <sup class="text-danger">*</sup> -->
            </label>
            <v-select
              v-model="parentData.country_id"
              name="country"
              class="w-full"
              @input="onChangeCountry"
              :options="countries"
              label="name"
              :reduce="(name) => name.id"
            />

            <span class="text-danger text-sm vx-col">
              {{ errors.first("country") | text_formatter }}
            </span>
          </div>

          <!-- city -->
          <div :class="grid2ColsClasses" class="my-1">
            <label>
              {{ $t("form.city") }}
              <!-- <sup class="text-danger">*</sup> -->
            </label>
            <v-select
              v-model="parentData.city_id"
              name="city"
              class="w-full"
              :options="cities"
              label="name"
              :reduce="(name) => name.id"
              :showLoadingIcon="showCitySelectLoader"
              :noOptionsText="$t('form.select_country_first')"
            />

            <span class="text-danger text-sm vx-col">
              {{ errors.first("city") | text_formatter }}
            </span>
          </div>

          <!-- district -->
          <div :class="grid2ColsClasses" class="my-1">
            <label
              >{{ $t("form.district") }}
              <!-- <sup class="text-danger">*</sup> -->
            </label>

            <vs-input
              v-model="parentData.district"
              name="district"
              class="w-full"
            />

            <span
              class="text-danger text-sm vx-col"
              v-if="errors.has('district')"
            >
              {{ errors.first("district") | text_formatter }}
            </span>
          </div>

          <!-- street -->
          <div :class="grid2ColsClasses" class="my-1">
            <label
              >{{ $t("form.Street") }}
              <!-- <sup class="text-danger">*</sup> -->
            </label>
            <vs-input
              v-model="parentData.street"
              name="street"
              class="w-full"
            />
            <span class="text-danger text-sm vx-col">
              {{ errors.first("street") | text_formatter }}
            </span>
          </div>
        </div>
        <!-- ////////////////////////////////
                 education data
        ///////////////////////////////////// -->
        <vs-divider :class="gridClasses">
          {{ $t("completeProfileData.education") | text_formatter }}
        </vs-divider>
        <div class="vx-row">
          <!-- Education School -->
          <div :class="grid2ColsClasses" class="my-1">
            <label>{{ $t("form.education_school") }} </label>
            <vs-input
              v-model="parentData.school"
              class="w-full"
              name="education_school"
            />
            <span class="text-danger text-sm">{{
              errors.first("education_school") | text_formatter
            }}</span>
          </div>

          <!-- Education University -->
          <div :class="grid2ColsClasses" class="my-1">
            <label>{{ $t("form.education_university") }} </label>
            <vs-input
              v-model="parentData.univeristy"
              class="w-full"
              name="education_university"
            />
            <span class="text-danger text-sm">{{
              errors.first("education_university") | text_formatter
            }}</span>
          </div>

          <!-- information_degree -->
          <div :class="grid2ColsClasses" class="my-1">
            <label>{{ $t("form.information_degree") }} </label>
            <vs-input
              v-model="parentData.information_degree"
              class="w-full"
              name="information_degree"
            />
            <span class="text-danger text-sm">{{
              errors.first("information_degree") | text_formatter
            }}</span>
          </div>

          <!-- Occupation -->
          <div :class="grid2ColsClasses" class="my-1">
            <label>{{ $t("form.occupation") }} </label>
            <vs-input
              v-model="parentData.occupation"
              class="w-full"
              name="occupation"
            />
            <span class="text-danger text-sm">{{
              errors.first("occupation") | text_formatter
            }}</span>
          </div>

          <!-- Employer -->
          <div :class="grid2ColsClasses" class="my-1">
            <label>{{ $t("form.employer") }} </label>
            <vs-input
              v-model="parentData.employer"
              class="w-full"
              name="employer"
            />
            <span class="text-danger text-sm">{{
              errors.first("employer") | text_formatter
            }}</span>
          </div>

          <!-- Work Address -->
          <div :class="grid2ColsClasses" class="my-1">
            <label>{{ $t("form.work_address") }} </label>
            <vs-input
              v-model="parentData.work_address"
              class="w-full"
              name="work_address"
            />
            <span class="text-danger text-sm">{{
              errors.first("work_address") | text_formatter
            }}</span>
          </div>

          <!-- Mobile -->
          <div :class="grid2ColsClasses" class="my-1">
            <label :class="gridClasses">
              {{ $t("form.work_mobile") }}
            </label>
            <!-- Key & Number -->
            <div class="vx-row">
              <!-- Key -->
              <div class="vx-col w-1/3">
                <v-select
                  v-model="parentData.work_mobile.code"
                  name="mobile_code"
                  class="w-full"
                  :options="mobile_keys"
                />

                <span class="text-danger text-sm">
                  {{ errors.first("mobile_code") | text_formatter }}
                </span>
              </div>
              <!-- number -->
              <div class="vx-col w-2/3">
                <vs-input
                  v-model="parentData.work_mobile.number"
                  class="w-full"
                  name="mobile"
                  v-validate="'numeric|length:8'"
                />

                <span class="text-danger text-sm">
                  {{ errors.first("mobile") | text_formatter }}
                </span>
              </div>
            </div>
          </div>

          <!-- Telephone -->
          <div :class="grid2ColsClasses" class="my-1">
            <label :class="gridClasses">
              {{ $t("form.work_telephone") }}
            </label>
            <!-- Key & Number -->
            <div class="vx-row">
              <!-- Key -->
              <div class="vx-col w-1/3">
                <v-select
                  v-model="parentData.work_telephone.code"
                  name="telephone_code"
                  class="w-full"
                  :options="tel_keys"
                />

                <span class="text-danger text-sm">
                  {{ errors.first("telephone_code") | text_formatter }}
                </span>
              </div>
              <!-- number -->
              <div class="vx-col w-2/3">
                <vs-input
                  v-model="parentData.work_telephone.number"
                  class="w-full"
                  name="telephone"
                  v-validate="'numeric|length:8'"
                />

                <span class="text-danger text-sm">
                  {{ errors.first("telephone") | text_formatter }}
                </span>
              </div>
            </div>
          </div>

          <!-- Fax -->
          <div :class="grid2ColsClasses" class="my-1">
            <label :class="gridClasses">
              {{ $t("form.work_fax") }}
            </label>
            <!-- Key & Number -->
            <div class="vx-row">
              <!-- Key -->
              <div class="vx-col w-1/3">
                <v-select
                  v-model="parentData.work_fax.code"
                  name="telephone_code"
                  class="w-full"
                  :options="fax_keys"
                />

                <span class="text-danger text-sm">
                  {{ errors.first("fax_code") | text_formatter }}
                </span>
              </div>
              <!-- number -->
              <div class="vx-col w-2/3">
                <vs-input
                  v-model="parentData.work_fax.number"
                  class="w-full"
                  name="fax"
                  v-validate="'numeric|length:8'"
                />

                <span class="text-danger text-sm">
                  {{ errors.first("fax") | text_formatter }}
                </span>
              </div>
            </div>
          </div>

          <!-- Work Email -->
          <div :class="grid2ColsClasses" class="my-1">
            <label>
              {{ $t("form.work_email") }}
            </label>
            <vs-input
              type="email"
              v-model="parentData.work_email"
              class="w-full"
              name="email"
              v-validate="'email'"
            />
            <span class="text-danger text-sm">
              {{ errors.first("email") | text_formatter }}
            </span>
          </div>

          <!-- generate Password  -->
          <div :class="grid2ColsClasses" v-if="edit_id" class="my-1">
            <!--  Password -->
            <label>
              {{ $t("form.password") }}
            </label>
            <vx-input-group class="w-full mb-2">
              <vs-input
                v-model="parentData.password"
                class="w-full"
                name="password"
              />
            </vx-input-group>
          </div>

          <!-- school alumni -->
          <div :class="grid2ColsClasses" class="my-1">
            <label
              >{{ $t("form.school_alumni") }}
              <!-- <sup class="text-danger">*</sup> -->
            </label>
            <ul class="vx-row mt-3">
              <li class="vx-col w-1/2">
                <vs-radio
                  v-model="parentData.is_school_alumni"
                  :vs-value="true"
                  vs-name="school_alumni"
                  name="school_alumni"
                  >{{ $t("basic.yes") }}</vs-radio
                >
              </li>
              <li class="vx-col w-1/2">
                <vs-radio
                  v-model="parentData.is_school_alumni"
                  :vs-value="false"
                  vs-name="school_alumni"
                  name="school_alumni"
                  >{{ $t("basic.no") }}</vs-radio
                >
              </li>
            </ul>
          </div>
          <!-- offical Email -->
          <div :class="grid2ColsClasses" v-if="edit_id" class="my-1">
            <label>
              {{ $t("form.official_email") }}
            </label>
            <vs-input
              type="email"
              v-model="parentData.official_email"
              class="w-full"
              name="email"
              v-validate="'email'"
            />
            <span class="text-danger text-sm">
              {{ errors.first("email") | text_formatter }}
            </span>
          </div>
          <!-- Start Image  -->
          <div :class="grid2ColsClasses" class="my-1">
            <label>
              {{ $t("teachersGateway.image") }}
            </label>

            <vs-input
              @change="updateCurrImg"
              v-model="image"
              name="Image"
              class="w-full"
              type="file"
              accept="image/*;capture=camera"
            />
          </div>
          <feather-icon
            v-if="imageView || parentData.image"
            icon="XIcon"
            svgClasses="h-5 w-5"
            @click="deleteDocument()"
            class="mr-2 remove-teacher-attachment"
          />
          <img
            alt="teacher_Pic"
            v-if="imageView || parentData.image"
            :src="parentData.image"
          />

          <!-- End Image  -->
        </div>

        <!-- ////////////////////////////////
                      submit data
        ///////////////////////////////////// -->
        <div class="flex justify-between items-center mt-auto mb-0 w-full">
          <vs-button
            class="mt-5 md:w-auto md:mr-0 ml-auto mr-auto"
            :disabled="!validateForm"
            @click="submit"
          >
            {{ $t("basic.save") }}
          </vs-button>
        </div>
      </form>

      <!-- Loader -->
      <div
        ref="formLoader"
        id="formLoader"
        class="vs-con-loading__container"
        v-show="showLoader"
      ></div>
    </vx-card>
  </div>
</template>
<script>
// API
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const ParentRepository = RepositoryFactory.get('ParentRepository', 'parent')

// Mixins
import { commonDataMixin } from '@/mixins.js'
// picker
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  name: 'completeProfileData',
  mixins: [commonDataMixin],
  components: { flatPickr },
  props: ['edit_id'],
  data () {
    return {
      imageView: '',
      image: '',
      file: '',
      ///////////////////////////
      // Loader
      ///////////////////////////
      showLoader: false,
      showCitySelectLoader: false,
      ///////////////////////////
      // Datepicker
      ///////////////////////////
      configdatePicker: {
        minDate: null,
        maxDate: `${new Date().getFullYear() - 13}`,
        dateFormat: 'Y-m-d'
      },
      ///////////////////////////
      // parent Info
      ///////////////////////////
      parentData: {
        // full name
        first_name: null,
        middle_name: null,
        last_name: null,
        forth_name: null,
        arFirst_name: null,
        arMiddle_name: null,
        arLast_name: null,
        arForth_name: null,

        // personal data
        national_id: null,
        birth_date: null,
        religion_id: null,
        other_religion: null,
        nationality: null,

        // Address
        country_id: null,
        city_id: null,
        district: null,
        street: null,

        // Contacts
        mobile: {
          number: '',
          code: '011'
        },
        telephone: {
          number: '',
          code: '02'
        },
        // email: null,
        // officialEmail: null

        // education
        school: null,
        univeristy: null,
        occupation: null,
        information_degree: null,

        // work
        employer: null,
        work_address: null,
        work_mobile: {
          number: '',
          code: '011'
        },
        work_telephone: {
          number: '',
          code: '02'
        },
        work_fax: {
          number: '',
          code: '02'
        },
        work_email: null,
        official_email: null,
        is_school_alumni: false,
        image: '',
        password: '',
        students: []
      },
      birth_date: null
    }
  },

  computed: {
    validateForm () {
      return !this.errors.any()
    },
    gridClasses () {
      return 'vx-col w-full'
    },
    grid4ColsClasses () {
      return `${this.gridClasses} md:w-1/4 sm:w-1/2`
    },
    grid2ColsClasses () {
      return `${this.gridClasses} md:w-1/2`
    },
    grid3ColsClasses () {
      return `${this.gridClasses} xl:w-1/3 md:w-1/2`
    },
    isParentCompletedProfile () {
      return this.authUser.isParentCompletedProfile
    },
    isParentResponsible () {
      return this.authUser.isParentResponsible
    }
  },
  watch: {
    birth_date (v) {
      this.parentData.birth_date = new Date(v).getTime()
    }
  },
  methods: {
    // ----- start delete upload img ----------
    deleteDocument () {
      const document = this.parentData.image
      const data = {
        file_path: document
      }
      this.deleteDocumentFromS3(data)
        .then(() => {
          this.successMsg(this.$t('form.Delete_Document_Successfully'))
          this.parentData.image = ''
          this.imageView = ''
          this.image = ''
        })
        .catch(() => {
          this.errMsg(this.$t('form.failed_to_delete_documment'))
        })
    },
    // ----- End delete upload img ----------
    // ----- Start upload img ----------
    updateCurrImg (e) {
      this.file = e.target.files[0]
      const data = new FormData()
      data.append('attachments[0]', this.file)
      this.commonUploadFiles(data).then((res) => {
        this.parentData.image = res[0].attachment_url
        this.imageView = res[0].attachment_url
        this.successMsg(this.$t('form.your_document_uploaded_successfully'))
      })
    },
    // ----- End upload img ----------
    ///////////////////////
    // loader
    ///////////////////////
    // Create Group
    async startLoading () {
      await (this.showLoader = true)
      this.VsLoadingContained(this.$refs.formLoader)
    },
    endLoading () {
      this.stopVsLoadingContained(this.$refs.formLoader)
      this.showLoader = false
    },
    ///////////////////////
    // APIs
    ///////////////////////
    getParentData () {
      if (!this.edit_id) {
        this.startLoading()
        ParentRepository.getParentData()
          .then((res) => {
            // console.log('res', res.data)
            res.data.country_id = parseInt(res.data.country_id)
            res.data.city_id = parseInt(res.data.city_id)
            if (!res.data.telephone) res.data.telephone = { number: '', code: '02' }

            if (!res.data.mobile) res.data.mobile = { number: '', code: '011' }
            if (!res.data.work_telephone) res.data.work_telephone = { number: '', code: '02' }
            if (!res.data.work_mobile) res.data.work_mobile = { number: '', code: '011' }
            if (!res.data.work_fax) res.data.work_fax = { number: '', code: '012' }

            // if edit
            // if (this.isParentCompletedProfile) {
            this.parentData = res.data
            this.birth_date = this.fixDate(res.data.birth_date)
            this.getCitiesOfCountry(res.data.country_id)
            // }
            // if responsible
            const type = res.data.type,
              responsible = res.data.responsible
            if (type && responsible) {
              // alert(type && responsible)
              if (
                type === responsible ||
                (responsible === 'other' && responsible === 'custody')
              ) {
                this.UpdateLocalStorageAuthUser('isParentResponsible', true)
              }
            }
          })
          .finally(this.endLoading)
      } else {
        this.startLoading()
        ParentRepository.getSingleParentsById(this.edit_id)
          .then((res) => {
            res.data.country_id = parseInt(res.data.country_id)
            res.data.city_id = parseInt(res.data.city_id)
            if (!res.data.telephone) res.data.telephone = { number: '', code: '02' }

            if (!res.data.mobile) res.data.mobile = { number: '', code: '011' }
            if (!res.data.work_telephone) res.data.work_telephone = { number: '', code: '02' }
            if (!res.data.work_mobile) res.data.work_mobile = { number: '', code: '011' }
            if (!res.data.work_fax) res.data.work_fax = { number: '', code: '012' }
            // if edit
            // if (this.isParentCompletedProfile) {
            this.parentData = res.data
            this.birth_date = this.fixDate(res.data.birth_date)
            this.getCitiesOfCountry(res.data.country_id)
            // }
            // if responsible
          })
          .finally(this.endLoading)
      }
    },
    saveParentData () {
      if (!this.edit_id) {
        this.parentData.is_completed = true
        ParentRepository.saveParentData(this.parentData).then(() => {
          this.successMsg(this.$t('form.saved_successfully'))
          this.UpdateLocalStorageAuthUser('isParentCompletedProfile', true)

          // Update Names
          this.UpdateLocalStorageAuthUser(
            'displayName',
            `${this.parentData.first_name} ${this.parentData.middle_name} ${this.parentData.last_name} ${this.parentData.forth_name}`
          )
          this.UpdateLocalStorageAuthUser(
            'displayFirstAndSecondName',
            `${this.parentData.first_name} ${this.parentData.middle_name} `
          )

          if (this.isParentResponsible) this.$router.push({ name: 'completeParentCustodyData' })
          else this.$router.push({ name: 'ParentProfile' })

          window.location.reload()
        })
      } else {
        this.parentData.is_completed = true
        ParentRepository.updateParentById(this.edit_id, this.parentData).then(
          () => {
            this.successMsg(this.$t('form.saved_successfully'))
            this.$router.push({ name: 'all-parents' })
          }
        )
      }
    },
    ///////////////////////
    // OnChange
    ///////////////////////
    onChangeCountry () {
      this.showCitySelectLoader = true
      this.parentData.city_id = null
      this.getCitiesOfCountry(this.parentData.country_id).finally(() => {
        this.showCitySelectLoader = false
      })
    },
    ///////////////////////
    // Submit
    ///////////////////////
    submit () {
      this.$validator.validateAll().then((valid) => {
        if (valid) this.saveParentData()
        else this.errMsg()
      })
    }
  },

  async created () {
    await (this.getNationalities(), this.getReligions(), this.getCountries())
    this.getTelKeys()
    this.getMobileKeys()
    this.getFaxKeys()
    this.getParentData()
  }
}
</script>
<style lang="scss" scoped >
#longForm {
  > div {
    margin-bottom: 1.25rem;
  }
}
img {
  max-height: 20%;
  max-width: 20%;
  display: block;
  padding: 5px;
  border: 1px solid #ccc;
  margin: 1em;
}
.remove-teacher-attachment {
  cursor: pointer;
  svg {
    stroke: red;
    stroke-width: 2px;
  }
}
</style>
