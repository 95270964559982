<template>
  <div>
    <vs-button :to="{ name: 'InstitutionCreate' }">{{
      $t("InstitutionDetails.InstitutionCreate")
    }}</vs-button>
    <!-- <data-table :actions_prop="actions" :headers_prop="headers" list_url_prop="/institutions"/>-->
  </div>
</template>

<script>
//import DataTable from '@/components/general/DataTable.vue'
export default {
  // components: {DataTable},
  //comments: [DataTable],
  name: 'Details',
  data () {
    return {
      headers: [
        { text: 'InstitutionDetails.S', value: 'id' },
        { text: 'InstitutionDetails.LongName', value: 'round' },
        { text: 'InstitutionDetails.ShortName', value: 'total_days' },
        { text: 'InstitutionDetails.Logo', value: 'from' },
        { text: 'InstitutionDetails.Address', value: 'created_by' },
        { text: 'InstitutionDetails.Address', value: 'created_by' },
        { text: 'InstitutionDetails.Address', value: 'created_by' },
        { text: 'InstitutionDetails.ContactGeneral', value: 'contact' }
      ],
      actions: [
        { title: 'main.Edit', route_name: 'InstitutionEdit', icon: 'feather icon-edit-2' },
        { title: 'main.show', route_name: 'InstitutionShow', icon: 'feather icon-eye' }
      ],
      allNameData: { longName: null, ShortName: null },
      allAddressData: { desc: null },

      //locales:this.$store.getters['main/locales'],
      locales: ['ar', 'en'],
      error: '',
      lat: '',
      lon: '',
      image: null,
      imageUrl: null,
      contacts: {
        tel: [{ number: '', type: this.$t('InstitutionDetails.General') }, { number: '', type: this.$t('InstitutionDetails.Reception') }, { number: '', type: this.$t('InstitutionDetails.Admission')}, { number: '', type: this.$t('InstitutionDetails.Accounting')}],
        fax: [{ number: '', type: this.$t('InstitutionDetails.General') }, { number: '', type: this.$t('InstitutionDetails.Reception') }, { number: '', type: this.$t('InstitutionDetails.Admission')}, { number: '', type: this.$t('InstitutionDetails.Accounting')}],
        mobile: [{ number: '', type: this.$t('InstitutionDetails.General') }, { number: '', type: this.$t('InstitutionDetails.Reception') }, { number: '', type: this.$t('InstitutionDetails.Admission')}, { number: '', type: this.$t('InstitutionDetails.Accounting')}],
        email: [{ email: '', type: this.$t('InstitutionDetails.General') }, { email: '', type: this.$t('InstitutionDetails.Reception') }, { email: '', type: this.$t('InstitutionDetails.Admission')}, { email: '', type: this.$t('InstitutionDetails.Accounting')}]
      },
      loaded: false,
      markers: [{ position: { lat: 47.376332, lng: 8.547511 }, infoText: 'Marker 1' }],
      website: null,
      Facebook: null,
      Instagram: null,
      selected: []
    }
  },
  methods: {
    loadeInstatution () {
      InstatutionRepository
        .getInstitution()
        .then((response) => {
          if (response.institution.length >= 0) {
            this.allNameData.ShortName = response.institution[this.current_locale].short_name
            this.allNameData.longName = response.institution[this.current_locale].long_name
            this.allAddressData.desc = response.institution[this.current_locale].address
            this.image = response.institution.logo
            this.contacts = response.institution.contacts
            this.website = response.website
            this.Facebook = response.facebook
            this.Instagram = response.instagram

          }
        }).catch((error) => {
        })
    }
  },
  created () {
    this.loadeInstatution()
  }

}
</script>

<style scoped>
</style>
