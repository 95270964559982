<template>
  <ListPageWithCreateButton :showCreateBtn="false">
    <template slot="listDataContent">
      <Datatable :headers_prop="headers" list_url_prop="/meetings" />
    </template>
  </ListPageWithCreateButton>
</template>

<script>
import Datatable from '@/components/general/datatable/DataTable2.vue'

export default {
  name: 'SchedulerMeetingList',
  components: {
    Datatable
  },
  data() {
    return {
      headers: [
        {
          minWidth: 200,
          headerName: this.$t('admission.application_ID'),
          field: 'application_id',
          checkboxSelection: true,
          width: 200,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true
        },
        // {
        //   minWidth: 160,
        //   headerName: this.$t('basic.name'),
        //   field: 'application_data.first_name'
        // },
        // {
        //   minWidth: 160,
        //   headerName: this.$t('basic.email'),
        //   field: 'application_data.first_name'
        // },
        {
          minWidth: 160,
          headerName: this.$t('basic.date'),
          field: 'date'
        },
        {
          minWidth: 160,
          headerName: this.$t('basic.time'),
          field: 'time'
        },
        {
          minWidth: 160,
          headerName: this.$t('basic.gate'),
          field: 'gate_id' // TODO change it to name instead of id
        },
        {
          minWidth: 160,
          headerName: this.$t('basic.building'),
          field: 'building_id', // TODO change it to name instead of id
          width: 300
        },
        {
          minWidth: 160,
          headerName: this.$t('basic.meeting_type'),
          field: 'type_id',
          width: 300
        },

        {
          minWidth: 160,
          headerName: this.$t('basic.persons'),
          field: 'user_id',
          width: 300
        },
        {
          minWidth: 200,
          headerName: this.$t('basic.quick_or_detailed'),
          field: 'is_quick_meeting',
          width: 200
        }
      ]
    }
  },
  watch: {},
  created() {},
  mounted() {},
  beforeDestroy() {},

  methods: {}
}
</script>
