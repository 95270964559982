export default {
  attendance: 'Attendance',
  attendanceConsole: 'Attendance Console',
  insights: 'Insights',
  settings: 'Settings',
  attendanceRegisterationPeriod: 'Attendance Registeration Period',
  giveAccessTo: 'Give Access To Attendance',
  turnToUsualAbsenceAfter: 'Turn To Usual Absence After',
  turnToRecentUsualAbsenceAfter: 'Turn To Recent Usual Absence After',
  presentImprovement: 'Present Improvement Meter After',
  onMonthlyBasis: 'On Monthly Basis',
  maxDaysToSendReason: 'Max Days To Send Reason For Absence',
  daysBeingAbsent: 'Days Being Absent',
  daysAfterAbsencsDate: 'Days After Absence Date',
  turnToContinuousDaysAfter: 'Turn To Continuous Days Being Absence After',
  continuousDays: 'Continuous Days',
  maxAcceptedAbsenceDays: 'Max Accepted Absence Days Per Month',
  days: 'Days',
  maxAcceptedAbsenceTerm: 'Max Accepted Absence Days Per Term',
  perTerm: 'Per Term',
  className: 'Class Name',
  accpetTakingAttendanceDuringEClass: 'Accept Taking Attendance During E-Class',
  letTeachersUseTheApp: 'Let Teachers Use The App To Take Attendance',
  takeAttendance: 'Take Attendance',
  byStudent: 'Taking Attendance By Student',
  academicDate: 'Academic Date',
  studentId: 'Student ID',
  studentName: 'Student Name',
  division: 'Division',
  grade: 'Grade',
  class: 'Class',
  status: 'Status',
  name: 'Name',
  discipline: 'Discipline',
  school: 'School',
  classCode: 'Class Code',
  noClasses: 'There are no classes with this code',
  unfinished_Classes: 'unfinished Classes',
  There_are_no_unregistered_classes :'There are no unregistered classes',
  unfinished: 'unfinished',
  Change_Academic_Date: 'Change Academic Date',
  There_is_no_student_with_this_ID: 'There is no student with this ID',
  No_such_student: 'No such student',
  Online_Attendance_report:'Online Attendance report',
  Take_Attendance:'Take Attendance',
  By_Student:'By Student',
  By_Class: 'By Class',
  submit_attendance: 'submit attendance',
  open_attendance:'open attendance',
  Attendance_Submitted:'Attendance Submitted',
  Attendance_opened:'Attendance opened',
  download_csv: 'Download CSV',
  all_Students_are_present: 'All Students Are Present',
  attendance_register: 'Attendance Register',
  there_are_no_classes_with_this_code: 'There are no classes with this code',
  there_are_no_classes_with_this_name: 'There are no classes with this name',
  student_not_found: 'Student not found',
  there_is_no_student_with_this_name: 'There is no student with this name',
  class_not_found: 'Class not found',
  attendance_updated: 'Attendance Updated',
  attendance_update_failed:'Attendance Update Failed',
  all_student_should_have_a_status: 'All student should have a status',
  the_attendance_is_closed: 'The Attendance is Closed',
  do_you_confirm_changing_the_academic_date:'Do you confirm changing the academic date',
  close_attendance:'Close Attendance'
}
