import unitModal from '../../modals/create-unit/create-unit.vue'
import Service from '../../services/unit-planner'

export default {
  name: 'plannerContainer',
  components: { unitModal },
  data () {
    return {
      restService: Service,
      statusCard: [],
      currentAcademicYear: null,
      gridSettings: {
        columnDefs: [
          { headerName: 'ID', field: 'id' },
          { headerName: 'Unit Name', field: 'name' },
          { headerName: 'Grade', field: 'gradeValue' },
          { headerName: 'Subjects', field: 'subjectsValue', width: 300 },
          { headerName: 'Created', field: 'created_at' },
          { headerName: 'Type', field: 'type' },
          {
            headerName: 'Actions',
            field: 'status',
            renderActions: [
              {
                type: 'span',
                text: '',
                renderFieldValue: false,
                visibilityRole (data) {
                  switch (data) {
                  case 'Active':
                    return true

                  case 'Archived':
                    return false

                  case 'Inactive':
                    return true

                  case 'Draft':
                    return true
                  }
                },
                hasEvent: {
                  eventType: 'click',
                  eventName: 'AddUnitResources'
                },
                classList: ['feather', 'icon-file-plus', 'pointer'],
                attributes: [
                  { key: 'title', value: 'Add Resources' },
                  { key: 'style', value: 'font-size:20px; color:rgba(var(--vs-primary),1);' }
                ]
              },
              {
                type: 'span',
                text: '',
                renderFieldValue: false,
                visibilityRole (data) {
                  switch (data) {
                  case 'Active':
                    return true

                  case 'Archived':
                    return false

                  case 'Inactive':
                    return false

                  case 'Draft':
                    return true
                  }
                },
                hasEvent: {
                  eventType: 'click',
                  eventName: 'edit-teacher-unit'
                },
                classList: ['feather', 'icon-edit', 'pointer'],
                attributes: [
                  { key: 'title', value: 'Edit Unit' },
                  { key: 'style', value: 'font-size:20px; color:rgba(var(--vs-primary),1);' }
                ]
              },
              {
                type: 'span',
                text: '',
                renderFieldValue: false,
                visibilityRole (data) {
                  switch (data) {
                  case 'Active':
                    return true

                  case 'Archived':
                    return false

                  case 'Inactive':
                    return true

                  case 'Draft':
                    return true
                  }
                },
                hasEvent: {
                  eventType: 'click',
                  eventName: 'ArchiveUnit'
                },
                classList: ['feather', 'icon-archive', 'pointer'],
                attributes: [
                  { key: 'title', value: 'Archive Unit' },
                  { key: 'style', value: 'font-size:20px; color:rgba(var(--vs-primary),1);' }
                ]
              }
            ],
            cellRenderer: 'cellFunction'
          },
          {
            headerName: 'Status',
            field: 'status',
            renderActions: [
              {
                type: 'p',
                text: '',
                renderFieldValue: true,
                classList: [],
                classRole (data) {
                  switch (data) {
                  case 'Active':
                    return 'active'

                  case 'Archived':
                    return 'archived'

                  case 'Inactive':
                    return 'inactive'

                  case 'Draft':
                    return 'draft'
                  }
                },
                attributes: []
              }
            ],
            cellRenderer: 'cellFunction'
          }
        ],
        selectedColumns: null,
        rowData: [],
        compactViewColumns: ['id', 'name', 'gradeValue', 'type'],
        standardViewColumns: ['id', 'name', 'gradeValue', 'resources', 'status'],
        height: '375px',
        width: '100%'
      },
      showGrid: false,
      dataList: [],
      customEvents: [
        { eventName: 'AddUnitResources', callback: this.addUnitResources },
        { eventName: 'added-draft-unit', callback: this.handleDraftUnit },
        { eventName: 'edit-teacher-unit', callback: this.editUnit },
        { eventName: 'ArchiveUnit', callback: this.archiveUnit }
      ],
      paginationData:{}
    }
  },
  created () {
    ////////////////////////////////////////////////////
    //**          Custom Event Listener           ** //
    //////////////////////////////////////////////////
    this.customEvents.forEach(function (customEvent) {
      // eslint-disable-next-line no-undef
      $sharedEvent.listen(customEvent.eventName, customEvent.callback)
    })


    this.getCurrentAcademicYear()
      .then(() => {
        this.getTeacherUnits()
          .then(() => {
            this.formatData()
          })
      })
  },
  mounted () {
    /*this.$nextTick(()=>{
          //this.customSwitchAction();
        })*/
  },
  destroyed () {
    ////////////////////////////////////////////////////
    //**      Remove Custom Event Listener        ** //
    //////////////////////////////////////////////////
    this.customEvents.forEach(function (customEvent) {
      // eslint-disable-next-line no-undef
      $sharedEvent.off(customEvent.eventName, customEvent.callback)
    })
  },
  methods: {
    async getCurrentAcademicYear () {
      //** Show Loader **//
      this.$vs.loading()
      //** Get Menus Of OutLines **//
      await this.restService.getCurrentAcademicYear()
        .then(res => {
          this.currentAcademicYear = res
          //** Set Current Academic Year  **//
          this.$store.dispatch('currentAcademicYearPlanner', this.currentAcademicYear)
        })
        .catch(err => console.error(err))
      //** Hide Loader **//
      this.$vs.loading.close()
    },
    async getTeacherUnits (page = null) {
      //** Show Loader **//
      this.$vs.loading()

      //** Get Teacher Units **//
      await this.restService.getTeacherUnits(this.currentAcademicYear.id, page)
        .then(res => {
          const { data, ...paginationData } = res
          this.teacherUnits = data
          this.paginationData = paginationData

          // console.log('this.teacherUnits', this.teacherUnits)
        })
        .catch(err => console.error(err))
      //** Hide Loader **//
      this.$vs.loading.close()
    },

    formatData () {
      this.mappingGridData()
      this.setStatusCardsInfo()
      this.setLoggedInUser()

      /*this.$nextTick(()=>{
              document.body.appendChild(this.$refs.unitStatus);
            })*/
    },
    setStatusCardsInfo () {
      this.statusCard = [
        {
          name: 'All units',
          value: 'all-units',
          color: 'primary',
          icon: 'StarIcon',
          count: this.teacherUnits.length
        },
        {
          name: 'Draft Units',
          value: 'draft-units',
          color: 'RGB',
          icon: 'ClipboardIcon',
          count: this.teacherUnits.filter(item => item.status.name === 'Draft').length

        },
        {
          name: 'Active Units',
          value: 'active-units',
          color: 'danger',
          icon: 'EditIcon',
          count: this.teacherUnits.filter(item => item.status.name === 'Active').length
        },
        {
          name: 'Closed Units',
          value: 'closed-units',
          color: 'warning',
          icon: 'WatchIcon',
          count: this.teacherUnits.filter(item => item.type === 'Closed').length

        }
      ]
    },
    mappingGridData (dataList) {
      this.showGrid = false
      dataList = (dataList && dataList.length) ? dataList : this.cloneItem(this.teacherUnits)
      //** Empty Grid Data **//
      this.gridSettings.rowData.splice(0, this.gridSettings.rowData.length)

      dataList.forEach(data => {
        const obj = {
          id: data.id,
          name: data.name,
          grade: data.grade,
          gradeValue: data.grade.name,
          subjectsValue: data.subjects.map(item => item.name).join(', '),
          subjects: data.subjects,
          section: data.section.name,
          sectionObj: data.section,
          type: data.type,
          startDate: data.start_date,
          endDate: data.end_date,
          weeks: data.academic_weeks,
          hours: data.academic_hours,
          status: data.status.name,
          description: data.description,
          is_stand_alone: data.is_stand_alone,
          unit_academic_id: data.unit_academic_id
        }
        this.gridSettings.rowData.push(obj)
      })

      this.showGrid = true
    },
    filterGridData (dataList) {
      this.showGrid = false
      const filteredList = []
      dataList = (dataList) ? dataList : this.teacherUnits
      dataList.forEach(data => {
        const obj = {
          id: data.id,
          name: data.name,
          grade: data.grade,
          gradeValue: data.grade.name,
          subjectsValue: data.subjects.map(item => item.name).join(', '),
          subjects: data.subjects,
          section: data.section.name,
          sectionObj: data.section,
          type: data.type,
          startDate: data.start_date,
          endDate: data.end_date,
          weeks: data.academic_weeks,
          hours: data.academic_hours,
          status: data.status.name,
          description: data.description,
          is_stand_alone: data.is_stand_alone,
          unit_academic_id: data.unit_academic_id
        }
        filteredList.push(obj)
      })
      this.gridSettings.rowData.splice(0, this.gridSettings.rowData.length)
      this.gridSettings.rowData.push(...filteredList)
      this.showGrid = true
    },
    openCreateUnitModal (data) {
      // eslint-disable-next-line no-undef
      $sharedEvent.fire('openCreateUnitModal', data)
    },
    addUnitResources (data) {
      const obj = data.data
      console.log(obj)
      this.$store.dispatch('createUnit', obj)
      this.$router.push({ name: 'unitResources', query: { type: obj.section.toLowerCase() } })
    },
    handleDraftUnit (data) {
      const updateDraftIndex = this.teacherUnits.findIndex(item => item.id === data.id)
      if (updateDraftIndex !== -1) {
        this.teacherUnits.splice(updateDraftIndex, 1, data)
        this.filterGridData()
      } else {
        this.teacherUnits.unshift(data)
        this.mappingGridData()
      }
    },
    setLoggedInUser () {
      const loggedInUser = JSON.parse(localStorage.getItem('authUser'))
      this.$store.dispatch('setLoggedInUser', {
        id: loggedInUser.uid,
        name: loggedInUser.displayName,
        image: require('@/assets/images/avatar.png')
      })
    },
    editUnit (data) {
      // eslint-disable-next-line no-undef
      $sharedEvent.fire('openCreateUnitModal', data.data)
    },
    filterActions (filter) {
      let data
      switch (filter) {
      case 'all-units':
        this.filterGridData(this.teacherUnits)
        break
      case 'draft-units':
        data = this.teacherUnits.filter(item => item.status.name === 'Draft')
        this.filterGridData(data)
        break
      case 'active-units':
        data = this.teacherUnits.filter(item => item.status.name === 'Active')
        this.filterGridData(data)
        break
      case 'closed-units':
        data = this.teacherUnits.filter(item => item.type === 'Closed')
        this.filterGridData(data)
        break
      }
    },

    async archiveUnit (unit) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'primary',
        title: 'Confirm Archieve',
        text: 'You are about to archieve this unit',
        accept: () => this.confirmArchiveUnit(unit)
      })
    },
    async confirmArchiveUnit (unit) {
      //** Show Loader **//
      this.$vs.loading()

      await this.restService.changeUnitStatus(unit.data.id, { status: 'archived' })
        .then(res => {
          this.handleChangeStatusData(res.data)
        })
        .catch(err => {
          console.error(err)
          //** Hide Loader **//
          this.$vs.loading.close()

          this.$vs.notify({
            title: 'Creation Error',
            text: err.data.message,
            color: 'danger',
            position: 'bottom-right'
          })
        })
      //** Hide Loader **//
      this.$vs.loading.close()
    },
    handleChangeStatusData (data) {
      const updatedUnitIndex = this.teacherUnits.findIndex(item => item.id === data.id)
      if (updatedUnitIndex !== -1) {
        this.teacherUnits.splice(updatedUnitIndex, 1, data)
        this.filterGridData()
        this.$vs.notify({
          title: 'Unit Status',
          text: 'Changed Successfully',
          color: 'success',
          position: 'bottom-right'
        })
      }
    },

    getOffset (el) {
      /*const rect = el.getBoundingClientRect();
            return {
              left: rect.left + window.scrollX,
              top: rect.top + window.scrollY
            };*/
      const box = el.getBoundingClientRect()

      return {
        top: box.top + window.pageYOffset,
        right: box.right + window.pageXOffset,
        bottom: box.bottom + window.pageYOffset,
        left: box.left + window.pageXOffset
      }
    },
    setUnitStatus (data) {
      /*debugger
            this.$refs.unitStatus.style.display = "block";
            this.$refs.unitStatus.style.left = this.getOffset(data.event.target).left+"px";
            this.$refs.unitStatus.clientX.top = this.getOffset(data.event.target).top+"px";*/
    },
    selectStatus (stauts) {
      this.$refs.unitStatus.style.display = 'none'
    },
    customSwitchAction () {
      const switches = document.querySelectorAll('#activation-switch')
      const xc = this.$refs['activationSwitch']
      switches.forEach(ele => {
        ele.addEventListener('click', event => {
          if (!event.target.classlist.contain('vs-switch-active')) {
            event.target.classlist.add('vs-switch-active')
          } else {
            event.target.classlist.remove('vs-switch-active')
          }
        })
      })
    }
  }
}
