var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vs-dropdown',{staticClass:"cursor-pointer",attrs:{"vs-custom-content":"","vs-trigger-click":""}},[_c('vx-tooltip',{attrs:{"text":_vm.$t('basic.Notifications'),"position":"bottom"}},[_c('feather-icon',{staticClass:"cursor-pointer mt-1 mr-2",attrs:{"icon":"BellIcon","badge":_vm.unReadCount,"svgClasses":"h-6 w-6 cursor-pointer hover:text-primary","title":_vm.$t('basic.Notifications')}})],1),_c('vs-dropdown-menu',{staticClass:"notification-dropdown dropdown-custom vx-navbar-dropdown"},[_c('div',{staticClass:"notification-top text-center p-5 bg-primary text-white"},[_c('h3',{staticClass:"text-white"},[_vm._v(_vm._s(_vm.unReadCount)+" "+_vm._s(_vm.$t("basic.new")))]),_c('p',{staticClass:"opacity-75"},[_vm._v(_vm._s(_vm.$t("basic.app_notifications")))]),_c('div',{staticClass:"text-right"},[_c('p',{staticClass:"p-1 clear-all inline-block",on:{"click":function($event){return _vm.clearAll()}}},[_vm._v(" "+_vm._s(_vm.$t("basic.clear_all"))+" ")])])]),_c(_vm.scrollbarTag,{key:_vm.$vs.rtl,ref:"mainSidebarPs",tag:"component",staticClass:"scroll-area--nofications-dropdown p-0",attrs:{"settings":_vm.settings},on:{"ps-scroll-down":_vm.notificationsAreaScoll}},[_c('ul',{staticClass:"bordered-items",attrs:{"id":"contianter-notification"}},_vm._l((_vm.unreadNotifications),function(ntf,index){return _c('li',{key:index,staticClass:"flex justify-between px-4 py-4 notification cursor-pointer",on:{"click":function($event){return _vm.unread(ntf)}}},[_c('div',{staticClass:"flex items-start p-relative"},[_c('feather-icon',{attrs:{"icon":ntf.icon,"svgClasses":[
                `text-${ntf.category}`,
                'stroke-current mr-1 h-5 w-5',
              ]}}),_c('div',[_c('span',{staticClass:"font-medium block notification-title",class:[`text-${ntf.category}`]},[_vm._v(_vm._s(ntf.title))]),_c('small',{class:[ntf.is_read === 1 ? 'font-normal' : 'font-bold']},[_vm._v(_vm._s(ntf.msg))]),_vm._v("   "),(ntf.link)?[(ntf.link.includes('routeName-'))?[_c('a',{attrs:{"href.prevent":""},on:{"click":function($event){return _vm.handleLinkBeforeGo(ntf.link)}}},[_vm._v(_vm._s(_vm.$t("basic.here"))+" ")])]:[(ntf.link)?_c('a',{attrs:{"href":ntf.link}},[_vm._v(_vm._s(_vm.$t("basic.here"))+" ")]):_vm._e()]]:_vm._e()],2)],1),_c('small',{class:[
              ntf.is_read === 1 ? 'font-normal' : 'font-bold',
              `t-1   whitespace-no-wrap `,
            ]},[_vm._v(_vm._s(ntf.time))])])}),0)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }