<template>
  <div id="list_datatable" class="w-full">

    <div class="vx-row">
      <div :class="'vx-col w-full ' + (activeSidebar ? 'lg:w-3/3' : '')">
        <data-table
          :csv_url="'section/export/section'"
          :pdf_url="'sections/pdf'"
          :headers_prop="headers"
          list_url_prop="/section"
        >
          <template slot="center_content">
            <!--  TODO: add the appropriate export element -->
            <!-- <h3>Center Content</h3> -->
          </template>
        </data-table>
      </div>

      <!-- Filter Sidebar -->

      <div class="vx-col lg:w-1/3 w-full" v-if="activeSidebar === true">
        <round-filter
          :filters_prop="filters"
          :filters_choosen_prop="filter_choosen"
        ></round-filter>
      </div>
    </div>
  </div>
</template>
<script>
import DataTable from '@/components/general/datatable/DataTable2.vue'
import RoundFilter from '@/components/general/RoundFilter.vue'
import StatusTabs from './StatusTabs.vue'

export default {
  name: 'DivisionManager',
  components: { DataTable, RoundFilter, StatusTabs },
  comments: [DataTable],

  data () {
    return {
      headers: [
        {
          minWidth: 160,
          headerName: this.$t('RoundManager.S'),
          field: 'id',
          checkboxSelection: true,
          width: 200,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true
        },
        // { headerName: this.$t('RoundManager.Round'), field: 'round', width:200},
        {
          minWidth: 160,
          headerName: this.$t('division.Name'),
          field: 'name'
        },
        ///{ headerName: this.$t('RoundManager.From'), field: 'from', width:290 },

        {
          minWidth: 160,
          headerName: this.$t('division.DivisionName'),
          field: 'division_name',
          width: 240
          //  cellRendererFramework: 'CellRendererStatus',
          // cellRendererParams : {
          //   status_prop:this.status
          // }
        },
        {
          minWidth: 160,
          headerName: this.$t('division.status'),
          field: 'status',
          width: 290,
          cellRendererFramework: 'CellRendererStatus'

        },

        {
          minWidth: 160,
          filter: false,
          headerName: this.$t('basic.actions'),
          field: 'transactions',
          width: 240,
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            actions_prop: [
              {
                title: this.$t('basic.show'),
                route_name: 'ShowSection',
                parameter_name: 'id',
                icon: 'EyeIcon',
                navigate_action: true
              },
              {
                title: this.$t('main.Edit'),
                route_name: 'SectionEdit',
                parameter_name: 'id',
                icon: 'Edit3Icon',
                navigate_action: true
              },
              {


              }
            ],
            status: [
              {
                id: 0,
                title: this.$t('status.new'),
                value: 'new',
                icon: 'StarIcon',
                count: '22',
                link: '#!'
              },
              {
                id: 1,
                title: this.$t('status.shortlisted'),
                value: 'shortlisted',
                icon: 'ClipboardIcon',
                count: '22',
                link: '#!'
              },
              {
                id: 2,
                title: this.$t('status.on_assessment'),
                value: 'on_assessment',
                icon: 'UserCheckIcon',
                count: '22',
                link: '#!'
              },
              {
                id: 3,
                title: this.$t('status.waiting_list'),
                value: 'w_list',
                icon: 'WatchIcon',
                count: '22',
                link: '#!'
              },
              {
                id: 4,
                title: this.$t('status.disqualified'),
                value: 'disqualified',
                icon: 'XCircleIcon',
                count: '22',
                link: '#!'
              },
              {
                id: 5,
                title: this.$t('status.qualified'),
                value: 'qualified',
                icon: 'CheckCircleIcon',
                count: '22',
                link: '#!'
              }
            ]
          }
        }
      ],
      filter_choosen: [],
      activePrompt: false,
      selectedUsers: [],
      export_file: false,
      activeSidebar: false
    }
  },
  methods: {

  }
}
</script>


<style scoped lang="scss">
#round_buttons {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  min-width: 155px;
  height: fit-content;
  z-index: 999;
  transform: rotate(90deg) translateY(-140px);
  button {
    display: inline-block;
    margin-left: 6px;
  }
}
</style>
