/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from 'axios'
import globalStore from '../store'

export default {
  getLocales ({commit}) {
    axios.get('configuration/locals')
      .then(res => {
        commit('setLocales', res)
      })
      .catch()
  },
  getInstitutionData (store) {
    const
      authUser = globalStore.getters['auth/authUser'],
      isSMDUser = authUser?.userRole === 'SMD',
      branchId = authUser?.school_id,
      updateAssets = (info) => {
        store.commit('Update_institution_data', info)
        if (info.brandColor) globalStore.commit('UPDATE_PRIMARY_COLOR', info.brandColor)

      // broken imgs
      // document.images.forEach(img => {
      //   img.onerror = function () { this.src = 'logo.png' }
      // })}
      }
    return new Promise((resolve, reject) => {
      axios.get('/institutiondata')
        .then(async (res) => {
          const info = res.institution

          if (!isSMDUser) {
            await axios.get('/user-school')
              .then((res) => {
                const branchData = res.data
                for (const key in branchData) {
                  if (info[key]) info[key] = branchData[key]
                }
                info.imageURL = branchData.imageUrl
                info.logo = branchData.logo
              })
          }
          updateAssets(info)
          resolve(info)
        })
        .catch(reject)
    })
  }
}
