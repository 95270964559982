<template>
  <div id="page-user-list">
    <div class="vx-card p-3 pt-6 pb-6 md:p-6">
      <div
        class="flex flex-wrap items-center justify-center md:justify-between"
      >
        <div
          class="flex-grow flex-wrap flex mb-3 md:justify-start justify-center"
        >
          <slot name="left_content">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="
                  p-4
                  border border-solid
                  d-theme-border-grey-light
                  rounded-full
                  d-theme-dark-bg
                  cursor-pointer
                  flex
                  items-center
                  justify-between
                  font-medium
                "
              >
                <span class="mr-2"
                  >{{
                    currentPage * paginationPageSize - (paginationPageSize - 1)
                  }}
                  -
                  {{
                    list_length - currentPage * paginationPageSize > 0
                      ? currentPage * paginationPageSize
                      : list_length
                  }}
                  of {{ list_length }}</span
                >
                <!-- <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" /> -->
              </div>
            </vs-dropdown>
          </slot>
        </div>

        <!-- Centerd Content (View Filters) -->
        <div class="flex-grow justify-center flex-wrap flex mb-3">
          <slot name="center_content"> </slot>
        </div>

        <!-- Right content (Se+arch && action ) -->
        <div
          class="
            justify-end
            flex-wrap flex
            mb-3
            md:justify-end
            justify-center
            flex-grow
          "
        >
          <slot name="right_content">
            <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV  sm:w-auto w-full-->

            <!-- ACTION - DROPDOWN  dropdown-left-->
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="
                  p-3
                  shadow-drop
                  rounded-lg
                  d-theme-dark-light-bg
                  cursor-pointer
                  flex
                  items-end
                  justify-center
                  text-lg
                  font-medium
                  w-32
                "
              >
                <span class="mr-2 leading-none">Actions</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>

              <vs-dropdown-menu>
                <vs-dropdown-item @click="getExportToExcelFileLink">
                  <span class="flex items-center">
                    <feather-icon
                      icon="SaveIcon"
                      svgClasses="h-4 w-4"
                      class="mr-2"
                    />
                    <span>CSV</span>
                  </span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="exportToPDF()">
                  <span class="flex items-center">
                    <feather-icon
                      icon="BookOpenIcon"
                      svgClasses="h-4 w-4"
                      class="mr-2"
                    />
                    <span>PDF</span>
                  </span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </slot>
        </div>
      </div>

      <!-- AgGrid Table -->
      <div id="grid-wrapper">
        <ag-grid-vue

          :appStatus="appStatus"
          ref="agGridTable"
          :components="components"
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="headers"
          :defaultColDef="defaultColDef"
          :rowData="list"
          rowSelection="single"
          rowMultiSelectWithClick="false"
          colResizeDefault="shift"
          :resizable="true"
          :animateRows="true"
          :floatingFilter="false"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          :enableRtl="$vs.rtl"
          @grid-ready="onGridReady"
          @row-selected="getSelectedRows"
          @first-data-rendered="onFirstDataRendered"
          @grid-size-changed="onGridSizeChanged"
        ></ag-grid-vue>
      </div>
      <vs-pagination
        :total="totalPages"
        :max="7"
        v-model="currentPage"
        v-if="totalPages > 0"
      />

      <!-- Schedule Meeting popup -->
      <scheduleMeeting
        :sheduleMeetingPopupActive="sheduleMeetingPopupActive"
        :applicationId="application_id"
        v-if="sheduleMeetingPopupActive"
        @closeSheduleMeetingPopup="sheduleMeetingPopupActive = false"
      ></scheduleMeeting>
    </div>
    <div
      ref="dataTableLoader"
      id="dataTableLoader"
      class="vs-con-loading__container"
      v-show="loading"
    ></div>
  </div>
</template>

<script>
import scheduleMeeting from './ScheduleMeeting'

import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'

import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const ApplicationRepository = RepositoryFactory.get(
  'AdmissionModule',
  'applications'
)
import applicationRepository from '../../../Repositories/applicationRepository'
const EvaluationRepository = RepositoryFactory.get(
  'AdmissionModule',
  'evaluations'
)

// Cell Renderer
import CellRendererStatus from '@/components/general/datatable/cell-renderer/CellRendererStatus'
import CellRendererActions from '@/modules/admission/cell-renderer/CellRendererActionsApp.vue'
import _ from 'lodash'

const DataTableRepository = RepositoryFactory.get('General', 'dataTable')

export default {
  name: 'ApplicationTable',
  props: {
    propSearch:{
      type: String,
      required: false
    },
    filter_type: {
      type: String,
      required: false
    },
    headers_prop: {
      type: Array,
      required: true
    },
    list_url_prop: {
      type: String,
      required: true
    },
    export_prop: {
      type: Boolean,
      default: true
    },
    side_filters: {
      type: Object
    }

  },
  components: {
    AgGridVue,
    vSelect,
    // Cell Renderer
    CellRendererStatus,
    CellRendererActions,
    scheduleMeeting
  },
  data () {
    return {

      SelectedAppDataOption: 'All',
      appStatus: '',
      headerTitle: [],
      headerVal: [],
      popupActive: false,
      headers: this.headers_prop,

      selected_data: {
        birth_date: '',
        student: {
          name: {
            first_name: '',
            middle: '',
            last: ''
          },

          email: '',
          birth_date: '',
          gender: 'male',
          religion: '',

          //   nationalities
          nationalities: [
            {
              id: 0,
              value: 'egyptian'
            }
          ],

          //   Address
          address: {
            city: '',
            country: 'egypt',
            district: '',
            street: ''
          },
          mobile: {
            key: '010',
            num: ''
          },
          tel: [
            {
              id: 0,
              key: '02',
              num: ''
            }
          ],
          official_email: '',

          //   School data
          id_num: '',
          school: '',
          division: '',
          grade: '',
          school_year: '',
          school_month_start: ''
        },
        // Student_info
        student_info: {
          first_join: 'first_join_true',
          schools: [
            {
              id: 0,
              name: '',
              division: '',
              grade: '',
              year: '',
              month_start: '',
              leaving_reason: ''
            }
          ],
          languages: {
            mother: '',
            common: '',
            first_foregin: ''
          },
          child_exempted: 'child_exempted_true',
          child_skipped: {
            do: 'child_skipped_false',
            grade: ''
          },
          child_repeat: {
            do: 'child_repeat_false',
            grade: ''
          },
          support_services: {
            do: 'support_services_false',
            details: ''
          },
          applying_reason: ''
        },
        //Father
        father: {
          name: {
            first: '',
            middle: '',
            last: ''
          },

          email: '',
          birth_date: '',
          religion: '',
          id_num: '',
          //   nationalities
          nationalities: [
            {
              id: 0,
              value: 'egyptian'
            }
          ],

          //   Address
          sameAddress: true,
          address: {
            city: '',
            country: 'egypt',
            district: '',
            street: ''
          },
          mobile: {
            key: '010',
            num: ''
          },
          tel: [
            {
              id: 0,
              key: '02',
              num: ''
            }
          ],
          sameOfficalEmail: true,
          official_email: '',

          school: '',
          university: '',
          occupation: '',
          employer: '',
          business_type: '',
          work: {
            address: '',
            email: '',
            mobile: {
              key: '010',
              num: ''
            },
            tel: [
              {
                id: 0,
                key: '02',
                num: ''
              }
            ],
            fax: [
              {
                id: 0,
                key: '02',
                num: ''
              }
            ]
          },
          school_alumni: 'school_alumni_true'
        },
        //mother
        mother: {
          name: {
            first: '',
            middle: '',
            last: ''
          },

          email: '',
          birth_date: '',
          religion: '',
          id_num: '',

          //   nationalities
          nationalities: [
            {
              id: 0,
              value: 'egyptian'
            }
          ],

          //   Address
          sameAddress: true,
          address: {
            city: '',
            country: 'egypt',
            district: '',
            street: ''
          },
          mobile: {
            key: '010',
            num: ''
          },
          tel: [
            {
              id: 0,
              key: '02',
              num: ''
            }
          ],
          sameOfficalEmail: true,
          official_email: '',
          school: '',
          university: '',
          occupation: '',
          employer: '',
          business_type: '',
          work: {
            address: '',
            email: '',
            mobile: {
              key: '010',
              num: ''
            },
            tel: [
              {
                id: 0,
                key: '02',
                num: ''
              }
            ],
            fax: [
              {
                id: 0,
                key: '02',
                num: ''
              }
            ]
          },
          school_alumni: 'school_alumni_true'
        },
        // Siblings
        sibling_data: {
          have: 'siblings_have_true',
          siblings: [
            {
              id: 0,
              name: {
                first: '',
                middle: '',
                last: ''
              },

              email: '',
              sameOfficalEmail: true,
              birth_date: '',
              gender: 'male',
              religion: '',

              //   nationalities
              nationality: 'egyptian',

              //   Address
              sameAddress: true,
              address: {
                city: '',
                country: 'egypt',
                district: '',
                street: ''
              },
              mobile: {
                key: '010',
                num: ''
              },
              tel: [
                {
                  id: 0,
                  key: '02',
                  num: ''
                }
              ],
              //   School data
              at_school: 'at_school_true',
              school_data: {
                have_id: 'have_id_true',
                id_num: '',
                name: {
                  first: '',
                  last: '',
                  middle: ''
                },
                division: '',
                grade: '',
                school_year: ''
              }
            }
          ]
        },
        // Martial Data
        marital_data: {
          status: 'married_couple', //.. married_couple || divorced || widowed_father || widowed_mother
          custody: {
            who: 'others', //.. custody_widowed_father || custody_widowed_mother || custody_divorced_mother || custody_divorced_father || others
            others: {
              name: {
                first: '',
                middle: '',
                last: ''
              },

              email: '',
              sameOfficalEmail: true,
              birth_date: '',
              gender: 'male',
              religion: '',

              //   nationality
              nationality: '',

              //   Address
              sameAddress: true,
              address: {
                city: '',
                country: 'egypt',
                district: '',
                street: ''
              },
              mobile: {
                key: '010',
                num: ''
              },
              tel: [
                {
                  id: 0,
                  key: '02',
                  num: ''
                }
              ],
              relationship: ''
            }
          }
        },
        general: {
          bus_transportation: ''
        }
      },
      list: [],
      export_file: this.export_prop,

      gridApi: null,
      gridColumnApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: false,
        resizable: true,
        suppressMenu: true,
        editable: false,
        filter: true,
        enableBrowserTooltips:true

      },
      searchQuery: '',

      totalPages: 0,
      currentPage: 1,
      list_length: 0,
      showSchedularMeetings: false,
      // Cell Renderer Components
      components: {
        CellRendererStatus,
        CellRendererActions
      },
      // shedule Meeting Popup
      sheduleMeetingPopupActive: false,
      application_id: null,
      // loading
      loading: false
    }
  },
  watch: {
    roleFilter (obj) {
      this.setColumnFilter('role', obj.value)
    },
    statusFilter (obj) {
      this.setColumnFilter('status', obj.value)
    },
    isVerifiedFilter (obj) {
      const val =
        obj.value === 'all' ? 'all' : obj.value === 'yes' ? 'true' : 'false'
      this.setColumnFilter('is_verified', val)
    },
    departmentFilter (obj) {
      this.setColumnFilter('department', obj.value)
    },
    currentPage (page) {
      const page_link = `?page=${page}`
      this.sideFilterGetData(page_link)
    },
    filter_type (newVal) {
      this.setColumnFilter('', newVal)
    },
    side_filters: {
      handler (newVal) {
        this.UpdatefiltersData('')
      },
      deep: true
    },
    SelectedAppDataOption () {
      this.changeAppDataOption()
    },
    showSchedularMeetings (val) {
      // if (val) this.getSchedularMeetings('')
      // else this.sideFilterGetData('')
    },
    propSearch () {
      this.UpdatefiltersData('')
    }
  },
  computed: {
    list_url () {
      return  this.list_url_prop
    },
    paginationPageSize: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetPageSize()
        else return 10
      },
      set (v) {
        return v
      }
    },
    shortlist () {
      return this.selected_data.status === 'new'
    },
    reject () {
      return (
        this.selected_data.status === 'new' ||
        this.selected_data.status === 'shortlisted'
      )
    },
    archive () {
      return true
    },
    to_assess () {
      return this.selected_data.status === 'shortlisted'
    },
    to_reassess () {
      return (
        this.selected_data.status === 'waiting List' ||
        this.selected_data.status === 'disqualified'
      )
    },
    disqualify () {
      return (
        this.selected_data.status === 'waiting list' ||
        this.selected_data.status === 'on Assessment'
      )
    },
    waitingList () {
      return this.selected_data.status === 'on Assessment'
    },
    qualify () {
      return (
        this.selected_data.status === 'on Assessment' ||
        this.selected_data.status === 'waiting list'
      )
    },
    evaluationCard () {
      return (
        this.selected_data.status === 'on Assessment' ||
        this.selected_data.status === 'waiting list'
      )
    },
    enabledEnroll () {
      return this.selected_data.status === 'qualified'
    },
    withdraw () {
      return this.selected_data.status === 'qualified'
    },
    // scheduleMeeting () {
    //   return (
    //     this.selected_data.status ===  'qualified' ||
    //     this.selected_data.status ===  'enrolled'
    //   )
    // },
    reQualify () {
      return (
        this.selected_data.status === 'declined' ||
        this.selected_data.status === 'withdrawn'
      )
    },
    decline () {
      return (
        (this.selected_data.status === 'qualified' ||
          this.selected_data.status === 'withdrawn') &&
        this.params.colDef.cellRendererParams.enable_enroll
      )
    },
    schoolName () {
      return this.$store.state.institutionName
    }
  },
  methods: {
    ///////////////////////////////
    // loading
    ///////////////////////////////
    startLoading () {
      this.loading = true
      this.VSLoading(this.$refs.dataTableLoader)
    },
    endLoading () {
      this.stopVSLoading(this.$refs.dataTableLoader)
      this.loading = false
    },
    changeAppDataOption () {
      switch (this.SelectedAppDataOption) {
      case 'All':
        this.allAppData()
        break
      case 'Viewed':
        this.ViewAppData()
        break
      case 'Not Viewed':
        this.NotViewedAppData()
        break
      }
    },
    allAppData () {
      // this.list_url = '/application'
      this.sideFilterGetData('')
    },
    updateAllAppData (filterVal) {
      // this.list_url = '/application'
      this.SelectedAppDataOption = 'All'
      this.getListDataFilter('', filterVal)
    },
    UpdatefiltersData: _.debounce(function () {
      this.sideFilterGetData()
    }, 700),
    setListTooltips (data) {
      data.forEach(e => {
        const field = this.headers.find(h => e[h.field]).field
        e.tooltipField = field
        e.tooltipComponentParams = {text:e[field]}
      })
      return data
    },
    sideFilterGetData (page = '') {

      this.SelectedAppDataOption = 'All'
      applicationRepository
        .applicationsFilter(page, this.side_filters, this.propSearch)
        .then(res => {
          this.list = this.setListTooltips(res.data.data)
          this.totalPages = res.data.last_page
          this.paginationPageSize = res.data.per_page
          this.currentPage = res.data.current_page
          this.list_length = res.data.total
        })
    },


    ViewAppData () {
      this.list_url = `/application/viewed/${1}`
      applicationRepository.viewApplications(this.filter_type).then(res => {
        this.list = this.setListTooltips(res.data.data)
        this.totalPages = res.data.last_page
        this.paginationPageSize = res.data.per_page
        this.currentPage = res.data.current_page
        this.list_length = res.data.total_pages
      })
    },
    NotViewedAppData () {
      this.list_url = `/application/viewed/${0}`
      applicationRepository.notViewApplications(this.filter_type).then(res => {
        this.list = this.setListTooltips(res.data.data)
        this.totalPages = res.data.total_pages
        this.paginationPageSize = res.data.per_page
        this.currentPage = res.data.current_page
        this.list_length = res.data.total_pages
      })
      // this.list_url = '/application'
    },
    deleteApp (action) {
      const id = this.selected_data.id
      ApplicationRepository.delete(id)
        .then(() => {
          this.showActionSuccess(action)
          this.$root.$emit('row:status')
          this.params.data.splice(id, 1)
        })
        .catch()
    },
    enroll () {
      this.student.name = `${this.student.first_name} ${this.student.last_name}`
      this.student.email = this.student.student_email
      applicationRepository.enrollStudent(this.student)

      this.changeAppStatus('edit', 6)
    },
    navigateToEvaluationCard () {
      const applicationId = this.selected_data.id
      EvaluationRepository.getEvaluation(applicationId).then(
        res => {
          if (res.data.is_evaluated === true) {
            this.$router.push({
              name: 'edit-evaluation',
              params: { id: applicationId }
            })
          } else {
            this.$router.push({
              name: 'create-evaluation',
              params: { id: applicationId }
            })
          }
        },
        error => {
          this.$router.push({
            name: 'create-evaluation',
            params: { id: applicationId }
          })
        }
      )
    },
    changeAppStatus (action, status) {
      const id = this.selected_data.id
      ApplicationRepository.changeStatus(id, status)
        .then(() => {
          if (status === 0) {
            this.appStatus = 'new'
            this.selected_data.status = 'new'
          }
          if (status === 1) {
            this.appStatus = 'shortlisted'
            this.selected_data.status = 'shortlisted'
          }
          if (status === 2) {
            this.appStatus = 'on Assessment'
            this.selected_data.status = 'on Assessment'
          }
          if (status === 3) {
            this.appStatus = 'waiting list'
            this.selected_data.status = 'waiting list'
          }
          if (status === 4) {
            this.appStatus = 'qualified'
            this.selected_data.status = 'qualified'
          }
          if (status === 5) {
            this.appStatus = 'Schedule a meeting'
            this.selected_data.status = 'Schedule a meeting'
          }
          if (status === 6) {
            this.appStatus = 'Enrolled'
            this.selected_data.status = 'Enrolled'
          }
          if (status === 7) {
            this.appStatus = 'withdrawn'
            this.selected_data.status = 'withdrawn'
          }
          if (status === -1) {
            this.appStatus = 'disqualified'
            this.selected_data.status = 'disqualified'
          }
          if (status === -2) {
            this.appStatus = 'rejected'
            this.selected_data.status = 'rejected'
          }
          if (status === -3) {
            this.appStatus = 'declined'
            this.selected_data.status = 'declined'
          }
          this.showActionSuccess(action)
          this.sideFilterGetData('')
          this.$root.$emit('row:status')
        })
        .catch()
    },
    showActionSuccess (action) {
      this.successMsg(
        `${this.$t('basic.the_selected_row_was_successfully')} ${action}`,
        `${this.$t('basic.row')} ${action}`
      )
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        if (j === 'student.first_name') {
          v[j] = v.student.first_name
        }
        if (j === 'student.division') {
          v[j] = v.student.division
        }
        if (j === 'father.contact_mobile') {
          v[j] = v.father.contact_mobile
        }
        if (j === 'student.year_group') {
          v[j] = v.student.year_group
        }
        // Add col name which needs to be translated
        // if (j ===  'timestamp') {
        //   return parseTime(v[j])
        // } else {
        //   return v[j]
        // }

        return v[j]
      })
      )
    },
    getCurrentPageData () {
      const page_link = `?page=${this.currentPage}`
      this.sideFilterGetData(page_link)
    },
    ///////////start CSV////////////

    exportToExcel () {
      this.headerTitle = []
      this.headerVal = []
      import('@/vendor/Export2Excel').then(excel => {
        this.headers.forEach(element => {
          if (element.headerName != 'Actions') {
            this.headerTitle.push(element.headerName)
            this.headerVal.push(element.field)
          }
        })
        const list = this.list
        const data = this.formatJson(this.headerVal, list)
        excel.export_json_to_excel({
          header: this.headerTitle,
          data
        })
        this.clearFields()
      })
    },
    clearFields () {
      this.fileName = ''
      this.cellAutoWidth = true
      this.selectedFormat = 'xlsx'
    },
    getExportToExcelFileLink () {
      applicationRepository
        .applicationsFilterExport('', this.side_filters)
        .then(this.startDownloadExporting)
        .catch(this.errMsg)
    },
    ///////////End CSV////////////
    //////////////////////////////
    //PDF
    //////////////////////////////

    startDownloadExporting (url) {
      this.successMsg(this.$t('form.Started_Downloading'))

      const downloadUrl = url.toString(),
        fileName = downloadUrl.split('/').slice(-1),
        link = document.createElement('a')

      link.href = downloadUrl
      link.setAttribute('download', fileName)
      link.setAttribute('target', '_blank')
      document.body.appendChild(link)
      link.click()
      link.remove()
    },
    exportToPDF () {
      // const
      //   schools = this.side_filters.schools,
      //   divisions = this.side_filters.divisions,
      //   grades = this.side_filters.grades,
      //   gender = this.side_filters.gender,
      //   religion = this.side_filters.religion,
      //   nationality = this.side_filters.nationality,
      //   language = this.side_filters.language,
      //   second_language = this.side_filters.second_language,
      //   alumni = this.side_filters.alumni,
      //   sibling = this.side_filters.sibling,
      //   bus_required = this.side_filters.bus_required,
      //   has_special_needs = this.side_filters.has_special_needs,
      //   status = this.filter_type,
      //   //////////////////////////////////////
      //   //Params
      //   /////////////////////////////////////
      //   params = (schools.length ? `schools[0]=[${schools}]&` : '') +
      //   (divisions.length ? `divisions[0]=[${divisions}]&` : '') +
      //   (grades.length ? `grades[0]=[${grades}]&` : '') +
      //   (gender.length ? `gender[0]=[${gender}]&` : '') +
      //   (religion.length ? `religion[0]=[${religion}]&` : '') +
      //   (nationality.length ? `nationality[0]=[${nationality}]&` : '') +
      //   (language.length ? `language[0]=[${language}]&` : '') +
      //   (second_language.length ? `second_language[0]=[${second_language}]&` : '') +
      //   (alumni.length ? `alumni[0]=[${alumni}]&` : '') +
      //   (sibling.length ? `sibling[0]=[${sibling}]&` : '') +
      //   (bus_required.length ? `bus_required[0]=[${bus_required}]&` : '') +
      //   (has_special_needs.length ? `has_special_needs[0]=[${has_special_needs}]&` : '') +
      //   (status.length ? `status[0]=["${status}"]` : ''),

      //   path_url = axios.defaults.baseURL,
      //   url = `ApplicationsPDF/?${params}`

      // window.location.href = `${path_url}${url}`
      //    axios.get('/ApplicationsPDF', { params: { schools,
      //     divisions,
      //     grades,
      //     gender,
      //     religion,
      //     nationality,
      //     language,
      //     second_language,
      //     alumni,
      //     sibling,
      //     bus_required,
      //     has_special_needs,
      //     status} })
      // }
      // window.location.href = `${path_url}${url}`
      applicationRepository.getPDFDownloadLink(this.side_filters).then(res => {
        this.startDownloadExporting(res)
      })
    },
    //////////////////////////////
    setColumnFilter (column, val) {
      const filter = this.gridApi.getFilterInstance(column)
      let modelObj = null

      if (val !== 'all') {
        modelObj = { type: 'equals', filter: val }
      }

      filter.setModel(modelObj)
      this.gridApi.onFilterChanged()
    },
    resetColFilters () {
      // Reset Grid Filter
      this.gridApi.setFilterModel(null)
      this.gridApi.onFilterChanged()

      // Reset Filter Options
      this.roleFilter = this.statusFilter = this.isVerifiedFilter = this.departmentFilter = {
        label: 'All',
        value: 'all'
      }

      this.$refs.filterCard.removeRefreshAnimation()
    },
    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
      this.setColumnFilter('', val)
    },
    // Get Selected Data
    onGridReady (params) {
      this.gridApi = params.api
      this.columnApi = params.columnApi
    },
    getSelectedRows () {
      const data = this.gridApi.getSelectedRows()
      this.selected_data = data.map(elm => elm)[0]
    },
    // Fit columns width
    onFirstDataRendered () {
      this.gridApi.sizeColumnsToFit()
    },
    getListData (page) {
      this.startLoading()
      DataTableRepository.listData(this.list_url + page)
        .then(res => {
          this.list = this.setListTooltips(res.data.data)
          this.totalPages = res.data.total_pages
          this.paginationPageSize = res.data.per_page
          this.currentPage = res.data.current_page
          this.list_length = res.data.total
          this.$vs.loading.close()
        })
        .finally(this.endLoading)
    },
    getListDataFilter (page, filter) {
      this.startLoading()

      DataTableRepository.listData(this.list_url + page)
        .then(res => {
          this.list = this.setListTooltips(res.data.data)
          this.totalPages = res.data.total_pages
          this.paginationPageSize = res.data.per_page
          this.currentPage = res.data.current_page
          this.list_length = res.data.total
        })
        .then(() => {
          this.setColumnFilter('status', filter)
        })
        .finally(this.endLoading)
    },
    getSchedularMeetings () {
      this.startLoading()

      DataTableRepository.listData('/meetings')
        .then(res => {
          this.list = this.setListTooltips(res.data.data)
          this.totalPages = res.data.total_pages
          this.paginationPageSize = res.data.per_page
          // this.currentPage = res.data.current_page
          this.list_length = res.data.total
        })
        .finally(this.endLoading)
    },
    getListDataAfterChange () {
      this.startLoading()

      DataTableRepository.listData(`${this.list_url}?page=${this.currentPage}`)
        .then(res => {
          this.list = this.setListTooltips(res.data.data)
          this.totalPages = res.data.total_pages
          this.paginationPageSize = res.data.per_page
          this.currentPage = res.data.current_page
          this.list_length = res.data.total_pages
          this.$root.$emit('row:status')
        })
        .then(() => {
          this.setColumnFilter('status', this.filter_type)
        })
        .finally(this.endLoading)
    },
    onGridSizeChanged (params) {
      // const gridWidth = document.getElementById("grid-wrapper").offsetWidth;
      const columnsToShow = []
      const columnsToHide = []
      let totalColsWidth = 0
      const allColumns = params.columnApi.getAllColumns()
      for (let i = 0; i < allColumns.length; i++) {
        const column = allColumns[i]
        totalColsWidth += column.getMinWidth()
        // if (totalColsWidth > gridWidth) {
        //   columnsToHide.push(column.colId);
        // } else {
        //   columnsToShow.push(column.colId);
        // }
      }
      params.columnApi.setColumnsVisible(columnsToShow, true)
      params.columnApi.setColumnsVisible(columnsToHide, false)
      params.api.sizeColumnsToFit()
    },
    getSelectedApplicationData (id) {
      this.selected_data = this.list.find(el => el.id === id)
    }
  },
  mounted () {
    this.gridApi = this.gridOptions.api

    this.gridColumnApi = this.gridOptions.columnApi

    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(
        '.ag-header-container'
      )
      header.style.left = `-${String(
        Number(header.style.transform.slice(11, -3)) + 9
      )}px`
    }
  },
  created () {
    this.sideFilterGetData('')
    this.$root.$on('row:status', () => {
      this.sideFilterGetData('')
    })
    this.$root.$on('row:makeScheduleMeeting', val => {
      this.application_id = val
      this.sheduleMeetingPopupActive = true
    })
    this.gridOptions = {
      suppressRowClickSelection: true,
      onCellClicked: e => {
        if (e.column.colId !== 'transactions') {
          // cell is from non-select column
          e.node.setSelected(true)
        }
      }
    }
  },
  beforeDestroy () {
    this.$root.$off('row:makeScheduleMeeting')
  }
}
</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}

.status-position {
  position: relative;
  bottom: 82px;
}

.dropdown-p {
  // position: relative;
  // bottom: 690px;
  // left: 210px;
  margin-left: 9px;
}
.dropdown-left {
  position: absolute !important;
  left: 120px;
}
</style>
