import { render, staticRenderFns } from "./StatusCounter.vue?vue&type=template&id=6f13f841&scoped=true"
import script from "./StatusCounter.vue?vue&type=script&lang=js"
export * from "./StatusCounter.vue?vue&type=script&lang=js"
import style0 from "./StatusCounter.vue?vue&type=style&index=0&id=6f13f841&prod&scoped=true&lang=css"
import style1 from "./StatusCounter.vue?vue&type=style&index=1&id=6f13f841&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f13f841",
  null
  
)

export default component.exports