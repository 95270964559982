/*=========================================================================================
  File Name: moduleAuthGetters.js
  Description: Auth Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  authUser: state => {
    return state.authUser
  },
  accessToken: state => {
    return state.access_token
  },
  refreshToken: state => {
    return state.refresh_token
  },
  lastRoute: state => {
    return state.last_route
  },
  isUserLoggedIn: state => {
    return state.authUser ? state.authUser.isAuthenticated : false
  },
  permissions: state => {
    return ['can-read']
  },
  actAsOriginalAuthUser: state => {
    return state.actAsOriginalAuthUser || null
  },
  isUserLoggedInActAs: state => {
    return Boolean(state.actAsOriginalAuthUser)
  }

}
