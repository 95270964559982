import Service from '../../services/unit-planner'
import VxTooltip from '@/layouts/components/vx-tooltip/VxTooltip.vue'


export default {
  props: ['teacherUnitId'],
  components: {
    VxTooltip
  },
  data () {
    return {
      tasksData: [],
      restService: Service,
      currentTaskToArchive: null,
      customEvents: [{eventName: 'updateAssessmentGridData', callback: this.updateGrid}]
    }
  },
  created () {
    ////////////////////////////////////////////////////
    //**          Custom Event Listener           ** //
    //////////////////////////////////////////////////
    this.customEvents.forEach(function (customEvent) {
      // eslint-disable-next-line no-undef
      $sharedEvent.listen(customEvent.eventName, customEvent.callback)
    })
  },
  mounted () {
    this.fetchTeacherAssessment()
  },
  destroyed () {
    ////////////////////////////////////////////////////
    //**      Remove Custom Event Listener        ** //
    //////////////////////////////////////////////////
    this.customEvents.forEach(function (customEvent) {
      // eslint-disable-next-line no-undef
      $sharedEvent.off(customEvent.eventName, customEvent.callback)
    })
  },
  methods: {
    updateGrid (data) {
      const itemIndex = this.tasksData.findIndex(item => item.id === data.id)
      if (itemIndex !== -1) {
        /*case update*/
        this.tasksData.splice(itemIndex, 1, data)
      } else {
        this.tasksData.unshift(data)
      }
    },
    archiveTask () {
      this.$vs.loading()
      //** Archive Task **//
      this.restService.archiveAssessmentTask(this.currentTaskToArchive, {status: 'archived'})
        .then(res => {
          this.updateGrid(res.data)
          //** Hide Loader **//
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Archive',
            text: 'Task Archived Successfully',
            color: 'success',
            position: 'bottom-right'
          })
        })
        .catch(err => {
          console.error(err)
          //** Hide Loader **//
          this.$vs.loading.close()

          this.$vs.notify({
            title: 'Loading Data Error',
            text: err.data.message,
            color: 'danger',
            position: 'bottom-right'
          })
        })

    },
    archiveTaskConfirm (taskId) {
      this.currentTaskToArchive = taskId
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: `${this.$t('basic.confirm')} `,
        text: 'Are you sure to archive this task?.',
        accept:this.archiveTask
      })
    },
    editAssessment (data) {
      $sharedEvent.fire('openCreateTaskModal', data)
    },
    fetchTeacherAssessment () {
      this.$vs.loading()
      //** Get IB types **//
      this.restService
        .getTeacherAssignments()
        .then(res => {
          console.log('assignments', res)
          this.tasksData = res.data.sort((a, b) => b.id - a.id)
          //** Hide Loader **//
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          //** Hide Loader **//
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Loading Data Error',
            text: err.data.message,
            color: 'danger',
            position: 'bottom-right'
          })
        })
    },
    assessmentStudentsTask (data) {
      const tasksWithAnswers = this.tasksData.filter(item =>  item.statistics.has_answer)
      $sharedEvent.fire('openReviewTaskModal', {data, tasks: tasksWithAnswers})
    }
  }
}
