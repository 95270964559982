<template>
  <div>
    <vx-card class="p-relative">
      <!--------------- Action Buttons --------------->
      <vs-dropdown
        class="actionsButtons text-capitalize cursor-pointer"
        vs-custom-content
        vs-trigger-click
      >
        <vs-button color="#999" type="flat" icon="more_vert"></vs-button>
        <vs-dropdown-menu>
          <vs-dropdown-item @click="goToExam">
            {{ $t("basic.show") }}
          </vs-dropdown-item>

          <!-- <vs-dropdown-item :to="'/teacher/exam/running/'+ exam.id">{{
                $t("basic.start")
              }}</vs-dropdown-item> -->
          <!--  -->
          <vs-dropdown-item
            v-if="hasAccessToAssess"
            :to="{
              name: 'ExamGradeStudent',
              params: {
                id: exam.id,
              },
            }"
            >{{ $t("exam.grade_exam") }}</vs-dropdown-item
          >

          <!-- <vs-dropdown-item>{{ $t("basic.duplicate") }}</vs-dropdown-item>
              <vs-dropdown-item>{{ $t("basic.insights") }}</vs-dropdown-item> -->
          <!-- If Teacher  -->
          <template v-if="is_teacher && !is_archived">
            <vs-dropdown-item
              v-if="canEdit || is_drafted"
              @click="goto({ name: 'exam_edit', params: { id: exam.id } })"
            >
              {{ $t("basic.edit") }}
            </vs-dropdown-item>
            <vs-dropdown-item
              v-if="canAssess"
              :to="{ name: 'exam_assessment', params: { id: exam.id } }"
            >
              {{ $t("exam.assessStudents") }}
            </vs-dropdown-item>
            <!-- Exam Status  -->
            <vs-dropdown-item
              v-if="is_suspended"
              @click="changeStatus('Active')"
              >{{ $t("basic.activate") }}</vs-dropdown-item
            >
            <vs-dropdown-item
              v-if="canSuspended"
              @click="changeStatus('Suspend')"
              >{{ $t("basic.suspend") }}</vs-dropdown-item
            >
            <vs-dropdown-item
              v-if="canArchive"
              @click="changeStatus('Archive')"
              >{{ $t("basic.archive") }}</vs-dropdown-item
            >
          </template>
        </vs-dropdown-menu>
      </vs-dropdown>
      <div class="min_height_110 vx-row flex items-center p-1 pt-1 pb-1 md:p-1">
        <div class="vx-col w-full lg:w-1/4 sm:w-1/2 text-capitalize">
          <h4 class="examnamecard mb-2">{{ exam.name }}</h4>
          <p class="mb-2">{{ exam.subject.name }}</p>
          <p class="mb-2" v-if="is_teacher">
            {{ exam.classes.name.join(" , ") }}
          </p>
          <div v-if="exam.grade && exam.grade.section.name == 'MYP'">
            <p
              class="mb-2"
              v-for="(criteria, index) in exam.detailed_criteria"
              :key="index"
            >
              {{ criteria.key }} - {{ criteria.title }}
            </p>
          </div>
        </div>
        <div class="vx-col w-full lg:w-1/4 sm:w-1/2 text-capitalize">
          <p class="mb-2 text-sm mr-5">
            <feather-icon
              icon="CalendarIcon"
              svgClasses="text-primary h-4 w-4 mr-1"
            />
            <span>{{ $t("basic.date") }} :</span> {{ formatDate(exam.date) }}
          </p>
          <p class="mb-2 text-sm mr-5">
            <feather-icon
              icon="ClockIcon"
              svgClasses="text-primary h-4 w-4 mr-1"
            />
            <span>
              {{ $t("basic.startAt") }}:
              {{ changeTime12Formate(exam.duration_fixed_time_start_time) }}
            </span>
          </p>
          <p class="mb-2 text-sm">
            <feather-icon
              icon="ClockIcon"
              svgClasses="text-primary h-4 w-4 mr-1"
            />
            {{ $t("basic.endAt") }}:
            {{ changeTime12Formate(exam.duration_fixed_time_end_time) }}
          </p>
        </div>
        <div class="vx-col w-full lg:w-1/5 sm:w-1/2 text-capitalize">
          <p class="mb-2 text-sm flex items-center">
            <vs-icon
              icon="not_listed_location"
              color="primary"
              class="examTypeIcon mr-1"
            />
            {{ exam.questions.length || $t("basic.no") }}
            {{ $t("basic.questions") }}
          </p>

          <p class="mb-2 text-sm flex items-center">
            <vs-icon
              icon="bar_chart"
              color="primary"
              class="examTypeIcon mr-1"
            />
            {{ exam.type.type }} {{ $t("basic.exam") }}
          </p>

          <p class="mb-2 text-sm flex items-center" v-if="is_student">
            <tempalte>
              <feather-icon
                icon="UserIcon"
                svgClasses="text-primary h-4 w-4 mr-1"
              />
              <span class="ml-2"> {{ exam.teacher.name }}</span>
            </tempalte>
          </p>
        </div>
        <div class="vx-col w-full lg:w-1/5 sm:w-1/2 text-capitalize">
          <div
            class="text-sm flex flex-wrap items-center mb-2"
            v-if="exam.questions.length"
          >
            <!-- <feather-icon
                icon="EditIcon"
                svgClasses="text-primary h-4 w-4 mr-1"
              /> -->
            <template>
              <div
                v-for="(question, index) in questionsTypes"
                :key="index"
                class=""
              >
                <p class="text-sm">
                  {{ question }}
                  <span class="mx-1" v-if="index + 1 < questionsTypes.length">
                    ,
                  </span>
                </p>
              </div>
            </template>
            <!-- <div v-if="exam.questions.length == 0">
                <p class="text-sm">{{ $t("basic.no_questions") }}</p>
              </div> -->
          </div>
          <p class="mb-2 text-sm flex items-center">
            <feather-icon
              icon="CheckIcon"
              svgClasses="text-primary h-4 w-4 mr-1"
            />
            <span v-if="Boolean(exam.is_auto_grading)">{{
              $t("exam.auto_grading")
            }}</span>
            <span v-else>{{ $t("exam.manual_grading") }}</span>
          </p>
          <p class="mb-2" v-if="is_teacher">
            <vs-chip class="status--dark">
              <template>{{ exam.status.status.toLowerCase() }}</template>
            </vs-chip>
          </p>
          <p class="mb-2" v-if="exam.time_status">
            <vs-chip :class="`status--${exam.time_status}`">
              <template v-if="exam.time_status === 'closed'">
                {{
                  is_student
                    ? exam.submitted_at
                      ? $t("exam.no_submitted")
                      : $t("exam.submitted")
                    : $t("basic.Finished")
                }}
              </template>
              <template v-else>{{ exam.time_status }}</template>
            </vs-chip>
          </p>
        </div>
      </div>
    </vx-card>
  </div>
</template>
<script>
// mixins
import { authUserMixin } from '@/mixins.js'
// API
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const ExamRepo = RepositoryFactory.get('ExamModule', 'Exam')
export default {
  name: 'exam-card',
  props: {
    exam: {
      type: Object,
      required: true
    }
  },
  mixins: [authUserMixin],
  data () {
    return {

    }
  },
  computed: {
    questionsTypes () {
      return [...new Set(this.exam.questions.map((q) => q.type.type))]
    },
    is_submittedStudent () {
      return this.exam.submittedStudentsCount > 0
    },
    is_archived () {
      return this.exam.is_archived || this.exam.status.status === 'Archive'
    },
    is_drafted () {
      return this.exam.status.status.toLowerCase() === 'draft'
    },
    is_suspended () {
      return this.exam.status.status.toLowerCase() === 'suspend'
    },
    canEdit () {
      return (
        this.is_teacher &&
        !this.is_submittedStudent &&
        !this.is_archived &&
        !this.is_suspended &&
        this.hasExamStarted()
      )
    },
    canArchive () {
      return (
        this.is_teacher &&
        !this.is_submittedStudent &&
        !this.is_archived &&
        this.hasExamStarted())
    },
    canSuspended () {
      return (
        this.is_teacher &&
        !this.is_submittedStudent &&
        !this.is_archived &&
        !this.is_suspended &&
        this.hasExamStarted())
    },
    canAssess () {
      return (
        this.is_teacher &&
        (this.is_submittedStudent || this.exam.hasEnded) &&
        !this.is_archived && !this.is_suspended && !this.is_drafted
      )
    },
    hasAccessToAssess () {
      return (
        this.is_student &&
        this.exam.show_result_to_student &&
        (this.exam.assessTask ||
          (this.exam.submitted_at && this.exam.is_auto_grading))
      )
    }
  },
  methods: {
    hasExamStarted () {
      const diff = this.daysBetweenDates(
        new Date(
          `${this.exam.date} ${this.exam.duration_fixed_time_start_time}`
        ),
        this.currentDate
      )
      return diff >= 0
    },
    /////////////////////
    // Exam Actions
    /////////////////////
    goToExam () {
      this.$router.push({
        name: this.is_teacher ? 'exam_show' : 'ExamRunningStudent',
        params: { id: this.exam.id }
      })
    },
    openExam () {
      window.open(
        'http://www.google.com',
        'WindowName',
        'minimizable=no,scrollbars=no,resizable=no,titlebar=no,location=no'
      )
    },
    /////////////////////
    // APIs
    /////////////////////
    changeStatus (status) {
      if (this.hasExamStarted()) {
        this.VSLoading()
        ExamRepo.changeStatus(this.exam.id, status)
          .then(() => this.$emit('reloadData'))
          .finally(() => this.stopVSLoading())
      } else {
        this.errMsg(this.$t('exam.cant_change_status_after_exam_start'))
      }
    },
    goto (routeDeatils) {
      if (this.hasExamStarted() || this.is_drafted) {
        this.$router.push(routeDeatils)
      } else {
        this.errMsg(this.$t('exam.cant_change_data_after_exam_start'))
        this.$emit('reloadData')
      }

    }
  }
}
</script>
<style lang="scss" scoped>
.examTypeIcon {
  font-size: 20px;
}

.examnamecard {
  font-size: 12px;
  font-weight: bold;
}

.examCardMiniText {
  font-size: 8px;
}
.actionsButtons {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
