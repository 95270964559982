import FileUploader from '@/modules/unit-planner/components/uploadButton/uploadButton.vue'
import vSelect from 'vue-select'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'

export default {
  components: { FileUploader, 'v-select': vSelect },
  name: 'taskModal',
  data () {
    return {
      StudentRepository: RepositoryFactory.get('StudentsModule', 'student'),
      modalProps: {
        width: '1050px',
        height: 'auto',
        minHeight: 500,
        scrollable: true
      },
      modalName: 'taskModal',
      task: null,
      taskId: this.$route.params.id,
      taskAnswer: {
        attachments: [],
        answer: ''
      },
      feedback: [
        { id: 1, name: 'Very Happy' },
        { id: 2, name: 'Not Happy' },
        { id: 3, name: 'In Power' }
      ],
      currentSection: null,
      studentNote: '',
      selectedFeedback: null,
      imgBaseUrl: this.institutionAssetsLink,
      autoSavingInterval: null,
      isAutoSaving: false,
      uploadedFiles: [],
      customEvents: [{ eventName: 'openTaskModal', callback: this.loadData }]
    }
  },
  created () {
    this.StudentRepository.getSingleTask(this.taskId)
      .then(res => {
        this.loadData(res.data)
      })
      .catch(err => {
        console.log(err)
      })
  },
  methods: {
    loadData (data) {
      this.resetProps()
      this.task = data
      this.currentSection = data.class_teacher.section.short_name
      this.taskAnswer = {
        attachments: data.task ? data.task.attachments : [],
        answer: data.task ? data.task.answer : ''
      }
      this.autoSaving()
    },
    resetProps () {
      this.task = null
      this.selectedFeedback = null
      this.studentNote = ''
      this.taskAnswer = {
        attachments: [],
        answer: ''
      }
    },
    autoSaving () {
      this.autoSavingInterval = setInterval(() => {
        this.isAutoSaving = true
        this.save('draft')
      }, 300 * 1000)
    },
    submitAnswer (statusValue) {
      if (!this.canAnswer(statusValue)) {
        return
      }
      const msg = statusValue === 'draft' ? 'Drafted' : 'Submitted'
      //** Show Loader **//
      this.$vs.loading()
      const data = {
        ...this.taskAnswer,
        status: statusValue,
        student_feedback: this.selectedFeedback
          ? this.selectedFeedback.name
          : '',
        note: this.studentNote
      }
      this.StudentRepository.createStudentTaskAnswer(this.task.id, data)
        .then(res => {
          if (!this.isAutoSaving) {
            const data = { ...this.task, task: res.data }
            this.sendDataToGrid(data)
            this.$vs.loading.close()
            this.cancel()
            this.$vs.notify({
              title: 'Submitting Answer',
              text: `Answer ${msg} Successfully`,
              color: 'success',
              position: 'bottom-right'
            })
          } else {
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Auto Saving',
              text: 'Data saved',
              color: 'success',
              position: 'bottom-right'
            })
          }
          this.isAutoSaving = false
        })
        .catch(err => {
          console.error(err)
          //** Hide Loader **//
          this.$vs.loading.close()

          this.$vs.notify({
            title: 'Saving Data Error',
            text: err.data.message,
            color: 'danger',
            position: 'bottom-right'
          })
        })
    },
    updateAnswer (statusValue) {
      if (!this.canAnswer(statusValue)) {
        return
      }
      //** Show Loader **//
      this.$vs.loading()

      const data = {
        ...this.taskAnswer,
        status: statusValue,
        student_feedback: this.selectedFeedback
          ? this.selectedFeedback.name
          : '',
        note: this.studentNote
      }
      this.StudentRepository.updateStudentTaskAnswer(this.task.task.id, data)
        .then(res => {
          if (!this.isAutoSaving) {
            const data = { ...this.task, task: res.data }
            this.sendDataToGrid(data)
            this.$vs.loading.close()
            this.cancel()
            this.$vs.notify({
              title: 'Updating Answer',
              text: 'Answer Updated Successfully',
              color: 'success',
              position: 'bottom-right'
            })
          } else {
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Auto Saving',
              text: 'Data saved',
              color: 'success',
              position: 'bottom-right'
            })
          }
          this.isAutoSaving = false
        })
        .catch(err => {
          console.error(err)
          //** Hide Loader **//
          this.$vs.loading.close()

          this.$vs.notify({
            title: 'Saving Data Error',
            text: err.data.message,
            color: 'danger',
            position: 'bottom-right'
          })
        })
    },
    setUploadedFiles (files) {
      this.taskAnswer.attachments.push(...files)
    },
    sendDataToGrid (data) {
      $sharedEvent.fire('changeTaskData', data)
    },
    canAnswer (status) {
      if (this.task && status === 'active') {
        if (!this.taskAnswer.answer) {
          this.$vs.notify({
            title: 'Validation',
            text: 'Please Answer',
            color: 'warning',
            position: 'bottom-right'
          })
          return false
        } else {
          return true
        }
      } else if (this.task && status === 'draft') {
        if (!this.taskAnswer.answer) {
          this.$vs.notify({
            title: 'Validation',
            text: 'Please Answer',
            color: 'warning',
            position: 'bottom-right'
          })
          return false
        } else {
          return true
        }
      }
    },
    save (statusValue) {
      if (this.task.task) {
        this.updateAnswer(statusValue)
      } else {
        this.submitAnswer(statusValue)
      }
    },
    cancel () {
      this.resetProps()
      this.$router.push({
        name: 'BackToProfile',
        params: {
          id: this.authUser.uid
        }
      })
    },
    onCloseModal (data) {
      clearInterval(this.autoSavingInterval)
    },
    removeAttachment (file, index) {
      //** Show Loader **//
      this.$vs.loading()
      const attachment = {
          file_path: file.attachment_url
        },
        file_attachment_url_split = file.attachment_url.split('/'),
        payload = {
          attachments: [
            {
              attachment:
                file_attachment_url_split[file_attachment_url_split.length - 1],
              attachment_url: attachment.file_path
            }
          ]
        }
      this.StudentRepository.deleteAssessmentAttachments(payload)
        .then(() => {
          this.taskAnswer.attachments.splice(index, 1)
          //** Hide Loader **//
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          //** Hide Loader **//
          this.$vs.loading.close()

          this.$vs.notify({
            title: 'Deletion Error',
            text: err.data.message,
            color: 'danger',
            position: 'bottom-right'
          })
        })
    }
  }
}
