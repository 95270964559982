<template>
  <div class="mt-5">
    <vs-tabs
      class="tabs-shadow-none"
      :key="isSmallerScreen"
      id="profile-tabs"
      :position="isSmallerScreen ? 'top' : 'left'"
      alignment="center"
    >
      <!-- alignment="center"
       :position="isSmallerScreen ? 'top' : 'left'"
-->
      <!-- GENERAL -->
      <vs-tab
        icon-pack="feather"
        :icon="item.icon ? item.icon : 'icon-user'"
        :label="item.title"
        v-for="item in listItems"
        :key="item.action"
      >
        <div class="tab-general">
          <component :is="item.action" :component_data="student" />
        </div>
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<script>
import Father from './FamilyProfile/Father'
import Mother from './FamilyProfile/Mother'
import MartialStatus from './FamilyProfile/MartialStatus'
import Siblings from './FamilyProfile/Siblings'
import Emergency from './FamilyProfile/Emergency'

export default {
  name: 'FamilyProfile',
  props: {
    student: {
      type: Object,
      required: true
    }
  },
  components: {
    Father,
    Mother,
    MartialStatus,
    Siblings,
    Emergency
  },
  data () {
    return {
      currentActiveData: '',
      listItems: [
        {
          title: 'Martial Status & Custody',
          action: 'martial-status',
          icon:'icon-users'
        },
        {
          title: 'Siblings',
          action: 'siblings',
          icon:'icon-users'
        },
        {
          title: 'Emergency Contacts',
          action: 'emergency',
          icon:'icon-phone-call'
        }
      ]
    }
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < 1204
    }
  },
  methods: {
    changeData (action) {
      this.currentActiveData = action
    }
  },
  created () {
    const fatherName = `${this.student.father.first_name} ${this.student.father.middle_name || ''} ${this.student.father.last_name || ''}`
    const motherName = `${this.student.mother.first_name} ${this.student.mother.middle_name || ''} ${this.student.mother.last_name || ''}`
    this.listItems.unshift({
      // title: motherName.replace(/\s+/g, ' '),
      title: 'mother',
      action: 'mother'
    })
    this.listItems.unshift({
      // title: fatherName.replace(/\s+/g, ' '),
      title: 'father',
      action: 'father'
    })
  }
}
</script>
<style lang="scss">
.vs-tabs-position-left .vs-tabs--ul {
  box-shadow: none;
  max-width: 251px;
  overflow: hidden;
}
</style>
