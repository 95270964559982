<template>
  <vx-card class="p-relative">
    <slot name="actionButtons"></slot>
    <div class="vx-row w-full items-center">
      <div class="w-full vx-col lg:w-1/4 lg:mb-0 mb-2">
        <slot name="first"></slot>
      </div>
      <div class="w-full vx-col lg:w-1/4 md:w-1/3 lg:mb-0 mb-2">
        <slot name="second"></slot>
      </div>
      <div class="w-full vx-col lg:w-1/4 md:w-1/3 md:mb-0 mb-2">
        <slot name="third"></slot>
      </div>
      <div class="w-full vx-col lg:w-1/4 md:w-1/3 md:mb-0 mb-2">
        <slot name="fourth"></slot>
      </div>
    </div>
  </vx-card>
</template>
<script>
export default {
  name: 'grid-card-4'
}
</script>
