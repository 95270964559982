import modalPreviewer from '../../modals/previwer-modal/previwer-modal.vue'
export default {
  name: 'FilePreviewer',
  props: ['url', 'urlType', 'width', 'height'],
  components:{modalPreviewer},
  data () {
    return {
      mimeTypes:{
        png: 'image',
        jpeg: 'image',
        jpg: 'image',
        mp4: 'video',
        pdf: 'pdf',
        docx:'wordFile',
        doc: 'wordFile',
        xlsx: 'excelFile',
        xls: 'excelFile',
        ppt: 'powerPointFile',
        pptx: 'powerPointFile'
      },
      type: null
    }
  },
  created () {
    this.type = this.mimeTypes[this.urlType]
  },
  methods:{
    modalPreview () {
      $sharedEvent.fire('openPreviewModal', {url: this.url, urlType: this.urlType})
    }
  }
}
