<template>
  <ListPageWithCreateButton :showCreateBtn="false">
    <template slot="listDataContent">
      <wallet-table
        :csv_url="'/utilities/csv'"
        :export_csv_method="'get'"
        :headers_prop="headers"
        :list_url_prop="'/utilities/all-students'"
        :loading="loading"
        :searchData="searchData"
        :list_items="searchResult">
        <template v-slot:center_content>
          <vs-input
            :label-placeholder="$t('form.search_by_name_email_or_ID')"
            v-model="searchData"
            v-validate="{email: searchData.includes('@')}"
            class="w-8/12"
            name="email"
            icon="icon icon-search"
            icon-pack="feather"
            @keyup.enter="search" />
          <span class="text-sm text-danger" v-if="errors.first('email')">{{
            errors.first('email') | text_formatter
          }}</span>
        </template>
      </wallet-table>
      <vs-prompt
        @cancel="refundData.user_id = ''"
        @accept="confirmRefund"
        :active.sync="requestFund"
        title="Refund"
        type="confirm">
        <div class="con-exemple-prompt">
          <p>
            {{ $t('wallet.refundAlert') }} {{ refundValue }}
            {{ $t('wallet.currency') }}
          </p>
        </div>
      </vs-prompt>
    </template>
  </ListPageWithCreateButton>
</template>

<script>
import WalletTable from '@/components/general/datatable/DataTable2.vue'
import {commonDataMixin} from '@/mixins'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const allWalletRepository = RepositoryFactory.get(
  'walletRepository',
  'walletRepository'
)
export default {
  name: 'walletList',
  components: {
    WalletTable
  },
  mixins: [commonDataMixin],
  data() {
    return {
      headers: [
        {
          minWidth: 250,
          headerName: this.$t('StudentAffairs.student_id'),
          field: 'student_id'
        },
        {
          minWidth: 300,
          headerName: this.$t('form.fullName'),
          field: 'name'
        },
        {
          minWidth: 200,
          headerName: this.$t('wallet.class'),
          field: 'class_name'
        },
        {
          minWidth: 200,
          headerName: this.$t('wallet.totalAmount'),
          field: 'total_amount'
        },
        {
          minWidth: 150,
          headerName: this.$t('basic.status'),
          field: 'status',
          cellRendererFramework: 'CellRendererStatus'
        },
        {
          minWidth: 200,
          headerName: this.$t('basic.actions'),
          filter: false,
          field: 'actions',

          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            actions_prop: [
              {
                dropDown: true,
                dropDown_items: [
                  /*                  {
                    title: this.$t('StudentAffairs.leaver'),
                    parameter_name: 'user_id',
                    icon: 'LogOutIcon',
                    action: 'leaver',
                    resource: '/user/:id/role/status',
                    color: 'danger',
                    showIf:(data) => data.system_status !== 'leaver'
                  },*/
                  {
                    title: this.$t('wallet.addFund'),
                    parameter_name: 'user_id',
                    icon: 'PlusIcon',
                    navigate_action: true,
                    route_name: 'add-fund',
                    resource: '/wallet/add-fund/:user_id'
                  },
                  {
                    title: this.$t('wallet.refundFund'),
                    parameter_name: 'user_id',
                    icon: 'Edit3Icon',
                    actionEmitter: 'refundFundRequest',
                    actionEmitterParams: ['user_id']
                  },
                  {
                    title: this.$t('wallet.funds'),
                    parameter_name: 'user_id',
                    icon: 'DatabaseIcon',
                    navigate_action: true,
                    route_name: 'fund-history',
                    resource: '/wallet/fund-history/:user_id'
                  },
                  {
                    title: this.$t('wallet.transaction'),
                    parameter_name: 'user_id',
                    icon: 'RepeatIcon',
                    navigate_action: true,
                    route_name: 'fund-transaction',
                    resource: '/wallet/fund-transaction/:user_id'
                  },
                  {
                    title: this.$t('basic.suspend'),
                    parameter_name: 'id',
                    icon: 'PowerIcon',
                    resource: 'utilities/suspend/:id',
                    color: 'warning',
                    showIf: (data) => data.status !== 'Suspended'
                  },
                  {
                    title: this.$t('status.active'),
                    parameter_name: 'id',
                    icon: 'CheckSquareIcon',
                    resource: 'utilities/activate/:id',
                    showIf: (data) => data.status == 'Suspended'
                  }
                ]
              }
            ]
          }
        }
      ],
      searchResult: [],
      loadingConfirmRefund: true,
      loading: false,
      requestFund: false,
      refundValue: '',
      searchData: '',
      refundData: {
        user_id: '',
        transable_type: 'SND'
      }
    }
  },
  methods: {
    confirmRefund() {
      this.VSLoading()
      allWalletRepository
        .makeWalletRefund(this.refundData)
        .then(() => {
          this.stopVSLoading()
          this.successMsg(this.$t('wallet.refundSuccessfully'))
          this.refundData.user_id = ''
        })
        .catch((err) => {
          this.errMsg(err)
        })
    },
    startRefundFundRequest(userId) {
      this.loading = true
      allWalletRepository.getStudentAmount(userId).then((res) => {
        this.refundValue = res.data.total_amount
        this.loading = false
        this.requestFund = true
      })
      this.refundData.user_id = userId
    },
    search() {
      this.loading = true
      allWalletRepository
        .search(this.searchData)
        .then((res) => {
          this.searchResult = res.students
        })
        .catch(this.errMsg)
        .finally(() => {
          this.loading = false
        })
    }
  },
  watch: {
    searchData(v) {
      if (!v) this.searchResult = null
    }
  },
  computed: {},
  beforeDestroy() {
    this.$root.$off('refundFundRequest')
  },
  created() {
    this.$root.$on('refundFundRequest', (data) =>
      this.startRefundFundRequest(data.user_id)
    )
  }
}
</script>

<style lang="scss" scoped>
.active .vx-card {
  -webkit-box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
  box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
}
</style>
