import axios from 'axios'

export default {
  getTeacherClasses (data) {
    return axios.get('teachers/classes-of-grade', {
      params: { ...data }
    })
  },
  getTeacherTypes () {
    return axios.get('teachers/teacher-types')
  },
  getAllTeacher () {
    return axios.get('teachers/all-teachers')
  },
  getAssignedTeachers (data) {
    return axios.get('teachers/teacher-of-class-teacher', {
      params: { ...data }
    })
  },
  assignTeacher (data) {
    return axios.post('teachers/assign-teacher-to-subject', data)
  },
  /************ Student Api **************/
  getUnassignStudents (data) {
    return axios.get('student/students-assigned-to-subject', {
      params: { ...data }
    })
  },
  assignStudents (data) {
    return axios.post('student/assign-students-to-subject', data)
  },
  getStudentsByClassroom (data) {
    return axios.get(`student/studentInClass/${data.class_id}/${data.grade_id}/${data.division_id}`)
  }

}