export default {
  Dashboard: 'Dashboard',
  Applications: 'Applications',
  eCommerce: 'eCommerce',
  Apps: 'Apps',
  Todo: 'Todo',
  Chat: 'Chat',
  Email: 'Email',
  Calendar: 'Calendar',
  FullCalendar: 'Full Calendar',
  SimpleCalendar: 'Simple Calendar',
  Shop: 'Shop',
  ItemDetails: 'Item Details',
  Cart: 'Cart',
  WishList: 'Wish List',
  Checkout: 'Checkout',
  UI: 'UI',
  DataList: 'Data List',
  ListView: 'List View',
  ThumbView: 'Thumb View',
  Grid: 'Grid',
  Vuexy: 'Vuexy',
  Vuesax: 'Vuesax',
  Tailwind: 'Tailwind',
  activate: 'Activate',
  deactivate: 'Deactivate',
  show: 'Show',
  Colors: 'Colors',
  Card: 'Card',
  Basic: 'Basic',
  Statistics: 'Statistics',
  Analytics: 'Analytics',
  CardActions: 'Card Actions',
  CardColors: 'Card Colors',
  Table: 'Table',
  agGridTable: 'agGrid Table',
  Components: 'Components',
  Alert: 'Alert',
  Avatar: 'Avatar',
  Badge: 'Badge',
  Breadcrumb: 'Breadcrumb',
  Button: 'Button',
  ButtonGroup: 'Button Group',
  Chip: 'Chip',
  Collapse: 'Collapse',
  Dialogs: 'Dialogs',
  Divider: 'Divider',
  DropDown: 'DropDown',
  List: 'List',
  Loading: 'Loading',
  Navbar: 'Navbar',
  Notifications: 'Notifications',
  Pagination: 'Pagination',
  Popup: 'Popup',
  Progress: 'Progress',
  Sidebar: 'Sidebar',
  Slider: 'Slider',
  Tabs: 'Tabs',
  Tooltip: 'Tooltip',
  Upload: 'Upload',
  FormsAndTable: 'Forms & Table',
  FormElements: 'Form Elements',
  Select: 'Select',
  Switch: 'Switch',
  Checkbox: 'Checkbox',
  Radio: 'Radio',
  Input: 'Input',
  NumberInput: 'Number Input',
  Textarea: 'Textarea',
  FormLayouts: 'Form Layouts',
  FormWizard: 'Form Wizard',
  FormValidation: 'Form Validation',
  FormInputGroup: 'Form Input Group',
  Pages: 'Pages',
  Authentication: 'Authentication',
  Login: 'Login',
  Register: 'Register',
  ForgotPassword: 'Forgot Password',
  ResetPassword: 'Reset Password',
  LockScreen: 'Lock Screen',
  Miscellaneous: 'Miscellaneous',
  ComingSoon: 'Coming Soon',
  Error: 'Error',
  404: '404',
  500: '500',
  NotAuthorized: 'Not Authorized',
  Maintenance: 'Maintenance',
  Profile: 'Profile',
  User: 'User',
  View: 'View',
  Edit: 'Edit',
  addSection: 'Add Section',
  UserSettings: 'User Settings',
  Invoice: 'Invoice',
  FAQ: 'FAQ',
  Search: 'Search',
  KnowledgeBase: 'Knowledge Base',
  ChartsAndMaps: 'Charts & Maps',
  Charts: 'Charts',
  ApexCharts: 'Apex Charts',
  chartjs: 'chartjs',
  echarts: 'echarts',
  chartist: 'chartist',
  GoogleMap: 'Google Map',
  Extensions: 'Extensions',
  QuillEditor: 'Quill Editor',
  DragAndDrop: 'Drag & Drop',
  Datepicker: 'Datepicker',
  DatetimePicker: 'Datetime Picker',
  AccessControl: 'Access Control',
  I18n: 'I18n',
  Carousel: 'Carousel',
  Clipboard: 'Clipboard',
  ContextMenu: 'Context Menu',
  StarRatings: 'Star Ratings',
  Autocomplete: 'Autocomplete',
  Tree: 'Tree',
  // "Import/Export": 'Import/Export',
  Import: 'Import',
  Export: 'Export',
  ExportSelected: 'Export Selected',
  Others: 'Others',
  MenuLevels: 'Menu Levels',
  MenuLevel2p1: 'Menu Level 2.1',
  MenuLevel2p2: 'Menu Level 2.2',
  MenuLevel3p1: 'Menu Level 3.1',
  MenuLevel3p2: 'Menu Level 3.2',
  DisabledMenu: 'Disabled Menu',
  Support: 'Support',
  Documentation: 'Documentation',
  RaiseSupport: 'Raise Support',
  demoTitle: 'Card Title',
  demoText:
    'Cake sesame snaps cupcake gingerbread danish I love gingerbread. Apple pie pie jujubes chupa chups muffin halvah lollipop. Chocolate cake oat cake tiramisu marzipan sugar plum. Donut sweet pie oat cake dragée fruitcake cotton candy lemon drops.',
  Show: 'Show',
  Delete: 'Delete',
  Actions: 'Actions',
  AutoComplete: 'Auto Complete',
  Mobile: 'Mobile',
  Tel: 'Tel',
  Fax: 'Fax',
  week_days: [
    {
      text: 'Monday',
      value: 'monday'
    },
    {
      text: 'Tuesday',
      value: 'tuesday'
    },
    {
      text: 'Wednesday',
      value: 'wednesday'
    },
    {
      text: 'Thursday',
      value: 'thursday'
    },
    {
      text: 'Friday',
      value: 'friday'
    },
    {
      text: 'Saturday',
      value: 'saturday'
    },
    {
      text: 'Sunday',
      value: 'sunday'
    }
  ],
  create: 'Create',
  clone: 'Clone',
  login: 'login',
  forgotPassword: 'forgot Password',
  password: 'password',
  resetPassword: 'reset Password',
  email: 'email',
  Welcome_back_please_login_to_your_account:
    'Welcome back, please login to your account.',
  Recover_your_password: 'Recover your password',
  Please_enter_your_email_address_and_we_will_send_you_a_link_to_reset_your_password:
    'Please enter your email address and we will send you a link to reset your password.',
  Back_To_Login: 'Back To Login',
  Recover_Password: 'Recover Password',
  Please_enter_your_new_passowrd: 'Please enter your new password',
  confirm_passowrd: 'confirm password',
  new_password: 'new password',
  save: 'save',
  enter_your_email: 'enter your email',
  password_confirmation_does_not_match: 'Password confirmation does not match',
  note: 'note',
  enable: 'enable',
  disable: 'disable',
  Schedule_Meeting: 'Schedule Meeting',
  selectAll: 'select All',
  please_wait_until_loading_data_and_make_sure_the_notification_permissions_is_allowed_in_the_browser_setting:
    'Please wait until loading data and make sure the notification permission is allowed in the browser settings',
  Login_Failed: 'Login Failed',
  Login_Success: 'Login Success',
  You_are_authenticated: 'You are authenticated',
  Invalid_login_data: 'Invalid login data',
  Welcome: 'Welcome',
  welcome: 'Welcome',
  upgrade: 'upgrade',
  parent: 'parent',
  student: 'student',
  teacher: 'teacher',
  roles: 'roles',
  current_password:'current password'
}
