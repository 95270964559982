<template>
  <div id="list_datatable w-full">
    <div class="vx-col w-full mb-2">
      <status-counter
        @filter-change="changeFilter"
        :status_prop="all_attendance_status"
      ></status-counter>

      <status-counter
        @filter-change="changeFilter($event, true)"
        :status_prop="present_details_all"
      ></status-counter>
    </div>

    <div class="vx-row">
      <div
        :class="`vx-col w-full mb-5 ${
          !this.$store.state.reduceButton ? 'lg:w-3/4' : 'lg:w-4/4'
        }`"
      >
        <AttendanceTable
          :filter_type="filterType"
          :side_filters="sideFilters"
          ref="AttendanceTable"
          total="1"
          :headers_prop="headers"
          :list_url_prop="`/attendance/students/${authUser.school_id}`"
          :filter_by_date="filterBydate"
        >
        </AttendanceTable>
      </div>
      <div class="vx-col lg:w-1/4 w-full">
        <attendance-filter
          @changeSideFilter="changeSideFilter"
          @resetFilters="resetFilters"
          :filters_prop="filters"
          :filters_choosen_prop="filter_choosen"
          :rounds_prop="rounds"
          active_prop.sync="true"
        >
          <template slot="customFilters">
            <div class="mt-5">
              <p class="vs-collapse-item--header mb-2">
                {{ $t("basic.date") }}
              </p>
              <div class="w-full">
                <vx-input-group class="w-full">
                  <flat-pickr
                    icon-pack="feather"
                    icon="icon-user"
                    :config="configDate"
                    v-model="filterBydate"
                    name="start_date"
                    class="w-full"
                  />

                  <template slot="append" v-if="filterBydate">
                    <div class="append-text btn-addon">
                      <vs-button
                        type="border"
                        icon-pack="feather"
                        icon="icon-x"
                        @click="filterBydate = ''"
                      ></vs-button>
                    </div>
                  </template>
                </vx-input-group>
              </div>
            </div>
          </template>
        </attendance-filter>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import AttendanceTable from './components/AttendanceTable'
import AttendanceFilter from './components/AttendanceFilter'
import StatusCounter from '@/components/general/StatusCounter'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'

const ConsoleRepository = RepositoryFactory.get(
  'Attendance',
  'attendanceConsole'
)

const AllStudentsRepository = RepositoryFactory.get('AdmissionModule', 'allStudents')

// Datepicker
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  components: {
    StatusCounter,
    AttendanceTable,
    AttendanceFilter,
    flatPickr
  },
  computed: {
    ...mapGetters('auth', ['authUser']),
    filters () {
      const custom_filter = [
        {
          id: 1,
          name: this.$t('basic.divisions'),

          by: []
        },
        {
          id: 2,
          name: this.$t('basic.sections'),
          by: []
        },
        {
          id: 3,
          name: this.$t('basic.grades'),

          by: []
        },
        {
          id: 4,
          name: this.$t('basic.class_groups'),
          by: []
        }
      ]

      // eslint-disable-next-line vue/no-async-in-computed-properties
      ConsoleRepository.getAllDivisionsGrades().then((res) => {
        const all_divsions = res.divisions
        const all_grades = res.grades
        all_divsions.map((el) => {
          custom_filter[0]['by'].push({
            id: el.id,
            name: el.long_name,
            checked: false
          })
        })

        all_grades.map((el) => {
          custom_filter[2]['by'].push({
            id: el.id,
            name: el.name,
            checked: false
          })
        })
      })

      // eslint-disable-next-line vue/no-async-in-computed-properties
      ConsoleRepository.getAllSections().then((res) => {
        const all_sections = res.data.data

        all_sections.map((el) => {
          custom_filter[1]['by'].push({
            id: el.id,
            name: el.name,
            checked: false
          })
        })
      })

      // eslint-disable-next-line vue/no-async-in-computed-properties
      ConsoleRepository.getAllFilterActiveClasses().then((res) => {

        const all_classes = res.data

        all_classes.filter(el => !el.in_holiday).map((el) => {
          custom_filter[3]['by'].push({
            id: el.id,
            name: el.name,
            checked: false
          })

        })
      })

      return custom_filter
    }

  },
  data () {
    return {
      sideFilters: {
        status: []
      },
      activeTab: '',
      status_id: '',
      filterType: '',
      all_attendance_status: [],
      present_details_all: [],
      filterBydate: '',
      headers: [
        {
          // minWidth: 50,
          headerName: this.$t('basic.ID'),

          field: 'code',
          width: 140,
          minWidth: 120

        },
        {
          minWidth: 350,
          width: 450,
          headerName: this.$t('Attendance.name'),
          valueGetter:
            '`${data.first_name} ${data.middle_name} ${data.last_name}`'
        },
        {
          minWidth: 160,
          headerName: this.$t('Attendance.division'),
          field: 'division',
          width: 290
        },
        {
          minWidth: 160,
          headerName: this.$t('Attendance.grade'),
          field: 'grade',
          width: 290
        },
        {
          minWidth: 160,
          headerName: this.$t('Attendance.class'),
          width: 290,
          valueGetter:
            'data.class ? data.class : \'No class assigned\' '

        },
        {
          minWidth: 160,
          colId: 'status',
          headerName: this.$t('Attendance.status'),
          valueGetter:
            'data.status === \'unfinished\' ? \'Undefined\' : data.status.status_title',
          // "data.attendance_status === 'unfinished' ? 'Not Taken' : data.attendance_status.status_title",
          width: 290,
          filterParams: {
            filterOptions: [
              {
                displayKey: 'memberOf',
                displayName: 'Member Of',
                test (filterValue, cellValue) {
                  let values = []
                  switch (filterValue.toLowerCase()) {
                  case 'present':
                    values = ['present']
                    break
                  case 'absent':
                    values = [
                      'absent',
                      'authorised absent',
                      'study leave',
                      'extended family holiday',
                      'religious observance',
                      'no reason yer provided for absence',
                      'medical leave',
                      'continuous days being absent'
                    ]
                    break
                  case 'authorized absent':
                    values = ['authorized absent']
                    break
                  case 'usual absent':
                    values = ['usual absent', 'continuous days being absent']
                    break
                  case 'on leave':
                    values = [
                      'on leave',
                      'extended family holiday',
                      'medical leave'
                    ]
                    break
                  case 'disciplined':
                    values = [
                      'disciplined',
                      'study leave',
                      'extended family holiday',
                      'religious observance',
                      'medical leave'
                    ]
                    break
                  }
                  return values.includes(cellValue.toLowerCase())
                },
                hideFilterInput: false
              }
            ]
          },
          cellRendererFramework: 'CellRendererStatus',
          cellRendererParams: {
            status_prop: this.status
          }
        }
      ],
      filter_choosen: [],
      rounds: [],
      configDate: {
        year: 'Y-M-D',
        maxDate: new Date
      }
    }
  },
  methods: {
    resetFilters (items) {
      this.changeSideFilter(items)
      this.filterBydate = ''
      this.status_id = []
    },
    changeFilter (filter, status_details = false) {
      const status = {}
      if (filter) {
        if (status_details) {
          status.id = this.present_details_all.filter(state => state.value === filter)[0].status_id || 0
          status.status_details_id = this.present_details_all.filter(state => state.value === filter)[0].id || 0
        } else {
          status.id = this.all_attendance_status.filter(state => state.value === filter)[0].id || 0
        }
      } else {
        status.id = ''
      }
      this.changeSideFilter('', status)
    },
    changeSideFilter (selected_filters, selected_status) {
      const adjustData = (fils) => {
        return {
          divisions: fils.length ? fils[0].by.filter((el) => el.checked).map((el) => el.id) : [],
          sections: fils.length ? fils[1].by.filter((el) => el.checked).map((el) => el.id) : [],
          grades: fils.length ? fils[2].by.filter((el) => el.checked).map((el) => el.id) : [],
          classes: fils.length ? fils[3].by.filter((el) => el.checked).map((el) => el.id) : [],
          status: this.status_id && selected_status !== '' ? this.status_id : []
        }

      }
      if (!selected_status) this.sideFilters = adjustData(selected_filters)
      else {
        this.sideFilters = {
          divisions: this.sideFilters.divisions,
          sections: this.sideFilters.sections,
          grades: this.sideFilters.grades,
          classes: this.sideFilters.classes,
          status: selected_status === 0 ? [] : [selected_status]
        }
        this.status_id = this.sideFilters.status
      }
    },
    async getStatus () {
      //todo make it with async and await
      await ConsoleRepository.getStatus().then((res) => {

        let all_status = res.response.map((state) => {
          return {
            id: state.id,
            name: state[this.current_locale].title,
            flag: state.flag,
            value: state[this.current_locale].title.toLowerCase().replace(/\s+/g, '_'),
            icon: 'UsersIcon',
            count: 0
          }
        })
        all_status.pop() //for remove undifind
        all_status = all_status.filter(
          (state) => state.flag.toLowerCase() !== 'usual absent' &&
            state.flag.toLowerCase() !== 'disciplined' &&
            state.flag.toLowerCase() !== 'improvement in attendance' &&
            state.flag.toLowerCase() !== 'not recorded' &&
            state.flag.toLowerCase() !== 'recently usual absent' &&
            state.flag.toLowerCase() !== 'used to miss the first bell'
        )

        all_status.push({
          id: '0',
          name: 'Undefined',
          value: 'undefined',
          icon: 'UsersIcon',
          count: 0
        })

        const all_present_details = res.response.filter((state) => state.flag.toLowerCase() === 'present')[0]['details'].map((detail) => {
          return {
            name: detail[this.current_locale].title,
            id: detail.id,
            icon: 'UsersIcon',
            count: 0,
            value:detail[this.current_locale].title.toLowerCase().replace(/\s+/g, '_')
          }
        })

        const present_id = res.response.filter((state) => state.flag.toLowerCase() === 'present'
        )[0]['id']

        ConsoleRepository.getStudentsUnpaginated(this.authUser.school_id, this.filterBydate, this.sideFilters)
          .then((res) => {
            const all_students = res.data

            if ((Array.isArray(all_students) && all_students.length) || all_students) {
              all_status.map(state => {
                state.count = Object.values(all_students).filter(student => (student.id === state.id)
                )[0].count
                return state
              })

              const present_status_details = Object.values(all_students).filter(student => (student.id === present_id)
              )[0].status_details

              all_present_details.map(state => {
                state.count = Object.values(present_status_details).filter(student => (student.id === state.id)
                )[0].count
                state.status_id = present_id
                return state
              })
            }

          })
        this.all_attendance_status = all_status
        this.present_details_all = all_present_details
        this.present_details_all.shift()

      })
    }
  },

  watch: {
    filterBydate (v) {
      this.getStatus(v)
    }
  },
  async created () {
    // todo add the loader globally in axios interceptors
    this.VSLoading()
    const today = new Date()

    this.rounds.push(
      {
        id: 1,
        name: today.toLocaleString('en-US', {year: 'numeric', month: 'long', day: 'numeric'})
      }
    )

    await this.getStatus()

  }
}
</script>

<style lang="scss" scoped>
.active .vx-card {
  -webkit-box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
  box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
}
</style>
