<template>
  <div>
    <vs-card>
      <div class="vx-row">
        <div class="vx-col lg:w-1/2 mb-2">
          <h4 class="py-3"><span>Title : </span>{{ announcement.title }}</h4>
        </div>
        <div class="vx-col lg:w-1/2">
          <h6 class="py-3">
            <span>created at : </span> {{  dateToYMD(announcement.created_at)  }}
          </h6>
        </div>
      </div>
      <h5 class="py-3">
        <span>To : </span>
        <span v-for="(type, index) in typeNmaes" :key="index">
          {{ type }}
        </span>
      </h5>
      <h6 class="py-3">
        <span>created by : </span>{{ announcement.creator_by }}
      </h6>
      <hr />
      <h5 v-html="announcement.description" class="py-4">
        {{ announcement.description }}
      </h5>
    </vs-card>
  </div>
</template>

<script>
import moment from 'moment'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const announcementRepository = RepositoryFactory.get(
  'announcementRepository',
  'announcement'
)
export default {
  watch:{
    '$route.params.id': {
      handler () {
        this.showAnnouncement()
      }

    }
  },
  data () {
    return {
      announcement: {},
      announcementsReceivers: {}
    }
  },
  methods: {
    dateToYMD (date) {
      return moment(String(date)).format('DD/MM/YYYY : hh:mm:ss a')
    },
    showAnnouncement () {
      announcementRepository
        .showAnnouncement(this.$route.params.id)
        .then((res) => {
          console.log(res)
          this.announcement = res.data
          this.announcementsReceivers = res.data.announcements_receivers[0]
        })
    }
  },
  computed: {
    typeNmaes () {
      let x = []
      if (this.announcementsReceivers.class_name) {
        x = this.announcementsReceivers.class_name
      } else if (this.announcementsReceivers.section_name) {
        x = this.announcementsReceivers.section_name
      } else if (this.announcementsReceivers.grade_name) {
        x = this.announcementsReceivers.grade_name
      } else if (this.announcementsReceivers.division_name) {
        x = this.announcementsReceivers.division_name
      }
      return x
    }
  },
  mounted () {
    this.showAnnouncement()
  }
}
</script>

<style scoped lang="scss">
</style>
