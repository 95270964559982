import axios from 'axios'

const resource = '/grading'
export default {
  addInventory (payload) {
    return axios.post(`${resource}/inventories/create`, payload)
  },
  addInventoryWithTerm (payload) {
    return axios.post(`${resource}/inventory/create`, payload)
  },
  updateStatus (payload) {
    return axios.post(`${resource}/inventories/multiple-update-status`, payload)
  },
  deleteMultiInventory (payload) {
    return axios.post(`${resource}/inventories/multiple-delete`, payload)
  },
  search (payload) {
    return axios.post(`${resource}/inventories?search=${payload}`)
  }
}
