import axios from 'axios'

export default {

  getAllSettings () {
    return axios.get('/configuration/app-setting?key=myday')
  },
  updateSettings (payload) {
    return axios.post('/configuration/setting-my-day', payload)
  }
}
