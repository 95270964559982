import {mapGetters} from 'vuex'
import Service from '../../services/unit-planner'
import uploader from '../uploadButton/uploadButton'
import ImagePreviewModal from '../../modals/image-preview/image-preview.vue'

export default {
  name: 'discussions',
  props:['componentData'],
  components:{uploader, ImagePreviewModal},
  data () {
    return {
      restService: Service,
      discussionsList:[],
      newPostObj:{
        teacher_unit_id: this.componentData.teacherUnitId,
        title:'',
        attachments:[],
        description:''
      },
      newPost:null,
      newReply:'',
      teachersDiscussions:null,
      imgBaseUrl:this.institutionAssetsLink,
      customEvents: [{eventName: 'contentClicked', callback: this.closeNewPost}]
    }
  },
  created () {
    ////////////////////////////////////////////////////
    //**          Custom Event Listener           ** //
    //////////////////////////////////////////////////
    this.customEvents.forEach(function (customEvent) {
      // eslint-disable-next-line no-undef
      $sharedEvent.listen(customEvent.eventName, customEvent.callback)
    })

    this.newPost = this.cloneItem(this.newPostObj)
    this.teachersDiscussions = this.cloneItem(this.componentData.data.contents.data)
    this.teachersDiscussions.forEach(item => {
      item['newReply'] = ''
    })
  },
  destroyed () {
    ////////////////////////////////////////////////////
    //**      Remove Custom Event Listener        ** //
    //////////////////////////////////////////////////
    this.customEvents.forEach(function (customEvent) {
      // eslint-disable-next-line no-undef
      $sharedEvent.off(customEvent.eventName, customEvent.callback)
    })
  },
  mounted () {
  },
  methods:{
    creatNewPost () {
      //** Show Loader **//
      this.$vs.loading()
      this.restService.createTeacherPost(this.newPost)
        .then(res => {
          this.newPost = this.cloneItem(this.newPostObj)
          this.teachersDiscussions.unshift(res.data)
          this.closeNewPost()
          this.$vs.notify({
            title: 'New Post',
            text: 'Saved Successful',
            color: 'success',
            position: 'bottom-right'
          })
          //** Hide Loader **//
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          //** Hide Loader **//
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Saving Data Error',
            text: err.data.message,
            color: 'danger',
            position: 'bottom-right'
          })
        })
    },
    addCommentToPost (item, index, event) {
      //** Show Loader **//
      this.$vs.loading()
      this.updateSizeOfCollapseItem(item.id)
      this.restService.addCommentOnPost(item.id, {comment: item.newReply})
        .then(res => {
          this.teachersDiscussions[index].comments.push(res.data)
          this.teachersDiscussions[index].newReply = ''
          this.$vs.notify({
            title: 'New Comments',
            text: 'Saved Successful',
            color: 'success',
            position: 'bottom-right'
          })
          //** Hide Loader **//
          this.$vs.loading.close()
          this.$nextTick(() => {
            this.updateSizeOfCollapseItem(item.id)
          })
        })
        .catch(err => {
          console.error(err)
          this.updateSizeOfCollapseItem(item.id)
          //** Hide Loader **//
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Saving Data Error',
            text: err.data.message,
            color: 'danger',
            position: 'bottom-right'
          })
        })
    },
    updateSizeOfCollapseItem (id) {
      const refId = `collapsItem${ id}`
      this.$refs[refId][0].$el.click()
    },
    isImage (type) {
      const imageTypes = ['apng', 'avif', 'gif', 'jpg', 'jpeg', 'png', 'webp']
      if (imageTypes.includes(type)) { return 'image' }
    },
    setAttachedFiles (files) {
      this.newPost.attachments.push(...files)
      /*const uploadedFiles = files.map(item => {
        return {
          type: item.type,
          attachment: item.attachment,
          previewUrl: this.imgBaseUrl + item.attachment
        }
      })
      this.newPost.attachments.push(...uploadedFiles);*/
    },
    previewImage (image) {
      // eslint-disable-next-line no-undef
      $sharedEvent.fire('openImagePreviewModal', image)
    },
    openNewPost () {
      this.$refs.newPost.classList.add('open-post')
    },
    closeNewPost () {
      this.$refs.newPost.classList.remove('open-post')
    },
    removeAttachment (file, index) {
      //** Show Loader **//
      this.$vs.loading()
      this.restService.deleteFile({file_path: file.attachment_url})
        .then(() => {
          this.newPost.attachments.splice(index, 1)
          //** Hide Loader **//
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          //** Hide Loader **//
          this.$vs.loading.close()

          this.$vs.notify({
            title: 'Deletion Error',
            text: err.data.message,
            color: 'danger',
            position: 'bottom-right'
          })
        })
    }

  },
  computed: {
    ...mapGetters({loggedInUser: 'getLoggedInUserForPlanner'})
  }
}
