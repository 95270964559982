
<template>
  <!--<div>
    <feather-icon  class="mx-2 icon cursor-pointer" icon="SettingsIcon"/>
  </div>-->
  <div>
    <vs-dropdown
      vs-trigger-click
      class="cursor-pointer"
      icon="MoreVerticalIcon"
      svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
    >
      <feather-icon
        icon="MoreVerticalIcon"
        svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
      ></feather-icon>
      <vs-dropdown-menu>
        <vs-dropdown-item>
          <span class="flex items-center" @click="fireAction('teacherSuspend')">
            <feather-icon class="mr-2" icon="PowerIcon" svgClasses="h-4 w-4" />
            <span>Suspend</span>
          </span>
        </vs-dropdown-item>
        <vs-dropdown-item>
          <span
            class="flex items-center"
            @click="fireAction('teacherActivation')"
          >
            <feather-icon
              icon="UserCheckIcon"
              svgClasses="h-4 w-4"
              class="mr-2"
            />
            <span>Activate</span>
          </span>
        </vs-dropdown-item>
        <vs-dropdown-item>
          <span
            class="flex items-center"
            @click="fireAction('teacherAsEditor')"
          >
            <feather-icon icon="Edit2Icon" svgClasses="h-4 w-4" class="mr-2" />
            <span>Set As Editor</span>
          </span>
        </vs-dropdown-item>
        <vs-dropdown-item>
          <span class="flex items-center" @click="fireAction('teacherAsAdmin')">
            <feather-icon
              icon="SlidersIcon"
              svgClasses="h-4 w-4"
              class="mr-2"
            />
            <span>Set As Admin</span>
          </span>
        </vs-dropdown-item>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
export default {
  name: 'cell-render',
  props: ['name'],
  methods: {
    fireAction (action) {
      /*console.log("this.params.data",this.params.data);*/
      $sharedEvent.fire(action, this.params.data)
    }
  }
}

</script>

<style scoped>
</style>
