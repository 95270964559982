<template>
  <div
    class="the-navbar__user-meta flex items-center"
    v-if="activeUserInfo.displayName"
  >
    <img
      v-if="activeUserInfo.image"
      key="onlineImg"
      :src="activeUserInfo.image"
      :alt="activeUserInfo.displayName"
      width="40"
      height="40"
      class="rounded-full shadow-md block mr-5"
    />
    <div class="text-left leading-tight hidden sm:block">
      <p class="font-semibold">
        {{ activeUserInfo.displayFirstAndSecondName }}
      </p>
      <small>{{ $t("status.available") }}</small>
    </div>

    <!-- <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
      <div class="con-img ml-3">
        <!-- <img
          v-if="activeUserInfo.image"
          key="onlineImg"
          :src="activeUserInfo.image"
          alt="user-img"
          width="40"
          height="40"
          class="rounded-full shadow-md cursor-pointer block"
        />
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 15rem">
          <li
            class="
              flex
              py-2
              px-4
              cursor-pointer
              hover:bg-primary
              hover:text-white
            "
            @click="$router.push('/student/StudentProfile').catch(() => {})"
          >
            <span class="ml-2">My Profile</span>
          </li>
          <li
            class="
              flex
              py-2
              px-4
              cursor-pointer
              hover:bg-primary
              hover:text-white
            "
            @click="$router.push('/student/StudentProfile').catch(() => {})"
          >
            <span class="ml-2">Child profile</span>
          </li>
          <hr />
          <li
            class="
              flex
              py-2
              px-4
              cursor-pointer
              hover:bg-primary
              hover:text-white
            "
            @click="$router.push('/apps/email').catch(() => {})"
          >
            <span class="ml-2">Admission</span>
          </li>
          <li
            class="
              flex
              py-2
              px-4
              cursor-pointer
              hover:bg-primary
              hover:text-white
            "
            @click="$router.push('/apps/email').catch(() => {})"
          >
            <span class="ml-2">School Fees</span>
          </li>
          <hr />
          <li
            class="
              flex
              py-2
              px-4
              cursor-pointer
              hover:bg-primary
              hover:text-white
            "
            @click="$router.push('/apps/email').catch(() => {})"
          >
            <span class="ml-2">Email School</span>
          </li>
          <li
            class="
              flex
              py-2
              px-4
              cursor-pointer
              hover:bg-primary
              hover:text-white
            "
            @click="$router.push('/apps/email').catch(() => {})"
          >
            <span class="ml-2">Edit Information</span>
          </li>
          <li
            class="
              flex
              py-2
              px-4
              cursor-pointer
              hover:bg-primary
              hover:text-white
            "
            @click="$router.push('/apps/email').catch(() => {})"
          >
            <span class="ml-2">Certificates</span>
          </li>

          <li
            class="
              flex
              py-2
              px-4
              cursor-pointer
              hover:bg-primary
              hover:text-white
            "
            @click="$router.push('/apps/email').catch(() => {})"
          >
            <span class="ml-2">Documents</span>
          </li>
          <li
            class="
              flex
              py-2
              px-4
              cursor-pointer
              hover:bg-primary
              hover:text-white
            "
            @click="$router.push('/apps/email').catch(() => {})"
          >
            <span class="ml-2">Print Profile</span>
          </li>
          <li
            class="
              flex
              py-2
              px-4
              cursor-pointer
              hover:bg-primary
              hover:text-white
            "
            @click="$router.push('/apps/email').catch(() => {})"
          >
            <span class="ml-2">Create PDF</span>
          </li>
          <hr />

          <li
            class="
              flex
              py-2
              px-4
              cursor-pointer
              hover:bg-primary
              hover:text-white
            "
            @click="$router.push('/apps/chat').catch(() => {})"
          >
            <span class="ml-2">Settings</span>
          </li>

          <vs-divider class="m-1" />

          <li
            class="
              flex
              py-2
              px-4
              cursor-pointer
              hover:bg-primary
              hover:text-white
            "
            @click="logout"
          >
            <span class="ml-2">Logout</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown> -->
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  computed: {
    activeUserInfo () {
      return this.authUser
    }
  },
  methods: {

  }
}
</script>
