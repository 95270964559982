<template>
  <ListPageWithCreateButton
    createRoutName="exam_create"
    :createBtnName="$t('basic.exam_create')">
    <template slot="leftSideButtonContent">
      <status-counter
        :status_prop="status"
        @filter-change="changeFilter"
        ref="status_counter"></status-counter>
    </template>
    <template slot="listDataContent">
      <div class="mt-5">
        <div class="flex justify-end" style="margin-bottom: 15px">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium">
              <span class="mr-2 leading-none">Actions</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="printCSV()">
                <span class="flex items-center">
                  <feather-icon
                    icon="SaveIcon"
                    svgClasses="h-4 w-4"
                    class="mr-2" />
                  <span>CSV</span>
                </span>
              </vs-dropdown-item>
              <!-- TODO:: PDF BTN  -->
              <vs-dropdown-item @click="printPDF()">
                <span class="flex items-center">
                  <feather-icon
                    icon="FileIcon"
                    svgClasses="h-4 w-4"
                    class="mr-2" />
                  <span>PDF</span>
                </span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
        <transition-group class="list-transition" name="list-enter-up" tag="ul">
          <li
            class="mb-16"
            v-for="(val, i) in exams"
            v-show="currentView == 'cards'"
            :key="val.id"
            :style="[{transitionDelay: i * 0.1 + 's'}]">
            <exam-card
              :exam="val"
              @duplicate="duplicate"
              @reloadData="getAllExams"></exam-card>
          </li>
          <examsTable
            :headers="tableHeaders"
            :data="exams"
            key="examTable"
            v-if="currentView == 'table'" />
        </transition-group>
      </div>
      <template v-if="exams.length > 0 && totalPages > 1">
        <vs-pagination
          :total="totalPages"
          :max="countPerPage"
          v-model="currentPage"
          class="mt-6" /> </template
    ></template>
    <template slot="rightSide">
      <sidebar-filter
        ref="sidebarFilter"
        :filters_prop="filters"
        :active_prop="true"
        @refilter="setFilters">
      </sidebar-filter
    ></template>
  </ListPageWithCreateButton>
</template>
<script>
import ExamCard from '@/modules/exam/components/ExamCard.vue'
import SidebarFilter from '@/components/general/RoundFilter.vue'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const TeacherRepository = {
  BasicInfo: RepositoryFactory.get('TeachersModule', 'BasicInfo'),
  Exam: RepositoryFactory.get('ExamModule', 'Exam')
}
import StatusCounter from '@/components/general/StatusCounter'
import examsTable from './components/ExamsTable'
import vSelect from 'vue-select'

export default {
  name: 'all-exams',
  components: {
    ExamCard,
    SidebarFilter,
    StatusCounter,
    examsTable,
    'v-select': vSelect
  },
  data() {
    return {
      divisions: [],
      classes: [],
      grades: [],
      subjects: [],
      action: [],
      examStatus: [],
      topFilter: '',
      currentView: 'cards',
      tableHeaders: [
        '',
        'ID',
        'Exam Name',
        'Subject',
        'Time Based',
        'Type',
        'Access',
        'Publish',
        'Grades',
        'Status',
        'Action'
      ],
      // status
      total: null,
      graded_count: 0,
      closed_count: 0,
      upcoming_count: 0,
      ongoing_count: 0,
      // exams
      exams: [],
      rounds: [
        {
          id: 1,
          name: 'Filters',
          start: '2 March 2020',
          end: '2 April 2020'
        }
      ],
      sidebar_filter_choosen: [],
      /////////////////////////
      // Pagination
      /////////////////////////
      totalPages: 0,
      currentPage: 1,
      countPerPage: 10
    }
  },
  created() {
    this.getAllExams()
    this.getAllExamsCount()
  },
  methods: {
    setFilters(val) {
      this.sidebar_filter_choosen = val
    },
    duplicate(val) {
      const duplicate_exam = this.exams.find((el) => el.id === val)
      duplicate_exam.id = this.exams.length
      this.exams.push(duplicate_exam)
    },
    changeFilter(val) {
      this.topFilter = val
    },
    changeView(val) {
      this.currentView = val
    },
    getAllExams() {
      this.VSLoading()
      const params = [
        `page=${this.currentPage}`,
        `${this.filtered_types}`,
        `${this.filtered_status}`,
        `${this.filtered_time_statues}`,
        `${this.filtered_classes}`
      ]
      TeacherRepository.Exam.getAllExam(params.filter(Boolean).join('&'))
        .then((res) => {
          this.setPagination(res.data)
          this.exams = res.data.data
        })
        .catch((err) => {
          console.log('err', err)
        })
        .finally(() => this.stopVSLoading())
    },
    getAllExamsCount() {
      TeacherRepository.Exam.getAllExamsCount()
        .then((res) => {
          this.total = res.data.total
          this.graded_count = res.data.graded_count
          this.closed_count = res.data.closed_count
          this.upcoming_count = res.data.upcoming_count
          this.today_count = res.data.today_count
          this.ongoing_count = res.data.ongoing_count
        })
        .catch((err) => {
          console.log('err', err)
        })
    },
    ////////////////////////////
    // Pagination
    ////////////////////////////
    setPagination(data) {
      this.totalPages = data.total_pages || data.last_page
      this.countPerPage = data.per_page
      this.currentPage = data.current_page
    },
    printPDF() {
      const params = [
        `${this.filtered_types}`,
        `${this.filtered_status}`,
        `${this.filtered_classes}`
      ]
      TeacherRepository.Exam.downloadPdf(params.filter(Boolean).join('&'))
        .then((res) => {
          this.downloadFile(res)
        })
        .catch((err) => {
          console.log('err', err)
        })
    },
    printCSV() {
      const params = [
        `${this.filtered_types}`,
        `${this.filtered_status}`,
        `${this.filtered_classes}`
      ]
      TeacherRepository.Exam.downloadCsv(params.filter(Boolean).join('&'))
        .then((res) => {
          this.downloadFile(res)
        })
        .catch((err) => {
          console.log('err', err)
        })
    }
  },
  computed: {
    filtered_list() {
      let filtered = null
      filtered = this.exams.filter((el) => el === this.sidebar_filter_choosen)
      return filtered
    },
    filtered_classes() {
      let classes = ''
      const allClassesFilter = this.sidebar_filter_choosen.filter(
        (val) => val.name === 'classes'
      )[0]
      if (
        allClassesFilter !== undefined &&
        allClassesFilter.checked.length > 0
      ) {
        classes = `class_id=${allClassesFilter.checked.join()}`
      }
      return classes
    },
    filtered_types() {
      let types = ''
      const allTypesFilter = this.sidebar_filter_choosen.filter(
        (val) => val.name === 'types'
      )[0]
      if (allTypesFilter !== undefined && allTypesFilter.checked.length > 0) {
        types = `type_id=${allTypesFilter.checked.join()}`
      }
      return types
    },
    filtered_status() {
      let status = ''
      const allStatusFilter = this.sidebar_filter_choosen.filter(
        (val) => val.name === 'status'
      )[0]
      if (allStatusFilter !== undefined && allStatusFilter.checked > 0) {
        status = `status_id=${allStatusFilter.checked}`
      }
      return status
    },
    filtered_time_statues() {
      let filter = ''
      const statusId = Number(this.topFilter)
      if (statusId) filter = `status_id=${this.topFilter}`
      else {
        switch (this.topFilter) {
          case 'upcoming':
            filter = 'upcoming=true'
            break
          case 'closed':
            filter = 'upcoming=false'
            break

          case 'ongoing':
            filter = 'ongoing=true'
            break

          case 'today':
            filter = 'today=true'
            break
          default:
            filter = ''
            break
        }
      }
      // if ([].some(s=>this.topFilter.includes(s)) && this.topFilter !== 'upcoming' && this.topFilter && ) {
      //   filter = `status_id=${this.topFilter}`
      // } else if (this.topFilter == 'upcoming') {
      //   filter = 'upcoming=true'
      // }
      return filter
    },
    status() {
      const status = [
        {
          icon: 'EyeIcon',
          value: 'all',
          name: this.$t('basic.All'),
          color: 'blue',
          count: this.total
        },
        {
          icon: 'SmileIcon',
          value: 'upcoming',
          name: this.$t('basic.Upcoming'),
          count: this.upcoming_count
        },
        {
          icon: 'ClockIcon',
          value: 'today',
          color: 'warning',
          name: this.$t('basic.Today'),
          count: this.today_count
        },
        {
          icon: 'ActivityIcon',
          value: 'ongoing',
          color: 'dark',
          name: this.$t('basic.ongoing'),
          count: this.ongoing_count
        },
        {
          icon: 'CheckIcon',
          value: 'closed',
          color: 'danger',
          name: this.$t('basic.Finished'),
          count: this.closed_count
        }
      ]
      return status
    },
    filters() {
      const custom_filter = [
        {
          id: 1,
          name: this.$t('exam.Classes'),
          by: []
        },
        {
          id: 3,
          name: this.$t('basic.types'),
          by: [
            {
              checked: false,
              name: this.$t('exam.digital_exam'),
              id: 1
            },
            {
              checked: false,
              name: this.$t('exam.paper_exam'),
              id: 2
            }
          ]
        },
        {
          id: 4,
          singleSeletable: true,
          // onChangeFireAction:true,
          name: this.$t('basic.status'),
          by: []
        }
      ]
      // Fill all the classes
      // eslint-disable-next-line vue/no-async-in-computed-properties
      TeacherRepository.BasicInfo.getClasses().then((res) => {
        const all_classes = res.data
        all_classes.map((el) => {
          custom_filter[0]['by'].push({
            id: el.id,
            name: el.name,
            checked: false
          })
        })
      })

      // Fill all the classes
      // eslint-disable-next-line vue/no-async-in-computed-properties
      TeacherRepository.Exam.getExamStatus().then((res) => {
        const all_status = res.data
        all_status.map((el) => {
          custom_filter[2]['by'].push({
            id: el.id,
            name: el.status,
            checked: false
          })
        })
      })

      return custom_filter
    }
  },
  watch: {
    filtered_types() {
      this.getAllExams()
    },
    filtered_status() {
      this.getAllExams()
    },
    filtered_classes() {
      this.getAllExams()
    },
    filtered_time_statues() {
      this.getAllExams()
    },
    currentPage(v) {
      this.getAllExams()
    }
  }
}
</script>
<style lang="scss">
.examFilterIcon {
  font-size: 30px;
  cursor: pointer;
}
</style>
