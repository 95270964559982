<template>
  <vs-card class="teacher-principal p-3 pb-1">
    <vs-dropdown class="actions-buttons-teacher-principal" vs-trigger-click>
      <vs-button radius color="#999" type="flat" icon="more_vert"></vs-button>
      <vs-dropdown-menu>
        <vs-dropdown-item @click="goTo(teacher.user_id)">
          <span class="flex items-center">
            <feather-icon
              class="mr-2"
              icon="MessageSquareIcon"
              svgClasses="h-4 w-4"
            />
            <span>{{ $t('chat.chat') }}</span>
          </span>
        </vs-dropdown-item>
      </vs-dropdown-menu>
    </vs-dropdown>
    <div class="vx-row w-full items-center">
      <div class="w-full vx-col md:w-1/5">
        <div class="flex flex-col justify-center items-center">
          <h6 class="lg:mb-4">
            {{ teacher.teacher_name }}
          </h6>
          <p class="my-2 font-semibold">
            {{ gradesNames.join(', ') }}
          </p>
          <p class="my-2 font-semibold">
            {{ divisionsNames.join(', ') }}
          </p>
        </div>
      </div>
      <div class="w-full vx-col md:w-4/5 flex">
        <div
          class="w-1/2 md:w-1/3 lg:w-1/5 flex-col justify-center items-center"
        >
          <div class="w-full table-header">
            <p>
              {{ $t('basic.subject') }}
            </p>
          </div>
          <div class="w-full table-header">
            <p>
              {{ $t('basic.class') }}
            </p>
          </div>
          <div class="w-full table-header">
            <p>
              {{ $t('basic.exams') }}
            </p>
          </div>
          <div class="w-full table-header">
            <p>
              {{ $t('basic.assignments') }}
            </p>
          </div>
          <div class="w-full table-header">
            <p>
              {{ $t('basic.tasks') }}
            </p>
          </div>
        </div>
        <div class="w-1/2 md:w-2/3 lg:w-4/5">
          <div class="overflow-x-auto flex w-full">
            <div
              v-for="item in filteredSubjects"
              :key="item.id"
              class="inline-block teacher-col"
            >
              <div class="flex flex-col justify-center items-center">
                <div class="teacher-principal-table-cell">
                  {{ item.subjet_name.split(' ').join('-') }}
                </div>
                <div class="teacher-principal-table-cell">
                  {{ item.class_name }}
                </div>
                <div class="teacher-principal-table-cell">
                  {{ item.exam_count }}
                </div>
                <div class="teacher-principal-table-cell">
                  {{ item.assignment_count }}
                </div>
                <div class="teacher-principal-table-cell">
                  {{ item.tasks_ccount }}
                </div>
              </div>
            </div>
            <div
              class="inline-block teacher-col"
              v-if="!teacher.teacher_subjets_classes.length"
            >
              <div class="flex flex-col justify-center items-center">
                <div class="teacher-principal-table-cell">none</div>
                <div class="teacher-principal-table-cell">none</div>
                <div class="teacher-principal-table-cell">0</div>
                <div class="teacher-principal-table-cell">0</div>
                <div class="teacher-principal-table-cell">0</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </vs-card>
</template>

<script>
export default {
  name: 'principal-teacher-card',
  props: {
    teacher: {
      required: true,
      default: () => {
        return {
          teacher_subjets_classes: []
        }
      }
    }
  },
  data () {
    return {}
  },
  computed: {
    gradesNames () {
      return [...new Set(this.teacher.teacher_grades_name)]
    },
    divisionsNames () {
      return [...new Set(this.teacher.teacher_divisions_name)]
    },
    filteredSubjects () {
      return this.teacher.teacher_subjets_classes.filter(
        (obj) => obj.subjet_name
      )
    }
  },
  methods: {
    goTo (id) {
      this.$router.push({
        name: 'chat',
        params: {
          userID: id
        }
      })
    }
  }
}
</script>

<style lang="scss">
.teacher-principal {
  position: relative;
  .actions-buttons-teacher-principal {
    position: absolute;
    top: 0;
    right: 0;
  }
  .table-header {
    border-right: 0.5px solid rgb(207, 207, 207);
    border-bottom: 0.5px solid rgb(207, 207, 207);
    border-left: 0.5px solid transparent;
    border-top: 0.5px solid transparent;
    padding: 0.2rem;
    text-align: center;
    font-size: 1rem;
  }
  .teacher-col {
    min-width: 25%;
    @media (max-width: 991px) {
      min-width: 35%;
    }
    @media (max-width: 567px) {
      min-width: 50%;
    }
    .teacher-principal-table-cell {
      width: 100%;
      padding: 0.2rem;
      font-size: 1rem;
      text-align: center;
      border: 0.5px solid rgb(207, 207, 207);
      white-space: nowrap;
      overflow: hidden;
      // &:nth-child(5n + 3) {
      //   border-top: 0.5px solid transparent;
      // }
    }
  }
}
</style>
