import ConsoleHome from './Pages/attendance-console/Home'
import TakeAttendance from './Pages/attendance-console/TakeAttendance'
import InsightsHome from './Pages/insights/Home'
import SettingsHome from './Pages/settings/Create'

export default [
///////////////////////     Attendance Console Routes ////////////////////////
  {
    path: 'attendance-console',
    name: 'ConsoleHome',
    component: ConsoleHome,
    meta: {
      breadcrumb: [
        { title: 'config.Attendance', url:'', active:true },
        {
          title: 'Attendance.attendanceConsole',
          url: '/attendance/attendance-console',
          active: true
        }
      ],
      pageTitle: 'Attendance.attendanceConsole',
      rule: 'TeacherSND'
    }
  },
  {
    path: 'attendance-console/take-attendance/:student_attendance',
    name: 'TakeAttendance',
    component: TakeAttendance,
    meta: {
      breadcrumb: [
        { title: 'config.Attendance', url:'', active:true },
        {
          title: 'Attendance.attendanceConsole',
          url: '/attendance/attendance-console'
        },
        {
          title: 'main.Edit',
          url: '/attendance/attendance-console/take-attendance',
          active: true
        }
      ],
      pageTitle: 'Attendance.takeAttendance',
      rule: 'TeacherSND'
    }
  },

  ///////////////////////     Insights Routes ////////////////////////
  {
    path: 'insights',
    name: 'InsightsHome',
    component: InsightsHome,
    meta: {
      breadcrumb: [
        { title: 'config.Attendance', url:'', active:true },
        {
          title: 'Attendance.insights',
          url: '/attendance/insights',
          active: true
        }
      ],
      pageTitle: 'Attendance.insights',
      rule: 'TeacherSNDSMD'
    }
  },

  ///////////////////////     Settings Routes ////////////////////////
  {
    path: 'settings',
    name: 'SettingsHome',
    component: SettingsHome,
    meta: {
      breadcrumb: [
        { title: 'config.Attendance', url:'', active:true },
        {
          title: 'Attendance.settings',
          url: '/attendance/settings',
          active: true
        }
      ],
      pageTitle: 'Attendance.settings',
      rule: 'TeacherSND'
    }
  }
]
