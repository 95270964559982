

<template>
  <div class="add_student" v-if="student">
    <studentForm @formSubmited="updateStudent" :studentInfo="student" />
  </div>
</template>

<script>
import studentForm from '../../../components/StudentForm.vue'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const addStudentRepository = RepositoryFactory.get('studentAffairsRepository', 'addStudent')

export default {
  components: {
    studentForm
  },
  data () {
    return {
      student_id:this.$route.params.id,
      student:{}
    }
  },
  computed: {},
  watch: {},
  methods: {
    getSingleStudent (id) {
      this.VSLoading()


      addStudentRepository.getSingleStudent(id).then((res) => {
        this.student = res.data
      }).catch((error) => {
        this.errMsg(error)
      }).finally(() => {
        this.stopVSLoading()
      })
    },
    updateStudent (payload) {
      this.VSLoading()
      const id = this.student_id
      addStudentRepository.updateStudent({id, payload}).then((res) => {
        this.successMsg(this.$t('sudentAffairs.student_updated_successfully'))
        this.$router.push({name:'all-students'})
        this.stopVSLoading()
      }).catch((error) => {
        this.errMsg(error)
      })
    }
  },
  created () {
    this.VSLoading()
    this.getSingleStudent(this.student_id)
  }
}
</script>
<style lang="scss">
</style>
