export default {
  data() {
    return {
      /////////////////
      // Loading
      /////////////////
      loadDialog: false,
      loadTypes: false,
      loadSubmit: false,
      /////////////////
      // Data
      /////////////////
      // resourcesTypes:[
      //   // {
      //   //   title:this.$t('basic.all'),
      //   //   value:'all'
      //   // },
      //   // {
      //   //   title:this.$t('basic.assignments'),
      //   //   value:'assignment'

      //   // },
      //   // {
      //   //   title:this.$t('basic.exams'),
      //   //   value:'exam'
      //   // },
      //   // {
      //   //   title:this.$t('assessments.assessments'),
      //   //   value:'assessment'
      //   // }
      // ],
      exams: [],
      assignments: [],
      assessments: [],
      otherTypesGrades: [],

      allData: [],
      allResources: []
    }
  },
  computed: {
    selectedClass: {
      get() {
        return this.$store.getters.getSelectedClass || {}
      },
      set(v) {
        this.$store.commit('gradingSelectClass', v)
      }
    },
    isIB() {
      return this.$store.getters.isIB
    },
    isMYPSection() {
      return this.$store.getters.isMYPSection
    },
    isPYPSection() {
      return this.$store.getters.isPYPSection
    },
    isDPSection() {
      return this.$store.getters.isDPSection
    },
    isAmerican() {
      return this.$store.getters.isAmerican
    },
    criterias: {
      get() {
        return this.$store.getters.criteria
      },
      set(v) {
        this.$store.commit('setCriteria', v)
      }
    },
    resourcesTypes() {
      return this.$store.getters.resourcesTypes
    },
    studentsOfSelectedClass: {
      get() {
        return this.$store.getters.studentsOfSelectedClass
      },
      set(v) {
        this.$store.commit('setStudentsOfSelectedClass', v)
      }
    },

    subjects() {
      return this.$store.getters.subjects
    },
    activeRound: {
      get() {
        return this.$store.getters.activeRound
      },
      set(v) {
        this.$store.commit('setActiveRound', v)
      }
    },
    selectedStudent: {
      get() {
        return this.$store.getters.selectedStudent
      },
      set(v) {
        this.$store.commit('setSelectedStudent', v)
      }
    },
    selectedSubjectId: {
      get() {
        return this.$store.getters.selectedSubjectId
      },
      set(v) {
        this.$store.commit('setSelectedSubjectId', v)
      }
    },
    gradeBookTableData: {
      get() {
        return this.$store.getters.gradeBookTableData
      },
      set(v) {
        this.$store.commit('setGradeBookTableData', v)
      }
    },
    criteriaScores: {
      get() {
        return this.$store.getters.criteriaScores
      },
      set(v) {
        this.$store.commit('setCriteriaScores', v)
      }
    },
    selectedBoundary: {
      get() {
        return this.$store.getters.selectedBoundary
      },
      set(v) {
        this.$store.commit('setsSelectedBoundary', v)
      }
    },
    selectedStudentId() {
      return this.selectedStudent.id || this.$route.params.studentId
    },
    selectedStudentUserId() {
      return (
        this.selectedStudent.userId ||
        this.selectedStudent.user ||
        this.selectedStudent.user_id ||
        this.$route.params.userId
      )
    },
    activeRoundGradingId() {
      return this.activeRound.id || this.$route.params.gradingRoundId || null
    },
    reportCardPagesNodes() {
      return document.querySelectorAll('.reportCardPage')
    },
    reportCardPagesLength: {
      get() {
        return this.$store.getters.reportCardPagesLength
      },
      set(v) {
        this.$store.commit('setReportCardPagesLength', v)
      }
    },
    isReportCardCreated: {
      get() {
        return this.$store.getters.isReportCardCreated
      },
      set(v) {
        this.$store.commit('setIsReportCardCreated', v)
      }
    },
    academicYear() {
      return this.selectedStudent?.academicYear?.name
    },
    termName() {
      return this.activeRound.term
    },
    roundDate() {
      const date = this.activeRound.display_date,
        dateArr = new Date(date).toDateString().split(' ')
      return `${dateArr[1]} ${dateArr[3]}`
    },

    divisionName() {
      return this.selectedStudent?.division?.long_name
    },
    sectionName() {
      return this.selectedStudent?.section?.name
    },
    selectedStudentResources() {
      return this.selectedStudent?.allResources
    },
    reportCardData: {
      get() {
        return this.$store.getters.reportCardData
      },
      set(v) {
        this.$store.commit('setReportCardData', v)
      }
    },
    classId() {
      return parseInt(this.selectedClass.id || this.$route.params.classId)
    },
    isCoordinator() {
      return (
        this.checkUserRole('SND') ||
        this.checkUserRole('TeacherSectionCoodinator') ||
        this.checkUserRole('SectionsCoodinator') ||
        this.checkUserRole('SchoolPrincipal')
      )
    },
    isStrandApproach: {
      get() {
        return (
          this.$store.getters.isStrandApproach ||
          Boolean(this.activeRound.is_strand)
        )
      },
      set(v) {
        this.$store.commit('setIsStrandApproach', v)
      }
    },
    subjectStrands: {
      get() {
        return this.$store.getters.subjectStrands
      },
      set(v) {
        this.$store.commit('setSubjectStrands', v)
      }
    },
    showSubjectPercentage: {
      get() {
        return this.$store.getters.showSubjectPercentage
      },
      set(v) {
        this.$store.commit('setShowSubjectPercentage', v)
      }
    },
    showSubjectWeight: {
      get() {
        return this.$store.getters.showSubjectWeight
      },
      set(v) {
        this.$store.commit('setShowSubjectWeight', v)
      }
    },
    selectedSubject: {
      get() {
        return this.$store.getters.selectedSubject
      },
      set(v) {
        this.$store.commit('setSelectedSubject', v)
      }
    },
    isUpdateReportCard: {
      get() {
        return this.$store.getters.isUpdateReportCard
      },
      set(v) {
        this.$store.commit('setIsUpdateReportCard', v)
      }
    },
    academicYearID() {
      return (
        this.selectedBoundary?.academic_year?._id ||
        this.selectedBoundary.academic_year_id
      )
    },
    isTeacherSectionCoodinator() {
      return this.checkUserRole('TeacherSectionCoodinator')
    }
  },
  watch: {},
  methods: {
    /////////////////////////
    // Loading
    /////////////////////////
    // dialogs
    async startLoadingDialog() {
      await (this.loadDialog = true)
      this.VsLoadingContained(this.$refs.loadDialog)
    },
    async endLoadingDialog() {
      await this.stopVsLoadingContained(this.$refs.loadDialog)
      this.loadDialog = false
    },
    // submit loader
    async startLoadingSubmit() {
      await (this.loadSubmit = true)
      this.VsLoadingContained('#submitLoader')
    },
    endLoadingSubmit() {
      this.stopVsLoadingContained('#submitLoader> .con-vs-loading')
      this.loadSubmit = false
    },
    /////////////////////////
    // export
    /////////////////////////
    handlePrintGradeBookTableData(tableType = 'gradebook') {
      try {
        const headers = window._.cloneDeep(this.headers),
          data = window._.cloneDeep(this.data)

        // Handle Haeder
        if (tableType === 'gradebook') headers.pop()

        const lastHeaderCellIndex = headers.length - 1

        headers[0].title = this.$t('basic.Student_ID')
        headers[0].field = 'id'

        headers[lastHeaderCellIndex - 1].title = this.$t('grading.student_rank')
        headers[lastHeaderCellIndex - 1].field = 'rank'

        headers[lastHeaderCellIndex].title = this.$t(
          'grading.student_total_score'
        )
        headers[lastHeaderCellIndex].field = 'total_score'

        // Handle Data

        data.map((r) => {
          const isResourceRow = Boolean(r.resourceInfo_field)
          if (isResourceRow) {
            r['name'] = r.resourceInfo_field
            delete r.resourceInfo_field
            for (const key in r) {
              if (Object.hasOwnProperty.call(r, key)) {
                if (
                  !key.includes('emptyCell') &&
                  !key.includes('name') &&
                  !key.includes('score')
                ) {
                  r[`${key}_score`] = r[key]
                  delete r[key]
                }
              }
            }
          }
        })

        return {headers, data}
      } catch (err) {
        console.err('handlePrintGradeBookTableData', err)
      }
    },
    exportCSV(title, tableType = 'gradebook') {
      const handledList = this.handlePrintGradeBookTableData(tableType)
      if (handledList) {
        this.successMsg(this.$t('form.Started_Downloading'))
        this.exportToExcel(
          handledList.headers,
          handledList.data,
          `${this.selectedClass && this.selectedClass.name} - ${title}`
        )
      }
    },
    //////////////////////////
    // Table Helpers
    /////////////////////////
    // UI
    setCellClass(tr, field, indextr, row_data = null) {
      const cellClasses = [],
        isResourceInfoCell = Boolean(tr.resourceInfo_field),
        isResourceInfoField = field === 'resourceInfo_field',
        isHeaderFields = field.includes('_field'),
        isStudentNameField = field === 'name',
        isStudentIDField = field === 'id',
        isEmptyCell = field.includes('empty'),
        isFirstRow = indextr === 0,
        isNotAvailableData =
          row_data === 'N/A' || row_data === 'N-A' || row_data === '-'

      if (isEmptyCell) cellClasses.push('empty-cell')
      else if (isResourceInfoCell) {
        if (!isResourceInfoField && !isHeaderFields)
          cellClasses.push('resource-info-cell')
        else if (isResourceInfoField)
          cellClasses.push('text-right', 'resource-field')
      }
      if (
        !isEmptyCell &&
        !isResourceInfoField &&
        !isStudentNameField &&
        !isStudentIDField
      )
        cellClasses.push('text-center')
      if (isNotAvailableData && !isResourceInfoCell)
        cellClasses.push('not-available-cell')
      if (isHeaderFields) cellClasses.push('table__header')
      // if (isHeaderFields && !isResourceInfoField) cellClasses.push('text-center')
      return cellClasses.join(' ')
    },
    // Generate empty cell
    emptyCells(count, sameRow = false) {
      const obj = {}
      for (let i = 1; i <= count; i++)
        obj[`${sameRow ? `emptyCell${i}` : 'emptyCell'}_${i}`] = ''
      return obj
    },
    // Get Single Data
    getSingleData(targetValue, key, arr) {
      let minIndex = 0,
        maxIndex = arr.length - 1
      try {
        while (minIndex <= maxIndex) {
          let midIndex = Math.floor((minIndex + maxIndex) / 2),
            guess = arr[midIndex]

          if (guess[key] === targetValue) return guess
          else if (guess[key] > targetValue) maxIndex = midIndex - 1
          else minIndex = midIndex + 1
        }
      } catch (err) {
        console.log('getSingleData', err)
      }
    },
    //////////////////////////
    // Handle data
    /////////////////////////
    resetMainData() {
      this.students = []
      this.exams = []
      this.assignments = []
      this.assessments = []
      this.gradings = []
      this.allData = []
      this.allResources = []
      this.resourcesTypes.map((e) => {
        e.count = 0
      })
      this.resetStoreSelectedData()
    },
    resetStoreSelectedData() {
      this.$store.commit('setStudentsOfSelectedClass', [])
      this.$store.commit('setSelectedStudent', {})
    },
    // Handle criteria
    handleCriteria(data) {
      this.$store.commit('setCriteria', data)
      return this.$store.getters.criteria
    },
    //////////////////////////
    // Reset Validation
    //////////////////////////
    resetValidation() {
      this.$validator.errors.clear()
      this.$validator.fields.items.forEach((field) => field.reset())
      this.$validator.fields.items.forEach((field) => this.errors.remove(field))
      this.$validator.resume()
    },
    //////////////////////////
    // getTypes
    //////////////////////////
    async getTypes() {
      if (!this.resourcesTypes.length) {
        this.loadTypes = true
        return await this.$store.dispatch('getGradingTypes').finally(() => {
          this.loadTypes = false
        })
      }
    },
    /////////////////////////
    // Handle Strands Object
    /////////////////////////
    handleStrandsSubjectData(arr) {
      return arr.map((e) => ({
        description: e.strand?.description || e.description,
        strand_id: e.strand_id || e.strand?.id || e.id,
        assement: e.assement || (this.isMYPSection ? {} : null),
        other_assement: e.other_assement || null
      }))
    },
    ///////////////////////
    // GOTO
    ///////////////////////
    goToGradeBook() {
      if (this.checkUserRole('Teacher'))
        this.$router.push({name: 'teacherGradebook'})
      else if (this.checkUserRole('gradebookPrincipalCoordinators'))
        this.$router.push({name: 'SchoolPrincipalGradebook'})
      else this.$router.push({name: 'gradebook'})
    }
  },
  beforeDestroy() {
    // this.resetValidation()
  }
}
