<template>
  <under-development
    :featureName="$t('StudentProfile.siblings')"
  ></under-development>
</template>

<script>
export default {
  name: 'Siblings',
  props: {
    component_data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {

    }
  },
  created () {

  }
}
</script>
