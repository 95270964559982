<template>
  <div id="list_datatable w-full">
    <div class="vx-row">
      <div
        class="vx-col w-full"
      >
        <TransactionCanteenTable
          :export_csv_method="'post'"
          :export_pdf_method="'post'"
          :csv_url="'student/student/export'"
          :pdf_url="'student/student/pdf'"
          :headers_prop="headers"
          :list_url_prop="`/student/filters`"
          :list_items="searchResult"
          :loading="loading"
        >
        </TransactionCanteenTable>
      </div>
    </div>
  </div>
</template>

<script>

import TransactionCanteenTable from '@/components/general/datatable/DataTable2.vue'

import { commonDataMixin } from '@/mixins'

export default {
  components: {
    TransactionCanteenTable
  },
  mixins: [commonDataMixin],
  data () {
    return {
      headers: [
        {
          minWidth: 170,
          headerName: this.$t('basic.ID'),
          field: 'id'
        },
        {
          minWidth: 250,
          headerName: this.$t('canteen.canteen'),
          field: 'category_name'
        },
        {
          minWidth: 160,
          headerName: this.$t('canteen.items'),
          field: 'item_name'
        },
        {
          minWidth: 95,
          headerName: this.$t('basic.status'),
          field: 'status',
          cellRendererFramework: 'CellRendererStatus'
        },
        {
          minWidth: 95,
          headerName: this.$t('canteen.store'),
          field: 'store_name',
          cellRendererFramework: 'CellRendererStatus'
        },
        {
          minWidth: 95,
          headerName: this.$t('canteen.salePrice'),
          field: 'sales_price',
          cellRendererFramework: 'CellRendererStatus'
        },
        {
          minWidth: 120,
          headerName: this.$t('basic.actions'),
          filter: false,
          field: 'actions',

          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            actions_prop: [
              {
                dropDown: true,
                dropDown_items: [
                  {
                    title: this.$t('canteen.showItems'),
                    parameter_name: 'id',
                    icon: 'EyeIcon',
                    navigate_action: true,
                    route_name: 'StudentProfile',
                    resource: 'student/'
                  },
                  {
                    title: this.$t('basic.update'),
                    parameter_name: 'id',
                    icon: 'Edit3Icon',
                    navigate_action: true,
                    route_name: 'edit-student',
                    resource: '/student-affairs/edit-student/:id'
                  },
                  {
                    title: this.$t('canteen.suspend'),
                    parameter_name: 'id',
                    icon: 'EditIcon',
                    actionEmitter: 'activeAddNote',
                    actionEmitterParams: ['id']
                  },
                  {
                    title: this.$t('basic.archive'),
                    parameter_name: 'id',
                    icon: 'ArchiveIcon',
                    actionEmitter: 'activeAddNote',
                    actionEmitterParams: ['id']
                  }
                ]
              }
            ]
          }
        }
      ],
      searchResult:[
        {
          id:365434131,
          category_name: 'Bakery',
          item_name: 'Cheese',
          store_name: 'Oasis',
          sales_price: 20,
          status: 'Active'
        }
      ],
      loading:false
    }
  },
  watch:{
  },
  computed: {
  }
}
</script>

<style lang="scss" scoped>
.active .vx-card {
  -webkit-box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
  box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
}
</style>
