<template>
  <div>
    <vx-card class="p-3 py-6 md:p-6 mx-auto" :show-loading="loading">
      <div>
        <div class="vx-row w-full lg:w-4/5">
          <div class="vx-col w-full mt-8">
            <div class="vx-row">
              <h6 class="vx-col w-1/2 my-2 lg:m-0 lg:w-1/5 text-center">
                {{ $t('basic.divisions') }}:
              </h6>
              <p class="vx-col w-1/2 my-2 lg:m-0 lg:w-4/5">
                {{ syllbus.divisionName }}
              </p>
            </div>
          </div>
          <div class="vx-col w-full mt-8">
            <div class="vx-row">
              <h6 class="vx-col w-1/2 my-2 lg:m-0 lg:w-1/5 text-center">
                {{ $t('basic.grade') }}:
              </h6>
              <p class="vx-col w-1/2 my-2 lg:m-0 lg:w-4/5">
                {{ syllbus.gradeName }}
              </p>
            </div>
          </div>
          <div class="vx-col w-full mt-8">
            <div class="vx-row">
              <h6 class="vx-col w-1/2 my-2 lg:m-0 lg:w-1/5 text-center">
                {{ $t('basic.subject') }}:
              </h6>
              <p class="vx-col w-1/2 my-2 lg:m-0 lg:w-4/5">
                {{ getSubject(details_ids.subject_id).name }}
              </p>
            </div>
          </div>
        </div>
        <form @submit.prevent="submitForm" @keyup.enter="submitForm">
          <div
            class="vx-row text-center w-full mt-10 items-center"
            v-for="(classroom, index) in classes"
            :key="`${classroom.name} ${index}`">
            <div class="vx-col w-full my-2 lg:m-0 lg:w-1/5">
              <label>{{ classroom.name }} </label>
            </div>
            <div class="vx-col w-full my-2 lg:m-0 lg:w-4/5">
              <v-select
                multiple
                label="name"
                :reduce="(option) => option.user_id"
                :options="classroom.students"
                v-model="classroom.assigned"
                placeholder="Assign Student"
                @input="checkDiff(index)" />
              <vs-input
                v-validate="'required'"
                class="hidden"
                :name="`classroom_${classroom.name}`"
                v-model="classroom.assigned[0]" />
              <span class="text-danger text-sm">{{
                errors.first(`classroom_${classroom.name}`) | text_formatter
              }}</span>
            </div>
          </div>
          <div class="mt-12 flex justify-end">
            <vs-button @click.native="submitForm" class="mb-5">
              {{ $t('basic.submit') }}
            </vs-button>
          </div>
        </form>
      </div>
    </vx-card>
  </div>
  <!-- </modal> -->
</template>

<script>
// import vSelect from 'vue-select'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const SyllabusRepository = RepositoryFactory.get('AcademicModule', 'syllabus')
const AssignTeacherRepository = RepositoryFactory.get(
  'AcademicModule',
  'assignTeacher'
)
import {commonArrayMethodMixin} from '@/mixins.js'

export default {
  // components: {
  //   vselect: vSelect
  // },
  mixins: [commonArrayMethodMixin],
  data() {
    return {
      classroom: {},
      syllbus: {},
      classes: [],
      details_ids: {
        syllabus_id: Number(this.$route.params.id),
        subject_id: Number(this.$route.query.subject_id),
        division_id: null,
        grade_id: null
      },
      students: [],
      loading: false
    }
  },
  computed: {
    getSubject() {
      return (id) => {
        if (this.syllbus?.Subjects?.length) {
          return this.syllbus.Subjects.filter((subject) => subject.id === id)[0]
        }
        return ''
      }
    },
    isSubjectRequired() {
      return !this.getSubject(this.details_ids.subject_id).is_optional
    }
  },
  methods: {
    checkDiff(index) {
      const oldStudentsArr = [...this.students[index]]
      const newStudentsArr = [...this.classes[index].assigned]
      if (this.isSubjectRequired) {
        const diff = this.arr_diff(newStudentsArr, oldStudentsArr)
        let isOldStudentsArrChanged = false
        diff.forEach((element) => {
          if (oldStudentsArr.includes(Number(element)))
            isOldStudentsArrChanged = true
        })

        if (isOldStudentsArrChanged) {
          const fixedArr = [...new Set([...oldStudentsArr, ...newStudentsArr])]
          this.$nextTick(() => {
            this.classes[index].assigned = fixedArr
          })
          this.errMsg(this.$t('Syllabus.you_couldnt_uncheck_choosen_students'))
        }
      }
    },

    getSyllabus(id) {
      this.loading = true
      SyllabusRepository.get(id)
        .then((res) => {
          const data = res.data
          this.syllbus = data
          this.details_ids.division_id = data.division_id
          this.details_ids.grade_id = data.grade.id
        })
        .then(() => {
          this.getClasses()
        })
    },
    async getClasses() {
      const data = {
        ...this.details_ids
      }
      AssignTeacherRepository.getTeacherClasses(data)
        .then((res) => {
          const classes = res.data
          const classIds = []
          this.classes = classes.map((item, index) => {
            item.assigned = []
            item.un_assigned = []
            item.students = []
            this.getStudentsByClassroom(item.id).then((response) => {
              const students = response.map((student) => {
                return {
                  name: `${student.first_name} ${student.middle_name} ${student.last_name}`,
                  user_id: student.user_id
                }
              })
              this.$set(this.classes[index], 'students', students)
            })
            item.class_id = item.id
            classIds.push(item.id)
            delete item.id
            return item
          })
          this.getUnassignStudents(classIds)
        })
        .catch((err) => {
          this.errMsg(err)
        })
        .finally(() => {
          tis.loading = false
        })
    },
    getStudentsByClassroom(class_id) {
      return new Promise((resolve, reject) => {
        const data = {
          class_id,
          ...this.details_ids
        }
        AssignTeacherRepository.getStudentsByClassroom(data).then((res) => {
          resolve(res.data)
        })
      })
    },
    getUnassignStudents(classIds) {
      const data = {
        ...this.details_ids,
        class_ids: [...classIds]
      }
      AssignTeacherRepository.getUnassignStudents(data)
        .then((res) => {
          const data = res.data
          Object.keys(data).map((item) => {
            const classIndex = this.classes.findIndex(
              (class_obj) => class_obj.class_id === Number(item)
            )
            const studentIds = data[item].map((student) => student.id)
            this.classes[classIndex].assigned = studentIds
            this.students[classIndex] = studentIds
            // this.$set(this.students, classIndex, studentIds)
          })
        })
        .finally(() => {
          this.stopVSLoading()
        })
    },
    getTeacherTypeId(type) {
      return this.teacher_types.find((item) => item.flag === type).id
    },
    getTeacherType(id) {
      return this.teacher_types.find((item) => item.id === id).flag
    },
    mappingData() {
      this.classes.forEach((item) => {
        const classessIds = item.students.map((student) => student.user_id)
        item.un_assigned = classessIds.filter(
          (x) => item.assigned.indexOf(x) === -1
        )
      })
      const data = {
        ...this.details_ids,
        data: [...this.classes]
      }
      return data
    },
    submitForm() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          const payload = this.mappingData()
          this.assignStudents(payload)
        } else {
          this.errMsg()
        }
      })
    },
    assignStudents(data) {
      this.loading = true
      AssignTeacherRepository.assignStudents(data)
        .then((res) => {
          this.successMsg(res.message)
          this.$router.push({
            name: 'syllabus_assign_teacher',
            params: {
              id: this.details_ids.syllabus_id
            }
          })
        })
        .catch(this.errMsg)
        .finally(() => {
          this.loading = false
        })
    }
  },
  created() {
    this.getSyllabus(this.details_ids.syllabus_id)
  }
}
</script>

<!--
<style lang="scss" src="./assign-teacher-modal.scss"></style>
<script src="./assign-teacher-modal.js"></script>
-->
