<template>
  <div>
    <ListPageWithCreateButton :showCreateBtn="true">
      <template slot="rightSideButtonContent">
        <vs-dropdown vs-trigger-click class="w-full h-full">
          <div
            class="w-full flex items-center justify-center h-full p-3 bg-primary text-white shadow-drop rounded-lg d-theme-dark-bg cursor-pointer text-lg font-medium">
            <span class="leading-none">{{ $t('basic.create') }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu class="custom-width">
            <!-- Start Create Online Class -->
            <vs-dropdown-item
              class="p-2 w-full"
              :to="{name: 'onlineClass_create'}">
              <feather-icon icon="FilePlusIcon" svgClasses=" h-6 w-6 mr-1" />
              <span> {{ $t('onlineClass.online_meeting') }} </span>
            </vs-dropdown-item>
            <!-- End Create Online Class -->

            <!-- Start schedule Online Class -->
            <vs-dropdown-item
              class="p-2 w-full"
              :to="{name: 'onlineClass_schedule'}">
              <feather-icon icon="CalendarIcon" svgClasses=" h-6 w-6 mr-1" />
              <span> {{ $t('onlineClass.scheduled_meeting') }} </span>
            </vs-dropdown-item>
            <!-- End schedule Online Class -->

            <!-- Start Create Co online class -->
            <vs-dropdown-item
              class="p-2 w-full"
              :to="{name: 'co_online_class'}">
              <feather-icon icon="FilePlusIcon" svgClasses=" h-6 w-6 mr-1" />
              <span> {{ $t('onlineClass.co_meeting') }} </span>
            </vs-dropdown-item>
            <!-- End Create Co online class -->

            <!-- Start Create Co online class -->
            <vs-dropdown-item
              class="p-2 w-full"
              :to="{name: 'Schedule_co_online_class'}">
              <feather-icon icon="CalendarIcon" svgClasses=" h-6 w-6 mr-1" />
              <span>
                {{ $t('onlineClass.scheduled_co_meeting') }}
              </span>
            </vs-dropdown-item>
            <!-- End Create Co online class -->
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template slot="leftSideButtonContent">
        <StatusCounter
          @filter-change="changeFilter"
          :status_prop="status"
          ref="status_counter"
      /></template>

      <template slot="listDataContent">
        <!-- Start Card Of Onlone Class -->
        <div class="w-full mt-5 mb-5" ref="printMe">
          <transition-group
            class="list-transition"
            name="list-enter-up"
            tag="ul">
            <template>
              <li
                class="mb-10"
                v-for="(val, i) in onlineClasses"
                :key="val.id"
                :style="[{transitionDelay: i * 0.1 + 's'}]">
                <class-card
                  :onlineClass_prop="val"
                  @reloadData="getAllOnlineClass"
                  @counter="counter"></class-card>
              </li>
            </template>
          </transition-group>
          <template v-if="onlineClasses.length < 1">
            <p class="text-center">{{ $t('basic.no_data_available') }}</p>
          </template>
        </div>
        <!-- End Card Of Onlone Class -->

        <!-- Start Pagination -->
        <template v-if="onlineClasses.length > 0 && totalPages > 1">
          <vs-pagination
            :total="totalPages"
            :max="countPerPage"
            v-model="currentPage"
            class="mt-6" />
        </template>
        <!-- End Pagination -->
      </template>
      <template slot="rightSide">
        <class-filter
          @resetAll="getAllOnlineClass()"
          :active_prop="true"
          :filters_prop="filters"
          :rounds_prop="rounds"
          @refilter="setFilters"
      /></template>
      <!-- Start Poup Up When Create Create Zoom -->
      <vs-popup
        :title="$t('onlineClass.createZoomMeeting')"
        :active.sync="activateZoomPopup">
        <form class="p-3 py-6 md:p-6" @submit.prevent="createZoom">
          <label class="w-full">Meeting Name</label>
          <vs-input type="text" v-model="meetingName" class="w-full" />
          <label class="w-full">{{ $t('form.choose_class') }}</label>
          <v-select
            closeOnSelect
            label="name"
            :reduce="(name) => name.id"
            :options="classes"
            v-model="class_id"
            class="w-full select-large"
            name="class"
            v-validate="'required'">
          </v-select>
          <div
            class="flex flex-wrap md:justify-between justify-center items-center md:flex-row flex-col mt-10">
            <vs-button
              color="warning"
              type="border"
              @click="class_id = '' & (activateZoomPopup = false)"
              class="mt-5">
              {{ $t('form.cancel') }}</vs-button
            >
            <vs-button @click="createZoom" class="mt-5">{{
              $t('form.save')
            }}</vs-button>
          </div>
        </form>
      </vs-popup>
      <!-- End Poup Up When Create Create Zoom -->
    </ListPageWithCreateButton>
  </div>
</template>
<script>
import ClassFilter from './components/ClassFilter'
import StatusCounter from '@/components/general/StatusCounter'
import ClassCard from '@/modules/onlineClass/components/ClassCard'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'

const TeacherRepository = {
  BasicInfo: RepositoryFactory.get('TeachersModule', 'BasicInfo'),
  OnlineClass: RepositoryFactory.get('TeachersModule', 'OnlineClass'),
  Zoom: RepositoryFactory.get('TeachersModule', 'Zoom')
}
export default {
  name: 'ListOnlineClasses',
  components: {
    ClassFilter,
    StatusCounter,
    ClassCard
  },
  created() {
    this.getClasses()
    this.getAllOnlineClass()
  },
  data() {
    return {
      count: {
        all: '',
        archive: '',
        closed: '',
        ongoing: '',
        today: '',
        upcoming: ''
      },
      sidebar_filter_choosen: [],
      // ------------- pagination -----------
      totalPages: 0,
      currentPage: 1,
      countPerPage: 10,
      // ------------- pagination -----------
      topFilter: '',
      class_id: '',
      meetingName: '',
      activateZoomPopup: false,
      rounds: [
        {
          id: 1,
          name: 'class name',
          start: '5 March 2020',
          end: '5 March 2020'
        },
        {
          id: 2,
          name: 'class name',
          start: '5 March 2020',
          end: '5 March 2020'
        }
      ],
      onlineClasses: [],
      classes: [],
      printElement: null,
      printElementDimension: {
        width: null,
        height: null
      },
      pdfOptions: {
        orientation: 'portrait',
        unit: 'pt',
        fileName: 'Generated Report',
        format: {}
      }
    }
  },
  mounted() {
    this.printElement = this.$refs.printMe
    this.counter()
  },

  computed: {
    // Start Staues Of Top Cards
    status() {
      const status = [
        {
          icon: 'EyeIcon',
          value: 'all',
          name: 'All Classes',
          count: this.count.all
        },
        {
          icon: 'SmileIcon',
          value: 'upcoming',
          name: 'upcoming Classes',
          count: this.count.upcoming
        },
        {
          icon: 'ClockIcon',
          value: 'today',
          name: 'today classes',
          count: this.count.today
        },
        {
          icon: 'CheckCircleIcon',
          value: 'closed',
          name: 'closed classes',
          count: this.count.closed
        },
        {
          icon: 'XCircleIcon',
          value: 'archive',
          name: 'archived',
          count: this.count.archive
        }
      ]
      return status
    },
    // End Staues Of Top Cards

    //Start filters Of left Side Bar
    filters() {
      const custom_filter = [
        {
          id: 1,
          name: 'Classes',
          by: []
        },
        {
          id: 2,
          name: this.$t('basic.subjects'),
          by: []
        }
      ]

      // Fill all the classes

      // eslint-disable-next-line vue/no-async-in-computed-properties
      TeacherRepository.BasicInfo.getClasses().then((res) => {
        const all_classes = res.data
        all_classes.map((el) => {
          custom_filter[0]['by'].push({
            id: el.id,
            name: el.name,
            checked: false
          })
        })

        all_classes.map((el) => {
          // eslint-disable-next-line vue/no-async-in-computed-properties
          TeacherRepository.BasicInfo.getClassSubjects(el.id).then((res) => {
            const all_subjects = res.data
            all_subjects.map((subject) => {
              custom_filter[1]['by'].push({
                id: subject.id,
                name: `${subject.name} (${el.name})`,
                checked: false
              })
            })
          })
        })
      })

      return custom_filter
    },
    //End filters Of left Side Bar

    //Start filters Class Of left Side Bar
    filtered_classes() {
      let classes = []
      const allClassesFilter = this.sidebar_filter_choosen.filter(
        (val) => val.name === 'classes'
      )[0]
      if (
        allClassesFilter !== undefined &&
        allClassesFilter.checked.length > 0
      ) {
        classes = allClassesFilter.checked
      }
      return classes
    },
    //End filters Class Of left Side Bar

    //Start filters Subjects Of left Side Bar
    filtered_subjects() {
      let subjects = []
      const allsubjectsFilter = this.sidebar_filter_choosen.filter(
        (val) => val.name === 'subjects'
      )[0]
      if (
        allsubjectsFilter !== undefined &&
        allsubjectsFilter.checked.length > 0
      ) {
        subjects = allsubjectsFilter.checked
      }
      return subjects
    },
    //End filters Subjects Of left Side Bar

    //Start change Filter for Top Cards
    filtered_time_statues() {
      let filter = ''
      switch (this.topFilter) {
        case 'upcoming':
          filter = 'upcoming=true'
          break
        case 'closed':
          filter = 'closed=true'
          break

        case 'today':
          filter = 'today=true'
          break

        case 'archive':
          filter = 'archive=true'
          break

        default:
          filter = ''
          break
      }

      return filter
    }
    //End change Filter for Top Cards
  },
  watch: {
    // ---Start Pagination ---
    currentPage() {
      this.getAllOnlineClass()
    },
    // ---End Pagination ---

    //Start Status for Top Cards
    filtered_time_statues() {
      this.currentPage = 1
      this.getAllOnlineClass()
    },
    //End Status for Top Cards

    filtered_classes() {
      this.currentPage = 1
      this.getAllOnlineClass()
    },

    filtered_subjects() {
      this.currentPage = 1
      this.getAllOnlineClass()
    }
  },
  methods: {
    // --- Start Count Card --------
    counter() {
      TeacherRepository.OnlineClass.counter().then((res) => {
        this.count.all = res.data.all
        this.count.archive = res.data.archive
        this.count.closed = res.data.closed
        this.count.ongoing = res.data.ongoing
        this.count.today = res.data.today
        this.count.upcoming = res.data.upcoming
      })
    },
    // -------------Start Filter In SideBar -----------
    setFilters(val) {
      this.sidebar_filter_choosen = val
    },
    // -------------End Filter In SideBar -----------

    // ------------- Start pagination ---------
    setPagination(data) {
      this.totalPages = data.total_pages || data.last_page
      this.countPerPage = data.per_page
      this.currentPage = data.current_page
    },
    // ------------- End pagination ----------

    // Get ALl Online Classes
    getAllOnlineClass() {
      this.onlineClasses = []
      this.VSLoading()
      const stringParams = [
        `page=${this.currentPage}`,
        `${this.filtered_time_statues}`
      ]
      const params = {
        classes: this.filtered_classes,
        subjects: this.filtered_subjects
      }

      TeacherRepository.OnlineClass.getOnlineClasses(
        stringParams.filter(Boolean).join('&'),
        params
      )
        .then((res) => {
          this.setPagination(res.data)
          this.onlineClasses = res.data.data
        })
        .catch((err) => {})
        .finally(() => this.stopVSLoading())
    },

    //Change Filter Cards
    changeFilter(val) {
      this.topFilter = val
    },

    // Get ALL ClassRooms
    getClasses() {
      //create a new api request for student classes
      TeacherRepository.BasicInfo.getClasses().then((res) => {
        this.classes = res.data
      })
    },
    // Creat Zoom Meeting
    createZoom() {
      //**Show Loader **//
      this.$vs.loading()
      const data = {
        classID: this.class_id,
        name: this.meetingName
      }
      this.$validator.validate().then((valid) => {
        if (valid) {
          TeacherRepository.Zoom.createZoomMeeting(data).then((res) => {
            this.$vs.notify({
              color: 'success',
              title: 'Success',
              text: 'New Zoom Meeting has been created successfully'
            })
            this.activateZoomPopup = false
            /*this.$router.push(`./zoom-meeting/${res.zoom_meeting_id}`)*/
            const route = this.$router.resolve({
              path: `./zoom-meeting/${res.zoom_meeting_id}`
            })
            window.open(route.href, '_blank')
            //** Hide Loader **//
            this.$vs.loading.close()
          })
        } else {
          //** Hide Loader **//
          this.$vs.loading.close()
          this.$vs.notify({
            color: 'danger',
            title: 'error',
            text: 'Zoom Meeting has not been saved please complete your data'
          })
        }
      })
    },

    async printMe() {
      // convert from px to point //
      this.printElementDimension = {
        width: this.printElement.clientWidth * 0.75,
        height: this.printElement.clientHeight * 0.75
      }

      this.pdfOptions.format = this.printElementDimension

      this.pdfBase64 = await this.html2PdfBase64(
        this.printElement,
        this.pdfOptions
      )
    }
  }
}
</script>

<style lang="scss">
.custom-width {
  width: fit-content !important;
}
</style>
