<template lang="html">
  <div>
    <vs-table multiple v-model="selected" max-items="3" pagination :data="data">
      <template slot="thead">
        <vs-th v-for="item in headers" :key="item">
          {{item}}
        </vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr v-for="(exam, index) in data" :key="index">
          <vs-td>
            {{indextr}}
          </vs-td>
          <vs-td>
            {{exam.id}}
          </vs-td>
          <vs-td>
            {{exam.name}}
          </vs-td>
          <vs-td>
            {{exam.subject.name}}
          </vs-td>
          <vs-td>
            {{exam.duration_type.type}}
          </vs-td>
          <vs-td>
            {{exam.type.type}}
          </vs-td>
          <vs-td>
            <span v-if="exam.is_private_access">Private </span>
            <span v-else>Public</span>
          </vs-td>
          <vs-td>
            <span v-if="exam.auto_publish">Auto </span>
            <span v-else>Manual</span>
          </vs-td>
          <vs-td>
            <span v-if="exam.is_auto_grading">Auto </span>
            <span v-else>Manual</span>
          </vs-td>
          <vs-td>
            <vs-chip  color="success" :class="' status--success'">
              <span>{{exam.status.status}}</span>
            </vs-chip>
          </vs-td>
          <vs-td>
                      <vs-dropdown>
            <vs-button
              radius
              color="#999"
              type="flat"
              icon="settings"
            ></vs-button>

            <vs-dropdown-menu>
              <vs-dropdown-item :to="teacher_paths.exam_show">{{
                $t("basic.show")
              }}</vs-dropdown-item>
              <vs-dropdown-item :to="teacher_paths.exam_edit">{{
                $t("basic.edit")
              }}</vs-dropdown-item>
              <!-- <vs-dropdown-item>{{ $t("basic.duplicate") }}</vs-dropdown-item>
              <vs-dropdown-item>{{ $t("basic.insights") }}</vs-dropdown-item>
              <vs-dropdown-item v-if="exam.is_archived">{{
                $t("basic.unarchive")
              }}</vs-dropdown-item>
              <vs-dropdown-item v-else>{{
                $t("basic.archive")
              }}</vs-dropdown-item> -->
            </vs-dropdown-menu>
          </vs-dropdown>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import { teacher_paths } from '../../../../paths'

export default {
  data:() => ({
    teacher_paths,
    selected: [],
    users:[
      {
        'id': 1,
        'name': 'Leanne Graham',
        'username': 'Bret',
        'email': 'Sincere@april.biz',
        'website': 'hildegard.org'
      },
      {
        'id': 2,
        'name': 'Ervin Howell',
        'username': 'Antonette',
        'email': 'Shanna@melissa.tv',
        'website': 'anastasia.net'
      },
      {
        'id': 3,
        'name': 'Clementine Bauch',
        'username': 'Samantha',
        'email': 'Nathan@yesenia.net',
        'website': 'ramiro.info'
      },
      {
        'id': 4,
        'name': 'Patricia Lebsack',
        'username': 'Karianne',
        'email': 'Julianne.OConner@kory.org',
        'website': 'kale.biz'
      },
      {
        'id': 5,
        'name': 'Chelsey Dietrich',
        'username': 'Kamren',
        'email': 'Lucio_Hettinger@annie.ca',
        'website': 'demarco.info'
      },
      {
        'id': 6,
        'name': 'Mrs. Dennis Schulist',
        'username': 'Leopoldo_Corkery',
        'email': 'Karley_Dach@jasper.info',
        'website': 'ola.org'
      },
      {
        'id': 7,
        'name': 'Kurtis Weissnat',
        'username': 'Elwyn.Skiles',
        'email': 'Telly.Hoeger@billy.biz',
        'website': 'elvis.io'
      },
      {
        'id': 8,
        'name': 'Nicholas Runolfsdottir V',
        'username': 'Maxime_Nienow',
        'email': 'Sherwood@rosamond.me',
        'website': 'jacynthe.com'
      },
      {
        'id': 9,
        'name': 'Glenna Reichert',
        'username': 'Delphine',
        'email': 'Chaim_McDermott@dana.io',
        'website': 'conrad.com'
      },
      {
        'id': 10,
        'name': 'Clementina DuBuque',
        'username': 'Moriah.Stanton',
        'email': 'Rey.Padberg@karina.biz',
        'website': 'ambrose.net'
      }
    ]
  }),
  props: ['headers', 'data']
}
</script>