<template>
  <div>
  <vs-card class="p-3 py-6 md:p-6">
    <form
      class="flex justify-between flex-col"
      @submit.prevent="formSubmitted"
    >
      <div class="mb-4 mt-auto w-full vx-row items-center mx-auto">
        <div class="my-2 vx-col w-full">
          <div class="vx-row">
            <label>{{ $t("basic.email") }}<sup class="text-danger">*</sup></label>
            <vs-input
              v-model="form.official_email"
              name="Email"
              v-validate="'required|email'"
              class="w-full mt-1"
              :placeholder="`${$t('basic.email')}`"
              type="text"
            />
          </div>

          <div class="w-full">
              <span class="text-danger text-sm text-center">{{
                  errors.first("Email")
                }}</span>
          </div>
        </div>
      </div>
      <!-- Actions -->
      <div class="flex flex-wrap justify-between items-center mt-10">
        <vs-button
          color="warning"
          type="border"
          class="inline-block"
          @click="back()"
        >{{ $t("form.cancel") }}</vs-button
        >
        <vs-button class="inline-block" @click="openPopup()">
          {{ $t("form.submit") }}
        </vs-button>
      </div>
    </form>
  </vs-card>
    <vs-prompt
      @accept="formSubmitted"
      :active.sync="confirmation"
      title="Alert"
      type="confirm"
    >
      <div class="con-exemple-prompt">
        <p>{{ $t('teachersGateway.submit_teacher_parent') }} ?</p>
      </div>
    </vs-prompt>
</div>
</template>

<script>
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const addTeacherRepo = RepositoryFactory.get(
  'teachersGatewayModule',
  'addTeacher'
)
import { commonDataMixin } from '@/mixins.js'
export default {
  mixins: [commonDataMixin],
  props: {
    teacher_id: {
      type: [String, Number],
      default: () => {
        return ''
      }
    }
  },
  data () {
    return {
      confirmation : false,
      form: {
        official_email: ''
      }
    }
  },
  methods: {
    createTeacherParent () {
      addTeacherRepo
        .createTeacherParent({
          teacher_id:this.teacher_id,
          email:this.form.official_email
        })
        .then((res) => {
          this.$router.push('/teachers-gateway')
          this.successMsg(res.status)
        })
        .catch((err) => {
          console.log()
          this.errMsg(err.message)
        })
    },
    openPopup () {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.confirmation = true
        } else {
          this.errMsg()
        }
      })
    },
    formSubmitted () {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.createTeacherParent()
        } else {
          this.errMsg()
        }
      })
    },
    getEmailTeacherParent () {
      this.VSLoading()
      addTeacherRepo
        .getEmailTeacherParent(this.teacher_id)
        .then((res) => {
          this.form.official_email = res.data.parent_email
        }).catch(err => {
        this.form.official_email = ''
      }).finally(() => {
        this.stopVSLoading()
      })
    },
    back () {
      this.$router.back()
    }
  },
  created() {
    this.getEmailTeacherParent()
  },
  mounted () {
  }
}
</script>
