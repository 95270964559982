import axios from 'axios'

const resource = '/division'
export default {
  createDevision (divData) {
    return axios.post(`${resource}`, divData)
  },
  getdata () {
    return axios.get(`${resource}`)
  },
  getUsers () {
    return axios.get('/search')
  },
  getschools () {
    return axios.get('/school')
  },
  getDivision (id) {
    return axios.get(`${resource}/${id}`)
  },
  updateDevision (id, payload) {
    return axios.put(`${resource}/${id}`, payload)
  },

  //not done
  getGrades (id) {
    return axios.get(`/division/${id}/grades`)
  },
  getSectionGrades (id) {
    return axios.get(`/division/${id}/grades/section`)
  },

  activateDivision (DivisionId) {
    return axios.get(`division/activate/${DivisionId}`)
  },
  deactivateDivision (DivisionId) {
    return axios.get(`division/deactivate/${DivisionId}`)
  },

  getGradesInDivisionSection (division_id, section_id) {
    return axios.get(`/division/${division_id}/${section_id}/grades`)
  },
  getGradesInOneDivision (divisionId) {
    return axios.get(`/division/${divisionId}/grades`)
  },
  getGradesInManyDivisions (divisionsIds) {
    return axios.post('/division/getDevsGrades', {divIds:divisionsIds})
  },
  getSchoolsDivisions (payload) {
    return axios.post('/division/schoolDivisions', payload)
  },

  /////////////////////////////
  // Sections
  /////////////////////////////
  GetSection (id) {
    return axios.get(`section/${id}`)
  },
  //not done
  createSection (SectionData) {
    return axios.post('/section', SectionData)
  },
  EditSection (payload, id) {
    return axios.put(`section/${id}`, payload)
  },
  addSectionGrade (payload) {
    return axios.post('grade/assign-sections', payload)
  },
  changeSectionStatus (id, status) {
    return axios.get(`section/status/${id}/${status}`)
  },

  getDivisionsSections (divisionsIds) {
    return axios.post('/section/sectionsInDivisions', {divIds:divisionsIds})
  },
  getSectionClasses (sectionId) {
    return axios.get(`/section/${sectionId}/classes`)
  }
}
