<template>
  <div>
    <certificateForm @formSubmited="addCertificate" />
  </div>
</template>
<script>

// Components
import { commonDataMixin } from '@/mixins'
import  certificateForm from '../components/uploadWithTerm'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
import {checkValidationMixin} from "../../../mixins";
const inventoryRepository = RepositoryFactory.get(
  'inventoryRepository',
  'inventoryRepository'
)
export default {
  name: 'addCertificate',
  mixins: [commonDataMixin, checkValidationMixin],
  data () {
    return {
    }
  },
  components: {
    certificateForm
  },
  methods: {
    addCertificate (payload) {
      this.$vs.loading()
      console.log(payload)
      if (payload.term_id) {

      } else {
        inventoryRepository.addInventory(payload).then(() => {
          this.successMsg(this.$t('inventory.addInventorySuccessfully'))
          this.$router.push({name: 'inventory'})
        }).catch(err => {
          this.errMsg(err)
        }).finally(() => {
          this.$vs.stopLoading()
        })
      }
    }
  },
  created () {
  }
}
</script>
