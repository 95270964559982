<!-- --------------------------------------------------------------------------
    File Name: VxTimeline.vue
    Description: Timeline Component
    Component Name: VxTimeline
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
--------------------------------------------------------------------------= -->

<template>
  <ul class="vx-timeline">
    <li v-for="(item, i) in data" :key="i">
      <div class="timeline-icon" :class="`bg-${item.color}`">
        <feather-icon
          :icon="item.icon || 'ActivityIcon'"
          svgClasses="text-white stroke-current w-5 h-5"
        />
      </div>
      <div class="timeline-info">
        <p class="font-semibold" v-if="item.title">
          <span class="opacity-75 text-sm" v-if="item.styledTime">
            {{ item.time | text_formatter }} -
          </span>
          {{ item.name || item.title | text_formatter }}
        </p>
        <span class="activity-desc" v-if="item.desc">{{
          item.desc | text_formatter
        }}</span>
      </div>
      <small class="text-grey activity-e-time" v-if="item.time">{{
        (item.type || item.time) | text_formatter
      }}</small>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'vx-timeline',
  props: {
    data: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/components/vxTimeline.scss";
</style>
