<template>
  <div>
    <vs-card class="p-3 py-6 md:p-6">
      <form
        class="flex justify-between flex-col"
        @submit.prevent="submitForm"
        @keyup.enter="submitForm"
      >
        <div class="mb-4 mt-auto w-full vx-row items-center mx-auto">

          <!--new_fund -->
          <div class="my-2 vx-col  w-full mb-2">
            <label>{{ $t("wallet.addNewFund") }}</label>
            <vs-input
              type="number"
              v-model="fund.amount"
              class="w-full"
              name="new_fund"
            />
          </div>
          <!-- new_fund -->

          <!--new_fund -->
          <div class="my-2 vx-col  w-full mb-2">
            <label>{{ $t("wallet.setDailyLimit") }}<sup class="text-danger">*</sup></label>
            <vs-input
              type="number"
              v-model="fund.limit"
              class="w-full"
              name="daily_limit"
              v-validate="'required|min_value:1|numeric'"
            />
            <span class="text-danger text-sm">{{
                errors.first("daily_limit")
              }}</span>
          </div>
          <!-- new_fund -->

        </div>
        <!-- Actions -->
        <div class="flex flex-wrap justify-between items-center mt-auto mb-0">
          <vs-button class="mt-5 md:w-auto md:mr-0 ml-auto" @click="submitForm">
            {{ $t("form.submit") }}
          </vs-button>
        </div>
      </form>
    </vs-card>
  </div>
</template>
<script>

// import getData from './form/common_data_mixin'

import { commonDataMixin, checkValidationMixin } from '@/mixins'

export default {
  name: 'add-fund',
  props: {
    studentInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isShow: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  mixins: [commonDataMixin, checkValidationMixin],

  data () {
    return {
      fund: {
        user_id:this.$route.params.user_id,
        transable_type:'SND',
        amount: '',
        limit: ''
      }
    }
  },
  watch: {
  },
  computed: {
  },
  methods: {
    submitForm () {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.$emit('formAddFundSubmited', this.fund)
        } else {
          this.errMsg()
        }
      })
    }
  },
  created () {

  },
  mounted () {}
}
</script>
<style lang="scss">
.remove-student-attachment {
  top: 32px;
  left: 90px;
  z-index: 20;
  cursor: pointer;
  svg {
    stroke: red;
    stroke-width: 2px;
  }
}
</style>
