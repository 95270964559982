import Service from '../../services/unit-planner'
import ExtraFeatureModal from '../../modals/new-feature/new-feature.vue'
import {
  mapGetters
} from 'vuex'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import { quillEditor } from 'vue-quill-editor'
import initialComments from './initialComments'
export default {

  name: 'UnitFeatures',
  mixins:[initialComments],
  props: ['data'],
  components: {
    ExtraFeatureModal,
    quillEditor
  },
  data () {
    return {
      restService: Service,
      features: null,
      selectedFeature: null,
      newCommentObj: {
        GUID: null,
        comment: '',
        user: null
      },
      changedComments: [],
      newFeatureModalEvent: 'openCreateNewFeatureModal',
      customEvents: [
        {
          eventName: 'addExtraFeature',
          callback: this.addExtraFeature
        }
      ]
    }
  },
  created () {
    ////////////////////////////////////////////////////
    //**          Custom Event Listener           ** //
    //////////////////////////////////////////////////
    this.customEvents.forEach(function (customEvent) {
      // eslint-disable-next-line no-undef
      $sharedEvent.listen(customEvent.eventName, customEvent.callback)
    })

    this.loadAndMapData()
  },
  destroyed () {
    ////////////////////////////////////////////////////
    //**      Remove Custom Event Listener        ** //
    //////////////////////////////////////////////////
    this.customEvents.forEach(function (customEvent) {
      // eslint-disable-next-line no-undef
      $sharedEvent.off(customEvent.eventName, customEvent.callback)
    })
  },
  methods: {
    loadAndMapData () {
      this.features = this.data
      this.mappingHelper()
    },
    mappingHelper () {
      if (typeof this.features !== 'string') {
        this.features.data.features.forEach(item => {
          this.addEmptyComment(item)
          item['changedComments'] = []
        })
        this.features.data.extra_features.forEach(item => {
          item['changedComments'] = []
        })
      }
    },
    addEmptyComment (item) {
      console.log('addEmptyComment', item)
      if (!item.have_features) {
        if (!item.comments.length) {
          this.addCommentToFeature(item)
        }
      } else {
        item.features.forEach(obj => {
          if (!obj.comments.length) {
            this.addCommentToFeature(obj)
          }
          obj['changedComments'] = []
        })

      }
    },
    setSelectedFeature (feature) {
      this.selectedFeature = feature
    },
    addCommentToFeature (item) {
      const obj = {
        ...this.newCommentObj
      }
      switch (item.outlineFeature.key) {
      case 'learning-experiences-and-strategies-planning-for-self-supporting-learning': 
        //write code here
        obj.comment = this.initialComments['learning-experiences-and-strategies-planning-for-self-supporting-learning']
        break
      case 'differentiation': 
        //write code here
        obj.comment = this.initialComments['differentiation']

        break
      case 'approaches-to-learning': 
        //write code here
        obj.comment = this.initialComments['approaches-to-learning']

        break
      case 'language-and-learning': 
        //write code here
        obj.comment = this.initialComments['language-and-learning']

        break
      case 'tok-connections': 
        //write code here
        obj.comment = this.initialComments['tok-connections']

        break
      case 'cas-connections': 
        //write code here
        obj.comment = this.initialComments['cas-connections']
        break
      default:
        break
      } 
      obj.user = this.loggedInUser
      this.setSelectedFeature(item)
      this.selectedFeature.comments.push(obj)
    },
    detectChanges (comment) {
      //**Case Added New Comments **//
      if (!comment.id && !comment.GUID) {
        comment.GUID = this.uniqueID()
        this.selectedFeature.changedComments.push(comment)
      }
      //**Case Updated Comments For First Time**//
      else if (comment.id && !comment.GUID) {
        const foundedItem = this.selectedFeature.changedComments.find(item => item.id === comment.id)
        if (!foundedItem) {
          this.selectedFeature.changedComments.push(comment)
        }
      }
      //**Case Update last Created Comments after saving data again **//
      else if (!comment.id && comment.GUID && this.selectedFeature.changedComments.length === 0) {
        this.selectedFeature.changedComments.push(comment)
      }
      console.log('this.changedComments', this.selectedFeature.changedComments)
    },
    mappingData () {
      return this.selectedFeature.changedComments.map(item => {
        return {
          id: item.id || '',
          ref_type: this.selectedFeature.type,
          teacher_unit_id: this.features.teacherUnitId,
          comment: item.comment
        }
      })
    },
    save () {
      if (this.selectedFeature.changedComments.length) {
        this.restService.addCommentToOutlineFeature(this.selectedFeature.id, {
          comments: this.mappingData()
        })
          .then(res => {
            //** Reset Current Array After Saving To detect new changes **//
            this.selectedFeature.changedComments = []

            //** Replace Comments With updated or created comments with Ids from server **//
            this.selectedFeature.comments = [...res.data]

            this.$vs.notify({
              title: 'Modified Comments',
              text: 'Saved Successful',
              color: 'success',
              position: 'bottom-right'
            })
          })
          .catch(err => {
            console.error(err)
            this.$vs.notify({
              title: 'Comments Error',
              text: err.message,
              color: 'danger',
              position: 'bottom-right'
            })
          })
      }
    },
    openAddExtraFeatureModal () {
      // eslint-disable-next-line no-undef
      $sharedEvent.fire(this.newFeatureModalEvent, {
        ...this.features
      })
    },
    addExtraFeature (data) {
      data['changedComments'] = []
      this.features.data.extra_features.push(data)
    }
  },
  watch: {
    data (newValue) {
      this.features = newValue
      this.mappingHelper()
    }
  },
  computed: {
    ...mapGetters({
      loggedInUser: 'getLoggedInUserForPlanner'
    })
  }
}