/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

Vue.use(Vuex)

// import moduleTodo from './todo/moduleTodo.js'
// import moduleCalendar from './calendar/moduleCalendar.js'
// import moduleChat from './chat/moduleChat.js'
// import moduleEmail from './email/moduleEmail.js'
import moduleAuth from './auth'
import moduleMain from './main'
import poiStore from '../modules/poi/poi.store.js'
import unitPlannerStore from '../modules/unit-planner/unit-planner.store.js'
import gradingStore from '../modules/grading/grading.store.js'


export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {

    auth: moduleAuth,
    main: moduleMain,
    poiStore,
    unitPlannerStore,
    gradingStore
  },
  strict: process.env.NODE_ENV !== 'production'
})
