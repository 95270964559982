<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full xl:w-1/4 md:w-1/3 mb-5">
        <section class="side-menu-container">
          <side-menus-container
            class="p-1 m-auto w-auto"
            :student_photo="hasImg ? student_img : null"
            :siblings="siblings"
            :callBack="changeCurrentComponent"
            :menus.sync="menusData"
            :info.sync="sectionInfo"
            ref="sideMenuContainer"
          ></side-menus-container>
        </section>
      </div>
      <div class="vx-col w-full xl:w-3/4 md:w-2/3 mb-3">
        <h2 class="pb-3" v-if="!currentActiveRoute">
          {{ $t("main.Welcome") }} {{ authUser.displayFirstAndSecondName }}
        </h2>
<!--         <section class="topbar" v-if="!hideTopBar">
          <tabs-bar
            :tabs_prop="tabs"
            :action_function="changeCurrentComponent"
          ></tabs-bar>
        </section>-->

        <section class="center-content mb-3">
          <transition name="zoom-fade" mode="out-in">
            <component
              :is="currentActiveRoute"
              :student="currentStudentData"
              :params="currentActiveRouteParams"
            />
          </transition>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
// Importing LeftSidebar Components
// import Assignments from './Components/Left-Sidebar/Assignments/assignments.vue'
import Assignments from '@/modules/assignment/sub_modules/student/pages/list'
import Classwork from './Components/Left-Sidebar/Classwork'
import Exams from '@/modules/exam/sub/list'
import Gradebook from './Components/Left-Sidebar/Gradebook'
import OnlineClasses from './Components/Left-Sidebar/OnlineClasses'
import PrivateContent from './Components/Left-Sidebar/PrivateContent'
import Report from './Components/Left-Sidebar/Report'
import Repository from '@/modules/Teachers/pages/dashboard/repository/Repository'
import Results from './Components/Left-Sidebar/Results'
import Tasks from './Components/Left-Sidebar/tasks/tasks.vue'
import Timetable from './Components/Left-Sidebar/Timetable'
import wallet from './Components/Left-Sidebar/wallet'
import AllReportCard from './Components/Left-Sidebar/AllReportCard'
import MyReportCard from './Components/Left-Sidebar/MyReportCard'
import myDayParent from './Components/Left-Sidebar/myDayParent'

// CAS Components
import tasksList from '../../../cas/pages/Tasks/List'
import createTask from '../../../cas/pages/Tasks/Create'
import showTask from '../../../cas/pages/Tasks/Show'

// Importing Topbar Components
import Attendance from './Components/Topbar/Attendance'
import DocumentsResources from './Components/Topbar/DocumentsResources'
import FamilyProfile from './Components/Topbar/FamilyProfile'
import FeesPayment from './Components/Topbar/FeesPayment'

// Importing Topbar Components
import TabsBar from '@/components/profile/TabsBar'

// Importing Agenda Components
import agenda from '@/modules/agenda/pages/List'

// teaching-materials
import teaching_materials from './Components/Left-Sidebar/teaching-materials/list'

//Announcement
import announcement from '@/modules/announcement/sub-modules/students/pages/list'


import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const StudentRepository = RepositoryFactory.get('StudentsModule', 'student')
const myDayRepository = RepositoryFactory.get(
  'myDay',
  'myDay'
)
export default {
  name: 'StudentProfile',
  components: {

    // General Components
    TabsBar,
    // Topbar Components
    Attendance,
    DocumentsResources,
    FamilyProfile,
    FeesPayment,
    // LeftSidebar Components
    Assignments,
    Classwork,
    Exams,
    Gradebook,
    OnlineClasses,
    PrivateContent,
    Report,
    Repository,
    Results,
    Tasks,
    Timetable,
    tasksList,
    createTask,
    showTask,
    agenda,
    wallet,
    teaching_materials,
    announcement,
    AllReportCard,
    MyReportCard,
    myDayParent
  },
  props: { app_id: { type: Number } },
  data () {
    return {
      //////////////////////////////////
      // Route || Component
      ///////////////////////////////////
      currentActiveRoute: '',
      currentActiveRouteParams: null,
      hideTopBar: false,
      //////////////////////////////////
      // Data
      ///////////////////////////////////
      currentStudentData: {},
      siblings: [],
      menusData: [
        {
          header: this.$t('basic.tasks_and_assessments'),
          headerAction: 'tasks_and_assessments',
          items: [
            {
              title: this.$t('basic.assignments'),
              icon: 'icon-activity',
              action: 'assignments'
            },
            {
              title: this.$t('basic.tasks'),
              icon: 'icon-activity',
              action: 'tasks'
            },
            {
              title: this.$t('basic.my_drive'),
              icon: 'icon-activity',
              action: 'repository'
            }
          ]
        },
        {
          header: this.$t('basic.exams_and_results'),
          headerAction: 'exams_and_results',
          items: [
            {
              title: this.$t('basic.exams'),
              icon: 'icon-activity',
              action: 'exams'
            },
            // {
            //   title: this.$t('basic.results_base'),
            //   icon: 'icon-activity',
            //   action: 'results'
            // },
            // {
            //   title: this.$t('basic.grade_book'),
            //   icon: 'icon-activity',
            //   action: 'gradebook'
            // },
            /*  {
              title: this.$t('basic.report_card'),
              icon: 'icon-activity',
              action: 'report'
            },
            {
              title: this.$t('basic.all_report_card'),
              icon: 'icon-activity',
              action: 'AllReportCard'
            },*/
            {
              title: this.$t('inventory.my_report_card'),
              icon: 'icon-activity',
              action: 'MyReportCard'
            }
          ]
        },
        {
          header: this.$t('basic.tools'),
          headerAction: 'tools',
          items: [
            // {
            //   title: this.$t('basic.timetable'),
            //   icon: 'icon-activity',
            //   action: 'timetable'
            // },
            {
              title: this.$t('basic.agenda'),
              icon: 'icon-activity',
              action: 'agenda'
            },
            {
              title: this.$t('basic.online_classes'),
              icon: 'icon-activity',
              action: 'online-classes'
            },
            // {
            //   title: this.$t('basic.private_content'),
            //   icon: 'icon-activity',
            //   action: 'private-content'
            // },
            {
              title: this.$t('teachingMaterials.teaching_materials'),
              icon: 'icon-activity',
              action: 'teaching_materials'
            },
            {
              title: this.$t('wallet.wallet'),
              icon: 'icon-activity',
              action: 'wallet'
            },
            {
              title: this.$t('announcement.announcements'),
              icon: 'icon-activity',
              action: 'announcement'
            }
          ]
        }
      ],
      sectionInfo: {
        header: this.$t('basic.student_information'),
        items: []
      },
      student_img: null,
      tabs: [
        /*{
          title: this.$t('basic.attendance'),
          icon: 'icon-activity',
          action: 'attendance'
        },
        {
          title: this.$t('basic.family_profile'),
          icon: 'icon-activity',
          action: 'family-profile'
        }*/
        {
          title: this.$t('basic.my_day'),
          icon: 'icon-activity',
          action: 'myDayParent',
          color: 'primary'
        }
        // {
        //   title: this.$t('basic.fees_payment'),
        //   icon: 'icon-activity',
        //   action: 'fees-payment'
        // },
        // {
        //   title: this.$t('basic.documents_resources'),
        //   icon: 'icon-activity',
        //   action: 'documents-resources'
        // }
      ]
    }
  },
  computed: {
    hasImg () {
      if (this.student_img) {
        const img_types = ['jpg', 'jpeg', 'png'],
          isValidLink = !this.student_img.includes('127.0.0.1:9091') && !this.student_img.includes('localhost:8080'),
          isValidType = img_types.some(el => this.student_img.includes(el))

        return isValidLink && isValidType
      } else return false
    },
    is_DP_Student () {
      return JSON.parse(window.localStorage.getItem('authUser')).DP
    }
  },
  methods: {
    ///////////////////////////////////////
    // Change tabs
    //////////////////////////////////////
    changeCurrentComponent ({ action }) {
      // reset params
      this.currentActiveRouteParams = null

      // Set currentRoute
      this.currentActiveRoute = action

      // Show || Hide Topbar
      this.menusData.find(data => data.items.find(el => {
        if (el.action === action) {
          this.hideTopBar = el.hideTopBar || false
        }
      })
      )
      // Scroll to Top of page
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    changeActiveRouteByEmit (Component) {
      // reset params
      this.currentActiveRouteParams = null

      // check if component is in the menusData || leftSidebar menu
      let menusDataMatched = null
      this.menusData.find(data => data.items.find(el => {
        if (el.action === Component.name) {
          this.hideTopBar = el.hideTopBar || false
          menusDataMatched = el || null
        }
      })
      )

      // Load Component
      if (menusDataMatched) {
        this.changeCurrentComponent(menusDataMatched)
        // active link
        this.$refs.sideMenuContainer.$children[2].activeMenu = Component.name
      } else this.currentActiveRoute = Component.name

      // Set Params
      this.currentActiveRouteParams = Component.params || null
    },
    /////////////////////////////////////
    // Set data
    /////////////////////////////////////
    getStudentAge (birthday) {
      const age = birthday
      return age
    },

    async getStudentData (
      repoFunction = 'getdata',
      params = this.$route.params.user_id
    ) {

        this.VSLoading()
        await StudentRepository[repoFunction](params)
        .then((res) => {
          this.setCurrentStudentInfo(res.application)
          this.StopVSLoading()
        } )

    },
    setCurrentStudentInfo (data) {

      this.currentStudentData = data
      this.siblings = [this.currentStudentData]
      const birthDate = Number(this.currentStudentData.student.birth_date)
      this.student_img = this.currentStudentData.student_full_details.photo
      // Reset the items in student info
      this.sectionInfo['items'].length = 0

      // Push the new student data
      this.sectionInfo.items.push(
        {
          title: this.$t('basic.ID'),
          value: this.currentStudentData.student_code
        },

        {
          title: this.$t('basic.name'),
          value: this.currentStudentData.fullName.replace(/\s+/g, ' ') // To remove extra spaces between names
        },
        {
          title: this.$t('basic.birthday'),
          value: birthDate
            ? `${new Date(birthDate).getDate()}/${new Date(
              birthDate
            ).getMonth() + 1}/${new Date(birthDate).getFullYear()}`
            : this.$t('profile.birthday_is_not_available')
        },
        {
          title: this.$t('basic.age'),
          value: birthDate
            ? new Date().getFullYear() - new Date(birthDate).getFullYear()
            : this.$t('profile.birthday_is_not_available')
        },
        {
          title: this.$t('basic.division'),
          value: this.currentStudentData.divisionName
        },
        {
          title: this.$t('basic.grade'),
          value: this.currentStudentData.gradeName
        },
        {
          title: this.$t('basic.class_group'),
          value: this.currentStudentData.student.classGroupName  || data.student.classGroupName
        }
      )


      if (this.currentStudentData.username) {
        this.sectionInfo.items.push({
          title: this.$t('basic.username'),
          value: this.currentStudentData.username
        })
      }
      this.getRoundData(this.currentStudentData.round_id)
    },

    getRoundData (round_id) {
      StudentRepository.getRound(round_id).then(res => {
        Object.assign(this.currentStudentData, { round: res.data.roundData })
      })
    }
  },
  created () {
    // if (this.$route.params.id) this.getStudentData('getDataByAppId', this.$route.params.id.app_id)
    this.getStudentData()

    ////////////////////////
    // if suspended Student
    ////////////////////////
    if (this.is_SuspendedAuthUser) {
      const hideSections = [
        'repository',
        'gradebook',
        'report',
        'online-classes'
      ]
      this.menusData.forEach(e => {
        e.items.forEach((s, i) => {
          if (hideSections.includes(s.action)) e.items.splice(i, 1)
        })
      })
    }
    ////////////////////////
    // if DP Student
    ////////////////////////
    if (this.is_DP_Student) {
      this.menusData
        .find(el => el.headerAction === 'tasks_and_assessments')
        .items.push({
          title: this.$t('cas.cas'),
          icon: 'icon-activity',
          action: 'tasksList',
          hideTopBar: true
        })
    }
    //////////////////////////////
    // Route To Another Component
    //////////////////////////////

    myDayRepository.getAllSettings().then(res => {
      if (res.data.myday_for_student_for_web) {
        this.menusData[2].items.push({
          title: this.$t('basic.my_day'),
          icon: 'icon-activity',
          action: 'myDayParent'
        })
      }
    })


    this.$root.$on('changeActiveRoute', Component => this.changeActiveRouteByEmit(Component)
    )
  },
  beforeDestroy () {
    this.$root.$off('changeActiveRoute')
  }
}
</script>

<style scoped></style>
