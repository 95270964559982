<template>
  <div class="">
    <ul role="list" class="divide-y divide-gray-200">
      <li v-for="(info, key ) in details" :key="key" class="border mb-2 bg-white shadow">
        <span class="block">
          <div class="flex items-center px-4 py-4 sm:px-6 align-middle">
            <div class="min-w-0 flex-1 flex items-center">
              <div class="flex-shrink-0">
                <img class="h-16 w-16 rounded-full" :src="info.photo" alt="" />
              </div>
              <div class="min-w-0 flex-1 px-4 grid grid-cols-2 md:gap-4">
                <div>
                  <h4 class="font-bold text-black">{{ info.name }}</h4>
                  <h6 class="mt-2 flex items-center text-sm text-gray-500">
                    <span class="text-black">{{ info.description }}</span>
                  </h6>
                </div>
                <div>
                  <div class="h-full flex flex-col justify-center items-center">
                    <p class="text-sm text-gray-900">
                      <feather-icon v-if="info.status == 'active'" icon="CheckCircleIcon" class="text-success" svgClasses="h-4 w-4" />
                      <feather-icon v-if="info.status == 'archive'" icon="ArchiveIcon" class="text-gray" svgClasses="h-4 w-4" />
                      <span class="font-bold ml-1">{{ info.status }}</span>
                    </p>
                    <p class="mt-2 flex items-center text-sm text-gray-500">
                      <span class="text-primary"> {{info.type}} </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <vs-dropdown
                vs-trigger-click
                class="cursor-pointer"
                icon="MoreVerticalIcon"
                svgClasses="h-5 hover:text-primary cursor-pointer"
              >
              <feather-icon
                icon="MoreVerticalIcon"
                svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
              ></feather-icon>
                <vs-dropdown-menu>
                  <vs-dropdown-item v-for="(option, key) in options" :key="key">
                    <span v-if="option.routerName" class="flex items-center whitespace-no-wrap" @click="goToPage(option.routerName, option.parameter?info[option.parameter] :'')">
                      <feather-icon class="mr-2" :icon="option.icon" svgClasses="h-4 w-4" />
                      <span>{{ option.name }}</span>
                    </span>
                    <span v-if="option.archive" class="flex items-center whitespace-no-wrap" @click="$emit('archive', info[option.parameter])">
                      <feather-icon class="mr-2" :icon="option.icon" svgClasses="h-4 w-4" />
                      <span>{{ option.name }}</span>
                    </span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </div>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>


export default {
  components: {
  },
  props:{
    details: {
      type: Array
    },
    options: {
      type: Array
    }
  },
  methods: {
    goToPage (name, params = null) {
      this.$router.push({name, params: {id: params}})
    }
  }
}
</script>
<style scoped></style>
