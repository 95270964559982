<template>
  <!-- NOTIFICATIONS -->
  <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
    <vx-tooltip :text="$t('basic.Notifications')" position="bottom">
      <feather-icon
        icon="BellIcon"
        class="cursor-pointer mt-1 mr-2"
        :badge="unReadCount"
        svgClasses="h-6 w-6 cursor-pointer hover:text-primary"
        :title="$t('basic.Notifications')"
      />
    </vx-tooltip>

    <vs-dropdown-menu
      class="notification-dropdown dropdown-custom vx-navbar-dropdown"
    >
      <div class="notification-top text-center p-5 bg-primary text-white">
        <h3 class="text-white">{{ unReadCount }} {{ $t("basic.new") }}</h3>
        <p class="opacity-75">{{ $t("basic.app_notifications") }}</p>
        <div class="text-right">
          <p class="p-1 clear-all inline-block" @click="clearAll()">
            {{ $t("basic.clear_all") }}
          </p>
        </div>
      </div>

      <component
        :is="scrollbarTag"
        ref="mainSidebarPs"
        class="scroll-area--nofications-dropdown p-0"
        :settings="settings"
        :key="$vs.rtl"
        @ps-scroll-down="notificationsAreaScoll"
      >
        <ul class="bordered-items" id="contianter-notification">
          <li
            v-for="(ntf, index) in unreadNotifications"
            :key="index"
            class="flex justify-between px-4 py-4 notification cursor-pointer"
            @click="unread(ntf)"
          >
            <div class="flex items-start p-relative">
              <feather-icon
                :icon="ntf.icon"
                :svgClasses="[
                  `text-${ntf.category}`,
                  'stroke-current mr-1 h-5 w-5',
                ]"
              ></feather-icon>
              <div>
                <span
                  class="font-medium block notification-title"
                  :class="[`text-${ntf.category}`]"
                  >{{ ntf.title }}</span
                >
                <small
                  :class="[ntf.is_read === 1 ? 'font-normal' : 'font-bold']"
                  >{{ ntf.msg }}</small
                >
                &nbsp;
                <template v-if="ntf.link">
                  <template v-if="ntf.link.includes('routeName-')">
                    <a href.prevent @click="handleLinkBeforeGo(ntf.link)"
                      >{{ $t("basic.here") }}
                    </a>
                  </template>
                  <template v-else>
                    <a :href="ntf.link" v-if="ntf.link"
                      >{{ $t("basic.here") }}
                    </a>
                    <!-- Un-comment to test notification local -->
                    <!-- <a :href="notificationsQC(ntf)" v-if="ntf.link"
                      >{{ $t("basic.here") }}
                    </a> -->
                  </template>
                </template>
              </div>
            </div>
            <small
              :class="[
                ntf.is_read === 1 ? 'font-normal' : 'font-bold',
                `t-1   whitespace-no-wrap `,
              ]"
              >{{ ntf.time }}</small
            >
          </li>
        </ul>
      </component>

      <!-- <div
        class="checkout-footer fixed bottom-0 rounded-b-lg text-primary w-full p-2 font-semibold text-center border border-b-0 border-l-0 border-r-0 border-solid d-theme-border-grey-light cursor-pointer"
      >
        <span>View All Notifications</span>
      </div> -->
    </vs-dropdown-menu>
  </vs-dropdown>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import axios from 'axios'
import firebase from 'firebase'
import VxTooltip from '@/layouts/components/vx-tooltip/VxTooltip.vue'

export default {
  components: {
    VuePerfectScrollbar,
    VxTooltip
  },
  data () {
    return {
      total:'',
      page:1,
      unReadCount: '',
      unreadNotifications: [],
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      }
    }
  },
  computed: {
    comparePages () {
      if (this.page <= this.totalPages) return true
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    },

    is_student () {
      return this.authUser.userRole === 'student' || this.$acl.check('Student')
    },
    is_student_profile () {
      return (
        this.$route.name === 'BackToProfile' ||
        this.$route.name === 'StudentProfile'
      )
    },

    is_parent_profile () {
      return this.$route.name === 'ParentProfile'
    }
    // Un-comment this Computed to test nofications on local
    // notificationsQC () {
    //   return ntf => {
    //     const ntfArr = ntf.link.split('#')
    //     const newRoute = `http://localhost:8080/#${ntfArr[1]}`
    //     return newRoute
    //   }
    // }
  },
  methods: {
    ////////////////////////////////
    // APIs
    ////////////////////////////////
    getNotifications () {

      axios
        .get(`v1/notifications?page=${this.page}`)
        .then((res) => {
          this.page = res.meta.current_page
          this.totalPages = res.meta.last_page
          this.unReadCount = res.meta.un_read_count
          this.total = res.meta.total

          this.unreadNotifications.push(...res.data)
          // this.unreadNotifications = res.data

        })
        .catch()
    },
    unread (obj) {
      if (obj.is_read === 0) {
        axios
          .post(`/v1/notifications/${obj.id}/read`)
          .then(() => {
            obj.is_read = 1
            if (this.unReadCount > 0) {
              this.unReadCount--
            }

          })
          .catch()
      }
    },
    clearAll () {
      if (this.total > 0) {
        axios
          .delete('/v1/notifications/clear-all')
          .then(() => {
            this.unreadNotifications = []
            this.getNotifications()
          })
          .catch()
      }

    },
    ////////////////////////////////
    // Go To Route
    ////////////////////////////////
    //Link:ex => routeName-assignments/params--studentId-4

    handleLinkBeforeGo (link) {
      const is_haveParams = link.includes('/params'),
        // Route
        routeNamePart = link.split('/')[0],
        routeName = routeNamePart.replace('routeName-', '')

      let // Params
        paramsPart = null,
        paramsKey = null,
        paramsValue = null,
        is_paramsKey_id = false,
        params = {}

      // set params
      if (is_haveParams) {
        paramsPart = link.split('/')[1].replace('params--', '')
        paramsKey = paramsPart.split('-')[0]
        paramsValue = paramsPart.split('-')[1]
        is_paramsKey_id = ['id', 'Id', 'ID'].some((e) => paramsKey.slice(-2).includes(e)
        )
        params[paramsKey] = is_paramsKey_id
          ? parseInt(paramsValue)
          : paramsValue
      }

      this.goTo(routeName, params)
    },
    goTo (name, params = {}) {
      const activeRoute = {
        name,
        params
      }

      if (this.is_student_profile || this.is_parent_profile) {
        this.$root.$emit('changeActiveRoute', activeRoute)
      } else if (params) this.$router.push(activeRoute)
      else this.$router.push({
        name
      })
    },
    ////////////////////////////////

    elapsedTime (startTime) {
      const x = new Date(startTime)
      const now = new Date()
      let timeDiff = now - x
      timeDiff /= 1000

      const seconds = Math.round(timeDiff)
      timeDiff = Math.floor(timeDiff / 60)

      const minutes = Math.round(timeDiff % 60)
      timeDiff = Math.floor(timeDiff / 60)

      const hours = Math.round(timeDiff % 24)
      timeDiff = Math.floor(timeDiff / 24)

      const days = Math.round(timeDiff % 365)
      timeDiff = Math.floor(timeDiff / 365)

      const years = timeDiff

      if (years > 0) {
        return `${years + (years > 1 ? ' Years ' : ' Year ')}ago`
      } else if (days > 0) {
        return `${days + (days > 1 ? ' Days ' : ' Day ')}ago`
      } else if (hours > 0) {
        return `${hours + (hours > 1 ? ' Hrs ' : ' Hour ')}ago`
      } else if (minutes > 0) {
        return `${minutes + (minutes > 1 ? ' Mins ' : ' Min ')}ago`
      } else if (seconds > 0) {
        return seconds + (seconds > 1 ? ' sec ago' : 'just now')
      }

      return 'Just Now'
    },
    // Method for creating dummy notification time
    randomDate ({ hr, min, sec }) {
      const date = new Date()

      if (hr) date.setHours(date.getHours() - hr)
      if (min) date.setMinutes(date.getMinutes() - min)
      if (sec) date.setSeconds(date.getSeconds() - sec)

      return date
    },
    notificationsAreaScoll (event) {

      const
        messageArea = event.target,
        lastMessagesIntoView = (messageArea.scrollHeight - (messageArea.scrollTop + 300)) < 100

      if (lastMessagesIntoView && this.comparePages) {
        this.page++
      }

    }
  },
  watch : {
    page ()  {
      this.VSLoading()
      this.getNotifications()

      this.stopVSLoading()
    }
  },
  created () {


    //todo list notifications from api
    this.getNotifications()
    //realtime notification integration
    //

    if (!firebase.apps.length) {
      const firebaseConfig = {
        apiKey: 'AIzaSyCJuLiHrFLqcxxCfkCj5UeWeb8mSPsDPHs',
        authDomain: 'schoolia-53bdb.firebaseapp.com',
        projectId: 'schoolia-53bdb',
        storageBucket: 'schoolia-53bdb.appspot.com',
        messagingSenderId: '32984757712',
        appId: '1:32984757712:web:138303dc8171f5307375d5',
        measurementId: 'G-E2R9H0EEEP'
      }
      firebase.initializeApp(firebaseConfig)
      const that = this
      firebase.messaging().onMessage((payload) => {
        const notification = {
          title: payload.notification.title,
          msg: payload.notification.body,
          icon: 'MessageSquareIcon',
          time: '12:00',
          category: 'primary',
          link: payload.data.link
        }
        that.unreadNotifications.unshift(notification)
        console.log('Received  not ', payload)
        console.log('unreadNotifications ', that.unreadNotifications)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.clear-all {
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  &:hover {
    color: #dae1e7;
  }
}
</style>
