export default [
  //////////////////////
  // Grade Book
  /////////////////////
  {
    path: 'teacher/gradebook',
    name: 'teacherGradebook',
    component: () => import('./pages/gradebook/List'),

    meta: {
      breadcrumb: [{title: 'grading.gradebook', url: '', active: true}],
      rule: 'Teacher',
      pageTitle: 'grading.gradebook'
    }
  }
]
