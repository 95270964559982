<template>
  <div>
    <div class="justify-between items-center vx-row">
      <div
        class="mb-4"
        :class="`vx-col flex-1 w-full xl:w-1/${tabs.length} `"
        v-for="tab in tabs"
        :key="tab.action"
      >
        <button class="no_style block w-full">
          <vx-card
            @click="action_function(tab)"
            class="overflow-hidden small-card"
          >
            <div slot="no-body">
              <div
                class="p-4"
                :class="'flex justify-between flex-row-reverse items-center'"
              >
                <!-- <div  :class=""></div> -->
                <div
                  :icon="tab.icon"
                  class="feather p-2 inline-flex rounded-full"
                  :class="`status--${tab.color ? tab.color : tab.action} ${
                    tab.icon
                  }`"
                ></div>
                <div class="truncate">
                  <span>{{ tab.title }}</span>
                </div>
              </div>
            </div>
          </vx-card>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TabsBar',
  props: {
    tabs_prop: {
      type: Array,
      required: true
    },
    action_function: {
      type: Function,
      required: true
    }
  },
  watch: {
  },
  data () {
    return {
      tabs: this.tabs_prop
    }
  },
  methods: {

  },
  computed: {
    card_width () {
      return `${this.status_prop.length / 100}%`
    }
  }
}
</script>
<style lang="scss">
.vxcard {
  cursor: pointer;
  .active & {
    box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.2);
  }
}
.small-card {
  // min-width: 130px;

  .feather-icon {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .truncate {
    span {
      text-transform: capitalize;
      color: #6e6d6d;
    }
  }
}
</style>
