import {
  RepositoryFactory
} from '@/Repositories/RepositoryFactory'
const assessmentsRepo = RepositoryFactory.get('schoolPrincipalRepository', 'assessmentsRepo')

export default {

  data () {
    return {
      tasksData: [],
      teacherId: this.$route.params.id
    }
  },
  created () {
    this.fetchTeacherassessment()
  },
  methods: {
    updateGrid (data) {
      const itemIndex = this.tasksData.findIndex(item => item.id === data.id)
      if (itemIndex !== -1) {
        /*case update*/
        this.tasksData.splice(itemIndex, 1, data)
      } else {
        this.tasksData.unshift(data)
      }
    },

    fetchTeacherassessment () {
      this.VSLoading()
      //** Get IB types **//
      assessmentsRepo
        .getTeacherassessments(this.teacherId)
        .then(res => {
          // console.log('assessments', res)
          this.tasksData = res.data.sort((a, b) => b.id - a.id)
        })
        .catch(err => {
          // console.error(err)
          this.errMsg(err.data.message, this.$t('form.loading_data_error'))
        })
        .finally(() => this.stopVSLoading())
    }

  }
}
