import axios from 'axios'

const resource = '/student'
export default {

  getFilteredStudents (page, filters) {
    return axios.post(`${resource}/filters${page ? page : ''}`,
      filters
    )
  },
  getAllClasses () {
    return axios.get('/class')
  },
  getAllFilterClasses () {
    return axios.get('/filter/classes')
  },
  getAllSections () {
    return axios.get('/section')
  }
}
