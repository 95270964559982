<template>
  <ListPageWithCreateButtonSeparateRow
    createRoutName="create-students"
    :showCreateBtn="!checkUserRole('SMD')"
    :createBtnName="$t('StudentAffairs.addStudent')">
    <template slot="topRow">
      <h5 class="mb-4 text-gray">
        {{ $t('AcademicYears.AcademicYear') }} :
        <small class="status--active">
          {{ this.currentAcademicYear.name }}</small
        >
      </h5>
    </template>
    <template slot="leftSideButtonContent">
      <status-counter
        @filter-change="changeTabsFilter"
        :status_prop="allStatusCounts"
        :selected_prop="activeTab || 'current'" />
    </template>
    <template slot="listDataContent">
      <StudentAffairsTable
        :import_users="'student/import'"
        :reset_paswwords="'students/reset-password'"
        :export_csv_method="'post'"
        :export_pdf_method="'post'"
        :csv_url="'student/student/export'"
        :pdf_url="'student/student/pdf'"
        :headers_prop="headers"
        :list_url_prop="`/student/filters`"
        :general_search_prop="searchContent"
        :side_filters="sideFilters"
        :filter_type="filterTab"
        :list_items="searchResult"
        :loading="loading">
      </StudentAffairsTable>
      <!-- ///////////////////////////////////////
          // Dialogs
        ///////////////////////////////////////////// -->
      <!-- Student Add Note -->

      <vs-prompt
        @cancel="studentNote = ''"
        @accept="acceptStudentAddNote"
        :is-valid="studentAddNotevalidateForm"
        :active.sync="studentAddNote"
        title="Add Note"
        type="confirm">
        <!-- Loader -->
        <div
          v-show="loadingAddNote"
          ref="addNoteLoader"
          id="addNoteLoader"
          class="vs-con-loading__container"></div>
        <div class="con-exemple-prompt">
          <vs-textarea
            v-model="studentNote"
            class="mt-4 mb-2 w-full"
            v-validate="'required|min:5'"
            name="note" />

          <span
            v-if="!studentAddNotevalidateForm"
            class="text-sm w-full text-center"
            >{{
              $t('StudentAffairs.the_note_field_must_be_at_least_5_characters')
            }}
          </span>
        </div>
      </vs-prompt>
    </template>
    <template slot="rightSide">
      <student-affairs-filter
        @refilter="changeSideFilter"
        @resetAll="restSideFilters"
        :filters_prop="side_filter"
        :loading="loadingFilter">
        <template slot="extra_content">
          <div class="w-full mb-10">
            <vs-input
              :label-placeholder="$t('form.search_by_name_email_or_ID')"
              v-model="searchContent"
              v-validate="{email: searchContent.includes('@')}"
              class="w-full"
              name="email"
              icon="icon icon-search"
              icon-pack="feather"
              @change="changeSideFilter"
              />
            <span class="text-sm text-danger" v-if="errors.first('email')">{{
              errors.first('email') | text_formatter
            }}</span>
          </div>
        </template>
      </student-affairs-filter>
    </template>
  </ListPageWithCreateButtonSeparateRow>
</template>

<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine'
import {mapGetters} from 'vuex'
// import StudentAffairsTable from '../components/StudentAffairsTable'
import StudentAffairsTable from '@/components/general/datatable/DataTable2.vue'

// import StudentAffairsFilter from '../components/StudentAffairsFilter'
import StudentAffairsFilter from '@/components/general/RoundFilter'
import StatusCounter from '@/components/general/StatusCounter'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const AllStudentsRepository = RepositoryFactory.get(
  'studentAffairsRepository',
  'allStudents',
)
import {commonDataMixin, authUserMixin} from '@/mixins'

export default {
  components: {
    StatusCounter,
    StudentAffairsTable,
    StudentAffairsFilter,
    StatisticsCardLine
  },
  mixins: [commonDataMixin, authUserMixin],
  data() {
    return {
      resetFiltesPayload: {
        schools: [],
        academics: [],
        divisions: [],
        sections: [],
        grades: [],
        classes: [],
        status: [],
        type: [],
        general_search: "",
        // academic_id: []
      },
      sideFilters: {},
      activeTab: '',
      filterTab: '',
      all_sections_count: [],
      headers: [
        // {
        //   minWidth: 170,
        //   headerName: this.$t('basic.ID'),
        //   field: 'user_id'
        // },
        {
          minWidth: 170,
          headerName: this.$t('basic.code'),
          field: 'student_code'
        },
        {
          minWidth: 250,
          headerName: this.$t('StudentAffairs.fullName'),
          field: 'fullName',
          valueGetter:
            '`${data.student.first_name} ${data.student.middle_name} ${data.student.last_name}`'
        },
        {
          minWidth: 250,
          headerName: this.$t('StudentAffairs.email'),
          field: 'official_email',
          valueGetter:
            '`${data.student.student_email}`'
        },
        {
          minWidth: 160,
          headerName: this.$t('classes.AcademicYear'),
          field: 'academic_year'
        },
        {
          minWidth: 130,
          headerName: this.$t('StudentAffairs.division'),
          field: 'divisionName'
        },
        {
          minWidth: 130,
          headerName: this.$t('StudentAffairs.section'),
          field: 'student.sectionName'
        },
        {
          minWidth: 95,
          headerName: this.$t('StudentAffairs.grade'),
          field: 'gradeName'
        },
        {
          minWidth: 140,
          headerName: this.$t('StudentAffairs.classGroup'),
          field: 'student.classGroupName'
        },
        {
          minWidth: 120,
          headerName: this.$t('basic.status'),
          field: 'system_status',
          cellRendererFramework: 'CellRendererStatus',
          cellRendererParams: {
            status_prop: this.allStatusCounts
          }
        }
      ],
      currentAcadmicYearID: 0,
      allTypes: [],
      allStatusCounts: [
        {
          name: this.$t('StudentAffairs.current'),
          icon: 'UserCheckIcon',
          count: 0,
          value: 'current',
          color: 'success',
          active: true
        },
        {
          name: this.$t('StudentAffairs.new_students'),
          icon: 'StarIcon',
          count: 0,
          value: 'new',
          color: 'blue'
        },
        {
          name: this.$t('StudentAffairs.unassigned_students'),
          icon: 'ClockIcon',
          count: 0,
          value: 'unassigned',
          color: 'warning'
        },
        {
          name: this.$t('StudentAffairs.leaver'),
          icon: 'UserXIcon',
          count: 0,
          value: 'leaver',
          color: 'danger'
        },
        {
          name: this.$t('StudentAffairs.graduated'),
          icon: 'AwardIcon',
          count: 0,
          value: 'graduated',
          color: 'success'
        }
      ],
      // Add Note
      studentAddNote: false,
      studentAddNoteID: null,
      studentNote: '',
      loadingAddNote: false,
      // Change Status
      studentChangeStatus: false,
      studentStatus: '',
      side_filter: [
        {
          id: 1,
          name: this.$t('basic.academic_year'),
          payload_name: 'academics',
          by: []
        },
        {
          id: 2,
          name: this.$t('basic.schools'),
          payload_name: 'schools',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 3,
          name: this.$t('basic.divisions'),
          payload_name: 'divisions',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 4,
          name: this.$t('basic.sections'),
          payload_name: 'sections',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 5,
          name: this.$t('basic.grades'),
          payload_name: 'grades',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 6,
          name: this.$t('basic.class_groups'),
          payload_name: 'classes',
          by: []
        },
        {
          id: 7,
          name: this.$t('basic.status'),
          payload_name: 'status',
          by: []
        },
        {
          id: 8,
          name: this.$t('basic.types'),
          payload_name: 'type',
          by: []
        }
        // {
        //   id: 9,
        //   name: this.$t('basic.enrolment_year'),
        //   payload_name: 'academic_id',
        //   by: []
        // }
      ],
      // Search
      searchContent: '',
      searchResult: null,
      loading: false,
      loadingFilter: false
    }
  },
  watch: {
    searchContent(v) {
      if (!v) this.searchResult = null
    }
  },
  computed: {
    ...mapGetters('auth', ['authUser']),
    allCurrentStatus() {
      return this.side_filter.find((e) => e.payload_name === 'status').by
    },

    studentAddNotevalidateForm() {
      return this.studentNote.length >= 5
    },
    studentChangeStatusvalidateForm() {
      return this.studentStatus !== ''
    }
  },
  methods: {
    //////////////////////////////
    // APIs
    //////////////////////////
    getFilteredStudents() {
      AllStudentsRepository.getFilteredStudents('', this.sideFilters).then()
    },

    getAllSections() {
      AllStudentsRepository.getAllSections().then((res) => {
        const data = res.data.data
        const total_count = data
          .map((el) => el.students)
          .reduce((a, b) => a + b, 0)
        // this.allStatusCounts[0].count = total_count
      })
    },
    getAllTypes() {
      AllStudentsRepository.getStudentTypes().then((res) => {
        this.allTypes = res.data.map((el) => {
          return {name: el.name, id: el.id}
        })
      })
    },
    getCurrentAcadmicYear() {
      AllStudentsRepository.getCurrentAcadmicYear().then((res) => {
        this.currentAcadmicYearID = res.data._id
        this.sideFilters = {
          ...this.resetFiltesPayload,
          status: [],
          type: ['current'],
          academics: [this.currentAcadmicYearID]
        }
      })
    },
    getStudentsStatusCounts() {
      AllStudentsRepository.getStudentsStatusCounts().then((res) => {
        const data = res.students || res.data
        this.allStatusCounts.forEach((e) => {
          const matched = data.find((s) => s.status === e.value),
            isMatched = Boolean(matched)
          if (isMatched) e.count = matched.count
        })
      })
    },
    // studentAddNote API
    acceptStudentAddNote() {
      if (!this.studentAddNotevalidateForm)
        this.errMsg(this.$t('basic.add_text_before_save'))
      else {
        const note = {
          student_id: this.studentAddNoteID,
          note: this.studentNote
        }
        AllStudentsRepository.addStudentNote(note)
          .then(
            (res) => {
              if (res) {
                this.successMsg(this.$t('StudentAffairs.note_is_added'))
                this.endAddNote()
              }
            },
            (error) => this.errMsg(error.message)
          )
          .catch((err) => this.errMsg(err.message))
      }
    },
    // search
    search() {
      this.loading = true
      AllStudentsRepository.search(this.searchContent)
        .then((res) => {
          this.searchResult = res.students
        })
        .catch(this.errMsg)
        .finally(() => {
          this.loading = false
        })
    },
    //////////////////////////////////
    // Filtrating
    ///////////////////////////////
    changeTabsFilter(filter) {
      let id = 0
      switch (filter.toLowerCase()) {
        case 'pyp':
          id = 1
          break
        case 'dp':
          id = 3
          break
        case 'myp':
          id = 2
          break
        case 'american': // It is still Amreicam in the back-end
          id = 4
          break
        case 'allstudents':
          id = 5
          break
      }
      this.activeTab = filter
      this.filterTab = filter
      if (!filter) {
        // this.sideFilters.type = {
        //   status: [],
        //   type: ['current']
        // }
        // this.sideFilters = {
        //   type: [],
        //   academics: [this.currentAcadmicYearID]
        // }
        this.sideFilters.type = []
        if (this.sideFilters.status.length)
          this.allCurrentStatus.forEach((e) => {
            e.checked = false
          })
      } else if (filter) this.changeSideFilter('', filter)
      else this.getFilteredStudents()
    },
    startDownload(downloadLink) {
      this.successMsg(this.$t('form.Started_Downloading'))

      const downloadUrl = downloadLink
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', 'exported_data.csv')
      document.body.appendChild(link)
      link.click()
      link.remove()
    },

    ////////////////////////////
    // Sidebar Filters
    ///////////////////////////

    disableCurrentAcademicFilter(disable = true) {
      const haveAcademicYearsFiters = this.side_filter.find(
        (f) => f.payload_name === 'academics'
      ).by.length
      if (this.currentAcadmicYearID && haveAcademicYearsFiters) {
        const options = this.side_filter.find(
            (f) => f.payload_name === 'academics'
          ).by,
          optionsCurrentAcdemic = options.find(
            (s) => s.id === this.currentAcadmicYearID
          )

        optionsCurrentAcdemic.disabled = disable
        if (disable) optionsCurrentAcdemic.checked = true
      }
    },
    changeSideFilter(selected_filters, tab_filter) {

      // this.resetFiltesPayload['general_search'] = this.searchContent;

      if (selected_filters.length && selected_filters != undefined) {
        this.sideFilters = {
          ...this.resetFiltesPayload,
          type: this.sideFilters.type || []
        }



        this.sideFilters['general_search'] = this.searchContent;

        for (const v in this.sideFilters) {
          const matched = selected_filters.find((e) => e.name === v),
            assignCheckedFilters = () => {
              this.sideFilters[v] = tab_filter
                ? [...matched.checked]
                : matched.checked
            }
          // TODO Remove  one od ['academic_id', 'academics'] that related of enrollment year
          const isAcademicFilter = ['academics'].includes(v)
          if (isAcademicFilter) {
            // if default case or the currentYear is checked
            if (
              !matched.checked.length ||
              (matched.checked.length === 1 &&
                matched.checked[0] === this.currentAcadmicYearID)
            ) {
              this.sideFilters[v] = [this.currentAcadmicYearID]
              this.disableCurrentAcademicFilter(false)
            } else {
              assignCheckedFilters()
              this.disableCurrentAcademicFilter(false)
            }
          }
          if (!isAcademicFilter && matched.checked.length)
            assignCheckedFilters()
        }

        if (this.filterTab) {
          if (
            !this.sideFilters.status.length ||
            this.sideFilters.status.length > 1
          ) {
            this.activeTab = ''
          }
        }
      }

      if (tab_filter) {
        this.sideFilters.academics = [this.currentAcadmicYearID]
        const isTypeFilter = this.allTypes.some((e) => e.name === tab_filter),
          isStatusFilter = this.allCurrentStatus.some(
            (e) => e.name === tab_filter
          )

        if (isTypeFilter || isStatusFilter) {
          if (isTypeFilter && this.sideFilters.status.length) {
            this.sideFilters.status = []
            this.allCurrentStatus.forEach((e) => {
              e.checked = false
            })
          }
          if (isStatusFilter) this.sideFilters.type = []

          this.sideFilters[isTypeFilter ? 'type' : 'status'] = [tab_filter]
        } else {
          this.sideFilters.type = []
          this.sideFilters.status = []
        }

        if (isStatusFilter) {
          this.allCurrentStatus.forEach((e) => {
            e.checked = false
          })
          this.allCurrentStatus.find(
            (e) => e.name === tab_filter
          ).checked = true
        }
      }


      // this.getFilteredStudents()
    },
    restSideFilters() {
      this.sideFilters = this.resetFiltesPayload
      this.side_filter[2].by = []
      this.side_filter[3].by = []
      this.side_filter[4].by = []
      this.side_filter[5].by = []
      this.getFilteredStudents()
      this.disableCurrentAcademicFilter(true)
      this.sideFilters = {
        status: [],
        type: ['current']
      }
    },
    //////////////////////
    // Add Note
    //////////////////////
    startAddNoteLoading() {
      this.stratAddNoteLoading = true
      // addNoteLoader
      this.VSLoading(this.$refs.addNoteLoader)

      // this.stopVSLoading(this.$refs.addNoteLoader)
    },
    startAddNote(studentId) {
      this.studentAddNote = true
      this.studentAddNoteID = studentId
    },
    endAddNote() {
      this.studentAddNote = false
      this.studentAddNoteID = null
      this.studentNote = ''
    },
    ///////////////////////////////////
    // Hide , show datatble Actions
    ///////////////////////////////////
    toggleDatatbleActionsCol() {
      const showStudenActiontObj = {
          title: this.$t('StudentAffairs.show_student_profile'),
          parameter_name: 'user_id',
          icon: 'EyeIcon',
          navigate_action: true,
          route_name: 'StudentProfile',
          resource: 'student/'
        },
        actionsCol = {
          minWidth: 120,
          headerName: this.$t('basic.actions'),
          filter: false,
          field: 'actions',

          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            actions_prop: [
              {
                dropDown: true,
                dropDown_items: [
                  {
                    title: this.$t('basic.update'),
                    parameter_name: 'id',
                    icon: 'Edit3Icon',
                    navigate_action: true,
                    route_name: 'edit-student',
                    resource: '/student-affairs/edit-student/:id'
                  },
                  {
                    title: this.$t('StudentAffairs.add_Note'),
                    parameter_name: 'id',
                    icon: 'EditIcon',
                    actionEmitter: 'activeAddNote',
                    actionEmitterParams: ['id']
                  },
                  {
                    title: this.$t('StudentAffairs.suspend_student'),
                    parameter_name: 'user_id',
                    icon: 'ClipboardIcon',
                    action: 'suspended',
                    resource: '/user/:id/role/status',
                    color: 'danger',
                    showIf: (data) => data.system_status === 'active'
                  },
                  {
                    title: this.$t('StudentAffairs.graduated'),
                    parameter_name: 'user_id',
                    icon: 'FlagIcon',
                    action: 'graduated',
                    resource: '/user/:id/role/status',
                    color: 'warning',
                    showIf: (data) => data.system_status !== 'graduated'
                  },
                  // {
                  //   title:this.$t('StudentAffairs.block_student'),
                  //   parameter_name:'user_id',
                  //   icon:'SlashIcon',
                  //   action:'closed',
                  //   resource:'/user/:id/role/status',
                  //   color:'danger',
                  //   showIf:(data) => data.system_status === 'active'

                  // },
                  {
                    title: this.$t('StudentAffairs.active_student'),
                    parameter_name: 'user_id',
                    icon: 'CheckIcon',
                    action: 'active',
                    resource: '/user/:id/role/status',
                    color: 'danger',
                    showIf: (data) => data.system_status !== 'active'
                  },
                  {
                    title: this.$t('teachersGateway.copy_student_email'),
                    icon: 'CopyIcon',
                    color: 'danger',
                    actionEmitter: 'copyStudentEmail',
                    actionEmitterParams: [
                      'official_email',
                      'reset_password',
                      'school_name'
                    ]
                  },
                  {
                    title: this.$t('teachersGateway.copy_mother_email'),
                    icon: 'CopyIcon',
                    color: 'danger',
                    actionEmitter: 'copyMotherEmail',
                    actionEmitterParams: [
                      'mother_official_email',
                      'reset_password',
                      'school_name'
                    ]
                  },
                  {
                    title: this.$t('teachersGateway.copy_father_email'),
                    icon: 'CopyIcon',
                    color: 'danger',
                    actionEmitter: 'copyFatherEmail',
                    actionEmitterParams: [
                      'father_official_email',
                      'reset_password',
                      'school_name'
                    ]
                  },
                  {
                    title: this.$t('StudentAffairs.leaver'),
                    parameter_name: 'user_id',
                    icon: 'LogOutIcon',
                    action: 'leaver',
                    resource: '/user/:id/role/status',
                    color: 'danger',
                    showIf: (data) => data.system_status !== 'leaver'
                  },
                  {
                    title: this.$t('basic.chat'),
                    icon: 'MessageSquareIcon',
                    color: 'danger',
                    actionEmitter: 'goToChat',
                    actionEmitterParams: ['user_id']
                  },
                  {
                    title: this.$t('basic.act_as'),
                    icon: 'VideoIcon',
                    actionEmitter: 'actAs',
                    actionEmitterParams: ['user_id', 'fullName'],
                    showIf: () =>
                      this.checkUserRole('SND') ||
                      this.checkUserRole('SchoolPrincipal')
                  }
                ]
              }
            ]
          }
        }
      // if SND
      if (!this.checkUserRole('SMD')) {
        actionsCol.cellRendererParams.actions_prop
          .find((e) => e.dropDown)
          .dropDown_items.unshift(showStudenActiontObj)
      } else actionsCol.cellRendererParams.actions_prop = [showStudenActiontObj]

      this.headers.push(actionsCol)
    },
    getFiltersData() {
      this.getCurrentAcadmicYear()

      this.getAllAcademic().then((res) => {
        res.map((el) => {
          this.side_filter[0].by.push({
            name: el.name,
            id: el._id,
            checked: false
          })
        })
      })
      this.getFilterData().then((res) => {
        res.schools.map((el) => {
          this.side_filter[1]['by'].push({
            id: el.id,
            name: el.branch_name,
            checked: false
          })
        })
      })

      AllStudentsRepository.getAllStatus().then((res) => {
        const all_status = res.data || res.response
        all_status.map((el) => {
          // if (!this.allStatusCounts.some(e => e.value === el.flag)) {
          this.side_filter[6]['by'].push({
            id: el.flag,
            name: el.name,
            checked: false
          })
          // }
        })
      })
      // AllStudentsRepository.getAllAcademic().then((res) => {
      //   const all_academicYears = res.data
      //   all_academicYears.map((el) => {
      //     this.side_filter[8]['by'].push({
      //       id: el._id,
      //       name: el.name,
      //       checked: false
      //     })
      //   })
      // })
    },
    getDivision(chosen) {
      this.loadingFilter = true
      this.side_filter[2].by = []
      this.side_filter[3].by = []
      this.side_filter[4].by = []
      this.side_filter[5].by = []
      this.getFilterData({schools: chosen.schools})
        .then((res) => {
          res.divisions.map((el) => {
            this.side_filter[2]['by'].push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
        })
        .finally(() => {
          this.loadingFilter = false
        })
    },
    getSection(chosen) {
      this.loadingFilter = true
      this.side_filter[3].by = []
      this.side_filter[4].by = []
      this.side_filter[5].by = []
      const data = {
        schools: chosen.schools,
        divisions: chosen.divisions
      }
      this.getFilterData(data)
        .then((res) => {
          res.sections.map((el) => {
            this.side_filter[3]['by'].push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
        })
        .finally(() => {
          this.loadingFilter = false
        })
    },
    getGrade(chosen) {
      this.loadingFilter = true
      this.side_filter[4].by = []
      this.side_filter[5].by = []
      const data = {
        schools: chosen.schools,
        divisions: chosen.divisions,
        sections: chosen.sections
      }
      this.getFilterData(data)
        .then((res) => {
          res.grades.map((el) => {
            this.side_filter[4]['by'].push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
        })
        .finally(() => {
          this.loadingFilter = false
        })
    },
    getClasses(chosen) {
      this.loadingFilter = true
      this.side_filter[5].by = []
      const data = {
        schools: chosen.schools,
        divisions: chosen.divisions,
        grades: chosen.grades,
        sections: chosen.sections
      }
      this.getFilterData(data)
        .then((res) => {
          res.grades_classes.map((el) => {
            this.side_filter[5]['by'].push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
        })
        .finally(() => {
          this.loadingFilter = false
        })
    },
    copyStudentEmail(data) {
      this.copyEmail(data)
    },
    copyMotherEmail(data) {
      const obj = {
        official_email: data.mother_official_email
      }
      this.copyEmail(obj)
    },
    copyFatherEmail(data) {
      const obj = {
        official_email: data.father_official_email
      }
      this.copyEmail(obj)
    },
    copyEmail(data) {
      const text = `${data.official_email}`
      navigator.clipboard.writeText(text)
      this.successMsg(this.$t('form.Copied_success'))
    },
    goToChat(data) {
      this.$router.push({
        name: 'chat',
        params: {
          userID: data.user_id
        }
      })
    }
  },
  beforeDestroy() {
    this.$root.$off('copyStudentEmail')
    this.$root.$off('copyMotherEmail')
    this.$root.$off('copyFatherEmail')
    this.$root.$off('activeAddNote')
    this.$root.$off('onChange:schools')
    this.$root.$off('onChange:divisions')
    this.$root.$off('onChange:sections')
    this.$root.$off('onChange:grades')
    this.$root.$off('actAs')
  },
  created() {
    this.$root.$on('goToChat', (data) => this.goToChat(data))
    this.$root.$on('copyStudentEmail', (data) => this.copyStudentEmail(data))
    this.$root.$on('copyMotherEmail', (data) => this.copyMotherEmail(data))
    this.$root.$on('copyFatherEmail', (data) => this.copyFatherEmail(data))
    this.$root.$on('goToChat')
    this.$root.$on('actAs', (data) => {
      data.id = data.user_id
      data.name = `${this.$t('main.student')} - ${data.fullName}`
      this.startActAs(data)
    })

    this.getFiltersData()
    this.getAllSections()
    this.getAllTypes()
    this.getStudentsStatusCounts()
    this.toggleDatatbleActionsCol()

    this.$root.$on('onChange:schools', this.getDivision)
    this.$root.$on('onChange:divisions', this.getSection)
    this.$root.$on('onChange:sections', this.getGrade)
    this.$root.$on('onChange:grades', this.getClasses)

    this.$root.$on('activeAddNote', (data) => this.startAddNote(data.id))
  },
  beforeCreate() {}
}
</script>

<style lang="scss" scoped>
.active .vx-card {
  -webkit-box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
  box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
}
</style>
