import axios from 'axios'

const resource = '/zoom-integration'
export default {

    createZoomMeeting (params) {
        return axios.get(`${resource}/class/${params.classID}/create-meeting?meetingName=${params.name} | Class 3&forceCreate=1`)
    },
    endZoomMeeting (id) {
        return axios.get(`${resource}/online-class/${id}/end-meeting`)
    }
}