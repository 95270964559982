import Vue from 'vue'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import moment from 'moment'

// Api
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'

const CommonDataRepo = RepositoryFactory.get(
    'ConfigurationModule',
    'CommonData'
  ),
  getCurrentAcademicYear = () => {
    if (Vue.currentAcademicYear?.data?._id || Vue.currentAcademicYearId) return
    return new Promise((resolve, reject) => {
      CommonDataRepo.getCurrentAcadmicYear()
        .then((res) => {
          res.data.simpleTermsArr = res.terms
          resolve(res)
        })
        .catch((err) => reject(err))
    })
  }

Vue.mixin({
  data() {
    return {}
  },
  computed: {
    /////////////////////////
    //authuser
    /////////////////////////
    is_authUser() {
      return this.$store.getters['auth/isUserLoggedIn']
    },
    authUser() {
      return (
        this.$store.getters['auth/authUser'] ||
        JSON.parse(window.localStorage.getItem('authUser')) ||
        {}
      )
    },
    authUserStudent() {
      const studentInfo = this.authUser.student
      if (studentInfo)
        studentInfo.fullName = `${studentInfo.first_name} ${
          studentInfo.middle_name || ''
        } ${studentInfo.last_name || ''}`
      return studentInfo
    },

    authUserStatus() {
      return this.authUserStudent && this.authUserStudent.status
        ? [this.authUser.status, this.authUserStudent.status]
        : this.authUser.status || null
    },
    is_SuspendedAuthUser() {
      return this.authUserStatus
        ? this.authUserStatus.includes('suspended')
        : false
    },
    authUserName() {
      return this.authUserStudent
        ? this.authUserStudent.fullName
        : this.authUser.displayName
    },
    // act as
    isActAsMode() {
      // return this.$store.getters['auth/isUserLoggedInActAs'] || Boolean(localStorage.getItem('actAsOriginalAuthUser'))
      return (
        this.$store.getters['auth/isUserLoggedInActAs'] ||
        Boolean(
          JSON.parse(window.localStorage.getItem('actAsOriginalAuthUser'))
        )
      )
    },

    ///////////////
    // AppInfo
    ///////////////
    institutionApiRes() {
      return this.$store.getters['main/institutionApiRes']
    },
    institutionDriverLink() {
      return this.$store.getters['main/institutionDriverLink']
    },
    siteLink() {
      return this.$store.getters['main/siteLink']
    },
    siteTitle() {
      return this.$store.getters['main/siteTitle']
    },
    appName() {
      return this.$store.getters['main/appName']
    },
    appLink() {
      return this.$store.getters['main/appLink']
    },
    institutionAssetsLink() {
      return this.$store.getters['main/institutionAssetsLink']
    },

    logoImg() {
      return this.$store.getters['main/logoImg']
    },
    current_locale() {
      return this.$store.getters['main/currentLocale']
    },
    currentDateString() {
      return new Date().toLocaleString('en-us')
    },
    currentDate() {
      return new Date(this.currentDateString)
    },
    currentOnlyDate() {
      return new Date().toLocaleDateString('en-us')
    },
    themePrimaryColor() {
      return this.$store.getters.themePrimaryColor
    },
    institutionShortName() {
      return this.$store.getters['main/institutionShortName']
    },
    institutionLongName() {
      return this.$store.getters['main/institutionLongName']
    },
    currentAcademicYearId() {
      return this.$store?.getters['main/currentAcademicYearId']
    },
    currentAcademicYearData() {
      return this.$store?.getters['main/currentAcademicYearData']
    },
    currentAcademicYear: {
      get() {
        return this.$store?.getters['main/currentAcademicYearData']
      },
      set(v) {
        this.$store.commit('main/setAcademicYearData', v)
      }
    },
    //////////////////////
    //  UI
    /////////////////////
    windowWidth() {
      return this.$store.state.windowWidth
    },
    isMobileWebView() {
      const platform = window.localStorage.getItem('platform')
      return platform && platform === 'mobile'
    }
  },
  methods: {
    /////////////////////////////////////////////////
    // Download File
    /////////////////////////////////////////////////
    toDataURL(requestUrl) {
      const proxyUrl = 'https://cors-anywhere.herokuapp.com/'
      return fetch(requestUrl)
        .then((res) => {
          return res.blob()
        })
        .then((blob) => {
          return URL.createObjectURL(blob)
        })
    },
    async downloadFile(url, name = '') {
      this.VSLoading()
      this.successMsg(this.$t('form.Started_Downloading'))

      const a = document.createElement('a')
      a.href = await this.toDataURL(url)
      a.download = name || 'Attachment'
      a.target = '_blank'
      document.body.appendChild(a)
      a.click()
      this.stopVSLoading()
      document.body.removeChild(a)
    },
    // Download Directly from the link
    startDownloadExporting(url, name = '', inAnotherPage = false) {
      this.successMsg(this.$t('form.Started_Downloading'))

      const downloadUrl = url.toString(),
        fileName = name || downloadUrl.split('/').slice(-1),
        link = document.createElement('a')

      link.href = downloadUrl
      link.setAttribute('download', fileName)

      if (inAnotherPage) link.setAttribute('target', '_blank')

      document.body.appendChild(link)
      link.click()
      link.remove()
    },

    /////////////////////////////////////////////////
    // Export to excel sheet
    /////////////////////////////////////////////////
    exportToExcel(headers, list, filename) {
      const headerTitle = [],
        headerVal = []
      import('@/vendor/Export2Excel').then((excel) => {
        headers.forEach((e) => {
          const th = e.headerName || e.title || e.name,
            td = e.field || e.field_name
          if (th) {
            headerTitle.push(th)
            headerVal.push(td)
          }
        })

        const data = this.formatJson(headerVal, list)
        excel.export_json_to_excel({
          header: headerTitle,
          data,
          filename
        })
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          // Add col name which needs to be translated
          // if (j === 'timestamp') {
          //   return parseTime(v[j])
          // } else {
          //   return v[j]
          // }

          return v[j]
        })
      )
    },
    /////////////////////////////////////////////////
    // HTML TO PDF
    /////////////////////////////////////////////////
    async $html2Image(el) {
      //** Convert HTML To Image **//
      const options = {
        type: 'dataURL',
        allowTaint: true,
        useCORS: true,
        ignoreElements: (node) => {
          return node.nodeName === 'IFRAME'
        }
      }

      const canvas = await html2canvas(el, options)
      return canvas.toDataURL('image/jpeg', 1.0)
    },
    img2Pdf(img, option) {},
    imgs2Pdf(imgs, dim, option, downloadFile = true) {
      const pdf = new jsPDF('l', 'px')

      //** Convert Image To Pdf By Drawing it inside pdf  **//
      imgs.forEach((img, index) => {
        const imgData = img
        pdf.internal.pageSize.setWidth(dim[index].width)
        pdf.internal.pageSize.setHeight(dim[index].height)
        // Get Correct Scale //
        const width = pdf.internal.pageSize.getWidth()
        const height = pdf.internal.pageSize.getHeight()
        pdf.addImage(imgData, 'JPEG', 0, 0, width, height)

        if (index < imgs.length - 1) {
          pdf.addPage()
        }
      })
      if (downloadFile) pdf.save(option.fileName)
      const file = new File(
        [pdf.output('blob', {filename: option.fileName})],
        `${option.fileName}.pdf`
      )
      return file
    },
    async html2PdfBase64(element, pdfOption) {
      //** Step 1- Convert HTML To Image **//
      const output = await this.$html2Image(element)
      console.log('HTML@Image>>>>', output)
      //** Step 2- Convert Image To PDF Then Get Base 64 **//
      const pdfBase64 = this.img2Pdf(output, pdfOption)

      return pdfBase64
    },
    async html2PdfBase64NoPrint(element) {
      //** Step 1- Convert HTML To Image **//
      const output = await this.$html2Image(element)
      return output
    },

    ////////////////////////////
    // Check Devices
    ////////////////////////////
    checkDeviceType() {
      let isTouchDevice = false
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        isTouchDevice = true
      } else {
        isTouchDevice = false
      }
      return isTouchDevice
    },
    checkIosDevice() {
      let isIosDevice = false
      if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        isIosDevice = true
      } else {
        isIosDevice = false
      }
      return isIosDevice
    },
    /////////////////////////////
    // Text formatter
    /////////////////////////////
    text_formatter(v) {
      try {
        if (!v) return ''
        else {
          const firstLetter = v.split('').filter(Boolean)[0]
          return v
            .replace(/[_-]/g, ' ')
            .replace(firstLetter, firstLetter.toUpperCase())
        }
      } catch (err) {
        console.group('text_formatter filter')
        console.log('text', v)
        console.log('err', err)
        console.groupEnd()
      }
    },
    /////////////////////////////
    // Times && Dates
    /////////////////////////////

    // Get Dates
    getDateOnly(date = null, revese) {
      date = new Date(date) || this.currentDate(Date)
      return new Date(date.getFullYear(), date.getMonth(), date.getDate())
    },

    getCurrentDate(
      dateType = String,
      onlyDate = null,
      onlyTime = null,
      options = null
    ) {
      const date = new Date().toLocaleString('en-us', options)
      let result = null
      switch (dateType) {
        case String:
          result = onlyDate
            ? date.split(',')[0]
            : onlyTime
            ? date.split(',')[1]
            : date
          break

        case Date:
          result = onlyDate
            ? this.getDateOnly(date)
            : onlyTime
            ? date.split(',')[1]
            : new Date(date)
          break
        default:
          result = date
          break
      }
      return result
    },
    ///////////////
    // Formats
    ///////////////

    //  Date  DD-MM-yyyy ( sometimes gives toJSON error)
    formatDate(date) {
      let newDate = date
      if (this.checkIosDevice()) {
        newDate = new Date(date.replace(' ', 'T'))
      }
      // this will return date as DD-MM-yyyy //
      return new Date(newDate)
        .toJSON()
        .slice(0, 10)
        .split('-')
        .reverse()
        .join('-')
    },

    // Date  DD-MM-yyyy The alternative to the formatDate method
    formatDate2(date) {
      return this.formatDateTime(new Date(date))
        .split(' ')[0]
        .split('-')
        .reverse()
        .join('-')
    },

    // DD-MM-yyyy hh:mm : delayed two hours
    formatDateTime(date) {
      // this will return date as DD-MM-yyyy hh:mm : delayed two hours //
      // return `${new Date(date).toJSON().slice(0, 10).split('-').join('-')} ${new Date(date).toJSON().slice(11, 16)}`
      return `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(
        -2
      )}-${`0${date.getDate()}`.slice(
        -2
      )} ${date.getHours()}:${date.getMinutes()}`
    },

    // Time 12 Formate
    changeTime12Formate(time) {
      const hh = time.split(':')[0],
        hh_num = Number(hh) - 12 || 12,
        hh_num_str = `${hh_num}`,
        mm = time.split(':')[1]

      if (parseInt(hh) >= 12)
        return `${hh_num_str.length < 2 ? `0${hh_num}` : hh_num}:${mm} PM`
      else return `${hh}:${mm} AM`
    },

    // Diffs
    daysBetweenDates(date1, date2) {
      let Difference_In_Time
      let Difference_In_Days
      date1 = new Date(date1)
      date2 = new Date(date2)
      if (this.getDateOnly(date1) > this.getDateOnly(date2)) {
        // To calculate the time difference of two dates
        Difference_In_Time = date1.getTime() - date2.getTime()
        // To calculate the no. of days between two dates
        Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24)
      } else if (this.getDateOnly(date2) > this.getDateOnly(date1)) {
        // To calculate the time difference of two dates
        Difference_In_Time = date1.getTime() - date2.getTime()
        // To calculate the no. of days between two dates
        Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24)
      } else {
        // To calculate the time difference of two dates
        Difference_In_Time = date1.getTime() - date2.getTime()
        // To calculate the no. of days between two dates
        Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24)
      }
      // To handle if the time is over
      if (Difference_In_Days > -1 && Difference_In_Days < 0) {
        Difference_In_Days = -1
      }
      //To display the final no. of days (result)
      return this.toRound(Difference_In_Days, 0)
    },

    // get Dates between 2 dates
    dateBetween2Dates(startDate, endDate, formatted = false) {
      const arr = []
      for (
        let dt = new Date(startDate);
        dt <= new Date(endDate);
        dt.setDate(dt.getDate() + 1)
      ) {
        arr.push(new Date(dt))
      }
      return formatted ? arr.map((e) => this.formatDate2(e)) : arr
    },
    // fix Date formate by moment
    fixDate(date) {
      return moment(Number(date)).format('YYYY-MM-DD')
    },
    // `YYYY-MM-DD HH:mm`
    checkTimeDiff(dateTime) {
      const timeNow = moment(new Date())
      const due_dateTime = moment(dateTime)
      const diff = due_dateTime - timeNow
      return diff > 0
    },

    ////////////////////////////
    //  GET ID
    ////////////////////////////
    youTubeGetID(url) {
      url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
      return url[2] !== undefined ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0]
    },

    uniqueID() {
      // Math.random should be unique because of its seeding algorithm.
      // Convert it to base 36 (numbers + letters), and grab the first 9 characters
      // after the decimal.
      return `_${Math.random().toString(36).substr(2, 9)}`
    },
    toRound(number, fraction) {
      const final = Number(
        `${Math.round(`${number}e+${fraction}`)}e-${fraction}`
      )
      return Number(final.toFixed(fraction))
    },
    toPercent(value, total) {
      return Math.round((value / total) * 100)
    },
    ////////////////////////////
    // CLONING
    ////////////////////////////
    cloneItem(obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    /////////////////////////////
    // GOTO
    /////////////////////////////
    goTo(name, params = {}, query = {}) {
      const activeRoute = {
        name,
        params,
        query
      }

      if (this.is_student_profile || this.is_parent_profile) {
        this.$root.$emit('changeActiveRoute', activeRoute)
      } else if (params || query) this.$router.push(activeRoute)
      else
        this.$router.push({
          name
        })
    },
    /////////////////////////////
    // loading
    /////////////////////////////
    VSLoading(container = null, type = null) {
      if (container || type) this.VsLoadingContained(container, type)
      else this.VsLoadingFull()
    },
    stopVSLoading(container = null, type = null) {
      if (container || type) this.stopVsLoadingContained(container, type)
      else this.stopVsLoadingFull()
      //  remove any default loader in the page
      const activeDOMLoaders = document.querySelectorAll('body>.con-vs-loading')
      activeDOMLoaders.forEach((e) => e.remove())
    },
    VsLoadingFull() {
      this.$vs.loading()
    },
    stopVsLoadingFull() {
      this.$vs.loading.close()
    },

    VsLoadingContained(container, type) {
      this.$vs.loading({
        container,
        scale: type === 'button' ? 0.45 : 0.6
      })
    },
    stopVsLoadingContained(container) {
      this.$vs.loading.close(container)
    },

    // Button Submit Loading
    // submit loader
    async startLoadingSubmit() {
      await (this.loadSubmit = true)
      this.VsLoadingContained('#submitLoader')
    },
    endLoadingSubmit() {
      this.stopVsLoadingContained('#submitLoader> .con-vs-loading')
      this.loadSubmit = false
    },
    ////////////////////////////////
    // Notify
    ////////////////////////////////

    // success
    successMsg(msg = null, title = null, time = null) {
      this.$vs.notify({
        title: title || this.$t('basic.success'),
        text: this.text_formatter(msg || this.$t('form.the_data_is_submitted')),
        color: 'success',
        iconPack: 'feather',
        icon: 'icon-check-circle',
        time: time || 5000
      })
    },
    // err
    errMsg(err = null, title = null, time = null) {
      const msg = err
        ? err.data
          ? err.data.message ||
            err.data.msg ||
            err.data.data ||
            err.data.error?.message ||
            err.data.error ||
            err.data
          : err.error
          ? err.error.message
          : err.message || err.msg || err
        : null

      this.$vs.notify({
        title: title || this.$t('basic.Error'),
        text: this.text_formatter(
          msg || `${this.$t('form.Enter_Required_Data')}!`
        ),
        color: 'danger',
        iconPack: 'feather',
        icon: 'icon-x-circle',
        time: time || 5000
      })
    },

    // Delete
    deleteMsg(msg = null, title = null, time = null) {
      this.$vs.notify({
        title: title || this.$t('form.Deleted'),
        text: this.text_formatter(msg || this.$t('form.delete_successfully')),
        color: 'danger',
        iconPack: 'feather',
        icon: 'icon-x-circle',
        time: time || 5000
      })
    },

    // Warning
    warningMsg(msg = null, title = null, time = null) {
      this.$vs.notify({
        title: title || this.$t('form.Warning'),
        text: this.text_formatter(
          msg || this.$t('form.Please_fill_all_data_first')
        ),
        color: 'warning',
        iconPack: 'feather',
        icon: 'icon-close-circle',
        time: time || 5000
      })
    },
    ////////////////////////////////
    // Dialogs
    ////////////////////////////////
    dangerDialog(
      title,
      text,
      acceptCallback,
      acceptText = null,
      cancelCallback
    ) {
      this.confirmDialog(
        title,
        text,
        acceptCallback,
        'danger',
        acceptText,
        cancelCallback
      )
    },
    confirmDialog(
      title,
      text,
      acceptCallback,
      color = null,
      acceptText = null,
      cancelCallback
    ) {
      this.$vs.dialog({
        type: 'confirm',
        color: color || 'primary',
        title: this.text_formatter(title || this.$t('basic.confirm')),
        text: this.text_formatter(text),
        accept: () => {
          {
            if (typeof acceptCallback === 'function') acceptCallback()
          }
        },
        acceptText,
        cancel: () => {
          if (typeof cancelCallback === 'function') cancelCallback()
        }
      })
    },
    ////////////////////////////////
    // Dealing with LocalStorage
    ////////////////////////////////
    UpdateLocalStorageAuthUser(key, value) {
      const authUser = JSON.parse(localStorage.getItem('authUser'))
      authUser[key] = value
      localStorage['authUser'] = JSON.stringify(authUser)
    },

    ///////////////////////////////
    // AuthUser
    ///////////////////////////////
    checkUserRole(roleName) {
      //** added authUser for checking if one of the acl group's roles is matched */
      return this.$acl.check(roleName) || this.authUser.userRole === roleName
    },

    ////////////////////////////
    // Pagination
    ////////////////////////////
    setPagination(data) {
      data = data.meta || data
      this.totalPages =
        data.total_pages || data.totalPages || data.last_page || 0
      this.countPerPage = data.per_page || data.countPerPage || 0
      this.currentPage = data.current_page || data.currentPage || 1
    },
    /////////////////////////////
    // Generate Password
    ////////////////////////////
    generateRandomPassword(length = 8) {
      return Math.random().toString(36).slice(-length)
    },
    ////////////////////////////////
    // Convert Text to HyperLink
    ///////////////////////////////
    textToHyperlink(text) {
      text = text.split(' ')
      text = text.map((w) => {
        if (w.includes('http')) w = `<a href="${w}" target="_blank">${w}</a>`
        return w
      })
      return text.join(' ')
    },
    ////////////////////////////////
    // Scrolling
    ///////////////////////////////
    scrollTo(refElement, direction = 'up', scrollMount = null) {
      this.$nextTick(() => {
        if (!this.$refs[refElement]) return
        const scroll_el = this.$refs[refElement].$el || this.$refs[refElement]
        scroll_el.scrollTop = scrollMount
          ? scrollMount
          : direction === 'up'
          ? 0
          : scroll_el.scrollHeight
      })
    },
    scrollToTop(refElement) {
      this.scrollTo(refElement, 'up')
    },
    scrollToBottom(refElement) {
      this.scrollTo(refElement, 'down')
    },
    //////////////////////////
    getCurrentAcademicYear,
    handleSetCurrentAcademicYear(res) {
      const termsCount = res.data?.terms.length || res.terms.length || 0
      if (!res.data?.start_date || !res.data?.end_date) {
        this.currentAcademicYear.startDate =
          res.data.start_date ||
          (termsCount ? res.data?.terms[0]?.start_date : null)
        this.currentAcademicYear.endDate =
          res.data.end_date || termsCount
            ? res.data?.terms[termsCount - 1]?.end_date
            : null
      }

      this.currentAcademicYear = res.data
    }
  },
  mounted() {
    // if (!this.currentAcademicYearId) this.getCurrentAcademicYear()
  }
})
////////////////////////////////
// authUser
////////////////////////////////
export const authUserMixin = {
  computed: {
    //////////////////////
    // Check Users Roles
    //////////////////////

    is_teacher() {
      return this.checkUserRole('Teacher')
    },
    is_parent() {
      return this.checkUserRole('Parent')
    },
    is_student() {
      return (
        this.authUser.userRole === 'student' || this.checkUserRole('Student')
      )
    },
    is_schoolPrincipal() {
      return this.checkUserRole('SchoolPrincipal')
    },
    is_DP() {
      return this.authUser.DP
    },
    is_coordinator() {
      return this.checkUserRole('CasCoordinator')
    },
    is_SMD() {
      return this.checkUserRole('SMD')
    },
    is_sectionsCoodinator() {
      return this.checkUserRole('SectionsCoodinator')
    },
    //////////////////////
    // Check Routes
    //////////////////////
    is_student_profile() {
      return (
        this.$route.name === 'BackToProfile' ||
        this.$route.name === 'StudentProfile'
      )
    },
    is_parent_profile() {
      return this.$route.name === 'ParentProfile'
    },
    //////////////////////
    // Get User Info
    //////////////////////
    userId() {
      return this.authUser.uid
    },
    student_id() {
      return this.checkUserRole('Student') ? this.userId : null
    }
  },
  methods: {
    /////////////////////////////
    // Set Authuser
    ////////////////////////////
    async setDefaultAuthUserSettings(user, updateRoute = false) {
      await this.$acl.change(user.role_name || user.userRole)
      if (updateRoute) await this.pushDefaultRoutes(user)

      // Get assets
      this.$store
        .dispatch('main/getInstitutionData')
        .then((res) => this.$vs.theme({primary: res.brandColor}))
    },
    async pushDefaultRoutes(user) {
      const userRole = user.role_name || user.userRole
      if (this.checkUserRole('Student')) {
        await this.$router.replace(
          this.$route.query.redirect ||
            `/student/profile/${user.id || user.uid}`
        )
      } else if (this.checkUserRole('Parent')) {
        if (!user.is_completed) {
          await this.$router.replace(
            this.$route.query.redirect || '/parent/profile'
          )
        } else
          await this.$router.replace(
            this.$route.query.redirect || '/parent/profile'
          )
      } else if (this.$route.name !== 'Home')
        await this.$router.replace('/landing/home')
    },
    refreshLayoutComponents() {
      // if (this.checkUserRole('Student') || this.checkUserRole('Parent')) {  window.location.reload() }

      this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)
      this.$store.commit('TOGGLE_IS_VERTICAL_NAV_BAR_ACTIVE', false)
      setTimeout(() => {
        if (!this.$route.meta.hideVerticalMenu)
          this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_BAR_ACTIVE', true)
      }, 200)
    },
    ////////////////////////////////
    // Act As
    ///////////////////////////////
    startActAs(userData) {
      //{id , name}
      if (!userData || !userData.id || !userData.name) {
        this.errMsg(this.$t('basic.you_did_not_choose_a_user'))
        console.log('please check userData && userData.id && userData.name')
        return
      }

      try {
        const proceed = () => this.proceedActAs(userData.id)
        this.confirmDialog(
          null,
          `${this.$t('basic.you_are_about_to_enter_as')} ${userData.name}`,
          proceed
        )
      } catch {}
    },
    proceedActAs(userId) {
      this.VSLoading()
      this.$store
        .dispatch('auth/actAs', userId)
        .then(async (res) => {
          // try {
          // Notify
          await this.successMsg(
            `${this.$t('basic.you_are_acting_as')} ${res.user.name} ${this.$t(
              'basic.now'
            )}`
          )
          // Route to
          await this.setDefaultAuthUserSettings(this.authUser, true).then(
            this.refreshLayoutComponents
          )
        })
        .catch(this.errMsg)
        .finally(this.stopVSLoading)
    },
    endActAs() {
      this.VSLoading()
      this.$store
        .dispatch('auth/endActAs')
        .then(async (res) => {
          // Notify
          await this.successMsg(
            `${this.$t('basic.exit_act_as')} ${
              this.authUser.displayFirstAndSecondName
            }`
          )
          // Route to
          // try {
          this.setDefaultAuthUserSettings(res.authUser).then(
            this.refreshLayoutComponents
          )
          await (window.location.hash = res.last_route)

          // } catch (err) { console.error('endActAs', err) } finally { window.location.reload() }
        })

        .catch(this.errMsg)
        .finally(this.stopVSLoading)
    }
  }
}

////////////////////////////////
// check Validations
////////////////////////////////
export const checkValidationMixin = {
  methods: {
    //////////////////////////////////////////////
    /**
     * File Validation Main Method
     * Check File Validation
     * @param file =>  e.target.file[0]
     * @param {Number} maxSizeInMB => EX 2
     * @param {Array} acceptFileEx  => EX ['xlsx', 'csv', 'xls', 'png']
     * @param {Array} acceptFileTypes ['application/pdf', 'image/']
     */
    ////////////////////////////////////////////////
    fileValidation(file, maxSizeInMB, acceptFileEx, acceptFileTypes) {
      const // Size In MB
        // maxSizeInByes = maxSizeInMB * (1048576),

        // File Data
        fileName = file.name,
        fileSize = file.size / 1024 / 1024,
        fileType = file.type,
        fileEx = fileName.split('.')[fileName.split('.').length - 1],
        // Check Validation
        is_fileType_valid = acceptFileTypes.some((el) => fileType.includes(el)),
        is_fileEx_valid = acceptFileEx.some((el) => fileEx.includes(el)),
        is_fileSize_valid = fileSize < maxSizeInMB

      return new Promise((resolve, reject) => {
        if (is_fileEx_valid && is_fileType_valid && is_fileSize_valid)
          resolve(true)
        else {
          const getDocExText = [...new Set(acceptFileEx)]
              .join(' , ')
              .toUpperCase()
              .replace('.', ''),
            err = {
              message: `${this.$t(
                'basic.accept_only'
              )} ${getDocExText} ${this.$t('basic.and')} (${this.$t(
                'basic.max_size'
              )} ${maxSizeInMB}MB)`
            }
          console.group('fileValidation')
          console.log('acceptFileTypes', acceptFileTypes)
          console.log('fileType', fileType)
          console.log('is_fileType_valid', is_fileType_valid)
          console.log('is_fileEx_valid', is_fileEx_valid)
          console.log('is_fileSize_valid', is_fileSize_valid)
          console.groupEnd()
          this.errMsg(err)
          reject(false)
        }
      })
    },
    /////////////////////////////////////
    // Check Text && Image file 2MB Size
    ////////////////////////////////////
    checkTextImage2MBFileValid(file) {
      const acceptFileEx = [
          'xlsx',
          'csv',
          'xls',
          'png',
          'jpg',
          'jpeg',
          'jpgs',
          'webp',
          'pdf',
          'doc',
          'docx',
          'pptx'
        ],
        acceptFileTypes = [
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/vnd.ms-excel',
          'application/vnd.ms-powerpoint',
          'application/vnd.openxmlformats-officedocument.presentationml.presentation',
          'application/pdf',
          'image/',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ]

      return this.fileValidation(file, 2, acceptFileEx, acceptFileTypes)
    },
    /////////////////////////
    // Check Image
    /////////////////////////
    checkImageValid(file, maxSize = 2) {
      const acceptFileEx = ['png', 'jpg', 'jpeg'],
        acceptFileTypes = ['image/']

      return this.fileValidation(file, maxSize, acceptFileEx, acceptFileTypes)
    },

    //////////////////////////////////
    // Check  Text file ( word,pdf)
    //////////////////////////////////
    checkTextFileValid(file, maxSize) {
      const acceptFileEx = ['pdf', 'doc', 'docx'],
        acceptFileTypes = ['application/msword', 'application/pdf']

      return this.fileValidation(file, maxSize, acceptFileEx, acceptFileTypes)
    },
    // with 2MB Size by default
    checkText2MBFileValid(file) {
      const acceptFileEx = ['pdf', 'doc', 'docx'],
        acceptFileTypes = ['application/msword', 'application/pdf']

      return this.fileValidation(file, 2, acceptFileEx, acceptFileTypes)
    }
    /////////////////////////////////////////////////
  }
}

////////////////////////////////
// Mixin get Common Data
////////////////////////////////
export const commonDataMixin = {
  data() {
    return {
      nationalities: [],
      religions: [],
      languages: [],
      languages_with_Other: [],
      relationships: [],
      relationshipsWithoutParents: [],
      relationships_male_ids: [],
      relationships_female_ids: [],
      countries: [],
      cities: [],
      branches: [],
      divisions: [],
      grades: [],
      tel_keys: [],
      mobile_keys: [],
      fax_keys: [],
      allClasses: [],
      allSections: [],
      allAcademicYears: [],
      allStatus: [],
      StudentTypes: [],

      currentMasterAcademicYear: {},
      allCustodyOwners: [],
      allMaritalStatus: [],
      genders: []
    }
  },
  computed: {
    currentAcademicYear: {
      get() {
        return this.currentAcademicYearData
      },
      set(v) {
        this.$store.commit('main/setAcademicYearData', v)
      }
    }
  },
  methods: {
    //branches
    getBranches() {
      return new Promise((resolve, reject) => {
        CommonDataRepo.getBranches()
          .then((res) => {
            this.branches = res.data
            resolve(res.data)
          })
          .catch((err) => {
            this.errMsg(err)
            reject(err)
          })
      })
    },
    ///////////////////////////
    // divisions
    ///////////////////////////
    getDivisions(branchId) {
      return new Promise((resolve, reject) => {
        CommonDataRepo.getDivisions(branchId)
          .then((res) => {
            this.divisions = res.data
            resolve(res.data)
          })
          .catch((err) => {
            this.errMsg(err)
            reject(err)
          })
      })
    },
    getDivisionsWithoutBranchId() {
      return new Promise((resolve, reject) => {
        CommonDataRepo.getDivisionsWithoutBranchId()
          .then((res) => {
            this.divisions = res.data.data
            resolve(res.data.data)
          })
          .catch((err) => {
            this.errMsg(err)
            reject(err)
          })
      })
    },
    getDivisionsWithoutPagination() {
      return new Promise((resolve, reject) => {
        CommonDataRepo.getDivisionsWithoutPagination()
          .then((res) => {
            this.divisions = res.data
            resolve(res.data)
          })
          .catch((err) => {
            this.errMsg(err)
            reject(err)
          })
      })
    },
    ///////////////////////////
    // grades
    ///////////////////////////
    getGrades(divisionId) {
      return new Promise((resolve, reject) => {
        CommonDataRepo.getGrades(divisionId)
          .then((res) => {
            this.grades = res.data
            resolve(res.data)
          })
          .catch((err) => {
            this.errMsg(err)
            reject(err)
          })
      })
    },
    ///////////////////////////
    // Classes
    ///////////////////////////
    getAllClasses() {
      return new Promise((resolve, reject) => {
        CommonDataRepo.getAllClasses()
          .then((res) => {
            this.allClasses = res.data
            resolve(res.data)
          })
          .catch((err) => {
            this.errMsg(err)
            reject(err)
          })
      })
    },
    ///////////////////////////
    // Sections
    ///////////////////////////
    getAllSections() {
      return new Promise((resolve, reject) => {
        CommonDataRepo.getAllSections()
          .then((res) => {
            this.allSections = res.data.data
            resolve(res.data)
          })
          .catch((err) => {
            this.errMsg(err)
            reject(err)
          })
      })
    },

    ///////////////////////////
    // Academic Year
    ///////////////////////////
    getAllAcademic() {
      return new Promise((resolve, reject) => {
        CommonDataRepo.getAllAcademic()
          .then((res) => {
            this.allAcademicYears = res.data
            resolve(res.data)
          })
          .catch((err) => {
            this.errMsg(err)
            reject(err)
          })
      })
    },

    getCurrentAcademicYear,
    getCurrentMasterAcademicYear() {
      return new Promise((resolve, reject) => {
        CommonDataRepo.getCurrentMasterAcademicYear()
          .then((res) => {
            const termsCount = res.data?.terms.length || 0
            this.currentMasterAcademicYear = res.data

            this.currentMasterAcademicYear.startDate = termsCount
              ? res.data?.terms[0]?.start_date
              : null
            this.currentMasterAcademicYear.endDate = termsCount
              ? res.data?.terms[termsCount - 1]?.end_date
              : null

            resolve(res)
          })
          .catch((err) => reject(err))
      })
    },
    ///////////////////////////
    // Dealing with files
    ///////////////////////////
    commonUploadFiles(file) {
      return new Promise((resolve, reject) => {
        CommonDataRepo.commonUploadFiles(file)
          .then((res) => resolve(res))
          .catch((err) => reject(err))
      })
    },
    deleteDocumentFromS3(data) {
      return new Promise((resolve, reject) => {
        CommonDataRepo.deleteDocumentFromS3(data)
          .then(() => {
            resolve(true)
            this.successMsg(this.$t('form.delete_successfully'))
          })
          .catch((err) => {
            this.errMsg(err)
            reject(false)
          })
      })
    },

    ////////////////////////
    // Status
    ///////////////////////
    getAllStatus() {
      return new Promise((resolve, reject) => {
        CommonDataRepo.getAllStatus()
          .then((res) => {
            this.allStatus = res.data
            resolve(res.data)
          })
          .catch((err) => {
            this.errMsg(err)
            reject(err)
          })
      })
    },
    getStudentTypes() {
      return new Promise((resolve, reject) => {
        CommonDataRepo.getStudentTypes()
          .then((res) => {
            this.StudentTypes = res.data
            resolve(res.data)
          })
          .catch((err) => {
            this.errMsg(err)
            reject(err)
          })
      })
    },
    ///////////////////////////
    // Static Data
    //////////////////////////
    // tel keys
    getTelKeys() {
      return new Promise((resolve, reject) => {
        CommonDataRepo.getTelKeys()
          .then((res) => {
            this.tel_keys = res.data.value
            resolve(res.data)
          })
          .catch((err) => {
            this.errMsg(err)
            reject(err)
          })
      })
    },
    //Mobile Keys
    getMobileKeys() {
      CommonDataRepo.getMobileKeys().then((res) => {
        this.mobile_keys = res.data.value
      })
    },
    //Fax Keys
    getFaxKeys() {
      CommonDataRepo.getFaxKeys().then((res) => {
        this.fax_keys = res.data.value
      })
    },
    getMaritalStatus() {
      return new Promise((resolve, reject) => {
        CommonDataRepo.getMaritalStatus()
          .then((res) => {
            this.allMaritalStatus = res.data
            resolve(res.data)
          })
          .catch((err) => reject(err))
      })
    },
    getCustodyOwner() {
      return new Promise((resolve, reject) => {
        CommonDataRepo.getCustodyOwner()
          .then((res) => {
            this.allCustodyOwners = res.data
            resolve(res.data)
          })
          .catch((err) => reject(err))
      })
    },
    // Genders
    getGenders() {
      return new Promise((resolve, reject) => {
        CommonDataRepo.getGenders()
          .then((res) => {
            this.genders = res.data
            resolve(res.data)
          })
          .catch((err) => {
            this.errMsg(err)
            reject(err)
          })
      })
    },
    //nationalities
    getNationalities() {
      return new Promise((resolve, reject) => {
        CommonDataRepo.getNationalities()
          .then((res) => {
            this.nationalities = res.data
            resolve(res.data)
          })
          .catch((err) => {
            this.errMsg(err)
            reject(err)
          })
      })
    },
    //religions
    getReligions() {
      return new Promise((resolve, reject) => {
        CommonDataRepo.getReligions()
          .then((res) => {
            this.religions = res.data
            this.religions.push({
              id: -1,
              name: this.$t('basic.others')
            })
            resolve(res.data)
          })
          .catch((err) => {
            this.errMsg(err)
            reject(err)
          })
      })
    },
    //languages
    getLanguages() {
      return new Promise((resolve, reject) => {
        CommonDataRepo.getLanguages()
          .then((res) => {
            this.languages = res.data
            this.languages_with_Other = [
              ...res.data,
              {
                id: -1,
                name: this.$t('basic.others')
              }
            ]
            resolve(res.data)
          })
          .catch((err) => {
            this.errMsg(err)
            reject(err)
          })
      })
    },
    //relationships
    getRelationships() {
      return new Promise((resolve, reject) => {
        CommonDataRepo.getRelationships()
          .then((res) => {
            this.relationships = res.data
            this.relationshipsWithoutParents = res.data.filter(
              (el) => el.id !== 1 && el.id !== 2
            )
            this.relationships_male_ids = [1, 3, 5, 6] //father , brother,uncle,grandFather
            this.relationships_female_ids = [2, 4, 7] //mother , sister,grandmother
            resolve(res.data)
          })
          .catch((err) => {
            this.errMsg(err)
            reject(err)
          })
      })
    },
    //countries
    getCountries() {
      return new Promise((resolve, reject) => {
        CommonDataRepo.getCountries()
          .then((res) => {
            this.countries = res.data
            resolve(res.data)
          })
          .catch((err) => {
            this.errMsg(err)
            reject(err)
          })
      })
    },
    //cities
    getCitiesOfCountry(countryId) {
      return new Promise((resolve, reject) => {
        CommonDataRepo.getCitiesOfCountery(countryId)
          .then((res) => {
            this.cities = res.data
            resolve(res)
          })
          .catch((err) => reject(err))
      })
    },

    getFilterData(data) {
      /* payload
       {
        schools: [this.filtersChoosen.schools?.id || this.authUser.school_id],
        divisions: [this.filtersChoosen.divisions?.id || 0],
        grades: [this.filtersChoosen.grades?.id || 0],
        sections: [this.filtersChoosen.sections?.id || 0]
      }*/
      return new Promise((resolve, reject) => {
        CommonDataRepo.getFilterData(data)
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            this.errMsg(err)
            reject(err)
          })
      })
    },

    getSubjectOfClass(classId) {
      return new Promise((resolve, reject) => {
        CommonDataRepo.getSubjectOfClass(classId)
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            this.errMsg(err)
            reject(err)
          })
      })
    }
  }
}
////////////////////////////////
// Mixin get Common Data
////////////////////////////////
export const commonArrayMethodMixin = {
  methods: {
    arr_diff(arr1, arr2) {
      return arr1
        .filter((x) => !arr2.includes(x))
        .concat(arr2.filter((x) => !arr1.includes(x)))
    }
  }
}
