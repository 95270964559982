import axios from 'axios'

export default {
  forgetPassword (payload, type = null) {
    return axios.post(`/forgotPassword?type=${type}`, payload)
  },
  resetPassword (payload) {
    return axios.post('/password/reset', payload)
  }
}
