<template>
  <item-form @formSubmited = "addItem"/>
</template>

<script>
import itemForm from '@/modules/canteen/pages/item/components/itemForm'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const canteenRepository = RepositoryFactory.get(
  'canteenRepository',
  'canteenRepository'
)
export default {
  name: 'create',
  components:{
    itemForm
  },
  methods: {
    addItem (payload) {
      canteenRepository.addNewItem(payload).then(() => {
        this.successMsg(this.$t('canteen.addCategorySuccessfully'))
        this.$router.go(-1)
        this.stopVSLoading()
      }).catch((err) => {
        this.errMsg(err)
      })
    }
  }
}
</script>

<style scoped>

</style>
