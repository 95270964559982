import axios from 'axios'

export default {
  // ------Start Add Teacher ------
  addTeacher (payload) {
    return axios.post('./teacher/store', payload)
  },
  // ------End Add Teacher ------

  // ------Start Show Single Teacher ------
  showSingleTeacher (id) {
    return axios.get(`teacher/edit/${id}`)
  },
  // ------End Show Single Teacher ------

  // ------Start Edit Teacher ------
  editTeacher (id, payload) {
    return axios.put(`teacher/update/${id}`, payload)
  },
  // ------End Edit Teacher ------

  learingStages (payload) {
    return axios.post('./teachers/learning_stages', payload)
  },
  getAllSubjects () {
    return axios.get('./getAllSubjects')
  },
  fiterTeachers (payload) {
    return axios.post('.teacher/filter', payload)
  },
  search (data) { // search by username , email or Id
    return axios.get(`teacher/search?search=${data}`)
  },
  getTeacherDivisions () { //get divions in teacher
    return axios.get('division')
  },
  getGradesByDivision (payload) {
    return axios.post('hod/grades', payload)
  },
  getSubjectsGrades (payload) {
    return axios.post('hod/grade-subjects', payload)
  },
  createHod (id, payload) {
    return axios.post(`hod/set-role/${id}`, payload)
  },
  createTeacherParent (payload) {
    return axios.post(`/teacher-parent`, payload)
  },
  getEmailTeacherParent (payload) {
    return axios.get(`/teacher-parent/show/${payload}`)
  },
  showSingleHod (id) {
    return axios.get(`hod/show/${id}`)
  },
  createSectionCoodinator (payload) {
    return axios.post('/assign-section-coordinator', payload)
  },
  getSectionsBydivisionId (division_id) {
    return axios.get(`division/${division_id}/sections`)
  },
  createGradesCoodinator (payload) {
    return axios.post('/assign-grade-coordinator', payload)
  },
  getSingleSectionsCoodinator (id) {
    return axios.get(`/section-of-section-coordinator/${id}`)
  }
}
