import axios from 'axios'

const resource = '/chatting/group',
  socketResource = `${process.env.VUE_APP_MESSENGER_APP_BASE_URL}/api/chat`
//'https://messenger.schoolia-eg.com/api/chat'
// socketResource = 'http://127.0.0.1:3010/api/chat'
export default {

  // Create Group
  async createGroup (payload) {
    return await axios.post(`${resource}`, payload)
  },
  // Add Members
  async addGroupMembers (payload) {
    return await axios.post(`${resource}/add_members`, payload)
    //  { "members_ids": [1544,307], "group_id":21}
  },
  // Remove Members
  async removeGroupMembers (payload) {
    return await axios.post(`${resource}/remove_members`, payload)
  },
  // Make member Group Admin
  async makeGroupAdmin (members_ids, group_id) {
    return await axios.post(`${resource}/make_admin`, {members_ids, group_id})
  },
  // make Admin Group member
  async makeGroupMember (members_ids, group_id) {
    return await axios.post(`${resource}/make_member`, {members_ids, group_id})
  },
  // can reply
  async onlyAdminsCanReply (group_id, can_reply) {
    return await axios.post(`${resource}/can_reply`, {group_id, can_reply})
  },
  // Leave Group
  async leaveGroup (group_id) {
    return await axios.post(`${resource}/${group_id}/exit_group`)
  },

  async deleteGroup (groupId) {
    return await axios.delete(`${resource}/${groupId}`)
  },
  ///////////////////////
  // Socket
  //////////////////////
  saveGroupMessageNode (payload) {
    return axios.post(`${socketResource}/group-send-message`, payload)
    // payload = { receiver_id, content, attachment}
  }
}
