import {mapGetters} from 'vuex'

export default {
  props: ['unitData'],
  data () {
    return {
      units: null,
      defaultUnits: null
    }
  },
  created () {
    this.units = this.unitData
    this.defaultUnits = this.unitData[0]
    // console.log('this.unit', this.units)
  },
  computed: {
    /*...mapGetters({defaultUnits: 'getDefaultUnits'}),*/
  }
}
