<template>
  <div>
    <user-info :userInfo="userData" />
    <div id="list_datatable w-full">
      <div class="vx-row">
        <div class="vx-col w-full">
          <fund-history-table
            :headers_prop="headers"
            :loading="loading"
            :list_url_prop="`/utilities/get-funds/${user_id}`"
          >
          </fund-history-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// Components
import userInfo from '../components/userInfo'
import fundHistoryTable from '@/components/general/datatable/DataTable2.vue'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
import { commonDataMixin } from '@/mixins'
import moment from 'moment'
const allWalletRepository = RepositoryFactory.get(
  'walletRepository',
  'walletRepository'
)
export default {
  name: 'historyList',
  mixins: [commonDataMixin],
  data () {
    return {
      user_id: this.$route.params.user_id,
      userData: {},
      loading: false,
      headers: [
        {
          headerName: this.$t('form.ID'),
          field: 'id',
          minWidth: 70
        },
        {
          headerName: this.$t('form.date'),
          field: 'created_at',
          minWidth: 300,
          valueGetter (data) {
            return moment(data.data.created_at).format('MMMM Do YYYY, h:mm:ss a')
          }
        },
        {
          headerName: this.$t('wallet.value'),
          field: 'amount',
          minWidth: 70
        },
        {
          headerName: this.$t('form.name'),
          field: 'transable_type',
          minWidth: 70
        }
      ],
      searchResult: []
    }
  },
  components: {
    userInfo,
    fundHistoryTable
  },
  methods: {
    getStudentInfo () {
      allWalletRepository.getStudentAmount(this.user_id).then((res) => {
        this.userData = res.data
      })
    },
    getFundHistory () {
      allWalletRepository.showFundHistory(this.user_id).then((res) => {
        const alldata = res.data.data.map((item) => {
          return {
            ...item,
            created_at: moment(item.created_at).format(
              'MMMM Do YYYY, h:mm:ss a'
            )
          }
        })
        this.searchResult = alldata
      })
    }
  },
  created () {
    this.getStudentInfo()
    // this.getFundHistory()
  }
}
</script>
