import axios from 'axios'

const resource = '/round'
export default {
  getDropDownData () {
    return axios.get(`${resource}/create`)

  },

  getUsers () {
    return axios.get('/search')
  },

  createRound (payload) {
    return axios.post(`${resource}/store`, payload)
  },

  editRound (id, payload) {
    return axios.put(`${resource}/${id}`, payload)
  },

  getRound (round_id) {
    return axios.get(`${resource}/${round_id}`)
  },

  getScores () {
    return axios.get('score-manager/active')
  },
  getGrades (id) {
    return axios.get(`/division/${id  }/grades`)
  },
  getDivsGrades (payload) {
    return axios.post('/division/getDevsGrades', payload)
  },
  getSchoolDivisions (payload) {
    return axios.post('/division/schoolDivisions', payload)
  }
}
