export default {
  data () {
    return {}
  },
  methods: {
    uniqueID () {
      // Math.random should be unique because of its seeding algorithm.
      // Convert it to base 36 (numbers + letters), and grab the first 9 characters
      // after the decimal.
      return `_${  Math.random().toString(36).substr(2, 9)}`
    },
    cloneItem (obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    getDateOnly (date = null) {
      date = new Date(date) || new Date()
      return new Date(date.getFullYear(), date.getMonth(), date.getDate())
    },
    arrGroupBy (array, key, filter = null) {
      //** GroupBy Like Sql For Array Of Objects **//
      //** https://learnwithparam.com/blog/how-to-group-by-array-of-objects-using-a-key/  **//
      //** filter = {key,value}
      let data
      if (filter) {
        data = array.filter(item => item[filter.key] === filter.value)
      } else {
        data = array || []
      }
      return data.reduce((result, currentValue) => {
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
          currentValue
        )
        return result
      }, {})
    }
  }
}
