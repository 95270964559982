import axios from 'axios'

const resource = '/evaluations'
export default {
  createEvaluation (payload) {
    return axios.post(`${resource}`, payload)
  },


  getEvaluation (id) {
    return axios.get(`${resource}/${id}`)
  },
  updateEvaluation (id, payload) {
    return axios.put(`${resource}/${id}`, payload)
  },
  getDetails (application_id) {
    return axios.get(`application/${application_id}/details`)
  },
  getAll () {
    return axios.get(resource)
  },
  getAppDetails (id) {
    return axios.get(`/application/${id}/details`)
  }

}
