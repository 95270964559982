import axios from 'axios'

const resource = '/teachers'
export default {

  getClasses () {
    return axios.get(`${resource}/classes`)
  },
  getClassSubjects (id) {
    return axios.get(`${resource}/classes/${id}/subjects`)
  },
  getSubjectsOfClasses (payload) {
    return axios.post(`${resource}/classes/subjects`, payload)
  }
}
