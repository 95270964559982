import InstitutionsRepository from './institutionRepository'
import DivisionRepository from './divisionRepository'
import BranchRepository from './branchRepository'
import UserManagementRepository from './userManagementRepository'
import CommonDataRepository from './commonDataRepository'
import userLogs from '@/modules/users-logs/Repositories/users-logs'

export default {
  institutions: InstitutionsRepository,
  division: DivisionRepository,
  branch: BranchRepository,
  userManagement: UserManagementRepository,
  CommonData:CommonDataRepository,
  userLogs
}
