<template>
  <!--  :createBtnName="$t('teachingMaterials.create_matiral')" -->
  <ListPageWithCreateButton createRoutName="TeachingMaterialsCreate">
    <template slot="leftSideButtonContent">
      <StatusCounter :status_prop="status" ref="status_counter" />
    </template>
    <template slot="listDataContent">
      <transition-group class="list-transition" name="list-enter-up" tag="ul">
        <li
          v-for="(material, index) in materials"
          :key="index"
          :style="[{transitionDelay: index * 0.1 + 's'}]">
          <matrial-card
            :materialProp="material"
            :getTeachingMaterialList="getTeachingMaterialList"></matrial-card>
        </li>
      </transition-group>
      <template v-if="materials.length > 0 && totalPages > 1">
        <vs-pagination
          :total="totalPages"
          :max="countPerPage"
          v-model="currentPage"
          class="mt-6" />
      </template>
    </template>
    <template slot="rightSide">
      <round-filter
        :filters_prop="custom_filter"
        @refilter="setFilters"
        @resetAll="resetAll"
        :loading="loadingFilter">
      </round-filter>
    </template>
  </ListPageWithCreateButton>
</template>

<script>
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'

const TeacherRepository = RepositoryFactory.get(
  'TeachersModule',
  'teaching_materials'
)
import StatusCounter from '@/components/general/StatusCounter'
import matrialCard from './components/matrial-card.vue'
import roundFilter from '@/components/general/RoundFilter'
import {commonDataMixin} from '@/mixins.js'

export default {
  mixins: [commonDataMixin],
  components: {
    matrialCard,
    StatusCounter,
    roundFilter
  },
  data() {
    return {
      materials: [],
      topFilter: '',
      counts: {},
      sideFilters: {
        divisions: [],
        sections: [],
        grades: [],
        subjects: [],
        share_type: [],
        creators: [],
        types: [],
        status: ''
      },
      custom_filter: [
        {
          id: 1,
          name: this.$t('basic.divisions'),
          payload_name: 'divisions',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 2,
          name: this.$t('basic.sections'),
          payload_name: 'sections',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 3,
          name: this.$t('basic.grades'),
          onChangeFireAction: true,
          payload_name: 'grades',
          by: []
        },
        {
          id: 4,
          name: this.$t('basic.classes'),
          payload_name: 'classes',
          by: []
        },
        {
          id: 5,
          name: this.$t('basic.subjects'),
          payload_name: 'subjects',
          by: []
        },
        {
          id: 6,
          name: this.$t('teachingMaterials.share_type'),
          payload_name: 'share_type',
          singleSeletable: true,
          by: [
            {
              name: this.$t('teachingMaterials.created_by_me'),
              id: 'created_by_me',
              checked: false
            },
            {
              name: this.$t('teachingMaterials.shared_with_me'),
              id: 'shared_with_me',
              checked: false
            },
            {
              name: this.$t('teachingMaterials.shared_by_me'),
              id: 'shared_by_me',
              checked: false
            },
            {
              name: this.$t('teachingMaterials.not_shared_yet'),
              id: 'not_shared_yet',
              checked: false
            }
          ]
        },
        {
          id: 7,
          name: this.$t('basic.by'),
          payload_name: 'creators',
          by: []
        },
        {
          id: 8,
          name: this.$t('basic.types'),
          payload_name: 'types',
          by: []
        },
        {
          id: 9,
          name: this.$t('basic.status'),
          payload_name: 'status',
          singleSeletable: true,
          by: [
            {
              name: this.$t('basic.active'),
              id: 'active',
              checked: false
            },
            {
              name: this.$t('basic.archive'),
              id: 'archive',
              checked: false
            }
          ]
        }
      ],
      loadingFilter: false,
      /////////////////////////
      // Pagination
      /////////////////////////
      totalPages: 0,
      currentPage: 1,
      countPerPage: 10
    }
  },
  watch: {
    currentPage() {
      this.getTeachingMaterialList()
    },

    sideFilters: {
      deep: true,
      handler() {
        this.currentPage = 1
        this.getTeachingMaterialList()
      }
    }
  },

  computed: {
    // Start Staues Of Top Cards
    status() {
      const status = [
        {
          icon: 'EyeIcon',
          value: 'all',
          name: this.$t('basic.All'),
          count: this.counts.all
        },
        {
          icon: 'SmileIcon',
          value: 'documents',
          name: this.$t('teachingMaterials.Documents'),
          count: this.counts.Document,
          color: 'black'
        },

        {
          icon: 'BookOpenIcon',
          value: 'books',
          name: this.$t('teachingMaterials.Books'),
          count: this.counts.Book,
          color: 'black'
        },
        {
          icon: 'Volume2Icon',
          value: 'audio',
          name: this.$t('teachingMaterials.Audio'),
          count: this.counts.Audio,
          color: 'black'
        },
        {
          icon: 'VideoIcon',
          value: 'video',
          name: this.$t('teachingMaterials.Video'),
          count: this.counts.Video,
          color: 'black'
        },
        {
          icon: 'AirplayIcon',
          value: 'presentation',
          name: this.$t('teachingMaterials.Presentation'),
          count: this.counts.Presentation,
          color: 'black'
        }
      ]
      return status
    }
    //Start change Filter for Top Cards
  },

  methods: {
    setFilters(val) {
      val.map((item) => {
        this.sideFilters[item.name] = item.checked
      })
    },
    resetAll() {
      Object.keys(this.sideFilters).forEach((filterName) => {
        this.sideFilters[filterName] = []
      })
      this.custom_filter[1]['by'] = []
      this.custom_filter[2]['by'] = []
      this.custom_filter[3]['by'] = []
      this.custom_filter[4]['by'] = []
    },
    setPagination(data) {
      this.totalPages = data.total_pages || data.last_page
      this.countPerPage = data.per_page
      this.currentPage = data.current_page
    },
    getFiltersData() {
      this.custom_filter[0]['by'] = []
      this.custom_filter[1]['by'] = []

      const schools = [this.authUser.school_id]
      this.getFilterData({schools}).then((res) => {
        res.divisions.map((el) => {
          this.custom_filter[0]['by'].push({
            id: el.id,
            name: el.name,
            checked: false
          })
        })
      })

      TeacherRepository.teachersOfSection().then((res) => {
        res.data.data.map((el) => {
          this.custom_filter[6]['by'].push({
            id: el.id,
            name: el.name,
            checked: false
          })
        })
      })

      TeacherRepository.getTypes().then((res) => {
        res.data.map((el) => {
          this.custom_filter[7]['by'].push({
            id: el.id,
            name: el.name,
            checked: false
          })
        })
      })
    },
    getFilteredSections(chosen) {
      this.loadingFilter = true
      this.custom_filter[1]['by'] = []
      this.custom_filter[2]['by'] = []
      this.custom_filter[3]['by'] = []
      this.custom_filter[4]['by'] = []
      const data = {
        divisions: chosen.divisions,
        schools: [this.authUser.school_id]
      }
      this.getFilterData(data)
        .then((res) => {
          res.sections.map((el) => {
            this.custom_filter[1]['by'].push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
          res.divisions_subjects.map((el) => {
            this.custom_filter[4]['by'].push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
        })
        .finally(() => {
          this.loadingFilter = false
        })
    },
    getFilteredGrades(chosen) {
      this.loadingFilter = true
      this.custom_filter[2]['by'] = []
      this.custom_filter[3]['by'] = []

      const data = {
        sections: chosen.sections,
        divisions: chosen.divisions,
        schools: [this.authUser.school_id]
      }
      this.getFilterData(data)
        .then((res) => {
          res.grades.map((el) => {
            this.custom_filter[2]['by'].push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
        })
        .finally(() => {
          this.loadingFilter = false
        })
    },
    getFiltereClassess(chosen) {
      this.loadingFilter = true
      this.custom_filter[3]['by'] = []
      const data = {
        sections: chosen.sections,
        divisions: chosen.divisions,
        grades: chosen.grades,
        schools: [this.authUser.school_id]
      }
      this.getFilterData(data)
        .then((res) => {
          res.grades_classes.map((el) => {
            this.custom_filter[3]['by'].push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
        })
        .finally(() => {
          this.loadingFilter = false
        })
    },
    getTeachingMaterialList() {
      this.VSLoading()
      const params = {
        ...this.sideFilters,
        page: this.currentPage
      }
      TeacherRepository.getTeachingMaterialList(params)
        .then((res) => {
          this.materials = res.data.data
          this.setPagination(res.data)
        })
        .finally(() => {
          this.stopVSLoading()
        })
    },
    counter() {
      TeacherRepository.count().then((res) => {
        this.counts = res.data
      })
    }
    //Change Filter Cards
  },
  created() {
    this.$root.$on('onChange:divisions', this.getFilteredSections)
    this.$root.$on('onChange:sections', this.getFilteredGrades)
    this.$root.$on('onChange:grades', this.getFiltereClassess)
    this.getFiltersData()
    this.getTeachingMaterialList()
    this.counter()
  },
  beforeDestroy() {
    this.$root.$off('onChange:divisions')
    this.$root.$off('onChange:sections')
    this.$root.$off('onChange:grades')
  }
}
</script>
