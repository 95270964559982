import axios from 'axios'

const resource = '/teachers'
export default {

  getFilteredStudents (page, filters) {
    return axios.post(`${resource}/student/filters${page ? page : ''}`,
      filters
    )
  },
  getAllClasses () {
    return axios.get('/class')
  },
  getAllFilterClasses () {
    return axios.get('/filter/classes')
  },
  getAllSections () {
    return axios.get('/section')
  },
  addGeneralNote (payload) {
    return axios.post('/general/note', payload)
  }
}
