<template>
  <ListPageWithCreateButton :showCreateBtn="false">
    <template slot="leftSideButtonContent">
      <StatusCounter
        @filter-change="changeFilter"
        :status_prop="statistics_data"
        ref="status_counter" />
    </template>
    <template slot="listDataContent">
      <ApplicationTable
        v-if="showTable"
        :filter_type="filterType"
        :headers_prop="headers"
        :list_url_prop="listUrl"
        :side_filters="filter_choosen"
        :allFilters="filter_choosen"
        :propSearch="propSearch"></ApplicationTable>
    </template>
    <template slot="rightSide">
      <round-filter
        :filters_prop="filters"
        @refilter="refilter"
        @resetAll="resetfilter"
        :loading="loadingFilter">
        <template slot="extra_content">
          <div class="w-full mb-10">
            <vs-input
              :label-placeholder="$t('form.search_by_name_email_or_ID')"
              v-model="searchContent"
              class="w-full"
              name="email"
              icon="icon icon-search"
              icon-pack="feather"
              @change="refilter" />
            <span class="text-sm text-danger" v-if="errors.first('email')">{{
              errors.first('email') | text_formatter
            }}</span>
          </div>
        </template>
      </round-filter>
    </template>
  </ListPageWithCreateButton>
</template>

<script>
import StatusCounter from '@/components/general/multiDataStatusCounter'
import ApplicationTable from './components/ApplicationsTable'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
import RoundFilter from '@/components/general/RoundFilter' //Right-sidebar
const ApplicationRepository = RepositoryFactory.get(
  'AdmissionModule',
  'applications'
)
import {commonDataMixin} from '@/mixins.js'
export default {
  name: 'ApplicationList',
  components: {
    ApplicationTable,
    StatusCounter,
    RoundFilter
  },
  mixins: [commonDataMixin],
  data() {
    return {
      propSearch: '',
      searchContent: '',
      listUrl: '/application/registered/filter',
      filterType: '',
      newCount: 0,
      shortlisted: 0,
      rejected: 0,
      onAssesment: 0,
      waitingList: 0,
      disqualified: 0,
      qualified: 0,
      withdrawn: 0,
      enrolled: 0,
      declined: 0,
      filter_choosen: {
        school_ids: [],
        division_ids: [],
        section_ids: [],
        grade_ids: [],
        alumni: [],
        sibling: [],
        bus_required: [],
        rounds: [],
        status: [],
        general_search: ''
      },
      filters: [
        {
          id: 1,
          name: this.$t('basic.schools'),
          payload_name: 'school_ids',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 2,
          name: this.$t('basic.divisions'),
          payload_name: 'division_ids',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 14,
          name: this.$t('basic.sections'),
          payload_name: 'section_ids',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 3,
          name: this.$t('basic.grades'),
          payload_name: 'grade_ids',
          by: []
        },
        {
          id: 15,
          name: this.$t('admission.rounds'),
          payload_name: 'rounds',
          by: []
        },
        {
          id: 9,
          name: this.$t('admission.alumni'),
          payload_name: 'alumni',

          by: [
            {name: this.$t('admission.has_alumni'), id: 1, checked: false},
            {
              name: this.$t('admission.has_not_alumni'),
              id: 0,
              checked: false
            }
          ]
        },
        {
          id: 10,
          name: this.$t('admission.siblings'),
          payload_name: 'sibling',

          by: [
            {name: this.$t('admission.has_siblings'), id: 1, checked: false},
            {
              name: this.$t('admission.has_not_siblings'),
              id: 0,
              checked: false
            }
          ]
        },
        {
          id: 11,
          name: this.$t('admission.transportation'),
          payload_name: 'bus_required',

          by: [
            {name: this.$t('admission.bus_Required'), id: 1, checked: false},
            {
              name: this.$t('admission.no_bus_required'),
              id: 0,
              checked: false
            }
          ]
        }
      ],
      statistics_data: [
        {
          id: 1,
          icon: 'CpuIcon',
          value: 'qualified',
          color: 'primary',
          sub: [
            {
              name: this.$t('status.all_qualified'),
              count: 0
            },
            {
              name: this.$t('status.qualified'),
              count: 0
            }
          ]
        },
        {
          id: 2,
          icon: 'XCircleIcon',
          value: 'declined',
          sub: [
            {
              name: this.$t('status.all_declined'),
              count: 0
            },
            {
              name: this.$t('status.declined'),
              count: 0
            }
          ]
        },
        {
          id: 3,
          icon: 'XCircleIcon',
          value: 'withdrawn',
          sub: [
            {
              name: this.$t('status.all_withdrawn'),
              count: 0
            },
            {
              name: this.$t('status.withdrawn'),
              count: 0
            }
          ]
        },
        {
          id: 4,
          icon: 'CheckCircleIcon',
          value: 'enrolled',
          sub: [
            {
              name: this.$t('status.all_enrolled'),
              count: 0
            },
            {
              name: this.$t('status.enrolled'),
              count: 0
            }
          ]
        },
        {
          id: 5,
          icon: 'ArchiveIcon',
          value: 'archive',
          sub: [
            {
              name: this.$t('status.all_archived'),
              count: 0
            },
            {
              name: this.$t('status.archived'),
              count: 0
            }
          ]
        }
      ],
      headers: [
        {
          minWidth: 160,
          headerName: this.$t('RoundManager.S'),
          field: 'id',
          checkboxSelection: true,
          width: 150,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true
        },
        {
          minWidth: 160,
          headerName: this.$t('form.fullName'),
          field: 'student_name'
        },
        {
          minWidth: 160,
          headerName: this.$t('basic.division'),
          field: 'divisionName'
        },
        {
          minWidth: 160,
          headerName: this.$t('basic.grade'),
          field: 'gradeName'
        },
        {
          minWidth: 300,
          headerName: this.$t('admission.roundName'),
          field: 'round_name'
        },
        /* {
          minWidth: 160,
          headerName: this.$t('admission.recommended_by'),
          field: 'recommended_by'
        },*/
        {
          minWidth: 160,
          headerName: this.$t('RoundManager.Status'),
          colId: 'status',
          field: 'status',
          width: 240,
          cellRendererFramework: 'CellRendererStatus',
          cellRendererParams: {
            status_prop: this.status
          }
        },
        // { headerName: 'siblings', field: 'sibling' },
        {
          minWidth: 300,
          headerName: this.$t('basic.actions'),
          filter: false,
          field: 'transactions',
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            actions_prop: [
              {
                title: this.$t('basic.lock'),
                route_name: 'lock',
                parameter_name: 'id',
                icon: 'LockIcon',
                navigate_action: false,
                dropDown: false,
                no_action: true
              },
              {
                title: this.$t('basic.show'),
                resource: 'application',
                action: 'view',
                parameter_name: 'id',
                icon: 'EyeIcon',
                navigate_action: false,
                dropDown: false,
                no_action: true
              },
              {
                title: this.$t('basic.edit'),
                action: 'edit',
                resource: 'round',
                parameter_name: 'id',
                icon: 'Edit3Icon',
                navigate_action: false,
                dropDown: true
              }
            ],
            viewed_prop: this.viewed,
            parameter_name: 'id',
            enable_enroll: true
          }
        }
      ],
      status: [
        {
          id: 0,
          title: this.$t('status.new'),
          value: 'new',
          icon: 'StarIcon',
          count: '22',
          link: '#!'
        },
        {
          id: 1,
          title: this.$t('status.shortlisted'),
          value: 'shortlisted',
          icon: 'ClipboardIcon',
          count: '22',
          link: '#!'
        },
        {
          id: 2,
          title: this.$t('status.on_assessment'),
          value: 'on_assessment',
          icon: 'UserCheckIcon',
          count: '22',
          link: '#!'
        },
        {
          id: 3,
          title: this.$t('status.waiting_list'),
          value: 'w_list',
          icon: 'WatchIcon',
          count: '22',
          link: '#!'
        },
        {
          id: 4,
          title: this.$t('status.disqualified'),
          value: 'disqualified',
          icon: 'XCircleIcon',
          count: '22',
          link: '#!'
        },
        {
          id: 5,
          title: this.$t('status.qualified'),
          value: 'qualified',
          icon: 'CheckCircleIcon',
          count: '22',
          link: '#!'
        },
        {
          id: 6,
          title: this.$t('status.enrolled'),
          value: 'enrolled',
          icon: 'CheckCircleIcon',
          count: '22',
          link: '#!'
        }
      ],
      itemList: [],
      export_file: false,
      statisticsData: [],
      viewed: 0,
      showTable: true,
      loadingFilter: false
    }
  },
  created() {
    this.getStatisticsData()
    this.$root.$on('statistics:status', this.getStatisticsData)
    this.$root.$on('resetAction', (data) => this.resetTable(data))
    this.updateFilters(this.filter_choosen)
    // this.getSchools()
    this.$root.$on('onChange:school_ids', this.getDivision)
    this.$root.$on('onChange:division_ids', this.getFilteredSection)
    this.$root.$on('onChange:section_ids', this.getFilteredGrades)
    /*    this.$root.$on('sheduleMeetingAdded', this.getMeetings)*/
    this.$root.$on('activeChangeDivisionPopup', this.openPopup)

    this.getDivisionsWithoutBranchId().then((res) => {
      this.divisions = res.map((el) => {
        const item = {
          id: el.id,
          name: el.long_name
        }
        return item
      })
    })
  },
  beforeDestroy() {
    this.$root.$off('statistics:status')
    this.$root.$off('resetAction')
    this.$root.$off('row:status')
    this.$root.$off('onChange:school_ids')
    this.$root.$off('onChange:division_ids')
    this.$root.$off('onChange:section_ids')
    this.$root.$off('sheduleMeetingAdded')
    this.$root.$off('activeChangeDivisionPopup')
    this.$root.$off('resetAction')
  },

  methods: {
    search() {
      this.propSearch = this.searchContent
      this.filter_choosen.search = this.searchContent
    },
    resetfilter() {
      this.filter_choosen = {
        school_ids: [],
        division_ids: [],
        section_ids: [],
        grade_ids: [],
        alumni: [],
        sibling: [],
        bus_required: [],
        rounds: [],
        status: [],
        search: ''
      }
      this.filters[1].by = []
      this.filters[2].by = []
      this.filters[3].by = []
    },
    refilter(filters) {
      this.filter_choosen['general_search'] = this.searchContent

      if (filters.length > 0) {
        filters.forEach((el) => {
          if (!Array.isArray(el.checked)) el.checked = [el.checked]
        })
        filters.forEach((e) => {
          this.filter_choosen[e.name] = e.checked
        })
      }
      /*     ApplicationRepository.filterRegistationTable(this.filter_choosen).then(res => {
        this.itemList = res.data
      })*/
    },
    resetTable() {
      this.showTable = false
      setTimeout(() => {
        this.showTable = true
      })
    },
    async getStatisticsData() {
      const data = await ApplicationRepository.getApplicationsStatistics()
      this.statisticsData = data.data
      // if(data.data=="qualified")
      // {
      //   this.statisticsData["FutureCandidates"]=data.data["qualified"]
      // }
      this.setStatisticsData()
      // this.statistics_data = this.statistics_data.map(({ ...state_data }) => {
      //   const found = this.statisticsData.filter(
      //     (state) => state.status.toLowerCase() === state_data.name.toLowerCase()
      //   )
      //   return {
      //     count: found.length > 0 ? found[0].count : 0,
      //     ...state_data
      //   }
      // })
      this.statistics_data.forEach((el) => {
        const found = this.statisticsData.allStatistics.find(
          (state) => state.all_status.toLowerCase() === el.value.toLowerCase()
        )
        el.sub[0].count = found ? found.all_count : 0
      })
      this.statistics_data.forEach((el) => {
        const found = this.statisticsData.roundStatistics.find(
          (state) => state.status.toLowerCase() === el.value.toLowerCase()
        )
        el.sub[1].count = found ? found.count : 0
      })
    },
    changeFilter(filter) {
      // console.log(filter)
      // console.log(typeof (filter))
      // console.log(filter.includes('qualified'))
      /*
      this.filterType = filter
*/
      let status = ''
      if (filter == 'qualified') {
        status = 4
      } else if (filter == 'new') {
        status = 0
      } else if (filter == 'shortlisted') {
        status = 1
      } else if (filter == 'on Assessment') {
        status = 2
      } else if (filter == 'waiting list') {
        status = 3
      } else if (filter == 'Schedule a meeting') {
        status = 5
      } else if (filter == 'enrolled') {
        status = 6
      } else if (filter == 'withdrawn') {
        status = 7
      } else if (filter == 'disqualified') {
        status = -1
      } else if (filter == 'rejected') {
        status = -2
      } else if (filter == 'declined') {
        status = -3
      } else {
        status = -4
      }
      this.filter_choosen.status = [status]
    },
    setStatisticsData() {
      this.newCount = 0
      this.rejected = 0
      this.shortlisted = 0
      this.waitingList = 0
      this.qualified = 0
      this.disqualified = 0
      this.withdrawn = 0
      this.enrolled = 0
      this.declined = 0
      for (let i = 0; i < this.statisticsData.length; i++) {
        switch (this.statisticsData[i].all_status.toLowerCase()) {
          case 'new':
            this.newCount = this.statisticsData[i].all_count
            break
          case 'rejected':
            this.rejected = this.statisticsData[i].all_count
            break
          case 'shortlisted':
            this.shortlisted = this.statisticsData[i].all_count
            break
          case 'on Assessment':
            this.onAssesment = this.statisticsData[i].all_count
            break
          case 'waiting list':
            this.waitingList = this.statisticsData[i].all_count
            break
          case 'qualified':
            this.qualified = this.statisticsData[i].all_count
            break
          case 'disqualified':
            this.disqualified = this.statisticsData[i].all_count
            break
          case 'Enrolled':
            this.enrolled = this.statisticsData[i].all_count
            break
          case 'withdrawn':
            this.withdrawn = this.statisticsData[i].all_count
            break
          case 'declined':
            this.declined = this.statisticsData[i].all_count
            break
          default:
            break
        }
      }
    },
    getAllRounds() {
      ApplicationRepository.getAllRounds()
        .then((res) => {
          this.filters[4].by = []
          res.data.map((el) => {
            this.filters[4].by.push({
              name: `  ${el.name ? el.name : `${el.from} - ${el.to}`}`,
              id: el.id,
              checked: false
            })
          })
        })
        .catch((err) => console.error('getRounds', err))
    },
    updateFilters(choosen) {
      this.getAllRounds()
      this.getFilterData().then((res) => {
        res.schools.map((el) => {
          this.filters[0]['by'].push({
            id: el.id,
            name: el.branch_name,
            checked: false
          })
        })
      })
    },
    getDivision(chosen) {
      this.loadingFilter = true
      this.filters[1].by = []
      this.filters[2].by = []
      this.filters[3].by = []
      this.getFilterData({schools: chosen.school_ids})
        .then((res) => {
          res.divisions.map((el) => {
            this.filters[1]['by'].push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
        })
        .finally(() => {
          this.loadingFilter = false
        })
    },
    getFilteredSection(chosen) {
      this.loadingFilter = true
      this.filters[2].by = []
      this.filters[3].by = []
      const data = {
        divisions: chosen.division_ids,
        schools: chosen.school_ids
      }
      this.getFilterData(data)
        .then((res) => {
          res.sections.map((el) => {
            this.filters[2]['by'].push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
        })
        .finally(() => {
          this.loadingFilter = false
        })
    },
    getFilteredGrades(chosen) {
      this.loadingFilter = true
      this.filters[3].by = []
      const data = {
        sections: chosen.section_ids,
        divisions: chosen.division_ids,
        schools: chosen.school_ids
      }
      this.getFilterData(data)
        .then((res) => {
          res.grades.map((el) => {
            this.filters[3]['by'].push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
        })
        .finally(() => {
          this.loadingFilter = false
        })
    }
  }
}
</script>
