<template >
  <div>
    <vs-card class="p-3 pt-6 pb-6 md:p-6">
      <form @submit="submitData">
        <div class="vx-row">
          <!-------------------------- Name -------------------------->

          <div class="vx-col lg:w-1/2 md:w-1/2 w-full mb-6">
            <label>
              {{ $t("InstitutionDetails.Name") }}
              <sup class="text-danger">*</sup>
            </label>
            <vs-input
              name="LongName"
              v-validate="'required|min:2|max:40'"
              class="w-full"
              :placeholder="`${$t('InstitutionDetails.LongName')}`"
              v-model="allNameData.longName"
            />
            <span
              v-if="issueSubmitted && errors.has('LongName')"
              class="text-danger text-sm"
              >{{ errors.first("LongName") | text_formatter }}</span
            >
          </div>

          <div class="vx-col lg:w-1/2 md:w-1/2 w-full mb-6">
            <label>{{ $t("InstitutionDetails.ShortName") }}</label>
            <vs-input
              name="ShortName"
              v-validate="
                'min:2|max:15|regex:^[a-zA-Z_][a-zA-Z0-9_]+( [a-zA-Z0-9_]+)*$'
              "
              class="w-full"
              :placeholder="`${$t('InstitutionDetails.ShortName')}*`"
              v-model="allNameData.ShortName"
            />

            <span
              v-if="issueSubmitted && errors.has('ShortName')"
              class="text-danger text-sm"
              >{{ errors.first("ShortName") | text_formatter }}</span
            >
          </div>
          <!-------------------------- /Name -------------------------->
        </div>
        <!-------------------------- Address -------------------------->
        <div class="vx-row">
          <!-- Text Address -->
          <div class="vx-col lg:w-1/2 md:w-1/2 w-full mb-6">
            <label>
              {{ $t("InstitutionDetails.Address") }}
              <sup class="text-danger">*</sup>
            </label>
            <vs-textarea
              name="address"
              v-validate="'required'"
              class="w-full m-0"
              :placeholder="`${$t('InstitutionDetails.Address')}`"
              v-model="allAddressData.desc"
            />
            <span
              v-if="issueSubmitted && errors.has('address')"
              class="text-danger text-sm"
              >{{ errors.first("address") | text_formatter }}</span
            >
          </div>
          <!-- /Text Address -->
          <!---  GPSAddress --->
          <div class="vx-col lg:w-1/2 md:w-1/2 w-full mb-6 flex items-center">
            <label>
              <button
                @click.prevent="googleAddress()"
                class="no_style flex items-center"
              >
                <img
                  src="@/assets/images/file-icons/GPS.png"
                  :alt="$t('main.GoogleMap')"
                  height="30"
                  width="30"
                  v-validate="'image'"
                  name="logoImage"
                  data-vv-as="image"
                  class="inline-flex mr-3"
                />
                <span>
                  {{ $t("InstitutionDetails.ClickForGPSAddress") }}
                  <sup class="text-danger">*</sup>
                </span>
              </button>
            </label>

            <div v-if="googleError" class="text-danger text-sm">
              {{ $t("InstitutionDetails.ClickForGPSAddress") }}
              <span class="text-danger">*</span>
            </div>
          </div>

          <GoogleMapInfoWindow
            @updateLocation="updateLocation"
            v-if="loaded"
            :markers="markers"
            :setPlace="setPlace"
            :loaded="loaded"
            class="vx-col w-full mb-6"
            ref="googleMap"
            :mapErrorAlert="mapErrorAlert"
          />

          <!---  /GPSAddress --->
        </div>
        <!-------------------------- /Address -------------------------->
        <!-------------------------- UploadLogo -------------------------->
        <vs-divider position="center">{{
          $t("InstitutionDetails.Logo")
        }}</vs-divider>

        <div class="vx-row">
          <div class="vx-col md:w-1/3 w-full mb-3">
            <label class="mb-2 block">
              {{ $t("InstitutionDetails.UploadLogo") }}
              <sup class="text-danger">*</sup>
            </label>
            <vs-input
              type="file"
              accept=".png,.jpg;capture=camera"
              class="w-full"
              v-model="logoFile"
              @change="onChange($event, 'logo')"
            ></vs-input>
            <div
              id="preview"
              class="mt-3"
              style="max-height: 100%; width: auto; max-width: 222px"
              v-if="logoURL"
            >
              <img
                :alt="`${$t('basic.Logo')}`"
                :src="logoURL"
                class="h-full w-full"
              />
              <!-- <vs-button
                color="danger"
                type="border"
                size="small"
                class="w-full"
                @click="deleteFile(logo)"
                >{{ $t("form.delete") }}</vs-button
              > -->
            </div>
          </div>

          <div class="vx-col md:w-1/3 w-full mb-3">
            <label class="mb-2 block">
              {{ $t("InstitutionDetails.favicon") }} ({{
                $t("InstitutionDetails.logo_icon")
              }} 60*60)
              <sup class="text-danger">*</sup>
            </label>
            <vs-input
              type="file"
              accept=".jpg, .jpeg, .png;capture=camera"
              class="w-full"
              v-model="faviconFile"
              @change="onChange($event, 'favicon')"
            ></vs-input>

            <div
              id="preview"
              class="mt-3"
              v-if="faviconURL"
              style="height: auto; max-height: 60px; width: 60px"
            >
              <img
                :alt="`${$t('basic.school_icon')}`"
                :src="faviconURL"
                class="h-full"
                style="max-width: 100%"
              />
              <!-- <vs-button
                color="danger"
                type="border"
                size="small"
                class="w-full"
                @click="deleteFile(favicon)"
                >{{ $t("form.delete") }}</vs-button
              > -->
            </div>
          </div>

          <div class="vx-col md:w-1/6 w-full mb-3" v-if="logoURL">
            <label class="mb-2 block">{{
              $t("InstitutionDetails.choose_primary_color")
            }}</label>
            <input
              class="vs-input vs-input-primary"
              type="color"
              v-model="brandColor"
              style="height: 44px; width: 140px"
            />
          </div>
          <span v-if="issueSubmitted && errors.has('logoImage')" class="text-danger text-sm w-full">{{ errors.first("logoImage") | text_formatter }}</span>
          <div class="vx-col md:w-1/6 w-full mb-3" v-if="previewMode">
                <a class="button-pulse" @click.prevent="smoothScroll()" href="#">{{ $t("InstitutionDetails.confirm_to_save_changes") }}<br>
                  <svg class="m-auto" fill="yellowgreen" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M246.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-160-160c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L224 402.7 361.4 265.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3l-160 160zm160-352l-160 160c-12.5 12.5-32.8 12.5-45.3 0l-160-160c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L224 210.7 361.4 73.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3z"/></svg>
                </a>
          </div>
        </div>
        <!-------------------------- /UploadLogo -------------------------->
        <!-------------------------- Contacts -------------------------->
        <vs-divider position="center">{{
          $t("InstitutionDetails.Contacts")
        }}</vs-divider>
        <!-- General -->
        <div class="vx-row">
          <div class="vx-col mb-2 w-full">
            <label>
              {{ $t("InstitutionDetails." + contacts.tel[0].type) }}
              <sup class="text-danger">*</sup>
            </label>
          </div>
          <div class="vx-col lg:w-1/4 md:w-1/2 w-full mb-4">
            <!---key---str2.concat(str1)-->
            <div class="vx-row mb-4">
              <div class="vx-col lg:w-1/4 md:w-1/3 w-full md:pr-0">
                <vs-select
                  autocomplete
                  v-model="contacts.tel[0].key"
                  name="General_tel_key"
                  v-validate="'required'"
                  class="select-small w-full"
                >
                  <vs-select-item
                    :key="index"
                    :value="item"
                    :text="item"
                    v-for="(item, index) in tel_keys"
                    class="w-full"
                  />
                </vs-select>
                <span
                  v-if="issueSubmitted && errors.has('General_tel_key')"
                  class="text-danger text-sm"
                  >{{ errors.first("General_tel_key") | text_formatter }}</span
                >
              </div>
              <div class="vx-col lg:w-3/4 md:w-2/3 w-full md:pl-1 md:mt-0 mt-3">
                <vs-input
                  name="GeneralTelephone"
                  v-validate="'required|numeric|length:8'"
                  :placeholder="$t('main.Tel')"
                  v-model="contacts.tel[0].number"
                  class="w-full"
                ></vs-input>
                <span
                  v-if="issueSubmitted && errors.has('GeneralTelephone')"
                  class="text-danger text-sm"
                  >{{ errors.first("GeneralTelephone") | text_formatter }}</span
                >
              </div>
            </div>
          </div>

          <div class="vx-col lg:w-1/4 md:w-1/2 w-full mb-4">
            <vs-input
              name="GeneralFax"
              v-validate="'required|min:2|max:15|regex:^[0-9\-\+]*$'"
              :placeholder="$t('main.Fax')"
              v-model="contacts.fax[0].number"
              class="w-full"
            ></vs-input>
            <span
              v-if="issueSubmitted && errors.has('GeneralFax')"
              class="text-danger text-sm"
              >{{ errors.first("GeneralFax") | text_formatter }}</span
            >
          </div>
          <div class="vx-col lg:w-1/4 md:w-1/2 w-full mb-4">
            <div class="vx-row mb-4">
              <div class="vx-col lg:w-1/4 md:w-1/3 w-full md:pr-0">
                <vs-select
                  autocomplete
                  v-model="contacts.mobile[0].key"
                  name="General_mobile_key"
                  class="select-small w-full"
                >
                  <vs-select-item
                    :key="index"
                    :value="item"
                    :text="item"
                    v-for="(item, index) in mobile_keys"
                    class="w-full"
                  />
                </vs-select>
                <span
                  v-if="issueSubmitted && errors.has('General_mobile_key')"
                  class="text-danger text-sm"
                  >{{
                    errors.first("General_mobile_key") | text_formatter
                  }}</span
                >
              </div>
              <div class="vx-col lg:w-3/4 md:w-2/3 w-full md:pl-1 md:mt-0 mt-3">
                <vs-input
                  name="GeneralMobile"
                  v-validate="'required|numeric|length:8'"
                  :placeholder="$t('main.Mobile')"
                  v-model="contacts.mobile[0].number"
                  class="w-full"
                ></vs-input>
                <span
                  v-if="issueSubmitted && errors.has('GeneralMobile')"
                  class="text-danger text-sm"
                  >{{ errors.first("GeneralMobile") | text_formatter }}</span
                >
              </div>
            </div>
          </div>
          <div class="vx-col lg:w-1/4 md:w-1/2 w-full mb-4">
            <vs-input
              name="GeneralEmail"
              v-validate="'required|email'"
              :placeholder="`${$t('main.Email')}`"
              v-model="contacts.email[0].email"
              class="w-full"
            ></vs-input>
            <span
              v-if="issueSubmitted && errors.has('GeneralEmail')"
              class="text-danger text-sm"
              >{{ errors.first("GeneralEmail") | text_formatter }}</span
            >
          </div>
        </div>
        <!-- / General -->
        <!-- Accounting -->
        <div class="vx-row">
          <div class="vx-col mb-2 w-full">
            <label>
              {{ $t("InstitutionDetails." + contacts.tel[3].type) }}
              <sup class="text-danger">*</sup>
            </label>
          </div>
          <div class="vx-col lg:w-1/4 md:w-1/2 w-full mb-4">
            <div class="vx-row mb-4">
              <div class="vx-col lg:w-1/4 md:w-1/3 w-full md:pr-0">
                <vs-select
                  autocomplete
                  v-model="contacts.tel[3].key"
                  name="AccountingTelkey"
                  class="select-small w-full"
                >
                  <vs-select-item
                    :key="index"
                    :value="item"
                    :text="item"
                    v-for="(item, index) in tel_keys"
                    class="w-full"
                  />
                </vs-select>
                <span
                  v-if="issueSubmitted && errors.has('AccountingTelkey')"
                  class="text-danger text-sm"
                  >{{ errors.first("AccountingTelkey") | text_formatter }}</span
                >
              </div>
              <div class="vx-col lg:w-3/4 md:w-2/3 w-full md:pl-1 md:mt-0 mt-3">
                <vs-input
                  name="AccountingTel"
                  v-validate="'required|numeric|length:8'"
                  :placeholder="`${$t('main.Tel')}`"
                  v-model="contacts.tel[3].number"
                  class="w-full"
                ></vs-input>
                <span
                  v-if="issueSubmitted && errors.has('AccountingTel')"
                  class="text-danger text-sm"
                  >{{ errors.first("AccountingTel") | text_formatter }}</span
                >
              </div>
            </div>
          </div>
          <div class="vx-col lg:w-1/4 md:w-1/2 w-full mb-4">
            <vs-input
              name="AccountingFax"
              v-validate="'required|min:2|max:15|regex:^[0-9\-\+]*$'"
              :placeholder="`${$t('main.Fax')}`"
              v-model="contacts.fax[3].number"
              class="w-full"
            ></vs-input>
            <span
              v-if="issueSubmitted && errors.has('AccountingFax')"
              class="text-danger text-sm"
              >{{ errors.first("AccountingFax") | text_formatter }}</span
            >
          </div>

          <div class="vx-col lg:w-1/4 md:w-1/2 w-full mb-4">
            <div class="vx-row mb-4">
              <div class="vx-col lg:w-1/4 md:w-1/3 w-full md:pr-0">
                <vs-select
                  autocomplete
                  v-model="contacts.mobile[3].key"
                  name="Accounting_mobile_key"
                  class="select-small w-full"
                >
                  <vs-select-item
                    :key="index"
                    :value="item"
                    :text="item"
                    v-for="(item, index) in mobile_keys"
                    class="lg:w-1/4 md:w-1/2 w-full"
                  />
                </vs-select>
                <span
                  v-if="issueSubmitted && errors.has('Accounting_mobile_key')"
                  class="text-danger text-sm"
                  >{{
                    errors.first("Accounting_mobile_key") | text_formatter
                  }}</span
                >
              </div>
              <div class="vx-col lg:w-3/4 md:w-2/3 w-full md:pl-1 md:mt-0 mt-3">
                <vs-input
                  name="AccountingMobile"
                  v-validate="'required|numeric|length:8'"
                  :placeholder="`${$t('main.Mobile')}`"
                  v-model="contacts.mobile[3].number"
                  class="w-full"
                ></vs-input>
                <span
                  v-if="issueSubmitted && errors.has('AccountingMobile')"
                  class="text-danger text-sm"
                  >{{ errors.first("AccountingMobile") | text_formatter }}</span
                >
              </div>
            </div>
          </div>
          <div class="vx-col lg:w-1/4 md:w-1/2 w-full mb-4">
            <vs-input
              name="AccountingEmail"
              v-validate="'required|email'"
              :placeholder="`${$t('main.Email')}`"
              v-model="contacts.email[3].email"
              class="w-full"
            ></vs-input>
            <span
              v-if="issueSubmitted && errors.has('AccountingEmail')"
              class="text-danger text-sm"
              >{{ errors.first("AccountingEmail") | text_formatter }}</span
            >
          </div>
        </div>
        <!-- /Accounting -->
        <!-- Reception -->
        <div class="vx-row">
          <div class="vx-col mb-2 w-full">
            <label>
              {{ $t("InstitutionDetails." + contacts.tel[1].type) }}
              <sup class="text-danger">*</sup>
            </label>
          </div>
          <div class="vx-col lg:w-1/4 md:w-1/2 w-full mb-4">
            <div class="vx-row mb-4">
              <div class="vx-col lg:w-1/4 md:w-1/3 w-full md:pr-0">
                <vs-select
                  v-model="contacts.tel[1].key"
                  name="Reception_tel_key"
                  class="select-small w-full"
                >
                  <vs-select-item
                    :key="index"
                    :value="item"
                    :text="item"
                    v-for="(item, index) in tel_keys"
                    class="lg:w-1/4 md:w-1/2 w-full"
                  />
                </vs-select>
                <span
                  v-if="issueSubmitted && errors.has('Reception_tel_key')"
                  class="text-danger text-sm"
                  >{{
                    errors.first("Reception_tel_key") | text_formatter
                  }}</span
                >
              </div>
              <div class="vx-col lg:w-3/4 md:w-2/3 w-full md:pl-1 md:mt-0 mt-3">
                <vs-input
                  name="ReceptionTel"
                  v-validate="'required|numeric|length:8'"
                  :placeholder="$t('main.Tel')"
                  v-model="contacts.tel[1].number"
                  class="w-full"
                ></vs-input>
                <span
                  v-if="issueSubmitted && errors.has('ReceptionTel')"
                  class="text-danger text-sm"
                  >{{ errors.first("ReceptionTel") | text_formatter }}</span
                >
              </div>
            </div>
          </div>
          <div class="vx-col lg:w-1/4 md:w-1/2 w-full mb-4">
            <vs-input
              name="ReceptionFax"
              v-validate="'required|min:2|max:15|regex:^[0-9\-\+]*$'"
              :placeholder="$t('main.Fax')"
              v-model="contacts.fax[1].number"
              class="w-full"
            ></vs-input>
            <span
              v-if="issueSubmitted && errors.has('ReceptionFax')"
              class="text-danger text-sm"
              >{{ errors.first("ReceptionFax") | text_formatter }}</span
            >
          </div>
          <div class="vx-col lg:w-1/4 md:w-1/2 w-full mb-4">
            <div class="vx-row mb-4">
              <div class="vx-col lg:w-1/4 md:w-1/3 w-full md:pr-0">
                <vs-select
                  v-model="contacts.mobile[1].key"
                  name="ReceptionTelkey"
                  class="select-small w-full"
                >
                  <vs-select-item
                    :key="index"
                    :value="item"
                    :text="item"
                    v-for="(item, index) in mobile_keys"
                    class="w-full"
                  />
                </vs-select>
                <span
                  v-if="issueSubmitted && errors.has('ReceptionTelkey')"
                  class="text-danger text-sm"
                  >{{ errors.first("ReceptionTelkey") | text_formatter }}</span
                >
              </div>
              <div class="vx-col lg:w-3/4 md:w-2/3 w-full md:pl-1 md:mt-0 mt-3">
                <vs-input
                  name="ReceptionMobile"
                  v-validate="'required|numeric|length:8'"
                  :placeholder="`${$t('main.Mobile')}`"
                  v-model="contacts.mobile[1].number"
                  class="w-full"
                ></vs-input>
                <span
                  v-if="issueSubmitted && errors.has('ReceptionMobile')"
                  class="text-danger text-sm"
                  >{{ errors.first("ReceptionMobile") | text_formatter }}</span
                >
              </div>
            </div>
          </div>
          <div class="vx-col lg:w-1/4 md:w-1/2 w-full mb-4">
            <vs-input
              name="ReceptionEmail"
              v-validate="'required|email'"
              :placeholder="$t('main.Email')"
              v-model="contacts.email[1].email"
              class="w-full"
            ></vs-input>
            <span
              v-if="issueSubmitted && errors.has('ReceptionEmail')"
              class="text-danger text-sm"
              >{{ errors.first("ReceptionEmail") | text_formatter }}</span
            >
          </div>
        </div>
        <!-- /Reception -->
        <!-- Admission -->
        <div class="vx-row">
          <div class="vx-col mb-2 w-full">
            <label>
              {{ $t("InstitutionDetails." + contacts.tel[2].type) }}
              <sup class="text-danger">*</sup>
            </label>
          </div>
          <div class="vx-col lg:w-1/4 md:w-1/2 w-full mb-4">
            <div class="vx-row mb-4">
              <div class="vx-col lg:w-1/4 md:w-1/3 w-full md:pr-0">
                <vs-select
                  v-model="contacts.tel[2].key"
                  name="AdmissionTelkey"
                  class="select-small w-full"
                >
                  <vs-select-item
                    :key="index"
                    :value="item"
                    :text="item"
                    v-for="(item, index) in tel_keys"
                    class="w-full"
                  />
                </vs-select>
                <span
                  v-if="issueSubmitted && errors.has('AdmissionTelkey')"
                  class="text-danger text-sm"
                  >{{ errors.first("AdmissionTelkey") | text_formatter }}</span
                >
              </div>
              <div class="vx-col lg:w-3/4 md:w-2/3 w-full md:pl-1 md:mt-0 mt-3">
                <vs-input
                  name="AdmissionTel"
                  v-validate="'required|numeric|length:8'"
                  :placeholder="`${$t('main.Tel')}`"
                  v-model="contacts.tel[2].number"
                  class="w-full"
                ></vs-input>
                <span
                  v-if="issueSubmitted && errors.has('AdmissionTel')"
                  class="text-danger text-sm"
                  >{{ errors.first("AdmissionTel") | text_formatter }}</span
                >
              </div>
            </div>
          </div>
          <div class="vx-col lg:w-1/4 md:w-1/2 w-full mb-4">
            <vs-input
              name="AdmissionFax"
              v-validate="'required|min:2|max:15|regex:^[0-9\-\+]*$'"
              :placeholder="`${$t('main.Fax')}`"
              v-model="contacts.fax[2].number"
              class="w-full"
            ></vs-input>
            <span
              v-if="issueSubmitted && errors.has('AdmissionFax')"
              class="text-danger text-sm"
              >{{ errors.first("AdmissionFax") | text_formatter }}</span
            >
          </div>
          <div class="vx-col lg:w-1/4 md:w-1/2 w-full mb-4">
            <div class="vx-row mb-4">
              <div class="vx-col lg:w-1/4 md:w-1/3 w-full md:pr-0">
                <vs-select
                  v-model="contacts.mobile[2].key"
                  name="AdmissionTelkey"
                  class="select-small w-full"
                >
                  <vs-select-item
                    :key="index"
                    :value="item"
                    :text="item"
                    v-for="(item, index) in mobile_keys"
                    class="w-full"
                  />
                </vs-select>
                <span
                  v-if="issueSubmitted && errors.has('AdmissionTelkey')"
                  class="text-danger text-sm"
                  >{{ errors.first("AdmissionTelkey") | text_formatter }}</span
                >
              </div>
              <div class="vx-col lg:w-3/4 md:w-2/3 w-full md:pl-1 md:mt-0 mt-3">
                <vs-input
                  name="AdmissionMobile"
                  v-validate="'required|numeric|length:8'"
                  :placeholder="`${$t('main.Mobile')}`"
                  v-model="contacts.mobile[2].number"
                  class="w-full"
                ></vs-input>
                <span
                  v-if="issueSubmitted && errors.has('AdmissionMobile')"
                  class="text-danger text-sm"
                  >{{ errors.first("AdmissionMobile") | text_formatter }}</span
                >
              </div>
            </div>
          </div>
          <div class="vx-col lg:w-1/4 md:w-1/2 w-full mb-4">
            <vs-input
              name="AdmissionEmail"
              v-validate="'required|email'"
              :placeholder="`${$t('main.Email')}`"
              v-model="contacts.email[2].email"
              class="w-full"
            ></vs-input>
            <span
              v-if="issueSubmitted && errors.has('AdmissionEmail')"
              class="text-danger text-sm"
              >{{ errors.first("AdmissionEmail") | text_formatter }}</span
            >
          </div>
        </div>
        <!-- /Admission -->
        <!-------------------------- / Contacts -------------------------->
        <!-------------------------- Internet -------------------------->
        <vs-divider position="center">{{
          $t("InstitutionDetails.Internet")
        }}</vs-divider>

        <div class="vx-row mb-4">
          <div class="vx-col lg:w-1/3 md:w-1/2 w-full mb-4">
            <vs-input
              class="w-full"
              name="website"
              v-validate="'min:10|url'"
              :label="`${$t('InstitutionDetails.website')}`"
              v-model="website"
              type="url"
            />
            <span v-if="websiteError" class="text-danger text-sm">{{
              $t("form.please_enter_valid_website")
            }}</span>
            <span
              v-if="issueSubmitted && errors.has('website')"
              class="text-danger text-sm"
              >{{ errors.first("website") | text_formatter }}</span
            >
          </div>
          <div class="vx-col lg:w-1/3 md:w-1/2 w-full mb-4">
            <vs-input
              class="w-full"
              :label="`${$t('InstitutionDetails.Facebook')}`"
              v-validate="'min:10|url'"
              name="Facebook"
              v-model="Facebook"
              type="url"
            />
            <span v-if="FacebookError" class="text-danger text-sm">{{
              $t("form.please_enter_valid_facebook_url")
            }}</span>

            <span
              v-if="issueSubmitted && errors.has('Facebook')"
              class="text-danger text-sm"
              >{{ errors.first("Facebook") | text_formatter }}</span
            >
          </div>
          <div class="vx-col lg:w-1/3 md:w-full w-full mb-4">
            <vs-input
              class="w-full"
              :label="`${$t('InstitutionDetails.Instagram')}`"
              v-validate="'min:10|url'"
              name="Instagram"
              v-model="Instagram"
              type="url"
            />
            <span v-if="InstagramError" class="text-danger text-sm">{{
              $t("form.please_enter_valid_Instagram_url")
            }}</span>

            <span
              v-if="issueSubmitted && errors.has('Instagram')"
              class="text-danger text-sm"
              >{{ errors.first("Instagram") | text_formatter }}</span
            >
          </div>
        </div>
        <!-------------------------- / Internet -------------------------->
        <div class="vx-row" id="actions-box">
          <div class="vx-col w-full pt-4 flex items-center justify-between">
            <vs-button @click="back()" color="warning" type="border">{{
              $t("InstitutionDetails.Cancel")
            }}</vs-button>
            <!-- v-if="isEditing" -->
            <!-- <vs-button
            v-else-if="isEditing==false"
            color="warning"
            type="border"
            @click="input25 = input26 = input27 = input28 = input29 = input30 = ''; check7 = false;"
          >{{$t('basic.reset')}}</vs-button> -->

            <vs-button v-if="isEditing" @click.stop="submitData()">{{
              $t("InstitutionDetails.Edit")
            }}</vs-button>
            <vs-button
              @click.stop="submitData()"
              v-else-if="isEditing == false"
              >{{ $t("InstitutionDetails.Save") }}</vs-button
            >
          </div>
        </div>
      </form>
    </vs-card>
  </div>
</template>
<script>
import GoogleMapInfoWindow from '../components/GoogleMapInfoWindow.vue'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
import {commonDataMixin} from '@/mixins.js'
import  UploadInput from '@/components/UploadInput.vue'
const InstatutionRepository = RepositoryFactory.get(
  'ConfigurationModule',
  'institutions'
)
import * as Vibrant from 'node-vibrant'
export default {
  name: 'Create',
  components: { GoogleMapInfoWindow, UploadInput },
  mixins:[commonDataMixin],

  data () {
    return {
      mobile_keys: [],
      tel_keys: [],
      previewMode: false,
      FacebookError: false,
      InstagramError: false,
      websiteError: false,
      googleError: false,
      allNameData: { longName: null, ShortName: null },
      allAddressData: { desc: null },

      locales: this.$store.getters['main/locales'],
      error: '',
      lat: '',
      lon: '',
      logo: null,
      logoURL: null,
      logoFile:null,
      favicon:null,
      faviconURL:null,
      faviconFile:null,
      success: '',
      contacts: {
        tel: [
          { key: '', number: '', type: this.$t('InstitutionDetails.General') },
          { key: '', number: '', type: this.$t('InstitutionDetails.Reception') },
          { key: '', number: '', type: this.$t('InstitutionDetails.Admission') },
          { key: '', number: '', type:this.$t('InstitutionDetails.Accounting') }
        ],
        fax: [
          { number: '', type: this.$t('InstitutionDetails.General') },
          { number: '', type: this.$t('InstitutionDetails.Reception') },
          { number: '', type: this.$t('InstitutionDetails.Admission')},
          { number: '', type: this.$t('InstitutionDetails.Accounting')}
        ],
        mobile: [
          { key: '', number: '', type: this.$t('InstitutionDetails.General') },
          { key: null, number: '', type: this.$t('InstitutionDetails.Reception') },
          { key: null, number: '', type: this.$t('InstitutionDetails.Admission')},
          { key: null, number: '', type: this.$t('InstitutionDetails.Accounting')}
        ],
        email: [
          { email: '', type: this.$t('InstitutionDetails.General') },
          { email: '', type: this.$t('InstitutionDetails.Reception') },
          { email: '', type: this.$t('InstitutionDetails.Admission')},
          { email: '', type: this.$t('InstitutionDetails.Accounting')}
        ]
      },
      loaded: false,
      markers: [{ position: { lat: null, lng: null }, infoText: 'Marker 1' }],
      website: null,
      Facebook: null,
      Instagram: null,
      isEditing: false,
      AllData: {},
      issueSubmitted: false,
      currentPlace: null,
      brandColor:null,
      mapErrorAlert:false

    }
  },
  methods: {
    smoothScroll (){
        document.querySelector('#actions-box').scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start'
        });
    },
    updateLocation (loc) {
      if (loc) {
        this.mapErrorAlert = false
        const
          lat = parseFloat(loc.latLng.lat()),
          lng = parseFloat(loc.latLng.lng())

        this.markers[0].position.lat = lat
        this.markers[0].position.lng = lng
        this.currentPlace = loc
        if (this.loaded) this.autocompleteValue  = `${lat},${lng}`
      } else {
        this.mapErrorAlert = true

      }

    },
    async setPlace (place) {


      if (place.geometry) {
        this.mapErrorAlert = false
        this.currentPlace = place
        this.markers[0].position.lat = parseFloat(place.geometry.location.lat())
        this.markers[0].position.lng = parseFloat(place.geometry.location.lng())
        if (await this.loaded) this.$refs.googleMap.center =  this.markers[0].position
        this.autocompleteValue =  this.currentPlace.formatted_address
      } else if (place.latLng) {
        this.mapErrorAlert = false
      } else {
        this.mapErrorAlert = true

      }


    },

    //for google map
    async googleAddress () {
      if (await this.loaded)  this.loaded = false
      else {
        await  (this.loaded = true)
        if (this.currentPlace) {
          if (this.currentPlace.formatted_address)  this.setPlace(this.currentPlace)
          else   this.updateLocation(this.currentPlace)


        } else if (!this.markers[0].position.lat)         {
          /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          if (navigator.geolocation) if (await this.loaded) this.$refs.googleMap.getGoogleCurrent()
          else this.error = this.$t('form.geolocation_is_not_supported')
        }
      }
      return this.loaded
    },

    autoCompleteShowLatLng () {
      this.autocompleteValue  = `${this.markers[0].position.lat},${this.markers[0].position.lng}`
    },
    reload () {

      this.$router.go()
    },
    back () {

      this.$store.dispatch('main/getInstitutionData').then(res => this.$vs.theme({primary: res.brandColor}))
      this.$router.back()
    },

    //add data
    submitData () {
      this.issueSubmitted = true
      this.FacebookError = false
      this.InstagramError = false
      this.websiteError = false
      this.googleError = false
      if (!this.logo || !this.favicon) {
        this.errMsg(this.$t('InstitutionDetails.please_upload_the_logo_files'))
        return
      }


      this.$validator.validate()
        .then((valid) => {

          this.AllInstatutionLocalesData()
          InstatutionRepository.createInstitution(this.AllData)
            .then(
              (response) => {
                this.$store.commit('Update_institution_data', response.institution)

                this.successMsg(this.$t('InstitutionDetails.institution_info_has_been_added_successfully'))

                this.$router.push({ name: 'Home' })
              },
              (error) => {
                if (error?.errors) {
                  if (error.errors['website']) {
                    this.websiteError = true
                  }
                  if (error.errors.lat) {
                    this.googleError = true
                  }
                  if (error.errors.facebook) {
                    this.FacebookError = true
                  }
                  if (error.errors.instagram) {
                    this.InstagramError = true
                  }
                }

                this.errMsg(error)
              }).catch(this.errMsg)
        }).catch(this.errMsg)
    },
    AllInstatutionLocalesData () {
      this.locales.forEach((key, locale) => {
        this.$set(this.AllData, key, {
          short_name: this.allNameData.ShortName,
          long_name: this.allNameData.longName,
          address: this.allAddressData.desc
        })
      })
      this.$set(this.AllData, 'lat', this.markers[0].position.lat)
      this.$set(this.AllData, 'long', this.markers[0].position.lng)
      this.$set(this.AllData, 'contacts', this.contacts)
      this.$set(this.AllData, 'website', this.website)
      this.$set(this.AllData, 'facebook', this.Facebook)
      this.$set(this.AllData, 'instagram', this.Instagram)
      this.$set(this.AllData, 'logo', this.logo)
      this.$set(this.AllData, 'favicon', this.favicon)
      this.$set(this.AllData, 'brandColor', this.brandColor)

    },
    showPosition (position) {
      this.markers[0].position.lat = parseFloat(position.coords.latitude)
      this.markers[0].position.lng = parseFloat(position.coords.longitude)
    },

    onChange (e, type) {
      const
        // Get file
        file = e.target.files[0],
        // Check Validation
        is_fileType_valid = ['image/jpg', 'image/png'].some(e => file.type.includes(e)),
        is_fileSize_valid = type === 'logo' ? ((file.size / 1024) < 60) : ((file.size / 1024) < 5)


      // Images Links
      let logoUrl = '', faviconUrl = ''
      if (is_fileType_valid && is_fileSize_valid) {
        if (type === 'logo')  (logoUrl = URL.createObjectURL(file))
        else  (faviconUrl = URL.createObjectURL(file))


        //////////////////////////////////////
        const formData = new FormData()

        formData.append('image', e.target.files[0])
        formData.append('directory', 'institution/logo')
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
        //////////////////////////////
        let imageData = null

        if (type === 'logo') imageData = this.logo
        else  imageData = this.favicon


        formData.append('imageData', imageData)
        InstatutionRepository.uploadeImage(formData, config)
          .then((res) => {

            if (type === 'logo') {
              this.logoURL = logoUrl
              this.logo = `institution/logo/${res.data}`
              this.getLogoColor()
              this.$store.commit('main/Update_logoImg', this.logoURL)
              this.previewMode = true
            } else  {
              this.faviconURL = faviconUrl
              this.favicon = `institution/logo/${ res.data}`
              this.$store.commit('main/Update_favicon', this.faviconURL)

            }

          })
          .catch(() => this.errMsg(
            this.$t('basic.Something_went_wrong_check_your_connection')
          ))

      } else {
        if (type === 'logo')  this.logo = this.logoURL = this.logoFile = null
        else this.favicon = this.faviconURL  = this.faviconFile = null

        let msg = ''
        if (!is_fileType_valid) msg =  `${this.$t('basic.accept_only')} PNG`
        else msg = `${this.$t('basic.accept_only')} ${this.$t('basic.max_size')} ${type === 'logo' ? '60' : '5'}KB`
        this.errMsg(msg)
      }
    },
    getLogoColor () {
      //  Set Logo color
      Vibrant.from(this.logoURL).getPalette()
        .then((palette) => {
          const color = palette.Vibrant.hex
          // this.$vs.theme({
          //   primary: color
          // })
          this.brandColor = color
          this.$store.commit('UPDATE_PRIMARY_COLOR', color)

        })
    },
    async  loadeInstatution () {
      await this.VSLoading()
      await  InstatutionRepository.getInstitution()
        .then(async (response) => {

          this.isEditing = true

          const
            inst = response.institution,
            lat = parseFloat(inst.lat),
            lng = parseFloat(inst.long)

          // Names
          this.allNameData.ShortName = inst[this.current_locale]?.short_name
          this.allNameData.longName = inst[this.current_locale]?.long_name

          // Contacts
          this.contacts = inst.contacts
          this.website = inst.website
          this.Facebook = inst.facebook
          this.Instagram = inst.instagram

          // Logo
          this.logo = inst.logo && inst.logo.includes('institution/logo') ? inst.logo : null
          this.logoURL = this.logo ? inst.imageURL : null
          this.favicon =   inst.favicon && inst.favicon.includes('institution/logo') ? inst.favicon : null
          this.faviconURL =   inst.favicon ? inst.faviconURL : null
          this.brandColor = this.$store.state.themePrimaryColor

          // Address
          this.allAddressData.desc =  inst[this.current_locale]?.address
          if (lat && lng) {
            this.markers[0].position.lat = lat
            this.markers[0].position.lng = lng
            // this.loaded = true
            this.autoCompleteShowLatLng()
          }
        })


      this.stopVSLoading()

    },
    // delete file
    deleteFile (url) {
      this.deleteDocumentFromS3({file_path:url}).then(() => {
        url = null
      })
    }
  },
  computed: {
    coordinates () {
      return `${this.lat}, ${this.lng}`
    },
    autocompleteValue:{
      get () {
        return  this.$refs.googleMap.locationText
      },
      async set (v) {
        if (await this.loaded) this.$refs.googleMap.locationText = v
        else this.$refs.googleMap.locationText = ''
      }
    }


  },
  created () {
    this.loadeInstatution()
    this.getTelKeys()
    this.getMobileKeys()
  },

  watch: {
    lat (v) {
      this.markers[0].position.lat = v
    },
    lon (v) {
      this.markers[0].position.lng = v
    },
    brandColor (color) {
      this.$store.commit('UPDATE_PRIMARY_COLOR', color)
      this.$vs.theme({
        primary: color
      })
    },
    // 'this.currentPlace' (val) {
    //   this.autocompleteValue  = val.formatted_address ?  val.formatted_address :
    // },
    'this.markers' () {
      if (!this.currentPlace.formatted_address) this.autoCompleteShowLatLng()
    }


  }
}
</script>
<style  scoped>
a:active,
a:visited,
a:hover,
a {
  cursor: pointer;
  color: black;
}
.selectExample {
  margin-left: 9px;
}
#preview {
  position: relative;
  /* background-color: #f7f7f7; */
}


a.button-pulse {
    text-align: center;
    text-decoration: none;
    color: yellowgreen;
    background-color: white;
    display: block;
    font-weight: 900;
    font-size: 12px;
    padding: 13px;
    border: 0.5px solid yellowgreen;
    border-radius: 25px;
    animation: pulse-e64639fa 1s infinite;
    margin-top: 20px;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 20px 0px rgb(170, 243, 23);
  }

  100% {
    box-shadow: 0 0 20px 35px rgba(0, 0, 0, 0);
  }
}

a:hover {
  cursor: pointer;
  background-color: rgb(170, 243, 23);
  color: white;
  animation: fill-background 1s 1;
}

@keyframes fill-background {
  0% {
    background-color: white;
  }

  100% {
    background-color: rgb(170, 243, 23);
  }
}

</style>
