import showWalletList from './pages/list'
import addFund from './pages/addFund'
import listFundHistory from './pages/listFundHistory'

export default [
  {
    path: '',
    name: 'wallet',
    component: showWalletList,
    props: true,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        {
          title: 'wallet.wallet',
          active: true
        }
      ],
      pageTitle: 'wallet.wallet',
      rule: 'TeacherSNDSMD'
    }
  },
  {
    path: 'add-fund/:user_id',
    name: 'add-fund',
    component: addFund,
    props: true,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        {
          title: 'wallet.wallet',
          url: '/wallet'
        },
        {
          title: 'wallet.addFund',
          active: true
        }
      ],
      pageTitle: 'wallet.addFund',
      rule: 'TeacherSNDSMD'
    }
  },
  {
    path: 'fund-history/:user_id',
    name: 'fund-history',
    component: listFundHistory,
    props: true,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        {
          title: 'wallet.wallet',
          url: '/wallet'
        },
        {
          title: 'wallet.fundHistory',
          active: true
        }
      ],
      pageTitle: 'wallet.fundHistory',
      rule: 'TeacherSNDSMD'
    }
  },
  {
    path: 'fund-transaction/:user_id',
    name: 'fund-transaction',
    component: listFundHistory,
    props: true,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        {
          title: 'wallet.wallet',
          url: '/wallet'
        },
        {
          title: 'wallet.transaction',
          active: true
        }
      ],
      pageTitle: 'wallet.transaction',
      rule: 'TeacherSNDSMD'
    }
  }
]
