var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BasicListPage',{attrs:{"syncReduceButton":_vm.syncReduceButton}},[_c('template',{slot:"leftSide"},[_c('div',{class:{'mb-3': _vm.$slots.leftTopRow}},[_vm._t("leftTopRow")],2),_c('div',{staticClass:"w-full flex flex-wrap md:flex-row flex-col-reverse justify-start items-stretch",attrs:{"id":"listPageWithCreate"}},[(_vm.$slots.leftSideButtonContent)?_c('div',{staticClass:"w-full flex-2",class:{'md:w-5/6': _vm.showCreateBtn}},[_vm._t("leftSideButtonContent")],2):_vm._e(),(_vm.showCreateBtn)?_c('div',{staticClass:"w-full flex-1 mb-4",class:{
          'md:w-1/6': _vm.$slots.leftSideButtonContent
        }},[(_vm.route)?_c('vs-button',{staticClass:"block h-full border-box-shadow",class:_vm.$slots.leftSideButtonContent
              ? 'w-full px-2'
              : `w-full md:w-auto ${
                  _vm.$vs.rtl ? 'ml-0 mr-auto' : 'mr-0 ml-auto'
                }`,attrs:{"id":"CreateBtn","to":_vm.route}},[_vm._v(_vm._s(_vm.createBtnName || this.$t('basic.create'))+" ")]):_vm._t("rightSideButtonContent")],2):_vm._e()]),_c('div',{staticClass:"block w-full"},[_vm._t("listDataContent")],2)]),(_vm.$slots.rightSide)?_c('template',{slot:"rightSide"},[_vm._t("rightSide")],2):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }