<template>
  <vs-table
    class="m-auto"
    :data="students"
    :hoverFlat="true"
    :noDataText="$t('assessments.no_students_assessed')"
    :class="{'no-data': !students.length}"
    id="student-score-table">
    <!-- Header -->
    <template slot="thead" v-if="students.length">
      <vs-th></vs-th>
      <vs-th class="table__header">
        <span> {{ $t('grading.set_grade_to_all_students') }} </span>
      </vs-th>
      <vs-th class="table__header">
        <div class="w-full">
          <template v-if="isPYPSection">
            <v-select
              :options="criteria"
              label="title"
              :reduce="(c) => c.key"
              v-model="allStudentsScore"
              class="w-full"
              :placeholder="$t('form.grade') | text_formatter"
              name="all_grades" />
          </template>
          <template v-else-if="isMYPSection">
            <div
              class="flex items-center"
              :class="{'mb-3': criteria.length}"
              v-for="(cr, i) in criteria"
              :key="i">
              <label class="w-1/5">{{ cr }}</label>
              <vs-input
                v-model="allStudentsScore[cr]"
                type="number"
                :max="8"
                min="0"
                class="w-4/5"
                :placeholder="$t('form.grade') | text_formatter"
                v-validate="'numeric|max_value:8|min_value:0'"
                name="all_grades" />
            </div>
          </template>
          <template v-else-if="isDPSection || isAmerican">
            <v-select
              :options="criteria"
              v-model="allStudentsScore"
              class="w-full"
              :placeholder="$t('form.grade') | text_formatter"
              name="all_grades"
          /></template>
          <span class="text-danger text-sm block w-full">{{
            errors.first('all_grades') ||
            getErrorMsgManual('all_grades') | text_formatter
          }}</span>
        </div>
      </vs-th>
      <vs-th v-if="showPoints" class="table__header">
        <div class="w-full">
          <vs-input
            v-model="allStudentsPoints"
            type="number"
            :max="maxPoints"
            :disabled="!maxPoints"
            min="0"
            class="w-full"
            :placeholder="$t('grading.points') | text_formatter"
            v-validate="{
              numeric: true,
              max_value: maxPoints,
              min_value: 0
            }"
            name="all_points" />
          <span class="text-danger text-sm block w-full">{{
            errors.first('all_points') ||
            getErrorMsgManual('all_points') | text_formatter
          }}</span>
        </div>
      </vs-th>
      <vs-th v-if="showAttended" class="table__header">
        <div class="w-full">
          <vs-switch v-model="allStudentsAttended" name="allStudentsAttended" />
          <span class="mx-2">{{
            $t('grading.attended') | text_formatter
          }}</span>
        </div>
      </vs-th>
    </template>
    <!-- Rows -->
    <template slot-scope="{data}">
      <vs-tr
        v-for="(tr, indextr) in (data = students)"
        :data="tr"
        :key="indextr">
        <template v-for="(row_data, field, indx) in tr">
          <vs-td v-if="field === 'name' || field === 'id'" :key="indx">
            <!-- Data -->
            {{ row_data }}
          </vs-td>
        </template>
        <vs-td v-if="showAttended">
          <vs-switch v-model="tr.is_attended" name="StudentsAttended" />
        </vs-td>
        <vs-td>
          <div class="">
            <template v-if="isPYPSection">
              <v-select
                :options="criteria"
                label="title"
                :reduce="(c) => c.key"
                v-model="tr.score"
                class="w-full"
                :placeholder="$t('form.grade') | text_formatter"
                v-validate="{
                  required: !Boolean(tr.score)
                }"
                :name="`${tr.name.split(' ')[0]}_grade`" />
            </template>
            <template v-else-if="isMYPSection">
              <div
                class="flex items-center"
                :class="{'mb-3': criteria.length}"
                v-for="(cr, i) in criteria"
                :key="i">
                <label class="w-1/5">{{ cr }}</label>
                <vs-input
                  v-model="tr.score[cr]"
                  type="number"
                  :max="8"
                  min="0"
                  class="w-4/5"
                  :placeholder="$t('form.grade') | text_formatter"
                  v-validate="{
                    required: true,
                    numeric: true,
                    max_value: 8,
                    min_value: 0
                  }"
                  :name="`${tr.name.split(' ')[0]}_grade`" />
              </div>
            </template>
            <template v-else-if="isDPSection || isAmerican">
              <v-select
                :options="criteria"
                v-model="tr.score"
                class="w-full"
                :placeholder="$t('form.grade') | text_formatter"
                v-validate="{required: !Boolean(tr.score)}"
                :name="`${tr.name.split(' ')[0]}_grade`"
                :value="tr.score"
            /></template>

            <span class="text-danger text-sm block w-full">{{
              errors.first(`${tr.name.split(' ')[0]}_grade`) ||
              getErrorMsgManual(`${tr.name.split(' ')[0]}_grade`)
                | text_formatter
            }}</span>
          </div>
        </vs-td>
        <vs-td v-if="showPoints">
          <vs-input
            v-model="tr.points"
            type="number"
            :max="maxPoints"
            min="0"
            class="w-full"
            :placeholder="$t('grading.points') | text_formatter"
            :disabled="!maxPoints"
            v-validate="{
              required: !Boolean(tr.points),
              numeric: true,
              max_value: maxPoints,
              min_value: 0
            }"
            :name="`${tr.name.split(' ')[0]}_points`" />
          <span class="text-danger text-sm block w-full">{{
            errors.first(`${tr.name.split(' ')[0]}_points`) ||
            getErrorMsgManual(`${tr.name.split(' ')[0]}_points`)
              | text_formatter
          }}</span>
        </vs-td>
      </vs-tr>
    </template>
  </vs-table>
</template>
<script>
import gradingMixins from '../grading.mixins'

export default {
  name: 'student-score-table',
  inject: ['parentValidator'],
  mixins: [gradingMixins],
  props: {
    students: {
      type: Array,
      require: true
    },
    criteria: {
      type: Array,
      require: true
    },
    showPoints: {
      type: Boolean,
      require: false,
      default: false
    },
    maxPoints: {
      type: Number,
      require: false,
      default: 8
    },
    showAttended: {
      type: Boolean,
      require: false,
      default: false
    }
  },

  data() {
    return {
      allStudentsScore: null,
      allStudentsPoints: null,
      allStudentsAttended: null
    }
  },
  computed: {
    validateAllScores() {
      return !this.errors.any()
    }
  },
  watch: {
    validateAllScores(v) {
      this.$emit('validateScores', v)
    },
    criteria(v) {
      this.setAllStudentsScoreWatchers()
    },
    allStudentsPoints(v) {
      this.students.map((s) => {
        s.points = v
      })
    },
    allStudentsAttended(v) {
      this.students.map((s) => {
        s.is_attended = v
      })
    }
  },
  methods: {
    setAllStudentsScoreWatchers() {
      if (this.isMYPSection) {
        this.allStudentsScore = {}
        this.criteria.map((e) => {
          this.$set(this.allStudentsScore, e, null)
          this.$watch(`allStudentsScore.${e}`, (v) => {
            this.students.map((s) => {
              s.score[e] = v
            })
          })
        })
      } else {
        this.allStudentsScore = null
        this.$watch('allStudentsScore', (v) => {
          this.students.map((s) => {
            s.score = v
          })
        })
      }
    },
    getErrorMsgManual(name = null) {
      return this.errors.items.find((e) => e.field === name)?.msg || ''
    }
  },

  created() {
    this.setAllStudentsScoreWatchers()
    this.$validator = this.parentValidator || this.$validator
  },
  updated() {},
  beforeDestroy() {}
}
</script>
<style lang="scss">
#student-score-table {
  &:not(.no-data) .vs-con-tbody {
    min-height: 300px;
    max-height: 100%;
  }
  tr:nth-child(2n) {
    background-color: transparent;
  }
  .v-select {
    min-width: 190px;
  }
  .vs-input {
    min-width: 50px;
  }
}
</style>
