<template>
  <sideBar
    :filters_prop="custom_filter"
    @refilter="setFilters"
    :loading="loadingFilter">
    <!--    <template slot="extra_content">-->
    <!--      <div class="w-full mb-10">-->
    <!--        <vs-input-->
    <!--          :label-placeholder="$t('form.search_by_name')"-->
    <!--          v-model="searchContent"-->
    <!--          class="w-full"-->
    <!--          name="name"-->
    <!--          icon="icon icon-search"-->
    <!--          icon-pack="feather"-->
    <!--          @keyup.enter="search" />-->
    <!--      </div>-->
    <!--    </template>-->
  </sideBar>
</template>

<script>
import sideBar from '@/components/general/RoundFilter'
import {commonDataMixin} from '@/mixins.js'

export default {
  name: 'FilterBar',
  components: {sideBar},
  mixins: [commonDataMixin],
  data() {
    return {
      //////////////////////////
      // Filters
      /////////////////////////

      subjects: [],
      schools: [],
      custom_filter: [
        {
          name: this.$t('basic.academic_year'),
          payload_name: 'academic_year_id',
          singleSeletable: false,
          by: []
        },
        {
          name: this.$t('basic.schools'),
          payload_name: 'schools',
          onChangeFireAction: true,
          by: []
        },
        {
          name: this.$t('basic.divisions'),
          payload_name: 'divisions',
          onChangeFireAction: true,
          by: []
        },
        {
          name: this.$t('basic.sections'),
          payload_name: 'sections',
          onChangeFireAction: true,
          by: []
        },
        {
          name: this.$t('basic.grades'),
          payload_name: 'grades',
          by: []
        },
        {
          name: this.$t('basic.status'),
          payload_name: 'statuses',

          by: [
            {id: 'active', name: this.$t('status.active'), checked: false},
            {
              id: 'suspended',
              name: this.$t('status.suspended'),
              checked: false
            },
            {
              id: 'expired',
              name: this.$t('status.expired'),
              checked: false
            }
          ]
        }
      ],

      // Search
      searchContent: '',
      loadingFilter: false
    }
  },
  computed: {
    schoolID() {
      return this.authUser.school_id
    },
    sideFilters() {
      const obj = {}
      this.custom_filter.forEach((c) => {
        // if (c.payload_name === 'schools' && !c.by.some((e) => e.checked)) {
        //   obj['schools'] = [this.schoolID]
        // } else obj[c.payload_name] = null
        obj[c.payload_name] = null
      })
      return obj
    }
  },

  methods: {
    //////////////////////
    // Helpers
    /////////////////////
    setFilters(val) {
      val.map((item) => {
        this.sideFilters[item.name] = item.checked
      })
      this.reloadData()
    },
    getCustomFilterEl(payload_name) {
      return this.custom_filter.find((e) => e.payload_name === payload_name)
    },
    byObj(el) {
      return {
        id: el.id || el._id,
        name: el.name,
        checked: false
      }
    },
    resetOptions(arrOfPayloadNames) {
      arrOfPayloadNames.forEach((payload_name) => {
        this.getCustomFilterEl(payload_name).by = []
      })
    },
    search() {
      this.sideFilters.name = this.searchContent
      if (this.searchContent.length >= 3 || !this.searchContent) {
        this.reloadData()
      }
    },
    reloadData() {
      this.$emit('reloadData', this.sideFilters)
    },
    //////////////////////
    // APIs
    /////////////////////
    getSchools() {
      this.loadingFilter = true
      this.resetOptions(['divisions', 'sections', 'grades'])

      this.getFilterData()
        .then((res) => {
          res.schools.map((el) => {
            this.getCustomFilterEl('schools').by.push(this.byObj(el))
          })
        })
        .catch(this.errMsg)
        .finally(() => {
          this.loadingFilter = false
        })
    },
    getDivisions(chosen) {
      this.loadingFilter = true
      this.resetOptions(['divisions', 'sections', 'grades'])
      this.getFilterData({schools: chosen.schools})
        .then((res) => {
          res.divisions.map((el) => {
            this.getCustomFilterEl('divisions').by.push(this.byObj(el))
          })
        })
        .catch(this.errMsg)
        .finally(() => {
          this.loadingFilter = false
        })
    },
    getSections(chosen) {
      this.loadingFilter = true
      const data = {
        schools: chosen.schools,
        divisions: chosen.divisions
      }
      this.resetOptions(['sections', 'grades'])

      this.getFilterData(data)
        .then((res) => {
          res.sections.map((el) => {
            this.getCustomFilterEl('sections').by.push(this.byObj(el))
          })
        })
        .catch(this.errMsg)
        .finally(() => {
          this.loadingFilter = false
        })
    },
    getGrades(chosen) {
      this.loadingFilter = true

      this.resetOptions(['grades'])
      const data = {
        schools: [this.schoolID],
        divisions: chosen.divisions,
        sections: chosen.sections
      }
      this.getFilterData(data)
        .then((res) => {
          res.grades.map((el) => {
            this.getCustomFilterEl('grades').by.push(this.byObj(el))
          })
        })
        .catch(this.errMsg)
        .finally(() => {
          this.loadingFilter = false
        })
    },
    getAllAcademicYears() {
      this.getAllAcademic().then((res) => {
        res.forEach((el) => {
          this.getCustomFilterEl('academic_year_id').by.push(this.byObj(el))
        })
      })
    }
  },
  created() {
    this.getSchools()
    this.getAllAcademicYears()
    this.$root.$on('onChange:schools', (v) => {
      this.getDivisions(v)
    })
    this.$root.$on('onChange:divisions', (v) => {
      this.getSections(v)
    })
    this.$root.$on('onChange:sections', (v) => {
      this.getGrades(v)
    })
  },
  beforeDestroy() {
    this.$root.$off('onChange:schools')
    this.$root.$off('onChange:divisions')
    this.$root.$off('onChange:sections')
  }
}
</script>

<style scoped></style>
