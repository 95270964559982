import { render, staticRenderFns } from "./unit-materialization.vue?vue&type=template&id=11c27935&scoped=true"
import script from "./unit-materialization.js?vue&type=script&lang=js&external"
export * from "./unit-materialization.js?vue&type=script&lang=js&external"
import style0 from "./unit-materialization.scss?vue&type=style&index=0&id=11c27935&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11c27935",
  null
  
)

export default component.exports