import FileUploader from '@/modules/unit-planner/components/uploadButton/uploadButton.vue'
// import Service from '../../services.js'
import vSelect from 'vue-select'
import {
  mapGetters
} from 'vuex'
import {
  RepositoryFactory
} from '@/Repositories/RepositoryFactory'
const ExamRepo = RepositoryFactory.get('ExamModule', 'Exam')

export default {
  components: {
    FileUploader,
    'v-select': vSelect
  },
  name: 'GradeModal',
  data () {
    return {
      modalProps: {
        width: '1050px',
        height: 'auto',
        minHeight: 500,
        scrollable: true
      },
      modalName: 'GradeModal',
      exam: {},
      assess: {},
      questions: [],
      criterias: [],
      studentID: '',
      currentSection: '',
      teacherComment: '',
      alreadySubmited: false,
      selectedCriteria: null,
      examId: this.$route.params.id,
      imgBaseUrl: this.institutionAssetsLink,
      customEvents: [
        {
          eventName: 'openGradeModal',
          callback: this.loadData
        }
      ]
    }
  },
  created () {
    ////////////////////////////////////////////////////
    //**          Custom Event Listener           ** //
    //////////////////////////////////////////////////
    this.customEvents.forEach(function (customEvent) {
      // eslint-disable-next-line no-undef
      $sharedEvent.listen(customEvent.eventName, customEvent.callback)
    })
  },
  beforeDestroy () {
    this.$validator.reset()
    this.$validator.pause()
  },
  destroyed () {
    ////////////////////////////////////////////////////
    //**      Remove Custom Event Listener        ** //
    //////////////////////////////////////////////////
    this.customEvents.forEach(function (customEvent) {
      // eslint-disable-next-line no-undef
      $sharedEvent.off(customEvent.eventName, customEvent.callback)
    })
  },
  methods: {
    showModal () {
      this.$modal.show(this.modalName)
    },
    hideModal () {
      this.$modal.hide(this.modalName)
    },
    async loadData (studentID) {
      this.$vs.loading()
      this.resetProps()
      this.studentID = studentID
      await ExamRepo.getStudentExam(studentID).then((res) => {
        this.exam = res.exam
        this.questions = res.exam.questions
        this.assess = res.assess
        this.currentSection = res.exam.grade.section.name
        this.$vs.loading.close()
      })
      ExamRepo.getExamCriteria(this.examId)
        .then((res) => {
          this.criterias = res.data
        })
        .catch((err) => {
          console.log('err', err)
        })
      // this.getTaskStudents()
      this.showModal()
    },
    resetProps () {
      this.exam = Object.assign({})
      this.assess = Object.assign({})
      this.questions = []
      this.criterias = []
      this.currentSection = ''
      this.selectedCriteria = ''
      this.teacherComment = ''
    },
    mappingAssessmentData () {
      const data = {
        criteria: {}
      }
      /* debugger */
      if (this.currentSection === 'MYP') {
        this.criterias.forEach((item) => {
          data.criteria[item.key] = item.value
        })
        return data
      }
      data.criteria = [this.selectedCriteria.key]
      return data
    },
    mappingData () {
      /* debugger */
      const data = {
        criteria: {},
        student_message:this.teacherComment
      }
      if (this.exam.grading_type.type !== 'Points_Per_Question' || this.exam.type.type === 'Paper') {
        data.score = this.exam.score
      } else {
        const test = this.questions.map((item) => {
          return {
            id: item.id,
            score: parseInt(item.score)
          }
        })
        data.questions = test
      }
      if (this.currentSection === 'MYP') {
        this.criterias.forEach((item) => {
          data.criteria[item.key] = item.value
        })
        return data
      } else if (this.currentSection === 'PYP') {
        data.criteria = [this.selectedCriteria.key]
        return data
      } else {
        data.criteria = [this.selectedCriteria]
        return data
      }
    },
    async validation () {
      const validData = await this.$validator.validateAll()
      return Boolean(validData)
    },

    async submitForm () {
      if (this.is_submitted) {
        return false
      } else if (!this.is_submitted) {
        const isValidData = await this.validation()
        if (isValidData) {
          this.$vs.loading()
          // const is_graded = await this.submitGrade()
          const is_assessed = await this.submitAssess()
          if (is_assessed) {
            this.$vs.notify({
              title: 'Exam Assessment',
              text: 'Saved Successful',
              color: 'success',
              position: 'bottom-right'
            })
            this.$emit('FetchData')
            // ** Hide Loader **//
            this.resetProps()
            this.hideModal()
          }
          this.$vs.loading.close()
        } else {
          this.$vs.notify({
            title: 'Validation',
            text: 'Please Fill required data first',
            color: 'danger',
            position: 'bottom-right'
          })
        }

      }

    },
    // async submitGrade () {
    //   return new Promise((resolve, reject) => {
    //     if (this.exam.is_auto_grading) {
    //       resolve(true)
    //     } else {
    //       Axios.post(`teachers/exam-tasks/${this.studentID}/grade`, this.mappingData())
    //         .then(() => {
    //           resolve(true)
    //         })
    //         .catch(error => {
    //           this.$vs.notify({
    //             title: 'Error',
    //             text: error.data.msg,
    //             color: 'danger',
    //             position: 'bottom-right'
    //           })
    //           reject(error)
    //         })
    //     }
    //   })
    // },
    async submitAssess () {
      return new Promise((resolve, reject) => {
        ExamRepo.submitAssess(this.studentID, this.mappingData())
          .then(() => {
            resolve(true)
          })
          .catch(error => {
            reject(error)
            this.$vs.notify({
              title: 'Error',
              text: error.data.msg,
              color: 'danger',
              position: 'bottom-right'
            })
          })
      })
    },
    cancel () {
      this.resetProps()
      this.hideModal()
    }
  },


  computed: {
    ...mapGetters({
      loggedInUser: 'getLoggedInUserForPlanner'
    }),
    notDoneLabel () {
      const dueDate = `${this.task.assignment_date} ${this.task.assignment_time}`
      if (this.formatDateTime(new Date()) > dueDate) {
        return `${this.task.statistics.delayed} ${this.$t('basic.student_delayed')}`
      } else {
        return `${this.task.statistics.not_answered_yet} ${this.$t('basic.student_not_answered_yet')}`
      }
    },
    is_submitted () {
      return Boolean(this.assess.graded_at) && Boolean(this.assess.has_assess)
    }
  }
}
