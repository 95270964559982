import Service from '../../services/unit-planner'
export default {
  name: 'newFeature',
  data () {
    return {
      restService: Service,
      modalProps: {
        width: '600px',
        height: 'auto',
        minHeight:450,
        scrollable: true
      },
      modalName: 'createNewFeatureModal',
      newFeatureData:null,
      menuId:null,
      sendDataEvent:'addExtraFeature',
      customEvents: [{eventName: 'openCreateNewFeatureModal', callback: this.loadData}]
    }
  },
  created () {
    ////////////////////////////////////////////////////
    //**          Custom Event Listener           ** //
    //////////////////////////////////////////////////
    this.customEvents.forEach(function (customEvent) {
      // eslint-disable-next-line no-undef
      $sharedEvent.listen(customEvent.eventName, customEvent.callback)
    })
  },
  destroyed () {
    ////////////////////////////////////////////////////
    //**      Remove Custom Event Listener        ** //
    //////////////////////////////////////////////////
    this.customEvents.forEach(function (customEvent) {
      // eslint-disable-next-line no-undef
      $sharedEvent.off(customEvent.eventName, customEvent.callback)
    })
  },
  methods:{
    showModal () {
      this.$modal.show(this.modalName)
    },
    hideModal () {
      this.$modal.hide(this.modalName)
    },
    loadData (obj) {
      this.resetProps()
      this.menuId = obj.menuId
      this.newFeatureData = {
        name:'',
        teacher_unit_id: obj.teacherUnitId,
        icon:'icon-activity'
      }
      this.showModal()
    },
    async addExtraFeature () {
      //** Show Loader **//
      this.$vs.loading()

      await this.restService.addExtraFeatureToOutline(this.menuId, this.newFeatureData)
        .then(res => {
          this.save(res.data)
        })
        .catch(err => {
          console.error(err)
          this.$vs.notify({
            title: 'Extra Feature Error',
            text: err.message,
            color: 'danger',
            position: 'bottom-right'
          })
        })

      //** Hide Loader **//
      this.$vs.loading.close()
    },
    save (data) {
      // eslint-disable-next-line no-undef
      $sharedEvent.fire(this.sendDataEvent, data)

      this.$vs.notify({
        title: 'Extra Feature',
        text: 'Created Successful',
        color: 'success',
        position: 'bottom-right'
      })
      this.cancel()
    },
    cancel () {
      this.resetProps()
      this.hideModal()
    },
    resetProps () {
      this.menuId = null
      this.newFeatureData = null
    }
  }
}
