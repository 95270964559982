import axios from 'axios'

const resource = '/subjects'
export default {
  getDropDownData() {
    return axios.get('/round/create')
  },
  getGrades(id) {
    return axios.get(`/division/${id}/grades`)
  },
  getDivsGrades(payload) {
    return axios.post('/division/getDevsGrades', payload)
  },
  create(payload) {
    return axios.post(`${resource}`, payload)
  },
  getSubjectData(id) {
    return axios.get(`${resource}/${id}`)
  },
  update(id, payload) {
    return axios.put(`${resource}/${id}`, payload)
  },
  changeStatus(id, status) {
    return axios.get(`${id}/status/${status}`)
  },
  getAllSubjects() {
    return axios.get('/getAllSubjects')
  },
  addSubjectStrands(payload) {
    return axios.post('subject/strands/create', payload)
  },
  getSubjectStrands(subjectId) {
    return axios.get(`subject/strands/list/${subjectId}`)
  },
  updateSubjectStrand(strandId, payload) {
    return axios.post(`subject/strands/update/${strandId}`, payload)
  },
  updateSubjectStrands(payload) {
    return axios.post('subject/strands/subject-update', payload)
  },
  getSubjectsPaginated(params) {
    return axios.get('/subjects', {params})
  }
}
