<template>
<div>
    <vx-card class="p-relative">
        <h6 class="text-primary">Exam Tools</h6>
        <hr class="m-2">
        <vs-row>
            <vs-row class="runungExamInfFont mt-2" vs-type="flex" vs-justify="center" vs-align="center">
                <vs-chip style="width: 100%" class="status--dark">
                    <a class="no_style">
                        <vx-tooltip @click="Suspend" text="Suspend - reactivate - Archive" class="text-right inline-block">
                            <feather-icon icon="AlertCircleIcon" title="Suspend - reactivate - Archive" svgClasses="h-5 w-5   cursor-pointer" />
                        </vx-tooltip>
                    </a>
                    <span style="margin-left:10px">Suspend - reactivate - Archive</span>
                </vs-chip>
            </vs-row>
            <vs-row class="runungExamInfFont mt-2" vs-type="flex" vs-justify="center" vs-align="center">
                <vs-chip style="width: 100%" class="status--dark">
                    <a class="no_style">
                        <vx-tooltip text="View result base / Questions " class="text-right inline-block">
                            <feather-icon icon="EyeIcon" title="View result base / Questions" svgClasses="h-5 w-5   cursor-pointer" />
                        </vx-tooltip>
                    </a>
                    <span style="margin-left:10px">View result base / Questions </span>
                </vs-chip>
            </vs-row>
            <vs-row class="runungExamInfFont mt-2" vs-type="flex" vs-justify="start" vs-align="center">
                <vs-chip style="width: 100%" class="status--dark">
                    <a class="no_style">
                        <vx-tooltip text="Reveal Identities " class="text-right inline-block">
                            <feather-icon icon="ApertureIcon" title="Reveal Identities " svgClasses="h-5 w-5   cursor-pointer" />
                        </vx-tooltip>
                    </a>
                    <span style="margin-left:10px">Reveal Identities </span>
                </vs-chip>
            </vs-row>
        </vs-row>
    </vx-card>
</div>
</template>

<script>
import Axios from "axios"
export default {
    props: {
        exam: {
            type: Object,
            required: true
        }
    },
    methods: {
    Suspend () {
      Axios.post(`teachers/exams/${this.exam.id}/suspend`, this.exam).then(res => {
        console.log("res", res)
        this.$vs.notify({
          title: "Scusses",
          text: "Exam suspend",
          color: "green"
        })
      }).catch(err => {
        this.$vs.notify({
          title: 'Error',
          text:  err.message,
          color: 'danger'
        })
      })
    },
    },
}
</script>
