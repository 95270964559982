<template>
  <component :is="componentName" :student="studentData" />
</template>
<script>
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const StudentRepository = RepositoryFactory.get('StudentsModule', 'student')

export default {
  name:'separated-page',
  props:{
    componentName:{
      type:[String, Object],
      default:'div'
    }
  },
  data () {
    return {
      studentData:{}
    }
  },

  methods: {
    async getStudentData (
      repoFunction = 'getdata',
      params = this.$route.params.child_id
    ) {
      this.VSLoading()
      await StudentRepository[repoFunction](params)
        .then(res => { this.studentData = res.application })
        .catch(err => {
          if (this.authUserStudent) {
            this.studentData = {
              student_id: this.params.child_id,
              id: this.params.id,
              student: this.authUserStudent,
              student_full_details: { photo: this.authUserStudent.photo },
              fullName: this.authUserStudent.fullName,
              name: this.authUserStudent.fullName,
              divisionName: this.authUserStudent.divisionName || null,
              gradeName: this.authUserStudent.gradeName || null,
              round_id: this.authUserStudent.round_id
            }
          }
        })
    }
  },
  created () {
    this.getStudentData()
  }
}
</script>
