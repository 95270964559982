export default {
  EvaluationCard: 'Evaluation Card',
  NewEvaluation: 'New Evaluation',
  OnAssessment: 'On Assessment',
  ScheduleMeeting: 'Schedule a meeting',
  actions: 'Actions',
  building: 'Choose Building',
  type: 'Choose Type',
  gate: 'Choose Gate',
  setup_quick_meeting: 'set up quick meeting',
  setup_detailed_meeting: 'set up detailed meeting',

  meeting_type:'meeting type',
  gates:'gates',
  buildings: 'buildings',
  persons:'persons'
}
