// Repos
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const GradingRepository = RepositoryFactory.get('GradingModule', 'common')

export default {
  state: {
    //////////////////////
    // Selected Data
    //////////////////////
    selectedClass: {},
    selectedStudent: {},
    selectedSubjectId: null,
    activeRound: {},
    gradeBookTableData: {},
    criteriaScores: {},
    selectedBoundary: {},
    subjectStrands: [],
    selectedSubject: {},
    //////////////////////
    // Check Data
    //////////////////////
    isIB: false,
    isMYPSection: false,
    isPYPSection: false,
    isDPSection: false,
    isAmerican: false,
    //////////////////////
    // Data
    //////////////////////
    subjects: [],
    criteria: [],
    resourcesTypes: [],
    studentsOfSelectedClass: [],
    //////////////////////////
    // Report Card
    //////////////////////////
    reportCardPagesLength: 0,
    isReportCardCreated: false,
    isUpdateReportCard: false,
    isStrandApproach: false,
    reportCardData: {},
    showSubjectPercentage: true,
    showSubjectWeight: true
  },
  mutations: {
    gradingSelectClass(state, payload) {
      state.selectedClass = payload
      if (state.selectedClass?.division_id) {
        state.isIB = state.selectedClass.division_id === 1
        state.isMYPSection = state.selectedClass.section_id === 2 && state.isIB
        state.isPYPSection = state.selectedClass.section_id === 1 && state.isIB
        state.isDPSection = state.selectedClass.section_id === 3 && state.isIB
        state.isAmerican = state.selectedClass.division_id === 2
      }
    },
    setCriteria(state, data) {
      if (!data || !data.length) state.criteria = []
      if (state.isMYPSection) {
        state.criteria = data.map((item) => {
          item.key = item.key.toLowerCase()
          item.name = item.title
          item.title = `${item.key.toUpperCase()} - ${item.name}`
          return item
        })
      } else if (state.isPYPSection) {
        state.criteria = data
      } else if (state.isDPSection) {
        state.criteria = Array.from(Array(7), (e, i) => i + 1)
      } else if (state.isAmerican) {
        state.criteria = Array.from(Array(20), (e, i) => i + 1)
      }
    },
    getGradingTypes(store, data) {
      store.resourcesTypes = data
    },
    setStudentsOfSelectedClass(store, data) {
      store.studentsOfSelectedClass = data
    },
    setSelectedStudent(store, data) {
      store.selectedStudent = data
    },
    setSubjects(store, data) {
      store.subjects = data
    },
    setSelectedSubjectId(store, data) {
      store.selectedSubjectId = data
    },
    setActiveRound(store, data) {
      store.activeRound = data
    },
    setGradeBookTableData(store, data) {
      store.gradeBookTableData = data
    },
    setCriteriaScores(store, data) {
      store.criteriaScores = data
    },
    setsSelectedBoundary(store, data) {
      store.selectedBoundary = data
    },
    setReportCardPagesLength(store, data) {
      store.reportCardPagesLength = data
    },
    setIsReportCardCreated(store, data) {
      store.isReportCardCreated = data
    },
    setReportCardData(store, data) {
      store.reportCardData = data
    },
    setIsStrandApproach(store, data) {
      store.isStrandApproach = data
    },
    setSubjectStrands(store, data) {
      store.subjectStrands = data
    },
    setShowSubjectPercentage(store, data) {
      store.showSubjectPercentage = data
    },
    setShowSubjectWeight(store, data) {
      store.showSubjectWeight = data
    },
    setSelectedSubject(store, data) {
      store.selectedSubject = data
    },
    setIsUpdateReportCard(store, data) {
      store.isUpdateReportCard = data
    }
  },
  actions: {
    gradingSelectClass({commit}, payload) {
      commit('gradingSelectClass', payload)
    },
    gradingSelectedStudent({commit}, payload) {
      commit('setSelectedStudent', payload)
    },
    setCriteria({commit}, payload) {
      commit('setCriteria', payload)
    },
    getGradingTypes({commit}) {
      return new Promise((resolve, reject) => {
        GradingRepository.getGradingTypes()
          .then((res) => {
            commit('getGradingTypes', res.data)
            resolve(res.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    setStudentsOfSelectedClass({commit}, data) {
      commit('setStudentsOfSelectedClass', data)
    },
    setSubjects({commit}, data) {
      commit('setSubjects', data)
    }
  },
  getters: {
    getSelectedClass(state) {
      return state.selectedClass
    },
    criteria(state) {
      return state.criteria
    },
    isIB(state) {
      return state.isIB
    },
    isMYPSection(state) {
      return state.isMYPSection
    },
    isPYPSection(state) {
      return state.isPYPSection
    },
    isDPSection(state) {
      return state.isDPSection
    },
    isAmerican(state) {
      return state.isAmerican
    },
    resourcesTypes(state) {
      return state.resourcesTypes
    },
    studentsOfSelectedClass(state) {
      return state.studentsOfSelectedClass
    },
    selectedStudent(state) {
      return state.selectedStudent
    },
    subjects(state) {
      return state.subjects
    },
    selectedSubjectId(state) {
      return state.selectedSubjectId
    },
    activeRound(state) {
      return state.activeRound
    },
    gradeBookTableData(state) {
      return state.gradeBookTableData
    },
    criteriaScores(state) {
      return state.criteriaScores
    },
    selectedBoundary(state) {
      return state.selectedBoundary
    },
    reportCardPagesLength(state) {
      return state.reportCardPagesLength
    },
    isReportCardCreated(state) {
      return state.isReportCardCreated
    },
    reportCardData(state) {
      return state.reportCardData
    },
    isStrandApproach(state) {
      return state.isStrandApproach
    },
    subjectStrands(state) {
      return state.subjectStrands
    },
    showSubjectPercentage(state) {
      return state.showSubjectPercentage
    },
    showSubjectWeight(state) {
      return state.showSubjectWeight
    },
    selectedSubject(state) {
      return state.selectedSubject
    },
    isUpdateReportCard(state) {
      return state.isUpdateReportCard
    }
  }
}
