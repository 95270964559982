export default {
  completeProfileData: 'complete Profile Data',
  completeChildrenData:'complete Children Data',
  personal_data:'Personal Data',
  student_data: 'Student Data',
  student_details: 'Student details',
  family_Data: 'Family Data',
  marital_Data: 'Marital Data',
  general_Data: 'General Data',
  address_communication: 'Address & Communication',
  communication_address: 'Communication & Address ',
  address: ' Address ',
  communication: 'Communication  ',
  Student_ID: 'Student ID',
  Located_at_the_student_birth_certificate: 'Located at the student birth certificate',
  applying_info: 'applying information',
  is_this_your_first_time_your_child_join_any_school: 'Is this your first time your child join any school',
  has_your_child_ever_skipped_a_grade: 'Has Your Child Ever Skipped A Grade',
  has_your_child_ever_been_asked_to_repeat_a_year: 'Has your child ever been asked to repeat a year',
  has_your_child_received_any_learning_support_services_in_a_pervious_school_or_centre: 'Has your child received any learning support services in a pervious school or centre',
  list_all_schools_attended_most_recent_first: 'List All Schools Attended, Most Recent First',
  spoken_languages: 'Spoken Languages',
  mother_tongue: 'Mother Tongue',
  most_commonly_spoken_at_home: 'Most Commonly Spoken At Home',
  first_foreign_language: 'First Foreign Language',
  is_your_child_arabic_exempted: 'Is Your Child Arabic Exempted',
  father_data: 'Father Data',
  father_ID: 'Father ID',
  education: 'Education',
  father_education: 'Father Education',
  mother_data: 'Mother Data',
  mother_ID: 'Mother ID',
  mother_education: 'Mother Education',
  family_data: 'Family Data',
  marital_status: 'Marital Status',
  emergency_info: 'Emergency Information',
  questions: 'Questions',
  documents: 'Documents',
  add_emergency_contacts: 'Add Emergency Contacts',
  we_are_happy_to_receive_your_application_you_are_aware_that_this_application_does_not_guarantee_a_place_at: 'We are happy to receive your Application you are aware that this application does not guarantee a place at ',
  and_you_can_track_your_application: 'and you can track your application',
  by_hitting_the_submit_button_you_are_aware_that_this_application_does_not_guarantee_a_place_at: 'By hitting the submit button you are aware that this application does not guarantee a place  at',
  accept_only: 'Accept only',
  students_second_language_form_grade_1_onwards: 'students second language form grade 1 onwards',
  medicalForm: 'medical Form',
  do_you_agree_that_the_school_supervises_your_childs_vaccinations_periodically_and_as_indicated_by_and_under_the_supervision_of_the_ministry_of_health: 'Do you agree that the school supervises your child\'s vaccinations periodically and as indicated by and under the supervision of the Ministry of Health',
  does_your_child_suffer_from_one_or_more_of_the_following: 'If your child suffer from one or more of the following diseases please select',
  Chest_Asthma: 'Chest Asthma',
  Food_Allergies: 'Food Allergies',
  Drug_Allergies: 'Drug Allergies',
  Diabetes: 'Diabetes',
  Past_History_of_Surgery: 'Past History of Surgery',
  Convulsions_due_to_high_fever: 'Convulsions due to high fever',
  Other: 'Other',
  please_specify_in_details: 'please specify in details',
  Is_your_child_taking_any_specific_medication_on_regular_basis: 'Is your child taking any specific medication on regular basis',
  is_there_any_reason_why_your_child_could_not_participate_in_any_physical_activities: 'Is there any reason why your child could not participate in any physical activities',

  is_your_child_a_special_needs_student: 'Is your child a special needs student',
  please_specify_the_type_of_disability: 'please specify the type of disability',
  did_your_child_need_a_shadow_teacher_previously: 'Did your child need a shadow teacher previously',
  reason_for_applying: 'Reason For Applying',
  select_child: 'select child',
  loading_children_information: 'loading children information',
  please_complete_your_profile_to_continue:'Please complete your profile to continue',
  emergency_contact: 'Emergency Contact',
  medical_Data: 'Medical Data'

}
