<!--
/**
//////////////////////////////////////////////////
 ** params.colDef.cellRendererParams => For Accessing actions from Headers[]

 ** Action Obj EX :

 {
    **-- UI
    title: this.$t('main.Edit'),     // Title  String || function (EX round-manager list )
    icon: 'Edit3Icon',               // Icon (Feather Icons : check UI DOC )
    color:'danger',
    msg:'texxxxxt here '      // For the action dialog message

    **-- For Pushing to another route
    navigate_action: true,
    route_name: 'scoreManager.edit', // route name,
    getParams: function ,  // EX getParams:  (data) => ({id:data.id , status: data.status})
    route_path:'user/:id/role/:status',
    parameter_name: 'id' || ['id','status'],            // pass to route.params
  *! using one of  [getParams , route_path ,parameter_name : [] ]  will override the others


   **-- For API requested actions
    resource:'' || function  ,   // default: "resource/target_status/id"  || add
    ":id"/ "status" to relocate them || (data) => `resource/target_status/${data.id}`
    action:'edit',                    // action request name '/score-manager/:id/edit ' or /score-manager/edit/id'
    requestType:'post',              // delete || post || put
    requestPayload:{status:'Active'},    // For post || put request's payload
    action_param_name:'',      // for action api param
    getRequestPayload:function  // EX getRequestPayload:  (data) => ({id:data.id , status: data.status})

   **-- For API requested Change status
    statusTarget:'' || {key:'',value:''}  // the related status that will change by
    this action

   **-- For Show Conditions
    showIf: Function         // EX showIf:  (data) => new Date() < new Date(data.to)


    **-- For dropdown actions
    // todo: navigate_action :false  && icon: 'MoreVerticalIcon'
      dropDown: true,
      dropDown_items:[{ ... same as Action OBJ }]

  **-- For CallbackAction
      callbackAction: Function         // EX CallbackAction:  () => {this.$emit('test callback')}

  **-- For Emitting Actions by $root.emit , $root.$on , $root.$off
      actionEmitter : emit_name ( string),
      actionEmitterParams: ['id','name']  // params_names that will be return { id: 1 , name :'Student name'}
  },

//////////////////////////////////////////////////
*/
-->

<template>
  <div :style="{direction: $vs.rtl ? 'rtl' : 'ltr'}">
    <template v-if="actions.length">
      <template v-for="(action, j) in actions">
        <vx-tooltip
          :text="
            (typeof action.title === 'function'
              ? action.title(rowData)
              : action.title) | text_formatter
          "
          :key="`id_${j}`"
          v-if="
            !action.dropDown && (action.showIf ? action.showIf(rowData) : true)
          ">
          <feather-icon
            :icon="getActionIcon(action) || action.icon"
            :title="
              (typeof action.title === 'function'
                ? action.title(rowData)
                : action.title) | text_formatter
            "
            svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
            @click="
              action.no_action
                ? ''
                : action.callbackAction
                ? action.callbackAction()
                : makeAction(action)
            " />
        </vx-tooltip>
      </template>
      <template v-for="(action, i) in actions">
        <vx-tooltip
          :text="
            (typeof action.title === 'function'
              ? action.title(rowData)
              : action.title || $t('basic.actions')) | text_formatter
          "
          :key="i"
          v-if="
            action.dropDown && (action.showIf ? action.showIf(rowData) : true)
          ">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <feather-icon
              :icon="'MoreVerticalIcon'"
              svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"></feather-icon>

            <vs-dropdown-menu>
              <template v-if="action.dropDown_items">
                <template v-for="(v, i) in dropdown_actions">
                  <vs-dropdown-item
                    :key="i"
                    @click="makeAction(v)"
                    v-if="v.showIf ? v.showIf(rowData) : true">
                    <span class="flex items-center">
                      <feather-icon
                        :icon="getActionIcon(v) || v.icon"
                        v-if="v.icon"
                        svgClasses="h-4 w-4"
                        class="mr-2" />
                      <span>{{
                        v.name ||
                        (typeof v.title === 'function'
                          ? v.title(rowData)
                          : v.title) ||
                        v.action | text_formatter
                      }}</span>
                    </span>
                  </vs-dropdown-item>
                </template>
              </template>
            </vs-dropdown-menu>
          </vs-dropdown>
        </vx-tooltip>
      </template>
    </template>
    <template v-else>
      <vx-tooltip :text="$t('basic.no_available_actions')">
        <feather-icon
          icon="MoreHorizontalIcon"
          svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"></feather-icon>
      </vx-tooltip>
    </template>
  </div>
</template>

<script>
import VxTooltip from '@/layouts/components/vx-tooltip/VxTooltip.vue'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const DataTableRepository = RepositoryFactory.get('General', 'dataTable')

export default {
  name: 'CellRendererActions',
  components: {
    VxTooltip
  },
  data () {
    return {
      actionsUI: [
        {
          name: [
            'disable',
            'Disable',
            'close',
            'closed',
            'Close',
            'Closed',
            'deactivate',
            'deactivate',
            'deactivated',
            'Deactivate',
            'Deactivated',
            'delete',
            'Delete'
          ],
          color: 'danger',
          icon: 'Trash2Icon'
        },
        {
          name: ['archived', 'archive', 'Archived', 'Archive'],
          color: 'danger',

          icon: 'ArchiveIcon'
        },
        {
          name: [
            'suspend',
            'Suspend',
            'suspended',
            'Suspended',
            'banned',
            'Banned'
          ],
          color: 'warning',

          icon: 'PowerIcon'
        },
        {
          name: [
            'active',
            'Active',
            'activate',
            'Activate',
            'enable',
            'Enable'
          ],
          color: 'success',
          icon: 'CheckCircleIcon' // CheckCircleIcon || CheckSquareIcon || CheckIcon
        }
      ],
      actions: [],
      dropdown_actions: [],
      actions_prop: []
    }
  },
  computed: {
    rowData () {
      return this.params.data
    },
    status () {
      const data = this.rowData.status || this.rowData.active
      let data_shapes = []
      if (isNaN(data)) {
        data_shapes = [
          // data.slice(0, -1),
          // data.slice(0, -1).toLowerCase(),
          // data.slice(0, -1).charAt(0).toUpperCase() + data.slice(1, -1),
          data,
          data.toLowerCase(),
          data.charAt(0).toUpperCase() + data.slice(1)
        ]
      } else return data
      return data_shapes
    }
  },
  methods: {
    getActions (act) {
      const status = this.rowData.status || null
      if (!status) return act
      if (status.toLowerCase() === 'activated') {
        return act.filter(
          (action) => action.action.toLowerCase() !== 'activate'
        )
      } else {
        return act.filter(
          (action) => action.action.toLowerCase() !== 'deactivate'
        )
      }
    },
    filterActions (act) {
      const data_status = this.rowData.status || this.rowData.active || null
      if (act.length) {
        if (!data_status) return act
        else if (isNaN(data_status)) {
          return act.filter((el) => {
            if (el.statusTarget) {
              const target = el.statusTarget
              if (typeof target === 'string') return !this.status.some((s) => target === s)
              else return this.rowData[target.key] !== target.value
            } else return !this.status.some(
              (s) => (el.title || el.name || el.action) === s
            )
          })
        } else return act
      }
    },
    getAllActions () {
      this.actions_prop = this.params.colDef.cellRendererParams.actions_prop
      this.actions = this.filterActions(this.actions_prop)
      const dropdown_items = this.actions_prop.find((el) => el.dropDown_items)
        ? this.actions_prop.find((el) => el.dropDown_items).dropDown_items
        : []
      this.dropdown_actions = this.filterActions(dropdown_items)
    },

    ////////////////////////////////////////////////////////////////
    // Start Action if Navigate or (Change Status || API Request )
    ////////////////////////////////////////////////////////////////
    makeAction (action) {

      if (action.navigate_action) {
        // Get Params
        let params = {},
          query = {},
          path = action.route_path
        const actionParams = action.parameter_name || null,
          actionQuery = action.query_name || null

        if (action.getParams) params = action.getParams(this.rowData)
        else if (action.route_path) {
          const pathParams = action.route_path
            .split('/')
            .filter((e) => e.includes(':'))
            .map((e) => e.replace(':', ''))

          pathParams.forEach((e) => {
            params[e] = this.rowData[e]

            path = path.replace(`:${e}`, this.rowData[e])
          })
        } else if (Array.isArray(actionParams)) {
          actionParams.forEach((e) => {
            params[e] = this.rowData[e]
          })
        } else params[actionParams || 'id'] = this.rowData[actionParams || 'id']

        if (action.query_name) query[action.query_name] = this.rowData[action.query_name]
        // console.log('path', path)
        // Route
        this.$router.push(
          path || {
            name: action.route_name,
            params,
            query
          }
        )
      }
      // If Dialog Action
      else if (action.callbackAction) action.callbackAction()
      // If emitting action
      else if (action.actionEmitter) {
        const emittingData = {}
        if (action.actionEmitterParams) {
          const data = this.rowData,
            emittingParams = action.actionEmitterParams

          emittingParams.forEach((e) => {
            if (data[e]) emittingData[e] = data[e]
          })
        }
        // console.log(emittingData)

        this.$root.$emit(action.actionEmitter, emittingData)
      } else this.confirmMakeAction(action)
      // this.$router.push(`${routName}/${this.params.list_prop.id}`).catch(() => {})
    },
    /////////////////////////////////////////////////
    // Confirm Dialog for not Navidated action
    /////////////////////////////////////////////////
    confirmMakeAction (action) {
      const action_params_name = !Array.isArray(action.parameter_name)
          ? action.parameter_name
          : 'id',
        actionParam =
          this.rowData[action.action_param_name || action_params_name || 'id'],
        action_name =
          (typeof action.title === 'function'
            ? action.title(this.rowData)
            : action.title) ||
          action.name ||
          action.action,
        actionStatusName =
          action.action ||
          action.requestPayload?.status ||
          (action.getRequestPayload
            ? action.getRequestPayload(this.rowData).status
            : ''),
        color = this.actionsUI.find((e) => e.name.some((e) => e === actionStatusName)
        )?.color
      this.$vs.dialog({
        type: 'confirm',
        color: color || action.color || 'primary',
        title: `${this.$t('basic.confirm')} .!!` ,// ${action_name}
        text:
          action.msg || ` ${this.$t('basic.you_are_about_to')} ${action_name}`,
        accept: () => this.makeActionRequest(action, actionParam),
        acceptText: `${action_name}`
      })
    },

    showActionSuccess (action) {
      this.successMsg(
        `${this.$t('basic.the_selected_row_was_successfully')}`,
        `${this.$t('basic.row_uptaded')}`
      )
    },
    /////////////////////////////////////////////////
    // Change Status ||  API Request
    /////////////////////////////////////////////////
    makeActionRequest (action, id) {
      // URL
      let url = ''
      const payload = action.getRequestPayload
        ? action.getRequestPayload(this.rowData)
        : action.requestPayload || null

      if (typeof action.resource === 'function') url = action.resource(this.rowData)
      else {
        let resource = action.resource
        const resourceHaveStatusParam = resource.includes(':status') || null,
          resourceHaveIdParam = resource.includes(':id') || null,
          requestHavePayload = Boolean(action.requestPayload),
          url_action_part = !requestHavePayload
            ? !resourceHaveStatusParam && action.action
              ? `${action.action}`
              : ''
            : ''

        // Replace Params
        resource = action.resource
          .replace(':id', id)
          .replace(':status', action.action)
        // Set URL
        url = `${resource}/${url_action_part}${
          !resourceHaveIdParam ? `/${id}` : ''
        }`
        if (url.slice(-1) === '/') url = url.slice(0, -1)

      }

      // RequestType
      let repo_method_name = 'makeActionRequest'
      switch (action.requestType) {
      case 'post':
        repo_method_name = 'makeActionPOSTRequest'
        break
      case 'put':
        repo_method_name = 'makeActionPUTRequest'
        break
      case 'delete':
        repo_method_name = 'makeActionDELETERequest'
        break
      default:
        repo_method_name = 'makeActionRequest'
        break
      }
      this.VSLoading()
      // make Request
      DataTableRepository[repo_method_name](url, payload)
        .then((res) => {
          this.stopVSLoading()
          if (!(res.status || res.data?.status).includes('success')) {
            this.errMsg(res.data.status)
            return
          }
          this.showActionSuccess(action)
          this.$root.$emit('row:status')
        })
        .catch((err) => {
          this.stopVSLoading()
          this.errMsg(err.data?.status || err.status)
        })
      // this.$store.dispatch("userManagement/removeRecord", this.rowData.id)
      //   .then(()   => { this.showDeleteSuccess() })
      //   .catch(err => { console.error(err)       })
    },
    /////////////////////////////////////////////////
    // UI
    /////////////////////////////////////////////////
    getActionUI (actionItem) {
      const actionName =
        actionItem.action ||
        actionItem.requestPayload?.status ||
        (actionItem.getRequestPayload
          ? actionItem.getRequestPayload(this.rowData).status
          : '')
      return actionName
        ? this.actionsUI.find((e) => e.name.some((e) => e === actionName))
        : false
    },
    getActionIcon (action) {
      if (action.route_name?.includes('edit')) return 'Edit3Icon'
      else if (action.route_name?.includes('show')) return 'EyeIcon'
      else return this.getActionUI(action)?.icon || false
    }
  },
  mounted () {},
  created () {
    this.getAllActions()
  }
}
</script>

<style lang="scss" scoped>
.con-vs-tooltip {
  display: inline-block;
}
</style>
