import vSelect from 'vue-select'

export default {
  name: 'SideMenusContainer',
  props: ['menus', 'info', 'callBack', 'siblings', 'student_photo'],
  components:{
    'v-select': vSelect
  },
  data () {
    return {
      menusData:null,
      information:null,
      selectedStudent: 0
    }
  },
  watch: {
    siblings (newval) {
      this.selectedStudent = newval.length > 0 ? newval[0].id : 0
    }
  },
  created () {
    this.menusData = this.menus
    this.information = this.info
    this.applyAction = this.callBack
  },
  methods:{
    applyAction (data) {
    }
  }
}
