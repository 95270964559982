import axios from 'axios'

const resource = '/teachers'


export default {

  getLabels () {
    return axios.get(`${resource}/assignment/lables`)
  },
  create (payload) {
    return axios.post(`${resource}/assignments`, payload)
  },
  edit (id, payload) {
    return axios.put(`${resource}/assignments/${id}`, payload)
  },
  getAll () {
    return axios.get(`${resource}/assignments/`)
  },
  show (id) {
    return axios.get(`${resource}/find-assignment-Teacher?id=${id}`)
  },
  changeStatus (id, status) {
    return axios.get(`${resource}/assignment/status/${id}/${status}`)
  },

  getByFilter (filters) {
    return axios.post(`${resource}/filter/assignments`, filters)
  }
}
