<template>
  <ListPageWithCreateButton :showCreateBtn="false">
    <template slot="listDataContent">
      <transition-group class="list-transition" name="list-enter-up" tag="ul">
        <template v-if="filtrated_data.length > 0">
          <li
            class="mb-10"
            v-for="(val, i) in filtrated_data"
            :key="val.id"
            :style="[{transitionDelay: i * 0.1 + 's'}]">
            <class-card
              :onlineClass_prop="val"
              @reloadData="reloadData"
              userType="parent"></class-card>
          </li>
        </template>
      </transition-group>
      <template v-if="filtrated_data.length < 1">
        <p class="text-center">{{ $t('basic.no_data_available') }}</p>
      </template>
    </template>
    <template slot="rightSide">
      <class-filter
        @refilter="refilter"
        @resetAll="getAllData"
        ref="classFilter"
        :filters_prop="filters"
        :rounds="student.round ? [student.round] : null"
        :SyncWithReduceButton="false" />
    </template>
  </ListPageWithCreateButton>
</template>
<script>
import ClassFilter from '@/components/general/RoundFilter'
import ClassCard from '@/modules/onlineClass/components/ClassCard'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const StudentRepository = RepositoryFactory.get('StudentsModule', 'student')
export default {
  props: {
    student: {
      type: Object,
      required: true
    }
  },
  components: {
    ClassFilter,
    ClassCard
  },
  created() {
    // this.getAllData()
  },
  data() {
    return {
      filter_choosen: [],
      onlineClasses: [],
      status_filterd: 'all',
      filtrated_data: [],
      status_ready: false,
      archivedClasses: [],
      showArchived_status: false
    }
  },

  computed: {
    filters() {
      const custom_filter = [
        {
          id: 1,
          name: this.$t('basic.dates'),
          singleSeletable: true,

          by: [
            {
              name: this.$t('basic.today'),
              id: 'today',
              checked: false
            },
            {
              name: this.$t('basic.this_week'),
              id: 'this week',
              checked: false
            },
            {
              name: this.$t('basic.this_month'),
              id: 'this month',
              checked: false
            }
          ]
        },
        {
          id: 2,
          name: this.$t('basic.subjects'),
          by: []
        },
        {
          id: 3,
          name: this.$t('basic.status'),
          by: [
            {id: 'active', name: this.$t('status.active'), checked: false},

            {
              id: 'archived',
              name: this.$t('status.archived'),
              checked: false
            }
          ]
        }
      ]

      // Fill all the classes ( ClassId)
      // eslint-disable-next-line vue/no-async-in-computed-properties
      StudentRepository.getClassSubjects(
        this.class_id,
        this.student.year_group
      ).then((res) => {
        const all_subjects = res.data
        all_subjects.map((subject) => {
          custom_filter[1]['by'].push({
            id: subject.id,
            name: `${subject.name}`,
            checked: false
          })
        })
      })

      return custom_filter
    },
    class_id() {
      return this.student?.student?.classGroup_id
    },
    userId() {
      return this.student.user_id || this.$route.params.child_id
    }
  },
  watch: {
    student(val) {
      this.refilter(this.filter_choosen)
    }
  },
  methods: {
    /////////////////////////////////////////
    // APIs
    //////////////////////////////////////////
    // Get Online Classes
    async getData(repoFunction, params = null) {
      await StudentRepository[repoFunction](params).then((res) => {
        this.onlineClasses = res.data ? res.data : res
        this.filtrated_data = this.onlineClasses.filter(
          (el) => el.status !== 'canceled'
        )
      })
    }, // Get Filter

    // Get All data
    getAllData() {
      this.getData('getOnlineClasses', this.userId)
    },
    // Get refilter Data
    refilter(checked) {
      const nothingChecked =
        checked.filter((el) => el.checked.length > 0).length === 0
      if (nothingChecked) {
        this.getAllData()
      } else {
        const temp = {
          status: [],
          subjects: [],
          dates: ''
        }
        checked.map((el) => {
          temp[el.name] = el.checked
        })
        temp.class_id = this.class_id
        this.filter_choosen = checked
        console.log(temp)
        this.getData('filterOnlineClasses', temp)
      }
    },

    // changeFilter
    async changeFilter(val) {
      this.status_filterd = val
      if (val && val !== 'all') {
        await (this.filtrated_data = [])
      } else {
        this.$refs.classFilter.resetAll()
        await (this.filtrated_data = [])
        this.getAllData()
      }
    },
    // Reload After anychange
    reloadData() {
      this.filtrated_data = []
      this.status_ready = false

      this.refilter(this.filter_choosen)
    }
  }
}
</script>
