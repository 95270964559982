<template>
  <div>
    <h1>Dashboard Page</h1>
  </div>
</template>
<script>
export default {
  name:'assignments'
}
</script>
