import showInventoryList from './pages/list'
import addCertificate from './pages/addCertificate'
import addCertificateWithTerm from './pages/addCertificateWithTerm'

export default [
  {
    path: '',
    name: 'inventory',
    component: showInventoryList,
    props: true,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        {
          title: 'basic.report_card',
          active: true
        }
      ],
      pageTitle: 'basic.all_report_card',
      rule: 'TeacherSNDSMD'
    }
  },
  {
    path: 'add-final-certificate',
    name: 'addCertificate',
    component: addCertificate,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        {
          title: 'basic.report_card',
          url: '/grading/inventory'
        },
        {
          title: 'inventory.finalReportCard',
          active: true
        }
      ],
      pageTitle: 'inventory.finalReportCard',
      rule: 'TeacherSNDSMD'
    }
  },
  {
    path: 'add-certificate',
    name: 'addCertificateWithTerm',
    component: addCertificateWithTerm,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        {
          title: 'basic.report_card',
          url: '/grading/inventory'
        },
        {
          title: 'inventory.uploadReportCard',
          active: true
        }
      ],
      pageTitle: 'inventory.uploadReportCard',
      rule: 'TeacherSNDSMD'
    }
  }
]
