var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"justify-between flex align-center w-full flex-wrap",class:{ 'lg:flex-no-wrap': _vm.status.length < 8 }},_vm._l((_vm.status),function(val,i){return _c('div',{key:val.id || i,staticClass:"mb-4 flex-1 counter_block"},[_c('button',{staticClass:"w-full no_style block pl-1 pr-1",class:{ active: (val.id || val.value) === _vm.selected },attrs:{"title":val.title ? val.title : val.name},on:{"click":function($event){return _vm.getFilter(val)}}},[_c('vx-card',{staticClass:"overflow-hidden small-card"},[_c('div',{attrs:{"slot":"no-body"},slot:"no-body"},[_c('div',{staticClass:"p-4",class:'flex justify-between flex-row-reverse items-center'},[_c('feather-icon',{staticClass:"p-2 inline-flex rounded-full",class:`status--${
                val.color
                  ? val.color
                  : val.value
                  ? val.value
                  : val.name.includes(' ')
                  ? val.name.toLowerCase().replaceAll(' ', '_')
                  : val.name.toLowerCase()
              }`,attrs:{"icon":val.icon}}),_c('div',{staticClass:"truncate"},[_c('span',[_vm._v(_vm._s(val.title ? val.title : val.name))])])],1)])])],1)])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }