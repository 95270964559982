<template>
  <div>
    <vs-card class="p-3 py-6 md:p-6">

      <form
        class="flex justify-between flex-col"
        @submit.prevent="submitForm"
        @keyup.enter="submitForm"
      >
        <div class="mb-4 mt-auto w-full vx-row items-center mx-auto">

          <!--Category Name -->
          <div class="my-2 vx-col  w-full">
            <label>{{ $t("canteen.categoryName") }}<sup class="text-danger">*</sup></label>
            <vs-input
              type="text"
              v-model="category.name"
              class="w-full mb-2"
              name="category_name"
              v-validate="'required'"
            />
            <span class="text-danger text-sm">{{
                errors.first("category_name")
              }}</span>
          </div>
          <!-- End Category Name -->

        </div>
        <!-- Actions -->
        <div class="flex flex-wrap justify-between items-center mt-auto mb-0">
          <vs-button class="mt-5 md:w-auto md:mr-0 ml-auto" @click="submitForm">
            {{ $t("form.submit") }}
          </vs-button>
        </div>
      </form>
    </vs-card>
  </div>
</template>
<script>
// Components
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import vSelect from 'vue-select'
import fileUploader from '@/modules/unit-planner/components/uploadButton/uploadButton.vue'

// import getData from './form/common_data_mixin'

import { commonDataMixin, checkValidationMixin } from '@/mixins'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const canteenRepository = RepositoryFactory.get(
  'canteenRepository',
  'canteenRepository'
)
export default {
  name: 'canteen-user-info',
  props: {
    studentInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    ifEdit: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  mixins: [commonDataMixin, checkValidationMixin],
  // mixins:[getData()],
  components: { flatPickr, vSelect, fileUploader },

  data () {
    return {
      category: {
        name: ''
      }
    }
  },
  watch: {
  },
  computed: {

  },
  methods: {
    fillData () {
      if (this.ifEdit) {
        canteenRepository.showCategory(this.$route.query.canteen_id, this.$route.params.id).then(res => {
          this.category.name = res.data.name
        })
      }
    },
    submitForm () {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.$emit('formSubmited', this.category, this.$route.query.canteen_id, this.$route.params.id)
        } else {
          this.errMsg()
        }
      })
    }
  },
  created () {
    this.fillData()
  },
  mounted () {}
}
</script>
<style lang="scss">
.remove-student-attachment {
  top: 32px;
  left: 90px;
  z-index: 20;
  cursor: pointer;
  svg {
    stroke: red;
    stroke-width: 2px;
  }
}
</style>
