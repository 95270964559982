import axios from 'axios'

const resource = '/class'
export default {
  getNotAssignStudents (gradeId, divID) {
    return axios.get(`/student/studentNotAssign/${gradeId}/${divID}`)
  },
  create (payload) {
    return axios.post(`${resource}`, payload)
  },
  studentInClass (classID, gradeId, divID) {
    return axios.get(`/student/studentInClass/${classID}/${gradeId}/${divID}`)
  },
  getClasses (divID, gradeId) {
    return axios.get(`availableclasses/${divID}/${gradeId}`)
  },
  AllStudentsNotInClass (classID, gradeId, divID) {
    return axios.get(`/student/AllStudentsNotInClass/${classID}/${gradeId}/${divID}`)
  },
  createRecurrent (payload) {
    return axios.post('/createRecurrentClass', payload)
  },
  changeStatus (id, status) {
    return axios.get(`${id}/classstatus/${status}`)
  },
  showClass (id) {
    return axios.get(`${resource}/${id}`)
  },
  updateNewStudents (id, payload) {
    return axios.put(`updateNewStudents/${id}`, payload)
  },
  removeStudentFromClass (id) {
    return axios.get(`removeStudentFromClass/${id}`)
  },
  //////////////////////////////////
  //  Filters
  /////////////////////////////////
  classesFilter (payload) {
    return axios.post('/classes/filter', payload)
  },
  getSchools () {
    return axios.get('application/student/schools')
  },
  getSchoolDivsions (payload) {
    return axios.post('application/student/divisions', payload)
  },
  getDivisions () {
    return axios.get('application/student/divisions')

  },
  getGrades (payload) {
    return axios.post('application/student/grades', payload)
  },
  getActiveClasses () {
    return axios.get('filter/active/classes')
  },
  //////////////////////////////
  // Upgrade Class actions
  /////////////////////////////
  getOldClasses (grade_id) {
    return axios.post('last/grade', {
      grade_id
    })
  },
  getUpgradeStatus () {
    return axios.get('/upgrade/status')
  },
  upgradeClass (upgradedClassId, payload) { // selectedClassId for upgrading
    return axios.post(`/upgrade/students/${upgradedClassId}`, payload)
  },
  assignStudentsToTheClass (classId, newStudents) {
    return axios.post(`/store/student/class/${classId}`, {
      students :newStudents
    })
  }

  /*
  getDivsGrades (payload) {
    return axios.post(`/division/getDevsGrades`,payload)
  },

  getSubjectData (id) {
    return axios.get(`${resource}/${id}`)
  },
  update (id,payload) {
    return axios.put(`${resource}/${id}`,payload)
  },
  changeStatus(id,status){
    return axios.get(id+"/status/"+status)
  }*/
}
