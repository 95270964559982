export default [
  //////////////////////
  // Grade Book
  /////////////////////
  {
    path: 'gradebook',
    name: 'gradebook',
    component: () => import('./pages/gradebook/list'),

    meta: {
      breadcrumb: [{title: 'grading.gradebook', url: '', active: true}],
      rule: 'SNDSMD',

      pageTitle: 'grading.gradebook',
      hideVerticalMenu:false
    }
  }
]
