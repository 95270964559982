<template>
  <div :style="{ direction: $vs.rtl ? 'rtl' : 'ltr' }">
    <vx-tooltip
      :text="action.title"
      v-for="action in getActions(
        params.colDef.cellRendererParams.actions_prop
      )"
      :key="action.title"
      v-if="!action.dropDown && (action.showIf ? action.showIf(rowData) : true)"
    >
      <feather-icon
        v-if="!action.dropDown"
        :icon="action.icon"
        :title="action.title"
        svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
        @click="makeAction(action)"
      />
    </vx-tooltip>
    <vx-tooltip
      :text="action.title"
      v-for="(action, i) in params.colDef.cellRendererParams.actions_prop"
      :key="i"
    >
      <vs-dropdown
        vs-trigger-click
        class="cursor-pointer"
        v-if="action.dropDown"
        :icon="action.icon"
        svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
      >
        <feather-icon
          :icon="action.icon"
          svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
        ></feather-icon>

        <vs-dropdown-menu></vs-dropdown-menu>
      </vs-dropdown>
    </vx-tooltip>

    <!-- ///////////////////////////////////////
        // Dialogs
     ///////////////////////////////////////////// -->
    <!-- Student Add Note -->

    <vs-prompt
      @cancel="studentNote = ''"
      @accept="acceptStudentAddNote"
      :is-valid="studentAddNotevalidateForm"
      :active.sync="studentAddNote"
      title="Add Note"
      type="confirm"
    >
      <div class="con-exemple-prompt">
        <vs-textarea
          v-model="studentNote"
          class="mt-4 mb-2 w-full"
          v-validate="'required|min:5'"
          name="note"
        />

        <span
          v-if="!studentAddNotevalidateForm"
          class="text-sm w-full text-center"
          >{{
            $t("StudentAffairs.the_note_field_must_be_at_least_5_characters")
          }}
        </span>
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import VxTooltip from '@/layouts/components/vx-tooltip/VxTooltip.vue'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'

const DataTableRepository = RepositoryFactory.get('General', 'dataTable')
const AllStudentsRepository = RepositoryFactory.get('studentAffairsRepository', 'allStudents')
export default {
  name: 'CellRendererActions',
  components: {
    VxTooltip
  },
  data () {
    return {
      studentAddNote: false,
      studentNote: ''
    }
  },
  computed: {
    studentAddNotevalidateForm () {
      return this.studentNote.length >= 5
    }
  },
  methods: {
    makeAction (action) {
      if (action.navigate_action) {
        const params = {}
        params[`${action.params_name}`] = this.params.data[
          action.parameter_name
        ]
        this.$router.push({
          name: action.route_name,
          params: {...params}
        })
      } else if (action.dialog_action) {
        this[action.dialog_name] = true
      } else if (action.copy_action) {

        const value = this.params.data[action.parameter_name]
        navigator.clipboard.writeText(value)
        this.successMsg('Copied sccuess')

      } else this.confirmMakeAction(action)
      // this.$router.push(`${routName}/${this.params.list_prop.id}`).catch(() => {})

      /*
              Below line will be for actual product
              Currently it's commented due to demo purpose - Above url is for demo purpose

              this.$router.push("/apps/user/user-edit/" + this.params.data.id).catch(() => {})
            */
    },
    confirmMakeAction (action) {
      const id = this.params.data[action.parameter_name]
      this.$vs.dialog({
        type: 'confirm',
        color: action.action === 'deactivate' ? 'danger' : 'success',
        title: `${this.$t('basic.confirm')}  ${action.action}`,
        text: ` ${this.$t('basic.you_are_about_to')} ${action.action}`,
        accept: this.makeActionRequest(action, id),
        acceptText: `${action.action}`
      })
    },
    makeActionRequest (action, id) {
      const url = `${action.resource}/${action.action}/${id}`
      /* Below two lines are just for demo purpose */

      /* UnComment below lines for enabling true flow if deleting user */
      DataTableRepository.makeActionRequest(url)
        .then(() => {
          this.showActionSuccess(action)
          this.$root.$emit('row:status')
        })
        .catch()
      // this.$store.dispatch("userManagement/removeRecord", this.params.data.id)
      //   .then(()   => { this.showDeleteSuccess() })
      //   .catch(err => { console.error(err)       })
    },
    // changeAppStatus (action, status) {
    //   const id = this.params.data[action.parameter_name]
    //   ApplicationRepository.changeStatus(id, status)
    //     .then(() => {
    //       this.showActionSuccess()
    //       this.$root.$emit('row:status')
    //     })
    //     .catch()
    // },
    // view (action) {
    //   const id = this.params.data[action.parameter_name]
    //   ApplicationRepository.view(id, status)
    //     .then(() => {
    //       this.showActionSuccess()
    //     })
    //     .catch()
    // },
    showActionSuccess (action) {
      this.$vs.notify({
        color: action.action === 'deactivate' ? 'danger' : 'success',
        title: 'Row uptaded',
        text: 'The selected row was successfully uptaded'
      })
    },
    getActions (act) {
      const status = this.params.data.status || null
      if (!status) return act
      if (status.toLowerCase() === 'activated') {
        return act.filter(action => action.title.toLowerCase() !== 'activate')
      } else {
        return act.filter(action => action.title.toLowerCase() !== 'deactivate')
      }
    },

    // studentAddNote API
    acceptStudentAddNote () {
      if (!this.studentAddNotevalidateForm) {
        this.$vs.notify({
          color: 'danger',
          text: 'Add text before save'
        })
      } else {
        const note = {
          student_id: this.params.data.id,
          note: this.studentNote
        }
        AllStudentsRepository.addStudentNote(note).then(res => {
          if (res) {
            this.$vs.notify({
              text: 'Note is added',
              title: 'success',
              iconPack: 'feather',
              icon: 'icon-check-circle',
              color: 'success'
            })
            this.studentNote = ''
          }
        }, error => {
          this.$vs.notify({
            title: 'Error',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
      }
    }

  }
}
</script>

<style lang="scss" scoped>
.con-vs-tooltip {
  display: inline-block;
}
</style>
