<template>
  <ListPageWithCreateButton
    :showCreateBtn="checkUserRole('SND')"
    createRoutName="create-canteen"
    :createBtnName="$t('canteen.createCanteen')">
    <template slot="listDataContent">
      <canteen-card
        :details="canteen"
        :options="optionsList"
        @archive="openPropmpt" />
      <vs-pagination
        :total="paginationDetails.totalPages"
        :max="paginationDetails.countPerPage"
        v-model="paginationDetails.currentPage"
        v-if="paginationDetails.totalPages > 1" />
      <vs-prompt
        @cancel=";(canteenID = '') && (ifCanteenArchive = false)"
        @accept="archiveCanteen"
        :active.sync="ifCanteenArchive"
        title="Archive"
        type="confirm"
        color="danger"
        accept-text="Archive">
        <div class="con-exemple-prompt">
          <p>{{ $t('alerts.archive') }}</p>
        </div>
      </vs-prompt>
    </template>
  </ListPageWithCreateButton>
</template>

<script>
import canteenCard from '@/modules/canteen/pages/canteen/components/canteenCard'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const canteenRepository = RepositoryFactory.get(
  'canteenRepository',
  'canteenRepository'
)
export default {
  components: {
    canteenCard
  },
  data() {
    return {
      canteen: [],
      canteenId: '',
      ifCanteenArchive: false,
      optionsList: [
        {
          name: 'Edit',
          icon: 'Edit3Icon',
          routerName: 'edit-canteen',
          parameter: 'id'
        },
        {
          name: 'Users',
          icon: 'UsersIcon',
          routerName: 'canteen-users',
          parameter: 'id'
        },
        {
          name: 'Categories',
          icon: 'LayersIcon',
          routerName: 'canteen-categories',
          parameter: 'id'
        },
        {
          name: 'Items',
          icon: 'LayersIcon',
          routerName: 'canteen-items',
          parameter: 'id'
        },
        {
          name: 'Transactions',
          icon: 'RepeatIcon',
          routerName: 'canteen-transaction'
        },
        {
          name: 'Archived',
          icon: 'ArchiveIcon',
          parameter: 'id',
          archive: true
        }
      ],
      paginationDetails: {
        totalPages: '',
        countPerPage: '',
        currentPage: ''
      }
    }
  },
  watch: {
    'paginationDetails.currentPage'(val) {
      this.getCanteenList(val)
    }
  },
  methods: {
    getCanteenList(page = 1) {
      this.VSLoading()
      canteenRepository
        .getCanteenList(page)
        .then((res) => {
          this.canteen = res.data.data
          this.paginationDetails.totalPages = res.data.last_page
          this.paginationDetails.countPerPage = res.data.per_page
          this.paginationDetails.currentPage = res.data.current_page
        })
        .finally(() => {
          this.stopVSLoading()
        })
    },
    openPropmpt(canteenId) {
      this.canteenId = canteenId
      this.ifCanteenArchive = true
    },
    archiveCanteen() {
      canteenRepository
        .archiveCanteen(this.canteenId, {status: 'archive'})
        .then(() => {
          this.successMsg(this.$t('canteen.editCanteenSuccessfully'))
          this.getCanteenList(this.paginationDetails.currentPage)
        })
    }
  },
  created() {
    this.getCanteenList()
  }
}
</script>
<style scoped></style>
