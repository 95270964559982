import RoundsList from './pages/round-manager/List.vue'
import CreateRound from './pages/round-manager/Create'
import Edit from './pages/round-manager/Edit'
import CreateEvaluationCard from './pages/evaluation-card/Create'
import EditEvaluationCard from './pages/evaluation-card/Edit'
import EvaluationCardList from './pages/evaluation-card/List'
import ApplicationsList from './pages/application-wrok-flow/List'
import SchedulerMeetingList from './pages/application-wrok-flow/SchedulerMeetingList'
import Subscribers from './pages/application-wrok-flow/Subscribers'
import registrationsList from './pages/registeration/List'
import AllStudentsList from '../student-affairs/pages/List'
import ConsoleHome from '../attendance/Pages/attendance-console/Home'
import TakeAttendance from '../attendance/Pages/attendance-console/TakeAttendance'
import InsightsHome from '../attendance/Pages/insights/Home'
import SettingsHome from '../attendance/Pages/settings/Create'

export default [
  ///////////////////////     Round Routes ////////////////////////
  {
    path: 'round-manager',
    name: 'RoundManager',
    component: RoundsList,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        // { title: 'admission.Admission', url: '/admission' },
        {
          title: 'admission.AdmissionManager',
          url: '/admission/round-manager',
          active: true
        }
      ],
      pageTitle: 'admission.AdmissionManager',
      rule: 'TeacherSNDSMD'
    }
  },
  {
    path: 'round-manager/edit/:id',
    name: 'update-round-manager',
    component: Edit,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        { title: 'admission.AdmissionManager', url: '/admission/round-manager' },
        { title: 'main.Edit', url: '', active: true }
      ],
      pageTitle: 'admission.editRound',
      rule: 'TeacherSNDSMD'
    }
  },

  {
    path: 'round-manager/create',
    name: 'create-round-manager',
    component: CreateRound,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        //  { title: 'admission.Admission', url: '/admission' },
        { title: 'admission.AdmissionManager', url: '/admission/round-manager' },
        {
          title: 'main.create',
          url: '/admission/round-manager/create',
          active: true
        }
      ],
      pageTitle: 'admission.NewRound',
      rule: 'TeacherSNDSMD'
    }
  },

  ///////////////////////  Evaluation Card Routes    /////////////////////

  {
    path: 'evaluation-cards',
    name: 'EvaluationCardList',
    component: EvaluationCardList,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        //{ title: 'admission.Admission', url: '/admission' },
        {
          title: 'evaluation.EvaluationCard',
          url: '/admission/evaluation-card',
          active: true
        }
      ],
      pageTitle: 'evaluation.EvaluationCard',
      rule: 'TeacherSNDSMD'
    }
  },

  {
    path: 'evaluation-card/create/:id',
    name: 'create-evaluation',
    component: CreateEvaluationCard,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        // { title: 'evaluation.EvaluationCard', url: '/admission' },
        {
          title: 'evaluation.EvaluationCard',
          url: '/admission/evaluation-card'
        },
        {
          title: 'evaluation.EvaluationCard',
          url: '/admission/evaluation-card/create/:id',
          active: true
        }
      ],
      pageTitle: 'evaluation.EvaluationCard',
      rule: 'TeacherSNDSMD'
    }
  },
  {
    path: 'applications',
    name: 'applications-list',
    component: ApplicationsList,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        // { title: 'admission.Admission', url: '/admission' },
        {
          title: 'admission.Applications',
          url: '/admission/applications',
          active:true
        }
      ],
      pageTitle: 'Applications',
      rule: 'TeacherSNDSMD'
    }
  },
  {
    path: 'scheduler_meetings',
    name: 'schedulerMeetings-list',
    component: SchedulerMeetingList,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        // { title: 'admission.Admission', url: '/admission' },
        {
          title: 'admission.Applications',
          url: '/admission/applications'
        },
        {
          title: 'admission.scheduler_meetings',
          active:true
        }
      ],
      pageTitle: 'admission.scheduler_meetings',
      rule: 'TeacherSNDSMD'
    }
  },
  {
    path: 'subscribers',
    name: 'subscribers-list',
    component: Subscribers,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        // { title: 'admission.Admission', url: '/admission' },
        {
          title: 'admission.Applications',
          url: '/admission/applications'
        },
        {
          title: 'admission.Subscribers',
          active:true
        }
      ],
      pageTitle: 'admission.Subscribers',
      rule: 'TeacherSNDSMD'
    }
  },
  {
    path: 'registration',
    name: 'registration',
    component: registrationsList,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        //  { title: 'admission.Admission', url: '/admission' },
        {
          title: 'admission.registration',
          url: '/admission/registration',
          active: true
        }
      ],
      pageTitle: 'Registration',
      rule: 'TeacherSNDSMD'
    }
  },
  {
    path: 'evaluation-card/edit/:id',
    name: 'edit-evaluation',
    component: EditEvaluationCard,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        // { title: 'evaluation.EvaluationCard', url: '/admission' },
        {
          title: 'evaluation.EvaluationCard',
          url: '/admission/evaluation-card'
        },
        {
          title: 'evaluation.EvaluationCard',
          url: '/admission/evaluation-card/edit/:id',
          active: true
        }
      ],
      pageTitle: 'evaluation.EvaluationCard',
      rule: 'TeacherSNDSMD'
    }
  },
  {
    path: '/student-affairs/all-students',
    name: 'all-students',
    component: AllStudentsList,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },

        // {
        //   title: 'StudentAffairs.student_affairs',
        //   url: '/student-affairs'
        // },
        {
          title: 'StudentAffairs.allStudents',
          url: '/student-affairs/all-students',
          active: true
        }
      ],
      pageTitle: 'StudentAffairs.allStudents',
      rule: 'TeacherSNDSMD'
    }
  }

]
