<template>
  <ListPageWithCreateButton
    createRoutName="AddBranch"
    :showCreateBtn="SNDUsersNotAssigned.length"
    :createBtnName="$t('config.AddBranch')">
    <template slot="listDataContent">
      <data-table
        :headers_prop="headers"
        list_url_prop="/school/SchoolPaginated" />
    </template>
  </ListPageWithCreateButton>
</template>
<script>
import DataTable from '@/components/general/datatable/DataTable2.vue'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const BranchRepository = RepositoryFactory.get('ConfigurationModule', 'branch')
export default {
  name: 'BranchManager',
  components: {DataTable},

  data() {
    return {
      headers: [
        {
          minWidth: 160,
          headerName: this.$t('RoundManager.S'),
          field: 'id',
          checkboxSelection: true,
          width: 125,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true
        },
        // { headerName: this.$t('RoundManager.Round'), field: 'round', width:200},
        {
          minWidth: 240,
          headerName: this.$t('InstitutionDetails.LongName'),
          field: 'long_name'
        },
        {
          minWidth: 160,
          headerName: this.$t('InstitutionDetails.ShortName'),
          field: 'short_name'
        },
        // {
        //   minWidth: 200,
        //   headerName: this.$t('InstitutionDetails.BranchName'),
        //   field: 'branch_name',
        //   width: 450
        // },

        //{ headerName: this.$t('division.status'), field: 'status', width:290 },

        {
          minWidth: 160,
          filter: false,
          headerName: this.$t('basic.actions'),
          field: 'transactions',
          width: 130,
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            actions_prop: [
              {
                title: this.$t('main.Edit'),
                route_name: 'BranchEdit',
                parameter_name: 'id',
                icon: 'Edit3Icon',
                navigate_action: true
              }
              //  {title: 'Activate',action: "activate", resource:"division", parameter_name:"id", icon: 'CheckSquareIcon', navigate_action:false},
              // {title: 'Deactivate',action: "deactivate", resource:"division", parameter_name:"id", icon: 'SlashIcon', navigate_action:false}
            ],
            list_prop: 'list'
          }
        }
      ],

      selectedUsers: [],

      export_file: false,
      SNDUsersNotAssigned: []
    }
  },
  methods: {
    getUsers() {
      BranchRepository.getUsers().then((res) => {
        this.SNDUsersNotAssigned = res.users
      })
    }
  },
  created() {
    this.getUsers()
  }
}
</script>

<style scoped lang="scss">
#round_buttons {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  min-width: 155px;
  height: fit-content;
  z-index: 999;
  transform: rotate(90deg) translateY(-140px);
  button {
    display: inline-block;
    margin-left: 6px;
  }
}
</style>
