export default {
  name: 'gridSettingsModal',
  data () {
    return {
      modalName: 'gridSettingsModal',
      modalProps: {
        width: '600px',
        height: 'auto',
        scrollable: true
      },
      selectedColumns:null,
      gridSettings:{

      },
      customEvents: [{eventName: 'openGridSettingsModal', callback: this.loadData}]
    }
  },
  created () {
    ////////////////////////////////////////////////////
    //**          Custom Event Listener           ** //
    //////////////////////////////////////////////////
    this.customEvents.forEach(function (customEvent) {
      // eslint-disable-next-line no-undef
      $sharedEvent.listen(customEvent.eventName, customEvent.callback)
    })

  },
  mounted () {
  },
  destroyed () {
    ////////////////////////////////////////////////////
    //**      Remove Custom Event Listener        ** //
    //////////////////////////////////////////////////
    this.customEvents.forEach(function (customEvent) {
      // eslint-disable-next-line no-undef
      $sharedEvent.off(customEvent.eventName, customEvent.callback)
    })
  },
  methods:{
    showModal () {
      this.$modal.show(this.modalName)
    },
    hideModal () {
      this.$modal.hide(this.modalName)
    },
    loadData (data) {
      this.resetProps()
      this.gridSettings = data
      this.loadSelectedColumns()
      this.showModal()
    },
    loadSelectedColumns () {
      this.selectedColumns = this.gridSettings.columnDefs.map(item => {
        const selected =  this.gridSettings.selectedColumns.find(obj => obj.field === item.field)
        if (selected) {
          return {...item, 'selected':true}
        } else {
          return {...item, 'selected':false}
        }
      })

    },
    setSelectedColumns () {
      this.gridSettings.selectedColumns = this.selectedColumns.filter(item => {
        if (item.selected) {
          delete item.selected
          return item
        }
      })
    },
    resetProps () {
      this.gridSettings = null
      this.selectedColumns = null
    },
    save () {
      this.setSelectedColumns()
      // eslint-disable-next-line no-undef
      $sharedEvent.fire('loadGridSettingsModalData', this.gridSettings)
      this.cancel()
    },
    cancel () {
      this.resetProps()
      this.hideModal()
    }
  }
}
