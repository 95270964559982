
<template>
  <vx-card class="p-3 pt-6 pb-6 md:p-6">
    <div class="mt-5">
      <!-- <div class="vx-row">
        <div class="vx-col sm:w-1/3 w-full mt-5"></div>
        <div class="vx-col sm:w-1/3 w-full mt-5">
          <vs-radio
            class="m-2"
            v-on:change="resetform"
            v-model="new_class.is_new"
            vs-name="radios1"
            vs-value="1"
            >{{ $t("classes.new_class") }}</vs-radio
          >
          <vs-radio
            class="m-2"
            v-on:change="resetform"
            v-model="new_class.is_new"
            vs-name="radios1"
            vs-value="0"
            >{{ $t("classes.recurrent_class") }}</vs-radio
          >
        </div>
      </div> -->

      <div v-if="new_class.is_new == 1">
        <form-wizard
          :start-index="0"
          ref="new-class-wizard"
          color="rgba(var(--vs-primary), 1)"
          :title="null"
          :subtitle="null"
          finishButtonText="Submit"
          @on-complete="classlistRedirction"
          @on-change="validateStep"
        >
          <tab-content
            :title="`${$t('classes.BasicInformation')}`"
            class="mb-5"
          >
            <!-- tab 1 content -->

            <div class="vx-row">
              <div class="vx-col sm:w-1/4 w-full mt-5">
                <label>{{ $t("classes.AcademicYear") }}</label>
              </div>
              <div class="vx-col sm:w-1/2 w-full mt-5">
                {{ current_academic_year.name }}
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col sm:w-1/4 w-full mt-5">
                <label>{{ $t("classes.Name") }}</label>
              </div>
              <div class="vx-col sm:w-1/2 w-full mt-5">
                <vs-input v-model="new_class.name" class="w-full"></vs-input>
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col sm:w-1/4 w-full mt-5">
                <label>{{ $t("classes.Divisions") + " " }}</label>
                <span class="text-danger">*</span>
              </div>
              <div class="vx-col sm:w-1/2 w-full mt-5">
                <v-select
                  :placeholder="$t('classes.selectDivisions')"
                  closeOnSelect
                  label="long_name"
                  :reduce="(division) => division.id"
                  v-model="new_class.division"
                  v-validate="'required'"
                  class="w-full"
                  name="selected_divisions"
                  :options="divisions_all"
                  @input="availableGrades"
                />

                <span v-if="showErrorsTab0" class="text-danger text-sm">{{
                  errors.first("selected_divisions") | text_formatter
                }}</span>
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col sm:w-1/4 w-full mt-5">
                <label>{{ $t("classes.grades") + " " }}</label>
                <span class="text-danger">*</span>
              </div>
              <div class="vx-col sm:w-1/2 w-full mt-5">
                <v-select
                  closeOnSelect
                  :placeholder="$t('classes.selectGrades')"
                  :disabled="new_class.division === null"
                  label="name"
                  :reduce="(grade) => grade.id"
                  v-model="new_class.grade"
                  v-validate="'required'"
                  class="w-full"
                  name="selected_grades"
                  :options="grades_all"
                />
                <span v-if="showErrorsTab0" class="text-danger text-sm">{{
                  errors.first("selected_grades") | text_formatter
                }}</span>
              </div>
            </div>
          </tab-content>

          <!-- tab 2 content -->
          <tab-content :title="`${$t('classes.AssignStudents')}`" class="mb-5">
            <div class="vx-row">
              <div class="vx-col sm:w-3/3 w-full mt-5">
                <div>
                  <vs-table
                    hoverFlat
                    stripe
                    :data="students"
                    search
                    multiple
                    v-model="selected"
                    @selected="handleSelected"
                    :noDataText="
                      $t('classes.all_students_are_assigned_to_classes')
                    "
                  >
                    <template slot="thead">
                      <vs-th>{{ $t("form.ID") }}</vs-th>
                      <vs-th>{{ $t("form.name") }}</vs-th>
                      <vs-th>{{ $t("form.official_email") }}</vs-th>
                    </template>

                    <template slot-scope="{ data }">
                      <vs-tr
                        :data="tr"
                        :key="indextr"
                        v-for="(tr, indextr) in data"
                      >
                        <vs-td :data="data[indextr].id">{{
                          data[indextr].id
                        }}</vs-td>

                        <vs-td :data="data[indextr].first_name"
                          >{{ data[indextr].first_name }}
                          {{ data[indextr].middle_name }}
                          {{ data[indextr].last_name }}</vs-td
                        >

                        <vs-td :data="data[indextr].official_email">{{
                          data[indextr].official_email
                        }}</vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                </div>
              </div>
            </div>
          </tab-content>

          <!-- tab 3 content -->
          <tab-content :title="`${$t('classes.overview')}`" class="mb-5">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full mt-5 mb-5">
                <feather-icon
                  icon="UsersIcon"
                  svgClasses="h-5 w-5"
                  class="mr-3"
                />
                <strong class="mr-3">{{ StudentsCount }}</strong>
                {{ $t("basic.students") }}
              </div>
              <div class="vx-col sm:w-1/3 w-full mt-5 mb-5" v-if="Males">
                <feather-icon
                  icon="UsersIcon"
                  svgClasses="h-5 w-5"
                  class="mr-3"
                />
                <strong class="mr-3"> {{ Males }}</strong>
                {{ $t("basic.males") }}
              </div>
              <div class="vx-col sm:w-1/3 w-full mt-5 mb-5" v-if="Females">
                <feather-icon
                  icon="UsersIcon"
                  svgClasses="h-5 w-5"
                  class="mr-3"
                />
                <strong class="mr-3">{{ Females }}</strong
                >{{ $t("basic.females") }}
              </div>
            </div>
            <div class="vx-row">
              <vs-table stripe :data="overviewStudents">
                <template slot="thead">
                  <vs-th>{{ $t("form.ID") }}</vs-th>
                  <vs-th>{{ $t("form.name") }}</vs-th>
                  <vs-th>{{ $t("form.official_email") }}</vs-th>
                  <vs-th>{{ $t("form.type") }}</vs-th>
                </template>

                <template slot-scope="{ data }">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="data[indextr].id">{{
                      data[indextr].id
                    }}</vs-td>
                    <vs-td :data="data[indextr].first_name"
                      >{{ data[indextr].first_name }}
                      {{ data[indextr].middle_name }}
                      {{ data[indextr].last_name }}</vs-td
                    >
                    <vs-td :data="data[indextr].official_email">{{
                      data[indextr].official_email
                    }}</vs-td>
                    <vs-td :data="data[indextr].is_new">{{
                      checkIsNew(data[indextr].is_new)
                    }}</vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </tab-content>
        </form-wizard>
      </div>
      <div v-else>
        <!-----------================================================================================
      ===========================================****** start Recurrent Class *******=============================================
        ================================================================= ---------------------------------------->
        <form-wizard
          ref="Recurrent-class-wizard"
          :start-index="0"
          color="rgba(var(--vs-primary), 1)"
          :title="null"
          :subtitle="null"
          finishButtonText="Submit"
          @on-complete="classlistRedirction"
          @on-change="RecurrentClassValidateStep"
        >
          <tab-content
            :title="`${$t('classes.BasicInformation')}`"
            class="mb-5"
          >
            <!-- tab 1 content -->

            <div class="vx-row">
              <div class="vx-col sm:w-1/4 w-full mt-5">
                <label>{{ $t("classes.academicYear") }}</label>
              </div>
              <div class="vx-col sm:w-1/2 w-full mt-5">
                {{ current_academic_year.name }}
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col sm:w-1/4 w-full mt-5">
                <label>{{ $t("classes.Name") }}</label>
              </div>
              <div class="vx-col sm:w-1/2 w-full mt-5">
                <vs-input
                  v-model="Recurrent_class.name"
                  class="w-full"
                ></vs-input>
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col sm:w-1/4 w-full mt-5">
                <label>{{ $t("subject.division") }}</label>
                <span class="text-danger">*</span>
              </div>
              <div class="vx-col sm:w-1/2 w-full mt-5">
                <v-select
                  :placeholder="$t('classes.selectDivisions')"
                  closeOnSelect
                  label="long_name"
                  :reduce="(division) => division.id"
                  v-model="Recurrent_class.division"
                  v-validate="'required'"
                  class="w-full"
                  name="selectedRecurrent_divisions"
                  :options="divisions_all"
                  @input="availableGrades"
                />
                <span
                  v-if="RecurrentshowErrorsTab0"
                  class="text-danger text-sm"
                  >{{
                    errors.first("selectedRecurrent_divisions") | text_formatter
                  }}</span
                >
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col sm:w-1/4 w-full mt-5">
                <label>{{ $t("classes.grades") }}</label>
                <span class="text-danger">*</span>
              </div>
              <div class="vx-col sm:w-1/2 w-full mt-5">
                <v-select
                  closeOnSelect
                  :placeholder="$t('classes.selectGrades')"
                  :disabled="Recurrent_class.division === null"
                  label="name"
                  :reduce="(grade) => grade.id"
                  v-model="Recurrent_class.grade"
                  v-validate="'required'"
                  class="w-full"
                  name="selectedRecurrent_grades"
                  :options="grades_all"
                  @input="availableclasses"
                />
                <span
                  v-if="RecurrentshowErrorsTab0"
                  class="text-danger text-sm"
                  >{{
                    errors.first("selectedRecurrent_grades") | text_formatter
                  }}</span
                >
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col sm:w-1/4 w-full mt-5">
                <label>{{ $t("classes.classes") }}</label>
                <span class="text-danger">*</span>
              </div>
              <div class="vx-col sm:w-1/2 w-full mt-5">
                <v-select
                  closeOnSelect
                  :placeholder="$t('classes.selectClasses')"
                  :disabled="
                    Recurrent_class.division === null ||
                    Recurrent_class.grade === null
                  "
                  label="name"
                  :reduce="(classData) => classData.id"
                  v-model="Recurrent_class.classData"
                  v-validate="'required'"
                  class="w-full"
                  name="selectedRecurrent_classes"
                  :options="classes_all"
                />
                <span
                  v-if="RecurrentshowErrorsTab0"
                  class="text-danger text-sm"
                  >{{
                    errors.first("selectedRecurrent_classes") | text_formatter
                  }}</span
                >
              </div>
            </div>
          </tab-content>

          <!-- tab 2 content -->
          <tab-content :title="`${$t('classes.AssignStudents')}`" class="mb-5">
            <div class="vx-row">
              <div class="vx-col sm:w-3/3 w-full mt-5">
                <div class="flex justify-end items-center mb-5">
                  <vs-button
                    class="m-2"
                    color="warning"
                    type="line"
                    @click="NotAddAllStudents"
                    >{{ $t("classes.Unselect_all_students") }}</vs-button
                  >
                  <vs-button
                    class="m-2"
                    type="line"
                    @click="allClassStudents"
                    >{{ $t("classes.select_all_students") }}</vs-button
                  >
                </div>

                <vs-table
                  class="mt-3"
                  multiple
                  v-model="selectedRecurrentStudents"
                  :data="recurrentStudents"
                  @selected="handleSelectedRecurrentStudents"
                  :noDataText="
                    $t('classes.all_students_are_assigned_to_classes')
                  "
                >
                  <template slot="thead">
                    <vs-th>{{ $t("form.ID") }}</vs-th>
                    <vs-th>{{ $t("form.name") }}</vs-th>
                    <vs-th>{{ $t("form.type") }}</vs-th>
                    <vs-th>{{ $t("form.status") }}</vs-th>
                  </template>

                  <template slot-scope="{ data }">
                    <vs-tr
                      :data="tr"
                      :key="indextr"
                      v-for="(tr, indextr) in data"
                    >
                      <vs-td :data="data[indextr].id">{{
                        data[indextr].id
                      }}</vs-td>

                      <vs-td :data="data[indextr].first_name"
                        >{{ data[indextr].first_name }}
                        {{ data[indextr].middle_name }}
                        {{ data[indextr].last_name }}</vs-td
                      >

                      <vs-td :data="data[indextr].is_new">{{
                        checkIsNew(data[indextr].is_new)
                      }}</vs-td>

                      <vs-td :data="data[indextr].is_assign">{{
                        checkIsAssigned(data[indextr].is_assign)
                      }}</vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </div>
            </div>
          </tab-content>

          <!-- tab 3 content -->
          <tab-content :title="`${$t('classes.Customize')}`" class="mb-5">
            <div class="vx-row">
              <div class="vx-col sm:w-3/3 w-full mt-5">
                <div>
                  <vs-table
                    hoverFlat
                    stripe
                    :data="recurrentAllStudents"
                    search
                    multiple
                    v-model="AllStudens"
                    @selected="handleSelectedRecurrentStudents"
                  >
                    <template slot="thead">
                      <vs-th>{{ $t("form.ID") }}</vs-th>
                      <vs-th>{{ $t("form.name") }}</vs-th>
                      <vs-th>{{ $t("form.type") }}</vs-th>
                      <vs-th>{{ $t("form.status") }}</vs-th>
                    </template>

                    <template slot-scope="{ data }">
                      <vs-tr
                        :data="tr"
                        :key="indextr"
                        v-for="(tr, indextr) in data"
                      >
                        <vs-td :data="data[indextr].id">{{
                          data[indextr].id
                        }}</vs-td>

                        <vs-td :data="data[indextr].first_name"
                          >{{ data[indextr].first_name }}
                          {{ data[indextr].middle_name }}
                          {{ data[indextr].last_name }}</vs-td
                        >

                        <vs-td :data="data[indextr].is_new">{{
                          checkIsNew(data[indextr].is_new)
                        }}</vs-td>

                        <vs-td :data="data[indextr].is_assign">{{
                          checkIsAssigned(data[indextr].is_assign)
                        }}</vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                </div>
              </div>
            </div>
          </tab-content>

          <!-- tab 4 content -->
          <tab-content :title="`${$t('classes.overview')}`" class="mb-5">
            <div class="vx-row mb-5 w-full">
              <div class="vx-col sm:w-1/3 w-full mt-5 mb-5">
                <feather-icon
                  icon="UsersIcon"
                  svgClasses="h-5 w-5"
                  class="mr-3"
                />
                <strong class="mr-3">{{ StudentsCount }}</strong>
                {{ $t("basic.students") }}
              </div>
              <div class="vx-col sm:w-1/3 w-full mt-5 mb-5" v-if="Males">
                <feather-icon
                  icon="UsersIcon"
                  svgClasses="h-5 w-5"
                  class="mr-3"
                />
                <strong class="mr-3"> {{ Males }}</strong>
                {{ $t("basic.males") }}
              </div>
              <div class="vx-col sm:w-1/3 w-full mt-5 mb-5" v-if="Females">
                <feather-icon
                  icon="UsersIcon"
                  svgClasses="h-5 w-5"
                  class="mr-3"
                />
                <strong class="mr-3">{{ Females }}</strong
                >{{ $t("basic.females") }}
              </div>
            </div>
            <div class="vx-row w-full">
              <vs-table stripe :data="overviewStudents" class="w-full">
                <template slot="thead">
                  <vs-th>{{ $t("form.ID") }}</vs-th>
                  <vs-th>{{ $t("form.name") }}</vs-th>
                  <vs-th>{{ $t("form.official_email") }}</vs-th>
                  <!-- <vs-th>{{ $t("form.type") }}</vs-th> -->
                </template>

                <template slot-scope="{ data }">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="data[indextr].id">{{
                      data[indextr].id
                    }}</vs-td>

                    <vs-td :data="data[indextr].first_name"
                      >{{ data[indextr].first_name }}
                      {{ data[indextr].middle_name }}
                      {{ data[indextr].last_name }}
                    </vs-td>
                    <!-- <vs-td :data="data[indextr].email">{{
                      data[indextr].email
                    }}</vs-td> -->

                    <vs-td :data="data[indextr].official_email">{{
                      data[indextr].official_email
                    }}</vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </tab-content>
        </form-wizard>
      </div>
    </div>
  </vx-card>
</template>


<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import vSelect from 'vue-select'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const ClassesRepository = RepositoryFactory.get('AcademicModule', 'Classes')
const AcademicYearRepository = RepositoryFactory.get(
  'AcademicModule',
  'academicYears'
)
const DivisionRepository = RepositoryFactory.get(
  'ConfigurationModule',
  'division'
)

export default {
  name: 'Create',
  components: {
    FormWizard,
    TabContent,
    'v-select': vSelect
  },
  data () {
    return {
      Females: 0,
      Males: 0,
      StudentsCount: 0,
      AllStudens: [],
      overviewStudents: [],
      selected: [],
      new_class: {
        is_new: 1,
        students: [],
        AcademicYear: null,
        division: null,
        grade: null,
        code: null,
        name:null
      },
      Recurrent_class: {
        is_new: 0,
        students: [],
        AcademicYear: null,
        division: null,
        grade: null,
        classData: null,
        code: null,
        name:null
      },
      current_academic_year: [],
      divisions_all: [],
      grades_all: [],
      classes_all: [],
      subjects_all: [],
      showErrorsTab0: false,
      RecurrentshowErrorsTab0: false,
      showErrorsTab1: false,
      showErrorsTab2: false,
      students: [],
      recurrentStudents: [],
      recurrentAllStudents: [],
      selectedRecurrentStudents: []
    }
  },

  methods: {
    activeAcadmicYear () {
      // console.log('kkkkkk')
      AcademicYearRepository.activeAcadmicYear().then((res) => {
        // console.log(res)
        // console.log(res.data)
        // console.log(this.current_academic_year)
        this.current_academic_year = res.data
        this.new_class.AcademicYear = res.data._id
        this.Recurrent_class.AcademicYear = res.data._id
      })
    },
    resetform () {
      this.successMsg(this.$t('classes.you_changed_type_Of_Class'))

      this.$refs['Recurrent-class-wizard'].navigateToTab(0)
      this.$refs['new-class-wizard'].navigateToTab(0)
    },
    AllStudenshandleSelected () {
      ClassesRepository.AllStudentsNotInClass(
        this.Recurrent_class.classData,
        this.Recurrent_class.grade,
        this.Recurrent_class.division
      ).then((res) => {
        // console.log(res)
        this.recurrentAllStudents = res.data
        // console.log(this.recurrentAllStudents)
      })
      // console.log(this.recurrentAllStudents)
    },
    //========second tab recurrent students====top 2 buttons========//
    NotAddAllStudents () {
      this.selectedRecurrentStudents = []
    },
    allClassStudents () {
      this.recurrentStudents.map(student => {
        this.selectedRecurrentStudents.push(student)
        return student
      })

      // ClassesRepository.studentInClass(
      //   this.Recurrent_class.classData,
      //   this.Recurrent_class.grade,
      //   this.Recurrent_class.division
      // ).then((res) => {
      //   console.log(res)
      //   this.selectedRecurrentStudents = res.data
      // })
    },
    //========end second tab recurrent students====top 2 buttons========//

    studentsInClass () {
      ClassesRepository.studentInClass(
        this.Recurrent_class.classData,
        this.Recurrent_class.grade,
        this.Recurrent_class.division
      ).then((res) => {
        // console.log(res)
        this.recurrentStudents = res.data
      })
    },
    checkIsNew (typeData) {
      if (typeData === 1) return this.$t('classes.new_student')
      return this.$t('classes.recurrent_student')
    },
    checkIsAssigned (typeData) {
      if (typeData === 1) return this.$t('classes.assigned_student')
      return this.$t('classes.not_assigned_student')
    },
    classlistRedirction () {
      this.$router.push({ name: 'ClassesList' })
      this.successMsg(this.$t('classes.new_class_has_been_created_successfully_and_completed_overview'))

    },
    getNotAssignStudents () {
      ClassesRepository.getNotAssignStudents(
        this.new_class.grade,
        this.new_class.division
      ).then(
        (res) => {
          this.students = res.data
        },
        (error) => {
          // console.log(error)
        }
      )
    },
    handleSelectedRecurrentStudents () {
      const students = this.selectedRecurrentStudents.map((x) => x.id)
      const AllStudens = this.AllStudens.map((item) => item.id)
      this.Recurrent_class.students = students.concat(AllStudens)
    },
    handleSelected () {
      const students = this.selected.map((x) => x.id)
      this.new_class.students = students
    },

    validateStep (prevIndex, nextIndex) {
      const validateStep0 = () => {
        const hasErrors =
          this.$validator.errors.items.filter(
            (error) => error.field === 'syllabus_code' ||
              error.field === 'selected_divisions' ||
              error.field === 'selected_grades'
          ).length !== 0

        if (hasErrors) {
          this.$refs['new-class-wizard'].changeTab(nextIndex, prevIndex)
        } else if (this.selected.length === 0) {
          this.getNotAssignStudents()
        }
        this.showErrorsTab0 = true
      }

      const validateStep1 = () => {
        const hasErrors = this.selected.length === 0
        if (hasErrors) {
          this.errMsg(this.$t('classes.please_add_student_to_this_class'))

          this.$refs['new-class-wizard'].changeTab(nextIndex, prevIndex)
        } else {
          this.handleSelected()
          this.formSubmitted()
        }

        this.showErrorsTab1 = true
      }

      if (nextIndex > prevIndex) {
        this.$validator.validate().then((valid) => {
          switch (prevIndex) {
          case 0:
            validateStep0()
            break
          case 1:
            validateStep1()
            break
          }
        })
      }
    },
    //====================== Recurrent Class==================
    RecurrentClassValidateStep (prevIndex, nextIndex) {
      this.new_class.division = [1]
      this.new_class.grade = [1]
      const RecurrentvalidateStep0 = () => {
        const hasErrors =
          this.$validator.errors.items.filter(
            (error) => error.field === 'selectedRecurrent_divisions' ||
              error.field === 'selectedRecurrent_grades' ||
              error.field === 'selectedRecurrent_classes'
          ).length !== 0

        if (hasErrors) {
          this.$refs['Recurrent-class-wizard'].changeTab(nextIndex, prevIndex)
          this.RecurrentshowErrorsTab0 = true
        } else {
          this.studentsInClass()
          // this.$refs["new-class-wizard"].changeTab(1, 0) ;

          //this.$refs["new-class-wizard"].reset ;
        }
      }

      const RecurrentvalidateStep1 = () => {
        const hasErrors = this.selectedRecurrentStudents.length === 0
        if (hasErrors) {
          this.errMsg(
            this.$t('classes.you_do_not_add_student_from_this_old_class_to_recurrent'),
            this.$t('classes.you_do_not_assign_student')
          )

          //  this.$refs["Recurrent-class-wizard"].changeTab(nextIndex, prevIndex);
        }

        this.handleSelectedRecurrentStudents()
        this.AllStudenshandleSelected()

        this.showErrorsTab1 = true
      }
      const RecurrentvalidateStep2 = () => {
        let hasErrors = false
        if (this.selectedRecurrentStudents.length === 0 && this.AllStudens.length === 0) {
          hasErrors = true
        }
        if (hasErrors) {
          this.errMsg(this.$t('classes.please_add_student_to_this_class'))

          this.$refs['Recurrent-class-wizard'].changeTab(nextIndex, prevIndex)
        } else {
          this.handleSelectedRecurrentStudents()
          this.RecurrentformSubmitted()
        }

        this.showErrorsTab1 = true
      }

      if (nextIndex > prevIndex) {
        this.$validator.validate().then((valid) => {
          switch (prevIndex) {
          case 0:
            RecurrentvalidateStep0()
            break
          case 1:
            RecurrentvalidateStep1()
            break
          case 2:
            RecurrentvalidateStep2()
            break
          }
        })
      }
    },
    /////////////////////////////////=================end validate Recurrent Class============
    formSubmitted () {
      this.showErrorsTab1 = true
      console.log(this.new_class)
      this.$validator.validate().then((valid) => {
        if (valid) {
          ClassesRepository.create(this.new_class)
            .then((res) => {
              //this.$router.push({ name: "ClassesList" });
              this.successMsg(this.$t('classes.new_class_has_been_created_successfully'))

              // console.log(res.data)
              this.new_class.code = res.data.code
              ClassesRepository.studentInClass(
                res.data.id,
                res.data.grade_id,
                res.data.division_id
              ).then((res) => {
                // console.log(res)
                this.overviewStudents = res.data
                this.StudentsCount = res.Students
                this.Males = res.Males
                this.Females = res.Females
              })
            })
            .catch((err) => {
              // console.log(err)
            })
        } else this.errMsg(this.$t('classes.please_fill_in_the_missing_data'))


      })
      // console.log('Submitted')
      //console.log(payload);
    },
    RecurrentformSubmitted () {
      this.showErrorsTab1 = true
      this.$validator.validate().then((valid) => {
        if (valid) {
          ClassesRepository.createRecurrent(this.Recurrent_class)
            .then((res) => {
              //this.$router.push({ name: "ClassesList" });
              this.successMsg(this.$t('classes.new_class_has_been_created_successfully'))

              // console.log(res.data)
              this.Recurrent_class.code = res.data.code
              //get all students in created class
              ClassesRepository.studentInClass(
                res.data.id,
                res.data.grade_id,
                res.data.division_id
              ).then((res) => {
                // console.log(res)
                this.overviewStudents = res.data
                this.StudentsCount = res.Students
                this.Males = res.Males
                this.Females = res.Females
              })
            })
            .catch((err) => {
              // console.log(err)
            })
        } else this.errMsg(this.$t('classes.please_fill_in_the_missing_data'))

      })
      // console.log('Submitted')
    },
    availableGrades () {
      if (this.new_class.is_new == 1) {
        this.new_class.grade = null
        DivisionRepository.getGrades(this.new_class.division).then((res) => {
          // console.log(res.data)
          this.grades_all = res.data
        })
      } else {

        this.Recurrent_class.grade = null
        this.Recurrent_class.classData = null
        DivisionRepository.getGrades(this.Recurrent_class.division).then(
          (res) => {
            // console.log(res.data)
            this.grades_all = res.data
            this.availableclasses()
          }
        )
      }
    },
    availableclasses () {
      this.Recurrent_class.classData = null
      ClassesRepository.getClasses(
        this.Recurrent_class.division,
        this.Recurrent_class.grade
      ).then((res) => {
        // console.log(res.data)
        this.classes_all = res.data
      })
    },
    getAllData () {
      DivisionRepository.getdata().then((res) => {
        this.divisions_all = res.data.data
      })
    }
  },
  computed: {},
  created () {
    this.activeAcadmicYear()
    // this.new_class.AcademicYear =this.current_academic_year.id
    //this.Recurrent_class.AcademicYear=this.current_academic_year.id
    this.getAllData()
  }
}
</script>

<style  scoped>
.background-none {
  background-color: transparent !important;
  color: rgb(160, 152, 152);
  cursor: pointer;
}
</style>

