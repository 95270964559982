<template>
  <div class="vx-row">
    <div class="vx-col md:w w-full mb-base">
      <vx-card class="p-3 pt-6 pb-6 md:p-6" v-if="closedAttendance">
        <div class="vx-row mb-12">
          {{ $t("Attendance.the_attendance_is_closed") }}
        </div>
      </vx-card>
      <vx-card class="p-3 pt-6 pb-6 md:p-6" v-else>
        <div class="vx-row mb-6">

          <div class="vx-col sm:w-1/3 w-full">
            <label>{{ $t("Attendance.academicDate") }}</label>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <span>{{ attendance_date }}</span>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <label>{{ $t("Attendance.className") }}</label>
          </div>
          <div class="vx-col sm:w-1/3 w-full">

            <v-select
              :placeholder="$t('Attendance.className')"
              :clearable="false"
              closeOnSelect
              label="name"
              v-model="selected_class_name_id"
              v-validate="'required'"
              class="w-full"
              name="selected_claa_name"
              :options="classes_all"
              :showLoadingIcon="loadingClasses"
            />
          </div>
          <div class="vx-col sm:w-1/3 w-full"></div>
        </div>
        <div class="vx-row mb-6" v-if="show_card">
          <div class="vx-col ml-auto mr-0 auto sm:w-1/4 w-full">
            <vs-button
              class="ml-auto mr-0 block"
              v-if="selected_class !== null"
              @click="allStudentsAttended()"
              color="primary"
              type="filled"
              >All Students Are Present
            </vs-button>
          </div>
        </div>
        <div class="vx-row mb-6" v-if="show_card">
          <div class="vx-col sm:w-3/3 w-full ml-auto mr-auto">
            <div v-if="selected_class !== null">
              <vs-table
                stripe
                :hoverFlat="true"
                :noDataText="$t('Attendance.noClasses')"
                :data="selected_class"
              >
                <template slot="thead">
                  <vs-th>{{ $t("form.ID") }}</vs-th>
                  <vs-th>{{ $t("form.name") }}</vs-th>
                  <vs-th>{{ $t("Attendance.attendance_register") }}</vs-th>
                  <vs-th>{{ $t("form.status") }}</vs-th>
                  <vs-th>{{ $t("Attendance.attendance") }}</vs-th>
                </template>
                <template slot-scope="{ data }">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td
                      class="vx-col sm:w-1/6 w-full mt-5"
                      :data="data[indextr]"
                      >{{ selected_class[indextr].code }}</vs-td
                    >
                    <vs-td
                      class="vx-col sm:w-1/6 w-full mt-5"
                      :data="data[indextr]"
                      >{{
                        `${selected_class[indextr].first_name} ${selected_class[indextr].middle_name} ${selected_class[indextr].last_name}`
                      }}</vs-td
                    >
                    <vs-td
                      class="vx-col sm:w-1/6 w-full mt-5"
                      :data="data[indextr]"
                      >{{
                        selected_class[indextr].attendance_status === null
                          ? "Undefined"
                          : "Done"
                      }}</vs-td
                    >
                    <vs-td
                      class="vx-col sm:w-1/6 w-full mt-5"
                      :data="data[indextr]"
                    >
                      <vs-chip
                        :class="`status--${selected_class[
                          indextr
                        ].attendance_status.status_title
                          .toLowerCase()
                          .replaceAll(' ', '_')}`"
                        >{{
                          selected_class[indextr].attendance_status === null
                            ? "Undefined"
                            : selected_class[indextr].attendance_status
                                .status_title
                        }}</vs-chip
                      >
                      <vs-chip
                        v-if="
                          selected_class[indextr].attendance_status.id &&
                          selected_class[
                            indextr
                          ].attendance_status.flag.toLowerCase() ==
                            'authorized absent' &&
                          selected_class[indextr].attendance_status
                            .status_detail_id
                        "
                        :class="`status--${authorized_details_all
                          .find(
                            (item) =>
                              item.detail_id ===
                              selected_class[indextr].attendance_status
                                .status_detail_id
                          )
                          .title.toLowerCase()
                          .replaceAll(' ', '_')}`"
                        >{{
                          selected_class[indextr].attendance_status === null
                            ? "Undefined"
                            : selected_class[
                                indextr
                              ].attendance_status.flag.toLowerCase() ==
                              "authorized absent"
                            ? authorized_details_all.find(
                                (item) =>
                                  item.detail_id ===
                                  selected_class[indextr].attendance_status
                                    .status_detail_id
                              ).title
                            : ""
                        }}
                      </vs-chip>
                      <vs-chip
                        v-else-if="
                          selected_class[indextr].attendance_status.id &&
                          selected_class[
                            indextr
                          ].attendance_status.flag.toLowerCase() == 'present' &&
                          selected_class[indextr].attendance_status
                            .status_detail_id
                        "
                        :class="`status--${present_details_all
                          .find(
                            (item) =>
                              item.detail_id ===
                              selected_class[indextr].attendance_status
                                .status_detail_id
                          )
                          .title.toLowerCase()
                          .replaceAll(' ', '_')}`"
                        >{{
                          selected_class[indextr].attendance_status === null
                            ? "Undefined"
                            : selected_class[
                                indextr
                              ].attendance_status.flag.toLowerCase() ==
                              "present"
                            ? present_details_all.find(
                                (item) =>
                                  item.detail_id ===
                                  selected_class[indextr].attendance_status
                                    .status_detail_id
                              ).title
                            : ""
                        }}
                      </vs-chip>
                    </vs-td>
                    <vs-td
                      class="vx-col sm:w-2/6 w-full mt-5"
                      :data="data[indextr]"
                    >
                      <v-select
                        :placeholder="$t('Attendance.status')"
                        closeOnSelect
                        :clearable="false"
                        appendToBody
                        :value="selected_class[indextr].attendance_status"
                        @input="
                          (attendance_status) =>
                            updateStatus(indextr, attendance_status)
                        "
                        v-validate="'required'"
                        label="status_title"
                        name="selected_new_status"
                        :options="attendance_status_all"
                      >
                        <template v-slot:no-options>
                          <em style="opacity: 0.75">{{
                            $t("Attendance.noClasses")
                          }}</em>
                        </template>
                      </v-select>
                      <v-select
                        v-if="
                          selected_class[indextr].attendance_status.id &&
                          selected_class[
                            indextr
                          ].attendance_status.flag.toLowerCase() ==
                            'authorized absent'
                        "
                        class="mt-4"
                        :placeholder="$t('Attendance.status')"
                        closeOnSelect
                        :clearable="false"
                        appendToBody
                        :value="
                          selected_class[indextr].attendance_status
                            .status_detail_id
                        "
                        @input="
                          (status_detail_id) =>
                            updateStatusDetails(indextr, status_detail_id)
                        "
                        :reduce="(option) => option.detail_id"
                        v-validate="'required'"
                        name="selected_status_details"
                        label="title"
                        :options="authorized_details_all"
                      >
                        <template v-slot:no-options>
                          <em style="opacity: 0.75">{{
                            $t("Attendance.noClasses")
                          }}</em>
                        </template>
                      </v-select>
                      <v-select
                        v-else-if="
                          selected_class[indextr].attendance_status.id &&
                          selected_class[
                            indextr
                          ].attendance_status.flag.toLowerCase() == 'present'
                        "
                        class="mt-4"
                        :placeholder="$t('Attendance.status')"
                        closeOnSelect
                        :clearable="false"
                        appendToBody
                        :value="
                          selected_class[indextr].attendance_status
                            .status_detail_id
                        "
                        @input="
                          (status_detail_id) =>
                            updateStatusDetails(indextr, status_detail_id)
                        "
                        :reduce="(option) => option.detail_id"
                        v-validate="'required'"
                        name="selected_status_details"
                        label="title"
                        :options="present_details_all"
                      >
                        <template v-slot:no-options>
                          <em style="opacity: 0.75">{{
                            $t("Attendance.noClasses")
                          }}</em>
                        </template>
                      </v-select>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
              <div class="vx-row mb-6 mt-6" v-if="selected_class !== null">
                <div class="vx-col sm:w-1/4">
                  <vs-button type="border" color="warning" @click="cancel()">{{
                    $t("form.cancel")
                  }}</vs-button>
                </div>
                <div class="vx-col sm:w-1/4 ml-auto mr-0 block">
                  <vs-button
                    class="ml-auto mr-0 block"
                    @click="submitAttendance()"
                    type="filled"
                    color="primary"
                    >{{ $t("form.submit") }}</vs-button
                  >
                </div>
              </div>
            </div>
            <div v-else>
              <vs-alert title="No such class" color="danger" active="true"
                >{{ $t("Attendance.there_are_no_classes_with_this_code") }}:
                {{ selected_class_name }}</vs-alert
              >
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
import uploadButton from '@/modules/unit-planner/components/uploadButton/uploadButton.vue'
import { mapGetters } from 'vuex'

// import router from '@/router'
const ConsoleRepository = RepositoryFactory.get(
  'Attendance',
  'attendanceConsole'
)
export default {
  components: {
    Datepicker,
    uploadButton
  },
  computed: {
    ...mapGetters('auth', ['authUser'])
  },
  data () {
    return {
      new_attendance: {},
      class_attendance: [],
      classes_all: [],
      loadingClasses: false,
      attendance_date: '',
      attendance_status_all: [],
      authorized_details_all: [],
      present_details_all: [],
      attendance_by_student: false,
      selected_student_id: '',
      selected_class_name: '',
      selected_class_name_id: {},
      show_card: false,
      selected_student: null,
      selected_class: [],
      students: [],
      changing_status: false,
      new_status: null,
      // show_datepicker: false,
      disabled_dates: {},
      closedAttendance: false
    }
  },
  watch: {
    selected_class_name_id (v) {
      this.selected_class_name = v.name
      this.getClass(v.name)
    },
    selected_class_name (v) {
      if (!v) this.selected_class_name_id = {}
    }
  },
  methods: {
    allStudentsAttended () {
      const presentStatus = this.attendance_status_all.find(
        (item) => item.flag === 'Present'
      )

      const presentStatusDetail = this.present_details_all.find(
        (item) => item.title === 'Present'
      )

      this.selected_class = this.selected_class.map((temp) => {
        temp.attendance_status = presentStatus
        temp.attendance_status.status_detail_id = presentStatusDetail.detail_id
        return temp
      })
    },
    getClass (code) {
      ConsoleRepository.getClassStudents(code, this.attendance_date)
        .then((res) => {
          if (res.students.length === 0) {
            this.selected_class = null
          } else {
            this.selected_class = res.students
            this.selected_class.map((student) => {
              const attendance_status = this.attendance_status_all.find(
                (item) => item.id === student.attendance_status.status_id
              )
              if (student.attendance_status === 'unfinished') {
                student.attendance_status = {
                  status_title: 'Undefined',
                  status_details: null
                }
                return student
              } else if (
                attendance_status.flag.toLowerCase() === 'authorized absent' ||
                attendance_status.flag.toLowerCase() === 'present'
              ) {
                student.attendance_status = {
                  id: attendance_status.id,
                  flag: attendance_status.flag,
                  status_title: attendance_status.status_title
                  // status_detail_id: student.attendance_status.status_details.id
                }
                return student
              } else {
                student.attendance_status = attendance_status
                return student
              }
            })
          }
          this.show_card = true
        })
        .catch(() => {
          // this.$vs.notify({
          //   title: 'Class not found',
          //   text: 'There is no class with this name',
          //   iconPack: 'feather',
          //   icon: 'icon-alert-circle',
          //   color: 'danger'
          // })
          this.selected_class = null
          this.$vs.loading.close()
        })
    },
    updateStatus (indextr, attendance_status) {
      this.selected_class[indextr].attendance_status = attendance_status
      if (
        this.selected_class[indextr].attendance_status.flag.toLowerCase() !==
          'authorized absent' &&
        this.selected_class[indextr].attendance_status.flag.toLowerCase() !==
          'present'
      ) {
        this.selected_class[indextr].attendance_status = Object.keys(
          this.selected_class[indextr].attendance_status
        ).reduce((object, key) => {
          if (key !== 'status_detail_id') {
            object[key] = this.selected_class[indextr].attendance_status[key]
          }
          return object
        }, {})
      }
    },
    updateStatusDetails (indextr, status_detail_id) {
      this.selected_class[indextr].attendance_status = {
        ...this.selected_class[indextr].attendance_status
      }
      this.selected_class[indextr].attendance_status.status_detail_id =
        status_detail_id
    },
    submitAttendance () {
      const payload = {
        students: [],
        class_id: this.selected_class_name_id.id,
        date: this.attendance_date
      }
      const today = new Date()
      this.selected_class.map((temp) => {
        payload.students.push({
          student_id: temp.user_id,
          class_id: this.classes_all.filter(
            (el) => el.name === this.selected_class_name
          )[0].id,
          attendance: temp.attendance_status.id,
          flag: temp.attendance_status.flag,
          status_detail_id: temp.attendance_status.status_detail_id || null,
          date: this.attendance_date,
          attendance_time: `${today.getHours()}:${today.getMinutes()}`
        })
        return temp
      })

      const allSet =
        payload.students.filter((student) => {
          if (
            student.flag &&
            student.flag.toLowerCase() !== 'authorized absent' &&
            student.flag.toLowerCase() !== 'present'
          ) {
            return Boolean(
              this.attendance_status_all.find(
                (item) => item.flag === student.flag
              )
            )
          } else return student.status_detail_id !== null
        }).length === payload.students.length

      if (allSet) {
        ConsoleRepository.changeAttendanceStatus(payload)
          .then((res) => {
            this.$vs.notify({
              title: 'Attendance Updated',
              text: res.message,
              iconPack: 'feather',
              icon: 'icon-check-circle',
              color: 'success'
            })
            this.show_card = false
            this.selected_student_id = ''
            this.selected_class_name = ''
            // this.$router.push('/landing/home')
          })
          .catch((err) => {
            this.$vs.notify({
              title: 'Attendance Update Failed',
              text: err.data.message,
              iconPack: 'feather',
              icon: 'icon-close-circle',
              color: 'danger'
            })
            this.$vs.loading.close()
          })
      } else {
        this.$vs.notify({
          title: 'Attendance Update Failed',
          text: 'All student should have a status',
          iconPack: 'feather',
          icon: 'icon-close-circle',
          color: 'warning'
        })
      }
    },
    cancel () {
      this.show_card = false
      this.selected_class = []
    }
  },
  created () {
    const today = new Date()
    const dd = String(today.getDate()).padStart(2, '0')
    const mm = String(today.getMonth() + 1).padStart(2, '0')
    const yyyy = today.getFullYear()
    this.attendance_date = `${yyyy}-${mm}-${dd}`
    this.disabled_dates.from = today

    ConsoleRepository.checkCloseAttendance(this.authUser.school_id)
      .then((res) => {
        this.closedAttendance = res.response
        if (!this.closedAttendance) {
          this.loadingClasses = true
          ConsoleRepository.getTeacherClasses()
            .then((res) => {
              this.classes_all = res.data
            })
            .finally(() => {
              this.loadingClasses = false
            })
          ConsoleRepository.getStatus().then((res) => {
            let all_status = res.response.map((state) => {
              return {
                status_title: state.en.title,
                id: state.id,
                flag: state.flag
              }
            })
            const all_authorized_details = res.response
              .filter(
                (state) => state.flag.toLowerCase() === 'authorized absent'
              )[0]
              ['details'].map((detail) => {
                return { title: detail.en.title, detail_id: detail.id }
              })
            const all_present_details = res.response
              .filter((state) => state.flag.toLowerCase() === 'present')[0]
              ['details'].map((detail) => {
                return { title: detail.en.title, detail_id: detail.id }
              })

            all_status = all_status.filter(
              (state) => state.flag.toLowerCase() !== 'usual absent' &&
                state.flag.toLowerCase() !== 'disciplined' &&
                state.flag.toLowerCase() !== 'improvement in attendance' &&
                state.flag.toLowerCase() !== 'not recorded' &&
                state.flag.toLowerCase() !== 'recently usual absent' &&
                state.flag.toLowerCase() !== 'used to miss the first bell'
            )

            this.attendance_status_all = all_status
            console.log(this.attendance_status_all)
            this.authorized_details_all = all_authorized_details
            this.present_details_all = all_present_details
          })
        } else {
          this.errMsg(this.$t('Attendance.the_attendance_is_closed'))
        }
        this.$vs.loading.close()
      })
      .catch((err) => {
        this.errMsg(err.message)
      })
  }
}
</script>

<style lang="scss" scoped>
.con-input-upload {
  height: 3rem;
  width: auto;
  background: #666;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
}

// Upload
.con-upload {
  .con-input-upload,
  .con-img-upload {
    height: 3rem !important;
    width: auto !important;
    background: #666 !important;
    color: #fff !important;
    font-weight: bold !important;
    font-size: 18px !important;
  }
}

////////////////////////////@at-root
</style>
