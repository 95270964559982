var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"justify-between flex align-center w-full flex-wrap",class:{ 'lg:flex-no-wrap': _vm.status.length < 5 }},_vm._l((_vm.status),function(val,i){return _c('div',{key:val.id || i,staticClass:"mb-4 flex-1"},[_c('button',{staticClass:"w-full no_style block pl-1 pr-1",class:{ active: (val.value || val.id) === _vm.selected },attrs:{"title":val.title ? val.title : val.name},on:{"click":function($event){return _vm.getFilter(val)}}},[_c('vx-card',{staticClass:"overflow-hidden small-card"},[_c('div',{attrs:{"slot":"no-body"},slot:"no-body"},[_c('div',{staticClass:"p-4",class:''},[_c('div',{staticClass:"flex justify-between flex-row-reverse items-center"},[_c('feather-icon',{staticClass:"p-2 inline-flex rounded-full",class:`status--${
                  val.color
                    ? val.color
                    : val.value
                    ? val.value
                    : val.name.includes(' ')
                    ? val.name.toLowerCase().replaceAll(' ', '_')
                    : val.name.toLowerCase()
                }`,attrs:{"icon":val.icon}}),(val.sub && val.sub.length > 0)?_c('div',_vm._l((val.sub),function(item,itemIndex){return _c('div',{key:itemIndex,staticClass:"truncate"},[_c('p',{staticClass:"mb-1 mr-4 font-bold inline-block"},[_vm._v(" "+_vm._s(item.count || 0)+" ")]),_c('span',[_vm._v(_vm._s(_vm._f("text_formatter")(item.title ? item.title : item.name)))])])}),0):_c('div',{staticClass:"truncate"},[_c('h3',{staticClass:"mb-1 font-bold"},[_vm._v(" "+_vm._s(val.count || 0)+" ")]),_c('span',[_vm._v(_vm._s(_vm._f("text_formatter")(val.title ? val.title : val.name)))])])],1),(val.sub && val.sub.length > 0)?_c('div',{staticClass:"truncate mt-2"},[_c('h6',{staticClass:"font-bold"},[_c('span',[_vm._v(_vm._s(_vm._f("text_formatter")(val.title ? val.title : val.name)))])])]):_vm._e()])])])],1)])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }