import vSelect from 'vue-select'
import Service from '../../services/unit-planner'
import {mapGetters} from 'vuex'

export default {
  name: 'teachersCollaborators',
  props: ['componentData'],
  components: {
    'v-select': vSelect
  },
  data () {
    return {
      restService: Service,
      searchTerm: '',
      teachers: [],
      selectedTeachers: [],
      usersToInvite: [],
      teacherGridSettings: {
        columnDefs: [
          {
            headerName: 'Image',
            field: 'image',
            width: 125,
            cellRenderer: 'cellFunction',
            renderActions: [
              {
                type: 'img',
                text: '',
                renderFieldValue: false,
                classList: ['grid-image'],
                attributes: [{key: 'src', value: '', appendField: true}]
              }
            ]
          },
          {headerName: 'Name', field: 'name'},
          {headerName: 'Email', field: 'email'},
          {headerName: 'Phone', field: 'phone'},
          {headerName: 'Role', field: 'role', width: 125},
          {
            headerName: 'Status',
            field: 'status',
            width: 175,
            renderActions: [
              {
                type: 'p',
                text: '',
                renderFieldValue: true,
                classList: [],
                classRole (data) {
                  switch (data) {
                  case 'Active':
                    return 'active'
                    break

                  case 'Suspended':
                    return 'suspended'
                    break
                  }
                },
                attributes: []
              }
            ],
            cellRenderer: 'cellFunction'
          },
          {
            headerName: 'Actions',
            field: 'status',
            cellRendererFramework: 'ActionsCellRender',
            width: 150
          }
          /*{
            headerName: "Actions",
            field: "status",
            renderActions: [
              {
                type: "span",
                text: "",
                renderFieldValue: false,
                hasEvent: {
                  eventType: "click",
                  eventName: "AddUnitResources",
                },
                classList: ["feather", "icon-file-plus", "pointer"],
                attributes: [
                  {key: "title", value: "Add Resources"},
                  {key: "style", value: "font-size:20px; color:rgba(var(--vs-primary),1);"},
                ]
              },
              {
                type: "span",
                text: "",
                renderFieldValue: false,
                hasEvent: {
                  eventType: "click",
                  eventName: "ArchiveUnit",
                },
                classList: ["feather", "icon-archive", "pointer"],
                attributes: [
                  {key: "title", value: "Archive Unit"},
                  {key: "style", value: "font-size:20px; color:rgba(var(--vs-primary),1);"},
                ]
              },
            ],
            cellRenderer: "cellFunction"
          },*/
        ],
        selectedColumns: null,
        rowData: [],
        compactViewColumns: ['name', 'email', 'role', 'status'],
        standardViewColumns: ['image', 'name', 'role', 'status'],
        height: '335px',
        width: '100%'
      },
      showGrid: true,
      dataList: [],
      customEvents: [
        {eventName: 'teacherSuspend', callback: this.suspendTeacher},
        {eventName: 'teacherActivation', callback: this.activateTeacher},
        {eventName: 'teacherAsEditor', callback: this.setTeacherAsEditor},
        {eventName: 'teacherAsAdmin', callback: this.setTeacherAsAdmin}
      ]
    }
  },
  created () {
    ////////////////////////////////////////////////////
    //**          Custom Event Listener           ** //
    //////////////////////////////////////////////////
    this.customEvents.forEach(function (customEvent) {
      // eslint-disable-next-line no-undef
      $sharedEvent.listen(customEvent.eventName, customEvent.callback)
    })

    Service.getUsersToInvite(this.currentUnit.grade.id).then(res => {
      res.data.forEach((value, index) => {
        value.forEach((inner, ind) => {
          this.usersToInvite.push(inner.id)
        })
      });
    })
    this.teachersSearch = _.debounce(this.search, 700)

    this.showActionsBasedOnRole()

    this.mappingGridData()
  },
  mounted () {
    console.log('this.currentUnit', this.currentUnit)
  },
  destroyed () {
    ////////////////////////////////////////////////////
    //**      Remove Custom Event Listener        ** //
    //////////////////////////////////////////////////
    this.customEvents.forEach(function (customEvent) {
      // eslint-disable-next-line no-undef
      $sharedEvent.off(customEvent.eventName, customEvent.callback)
    })
  },
  methods: {
    async search () {
      if (this.searchTerm) {
        //** Show Loader **//
        this.$vs.loading()
        await this.restService.teacherSearch(this.currentUnit.id, this.searchTerm)
          .then(res => {
            this.teachers = res.data
          })
          .catch(err => {
            console.error(err)
            //** Hide Loader **//
            this.$vs.loading.close()

            this.$vs.notify({
              title: 'Loading Data Error',
              text: err.data.message,
              color: 'danger',
              position: 'bottom-right'
            })
          })
        //** Hide Loader **//
        this.$vs.loading.close()
      } else {
        this.resetSearch()
      }
    },
    resetSearch () {
      this.searchTerm = ''
      this.teachers.splice(0, this.teachers.length)
      this.selectedTeachers = []
    },
    async addTeachers () {
      /* debugger */
      //** Show Loader **//
      this.$vs.loading()
      await this.restService.addTeachersToUnit(this.currentUnit.id, {user_ids: this.selectedTeachers})
        .then(res => {
          /* debugger */
          this.updateTeachersGrid(res)
          this.$vs.notify({
            title: 'Saved Successfully',
            text: 'Teachers Added',
            color: 'success',
            position: 'bottom-right'
          })
        })
        .catch(err => {
          console.error(err)
          //** Hide Loader **//
          this.$vs.loading.close()

          this.$vs.notify({
            title: 'Teachers Error',
            text: err.data.message,
            color: 'danger',
            position: 'bottom-right'
          })
        })
      //** Hide Loader **//
      this.$vs.loading.close()
    },
    async addInvitations () {
      /* debugger */
      //** Show Loader **//
      this.$vs.loading()
      await this.restService.addTeachersToUnit(this.currentUnit.id, {user_ids: this.usersToInvite})
        .then(res => {
          this.$vs.notify({
            title: 'Saved Successfully',
            text: 'Invited Successfully',
            color: 'success',
            position: 'bottom-right'
          })
        })
        .catch(err => {
          //** Hide Loader **//
          this.$vs.loading.close()

          this.$vs.notify({
            title: 'Teachers Error',
            text: err.data.message,
            color: 'danger',
            position: 'bottom-right'
          })
        }).finally(() => {
          this.$vs.loading.close()
        })
      //** Hide Loader **//
      this.$vs.loading.close()
    },

    updateTeachersGrid (data) {
      this.resetSearch()
      this.mappingGridData(data)
    },
    mappingGridData (dataList) {
      //** clear current Data **//
      //this.teacherGridSettings.rowData.splice(0, this.teacherGridSettings.rowData.length)

      dataList = (dataList && dataList.length) ? dataList : this.cloneItem(this.componentData.data.contents.data)
      dataList.forEach(item => {
        const obj = {
          id:item.id,
          image: require('@/assets/images/avatar.png'),
          name: item.user.name,
          email: item.user.email,
          phone: item.user.phone,
          status: (item.status.name === 'Inactive') ? 'Suspended' : item.status.name,
          role: item.role.name
        }

        this.teacherGridSettings.rowData.unshift(obj)
      })
    },
    updateGridData (item) {
      const itemIndex = this.teacherGridSettings.rowData.findIndex(obj => obj.id === item.id)
      this.teacherGridSettings.rowData.splice(itemIndex, 1, item)
    },
    activateTeacher (data) {
      //** Show Loader **//
      this.$vs.loading()
      this.restService.changeTeacherStatus(data.id, {status:'active'})
        .then(() => {
          data.status = 'Active'
          this.updateGridData(data)
          //** Hide Loader **//
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Saved Successfully',
            text: 'Teacher Activated',
            color: 'success',
            position: 'bottom-right'
          })
        })
        .catch(err => {
          console.error(err)
          //** Hide Loader **//
          this.$vs.loading.close()

          this.$vs.notify({
            title: 'Teachers Error',
            text: err.data.message,
            color: 'danger',
            position: 'bottom-right'
          })
        })

    },
    suspendTeacher (data) {
      //** Show Loader **//
      this.$vs.loading()
      this.restService.changeTeacherStatus(data.id, {status:'inactive'})
        .then(() => {
          data.status = 'Suspended'
          this.updateGridData(data)
          //** Hide Loader **//
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Saved Successfully',
            text: 'Teacher Suspended',
            color: 'success',
            position: 'bottom-right'
          })
        })
        .catch(err => {
          console.error(err)
          //** Hide Loader **//
          this.$vs.loading.close()

          this.$vs.notify({
            title: 'Teachers Error',
            text: err.data.message,
            color: 'danger',
            position: 'bottom-right'
          })
        })
    },
    setTeacherAsEditor (data) {
      //** Show Loader **//
      this.$vs.loading()
      this.restService.changeTeacherRole(data.id, {role:'editor'})
        .then(() => {
          data.role = 'Editor'
          this.updateGridData(data)
          //** Hide Loader **//
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Saved Successfully',
            text: 'Teacher Role Changed',
            color: 'success',
            position: 'bottom-right'
          })
        })
        .catch(err => {
          console.error(err)
          //** Hide Loader **//
          this.$vs.loading.close()

          this.$vs.notify({
            title: 'Teachers Error',
            text: err.data.message,
            color: 'danger',
            position: 'bottom-right'
          })
        })
    },
    setTeacherAsAdmin (data) {
      //** Show Loader **//
      this.$vs.loading()
      this.restService.changeTeacherRole(data.id, {role:'admin'})
        .then(() => {
          data.role = 'Admin'
          this.updateGridData(data)
          //** Hide Loader **//
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Saved Successfully',
            text: 'Teacher Role Changed',
            color: 'success',
            position: 'bottom-right'
          })
        })
        .catch(err => {
          console.error(err)
          //** Hide Loader **//
          this.$vs.loading.close()

          this.$vs.notify({
            title: 'Teachers Error',
            text: err.data.message,
            color: 'danger',
            position: 'bottom-right'
          })
        })
    },
    showActionsBasedOnRole () {
      const currentRole = this.componentData.data.authUserRole.key
      const index = this.teacherGridSettings.columnDefs.findIndex(item => item.headerName === 'Actions')
      if (currentRole === 'editor' && index !== -1) {
        this.teacherGridSettings.columnDefs.splice(index, 1)
      }
    }
  },
  computed: {
    ...mapGetters({currentUnit: 'getCreatedUnit'})
  }
}
