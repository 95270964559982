<template >
  <div>
    <vs-card class="p-3 pt-6 pb-6 md:p-6">
      <div class="vx-row mb-6 w-full items-center">
        <div class="vx-col sm:w-1/3 w-full">
          <label
            >{{ $t("division.Name") }}
            <sup class="text-danger">*</sup>
          </label>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            :placeholder="`${$t('division.Name')}`"
            v-validate="'required'"
            name="Name"
            v-model="section.en.long_name"
          />
          <span
            v-if="issueSubmitted && errors.has('Name')"
            class="text-danger text-sm"
            >{{ errors.first("Name") | text_formatter }}</span
          >
        </div>
      </div>
      <!---------------------------------------------------------------------------------->
      <div class="vx-row mb-6 w-full items-center">
        <div class="vx-col sm:w-1/3 w-full">
          <label>
            {{ $t("division.Director") }}
            <sup class="text-danger">*</sup>
          </label>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <v-select
            :placeholder="`${$t('division.Director')}`"
            v-model="section.director_id"
            name="director"
            v-validate="'required'"
            :disabled="!users.length > 0"
            class="w-full"
            :options="users"
            label="name"
            :reduce="(user) => user.id"
          />
          <span
            v-if="issueSubmitted && errors.has('director')"
            class="text-danger text-sm"
            >{{ errors.first("director") | text_formatter }}</span
          >
        </div>
      </div>
      <!------------------------------------------------------------------------>

      <div class="vx-row mb-6 w-full items-center">
        <div class="vx-col sm:w-1/3 w-full">
          <label
            >{{ $t("division.grades") }}
            <sup class="text-danger">*</sup>
          </label>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <v-select
            :placeholder="`${$t('division.grades')}`"
            v-model="sectionGrade.grade"
            multiple
            name="vgrade"
            v-validate="'required'"
            :disabled="!grades.length > 0"
            class="w-full"
            :options="grades"
            label="name"
            :reduce="(grade) => grade.id"
          />
          <span
            v-if="issueSubmitted && errors.has('vgrade')"
            class="text-danger text-sm"
            >{{ errors.first("vgrade") | text_formatter }}</span
          >
        </div>
      </div>
      <!----------------------------------------------------------------------------->
      <div class="vx-row">
        <div class="vx-col w-full pt-4 flex justify-between">
          <vs-button
            color="warning"
            type="border"
            class="mb-2"
            @click="back()"
            >{{ $t("InstitutionDetails.Cancel") }}</vs-button
          >
          <vs-button @click.stop="submitData()">{{
            $t("InstitutionDetails.Save")
          }}</vs-button>
        </div>
      </div>
    </vs-card>
  </div>
</template>
<script>
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const DivisionRepository = RepositoryFactory.get(
  'ConfigurationModule',
  'division'
)

export default {
  name: 'Create',
  data () {
    return {
      users:[],

      locales: this.$store.getters['main/locales'],
      issueSubmitted: false,
      grades: [],
      id: '',
      section: {
        en: {
          long_name: ''
        },
        division_id: null,
        director_id:null
      },
      name: '',
      sectionGrade: {
        grade: [],
        section_id: null
      },

      gradeData: { division: null }
    }
  },
  methods: {
    getUsers () {
      DivisionRepository.getUsers().then((res) => {
        this.users = res.users
      })
    },
    submitData () {
      this.issueSubmitted = true
      this.$validator.validate().then((valid) => {
        if (valid) {
          DivisionRepository.EditSection(this.section, this.section.id).then((response) => {
            this.sectionGrade.section_id = response.data.id
            this.addSectionGrade(this.sectionGrade)
            this.$router.push({ name: 'SectionDetails' })
            this.successMsg(this.$t('InstitutionDetails.the_section_has_been_edited_successfully'))

          })
        }
      })
    },
    getGrades (division_id, section_id) {
      DivisionRepository.getGradesInDivisionSection(division_id, section_id).then((res) => {
        this.grades = res.data
      })
    },
    addSectionGrade (sectionGrade) {
      DivisionRepository.addSectionGrade(sectionGrade).then(
        (response) => {
        }
      )
    },
    getsectionData (id) {
      DivisionRepository.GetSection(id).then(res => {
        this.section = res.data
        this.sectionGrade.grade = res.data.grades_ids
        this.sectionGrade.section_id = res.data.id
        this.getGrades(res.data.division_id, id)
      })
    },
    back () {
      this.$router.back()
    }
  },

  created () {
    this.id = this.$route.params.id
    this.getsectionData(this.id)
    this.section.division_id = this.section.division_id
    this.gradeData.division_id = this.section.division_id
    this.getUsers()
  }
}
</script>
<style  scoped>
a {
  cursor: pointer;
}
.background-none {
  background-color: transparent !important;
}
</style>
