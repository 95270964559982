<template>
  <createSmd></createSmd>
</template>
<script>
import createSmd from '../../smd/pages/Create.vue'
// APIs
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const  addTeacherRepo = RepositoryFactory.get('teachersGatewayModule', 'addTeacher')

export default {
  name:' create snd',
  components:{createSmd},
  methods:{
    createTeacher () {
      addTeacherRepo.createTeacher()
    }
  },
  created () {
    this.createTeacher()
  }
}
</script>
