<!-- ------------------------------------------------------------------------------------------
  File Name: TheNavbar.vue
  Description: Navbar component
  Component Name: TheNavbar
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
------------------------------------------------------------------------------------------= -->

<template>
  <div class="relative">
    <div class="vx-navbar-wrapper" :class="classObj">
      <vs-navbar
        class="vx-navbar navbar-custom navbar-skelton"
        :color="navbarColorLocal"
        :class="textColor">
        <!-- SM - OPEN SIDEBAR BUTTON -->
        <feather-icon
          class="inline-flex cuursor-pointer p-2"
          v-if="windowWidth < 1200 || hideVerticalMenu"
          icon="MenuIcon"
          @click.stop="showSidebar" />

        <!-- <bookmarks :navbarColor="navbarColor" v-if="windowWidth >= 992" /> -->

        <profile-drop-down />

        <vs-spacer />

        <!--<i18n />-->
        <!-- Custom Roles Routes  -->
        <template>
          <!-- calendar  -->
          <vx-tooltip
            :text="$t('calendar.calendar')"
            position="top"
            class="mx-2"
            v-if="showCalendar">
            <feather-icon
              icon="CalendarIcon"
              @click="$router.push('/calendar')"
              svgClasses="h-6 w-6 hover:text-primary cursor-pointer p-0" />
          </vx-tooltip>

          <!-- Chat -->
          <vx-tooltip
            :text="$t('chat.chat')"
            position="bottom"
            class="mx-2"
            v-if="showChat">
            <feather-icon
              icon="MessageSquareIcon"
              @click="$router.push('/chat')"
              :badge="haveChatNotification"
              svgClasses="h-6 w-6 hover:text-primary cursor-pointer p-0" />
          </vx-tooltip>
          <!-- Notification Dropdown -->
          <notification-drop-down v-if="showNotifications" />

          <!-- announcement Dropdown -->
          <announcement-drop-down v-if="showNotifications" />
          <!-- Authuser  -->
          <template v-if="is_authUser">
            <!-- Logout -->
            <vx-tooltip
              :text="$t('basic.logout')"
              position="bottom"
              v-if="!showUserSettings">
              <feather-icon
                icon="LogOutIcon"
                @click="logout"
                svgClasses="h-5 w-5 hover:text-danger cursor-pointer" />
            </vx-tooltip>

            <!--  Settings -->
            <vs-dropdown vs-trigger-click class="ml-auto cursor-pointer" v-else>
              <feather-icon
                icon="SettingsIcon"
                svgClasses="h-5 w-5 hover:text-danger cursor-pointer ml-1" />
              <vs-dropdown-menu class="w-32">
                <vs-dropdown-item>
                  <div @click="changePhoto()" class="flex items-center">
                    <feather-icon
                      icon="ImageIcon"
                      class="inline-block mr-2"
                      svgClasses="w-4 h-4" />
                    <span>{{ $t('profile.change_photo') }}</span>
                  </div>
                </vs-dropdown-item>
                <vs-dropdown-item>
                  <div @click="changePassword()" class="flex items-center">
                    <feather-icon
                      icon="Edit3Icon"
                      class="inline-block mr-2"
                      svgClasses="w-4 h-4" />
                    <span>{{ $t('profile.change_password') }}</span>
                  </div>
                </vs-dropdown-item>
                <vs-dropdown-item divider />
                <vs-dropdown-item>
                  <div @click="logout()" class="flex items-center">
                    <feather-icon
                      icon="LogOutIcon"
                      class="inline-block mr-2"
                      svgClasses="w-4 h-4" />
                    <span>{{ $t('basic.logout') }}</span>
                  </div>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>
        </template>
      </vs-navbar>
      <!-- Act As Alert -->
      <vs-alert
        :active="showNavbarAlert"
        class="w-auto vx-navbar-alert"
        icon-pack="feather"
        icon="icon-video"
        color="dark"
        v-if="showNavbarAlert">
        <div class="flex justify-between md:flex-row flex-col items-center">
          <span>
            {{
              `${this.actAsOriginalAuthUser.authUser.displayFirstAndSecondName}
        : ${this.$t('basic.you_are_acting_as')}
       `
            }}
            <b>{{
              `${authUser.role_display_name} -  ${authUser.displayFirstAndSecondName}`
            }}</b>

            {{ ` ${this.$t('basic.now')}` }}</span
          >
          <vs-button
            @click="endActAs"
            class="py-2 px-3"
            color="dark"
            type="flat"
            >{{ $t('basic.exit_act_as') }}
          </vs-button>
        </div>
      </vs-alert>
      <!-- user change password -->

      <vs-prompt
        @close="closeChangePasswordDialog"
        @accept="acceptPasswordDialog"
        :is-valid="validateChangePasswordForm"
        :active.sync="showChangePasswordDialog"
        :title="$t('profile.change_password')"
        type="confirm"
        :buttons-hidden="true">
        <div>
          <!-- Loader -->
          <div
            v-show="loadingDialog"
            ref="DialogLoader"
            id="DialogLoader"
            class="vs-con-loading__container"></div>
          <!-- Current pasword -->
          <div class="mb-8">
            <label class="w-full block text-sm">{{
              $t('main.current_password')
            }}</label>
            <vx-input-group class="w-full">
              <vs-input
                v-validate="'required'"
                :type="showPassword ? 'text' : 'password'"
                name="current_password"
                icon="icon-lock"
                icon-pack="feather"
                v-model="currentPassword" />

              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button
                    color="primary"
                    type="border"
                    @click="showPassword = !showPassword"
                    icon="icon-eye"
                    icon-pack="feather"></vs-button>
                </div>
              </template>
            </vx-input-group>
            <span class="text-danger text-sm w-full">{{
              errors.first('current_password') | text_formatter
            }}</span>
          </div>
          <!-- Generate password -->
          <vs-button
            type="border"
            class="text-sm mb-8"
            @click="SetGenerateedPassword">
            {{ $t('form.generate_password') }}
          </vs-button>
          <!-- new password -->
          <div class="mb-8">
            <label class="w-full block text-sm">{{
              $t('main.new_password')
            }}</label>
            <vx-input-group class="w-full">
              <vs-input
                v-validate="'required|min:6'"
                :type="showPassword ? 'text' : 'password'"
                name="password"
                icon="icon-lock"
                icon-pack="feather"
                v-model="newPassword"
                ref="password" />

              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button
                    color="primary"
                    type="border"
                    @click="showPassword = !showPassword"
                    icon="icon-eye"
                    icon-pack="feather"></vs-button>
                </div>
              </template>
            </vx-input-group>
            <span class="text-danger text-sm w-full">{{
              errors.first('password') | text_formatter
            }}</span>
          </div>
          <!-- / new  Pass -->
          <!-- Confirm Pass -->
          <div class="mb-8">
            <label class="w-full block text-sm">{{
              $t('main.confirm_passowrd')
            }}</label>
            <vx-input-group class="w-full">
              <vs-input
                v-validate="'required|confirmed:password'"
                :type="showPassword ? 'text' : 'password'"
                name="confirmPassword"
                icon="icon-lock"
                icon-pack="feather"
                v-model="confirmPassword" />

              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button
                    color="primary"
                    type="border"
                    @click="showPassword = !showPassword"
                    icon="icon-eye"
                    icon-pack="feather"></vs-button>
                </div>
              </template>
            </vx-input-group>
            <span
              class="text-danger text-sm w-full"
              v-show="errors.has('confirmPassword')"
              >{{ $t('main.password_confirmation_does_not_match') }}</span
            >
          </div>
          <!-- /Confirm Pass -->

          <!-- Btns -->
          <div class="w-full flex justify-between">
            <vs-button
              class="mt-2"
              type="border"
              color="warning"
              @click="closeChangePasswordDialog"
              >{{ $t('basic.cancel') }}
            </vs-button>
            <vs-button
              class="mt-2 mx-2"
              @click="acceptPasswordDialog"
              :disabled="!validateChangePasswordForm"
              >{{ $t('basic.accept') }}
            </vs-button>
          </div>
        </div>
      </vs-prompt>

      <!-- user change Photo -->
      <vs-prompt
        @close="closeChangePhotoDialog"
        @accept="acceptPhotoDialog"
        :active.sync="showChangePhotoDialog"
        :title="$t('profile.change_profile_photo')"
        type="confirm"
        :buttons-hidden="true">
        <div>
          <!-- Loader -->
          <div
            v-show="loadingDialog"
            ref="DialogLoader"
            id="DialogLoader"
            class="vs-con-loading__container"></div>
          <div
            class="upload-image-container mb-8 border-2 rounded bg-gray"
            @click="uploadImage">
            <input
              type="file"
              ref="userPhotoRef"
              @change="selectImage"
              accept="image/*;capture=camera"
              hidden />
            <vs-button
              class="actionsButtons"
              @click="removeSelectedImage"
              v-if="imageFile && imageLink"
              radius
              color="danger"
              type="flat"
              icon-pack="feather"
              icon="icon-x">
            </vs-button>
            <p class="text-center" v-if="!imageLink">
              <feather-icon
                icon="UploadIcon"
                class="inline-block mr-2"
                svgClasses="w-4 h-4" />
              <span>{{ $t('profile.upload_photo') }}</span>
            </p>

            <img
              :src="imageLink"
              class="m-auto h-full w-full"
              :alt="`${$t('profile.your_photo')}`"
              v-else />
          </div>

          <!-- Btns -->
          <div class="w-full flex justify-between">
            <vs-button
              class="mt-2"
              type="border"
              color="warning"
              @click="closeChangePhotoDialog"
              >{{ $t('basic.cancel') }}
            </vs-button>
            <vs-button
              class="mt-2 mx-2"
              @click="acceptPhotoDialog"
              :disabled="!validationChangePhotoForm"
              >{{ $t('basic.accept') }}
            </vs-button>
          </div>
        </div>
      </vs-prompt>
    </div>
  </div>
</template>

<script>
import Bookmarks from './components/Bookmarks.vue'
import I18n from './components/I18n.vue'
import SearchBar from './components/SearchBar.vue'
import NotificationDropDown from './components/NotificationDropDown.vue'
import announcementDropDown from './components/announcementDropDown.vue'
import ProfileDropDown from './components/ProfileDropDown.vue'
import axios from 'axios'
import router from '@/router'
import {authUserMixin, checkValidationMixin} from '@/mixins.js'

// API
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const ProfileRepo = RepositoryFactory.get('AuthRepository', 'Profile')

export default {
  name: 'the-navbar-vertical',
  props: {
    navbarColor: {
      type: String,
      default: '#fff'
    }
  },
  mixins: [authUserMixin, checkValidationMixin],
  components: {
    announcementDropDown,
    Bookmarks,
    I18n,
    SearchBar,
    NotificationDropDown,
    ProfileDropDown
  },
  data() {
    return {
      haveChatNotification: false,
      loadingDialog: false,
      // Chnage password
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      showPassword: false,
      showChangePasswordDialog: false,
      // change photo
      imageFile: null,
      imageLink: null,

      showChangePhotoDialog: false
    }
  },
  computed: {
    navbarColorLocal() {
      return this.$store.state.theme === 'dark' && this.navbarColor === '#fff'
        ? '#10163a'
        : this.navbarColor
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth
    },
    textColor() {
      return {
        'text-white':
          (this.navbarColor !== '#10163a' &&
            this.$store.state.theme === 'dark') ||
          (this.navbarColor !== '#fff' && this.$store.state.theme !== 'dark')
      }
    },
    windowWidth() {
      return this.$store.state.windowWidth
    },
    hideVerticalMenu() {
      return this.$route.meta.hideVerticalMenu || false
    },
    // NAVBAR STYLE
    classObj() {
      if (this.verticalNavMenuWidth === 'default') return 'navbar-default'
      else if (this.verticalNavMenuWidth === 'reduced') return 'navbar-reduced'
      else if (this.verticalNavMenuWidth === 'full') return 'w-full'
      else if (this.verticalNavMenuWidth) return 'navbar-full'
    },

    isRouteChat() {
      return this.$route.name === 'chat'
    },
    isRouteCalendar() {
      return this.$route.name === 'calendar'
    },

    actAsOriginalAuthUser() {
      return this.$store.getters['auth/actAsOriginalAuthUser'] || null
    },

    isVerticalNavBarActive() {
      return this.$store.state.isVerticalNavBarActive
    },
    validateChangePasswordForm() {
      return (
        !this.errors.any() &&
        this.currentPassword &&
        this.newPassword &&
        this.confirmPassword &&
        this.newPassword === this.confirmPassword
      )
    },
    validationChangePhotoForm() {
      return this.imageFile && this.imageLink
    },
    // Showing
    showUserSettings() {
      return (
        this.isVerticalNavBarActive &&
        (this.checkUserRole('Student') ||
          this.checkUserRole('Parent') ||
          this.checkUserRole('Teacher'))
      )
    },
    showNavbarAlert() {
      return this.isActAsMode || this.actAsOriginalAuthUser
    },
    showCalendar() {
      return (
        !this.isRouteCalendar &&
        (this.checkUserRole('Teacher') || this.checkUserRole('Student'))
      )
    },
    showChat() {
      return !this.isRouteChat && !this.is_SuspendedAuthUser
    },
    showNotifications() {
      return (
        this.isVerticalNavBarActive &&
        (this.$acl.check('Student') ||
          this.$acl.check('Teacher') ||
          this.$acl.check('Parent'))
      )
    }
  },
  methods: {
    showSidebar() {
      this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)
    },
    logout() {
      // Apply log out feature.
      if (this.is_authUser) {
        this.$acl.change('visitor')
        this.$store.commit('auth/clearAuthData', {})
        router.push({name: 'login'})
      }
    },
    //////////////////////
    // chat notification
    /////////////////////
    getChatGlobalNotifications() {
      axios.get('/chatting/check-notification').then((res) => {
        this.haveChatNotification = res.data
      })
    },
    launchChatRequest() {
      if (this.showChat) this.getChatGlobalNotifications()
    },
    /////////////////////////
    // User Settings
    ////////////////////////
    // loading
    async startLoadingdialog() {
      await (this.loadingDialog = true)
      this.VsLoadingContained(this.$refs.DialogLoader)
    },
    endLoadingdialog() {
      this.stopVsLoadingContained(this.$refs.DialogLoader)
      this.loadingDialog = false
    },

    // ---------- Change Password
    changePassword() {
      this.showChangePasswordDialog = true
    },
    SetGenerateedPassword() {
      this.newPassword = this.confirmPassword = this.generateRandomPassword()
    },
    resetChangePassword() {
      this.newPassword = this.confirmPassword = ''
    },
    closeChangePasswordDialog() {
      this.resetChangePassword()
      this.showChangePasswordDialog = false
    },
    acceptPasswordDialog() {
      this.startLoadingdialog()
      const payload = {
        current_password: this.currentPassword,
        new_password: this.newPassword
      }
      ProfileRepo.updateProfile(payload)
        .then((res) => {
          this.closeChangePasswordDialog()
          this.successMsg(this.$t('form.saved_successfully'))
        })
        .catch((err) => {
          this.errMsg(err)
          this.showChangePasswordDialog = true
        })
        .finally(this.endLoadingdialog)
    },
    // --------------Change Photo

    changePhoto() {
      this.showChangePhotoDialog = true
    },
    selectImage(e) {
      const file = e.target.files[0]
      this.checkImageValid(file).then((res) => {
        this.imageFile = file
        this.imageLink = URL.createObjectURL(file)
      })
    },
    removeSelectedImage() {
      this.imageFile = this.imageLink = null
    },
    uploadImage() {
      this.$refs.userPhotoRef.click()
    },
    acceptPhotoDialog() {
      this.startLoadingdialog()
      const payload = new FormData()
      payload.append('image', this.imageFile)
      payload.append('isUpdated', 1)

      ProfileRepo.updateProfile(payload)
        .then((res) => {
          this.successMsg(this.$t('form.saved_successfully'))
          this.$store.commit('auth/updateUserProfilePhoto', res.data.image)
          this.closeChangePhotoDialog()
        })
        .catch((err) => {
          this.errMsg(err)
          this.showChangePhotoDialog = true
        })
        .finally(this.endLoadingdialog)
    },
    closeChangePhotoDialog() {
      this.removeSelectedImage()
      this.showChangePhotoDialog = false
    }
  },
  created() {
    this.launchChatRequest()
  },
  updated() {
    this.launchChatRequest()
  }
}
</script>
<style lang="scss">
.vx-navbar-wrapper {
  .con-vs-alert {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    height: max-content !important;
  }
}
.upload-image-container {
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  .actionsButtons {
    position: absolute;
    top: 0;
    right: 0;
  }
}
.navbar-floating .vx-navbar-wrapper.w-full {
  width: 100% !important;
}
</style>
