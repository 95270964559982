<template>
  <ListPageWithCreateButton :showCreateBtn="false">
    <template slot="listDataContent">
      <teacherSubjectsTable
        :headers_prop="headers"
        :list_url_prop="`teacher/subjects/in-syllabus/${$route.params.user_id}`">
      </teacherSubjectsTable
    ></template>
  </ListPageWithCreateButton>
</template>

<script>
import teacherSubjectsTable from '@/components/general/datatable/DataTable2.vue'

export default {
  name: 'teacherSubjects',
  components: {
    teacherSubjectsTable
  },

  data() {
    return {
      headers: [
        {
          minWidth: 150,
          headerName: this.$t('subject.Subjects'),
          field: 'grade_subject.subject_name'
        },
        {
          minWidth: 150,
          headerName: this.$t('basic.classes'),
          field: 'class'
        },
        {
          minWidth: 150,
          headerName: this.$t('Syllabus.terms'),
          field: 'term'
        }
      ],
      loading: false
    }
  },
  methods: {},
  watch: {},
  computed: {},
  beforeDestroy() {},
  created() {}
}
</script>

<style lang="scss" scoped>
.active .vx-card {
  -webkit-box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
  box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
}
</style>
