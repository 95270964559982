export default {
  Config: 'System Configuration',
  InstitutionDetails: 'Institution Details',
  InstitutionCreate: 'Institution Create',
  Roles: 'Roles',
  Admins: 'Admins',
  AddBranch: 'Add Branch',
  OfficialDaysAndVacations: 'Official Days And Vacations',
  Communications: 'Communications',
  DepartmentManager: 'Department Manager',
  FeesAndSalaries: 'Fees And Salaries',
  InstitutionShow: 'show Institution',
  InstitutionEdit: 'Edit Institution ',
  DivisionDetails: 'Divisions Details',
  DivisionCreate: 'Create Division',
  DivisionEdit: 'Division Edit',
  SectionCreate: 'Create Section',
  StudentProfile: 'Student Profile',
  student: 'Student',
  BranchDetails: 'Branch Details',
  BranchEdit: 'Edit Branch',
  Attendance: 'Attendance',
  AttendanceConsole: 'Attendance Console',
  Insights: 'Insights',
  Settings: 'Settings',
  roles: 'roles',
  userRoles: 'user Roles',
  UserManagement: 'User Management',
  createRole: 'create Role',
  editRole: 'edit role',
  SectionDetails: 'Sections Details ',
  DivisionName: 'Division Name',
  ShowSection: 'Show Section',
  SectionEdit: 'Edit Section',
  EditSection: 'Edit Section',
  role_name: 'Role name',
  name: 'Name',
  role: 'Role',
  status: 'Status',
  actions: 'Actions',
  users: 'Users',
  createUser: 'Create User',
  editUser: 'Edit User',
  school: 'School',
  selectSchool: 'Select School',
  branches: 'Branches',
  selectBranch: 'Select Branch',
  divisions: 'Divisions',
  selectDivision: 'Select Division',
  sections: 'Sections',
  selectSection: 'Select section',
  selectRole: 'Select role',
  type: 'Type',
  selected_type: 'Select Type',
  permissions: 'Permissions',
  firstName: 'First Name',
  lastName: 'Last Name',
  credentials: 'Credentials',
  gender: 'Gender',
  email: 'E-mail',
  phone: 'Phone',
  phone_key: 'Phone Key',
  selectSubjects: 'Select Subjects',
  subjects: 'Subjects',
  selectConsole: 'Select Console',
  console: 'Console',
  preview: 'Preview',
  user: 'user',
  assign_role_to_user:'assign role to user',
  POI: 'POI',
  parent: 'parent',
  profile:'profile'
}
