<template>
  <div>
    <vx-card class="p-3 pt-6 pb-6 md:p-6">
      <form @submit.prevent="addShare">
        <!-- Start Teacher -->
        <div class="vx-row mb-6">
          <div class="vx-col md:w-1/4 w-full">
            <label>
              {{ $t("basic.teacher") }}
            </label>
          </div>
          <div class="vx-col md:w-1/2 w-full">
            <v-select

              multiple
              :placeholder="`${$t('basic.teacher')}`"
              v-model="share_form.user_ids"
              class="w-full"
              :options="teachers"
              label="name"
              :reduce="(name) => name.id"
            />

          </div>
        </div>
        <!-- End Teacher -->

        <!-- Start Class -->
        <div class="vx-row mb-6">
          <div class="vx-col md:w-1/4 w-full">
            <label>
              {{ $t("basic.class") }}
            </label>
          </div>
          <div class="vx-col md:w-1/2 w-full">
            <v-select

              multiple
              :placeholder="`${$t('basic.class')}`"
              v-model="share_form.classes"
              class="w-full"
              :options="vSecltorClasses"
              label="name"
              :reduce="(name) => name.id"
            />

          </div>
        </div>
        <!-- End Class -->

        <!-- Actions -->
        <div class="flex flex-wrap justify-between items-center mt-10">
          <vs-button
            color="warning"
            type="border"
            class="inline-block"
            @click="back()"
            >{{ $t("form.cancel") }}</vs-button
          >
          <vs-button class="inline-block" @click="addShare()">
            {{ $t("form.submit") }}
          </vs-button>
        </div>
      </form>
    </vx-card>
  </div>
</template>

<script>
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'

const TeacherRepository = RepositoryFactory.get(
  'TeachersModule',
  'teaching_materials'
)

export default {

  mounted () {
    this.show()
  },
  data () {
    return {
      share_form: {
        user_ids: [],
        classes: []
      },
      teachersOfSections: [],
      classes: [],
      isUploader: '',
      selectedTeacher:[],
      selectedClasses:[]
    }
  },
  computed: {
    shareId () {
      return +this.$route.params.id
    },
    teachers () {
      if (this.isUploader === 'uploader') {
        return this.teachersOfSections
      } else {
        const selectedIds = this.selectedTeacher.map((teacher) => {
          return teacher.id
        })
        const difference = this.teachersOfSections.filter(
          (x) => !selectedIds.includes(x.id)
        )
        return difference
      }
    },

    vSecltorClasses () {
      if (this.isUploader === 'uploader') {
        return this.classes
      } else {
        const selectedIds = this.selectedClasses.map((classe) => {
          return classe.id
        })
        const difference = this.classes.filter(
          (x) => !selectedIds.includes(x.id)
        )
        return difference
      }
    },
    selectedTeacherWithID () {
      return this.selectedTeacher.map(item => item.id)
    },
    selectedClasseswithID () {
      return this.selectedClasses.map(item => item.id)
    }

  },
  methods: {
    show () {
      this.VSLoading()
      TeacherRepository.view(this.shareId).then((res) => {
        this.isUploader = res.data.auth_user
      }).then(() => {
        this.viewShare()
        this.teachersOfSection()
        this.classesOfTeacher()
      })
        .finally(() => {
          this.stopVSLoading()
        })
    },
    teachersOfSection () {
      TeacherRepository.teachersOfSection().then((res) => {
        this.teachersOfSections = res.data.data.filter(teacher => teacher.id !== this.authUser.uid)
      })
    },
    classesOfTeacher () {
      TeacherRepository.classesOfTeacher().then((res) => {
        this.classes = res.data
      })
    },

    addShare () {
      if (this.isUploader === 'uploader') {
        TeacherRepository.addShare(this.shareId, this.share_form).then((res) => {
          this.successMsg(res.message)
          this.$router.push({ name: 'TeachingMaterials' })
        })
      } else {
        const data = {
          user_ids: [... this.selectedTeacherWithID, ...this.share_form.user_ids],
          classes: [...this.selectedClasseswithID, ...this.share_form.classes]
        }
        TeacherRepository.addShare(this.shareId, data).then((res) => {
          this.successMsg(res.message)
          this.$router.push({ name: 'TeachingMaterials' })
        })
      }

    },

    viewShare () {
      TeacherRepository.viewShare(this.shareId).then((res) => {
        if (this.isUploader === 'uploader') {
          this.share_form.user_ids = res.teachers.filter((teacher) => { return teacher.id !== this.authUser.uid }).map(teacher =>  teacher.id)
          this.share_form.classes = res.classes.map(item =>  item.id)

        } else {
          this.share_form.user_ids = []
          this.selectedTeacher  = res.teachers
          this.share_form.classes = []
          this.selectedClasses = res.classes
        }

      })
    },

    back () {
      this.$router.back()
    }
  }
}
</script>
