<template>
  <div>
    <vx-card title="Score Manager" class="mb-5 pb-4">
      <div
        v-for="(criteria, index) in evaluation.criteria_ratings"
        class="vx-row mb-6"
        :key="index"
      >
        <div class="vx-col sm:w-1/3 w-full">
          <span>{{ criteria.score.name }}</span>
        </div>
        <div class="vx-col sm:w-1/3 w-full">
          <star-rating
            @rating-selected="
              $emit(
                'update:evaluation:criteria_ratings',
                evaluation.criteria_ratings
              )
            "
            v-model="criteria.score.rate"
            :rtl="$vs.rtl"
            :max-rating="10"
            :star-size="20"
          ></star-rating>
        </div>
      </div>
    </vx-card>
    <vx-card title>
      <div class="vx-row mb-6 mt-5">
        <div class="vx-col sm:w-1/3 w-full">
          <span>quick notes</span>
        </div>

        <div class="vx-col sm:w-2/3 w-full">
          <vx-input-group class="w-full">
            <vs-input
              @keyup="
                $emit(
                  'update:evaluation:evaluation_notes',
                  evaluation.evaluation_notes
                )
              "
              :placeholder="`${$t('main.note')}`"
              v-model="evaluation.evaluation_notes[userTypeIndex].notes[0]"
            ></vs-input>
            <!-- Add Another -->
            <template slot="append">
              <div class="append-text btn-addon">
                <vs-button
                  @click="addNote"
                  icon-pack="feather"
                  icon="icon-plus"
                  type="border"
                >
                </vs-button>
              </div>
            </template>
          </vx-input-group>
          <span class="text-danger" v-if="add_note_err"
            >add one before add another</span
          >
        </div>
      </div>

      <!--        added notes -->
      <div class="vx-row mb-6" :key="index" v-for="(note, index) in addedNotes">
        <div class="vx-col sm:w-1/3 w-full"></div>
        <div class="vx-col sm:w-2/3 w-full">
          <vx-input-group class="w-full">
            <vs-input
              @keyup="
                $emit(
                  'update:evaluation:evaluation_notes',
                  evaluation.evaluation_notes
                )
              "
              :placeholder="`${$t('main.note')}`"
              v-model="evaluation.evaluation_notes[userTypeIndex].notes[0]"
            ></vs-input>
            <!-- Add Another -->
            <template slot="append">
              <div class="append-text btn-addon">
                <vs-button
                  @click="removeNote(index)"
                  icon-pack="feather"
                  icon="icon-minus"
                  type="border"
                  color="danger"
                >
                </vs-button>
              </div>
            </template>
          </vx-input-group>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Entry Assessment</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-textarea
            rows="5"
            @keyup="
              $emit(
                'update:evaluation:evaluation_data',
                evaluation.evaluation_data
              )
            "
            v-model="evaluation.evaluation_data[userTypeIndex].entry_assessment"
            :rtl="$vs.rtl"
            :max-rating="10"
            :star-size="20"
          ></vs-textarea>
        </div>
      </div>

      <div v-if="auth_user_role !== 'registerer'" class="vx-row mb-6">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Observations</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-textarea
            rows="5"
            @keyup="
              $emit(
                'update:evaluation:evaluation_data',
                evaluation.evaluation_data
              )
            "
            v-model="evaluation.evaluation_data[userTypeIndex].observations"
            :rtl="$vs.rtl"
            :max-rating="10"
            :star-size="20"
          ></vs-textarea>
        </div>
      </div>

      <div v-if="auth_user_role !== 'registerer'" class="vx-row mb-6">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Reading</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-textarea
            rows="5"
            @keyup="
              $emit(
                'update:evaluation:evaluation_data',
                evaluation.evaluation_data
              )
            "
            v-model="evaluation.evaluation_data[userTypeIndex].reading"
            :rtl="$vs.rtl"
            :max-rating="10"
            :star-size="20"
          ></vs-textarea>
        </div>
      </div>

      <div v-if="auth_user_role !== 'registerer'" class="vx-row mb-6">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Writing</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <!--          TODO: change the binding to the right type of user from vuex auth user type-->
          <vs-textarea
            rows="5"
            @keyup="
              $emit(
                'update:evaluation:evaluation_data',
                evaluation.evaluation_data
              )
            "
            v-model="evaluation.evaluation_data[userTypeIndex].writing"
            :rtl="$vs.rtl"
            :max-rating="10"
            :star-size="20"
          ></vs-textarea>
        </div>
      </div>

      <div v-if="auth_user_role !== 'registerer'" class="vx-row mb-6">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Oral Fluency</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-textarea
            rows="5"
            @keyup="
              $emit(
                'update:evaluation:evaluation_data',
                evaluation.evaluation_data
              )
            "
            v-model="evaluation.evaluation_data[userTypeIndex].oral_fluency"
            :rtl="$vs.rtl"
            :max-rating="10"
            :star-size="20"
          ></vs-textarea>
        </div>
      </div>
      <div
        v-if="auth_user_role == 'principal' || auth_user_role == 'director'"
        class="vx-row mb-6"
      >
        <div class="vx-col sm:w-1/3 w-full">
          <span>Principle Review</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-textarea
            rows="5"
            @keyup="
              $emit(
                'update:evaluation:evaluation_data',
                evaluation.evaluation_data
              )
            "
            v-model="evaluation.evaluation_data[userTypeIndex].principal_review"
            :rtl="$vs.rtl"
            :max-rating="10"
            :star-size="20"
          ></vs-textarea>
        </div>
      </div>

      <div v-if="auth_user_role == 'director'" class="vx-row mb-6">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Director Review</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-textarea
            rows="5"
            @keyup="
              $emit(
                'update:evaluation:evaluation_data',
                evaluation.evaluation_data
              )
            "
            v-model="evaluation.evaluation_data[userTypeIndex].director_review"
            :rtl="$vs.rtl"
            :max-rating="10"
            :star-size="20"
          ></vs-textarea>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col sm:w-2/3 w-full ml-auto">
          <vs-button class="mr-3 mb-2" @click="$emit('saveEvaluation')">{{
            $t("form.submit")
          }}</vs-button>
          <vs-button
            color="warning"
            type="border"
            class="mb-2"
            :to="{ name: 'applications-list' }"
            >{{ $t("basic.cancel") }}</vs-button
          >
        </div>
      </div>
      <!-- CODE -->
    </vx-card>
  </div>
</template>
<script>

import StarRating from 'vue-star-rating'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const EvaluationRepository = RepositoryFactory.get('AdmissionModule', 'evaluations')

export default {
  components:{
    StarRating
  },
  props:{

    issueSubmitted:{
      type:Boolean
    },
    // evaluation_prop:{
    //   type:Object,
    //   required:true
    // },
    type:{
      type:String
    },
    app_id:{
      type:Number
    }

  },
  data () {
    return {
      auth_user_role: this.$store.getters['auth/authUser'].userRole,
      user_roles: ['registerer', 'assessor', 'principal', 'director', 'SMD'],

      evaluation: {
        criteria_ratings: [],
        application: {
          status: 'On Assessment',
          id: '1',
          meeting_timestamp: '',
          type: '',
          gate: '',
          building: ''
        },
        score: '0',
        evaluation_notes: [
          {
            user_type: 'SMD',
            notes: ['']
          },
          {
            user_type: 'registerer',
            notes: ['']
          },
          {
            user_type: 'assessor',
            notes: ['']
          },
          {
            user_type: 'principal',
            notes: ['']
          },
          {
            user_type: 'director',
            notes: ['']
          }
        ],

        evaluation_data: [
          {
            user_type: 'SMD',
            entry_assessment: ''
          },
          {
            user_type: 'registerer',
            entry_assessment: ''
          },
          {
            user_type: 'assessor',
            entry_assessment: '',
            observations: '',
            reading: '',
            writing: '',
            oral_fluency: ''
          },
          {
            user_type: 'principal',
            entry_assessment: '',
            observations: '',
            reading: '',
            writing: '',
            oral_fluency: '',
            principal_review: ''
          },
          {
            user_type: 'director',
            entry_assessment: '',
            observations: '',
            reading: '',
            writing: '',
            oral_fluency: '',
            principal_review: '',
            director_review: ''
          }
        ]
      },
      add_note_err:false
    }
  },
  mounted () {

    this.getData(this.$route.params.id)


  },
  methods: {
    getData (id) {

      EvaluationRepository.getAppDetails(id).then(res => {
        if (this.evaluation.criteria_ratings.length == 0) {
          this.evaluation.criteria_ratings = res.data.application_score.filter(criteria => {
            criteria.score.rate = 0
            return criteria.score.status && criteria.score.point > 0
          })
        }
      })

    },
    removeNote (index) {
      this.evaluation.evaluation_notes[this.userTypeIndex].notes.splice(index + 1)
    },
    addNote () {
      if (this.evaluation.evaluation_notes[this.userTypeIndex].notes[0] !== '') {
        this.add_note_err = false
        this.evaluation.evaluation_notes[this.userTypeIndex].notes.push(this.evaluation.evaluation_notes[this.userTypeIndex].notes[0])
        this.evaluation.evaluation_notes[this.userTypeIndex].notes[0] = ''
      } else this.add_note_err = true
    }

  },
  computed : {
    addedNotes () {
      return this.evaluation.evaluation_notes[this.userTypeIndex].notes.length > 1 ? this.evaluation.evaluation_notes[this.userTypeIndex].notes.slice(1, this.evaluation.evaluation_notes[this.userTypeIndex].notes.length) : []
    },
    userTypeIndex () {
      return this.user_roles.indexOf(this.auth_user_role)
    },
    evalData () {

      return this.$attrs['evaluation:evaluation_data']
    },
    evalNotes () {
      return this.$attrs['evaluation:evaluation_notes']
    },
    evalCriterias () {
      return this.$attrs['evaluation:criteria_ratings']
    }
  },
  watch: {
    'evalData' (evaluation_data) {
      if (this.type == 'edit') this.evaluation.evaluation_data = evaluation_data
    },
    'evalNotes' (evaluation_notes) {
      if (this.type == 'edit') this.evaluation.evaluation_notes = evaluation_notes
    },
    'evalCriterias' (criteria) {
      if (this.type == 'edit') this.evaluation.criteria_ratings = criteria

    }
  }
}
</script>

<style scoped>
.background-none {
  background-color: transparent !important;
}
</style>
