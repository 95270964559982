import axios from 'axios'

const resource = '/teachers'
export default {

  getOnlineClasses (param, FilterParams) {
    return axios.get(`${resource}/onlineClasses?${param}`, {
      params:{
        ...FilterParams
      }
    })
  },
  getByFilter (filters) {
    return axios.post(`${resource}/onlineClasses/classess/subjects/filter`, filters)
  },
  cancelClass (id) {
    return axios.get(`${resource}/onlineClasses/status/${id}/Canceled`)
  },
  show (id) {
    return axios.get(`${resource}/onlineClasses/${id}`)
  },
  create (payload) {
    return axios.post(`${resource}/onlineClasses`, payload)
  },
  edit (id, payload) {
    return axios.put(`${resource}/onlineClasses/${id}`, payload)
  },
  cancel (id) {
    return axios.get(`${resource}/onlineClasses/status/${id}/canceled`)
  },
  suspend (id) {
    return axios.get(`${resource}/onlineClasses/status/${id}/suspend`)
  },
  archive (id) {
    return axios.get(`${resource}/onlineClasses/status/${id}/archived`)
  },
  active (id) {
    return axios.get(`${resource}/onlineClasses/status/${id}/active`)
  },
  delete (id) {
    return axios.get(`${resource}/onlineClasses/delete/${id}`)
  },
  createQuickMeeting () {
    return axios.post(`${resource}/quick-meetings`, {})
  },
  getQuickMeetings () {
    return axios.get(`${resource}/quick-meetings`)
  },
  deleteQuickMeeting (id) {
    return axios.get(`${resource}/quick-meetings/delete/${id}`)
  },
  counter () {
    return axios.get(`${resource}/onlineClasses/counter`)
  },
  check_start_end_time  (payload) {
    return axios.post(`${resource}/onlineClass/check-start-end-time`, payload)

  },
  dailyAgenda (param) {
    return axios.get(`${resource}/onlineClasses/daily-agenda`, {
      params:{
        ...param
      }
    })
  },
  // BBB

  getStartMeetingLink (classId) {
    return axios.get(`/bbb-integration/start-meeting/${classId}`)

  },
  getJoinMeetingLink (classId) {
    return axios.get(`/bbb-integration/join-meeting/${classId}`)

  },
  getOnlineClassRecords (classId) {
    return axios.get(`/bbb-integration/online-classes/${classId}/recordings`)
  }

}
