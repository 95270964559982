import axios from 'axios'

export default {
  getTeacherList (param) {
    return axios.get('Principal/teachers-details', {
      params: {
        ...param
      }
    })
  }
}
