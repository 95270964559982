import axios from 'axios'
const resource = '/attendance'

export default {
  getStatus () {
    return axios.get(`${resource}/status`)
  },

  downloadReport (date) {
    return axios.post(`${resource}/online-report`, {
      date
    })
  },
  exportReportCSV (filters, date) {
    return axios.post(`${resource}/export`, {
      ...filters,
      date
    })
  },

  getStudent (student_id, date) {
    return axios.post(`${resource}/student/${student_id}`, {
      date
    })
  },

  getStudentsUnpaginated (school_id, date, filters) {
    return axios.post(`${resource}/students/un-paginated`, {
      date,
      school_id,
      ...filters
    })
  },

  // Side Filters
  getAllDivisionsGrades () {
    return axios.get(`${resource}/get/divisions-grades`)
  },

  getAllSections () {
    return axios.get(`${resource}/get-sections`)
  },

  getTeacherClasses () {
    return axios.get('/teachers/classes')

  },

  getAllClasses () {
    return axios.get('/class')
  },
  getAllFilterClasses () {
    return axios.get('filter/active/classes')
  },
  getAllFilterActiveClasses () {
    return axios.get('filter/active/classes')
  },

  getUnfinishedClasses (school_id) {
    return axios.get(`/unfinished/classes/${school_id}`)
  },
  getFilteredStudents ({school_id, page, filters, date}) {
    return axios.post(`${resource}/v2/students-filter${page ? page : ''}`,
      {
        school_id,
        ...filters,
        date
      }
    )
  },
  getFilteredStudentsV1 ({school_id, page, filters, date}) {
    return axios.post(`${resource}/students-filter${page ? page : ''}`,
      {
        school_id,
        ...filters,
        date
      }
    )
  },
  closeAttendance (school_id) {
    return axios.post('/attendance-settings/close-attendence',
      {
        school_id
      }
    )
  },
  openAttendance (school_id) {
    return axios.post('attendance-settings/open-attendence',
      {
        school_id
      }
    )
  },
  checkCloseAttendance (school_id) {
    return axios.post('/attendance-settings/check-close-attendence',
      {
        school_id
      }
    )
  },
  getAttendanceByDate ({school_id, page, filters, date }) {
    return axios.post(`${resource}/students-filter${page ? page : ''}`,
      {
        school_id,
        ...filters,
        date
      }
    )
  },
  getClassStudents (className, date) {
    return axios.post(`${resource}/class/students`, {
      date,
      className
    })
  },
  changeAttendanceStatus (payload) {
    return axios.post(`${resource}/submit`, payload)
  }

}
