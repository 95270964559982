<template>
  <category-form @formSubmited = "editCategory" :ifEdit="true"/>
</template>

<script>
import categoryForm from '@/modules/canteen/pages/category/components/categoryForm'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const canteenRepository = RepositoryFactory.get(
  'canteenRepository',
  'canteenRepository'
)
export default {
  name: 'create',
  components:{
    categoryForm
  },
  data () {
    return {
    }
  },
  methods: {
    editCategory (name, canteenId, categoryId) {
      this.VSLoading()
      canteenRepository.editCategory(canteenId, categoryId, name).then(() => {
        this.successMsg(this.$t('canteen.editCategorySuccessfully'))
        this.$router.go(-1)
        this.stopVSLoading()
      }).catch((err) => {
        this.errMsg(err)
      })
    }
  }
}
</script>

<style scoped>

</style>
