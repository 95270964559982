import main from './main'
import admission from './admission'
import RoundManager from './RoundManager'
import config from './config'
import InstitutionDetails from './InstitutionDetail'
import basic from './basic'
import scoreManager from './scoreManager'
import division from './division'
import evaluation from './evaluation'
import profile from './profile'
import AcademicYears from './AcademicYears'
import subject from './subject'
import Syllabus from './syllabus'
import exam from './exam'
import assignment from './assignment'
import alerts from './alerts'
import classes from './classes'
import AppReg from './RegGeneralData'
import Attendance from './Attendance'
import onlineClass from './onlineClass'
import form from './form'
import cas from './cas'
import chat from './chat'
import calendar from './calendar'
import canteen from './canteen'
import inventory from './inventory'
import boundariesAndDescriptors from './boundariesAndDescriptors'



export default {
  main,
  admission,
  RoundManager,
  config,
  InstitutionDetails,
  scoreManager,
  basic,
  division,
  evaluation,
  profile,
  AcademicYears,
  subject,
  form,
  exam,
  assignment,
  alerts,
  classes,
  Syllabus,
  Attendance,
  onlineClass,
  cas,
  chat,
  calendar,
  canteen,
  inventory,
  boundariesAndDescriptors
}
