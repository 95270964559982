var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"justify-between flex w-full flex-wrap",class:{ 'lg:flex-no-wrap': _vm.status.length < 8 }},_vm._l((_vm.status),function(val,i){return _c('div',{key:val.id || i,staticClass:"mb-4 flex-1 counter_block"},[_c('button',{staticClass:"w-full no_style block pl-1 pr-1 h-full",class:{ active: (val.value || val.id) === _vm.selected },attrs:{"title":val.title ? val.title : val.name},on:{"click":function($event){return _vm.getFilter(val)}}},[_c('vx-card',{staticClass:"overflow-hidden small-card h-full"},[_c('template',{slot:"no-body"},[_c('div',{staticClass:"p-4 h-full",class:'flex justify-between flex-row-reverse '},[_c('feather-icon',{staticClass:"p-2 inline-flex rounded-full",class:`status--${
                val.color
                  ? val.color
                  : val.value
                  ? val.value
                  : val.name.includes(' ')
                  ? val.name.toLowerCase().replaceAll(' ', '_')
                  : val.name.toLowerCase()
              }`,attrs:{"icon":val.icon}}),_c('div',{staticClass:"flex flex-col justify-between"},[_c('h3',{staticClass:"mb-1 font-bold"},[_vm._v(" "+_vm._s(val.count || 0)+" ")]),_c('p',{staticClass:"mt-auto mb-0"},[_vm._v(" "+_vm._s(_vm._f("text_formatter")(val.title ? val.title : val.name))+" ")])])],1)])],2)],1)])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }