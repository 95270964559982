import axios from 'axios'

const resource = 'Principal'

export default {
  // Filter onlineClasses && QuickMeeting via same endpoint
  filterBoth (currentPage, payload) {
    return axios.post(`${resource}/onlineClasses?page=${currentPage}`, payload)
  },
  // Get Filters
  getAllDivision () {
    return axios.get('division')
  },
  getAllSections () {
    return axios.get('section/sections')
  },
  getAllTeachers () {
    return axios.get(`${resource}/teachers`)
  },
  getAllTeacherRole () {
    return axios.get('common/teacher-role')
  },
  getAllClasses () {
    return axios.get('teachers/classes')
  },
  getPrincipalAssignemts (page, payload) {
    return axios.get(`assignmients-principal?page=${page}`, {
      params: { ...payload }
    })
  },
  getTeacherAssignments (class_teacher_id) {
    return axios.get('classTeacher-assignmients-principal', {
      params: {
        class_teacher_id
      }
    })
  },
  getAllTeacher () {
    return axios.get('teachers/all-teachers')
  }
}
