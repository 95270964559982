import axios from 'axios'

const resource = 'CAS',
  resource2 = `${resource}/Task`
export default {


  // ---------- Task Actions
  createTask (payload) {
    return axios.post(`${resource2}/create`, payload)
  },
  showTask (id) {
    return axios.get(`${resource2}/show/${id}`)
  },
  validateTask (task) {
    return axios.post(`${resource2}/validate-task`, task)
  },
  archiveTask (task_id) {
    return axios.post(`${resource2}/Archive-task`, {
      task_id
    })
  },
  acceptTask (task) {
    return axios.post(`${resource2}/accept-task`, task)
  },
  saveForm (task) {
    return axios.post(`${resource2}/answer`, task)
  },
  deleteDocument (form) {
    return axios.post(`${resource2}/delete-documents`, form)
  },

  //-------------  names
  getCriteria () {
    return axios.get(`${resource}/get-criteria`)
  },
  getOutcomes () {
    return axios.get(`${resource}/get-outcomes`)
  },
  getStatus () {
    return axios.get(`${resource}/get-status`)
  },
  getStudents () {
    return axios.get(`${resource2}/student-data`)
  },


  // ------------ Counts

  // for Students
  getCriteriaStudentCounter () {
    return axios.get(`${resource2}/criteria-student-counter`)
  },
  getOutcomesStudentCounter () {
    return axios.get(`${resource2}/outcomes-student-counter`)
  },

  //for coordinator
  getCriteriaCoordinatorCounter () {
    return axios.get(`${resource2}/criteria/count`)
  },
  getOutcomesCoordinatorCounter () {
    return axios.get(`${resource2}/outcome/count`)
  },

  // get studentCount
  studentCriteriaCount (student_user_id) {
    return axios.get(`${resource2}/criteria-student-counter/${student_user_id}`)
  },
  studentOutcmesCount (student_user_id) {
    return axios.get(`${resource2}/outcomes-student-counter/${student_user_id}`)
  },


  //-------------- filters
  studentTasksFilter (currentPage, payload) {
    return axios.post(`${resource2}/student-tasks-filter?page=${currentPage}`, payload)
  },
  coordinatorTasksFilter (currentPage, payload) {
    return axios.post(`${resource2}/coordinator-tasks?page=${currentPage}`, payload)
  },

  //--------------- Interviews
  getStudentInterviews () {
    return axios.get(`${resource}/interview/student-interviewes`)
  },
  getTeacherInterviews (student_id) {
    return axios.get(`${resource}/interview/teacher-interviewes/${student_id}`, student_id)
  },
  createInterview (payload) {
    return axios.post(`${resource}/interview/`, payload)
  },


  //------------- Final Report
  allowUploadFinalReport (student_id) {
    return axios.post(`${resource2}/allow-upload-final-report`, {
      student_id
    })
  },
  UploadFinalReport (final_report, config) {
    return axios.post(`${resource}/upload-final-report`, final_report, config)
  },
  checkAllowUploadFinalReport (student_id) {
    return axios.get(`${resource}/check-allow-upload-final-report?student_id=${student_id}`)
  },

  // ---------- Reflection
  createReflection (body, task_id) {
    return axios.post(`${resource2}/create-reflection`, { body, task_id })
  },
  replyReflection (reflection_id, reply) {
    return axios.post(`${resource2}/reply-reflection`, { reflection_id, reply })
  },
  getReflection (task_id) {
    return axios.get(`${resource}/task/reflections/${task_id}`)
  },
  deleteReflection (payload) {
    return axios.post(`${resource2}/delete/reflection`, payload)
  },
  editReflection (payload) {
    return axios.post(`${resource2}/edit/reflection`, payload)
  },

  casClasses () {
    return axios.get('/cas-classes')
  },
  pdfCas () {
    return axios.get('/CAS/pdf')
  }
}
