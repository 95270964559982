<!-- =========================================================================================
    File Name: UserProfile.vue
    Description: user profile sidebar
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="parentx-demo-2">
    <vs-sidebar
      parent="#chat-app"
      :position-right="!isLoggedInUser"
      :hidden-background="false"
      v-model="activeLocal"
      id="chat-profile-sidebar"
      class="items-no-padding"
    >
      <template v-if="chatUser">
        <div class="header-sidebar relative flex flex-col p-0" slot="header">
          <feather-icon
            icon="XIcon"
            svgClasses="m-2 cursor-pointer absolute top-0 right-0"
            @click="$emit('closeProfileSidebar', false)"
          ></feather-icon>

          <div class="relative inline-flex mx-auto mb-5 mt-6">
            <vs-avatar
              class="m-0 border-white border-2 border-solid shadow-md"
              :src="chatUser.photo || chatUser.image || null"
              size="70px"
              v-if="chatUser.photo || chatUser.image"
            />
            <vs-avatar
              class="border-2 border-solid border-white"
              :icon="isGroup ? 'people_alt' : 'person'"
              size="70px"
              v-else
            />

            <!-- <div
            v-if="chatUser.status"
            class="h-5 w-5 border-white border-2 border-solid rounded-full absolute right-0 bottom-0"
            :class="'bg-' + statusColor"
          ></div> -->
          </div>

          <h4 class="mr-2 self-center">
            {{ chatUser.displayName || chatUser.name }}
            {{ chatUser.parent_middle_name || "" }}
            {{ chatUser.parent_middle_name || "" }}
          </h4>
        </div>

        <component
          :is="scrollbarTag"
          class="scroll-area"
          :settings="settings"
          :key="$vs.rtl"
        >
          <div class="p-8">
            <h6 class="mb-2" :class="{ 'ml-4': isLoggedInUser }">
              {{ $t("basic.about") }}
            </h6>
            <template v-if="isLoggedInUser">
              <vs-textarea
                class="mb-10"
                counter="120"
                maxlength="120"
                :counter-danger.sync="counterDanger"
                v-model="about"
                rows="5"
              />
            </template>
            <!-- Group  -->
            <template v-if="isGroup">
              <p class="text-sm text-gray">
                {{ $t("basic.created_at") }} {{ groupCreatedDate }}
                {{ groupCreatedTime }}
              </p>

              <!-- Settings -->
              <template v-if="isActiveUserGroupModeratorOrAdmin">
                <h6 class="mt-10 mb-2">
                  {{ $t("chat.group_settings") }}
                </h6>
                <vs-checkbox
                  @change="
                    $emit('onlyGroupAdminsCanReply', $event.target.checked)
                  "
                  class="my-5"
                  v-model="groupDetails.can_reply"
                >
                  {{ $t("chat.only_admins_can_send_messages") }}
                </vs-checkbox>
              </template>
              <!-- Members -->
              <template v-if="hasMembers">
                <h6 class="mt-10 mb-2">
                  {{ groupMemberLength }} {{ $t("chat.participants") }}
                </h6>
                <!-- Add Member -->
                <button
                  class="
                    cursor-pointer
                    member-row
                    no_style
                    w-full
                    p-2
                    border-b-2
                    flex
                    items-center
                  "
                  @click="$emit('addGroupMember', groupID)"
                  v-if="showAddMembersBtn"
                >
                  <feather-icon
                    icon="UserPlusIcon"
                    svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
                  ></feather-icon>
                  {{ $t("chat.add_participant") }}
                </button>
                <!-- Show Memebers data && actions -->
                <div
                  class="relative flex justify-between items-center member-row"
                  v-for="(member, i) in groupMembers"
                  :key="'m_' + i"
                >
                  <!-- info -->
                  <button
                    class="
                      cursor-pointer
                      no_style
                      w-full
                      p-2
                      flex
                      justify-between
                      items-center
                    "
                    @click="$emit('toggleUsers', member.id)"
                  >
                    <p>{{ member.name | text_formatter }}</p>
                    <vs-chip
                      v-if="isAdminOrModerator(member.user_role)"
                      color="primary"
                      :class="
                        !canShowMemberActions(member)
                          ? isActiveUserGroupJustMember
                            ? 'mx-0'
                            : 'mx-5'
                          : ''
                      "
                    >
                      {{ member.user_role_name || member.user_role }}
                    </vs-chip>
                  </button>
                  <!-- More Actions  -->
                  <vx-tooltip
                    :text="$t('basic.more')"
                    v-if="canShowMemberActions(member)"
                  >
                    <vs-dropdown
                      class="text-capitalize cursor-pointer w-full"
                      vs-custom-content
                      vs-trigger-click
                    >
                      <feather-icon
                        icon="MoreVerticalIcon"
                        svgClasses="h-5 w-5 hover:text-primary cursor-pointer"
                      ></feather-icon>

                      <vs-dropdown-menu>
                        <template v-if="!isMemberActiveUser(member.id)">
                          <!-- Make member group Admin -->
                          <vs-dropdown-item
                            @click="
                              makeGroupAdmin(
                                { id: member.id, name: member.name },
                                i
                              )
                            "
                            v-if="member.user_role !== 'admin'"
                          >
                            <span class="flex items-center">
                              <feather-icon
                                icon="UserCheckIcon"
                                svgClasses="h-4 w-4"
                                class="mr-2"
                              />
                              <span>{{ $t("chat.make_group_admin") }}</span>
                            </span>
                          </vs-dropdown-item>
                          <!-- Make Admin group member -->
                          <vs-dropdown-item
                            @click="
                              dismissGroupAdmin(
                                { id: member.id, name: member.name },
                                i
                              )
                            "
                            v-if="member.user_role === 'admin'"
                          >
                            <span class="flex items-center">
                              <feather-icon
                                icon="UserXIcon"
                                svgClasses="h-4 w-4"
                                class="mr-2"
                              />
                              <span>{{ $t("chat.dismiss_as_admin") }}</span>
                            </span>
                          </vs-dropdown-item>
                        </template>
                        <!-- remove member -->
                        <vs-dropdown-item
                          @click="
                            removeMember(
                              { id: member.id, name: member.name },
                              i
                            )
                          "
                        >
                          <span class="flex items-center">
                            <feather-icon
                              icon="XIcon"
                              svgClasses="h-4 w-4"
                              class="mr-2"
                            />
                            <span>{{ $t("basic.remove") }}</span>
                          </span>
                        </vs-dropdown-item>
                      </vs-dropdown-menu>
                    </vs-dropdown>
                  </vx-tooltip>
                </div>
              </template>

              <!-- ---- ActiveUser group actions  ----  -->
              <!-- Leave group-->
              <vs-button
                type="flat"
                color="danger"
                class="w-full text-left px-2"
                @click="removeMember({ id: activeUser.id })"
                v-if="isActiveUserAnActiveGroupMember"
              >
                <feather-icon
                  icon="LogOutIcon"
                  svgClasses="h-4 w-4"
                  class="mr-2"
                />
                <span>{{ $t("chat.exit_group") }}</span>
              </vs-button>

              <!-- Delete group-->
              <vs-button
                type="flat"
                color="danger"
                class="w-full text-left px-2"
                @click="deleteGroup()"
                v-if="showDeleteGroupBtn"
              >
                <feather-icon
                  icon="DeleteIcon"
                  svgClasses="h-4 w-4"
                  class="mr-2"
                />
                <span>{{ $t("chat.delete_group") }}</span>
              </vs-button>
            </template>
            <!-- Private -->
            <template v-else>
              <p>
                <template v-if="hasRoles">
                  <span v-for="(role, i) in chatUser.roles" :key="'r_' + i">
                    {{ role.display_name | text_formatter }}
                    {{
                      role.description !== role.display_name
                        ? "- " + role.description
                        : ""
                    }}
                  </span>
                </template>
                <template v-else-if="hasOneRole">
                  <span class="inline-block">
                    {{
                      chatUser.role_name
                        .replaceAll("(", " - ")
                        .replaceAll(")", "")
                    }}
                  </span>
                </template>
                <span v-if="chatUser.code"> - {{ chatUser.code }} </span>
                <span v-if="chatUser.class_name">
                  {{ $t("basic.in") }} {{ $t("basic.class") }}
                  {{ chatUser.class_name | text_formatter }}
                </span>
              </p>

              <div class="mt-10" v-if="chatUser.email">
                <h6 class="mb-2">{{ $t("basic.contacts") }}</h6>
                <p>
                  <span class="text-capitalize"
                    >{{ $t("basic.email") }} :
                  </span>
                  <a :href="`mailTo:${chatUser.email}`">{{ chatUser.email }}</a>
                </p>
              </div>
              <template v-if="hasChildren">
                <div class="mt-10">
                  <h6 class="mb-2">{{ $t("basic.childern") }}</h6>
                  <ul>
                    <li
                      v-for="(child, i) in chatUser.students"
                      :key="'child_' + i"
                      class="mt-4"
                    >
                      <span class="text-capitalize">
                        {{ child.name }} - {{ child.code }} -
                        {{ child.class_name || child.classGroupName }}
                      </span>
                      <a :href="`mailTo:${child.email}`" class="block mt-1">{{
                        child.email
                      }}</a>
                    </li>
                  </ul>
                </div>
              </template>
            </template>
            <div class="userProfile__status" v-if="isLoggedInUser">
              <h6 class="mb-4">Status</h6>
              <ul>
                <li class="mb-2">
                  <vs-radio v-model="status" vs-value="online" color="success"
                    >Active</vs-radio
                  >
                </li>
                <li class="mb-2">
                  <vs-radio
                    v-model="status"
                    vs-value="do not disturb"
                    color="danger"
                    >Do Not Disturb</vs-radio
                  >
                </li>
                <li class="mb-2">
                  <vs-radio v-model="status" vs-value="away" color="warning"
                    >Away</vs-radio
                  >
                </li>
                <li class="mb-2">
                  <vs-radio v-model="status" vs-value="offline" color="grey"
                    >Offline</vs-radio
                  >
                </li>
              </ul>
            </div>
          </div>
        </component>
        <!-- userProfileLoading -->
        <div
          v-show="loading"
          ref="userProfileLoading"
          id="userProfileLoading"
          class="vs-con-loading__container"
        ></div>
      </template>
    </vs-sidebar>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import mixins from '../chat.mixins'

export default {
  name:'user-profile',
  props: {
    userId: {
      type: Number,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    },
    isLoggedInUser: {
      type: Boolean,
      required: true
    },
    isAddGroupMemberMode:{
      type: Boolean,
      required: true
    },
    loading:{
      type: Boolean,
      required: false
    }
  },
  mixins:[mixins],

  data () {
    return {
      counterDanger: false,
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      selectedMemberID:null,
      memberActionLoader:false,
      activeUserLeaveGroup:false

    }
  },
  computed: {
    chatUser () {
      return this.user
    },
    activeLocal: {
      get ()      { return this.active },
      set (value) { this.$emit('closeProfileSidebar', value) }
    },
    about: {
      get ()      { return this.chatUser.about },
      set (value) { this.$store.dispatch('updateUserInfo', { about: value }) }
    },
    status: {
      get ()      { return this.chatUser.status },
      set (value) { this.$store.dispatch('updateUserInfo', { status: value }) }
    },
    statusColor () {
      const userStatus = this.chatUser.status

      if (userStatus === 'online') {
        return 'success'
      } else if (userStatus === 'do not disturb') {
        return 'danger'
      } else if (userStatus === 'away') {
        return 'warning'
      } else {
        return 'grey'
      }
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    },
    hasChildren () {
      return  this.chatUser.students?.length
    },
    groupCreatedDate () {
      const creationDate = new Date(this.groupDetails.created_at)
      return creationDate.toLocaleDateString()
    },
    groupCreatedTime () {
      const creationTime = new Date(this.groupDetails.created_at)
      return creationTime.toLocaleTimeString()
    },
    showAddMembersBtn () {
      return  this.isActiveUserGroupModeratorOrAdmin && !this.isAddGroupMemberMode
    },
    showDeleteGroupBtn () {
      return this.canCreateDeleteGroup && this.isActiveUserGroupModeratorOrAdmin
    }

  },
  watch:{
    loading (v) {
      if (v) this.startLoading()
      else this.endLoading()
    }
  },
  components: {
    VuePerfectScrollbar
  },
  methods: {
    /////////////////////////////
    // Loading
    ////////////////////////////
    async startLoading () {
      // await (this.loading = true)
      this.VsLoadingContained(this.$refs.userProfileLoading, 'button')
    },
    async endLoading () {
      await  this.stopVsLoadingContained(this.$refs.userProfileLoading)
      // this.loading = false
    },
    /////////////////////////////
    // Group Actions
    ////////////////////////////
    // Basic Rules
    startAction (member) {
      this.selectedMemberID = member.id
    },
    endAction () {
      this.selectedMemberID = null
    },

    ///////////////////
    // Remove Member

    async removeMember (member) {
      this.startAction(member)
      const
        isActiveUserLeaveTheGroup = member.id === this.activeUser.id,
        dialogMsg = isActiveUserLeaveTheGroup ? `${this.$t('chat.exit')} "${this.groupTitle}" ${this.$t('chat.group')} ?` : `${this.$t('basic.remove') } ${member.name} ${this.$t('basic.from')} ${this.groupTitle} ${this.$t('chat.group')} ?`

      this.activeUserLeaveGroup = isActiveUserLeaveTheGroup

      await this.dangerDialog(
        this.$t('basic.confirm'),
        dialogMsg,
        this.confirmRemoveMember, null, null,
        this.endAction)
    },
    async confirmRemoveMember () {
      if (this.activeUserLeaveGroup) await this.$emit('leaveGroup')
      else await this.$emit('removeGroupMember', this.selectedMemberID)

      this.endAction()

    },
    ///////////////////
    // Make Admin

    async makeGroupAdmin (member) {
      this.selectedMemberID = member

      await this.confirmDialog(
        this.$t('basic.confirm'),
        `${this.$t('chat.make') } ${member.name}  ${this.$t('chat.an_admin')} ${this.$t('basic.for')} "${this.groupTitle}" ${this.$t('chat.group')}  ?`,
        this.confirmMakeAdmin, null, null,

        this.endAction)

    },
    confirmMakeAdmin () {
      this.$emit('makeGroupAdmin', this.selectedMemberID)
      this.endAction()
    },

    ////////////////////
    // Dismiss as Admin

    async dismissGroupAdmin (member) {
      this.selectedMemberID = member


      await this.dangerDialog(
        this.$t('basic.confirm'),
        `${this.$t('basic.remove') } ${member.name}   ${this.$t('basic.as')} "${this.groupTitle}" ${this.$t('chat.group')} ${this.$t('chat.admin')} ?`,
        this.confirmDismissGroupAdmin, null,
        this.endAction)
    },
    confirmDismissGroupAdmin () {
      this.$emit('dismissGroupAdmin', this.selectedMemberID)
      this.endAction()
    },


    //////////////////////////////
    // Delete
    deleteGroup () {
      this.dangerDialog(
        this.$t('basic.confirm'),
        `${this.$t('chat.messages_and_attachment_files_will_be_deleted_permanently')}`,
        this.confirmDeleteGroup, null, null)
    },
    confirmDeleteGroup () {
      this.$emit('deleteGroup')
    },

    //////////////////////////////
    // Can show member Actions

    canShowMemberActions (member) {
      return    this.isActiveUserGroupModeratorOrAdmin &&
                      !this.isGroupModerator(member.user_role) &&
                      !this.isMemberActiveUser(member.id)
    }
  }
}

</script>
<style lang="scss">
.member-row {
  border-radius: 3px;
  height: 44px;
  &:hover {
    background: rgba(var(--vs-primary), 0.1);
    filter: drop-shadow(0px);
  }
  &:not(:last-child) {
    border-bottom: 1px solid #eeeeee;
  }
}
.vs-sidebar--items {
  position: relative;
}
#userProfileLoading {
  position: absolute;
  inset-block: 0;
  width: 100%;
}
</style>

