export default [
  {
    path: '/',
    name: 'scoreManager.list',
    component: () => import('./pages/score-manager/List.vue'),
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        {
          title: 'scoreManager.scoreManager',
          url: '/score-manager',
          active: true
        }
      ],
      pageTitle: 'scoreManager.scoreManager',
      rule:'SNDSMD'
    }
  },

  {
    path: 'create',
    name: 'scoreManager.create',
    component: () => import('./pages/score-manager/Create2.vue'),
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        { title: 'scoreManager.scoreManager', url: '/score-manager' },
        {
          title: 'basic.create',
          url: '/admission/round-manager/create',
          active: true
        }
      ],
      pageTitle: 'scoreManager.scoreManager',
      rule:'SNDSMD'
    }
  },

  {
    path: 'edit/:id',
    name: 'scoreManager.edit',
    component: () => import('./pages/score-manager/update.vue'),
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        { title: 'scoreManager.scoreManager', url: '/score-manager' },
        { title: 'main.Edit', url: '', active: true }
      ],
      pageTitle: 'scoreManager.scoreManager',
      rule:'SNDSMD'
    }
  }
]
