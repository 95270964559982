<template>
  <sideBar
    :filters_prop="custom_filter"
    @refilter="setFilters"
    :loading="loadingFilter">
    <template slot="extra_content">
      <div class="w-full mb-10">
        <vs-input
          :label-placeholder="$t('form.search_by_subject_name')"
          v-model="searchContent"
          class="w-full"
          name="name"
          icon="icon icon-search"
          icon-pack="feather"
          @change="setFilters" />
      </div>
    </template>
  </sideBar>
</template>

<script>
import sideBar from '@/components/general/RoundFilter'
import {commonDataMixin} from '@/mixins.js'

export default {
  name: 'FilterBar',
  components: {sideBar},
  mixins: [commonDataMixin],
  data() {
    return {
      //////////////////////////
      // Filters
      /////////////////////////

      subjects: [],
      schools: [],
      custom_filter: [
        {
          name: this.$t('basic.academic_year'),
          payload_name: 'academic_year_id',
          by: []
        },
        {
          name: this.$t('basic.schools'),
          payload_name: 'schools',
          onChangeFireAction: true,
          by: []
        },
        {
          name: this.$t('basic.divisions'),
          payload_name: 'divisions',
          onChangeFireAction: true,
          by: []
        },
        {
          name: this.$t('basic.sections'),
          payload_name: 'sections',
          onChangeFireAction: true,
          by: []
        },
        {
          name: this.$t('basic.grades'),
          payload_name: 'grades',
          onChangeFireAction: true,
          by: []
        },
        {
          name: this.$t('basic.classes'),
          payload_name: 'classes',
          by: []
        },
        {
          name: this.$t('basic.status'),
          payload_name: 'statuses',

          by: [
            {id: 'Active', name: this.$t('status.active'), checked: false},
            // {
            //   id: 'Suspended',
            //   name: this.$t('status.suspended'),
            //   checked: false
            // },
            // {
            //   id: 'expired',
            //   name: this.$t('status.expired'),
            //   checked: false
            // },
            {
              id: 'Closed',
              name: this.$t('status.closed'),
              checked: false
            },
            {
              id: 'Archived',
              name: this.$t('status.archived'),
              checked: false
            }
          ]
        }
      ],

      // Search
      searchContent: '',
      loadingFilter: false
    }
  },
  computed: {
    schoolID() {
      return this.authUser.school_id
    },
    sideFilters() {
      const obj = {}
      this.custom_filter.forEach((c) => {
        // if (c.payload_name === 'schools' && !c.by.some((e) => e.checked)) {
        //   obj['schools'] = [this.schoolID]
        // } else obj[c.payload_name] = null
        obj[c.payload_name] = null
      })
      return obj
    }
  },

  methods: {
    //////////////////////
    // Helpers
    /////////////////////
    setFilters(val) {

      // to enable watcher when typing on the search input
      this.sideFilters["subject_search"] = this.searchContent;

      if (Array.isArray(val)) {
        val.map((item) => {
          this.sideFilters[item.name] = item.checked
        });
      }
      this.reloadData()
    },
    getCustomFilterEl(payload_name) {
      return this.custom_filter.find((e) => e.payload_name === payload_name)
    },
    byObj(el) {
      return {
        id: el.id || el._id,
        name: el.name,
        checked: false
      }
    },
    resetOptions(arrOfPayloadNames) {
      arrOfPayloadNames.forEach((payload_name) => {
        const matched = this.getCustomFilterEl(payload_name)
        if (matched) matched.by = []
      })
    },
    search() {
      this.sideFilters.subject_search = this.searchContent
      if (this.searchContent.length >= 3 || !this.searchContent) {
        this.reloadData()
      }
    },
    reloadData() {
      this.$emit('reloadData', this.sideFilters)
    },
    //////////////////////
    // APIs
    /////////////////////
    getSchools() {
      this.loadingFilter = true
      this.resetOptions(['divisions', 'sections', 'grades', 'classes'])

      this.getFilterData()
        .then((res) => {
          res.schools.map((el) => {
            this.getCustomFilterEl('schools').by.push(this.byObj(el))
          })
        })
        .catch(this.errMsg)
        .finally(() => {
          this.loadingFilter = false
        })
    },
    getDivisions(chosen) {
      this.loadingFilter = true
      this.resetOptions(['divisions', 'sections', 'grades', 'classes'])
      this.getFilterData({schools: chosen.schools})
        .then((res) => {
          res.divisions.map((el) => {
            this.getCustomFilterEl('divisions').by.push(this.byObj(el))
          })
        })
        .catch(this.errMsg)
        .finally(() => {
          this.loadingFilter = false
        })
    },
    getSections(chosen) {
      this.loadingFilter = true
      const data = {
        schools: chosen.schools,
        divisions: chosen.divisions
      }
      this.resetOptions(['sections', 'grades', 'classes'])

      this.getFilterData(data)
        .then((res) => {
          res.sections.map((el) => {
            this.getCustomFilterEl('sections').by.push(this.byObj(el))
          })
        })
        .catch(this.errMsg)
        .finally(() => {
          this.loadingFilter = false
        })
    },

    getGrades(chosen) {
      this.loadingFilter = true

      this.resetOptions(['grades', 'classes'])
      const data = {
        schools: [this.schoolID],
        divisions: chosen.divisions,
        sections: chosen.sections
      }
      this.getFilterData(data)
        .then((res) => {
          res.grades.map((el) => {
            this.getCustomFilterEl('grades').by.push(this.byObj(el))
          })
        })
        .catch(this.errMsg)
        .finally(() => {
          this.loadingFilter = false
        })
    },
    getClasses(chosen) {
      this.loadingFilter = true
      this.resetOptions(['classes'])

      const data = {
        schools: [this.schoolID],
        divisions: chosen.divisions,
        sections: chosen.sections,
        grades: chosen.grades
      }
      this.getFilterData(data)
        .then((res) => {
          res.grades_classes.map((el) => {
            this.getCustomFilterEl('classes').by.push(this.byObj(el))
          })
        })
        .catch(this.errMsg)
        .finally(() => {
          this.loadingFilter = false
        })
    },
    getAllAcademicYears() {
      this.getAllAcademic().then((res) => {
        res.forEach((el) => {
          this.getCustomFilterEl('academic_year_id').by.push(this.byObj(el))
        })
      })
    }
  },
  created() {
    this.getSchools()
    this.getAllAcademicYears()
    this.$root.$on('onChange:schools', (v) => {
      this.getDivisions(v)
    })
    this.$root.$on('onChange:divisions', (v) => {
      this.getSections(v)
    })
    this.$root.$on('onChange:sections', (v) => {
      this.getGrades(v)
    })
    this.$root.$on('onChange:grades', (v) => {
      this.getClasses(v)
    })
  },
  beforeDestroy() {
    this.$root.$off('onChange:schools')
    this.$root.$off('onChange:divisions')
    this.$root.$off('onChange:sections')
    this.$root.$off('onChange:grades')
  }
}
</script>

<style scoped></style>
