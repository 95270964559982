import announcementList from './pages/list.vue'
import teacherAnnouncementCreate from '../../components/announcementForm.vue'
import announcementShow from '../../components/announcementShow.vue'

export default [
  // list
  {
    path: '/teacher/announcement',
    name: 'announcementList',
    i18n: 'announcement.announcements',
    component: announcementList,
    meta: {
      breadcrumb: [
        {
          title: 'basic.dashboard',
          url: 'landing/home'
        },
        {
          title: 'basic.list',
          active: true
        }
      ],
      pageTitle: 'announcement.announcements',
      rule: 'Teacher'
    }
  },

  // show
  {
    path: '/teacher/announcement/show/:id',
    name: 'show-announcement-teacher',
    i18n: 'announcement.announcements',
    component: announcementShow,
    meta: {
      breadcrumb: [
        {
          title: 'basic.dashboard',
          url: 'landing/home'
        },
        {
          title: 'basic.list',
          url: '/teacher/announcement'
        },
        {
          title: 'main.show',
          active: true
        }
      ],
      pageTitle: 'main.show',
      rule: 'Teacher'
    }
  },
  //Create
  {
    path: '/teacher/announcement/create',
    name: 'teacherAnnouncementCreate',
    i18n: 'announcement.announcements',
    component: teacherAnnouncementCreate,
    meta: {
      breadcrumb: [
        {
          title: 'basic.dashboard',
          url: 'landing/home'
        },
        {
          title: 'basic.list',
          url: '/teacher/announcement'
        },
        {
          title: 'basic.create',
          active: true
        }
      ],
      pageTitle: 'announcement.create_announcement',
      rule: 'Teacher'
    }
  }
]
