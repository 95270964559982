<template>
  <div>
    <vs-card class="p-3 py-6 md:p-6">
<!--      <h5 class="text-center">Category Name</h5>-->
      <form
        class="flex justify-between flex-col"
        @submit.prevent="submitForm"
        @keyup.enter="submitForm"
      >
        <div class="mb-4 mt-auto w-full vx-row items-center mx-auto">
          <!--          Start Type-->
          <div  v-if="$route.query? $route.query.isCanteen : false" class="my-2 vx-col  w-full">
            <label>{{ $t("canteen.category") }}
              <sup class="text-danger">*</sup>
            </label>
            <v-select
              :closeOnSelect="true"
              v-model="item.canteen_category_id"
              :options="categories"
              label="name"
              :reduce="(name) => name.id"
              v-validate="'required'"
              name="type"
              class="w-full mb-2"
            />

            <span class="text-danger text-sm">{{
                errors.first("type")
              }}</span>
          </div>
          <!-- End Type -->

          <!--Item Name -->
          <div class="my-2 vx-col  w-full">
            <!-- Student Code  -->
            <label>{{ $t("canteen.itemName") }}<sup class="text-danger">*</sup></label>
            <vs-input
              type="text"
              v-model="item.name"
              class="w-full mb-2"
              name="item_name"
              v-validate="'required'"
            />
            <span class="text-danger text-sm">{{
                errors.first("item_name")
              }}</span>
          </div>
          <!-- End Item Name -->

          <!--Start description-->
          <div class="my-2 vx-col w-full">
            <label>{{ $t("form.description") }}<sup class="text-danger">*</sup></label>
            <vs-textarea
              class="w-full mb-2"
              v-model="item.description"
              v-validate="'required'"
              name="description"
            />
            <span class="text-danger text-sm">{{
                errors.first("description")
              }}</span>
          </div>
          <!--End description-->


          <!-- Start Price -->
          <div class="my-2 vx-col w-full">
            <div class="vx-col w-full">
              <label>
                {{ $t("canteen.price") }}
                <sup class="text-danger">*</sup>
              </label>
            </div>

            <div class="vx-col w-full">
              <div class="vx-row">
                <vs-input
                  v-model="item.price"
                  :name="'price'"
                  v-validate="'required|numeric'"
                  class="w-full vx-col"
                  :placeholder="`${$t('canteen.price')}`"
                  type="number"
                />
              </div>
              <div class="w-full">
                <span class="text-danger text-sm text-center">{{
                    errors.first(`price`)
                  }}</span>
              </div>
            </div>
          </div>
          <!-- End Price  -->


          <!-- Start Price -->
          <div class="my-2 vx-col w-full">
            <div class="vx-col w-full">
              <label>
                {{ $t("canteen.quantity") }}
                <sup class="text-danger">*</sup>
              </label>
            </div>

            <div class="vx-col w-full">
              <div class="vx-row">
                <vs-input
                  v-model="item.quantity"
                  :name="'quantity'"
                  v-validate="'required|numeric'"
                  class="w-full vx-col"
                  :placeholder="`${$t('canteen.quantity')}`"
                  type="number"
                />
              </div>
              <div class="w-full">
                <span class="text-danger text-sm text-center">{{
                    errors.first(`quantity`)
                  }}</span>
              </div>
            </div>
          </div>
          <!-- End Price  -->


          <!--Start Upload File -->
          <div class="my-2 vx-col w-full">
            <label class="w-full">{{ $t("exam.upload_attachment") }}</label>
            <vs-input
              type="file"
              accept="image/*,.doc,.docx,application/msword,.pdf,application/pdf;capture=camera"
              multiple
              @change="uploadDoc($event)"
              v-model="documents"
              class="w-full mb-2"
              ref="upload_file"
            />
          </div>
          <div class="my-2 vx-col w-full" v-if="item.documents.length">
            <div class="flex flex-row flex-wrap">
              <div
                v-for="(file, idx) in item.documents"
                :key="file.attachment_url"
              >
                <feather-icon
                  @click="deleteDocument(idx, file)"
                  class="mr-2 remove-student-attachment"
                  icon="XIcon"
                  svgClasses="h-5 w-5"
                />

                <file-previewer
                  :url="file.document"
                  :urlType="file.type"
                  class="ml-1 my-4 mr-4"
                ></file-previewer>
              </div>
            </div>
          </div>
          <!--End Upload File -->

        </div>
        <!-- Actions -->
        <div class="flex flex-wrap justify-between items-center mt-auto mb-0">
          <vs-button class="mt-5 md:w-auto md:mr-0 ml-auto" @click="submitForm">
            {{ $t("form.submit") }}
          </vs-button>
        </div>
      </form>
    </vs-card>
  </div>
</template>
<script>
// Components
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import vSelect from 'vue-select'
import fileUploader from '@/modules/unit-planner/components/uploadButton/uploadButton.vue'

/*import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const addStudentRepository = RepositoryFactory.get(
  'studentAffairsRepository',
  'addStudent'
)*/

// import getData from './form/common_data_mixin'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const canteenRepository = RepositoryFactory.get(
  'canteenRepository',
  'canteenRepository'
)

import { commonDataMixin, checkValidationMixin } from '@/mixins'

export default {
  name: 'item-info',
  props: {
    studentInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    ifEdit: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  mixins: [commonDataMixin, checkValidationMixin],
  // mixins:[getData()],
  components: { flatPickr, vSelect, fileUploader },

  data () {
    return {
      item: {
        name: '',
        description: '',
        canteen_category_id: this.$route.query.isCanteen ? '' : this.$route.params.id,
        price: '',
        quantity: '',
        photo: '',
        documents: []
      },
      documents: null,
      categories: []
    }
  },
  watch: {
  },
  computed: {
  },
  methods: {
    clickOnFile () {
      this.$refs.upload_file.click()
    },

    //  Upload Docs
    uploadDoc (e) {
      const files = e.target.files
      files.forEach((file) => {
        this.checkTextImage2MBFileValid(file).then(() => {
          const data = new FormData()
          data.append('attachments[0]', file)
          this.commonUploadFiles(data)
            .then((res) => {
              const doc = res[0]
              doc.name = doc.attachment
              doc.document = doc.attachment_url
              delete doc.attachment
              delete doc.attachment_url
              this.item.documents.push(doc)
              this.item.photo = doc.name
              this.successMsg(
                this.$t('form.your_document_uploaded_successfully')
              )
            })
            .then(() => (this.renderExamFile = true))
        })
      })
    },

    async deleteDocument (index, file) {
      const { document } = file
      const data = {
        file_path: document
      }
      const isDeletedFromS3 = await this.deleteDocumentFromS3(data)
      if (isDeletedFromS3) {
        this.deleteMsg(this.$t('form.Delete_Document_Successfully'))
        this.item.documents.splice(index, 1)
        this.item.photo = ''
      } else {
        this.errMsg(this.$t('form.failed_to_delete_documment'))
      }
    },
    getAllCategoryWithoutPagination () {
      if (this.$route.query.isCanteen) {
        canteenRepository.getAllCategoryWithoutPagination(this.$route.params.id).then(res => {
          this.categories = res.data
        })
      }
    },

    fillData () {
      if (this.ifEdit) {
        this.VSLoading()
        canteenRepository.showItemDetails(this.$route.params.id).then(res => {
          this.item = {
            name: res.data.name,
            description: res.data.description,
            canteen_category_id: res.data.category_id,
            price: res.data.price,
            quantity: res.data.quantity,
            photo: res.data.photo,
            documents: []
          }
          this.stopVSLoading()
        })
      }
    },
    submitForm () {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.$emit('formSubmited', this.item)
        } else {
          this.errMsg()
        }
      })
    }
  },
  created () {
    this.getAllCategoryWithoutPagination()
    this.fillData()
  },
  mounted () {}
}
</script>
<style lang="scss">
.remove-student-attachment {
  top: 32px;
  left: 90px;
  z-index: 20;
  cursor: pointer;
  svg {
    stroke: red;
    stroke-width: 2px;
  }
}
</style>
