<template >
  <separatedPage :componentName="dynamicComponent"></separatedPage>
</template>
<script>
import separatedPage from './Components/SeparatedPage.vue'
import OnlineClasses from './Components/Left-Sidebar/OnlineClasses.vue'
export default {
  name:'parent-onlineClass',
  components:{
    separatedPage,
    OnlineClasses
  },
  data: () => ({
    dynamicComponent:OnlineClasses
  })
}
</script>
<style lang="">
</style>
