import AdmissionModuleRepositories from '../modules/admission/Repositories'
import ConfigurationModuleRepositories from '../modules/configuration/Repositories'
import DataTableRepository from '../components/general/Repositories'
import ScoreManagerModuleRepositories from '../modules/scoreManager/Repositories'
import StudentsModuleRepositories from '../modules/Students/Repositories'
import AttendenceRepository from '../modules/attendance/Repositories'
import AcademicYearsRepository from '../modules/academic/Repositories'
import TeacherRepository from '../modules/Teachers/Repositories'
import AuthRepository from '../modules/auth/Repositories'
import ParentRepository from '../modules/parent/Repositories'

import studentAffairsRepository from '../modules/student-affairs/Repositories'
import schoolPrincipalRepository from '../modules/school-principal/Repositories'
import CASRepository from '../modules/cas/Repositories'
import AgendaRepository from '../modules/agenda/Repositories'
import ChatRepository from '../modules/chat/Repositories/index'
import ExamRepository from '../modules/exam/Repositories/index'
import CalendarRepository from '../modules/calendar/Repositories/index'

import teachersGateway from '../modules/teachers-gateway/Repositories'
import gradingSystem from '../modules/grading/Repositories'
import Assignments from '../modules/assignment/Repositories/index'

import Wallet from '../modules/wallet/Repositories/index'
import Canteen from '../modules/canteen/Repositories/index'
import announcement from '../modules/announcement/Repositories/index'

import Inventory from '../modules/inventory/Repositories/index'
import boundariesAndDescriptors from '../modules/grading/subModules/boundariesAndDescriptors/Repositories/index'
import myDay from '../modules/myDay/Repositories/index'


const repositories = []
repositories['AdmissionModule'] = AdmissionModuleRepositories
repositories['ConfigurationModule'] = ConfigurationModuleRepositories
repositories['General'] = DataTableRepository
repositories['ScoreManager'] = ScoreManagerModuleRepositories
repositories['StudentsModule'] = StudentsModuleRepositories
repositories['AcademicModule'] = AcademicYearsRepository
repositories['Attendance'] = AttendenceRepository
repositories['TeachersModule'] = TeacherRepository
repositories['AuthRepository'] = AuthRepository
repositories['ParentRepository'] = ParentRepository
repositories['studentAffairsRepository'] = studentAffairsRepository
repositories['schoolPrincipalRepository'] = schoolPrincipalRepository
repositories['CASRepository'] = CASRepository
repositories['AgendaRepository'] = AgendaRepository
repositories['ChatRepository'] = ChatRepository
repositories['ExamModule'] = ExamRepository
repositories['CalendarModule'] = CalendarRepository
repositories['teachersGatewayModule'] = teachersGateway
repositories['GradingModule'] = gradingSystem
repositories['AssignmentsModule'] = Assignments
repositories['walletRepository'] = Wallet
repositories['canteenRepository'] = Canteen
repositories['announcementRepository'] = announcement
repositories['inventoryRepository'] = Inventory
repositories['boundariesAndDescriptors'] = boundariesAndDescriptors
repositories['myDay'] = myDay
export const RepositoryFactory = {
  get: (module, repo) => repositories[module][repo]
}
