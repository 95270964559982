<!-- //////////////////////////////

status obj :{
  id:0,
  name:"", //name
  title :"", // title ( for just displaying)
  color:'',
  value:'',
  count:0
}
props : status_prop
emits : filter-change

//////////////////////////////
-->
<template>
  <div
    class="justify-between flex w-full flex-wrap"
    :class="{ 'lg:flex-no-wrap': status.length < 8 }"
  >
    <div
      class="mb-4 flex-1 counter_block"
      v-for="(val, i) in status"
      :key="val.id || i"
    >
      <button
        @click="getFilter(val)"
        class="w-full no_style block pl-1 pr-1 h-full"
        :class="{ active: (val.value || val.id) === selected }"
        :title="val.title ? val.title : val.name"
      >
        <vx-card class="overflow-hidden small-card h-full">
          <template slot="no-body">
            <div
              class="p-4 h-full"
              :class="'flex justify-between flex-row-reverse '"
            >
              <feather-icon
                :icon="val.icon"
                class="p-2 inline-flex rounded-full"
                :class="`status--${
                  val.color
                    ? val.color
                    : val.value
                    ? val.value
                    : val.name.includes(' ')
                    ? val.name.toLowerCase().replaceAll(' ', '_')
                    : val.name.toLowerCase()
                }`"
              ></feather-icon>
              <div class="flex flex-col justify-between">
                <h3 class="mb-1 font-bold">
                  {{ val.count || 0 }}
                </h3>
                <p class="mt-auto mb-0">
                  {{ val.title ? val.title : val.name | text_formatter }}
                </p>
              </div>
            </div>
          </template>
        </vx-card>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'status-counter',
  props: {
    status_prop: {
      type: Array,
      required: true
    },
    selected_prop:{
    }
  },
  watch: {
    status_prop (newValue) {
      this.status = newValue
    },
    selected_prop (newSelect) {
      this.selected = newSelect
    }

  },
  data () {
    return {
      status: this.status_prop,
      selected:this.selected_prop ? this.selected_prop : null

    }
  },
  methods: {
    getFilter (val) {
      if ((val.value || val.id) === this.selected) {

        this.selected = null
        this.$emit('filter-change', '')

      } else {

        this.selected = val.value || val.id || val.name
        this.$emit('filter-change', (val.value || val.name))

      }

    }
  },
  computed: {
    card_width () {
      return `${this.status_prop.length / 100}%`
    }

  },
  created () {

    if (this.status_prop.find(el => el.active === true)) {
      const defaultActiveStatus = this.status_prop.find(el => el.active === true)
      this.selected = defaultActiveStatus.value || defaultActiveStatus.id
    }
  }
}
</script>
<style scoped>
.vx-card .vx-card__collapsible-content.vs-con-loading__container {
  height: 100%;
}
</style>
<style lang="scss">
.status_card {
  cursor: pointer;
}
.vx-card {
  cursor: pointer;
  .active & {
    box-shadow: 0 14px 14px 0 rgba(0, 0, 0, 0.1),
      0 5px 5px 0 rgba(0, 0, 0, 0.11) !important;
    filter: drop-shadow(0 10px 10px rgba(0, 0, 0, 0.1));
    transform: translateY(-5px);
  }
}

.small-card {
  min-width: 100px;

  .feather-icon {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .truncate {
    span {
      text-transform: capitalize;
      color: #6e6d6d;
    }
  }
}
@media (min-width: 450px) {
  .counter_block {
    width: 144px;
  }
  .small-card {
    width: 100%;
  }
}
.vx-card__collapsible-content.vs-con-loading__container {
  height: 100%;
}
</style>
