<template>
  <div id="list_datatable w-full">
    <div class="vx-row mb-2">
      <!-- Create Student -->
    </div>
    <div class="vx-row">
      <div
        class="vx-col w-full"
      >
        <ItemsTable
          :headers_prop="headers"
          :list_url_prop="`category/${$route.params.id}/items`"
          :loading="loading"
        >
        </ItemsTable>
      </div>
    </div>
  </div>
</template>

<script>

import ItemsTable from '@/components/general/datatable/DataTable2.vue'

import { commonDataMixin } from '@/mixins'

export default {
  components: {
    ItemsTable
  },
  mixins: [commonDataMixin],
  data () {
    return {
      headers: [
        {
          minWidth: 170,
          headerName: this.$t('basic.code'),
          field: 'code'
        },
        {
          minWidth: 170,
          headerName: this.$t('form.name'),
          field: 'name'
        },
        {
          minWidth: 170,
          headerName: this.$t('canteen.price'),
          field: 'price'
        },
        {
          minWidth: 170,
          headerName: this.$t('canteen.quantity'),
          field: 'quantity'
        },
        {
          minWidth: 170,
          headerName: this.$t('canteen.categoryName'),
          field: 'category_name'
        },
        {
          minWidth: 95,
          headerName: this.$t('basic.status'),
          field: 'status',
          cellRendererFramework: 'CellRendererStatus'
        },
        {
          minWidth: 120,
          headerName: this.$t('basic.actions'),
          filter: false,
          field: 'actions',

          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            actions_prop: [
              {
                dropDown: true,
                dropDown_items: [
                  {
                    title: this.$t('basic.update'),
                    icon: 'Edit3Icon',
                    navigate_action: true,
                    route_name: 'edit-canteen-item',
                    resource: '/canteen/edit-canteen-item/:id'
                  },
                  {
                    title: this.$t('basic.archive'),
                    parameter_name: 'id',
                    icon: 'ArchiveIcon',
                    action: 'Archived',
                    resource: 'canteen/items/change-status/:id',
                    requestType: 'post',
                    requestPayload: { status: 'archive' },
                    showIf: (data) => data.status !== 'Archived'
                  }
                ]
              }
            ]
          }
        }
      ],
      loading:false
    }
  },
  methods: {
    goToEdit (categoryId) {
      this.$router.push({name: 'edit-canteen-category', params: {id: categoryId}, query:{canteen_id : this.$route.params.id} })
    }
  },
  watch:{
  },
  computed: {
  },
  beforeDestroy () {
    this.$root.$off('goToEdit')
  },
  created () {
    this.$root.$on('goToEdit', (data) => this.goToEdit(data.id))
  }
}
</script>

<style lang="scss" scoped>
.active .vx-card {
  -webkit-box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
  box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
}
</style>
