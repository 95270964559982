<template>
  <ListPageWithCreateButton :showCreateBtn="false">
    <template slot="leftSideButtonContent">
      <StatusCounter
        @filter-change="changeCounterFilter"
        :status_prop="statistics_data"
        ref="StatusCounter" />
    </template>
    <template slot="listDataContent">
      <ApplicationTable
        :loading="loading"
        v-if="resetTable"
        :filter_type="filterType"
        :headers_prop="headers"
        :list_url_prop="listUrl"
        :side_filters="filter_choosen"
        :propSearch="propSearch">
      </ApplicationTable>
    </template>
    <template slot="rightSide">
      <round-filter
        :filters_prop="filters"
        @refilter="refilter"
        @resetAll="refilter"
        :loading="loadingFilter">
        <template slot="extra_content">
          <p class="mb-1">
            <router-link
              :to="{name: 'schedulerMeetings-list'}"
              class="text-underline text-primary"
              >{{ $t('admission.scheduler_meetings') }}
            </router-link>
            <span>({{ meetingsCount }})</span>
          </p>
          <p class="mb-1">
            <router-link
              :to="{name: 'subscribers-list'}"
              class="text-underline text-primary"
              >{{ $t('admission.Subscribers') }}
            </router-link>
            <span>({{ subscribersCount }})</span>
          </p>
          <div class="w-full mb-10">
            <vs-input
              :label-placeholder="$t('form.search_by_name_email_or_ID')"
              v-model="searchContent"
              v-validate="{email: searchContent.includes('@')}"
              class="w-full"
              name="email"
              icon="icon icon-search"
              icon-pack="feather"
              @change="refilter" />
            <span class="text-sm text-danger" v-if="errors.first('email')">{{
              errors.first('email') | text_formatter
            }}</span>
          </div>
        </template>
      </round-filter>
      <vs-popup
        class="popup"
        :title="$t('admission.change_division')"
        :active.sync="changeDivisionPopup">
        <form @submit.prevent="submitChangeDivision" ref="recommend_form">
          <div class="vx-row">
            <div class="vx-col mb-4 lg:w-1/2 w-full block">
              <div class="vs-input--label">
                {{ $t('admission.select_division') }}
                <sup class="text-danger">*</sup>
              </div>
              <v-select
                v-model="changeDivisionForm.division_id"
                v-validate="'required'"
                :class="{'validation-error': errors.has('divisions')}"
                :clearable="true"
                :closeOnSelect="true"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                :options="divisions"
                label="name"
                :reduce="(option) => option.id"
                name="divisions"
                @input="getGrades(changeDivisionForm.division_id)" />
              <span
                v-show="errors.has('divisions')"
                class="text-danger text-sm">
                {{ errors.first('divisions') }}
              </span>
            </div>
            <div class="vx-col mb-4 lg:w-1/2 w-full">
              <div class="vs-input--label">
                {{ $t('admission.select_grades') }}
                <sup class="text-danger">*</sup>
              </div>
              <v-select
                v-model="changeDivisionForm.grade_id"
                v-validate="'required'"
                :class="{'validation-error': errors.has('grades')}"
                :clearable="true"
                :closeOnSelect="true"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                :options="grades"
                label="name"
                :reduce="(option) => option.id"
                name="grades" />
              <span v-show="errors.has('grades')" class="text-danger text-sm">
                {{ errors.first('grades') }}
              </span>
            </div>
            <div class="vx-col w-full flex justify-end">
              <vs-button @click="submitChangeDivision" :disabled="issubmitted">
                {{ $t('basic.submit') }}
              </vs-button>
            </div>
          </div>
        </form>
      </vs-popup>
    </template>
  </ListPageWithCreateButton>
</template>

<script>
import ApplicationTable from './components/ApplicationsTable'
// import ApplicationTable from '@/components/general/datatable/DataTable2.vue'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'

import RoundFilter from '@/components/general/RoundFilter' //Right-sidebar
import StatusCounter from '@/components/general/multiDataStatusCounter'

const ApplicationRepository = RepositoryFactory.get(
  'AdmissionModule',
  'applications'
)
import {commonDataMixin} from '@/mixins.js'

export default {
  name: 'ApplicationList',
  components: {
    ApplicationTable,
    StatusCounter,
    RoundFilter //Right-sidebar
  },
  mixins: [commonDataMixin],
  data() {
    return {
      propSearch: '',
      loading: false,
      searchContent: '',
      list: [],
      listUrl: '/application/filter',
      activeTab: 'New',
      filterType: 'New',
      newCount: 0,
      shortlisted: 0,
      rejected: 0,
      onAssesment: 0,
      waitingList: 0,
      disqualified: 0,
      qualified: 0,
      meetingsCount: 0,
      subscribersCount: 0,

      statistics_data: [
        {
          id: 0,
          icon: 'EyeIcon',
          value: 'new',
          sub: [
            {
              name: this.$t('status.all_new'),
              count: 0
            },
            {
              name: this.$t('status.new'),
              count: 0
            }
          ]
        },
        {
          id: 1,
          icon: 'SmileIcon',
          value: 'shortlisted',
          color: 'primary',
          sub: [
            {
              name: this.$t('status.all_shortlisted'),
              count: 0
            },
            {
              name: this.$t('status.shortlisted'),
              count: 0
            }
          ]
        },
        {
          id: 2,
          value: 'on Assessment',
          icon: 'XCircleIcon',
          sub: [
            {
              name: this.$t('status.all_on_assessment'),
              count: 0
            },
            {
              name: this.$t('status.on_assessment'),
              count: 0
            }
          ]
        },
        {
          id: 3,
          icon: 'ClockIcon',
          value: 'waiting list',
          sub: [
            {
              name: this.$t('status.all_waiting_list'),
              count: 0
            },
            {
              name: this.$t('status.waiting_list'),
              count: 0
            }
          ]
        },
        {
          id: 4,
          icon: 'CheckCircleIcon',
          value: 'qualified',
          sub: [
            {
              name: this.$t('status.all_accepted'),
              count: 0
            },
            {
              name: this.$t('admission.Accepted'),
              count: 0
            }
          ]
        },
        {
          id: 5,
          icon: 'XCircleIcon',
          value: 'disqualified',
          sub: [
            {
              name: this.$t('status.all_disqualified'),
              count: 0
            },
            {
              name: this.$t('status.disqualified'),
              count: 0
            }
          ]
        },
        {
          id: 6,
          icon: 'UserXIcon',
          value: 'rejected',
          sub: [
            {
              name: this.$t('status.all_rejected'),
              count: 0
            },
            {
              name: this.$t('status.rejected'),
              count: 0
            }
          ]
        }
      ],
      headers: [
        {
          minWidth: 160,
          headerName: this.$t('RoundManager.S'),
          field: 'id',
          checkboxSelection: true,
          width: 150,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true
        },
        {
          minWidth: 250,
          headerName: this.$t('basic.name'),
          field: 'student_name'
        },
        {
          minWidth: 160,
          headerName: this.$t('basic.division'),
          field: 'divisionName'
        },
        {
          minWidth: 160,
          headerName: this.$t('basic.grade'),
          field: 'gradeName'
        },

        {
          minWidth: 190,
          headerName: this.$t('admission.recommended_by'),
          field: 'recommended_by'
        },
        {
          minWidth: 190,
          headerName: this.$t('admission.roundName'),
          field: 'round_name'
        },
        {
          minWidth: 160,
          headerName: this.$t('RoundManager.Status'),
          colId: 'status',
          field: 'status',
          width: 240,
          cellRendererFramework: 'CellRendererStatus',
          cellRendererParams: {
            status_prop: this.status
          }
        },
        // { headerName: 'siblings', field: 'sibling' },
        {
          minWidth: 250,
          headerName: this.$t('basic.actions'),
          filter: false,
          field: 'transactions',
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            actions_prop: [
              {
                title: this.$t('basic.lock'),
                route_name: 'lock',
                parameter_name: 'id',
                icon: 'LockIcon',
                navigate_action: false,
                dropDown: false,
                no_action: true
              },
              {
                title: this.$t('basic.show'),
                resource: 'application',
                action: 'view',
                parameter_name: 'id',
                icon: 'EyeIcon',
                navigate_action: false,
                dropDown: false,
                no_action: true
              },
              {
                title: this.$t('basic.edit'),
                action: 'edit',
                resource: 'round',
                parameter_name: 'id',
                icon: 'Edit3Icon',
                navigate_action: false,
                dropDown: true
              },
              {
                title: 'pdf',
                resource: 'application',
                action: 'ApplicationPDF',
                parameter_name: 'id',
                icon: 'BookOpenIcon',
                navigate_action: false,
                dropDown: false,
                no_action: true
              }
            ],
            viewed_prop: this.viewed,
            parameter_name: 'id',
            enable_enroll: false
          }
        }
      ],

      export_file: false,
      statisticsData: [],
      viewed: 0,
      filter_choosen: {
        schools: [],
        divisions: [],
        sections: [],
        grades: [],
        gender: [],
        religion: [],
        nationality: [],
        language: [],
        second_language: [],
        alumni: [],
        sibling: [],
        bus_required: [],
        has_special_needs: [],
        status: ['New'],
        rounds: [],
        academic_year: '',
        general_search: '',
        is_viewed: []
      },

      filters: [
        {
          id: 13,
          name: this.$t('basic.academic_year'),
          payload_name: 'academic_year',
          by: []
        },
        {
          id: 1,
          name: this.$t('basic.schools'),
          payload_name: 'schools',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 2,
          name: this.$t('basic.divisions'),
          payload_name: 'divisions',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 14,
          name: this.$t('basic.sections'),
          payload_name: 'sections',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 3,
          name: this.$t('basic.grades'),
          payload_name: 'grades',
          by: []
        },
        {
          id: 4,
          name: this.$t('basic.gender'),

          payload_name: 'gender',
          by: []
        },
        {
          id: 5,
          name: this.$t('admission.religion'),
          payload_name: 'religion',
          by: []
        },
        {
          id: 6,
          name: this.$t('admission.nationality'),
          payload_name: 'nationality',
          by: []
        },
        {
          id: 7,
          name: this.$t('admission.spoken_language'),
          payload_name: 'language',
          by: []
        },
        {
          id: 8,
          name: this.$t('admission.second_language'),
          payload_name: 'second_language',
          by: []
        },
        {
          id: 15,
          name: this.$t('admission.rounds'),
          payload_name: 'rounds',
          by: []
        },
        {
          id: 9,
          name: this.$t('admission.alumni'),
          payload_name: 'alumni',

          by: [
            {name: this.$t('admission.has_alumni'), id: 1, checked: false},
            {
              name: this.$t('admission.has_not_alumni'),
              id: 0,
              checked: false
            }
          ]
        },
        {
          id: 10,
          name: this.$t('admission.siblings'),
          payload_name: 'sibling',

          by: [
            {name: this.$t('admission.has_siblings'), id: 1, checked: false},
            {
              name: this.$t('admission.has_not_siblings'),
              id: 0,
              checked: false
            }
          ]
        },
        {
          id: 11,
          name: this.$t('admission.transportation'),
          payload_name: 'bus_required',

          by: [
            {name: this.$t('admission.bus_Required'), id: 1, checked: false},
            {
              name: this.$t('admission.no_bus_required'),
              id: 0,
              checked: false
            }
          ]
        },
        {
          id: 12,
          name: this.$t('admission.special_needs'),
          payload_name: 'has_special_needs',

          by: [
            {
              name: this.$t('admission.has_special_needs'),
              id: 1,
              checked: false
            },
            {
              name: this.$t('admission.has_not_special_needs'),
              id: 0,
              checked: false
            }
          ]
        },
        {
          id: 14,
          name: this.$t('admission.view_status'),
          payload_name: 'is_viewed',
          singleSeletable: true,
          by: [
            {
              name: this.$t('admission.view'),
              id: 1,
              checked: false
            },
            {
              name: this.$t('admission.notview'),
              id: 0,
              checked: false
            }
          ]
        }
      ],
      changeDivisionPopup: false,
      resetTable: true,
      issubmitted: false,
      changeDivisionForm: {
        grade_id: '',
        division_id: ''
      },
      loadingFilter: false
    }
  },
  watch: {},
  async created() {
    this.getStatisticsData()
    this.updateFilters(this.filter_choosen)
    // this.getSchools()
    this.$root.$on('row:status', () => {
      this.getStatisticsData()
    })
    this.$root.$on('onChange:schools', this.getDivision)
    this.$root.$on('onChange:divisions', this.getFilteredSection)
    this.$root.$on('onChange:sections', this.getFilteredGrades)
    this.$root.$on('sheduleMeetingAdded', this.getMeetings)
    this.$root.$on('activeChangeDivisionPopup', this.openPopup)
    this.$root.$on('resetAction', this.resetTableAfterRecommend)

    this.getDivisionsWithoutBranchId().then((res) => {
      this.divisions = res.map((el) => {
        const item = {
          id: el.id,
          name: el.long_name
        }
        return item
      })
    })
  },
  mounted() {},
  beforeDestroy() {
    this.$root.$off('row:status')
    this.$root.$off('onChange:schools')
    this.$root.$off('onChange:divisions')
    this.$root.$off('onChange:sections')
    this.$root.$off('sheduleMeetingAdded')
    this.$root.$off('activeChangeDivisionPopup')
    this.$root.$off('resetAction')
  },

  methods: {
    search() {
      this.propSearch = this.searchContent
    },
    async openPopup({id, student}) {
      this.VSLoading()
      this.changeDivisionForm = {
        id,
        grade_id: student.grade_id,
        division_id: student.division_id
      }
      await this.getGrades(student.division_id)
      this.stopVSLoading()
      this.changeDivisionPopup = true
    },
    async submitChangeDivision() {
      this.issubmitted = true
      const isValid = await this.$validator.validateAll()
      if (!isValid) {
        this.issubmitted = false
        return this.errMsg()
      }
      this.VSLoading()
      const payload = {
        ...this.changeDivisionForm
      }
      this.resetTable = false
      ApplicationRepository.changeDivision(payload)
        .then((res) => {
          this.successMsg()
          this.resetChangeDivision()
        })
        .catch((err) => {
          this.errMsg(err)
        })
        .finally(() => {
          this.resetTable = true
          this.stopVSLoading()
        })
    },
    resetChangeDivision() {
      this.changeDivisionPopup = false
      this.issubmitted = false

      this.$validator.reset()
      this.changeDivisionForm = Object.assign({})
    },
    resetTableAfterRecommend() {
      this.resetTable = false
      setTimeout(() => {
        this.resetTable = true
      })
    },
    openModal(tab) {
      this.activeCard = tab
      //this.showModal = true;
    },
    async getStatisticsData() {
      const data = await ApplicationRepository.getApplicationsStatistics(
        this.filter_choosen
      )
      this.statisticsData = data.data
      // this.setStatisticsData()

      // this.statistics_data = this.statistics_data.map(({ ...state_data }) => {
      //   const found = this.statisticsData.find(
      //     (state) => state.status.toLowerCase() === state_data.name.toLowerCase()
      //   )
      //   return {
      //     count: found ? found.count : 0,
      //     ...state_data
      //   }
      // })
      this.statistics_data.forEach((el) => {
        const found = this.statisticsData.allStatistics.find(
          (state) => state.all_status.toLowerCase() === el.value.toLowerCase()
        )
        el.sub[0].count = found ? found.all_count : 0
      })
      this.statistics_data.forEach((el) => {
        const found = this.statisticsData.roundStatistics.find(
          (state) => state.status.toLowerCase() === el.value.toLowerCase()
        )
        el.sub[1].count = found ? found.count : 0
      })
    },
    changeCounterFilter(filter) {
      console.log(filter)
      // ApplicationRepository.getApplications().then((res) => {
      //   this.list = res.data.data
      // })
      // this.listUrl = '/application'

      this.filterType = filter
      this.activeTab = filter
      const statistics_data_filter = this.statistics_data.find(
        (el) => el.value === filter
      )
      let status = ''
      if (filter == 'qualified') {
        status = 4
      } else if (filter == 'new') {
        status = 0
      } else if (filter == 'shortlisted') {
        status = 1
      } else if (filter == 'on Assessment') {
        status = 2
      } else if (filter == 'waiting list') {
        status = 3
      } else if (filter == 'Schedule a meeting') {
        status = 5
      } else if (filter == 'enrolled') {
        status = 6
      } else if (filter == 'withdrawn') {
        status = 7
      } else if (filter == 'disqualified') {
        status = -1
      } else if (filter == 'rejected') {
        status = -2
      } else if (filter == 'declined') {
        status = -3
      } else {
        status = -4
      }
      this.filter_choosen.status = [status]
      /*this.filter_choosen.status = filter ? [statistics_data_filter.id] : []*/
      /*console.log('this.filter_choosen.status', this.filter_choosen.status)*/
    },
    setStatisticsData() {
      this.newCount = 0
      this.rejected = 0
      this.shortlisted = 0
      this.waitingList = 0
      this.qualified = 0
      this.disqualified = 0
      this.onAssesment = 0
      for (let i = 0; i < this.statisticsData.length; i++) {
        switch (this.statisticsData[i].status.toLowerCase()) {
          case 'new':
            this.newCount = this.statisticsData[i].count
            break
          case 'rejected':
            this.rejected = this.statisticsData[i].count
            break
          case 'shortlisted':
            this.shortlisted = this.statisticsData[i].count
            break
          case 'on Assessment':
            this.onAssesment = this.statisticsData[i].count
            break
          case 'waiting list':
            this.waitingList = this.statisticsData[i].count
            break
          case 'qualified':
            this.qualified = this.statisticsData[i].count
            break
          case 'disqualified':
            this.disqualified = this.statisticsData[i].count
            break
          default:
            break
        }
      }
    },

    ////////////////////////////
    // Round Filter
    ///////////////////////////
    getGender(choosen) {
      ApplicationRepository.getGender(choosen)
        .then((res) => {
          this.filters[5].by = []
          res.data.map((el) => {
            if (el.gender_id != 2) {
              this.filters[5].by.push({
                name: el.gender_id === 0 ? 'male' : 'female',
                id: el.gender_id,
                checked: false
              })
            }
          })
        })
        .catch((err) => console.error('getGender', err))
    },
    getReligion(choosen) {
      ApplicationRepository.getReligion(choosen)
        .then((res) => {
          this.filters[6].by = []
          res.data.map((el) => {
            this.filters[6].by.push({
              name: el.name,
              id: el.id,
              checked: false
            })
          })
        })
        .catch((err) => console.error('getReligion', err))
    },
    getNationalities(choosen) {
      ApplicationRepository.getNationalities(choosen)
        .then((res) => {
          this.filters[7].by = []
          res.data.map((el) => {
            this.filters[7].by.push({
              name: el.Nationality,
              id: el.id,
              checked: false
            })
          })
        })
        .catch((err) => console.error('getNationalities', err))
    },
    getLanguage(choosen) {
      ApplicationRepository.getLanguage(choosen)
        .then((res) => {
          this.filters[8].by = []
          res.data.map((el) => {
            this.filters[8].by.push({
              name: el.name,
              id: el.id,
              checked: false
            })
          })
        })
        .catch((err) => console.error('getLanguage', err))
    },
    getAllLanguages() {
      ApplicationRepository.getAllLanguages()
        .then((res) => {
          this.filters[9].by = []
          res.data
            .filter((el) => el.name !== 'Arabic' && el.name !== 'English')
            .map((el) => {
              this.filters[9].by.push({
                name: el.name,
                id: el.id,
                checked: false
              })
            })
        })
        .catch((err) => console.error('getLanguage', err))
    },
    getAllRounds() {
      ApplicationRepository.getAllRounds()
        .then((res) => {
          this.filters[10].by = []
          res.data.map((el) => {
            this.filters[10].by.push({
              name: `  ${el.name ? el.name : `${el.from} - ${el.to}`}`,
              id: el.id,
              checked: false
            })
          })
        })
        .catch((err) => console.error('getRounds', err))
    },

    getDivision(chosen) {
      this.loadingFilter = true
      this.filters[2].by = []
      this.filters[3].by = []
      this.filters[4].by = []
      console.log(chosen)
      this.getFilterData({schools: chosen.schools})
        .then((res) => {
          res.divisions.map((el) => {
            this.filters[2]['by'].push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
        })
        .finally(() => {
          this.loadingFilter = false
        })
    },
    getFilteredSection(chosen) {
      this.loadingFilter = true
      this.filters[3].by = []
      this.filters[4].by = []
      const data = {
        divisions: chosen.divisions,
        schools: chosen.schools
      }
      this.getFilterData(data)
        .then((res) => {
          res.sections.map((el) => {
            this.filters[3]['by'].push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
        })
        .finally(() => {
          this.loadingFilter = false
        })
    },
    getFilteredGrades(chosen) {
      this.loadingFilter = true
      this.filters[4].by = []
      const data = {
        sections: chosen.sections,
        divisions: chosen.divisions,
        schools: chosen.schools
      }
      this.getFilterData(data)
        .then((res) => {
          res.grades.map((el) => {
            this.filters[4]['by'].push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
        })
        .finally(() => {
          this.loadingFilter = false
        })
    },

    updateFilters(choosen) {
      this.getGender(choosen)
      this.getReligion(choosen)
      this.getNationalities(choosen)
      this.getLanguage(choosen)
      this.getAllLanguages()
      this.getMeetings()
      this.getSubscribers()
      this.getAllRounds()
      this.getAllAcademic().then((res) => {
        this.filters[0].by = []
        res.map((el) => {
          this.filters[0].by.push({
            name: el.name,
            id: el._id,
            checked: false
          })
        })
      })
      this.getFilterData().then((res) => {
        res.schools.map((el) => {
          this.filters[1]['by'].push({
            id: el.id,
            name: el.branch_name,
            checked: false
          })
        })
      })
    },
    getFiltratedData(choosen) {
      ApplicationRepository.applicationsFilter(choosen).then((res) => {
        this.list = res.data.data
      })
    },
    refilter(filters) {
      this.filter_choosen["general_search"] = this.searchContent;

      if (filters.length > 0) {
        filters.forEach((el) => {
          if (!Array.isArray(el.checked)) el.checked = [el.checked]
        })
        filters.forEach((e) => {
          this.filter_choosen[e.name] = e.checked
        })
      }
    },
    ///////////////////////////
    // Meetings && Subscribers
    //////////////////////////
    getMeetings() {
      ApplicationRepository.getMeetings()
        .then((res) => {
          this.meetingsCount = res.data.total
        })
        .catch((err) => console.error(err))
    },
    getSubscribers() {
      ApplicationRepository.getSubscribers()
        .then((res) => {
          this.subscribersCount = res.data.total
        })
        .catch((err) => console.error(err))
    }
  },
  computed: {}
}
</script>
