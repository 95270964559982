<template>
  <div>
    <template v-if="sectionCoordinator">
      <SectionCoodinatorForm
        :teacher_id="teacher_id"
        :sectionCoordinator_prop.sync="sectionCoordinator"
      ></SectionCoodinatorForm>
    </template>
  </div>
</template>
<script>
import SectionCoodinatorForm from '../../../components/SectionCoodinatorForm.vue'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const addTeacherRepo = RepositoryFactory.get(
  'teachersGatewayModule',
  'addTeacher'
)
export default {
  name: 'CreateSectionCoodinator',
  components: {
    SectionCoodinatorForm
  },
  created () {
    this.getSingleSectionsCoodinator()
  },
  data () {
    return {
      sectionCoordinator: null,
      teacher_id: this.$route.params.id
    }
  },
  methods: {
    getSingleSectionsCoodinator () {
      addTeacherRepo
        .getSingleSectionsCoodinator(this.teacher_id)
        .then((res) => {
          this.sectionCoordinator = res.data
        })
        .catch((err) => {
          this.errMsg(err.data.message)
        })
    }
  }
}
</script>
