import gradebook from './pages/gradebook/List'

export default [
  //////////////////////
  // Grade Book
  /////////////////////
  {
    path: 'SchoolPrincipal/gradebook/',
    name: 'SchoolPrincipalGradebook',
    component: gradebook,

    meta: {
      breadcrumb: [{title: 'grading.gradebook', url: '', active: true}],
      rule: 'gradebookPrincipalCoordinators',
      pageTitle: 'grading.gradebook'
    }
  }
]
