<template>
  <vx-card class="p-3 pt-6 pb-6 md:p-6">
    <h1>Reports cards</h1>
    <div class="justify-between">
      <div class="flex flex-wrap justify-between"></div>
      <div v-if="!isDP & !isPYP">
        <div v-for="(item, index) in file" :key="item">
          <a
            :href="file[index]"
            v-if="!fileError && file.length > index"
            style="display: block"
            download
            target="_blank"
            ref="file"
          >
            <vs-button class="mb-base mr-0 ml-auto">
              {{
                file[index].includes('final-transcript')
                  ? $t('basic.final_transcript')
                  : $t('basic.report_card_q') + ' ' + (index + 1)
              }}</vs-button
            >
          </a>
        </div>
      </div>
      <div v-else-if="!isDP & isPYP">
        <div v-for="(item, index) in file" :key="item" class="mt-8">
          <a
            :href="file[index]"
            v-if="!fileError && file.length > index"
            style="display: block"
            download
            target="_blank"
            ref="file"
          >
            <vs-button class="mb-base mr-0 ml-auto"
              >{{
                file[index].includes('final-transcript')
                  ? $t('basic.final_transcript')
                  : ordinal(index + 1) + ' ' + $t('basic.trimester')
              }}
            </vs-button>
          </a>
        </div>
      </div>
      <div v-else>
        <div v-for="(item, index) in file" :key="item">
          <a
            :href="file[index]"
            v-if="!fileError && file.length > index"
            style="display: block"
            download
            target="_blank"
            ref="file"
          >
            <vs-button class="mb-base mr-0 ml-auto">{{
              file[index].includes('final-transcript')
                ? $t('basic.final_transcript')
                : $t('basic.report_card_q') + ' ' + (index + 1)
            }}</vs-button>
          </a>
        </div>
      </div>
      <img
        v-if="fileError || !file.length"
        src="@/assets/images/pages/Untitled.001.jpeg"
      />
    </div>
  </vx-card>
</template>
<script>
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const StudentRepository = RepositoryFactory.get('StudentsModule', 'student')
export default {
  data () {
    return {
      file: {},
      done: false,
      fileError: false
    }
  },
  mounted () {
    this.getFile()
  },
  computed: {
    isDP () {
      let isDP = false
      if (this.$attrs.student.gradeName.slice(0, 2) === 'DP') {
        isDP = true
      }
      return isDP
    },
    isPYP () {
      if (this.$attrs.student.gradeName.slice(0, 3) === 'PYP') {
        return true
      }
      return false
    }
  },
  methods: {
    getFile () {
      // console.log(this.authUser.student.student_id, this.$route.params.id)
      const id = this.authUser.student.id
      StudentRepository.StudentReports(id)
        .then((res) => {
          this.file = res.data
        })
        .catch((err) => {
          this.fileError = true
        })
    },
    ordinal (number) {
      const english_ordinal_rules = new Intl.PluralRules('en', {
        type: 'ordinal'
      })
      const suffixes = {
        one: 'st',
        two: 'nd',
        few: 'rd',
        other: 'th'
      }
      const suffix = suffixes[english_ordinal_rules.select(number)]
      return number + suffix
    }
  }
}
</script>
