<template>
  <ListPageWithCreateButton :showCreateBtn="false">
    <template slot="listDataContent">
      <Datatable
        :headers_prop="headers"
        list_url_prop="admission/Subscribers" />
    </template>
  </ListPageWithCreateButton>
</template>

<script>
import Datatable from '@/components/general/datatable/DataTable2.vue'

export default {
  name: 'Subscribers',
  components: {
    Datatable
  },
  data() {
    return {
      headers: [
        {
          minWidth: 100,
          headerName: this.$t('basic.ID'),
          field: 'id',
          checkboxSelection: true,
          width: 100,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true
        },
        {
          minWidth: 160,
          headerName: this.$t('basic.name'),
          field: 'name'
        },
        {
          minWidth: 160,
          headerName: this.$t('basic.email'),
          field: 'email'
        },
        {
          minWidth: 160,
          headerName: this.$t('basic.mobile'),
          field: 'mobile'
        }
      ]
    }
  },
  watch: {},
  created() {},
  mounted() {},
  beforeDestroy() {},

  methods: {}
}
</script>
