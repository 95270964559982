import fr from './fr/'
import en from './en/'
import de from './de/'
import pt from './pt/'

export default {
  en,
  fr,
  de,
  pt
}
