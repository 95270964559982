<template>
  <div>
    <user-info :userInfo="userData"/>
    <fund-form @formAddFundSubmited="addFund"/>
  </div>
</template>
<script>

// Components
import { commonDataMixin } from '@/mixins'
import  userInfo from '../components/userInfo'
import  fundForm from '../components/addFundForm'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const allWalletRepository = RepositoryFactory.get(
  'walletRepository',
  'walletRepository'
)
export default {
  name: 'add-fund',
  mixins: [commonDataMixin],
  data () {
    return {
      user_id: this.$route.params.user_id,
      userData: {}
    }
  },
  components: {
    userInfo,
    fundForm
  },
  methods: {
    getStudentInfo () {
      this.VSLoading()
      allWalletRepository.getStudentAmount(this.user_id).then(res => {
        this.userData = res.data
        this.stopVSLoading()
      })
    },
    addFund (payload) {
      if (payload.amount) {
        allWalletRepository.addFund(payload).then(response => {
          this.successMsg(this.$t('wallet.addFundSuccessfully'))
          this.userData.total_amount = response.data[0].total_amount
          this.$router.push({name: 'fund-history', params: this.user_id})
        }).catch(err => {
          this.errMsg(err)
        })
      } else {
        allWalletRepository.changeWalletLimit({
          id:payload.user_id,
          limit: payload.limit
        }).then(response => {
          this.successMsg(this.$t('wallet.limitChangedSuccessfully'))
          this.$router.push({name: 'fund-history', params: this.user_id})
        }).catch(err => {
          this.errMsg(err)
        })
      }
    }
  },
  created () {
    this.getStudentInfo()
  }
}
</script>
