<template>
  <ListPageWithCreateButton
    createRoutName="create-round-manager"
    :showCreateBtn="!this.checkUserRole('SMD')"
    :createBtnName="$t('admission.NewRound')">
    <template slot="listDataContent">
      <data-table :headers_prop="headers" list_url_prop="/round" />
    </template>
  </ListPageWithCreateButton>
</template>

<script>
import DataTable from '@/components/general/datatable/DataTable2.vue'

export default {
  name: 'RoundManager',
  components: {DataTable},
  data() {
    return {
      headers: [
        {
          minWidth: 160,
          headerName: this.$t('RoundManager.S'),
          field: 'id',
          checkboxSelection: true,
          width: 200,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true
        },
        // { headerName: this.$t('RoundManager.Round'), field: 'round', width:200},
        {
          minWidth: 250,
          headerName: this.$t('basic.name'),
          field: 'name',
          valueGetter:
            '`${data.name ? data.name : data.from + " - " + data.to }`'
        },
        {
          minWidth: 160,
          headerName: this.$t('RoundManager.TotalDays'),
          field: 'total_days'
        },
        {
          minWidth: 160,
          headerName: this.$t('RoundManager.From'),
          field: 'from',
          width: 290
        },
        {
          minWidth: 160,
          headerName: this.$t('RoundManager.To'),
          field: 'to',
          width: 290
        },
        // { headerName: this.$t('RoundManager.CreatedBy'), field: 'owner.name', width:290 },
        // { headerName: this.$t('RoundManager.Contact'), field: 'contacts.email.0.email', width:390 },
        {
          minWidth: 160,
          headerName: this.$t('RoundManager.timeStatus'),
          field: 'timeStatus',
          width: 240,
          cellRendererFramework: 'CellRendererStatus',
          cellRendererParams: {
            status_prop: this.status
          }
        },
        {
          minWidth: 160,
          headerName: this.$t('RoundManager.active'),
          field: 'is_enable_is_active',
          width: 240,
          cellRendererFramework: 'CellRendererStatus',
          cellRendererParams: {
            status_prop: this.status
          }
        }
      ],

      status: [
        {
          id: 0,
          title: this.$t('status.new'),
          value: 'new',
          icon: 'StarIcon',
          count: '22',
          link: '#!'
        },
        {
          id: 1,
          title: this.$t('status.shortlisted'),
          value: 'shortlisted',
          icon: 'ClipboardIcon',
          count: '22',
          link: '#!'
        },
        {
          id: 2,
          title: this.$t('status.on_assessment'),
          value: 'on_assessment',
          icon: 'UserCheckIcon',
          count: '22',
          link: '#!'
        },
        {
          id: 3,
          title: this.$t('status.waiting_list'),

          value: 'w_list',
          icon: 'WatchIcon',
          count: '22',
          link: '#!'
        },
        {
          id: 4,
          title: this.$t('status.disqualified'),
          value: 'disqualified',
          icon: 'XCircleIcon',
          count: '22',
          link: '#!'
        },
        {
          id: 5,
          title: this.$t('status.qualified'),
          value: 'qualified',
          icon: 'CheckCircleIcon',
          count: '22',
          link: '#!'
        }
      ],
      export_file: false
    }
  },

  created() {
    this.toggleDatatbleActionsCol()
  },
  methods: {
    ///////////////////////////////////
    // Hide , show datatble Actions
    ///////////////////////////////////
    toggleDatatbleActionsCol() {
      const actionsCol = {
        minWidth: 160,
        headerName: this.$t('basic.actions'),
        field: 'transactions',
        filter: false,
        width: 240,
        cellRendererFramework: 'CellRendererActions',
        cellRendererParams: {
          actions_prop: [
            {
              title: this.$t('main.Edit'),
              route_name: 'update-round-manager',
              parameter_name: 'id',
              icon: 'Edit3Icon',
              navigate_action: true,
              // showIf: (data) =>
              //   data.timeStatus === 'ongoing' || data.timeStatus === 'upcoming'
            },
            {
              title: this.$t('main.activate'),
              icon: 'CheckCircleIcon',
              resource: '/round',
              action: 'enable',
              statusTarget: {key: 'is_enable', value: 1},
              showIf: (data) => {
                const dateToSplit = data.to.split('/'),
                  dateFromSplit = data.from.split('/'),
                  dateTo = `${dateToSplit[1]}/${dateToSplit[0]}/${dateToSplit[2]}`,
                  dateFrom = `${dateFromSplit[1]}/${dateFromSplit[0]}/${dateFromSplit[2]}`

                // return new Date() <= new Date(dateTo) || new Date() <= new Date(dateFrom)
                return !data.remove_buttons
              }
            },
            {
              title: this.$t('main.deactivate'),
              icon: 'SlashIcon',
              resource: '/round',
              action: 'disable',
              color: 'danger',
              statusTarget: {key: 'is_enable', value: 0},
              showIf: (data) => {
                const dateToSplit = data.to.split('/'),
                  dateFromSplit = data.from.split('/'),
                  dateTo = `${dateToSplit[1]}/${dateToSplit[0]}/${dateToSplit[2]}`,
                  dateFrom = `${dateFromSplit[1]}/${dateFromSplit[0]}/${dateFromSplit[2]}`

                // return (new Date() <= new Date(dateTo) || new Date() <= new Date(dateFrom)) && data.timeStatus !== 'ongoing'
                return !data.remove_buttons && data.timeStatus !== 'ongoing'
              }
            },
            {
              title: this.$t('RoundManager.close_this_round'),
              icon: 'StopCircleIcon',
              resource: '/round',
              action: 'stopped',
              color: 'danger',
              statusTarget: {key: 'is_enable', value: 0},
              showIf: (data) => data.timeStatus === 'ongoing'
            }
            // {
            //   title:this.$t('main.disable'),
            //   icon:'SlashIcon',
            //   resource:'/round',
            //   action:'disable',
            //   statusTarget:{key:'timeStatus', value:'ongoing'}
            // }
          ]
        }
      }
      // if SND
      if (!this.checkUserRole('SMD')) this.headers.push(actionsCol)
    }
  }
}
</script>
