

<template>
  <div class="vx-row">
    <!-- HORIZONTAL LAYOUT -->
    <div class="vx-col md:w w-full mb-base">
      <vx-card class="p-3 pt-6 pb-6 md:p-6">
        <!-- Schools -->
        <div class="vx-row mb-6">
          <div class="vx-col lg:w-1/5 md:w-1/4 w-full mb-2">
            <label>
              {{ $t("RoundManager.ChooseSchool") }}
              <sup class="text-danger">*</sup>
            </label>
          </div>
          <div class="vx-col lg:w-2/5 md:w-3/4 w-full mb-2">
            <v-select :placeholder="`${$t('RoundManager.AllSchools')}`" label="short_name"
              :reduce="(short_name) => short_name.id" v-model="round.schools" v-validate="'required'" class="w-full"
              name="school" :options="schools" @input="changeSchool" />


            <span v-if="issueSubmitted && errors.has('school')" class="text-danger text-sm">{{ errors.first("school") |
              text_formatter }}</span>
          </div>
        </div>
        <!-- Round name -->
        <div class="vx-row mb-6 ">
          <div class="vx-col lg:w-1/5 w-full mb-2">
            <label>{{ $t("basic.name") }}
              <sup class="text-danger">*</sup>
            </label>
          </div>
          <div class="vx-col lg:w-2/5 md:w-3/4 w-full mb-2">
            <vs-input v-validate="'required'" v-model="round.roundData.name" class="w-full" name="name"
              :placeholder="$t('basic.name')" />
            <span class="text-danger text-sm">{{
              errors.first("name") | text_formatter
            }}</span>
          </div>
        </div>
        <!-- Dates -->


        <div class="vx-row mb-6">
          <div class="vx-col lg:w-1/5 w-full mb-2">
            <label>
              {{ $t("RoundManager.RoundDates") }}
              <sup class="text-danger">*</sup>
            </label>
          </div>
          <div class="vx-col lg:w-1/5 md:w-1/2 w-full mb-2">
            <flat-pickr :placeholder="`${$t('RoundManager.Start')}`" :config="dateTimePickerConfig"
              v-model="round.roundData.from" name="start" v-validate="'required'" class="w-full"
              @input="onDateFromChange" />


            <span v-if="issueSubmitted && errors.has('start')" class="text-danger text-sm">{{ errors.first("start") |
              text_formatter }}</span>
          </div>
          <div class="vx-col lg:w-1/5 md:w-1/2 w-full mb-2">
            <flat-pickr :placeholder="`${$t('RoundManager.End')}`" :config="dateTimePickerConfigTo"
              v-model="round.roundData.to" name="end" v-validate="'required'" class="w-full" />


            <span v-if="issueSubmitted && errors.has('end')" class="text-danger text-sm">{{ errors.first("end") |
              text_formatter }}</span>
          </div>
        </div>
        <!-- Divisions -->
        <div class="vx-row mb-6">
          <div class="vx-col lg:w-1/5 md:w-1/4 w-full mb-2">
            <span>
              {{ $t("RoundManager.ChooseDivision") }}
              <sup class="text-danger">*</sup>
            </span>
          </div>
          <div class="vx-col lg:w-2/5 md:w-3/4 w-full mb-2">
            <v-select multiple :placeholder="`${$t('RoundManager.AllDivisions')}`" v-model="round.divisions"
              v-validate="'required'" class="w-full" name="Division" @input="changeDivision" :options="divisions"
              label="long_name" :reduce="(long_name) => long_name.id"
              :noOptionsText="$t('RoundManager.choose_school_first')" />
            <span v-if="issueSubmitted && errors.has('Division')" class="text-danger text-sm">{{ errors.first("Division")
              | text_formatter }}</span>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col lg:w-1/5 md:w-1/4 w-full mb-2">
            <span>
              {{ $t("RoundManager.ChooseGrads") }}
              <sup class="text-danger">*</sup>
            </span>
          </div>
          <div class="vx-col lg:w-2/5 md:w-3/4 w-full mb-2">
            <v-select multiple :placeholder="`${$t('RoundManager.AllGrads')}`" v-model="round.grades"
              v-validate="'required'" class="w-full" name="Grad" :options="grads" label="name" :reduce="(name) => name.id"
              :noOptionsText="$t('RoundManager.choose_division_first')" />


            <span v-if="issueSubmitted && errors.has('Grad')" class="text-danger text-sm">{{ errors.first("Grad") |
              text_formatter }}</span>
          </div>
        </div>
        <!-- RoundManager -->
        <div class="vx-row mb-6">
          <div class="vx-col lg:w-1/5 md:w-1/4 w-full mb-2">
            <span>
              {{ $t("RoundManager.RoundManager") }}
              <sup class="text-danger">*</sup>
            </span>
          </div>
          <div class="vx-col lg:w-2/5 md:w-3/4 w-full mb-2">
            <v-select multiple :placeholder="`${$t('RoundManager.AllManagers')}`" v-model="round.managers"
              v-validate="'required'" class="w-full" name="RoundManger" :options="users" label="name"
              :reduce="(name) => name.id" />
            <span v-if="issueSubmitted && errors.has('RoundManger')" class="text-danger text-sm">{{
              errors.first("RoundManger") | text_formatter }}</span>
          </div>
        </div>
        <!-- Contact Person -->
        <div class="vx-row mb-6">
          <div class="vx-col lg:w-1/5 md:w-1/4 w-full mb-2">
            <span>
              {{ $t("RoundManager.ContactPerson") }}
              <sup class="text-danger">*</sup>
            </span>
          </div>
          <div class="vx-col lg:w-2/5 md:w-3/4 w-full mb-2">
            <v-select multiple :placeholder="`${$t('RoundManager.AllPersons')}`" v-model="round.userContacts"
              v-validate="'required'" class="w-full" name="ContactPerson" :options="users" label="name"
              :reduce="(name) => name.id" />


            <span v-if="issueSubmitted && errors.has('ContactPerson')" class="text-danger text-sm">{{
              errors.first("ContactPerson") | text_formatter }}</span>
          </div>
        </div>
        <vs-divider />
        <!---------------------- RoundContacts ---------------------->
        <div class="vx-row mb-6">
          <div class="vx-col w-full mb-2">
            <h5 class="mb-4">
              {{ $t("RoundManager.RoundContacts") }}
              <sup class="text-danger">*</sup>
            </h5>
          </div>
          <!-- Tels -->
          <div class="vx-col lg:w-1/4 md:w-1/2 w-full mb-4">
            <!-- add new TEL -->
            <vx-input-group class="mb-2">
              <vs-input :placeholder="`${$t('main.Tel')}`" name="Tel" v-validate="`required|numeric|length:10`"
                v-model="round.contacts.tel[0].number"></vs-input>
              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button color="primary" type="border" @click="addTel()" icon-pack="feather"
                    icon="icon-plus"></vs-button>
                </div>
              </template>
            </vx-input-group>
            <!-- added tels -->
            <vx-input-group class="mb-2" :key="index" v-for="(tel, index) in addedTels">
              <vs-input :placeholder="`${$t('main.Tel')}`" v-model="tel.number" class="selectExample w-full"
                v-validate="`required|numeric|length:10`"></vs-input>
              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button color="danger" type="border" @click="removeTel(index)" icon-pack="feather"
                    icon="icon-minus"></vs-button>
                </div>
              </template>
            </vx-input-group>


            <span v-if="issueSubmitted && errors.has('Tel')" class="text-danger text-sm">{{ errors.first("Tel") |
              text_formatter }}</span>
          </div>
          <!-- fax -->
          <div class="vx-col lg:w-1/4 md:w-1/2 w-full mb-4">
            <!-- Add new fax -->
            <vx-input-group class="mb-2">
              <vs-input :placeholder="`${$t('main.Fax')}`" v-model="round.contacts.fax[0].number"
                class="selectExample w-full" name="Fax" v-validate="`numeric|length:8`"></vs-input>
              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button color="primary" type="border" icon-pack="feather" icon="icon-plus"
                    @click="addFax"></vs-button>
                </div>
              </template>
            </vx-input-group>
            <!-- added faxes -->
            <vx-input-group class="mb-2" :key="index" v-for="(fax, index) in addedFaxes">
              <vs-input :placeholder="`${$t('main.Fax')}`" v-model="fax.number" class="w-full"
                v-validate="`required|numeric|length:8`"></vs-input>
              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button color="danger" type="border" icon-pack="feather" icon="icon-minus"
                    @click="removeFax(index)"></vs-button>
                </div>
              </template>
            </vx-input-group>
            <span v-if="issueSubmitted && errors.has('Fax')" class="text-danger text-sm">{{ errors.first("Fax") |
              text_formatter }}</span>
          </div>
          <!-- Mobile -->
          <div class="vx-col lg:w-1/4 md:w-1/2 w-full mb-4">
            <!-- Add new mobile -->
            <vx-input-group class="mb-2">
              <vs-input :placeholder="`${$t('main.Mobile')}`" v-model="round.contacts.mobile[0].number"
                class="selectExample w-full" name="Mobile" v-validate="`required|numeric|length:11`"></vs-input>
              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button color="primary" type="border" icon-pack="feather" icon="icon-plus"
                    @click="addMobile"></vs-button>
                </div>
              </template>
            </vx-input-group>
            <!-- added mobiles -->
            <vx-input-group class="mb-2" :key="index" v-for="(mobile, index) in addedMobiles">
              <vs-input :placeholder="`${$t('main.Mobile')}`" v-model="mobile.number" class="selectExample w-full"
                v-validate="`required|numeric|length:11`"></vs-input>
              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button color="danger" type="border" icon-pack="feather" icon="icon-minus"
                    @click="removeMobile(index)"></vs-button>
                </div>
              </template>
            </vx-input-group>
            <span v-if="issueSubmitted && errors.has('Mobile')" class="text-danger text-sm">{{ errors.first("Mobile") |
              text_formatter }}</span>
          </div>
          <!-- email -->
          <div class="vx-col lg:w-1/4 md:w-1/2 w-full mb-4">
            <!-- Add new email -->
            <vx-input-group class="mb-2">
              <vs-input :placeholder="`${$t('main.Email')}`" v-model="round.contacts.email[0].email"
                class="selectExample w-full" name="Email" v-validate="`required|email`"></vs-input>
              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button color="primary" type="border" icon-pack="feather" icon="icon-plus"
                    @click="addEmail"></vs-button>
                </div>
              </template>
            </vx-input-group>
            <!-- added emails -->
            <vx-input-group class="mb-2" :key="index" v-for="(email, index) in addedEmails">
              <vs-input :placeholder="`${$t('main.Email')}`" v-model="email.email"
                class="selectExample w-full"></vs-input>
              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button color="danger" type="border" icon-pack="feather" icon="icon-minus"
                    @click="removeEmail(index)"></vs-button>
                </div>
              </template>
            </vx-input-group>
            <span v-if="issueSubmitted && errors.has('Email')" class="text-danger text-sm">{{ errors.first("Email") |
              text_formatter }}</span>
          </div>
        </div>
        <!---------------------- /RoundContacts ---------------------->
        <vs-divider />
        <!---------------------- Features ---------------------->
        <!-- <div class="vx-row mb-6">
<div class="vx-col sm:w-3/3 w-full">
<h5>{{ $t("RoundManager.Features") }}</h5>
</div>
</div>
<div class="vx-row mb-4 w-full md:w-3/4">
<div class="vx-col sm:w-1/6 mb-2">
<vs-switch v-model="round.roundData.score_management" />
</div>
<div class="vx-col md:w-1/3 w-full mb-2">
<label>{{ $t("RoundManager.ScoreManagement") }}</label>
</div>


<div
class="vx-col md:w-1/2 w-full mb-2"
v-show="round.roundData.score_management"
>
<vs-select
autocomplete
:placeholder="`${$t('RoundManager.ScoreSchema')}`"
v-model="round.roundData.score_manager_id"
class="selectExample w-full"
:disabled="scores.length > 0 ? false : true"
>
<vs-select-item
:key="index"
:value="item.id"
:text="item.id"
v-for="(item, index) in scores"
/>
</vs-select>
<div v-if="error" class="text-danger text-sm">
{{
$t("RoundManager.please_select_score_manager_schema")
| text_formatter
}}
</div>
</div>
</div>


<div class="vx-row mb-4 w-full md:w-1/2">
<div class="vx-col sm:w-1/4 mb-2">
<vs-switch v-model="round.roundData.show_medical_survey" />
</div>
<div class="vx-col md:w-3/4 w-full mb-2">
<label>{{ $t("RoundManager.ShowMedicalSurvey") }}</label>
</div>
</div>


<div class="vx-row mb-4 w-full md:w-1/2">
<div class="vx-col sm:w-1/4 mb-2">
<vs-switch v-model="round.roundData.show_fees" />
</div>
<div class="vx-col md:w-3/4 w-full mb-2">
<label>{{ $t("RoundManager.ShowFees") }}</label>
</div>
</div>


<div class="vx-row mb-4 w-full md:w-1/2">
<div class="vx-col sm:w-1/4 mb-2">
<vs-switch v-model="round.roundData.greeting_message" />
</div>
<div class="vx-col md:w-3/4 w-full mb-2">
<label>{{ $t("RoundManager.GreetingMessage") }}</label>
</div>
</div>


<div class="vx-row mb-4 w-full md:w-1/2">
<div class="vx-col sm:w-1/4 mb-2">
<vs-switch v-model="round.roundData.early_bird_active" />
</div>
<div class="vx-col md:w-3/4 w-full mb-2">
<label>{{ $t("RoundManager.ShowEarlyBid") }}</label>
</div>
</div>


<div class="vx-row mb-4 w-full md:w-1/2">
<div class="vx-col sm:w-1/4 mb-2">
<vs-switch v-model="round.roundData.set_submission_time_frame" />
</div>
<div class="vx-col md:w-3/4 w-full mb-2">
<label>{{ $t("RoundManager.SetSubmissionTimeframe") }}</label>
</div>
</div>


<div class="vx-row mb-4 w-full md:w-1/2">
<div class="vx-col sm:w-1/4 mb-2">
<vs-switch v-model="round.roundData.upload_photo_mask" />
</div>
<div class="vx-col md:w-3/4 w-full mb-2">
<label>{{ $t("RoundManager.UploadPhotoMask") }}</label>
</div>
</div>


<div class="vx-row mb-4 w-full md:w-1/2">
<div class="vx-col sm:w-1/4 mb-2">
<vs-switch v-model="round.roundData.show_ssr" />
</div>
<div class="vx-col md:w-3/4 w-full mb-2">
<label>{{ $t("RoundManager.ShowSSR") }}</label>
</div>
</div> -->
        <!---------------------- /Features ---------------------->
        <div class="flex justify-between">
          <vs-button color="warning" @click="$router.push({ name: 'RoundManager' })" type="border" class="mb-2">{{
            $t("basic.cancel") }}
          </vs-button>
          <vs-button class="mr-3 mb-2" :disabled="isSubmitDisabled" @click="saveRound">
            {{ $t("basic.submit") }}
          </vs-button>
        </div>


        <!-- CODE -->
      </vx-card>
    </div>
  </div>
</template>


<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'


import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const RoundsRepository = RepositoryFactory.get('AdmissionModule', 'rounds')


export default {
  name: 'Edit',
  components: { flatPickr },
  data() {
    return {
      error: false,
      disabled: false,
      isSubmitDisabled: false,
      allGrades: true,
      arrGrads: [],
      schools: [],
      divisions: [],
      grads: [],
      users: [],
      scores: [],
      dateTimePickerConfig: {
        minDate: null,
        maxDate: null,
        dateFormat: 'Y-m-d'


      },
      dateTimePickerConfigTo: {
        minDate: '',
        dateFormat: 'Y-m-d'
      },
      round: {
        schools: [],
        divisions: [],
        grades: [],
        managers: [],
        userContacts: [],
        contacts: {
          tel: [{ number: '' }],
          fax: [{ number: '' }],
          mobile: [{ number: '' }],
          email: [{ email: '' }]
        },
        roundData: {
          score_manager_id: '',
          from: '',
          to: '',
          name: '',
          early_bird_active: true,
          score_management: true,
          show_medical_survey: true,
          show_fees: true,
          greeting_message: true,
          set_submission_time_frame: true,
          upload_photo_mask: true,
          show_ssr: true
        }
      },
      issueSubmitted: false,
      current_locale: this.$store.getters['main/currentLocale']
    }
  },
  created() {


    this.getDropDownData()
    const round_id = this.$route.params.id
    this.getRound(round_id)
  },
  methods: {
    changeSchool() {
      const schoolCount = this.round.schools
      if (schoolCount.length == 0) {
        this.round.divisions = []
        this.round.grades = []


        this.disabled = true
      }
      this.getSchoolDivisions(this.round.schools)
    },
    getSchoolDivisions(schoolIdes) {
      RoundsRepository.getSchoolDivisions(schoolIdes).then((res) => {
        this.divisions = []
        this.divisions = res.data
      })
    },
    //get greades of spacific division
    getGrades(id) {
      let Grads = []
      RoundsRepository.getGrades(id).then((res) => {
        this.grads = []
        this.grads = res.data
        Grads = res.data
      })
      return Grads
    },
    getDivsGrades(divisions) {
      RoundsRepository.getDivsGrades(divisions).then((res) => {
        this.grads = res.data
      })
    },
    //when selected division is one get all greades related to it else
    //select all division of thi grade


    changeDivision() {
      const divCount = this.round.divisions
      this.round.grades = []
      this.grades = []
      if (divCount.length === 1) {
        this.disabled = false
        this.allGrades = false
        this.getGrades(this.round.divisions[0])
      } else if (divCount.length > 1) {
        this.disabled = true
        const data = { divIds: this.round.divisions }
        this.getDivsGrades(data)
      }
    },


    getRound(id) {
      RoundsRepository.getRound(id).then((res) => {
        this.round = res.data
        this.changeSchool()
        // this.dateTimePickerConfig.maxDate = res.data.roundData.to
        // console.log('res.data.roundData.to', res.data.roundData.to)
        // this.dateTimePickerConfig.minDate = res.data.roundData.from
        this.dateTimePickerConfig.minDate = new Date() < new Date(res.data.roundData.from) ? new Date() : res.data.roundData.from
        // console.log('new Date() < new Date(res.data.roundData.from) ? new Date() : res.data.roundData.from', new Date() < new Date(res.data.roundData.from) ? new Date() : res.data.roundData.from)
      })
    },
    onFromChange(selectedDates, dateStr) {
      this.$set(this.dateTimePickerConfig, 'minDate', dateStr)
    },


    onDateFromChange() {
      const dateFrom = this.round.roundData.from
      this.dateTimePickerConfigTo.minDate = `${(dateFrom).split('-')[0]}-${(dateFrom).split('-')[1]}-${Number((dateFrom).split('-')[2]) + 1}`
    },
    onToChange(selectedDates, dateStr) {
      this.$set(this.dateTimePickerConfig, 'maxDate', dateStr)
    },


    addTel() {
      if (this.round.contacts.tel[0].number !== '') {
        this.round.contacts.tel.push({ ...this.round.contacts.tel[0] })
        this.round.contacts.tel[0].number = ''
      }
    },


    removeTel(index) {
      this.round.contacts.tel.splice(index + 1)
    },


    addFax() {
      if (this.round.contacts.fax[0].number !== '') {
        this.round.contacts.fax.push({ ...this.round.contacts.fax[0] })
        this.round.contacts.fax[0].number = ''
      }
    },


    removeFax(index) {
      this.round.contacts.fax.splice(index + 1)
    },


    addMobile() {
      if (this.round.contacts.mobile[0].number !== '') {
        this.round.contacts.mobile.push({ ...this.round.contacts.mobile[0] })
        this.round.contacts.mobile[0].number = ''
      }
    },


    removeMobile(index) {
      this.round.contacts.mobile.splice(index + 1)
    },


    addEmail() {
      if (this.round.contacts.email[0].email !== '') {
        this.round.contacts.email.push({ ...this.round.contacts.email[0] })
        this.round.contacts.email[0].email = ''
      }
    },


    removeEmail(index) {
      this.round.contacts.email.splice(index + 1)
    },


    getDropDownData() {
      RoundsRepository.getDropDownData().then((res) => {
        this.schools = res.schools.map(el => el[this.current_locale])
        this.grads = res.grades
        this.divisions = res.divisions
        this.disabled = false
        // if (this.allGrades) {
        // if (this.disabled) {
        // const arr = []
        // this.grads.forEach((value, index) => {
        // arr.push(value.id)
        // })
        // this.round.grades = arr
        // }
        // }
      })


      RoundsRepository.getUsers().then((res) => {
        this.users = res.users
      })


      RoundsRepository.getScores().then((res) => {
        this.scores = res.data
      })
    },
    saveRound() {
      this.isSubmitDisabled = true
      if (this.round.roundData.score_management)
        this.error = round.roundData.score_manager_id == ''




      if ((this.round.roundData.score_management && this.round.roundData.score_manager_id !== '') || !this.round.roundData.score_management) {
        const round_id = this.$route.params.id
        this.issueSubmitted = true


        this.$validator.validate().then((valid) => {
          if (valid) {
            RoundsRepository.editRound(round_id, this.round)
              .then((res) => {
                this.$router.push({ name: 'RoundManager' })
                this.successMsg(this.$t('RoundManager.round_has_been_edited_successfully'))


                this.isSubmitDisabled = false
              })
              .catch(err => {
                this.isSubmitDisabled = false
                this.errMsg(err.data.data)
              })
          } else {
            this.isSubmitDisabled = false
            this.errMsg()
          }
        })
      } else {
        this.isSubmitDisabled = false
      }
    }
  },
  computed: {
    addedTels() {
      return this.round.contacts.tel.length > 1
        ? this.round.contacts.tel.slice(1, this.round.contacts.tel.length)
        : []
    },
    addedFaxes() {
      return this.round.contacts.fax.length > 1
        ? this.round.contacts.fax.slice(1, this.round.contacts.fax.length)
        : []
    },
    addedMobiles() {
      return this.round.contacts.mobile.length > 1
        ? this.round.contacts.mobile.slice(1, this.round.contacts.mobile.length)
        : []
    },
    addedEmails() {
      return this.round.contacts.email.length > 1
        ? this.round.contacts.email.slice(1, this.round.contacts.email.length)
        : []
    }
  }
}
</script>


<style scoped>
.background-none {
  background-color: transparent !important;
}
</style>



