<template>
  <online-classes :student="studentData"></online-classes>
</template>
<script>
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const StudentRepository = RepositoryFactory.get('StudentsModule', 'student')
import onlineClasses from './Components/Left-Sidebar/OnlineClasses.vue'

export default {
  name:'studentOnlineClass',
  components:{onlineClasses},
  data () {
    return {
      studentData:{}
    }
  },

  methods: {
    async getStudentData (
      repoFunction = 'getdata',
      params = this.$route.params.id || this.authUser.uid
    ) {
      this.VSLoading()
      await StudentRepository[repoFunction](params)
        .then(res => { this.studentData = res.application })
        .catch(err => {
          if (this.authUserStudent) {
            const data = {
              student_id: this.authUserStudent.student_id,
              id: this.authUserStudent.id,
              student: this.authUserStudent,
              student_full_details: { photo: this.authUserStudent.photo },
              fullName: this.authUserStudent.fullName,
              name: this.authUserStudent.fullName,
              divisionName: this.authUserStudent.divisionName || null,
              gradeName: this.authUserStudent.gradeName || null,
              round_id: this.authUserStudent.round_id
            }
            this.studentData = data
          }
        })
    }
  },
  created () {
    this.getStudentData()
  }
}
</script>
