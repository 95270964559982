import axios from 'axios'

const resource = '/student'
export default {
  AddStudent (payload) {
    return axios.post(`${resource}/main-info`, payload)
  },
  getSingleStudent (id) {
    return axios.get(`${resource}/${id}/main-info`)
  },
  updateStudent (payload) {
    return axios.put(`${resource}/${payload.id}/main-info`, payload.payload)
  },
  getClassByGradeId (id) {
    return axios.get(`/classes/all-active-classes/${id}`)
  }
}
