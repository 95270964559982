import List from './pages/List.vue'

export default [{
    path: '/',
    name: 'agendaList',
    component: List,
    meta: {
        breadcrumb: [{
                title: 'home',
                url: '/home'
            },
            {
                title: 'agenda.agenda',
                url: '/agenda',
                active: true
            }


        ],
        pageTitle: 'agenda.agenda',
        rule: 'SectionsCoodinator'
    }
}]