
import allStudentsRepository from './allStudentsRepository'
import allParentsRepository from './allParentsRepository'
import smdRepositories from '../sub_modules/smd/Repositories/index'

export default {
  allStudents: allStudentsRepository,
  allParents:allParentsRepository,
  ...smdRepositories
}
