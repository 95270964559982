<template>
  <vs-card class="p-3 py-6 md:p-6">
    <form @submit.prevent="formSubmitted">
      <h4 class="mb-5">{{ $t("cas.Adding_New_CAS_task") }}</h4>

      <div class="vx-row w-full mt-10 items-center">
        <div class="vx-col md:w-1/4 w-full">
          <label
            >{{ $t("cas.Cas_task_criteria") }}
            <sup class="text-danger">*</sup>
          </label>
        </div>
        <div class="vx-col lg:w-1/2 md:w-3/4 w-full">
          <v-select
            multiple
            :options="criterias"
            label="name"
            :reduce="(cat) => cat.id"
            v-validate="'required'"
            v-model="task.criteria_ids"
            name="criteria"
          />
          <span class="text-danger text-sm">{{
            errors.first("criteria") | text_formatter
          }}</span>
        </div>
      </div>
      <div class="vx-row w-full mt-6 items-center">
        <div class="vx-col md:w-1/4 w-full">
          <label
            >{{ $t("cas.CAS_Task_Duration") }}
            <sup class="text-danger">*</sup>
          </label>
        </div>
        <div
          class="
            vx-col
            lg:w-1/2
            md:w-3/4
            w-full
            flex
            md:flex-row
            flex-warp flex-col
          "
        >
          <div class="flex-1 w-full mr-2 md:mb-0 mb-3">
            <flat-pickr
              placeholder="start Date"
              :config="configDatepickerFrom"
              v-model="task.start_date"
              name="start_date"
              v-validate="'required'"
              class="w-full"
            />
            <span class="text-danger text-sm">{{
              errors.first("start_date") | text_formatter
            }}</span>
          </div>

          <div class="flex-1 w-full">
            <flat-pickr
              placeholder="End Date"
              :config="configDatepickerTo"
              v-model="task.end_date"
              name="end_date"
              v-validate="'required'"
              class="w-full"
            />
            <span class="text-danger text-sm">{{
              errors.first("end_date") | text_formatter
            }}</span>
          </div>
        </div>
      </div>
      <div class="vx-row w-full mt-6 items-center">
        <div class="vx-col md:w-1/4 w-full">
          <label
            >{{ $t("cas.CAS_Learning_Outcomes") }}
            <sup class="text-danger">*</sup>
          </label>
        </div>
        <div class="vx-col lg:w-1/2 md:w-3/4 w-full">
          <v-select
            multiple
            :options="outcomes"
            label="name"
            :reduce="(cat) => cat.id"
            v-validate="'required'"
            v-model="task.out_comes"
            name="outcomes"
          />
          <span class="text-danger text-sm">{{
            errors.first("outcomes") | text_formatter
          }}</span>
        </div>
      </div>
      <div class="vx-row w-full mt-6 items-center">
        <div class="vx-col md:w-1/4 w-full">
          <label
            >{{ $t("cas.CAS_Task_Proposed_Hours") }}
            <sup class="text-danger">*</sup>
          </label>
        </div>
        <div class="vx-col lg:w-1/2 md:w-3/4 w-full">
          <v-select
            :options="Array.from({ length: 31 }, (v, k) => k + 1)"
            v-validate="'required'"
            v-model="task.time"
            name="hours"
          />

          <span class="text-danger text-sm">{{
            errors.first("hours") | text_formatter
          }}</span>
        </div>
      </div>
      <div class="vx-row w-full mt-6 items-center">
        <div class="vx-col md:w-1/4 w-full">
          <label
            >{{ $t("cas.CAS_Task_Name") }}
            <sup class="text-danger">*</sup>
          </label>
        </div>
        <div class="vx-col lg:w-1/2 md:w-3/4 w-full">
          <vs-input
            v-validate="'required'"
            v-model="task.name"
            class="w-full"
            name="name"
          />
          <span class="text-danger text-sm">{{
            errors.first("name") | text_formatter
          }}</span>
        </div>
      </div>
      <div class="vx-row w-full mt-6 items-center">
        <div class="vx-col md:w-1/4 w-full">
          <label
            >{{ $t("cas.CAS_Task_Description") }}
            <sup class="text-danger">*</sup>
          </label>
        </div>
        <div class="vx-col lg:w-1/2 md:w-3/4 w-full">
          <vs-textarea
            v-validate="'required'"
            v-model="task.description"
            class="w-full mb-0"
            name="name"
          />
          <span class="text-danger text-sm">{{
            errors.first("name") | text_formatter
          }}</span>
        </div>
      </div>
      <div
        class="
          flex flex-wrap
          md:justify-between
          justify-center
          items-center
          md:flex-row
          flex-col
          mt-10
        "
      >
        <vs-button
          color="warning"
          type="border"
          @click="goTo('tasksList')"
          class="mt-5"
          >{{ $t("basic.discard") }}</vs-button
        >
        <vs-button color="gray" @click="saveAndDraft" class="mt-5">{{
          $t("form.save_as_draft_and_close")
        }}</vs-button>
        <vs-button @click="formSubmitted" class="mt-5">{{
          $t("cas.Submit_and_wait_for_coordinator_validation")
        }}</vs-button>
      </div>
    </form>
  </vs-card>
</template>
<script>

// APIs
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const CasRepository = RepositoryFactory.get(
  'CASRepository',
  'TaskRepository'
)

// Datepicker
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

// Mixins
import mixins from '../../mixins'

export default {
  name:'createTask',
  mixins:[mixins],
  components:{flatPickr},
  data () {
    return {
      hours:[],
      task:{
        'name':'',
        'description':'',
        'start_date':'',
        'end_date':'',
        'time':null,
        'criteria_ids':[],
        'out_comes':[],
        'status_id':1, // 1 => active , 2 => Draft
        'status':'Active' // 1 => active , 2 => Draft

      },
      /////////////////////////
      // DatePicker
      /////////////////////////
      configDatepickerFrom:{
        dateFormat: 'Y-m-d'
      },
      configDatepickerTo:{
        dateFormat: 'Y-m-d',
        minDate:null
      }
    }
  },
  watch:{
    'task.start_date' (v) {
      this.configDatepickerTo.minDate = v

    }
  },
  methods: {
    /////////////////////
    //APIs
    /////////////////////
    getCriteria () {
      CasRepository.getCriteria()
        .then(res => {
          const data = res.data
          this.criterias = data.map((el) =>  {
            return {
              name: el[this.current_locale],
              id: el.id
            }
          })

        })
        .catch()
    },
    getOutcomes () {
      CasRepository.getOutcomes()
        .then(res => {
          const data = res.data
          this.outcomes =  data.map((el) =>  {
            return {
              name: el[this.current_locale],
              id: el.id
            }
          })
        })
        .catch()
    },
    createTask () {
      this.loading()
      CasRepository.createTask(this.task)
        .then(() => {
          this.successMsg()

          this.goTo('tasksList')
        })
        .catch(err => {
          this.errMsg(err)
        })
        .finally(() => this.stopLoading())

    },


    //////////////////////////
    // Submitted Form
    /////////////////////////

    formSubmitted () {
      this.$validator.validate()
        .then(() => this.createTask())
        .catch(() => this.errMsg())
    },
    //////////////////////////
    // saveAndDraft
    /////////////////////////
    saveAndDraft () {
      this.task.status_id = 2
      this.task.status = 'Draft'
      this.task.submit = 'not_submited_student_yet'
      this.formSubmitted()

    }
  },
  created () {
    this.getCriteria()
    this.getOutcomes()
    this.configDatepickerFrom.minDate = this.currentDate
    this.configDatepickerTo.minDate = this.currentDate
  }
}
</script>
