export default {
  state: {
    poiYearsGroup: [],
    filteredPoiYearsGroup: [],
    defaultUnitsData: null,
    academicYearsOptions: null,
    futureAcademicYearsOptions: null,
    yearsGroupOptions: null,
    currentAcademicYear: null,
    selectedAcademicYear: null,
    selectedYearGroup: {id: -1, name: 'All'},
    showFilterBar: false,
    modal: {}
  },
  mutations: {
    poiYearsGroup (state, payload) {
      state.poiYearsGroup.push(payload)
      state.showFilterBar = true
    },
    resetYearsGroup (state, payload) {
      state.poiYearsGroup = payload
      state.defaultUnitsData = null
      state.showFilterBar = false
    },
    replacePoi (state, payload) {
      state.poiYearsGroup = payload
      if (payload.length) {
        state.showFilterBar = true
      }
      //state.selectedAcademicYear = state.academicYearsOptions.find(item => item.id === payload[0].academic_year_id);
    },
    addDefaultUnitsData (state, payload) {
      state.defaultUnitsData = payload
    },
    modifyUnit (state, payload) {
      const yearGroup = state.poiYearsGroup.find(item => item.id === payload.groupDetails.id)
      const unit = yearGroup.units.find(unit => unit.unit_academic_id === payload.unitDetails.unit_academic_id)
      unit.items = Object.assign({}, unit.items, payload.unitDetails.items)
      unit.description = payload.unitDetails.description
      unit.title = payload.unitDetails.title
    },
    setAcademicYearsOptions (state, payload) {
      state.academicYearsOptions = payload
    },
    setFutureAcademicYearsOptions (state, payload) {
      state.futureAcademicYearsOptions = payload
    },
    setYearsGroupOptions (state, payload) {
      state.yearsGroupOptions = payload
    },
    setCurrentAcademicYear (state, payload) {
      state.currentAcademicYear = payload
    },
    setSelectedAcademicYear (state, payload) {
      state.selectedAcademicYear = payload
    },
    setSelectedYearGroup (state, payload) {
      state.selectedYearGroup = payload
    },
    manageModal (state, payload) {
      state.modal = payload
    }
  },
  actions: {
    AddPoiYearsGroup ({commit, state}, payload) {
      commit('poiYearsGroup', payload)
    },
    resetPoiYearsGroup ({commit}, payload) {
      commit('resetYearsGroup', payload)
    },
    replacePoi ({commit, state}, payload) {
      commit('replacePoi', payload)
    },
    defaultPoiUnit ({commit}, payload) {
      commit('addDefaultUnitsData', payload)
    },
    modifyPoiUnit ({commit}, payload) {
      commit('modifyUnit', payload)
    },
    setAcademicYearsOptions ({commit, state}, payload) {
      commit('setAcademicYearsOptions', payload)
    },
    setFutureAcademicYearsOptions ({commit, state}, payload) {
      commit('setFutureAcademicYearsOptions', payload)
    },
    setYearsGroupOptions ({commit, state}, payload) {
      commit('setYearsGroupOptions', payload)
    },
    setCurrentAcademicYear ({commit, state}, payload) {
      commit('setCurrentAcademicYear', payload)
    },
    setSelectedAcademicYear ({commit, state}, payload) {
      commit('setSelectedAcademicYear', payload)
    },
    setSelectedYearGroup ({commit, state}, payload) {
      commit('setSelectedYearGroup', payload)
    },
    manageModal ({commit}, payload) {
      commit('manageModal', payload)
    }
  },
  getters: {
    getPoiYearsGroup (state) {
      return state.poiYearsGroup
    },
    getFilteredPoiYearsGroup (state) {
      let data
      //** filter Data Based on AcademicYear & YearGroup **//
      if (state.selectedAcademicYear) {
        data = state.poiYearsGroup.filter(item => item.academic_year_id === state.selectedAcademicYear.id)
        if (state.selectedYearGroup && state.selectedYearGroup.id !== -1) {
          data = data.filter(item => item.grade_id === state.selectedYearGroup.id)
        }
      } else {
        data = state.poiYearsGroup
      }
      return data
    },
    getPoiYearUnitById: (state) => (yearGroupId, unitId) => {
      //** Store Getters With Query **//
      const yearGroup = state.poiYearsGroup.find(yearGroup => yearGroup.id === yearGroupId)
      return yearGroup.units.find(units => units.id === unitId)
    },
    getPoiYearGroupByGradeId: (state) => (yearGroupId, gradeId) => {
      //** Store Getters With Query **//
      return state.poiYearsGroup.find(yearGroup => yearGroup.id === yearGroupId && yearGroup.grade_id === gradeId)
    },
    getPoiAllUnits (state) {
      return state.poiYearsGroup.map(group => group.units)[0]
    },
    getGroupedUnitsDetails (state) {
      let data
      const unitsData = {
        key_concepts: [],
        related_concepts: [],
        atl_skills: [],
        learner_profile: []
      }
      //** filter Data Based on AcademicYear & YearGroup **//
      if (state.selectedAcademicYear) {
        data = state.poiYearsGroup.filter(item => item.academic_year_id === state.selectedAcademicYear.id)

        if (state.selectedYearGroup && state.selectedYearGroup.id !== -1) {
          data = data.filter(item => item.grade_id === state.selectedYearGroup.id)
        }

      } else {
        data = state.poiYearsGroup
      }

      //** Loop OverData To Ge New Calculated Data **//
      data.forEach(group => {
        group.units.forEach(unit => {
          if (unit.items.key_concepts.length) {
            unitsData.key_concepts.push(
              ...unit.items.key_concepts.map(item => {
                return {...item, unitId: unit.id, groupId: group.id}
              })
            )
          }

          if (unit.items.related_concepts.length) {
            unitsData.related_concepts.push(
              ...unit.items.related_concepts.map(item => {
                return {...item, unitId: unit.id, groupId: group.id}
              })
            )
          }


          if (unit.items.atl_skills.length) {
            unitsData.atl_skills.push(
              ...unit.items.atl_skills.map(item => {
                return {...item, unitId: unit.id, groupId: group.id}
              })
            )
          }


          if (unit.items.learner_profile.length) {
            unitsData.learner_profile.push(
              ...unit.items.learner_profile.map(item => {
                return {...item, unitId: unit.id, groupId: group.id}
              })
            )
          }

        })
      })
      return unitsData
    },
    getDefaultUnits (state) {
      return state.defaultUnitsData
    },
    getAcademicYearsOptions (state) {
      return state.academicYearsOptions
    },
    getFutureAcademicYearsOptions (state) {
      return state.futureAcademicYearsOptions
    },
    getYearsGroupOptions (state) {
      return state.yearsGroupOptions
    },
    getCurrentAcademicYear (state) {
      return state.currentAcademicYear
    },
    getSelectedAcademicYear (state) {
      return state.selectedAcademicYear
    },
    getSelectedYearGroup (state) {
      return state.selectedYearGroup
    },
    filterBarState (state) {
      return state.showFilterBar
    }
  }
}
