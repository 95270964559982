import axios from 'axios'
import {getHeaders} from '@/axios'
import authState from './moduleAuthState'


export default {
  login (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/login', payload)
        .then(res => {
          const response = res
          setLoginData(context, response)
          resolve(res)
        })
        .catch(error => {
          window.localStorage.clear()
          reject(error)
        })
    })
  },
  ///////////////////////
  // Act As
  ///////////////////////
  actAs (context, userId) {
    return new Promise((resolve, reject) => {
      axios.get(`act-as/${userId}`)
        .then(res => {
          const response = res
          setActAsData(context)
          setLoginData(context, response)
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  endActAs (context) {
    return new Promise((resolve, reject) => {
      axios.get('end-act-as')
        .then(async (res) => {

          resolve(authState.actAsOriginalAuthUser)
          await restActAsData(context)

        })
        .catch(error => {
          reject(error)
        })
    })
  }


}

const setLoginData = function (context, response) {
  context.commit('setUser', response)

  context.commit('setAccessToken', response)
  context.commit('setRefreshToken', response)
  if (response.last_route) context.commit('setLastRoute', response.last_route)
}

const setActAsData =  (context) => {

  const originalAuthUser = {
    authUser:authState.authUser,
    access_token:authState.access_token,
    refresh_token: authState.refresh_token,
    last_route: window.location.hash
  }
  context.commit('setActAsOriginalAuthUser', originalAuthUser)
}
const restActAsData =  (context) => {
  context.commit('resetAuthUser', authState.actAsOriginalAuthUser.authUser)
  context.commit('setAccessToken', authState.actAsOriginalAuthUser)
  context.commit('setRefreshToken', authState.actAsOriginalAuthUser)
  context.commit('setLastRoute', authState.actAsOriginalAuthUser)
  context.commit('clearActAsData')
}

