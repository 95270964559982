<template>
  <div>
    <!-- Table -->
    <vs-table
      :max-items="max_items"
      pagination
      description
      description-body="2"
      :data="list"
      v-model="selected_data"
      search
      multiple
    >
      <template slot="header">
        <div class="vx-row mb-4 mt-4 w-full">
          <div class="vx-col md:w-1/3 w-full">
            <!-- ITEMS PER PAGE -->
            <!-- <vs-dropdown vs-trigger-click class="cursor-pointer mr-6">
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2">{{ max_items }} of {{ list.length }}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>

              <vs-dropdown-menu>
                <vs-dropdown-item
                  @click="max_items=Math.floor((list.length * value)/100)"
                  v-for="(value,i) in data_show_nums_percent=[30,60,80]"
                  :key="i"
                >
                  <span>{{ Math.floor((list.length * value)/100) }}</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="max_items=list.length">
                  <span>{{ list.length }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>-->

            <slot name="left_elms"></slot>
          </div>
          <div class="vx-col md:w-1/2 w-full">
            <slot name="center_elms"></slot>
          </div>
        </div>
      </template>
      <template slot="thead">
        <vs-th
          :key="indextr"
          v-for="(tr, indextr) in headers"
          :sort-key="tr.field"
        >{{ $t(headers[indextr]["headerName"]) }}</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :key="indx" v-for="(row_data, field_name ,indx ) in tr">
            <span :class="feild_class(row_data,field_name)">{{row_data}}</span>
          </vs-td>
          <vs-td>
            <vs-button
              :key="indx"
              v-for="(row_data2, indx) in actions"
              :to="{ name: row_data2.route_name, params: { id: tr.id } }"
              radius
              color="dark"
              type="flat"
              icon-pack="feather"
              :icon="row_data2.icon"
            ></vs-button>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
// import axios from '@/axios'

export default {
  name: 'DataTable',
  props: {
    headers_prop: {
      type: Array,
      required: true
    },
    list_url_prop: {
      type: String,
      required: false
    },
    export_prop: {
      type: Boolean,
      default: false
    },
    actions_prop: {
      type: Array,
      required: false
    },
    list_prop: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      headers: this.headers_prop,
      list_url: this.list_url_prop,
      selected_data: [],
      list:this.list_prop,
      export_file: this.export_prop,
      actions: this.actions_prop,
      max_items:5,
      feild_class (row_data, field_name) {
        const match_feilds = ['status']

        if (match_feilds.some(el => field_name.includes(el))) {
          const data = row_data.toString().toLowerCase().replace(' ', '_').replace('.', ''),
            classes = `${field_name}--${data}`
          return classes
        }
      },
      data_show_nums () {
        const nums_arr = [],
          nums_percent = [30, 60],
          list_len = this.list.length
        for (let i = 1; i <= nums_percent.length; i++) {
          nums_arr.push((list_len * i) / 100)
        }
        return nums_arr
      }
    }
  },
  created () {},
  methods: {
    // axios
    //   .get(this.list_url)
    //   .then(res => {
    //      this.data = res.data
    //   })
    //   .catch(err => {
    //TODO:search for alert component to show errors
    //   })
  }
}
</script>

<style scoped lang="scss">
.vs-button-dark.vs-button-flat {
  display: inline-block;
}
.flip-list-move,
.vs-table--tbody-table .tr-values {
  border-bottom: 1px solid #ddd;
  // transition: all 1s;
}
</style>
