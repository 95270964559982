import axios from 'axios'

const resource = 'grading/allocation'
export default {
  getAllocationTypes() {
    return axios.get(`${resource}/allocations/types`)
  },
  getSubjectsOfGradeWithinPeriod(payload) {
    /* Payload :
          {
          "grade_id":17,
          "start_date":"2021-11-10",
          "end_date":"2021-11-20"
        }*/
    return axios.post(`${resource}/grade/subjects`, payload)
  },
  getAllAllocations(page = null, payload) {
    return axios.post(
      `${resource}${page ? `?page=${page}` : ''}`,

      payload
    )
  },
  createAllocation(payload) {
    return axios.post(`${resource}/create`, payload)
  },
  editAllocation(allocationId, payload) {
    return axios.put(`${resource}/${allocationId}`, payload)
  },
  getAllocation(allocationId) {
    return axios.get(`${resource}/${allocationId}`)
  },
  changeAllocationStatus(id, isActive) {
    return axios.get(`${resource}/change-status/${id}/${isActive}`)
  },
  getClassAllocationSubjects(classId, allocationId) {
    return axios.get(`${resource}/subject/${classId}/${allocationId}`)
  },
  getAllocationWithoutPagination() {
    return axios.get(`${resource}/Without-pagination`)
  }
}
