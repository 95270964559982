<template>
  <ListPageWithCreateButton :showCreateBtn="true">
    <template slot="leftTopRow">
      <div class="flex flex-wrap justify-between">
        <!-- TODO:  pdf btn -->
        <!-- <vs-button @click="printMe" class="mb-base mr-auto ml-0">{{$t('basic.download_pdf')}}</vs-button> -->
      </div>
      <vs-button
        class="mb-base mr-0 ml-auto block"
        @click="createQuickMeeting"
        >{{ $t('basic.create_quick_meeting') }}</vs-button
      >
    </template>
    <template slot="listDataContent">
      <div class="w-full mt-5" ref="printMe">
        <transition-group class="list-transition" name="list-enter-up" tag="ul">
          <template v-if="quickMeetings.length > 0">
            <li
              class="mb-10"
              v-for="(val, i) in quickMeetings"
              :key="val.id"
              :style="[{transitionDelay: i * 0.1 + 's'}]">
              <quick-meeting-card
                :quickMeetingProp="val"
                @reloadData="reloadData"></quick-meeting-card>
            </li>
          </template>
        </transition-group>
        <template v-if="quickMeetings.length < 1">
          <p class="text-center">{{ $t('basic.no_data_available') }}</p>
        </template>
      </div></template
    >
  </ListPageWithCreateButton>
</template>
<script>
import QuickMeetingCard from '@/modules/onlineClass/components/QuickMeetingCard'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const OnlineClassRepository = RepositoryFactory.get(
  'TeachersModule',
  'OnlineClass'
)

export default {
  components: {
    QuickMeetingCard
  },
  created() {
    this.getAllData()
  },
  data() {
    return {
      quickMeetings: []
    }
  },
  methods: {
    getAllData() {
      this.VSLoading()
      OnlineClassRepository.getQuickMeetings()
        .then((res) => {
          this.quickMeetings = res.data
        })
        .catch(this.errMsg)
        .finally(this.stopVSLoading)
    },
    createQuickMeeting() {
      OnlineClassRepository.createQuickMeeting().then(this.reloadData)
    },
    reloadData() {
      this.getAllData()
    }
  }
}
</script>
