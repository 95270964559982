<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full xl:w-1/4 md:w-1/3 mb-5">
        <section class="side-menu-container">
          <side-menus-container
            class="p-1 md:ml-0 m-auto w-full"
            :student_photo="hasImg ? student_img : null"
            :siblings="students"
            :callBack="changeCurrentComponent"
            :menus.sync="menusData"
            :info.sync="sectionInfo"
            @change-student="updateStudentData"
            ref="sideMenuContainer"
          ></side-menus-container>
        </section>
      </div>
      <div class="vx-col w-full xl:w-3/4 md:w-2/3 mb-3">
        <section class="topbar">
          <tabs-bar
            :tabs_prop="tabs"
            :action_function="changeCurrentComponent"
          ></tabs-bar>
        </section>

        <section class="center-content mb-3">
          <transition name="zoom-fade" mode="out-in">
            <component
              :is="currentActiveRoute"
              :student.sync="currentStudentData"
              :siblings="students"
              :params="currentActiveRouteParams"
            />
          </transition>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
// Importing LeftSidebar Components
import Assignments from '@/modules/assignment/v1/card/assignments.vue'
import Classwork from './Components/Left-Sidebar/Classwork'
import Exams from './Components/Left-Sidebar/Exams'
import Gradebook from './Components/Left-Sidebar/Gradebook'
import OnlineClasses from './Components/Left-Sidebar/OnlineClasses'
import PrivateContent from './Components/Left-Sidebar/PrivateContent'
import Report from './Components/Left-Sidebar/Report'
import Repository from './Components/Left-Sidebar/Repository'
import Results from './Components/Left-Sidebar/Results'
import Tasks from '@/modules/Students/pages/student/Components/Left-Sidebar/tasks/tasks.vue'
import Timetable from './Components/Left-Sidebar/Timetable'
import Wallet from './Components/Left-Sidebar/wallet'
import myDayParent from './Components/Left-Sidebar/myDayParent'

// Importing Agenda Components
import agenda from '@/modules/agenda/pages/List'

// Importing Topbar Components
import MyDay from './Components/Topbar/MyDay'
import DocumentsResources from './Components/Topbar/DocumentsResources'
import FamilyProfile from './Components/Topbar/FamilyProfile'
import FeesPayment from './Components/Topbar/FeesPayment'

// Importing Topbar Components
import TabsBar from '@/components/profile/TabsBar'

import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const ParentRepository = RepositoryFactory.get('ParentRepository', 'parent')
const myDayRepository = RepositoryFactory.get(
  'myDay',
  'myDay'
)
export default {
  name: 'ParentProfile',
  components: {
    // General Components
    TabsBar,
    // Topbar Components
    MyDay,
    DocumentsResources,
    FamilyProfile,
    FeesPayment,
    // LeftSidebar Components
    Assignments,
    Classwork,
    Exams,
    Gradebook,
    OnlineClasses,
    PrivateContent,
    Report,
    Repository,
    Results,
    Tasks,
    Timetable,
    Wallet,
    agenda,
    myDayParent
  },
  data () {
    return {
      //////////////////////////////////
      // Route || Component
      ///////////////////////////////////
      currentActiveRoute: '',
      currentActiveRouteParams: null,
      hideTopBar: false,
      //////////////////////////////////
      // Data
      ///////////////////////////////////
      currentStudentData: {},
      siblings: [],
      siblingsData: [],
      students: [],
      menusData: [
        {
          header: this.$t('basic.tasks_and_assessments'),
          items: [
            {
              title: this.$t('basic.assignments'),
              icon: 'icon-activity',
              action: 'assignments'
            },
            {
              title: this.$t('basic.tasks'),
              icon: 'icon-activity',
              action: 'tasks'
            }
            // {
            //   title: this.$t('basic.repository'),
            //   icon: 'icon-activity',
            //   action: 'repository'
            // },
            // {
            //   title: this.$t('basic.classwork_and_behaviour'),
            //   icon: 'icon-activity',
            //   action: 'classwork'
            // }
          ]
        },
        {
          header: this.$t('basic.exams_and_results'),
          items: [
            {
              title: this.$t('basic.exams'),
              icon: 'icon-activity',
              action: 'exams'
            },
            // {
            //   title: this.$t('basic.results_base'),
            //   icon: 'icon-activity',
            //   action: 'results'
            // },
            // {
            //   title: this.$t('basic.grade_book'),
            //   icon: 'icon-activity',
            //   action: 'gradebook'
            // },
            {
              title: this.$t('basic.report_card'),
              icon: 'icon-activity',
              action: 'report'
            }
          ]
        },
        {
          header: this.$t('basic.tools'),
          items: [
            // {
            //   title: this.$t('basic.timetable'),
            //   icon: 'icon-activity',
            //   action: 'timetable'
            // },
            {
              title: this.$t('basic.agenda'),
              icon: 'icon-activity',
              action: 'agenda'
            },
            {
              title: this.$t('basic.online_classes'),
              icon: 'icon-activity',
              action: 'online-classes'
            },
            {
              title: this.$t('wallet.wallet'),
              icon: 'icon-activity',
              action: 'wallet'
            }
          /*  {
              title: this.$t('myDay.myDay'),
              icon: 'icon-activity',
              action: 'myDayParent'
            }*/
            // {
            //   title: this.$t('basic.private_content'),
            //   icon: 'icon-activity',
            //   action: 'private-content'
            // }
          ]
        }
      ],
      sectionInfo: {
        header: this.$t('basic.student_information'),
        items: []
      },
      student_img: null,
      tabs: [
        // {
        //   title: this.$t('basic.family_profile'),
        //   icon: 'icon-activity',
        //   action: 'family-profile'
        // },
        // {
        //   title: this.$t('basic.fees_payment'),
        //   icon: 'icon-activity',
        //   action: 'fees-payment'
        // },
        // {
        //   title: this.$t('basic.documents_resources'),
        //   icon: 'icon-activity',
        //   action: 'documents-resources'
        // }
      ],
      parentData: null
    }
  },
  computed: {
    hasImg () {
      if (this.student_img) {
        const img_types = ['jpg', 'jpeg', 'png']
        return img_types.some(el => this.student_img.includes(el))
      } else return false
    }
  },
  methods: {
    ////////////////////////////////////
    // APIs
    ////////////////////////////////////
    getParentData () {
      ParentRepository.getParentdata().then(res => {
        this.parentData = res
      })
    },
    getChildernsDayData (id) {
      ParentRepository.getChildernsDayData(id).then(res => {
        this.currentStudentData = res.application
      })
    },

    getStudentData (student_id) {
      ParentRepository.getStudentdata(student_id).then(res => {
        this.currentStudentData = res.application
      })
    },
    getParentStudents () {
      ParentRepository.getParentStudents().then(res => {
        this.students = res.students
        this.currentStudentData = this.students[0]
      })
    },

    ///////////////////////////////////////
    // Change tabs
    //////////////////////////////////////
    changeCurrentComponent ({ action }) {
      // reset params
      this.currentActiveRouteParams = null

      // Set currentRoute
      this.currentActiveRoute = action

      // Show || Hide Topbar
      this.menusData.find(data => data.items.find(el => {
        if (el.action === action) {
          this.hideTopBar = el.hideTopBar || false
        }
      })
      )

      // Scroll to Top of page
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    changeActiveRouteByEmit (Component) {
      console.log(this.currentActiveRouteParams)
      // reset params
      this.currentActiveRouteParams = null

      // check if component is in the menusData || leftSidebar menu
      let menusDataMatched = null
      this.menusData.find(data => data.items.find(el => {
        if (el.action === Component.name) {
          this.hideTopBar = el.hideTopBar || false
          menusDataMatched = el || null
        }
      })
      )

      // Load Component
      if (menusDataMatched) {
        this.changeCurrentComponent(menusDataMatched)
        // active link generic-menu component
        this.$refs.sideMenuContainer.$children[2].activeMenu = Component.name
      } else this.currentActiveRoute = Component.name

      // Set Params
      if (Component.params) {
        this.currentActiveRouteParams = Component.params || null

        //Change Student Data if PARAMS HAVE ID
        const params = Component.params,
          // Check if user_id || id
          is_ID_params =
            params.hasOwnProperty('id') ||
            params.hasOwnProperty('ID') ||
            params.hasOwnProperty('Id'),
          is_userID_params =
            params.hasOwnProperty('userId') || params.hasOwnProperty('user_id'),
          // get STUDENT ID
          userId = params.userId || params.user_id,
          studentId_by_userId = userId
            ? this.students.find(st => st.user_id === userId).id
            : null,
          studentId = studentId_by_userId || params.id || params.ID || params.Id

        // Update current student data
        if (is_ID_params || is_userID_params) {
          this.$refs.sideMenuContainer.selectedStudent = studentId
          this.updateStudentData(studentId)
        }
      }
    },
    /////////////////////////////////////
    // Set data
    /////////////////////////////////////
    //  Student age
    getStudentAge (birthday) {
      const age = birthday
      return age
    },

    // Set Section Info
    setSectionInfo (val) {
      const birthDate = Number(val.student.birth_date)

      // Reset the items in student info && img
      this.sectionInfo['items'].length = 0
      this.student_img = null

      // Add Student img
      this.student_img = val.student_full_details.photo

      // Push the new student data
      this.sectionInfo.items.push(
        {
          title: this.$t('basic.ID'),
          value: val.student_code
        },
        {
          title: this.$t('basic.name'),
          value: val.fullName.replace(/\s+/g, ' ') // To remove extra spaces between names
        },
        /*   {
          title: this.$t('basic.birthday'),
          value: birthDate
            ? `${new Date(birthDate).getDate()}/${new Date(
              birthDate
            ).getMonth() + 1}/${new Date(birthDate).getFullYear()}`
            : this.$t('profile.birthday_is_not_available')
        },
        {
          title: this.$t('basic.age'),
          value: birthDate
            ? new Date().getFullYear() - new Date(birthDate).getFullYear()
            : this.$t('profile.birthday_is_not_available')
        },
        {
          title: this.$t('basic.division'),
          value: val.divisionName
        },
        {
          title: this.$t('basic.grade'),
          value: val.gradeName
        },*/
        {
          title: this.$t('basic.class_group'),
          value: val.student.classGroupName
        }
      )

      if (val.student.username) {
        this.sectionInfo.items.push({
          title: this.$t('basic.username'),
          value: val.student.username
        })
      }
      this.currentStudentData = val
      this.getRoundData(this.currentStudentData.round_id)
    },
    getRoundData (round_id) {
      ParentRepository.getRound(round_id).then(res => {
        Object.assign(this.currentStudentData, { round: res.data.roundData })
      })
    },

    // Update StudentData
    updateStudentData (val) {
      this.setSectionInfo(this.students.find(el => el.id === val))
      this.changeCurrentComponent('')
      this.$refs.sideMenuContainer.$children[2].activeMenu = ''
    },
    updateStudentDatabyUserId (val) {
      this.setSectionInfo(this.students.find(el => el.user_id === val))
    }
  },
  created () {
    // this.getStudentData(162)
    this.getParentStudents()
    //////////////////////////////
    // Route To Another Component
    //////////////////////////////
    myDayRepository.getAllSettings().then(res => {
      if (res.data.myday_for_parent_for_web) {
        this.tabs.push({
          title: this.$t('basic.my_day'),
          icon: 'icon-activity',
          action: 'myDayParent',
          color: 'primary'
        })
      }
    })
    this.$root.$on('changeActiveRoute', Component => this.changeActiveRouteByEmit(Component)
    )
  },
  beforeDestroy () {
    this.$root.$off('changeActiveRoute')
  },
  watch: {
    currentStudentData (val) {
      this.setSectionInfo(this.students.find(el => el.id === val.id))
    }
  }
}
</script>

<style scoped></style>
