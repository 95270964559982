<template>
  <div>
    <ListPageWithCreateButton
      createRoutName="create-syllabus"
      :showCreateBtn="checkUserRole('SND')"
      :createBtnName="$t('Syllabus.syllabusCreate')">
      <template slot="listDataContent">
        <data-table
          v-if="refreshComponent"
          :csv_url="'/academic/syllabus/export'"
          :pdf_url="'syllabus/pdf'"
          :total="1"
          :headers_prop="headers"
          :side_filters="sideFilters"
          :reCallRequest.sync="recallTableData"
          list_url_prop="/academic/syllabus" />
      </template>
      <template slot="rightSide">
        <FilterBar @reloadData="reloadData" />
      </template>
    </ListPageWithCreateButton>
    <duplicateForm
      v-if="showDuplicateDialog"
      :showDialog="showDuplicateDialog"
      :loading="loadingDuplicateDialog"
      @cancel="cancelDuplicate"
      @submit="submitDuplicate" />
  </div>
</template>

<script>
import DataTable from '@/components/general/datatable/DataTable2.vue'
import FilterBar from './components/FilterBar'
import duplicateForm from '@/components/general/DuplicateForm'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'

const SyllabusRepository = RepositoryFactory.get('AcademicModule', 'syllabus')

export default {
  name: 'SyllabusCardList',
  components: {DataTable, FilterBar, duplicateForm},
  data() {
    return {
      headers: [
        /* {
          minWidth: 100,
          headerName: this.$t('basic.ID'),

          field: 'id',
          width: 150,
          headerCheckboxSelectionFilteredOnly: true
          // checkboxSelection: true,
          // headerCheckboxSelection: true
        },
        {
          minWidth: 130,
          headerName: this.$t('Syllabus.syllabusCode'),
          field: 'code',
          width: 200,
          headerCheckboxSelectionFilteredOnly: true
        },*/
        {
          minWidth: 130,
          headerName: this.$t('Syllabus.division'),
          field: 'divisionName'
        },
        {
          minWidth: 130,
          headerName: this.$t('Syllabus.grade'),
          field: 'gradeName'
        },
        {
          minWidth: 130,
          headerName: this.$t('Syllabus.created_by'),
          field: 'user',
          width: 290
        },
        {
          minWidth: 130,
          headerName: this.$t('Syllabus.academicYear'),
          field: 'academic_year_data.name',
          width: 290
        },
        /*  {
          minWidth: 95,
          headerName: this.$t('Syllabus.subjects'),
          field: 'Subjects.length',
          width: 290
        },
        {
          minWidth: 95,
          headerName: this.$t('Syllabus.online'),
          field: 'online',
          width: 290
        },*/
        {
          minWidth: 130,
          headerName: this.$t('Syllabus.status'),
          field: 'status',
          width: 290,
          cellRendererFramework: 'CellRendererStatus',
          cellRendererParams: {
            status_prop: this.status
          }
        },
        {
          minWidth: 200,
          headerName: this.$t('Syllabus.terms'),
          valueGetter(data) {
            return data.data.terms.map((item) => item.name).join(' , ')
          }
        },
        {
          minWidth: 120,
          filter: false,
          headerName: this.$t('Syllabus.actions'),
          field: 'actions',
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            actions_prop: [
              {
                title: this.$t('basic.show'),
                dropDown: false,
                parameter_name: 'id',
                icon: 'EyeIcon',
                navigate_action: true,
                route_name: 'show-syllabus'
              },

              {
                showIf: () => this.checkUserRole('SND'),
                dropDown: true,
                dropDown_items: [
                  {
                    title: this.$t('status.active'),
                    parameter_name: 'id',
                    icon: 'CheckSquareIcon',
                    resource: 'academic/syllabus/update-status/:id',
                    requestType: 'put',
                    requestPayload: {status: 'Active'},
                    showIf: (data) => data.status !== 'Active'
                  },
                  // {
                  //   title: this.$t('basic.suspend'),
                  //   parameter_name: 'id',
                  //   icon: 'PowerIcon',
                  //   resource: 'academic/syllabus/update-status/:id',
                  //   requestType: 'put',
                  //   requestPayload: {status: 'Suspended'},
                  //   showIf: (data) => data.status !== 'Suspended'
                  // },
                  {
                    title: this.$t('status.archive'),
                    parameter_name: 'id',
                    icon: 'ArchiveIcon',

                    resource: 'academic/syllabus/update-status/:id',
                    requestType: 'put',
                    requestPayload: {status: 'Archived'},
                    showIf: (data) => data.status !== 'Archived'
                  },
                  {
                    title: this.$t('status.close'),
                    parameter_name: 'id',
                    icon: 'SlashIcon',
                    resource: 'academic/syllabus/update-status/:id',
                    requestType: 'put',
                    requestPayload: {status: 'Closed'},
                    showIf: (data) => data.status !== 'Closed'
                  },
                  {
                    title: this.$t('basic.edit'),

                    parameter_name: 'id',
                    icon: 'Edit3Icon',
                    navigate_action: true,
                    route_name: 'edit-syllabus',
                    showIf: () => this.checkUserRole('SND')
                  },
                  {
                    title: this.$t('basic.duplicate'),
                    icon: 'CopyIcon',
                    actionEmitter: 'duplicate',
                    actionEmitterParams: ['id'],
                    showIf: () => this.checkUserRole('SND')
                  },
                  {
                    title: this.$t('Syllabus.syllabus_info'),
                    dropDown: false,
                    parameter_name: 'id',
                    icon: 'UserIcon',
                    navigate_action: true,
                    route_name: 'syllabus_assign_teacher',
                    showIf: () => this.checkUserRole('SND')
                  }

                  // {
                  //   title: this.$t('basic.delete'),
                  //   parameter_name: 'id',
                  //   action: 'delete',
                  //   requestType: 'delete',
                  //   showIf: () => this.checkUserRole('SND'),
                  //   resource: 'academic/syllabus/:id',
                  //   color: 'danger'
                  // },
                  // {
                  //   title: 'Clone',
                  //   dropDown: false,
                  //   parameter_name: 'id',
                  //   icon: 'CopyIcon',
                  //   navigate_action: true,
                  //   route_name: 'clone-syllabus'
                  // },
                ]
              }
            ]
          }
        }
      ],
      status: [
        {
          id: 0,
          title: this.$t('status.active'),
          value: 'active',
          icon: 'StarIcon',
          count: '22',
          link: '#!'
        },
        {
          id: 1,
          title: this.$t('status.suspended'),
          value: 'suspended',
          icon: 'StarIcon',
          count: '22',
          link: '#!'
        },
        {
          id: 2,
          title: this.$t('status.archived'),
          value: 'archived',
          icon: 'StarIcon',
          count: '22',
          link: '#!'
        },
        {
          id: 3,
          title: this.$t('status.closed'),
          value: 'closed',
          icon: 'StarIcon',
          count: '22',
          link: '#!'
        }
      ],
      export_file: false,
      sideFilters: {},

      deleteSyllbusPopup: false,
      refreshComponent: true,
      sylllabusId: null,
      studentNote: '',
      loadingDeleteSyllbus: false,
      ///////////////////////////
      // Duplicate Syllabus
      //////////////////////////
      showDuplicateDialog: false,
      loadingDuplicateDialog: false,
      recallTableData: false,
      duplicate: {
        syllabusId: null,
        targetAcademicYear: null
      }
    }
  },
  methods: {
    reloadData(v) {
      this.sideFilters = v
    },
    //////////////////////////
    // Duplicate
    //////////////////////////
    cancelDuplicate() {
      this.duplicate.syllabusId = null
      this.showDuplicateDialog = false
    },
    submitDuplicate(v) {
      this.loadingDuplicateDialog = true
      SyllabusRepository.duplicateSyllabus(
        this.duplicate.syllabusId,
        v.selectedAcademicYear
      )
        .then((res) => {
          this.successMsg()
          this.showDuplicateDialog = false
          this.recallTableData = true
        })
        .catch(this.errMsg)
        .finally(() => {
          this.loadingDuplicateDialog = false
        })
    }
  },
  mounted() {
    this.$root.$on('duplicate', (v) => {
      this.duplicate.syllabusId = v.id
      this.showDuplicateDialog = true
    })
  },
  created() {},
  beforeDestroy() {
    this.$root.$off('duplicate')
  }
}
</script>

<style scoped lang="scss"></style>
