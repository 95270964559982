import axios from 'axios'

const resource = 'grading'
export default {
  getGradingsOfClass (classId, payload) {
    // {start_date,end_date,subject_id}
    return axios.get(`${resource}/classes/${classId}`, { params: payload })
  },

  getGradingsOfSubject (classId, subjectId) {
    return axios.get(`${resource}/classes/${classId}/summary`, {
      params: { subject_id: subjectId }
    })
  },
  changeGradeStatus (payload) {
    return axios.post(`${resource}/change/model-status`, payload)
    //  {
    //   'resource_type': 'assignment',
    //   'resource_id': 4016,
    //   'status': 'unhidden'
    // }
  },
  updateScores (payload) {
    return axios.post(`${resource}/update-score`, payload)
    //  {
    //   'resource_type': 'assignment',
    //   'resource_id': 4016,
    //   'status': 'unhidden'
    // }
  },
  getStudentGrades (classId, StudentId) {
    return axios.get(`${resource}/classes/${classId}/${StudentId}`)
  },
  addExamGradesManual (payload) {
    return axios.post('teachers/exam/manual', payload)
  },
  getExamGradesManual (examId) {
    return axios.get(`teachers/exam/manual/${examId}`)
  },
  updateExamGradesManual (examId, payload) {
    return axios.put(`teachers/exam/manual/${examId}`, payload)
  },
  addGradeTypeDetails (payload) {
    return axios.post(`${resource}/type-details`, payload)
  },
  showAllGradeTypeDetails (id) {
    return axios.get(`${resource}/type-details`)
  },
  showGradeTypeDetail (id) {
    return axios.get(`${resource}/type-details/${id}`)
  },
  updateGradeTypeDetails (id, payload) {
    return axios.put(`${resource}/type-details/${id}`, payload)
  },
  getStudentGradingsByAllocation (classId, studentId, allocationId, params) {
    return axios.get(`${resource}/classes/${classId}/${studentId}/${allocationId}`, { params })
    // params : subject_id ,start_date (of the round), end_date (of the round)
  }

}
