<template>
  <div>
    <vs-popup
      class="popup"
      title="Notes"
      @close="showNotePopup = false"
      :active.sync="showNotePopup"
    >
      <div v-for="(note, i) in notes" :key="i" class="py-2">
        <p class="d-block mb-4">{{note.note}}</p>
        <p class="d-flex text-created text-base">Created By <span class="text-primary">{{note.author}}</span></p>
        <hr>
      </div>
    </vs-popup>
    <h3 class="text-primary mb-4">
      <feather-icon
        icon="CalendarIcon"
        class="mr-4"
      />
      {{Object.keys(details.day)[0]}} ( {{details.student.name}} )
    </h3>
    <div  v-for="(i, key) in details.day" :key="key">
      <div class="vx-card p-5 mb-4">
        <h5>1- Attendance summery :</h5>
        <ul class="p-4">
          <li v-if="i.attendance.hasOwnProperty('attendance_status') && i.attendance == 'present'">
            <p>
              <span class="text-primary font-bold">We hope  spent another fruitful day  inside the school campus</span>
            </p>
          </li>
          <li v-else-if="i.attendance.hasOwnProperty('attendance_status') && i.attendance == 'absent'">
            <p>
              <span class="text-danger font-bold">absent</span>
            </p>
          </li>
          <li v-else>
            <p>
              <span class="d-inline-block mr-2">No attendance was recorded for You today</span>
            </p>
          </li>

          <li class="mt-3">
            <p>
              <span class="d-inline-block mr-2">Count Absent In Month is</span>
              <span class="text-primary font-bold">{{i.attendance.countAbsentInMonth}}</span>
            </p>
          </li>
        </ul>
      </div>
      <div class="vx-card p-5 mb-4">
        <h5>2- TODO List :</h5>
        <ul class="p-4" v-if="i.assignments_todo[i.assignments_todo.length -1].counter !== 0 &&
        i.assessments_todo[i.assessments_todo.length -1].counter !== 0 &&
        i.exams_todo[i.exams_todo.length -1].counter !== 0">
          <li class="mt-3" v-if="i.assignments_todo[i.assignments_todo.length -1].counter !== 0">
            <h5 class="text-secondary mb-2">- Assignments ( {{i.assignments_todo.length - 1}} ):</h5>
            <div v-for="(data, key1) in i.assignments_todo" :key="key1" class="ml-4">
              <p v-if="data.hasOwnProperty('subject')">
                You have Assignment/s
                in <span  class="text-primary">{{ data.subject }}</span>
                ( <span class="text-primary"> {{data.assignmentName ? data.assignmentName : 'No Title'}} </span> )
                Created By <span class="text-primary"> {{ data.created_by }} </span>
              </p>
            </div>
          </li>
          <li class="mt-3" v-if="i.assessments_todo[i.assessments_todo.length -1].counter !== 0">
            <h5 class="text-secondary mb-2">- Assessments ( {{i.assessments_todo.length - 1}} ):</h5>
            <div v-for="(data, key1) in i.assessments_todo" :key="key1" class="ml-4">
              <p v-if="data.hasOwnProperty('subject')">
                You have Assessment/s
                in <span  class="text-primary">{{ data.subject }}</span>
                ( <span class="text-primary"> {{data.title ? data.title : 'No Title'}} </span> )
                Created By <span class="text-primary"> {{ data.created_by }} </span>
              </p>
            </div>
          </li>
          <li class="mt-3" v-if="i.exams_todo[i.exams_todo.length -1].counter !== 0">
            <h5 class="text-secondary mb-2">- Exams ( {{i.exams_todo.length - 1}} ):</h5>
            <div v-for="(data, key1) in i.exams_todo" :key="key1" class="ml-4">
              <p v-if="data.hasOwnProperty('subject')">
                You have Exam/s
                in <span  class="text-primary">{{ data.subject }}</span>
                ( <span class="text-primary"> {{data.title ? data.title : 'No Title'}} </span> )
                Created By <span class="text-primary"> {{ data.created_by }} </span>
              </p>
            </div>
          </li>
        </ul>
        <p v-else class="p-4 mt-3"> No Updates For Today </p>
      </div>
      <div class="vx-card p-5 mb-4">
        <h5>3- Latest assessments feeds:</h5>
        <ul class="p-4">
          <li class="mt-3" v-if="i.assessments_assessed[i.assessments_assessed.length -1].counter != 0">
            <h5 class="text-secondary mb-2">- Assessments :</h5>
              <div v-for="(data, key1) in i.assessments_assessed" :key="key1" class="ml-4">
                <p v-if="data.hasOwnProperty('subject')">
                  You got <span class="text-primary">{{data.criteria[0]}}</span>
                  in the<span  class="text-primary">{{ data.subject }}</span>
                  Assessments <span  class="text-primary">{{data.title }}</span>
                  by <span  class="text-primary">{{data.created_by }}</span>
                </p>
              </div>
          </li>
          <li class="mt-3" v-if="i.assignments_assessed[i.assignments_assessed.length -1].counter != 0">
            <h5 class="text-secondary mb-2">- Assignment :</h5>
            <div v-for="(data, key1) in i.assignments_assessed" :key="key1" class="ml-4">
              <p v-if="data.hasOwnProperty('subject')">
                You got <span class="text-primary">{{data.criteria[0]}}</span>
                in the<span  class="text-primary"> {{ data.subject }}</span>
                assignment <span  class="text-primary">{{data.assignmentName }}</span>
                by <span  class="text-primary">{{data.created_by }}</span>
              </p>
            </div>
          </li>
          <li class="mt-3" v-if="i.exams_assessed[i.exams_assessed.length -1].counter != 0">
            <h5 class="text-secondary mb-2">- Exam :</h5>
            <div v-for="(data, key1) in i.exams_assessed" :key="key1" class="ml-4">
              <p v-if="data.hasOwnProperty('subject')">
                You got <span class="text-primary">{{data.score}}</span>
                out of <span>{{data.points}}</span>
                ( <span class="text-primary"> {{data.criteria[0] }} </span>  )
                in the<span  class="text-primary"> {{ data.subject[0] }}</span>
                Exam <span  class="text-primary">{{ data.title }}</span>
                by <span  class="text-primary">{{ data.created_by }}</span>
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div class="vx-card p-5 mb-4">
        <h5>4- Upcoming soon:</h5>
        <ul class="p-4"
            v-if="i.assessments_upcoming[i.assessments_upcoming.length -1].counter !== 0 &&
                  i.assignments_upcoming[i.assignments_upcoming.length -1].counter !== 0 &&
                  i.exams_upcoming[i.exams_upcoming.length -1].counter !== 0"
        >
          <li class="mt-3" v-if="i.assessments_upcoming[i.assessments_upcoming.length -1].counter != 0">
            <h5 class="text-secondary mb-2">- Assessments :</h5>
            <div v-for="(data, key1) in i.assessments_upcoming" :key="key1" class="ml-4">
              <p v-if="data.hasOwnProperty('subject')">
                {{ data.date }}
                You have Assessment/s
                in <span  class="text-primary">{{ data.subject }}</span>
                that need to be submitted
              </p>
            </div>
          </li>
          <li class="mt-3" v-if="i.assignments_upcoming[i.assignments_upcoming.length -1].counter != 0">
            <h5 class="text-secondary mb-2">- Assignments :</h5>
            <div v-for="(data, key1) in i.assignments_upcoming" :key="key1" class="ml-4">
              <p v-if="data.hasOwnProperty('subject')">
                {{ data.date }}
                You have Assignments/s
                in <span  class="text-primary">{{ data.subject }}</span>
                that need to be submitted
              </p>
            </div>
          </li>
          <li class="mt-3" v-if="i.exams_upcoming[i.exams_upcoming.length -1].counter != 0">
            <h5 class="text-secondary mb-2">- Exam :</h5>
            <div v-for="(data, key1) in i.exams_upcoming" :key="key1" class="ml-4">
              <p v-if="data.hasOwnProperty('subject')">
                {{ data.date }}
                You have Exam/s
                in <span  class="text-primary">{{ data.subject }}</span>
                ( <span class="text-primary"> {{ data.title }} </span>)
                Get ready
              </p>
            </div>
          </li>
        </ul>
        <p v-else class="p-4 mt-3"> No Updates For Today </p>
      </div>
      <div class="vx-card p-5 mb-4">
        <h5>5- What missed for today:</h5>
        <ul class="p-4"
            v-if="i.assessments_missed[i.assessments_missed.length -1].counter !== 0 &&
                  i.assignments_missed[i.assignments_missed.length -1].counter !== 0"
        >
          <li class="mt-3" v-if="i.assessments_missed[i.assessments_missed.length -1].counter != 0">
            <h5 class="text-secondary mb-2">- Assessments :</h5>
            <div v-for="(data, key1) in i.assessments_missed" :key="key1" class="ml-4">
              <p v-if="data.hasOwnProperty('subject')">
                You Missed Assessment/s
                in <span  class="text-primary">{{ data.subject }}</span>
                ( <span class="text-primary"> {{data.title}} </span> )
                Created By <span class="text-primary"> {{ data.created_by }} </span>
              </p>
            </div>
          </li>
          <li class="mt-3" v-if="i.assignments_missed[i.assignments_missed.length -1].counter != 0">
            <h5 class="text-secondary mb-2">- Assignments :</h5>
            <div v-for="(data, key1) in i.assignments_missed" :key="key1" class="ml-4">
              <p v-if="data.hasOwnProperty('subject')">
                You Missed Assignment/s
                in <span  class="text-primary">{{ data.subject }}</span>
                ( <span class="text-primary"> {{data.assignmentName}} </span> )
                Created By <span class="text-primary"> {{ data.created_by }} </span>
              </p>
            </div>
          </li>
        </ul>
        <p v-else class="p-4 mt-3"> No Updates For Today </p>
      </div>
      <div class="vx-card p-5 mb-4">
        <h5>6- News And Updates:</h5>
        <ul class="p-4">
          <li class="mt-3" v-if="i.notes">
            A new agenda was created
          </li>
          <li class="mt-3" v-else>
            No Agenda
          </li>
          <li class="mt-3">
              <p v-if="i.report_card[0] !== 'no Report card released yet'">{{i.report_card[0]}}</p>
          </li>
          <li class="mt-3" v-if="Array.isArray(i.notes)">
            <p>You have <span class="text-primary"  @click="showAllNote(i.notes)">{{ i.notes.length }}</span> notes to read <span
              @click="showAllNote(i.notes)">
              <feather-icon
              icon="EyeIcon" class="text-primary"/></span></p>
          </li>
          <li v-else class="mt-3">No Notes</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['details'],
  methods:{
    showAllNote (note) {
      this.showNotePopup = true
      this.notes = note
    }
  },
  data () {
    return {
      showNotePopup : false,
      notes: []
    }
  },
  mounted () {
    if (!this.details) {
      this.$router.push(`/student/my-day/${localStorage.getItem('studentDay')}`)
    }
  }
}
</script>
<style>
.text-created{
  font-size: 8px !important;
  text-align: right !important;
}
</style>
