import axios from 'axios'

const resource = '/school'
const common = '/common'
export default {
  createBranch (BranchData) {
    return axios.post(`${resource}/store`, BranchData)
  },
  getBranchs () {
    return axios.get(`${resource}`)
  },
  getBranch (id) {
    return axios.get(`${resource}/show/${id}`)
  },
  getBranchBasicData () {
    return axios.get('/user-school')
  },
  editBranch (id, BranchData) {
    return axios.post(`${resource}/edit/${id}`, BranchData)
  },
  getUsers () {
    // return axios.get('/search')
    // get all SND users
    return axios.get('/users/snd/free')
  },
  uploadeImage (imageData, config) {
    return axios.post(`${common}/UploadeImage`, imageData, config)
  },
  updateImage (imageData, config) {
    return axios.post(`${common}/updateImage`, imageData, config)
  }

}
