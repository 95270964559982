export default {
  'cas': 'CAS',
  'casTasks': 'CAS Tasks',
  'createTask': 'Create Task',
  'Cas_task_criteria': 'CAS task Criteria',
  'CAS_Learning_Outcomes': 'CAS Learning Outcomes',
  'Learning_Outcomes': 'Learning Outcomes',
  'CAS_Task_Duration': 'CAS Task Duration',
  'CAS_Task_Proposed_Hours': 'CAS Task Proposed Hours',
  'CAS_Task_Name': 'CAS Task Name',
  'CAS_Task_Description': 'CAS Task Description',
  'Submit_and_wait_for_coordinator_validation': 'Submit and wait for coordinator validation',
  'Adding_New_CAS_task': 'Adding New CAS task',
  'Status': 'Status',
  'CAS_criteria': 'CAS criteria',
  'Validation': 'Validation',
  'Time_based': 'Time based',
  'Indicators': 'Indicators',
  'Evaluation': 'Evaluation',
  'validated': 'validated',
  'not_valiated': 'not valiated',
  'not_validated_yet': 'not validated yet',
  'validate': 'validate',
  'do_not_validate': 'Do Not Validate',
  'notes_interviews': 'Notes & Interview',
  'interviews': 'Interview',
  'Students': 'Students',
  'Set_Interview_or_note': 'Set Interview or note',
  'Upload_Final_Report': 'Upload Final Report',
  'Send_message': 'Send message',
  'Congratulation_you_made_it_kindly_upload_your_final_report_from': 'Congratulation, you made it. kindly, upload your final report from',
  'Congratulation_you_made_it_kindly_upload_your_final_report_from_here': 'Congratulation, you made it. kindly, upload your final report from here',
  'Materialization': 'Materialization',
  'Achieved_Hours': 'Achieved Hours',
  'cas_overview': 'CAS overview',
  'approved': 'approved',
  'not_approved': 'not approved',
  'days_to_submit': 'days to submit',
  'Delayed': 'Delayed ',
  'days_to_delayed': 'days to delayed',
  'time_to_take_action': 'Time to take action',
  'submitted': 'Submitted',
  'Search_by_Student_ID': 'Search by Student ID',
  'Student_has_completed_the_CAS_program': 'Student has completed the CAS program',
  'Kindly_let_the_student_upload_his_final_report': 'Kindly, let the student upload his final report',
  'new_updates': 'new updates',
  'accept_and_add_it_to_student_record': 'accept and add it to student record',
  'do_not_accept_it': 'do not accept it',
  'insert_some_words':'insert some words',
  'first_interview':'first interview post',
  'second_interview':'second interview post',
  'Third_Final_Interview': 'Third & Final Interview post',
  'can_upload_final_report': 'can upload final report',
  'allow_Upload_Final_Report': 'allow Upload Final Report',
  'show_student_interviews': 'show student interviews',
  'delayed': 'delayed',
  'days_delayed': 'days  delayed',
  'your_final_report_uploaded_successfully':'your final report uploaded successfully',
  'The_CAS_final_report': 'The CAS final report',
  'showTask': 'show Task',
  'first_reflection':'first reflection',
  'second_reflection':'second reflection',
  'Third_Final_reflection': 'Third & Final reflection',
  'you_can_reflect_only_3_times': 'you_can_reflect_only_3_times',
  'you_reflection_is_saved':'you reflection is saved'


}
