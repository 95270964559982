import BasicInfo from './BasicInfo'
import OnlineClass from './OnlineClass'
import Assignments from './Assignments'
import StudentDirectory from './StudentDirectory'
import Zoom from './Zoom'
import teaching_materials from './teaching-materials'

export default {
  BasicInfo,
  OnlineClass,
  Assignments,
  StudentDirectory,
  Zoom,
  teaching_materials

}
