<template>
  <vx-card no-body>
    <vs-list>
      <vs-list-header :color="color" :icon="icon" icon-pack="feather" :title="title"></vs-list-header>
    </vs-list>
    <vue-apex-charts
      type="area"
      height="250"
      :options="chartData.chartOptions"
      :series="chartData.series"
    ></vue-apex-charts>
  </vx-card>
</template>


<script>
import VueApexCharts from 'vue-apexcharts'
import { colors } from '@/../themeConfig.js'
export default {
  components: {
    VueApexCharts
  },
  props: {
    title: '',
    icon: '',
    color: '',
    chartData: ''
  },
  data () {
    return {}
  },
  created () {
    this.chartData.chartOptions.colors =
      this.color[0] === '#' ? [this.color] : [colors[this.color]]
  }
}
</script>