<template>
  <div class="pb-10">
    <grid-card-4>
      <template v-slot:actionButtons>
        <vs-dropdown
          class="matiralactionButtons text-capitalize cursor-pointer"
          vs-custom-content
          vs-trigger-click
        >
          <vs-button color="#999" type="flat" icon="more_vert"></vs-button>
          <vs-dropdown-menu>
            <vs-dropdown-item @click="downloadFiles()">
              <feather-icon
                icon="DownloadCloudIcon"
                svgClasses="text-blue h-4 w-4 mr-1"
              />{{ $t("basic.download") }}</vs-dropdown-item
            >
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template v-slot:first>
        <div class="pb-3">
          <h5 class="pb-2">{{ materialProp.name }}</h5>
          <p>{{ materialProp.subject_name }}</p>
          <div class="py-3">
            <vs-chip :class="`status--${materialProp.status} mb-6`">
              <span>{{ materialProp.status }}</span>
            </vs-chip>
          </div>
        </div>
      </template>
      <template v-slot:second>
        <div class="pb-3">
          <h6 class="pb-3">
            <feather-icon icon="UserIcon" svgClasses="text-blue h-4 w-4 mr-1" />
            {{ materialProp.created_by.name }}
          </h6>
          <div class="mb-2 text-sm">
            <feather-icon icon="CalendarIcon" svgClasses=" h-4 w-4 mr-1" />
            <span>{{ $t("basic.date") }} : {{ materialProp.created_at }} </span>
          </div>
        </div>
      </template>
      <template v-slot:third>
        <div class="pb-3">
          <div>
            <div v-for="oneClass in materialProp.classes" :key="oneClass.id">
              <vs-chip
                class="status--primary status-text--blue inline-block my-1"
              >
                <span>{{ oneClass.name }}</span>
              </vs-chip>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:fourth>
        <div v-for="type in materialProp.types" :key="type.id">
          <vs-chip class="status--black status-text--blue block">
            <span>{{ type.name }}</span>
          </vs-chip>
        </div>
      </template>
    </grid-card-4>
  </div>
</template>

<script>
export default {
  props: ['materialProp', 'getTeachingMaterialList'],

  methods: {
    downloadFiles () {
      this.materialProp.files.forEach((element) => {
        this.downloadFile(element.attachment_url)
      })
    }
  }
}
</script>
<style lang="scss" scoped >
.vs-dropdown--custom {
  width: 10em;
}
.matiralactionButtons {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
