<template>
  <!-- <div> -->
  <grid-card class="assignment-card">
    <template v-slot:actionButtons>
      <vs-dropdown class="actionsButtons" vs-trigger-click v-if="is_teacher">
        <vs-button radius color="#999" type="flat" icon="more_vert"></vs-button>

        <vs-dropdown-menu>
          <vs-dropdown-item
            v-if="assignment_prop.statistics.has_answer"
            @click="assessmentStudentsTask(assignment_prop)"
          >
            <span class="flex items-center">
              <feather-icon class="mr-2" icon="EyeIcon" svgClasses="h-4 w-4" />
              <span>Assess Students</span>
            </span>
          </vs-dropdown-item>
          <vs-dropdown-item
            v-if="!assignment_prop.statistics.has_answer"
            @click="editAssessment(assignment_prop)"
          >
            <span class="flex items-center">
              <feather-icon class="mr-2" icon="EditIcon" svgClasses="h-4 w-4" />
              <span>{{ $t("basic.edit") }}</span>
            </span>
          </vs-dropdown-item>
          <vs-dropdown-item
            v-if="!assignment_prop.statistics.has_answer"
            @click="deleteAssignment(assignment_prop.id)"
          >
            <span class="flex items-center">
              <feather-icon
                class="mr-2"
                icon="TrashIcon"
                svgClasses="h-4 w-4"
              />
              <span>{{ $t("basic.delete") }}</span>
            </span>
          </vs-dropdown-item>

          <vs-dropdown-item
            v-if="
              assignment_prop.status.key !== 'archived' &&
              !assignment_prop.statistics.has_answer
            "
          >
            <span
              class="flex items-center"
              @click="changeArchiveStatus(assignment_prop.id, 'archived')"
            >
              <feather-icon
                class="mr-2"
                icon="ArchiveIcon"
                svgClasses="h-4 w-4"
              />
              <span>{{ $t("basic.archive") }}</span>
            </span>
          </vs-dropdown-item>
          <vs-dropdown-item v-if="notIncludedInGradebook">
            <span class="flex items-center" @click="showInGradebook(true)">
              <feather-icon class="mr-2" icon="EyeIcon" svgClasses="h-4 w-4" />
              <span>{{ $t("grading.add_to_grade_book") }}</span>
            </span>
          </vs-dropdown-item>
          <vs-dropdown-item v-else>
            <span class="flex items-center" @click="showInGradebook(false)">
              <feather-icon
                class="mr-2"
                icon="EyeOffIcon"
                svgClasses="h-4 w-4"
              />
              <span>{{ $t("grading.remove_from_grade_book") }}</span>
            </span>
          </vs-dropdown-item>

          <vs-dropdown-item v-if="assignment_prop.status.key === 'archived'">
            <span
              class="flex items-center"
              @click="changeArchiveStatus(assignment_prop.id, 'active')"
            >
              <feather-icon
                class="mr-2"
                icon="CheckIcon"
                svgClasses="h-4 w-4"
              />
              <span>{{ $t("basic.activate") }}</span>
            </span>
          </vs-dropdown-item>
        </vs-dropdown-menu>
      </vs-dropdown>
      <template v-else-if="is_student">
        <div class="iconButtons">
          <vx-tooltip :text="$t('assignment.submit_the_answer')" position="top">
            <feather-icon
              icon="EditIcon"
              class="cursor-pointer text-warning"
              v-if="canAnswer(assignment_prop)"
              @click="openAssignment(assignment_prop)"
            />
          </vx-tooltip>
        </div>
        <div class="iconButtons">
          <vx-tooltip :text="$t('assignment.show_feedBack')" position="top">
            <feather-icon
              icon="UserCheckIcon"
              class="cursor-pointer text-success"
              v-if="canShowGrades(assignment_prop)"
              @click="openAssignment(assignment_prop)"
            />
          </vx-tooltip>
        </div>
        <div class="iconButtons" v-if="is_answered">
          <vx-tooltip :text="$t('assignment.waiting_feedback')" position="top">
            <feather-icon
              icon="CheckSquareIcon"
              class="cursor-pointer text-success"
            />
          </vx-tooltip>
        </div>
      </template>
      <vs-chip
        transparent
        v-if="
          is_teacher &&
          !assignment_prop.did_teacher_show_new_updates &&
          assignment_prop.did_teacher_show_new_updates != null
        "
        class="hasUpdates shadow-inner"
        color="warning"
      >
        <div class="h-2 w-2 rounded-full mr-1 bg-warning"></div>
        <span class="text-capitalize">{{ $t("cas.new_updates") }}</span>
      </vs-chip>
      <div class="iconButtons mt-8" v-if="assignment_prop.is_multiple">
        <vx-tooltip :text="$t('assignment.multiple_answers')" position="top">
          <vs-icon icon="content_copy" size="small"></vs-icon>
        </vx-tooltip>
      </div>
    </template>

    <template v-slot:first>
      <div class="text-left">
        <vx-tooltip :text="assignment_prop.title" position="top">
          <h5 class="mb-4 font-semibold">
            {{ assignment_prop.title | truncate(15) | tailing("...") }}
          </h5>
        </vx-tooltip>

        <p class="mb-4">
          {{ assignment_prop.division_subject.name }}
        </p>
        <p class="mb-4">
          {{ assignmentClassess }}
        </p>
        <p class="mb-4 md:m-0">
          {{ assignment_prop.user.name | truncate(15) | tailing("...") }}
        </p>
      </div>
    </template>
    <template v-slot:second v-if="!isMobileWebView">
      <div>
        <p class="mb-4">
          {{ assignment_prop.type }}
        </p>
        <p class="mb-4">
          {{
            assignment_prop.is_formative
              ? $t("assignment.formative")
              : $t("assignment.summative")
          }}
        </p>
        <p
          class="mb-4"
          v-if="
            assignment_prop.datetime_launch != null &&
            checkDate(assignment_prop.datetime_launch) &&
            assignment_prop.status.key != 'archived'
          "
        >
          {{ $t("basic.launched") }}
        </p>

        <p class="mb-4" v-if="is_archieved">
          {{ $t("basic.archived") }}
        </p>

        <p class="mb-4 md:m-0">
          {{ $t("basic.lunched_at") }}:
          {{ assignment_prop.launch_date }}
          {{ assignment_prop.launch_time }}
        </p>
      </div>
    </template>
    <template v-slot:third v-if="is_teacher">
      <div>
        <p class="mb-4 text-success">
          {{ assignment_prop.statistics.done_on_time }}
          {{ $t("assessments.students_done_on_time") }}
        </p>
        <p class="mb-4 text-warning">
          {{ assignment_prop.statistics.done_but_late }}
          {{ $t("assessments.students_done_but_late") }}
        </p>
        <p class="mb-4 text-danger">
          {{ assignment_prop.statistics.delayed }}
          {{ $t("assessments.students_delayed") }}
        </p>
        <p class="mb-4 md:m-0 flex items-center">
          <vx-tooltip
            :text="$t('assignment.cant_answer_after_due_time')"
            position="top"
            v-if="assignment_prop.is_disable_task_date"
          >
            <feather-icon
              class="w-5 mx-2"
              icon="LockIcon"
              v-if="assignment_prop.is_disable_task_date"
            />
          </vx-tooltip>
          {{
            assignment_prop.is_multiple
              ? $t("assignment.cut_off_date")
              : $t("assignment.due_date")
          }}:
          {{ assignment_prop.assignment_date }}
        </p>
      </div>
    </template>
    <template v-slot:third v-else-if="is_student">
      <div>
        <div v-if="!is_answered && !canShowGrades(assignment_prop)">
          <p class="mb-4 text-success" v-if="assignment_prop.diff_days > 0">
            {{ assignment_prop.diff_days }}
            {{ $t("assignment.days_to_submit") }}
          </p>
          <p class="mb-4 text-danger" v-else-if="assignment_prop.diff_days < 0">
            {{
              assignment_prop.is_disable_task_date
                ? $t("assignment.time_passed")
                : `${assignment_prop.diff_days} ${$t("assignment.days_late")}`
            }}
          </p>

          <p
            class="mb-4"
            v-else
            :class="{
              'text-warning': checkTimeDiff(
                `${assignment.assignment_date} ${assignment.assignment_time}`
              ),
              'text-danger': !checkTimeDiff(
                `${assignment.assignment_date} ${assignment.assignment_time}`
              ),
            }"
          >
            {{
              checkTimeDiff(
                `${assignment.assignment_date} ${assignment.assignment_time}`
              )
                ? $t("assignment.time_to_take_action")
                : $t("assignment.time_passed")
            }}
          </p>
        </div>

        <p class="mb-4 text-success" v-else>
          {{ $t("assignment.submitted") }}
          <span class="text-xs">
            {{
              !canShowGrades(assignment_prop)
                ? `(${$t("assignment.wait_for_feed_back")})`
                : ""
            }}
          </span>
        </p>

        <p class="mb-4 md:m-0 flex items-center">
          <vx-tooltip
            :text="$t('assignment.cant_answer_after_due_time')"
            position="top"
            v-if="assignment_prop.is_disable_task_date"
          >
            <feather-icon
              class="w-4 mx-2"
              icon="LockIcon"
              v-if="assignment_prop.is_disable_task_date"
            />
          </vx-tooltip>
          {{
            assignment_prop.is_multiple
              ? $t("assignment.cut_off_date")
              : $t("assignment.due_date")
          }}: {{ assignment_prop.assignment_date }} -
          {{ assignment_prop.assignment_time }}
        </p>
      </div>
    </template>
    <vs-chip
      transparent
      v-if="
        !assignment_prop.is_teacher_show_new_updates &&
        assignment_prop.is_teacher_show_new_updates != null
      "
      class="hasUpdates shadow-inner"
      color="warning"
    >
      <div class="h-2 w-2 rounded-full mr-1 bg-warning"></div>
      <span class="text-capitalize">{{ $t("cas.new_updates") }}</span>
    </vs-chip>
  </grid-card>
  <!-- </div> -->
</template>
<script>
import VxCard from '../../../components/vx-card/VxCard.vue'
import { authUserMixin } from '@/mixins'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'


const AssignmentRepository = RepositoryFactory.get(
    'AssignmentsModule',
    'assignments'
  ),
  GradebookRepo = RepositoryFactory.get('GradingModule', 'gradebook')

export default {
  name: 'assignment-card',
  props: {
    assignment_prop: {
      type: Object,
      required: true
    },
    actions_enable: {
      type: Boolean,
      default:true
    }
  },
  components: {
    VxCard
    // gridCard: () => import('@/components/GridCard')
  },
  mixins: [authUserMixin],
  data () {
    return {
      statusChangePopupActive: false,
      assignment: {},
      status: [
        { name: this.$t('status.active'), icon: 'CheckSquareIcon' },
        { name: 'archived', icon: 'ArchiveIcon' },
        { name: 'draft', icon: 'SlashIcon' }
      ],
      AssignmentRepository: {},
      currentTaskToArchive: {}
    }
  },
  created () {
    this.assignment = this.assignment_prop
  },
  computed: {
    current_locale () {
      return this.$store.getters['main/currentLocale']
    },
    checkDate () {
      return (date) => {
        const lunchDate = new Date(date),
          dateNow = new Date()
        const diffTime = Math.ceil(((dateNow - lunchDate) / 1000) * 60)
        if (diffTime >= 0) return true
        else return false
      }
    },
    is_touch_device () {
      return this.$store.state.is_touch_device
    },
    assignmentClassess () {
      return this.assignment_prop.class_teacher
        .map((item) => item.class.name)
        .join(', ')
    },
    is_archieved () {
      return this.assignment_prop.status.key === 'archived'
    },
    is_answered () {
      return (
        this.is_student &&
        this.assignment_prop.task &&
        this.assignment_prop.task.status.key !== 'draft' &&
        !this.assignment_prop.task?.assess?.is_final &&
        !this.assignment_prop.task.is_able_to_answer
      )
    },
    notIncludedInGradebook () {
      return Boolean(this.assignment_prop.hide_from_grading)
    }
  },
  methods: {
    changeStatus (data) {
      this.$emit('statusChanged', data)
    },
    assessmentStudentsTask (data) {
      this.$router.push({ name: 'assess_assignment', params: { id: data.id } })
    },
    canAnswer (assignment) {
      if (assignment.task && assignment.task.status.key === 'draft') {
        if (assignment.diff_days < 0 && !assignment.is_disable_task_date) {
          return true
        } else if (
          assignment.diff_days < 0 &&
          assignment.is_disable_task_date
        ) {
          return false
        } else {
          return true
        }
      } else if (
        assignment.status.key === 'active' &&
        (!assignment.task ||
          (!assignment.task.assess?.is_final &&
            assignment.task.is_able_to_answer))
      ) {
        if (assignment.diff_days < 0) {
          return !assignment.is_disable_task_date
        } else if (assignment.diff_days === 0) {
          if (assignment.is_disable_task_date) {
            return this.checkTimeDiff(
              `${assignment.assignment_date} ${assignment.assignment_time}`
            )
          } else {
            return true
          }
        } else {
          return true
        }
      }
    },
    canShowGrades (assignment) {
      if (
        assignment.task &&
        assignment.task.status.key === 'active' &&
        assignment.task.submit_date &&
        assignment.task.assess?.is_final
      ) {
        if (assignment.task.assess) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    openAssignment (assignment) {
      if (assignment.diff_days === 0) {
        if (assignment.is_disable_task_date) {
          if (
            !this.checkTimeDiff(
              `${assignment.assignment_date} ${assignment.assignment_time}`
            )
          ) {
            this.errMsg(this.$t('assignment.cant_access_access_after_due_date'))
            return this.$emit('refreshData')
          }
        }
      }

      this.$router.push({
        name: 'answer_assignemnt',
        params: {
          id: assignment.id
        }
      })
    },
    editAssessment (data) {
      this.$router.push({
        name: 'assignment_edit',
        params: {
          assignment_id: data.id
        }
      })
    },

    //////////////////////////////////
    // Handle Delete Method
    /////////////////////////////////
    deleteAssignment (id) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: this.$t('basic.confirm_msg'),
        accept: () => this.handleDeleteAction(id)
      })
    },
    handleDeleteAction (id) {
      this.VSLoading()
      AssignmentRepository.deleteAssignment(id)
        .then(() => {
          this.changeStatus()
          this.successMsg(this.$t('assignemnt.assignment_deleted_successfully'))
        })
        .catch((err) => {
          this.errMsg(err, this.$t('form.loading_data_error'))
        })
        .finally(() => this.stopVSLoading())
    },
    //////////////////////////////////
    // Handle Archieve Method
    /////////////////////////////////
    archiveTask () {
      this.VSLoading()
      AssignmentRepository.archiveAssessmentTask(this.currentTaskToArchive.id, {
        status: this.currentTaskToArchive.status
      })
        .then((res) => {
          this.changeStatus(res.data)
          this.successMsg(
            `${this.$t('assignment.change_status_successfully_to')} ${
              this.currentTaskToArchive.status
            }`
          )
        })
        .catch((err) => {
          this.errMsg(err, this.$t('form.loading_data_error'))
        })
        .finally(() => this.stopVSLoading())
    },
    changeArchiveStatus (taskId, status) {
      this.currentTaskToArchive.id = taskId
      this.currentTaskToArchive.status = status
      console.log(this.currentTaskToArchive)
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm',
        text: `${this.$t('basic.are_you_sure_to')}  ${
          status === 'archived' ? this.$t('status.archive') : this.$t('status.active')
        } ${this.$t('basic.this_task')} ?.`,
        accept: this.archiveTask
      })
    },

    ////////////////////////////////
    // Handle showInGradebook
    ////////////////////////////////
    showInGradebook (show = true) {
      const payload = {
        resource_type:'assignment',
        resource_id: Number(this.assignment_prop.id),
        status:show ? 'unhidden' : 'hidden'
      }

      // --- Continue
      GradebookRepo.changeGradeStatus(payload)
        .then(res => {
          this.successMsg(this.$t('form.saved_successfully'))
          this.assignment_prop.hide_from_grading = !show
        })
        .catch(this.errMsg)
        .finally(this.endLoadingTable)
    }
  }
}
</script>
<style lang="scss" scoped>
.actionsButtons {
  position: absolute;
  top: 0;
  right: 0;
}
.iconButtons {
  position: absolute;
  top: 5px;
  right: 5px;
}
.hasUpdates {
  position: absolute;
  right: 10px !important;
  bottom: 10px !important;
}
.assignment-card {
  p {
    min-height: 1.5rem;
  }
}
</style>
