import axios from 'axios'

const resource = '/academic/syllabus'
export default {
  create(payload) {
    return axios.post(`${resource}/create`, payload)
  },
  get(id) {
    return axios.get(`${resource}/${id}`)
  },
  update(id, payload) {
    return axios.put(`${resource}/${id}`, payload)
  },
  getAll() {
    return axios.get(resource)
  },
  getGradesSubjects(id) {
    return axios.get(`gradeSubjects/${id}`)
  },
  getGradesSubjectsActive(id) {
    return axios.get(`gradeSubjects/active/${id}`)
  },
  changeStatus(id, payload) {
    return axios.put(`${resource}/update-status/${id}`, payload)
  },
  getTermsWithGradeAndDivisionIds(data) {
    return axios.get(`${resource}/term/${data.division_id}/${data.grade_id}`)
  },
  deleteSyllabus(syllabus_id) {
    return axios.delete(`${resource}/${syllabus_id}`)
  },
  duplicateSyllabus(syllabus_id, academic_year_id) {
    return axios.post(`${resource}/${syllabus_id}/duplicate`, {
      academic_year_id
    })
  }
}
