export default {
  AcademicYears: 'Academic Years',
  academic: 'Academic',
  AcademicYearsCreate: 'Create Academic Year',
  StartEndDates: 'Start & End Dates',
  StartDate: 'Start Date',
  EndDate: 'End Date',
  WeekStartDay: 'Week Start Day',
  SetTerms: 'Set Terms',
  Term: 'Term',
  Semester: 'Semester',
  PublicName: 'Public Name',
  SetVacations: 'Set Vacations',
  VacationName: 'Vacation Name',
  HolidayName: 'Holiday Name',
  PublicYear: 'Public Year',
  SetSpecialHoliday: 'Set Special Holiday',
  ChooseDate: 'Choose Date',
  DataSettings: 'Data & Settings',
  DivisionsCustomization: 'Divisions Customization',
  AllowDivisionToSetSpecialStartDate:
    'Allow division to set special start date',
  AllowDivisionToSetSpecialEndDate: 'Allow division to set special end date',
  AllowDivisionToSetSpecialDaysAndHours:
    'Allow division to set special days and hours',
  AllowDivisionToSetSpecialTerms: 'Allow division to set special terms',
  AllowDivisionToSetSpecialHolidaysAndVacations:
    'Allow division to set special holidays and vacations',
  TermsVacations: 'Terms & Vacations',
  CreatedBy: 'Created By',
  AcademicYear: 'Academic Year',
  DateOfCreation: 'Date Of Creation',
  Status: 'Status',
  Actions: 'Actions',
  AcademicYearsEdit: 'Academic Years Edit'
}
