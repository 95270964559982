<template>
  <!-- Update grades Popup -->
  <div>
    <!-- Loader -->
    <div
      id="loadDialog"
      v-show="loadDialog"
      ref="loadDialog"
      class="vs-con-loading__container"
    ></div>

    <!-- Resources Table -->
    <vs-table
      class="m-auto my-10"
      :data="data"
      :hoverFlat="true"
      :noDataText="!loadDialog ? $t('assessments.no_students_assessed') : ''"
      :class="{ 'no-data': !data.length }"
      id="student-score-table"
    >
      <!-- Header -->
      <template slot="thead" v-if="data.length">
        <vs-th></vs-th>
        <vs-th
          v-for="(tr, indextr) in headers"
          :key="indextr"
          :sort-key="tr.field"
          class="table__header"
          :class="{ 'text-center': tr.field !== 'name' }"
        >
          {{ tr.title }}
        </vs-th>
      </template>
      <!-- Rows -->
      <template slot-scope="{ data }">
        <vs-tr v-for="(tr, indextr) in data" :data="tr" :key="indextr">
          <template v-for="(row_data, field, indx) in tr">
            <vs-td
              v-if="
                field !== 'score' &&
                field !== 'grade_id' &&
                field !== 'criteria'
              "
              :key="indx"
              :class="{ 'text-center': field !== 'name' }"
            >
              <vs-checkbox
                v-model="tr.showInGrading"
                v-if="field === 'showInGrading'"
                :vs-value="tr.showInGrading"
                :disabled="!tr.showInGrading"
              />
              <span v-else class="text-center"> {{ row_data }}</span>
            </vs-td>
          </template>

          <vs-td>
            <div class="">
              <template v-if="isPYPSection">
                <v-select
                  :options="tr.criteria"
                  label="title"
                  :reduce="(c) => c.key"
                  v-model="tr.score"
                  class="w-full"
                  :placeholder="$t('form.grade') | text_formatter"
                  v-validate="{
                    required: !Boolean(tr.score),
                  }"
                  :name="`${tr.name}_grade`"
                />
              </template>
              <template v-else-if="isMYPSection">
                <div
                  class="flex items-center"
                  :class="{ 'mb-3': tr.criteria.length }"
                  v-for="(cr, i) in tr.criteria"
                  :key="i"
                >
                  <label class="w-1/5 mr-2">{{ cr.key }}</label>
                  <vs-input
                    v-model="tr.score[cr.key]"
                    type="number"
                    :max="8"
                    min="0"
                    class="w-4/5"
                    :placeholder="$t('form.grade') | text_formatter"
                    v-validate="{
                      required: true,
                      numeric: true,
                      max_value: 8,
                      min_value: 0,
                    }"
                    :name="`${tr.name}_grade`"
                  />
                </div>
              </template>
              <template v-else-if="isDPSection || isAmerican">
                <v-select
                  :options="tr.criteria"
                  v-model="tr.score"
                  class="w-full"
                  :placeholder="$t('form.grade') | text_formatter"
                  v-validate="{ required: !Boolean(tr.score) }"
                  :name="`${tr.name}_grade`"
                  :value="tr.score"
              /></template>

              <span class="text-danger text-sm block w-full">{{
                errors.first(`${tr.name}_grade`) | text_formatter
              }}</span>
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <!-- Btns -->
    <div class="flex justify-between items-center">
      <vs-button
        class="mt-2"
        type="border"
        color="danger"
        @click="closeUpdateStudentGrades"
        >{{ $t("basic.cancel") }}
      </vs-button>
      <vs-button
        class="mt-2 mx-2"
        @click="submitUpdateStudentGrades"
        v-if="data.length"
        >{{ $t("form.submit") }}
      </vs-button>
    </div>
  </div>
</template>
<script>
// Repos
import {
  RepositoryFactory
} from '@/Repositories/RepositoryFactory'
const GradebookRepo = RepositoryFactory.get('GradingModule', 'gradebook')


import gradingMixins from '../grading.mixins'

// plugins
import moment from 'moment'

export default {
  name:'update-student-scores',
  props:{

    selectedStudent:{
      type:Object,
      required:true
    }


  },
  mixins: [gradingMixins],
  data () {
    return {
      headers:[
        {
          field:'name',
          title:this.$t('basic.name')
        },
        {
          field:'type',
          title:this.$t('basic.type')
        },
        {
          field:'subject',
          title:this.$t('basic.subject')
        },
        {
          field:'date',
          title:this.$t('basic.date')
        },
        {
          field:'grade',
          title:this.$t('form.grade')
        },
        {
          field:'manual_grade',
          title:this.$t('grading.manual_grade')
        }

      ],
      data:[]
    }
  },
  watch:{

  },
  methods: {

    /////////////////////
    // APIs
    //////////////////////
    // Get student grades

    async getStudentGrades () {
      // --- Continue
      this.startLoadingDialog()
      await  GradebookRepo.getStudentGrades(this.selectedClass.id, this.selectedStudent.student_id)
        .then(res => {
          if (res.gradings.length) this.handleStudentData(res)
        })
        .catch(this.errMsg)
        .finally(this.endLoadingDialog)
    },
    // Update grade
    async updateGradeScores () {
      const payload =  this.data.map(e => ({
        id:e.grade_id,
        hide_from_grading:!e.showInGrading,
        score:this.isMYPSection ? e.score : [e.score]
      }))
      // --- Continue
      this.startLoadingDialog()
      await  GradebookRepo.updateScores({ grading_data:payload})
        .then(res => {
          this.successMsg(this.$t('form.saved_successfully'))
          this.$emit('reloadGrades')
          this.closeUpdateStudentGrades()
        })
        .catch(this.errMsg)
        .finally(this.endLoadingDialog)
    },

    ///////////////////////
    // Handle Data
    ///////////////////////

    //  Resources
    handleResourcesInfo (data) {
      const
        resources = [],
        commonProps = (e) => ({
          allCriterias: this.handleCriteria(e.criteria),
          subjectName:e.subject.name,
          // subjectId:e.subject.id,
          dateFormatted:new moment(e.date).format('D-MMM')

        })

      if (data.assignment?.length) resources.push(...data.assignment.map(e => {
        e.typeFlag = 'assignment'
        e.type = this.$t('basic.assignment')
        e.typeShort = 'Assi'
        e.gradeType = 'assess_assignment_task'
        const common = commonProps(e)
        e = Object.assign(common, e)
        return e
      }))
      if (data.assessment?.length) resources.push(...data.assessment.map(e => {
        e.typeFlag = 'assessment'
        e.type = this.$t('assessments.assessment')
        e.typeShort = 'Asse'
        e.gradeType = 'assess_assessment_task'
        const common = commonProps(e)
        e = Object.assign(common, e)
        return e
      }))
      if (data.exam?.length) resources.push(...data.exam.map(e => {
        e.typeFlag = 'exam'
        e.type = this.$t('basic.exam')
        e.typeShort = 'E'
        e.gradeType = 'exam_student'
        const common = commonProps(e)
        e = Object.assign(common, e)
        return e
      }))
      if (data.grading_types_details?.length) resources.push(...data.grading_types_details.map(e => {
        e.typeFlag = 'gradingTypeDetail'
        e.type = e.type.name
        e.typeShort = e.type.name
        e.gradeType = 'exam_student'
        const common = commonProps(e)
        e = Object.assign(common, e)
        return e
      }))

      return resources.sort((a, b) => new moment(a.date).diff(b.date))
    },
    handleStudentData (data) {
      const resources = this.handleResourcesInfo(data),
        getGrade = (resourceId, resourceType) => data.gradings.find(g => g.original_resource_id === resourceId && g.original_resource_type === resourceType),
        getResource = (id, type) => resources.find(r => r.id === id && r.typeFlag === type),
        gradeScore = (g) => { return g.original_criteria ? (g.original_criteria[0] || (Object.values(g.original_criteria).join('-'))) : g.score || g.label }

      this.data = data.gradings.map(g => {
        const resource = getResource(g.original_resource_id, g.original_resource_type)
        if (resource) {
          return {
            showInGrading:!resource.hide_from_grading,
            name:resource.name,
            type:resource.type,
            subject:resource.subjectName,
            date:resource.dateFormatted,
            grade:(g.label ===  null && g.score === null) ? 'N-A' : gradeScore(g),
            score: this.isMYPSection ? g.original_criteria : g.original_criteria[0] ||  g.score || null,
            grade_id:g.id,
            criteria:resource.allCriterias

          }
        }
      }).filter(Boolean)
      // this.data = resources.map(r => {
      //   const grade = getGrade(r.original_resource_id, r.original_resource_type)
      //   if (grade) {
      //     return {
      //       hide_from_grading:g.hide_from_grading,
      //       name:resource.name,
      //       type:resource.type,
      //       subject:resource.subjectName,
      //       date:resource.dateFormatted,
      //       score: this.isMYPSection ? g.original_criteria : g.original_criteria[0] ||  g.score || null,
      //       grade_id:g.id,
      //       criteria:resource.allCriterias

      //     }
      //   }
      // }).filter(Boolean)
    },


    ///////////////////////
    // Popup
    ///////////////////////
    activeUpdateStudentGrades () {
      this.allStudentsScore = null
      this.getStudentGrades()
    },
    resetSelectedResource () {
      this.allStudentsScore = null
      this.updateScoreStudentsData = []
    },

    closeUpdateStudentGrades () {
      this.resetSelectedResource()
      this.$emit('closeUpdateStudentGrades')
    },

    submitUpdateStudentGrades () {
      this.$validator.validateAll()
        .then((valid) => {
          if (valid) this.updateGradeScores()
          else this.errMsg()
        })
    }
  },
  created () {
    this.getStudentGrades()
  }

}
</script>
<style lang="scss" >
@import "./gradeBookTable/gradingTable.scss";
#student-score-table {
  &:not(.no-data) .vs-con-tbody {
    min-height: 300px;
    max-height: 100%;
  }
  tr:nth-child(2n) {
    background-color: transparent;
  }
  .v-select {
    min-width: 190px;
  }
  .vs-input {
    min-width: 50px;
  }
}
</style>
