<template>
  <div>
    <div class="assesment-task-container__btn">
      <vs-button
        @click="openCreateTaskModal(null)"
        class="ml-auto mr-0 block"
        color="primary"
        type="filled"
      >
        {{ $t("assignment.Create_Assignment_homework") }}
      </vs-button>
    </div>
    <teacher-assignment-homework
      :teacherUnitId="teacherUnitId"
      v-if="teacherUnitId"
    ></teacher-assignment-homework>
    <assessment-task-modal
      :teacherUnitId="teacherUnitId"
    ></assessment-task-modal>
    <review-task-modal></review-task-modal>
  </div>
</template>

<script>
import teacherAssignmentHomework from '../teacher-assignment-homework/teacher-assignment-homework.vue'
import assessmentTaskModal from '../../modals/create-assignment/create-assignment-homework.vue'
import ReviewTaskModal from '../../modals/review-assignments/review-assignments'
export default {
  props: ['teacherUnitId'],
  components: {
    teacherAssignmentHomework,
    assessmentTaskModal,
    ReviewTaskModal
  },
  methods: {
    openCreateTaskModal (data) {
      // eslint-disable-next-line no-undef
      $sharedEvent.fire('openCreateTaskModal', data)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
