import {
  mapGetters
} from 'vuex'
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker'
import Service from '../../services/unit-planner'

export default {
  name: 'createUnitModal',
  components: {
    'v-select': vSelect,
    Datepicker
  },
  data () {
    return {
      restService: Service,
      modalProps: {
        width: '600px',
        height: 'auto',
        minHeight: 500,
        scrollable: true
      },
      modalName: 'createUnitModal',
      loadingData: false,
      isShow: true,
      datePickerSettings: {
        format: 'dd/MM/yyyy',
        disabledDates: {
          to: null, // Disable all dates up to specific date
          from: null // Disable all dates after specific date
        },
        dateValue: null
      },
      type: [],
      grades: [],
      poiFocus: [],
      subjects: [],
      unitItems: {
        type: null,
        poi_focus: null,
        subjects: [],
        grades: null,
        start_date: null,
        end_date: null,
        academic_hours: null,
        academic_weeks: null,
        name: null,
        description: null
      },
      customEvents: [
        {
          eventName: 'openCreateUnitModal',
          callback: this.loadData
        }
      ]
    }
  },
  watch: {
    'unitItems.type' (val) {
      if (this.unitItems.type.id === 2 || this.unitItems.type.id === 3) {
        this.unitItems.poi_focus = {
          id: -1,
          name: 'Stand Alone Unit'
        }
      }
    }
  },
  created () {
    ////////////////////////////////////////////////////
    //**          Custom Event Listener           ** //
    //////////////////////////////////////////////////
    this.customEvents.forEach(function (customEvent) {
      // eslint-disable-next-line no-undef
      $sharedEvent.listen(customEvent.eventName, customEvent.callback)
    })
  },
  beforeDestroy () {
    this.$validator.reset()
    this.$validator.pause()
  },
  destroyed () {
    ////////////////////////////////////////////////////
    //**      Remove Custom Event Listener        ** //
    //////////////////////////////////////////////////
    this.customEvents.forEach(function (customEvent) {
      // eslint-disable-next-line no-undef
      $sharedEvent.off(customEvent.eventName, customEvent.callback)
    })
  },
  methods: {
    showModal () {
      /* debugger */
      this.$modal.show(this.modalName)
    },
    hideModal () {
      this.$modal.hide(this.modalName)
    },

    async getIbTypes () {
      //** Show Loader **//
      this.$vs.loading()
      //** Get IB types **//
      await this.restService.getSectionsOfIB()
        .then(res => this.type = res.data)
        .catch(err => {
          console.error(err)
          //** Hide Loader **//
          this.$vs.loading.close()

          this.errMsg(err.data.message, this.$t('form.loading_data_error'))

        })
      //** Hide Loader **//
      this.$vs.loading.close()
    },
    checkSelectedSection () {
      if (this.unitItems.type.name === 'AMERICAN') {
        //* hide poi focus and make unit stand alone *//
        this.isShow = false
        this.unitItems.is_stand_alone = 1
        this.unitItems.poi_focus = {
          id: -1,
          name: 'Stand Alone Unit'
        }
      }
      /* debugger */
    },
    async getIbGrades () {
      this.checkSelectedSection()
      /* debugger */
      //** Show Loader **//
      this.$vs.loading()
      //** Get IB Grades **//
      await this.restService.getIBGrades(this.unitItems.type.id)
        .then(res => this.grades = res.data)
        .catch(err => {
          console.error(err)
          //** Hide Loader **//
          this.$vs.loading.close()

          this.errMsg(err.data.message, this.$t('form.loading_data_error'))

        })
      //** Hide Loader **//
      this.$vs.loading.close()
    },

    async getGradesData () {
      console.log('this.unitItems', this.unitItems)
      //** Show Loader **//
      this.$vs.loading()
      //** Get Grades Subjects **//
      await this.restService.getGradesSubject(this.unitItems.grades.id)
        .then(res => this.subjects = res.data)
        .catch(err => console.error(err))
      if (this.isShow) {
        //** Get Active Units By Grades (POI Focus) **//
        await this.restService.getActiveUnitsByGrade(this.unitItems.grades.id)
          .then(res => {
            this.poiFocus = res.data
            this.poiFocus.push({
              id: -1,
              name: 'Stand Alone Unit'
            })

            //!** handle update mode *!/
            if (this.unitItems.id) {
              if (this.unitItems.type.id !== 2 && this.unitItems.type.id !== 3) {
                this.unitItems.poi_focus = this.poiFocus.find(unit => unit.id === this.unitItems.poi_focus)
              }
              this.loadingData = false
            }

          })
          .catch(err => {
            console.error(err)
            //** Hide Loader **//
            this.$vs.loading.close()

            this.$vs.notify({
              title: 'Loading Data Error',
              text: err.data.message,
              color: 'danger',
              position: 'bottom-right'
            })
          })
      } else {
        this.loadingData = false
      }
      //** Hide Loader **//
      this.$vs.loading.close()
    },
    limiter (e) {
      if (this.is_MYP || this.is_DP) {
        if (e.length > 2) {
          e.pop()
          this.$vs.notify({
            title: 'You Couldn\'t choose more than 2 subjects',
            color: 'warning',
            position: 'bottom-right'
          })
        }
      }
    },
    loadData (data) {
      this.resetProps()

      // binding Data **//
      this.datePickerSettings.disabledDates = {
        from: new Date(this.currentAcademicYear.end_date), // Disable all dates after specific date
        to: new Date(this.currentAcademicYear.start_date) // Disable all dates up to specific date
      }

      this.datePickerSettings.dateValue = new Date(this.currentAcademicYear.start_date)

      this.getIbTypes()
        .then(() => {
          //** bind data for update modal */
          this.bindUpdateData(data)

          this.showModal()
        })


    },
    async bindUpdateData (data) {
      if (data) {
        this.loadingData = true
        this.unitItems = {
          id: data.id,
          is_stand_alone: data.is_stand_alone,
          unit_academic_id: data.unit_academic_id,
          type: data.sectionObj,
          poi_focus: (data.is_stand_alone == 1) ? -1 : data.unit_academic_id,
          subjects: data.subjects,
          grades: data.grade,
          start_date: new Date(data.startDate),
          end_date: new Date(data.endDate),
          /*academic_hours: {id: Number(data.hours), name: Number(data.hours)},
                        academic_weeks: {id: Number(data.weeks), name: Number(data.weeks)},*/
          academic_hours: Number(data.hours),
          academic_weeks: Number(data.weeks),
          name: data.name,
          description: data.description
        }
        //** get select data */
        await this.getIbGrades()
        await this.getGradesData()
      }
    },
    resetProps () {
      this.unitItems = {
        type: null,
        poi_focus: null,
        subjects: [],
        grades: null,
        start_date: null,
        end_date: null,
        academic_hours: null,
        academic_weeks: null,
        name: null,
        description: null
      }
    },
    mappingData (status) {
      /* debugger */
      const poi_focus = this.unitItems.poi_focus
      return {
        name: this.unitItems.name,
        section_id: this.unitItems.type.id,
        grade_id: this.unitItems.grades.id,
        unit_academic_id: (poi_focus && poi_focus.id != -1) ? poi_focus.id : null,
        subject_ids: this.unitItems.subjects.map(item => item.division_subject_id),
        status: (status) ? status : 'active',
        academic_hours: Number(this.unitItems.academic_hours),
        academic_weeks: Number(this.unitItems.academic_weeks),
        description: this.unitItems.description,
        is_stand_alone: (poi_focus && poi_focus.id == -1),
        start_date: (poi_focus && poi_focus.id == -1) ? this.unitItems.start_date.toISOString().split('T')[0] : null,
        end_date: (poi_focus && poi_focus.id == -1) ? this.unitItems.end_date.toISOString().split('T')[0] : null
      }
    },
    async createUnit () {
      const validData = await this.$validator.validateAll()
      if (validData) {
        //** Show Loader **//
        this.$vs.loading()
        //** Get IB Grades **//
        await this.restService.createTeacherUnit(this.mappingData('active'))
          .then(res => this.afterSaving(res.data, 'active'))
          .catch(err => {
            console.error(err)
            //** Hide Loader **//
            this.$vs.loading.close()

            this.$vs.notify({
              title: 'Creation Error',
              text: err.data.message,
              color: 'danger',
              position: 'bottom-right'
            })
          })
        //** Hide Loader **//
        this.$vs.loading.close()
      }
    },

    async updateUnit () {
      const validData = await this.$validator.validateAll()
      if (validData) {
        //** Show Loader **//
        this.$vs.loading()
        //** Get IB Grades **//
        this.restService.updateTeacherUnit(this.mappingData('active'), this.unitItems.id)
          .then(res => this.afterSaving(res.data, 'active'))
          .catch(err => {
            console.error(err)
            //** Hide Loader **//
            this.$vs.loading.close()

            this.$vs.notify({
              title: 'Creation Error',
              text: err.data.message,
              color: 'danger',
              position: 'bottom-right'
            })
          })
        //** Hide Loader **//
        this.$vs.loading.close()
      }
    },

    async saveAsDraft () {
      const validData = await this.$validator.validateAll()
      if (validData) {
        //** Show Loader **//
        this.$vs.loading()

        if (this.unitItems.id) {
          await this.restService.updateTeacherUnit(this.mappingData('draft'), this.unitItems.id)
            .then(res => this.afterSaving(res.data, 'draft'))
            .catch(err => {
              console.error(err)
              //** Hide Loader **//
              this.$vs.loading.close()

              this.$vs.notify({
                title: 'Creation Error',
                text: err.data.message,
                color: 'danger',
                position: 'bottom-right'
              })
            })
        } else {
          await this.restService.createTeacherUnit(this.mappingData('draft'))
            .then(res => this.afterSaving(res.data, 'draft'))
            .catch(err => {
              console.error(err)
              //** Hide Loader **//
              this.$vs.loading.close()

              this.$vs.notify({
                title: 'Creation Error',
                text: err.data.message,
                color: 'danger',
                position: 'bottom-right'
              })
            })
        }

        //** Hide Loader **//
        this.$vs.loading.close()
      }

    },
    storeSaving (unitData, storeName) {
      //** Mapping Data before saving to store**//
      unitData.gradeValue = unitData.grade.name
      unitData.subjectsValue = unitData.subjects.map(item => item.subject_name).join(', ')
      unitData.startDate = unitData.start_date
      unitData.endDate = unitData.end_date
      unitData.hours = unitData.academic_hours
      unitData.weeks = unitData.academic_weeks

      this.$store.dispatch(storeName, unitData)

      this.$vs.notify({
        title: 'Unit Creation',
        text: 'Created Successful',
        color: 'success',
        position: 'bottom-right'
      })
    },
    afterSaving (data, type) {
      this.storeSaving(data, 'createUnit')

      if (type === 'active') {
        this.addResources(data.section.name)
      } else {
        this.sendDraftUnit(data)
      }
      this.cancel()
    },
    addResources (type) {
      this.$router.push({ name: 'unitResources', query: { type: type.toLowerCase() } })
    },
    sendDraftUnit (data) {
      $sharedEvent.fire('added-draft-unit', data)
    },
    cancel () {
      this.$validator.pause()
      this.resetProps()
      this.$nextTick(() => {
        this.$validator.errors.clear()
        this.$validator.fields.items.forEach(field => field.reset())
        this.$validator.fields.items.forEach(field => this.errors.remove(field))
        this.$validator.resume()
        this.hideModal()
      })
    }
  },
  computed: {
    ...mapGetters({
      currentAcademicYear: 'getCurrentAcademicYearPlanner'
    }),
    is_MYP () {
      return this.unitItems.type.id === 2
    },
    is_DP () {
      return this.unitItems.type.id === 3
    }
  }
}
