/*=========================================================================================
  File Name: moduleAuthGetters.js
  Description: Auth Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  currentLocale: (state) => {
    return state.currentLocale
  },
  locales: (state) => {
    return state.locales
  },
  siteLink: (state) => {
    return state.institutionLink || state.appLink
  },
  siteTitle: (state) => {
    return state.institutionName || state.appName
  },
  institutionAssetsLink: (state) => {
    return state.institutionAssetsLink
  },
  institutionDriverLink: (state) => {
    return state.institutionDriverLink
  },
  institutionShortName: (state) => {
    return state.institutionShortName
  },
  institutionLongName: (state) => {
    return state.institutionLongName
  },
  logoImg: (state) => {
    return state.institutionLogo || state.appLogo
  },
  appName: (state) => {
    return state.appName
  },
  appLink: (state) => {
    return state.appLink
  },

  favicon: (state) => {
    return state.institutionFavicon
  },
  institutionApiRes: (state) => {
    return state.institutionApiRes
  },
  themePrimaryColor: (state) => {
    return state.themePrimaryColor
  },
  currentAcademicYearId: (state) => state.currentAcademicYearId,
  currentAcademicYearData: (state) => state.currentAcademicYearData
}
