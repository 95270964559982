<template>
  <vs-upload
    :automatic="automaticUpload"
    :action="action"
    :text="text || $t('basic.upload_file')"
    :headers="requestHeaders || {}"
    :multiple="multiple"
    :accept="accept || 'all'"
    :show-upload-button="showButton"
    :disabled="disabled"
    :limit="limitFileNum || 1"
    :data="appendedData || {}"
    :fileName="fileName || ''"
    :v-validate="required ? 'required' : ''"
    @on-success="successUpload"
    @on-delete="deleteUpload"
    @on-error="errorUpload"
  />
</template>
<script>
export default {
  name:'upload-input',
  props:{
    automaticUpload:{ type:Boolean },
    action:{type:URL },
    text:{type:String},
    fileName:{type:String},
    requestHeaders:{type:Object},
    multiple:{type:Boolean},
    accept:{type:String},
    showButton:{type:Boolean},
    disabled:{type:Boolean},
    limitFileNum:{type:Number},
    appendedData:{type:Object}
  },
  methods:{
    successUpload ($event) {
      this.$emit('upload', $event)
      this.$vs.notify({color:'success', title:'Upload Success', text:'file uploaded Successfully'})
    },
    deleteUpload ($event) {
      this.$emit('delete', $event)

      this.$vs.notify({color:'warning', title:'Delete Success', text:'File deleted Successfully'})
    },
    errorUpload () {
      this.$vs.notify({color:'danger', title:'Upload Error ', text:'Somthing happed during uploading ,please Try Again'})
    }
  }
}
</script>
