import ListTeachers from './pages/list'
import addTeacher from './pages/Create'
import edit_teacher from './pages/edit'
import create_hod from './pages/create-hod.vue'
import create_teacher_parent from './pages/create-teacher-parent'
import create_section_coodinator from './pages/create-section-coodinator.vue'
import edit_section_coodinator from './pages/edit-section-coodinator.vue'
import create_grade_coodinator from './pages/create-grade-coodinator.vue'
import teacherSubjects from './pages/teacherSubjects.vue'
const routes = [
  //-------- Start List All Teacher -----
  {
    path: '/',
    name: 'ListTeacherGateway',
    component: ListTeachers,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        {
          title: 'teachersGateway.list_teachers',
          active: true
        }
      ],

      hideVerticalMenu:false,
      pageTitle: 'teachersGateway.list_teachers',
      rule:'SNDSMD'
    }
  },
  //-------- End List All Teacher -----

  //-------- Start add Teacher  -----
  {
    path: 'add-teacher',
    name: 'add-teacher',
    component: addTeacher,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        { title: 'teachersGateway.list_teachers', url: '/teachers-gateway/' },
        {
          title: 'teachersGateway.addTeacher',
          active: true
        }
      ],
      hideVerticalMenu:false,
      pageTitle: 'teachersGateway.addTeacher',
      rule:'SNDSMD'
    }
  },
  //-------- End add Teacher  -----

  //------Start edit Teacher------
  {
    path: 'edit-teacher/:id',
    name: 'edit-teacher',
    component: edit_teacher,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        { title: 'teachersGateway.list_teachers', url: '/teachers-gateway/' },
        {
          title: 'teachersGateway.edit_teacher',
          active: true
        }
      ],
      hideVerticalMenu:false,
      pageTitle: 'teachersGateway.edit_teacher',
      rule:'SNDSMD'
    }
  },
  //------Start edit Teacher------


  //------Start Create Hod Teacher------
  {
    path: 'create-teacher-parent/:id',
    name: 'create-teacher-parent',
    component: create_teacher_parent,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        { title: 'teachersGateway.list_teachers', url: '/teachers-gateway/' },
        {
          title: 'basic.create',
          active: true
        }
      ],
      hideVerticalMenu:false,
      pageTitle: 'teachersGateway.create_teacher_parent',
      rule:'SNDSMD'
    }
  },
  //------Start Create Hod Teacher------

  //------Start Create Teacher Parent----
  {
    path: 'create-hod/:id',
    name: 'create-hod',
    component: create_hod,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        { title: 'teachersGateway.list_teachers', url: '/teachers-gateway/' },
        {
          title: 'basic.create',
          active: true
        }
      ],
      hideVerticalMenu:false,
      pageTitle: 'teachersGateway.create_hod',
      rule:'SNDSMD'
    }
  },
  //------End Create Teacher Parent----


  //------Start  create-section-coodinator ------
  {
    path: 'create-section-coodinator/:id',
    name: 'create-section-coodinator',
    component: create_section_coodinator,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        { title: 'teachersGateway.list_teachers', url: '/teachers-gateway/' },
        {
          title: 'basic.create',
          active: true
        }
      ],
      hideVerticalMenu:false,
      pageTitle: 'teachersGateway.create_section_coodinator',
      rule:'SNDSMD'
    }
  },
  //------Start create-section-coodinator ------


  //------Start  Edit-section-coodinator ------
  {
    path: 'edit-section-coodinator/:id',
    name: 'edit-section-coodinator',
    component: edit_section_coodinator,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        { title: 'teachersGateway.list_teachers', url: '/teachers-gateway/' },
        {
          title: 'basic.create',
          active: true
        }
      ],
      hideVerticalMenu:false,
      pageTitle: 'teachersGateway.edit_section_coodinator',
      rule:'SNDSMD'
    }
  },
  //------Start Edit-section-coodinator ------


  //------Start  create-grade-coodinator ------
  {
    path: 'create-grade-coodinator/:id',
    name: 'create-grade-coodinator',
    component: create_grade_coodinator,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        { title: 'teachersGateway.list_teachers', url: '/teachers-gateway/' },
        {
          title: 'basic.create',
          active: true
        }
      ],
      hideVerticalMenu:false,
      pageTitle: 'teachersGateway.create_grade_coodinator',
      rule:'SNDSMD'
    }
  },
  //------Start create-grade-coodinator ------

  //-------- Start List All Teacher Subject -----
  {
    path: 'teacherSubjects/:user_id',
    name: 'teacherSubjects',
    component: teacherSubjects,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        {
          title: 'teachersGateway.list_teachers',
          url: '/teachers-gateway/',
          active: true
        },
        {
          title: 'subject.Subjects',
          active: true
        }
      ],

      hideVerticalMenu:false,
      pageTitle: 'subject.Subjects',
      rule:'SNDSMD'
    }
  },
  //-------- End List All Teacher Subject -----
]

export default routes
