<!--
/**
////////////////////////////////////////////////////////////
 ** aG grid Datatable Props :
{
  headerName:'ID' ,
  minWidth:20, // col min width
  field : 'id', // the matched field of the data
  checkboxSelection : Boolean,
  headerCheckboxSelectionFilteredOnly :Boolean
  headerCheckboxSelection: Boolean
  cellRendererFramework : 'cellRender component name',
  cellRendererParams: {}, // params that passes to the cellRender Component
  valueGetter: '' || function(data) , //for change the display data
}

** component props
headers_prop
list_url_prop
list_items
export_prop
export_csv_method
export_pdf_method
csv_url
reset_paswwords
pdf_url
allow_print_pdf
allow_print_csv
CSVFileName String /Function (row)=?row.gradeName for getting a data the list
side_filters
floatingFilter
loading
endPointParams
allowPagination

** reCallRequest RecallRequest without action from cellRenderAction
:reCallRequest="reCallRequest"
@update:reCallRequest="updateReCallRequest"
////////////////////////////////////////////////////////////
*/
-->

<template>
  <div id="page-user-list" ref="printMe">
    <div class="vx-card p-6">
      <div class="flex flex-wrap items-center justify-between">
        <!-- ITEMS PER PAGE -->

        <div
          class="p-4 border border-solid rounded-full cursor-pointer font-medium">
          <span class="mr-2"
            >{{
              allowPagination
                ? currentPage * paginationPageSize -
                  (paginationPageSize - 1) +
                  ' - '
                : ' '
            }}

            {{
              allowPagination &&
              list_length - currentPage * paginationPageSize > 0
                ? currentPage * paginationPageSize
                : list_length
            }}
            of {{ list_length }}</span
          >
        </div>
        <!-- <vs-dropdown vs-trigger-click class="cursor-pointer"> -->
        <!-- <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" /> -->
        <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
        <!--            <vs-dropdown-menu>-->
        <!--              <vs-dropdown-item @click="gridApi.paginationSetPageSize(10)">-->
        <!--                <span>10</span>-->
        <!--              </vs-dropdown-item>-->
        <!--              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">-->
        <!--                <span>20</span>-->
        <!--              </vs-dropdown-item>-->
        <!--              <vs-dropdown-item @click="gridApi.paginationSetPageSize(25)">-->
        <!--                <span>25</span>-->
        <!--              </vs-dropdown-item>-->
        <!--              <vs-dropdown-item @click="gridApi.paginationSetPageSize(30)">-->
        <!--                <span>30</span>-->
        <!--              </vs-dropdown-item>-->
        <!--            </vs-dropdown-menu>-->
        <!-- </vs-dropdown> -->

        <!-- Centerd Content -->
        <div class="w-auto mx-auto">
          <slot name="center_content"></slot>
        </div>
        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
        <!-- <vs-input
          class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
          v-model="searchQuery"
          @input="updateSearchQuery"
          placeholder="Search..."
        /> -->
        <!-- <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Export as CSV</vs-button> -->

        <!-- ACTION - DROPDOWN -->
        <vs-dropdown
          v-if="csv_url || pdf_url || banned || released || allow_print_CSV"
          vs-trigger-click
          class="cursor-pointer">
          <div
            class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
            <span class="mr-2 leading-none">{{ $t('basic.actions') }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>

          <vs-dropdown-menu>
            <!--  <vs-dropdown-item>
              <span class="flex items-center">
                <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                <span>Delete</span>
              </span>
            </vs-dropdown-item>-->

            <!-- <vs-dropdown-item>
              <span class="flex items-center">
                <feather-icon icon="ArchiveIcon" svgClasses="h-4 w-4" class="mr-2" />
                <span>Archive</span>
              </span>
            </vs-dropdown-item>-->
            <!-- TODO:: pdf link -->
            <!-- <vs-dropdown-item @click="printMe" v-if="allow_print_pdf">
              <span class="flex items-center">
                <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
                <span>PDF</span>
              </span>
            </vs-dropdown-item>-->

            <vs-dropdown-item
              v-if="csv_url || allow_print_CSV"
              @click="exportToCSV(csv_url || CSVFileName)">
              <span class="flex items-center">
                <feather-icon
                  icon="SaveIcon"
                  svgClasses="h-4 w-4"
                  class="mr-2" />
                <span>CSV</span>
              </span>
            </vs-dropdown-item>

            <vs-dropdown-item v-if="pdf_url" @click="exportToPDF(pdf_url)">
              <span class="flex items-center">
                <feather-icon
                  icon="BookOpenIcon"
                  svgClasses="h-4 w-4"
                  class="mr-2" />
                <span>PDF</span>
              </span>
            </vs-dropdown-item>

            <vs-dropdown-item
              v-if="reset_paswwords && checkUserRole('SND')"
              @click="resetAllPaswwords(reset_paswwords)">
              <span class="flex items-center">
                <feather-icon
                  icon="RotateCcwIcon"
                  svgClasses="h-4 w-4"
                  class="mr-2" />
                <span>{{ $t('form.reset_paswwords') }}</span>
              </span>
            </vs-dropdown-item>

            <vs-dropdown-item
              v-if="import_users && checkUserRole('SND')"
              @click="activePrompt = true">
              <span class="flex items-center">
                <feather-icon
                  icon="UploadCloudIcon"
                  svgClasses="h-4 w-4"
                  class="mr-2" />
                <span>import excel</span>
              </span>
            </vs-dropdown-item>
            <div v-if="selected_data.length > 0">
              <vs-dropdown-item v-if="released" @click="$emit('released')">
                <span class="flex items-center">
                  <feather-icon
                    icon="UserIcon"
                    svgClasses="h-4 w-4"
                    class="mr-2" />
                  <span>{{ $t('status.released') }}</span>
                </span>
              </vs-dropdown-item>
              <vs-dropdown-item v-if="banned" @click="$emit('banned')">
                <span class="flex items-center">
                  <feather-icon
                    icon="UserXIcon"
                    svgClasses="h-4 w-4"
                    class="mr-2" />
                  <span>{{ $t('status.banned') }}</span>
                </span>
              </vs-dropdown-item>
              <vs-dropdown-item
                v-if="released || banned"
                @click="$emit('remove')">
                <span class="flex items-center">
                  <feather-icon
                    icon="Trash2Icon"
                    svgClasses="h-4 w-4"
                    class="mr-2" />
                  <span>{{ $t('basic.delete') }}</span>
                </span>
              </vs-dropdown-item>
            </div>
            <vs-dropdown-item
              v-else-if="selected_data.length == 0 && (released || banned)">
              <span class="text-danger">Please Select Rows</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>
      <!-- propemt for upload excel -->
      <vs-prompt
        :title="'import excel File'"
        @accept="importUsers()"
        :active.sync="activePrompt">
        <div class="con-exemple-prompt">
          <vs-input
            type="file"
            @change="uploadExcl"
            accept="capture=camera"></vs-input>
        </div>
      </vs-prompt>

      <vs-popup
        title="Errors of Your File"
        background-color="rgba(255,255,255,.6)"
        :active.sync="activePromptForErrors">
        <div v-if="rows">
          <p class="py-1">Error In Row {{ rowNumber }}</p>
          <p v-for="(row, index) in rows" :key="index" class="py-1 text-bold">
            {{ row.errors[0] }}
          </p>
        </div>
      </vs-popup>

      <!-- AgGrid Table -->
      <div id="grid-wrapper" style="width: 100%; height: 100%">
        <ag-grid-vue
          ref="agGridTable"
          :components="components"
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="headers"
          :defaultColDef="defaultColDef"
          :rowData="list"
          rowSelection="multiple"
          rowMultiSelectWithClick="true"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="floatingFilter"
          :pagination="allowPagination"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="allowPagination"
          :enableRtl="$vs.rtl"
          :enableBrowserTooltips="true"
          :tooltipMouseTrack="true"
          :scrollbarWidth="10"
          @sortChanged="onSortChanged"
          @grid-ready="onGridReady"
          @row-selected="getSelectedRows"
          @first-data-rendered="onFirstDataRendered"
          @grid-size-changed="onGridSizeChanged" />
      </div>
      <vs-pagination
        :total="totalPages"
        :max="7"
        v-model="currentPage"
        v-if="totalPages > 0" />
    </div>
    <div
      ref="dataTableLoader"
      id="dataTableLoader"
      class="vs-con-loading__container"
      v-show="loading || showLoader"></div>
  </div>
</template>

<script>
import uploadButton from '@/modules/unit-planner/components/uploadButton/uploadButton.vue'
import {AgGridVue} from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'
import _ from 'lodash'

// Cell Renderer
import CellRendererStatus from './cell-renderer/CellRendererStatus.vue'
import CellRendererActions from './cell-renderer/CellRendererActions.vue'

import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
import axios from 'axios'

const DataTableRepository = RepositoryFactory.get('General', 'dataTable')
export default {
  name: 'DataTable',
  props: {
    headers_prop: {
      type: Array,
      required: true
    },
    list_url_prop: {
      type: String,
      required: false
    },
    list_items: {
      type: [Array, Object],
      required: false
    },
    export_prop: {
      type: Boolean,
      default: true
    },
    export_csv_method: {
      type: String,
      default: () => 'get'
    },
    export_pdf_method: {
      type: String,
      default: () => 'get'
    },
    csv_url: {
      type: String
    },
    reset_paswwords: {
      type: String
    },
    import_users: {
      type: String
    },
    pdf_url: {
      type: String
    },
    allow_print_pdf: {
      type: Boolean
    },
    allow_print_CSV: {
      type: Boolean
    },
    CSVFileName: {
      type: [String, Function]
    },
    side_filters: {
      type: Object
    },
    floatingFilter: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    endPointParams: {
      type: String,
      default: () => '',
      required: false
    },
    reCallRequest: {
      type: Boolean,
      default: false,
      required: false
    },
    banned: {
      type: Boolean,
      default: false,
      required: false
    },
    released: {
      type: Boolean,
      default: false,
      required: false
    },
    request_method: {
      type: 'string',
      required: false
    },
    allowPagination: {
      type: Boolean,
      default: true
    },
    searchData: {
      type: String,
      default: ''
    }
  },
  components: {
    AgGridVue,
    vSelect,
    uploadButton,

    // Cell Renderer
    CellRendererStatus,
    CellRendererActions
  },
  data() {
    return {
      sortedColumnName: '',
      sortType: '',
      rows: [],
      rowNumber: '',
      importFile: '',
      activePromptForErrors: false,
      activePrompt: false,
      inAnthorPage: true,
      downloadUrl: '',
      headerTitle: [],
      headerVal: [],
      headers: this.headers_prop,
      list_url: this.list_url_prop,
      selected_data: [],
      list: [],
      export_file: this.export_prop,
      searchQuery: '',
      // AgGrid
      gridApi: null,
      gridColumnApi: null,
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: false,
              suppressValues: false,
              suppressPivots: false,
              suppressPivotMode: true,
              suppressColumnFilter: false,
              suppressColumnSelectAll: true,
              suppressColumnExpandAll: false
            }
          }
        ],
        defaultToolPanel: 'columns'
      },

      gridOptions: {enableBrowserTooltips: true},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
        editable: false,
        filter: true,
        enablePivot: true,
        enableBrowserTooltips: true
      },

      totalPages: 0,
      currentPage: 1,
      list_length: 0,

      // pdf print
      printElement: null,
      printElementDimension: {
        width: null,
        height: null
      },
      pdfOptions: {
        orientation: 'portrait',
        unit: 'pt',
        fileName: 'Generated Report',
        format: {}
      },
      // Cell Renderer Components
      components: {
        CellRendererStatus,
        CellRendererActions
      },
      // loading
      showLoader: false,
      // hide colunms
      defaultToolPanel: 'columns',
      side_filters_mode: false,
      list_items_mode: false
    }
  },
  watch: {
    roleFilter(obj) {
      this.setColumnFilter('role', obj.value)
    },
    statusFilter(obj) {
      this.setColumnFilter('status', obj.value)
    },
    isVerifiedFilter(obj) {
      const val =
        obj.value === 'all' ? 'all' : obj.value === 'yes' ? 'true' : 'false'
      this.setColumnFilter('is_verified', val)
    },
    departmentFilter(obj) {
      this.setColumnFilter('department', obj.value)
    },
    currentPage(page) {
      if (this.side_filters_mode || this.list_items_mode) return
      const page_link = `?page=${page}`
      this.getListDataDebounce(page_link)
    },
    side_filters: {
      handler() {
        this.side_filters_mode = true
        if (this.currentPage > 1) this.currentPage = 1
        else this.getListDataDebounce()
      },
      deep: true
    },
    list_items: {
      handler(v) {
        if (v) {
          this.list_items_mode = true
          // this.assignData(v, 1)
          this.handleListItemsMode()
        } else this.getListDataDebounce()
      },
      deep: true
    },
    loading(v) {
      if (v) this.startLoading()
      else this.endLoading()
    },
    reCallRequest(v) {
      if (v) {
        this.selected_data = []
        this.getListDataDebounce()
      }
    }
  },
  computed: {
    paginationPageSize: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetPageSize()
        else return 10
      },
      set(v) {
        return v
      }
    }
  },
  methods: {
    onSortChanged(params){
      // Handle sorting event
      const sortedColumns = params.api.getSortModel();
      if (sortedColumns.length > 0) {
        const sortedColumn = sortedColumns[0]; // Assuming only one column is sorted
        this.sortedColumnName = sortedColumn.colId;
        this.sortType = sortedColumn.sort;
      }
    },
    ///////////////////////////////
    // loading
    ///////////////////////////////
    startLoading() {
      this.showLoader = true
      this.VSLoading(this.$refs.dataTableLoader)
    },
    endLoading() {
      this.stopVSLoading(this.$refs.dataTableLoader)
      this.showLoader = false
      this.reCallRequest = false
      this.$emit('update:reCallRequest', false)
    },
    ///////////////////////////////
    // Exporting
    ///////////////////////////////
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          // Add col name which needs to be translated
          // if (j === 'timestamp') {
          //   return parseTime(v[j])
          // } else {
          //   return v[j]
          // }

          return v[j]
        })
      )
    },

    startDownloadExporting(url) {
      this.successMsg(this.$t('form.Started_Downloading'))

      const downloadUrl = url.toString(),
        fileName = downloadUrl.split('/').slice(-1),
        link = document.createElement('a')

      link.href = downloadUrl
      link.setAttribute('download', fileName)

      if (this.inAnthorPage) {
        link.setAttribute('target', '_blank')
      }

      document.body.appendChild(link)
      link.click()
      link.remove()
    },
    /////////start CSV////////////

    exportToCSV(url) {
      if (this.csv_url) {
        axios[this.export_csv_method](url).then((res) => {
          this.$emit('startDownload', res)
          this.inAnthorPage = true
          this.startDownloadExporting(res)
        })
      } else {
        const rows = Array.from(this.list),
          headers = Array.from(this.headers),
          fileName = () => {
            if (typeof this.CSVFileName === 'function') {
              return this.CSVFileName(rows[0])
            } else return this.CSVFileName
          }
        if (this.headers[this.headers.length - 1].field === 'actions') {
          headers.pop()
        }

        this.exportToExcel(headers, rows, fileName() || 'Data CSV')
      }

      // this.headerTitle = []
      // this.headerVal = []
      // import('@/vendor/Export2Excel').then((excel) => {
      //   this.headers.forEach((element) => {
      //     if (element.headerName != 'Actions') {
      //       this.headerTitle.push(element.headerName)
      //       this.headerVal.push(element.field)
      //     }
      //   })
      //   const list = this.list
      //   const data = this.formatJson(this.headerVal, list)
      //   excel.export_json_to_excel({
      //     header: this.headerTitle,
      //     data
      //   })
      //   this.clearFields()
      // })
    },
    ///////////End CSV////////////
    exportToPDF(url) {
      axios[this.export_pdf_method](url).then((res) => {
        this.$emit('startDownload', res)
        this.inAnthorPage = true
        this.startDownloadExporting(res)
      })
    },
    /////////start PDF ////////////

    async printMe() {
      // convert from px to point //

      this.printElementDimension = {
        width: this.printElement.clientWidth * 0.75,
        height: this.printElement.clientHeight * 0.75
      }

      this.pdfOptions.format = this.printElementDimension

      this.pdfBase64 = await this.html2PdfBase64(
        this.printElement,
        this.pdfOptions
      )
    },
    ///////End PDF ////////////
    //////////////////////
    // AGgrid Filters
    //////////////////////
    setColumnFilter(column, val) {
      const filter = this.gridApi.getFilterInstance(column)
      let modelObj = null

      if (val !== 'all') {
        modelObj = {
          type: 'equals',
          filter: val
        }
      }

      filter.setModel(modelObj)
      this.gridApi.onFilterChanged()
    },
    resetColFilters() {
      // Reset Grid Filter
      this.gridApi.setFilterModel(null)
      this.gridApi.onFilterChanged()

      // Reset Filter Options
      this.roleFilter =
        this.statusFilter =
        this.isVerifiedFilter =
        this.departmentFilter =
          {
            label: 'All',
            value: 'all'
          }

      this.$refs.filterCard.removeRefreshAnimation()
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val)
    },
    //////////////////////
    // AGgrid Methods
    //////////////////////
    onGridReady(params) {
      this.gridApi = params.api
      this.columnApi = params.columnApi
    },
    onGridSizeChanged(params) {
      const gridWidth = document.getElementById('grid-wrapper').offsetWidth

      params.api.sizeColumnsToFit()
    },
    getSelectedRows() {
      const data = this.gridApi.getSelectedRows()
      this.selected_data = data.map((elm) => elm.id)
      if (this.banned || this.released) {
        this.$emit('selectedData', this.selected_data)
      }
    },
    setSidebarColumns() {
      this.sideBar = {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressColumnFilter: true,
              suppressColumnSelectAll: true,
              suppressColumnExpandAll: true
            }
          }
        ],
        defaultToolPanel: 'columns'
      }
    },
    // Fit columns width
    onFirstDataRendered() {
      this.gridApi.sizeColumnsToFit()
      // const allColumnIds = []
      // this.gridColumnApi.getAllColumns().forEach(function (column) {
      //   allColumnIds.push(column.colId)
      // })
      // this.gridColumnApi.autoSizeColumns(allColumnIds, true)
    },
    /////////////////////////////////
    //  GEt List Data APIs
    ////////////////////////////////
    setListTooltips(data) {
      if (data.length) {
        data.forEach((e) => {
          const field = this.headers.find((h) => e[h.field]).field
          e.tooltipField = field
          e.tooltipComponentParams = {text: e[field]}
        })
        return data
      }
    },
    resetModes() {
      this.side_filters_mode = false
      this.list_items_mode = false
    },
    assignData(res, page) {
      if (res.length > 0 && !res.per_page && this.allowPagination) {
        this.totalPages = Math.ceil(res.length / 10)
        this.currentPage = page
        this.list_length = res.length || 0
        this.list = res.slice(
          (this.currentPage - 1) * this.paginationPageSize,
          this.currentPage * this.paginationPageSize
        )
        this.resetModes()
        return
      }
      if (res.length > 0 && !res.per_page && !this.allowPagination) {
        this.list = res
        this.paginationPageSize = this.list_length = res.length
        this.resetModes()
        return
      }

      let data = [],
        meta = {}
      if (res.data?.data) {
        data = res.data.data
        meta = res.data
      } else {
        data = res.data
        meta = res
      }

      this.list = this.setListTooltips(data)

      // eslint-disable-next-line no-unreachable
      this.totalPages = meta.last_page || 1
      this.paginationPageSize = meta.per_page
      // eslint-disable-next-line no-unreachable
      this.currentPage = meta.current_page || 1
      this.list_length = meta.total || 0
      this.resetModes()
    },
    handleListItemsMode(page) {
      if (Array.isArray(this.list_items) && this.list_items?.length > 0) {
        const pageNumber = page ? Number(page.split('=')[1]) : 1
        return this.assignData(this.list_items, pageNumber)
      } else if (this.list_items?.per_page) {
        return this.assignData(this.list_items, 1)
      }
    },
    resetAllPaswwords(url) {
      DataTableRepository.resetAllPaswwords(url).then((res) => {
        this.successMsg(res.status)
      })
    },
    getListData(page) {
      if (!this.list_url) {
        this.handleListItemsMode()
        return
      }
      this.startLoading();

      let sortParams = `&sort_column=${this.sortedColumnName}&sort_type=${this.sortType}`;

      let repo_method = this.side_filters ? 'listDataFilter' : 'listData',
        url = page
          ? this.list_url + page + sortParams
          : `${this.list_url}?page=${this.currentPage + sortParams}${
              this.endPointParams ? `&${this.endPointParams}` : ''
            }`

        if(this.searchData)
          url += `&search=${this.searchData}`
      // const sideFilterHasAcademicYear = this.side_filters.find

      DataTableRepository[repo_method](url, this.side_filters || null)
        .then((res) => {
          this.assignData(res.students || res)
        })
        .catch(() => {
          if (this.list_items.length) this.handleListItemsMode()
        })
        .finally(() => {
          this.endLoading()
        })
    },
    getListDataAfterChange() {
      DataTableRepository.listData(
        `${this.list_url}?page=${this.currentPage}`
      ).then((res) => {
        this.assignData(res)
      })
    },
    getListDataDebounce: _.debounce(function (page) {
      this.getListData(page)
    }, 300),
    clearFields() {
      this.fileName = ''
      this.cellAutoWidth = true
      this.selectedFormat = 'xlsx'
    },

    uploadExcl(e) {
      this.importFile = e.target.files[0]
    },
    importUsers() {
      const formData = new FormData()
      formData.append('file', this.importFile)
      DataTableRepository.importExcl(this.import_users, formData)
        .then((res) => {
          this.successMsg(res.status)
          this.getListData()
        })
        .catch((err) => {
          this.activePromptForErrors = true
          this.rowNumber = err.data.message[0].row
          this.rows = err.data.message
        })
    }
  },
  mounted() {
    this.printElement = this.$refs.printMe
    this.gridApi = this.gridOptions.api

    this.gridColumnApi = this.gridOptions.columnApi
    this.setSidebarColumns()
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(
        '.ag-header-container'
      )
      header.style.left = `-${String(
        Number(header.style.transform.slice(11, -3)) + 9
      )}px`
    }
  },
  created() {
    this.getListDataDebounce()
    this.$root.$on('row:status', this.getListDataDebounce)
    this.headers.forEach((e) => {
      e.tooltip = (params) => params.value
    })
  }
}
</script>

<style lang="scss">
#page-user-list {
  position: relative;
  margin-bottom: 10px;

  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }

  .vs-con-loading__container {
    position: absolute;
    inset: 0;
  }
}

// .ag-header-cell-text {
//   margin-right: auto;
//   margin-left: auto;
// }
</style>
