<template>
  <div>
    <vx-card class="p-relative">
      <h6 class="text-primary">{{ $t("exam.exam_outline") }}</h6>
      <hr class="m-2" />
      <vs-row>
        <vs-row class="runungExamInfFont">
          <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="6">
            <p>Exam Date</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1">
            :
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="5">
            {{ exam.date.slice(0, 10) }}
          </vs-col>
        </vs-row>
        <vs-row class="runungExamInfFont">
          <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="6">
            <p>Exam Start time</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1">
            :
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="5">
            {{ exam.duration_fixed_time_start_time }}
          </vs-col>
        </vs-row>
        <vs-row class="runungExamInfFont">
          <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="6">
            <p>Exam End time</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1">
            :
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="5">
            {{ exam.duration_fixed_time_end_time }}
          </vs-col>
        </vs-row>
        <vs-row class="runungExamInfFont">
          <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="6">
            <p>Exam Subject</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1">
            :
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="5">
            {{ exam.subject.name }}
          </vs-col>
        </vs-row>
        <vs-row class="runungExamInfFont">
          <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="6">
            <p>Class Group</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1">
            :
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="5">
            {{ exam.class.name }}
          </vs-col>
        </vs-row>
        <vs-row class="runungExamInfFont">
          <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="6">
            <p>Exam Type</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1">
            :
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="5">
            {{ exam.type.type }}
          </vs-col>
        </vs-row>
        <vs-row class="runungExamInfFont">
          <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="6">
            <p>Exam Duration</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1">
            :
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="5">
            {{ exam.duration_type.type }}
          </vs-col>
        </vs-row>
        <vs-row class="runungExamInfFont">
          <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="6">
            <p>Identities</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1">
            :
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="5">
            Hidden
          </vs-col>
        </vs-row>
      </vs-row>
    </vx-card>
  </div>
</template>

<script>
export default {
    props: {
        exam: {
            type: Object,
            required: true
        }
    }
}
</script>
