import axios from 'axios'

const resource = '/planner'

const resource2 = '/teachers'

export default {
  uploadFiles (files) {
    return axios.post('/common/upload-files', files)
  },
  getCurrentAcademicYear () {
    return axios.get('/poi/academic-years?current=true')
  },
  /////////////////////////////////////
  // , deleteFile (file) {
  //   return  axios.post('/common/delete-file', file)
  // }
  deleteFile (file) {
    return axios.post('/teachers/assignments/attachment/delete', file)
  },
  archiveAssessmentTask (id, data) {
    return axios.patch(`/teachers/assignments/${id}/change-status`, data)
  },

  deleteAssignment (id) {
    return axios.delete(`/teachers/assignments/${id}`)
  },

  ///////////////////////////////////// New used api
  getTeacherAssignments (param) {
    return axios.post(`${resource2}/assignments/filter`, param)
  },
  getTeacherClassesForFilters () {
    return axios.get(`${resource2}/classes`)
  },
  getTeacherClasses (body) {
    return axios.get(`${resource2}/subject-classes`, {
      params: {
        ...body
      }
    })
  },
  getStudentsOfTeacherClass (data) {
    return axios.post(`${resource2}/teacher-class-subjects/students`, data)
  },
  getTeachersSubjects () {
    return axios.get(`${resource2}/teacher-subjects`)
  },

  getAssignmentStatusCount () {
    return axios.get(`${resource2}/assignment-status-count`)
  },

  saveAssignment (task) {
    return axios.post(`${resource2}/assignments`, task)
  },

  upadateAssignment (assignment) {
    return axios.post(`${resource2}/assignments/${assignment.id}`, assignment)
  },

  getSubmittedStudents (taskId) {
    return axios.get(`/teachers/assignments/${taskId}/task-students`)
  },

  getStudentTaskDetails (taskId) {
    return axios.get(`/teachers/assignment-tasks/${taskId}`)
  },

  assessStudentTask (taskId, data) {
    return axios.post(`/teachers/assignment-tasks/${taskId}/assess`, data)
  },
  getTeacherSingleAssignment (id) {
    return axios.get(`${resource2}/find-assignment-Teacher?id=${id}`)
  },
  getDailyAgenda (data) {
    return axios.post(`${resource2}/assignments-agenda`, data)
  }
  /////////////////////////////////////
}
