import virtualClasses from './pages/virtualClasses.vue'
import teacherList from '@/modules/teachers-gateway/sub-modules/school-principal/pages/list.vue'
import assignments from './pages/assignments/assignment'
import taecherAssignment from './pages/assignments/components/teacherAssignments'
import assessments from './pages/assessments/assessments'
import teacherAssessments from './pages/assessments/components/teacherAssessments'
export default [
  {
    path: '/virtualClasses',
    name: 'virtualClasses',
    component: virtualClasses,

    meta: {
      breadcrumb: [{}],
      pageTitle: 'basic.onlineClass',
      rule: 'SchoolPrincipal'
    }
  },
  {
    path: '/assignments',
    name: 'basic.assignments',
    component: assignments,

    meta: {
      breadcrumb: [{}],
      pageTitle: 'assignment.assignments',
      rule: 'SchoolPrincipal'
    }
  },
  {
    path: '/assessments',
    name: 'assessments.assessments',
    component: assessments,

    meta: {
      breadcrumb: [{}],
      pageTitle: 'basic.tasks',
      rule: 'SchoolPrincipal'
    }
  },
  {
    path: '/assignments/teacher-assignments/:id',
    name: 'principal_teacher_assignments',
    component: taecherAssignment,
    meta: {
      breadcrumb: [
        {
          title: 'basic.dashboard',
          url: 'landing/home'
        },
        {
          title: 'basic.assignments',
          url: '/assignments'
        },
        {
          title: 'basic.teacher_assignemts',
          url: null,
          active: true
        }
      ],
      pageTitle: 'basic.teacher_assignemts',
      rule: 'SchoolPrincipal'
    }
  },
  {
    path: '/assessments/teacher-assessments/:id',
    name: 'principal_teacher_assessments',
    component: teacherAssessments,
    meta: {
      breadcrumb: [
        {
          title: 'basic.dashboard',
          url: 'landing/home'
        },
        {
          title: 'assessments.assessments',
          url: '/assessments'
        },
        {
          title: 'assessments.teacher_assessments',
          url: null,
          active: true
        }
      ],
      pageTitle: 'assessments.teacher_assessments',
      rule: 'SchoolPrincipal'
    }
  },
  {
    path: '/teacher-list',
    name: 'principal-teacherList',
    component: teacherList,
    meta: {
      breadcrumb: [{}],
      pageTitle: 'teachersGateway.list_teachers',
      rule: 'SchoolPrincipal'
    }
  }
]
