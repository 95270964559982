<template>
  <div class="vx-row">
    <div class="vx-col mb-3 w-full mr-auto ml-auto text-center">
      <vs-chip
      class="status--dark w-full"
      >{{ $t('basic.feature_not_included') }}</vs-chip>
    </div>
    <div class="vx-col md:w w-full mb-base">
      <div v-for="(sub_arr, index) in thirdifyArray(line_charts)" :key="index" class="vx-row mb-6">
        <div v-for="chart in sub_arr" :key="chart.title" class="vx-col md:w-1/3 w-full">
          <LineChart
            :title="chart.title"
            :icon="chart.icon"
            :chartData="chart.data"
            :color="chart.color"
          ></LineChart>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col md:w-2/3 w-full">
          <ColumnChart
            title="Class Analytics"
            icon="icon-bar-chart-2"
            :chartData="column_chart"
            color="primary"
          ></ColumnChart>
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <RadialChart
            title="Attendance Analytics"
            icon="icon-bar-chart-2"
            :chartData="radial_chart"
            color="primary"
          ></RadialChart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from './components/LineChart/LineChart'
import ColumnChart from './components/ColumnChart/ColumnChart'
import RadialChart from './components/RadialChart/RadialChart'
export default {
  components: {
    LineChart,
    ColumnChart,
    RadialChart
  },
  data () {
    return {
      line_charts: '',
      column_chart: ''
    }
  },
  methods: {
    thirdifyArray (arr) {
      const return_array = []
      for (let i = 0; i < arr.length; i += 3) {
        return_array.push(arr.slice(i, i + 3))
      }
      return return_array
    }
  },
  created () {
    this.line_charts = [
      {
        title: 'Recently Usual Absence',
        icon: 'icon-users',
        color: 'primary',
        data: {
          series: [
            {
              name: 'Pupils',
              data: [31, 40, 28, 51, 42, 109, 100]
            }
          ],
          chartOptions: {
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth'
            },
            xaxis: {
              type: 'datetime',
              categories: [
                '2018-09-19T00:00:00',
                '2018-09-19T01:30:00',
                '2018-09-19T02:30:00',
                '2018-09-19T03:30:00',
                '2018-09-19T04:30:00',
                '2018-09-19T05:30:00',
                '2018-09-19T06:30:00'
              ]
            },
            tooltip: {
              x: {
                format: 'dd/MM/yy HH:mm'
              }
            }
          }
        }
      },
      {
        title: 'Improvement in Attendance',
        icon: 'icon-shopping-bag',
        color: 'warning',
        data: {
          series: [
            {
              name: 'Pupils',
              data: [31, 40, 28, 51, 42, 109, 100]
            }
          ],
          chartOptions: {
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth'
            },
            xaxis: {
              type: 'datetime',
              categories: [
                '2018-09-19T00:00:00',
                '2018-09-19T01:30:00',
                '2018-09-19T02:30:00',
                '2018-09-19T03:30:00',
                '2018-09-19T04:30:00',
                '2018-09-19T05:30:00',
                '2018-09-19T06:30:00'
              ]
            },
            tooltip: {
              x: {
                format: 'dd/MM/yy HH:mm'
              }
            }
          }
        }
      },
      {
        title: 'Missed First Bell',
        icon: 'icon-dollar-sign',
        color: 'success',
        data: {
          series: [
            {
              name: 'Pupils',
              data: [31, 40, 28, 51, 42, 109, 100]
            }
          ],
          chartOptions: {
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth'
            },
            xaxis: {
              type: 'datetime',
              categories: [
                '2018-09-19T00:00:00',
                '2018-09-19T01:30:00',
                '2018-09-19T02:30:00',
                '2018-09-19T03:30:00',
                '2018-09-19T04:30:00',
                '2018-09-19T05:30:00',
                '2018-09-19T06:30:00'
              ]
            },
            tooltip: {
              x: {
                format: 'dd/MM/yy HH:mm'
              }
            }
          }
        }
      },
      {
        title: 'Usual Absence',
        icon: 'icon-shopping-cart',
        color: 'danger',
        data: {
          series: [
            {
              name: 'Pupils',
              data: [31, 40, 28, 51, 42, 109, 100]
            }
          ],
          chartOptions: {
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth'
            },
            xaxis: {
              type: 'datetime',
              categories: [
                '2018-09-19T00:00:00',
                '2018-09-19T01:30:00',
                '2018-09-19T02:30:00',
                '2018-09-19T03:30:00',
                '2018-09-19T04:30:00',
                '2018-09-19T05:30:00',
                '2018-09-19T06:30:00'
              ]
            },
            tooltip: {
              x: {
                format: 'dd/MM/yy HH:mm'
              }
            }
          }
        }
      },
      {
        title: 'School Closed to Pupils',
        icon: 'icon-shopping-bag',
        color: 'warning',
        data: {
          series: [
            {
              name: 'Pupils',
              data: [31, 40, 28, 51, 42, 109, 100]
            }
          ],
          chartOptions: {
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth'
            },
            xaxis: {
              type: 'datetime',
              categories: [
                '2018-09-19T00:00:00',
                '2018-09-19T01:30:00',
                '2018-09-19T02:30:00',
                '2018-09-19T03:30:00',
                '2018-09-19T04:30:00',
                '2018-09-19T05:30:00',
                '2018-09-19T06:30:00'
              ]
            },
            tooltip: {
              x: {
                format: 'dd/MM/yy HH:mm'
              }
            }
          }
        }
      }
    ]
    this.column_chart = {
      series: [
        {
          name: 'Attendees',
          data: [76, 85, 101, 98, 87]
        },
        {
          name: 'Absnces',
          data: [44, 55, 57, 56, 61]
        }
      ],
      chartOptions: {
        colors: ['#7367f0', '#ea5455'],
        plotOptions: {
          bar: {
            horizontal: false,
            endingShape: 'rounded',
            columnWidth: '40%'
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },

        xaxis: {
          categories: ['Code', 'Code', 'Code', 'Code', 'Code']
        },
        yaxis: {
          title: {
            text: 'Number of Pupils'
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter (val) {
              return `${val} Pupil`
            }
          }
        }
      }
    }
    this.radial_chart = {
      series: [100],
      colors: ['#20E647'],
      chartOptions: {
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            track: {
              background: '#333',
              startAngle: -135,
              endAngle: 135
            },
            hollow: {
              margin: 0,
              size: '70%'
            },
            track: {
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                blur: 4,
                opacity: 0.15
              }
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                color: '#293450',
                fontSize: '50px',
                show: true
              }
            }
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            gradientFromColors: ['#7367f0'],
            gradientToColors: ['#28c76f'],
            stops: [0, 100]
          }
        },
        stroke: {
          lineCap: 'butt'
        }
      }
    }
  }
}
</script>
