export default {
  Config: 'System Configuration',
  Key: 'Key',
  Director: 'Director',
  Principal: 'Principal',
  grades: 'Grades',
  Name: 'Name',
  Supervisor: 'Supervisor',
  schoolBranch: 'Branch Name',
  SelectBranch: 'Select Branch',
  email: 'Email',
  status: 'Status',
  DivisionName: 'Division Name',
  noGradesInSection: 'There are no grades available for this section',
  cancel: 'Cancel',
  new_division_has_been_added_successfully:'New division has been added successfully',
  division_has_been_edit_successfully:'Division has been Edit successfully'
}
