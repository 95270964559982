import axios from 'axios'

export default {

  makeActionRequest (url) {
    return axios.get(url)
  },
  makeActionPOSTRequest (url, payload) {
    return axios.post(url, payload)
  },
  makeActionPUTRequest (url, payload) {
    return axios.put(url, payload)
  },
  makeActionDELETERequest (url) {
    return axios.delete(url)
  },

  listData (url) {
    return axios.get(url)
  },
  listDataFilter (url, payload) {
    return axios.post(url, payload)
  },
  resetAllPaswwords (url) {
    return axios.get(url)
  },
  importExcl (url, payload) {
    return axios.post(url, payload)
  }
}
