import axios from 'axios'
const resource = '/attendance-settings'

export default {
  getAttendance (id) {
    return axios.get(`${resource}/${id}`)
  },

  modifyAttendance (payload) {
    return axios.put(`${resource}/modify`, payload)
  },

  getTeachers (school_id) {
    return axios.get(`/teachers/attendance/${school_id}`)
  }
}
