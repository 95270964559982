<template>
  <div>
    <!-- Loader -->
    <div
      id="loadDialog"
      v-show="loadDialog"
      ref="loadDialog"
      class="vs-con-loading__container"
    ></div>
    <!-- Body -->
    <div class="content mb-10">

      <!-- students score  -->
      <div class="lg:w-2/3 m-auto w-full">
        <studentGradeTable
          :students="updateScoreStudentsData"
          :criteria="selectedResource.criteria"
          class="w-full"
        />
      </div>
    </div>
    <!-- Btns -->
    <div class="flex justify-between items-center">
      <vs-button
        class="mt-2"
        type="border"
        color="danger"
        @click="closeUpdateGrades"
      >{{ $t('basic.cancel') }}
      </vs-button>
      <vs-button
        class="mt-2 mx-2"
        @click="submitUpdateGrades"
        v-if="updateScoreStudentsData && updateScoreStudentsData.length"
      >{{ $t('form.submit') }}
      </vs-button>
    </div>
  </div>
</template>
<script>
// mixins
import {commonDataMixin} from '@/mixins.js'
import gradingMixins from '../grading.mixins'
import _ from 'lodash'

// Repos
import {
  RepositoryFactory
} from '@/Repositories/RepositoryFactory'

const GradebookRepo = RepositoryFactory.get('GradingModule', 'gradebook')

import studentGradeTable from './StudentGradeTable.vue'

export default {
  name: 'update-assessment-scores',
  props: {

    selectedResource: {
      type: Object,
      required: true
    },
    gradings: {
      type: Array,
      required: true
    },
    students: {
      type: Array,
      required: true
    }

  },
  // mixin
  components: {studentGradeTable},
  mixins: [commonDataMixin, gradingMixins],
  data () {
    return {
      /////////////////////
      // update grade
      /////////////////////
      validateScores: true,
      updateScoreStudentsData: []
    }
  },
  provide () {
    return {parentValidator: this.$validator}
  },
  computed: {},
  methods: {
    /////////////////////
    // API
    //////////////////////
    // Update grade
    async updateGradeScores () {
      const payload = this.updateScoreStudentsData.map(e => ({
        id: e.grade_id,
        score: this.isMYPSection ? e.score : [e.score]
      }))

      // --- Continue
      this.startLoadingDialog()
      await GradebookRepo.updateScores({grading_data: payload})
        .then(res => {
          this.successMsg(this.$t('form.saved_successfully'))
          this.$emit('reloadGrades')
          this.closeUpdateGrades()
        })
        .catch(this.errMsg)
        .finally(this.endLoadingDialog)
    },
    ///////////////////////////////
    // Handle students grades score
    //////////////////////////////
    // Handle Update Student Scores Data
    handleStudentsScore (resourceId) {
      if (!this.gradings.length) return
      const getGrade = (userId) => this.gradings.find(g => g.original_resource_id === resourceId && g.user_id === userId)
      this.updateScoreStudentsData = _.cloneDeep(this.students.map(s => {
        const grade = getGrade(s.user_id)
        if (grade) {
          return {
            id: s.code,
            userId: s.user_id,
            name: s.name,
            grade_id: grade.id,
            score: this.isMYPSection ? grade.original_criteria : grade.original_criteria ? grade.original_criteria[0] : grade.score || null

          }
        }
      }).filter(Boolean))

    },
    ///////////////////////////////
    // update grades , scores
    //////////////////////////////

    resetSelectedResource () {
      this.updateScoreStudentsData = []
    },
    closeUpdateGrades () {
      this.resetSelectedResource()
      this.$emit('closeUpdateGrades')
    },
    submitUpdateGrades () {
      this.$validator.validateAll().then(valid => {
        if (valid) this.updateGradeScores()
        else this.errMsg(this.$t('grading.please_enter_all_students_score'))

      })
    }
  },
  watch: {

    'errors.items' (v) {
      if (v.length > 0) this.validateScores = false
      else this.validateScores = true
    }
  },
  created () {
    if (this.gradings.length) this.handleStudentsScore(this.selectedResource.id)
  }
}
</script>

