import {mapGetters} from 'vuex'
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker'
import Service from '../../services/unit-planner'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import VxTooltip from '@/layouts/components/vx-tooltip/VxTooltip.vue'
import {Validator} from 'vee-validate'
import uploadButton from '@/modules/unit-planner/components/uploadButton/uploadButton.vue'
import moment from 'moment'

export default {
  name: 'createTaskModal',
  components: {
    'v-select': vSelect,
    Validator,
    Datepicker,
    flatPickr,
    uploadButton,
    VxTooltip
  },
  props: ['teacherUnitId'],
  data () {
    return {
      restService: Service,
      editmode: false,
      assessmentEditID: null,
      configTimePicker: {
        enableTime: true,
        enableSeconds: true,
        noCalendar: true,
        time_24hr: true,
        defaultDate: '08:00',
        dateFormat: 'H:i'
      },
      // datepicker
      configdatePicker: {
        minDate: !this.edit_id ? this.currentDate : null,
        maxDate: null,
        dateFormat: 'Y-m-d',
        disableMobile: false

      },
      configTimeDatePicker: {
        minDate: !this.edit_id ? this.currentDate : null,
        maxDate: null,
        enableTime: true,
        enableSeconds: true,
        time_24hr: true,
        dateFormat: 'Y-m-d H:i'
      },
      modalProps: {
        width: '600px',
        height: 'auto',
        minHeight: 500,
        scrollable: true
      },
      modalName: 'createTaskModal',
      loadingData: false,
      datePickerSettings: {
        format: 'dd/MM/yyyy',
        disabledDates: {
          to: null, // Disable all dates up to specific date
          from: null // Disable all dates after specific date
        },
        dateValue: null
      },
      ////task data
      datetimeLaunchCheck: false,
      classes: [],
      summative_formative: ['Summative', 'Formative'],
      labels: [' Conceptual', 'Factual'],
      types: [' Quiz', 'Homework', 'Essay', 'Paper', 'Test', 'Project'],
      students: [],
      grades: [],
      poiFocus: [],
      subjects: [],
      currentCriteria:[],
      selectedCriteria:null,
      datetime_launch: false,
      currentSection: null,
      taskItems: {
        title: '',
        type: '',
        label: '',
        teacher_unit_id: this.teacherUnitId,
        attachments: [],
        have_comments: true,
        have_attachments: true,
        is_disable_task_date: false,
        datetime_launch: '',
        is_formative: 'Summative',
        description: '',
        class_teacher_id: '',
        division_subject_id: '',
        student_ids: [],
        assessment_time: '08:00',
        assessment_date: '',
        status: '',
        criteria:[]
      },
      customEvents: [{eventName: 'openCreateTaskModal', callback: this.loadData}]
    }
  },
  created () {
    ////////////////////////////////////////////////////
    //**          Custom Event Listener           ** //
    //////////////////////////////////////////////////
    this.customEvents.forEach(function (customEvent) {
      // eslint-disable-next-line no-undef
      $sharedEvent.listen(customEvent.eventName, customEvent.callback)
    })
  },
  beforeDestroy () {
    this.$validator.reset()
    this.$validator.pause()
  },
  destroyed () {
    ////////////////////////////////////////////////////
    //**      Remove Custom Event Listener        ** //
    //////////////////////////////////////////////////
    this.customEvents.forEach(function (customEvent) {
      // eslint-disable-next-line no-undef
      $sharedEvent.off(customEvent.eventName, customEvent.callback)
    })
  },
  watch: {
    'taskItems.datetime_launch' (val) {
      this.datetime_launch = true
    }
  },
  methods: {
    showModal () {
      this.$modal.show(this.modalName)
    },
    hideModal () {
      this.$modal.hide(this.modalName)
    },
    loadData (data) {
      this.resetProps()

      // binding Data **//
      this.datePickerSettings.disabledDates = {
        from: new Date(this.currentAcademicYear.end_date), // Disable all dates after specific date
        to: new Date(this.currentAcademicYear.start_date) // Disable all dates up to specific date
      }

      this.datePickerSettings.dateValue = new Date(
        this.currentAcademicYear.start_date
      )

      this.getDropDownData(data)
        .then(() => {
          if (data && data.id) {
            this.reBindingData(data)
          }
          this.showModal()
        })
    },
    resetProps () {
      this.taskItems = {
        title: '',
        type: '',
        label: '',
        teacher_unit_id: this.teacherUnitId,
        attachments: [],
        have_comments: true,
        have_attachments: true,
        is_disable_task_date: false,
        datetime_launch: '',
        is_formative: 'Summative',
        description: '',
        class_teacher_id: '',
        division_subject_id: '',
        student_ids: [],
        assessment_time: '08:00',
        assessment_date: '',
        status: '',
        criteria:[]
      }
    },
    async getDropDownData (data) {
      const teacherClassId = (data && data.id) ? data.class_teacher.id : null
      await this.getTeacherClasses()
      await this.getStudentsOfTeacherClass(teacherClassId)
      await this.getDivisionSubjects(teacherClassId)
    },
    async launch () {
      const validData = await this.$validator.validateAll()
      if (validData) {
        //* Show Loader *//
        this.$vs.loading()
        if (this.editmode) {
          /* debugger */
          this.taskItems = {...this.taskItems, _method: 'patch'}
        }
        if (!this.datetime_launch) {
          //this.taskItems.datetime_launch = this.getNow()
          /*const tomorrow = new Date(
            new Date().setDate(new Date().getDate() + 1)
          );*/
          this.taskItems.datetime_launch = null /*this.formatDatetime(tomorrow).slice(0, -3);*/
        }
        const data =
          {
            ...this.taskItems,
            class_teacher_id: this.taskItems.class_teacher_id.id,
            division_subject_id: this.taskItems.division_subject_id.id,
            student_ids: this.taskItems.student_ids
              .filter(student => student.id !== '000000')
              .map(student => student.id),
            status: 'active',
            is_formative: this.taskItems.is_formative !== 'Summative'
          }

        data.criteria = (data.criteria) ? data.criteria.map(item => item.key) : []
        if (this.editmode) {
          await this.restService
            .upadateAssessmentTask(data, this.assessmentEditID)
            .then(res => {
              this.UpdateGrid(res.data)
              this.hideModal()
              this.$vs.notify({
                title: 'Update',
                text: 'Task Update Successfully',
                color: 'success',
                position: 'bottom-right'
              })
            })
            .catch(error => {
              console.log(error)
              this.$vs.notify({
                title: 'Error',
                text: error.data.message,
                color: 'danger',
                position: 'bottom-right'
              })
            })
        } else {
          await this.restService
            .createAssessmentTask(data)
            .then(res => {
              this.UpdateGrid(res.data)
              this.hideModal()
              this.$vs.notify({
                title: 'Launch',
                text: 'Task Launched Successfully',
                color: 'success',
                position: 'bottom-right'
              })
            })
            .catch(error => {
              console.log(error)
              this.$vs.notify({
                title: 'Error',
                text: error.data.message,
                color: 'danger',
                position: 'bottom-right'
              })
            })
          //* Hide Loader *//
        }
        this.editmode = false
        this.$vs.loading.close()
      }
    },
    async saveAsDraft () {
      const validData = await this.$validator.validateAll()
      if (validData) {
        //** Show Loader **//
        this.$vs.loading()
        if (this.editmode) {
          this.taskItems = {
            ...this.taskItems,
            _method: 'patch'
          }
        }
        if (!this.datetime_launch) {
          this.taskItems.datetime_launch = null
        }
        const data = {
          ...this.taskItems,
          class_teacher_id: this.taskItems.class_teacher_id.id,
          division_subject_id: this.taskItems.division_subject_id.id,
          student_ids: this.taskItems.student_ids
            .filter(student => student.id !== '000000')
            .map(student => student.id),
          status: 'draft',
          is_formative: this.taskItems.is_formative !== 'Summative'
        }

        data.criteria = (data.criteria) ? data.criteria.map(item => item.key) : []

        if (this.editmode) {
          await this.restService
            .upadateAssessmentTask(data, this.assessmentEditID)
            .then(res => {
              this.UpdateGrid(res.data)
              this.hideModal()
              this.$vs.notify({
                title: 'Update',
                text: 'Task Update Successfully',
                color: 'success',
                position: 'bottom-right'
              })
            })
            .catch(error => {
              this.$vs.notify({
                title: 'Error',
                text: error.data.message,
                color: 'danger',
                position: 'bottom-right'
              })
            })
        } else {
          await this.restService
            .createAssessmentTask(data)
            .then(res => {
              console.log(res)
              this.UpdateGrid(res.data)
              this.hideModal()
              this.$vs.notify({
                title: 'Draft',
                text: 'Task Draft Successfully',
                color: 'success',
                position: 'bottom-right'
              })
            })
            .catch(error => {
              console.log(error)
              this.$vs.notify({
                title: 'Error',
                text: error.data.message,
                color: 'danger',
                position: 'bottom-right'
              })
            })
        }
        this.editmode = false
        this.$vs.loading.close()
      }
    },
    async getTeacherClasses () {
      //** Show Loader **//
      this.$vs.loading()
      //** Get Grades Subjects **//
      await this.restService
        .getTeacherClasses()
        .then(res => {
          this.classes = res.data.map(item => {
            return {
              id: item.id,
              name: item.class.name,
              section: item.section
            }
          })
        })
        .catch(err => console.error(err))

      this.$vs.loading.close()
    },
    async getDivisionSubjects (classId) {
      classId = classId || this.taskItems.class_teacher_id.id
      if (classId) {
        //** Show Loader **//
        this.$vs.loading()
        //** Get Grades Subjects **//
        await this.restService
          .getDivisionSubjects(classId)
          .then(res => {
            this.subjects = res.data
            this.changeCriteria(res.data[0])
          })
          .catch(err => console.error(err))

        this.$vs.loading.close()
      }
    },
    async removeAttachment (attachment, index) {
      const file = {
          file_path: `${this.institutionAssetsLink}${attachment.attachment}`
        }, payload =
        {
          'attachments': [
            {
              'attachment': attachment.attachment,
              'attachment_url':file.file_path}
          ]
        }
      await this.restService
        .deleteAssessmentAttachments(payload)
        .then(res => {
          this.taskItems.attachments = this.taskItems.attachments.filter(
            a => a.attachment !== attachment.attachment
          )
        })
        .catch(error => {
          console.log(error)
        })
    },
    async getStudentsOfTeacherClass (classId) {
      classId = classId || this.taskItems.class_teacher_id.id
      if (classId) {
        //** Show Loader **//
        this.$vs.loading()
        await this.restService
          .getStudentsOfTeacherClass(classId)
          .then(res => {
            const data = [{id: '000000', name: 'All Students'}, ...res.data]
            this.taskItems.student_ids.splice(0, this.taskItems.student_ids.length)
            this.students.splice(0, this.students.length)
            this.students.push(...data)
          })
          .catch(err => console.error(err))
        this.$vs.loading.close()
      }
    },
    changeCriteria (data) {
      this.currentCriteria = data.criteria.map(item => {
        return {
          key: item.key,
          count: item.count,
          title: `${item.key.toUpperCase()} - ${item.title} (${item.count}) `
        }
      })
      if (this.taskItems.class_teacher_id.section && this.taskItems.class_teacher_id.section.name === 'PYP') {
        this.taskItems.criteria = this.currentCriteria
      }
    },

    loadRelatedTeacherData () {
      this.taskItems.division_subject_id = null
      console.log('Selected Teacher Class', this.taskItems.class_teacher_id)
      this.getDivisionSubjects(null)
      this.getStudentsOfTeacherClass(null)
    },
    reBindingData (data) {
      this.editmode = true
      this.assessmentEditID = data.id
      const currentCriteria = []
      data.criteria.forEach(item => {
        const obj = this.currentCriteria.find(data => data.key == item.key)
        currentCriteria.push(obj)
      })
      this.taskItems = {
        title: data.title,
        type: data.type,
        label: data.label,
        teacher_unit_id: this.teacherUnitId,
        attachments: data.attachments,
        have_comments: data.have_comments,
        have_attachments: data.have_attachments,
        is_disable_task_date: data.is_disable_task_date,
        datetime_launch: data.datetime_launch,
        is_formative: data.is_formative ? 'Formative' : 'Summative',
        description: data.description,
        class_teacher_id: this.classes.find(item => item.id === data.class_teacher.id),
        division_subject_id: data.division_subject,
        student_ids: this.students.filter(item => {
          if (data.student_ids.includes(item.id)) {
            return item
          }
        }),
        assessment_time: data.assessment_time,
        assessment_date: data.assessment_date,
        status: data.status,
        criteria: currentCriteria
      }
      if (this.taskItems.class_teacher_id.section && this.taskItems.class_teacher_id.section.name === 'PYP') {
        this.taskItems.criteria = this.currentCriteria
      }
    },
    selectStudents () {
      this.taskItems.student_ids.map(student => {
        if (student.id === '000000') {
          this.taskItems.student_ids = this.students.map(
            student_id => student_id
          )
        }
      })
    },
    uploadedFiles (data) {
      this.taskItems.attachments.push(...data)
    },
    UpdateGrid (data) {
      $sharedEvent.fire('updateAssessmentGridData', data)
    },
    formatDatetime (data) {
      //** This Format Like  2020/11/23 16:25:00 **//
      const date = new Date(data).toLocaleDateString('en-us')
      const iso = date
        .toISOString()
        .match(/(\d{4}\-\d{2}\-\d{2})T(\d{2}:\d{2}:\d{2})/)
      return `${iso[1]  } ${  iso[2]}`
    },
    cancel () {
      this.$validator.pause()
      this.resetProps()
      this.$nextTick(() => {
        this.$validator.errors.clear()
        this.$validator.fields.items.forEach(field => field.reset())
        this.$validator.fields.items.forEach(field => this.errors.remove(field)
        )
        this.$validator.resume()
        this.hideModal()
      })
      this.editmode = false
    }
  },
  computed: {
    ...mapGetters({
      currentAcademicYear: 'getCurrentAcademicYearPlanner'
    }),
    isLaunched () {
      return !!this.taskItems.datetime_launch
    }
  }
}
