<template>
  <ListPageWithCreateButton :showCreateBtn="false">
    <template slot="listDataContent">
      <!--          :headers_prop="headers"-->
      <div
        class="vx-card mb-2 flex justify-start p-4 flex-wrap lg:justify-around"
        v-if="allTransaction && allTransaction.data.length > 0">
        <p class="font-bold mb-2 lg:mb-0">
          {{ $t('wallet.creditInWallet') }}
          :
          <span class="text-primary">{{
            allTransaction.data[0].total_amount || 0
          }}</span>
          {{ $t('wallet.currency') }}
          <!--            <span class="font-light text-primary underline pointer">add fund</span>-->
        </p>

        <p class="font-bold mb-2 lg:mb-0">
          {{ $t('wallet.dailyPocketMoney') }}
          :
          <span class="text-primary">{{
            allTransaction.data[0].limit || 0
          }}</span>
          {{ $t('wallet.currency') }}
          <span
            class="font-light text-primary underline pointer"
            @click="changeLimit = true">
            {{ $t('wallet.change') }}</span
          >
        </p>
      </div>
      <wallet-table
        :loading="loading"
        :filter_type="filterTab"
        :list_items="allTransaction"
        :headers_prop="headers">
      </wallet-table>
      <vs-prompt
        @cancel="walletData.limit = 0"
        @accept="changeWalletLimit"
        :active.sync="changeLimit"
        title="Change Limit"
        type="Change">
        <div class="con-exemple-prompt">
          <div class="my-2 vx-col w-full mb-2">
            <label
              >{{ $t('wallet.setDailyLimit')
              }}<sup class="text-danger">*</sup></label
            >
            <vs-input
              type="number"
              v-model="walletData.limit"
              class="w-full"
              name="daily_limit"
              v-validate="'required|min_value:1|numeric'" />
            <span class="text-danger text-sm">{{
              errors.first('daily_limit')
            }}</span>
          </div>
        </div>
      </vs-prompt>
    </template>
  </ListPageWithCreateButton>
</template>

<script>
import WalletTable from '@/components/general/datatable/DataTable2.vue'
import {commonDataMixin} from '@/mixins'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const allWalletRepository = RepositoryFactory.get(
  'walletRepository',
  'walletRepository'
)
export default {
  name: 'wallet',
  components: {
    WalletTable
  },
  props: {
    student: {
      type: Object,
      required: true
    }
  },
  mixins: [commonDataMixin],
  data() {
    return {
      changeLimit: false,
      filterTab: {
        id: this.student.user_id
      },
      headers: [
        {
          minWidth: 300,
          headerName: this.$t('form.fullName'),
          field: 'name'
        },
        {
          minWidth: 200,
          headerName: this.$t('basic.by'),
          field: 'transable_type'
        },
        {
          minWidth: 200,
          headerName: this.$t('wallet.class'),
          field: 'class.name'
        },
        {
          minWidth: 200,
          headerName: this.$t('wallet.amount'),
          field: 'amount'
        },
        {
          minWidth: 200,
          headerName: this.$t('config.type'),
          field: 'action'
        },
        {
          minWidth: 200,
          headerName: this.$t('basic.actions'),
          filter: false,
          field: 'actions',

          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            actions_prop: [
              {
                dropDown: true,
                dropDown_items: [
                  {
                    title: this.$t('wallet.copyReferenceNumber'),
                    icon: 'CopyIcon',
                    color: 'danger',
                    actionEmitter: 'copyReferenceNumber',
                    actionEmitterParams: ['reference_number']
                  }
                ]
              }
            ]
          }
        }
      ],
      loading: false,
      walletData: {
        id: this.student.user_id,
        limit: ''
      },
      allTransaction: []
    }
  },
  methods: {
    getAllTransaction() {
      this.VSLoading()
      allWalletRepository.getAllTransaction(this.filterTab).then((res) => {
        this.allTransaction = res.data
        this.stopVSLoading()
      })
    },
    changeWalletLimit() {
      this.VSLoading()
      allWalletRepository
        .changeWalletLimit(this.walletData)
        .then(() => {
          this.successMsg(this.$t('wallet.limitChangedSuccessfully'))
          this.allTransaction.data[0].limit = this.walletData.limit
          this.walletData = {
            id: this.student.user_id,
            limit: ''
          }
        })
        .catch((err) => {
          this.errMsg(err)
        })
        .finally(() => {
          this.stopVSLoading()
        })
    },
    copyReferenceNumber(data) {
      const text = `${data.reference_number}`
      navigator.clipboard.writeText(text)
      this.successMsg('Copied sccuess')
    }
  },
  watch: {},
  computed: {},
  created() {
    this.$root.$on('copyReferenceNumber', (data) =>
      this.copyReferenceNumber(data)
    )

    this.getAllTransaction()
  },
  beforeDestroy() {
    this.$root.$off('copyReferenceNumber')
  }
}
</script>

<style lang="scss" scoped>
.active .vx-card {
  -webkit-box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
  box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
}
</style>
