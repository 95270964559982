import AcademicYearsList from './pages/academic-years/List'
import ListSubAcademicYear from './pages/academic-years/ListSubAcademicYear'
import AcademicYearsCreate from './pages/academic-years/Create'
import CreateSubAcademicYear from './pages/academic-years/CreateSubAcademicYear'
import AcademicYearsEdit from './pages/academic-years/Edit'
import AcademicYearsShow from './pages/academic-years/Show'
import EditSubAcademicYear from './pages/academic-years/EditSubAcademicYear'
import ShowSubAcademicYear from './pages/academic-years/ShowSubAcademicYear'
import SubjectsCreate from './pages/Subjects/Create'
import SubjectsList from './pages/Subjects/List'
import SubjectsEdit from './pages/Subjects/Edit'
import SubjectsShow from './pages/Subjects/Show'
import SyllabusList from './pages/syllabus/List'
import SyllabusCreate from './pages/syllabus/Create'
import SyllabusEdit from './pages/syllabus/Edit'
import SyllabusShow from './pages/syllabus/Show'
import SyllabusClone from './pages/syllabus/Clone'
import SyllabusAssignTeacher from './pages/syllabus/assignTeahcer'
import SyllabusCustomizeTeacher from './pages/syllabus/components/customize_teacher'
import SyllabusCustomizeStudent from './pages/syllabus/components/customize_student'
import ClassesList from './pages/Classes/List'
import ClassesCreate from './pages/Classes/Create'
import ClassesEdit from './pages/Classes/Edit'
import ClassesUpgrade from './pages/Classes/Upgrade'
import ClassesShow from './pages/Classes/Show'
import strandsSubjectsCreate from './pages/Subjects/addStrands'

export default [
  ///////////////////////  Academic Years Routes    /////////////////////

  // List Mster
  {
    path: 'academic-years',
    name: 'AcademicYearsList',
    component: AcademicYearsList,
    meta: {
      breadcrumb: [
        {title: 'home', url: '/home'},
        // { title: 'AcademicYears.academic', url: '/academic' },
        {
          title: 'AcademicYears.AcademicYears',
          url: '/academic/academic-years',
          active: true
        }
      ],
      pageTitle: 'AcademicYears.AcademicYears',
      rule: 'SNDSMD'
    }
  },
  // list Sub
  {
    path: 'academic-years/:id/sub-academic-years',
    name: 'subAcademicYearsList',
    component: ListSubAcademicYear,
    meta: {
      breadcrumb: [
        {title: 'home', url: '/home'},
        // { title: 'AcademicYears.academic', url: '/academic' },
        {
          title: 'AcademicYears.AcademicYears',
          url: '/academic/academic-years'
        },
        {
          title: 'AcademicYears.subAcademicYear',
          active: true
        }
      ],
      pageTitle: 'AcademicYears.subAcademicYear',
      rule: 'SNDSMD'
    }
  },
  //Creat Master
  {
    path: 'academic-years/create',
    name: 'create-academic-year',
    component: AcademicYearsCreate,
    meta: {
      breadcrumb: [
        {title: 'home', url: '/home'},
        // { title: 'AcademicYears.academic', url: '/academic' },
        {
          title: 'AcademicYears.AcademicYears',
          url: '/academic/academic-years'
        },
        {
          title: 'main.create',
          url: '/academic/academic-years/create',
          active: true
        }
      ],
      pageTitle: 'AcademicYears.AcademicYearsCreate',
      rule: 'SMD'
    }
  },
  //Creat Sub
  {
    path: 'academic-years/:id/sub-academic-years/create',
    name: 'create-sub-academic-year',
    component: CreateSubAcademicYear,
    meta: {
      breadcrumb: [
        {title: 'home', url: '/home'},
        // { title: 'AcademicYears.academic', url: '/academic' },
        {
          title: 'AcademicYears.AcademicYears',
          url: '/academic/academic-years'
        },
        {
          title: 'AcademicYears.subAcademicYear',
          url: '/academic/academic-years/:id/sub-academic-years/'
        },
        {
          title: 'main.create',
          active: true
        }
      ],
      pageTitle: 'AcademicYears.subAcademicYearsCreate',
      rule: 'SND'
    }
  },

  // Edit Master
  {
    path: 'academic-years/edit/:id',
    name: 'edit-academic-year',
    component: AcademicYearsEdit,
    meta: {
      breadcrumb: [
        {title: 'home', url: '/home'},
        //    { title: 'AcademicYears.academic', url: '/academic' },
        {
          title: 'AcademicYears.AcademicYears',
          url: '/academic/academic-years'
        },
        {
          title: 'main.Edit',
          url: '/academic/academic-years/edit/:id',
          active: true
        }
      ],
      pageTitle: 'AcademicYears.AcademicYearsEdit',
      rule: 'SMD'
    }
  },

  // Edit Sub
  {
    path: 'academic-years/:id/sub-academic-years/edit/:idSub',
    name: 'edit-sub-academic-year',
    component: EditSubAcademicYear,
    meta: {
      breadcrumb: [
        {title: 'home', url: '/home'},
        {
          title: 'AcademicYears.AcademicYears',
          url: '/academic/academic-years'
        },
        {
          title: 'AcademicYears.subAcademicYear',
          url: '/academic/academic-years/:id/sub-academic-years/'
        },
        {
          title: 'main.Edit',
          url: '/academic/academic-years/edit/:id',
          active: true
        }
      ],
      pageTitle: 'AcademicYears.subAcademicYearsEdit',
      rule: 'SND'
    }
  },
  // show Master
  {
    path: 'academic-years/show/:id',
    name: 'show-academic-year',
    component: AcademicYearsShow,
    meta: {
      breadcrumb: [
        {title: 'home', url: '/home'},
        //    { title: 'AcademicYears.academic', url: '/academic' },
        {
          title: 'AcademicYears.AcademicYears',
          url: '/academic/academic-years'
        },
        {
          title: 'main.show',
          url: '',
          active: true
        }
      ],
      pageTitle: 'AcademicYears.AcademicYearsShow',
      rule: 'SMD'
    }
  },
  // show Sub
  {
    path: 'academic-years/:id/sub-academic-years/show/:idSub',
    name: 'show-sub-academic-year',
    component: ShowSubAcademicYear,
    meta: {
      breadcrumb: [
        {title: 'home', url: '/home'},
        {
          title: 'AcademicYears.AcademicYears',
          url: '/academic/academic-years'
        },
        {
          title: 'AcademicYears.subAcademicYear',
          url: '/academic/academic-years/:id/sub-academic-years/'
        },
        {
          title: 'main.show',
          url: '',
          active: true
        }
      ],
      pageTitle: 'AcademicYears.subAcademicYearsShow',
      rule: 'SNDSMD'
    }
  },

  {
    path: 'Subjects/create',
    name: 'create-Subjects',
    component: SubjectsCreate,
    meta: {
      breadcrumb: [
        {title: 'home', url: '/home'},
        // { title: 'AcademicYears.academic', url: '/academic' },
        {title: 'subject.SubjectsList', url: '/academic/Subjects'},
        {
          title: 'main.create',
          url: '/academic/Subjects/create',
          active: true
        }
      ],
      pageTitle: 'subject.SubjectsCreate',
      rule: 'SND'
    }
  },

  {
    path: 'Subjects',
    name: 'SubjectsList',
    component: SubjectsList,
    meta: {
      breadcrumb: [
        {title: 'home', url: '/home'},
        // { title: 'AcademicYears.academic', url: '/academic' },
        {
          title: 'subject.SubjectsList',
          url: '/academic/Subjects',
          active: true
        }
      ],
      pageTitle: 'subject.SubjectsList',
      rule: 'SNDSMD'
    }
  },

  {
    path: 'Subjects/edit/:id',
    name: 'edit-Subjects',
    component: SubjectsEdit,
    meta: {
      breadcrumb: [
        {title: 'home', url: '/home'},
        //{ title: 'AcademicYears.academic', url: '/academic' },
        {title: 'subject.SubjectsList', url: '/academic/Subjects'},
        {
          title: 'main.Edit',
          url: '/academic/Subjects/edit/:id',
          active: true
        }
      ],
      pageTitle: 'subject.SubjectsEdit',
      rule: 'SND'
    }
  },
  {
    path: 'Subjects/show/:id',
    name: 'show-Subjects',
    component: SubjectsShow,
    meta: {
      breadcrumb: [
        {title: 'home', url: '/home'},
        // { title: 'AcademicYears.academic', url: '/academic' },
        {title: 'subject.Subjects', url: '/academic/Subjects'},
        {
          title: 'subject.SubjectsShow',
          url: 'Subjects/show/:id',
          active: true
        }
      ],
      pageTitle: 'subject.SubjectsShow',
      rule: 'SNDSMD'
    }
  },
  //////////////////////////    Class Routes   ////////////////////////////////

  {
    path: 'Classes/create',
    name: 'create-Classes',
    component: ClassesCreate,
    meta: {
      breadcrumb: [
        {title: 'home', url: '/home'},
        // { title: 'AcademicYears.academic', url: '/academic' },
        {title: 'classes.ClassesList', url: '/academic/Classes'},
        {
          title: 'main.create',
          url: '/academic/Classes/create',
          active: true
        }
      ],
      pageTitle: 'classes.ClassesCreate',
      rule: 'SNDSMD'
    }
  },

  {
    path: 'Classes',
    name: 'ClassesList',
    component: ClassesList,
    meta: {
      breadcrumb: [
        {title: 'home', url: '/home'},
        // { title: 'AcademicYears.academic', url: '/academic' },
        {title: 'classes.ClassesList', url: '/academic/Classes', active: true}
      ],
      pageTitle: 'classes.ClassesList',
      rule: 'SNDSMD'
    }
  },

  {
    path: 'Classes/edit/:id',
    name: 'edit-Classes',
    component: ClassesEdit,
    meta: {
      breadcrumb: [
        {title: 'home', url: '/home'},
        //{ title: 'AcademicYears.academic', url: '/academic' },
        {title: 'classes.ClassesList', url: '/academic/Classes'},
        {
          title: 'main.Edit',
          url: '',
          active: true
        }
      ],
      pageTitle: 'classes.ClassesEdit',
      rule: 'SND'
    }
  },
  {
    path: 'Classes/upgrade/:id',
    name: 'upgrade-Classes',
    component: ClassesUpgrade,
    meta: {
      breadcrumb: [
        {title: 'home', url: '/home'},
        //{ title: 'AcademicYears.academic', url: '/academic' },
        {title: 'classes.ClassesList', url: '/academic/Classes'},
        {
          title: 'main.upgrade',
          url: '',
          active: true
        }
      ],
      pageTitle: 'classes.ClassesUpgrade',
      rule: 'SND'
    }
  },
  {
    path: 'Classes/show/:id',
    name: 'show-Classes',
    component: ClassesShow,
    meta: {
      breadcrumb: [
        {title: 'home', url: '/home'},
        // { title: 'AcademicYears.academic', url: '/academic' },
        {title: 'classes.ClassesList', url: '/academic/Classes'},
        {
          title: 'main.Show',
          url: 'Subjects/show/:id',
          active: true
        }
      ],
      pageTitle: 'classes.ClassesShow',
      rule: 'SNDSMD'
    }
  },
  ///////////////////////  Syllabus Routes    /////////////////////

  {
    path: 'syllabus',
    name: 'SyllabusList',
    component: SyllabusList,
    meta: {
      breadcrumb: [
        {title: 'home', url: '/home'},
        {
          title: 'Syllabus.syllabusList',
          url: '/academic/syllabus',
          active: true
        }
      ],
      pageTitle: 'Syllabus.syllabusList',
      rule: 'SNDSMD'
    }
  },
  {
    path: 'syllabus/create',
    name: 'create-syllabus',
    component: SyllabusCreate,
    meta: {
      breadcrumb: [
        {title: 'home', url: '/home'},
        {
          title: 'Syllabus.syllabusList',
          url: '/academic/syllabus'
        },
        {
          title: 'main.create',
          url: '/academic/syllabus/create',
          active: true
        }
      ],
      pageTitle: 'Syllabus.syllabusCreate',
      rule: 'SND'
    }
  },
  {
    path: 'syllabus/edit/:id',
    name: 'edit-syllabus',
    component: SyllabusEdit,
    meta: {
      breadcrumb: [
        {title: 'home', url: '/home'},
        {
          title: 'Syllabus.syllabusList',
          url: '/academic/syllabus'
        },
        {
          title: 'main.Edit',
          url: '/academic/syllabus/edit/:id',
          active: true
        }
      ],
      pageTitle: 'Syllabus.syllabusEdit',
      rule: 'SND'
    }
  },
  {
    path: 'syllabus/show/:id',
    name: 'show-syllabus',
    component: SyllabusShow,
    meta: {
      breadcrumb: [
        {title: 'home', url: '/home'},
        {
          title: 'Syllabus.syllabusList',
          url: '/academic/syllabus'
        },
        {
          title: 'main.Show',
          url: '/academic/syllabus/show/:id',
          active: true
        }
      ],
      pageTitle: 'Syllabus.syllabusShow',
      rule: 'SNDSMD'
    }
  },
  {
    path: 'syllabus/:id/assign-teacher',
    name: 'syllabus_assign_teacher',
    component: SyllabusAssignTeacher,
    meta: {
      breadcrumb: [
        {title: 'home', url: '/home'},
        {
          title: 'Syllabus.syllabusList',
          url: '/academic/syllabus'
        },
        {
          title: 'Syllabus.syllabus_info',
          url: '/academic/syllabus/:id/assign-teacher',
          active: true
        }
      ],
      pageTitle: 'Syllabus.syllabus_info',
      rule: 'SND'
    }
  },
  {
    path: 'syllabus/:id/customize-teacher',
    name: 'syllabus_customize_teacher',
    component: SyllabusCustomizeTeacher,
    meta: {
      breadcrumb: [
        {title: 'home', url: '/home'},
        {
          title: 'Syllabus.syllabusList',
          url: '/academic/syllabus'
        },
        {
          title: 'Syllabus.syllabus_info',
          url: '/academic/syllabus/:id/assign-teacher'
        },
        {
          title: 'Syllabus.assign_teacher',
          url: '/academic/syllabus/:id/customize-teacher',
          active: true
        }
      ],
      pageTitle: 'Syllabus.assign_teacher',
      rule: 'SND'
    }
  },
  {
    path: 'syllabus/:id/customize-student',
    name: 'syllabus_customize_student',
    component: SyllabusCustomizeStudent,
    meta: {
      breadcrumb: [
        {title: 'home', url: '/home'},
        {
          title: 'Syllabus.syllabusList',
          url: '/academic/syllabus'
        },
        {
          title: 'Syllabus.syllabus_info',
          url: '/academic/syllabus/:id/assign-teacher'
        },
        {
          title: 'Syllabus.assign_student',
          url: '/academic/syllabus/:id/customize-student',
          active: true
        }
      ],
      pageTitle: 'Syllabus.assign_student',
      rule: 'SND'
    }
  },
  {
    path: 'syllabus/clone/:id',
    name: 'clone-syllabus',
    component: SyllabusClone,
    meta: {
      breadcrumb: [
        {title: 'home', url: '/home'},
        {
          title: 'Syllabus.syllabusList',
          url: '/academic/syllabus'
        },
        {
          title: 'main.clone',
          url: '/academic/syllabus/clone/:id',
          active: true
        }
      ],
      pageTitle: 'Syllabus.syllabusClone',
      rule: 'SND'
    }
  },
  {
    path: 'subjects/strands',
    name: 'add_subject_strands',
    component: strandsSubjectsCreate,
    meta: {
      breadcrumb: [
        {title: 'home', url: '/home'},
        {title: 'subject.SubjectsList', url: '/academic/Subjects'},
        {title: 'basic.create', url: null}
      ],
      pageTitle: 'AcademicYears.add_subject_strands',
      rule: 'SNDSMD'
    }
  }
]
