export default {
  data () {
    return {
      initialComments:{
        'learning-experiences-and-strategies-planning-for-self-supporting-learning':'<p>Lecture </p><p>Socratic seminar </p><p>Small group/pair work </p><p>PowerPoint lecture/notes </p><p>Individual presentations </p><p>Group presentations </p><p>Student lecture/leading </p><p>Interdisciplinary learning</p>',
        'differentiation':'<p>Affirm identity—build self-esteem </p><p>Value prior knowledge </p><p>Scaffold learning </p><p>Extend learning</p>',
        'approaches-to-learning':'<p>Thinking </p><p>Social </p><p>Communication </p><p>Self-management </p><p>Research&nbsp;</p>',
        'language-and-learning':'<p>Activating background knowledge </p><p>Scaffolding for new learning </p><p>Acquisition of new learning through practice </p><p>Demonstrating proficiency</p>',
        'tok-connections':'<p>Personal and shared knowledge </p><p>Ways of knowing </p><p>Areas of knowledge </p><p>The knowledge framework</p>',
        'cas-connections':'<p>Creativity </p><p>Activity </p><p>Service&nbsp;</p>'
      }
    
    }
  }

}