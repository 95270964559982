<template>
  <div class="announcement-form">
    <vx-card class="p-3 pt-6 pb-6 md:p-6">
      <form @submit.prevent="formSubmitted()">
        <!-- Snd div -->
        <div v-if="checkUserRole('SND')">
          <!-- Start Divisions -->
          <div class="lg:w-2/3 mb-5 w-full">
            <label class="inline-block mb-1">{{ $t("form.divisions") }}</label>
            <sup class="text-danger">*</sup>
            <v-select
              label="long_name"
              :reduce="(name) => name.id"
              :options="divisions"
              v-model="division_ids"
              class="w-full select-large"
              name="Divisions"
              v-validate="'required'"
              multiple
              @input="getSectionsByDivisionsIds"
            />
            <span class="text-danger text-sm w-full">{{
              errors.first("Divisions")
            }}</span>
          </div>
          <!-- End Divisions -->

          <!-- Start Sections -->
          <div class="lg:w-2/3 mb-5 w-full">
            <label class="inline-block mb-1">{{ $t("basic.sections") }}</label>
            <v-select
              label="long_name"
              :reduce="(name) => name.id"
              :options="sections"
              v-model="section_ids"
              class="w-full select-large"
              multiple
              :disabled="division_ids.length <= 0"
              @input="getGradesBySecstionsIds"
            />
          </div>
          <!-- End Sections -->

          <!-- Start Grades -->
          <div class="lg:w-2/3 mb-5 w-full">
            <label class="inline-block mb-1">{{ $t("form.grades") }}</label>
            <v-select
              label="name"
              :reduce="(name) => name.id"
              :options="grades"
              class="w-full select-large"
              v-model="grades_ids"
              multiple
              :disabled="division_ids.length <= 0 || section_ids.length <= 0"
              @input="getClasses"
            />
          </div>
          <!-- End Grades -->
        </div>
        <!-- Start Class -->
        <div class="lg:w-2/3 mb-5 w-full">
          <label class="inline-block mb-1">{{ $t("basic.classes") }}</label>
          <v-select
            label="name"
            :reduce="(name) => name.id"
            :options="classes"
            name="Classes"
            v-validate="checkUserRole('teacher') ? 'required' : false"
            class="w-full select-large"
            v-model="class_ids"
            multiple
            :disabled="
              checkUserRole('SND')
                ? division_ids.length <= 0 ||
                  section_ids.length <= 0 ||
                  grades_ids.length <= 0
                : false
            "
          />
          <span class="text-danger text-sm w-full">{{
            errors.first("Classes")
          }}</span>
        </div>
        <!-- End Class -->

        <!-- Start Type -->
        <div class="lg:w-2/3 mb-5 w-full">
          <label class="inline-block mb-1">{{ $t("form.type") }}</label>
          <sup class="text-danger">*</sup>
          <v-select
            label="name"
            :reduce="(name) => name.name"
            :options="types"
            v-model="type_ids"
            class="w-full select-large"
            name="Types"
            v-validate="'required'"
            multiple
          />
          <span class="text-danger text-sm w-full">{{
            errors.first("Types")
          }}</span>
        </div>
        <!-- End Type -->

        <!-- Start Title -->
        <div class="lg:w-2/3 mb-5 w-full">
          <label class="inline-block mb-1">{{
            $t("announcement.title")
          }}</label>
          <sup class="text-danger">*</sup>
          <vs-input
            :placeholder="`${$t('announcement.title_of_announcement')}`"
            v-model="title"
            name="title"
            class="w-full"
            v-validate="'required'"
          />
          <span class="text-danger text-sm w-full">{{
            errors.first("title")
          }}</span>
        </div>
        <!-- End Title -->

        <!-- Start Announcemnt -->
        <div class="lg:w-2/3 mb-5 w-full">
          <label class="inline-block mb-1">{{
            $t("announcement.announcements")
          }}</label>
          <sup class="text-danger">*</sup>
          <quill-editor
            v-model="announcemnt"
            v-validate="'required'"
            class="w-full mb-3"
            name="Announcemnt"
          ></quill-editor>
          <span class="text-danger text-sm w-full">{{
            errors.first("Announcemnt")
          }}</span>
        </div>
        <!-- End Announcemnt -->

        <!-- Start Due date -->
        <!-- <div class="lg:w-2/3 mb-5 w-full">
          <label class="w-1/4">{{ $t("announcement.launch_date") }}</label>
          <flat-pickr
            class="w-full"
            name="Date"
            v-model="lanchDate"
            v-validate="'required'"
          />
          <span v-if="errors.has('Date')" class="text-danger text-sm w-full">{{
            errors.first("Date")
          }}</span>
        </div> -->
        <!-- End Due date -->

        <!-- Start Due Time -->
        <!-- <div class="lg:w-2/3 mb-5 w-full">
          <label class="w-1/4">{{ $t("form.start_time") }}</label>
          <flat-pickr
            name="Time"
            v-model="lanchTime"
            :config="configTimePicker"
            v-validate="'required'"
            class="w-full"
          />
          <span v-if="errors.has('Time')" class="text-danger text-sm w-full">{{
            errors.first("Time")
          }}</span>
        </div> -->
        <!-- End Due Time -->

        <div class="flex flex-wrap justify-between items-center mt-10">
          <vs-button
            color="warning"
            type="border"
            class="inline-block"
            @click="back()"
            >{{ $t("form.cancel") }}</vs-button
          >
          <vs-button class="inline-block" @click="formSubmitted()">
            {{ $t("form.submit") }}
          </vs-button>
        </div>
      </form>
    </vx-card>
  </div>
</template>

<script>
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const announcementRepository = RepositoryFactory.get(
  'announcementRepository',
  'announcement'
)
const TeacherRepository = {
  BasicInfo: RepositoryFactory.get('TeachersModule', 'BasicInfo'),
  OnlineClass: RepositoryFactory.get('TeachersModule', 'OnlineClass')
}
// Editor
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import { quillEditor } from 'vue-quill-editor'

import { commonDataMixin } from '@/mixins.js'
export default {
  components: {
    quillEditor
  },
  mixins: [commonDataMixin],
  data () {
    return {
      divisions: [],
      division_ids: [],
      sections: [],
      section_ids: [],
      grades: [],
      grades_ids: [],
      classes: [],
      class_ids: [],
      announcemnt: '',
      configTimePicker: {
        enableTime: true,
        enableSeconds: false,
        noCalendar: true,
        time_24hr: false
      },
      lanchDate: null,
      lanchTime: null,
      title: '',
      type_ids: []
    }
  },
  computed: {
    types () {
      if (this.checkUserRole('SND')) {
        return [
          {
            id: 1,
            name: 'students'
          },
          {
            id: 2,
            name: 'teachers'
          },
          {
            id: 3,
            name: 'parents'
          }
        ]
      } else {
        return [
          {
            id: 1,
            name: 'students'
          }
        ]
      }
    }
  },
  methods: {
    // Get Classes from teacher

    back () {
      this.$router.back()
    },
    // get Sections
    getSectionsByDivisionsIds () {
      this.section_ids = ''
      this.grades_ids = ''
      this.class_ids = ''
      const payload = {
        divisions: this.division_ids
      }
      announcementRepository.getSectionsByDivisionsIds(payload).then((res) => {
        this.sections = res.data
      })
    },
    // get grades
    getGradesBySecstionsIds () {
      this.grades_ids = ''
      this.class_ids = ''
      const payload = {
        section_ids: this.section_ids
      }
      announcementRepository.getGradesBySecstionsIds(payload).then((res) => {
        this.grades = res.data
      })
    },
    //get Classes
    getClasses () {
      if (this.checkUserRole('teacher')) {
        TeacherRepository.BasicInfo.getClasses().then((res) => {
          this.classes = res.data
        })
      } else {
        this.class_ids = ''
        const payload = {
          grades: this.grades_ids
        }
        announcementRepository.getClasses(payload).then((res) => {
          this.classes = res.data
        })
      }
    },
    createAnnouncement () {
      const payload = {
        title: this.title,
        description: this.announcemnt,
        launch_date: this.lanchDate,
        launch_time: this.lanchTime,
        announceables: [],
        types: this.type_ids
      }

      if (this.class_ids.length > 0) {
        this.class_ids.forEach((element) => {
          const obj = { announceable_type: 'class', announceable_id: element }
          payload.announceables.push(obj)
        })
      } else if (this.grades_ids.length > 0) {
        this.grades_ids.forEach((element) => {
          const obj = { announceable_type: 'grade', announceable_id: element }
          payload.announceables.push(obj)
        })
      } else if (this.section_ids.length > 0) {
        this.section_ids.forEach((element) => {
          const obj = {
            announceable_type: 'section',
            announceable_id: element
          }
          payload.announceables.push(obj)
        })
      } else {
        this.division_ids.forEach((element) => {
          const obj = {
            announceable_type: 'division',
            announceable_id: element
          }
          payload.announceables.push(obj)
        })
      }

      announcementRepository.createAnnouncement(payload).then((res) => {
        this.$router.back()
        this.successMsg(res.status)
      })
    },
    formSubmitted () {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.createAnnouncement()
        } else {
          this.errMsg(
            this.$t(
              'announcement.announcement_has_not_been_saved_please_complete_your_data'
            )
          )
        }
      })
    }
  },
  mounted () {
    this.getClasses()
    this.getDivisionsWithoutBranchId() //from mixins
  }
}
</script>
<style lang="scss" scoped>
.announcement-form {
  .vs-con-textarea {
    margin-bottom: 0px;
  }
}
</style>
