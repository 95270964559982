<template>
  <BasicListPage :syncReduceButton="syncReduceButton">
    <template slot="leftSide">
      <div :class="{'mb-3': $slots.leftTopRow}">
        <slot name="leftTopRow"></slot>
      </div>
      <!-- Create button Row -->

      <div
        class="w-full flex flex-wrap md:flex-row flex-col-reverse justify-start items-stretch"
        id="listPageWithCreate">
        <div
          class="w-full flex-2"
          :class="{'md:w-5/6': showCreateBtn}"
          v-if="$slots.leftSideButtonContent">
          <slot name="leftSideButtonContent"></slot>
        </div>
        <div
          class="w-full flex-1 mb-4"
          :class="{
            'md:w-1/6': $slots.leftSideButtonContent
          }"
          v-if="showCreateBtn">
          <vs-button
            v-if="route"
            class="block h-full border-box-shadow"
            :class="
              $slots.leftSideButtonContent
                ? 'w-full px-2'
                : `w-full md:w-auto ${
                    $vs.rtl ? 'ml-0 mr-auto' : 'mr-0 ml-auto'
                  }`
            "
            id="CreateBtn"
            :to="route"
            >{{ createBtnName || this.$t('basic.create') }}
          </vs-button>
          <slot v-else name="rightSideButtonContent"></slot>
        </div>
      </div>

      <!-- List Data -->
      <div class="block w-full">
        <slot name="listDataContent"></slot>
      </div>
    </template>
    <!-- Right Sidebar -->
    <template slot="rightSide" v-if="$slots.rightSide">
      <slot name="rightSide"></slot>
    </template>
  </BasicListPage>
</template>
<script>
import BasicListPage from './Basic.vue'
export default {
  name: 'ListPageWithCreateButton',
  components: {BasicListPage},
  props: {
    showCreateBtn: {
      type: Boolean,
      default: true
    },
    syncReduceButton: {
      type: Boolean,
      default: true
    },
    createRoutName: {
      type: String
    },
    createRoute: {
      type: Object
    },
    createBtnName: {
      type: String
    }
  },
  computed: {
    route() {
      return this.createRoute?.name
        ? this.createRoute
        : this.createRoutName
        ? {name: this.createRoutName}
        : null
    }
  }
}
</script>
<style>
#CreateBtn {
  min-height: 55px;
}
</style>
<style scoped>
@media (min-width: 768px) {
  #listPageWithCreate .md\:w-1\/6 {
    width: 12%;
  }
  #listPageWithCreate .md\:w-5\/6 {
    width: 87%;
  }
}
</style>
