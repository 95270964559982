import poiContainer from './components/poi-container/poi-container.vue'

export default [
  {
    path: 'poi-container',
    name: 'poiContainer',
    component: poiContainer,
    props: true,
    meta: {
      rule: 'POISectionsCoodinator'
    }
  }
]
