<template>
  <div>
    <vs-card class="p-3 py-6 md:p-6">
      <form>
        <div class="vx-row w-full mt-6 justify-center">
          <div class="vx-col xl:w-1/2 w-full flex justify-between items-center">
            <h4 class="mb-5 text-center">{{ task.name }}</h4>
            <template
              v-if="
                (task.submit && task.submit.includes('accept')) ||
                (task['remind-time'] &&
                  task['remind-time'].toLowerCase().includes('approved'))
              "
            >
              <vs-chip
                :class="`status--${task.submit
                  .toLowerCase()
                  .replaceAll(' ', '_')}`"
              >
                {{
                  this.task.submit === "accepted" ||
                  (task["remind-time"] &&
                    task["remind-time"].toLowerCase().includes("approved"))
                    ? $t("cas.approved")
                    : $t("cas.not_approved")
                }}
              </vs-chip>
            </template>

            <template v-else>
              <div
                v-if="task.validation && task.validation != 'not_validated_yet'"
              >
                <vs-chip
                  transparent
                  :class="`status--${task.validation.toLowerCase()}`"
                >
                  {{ task.validation.replaceAll("_", " ") }}
                </vs-chip>
              </div>
            </template>
          </div>
        </div>
        <div class="vx-row justify-center w-full mt-6 items-center">
          <div class="vx-col xl:w-1/2 w-full">
            <vs-textarea
              disabled
              v-model="task.description"
              class="w-full mb-0"
              name="name"
            />
            <span class="text-danger text-sm">{{ errors.first("name") }}</span>
          </div>
        </div>

        <div class="vx-row justify-center w-full mt-6 items-center">
          <div class="vx-col xl:w-1/2 w-full flex flex-row flex-warp">
            <div class="flex-1 w-full md:mr-2">
              <flat-pickr
                placeholder="start Date"
                :disabled="is_student || task.validation != 'not_validated_yet'"
                :config="configDatepickerFrom"
                v-model="task.start_date"
                name="start_date"
                class="w-full"
              />
              <span class="text-danger text-sm">{{
                errors.first("start_date")
              }}</span>
            </div>
            <div class="flex-1 w-full">
              <flat-pickr
                placeholder="End Date"
                :disabled="is_student || task.validation != 'not_validated_yet'"
                :config="configDatepickerTo"
                v-model="task.end_date"
                name="end_date"
                class="w-full"
              />
              <span class="text-danger text-sm">{{
                errors.first("end_date")
              }}</span>
            </div>
          </div>
        </div>

        <div class="vx-row justify-center w-full mt-6 items-center">
          <div class="vx-col xl:w-1/2 w-full">
            <div class="flex justify-between w-full">
              <div class="w-full">
                <v-select
                  :options="Array.from({ length: 31 }, (v, k) => k + 1)"
                  v-model="task.time"
                  :disabled="
                    is_student || task.validation != 'not_validated_yet'
                  "
                  name="hours"
                />
                <span class="text-danger text-sm">{{
                  errors.first("hours")
                }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="vx-row justify-center w-full mt-6 items-center">
          <div class="vx-col xl:w-1/2 w-full">
            <v-select
              multiple
              :options="outcomes"
              label="name"
              :reduce="(cat) => cat.id"
              v-model="task.out_comes"
              name="outcomes"
              :disabled="is_student || task.validation != 'not_validated_yet'"
            />
            <span class="text-danger text-sm">{{
              errors.first("outcomes")
            }}</span>
          </div>
        </div>
        <div class="vx-row justify-center w-full mt-6 items-center">
          <div class="vx-col xl:w-1/2 w-full">
            <v-select
              multiple
              :options="criterias"
              label="name"
              :reduce="(cat) => cat.id"
              v-validate="'required'"
              v-model="task.criteria_ids"
              name="criteria"
              :disabled="is_student || task.validation != 'not_validated_yet'"
            />
            <span class="text-danger text-sm">{{
              errors.first("outcomes")
            }}</span>
          </div>
        </div>

        <div
          class="vx-row justify-center w-full mt-6 items-center"
          v-if="
            is_student &&
            (task.submit == null || task.submit == 'not_submited_student_yet')
          "
        >
          <div class="vx-col xl:w-1/2 w-full">
            <div class="flex justify-center">
              <div>
                <vs-button
                  v-if="
                    task.submit == null ||
                    task.submit == 'not_submited_student_yet'
                  "
                  color="gray"
                  class="mt-5"
                  @click="$refs.uploadDocument.click()"
                  >{{ $t("form.upload_document") }}
                </vs-button>
                <input
                  type="file"
                  ref="uploadDocument"
                  class="hidden"
                  multiple
                  accept="capture=camera"
                  @change="localFiles = $event.target.files"
                />
              </div>
              <!-- <div v-if="task.documents.length">
                <vs-button color="gray" class="mt-5 ml-8" @click="$refs.download.click()">{{ $t("form.view_document") }}
                </vs-button>
                <a :href="task.documents[0]" download target="_blank" class="hidden" ref="download" />
              </div> -->
            </div>
          </div>
        </div>

        <div
          class="vx-row justify-center w-full mt-6 items-center"
          v-if="documentsArr.length"
        >
          <div class="vx-col xl:w-1/2 w-full">
            <div class="justify-center">
              <vs-list>
                <vs-list-item
                  v-for="(document, index) in documentsArr"
                  :key="index"
                  icon="check"
                  :title="document.name"
                >
                  <div class="flex" v-if="document.id">
                    <vs-button
                      color="success"
                      class="mr-2"
                      icon="cloud_download"
                      @click="downloadDocument(document)"
                    ></vs-button>
                    <vs-button
                      v-if="is_student"
                      color="danger"
                      icon="delete"
                      @click="deleteDocument(document)"
                    ></vs-button>
                  </div>
                </vs-list-item>
              </vs-list>
              <a
                :href="documentUrl"
                target="_blank"
                class="hidden"
                ref="download"
              />
            </div>
          </div>
        </div>
        <div class="vx-row justify-center w-full mt-6 items-center">
          <div class="vx-col w-full">
            <vs-textarea
              v-model="task.answer"
              :disabled="is_coordinator"
              v-if="task.validation == 'validated'"
              placeholder="write task details..."
              class="w-full mb-0"
              name="name"
            />
            <span class="text-danger text-sm">{{ errors.first("name") }}</span>
          </div>
        </div>

        <div
          class="
            flex flex-wrap
            md:justify-between
            justify-center
            items-center
            md:flex-row
            flex-col
            mt-10
          "
          v-if="
            is_student &&
            (task.submit == null || task.submit == 'not_submited_student_yet')
          "
        >
          <vs-button color="warning" type="border" class="mt-5" @click="discard"
            >{{ $t("basic.discard") }}
          </vs-button>
          <vs-button
            color="gray"
            class="mt-5"
            @click="saveForm('not_submited_student_yet')"
          >
            {{ $t("form.save_and_close") }}</vs-button
          >
          <vs-button
            class="mt-5"
            color="success"
            @click="saveForm('submit_student')"
            >{{ $t("form.finish_and_submit") }}
          </vs-button>
        </div>

        <!-- Handle Validation senrio in coordinator view -->
        <div
          class="
            flex flex-wrap
            justify-center
            items-center
            md:flex-row
            flex-col
            mt-10
          "
          v-if="is_coordinator && task.validation == 'not_validated_yet'"
        >
          <vs-button
            color="danger"
            type="border"
            class="mt-5 mr-8"
            @click="validateTask('not_valiated')"
          >
            {{ $t("cas.do_not_validate") }}
          </vs-button>
          <vs-button
            color="success"
            class="mt-5"
            type="border"
            @click="validateTask('validated')"
          >
            {{ $t("cas.validate") }}</vs-button
          >
        </div>

        <!-- Handle Submition senrio in coordinator view -->
        <div
          class="
            flex flex-wrap
            justify-center
            items-center
            md:flex-row
            flex-col
            mt-10
          "
          v-if="
            is_coordinator &&
            task.validation != 'not_validated_yet' &&
            task.submit == 'submit_student'
          "
        >
          <vs-button
            color="gray"
            class="mt-5 mr-8"
            @click="acceptTask('not_accepted')"
            >{{ $t("cas.do_not_accept_it") }}
          </vs-button>
          <vs-button
            color="success"
            class="mt-5"
            @click="acceptTask('accepted')"
          >
            {{ $t("cas.accept_and_add_it_to_student_record") }}</vs-button
          >
        </div>
      </form>
    </vs-card>

    <Reflections
      v-if="task.validation === 'validated'"
      :task_prop="task"
    ></Reflections>
  </div>
</template>
<script>
// APIs
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const CasRepository = RepositoryFactory.get('CASRepository', 'TaskRepository')

// Datepicker
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

//mixins
import mixins from '../../mixins'

//Components
import Reflections from './Reflections'

export default {
  name: 'showTask',
  props: ['params'],
  mixins: [mixins],
  components: {
    flatPickr,
    Reflections
  },
  data () {
    return {
      criterias: [],
      outcomes: [],
      hours: [],
      localFiles: [],
      hoursShow: true,
      task: {
        name: '',
        description: '',
        start_date: '',
        end_date: '',
        time: null,
        criteria_id: 1,
        out_comes: [],
        status_id: 1 // 1 => active , 2 => Draft , 3 => Archived
      },
      /////////////////////////
      // DatePicker
      /////////////////////////
      configDatepickerFrom: {
        dateFormat: 'Y-m-d'
      },
      configDatepickerTo: {
        dateFormat: 'Y-m-d'
      },
      documentUrl: ''
    }
  },
  watch: {
    'task.start_date' (v) {
      this.configDatepickerTo.minDate = v
    }
  },
  computed: {
    getTaskId () {
      if (this.is_student) {
        return this.params.id
      } else {
        return this.$route.params.id
      }
    },
    documentsArr () {
      if (this.task.documents) {
        return [...this.task.documents, ...this.localFiles]
      }
    }
  },

  methods: {
    /////////////////////
    //APIs
    /////////////////////
    getCriteria () {
      CasRepository.getCriteria()
        .then((res) => {
          const data = res.data
          this.criterias = data.map((el) => {
            return {
              name: el[this.current_locale],
              id: el.id
            }
          })
        })
        .catch()
    },
    getOutcomes () {
      CasRepository.getOutcomes()
        .then((res) => {
          const data = res.data
          this.outcomes = data.map((el) => {
            return {
              name: el[this.current_locale],
              id: el.id
            }
          })
        })
        .catch()
    },
    showTask () {
      this.loading()
      CasRepository.showTask(this.getTaskId)
        .then((response) => {
          this.task = response.data
          this.task.out_comes = response.data.outcome_ids
        })
        .catch()
        .finally(() => this.stopLoading())
    },
    downloadDocument ({ name, document }) {
      this.documentUrl = document
      this.$nextTick(() => {
        this.$refs.download.click()
      })
    },
    deleteDocument ({ id }) {
      this.loading()
      const form = {
        ids: [id]
      }
      CasRepository.deleteDocument(form)
        .then((response) => {
          const index = this.task.documents
            .map((element) => element.id)
            .indexOf(id)
          this.task.documents.splice(index, 1)
          this.successMsg(response.data)
        })
        .catch((err) => {
          this.errMsg(err)
        })
        .finally(() => this.stopLoading())
    },
    ///////////////////////
    ///Coordinator Methods
    //////////////////////
    validateTask (validateStatus) {
      this.loading()
      this.task.validation = validateStatus
      CasRepository.validateTask(this.task)
        .then(() => {
          this.goTo('tasksList')
          this.successMsg()
        })
        .catch((err) => {
          this.errMsg(err)
        })
        .finally(() => this.stopLoading())
    },
    acceptTask (acceptStatus) {
      this.loading()
      const obj = {
        id: this.task.id,
        acceptedData: acceptStatus
      }
      CasRepository.acceptTask(obj)
        .then(() => {
          this.goTo('tasksList')
          this.successMsg()
        })
        .catch((err) => {
          this.errMsg(err)
        })
        .finally(() => this.stopLoading())
    },
    ///////////////////////
    ///Stduent Methods
    //////////////////////7

    saveForm (saveType) {
      this.loading()
      // console.log(this.task.document)
      const formData = new FormData()
      // formData.append('document', this.task.document)
      this.localFiles.forEach((element, index) => {
        formData.append(`documents[${index}]`, element)
      })
      formData.append('task_id', this.task.id)
      formData.append('answer', this.task.answer)
      formData.append('submit', saveType)
      CasRepository.saveForm(formData)
        .then(() => {
          this.goTo('tasksList')
          this.successMsg()
        })
        .catch((err) => {
          this.errMsg(err)
        })
        .finally(() => this.stopLoading())
    },
    discard () {
      this.goTo('tasksList')
    }
  },
  created () {
    this.getCriteria()
    this.getOutcomes()
    this.showTask()
  }
}
</script>
