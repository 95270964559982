import axios from 'axios'

const resource = '/utilities'
export default {

  getWalletList (search = '') {
    return axios.get(`${resource}/all-students`)
  },
  getStudentAmount (userId) {
    return axios.get(`${resource}/student/amount/${userId}`)
  },
  addFund (payload) {
    return axios.post(`${resource}/wallet-action`, payload)
  },
  makeWalletRefund (payload) {
    return axios.post(`${resource}/wallet-refund`, payload)
  },
  search (search) {
    return axios.get(`${resource}/all-students?search=${search}`)
  },
  showFundHistory (userId) {
    return axios.get(`${resource}/get-funds/${userId}`)
  },
  changeWalletLimit (paylod) {
    return axios.post(`${resource}/parent/change-limit`, paylod)
  },
  getAllTransaction (payload) {
    console.log('payload', payload)
    return axios.post(`${resource}/parent/transaction`,payload)
  }
}
