<template>
  <vs-tabs alignment="center" class="mt-10">
    <vs-tab
      :label="sibling.student.first_name"
      v-for="sibling in siblings"
      :key="sibling.id"
      @click="getChildernsDayData(sibling.user_id)"
    >
      <vs-collapse
        class="mr-auto ml-auto xl:w-1/2 w-full overflow-hidden"
        type="margin"
      >
        <vs-collapse-item
          v-for="(day, i) in last7days_data"
          class="bg-white"
          :style="[{ transitionDelay: i * 0.1 + 's' }]"
          :key="i + 0"
          :open="!closeCollapse ? (i != 0 ? false : true) : true"
        >
          <div slot="header" class="text-gray">
            <h5>
              <feather-icon
                icon="CircleIcon"
                :svgClasses="['h-2 w-2', 'text-primary']"
                class="mr-4"
              />
              {{ dayText(Object.keys(day)[0]) }}
            </h5>
          </div>
          <div>
            <!-- -----------------------------------
                           attendance
              ------------------------------------->
            <p>
              <feather-icon
                icon="CircleIcon"
                :svgClasses="['fill-current h-2 w-2', 'text-primary']"
                class="mr-4"
              />
              {{
                last7days_data[i][Object.keys(day)[0]].attendance.includes(
                  "not taken"
                )
                  ? $t("basic.attendance_not_taken")
                  : last7days_data[i][Object.keys(day)[0]].attendance
              }}
            </p>

            <!-- -----------------------------------
                           assignments
              ------------------------------------->
            <template v-if="last7days_data[i][Object.keys(day)[0]].assignments">
              <p
                v-for="(as, as_index) in last7days_data[i][Object.keys(day)[0]]
                  .assignments"
                :key="'assignments' + as_index"
              >
                <feather-icon
                  icon="CircleIcon"
                  :svgClasses="['fill-current h-2 w-2', 'text-warning']"
                  class="mr-4"
                />
                <template v-if="as.counter">
                  <strong>{{ sibling.name }}</strong> {{ $t("basic.has") }}
                  {{ as.counter }} {{ $t("basic.assignments") }}
                  {{ $t("basic.in") }}
                  <span
                    v-for="(subject, su_index) in as.subjects.split(',')"
                    :key="su_index"
                    >{{
                      `${subject} ${
                        su_index === as.subjects.split(",").length - 1
                          ? ""
                          : su_index === as.subjects.split(",").length - 2
                          ? $t("basic.and")
                          : ","
                      }`
                    }}
                    <!-- {{
                    su_index !== as.subjects.length - 2 ? $t("basic.and") : ""
                  }} -->
                  </span></template
                >
                <span v-else>{{ $t("basic.no_assignments") }}</span>
              </p>
            </template>
            <!-- -----------------------------------
                           assessments
              ------------------------------------->
            <template v-if="last7days_data[i][Object.keys(day)[0]].assessments">
              <p
                v-for="(as, as_index) in last7days_data[i][Object.keys(day)[0]]
                  .assessments"
                :key="'assessments' + as_index"
              >
                <feather-icon
                  icon="CircleIcon"
                  :svgClasses="['fill-current h-2 w-2', 'text-primary']"
                  class="mr-4"
                />
                <template v-if="as.counter">
                  <strong>{{ sibling.name }}</strong> {{ $t("basic.has") }}
                  {{ as.counter }} {{ $t("basic.task") }}
                  {{ $t("basic.in") }}
                  <span
                    v-for="(subject, su_index) in as.subjects.split(',')"
                    :key="su_index"
                    >{{
                      `${subject} ${
                        su_index === as.subjects.split(",").length - 1
                          ? ""
                          : su_index === as.subjects.split(",").length - 2
                          ? $t("basic.and")
                          : ","
                      }`
                    }}
                    <!-- {{
                    su_index !== as.subjects.length - 2 ? $t("basic.and") : ""
                  }} -->
                  </span></template
                >

                <span v-else>{{ $t("basic.no_assessments") }}</span>
              </p>
            </template>
            <!-- -----------------------------------
                           exam
              ------------------------------------->
            <template v-if="last7days_data[i][Object.keys(day)[0]].exams">
              <p
                v-for="(as, as_index) in last7days_data[i][Object.keys(day)[0]]
                  .exams"
                :key="'exams' + as_index"
              >
                <feather-icon
                  icon="CircleIcon"
                  :svgClasses="['fill-current h-2 w-2', 'text-danger']"
                  class="mr-4"
                />
                <template v-if="as.counter">
                  <strong>{{ sibling.name }}</strong> {{ $t("basic.has") }}
                  {{ as.counter }} {{ $t("basic.exam") }}
                  {{ $t("basic.in") }}
                  <span
                    v-for="(subject, su_index) in as.subjects.split(',')"
                    :key="su_index"
                    >{{
                      `${subject} ${
                        su_index === as.subjects.split(",").length - 1
                          ? ""
                          : su_index === as.subjects.split(",").length - 2
                          ? $t("basic.and")
                          : ","
                      }`
                    }}
                    <!-- {{
                    su_index !== as.subjects.length - 2 ? $t("basic.and") : ""
                  }} -->
                  </span>
                </template>
                <span v-else>{{ $t("basic.no_exams") }}</span>
              </p>
            </template>
            <!-- -----------------------------------
                           Notes
              ------------------------------------->
            <p
              v-for="(note, note_index) in last7days_data[i][
                Object.keys(day)[0]
              ].notes"
              :key="note_index"
              v-if="note.note"
            >
              <feather-icon
                icon="CircleIcon"
                :svgClasses="['fill-current h-2 w-2', 'text-danger']"
                class="mr-4"
              />
              <strong>{{ sibling.name }}</strong> {{ note.note }} by
              {{ note.author }}
            </p>
          </div>
        </vs-collapse-item>
      </vs-collapse>
      <template>
        <!-- Loader -->
        <!-- <div
          v-show="!last7days_data.length"
          ref="myDayLoader"
          id="myDayLoader"
          class="vs-con-loading__container"
        ></div> -->
        <!-- <p class="text-center p-20">{{ $t("basic.loading_data") }} ...</p> -->
      </template>
    </vs-tab>
  </vs-tabs>
</template>

<script>
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'

const ParentRepository = RepositoryFactory.get('ParentRepository', 'parent')

export default {
  name: 'my-day',
  props: {
    student: {
      type: Object,
      required: true
    },
    siblings: {
      type: Array
    }

  },
  data () {
    return {
      user_id: this.student.user_id,
      last7days_data: [],
      matchedDate: '',
      closeCollapse: true

    }
  },
  created () {
    this.getChildernsDayData(this.student.user_id)

  },
  computed: {
    last7days () {
      const days = []
      for (let i = 0; i < 8; i++) {
        const d = new Date()
        d.setDate(d.getDate() - i)
        days.push(`${d.getFullYear()}-${(d.getMonth() + 1) < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1}-${d.getDate()}`)

      }
      return days
    },
    matchDate_data () {

      return this.last7days_data.find(el => Object.keys(el)[0] === this.matchedDate)
    }

  },
  methods: {
    ///////////////////////////////////////
    // Data
    //////////////////////////////////////
    formateDate (val) {
      return new Date(val)
    },
    matchDate (date) {
      const matched = this.last7days_data.find(el => Object.keys(el)[0] === date)
      this.matchDate_data = matched
    },
    dayText (date) {
      const text = new Date(date).toDateString()
      return text.split(' ').slice(0, 3).join(' ')
    },
    //////////////////////////////////////
    // APIs
    //////////////////////////////////////
    // Get day data
    async getChildernsDayData (id) {
      await (this.closeCollapse = true, this.last7days_data = [])

      this.VSLoading()

      ParentRepository.getChildernsDayData(id)
        .then(res => {
          this.closeCollapse = false
          this.last7days_data = res
          const attendanceData = []
          attendanceData.map(data => {
            data['i18n'] = data.title.toLowerCase().replaceAll(' ', '_')
            return data
          })
        })
        .finally(() => this.stopVSLoading())
    }
  }
}
</script>
<style lang="scss">
#myDayLoader {
  position: absolute;
  inset: 0;
}
</style>
