<template>
  <div>
    <vs-card class="p-3 py-6 md:p-6">
      <form
        class="flex justify-between flex-col"
        @submit.prevent="submitForm"
        @keyup.enter="submitForm"
      >
        <div class="mb-4 mt-auto w-full vx-row items-center mx-auto">
          <!--Full Name -->
          <div class="my-2 vx-col w-full">
            <!-- Student Code  -->
            <label>{{ $t("form.name") }}<sup class="text-danger">*</sup></label>
            <vs-input
              type="text"
              v-model="canteen.name"
              class="w-full mb-2"
              name="Name"
              v-validate="'required'"
            />
            <span class="text-danger text-sm">{{ errors.first("Name") }}</span>
          </div>
          <!-- End Full Name -->

          <!--Start description-->
          <div class="my-2 vx-col w-full">
            <label>{{ $t("form.description") }}</label>
            <vs-textarea class="w-full mb-2" v-model="canteen.description" />
          </div>
          <!--End description-->

          <!-- start email -->
          <div
            class="my-2 vx-col w-full"
          >
            <div class="vx-col w-full">
              <label>
                {{ $t("teachersGateway.email") }}
                <sup class="text-danger">*</sup>
              </label>
            </div>

            <div class="vx-col w-full mb-3"  v-for="(phone, indexOfEmail) in canteen.emails"
                 :key="'email' + indexOfEmail">
              <div class="vx-row d-flex justify-between items-center">
                <vs-input
                  v-model="canteen.emails[indexOfEmail]"
                  :name="`email ${indexOfEmail + 1}`"
                  v-validate="'required|email'"
                  class="vx-col flex-grow"
                  :placeholder="`${$t('teachersGateway.email')}`"
                  type="email"
                  @input="check('canteen.emails', indexOfEmail)"
                />
                <vs-button
                  @click="removeEmail(indexOfEmail)"
                  v-show="
                    indexOfEmail || (!indexOfEmail && canteen.emails.length > 1)
                  "
                  color="danger"
                  type="border"
                  class="vx-col lg:w-1/7 mr-3"
                  icon-pack="feather"
                  icon="icon-minus"
                ></vs-button>
                <vs-button
                  @click="addEmail(indexOfEmail)"
                  v-show="indexOfEmail == canteen.emails.length - 1"
                  color="primary"
                  type="border"
                  icon-pack="feather"
                  icon="icon-plus"
                  class="vx-col lg:w-1/7"
                ></vs-button>
              </div>

              <div class="w-full">
                <span class="text-danger text-sm text-center">{{
                  errors.first(`email ${indexOfEmail + 1}`)
                }}</span>
              </div>
            </div>
          </div>
          <!-- end email -->

          <!-- Start mobile -->
          <div
            class="my-2 vx-col w-full"
          >
            <div class="vx-col w-full">
              <label>
                {{ $t("teachersGateway.mobile") }}

              </label>
            </div>

            <div class="vx-col w-full mb-3" v-for="(i, indexOfPhone) in mobile"
                 :key="'mobile' + indexOfPhone">
              <div class="vx-row d-flex justify-between items-center pl-5">
                <div>
                  <v-select
                    v-model="mobile[indexOfPhone].prefix_number"
                    :options = "mobile_keys"
                    :closeOnSelect="true"
                    label="prefix"
                    :reduce="(name) => name"
                    class="w-full h-full"
                  />
                </div>
                <vs-input
                  v-model="mobile[indexOfPhone].number"
                  :name="`Personal Mobile ${indexOfPhone + 1}`"
                  v-validate="'numeric|length:8'"
                  class="px-2 vx-col flex-grow"
                  :placeholder="`${$t('teachersGateway.mobile')}`"
                  type="text"
                />
                <vs-button
                  @click="removeMobile(indexOfPhone)"
                  v-show="indexOfPhone || (!indexOfPhone && mobile.length > 1)"
                  color="danger"
                  type="border"
                  class="vx-col lg:w-1/7 mr-3"
                  icon-pack="feather"
                  icon="icon-minus"
                ></vs-button>
                <vs-button
                  @click="addMobile(indexOfPhone)"
                  v-show="indexOfPhone == mobile.length - 1"
                  color="primary"
                  type="border"
                  icon-pack="feather"
                  icon="icon-plus"
                  class="vx-col lg:w-1/7"
                ></vs-button>
              </div>

              <div class="w-full">
                <span class="text-danger text-sm text-center">{{
                  errors.first(`Personal Mobile ${indexOfPhone + 1}`)
                }}</span>
              </div>
            </div>
          </div>
          <!-- End mobile  -->

          <!-- Start phone  -->
          <div
            class="my-2 vx-col  w-full"
          >
            <div class="vx-col w-full">
              <label>
                {{ $t("teachersGateway.phone") }}

              </label>
            </div>

            <div class="vx-col w-full mb-3"  v-for="(i, indexOfPhone) in phone"
                 :key="'phone' + indexOfPhone">
              <div class="vx-row d-flex justify-between items-center pl-5">
                <div>
                <v-select
                  v-model="phone[indexOfPhone].prefix_number"
                  :options = "tel_keys"
                  :closeOnSelect="true"
                  label="prefix"
                  :reduce="(name) => name"
                  class="w-full"
                />
              </div>
                <vs-input
                  v-model="phone[indexOfPhone].number"
                  :name="`Landline ${indexOfPhone + 1}`"
                  v-validate="'numeric|length:8'"
                  class="px-2 vx-col flex-grow"
                  :placeholder="`${$t('form.landline')}`"
                  type="text"
                />
                <vs-button
                  @click="removePhone(indexOfPhone)"
                  v-show="indexOfPhone || (!indexOfPhone && phone.length > 1)"
                  color="danger"
                  type="border"
                  class="vx-col lg:w-1/7 mr-3"
                  icon-pack="feather"
                  icon="icon-minus"
                ></vs-button>
                <vs-button
                  @click="addPhone(indexOfPhone)"
                  v-show="indexOfPhone == phone.length - 1"
                  color="primary"
                  type="border"
                  icon-pack="feather"
                  icon="icon-plus"
                  class="vx-col lg:w-1/7"
                ></vs-button>
              </div>

              <div class="w-full">
                <span class="text-danger text-sm text-center">{{
                  errors.first(`Landline ${indexOfPhone + 1}`)
                }}</span>
              </div>
            </div>
          </div>
          <!-- End phone  -->

          <!--Start Type -->
          <div class="my-2 vx-col w-full">
            <label
              >{{ $t("form.type") }}
              <sup class="text-danger">*</sup>
            </label>
            <v-select
              :closeOnSelect="true"
              v-model="canteen.type"
              :options="types"
              label="name"
              :reduce="(name) => name.name"
              v-validate="'required'"
              name="type"
              class="w-full mb-2"
            />

            <span class="text-danger text-sm">{{ errors.first("type") }}</span>
          </div>
          <!--End Type -->

          <!--Start Upload File -->
          <div class="my-2 vx-col w-full">
            <label class="w-full">{{ $t("exam.upload_attachment") }}</label>
            <vs-input
              type="file"
              accept="image/*,.doc,.docx,application/msword,.pdf,application/pdf;capture=camera"
              multiple
              @change="uploadDoc($event)"
              v-model="documents"
              class="w-full mb-2"
              ref="upload_file"
            />
          </div>
          <div class="my-2 vx-col w-full" v-if="canteen.documents.length">
            <div class="flex flex-row flex-wrap">
              <div
                v-for="(file, idx) in canteen.documents"
                :key="file.attachment_url"
              >
                <feather-icon
                  @click="deleteDocument(idx, file)"
                  class="mr-2 remove-student-attachment"
                  icon="XIcon"
                  svgClasses="h-5 w-5"
                />

                <file-previewer
                  :url="file.document"
                  :urlType="file.type"
                  class="ml-1 my-4 mr-4"
                ></file-previewer>
              </div>
            </div>
          </div>
          <!--End Upload File -->

          <!--Start Canteen Access -->
          <!--          <vs-divider class="text-capitalize vx-col w-full">
            {{ $t("form.Canteen_access") }}
          </vs-divider>
          <div class="my-2 vx-col lg:w-1/2 w-full">
            <label>{{ $t("basic.username") }}<sup class="text-danger">*</sup></label>
            <vs-input
              type="text"
              v-model="canteen.name"
              class="w-full mb-2"
              name="fullName"
              v-validate="'required'"
            />
            <span class="text-danger text-sm">{{
                errors.first("fullName")
              }}</span>
          </div>
          <div class="my-2 vx-col lg:w-1/2 w-full">
            <label
            >{{ $t("form.password") }}
              <sup class="text-danger" v-if="!isEdit">*</sup>
            </label>

            <vx-input-group class="w-full mb-2">
              <vs-input
                v-model="password"
                class="w-full"
                name="password"
                v-validate="{ required: !isEdit, min: 7 }"
              />
              &lt;!&ndash; Add Another &ndash;&gt;
              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button
                    type="border"
                    class="text-sm"
                    @click="generatePassword"
                  >
                    {{ $t("form.generate_password") }}
                  </vs-button>
                </div>
              </template>
            </vx-input-group>

            <span class="text-danger text-sm">{{
                errors.first("password") | text_formatter
              }}</span>
          </div>-->
          <!--End Canteen Accesss-->

          <div class="my-2 vx-col lg:w-1/2 w-full">
            <vs-checkbox
              v-model="canteen.is_manager_can_create_user"
              name="canteen.ifCreateCanteenUser"
              class="mb-3"
              >{{ $t("canteen.ifCreateCanteenUser") }}
            </vs-checkbox>

            <span class="text-danger text-sm">{{
              errors.first("canteen.ifCreateCanteenUser") | text_formatter
            }}</span>
          </div>
        </div>
        <!-- Actions -->
        <div class="flex flex-wrap justify-between items-center mt-auto mb-0">
          <vs-button class="mt-5 md:w-auto md:mr-0 ml-auto" @click="submitForm">
            {{ $t("form.submit") }}
          </vs-button>
        </div>
      </form>
    </vs-card>
  </div>
</template>
<script>
// Components
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import vSelect from 'vue-select'
import fileUploader from '@/modules/unit-planner/components/uploadButton/uploadButton.vue'

/*import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const addStudentRepository = RepositoryFactory.get(
  'studentAffairsRepository',
  'addStudent'
)*/

// import getData from './form/common_data_mixin'

import { commonDataMixin, checkValidationMixin } from '@/mixins'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const canteenRepository = RepositoryFactory.get(
  'canteenRepository',
  'canteenRepository'
)
export default {
  name: 'canteen-info',
  props: {
    studentInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isShow: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    ifEdit: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  mixins: [commonDataMixin, checkValidationMixin],
  // mixins:[getData()],
  components: { flatPickr, vSelect, fileUploader },

  data () {
    return {
      canteen: {
        /*        canteenAccess: {
          userName: '',
          password: ''
        },*/
        name: '',
        description: '',
        emails: [''],
        phones: [],
        type: '',
        documents: [],
        photo: '',
        is_manager_can_create_user: false
      },
      canteenId: this.$route.params.id || '',
      mobile: [
        {
          prefix_number: '010',
          type: 'mobile',
          number: ''
        }
      ],
      phone: [
        {
          prefix_number: '02',
          type: 'telephone',
          number: ''
        }
      ],
      mobilePrefix: ['010', '012', '011', '015'],
      phonePrefix: ['02'],
      documents: null,
      types: [
        {
          id: 1,
          name: 'school'
        },
        {
          id: 2,
          name: 'non-school'
        }
      ],
      password: null
    }
  },
  watch: {},
  computed: {},
  methods: {
    check (array, index) {
      const splitted = array.split('.')
      const arr = this[splitted[0]][splitted[1]]
      const unique = new Set(arr)
      if (unique.size !== arr.length) {
        this[splitted[0]][splitted[1]][index] = ''
        this.errMsg(this.$t('teachersGateway.you_cant_repeat_same_value'))
      }
    },
    removePhone (indexOfmobile) {
      this.phone.splice(indexOfmobile, 1)
    },
    addPhone () {
      const obj = {
        prefix_number: '02',
        type: 'telephone',
        number: ''
      }
      if (this.phone[this.phone.length - 1] !== '') {
        this.phone.push(obj)
      }
    },
    addMobile () {
      const obj = {
        prefix_number: '010',
        type: 'mobile',
        number: ''
      }
      if (this.mobile[this.mobile.length - 1] !== '') {
        this.mobile.push(obj)
      }
    },
    removeMobile (indexOfmobile) {
      this.mobile.splice(indexOfmobile, 1)
    },
    addEmail () {
      if (this.canteen.emails[this.canteen.emails.length - 1] !== '') {
        this.canteen.emails.push('')
      }
    },
    removeEmail (indexOfmobile) {
      this.canteen.emails.splice(indexOfmobile, 1)
    },
    clickOnFile () {
      this.$refs.upload_file.click()
    },

    //  Upload Docs
    uploadDoc (e) {
      const files = e.target.files
      files.forEach((file) => {
        this.checkTextImage2MBFileValid(file).then(() => {
          const data = new FormData()
          data.append('attachments[0]', file)
          this.commonUploadFiles(data)
            .then((res) => {
              const doc = res[0]
              doc.name = doc.attachment
              doc.document = doc.attachment_url
              delete doc.attachment
              delete doc.attachment_url
              this.canteen.documents.push(doc)
              this.canteen.photo = doc.name
              this.successMsg(
                this.$t('form.your_document_uploaded_successfully')
              )
            })
            .then(() => (this.renderExamFile = true))
        })
      })
    },
    generatePassword () {
      this.password = this.generateRandomPassword()
    },
    async deleteDocument (index, file) {
      const { document } = file
      const data = {
        file_path: document
      }
      const isDeletedFromS3 = await this.deleteDocumentFromS3(data)
      if (isDeletedFromS3) {
        this.deleteMsg(this.$t('form.Delete_Document_Successfully'))
        this.canteen.documents.splice(index, 1)
        this.canteen.photo = ''
      } else {
        this.errMsg(this.$t('form.failed_to_delete_documment'))
      }
    },
    fillData () {
      if (this.ifEdit) {
        this.VSLoading()
        canteenRepository.showCanteenDetails(this.canteenId).then((res) => {
          this.canteen = {
            name: res.data.name,
            description: res.data.description,
            emails: res.data.emails.map((data) => data.email),
            type: res.data.type,
            photo: res.data.photo,
            mobile: [
              {
                prefix_number: '010',
                type: 'mobile',
                number: ''
              }
            ],
            phone: [
              {
                prefix_number: '02',
                type: 'telephone',
                number: ''
              }
            ],
            documents: [{document: res.data.photo, type: res.data.photo.split('.').pop()}],
            is_manager_can_create_user: res.data.is_manager_can_create_user === 1 ? res.data.is_manager_can_create_user = true : res.data.is_manager_can_create_user = false
          }
          this.phone = [...res.data.telephones.map((v) => ({ ...v, type: 'telephone' }))]
          this.mobile = [...res.data.mobiles.map((v) => ({ ...v, type: 'mobile' }))]
          this.stopVSLoading()
        })
      }
    },
    /*---------------------
     submit
    -----------------------*/
    submitForm () {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.canteen.phones = [...this.phone, ...this.mobile]
          this.canteen.is_manager_can_create_user
            ? (this.canteen.is_manager_can_create_user = 1)
            : (this.canteen.is_manager_can_create_user = 0)
          this.$emit('formSubmited', this.canteen)
        } else {
          this.errMsg()
        }
      })
    }
  },
  created () {
    this.fillData()
    this.getTelKeys()
    this.getMobileKeys()
  }
}
</script>
<style lang="scss">
.remove-student-attachment {
  top: 32px;
  left: 90px;
  z-index: 20;
  cursor: pointer;
  svg {
    stroke: red;
    stroke-width: 2px;
  }
}
</style>
