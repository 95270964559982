export default {
  POI:'POI',
  poiDetails: 'POI Details',
  programme_Of_inquiry: 'Programme Of Inquiry',
  central_idea: 'Central Idea',
  key_concepts: 'Key Concepts',
  related_concepts: 'Related Concepts',
  ATL_skills: ' ATL Skills',
  learner_profile: ' Learner Profile',
  lines_Of_inquiry: 'Lines Of Inquiry',
  year_long: 'Year Long',
  poi_generated_report: 'POI Generated Report',
  year_group: 'Year Group',
  removed_successful: 'Removed Successful',
  year_group_deletion_warning: 'Year Group Deletion Warning',
  all_data_will_be_removed_are_you_sure: 'All data will be removed are you sure',
  clone_warning: 'Clone Warning',
  all_data_on_target_academic_year_will_be_replaced:'All data on target academic year will be replaced',
  clone_error: 'Clone Error',
  clone_academic_year: 'Clone Academic Year',
  saved_successfully: 'Saved Successfully',
  clone_academic_year_poi: 'Clone Academic Year POI',
  created_successfully: 'Created Successfully',
  updated_successfully: 'Updated Successfully',
  create_new_yeargroup_poi: 'Create New YearGroup POI',
  select_academic_year:'Select Academic Year',
  select_year_group: 'Select Year Group',
  all_years_group_selected: 'All years group selected',
  unit_modification: 'Unit Modification',
  invalid_data_please_correct_it: 'invalid data please correct it',
  you_cant_select_more_than_unit_as_year_long:'you can\'t select more than unit as year long',
  date_range_taken_before_on_another_unit:'date range Taken before on another unit',
  end_date_must_be_greater_than_start_date:'end date must be greater than start date',
  this_filed_is_required: 'this filed is required',
  set_section_title:'Set Section Title',
  title_is_required: ' Title Is Required',
  add_extra_section: 'Add Extra Section',
  start_date: 'Start Date',
  end_date: 'End Date',
  disable_unit: 'Disable Unit',
  set_as_year_long: 'Set As Year Long',
  update_unit:'Update Unit',
  create_unit: 'create Unit',
  POI_focus_is_required:'POI Focus Is Required'

}
