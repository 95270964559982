import axios from 'axios'

class PoiRestResource {
  resource = '/poi'

  constructor() {}
  /////////////////////////////////////////////////////////////////////////////////
  //** DropDowns Data **///////////////////////////////////////////////////////////
  async getAcademicYears() {
    return await axios.get(`${this.resource}/academic-years`)
  }

  async getYearsGroup() {
    return await axios.get(`${this.resource}/grades`)
  }

  async getKeyConcepts() {
    return await axios.get(`${this.resource}/approaches/key_concepts`)
  }

  async getAtlSkills() {
    return await axios.get(`${this.resource}/approaches/atl_skills`)
  }

  async getLearnerProfile() {
    return await axios.get(`${this.resource}/approaches/learner_profile`)
  }

  async getRelatedConcepts() {
    return await axios.get(`${this.resource}/approaches/related_concepts`)
  }

  async getRelatedConceptsData(id) {
    return await axios.get(`${this.resource}/approaches/related_concepts/${id}`)
  }

  async getAcdemicYearsGrades(academicId) {
    return await axios.get(
      `${this.resource}/academic-years/${academicId}/grades`
    )
  }
  //**********************************************************************************//

  //** Other getters **//
  async getDefaultUnits() {
    return await axios.get(`${this.resource}/units`)
  }

  //** Get Academic Year POI By Academic Id **//
  async getAcademicYearData(data) {
    return await axios.post(`${this.resource}/year-groups`, data)
  }

  ///////////////////////////////////////////////////////////////////////////////////////
  //** Update Or Create Or Delete **//////////////////////////////////////////////////////////////
  async createYearGroup(data) {
    return await axios.post(`${this.resource}/year-groups/create`, data)
  }

  async updateYearGroup(groupId, data) {
    return await axios.patch(
      `${this.resource}/year-groups/${groupId}/edit`,
      data
    )
  }

  async deleteYearGroup(groupId) {
    return await axios.delete(`${this.resource}/year-groups/${groupId}`)
  }
  //** ُEdit Unit Data **//
  async updateYearGroupUnit(id, data) {
    return await axios.patch(`${this.resource}/unit-academics/${id}/edit`, data)
  }

  //** ُClone Academic Year **//
  async cloneAcademicYear(data) {
    return await axios.post(`${this.resource}/academic-years/clone`, data)
  }

  async getGradeUnits(grade_id) {
    return await axios.get(`planner/grades/${grade_id}/units`)
  }

  //*********************************************************************************************//
}

//** Singleton Service **//
const poiSources = new PoiRestResource()

export default poiSources
