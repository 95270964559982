import axios from 'axios'

const resource = '/student'
export default {

  getFilteredStudents (page, filters) {
    return axios.post(`${resource}/filters${page || ''}`,
      filters
    )
  },
  getAllClasses () {
    return axios.get('/class')
  },
  getAllFilterClasses () {
    return axios.get('/filter/classes')
  },
  getAllSections () {
    return axios.get('/section')
  },
  addStudentNote (payload) {
    return axios.post(`/note${resource}`, payload)
  },
  getAllAcademic () {
    return axios.get('/academic')
  },
  getAllStatus () {
    return axios.get('/general-status')
  },
  getStudentTypes () {
    return axios.get('/student-type')
  },
  getCurrentAcadmicYear () {
    return axios.get('/academic/currentActive')
  },
  getStudentsStatusCounts () {
    return axios.get(`${resource}/student-status-count`)
  },
  changeUserStatus (userID, newStatus) {
    return axios.get(`${userID}/role/status/${newStatus}`)
  },
  search (data) { // search by username , email or Id
    return axios.get(`${resource}/search?search=${data}`)
  }
}
