<template>
  <div>
    <div class="flex items-center justify-end mb-3">
      <div>
        <div class="flex">
          <vs-switch v-model="announcement_for_teacher" :disabled="buttonDisabled"/>
          <label>{{ $t("announcement.announcement_for_teacher") }}</label>
        </div>
      </div>
      <vs-button class="mr-0 ml-6" v-if="!checkUserRole('SMD')" :to="{ name: 'announcementCreate' }">{{
        $t("basic.create")
      }}</vs-button>
    </div>

    <announcementList :showRoute="'show-announcement'"></announcementList>
  </div>
</template>

<script>
import announcementList from '../../../components/announcementList'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const announcementRepository = RepositoryFactory.get(
  'announcementRepository',
  'announcement'
)
export default {
  components: {
    announcementList
  },
  data () {
    return {
      announcement_for_teacher: 0,
      buttonDisabled: false,
    }
  },
  computed:{
    // announcement_for_teacher : {
    //   get () {
    //     return this.institutionApiRes?.announcement_for_teacher || 0
    //   },
    //   set (v) {
    //     this.announcement_for_teacherAPI(v)
    //   }
    // }
  },

  watch :{
    announcement_for_teacher (value) {
      this.buttonDisabled = true;
      announcementRepository.announcementForTeacher({announcement_for_teacher: value}).then((res) => {
        this.buttonDisabled = false;
      })
    }
  },
  created () {
    this.$store.dispatch('main/getInstitutionData').then(res => {
      this.announcement_for_teacher = res.announcement_for_teacher
    })
  }
}
</script>
