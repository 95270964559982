import Create from './pages/Tasks/Create.vue'
import Show from './pages/Tasks/Show.vue'
import List from './pages/Tasks/List.vue'

export default [
  {
    path: 'createTask',
    name: 'createTask',
    component: Create,
    meta: {
      breadcrumb: [
        {
          title: 'home',
          url: '/home'
        },
        {
          title: 'cas.casTasks',
          url: 'cas/tasks'
        },
        {
          title: 'basic.create',
          url: 'cas/create',
          active: true
        }
      ],
      pageTitle: 'cas.createTask',
      rule: 'Student'
    }
  }, {
    path: 'showTask/:id',
    name: 'showTask',
    component: Show,
    meta: {
      breadcrumb: [
        {
          title: 'home',
          url: '/home'
        },
        {
          title: 'cas.casTasks',
          url: '/cas/tasks'
        },
        {
          title: 'cas.showTask',
          url: '/cas/show',
          active: true
        }
      ],
      pageTitle: 'basic.show',
      rule: 'CasCoordinator'
    }
  },
  {
    path: 'tasks',
    name: 'tasksList',
    component: List,
    meta: {
      breadcrumb: [
        {
          title: 'home',
          url: '/home'
        },
        {
          title: 'cas.casTasks',
          url: '/cas/tasks',
          active: true
        }


      ],
      pageTitle: 'cas.casTasks',
      rule: 'CasCoordinator'
    }
  }
]
