export default {
  Admission: 'Admission',
  ActiveRounds: 'Active Rounds',
  Applications: 'applications',
  OnAssessment: 'On Assessment',
  FutureCandidates: 'Future Candidates',
  RoundManager: 'Round Manager',
  ScoreManager: 'Score Manager',
  NewRound: 'New Round',
  editRound: 'Edit Round',
  registration: 'Registration'
}
