import plannerContainer from './components/planner-container/planner-container.vue'
import unitResources from './components/planner-resources/planner-resources.vue'
export default [
  {
    path: '',
    name: 'plannerContainer',
    component: plannerContainer,
    props: true,
    meta: {
      rule: 'Teacher'
    }
  },
  {
    path: 'unit-planner',
    name: 'plannerContainer',
    component: plannerContainer,
    props: true,
    meta: {
      rule: 'Teacher'
    }
  },
  {
    path: 'unit-resources',
    name: 'unitResources',
    component: unitResources,
    props: true,
    meta: {
      breadcrumb: [
        { title: 'Unit Planner', url: '/teacher/planner', active: true },
        { title: 'Unit Resources', url: '/planner/unit-resources', active: true }
      ],
      pageTitle: 'Unit Resources',
      rule: 'Teacher'
    }
  }
]
