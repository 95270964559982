<template >
  <div>
    <template>
      <div>
        <vx-card class="py-6 md:p-6">
          <vs-divider :class="gridClasses" v-if="loadingchilderns">
            {{
              $t("completeProfileData.loading_children_information")
                | text_formatter
            }}
          </vs-divider>

          <!-- select childern -->
          <div :class="gridClasses">
            <template v-if="childern.length > 1">
              <label>
                {{ $t("completeProfileData.select_child") }}
                <!-- <sup class="text-danger">*</sup> -->
              </label>
              <v-select
                v-model="custodyData.student_id"
                name="child"
                class="w-full"
                :options="childern"
                label="name"
                :reduce="(name) => name.id"
                @change="onChangeStudent"
              />

              <span class="text-danger text-sm vx-col">
                {{ errors.first("city") | text_formatter }}
              </span>
            </template>
            <template v-else-if="childern.length === 1">
              <h4>{{ childern[0].name }}</h4>
            </template>
          </div>
          <template v-if="custodyData.student_id">
            <form-wizard
              ref="custody-wizard"
              color="rgba(var(--vs-primary), 1)"
              :title="null"
              :subtitle="null"
              :finishButtonText="$t('form.submit')"
              :backButtonText="$t('form.back')"
              :nextButtonText="$t('form.next')"
              :startIndex="0"
              @on-complete="submit"
              @on-change="onChangeTabs()"
            >
              <!-- ////////////////////////////////
                          applying custodyData
               /////////////////////////////////////-->
              <tab-content
                :title="`${$t('completeProfileData.applying_info')}`"
                class="mb-5"
                :before-change="validateStep1"
              >
                <form
                  class="vx-row w-full"
                  data-vv-scope="step-1"
                  @keyup.enter="validateStep1"
                >
                  <!-- Reason For Applying  -->
                  <!-- <div :class="gridClasses">
                  <label>{{
                    $t("completeProfileData.reason_for_applying")
                  }}</label>
                  <vs-textarea
                    v-model="custodyData.applying_reason"
                    name="applying_reason"
                    class="mb-0 w-full"
                  ></vs-textarea>
                  <span class="text-danger text-sm">
                    {{ errors.first("applying_reason") | text_formatter }}
                  </span>
                </div> -->

                  <!-- ask If first time -->
                  <div :class="grid2ColsClasses">
                    <label>
                      {{
                        $t(
                          "completeProfileData.is_this_your_first_time_your_child_join_any_school"
                        )
                      }}
                      ?
                    </label>
                    <ul class="vx-row mt-2 w-full">
                      <li class="vx-col w-1/2">
                        <vs-radio
                          v-model="custodyData.is_first_time_school"
                          :vs-value="true"
                          vs-name="is_first_time_school"
                          name="is_first_time_school"
                          >{{ $t("basic.yes") }}</vs-radio
                        >
                      </li>
                      <li class="vx-col w-1/2">
                        <vs-radio
                          v-model="custodyData.is_first_time_school"
                          :vs-value="false"
                          vs-name="is_first_time_school"
                          name="is_first_time_school"
                          >{{ $t("basic.no") }}</vs-radio
                        >
                      </li>
                    </ul>
                  </div>

                  <!-- isSkippedGrade -->
                  <div :class="grid2ColsClasses">
                    <label>
                      {{
                        $t(
                          "completeProfileData.has_your_child_ever_skipped_a_grade"
                        )
                      }}
                      ?
                    </label>
                    <ul class="vx-row mt-2 w-full">
                      <li class="vx-col w-1/2">
                        <vs-radio
                          v-model="custodyData.is_skipped_grade"
                          :vs-value="true"
                          vs-name="is_skipped_grade"
                          name="is_skipped_grade"
                          >{{ $t("basic.yes") }}</vs-radio
                        >
                      </li>
                      <li class="vx-col w-1/2">
                        <vs-radio
                          v-model="custodyData.is_skipped_grade"
                          :vs-value="false"
                          vs-name="is_skipped_grade"
                          name="is_skipped_grade"
                          >{{ $t("basic.no") }}</vs-radio
                        >
                      </li>
                    </ul>
                  </div>

                  <!-- hasReapetedYear -->
                  <div :class="grid2ColsClasses">
                    <label>
                      {{
                        $t(
                          "completeProfileData.has_your_child_ever_been_asked_to_repeat_a_year"
                        )
                      }}
                      ?
                    </label>
                    <ul class="vx-row mt-2 w-full">
                      <li class="vx-col w-1/2">
                        <vs-radio
                          v-model="custodyData.has_reapeted_year"
                          :vs-value="true"
                          name="has_reapeted_year"
                          vs-name="has_reapeted_year"
                          >{{ $t("basic.yes") }}</vs-radio
                        >
                      </li>
                      <li class="vx-col w-1/2">
                        <vs-radio
                          v-model="custodyData.has_reapeted_year"
                          :vs-value="false"
                          name="has_reapeted_year"
                          vs-name="has_reapeted_year"
                          >{{ $t("basic.no") }}</vs-radio
                        >
                      </li>
                    </ul>
                  </div>

                  <!-- hasLearningSupport -->
                  <div :class="gridClasses">
                    <label>
                      {{
                        $t(
                          "completeProfileData.has_your_child_received_any_learning_support_services_in_a_pervious_school_or_centre"
                        )
                      }}
                      ?
                    </label>
                    <ul class="vx-row mt-2 w-full">
                      <li class="vx-col lg:w-1/4 w-1/2">
                        <vs-radio
                          v-model="custodyData.has_learning_support"
                          :vs-value="true"
                          name="has_learning_support"
                          vs-name="has_learning_support"
                          >{{ $t("basic.yes") }}</vs-radio
                        >
                      </li>
                      <li class="vx-col lg:w-1/4 w-1/2">
                        <vs-radio
                          v-model="custodyData.has_learning_support"
                          :vs-value="false"
                          name="has_learning_support"
                          vs-name="has_learning_support"
                          >{{ $t("basic.no") }}</vs-radio
                        >
                      </li>
                    </ul>
                  </div>

                  <!-- attended Schools  -->
                  <div :class="gridClasses">
                    <h6>
                      {{
                        $t(
                          "completeProfileData.list_all_schools_attended_most_recent_first"
                        )
                      }}
                    </h6>
                    <div class="mt-3">
                      <!-- Schools Attended list -->
                      <template v-if="custodyData.attended_schools.length > 0">
                        <vs-collapse
                          :accordion="custodyData.attended_schools.length > 0"
                          type="border"
                          class="mb-6"
                        >
                          <vs-collapse-item
                            v-for="(val, i) in custodyData.attended_schools"
                            :key="val.id"
                            :style="[{ transitionDelay: i * 0.1 + 's' }]"
                            :open="
                              i + 1 === custodyData.attended_schools.length
                            "
                          >
                            <div slot="header">
                              <div class="flex justify-between items-center">
                                <h5>
                                  {{ val.school_name }}
                                </h5>

                                <div class="flex justify-between mx-5">
                                  <vs-button
                                    class="p-0"
                                    type="flat"
                                    icon-pack="feather"
                                    icon="icon-edit"
                                  ></vs-button>
                                  <vs-button
                                    class="p-0"
                                    type="flat"
                                    icon-pack="feather"
                                    icon="icon-trash"
                                    color="danger"
                                    @click="remove_school(i)"
                                  ></vs-button>
                                </div>
                              </div>
                            </div>
                            <div class="con-exemple-prompt">
                              <div class="vx-row w-full">
                                <!-- school name -->
                                <div :class="gridClasses">
                                  <label
                                    >{{ $t("form.school_name") }}
                                    <sup class="text-danger">*</sup></label
                                  >
                                  <vs-input
                                    v-model="val.school_name"
                                    class="w-full"
                                    :name="`school${i + 1}_name`"
                                    v-validate="'required'"
                                  />
                                  <span class="text-danger text-sm">{{
                                    errors.first(`step-1.school${i + 1}_name`)
                                      | text_formatter
                                  }}</span>
                                </div>

                                <!-- division -->
                                <div :class="grid3ColsClasses">
                                  <label
                                    >{{ $t("form.division") }}
                                    <sup class="text-danger">*</sup></label
                                  >
                                  <vs-input
                                    v-model="val.division"
                                    class="w-full"
                                    :name="`school${i + 1}_division`"
                                    v-validate="'required'"
                                  />
                                  <span class="text-danger text-sm">{{
                                    errors.first(
                                      `step-1.school${i + 1}_division`
                                    ) | text_formatter
                                  }}</span>
                                </div>

                                <!-- grade -->
                                <div :class="grid3ColsClasses">
                                  <label
                                    >{{ $t("form.grade") }}
                                    <sup class="text-danger">*</sup></label
                                  >
                                  <vs-input
                                    v-model="val.grade"
                                    class="w-full"
                                    :name="`school${i + 1}_grade`"
                                    v-validate="'required'"
                                  />
                                  <span class="text-danger text-sm">{{
                                    errors.first(`step-1.school${i + 1}_grade`)
                                      | text_formatter
                                  }}</span>
                                </div>
                                <!-- school year -->
                                <div :class="grid3ColsClasses">
                                  <label
                                    >{{ $t("form.school_year") }} (
                                    {{ $t("form.attended") }})
                                  </label>
                                  <vs-input
                                    v-model="val.year"
                                    class="w-full"
                                    :name="`school${i + 1}_year`"
                                    v-validate="'required'"
                                  />
                                  <span class="text-danger text-sm">{{
                                    errors.first(`step-1.school${i + 1}_year`)
                                      | text_formatter
                                  }}</span>
                                </div>
                                <!-- leavingReason -->
                                <div :class="gridClasses">
                                  <label>{{ $t("form.leavingReason") }} </label>
                                  <vs-textarea
                                    v-model="val.leaving_reason"
                                    class="w-full m-0"
                                    :name="`school${i + 1}_leaving_reason`"
                                    v-validate="'required'"
                                  />
                                  <span class="text-danger text-sm">{{
                                    errors.first(
                                      `school${i + 1}_leaving_reason`
                                    ) | text_formatter
                                  }}</span>
                                </div>
                              </div>
                            </div>
                          </vs-collapse-item>
                        </vs-collapse>
                      </template>
                      <!-- Add school btn  -->
                      <vs-button
                        type="line"
                        class="block mr-0 ml-auto"
                        @click="add_school"
                        >{{ $t("form.add_school") }}</vs-button
                      >
                    </div>
                  </div>
                </form>
              </tab-content>
              <!-- ////////////////////////////////
                          languges
               /////////////////////////////////////-->
              <tab-content
                :title="`${$t('completeProfileData.spoken_languages')}`"
                class="mb-5"
                :before-change="validateStep2"
              >
                <form
                  class="vx-row w-full"
                  data-vv-scope="step-2"
                  @keyup.enter="validateStep2"
                >
                  <!-- is_your_child_arabic_exempted -->
                  <div :class="grid2ColsClasses">
                    <label>
                      {{
                        $t("completeProfileData.is_your_child_arabic_exempted")
                      }}
                      ?
                      <sup class="text-danger">*</sup>
                    </label>
                    <ul class="vx-row mt-2 w-full">
                      <li class="vx-col w-1/2">
                        <vs-radio
                          v-model="custodyData.is_arabic_expemted"
                          :vs-value="true"
                          name="is_arabic_expemted"
                          vs-name="is_arabic_expemted"
                          >{{ $t("basic.yes") }}</vs-radio
                        >
                      </li>
                      <li class="vx-col w-1/2">
                        <vs-radio
                          v-model="custodyData.is_arabic_expemted"
                          :vs-value="false"
                          name="is_arabic_expemted"
                          vs-name="is_arabic_expemted"
                          >{{ $t("basic.no") }}</vs-radio
                        >
                      </li>
                    </ul>
                  </div>
                  <!-- motherTounge -->
                  <div :class="grid2ColsClasses">
                    <label
                      >{{ $t("completeProfileData.mother_tongue") }}
                      <sup class="text-danger">*</sup>
                    </label>
                    <v-select
                      v-model="custodyData.mother_tounge_id"
                      name="mother_tongue"
                      class="select-small w-full"
                      v-validate="'required'"
                      :options="languages"
                      label="name"
                      :reduce="(name) => name.id"
                    />

                    <span class="text-danger text-sm">{{
                      errors.first("step-2.mother_tongue") | text_formatter
                    }}</span>
                  </div>

                  <!-- most_commonly_spoken_at_home -->
                  <div :class="grid2ColsClasses">
                    <label
                      >{{
                        $t("completeProfileData.most_commonly_spoken_at_home")
                      }}
                      <sup class="text-danger">*</sup>
                    </label>
                    <v-select
                      v-model="custodyData.most_spoken_id"
                      name="most_spoken"
                      :options="languages"
                      label="name"
                      v-validate="'required'"
                      :reduce="(name) => name.id"
                    />

                    <span class="text-danger text-sm">{{
                      errors.first("step-2.most_spoken") | text_formatter
                    }}</span>
                  </div>

                  <!-- Student Second language  -->
                  <div :class="grid2ColsClasses">
                    <label
                      >{{
                        $t(
                          "completeProfileData.students_second_language_form_grade_1_onwards"
                        )
                      }}?
                    </label>
                    <v-select
                      v-model="custodyData.second_language_id"
                      name="second_language"
                      class="select-small w-full"
                      :options="secondLanguages"
                      label="name"
                      :reduce="(name) => name.id"
                    />

                    <span class="text-danger text-sm">{{
                      errors.first("step-2.second_language") | text_formatter
                    }}</span>
                  </div>

                  <!-- first_foreign_language -->
                  <div :class="grid2ColsClasses">
                    <label
                      >{{ $t("completeProfileData.first_foreign_language") }}
                      <sup class="text-danger">*</sup></label
                    >
                    <v-select
                      v-model="custodyData.first_foreign_id"
                      name="first_foreign"
                      class="select-small w-full"
                      v-validate="'required'"
                      :options="languages_with_Other"
                      label="name"
                      :reduce="(name) => name.id"
                    />
                    <vs-input
                      v-if="custodyData.first_foreign_id === -1"
                      v-model="custodyData.other_first_foreign_id"
                      name="first_foreign"
                      class="mt-5 w-full"
                      v-validate="'required'"
                      :placeholder="$t('form.please_enter_or_language')"
                    ></vs-input>
                    <span class="text-danger text-sm">{{
                      errors.first("step-2.first_foreign") | text_formatter
                    }}</span>
                  </div>
                </form>
              </tab-content>

              <!-- ////////////////////////////////
                          emergency
               /////////////////////////////////////-->
              <tab-content
                :title="`${$t('completeProfileData.emergency_info')}`"
                class="mb-5"
                :before-change="validateStep3"
              >
                <form data-vv-scope="step-3" @keyup.enter="validateStep3">
                  <template v-if="custodyData.emergencies.length > 0">
                    <vs-collapse
                      :accordion="custodyData.emergencies.length > 1"
                      type="border"
                      class="w-full mb-6"
                    >
                      <vs-collapse-item
                        v-for="(inf, inf_index) in custodyData.emergencies"
                        :key="inf_index"
                        :style="[{ transitionDelay: inf_index * 0.1 + 's' }]"
                        :open="inf_index + 1 === custodyData.emergencies.length"
                      >
                        <div slot="header">
                          <div class="flex justify-between items-center">
                            <h6>
                              <template v-if="inf.first_name">
                                {{
                                  `${inf.first_name} ${inf.middle_name} ${inf.last_name} `
                                }}
                              </template>
                              <template v-else
                                >{{
                                  $t("completeProfileData.emergency_contact")
                                }}
                                {{ inf_index + 1 }}</template
                              >
                            </h6>

                            <div class="flex justify-between mx-5">
                              <vs-button
                                class="p-0 edit_icon"
                                type="flat"
                                icon-pack="feather"
                                icon="icon-edit"
                              ></vs-button>
                              <vs-button
                                class="p-0"
                                type="flat"
                                icon-pack="feather"
                                icon="icon-trash"
                                color="danger"
                                @click="remove_emergency_contact(inf_index)"
                              ></vs-button>
                            </div>
                          </div>
                        </div>
                        <div class="vx-row w-full mt-7 mx-auto">
                          <!-- ////////////////////////////////
                                  Full Name   In English
                          /////////////////////////////////////-->

                          <vs-divider :class="gridClasses">
                            {{ $t("form.fullName") | text_formatter }}
                          </vs-divider>
                          <div :class="grid3ColsClasses">
                            <label
                              >{{ $t("form.first_name") }}
                              <sup class="text-danger">*</sup></label
                            >
                            <vs-input
                              v-model="inf.first_name"
                              class="w-full"
                              :name="`first_name_${inf_index + 1}`"
                              :placeholder="$t('form.In_English')"
                              v-validate="{
                                required: true,
                                alpha_spaces: true,
                                regex: /^[a-zA-Z\s]*$/,
                              }"
                            />
                            <span class="text-danger text-sm">{{
                              errors.first(`step-3.first_name_${inf_index + 1}`)
                                | text_formatter
                            }}</span>
                          </div>
                          <div :class="grid3ColsClasses">
                            <label
                              >{{ $t("form.middle_name") }}
                              <sup class="text-danger">*</sup></label
                            >
                            <vs-input
                              v-model="inf.middle_name"
                              class="w-full"
                              :name="`middle_name_${inf_index + 1}`"
                              :placeholder="$t('form.In_English')"
                              v-validate="{
                                required: true,
                                alpha_spaces: true,
                                regex: /^[a-zA-Z\s]*$/,
                              }"
                            />
                            <span class="text-danger text-sm">{{
                              errors.first(
                                `step-3.middle_name_${inf_index + 1}`
                              ) | text_formatter
                            }}</span>
                          </div>
                          <div :class="grid3ColsClasses">
                            <label
                              >{{ $t("form.last_name") }}
                              <sup class="text-danger">*</sup></label
                            >
                            <vs-input
                              v-model="inf.last_name"
                              class="w-full"
                              :name="`last_name_${inf_index + 1}`"
                              :placeholder="$t('form.In_English')"
                              v-validate="{
                                required: true,
                                alpha_spaces: true,
                                regex: /^[a-zA-Z\s]*$/,
                              }"
                            />
                            <span class="text-danger text-sm">{{
                              errors.first(`step-3.last_name_${inf_index + 1}`)
                                | text_formatter
                            }}</span>
                          </div>
                          <!-- ////////////////////////////////
                                      Personal Data
                          ///////////////////////////////////// -->
                          <vs-divider :class="gridClasses">
                            {{
                              $t("completeProfileData.personal_data")
                                | text_formatter
                            }}
                          </vs-divider>
                          <!-- Relationship -->
                          <div :class="grid2ColsClasses">
                            <label
                              >{{ $t("form.relationship")
                              }}<sup class="text-danger">*</sup></label
                            >
                            <v-select
                              v-model="inf.relationship_id"
                              class="w-full"
                              :options="relationshipsWithoutParents"
                              label="name"
                              :reduce="(re) => re.id"
                              @input="onChangeEmergancyRelationship(inf_index)"
                            />
                            <input
                              v-model="inf.relationship_id"
                              v-validate="'required'"
                              :name="`emergency_relationship_${inf_index + 1}`"
                              hidden
                            />
                            <span class="text-danger text-sm">{{
                              errors.first(
                                `step-3.emergency_relationship_${inf_index + 1}`
                              ) | text_formatter
                            }}</span>
                          </div>

                          <!-- Gender -->
                          <div :class="grid2ColsClasses" hidden>
                            <label
                              >{{ $t("form.gender")
                              }}<sup class="text-danger">*</sup></label
                            >
                            <v-select
                              v-model="inf.gender_id"
                              class="w-full"
                              :options="genders"
                              label="name"
                              :reduce="(name) => name.id"
                            />
                            <input
                              v-validate="'required'"
                              v-model="inf.gender_id"
                              :name="`gender_id_${inf_index + 1}`"
                              hidden
                            />
                            <span class="text-danger text-sm">
                              {{
                                errors.first(
                                  `step-3.gender_id_${inf_index + 1}`
                                ) | text_formatter
                              }}
                            </span>
                          </div>
                          <!-- Religion -->
                          <div :class="grid2ColsClasses">
                            <label class
                              >{{ $t("form.Religion")
                              }}<sup class="text-danger">*</sup></label
                            >

                            <v-select
                              v-model="inf.religion_id"
                              class="w-full"
                              :options="religions"
                              label="name"
                              :reduce="(rel) => rel.id"
                              :disable="!religions"
                            />
                            <vs-input
                              :name="`religion_${inf_index + 1}`"
                              class="w-full mt-5"
                              v-if="inf.religion_id < 0"
                              v-model="inf.other_religion"
                              v-validate="'required'"
                              :placeholder="$t('form.please_enter_or_religion')"
                            ></vs-input>
                            <input
                              v-model="inf.religion_id"
                              v-validate="'required'"
                              :name="`religion_${inf_index + 1}`"
                              hidden
                            />
                            <span class="text-danger text-sm">{{
                              errors.first(`step-3.religion_${inf_index + 1}`)
                                | text_formatter
                            }}</span>
                          </div>
                          <!-- ////////////////////////////////
                                 address
                          ///////////////////////////////////// -->
                          <vs-divider :class="gridClasses">
                            {{
                              $t("completeProfileData.address") | text_formatter
                            }}
                          </vs-divider>

                          <!-- Country -->
                          <div :class="grid3ColsClasses">
                            <label>
                              {{ $t("form.country") }}
                              <sup class="text-danger">*</sup>
                            </label>
                            <v-select
                              v-model="inf.country_id"
                              class="w-full"
                              @input="onChangeEmergenciesCountry(inf_index)"
                              :options="countries"
                              :reduce="(name) => name.id"
                              label="name"
                            />
                            <input
                              v-model="inf.country_id"
                              v-validate="'required'"
                              :name="`country_${inf_index + 1}`"
                              hidden
                            />
                            <span class="text-danger text-sm vx-col">
                              {{
                                errors.first(`step-3.country_${inf_index + 1}`)
                                  | text_formatter
                              }}
                            </span>
                          </div>
                          <!-- city -->
                          <div :class="grid3ColsClasses">
                            <label>
                              {{ $t("form.city") }}
                              <sup class="text-danger">*</sup>
                            </label>
                            <v-select
                              v-model="inf.city_id"
                              class="w-full"
                              :options="cities"
                              label="name"
                              :reduce="(name) => name.id"
                              :showLoadingIcon="showCitySelectLoader"
                            />
                            <input
                              :name="`city_${inf_index + 1}`"
                              v-validate="'required'"
                              v-model="inf.city_id"
                              hidden
                            />

                            <span class="text-danger text-sm vx-col">
                              {{
                                errors.first(`step-3.city_${inf_index + 1}`)
                                  | text_formatter
                              }}
                            </span>
                          </div>
                          <!-- district -->
                          <div :class="grid3ColsClasses">
                            <label
                              >{{ $t("form.district")
                              }}<sup class="text-danger">*</sup>
                            </label>

                            <vs-input
                              v-model="inf.district"
                              :name="`district_${inf_index + 1}`"
                              class="w-full"
                              v-validate="'required'"
                            />

                            <span class="text-danger text-sm vx-col">
                              {{
                                errors.first(`step-3.district_${inf_index + 1}`)
                                  | text_formatter
                              }}
                            </span>
                          </div>
                          <!-- street -->
                          <div :class="gridClasses" class="lg:w-2/3 md:w-1/2">
                            <label
                              >{{ $t("form.Street")
                              }}<sup class="text-danger">*</sup>
                            </label>
                            <vs-input
                              v-model="inf.street"
                              :name="`street_${inf_index + 1}`"
                              class="w-full"
                              v-validate="'required'"
                            />
                            <span class="text-danger text-sm vx-col">
                              {{
                                errors.first(`step-3.street_${inf_index + 1}`)
                                  | text_formatter
                              }}
                            </span>
                          </div>

                          <!-- ////////////////////////////////
                                communication
                          ///////////////////////////////////// -->
                          <vs-divider :class="gridClasses">
                            {{
                              $t("completeProfileData.communication")
                                | text_formatter
                            }}
                          </vs-divider>
                          <!-- Mobile -->
                          <div class="" :class="grid2ColsClasses">
                            <label :class="gridClasses">
                              {{ $t("form.mobile") }}
                              <sup class="text-danger">*</sup>
                            </label>
                            <div class="vx-row">
                              <!-- Key -->
                              <div class="vx-col w-1/3">
                                <v-select
                                  v-model="inf.mobile.code"
                                  class="w-full"
                                  :options="mobile_keys"
                                />
                                <input
                                  hidden
                                  v-model="inf.mobile.code"
                                  :name="`mobile_code_${inf_index + 1}`"
                                  v-validate="'required'"
                                />
                              </div>
                              <!-- number -->
                              <div class="vx-col w-2/3">
                                <vs-input
                                  v-model="inf.mobile.number"
                                  class="w-full"
                                  :name="`mobile_${inf_index + 1}`"
                                  v-validate="'required|numeric|length:8'"
                                />

                                <span class="text-danger text-sm">
                                  {{
                                    errors.first(
                                      `step-3.mobile_${inf_index + 1}`
                                    ) | text_formatter
                                  }}
                                </span>
                              </div>
                            </div>
                          </div>

                          <!-- Telephone -->
                          <div class="" :class="grid2ColsClasses">
                            <label :class="gridClasses">
                              {{ $t("form.telephone") }}
                              <!-- <sup class="text-danger">*</sup> -->
                            </label>
                            <div class="vx-row">
                              <!-- Key -->
                              <div class="vx-col w-1/3">
                                <v-select
                                  v-model="inf.telephone.code"
                                  class="w-full"
                                  :options="tel_keys"
                                />
                                <input
                                  hidden
                                  v-model="inf.telephone.code"
                                  :name="`telephone_code_${inf_index + 1}`"
                                  v-validate="
                                    inf.telephone.number ? 'required' : ''
                                  "
                                />
                              </div>
                              <!-- number -->
                              <div class="vx-col w-2/3">
                                <vs-input
                                  v-model="inf.telephone.number"
                                  class="w-full"
                                  :name="`telephone_${inf_index + 1}`"
                                  v-validate="'numeric|length:8'"
                                />

                                <span class="text-danger text-sm">
                                  {{
                                    errors.first(
                                      `step-3.telephone_${inf_index + 1}`
                                    ) | text_formatter
                                  }}
                                </span>
                              </div>
                            </div>
                          </div>

                          <!--  Email -->
                          <div :class="grid2ColsClasses">
                            <label>
                              {{ $t("form.email") }}
                            </label>
                            <vs-input
                              type="email"
                              v-model="inf.email"
                              class="w-full"
                              :name="`email_${inf_index + 1}`"
                              v-validate="'email'"
                            />
                            <span class="text-danger text-sm">{{
                              errors.first(`step-3.email_${inf_index + 1}`)
                                | text_formatter
                            }}</span>
                          </div>
                          <!-- Official  Email-->
                          <div :class="grid2ColsClasses">
                            <label>
                              {{ $t("form.official_mailing_email") }}
                              <sup class="text-danger">*</sup>
                            </label>
                            <div class="repeater">
                              <vs-input
                                type="email"
                                v-model="inf.official_email"
                                class="w-full"
                                :name="`official_email_${inf_index + 1}`"
                                v-validate="'required|email'"
                              />
                            </div>
                            <span class="text-danger text-sm">{{
                              errors.first(
                                `step-3.official_email_${inf_index + 1}`
                              ) | text_formatter
                            }}</span>
                          </div>
                        </div>
                      </vs-collapse-item>
                    </vs-collapse>
                  </template>
                  <div :class="gridClasses">
                    <vs-button
                      type="line"
                      @click="add_emergency_contact"
                      class="mr-0 ml-auto block"
                      >{{ $t("form.add_emergency_contact") }}</vs-button
                    >
                  </div>
                </form>
              </tab-content>

              <!-- ////////////////////////////////
                          General Data
               /////////////////////////////////////-->
              <tab-content
                :title="`${$t('completeProfileData.general_Data')}`"
                class="mb-5"
                :before-change="validateStep4"
                :lazy="true"
              >
                <form data-vv-scope="step-4" @keyup.enter="validateStep4">
                  <!-- Bus Question -->
                  <div :class="gridClasses">
                    <label>{{ $t("form.bus_question") }}</label>
                    <ul class="vx-row mt-2">
                      <li class="vx-col lg:w-1/4 w-1/2">
                        <vs-radio
                          v-model="custodyData.questions.bus_required"
                          :vs-value="true"
                          name="bus_required"
                          vs-name="bus_required"
                          @change="googleAddress()"
                          >{{ $t("basic.yes") }}</vs-radio
                        >
                      </li>
                      <li class="vx-col lg:w-1/4 w-1/2">
                        <vs-radio
                          v-model="custodyData.questions.bus_required"
                          :vs-value="false"
                          name="bus_required"
                          vs-name="bus_required"
                          @change="googleAddress()"
                          >{{ $t("basic.no") }}</vs-radio
                        >
                      </li>
                    </ul>
                  </div>
                  <template v-if="custodyData.questions.bus_required">
                    <div :class="gridClasses">
                      <!---  GPSAddress --->
                      <div
                        class="
                          vx-col
                          lg:w-1/2
                          md:w-1/2
                          w-full
                          mb-6
                          flex
                          items-center
                        "
                      >
                        <div v-if="googleError" class="text-danger text-sm">
                          {{ $t("form.ClickForGPSAddress") }}
                          <span class="text-danger">*</span>
                        </div>
                      </div>

                      <GoogleMapInfoWindow
                        @updateLocation="updateLocation"
                        @getSearchContent="updateAddress"
                        :markers="markers"
                        :setPlace="setPlace"
                        class="vx-col w-full mb-6"
                        ref="googleMap"
                        :loaded="mapLoader"
                        :mapErrorAlert="mapErrorAlert"
                      />
                      <!---  /GPSAddress --->
                    </div>
                  </template>

                  <!-- Submitted before question -->
                  <div :class="gridClasses">
                    <label>{{
                      $t("form.submitted_an_application_before_question")
                    }}</label>
                    <ul class="vx-row mt-2">
                      <li class="vx-col lg:w-1/4 w-1/2">
                        <vs-radio
                          v-model="custodyData.questions.submitted_before"
                          :vs-value="true"
                          vs-name="submitted_before"
                          name="submitted_before"
                          >{{ $t("basic.yes") }}</vs-radio
                        >
                      </li>
                      <li class="vx-col lg:w-1/4 w-1/2">
                        <vs-radio
                          v-model="custodyData.questions.submitted_before"
                          :vs-value="false"
                          vs-name="submitted_before"
                          name="submitted_before"
                          >{{ $t("basic.no") }}</vs-radio
                        >
                      </li>
                    </ul>
                  </div>
                  <vs-divider :class="gridClasses">
                    {{
                      $t("completeProfileData.medical_Data") | text_formatter
                    }}
                  </vs-divider>
                  <template>
                    <!-- Health problems quetion -->
                    <div :class="gridClasses">
                      <label>{{ $t("form.health_problem_question") }}</label>
                      <ul class="vx-row mt-2">
                        <li class="vx-col lg:w-1/4 w-1/2">
                          <vs-radio
                            v-model="custodyData.questions.has_health_problem"
                            :vs-value="true"
                            name="has_health_problem"
                            vs-name="has_health_problem"
                            >{{ $t("basic.yes") }}</vs-radio
                          >
                        </li>
                        <li class="vx-col lg:w-1/4 w-1/2">
                          <vs-radio
                            v-model="custodyData.questions.has_health_problem"
                            :vs-value="false"
                            name="has_health_problem"
                            vs-name="has_health_problem"
                            >{{ $t("basic.no") }}</vs-radio
                          >
                        </li>
                      </ul>
                    </div>
                    <div
                      :class="gridClasses"
                      v-if="custodyData.questions.has_health_problem"
                    >
                      <vs-textarea
                        :placeholder="$t('form.describe_health_problem')"
                        v-model="custodyData.questions.health_problem"
                        name="describe_health_problem"
                        class="mb-3 w-full vx-col"
                      ></vs-textarea>
                    </div>
                    <!--is_vaccinations -->
                    <div :class="gridClasses">
                      <label
                        >{{
                          $t(
                            "completeProfileData.do_you_agree_that_the_school_supervises_your_childs_vaccinations_periodically_and_as_indicated_by_and_under_the_supervision_of_the_ministry_of_health"
                          )
                        }}
                        ?</label
                      >
                      <ul class="vx-row mt-2 w-full">
                        <li class="vx-col lg:w-1/4 w-1/2">
                          <vs-radio
                            v-model="custodyData.medical_data.is_vaccinations"
                            :vs-value="1"
                            vs-name="vaccinations"
                            name="vaccinations"
                            >{{ $t("basic.yes") }}</vs-radio
                          >
                        </li>
                        <li class="vx-col lg:w-1/4 w-1/2">
                          <vs-radio
                            v-model="custodyData.medical_data.is_vaccinations"
                            :vs-value="0"
                            vs-name="vaccinations"
                            name="vaccinations"
                            >{{ $t("basic.no") }}</vs-radio
                          >
                        </li>
                      </ul>
                    </div>
                    <!--child_suffering -->
                    <div :class="gridClasses">
                      <label
                        >{{
                          $t(
                            "completeProfileData.does_your_child_suffer_from_one_or_more_of_the_following"
                          )
                        }}
                        ?
                      </label>

                      <ul class="w-full mt-5">
                        <li
                          v-for="val in childSufferingCases"
                          :key="val.flag"
                          class="mb-3"
                        >
                          <vs-checkbox
                            v-model="custodyData.medical_data.child_suffering"
                            :vs-value="val.name"
                            @change="
                              val.id === -1
                                ? is_vaccinations_id === -1
                                  ? (is_vaccinations_id = 0)
                                  : (is_vaccinations_id = val.id)
                                : 0
                            "
                            name="child_suffering"
                            >{{ val.name }}</vs-checkbox
                          >
                        </li>
                      </ul>
                    </div>
                    <!--is_vaccinations other -->
                    <div :class="gridClasses" v-if="is_vaccinations_id === -1">
                      <label
                        >{{
                          $t("completeProfileData.please_specify_in_details")
                        }}
                      </label>

                      <vs-input
                        class="w-full"
                        v-model="
                          custodyData.medical_data.other_suffering_details
                        "
                        v-validate="'required'"
                        name="other_suffering_details"
                      ></vs-input>
                      <span class="text-danger text-sm">{{
                        errors.first("step-4.other_suffering_details")
                          | text_formatter
                      }}</span>
                    </div>
                    <!--has_regular_medication -->
                    <div :class="gridClasses">
                      <label
                        >{{
                          $t(
                            "completeProfileData.Is_your_child_taking_any_specific_medication_on_regular_basis"
                          )
                        }}
                        ?</label
                      >
                      <ul class="vx-row mt-2 w-full">
                        <li class="vx-col lg:w-1/4 w-1/2">
                          <vs-radio
                            v-model="
                              custodyData.medical_data.has_regular_medication
                            "
                            :vs-value="1"
                            vs-name="has_regular_medication"
                            name="has_regular_medication"
                            >{{ $t("basic.yes") }}</vs-radio
                          >
                        </li>
                        <li class="vx-col lg:w-1/4 w-1/2">
                          <vs-radio
                            v-model="
                              custodyData.medical_data.has_regular_medication
                            "
                            :vs-value="0"
                            vs-name="has_regular_medication"
                            name="has_regular_medication"
                            >{{ $t("basic.no") }}</vs-radio
                          >
                        </li>
                      </ul>
                    </div>
                    <!--regular_medication_describing -->
                    <div
                      :class="gridClasses"
                      v-if="custodyData.medical_data.has_regular_medication"
                    >
                      <label
                        >{{
                          $t("completeProfileData.please_specify_in_details")
                        }}
                      </label>

                      <vs-input
                        class="w-full"
                        v-model="
                          custodyData.medical_data.regular_medication_describing
                        "
                        v-validate="'required'"
                        name="regular_medication_describing"
                      ></vs-input>
                      <span class="text-danger text-sm">{{
                        errors.first("step-4.regular_medication_describing")
                          | text_formatter
                      }}</span>
                    </div>
                    <!--has_physical_activities -->
                    <div :class="gridClasses">
                      <label
                        >{{
                          $t(
                            "completeProfileData.is_there_any_reason_why_your_child_could_not_participate_in_any_physical_activities"
                          )
                        }}
                        ?</label
                      >
                      <ul class="vx-row mt-2 w-full">
                        <li class="vx-col lg:w-1/4 w-1/2">
                          <vs-radio
                            v-model="
                              custodyData.medical_data.has_physical_activities
                            "
                            :vs-value="1"
                            vs-name="has_physical_activities"
                            name="has_physical_activities"
                            >{{ $t("basic.yes") }}</vs-radio
                          >
                        </li>
                        <li class="vx-col lg:w-1/4 w-1/2">
                          <vs-radio
                            v-model="
                              custodyData.medical_data.has_physical_activities
                            "
                            :vs-value="0"
                            vs-name="has_physical_activities"
                            name="has_physical_activities"
                            >{{ $t("basic.no") }}</vs-radio
                          >
                        </li>
                      </ul>
                    </div>
                    <!--physical_activities_details -->
                    <div
                      :class="gridClasses"
                      v-if="custodyData.medical_data.has_physical_activities"
                    >
                      <label
                        >{{
                          $t("completeProfileData.please_specify_in_details")
                        }}
                      </label>

                      <vs-input
                        class="w-full"
                        v-model="
                          custodyData.medical_data.physical_activities_details
                        "
                        v-validate="'required'"
                        name="physical_activities_details"
                      ></vs-input>
                      <span class="text-danger text-sm">{{
                        errors.first("step-4.physical_activities_details")
                          | text_formatter
                      }}</span>
                    </div>

                    <!--is_your_child_a_special_needs_student -->
                    <div :class="gridClasses">
                      <label
                        >{{
                          $t(
                            "completeProfileData.is_your_child_a_special_needs_student"
                          )
                        }}
                        ?<sup class="text-danger">*</sup></label
                      >
                      <ul class="vx-row mt-2 w-full">
                        <li class="vx-col lg:w-1/4 w-1/2">
                          <vs-radio
                            v-model="custodyData.medical_data.has_special_needs"
                            :vs-value="1"
                            vs-name="has_special_needs"
                            name="has_special_needs"
                            >{{ $t("basic.yes") }}</vs-radio
                          >
                        </li>
                        <li class="vx-col lg:w-1/4 w-1/2">
                          <vs-radio
                            v-model="custodyData.medical_data.has_special_needs"
                            :vs-value="0"
                            vs-name="has_special_needs"
                            name="has_special_needs"
                            >{{ $t("basic.no") }}</vs-radio
                          >
                        </li>
                      </ul>
                    </div>
                    <!--physical_activities_details -->
                    <div
                      :class="gridClasses"
                      v-if="custodyData.medical_data.has_special_needs"
                    >
                      <label
                        >{{
                          $t(
                            "completeProfileData.please_specify_the_type_of_disability"
                          )
                        }}
                      </label>

                      <vs-input
                        class="w-full"
                        v-model="custodyData.medical_data.special_needs_details"
                        name="special_needs_details"
                        v-validate="'required'"
                      ></vs-input>
                      <span class="text-danger text-sm">{{
                        errors.first("step-4.special_needs_details")
                          | text_formatter
                      }}</span>
                    </div>

                    <!--did_your_child_need_a_shadow_teacher_previously -->
                    <div :class="gridClasses">
                      <label
                        >{{
                          $t(
                            "completeProfileData.did_your_child_need_a_shadow_teacher_previously"
                          )
                        }}
                        ?</label
                      >
                      <ul class="vx-row mt-2 w-full">
                        <li class="vx-col lg:w-1/4 w-1/2">
                          <vs-radio
                            v-model="
                              custodyData.medical_data.has_shadow_teacher
                            "
                            :vs-value="1"
                            vs-name="has_shadow_teacher"
                            name="has_shadow_teacher"
                            >{{ $t("basic.yes") }}</vs-radio
                          >
                        </li>
                        <li class="vx-col lg:w-1/4 w-1/2">
                          <vs-radio
                            v-model="
                              custodyData.medical_data.has_shadow_teacher
                            "
                            :vs-value="0"
                            vs-name="has_shadow_teacher"
                            name="has_shadow_teacher"
                            >{{ $t("basic.no") }}</vs-radio
                          >
                        </li>
                      </ul>
                    </div>
                  </template>
                </form>
              </tab-content>
            </form-wizard>
          </template>

          <!-- Loader -->
          <div
            ref="formLoader"
            id="formLoader"
            class="vs-con-loading__container"
            v-show="showLoader"
          ></div>
        </vx-card>
      </div>
    </template>
  </div>
</template>
<script>
// API
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const ParentRepository = RepositoryFactory.get('ParentRepository', 'parent')

// Mixins
import {commonDataMixin} from '@/mixins.js'

// picker
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

// form wizerd
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

// Google map
import GoogleMapInfoWindow from '@/components/GoogleMapInfoWindow.vue'

export default {
  name: 'completeCustodyData',
  mixins: [commonDataMixin],
  components:{flatPickr, FormWizard, TabContent, GoogleMapInfoWindow },
  data () {
    return {
      ///////////////////////////
      // Loader
      ///////////////////////////
      showLoader:false,
      showStudentSelectLoader:false,
      showCitySelectLoader:false,
      loadingchilderns:true,
      ///////////////////////////
      // Datepicker
      ///////////////////////////
      configdatePicker: {
        minDate: null,
        maxDate: `${new Date().getFullYear() - 13}`,
        dateFormat: 'Y-m-d'
      },

      ///////////////////////////
      //  Childers
      ///////////////////////////
      childern:[],

      ///////////////////////////
      //  Static Data
      ///////////////////////////
      maritalStatus:[],
      genders:[],
      custodyOwners:[],
      childSufferingCases:[],

      ///////////////////////////
      //  emergancy
      ///////////////////////////
      emergencyObj:{
        first_name: '',
        middle_name: '',
        last_name: '',
        gender: 0,
        religion_id: null,
        other_religion:null,
        country_id: null,
        city_id: null,
        district: null,
        street: null,
        user_id:null,
        type: 'emergency',
        mobile: {code:'011', number:''},
        telephone:{code:'02', number:''},
        email: '',
        official_email: '',
        relationship_id: null
      },
      ///////////////////////////
      //  Google Map
      ///////////////////////////
      mapLoader:false,
      markers: [{ position: { lat: null, lng: null }, infoText: 'Marker 1' }],
      mapErrorAlert:false,

      googleError: false,
      map_popupActive:false,
      addressOnMap: '',
      map:{
        address:'',
        position:{ lat: 37.039374, lng:  31.231430 }
      },
      location: {
        address: '',
        latitude: '',
        longitude: ''
      },
      ///////////////////////////
      //  medical Data
      ///////////////////////////
      is_vaccinations_id:0,
      ///////////////////////////
      //  attended schools
      ///////////////////////////
      attendedSchoolObj:{
        school_name: '',
        division: '',
        grade: '',
        year: '',
        leaving_reason: ''
      },
      ///////////////////////////
      //  custody
      ///////////////////////////
      custodyData:{
        student_id:null,

        // Applying Info
        // applying_reason:'',
        is_first_time_school:true,
        is_skipped_grade:0,
        has_reapeted_year:0,
        has_learning_support:true,
        attended_schools:[],

        // emergencies
        emergencies:[],

        // Spoken Languages
        second_language_id:null,
        other_first_foreign_id:null,
        mother_tounge_id:null,
        most_spoken_id:null,
        first_foreign_id:null,
        is_arabic_expemted:true,

        // General Data
        questions : {
          bus_required: false,
          has_health_problem: 0,
          health_problem:'',
          submitted_before: 0,
          localion:{
            address: 'kkkkkkk',
            latitude: '9999777',
            longitude: '56777'
          }
        },
        medical_data: {
          is_vaccinations: 0,
          child_suffering: [],
          has_regular_medication: 0,
          has_physical_activities: 0,
          has_special_needs: 0,
          has_shadow_teacher: 0,
          special_needs_details: '',
          physical_activities_details: '',
          regular_medication_describing: '',
          other_suffering_details: ''
        }
      },

      birth_date:null

    }
  },

  computed: {
    validateForm () {
      return !this.errors.any()
    },
    gridClasses () {
      return 'vx-col w-full mb-6'
    },
    grid4ColsClasses () {
      return `${this.gridClasses} lg:w-1/4 md:w-1/2 sm:w-1/2`
    },
    grid2ColsClasses () {
      return `${this.gridClasses} md:w-1/2`
    },
    grid3ColsClasses () {
      return `${this.gridClasses} lg:w-1/3 md:w-1/2 `
    },
    secondLanguages () {
      return this.languages.filter(el => el.name !== 'Arabic' && el.name !== 'English')
    },
    authUserId () {
      return this.authUser.uid
    }

  },
  watch:{
    birth_date (v) {
      // this.parentData.birth_date = new Date(v).getTime()
      // moment(Number(date)).format('YYYY-MM-DD')
    },
    'custodyData.student_id' (v) {
      this.getParentStudentData()
    }
  },
  methods: {

    ///////////////////////
    // loader
    ///////////////////////
    async startLoading () {
      await (this.showLoader = true)
      this.VsLoadingContained(this.$refs.formLoader)
    },
    endLoading () {
      this.stopVsLoadingContained(this.$refs.formLoader)
      this.showLoader = false
    },

    ///////////////////////
    // APIs
    ///////////////////////
    getParentChildren () {
      this.startLoading()

      ParentRepository.getParentChildren()
        .then(res => {
          this.endLoading()

          this.childern = res.data.map((e) => { return { id:e.student_id, name:e.name } })
          if (this.childern.length === 1) {
            this.custodyData.student_id = this.childern[0].id
            this.getParentStudentData()
          }
        })
        .finally(() => {

          this.loadingchilderns = false
        })
    },
    getParentStudentData () {
      this.startLoading()
      ParentRepository.getParentStudentData(this.custodyData.student_id)
        .then(res => {
          this.custodyData = res.data
          if (!res.data.emergencies.length) this.custodyData.emergencies = [
            {
              user_id:this.authUserId,
              first_name: '',
              middle_name: '',
              last_name: '',
              gender_id: 0,
              religion_id: null,
              other_religion:null,
              country_id: null,
              city_id: null,
              district: null,
              street: null,
              type: 'emergency',
              mobile: {code:'011', number:''},
              telephone:{code:'02', number:''},
              email: '',
              official_email: '',
              relationship_id: null
            }
          ]
          if (!res.data.medical_data.child_suffering) this.custodyData.medical_data.child_suffering = []
        })
        .finally(this.endLoading)

    },
    getMaritalStatus () {
      ParentRepository.getMaritalStatus()
        .then(res => {
          this.maritalStatus = res.data
        })
    },
    getGenders () {
      ParentRepository.getGenders()
        .then(res => {
          this.genders = res.data
        })
    },
    getCustodyOwners () {
      ParentRepository.getCustodyOwners()
        .then(res => {
          this.custodyOwners = res.data
        })
    },
    getMedicalChildSuffers  () {
      ParentRepository.getMedicalChildSuffers()
        .then(res => { this.childSufferingCases = res.data })
    },
    saveParentStudentInfo () {
      ParentRepository.saveParentStudentInfo(this.custodyData)
        .then(res => {
          this.successMsg(this.$t('form.saved_successfully'))
          this.$router.push({name:'ParentProfile'})
        })
        .catch(err => this.errMsg(err))
    },
    ///////////////////////
    // OnChange
    ///////////////////////
    onChangeStudent () {
      this.showStudentSelectLoader = true
      this.custodyData.student_id = null
      this.getParentStudentData()
        .then(res => { this.custodyData = res.data })
        .finally(() => { this.showStudentSelectLoader = false })


    },
    onChangeCountry () {
      this.showCitySelectLoader = true
      this.parentData.city_id = null
      this.getCitiesOfCountry(this.parentData.country_id)
        .finally(() => { this.showCitySelectLoader = false })

    },

    //////////////////////////
    // Attended School
    //////////////////////////

    add_school () {
      const infoSchools =  this.custodyData.attended_schools
      this.$validator.validateAll('step-1')
        .then(valid => {
          if (valid) infoSchools.push({id:null, school_name:`School ${infoSchools.length + 1}`})
          else this.errMsg()
        })


    },
    remove_school (i) {
      this.custodyData.attended_schools.splice(i, 1)

    },
    ResetAttendSchoolOgj () {
      this.attended_school = {id:null}
    },
    ///////////////////////
    // emergency
    ///////////////////////
    onChangeEmergenciesCountry (index) {
      this.showCitySelectLoader = true

      const
        emergenyContact = this.custodyData.emergencies[index],
        country_id = emergenyContact.country_id

      emergenyContact.city_id = null
      this.getCitiesOfCountry(country_id)
        .finally(() => { this.showCitySelectLoader = false })
    },
    onChangeEmergancyRelationship (i) {
      const
        emergenyContact = this.custodyData.emergencies[i],
        relationShipId = emergenyContact.relationship_id,
        is_male = this.relationships_male_ids.includes(relationShipId),
        is_female = this.relationships_female_ids.includes(relationShipId)

      if (is_female) emergenyContact.gender_id = this.genders.find(e => e.flag === 'female').id
      else emergenyContact.gender_id = this.genders.find(e => e.flag === 'male').id
    },
    add_emergency_contact () {
      const newObj = {user_id:this.authUserId,

        first_name: '',
        middle_name: '',
        last_name: '',
        gender_id: 0,
        religion_id: null,
        other_religion:null,
        country_id: null,
        city_id: null,
        district: null,
        street: null,
        type: 'emergency',
        mobile: {code:'011', number:''},
        telephone:{code:'02', number:''},
        email: '',
        official_email: '',
        relationship_id: null
      }

      this.$validator.validateAll('step-3')
        .then(valid => {
          if (valid)  this.custodyData.emergencies.push(newObj)
          else this.errMsg()
        })

    },
    remove_emergency_contact (index) {
      this.custodyData.emergencies.splice(index, 1)
    },
    ///////////////////////
    // Google map
    ///////////////////////
    updateLocation (loc) {
      if (loc) {
        this.mapErrorAlert = false
        const
          lat = parseFloat(loc.latLng.lat()),
          lng = parseFloat(loc.latLng.lng())

        this.markers[0].position.lat = lat
        this.markers[0].position.lng = lng
        this.custodyData.questions.location.latitude = this.markers[0].position.lat
        this.custodyData.questions.location.longitude = this.markers[0].position.lng
        this.currentPlace = loc
        if (this.mapLoader) this.autocompleteValue  = `${lat},${lng}`
      } else {
        this.mapErrorAlert = true

      }

    },
    async setPlace (place) {
      if (place.geometry) {
        this.mapErrorAlert = false
        this.currentPlace = place
        console.log(this.currentPlace)
        this.markers[0].position.lat = parseFloat(place.geometry.location.lat())
        this.markers[0].position.lng = parseFloat(place.geometry.location.lng())
        this.custodyData.questions.location.latitude = this.markers[0].position.lat
        this.custodyData.questions.location.longitude = this.markers[0].position.lng
        if (await this.mapLoader) this.$refs.googleMap.center =  this.markers[0].position
        this.autocompleteValue =  this.currentPlace.formatted_address
      } else if (place.latLng) {
        this.mapErrorAlert = false
      } else {
        this.mapErrorAlert = true

      }


    },
    //for google map
    async googleAddress () {
      if (await this.mapLoader)  this.mapLoader = false
      else {
        await  (this.mapLoader = true)
        if (this.currentPlace) {
          console.log(this.currentPlace)
          if (this.currentPlace.formatted_address) this.setPlace(this.currentPlace)
          else   this.updateLocation(this.currentPlace)


        } else if (!this.markers[0].position.lat) {
          /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          if (navigator.geolocation) if (await this.mapLoader) this.$refs.googleMap.getGoogleCurrent()
          else this.mapErrorAlert = this.$t('form.geolocation_is_not_supported')
        }
      }
      return this.mapLoader
    },
    updateAddress (address) {
      this.custodyData.questions.location.address = address
    },
    removePacGoogleMapContainer (changedTabsEvent) {
      const
        googleMapTab = document.getElementById('GeneralData3'),
        is_inactiveTab = changedTabsEvent ? true : googleMapTab.attributes.getNamedItem('aria-hidden'),
        unWantedContainer = document.querySelector('.pac-container'),
        isunWantedContainerExist = Boolean(unWantedContainer)
      if (is_inactiveTab && isunWantedContainerExist) unWantedContainer.remove()
    },

    ///////////////////////
    // Submit
    ///////////////////////
    onChangeTabs () {
      this.removePacGoogleMapContainer(true)
    },
    validateStep (stepNum) {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll(`step-${stepNum}`)
          .then(result => {
            if (result) resolve(true)
            else {
              this.errMsg()
              reject('correct all values')
            }
          })
      })
    },
    validateStep1 () {
      return this.validateStep(1)
    },
    validateStep2 () {
      return this.validateStep(2)
    },
    validateStep3 () {
      return this.validateStep(3)
      // return true
    },
    validateStep4 () {
      return this.validateStep(4)
    },
    submit () {
      this.$validator.validateAll()
        .then(valid => {
          if (valid)  this.saveParentStudentInfo()
          else    this.errMsg()
        })
    }

  },
  updated () {
    this.removePacGoogleMapContainer()
  },

  created () {
    this.emergencyObj.user_id = this.authUserId
    this.getParentChildren()
    this.getMaritalStatus()
    this.getGenders()
    this.getCustodyOwners()
    this.getMedicalChildSuffers()

    // this.getNationalities()
    this.getReligions()
    this.getCountries()

    this.getTelKeys()
    this.getMobileKeys()
    this.getLanguages()
    this.getRelationships()

  }
}
</script>
<style lang="scss" >
.vs-collapse-item {
  &.open-item {
    .edit_icon {
      display: none;
    }
    .vs-collapse-item--header {
      border-bottom: 1px solid #ddd;
    }
  }
  &#{--content} {
    overflow-y: auto;
  }
}
</style>
