<template>
  <ListPageWithCreateButton
    createRoutName="create-Subjects"
    :showCreateBtn="isSND"
    :createBtnName="$t('subject.SubjectsCreate')">
    <template slot="listDataContent">
      <data-table
        :csv_url="'/subject/export'"
        :pdf_url="'subjects-file/pdf'"
        :headers_prop="headers"
        list_url_prop="/subjects/filters"
        :side_filters="sideFilters"
        :allow_print_pdf="false"
    /></template>
    <template slot="rightSide" v-if="showFilter">
      <FilterBar @reloadData="reloadData" />
    </template>
  </ListPageWithCreateButton>
</template>

<script>
import DataTable from '@/components/general/datatable/DataTable2.vue'
import FilterBar from './Components/FilterBar'
export default {
  name: 'SubjectsList',
  components: {DataTable, FilterBar},
  data() {
    return {
      headers: [
        {
          headerName: this.$t('basic.ID'),

          field: 'id',
          checkboxSelection: true,
          width: 200,
          minWidth: 160,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true
        },
        {
          headerName: this.$t('subject.name'),
          field: 'name',
          minWidth: 160
        },
        {
          headerName: this.$t('subject.division'),
          field: 'division',
          minWidth: 160
        },
        {
          headerName: this.$t('subject.in_use'),
          field: 'online',
          minWidth: 160
        },
        // { headerName: this.$t('RoundManager.CreatedBy'), field: 'owner.name', width:290 },
        // { headerName: this.$t('RoundManager.Contact'), field: 'contacts.email.0.email', width:390 },
        {
          headerName: this.$t('Syllabus.status'),
          field: 'status',
          width: 240,
          minWidth: 160,
          cellRendererFramework: 'CellRendererStatus',
          cellRendererParams: {
            status_prop: this.status
          }
        },
        {
          headerName: this.$t('subject.Actions'),
          field: 'transactions',
          width: 240,
          minWidth: 160,
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            actions_prop: [
              // {title: 'Report', route_name:'update-round-manager', parameter_name:"id", icon:'Edit3Icon', navigate_action:false},
              // {title: 'RevokeAccess', route_name:'show-round-manager', parameter_name:"id", icon: 'Edit3Icon', navigate_action:false},
              {
                title: this.$t('main.Edit'),
                route_name: 'edit-Subjects',
                parameter_name: 'id',
                icon: 'Edit3Icon',
                navigate_action: true,
                showIf: () => this.checkUserRole('SND')
              },

              {
                title: this.$t('basic.show'),
                route_name: 'show-Subjects',
                parameter_name: 'id',
                icon: 'EyeIcon',
                navigate_action: true
              },

              {
                showIf: () => this.checkUserRole('SND'),
                dropDown: true,
                dropDown_items: [
                  {
                    title: (data) =>
                      `${
                        data.type === 'strands'
                          ? this.$t('basic.update')
                          : this.$t('basic.add')
                      } ${this.$t('AcademicYears.strands')}`,
                    route_name: 'add_subject_strands',
                    parameter_name: 'id',
                    query_name: 'subject_id',
                    icon: 'PlusIcon',
                    navigate_action: true
                  },
                  {
                    title: this.$t('status.active'),
                    action: 'Active',
                    parameter_name: 'id',
                    icon: 'CheckSquareIcon',
                    resource: ':id/status',
                    showIf: (data) => data.status !== 'active'
                  },
                  {
                    title: this.$t('basic.suspend'),
                    parameter_name: 'id',
                    icon: 'PowerIcon',
                    action: 'Suspended',
                    resource: ':id/status',
                    showIf: (data) => data.status !== 'Suspended'
                  },
                  {
                    title: this.$t('basic.archive'),
                    parameter_name: 'id',
                    icon: 'ArchiveIcon',
                    action: 'Archived',
                    resource: ':id/status',
                    showIf: (data) => data.status !== 'Archived'
                  },
                  {
                    title: this.$t('status.close'),
                    parameter_name: 'id',
                    icon: 'SlashIcon',
                    action: 'Closed',
                    resource: ':id/status',
                    showIf: (data) => data.status !== 'Closed'
                  }
                ]
              }
              // {title: 'Deactivate',action: "deactivate", resource:"round", parameter_name:"id", icon: 'SlashIcon', navigate_action:false}
            ]
          }
        }
      ],

      export_file: false,
      /////////////////////////
      // Filters
      //////////////////////////
      sideFilters: {}
    }
  },
  computed: {
    showFilter() {
      return this.isSND
    },
    isSND() {
      return this.checkUserRole('SND')
    }
  },
  methods: {
    ////////////////////
    //Filter
    /////////////////////
    reloadData(v) {
      this.sideFilters = v
    }
  }
}
</script>

<style scoped lang="scss">
@media print {
  body * {
    border: 1px solid red;
  }
}
</style>
