import ListRound from './subModules/snd/pages/roundManager/list.vue'

import sndRoutes from './subModules/snd/snd.routes'
import smdRoutes from './subModules/smd/smd.routes'
import teacherRoutes from './subModules/teacher/teacher.routes'
import schoolPrincipalRoutes from './subModules/schoolPrincipal/schoolPrincipal.routes'
import boundariesAndDescriptors from './subModules/boundariesAndDescriptors/boundariesAndDescriptors.routes'

export default [
  // SubModules Routes
  ...smdRoutes,
  ...sndRoutes,
  ...teacherRoutes,
  ...schoolPrincipalRoutes,
  ...boundariesAndDescriptors,
  // global routes
  {
    path: '',
    component: ListRound

    // redirect: 'gradingCreateRound'
  },
  {
    path: 'updateStudentCard/:studentId/:classId/:subjectId/:divisionId/:sectionId/:gradeId/:userId',
    component: () =>
      import('./subModules/snd/pages/updateStudentCard/updateStudentCard.vue'),
    name: 'updateStudentCard',

    meta: {
      breadcrumb: [
        {title: 'home', url: ''}
        // {title: 'grading.gradebook', url: '/grading/gradebook'},
        // {title: 'grading.update_student_card', url: '', active: true}
      ],
      rule: 'SNDTeacher',
      pageTitle: 'grading.update_student_card',
    }
  },
  {
    path: 'reportCard/:studentId/:gradingRoundId',
    name: 'GradingReportCard',
    component: () => import('./subModules/snd/pages/reportCard/ReportCard.vue'),

    meta: {
      breadcrumb: [{title: 'home', url: ''}],
      rule: 'SNDTeacher',
      pageTitle: 'grading.print_report_card'
    }
  }
]
