<template>
  <vs-prompt
    @cancel="cancle"
    @accept="submit"
    :active="showDialog"
    :title="$t('basic.duplicate')"
    :is-valid="isValid"
    type="confirm">
    <!-- Loader -->
    <div
      v-show="loading"
      ref="loading"
      id="loading"
      class="vs-con-loading__container"></div>
    <div class="con-exemple-prompt">
      <div class="my-2 vx-col w-full mb-2">
        <form>
          <div class="w-full">
            <label
              >{{ $t('form.choose_academic_year') }}
              <sup class="text-danger">*</sup>
            </label>

            <v-select
              label="name"
              :reduce="(d) => d._id"
              v-model="selectedAcademicYear"
              v-validate="'required'"
              class="w-full"
              name="academic_year"
              :options="academicYears"
              :disabled="!academicYears.length"
              :showLoadingIcon="loadingAllAcademicYear" />
            <span class="text-danger text-sm">{{
              errors.first('academic_year') | text_formatter
            }}</span>
          </div>
          <!-- Actions -->
          <!--          <div class="w-full mt-10 flex justify-between items-center">-->
          <!--            <vs-button-->
          <!--              class="mb-5"-->
          <!--              type="border"-->
          <!--              color="warning"-->
          <!--              @click=""-->
          <!--              >{{ $t('basic.cancel') }}</vs-button-->
          <!--            >-->

          <!--            <vs-button @click="submit()" class="mb-5" :disabled="valid">{{-->
          <!--              $t('basic.submit')-->
          <!--            }}</vs-button>-->
          <!--          </div>-->
        </form>
      </div>
    </div>
  </vs-prompt>
</template>

<script>
import {commonDataMixin} from '@/mixins'

export default {
  name: 'duplicate-form',
  props: {
    showDialog: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  mixins: [commonDataMixin],
  data: () => {
    return {
      /////////////////////////
      // Loading
      /////////////////////////
      loadingAllAcademicYear: true,
      /////////////////////////
      // selected Data
      ////////////////////////
      selectedAcademicYear: null,
      ////////////////////////
      // Data
      ///////////////////////
      academicYears: []
    }
  },
  computed: {
    isValid() {
      return !this.errors.any() && this.selectedAcademicYear
    }
  },
  watch: {
    loading(v) {
      if (v) {
        this.VsLoadingContained(this.$refs.loading)
      } else {
        this.stopVsLoadingContained(this.$refs.loading)
      }
    }
  },
  methods: {
    //////////////////////
    // submit
    //////////////////////

    resetData() {
      this.selectedAcademicYear = null
    },
    cancle() {
      this.resetData()
      this.$emit('cancel')
    },
    submit() {
      this.$validator
        .validateAll()
        .then((valid) => {
          if (valid) {
            this.$emit('submit', {
              selectedAcademicYear: this.selectedAcademicYear
            })
          } else this.errMsg()
        })
        .catch(this.errMsg)
    }
  },
  created() {
    this.getAllAcademic()
      .then((res) => {
        this.academicYears = res // res.filter((e) => e.status !== 'Closed')
      })
      .finally(() => {
        this.loadingAllAcademicYear = false
      })
  }
}
</script>

<style scoped></style>
