export default {
  new: 'new',
  shortlisted: 'shortlisted',
  on_assessment: 'on assessment',
  waiting_list: 'waiting list',
  disqualified: 'disqualified',
  qualified: 'qualified',
  active: 'Active',
  suspended: 'Suspended',
  archived: 'Archived',
  archive: 'Archive',
  close: 'close',
  closed: 'Closed',
  future_candidates: 'Future Candidates',
  declined: 'Declined',
  withdrawn: 'Withdrawn',
  enrolled: 'Enrolled',
  eligible: 'Eligible',
  not_eligible: 'Not Eligible',
  rejected: 'Rejected',
  activate: 'Activate',
  deactivate: 'Deactivate',
  available: 'Available',
  suspend: 'Suspend',
  banned: 'Banned',
  released: 'Released',
  ban:'Ban',
  release :'Release',
  upcoming: 'upcoming',
  ongoing: 'ongoing',
  all_qualified: 'All qualified',
  all_declined: 'All Declined',
  all_withdrawn: 'All Withdrawn',
  all_enrolled: 'All Enrolled',
  all_archived: 'All Archived',
  all_new: 'All New',
  all_registered: 'All Registered',
  all_on_assessment: 'All On Assessment',
  all_waiting_list: 'All Waiting List',
  all_accepted: 'All Accepted',
  all_disqualified: 'All Disqualified',
  all_rejected: 'All Rejected',
  all_shortlisted: 'All Shortlisted',
  expired: 'expired',
  expired_date: 'Expired Date',
  you_are_about_to_ban_the_selected:'You are about to ban the selected',
  you_are_about_to_release_the_selected:'You are about to release the selected'

}
