<template>
  <div :style="{direction: $vs.rtl ? 'rtl' : 'ltr'}">
    <vs-prompt
      @cancel=""
      :active.sync="requestQualified"
      title="Enroll"
      :buttons-hidden="true">
      <qualified-form @formSubmited="changeToEnrolled"></qualified-form>
    </vs-prompt>
    <feather-icon
      icon="EyeIcon"
      title="view"
      :svgClasses="[
        'h-5 w-5 mr-4 hover:text-primary cursor-pointer',
        this.params.data['viewed'] ? 'text-success' : ''
      ]"
      @click="view('view')" />
    <feather-icon
      icon="BookOpenIcon"
      title="pdf"
      svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
      @click="ApplicationPDF()" />
    <feather-icon
      icon="BookmarkIcon"
      :title="$t('admission.recommend')"
      svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
      @click="openRecommendStudentPopup()" />
    <feather-icon
      icon="ShuffleIcon"
      :title="$t('admission.change_division')"
      svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
      @click="makeAction(actionEmitter)" />
    <vs-dropdown
      vs-trigger-click
      class="cursor-pointer"
      icon="MoreVerticalIcon"
      title="edit"
      svgClasses="h-5 w-6 mr-4 hover:text-primary cursor-pointer"
      v-if="!this.checkUserRole('SMD')">
      <feather-icon
        title="edit"
        icon="MoreVerticalIcon"
        svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"></feather-icon>

      <vs-dropdown-menu>
        <vs-dropdown-item @click="changeAppStatus('edit', 0)" v-if="start_over">
          <span class="flex items-center">
            <feather-icon
              title="archive"
              icon="ArchiveIcon"
              svgClasses="h-4 w-4"
              class="mr-2" />
            <span>Start Over</span>
          </span>
        </vs-dropdown-item>

        <vs-dropdown-item @click="changeAppStatus('edit', 1)" v-if="shortlist">
          <span class="flex items-center">
            <feather-icon
              title="archive"
              icon="ArchiveIcon"
              svgClasses="h-4 w-4"
              class="mr-2" />
            <span>Shortlist</span>
          </span>
        </vs-dropdown-item>
        <vs-dropdown-item
          @click="changeAppStatus('edit', -1)"
          v-if="disqualify">
          <span class="flex items-center">
            <feather-icon
              title="archive"
              icon="ArchiveIcon"
              svgClasses="h-4 w-4"
              class="mr-2" />
            <span>Disqualify</span>
          </span>
        </vs-dropdown-item>
        <vs-dropdown-item
          @click="changeAppStatus('edit', 2)"
          v-if="to_reassess">
          <span class="flex items-center">
            <feather-icon
              title="archive"
              icon="ArchiveIcon"
              svgClasses="h-4 w-4"
              class="mr-2" />
            <span>To Reassess</span>
          </span>
        </vs-dropdown-item>
        <vs-dropdown-item @click="changeAppStatus('edit', 2)" v-if="to_assess">
          <span class="flex items-center">
            <feather-icon
              title="archive"
              icon="ArchiveIcon"
              svgClasses="h-4 w-4"
              class="mr-2" />
            <span>To assess</span>
          </span>
        </vs-dropdown-item>
        <vs-dropdown-item @click="changeAppStatus('edit', 4)" v-if="qualify">
          <span class="flex items-center">
            <feather-icon
              icon="ArchiveIcon"
              svgClasses="h-4 w-4"
              class="mr-2" />
            <span>Qualify</span>
          </span>
        </vs-dropdown-item>
        <vs-dropdown-item @click="changeAppStatus('edit', 4)" v-if="reQualify">
          <span class="flex items-center">
            <feather-icon
              icon="ArchiveIcon"
              svgClasses="h-4 w-4"
              class="mr-2" />
            <span>Re-qualify</span>
          </span>
        </vs-dropdown-item>
        <vs-dropdown-item
          @click="changeAppStatus('edit', 3)"
          v-if="waitingList">
          <span class="flex items-center">
            <feather-icon
              icon="ArchiveIcon"
              svgClasses="h-4 w-4"
              class="mr-2" />
            <span>WaitingList</span>
          </span>
        </vs-dropdown-item>
        <vs-dropdown-item @click="changeAppStatus('edit', -2)" v-if="reject">
          <span class="flex items-center">
            <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
            <span>Reject</span>
          </span>
        </vs-dropdown-item>
        <vs-dropdown-item @click="changeAppStatus('edit', -3)" v-if="decline">
          <span class="flex items-center">
            <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
            <span>Decline </span>
          </span>
        </vs-dropdown-item>
        <vs-dropdown-item
          @click="navigateToEvaluationCard"
          v-if="evaluationCard">
          <span class="flex items-center">
            <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
            <span>Evaluation Card</span>
          </span>
        </vs-dropdown-item>

        <vs-dropdown-item @click="makeScheduleMeeting" v-if="scheduleMeeting">
          <span class="flex items-center">
            <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
            <span>schedule Meeting</span>
          </span>
        </vs-dropdown-item>
        <vs-dropdown-item @click="changeAppStatus('edit', 7)" v-if="withdraw">
          <span class="flex items-center">
            <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
            <span>withdraw</span>
          </span>
        </vs-dropdown-item>
        <!--        <vs-dropdown-item v-if="scheduleMeeting">-->
        <!--          <span class="flex items-center">-->
        <!--            <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />-->
        <!--            <span>schedule meeting</span>-->
        <!--          </span>-->
        <!--        </vs-dropdown-item>-->
        <vs-dropdown-item @click="enroll()" v-if="enabledEnroll">
          <span class="flex items-center">
            <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
            <span>Enroll</span>
          </span>
        </vs-dropdown-item>

        <vs-dropdown-item
          @click="deleteApp('delete')"
          v-if="this.params.data && this.params.data.status == 'rejected'">
          <span class="flex items-center">
            <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
            <span>Archive/Undo </span>
          </span>
        </vs-dropdown-item>
      </vs-dropdown-menu>
    </vs-dropdown>

    <!-- Application Data POPUP  -->
    <vs-popup
      class="popup"
      fullscreen
      title="Application Data"
      :active.sync="popupActive">
      <div class="flex flex-wrap mb-4">
        <!-- <span class="flex items-center">
          <feather-icon
            icon="ArrowLeftIcon"
            svgClasses="h-6"
            class="mr-2"
            id="prev"
          />
          <span></span>
        </span> -->

        <div class="md:w-4/5 w-full">
          <div class="flex flex-wrap">
            <div class="md:w-1/6 w-full text-center">
              <vs-avatar
                class="user-profile-img text-center"
                :src="this.selected_data.student_image"
                size="85px" />
            </div>
            <div class="md:w-2/3 w-full">
              <!-- Student Info  -->
              <div class="mb-6">
                <div>
                  <p>
                    <strong>Name :</strong>
                    {{
                      selected_data.student.first_name +
                      ' ' +
                      selected_data.student.middle_name +
                      ' ' +
                      selected_data.student.last_name
                    }}
                  </p>

                  <p>
                    <strong>Age :</strong>
                    {{
                      new Date().getFullYear() -
                      new Date(Number(selected_data.birth_date)).getFullYear()
                    }}
                    Years
                  </p>
                  <p>
                    <strong>National ID :</strong>
                    {{ selected_data.student.national_id }}
                  </p>

                  <p>
                    <strong>Division :</strong>
                    {{ selected_data.divisionName }} Division
                    {{ selected_data.gradeName }}
                  </p>

                  <p>
                    <strong>Address :</strong>
                    {{ selected_data.student.district }}
                    {{ selected_data.student.street }}
                  </p>

                  <p>
                    <strong>Official Email :</strong>
                    {{ selected_data.student.official_email }}
                  </p>
                  <p v-if="selected_data.student.mobile">
                    <strong>Mobile :</strong>
                    {{
                      `${selected_data.student.mobile.code}${selected_data.student.mobile.number}`
                    }}
                  </p>
                  <p v-if="selected_data.student.telephone">
                    <strong>Telephone :</strong>
                    {{
                      `${selected_data.student.telephone.code}${selected_data.student.telephone.number}`
                    }}
                  </p>
                </div>

              </div>
              <!-- Fmaily Data -->
              <div class="mb-6">
                <div class="flex">
                  <div class="md:w-1/2 w-full">
                    <p>
                      <strong> Father Work : </strong>
                      {{ selected_data.father.occupation }}
                    </p>
                  </div>
                  <div class="md:w-1/2 w-full">
                    <p>
                      <strong> Mother Work : </strong>
                      {{ selected_data.mother.occupation }}
                    </p>
                  </div>
                </div>
                <div class="flex">
                  <div class="md:w-1/2 w-full">
                    <p>
                      <strong> Father univeristy : </strong>
                      {{ selected_data.father.univeristy }}
                    </p>
                  </div>
                  <div class="md:w-1/2 w-full">
                    <p>
                      <strong> Mother Study : </strong>
                      {{ selected_data.mother.univeristy }}
                    </p>
                  </div>
                </div>
                <div class="flex">
                  <div class="w-full">
                    <p>
                      <strong> Marital Status : </strong>
                      {{ selected_data.marital_status }}
                    </p>
                  </div>
                </div>
                <div class="fle">
                  <div class="w-full">
                    <p>
                      <strong> BUS : </strong>
                      {{
                        selected_data.general
                          ? selected_data.general.bus_transportation === 1
                            ? 'yes'
                            : 'NO'
                          : 'NO'
                      }}
                    </p>
                  </div>
                </div>
              </div>

              <!-- <vx-card class="w-2/3">
                  <div>
                    <div class="flex">
                      <div class="w-full">
                        <h6 style="display: inline;">
                          What is your name ? :

                          <p >
                            {{ selected_data.father.occupation }}
                          </p>
                        </h6>
                      </div>
                    </div>
                    <div class="flex">
                      <div class="w-full">
                        <h6 style="display: inline;">
                          Please put links for some of your projects and short
                          description for each? :

                          <p >
                            {{ selected_data.father.education_univercity }}
                          </p>
                        </h6>
                      </div>
                    </div>
                    <div class="flex">
                      <div class="w-full">
                        <h6 style="display: inline;">
                          What makes you the ideal candidate for this position?
                          :

                          <p >
                            {{ selected_data.marital_status }}
                          </p>
                        </h6>
                      </div>
                    </div>
                    <div class="fle">
                      <div class="w-full">
                        <h6 style="display: inline;">
                          BUS :

                          <p >
                            {{
                              selected_data.general
                                ? selected_data.general
                                    .bus_transportation === 1
                                  ? "yes"
                                  : "NO"
                                : "NO"
                            }}
                          </p>
                        </h6>
                      </div>
                    </div>
                  </div>
                </vx-card>-->
            </div>
          </div>
        </div>
        <!-- status Buttons  -->
        <div
          class="md:w-1/6 w-full flex md:flex-col md:justify-start justify-around flex-wrap">
          <div class="d-none" style="display:flex; justify-content: end;">
             <!----------------- Start Status  -------------->
                <template v-if="selected_data.status">
                  <vs-chip
                    id="absoult-popup-status"
                    :class="`status-position status--${
                      selected_data.status.includes(' ')
                        ? selected_data.status
                            .toLowerCase()
                            .replaceAll(' ', '_')
                        : selected_data.status.toLowerCase()
                    }`"
                    >{{ selected_data.status }}
                  </vs-chip>
                </template>
                <!----------------- END Status  -------------->
          </div>
          <vs-button
            class="mb-5 w-auto"
            color="#0949d3"
            @click="changeAppStatus('edit', 0)"
            v-if="start_over"
            >Start Over</vs-button
          >

          <vs-button
            v-if="shortlist"
            class="mb-5"
            color="dark"
            @click="changeAppStatus('edit', 1)"
            >Shortlist</vs-button
          >
          <vs-button
            v-if="waitingList"
            class="mb-5"
            color="warning"
            @click="changeAppStatus('edit', 3)"
            >waitingList</vs-button
          >
          <vs-button
            v-if="to_assess"
            class="mb-5"
            color="#c505c5"
            @click="changeAppStatus('edit', 2)"
            >To assess</vs-button
          >
          <vs-button
            v-if="to_reassess"
            class="mb-5"
            color="#c505c5"
            type="border"
            @click="changeAppStatus('edit', 2)"
            >To Reassess</vs-button
          >

          <vs-button
            v-if="qualify"
            class="mb-5"
            color="success"
            @click="changeAppStatus('edit', 4)"
            >Qualify</vs-button
          >

          <vs-button
            v-if="reQualify"
            class="mb-5"
            color="success"
            type="border"
            @click="changeAppStatus('edit', 4)"
            >Re-qualify</vs-button
          >

          <vs-button
            v-if="disqualify"
            class="mb-5"
            color="danger"
            type="border"
            @click="changeAppStatus('edit', -1)"
            >Disqualify</vs-button
          >

          <vs-button
            v-if="reject"
            class="mb-5"
            color="danger"
            @click="changeAppStatus('edit', -2)"
            >Reject</vs-button
          >

          <vs-button
            v-if="decline"
            class="mb-5"
            type="border"
            color="danger"
            @click="changeAppStatus('edit', -3)"
            >Decline</vs-button
          >

          <vs-button
            v-if="evaluationCard"
            class="mb-5"
            color="rgb(134, 4, 98)"
            @click="navigateToEvaluationCard"
            >Evaluation Card</vs-button
          >
          <vs-button
            v-if="scheduleMeeting"
            class="mb-5"
            color="rgb(134, 4, 98)"
            @click="makeScheduleMeeting"
            >schedule Meeting</vs-button
          >

          <vs-button
            v-if="withdraw"
            class="mb-5"
            color="warning"
            @click="changeAppStatus('edit', 7)"
            >withdraw
          </vs-button>

          <!-- <vs-button v-if="scheduleMeeting" class="mb-5" color="primary" @click="changeAppStatus('edit', -3)"
                  >schedule meeting</vs-button
                >-->

          <vs-button
            v-if="enabledEnroll"
            class="mb-5"
            color="success"
            @click="enroll()"
            >Enroll</vs-button
          >

          <vs-button
            v-if="archive"
            class="mb-5"
            color="gray"
            @click="deleteApp('delete')"
            >Archive/Undo</vs-button
          >
        </div>
        <!-- <span class="flex items-center">
          <feather-icon
            icon="ArrowRightIcon"
            svgClasses="h-6 "
            class="mr-2"
            id="next"
          />
          <span></span>
        </span> -->
      </div>
    </vs-popup>
    <vs-popup
      class="popup"
      :title="$t('admission.recommend_student')"
      :active.sync="recommendStudentPopupActive">
      <form @submit.prevent="submitRecommendByForm" ref="recommend_form">
        <div class="vx-row">
          <div class="vx-col mb-4 lg:w-1/2 w-full">
            <div class="vs-input--label">
              {{ $t('admission.recommended_by') }}
              <sup class="text-danger">*</sup>
            </div>
            <vs-input
              v-model="recommendBy"
              name="recommended_by"
              v-validate="'required'"
              class="w-full" />
            <span
              v-show="errors.has('recommended_by')"
              class="text-danger text-sm">
              {{ errors.first('recommended_by') | text_formatter }}
            </span>
          </div>
          <div class="vx-col w-full flex justify-end">
            <vs-button @click="submitRecommendByForm" :disabled="issubmitted">
              {{ $t('basic.submit') }}
            </vs-button>
          </div>
        </div>
      </form>
    </vs-popup>
  </div>
</template>

<script>
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
import applicationRepository from '../Repositories/applicationRepository'
import qualifiedForm from '../pages/registeration/components/qualifiedForm'

const EvaluationRepository = RepositoryFactory.get(
  'AdmissionModule',
  'evaluations'
)
const DataTableRepository = RepositoryFactory.get('General', 'dataTable')
const ApplicationRepository = RepositoryFactory.get(
  'AdmissionModule',
  'applications'
)
import axios from 'axios'
export default {
  name: 'CellRendererActions',
  components: {
    qualifiedForm
  },
  data() {
    return {
      requestQualified: false,
      student: {
        name: 'name',
        email: 'email',
        password: 'password'
      },
      //////////////////////////////////
      //////// Form Submitted
      //////////////////////////////////
      issubmitted: false,
      //////////////////////////////////
      //////// Recommend Student
      //////////////////////////////////
      recommendStudentPopupActive: false,
      recommendBy: '',

      //////////////////////////////////
      //////// Action Emitter
      //////////////////////////////////
      actionEmitter: {
        actionEmitter: 'activeChangeDivisionPopup',
        actionEmitterParams: ['id', 'student']
      },
      actionEmitterReset: {
        actionEmitter: 'resetAction',
        actionEmitterParams: ['id']
      },

      // Application Data Popup
      popupActive: false,
      selected_data: {
        birth_date: '',
        student: {
          name: {
            first_name: '',
            middle: '',
            last: ''
          },

          email: '',
          birth_date: '',
          gender: 'male',
          religion: '',

          //   nationalities
          nationalities: [
            {
              id: 0,
              value: 'egyptian'
            }
          ],

          //   Address
          address: {
            city: '',
            country: 'egypt',
            district: '',
            street: ''
          },
          mobile: {
            key: '010',
            num: ''
          },
          tel: [
            {
              id: 0,
              key: '02',
              num: ''
            }
          ],
          official_email: '',

          //   School data
          id_num: '',
          school: '',
          division: '',
          grade: '',
          school_year: '',
          school_month_start: ''
        },
        // Student_info
        student_info: {
          first_join: 'first_join_true',
          schools: [
            {
              id: 0,
              name: '',
              division: '',
              grade: '',
              year: '',
              month_start: '',
              leaving_reason: ''
            }
          ],
          languages: {
            mother: '',
            common: '',
            first_foregin: ''
          },
          child_exempted: 'child_exempted_true',
          child_skipped: {
            do: 'child_skipped_false',
            grade: ''
          },
          child_repeat: {
            do: 'child_repeat_false',
            grade: ''
          },
          support_services: {
            do: 'support_services_false',
            details: ''
          },
          applying_reason: ''
        },
        //Father
        father: {
          name: {
            first: '',
            middle: '',
            last: ''
          },

          email: '',
          birth_date: '',
          religion: '',
          id_num: '',
          //   nationalities
          nationalities: [
            {
              id: 0,
              value: 'egyptian'
            }
          ],

          //   Address
          sameAddress: true,
          address: {
            city: '',
            country: 'egypt',
            district: '',
            street: ''
          },
          mobile: {
            key: '010',
            num: ''
          },
          tel: [
            {
              id: 0,
              key: '02',
              num: ''
            }
          ],
          sameOfficalEmail: true,
          official_email: '',

          school: '',
          university: '',
          occupation: '',
          employer: '',
          business_type: '',
          work: {
            address: '',
            email: '',
            mobile: {
              key: '010',
              num: ''
            },
            tel: [
              {
                id: 0,
                key: '02',
                num: ''
              }
            ],
            fax: [
              {
                id: 0,
                key: '02',
                num: ''
              }
            ]
          },
          school_alumni: 'school_alumni_true'
        },
        //mother
        mother: {
          name: {
            first: '',
            middle: '',
            last: ''
          },

          email: '',
          birth_date: '',
          religion: '',
          id_num: '',

          //   nationalities
          nationalities: [
            {
              id: 0,
              value: 'egyptian'
            }
          ],

          //   Address
          sameAddress: true,
          address: {
            city: '',
            country: 'egypt',
            district: '',
            street: ''
          },
          mobile: {
            key: '010',
            num: ''
          },
          tel: [
            {
              id: 0,
              key: '02',
              num: ''
            }
          ],
          sameOfficalEmail: true,
          official_email: '',
          school: '',
          university: '',
          occupation: '',
          employer: '',
          business_type: '',
          work: {
            address: '',
            email: '',
            mobile: {
              key: '010',
              num: ''
            },
            tel: [
              {
                id: 0,
                key: '02',
                num: ''
              }
            ],
            fax: [
              {
                id: 0,
                key: '02',
                num: ''
              }
            ]
          },
          school_alumni: 'school_alumni_true'
        },
        // Siblings
        sibling_data: {
          have: 'siblings_have_true',
          siblings: [
            {
              id: 0,
              name: {
                first: '',
                middle: '',
                last: ''
              },

              email: '',
              sameOfficalEmail: true,
              birth_date: '',
              gender: 'male',
              religion: '',

              //   nationalities
              nationality: 'egyptian',

              //   Address
              sameAddress: true,
              address: {
                city: '',
                country: 'egypt',
                district: '',
                street: ''
              },
              mobile: {
                key: '010',
                num: ''
              },
              tel: [
                {
                  id: 0,
                  key: '02',
                  num: ''
                }
              ],
              //   School data
              at_school: 'at_school_true',
              school_data: {
                have_id: 'have_id_true',
                id_num: '',
                name: {
                  first: '',
                  last: '',
                  middle: ''
                },
                division: '',
                grade: '',
                school_year: ''
              }
            }
          ]
        },
        // Martial Data
        marital_data: {
          status: 'married_couple', //.. married_couple || divorced || widowed_father || widowed_mother
          custody: {
            who: 'others', //.. custody_widowed_father || custody_widowed_mother || custody_divorced_mother || custody_divorced_father || others
            others: {
              name: {
                first: '',
                middle: '',
                last: ''
              },

              email: '',
              sameOfficalEmail: true,
              birth_date: '',
              gender: 'male',
              religion: '',

              //   nationality
              nationality: '',

              //   Address
              sameAddress: true,
              address: {
                city: '',
                country: 'egypt',
                district: '',
                street: ''
              },
              mobile: {
                key: '010',
                num: ''
              },
              tel: [
                {
                  id: 0,
                  key: '02',
                  num: ''
                }
              ],
              relationship: ''
            }
          }
        },
        general: {
          bus_transportation: ''
        }
      }
    }
  },
  computed: {
    start_over() {
      return (
        !this.params.data.studentID &&
        this.params.data['status'] !== 'new' &&
        this.params.data['status'] !== 'qualified'
      )
      // return this.params.data['status'] === 'new'
    },
    shortlist() {
      return this.params.data['status'] === 'new'
    },
    reject() {
      return (
        this.params.data['status'] === 'new' ||
        this.params.data['status'] === 'shortlisted'
      )
    },
    archive() {
      return (
        !this.params.data.studentID && this.params.data['status'] !== 'archived'
      )
    },
    to_assess() {
      return this.params.data['status'] === 'shortlisted'
    },
    to_reassess() {
      return (
        this.params.data['status'].includes('waiting') ||
        this.params.data['status'] === 'disqualified'
      )
    },
    disqualify() {
      // return this.params.data['status'].includes('waiting') || this.params.data['status'].includes('Assessment')
      return (
        this.params.data['status'] !== 'disqualified' &&
        this.params.data['status'] !== 'new'
      )
    },
    waitingList() {
      return (
        !this.params.data['status'].includes('waiting') &&
        this.params.data['status'] !== 'qualified'
      )
    },
    qualify() {
      // return this.params.data['status'].includes('Assessment') || this.params.data['status'].includes('waiting')
      return this.params.data['status'] !== 'qualified'
    },
    evaluationCard() {
      // return this.params.data['status'].includes('Assessment') || this.params.data['status'].includes('waiting')
      return false
    },
    enabledEnroll() {
      return (
        this.params.data['status'] === 'qualified' &&
        this.params.colDef.cellRendererParams.enable_enroll
      )
    },
    withdraw() {
      return (
        this.params.data['status'] === 'qualified' &&
        this.params.colDef.cellRendererParams.enable_enroll
      )
    },
    scheduleMeeting() {
      return (
        this.params.data['status'].includes('Assessment') ||
        this.params.data['status'].includes('waiting')
      )
    },
    reQualify() {
      return (
        this.params.data['status'] === 'declined' ||
        this.params.data['status'] === 'withdrawn'
      )
    },
    decline() {
      return (
        (this.params.data['status'] == 'qualified' ||
          this.params.data['status'] == 'withdrawn') &&
        this.params.colDef.cellRendererParams.enable_enroll
      )
    }
  },
  methods: {
    makeAction(action) {
      // if (navigate_action)
      //   this.$router.push({ name: action.route_name, params: { id: this.params.data.parameter_name } })
      // else
      // If emitting action
      if (action.actionEmitter) {
        const emittingData = {}
        if (action.actionEmitterParams) {
          const data = {...this.params.data},
            emittingParams = action.actionEmitterParams

          emittingParams.forEach((e) => {
            if (data[e]) emittingData[e] = data[e]
          })
        }
        // console.log(emittingData)

        this.$root.$emit(action.actionEmitter, emittingData)
      } else this.confirmMakeAction(action)
    },
    confirmMakeAction(action) {
      const id = this.params.data['id']
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `${this.$t('basic.confirm')}  ${action.action}`,
        text: ` ${this.$t('basic.you_are_about_to')} ${action.action} "${id}"`,
        accept: this.makeActionRequest(action, id),
        acceptText: `${action.action}`
      })
    },
    makeActionRequest(action, id) {
      const url = `application/${action}/${id}`
      /* Below two lines are just for demo purpose */

      /* UnComment below lines for enabling true flow if deleting user */
      DataTableRepository.makeActionRequest(url)
        .then(() => {
          this.showActionSuccess(action)
          this.$root.$emit('row:status')
        })
        .catch(this.errMsg)
      this.popupActive = false
    },

    navigateToEvaluationCard() {
      this.popupActive = false
      const applicationId = this.params.data['id']
      EvaluationRepository.getEvaluation(applicationId).then(
        (res) => {
          if (res.data.is_evaluated == true) {
            this.$router.push({
              name: 'edit-evaluation',
              params: {id: applicationId}
            })
          } else {
            this.$router.push({
              name: 'create-evaluation',
              params: {id: applicationId}
            })
          }
        },
        (error) => {
          this.$router.push({
            name: 'create-evaluation',
            params: {id: applicationId}
          })
        }
      )
    },
    changeAppStatus(action, status) {
      const col = this.params.data,
        id = col.id,
        col_status = col.status
      if (col_status !== 'Enrolled') {
        ApplicationRepository.changeStatus(id, status)
          .then(() => {
            this.showActionSuccess(action)
            this.$root.$emit('row:status')
            this.popupActive = false
          })
          .catch(this.errMsg)
      } else {
        this.errMsg(this.$t('admission.enrolled_change_error'))
      }
    },
    ApplicationPDF() {
      const path_url = axios.defaults.baseURL
      const id = this.params.data['id']
      const url = `ApplicationPDF/${id}`
      window.location.href = `${path_url}${url}`
    },
    //////////////////////////////////////////////
    ///////// Recommend Student Methods
    ////////////////////////////////////////////
    openRecommendStudentPopup() {
      this.recommendStudentPopupActive = true
      const recommended_by = this.params.data['recommended_by']
      if (recommended_by) this.recommendBy = recommended_by
    },
    async submitRecommendByForm() {
      this.issubmitted = true
      const isValid = await this.$validator.validateAll()
      if (!isValid) {
        this.issubmitted = false
        return this.errMsg()
      }
      this.VSLoading()
      const id = this.params.data['id']
      const payload = {
        id,
        data: {
          recommended_by: this.recommendBy
        }
      }
      ApplicationRepository.recommendStudent(payload)
        .then((res) => {
          this.successMsg(
            `${this.$t('admission.student_recommend_successfully')}`
          )
          this.resetRecommendFrom()
        })
        .catch(this.errMsg)
        .finally(() => {
          this.stopVSLoading()
        })
    },
    resetRecommendFrom() {
      this.recommendStudentPopupActive = false
      this.issubmitted = false
      this.$validator.reset()
      this.recommendBy = ''
      this.$nextTick(() => {
        this.makeAction(this.actionEmitterReset)
      })
    },
    //////////////////////////////////////////////
    ///////// Change Division Methods
    ////////////////////////////////////////////
    openChangeDivisionPopup() {
      this.divisionPopupActive = true
    },

    async submitRecommendByForm1() {
      this.issubmitted = true
      const isValid = await this.$validator.validateAll()
      if (!isValid) {
        this.issubmitted = false
        return this.errMsg()
      }
      this.VSLoading()
      const id = this.params.data['id']
      const payload = {
        id,
        data: {
          recommended_by: this.recommendBy
        }
      }
      ApplicationRepository.recommendStudent(payload)
        .then((res) => {
          this.successMsg(
            `${this.$t('admission.student_recommend_successfully')}`
          )
          this.resetRecommendFrom()
        })
        .catch(this.errMsg)
        .finally(() => {
          this.stopVSLoading()
        })
    },
    resetRecommendFrom1() {
      this.recommendStudentPopupActive = false
      this.issubmitted = false

      this.$validator.reset()
      this.recommendBy = ''
    },
    view(action) {
      this.VSLoading()
      const id = this.params.data['id']
      ApplicationRepository.view(id)
        .then(() => {
          this.showActionSuccess(action)

          applicationRepository.getApplication(id).then((res) => {
            const studentID = res.data.studentID
            if (studentID)
              this.$router.push({
                name: 'StudentProfile',
                params: {id: studentID}
              })
            else {
              this.selected_data = this.params.data
              this.popupActive = true
            }
          })
        })
        .catch(this.errMsg)
        .finally(() => {
          this.stopVSLoading()
        })
    },
    deleteApp(action) {
      const id = this.params.data['id']
      ApplicationRepository.delete(id)
        .then(() => {
          this.showActionSuccess(action)
          this.$root.$emit('row:status')
          this.params.data.splice(id, 1)
          this.popupActive = false
        })
        .catch(this.errMsg)
    },
    showActionSuccess(action) {
      this.successMsg(
        `${this.$t('basic.the_selected_row_was_successfully')}`,
        `${this.$t('basic.row')} ${action}`
      )
    },
    enroll() {
      this.student.name = `${this.params.data.student.first_name} ${this.params.data.student.last_name}`
      this.student.email = this.params.data.student.student_email
      ApplicationRepository.enrollStudent(this.student)
      this.popupActive = false
      this.changeAppStatus('edit', 6)
    },
    changeToEnrolled(payload) {
      const data = {...payload, appId: this.params.data.id, status: 6}
      ApplicationRepository.changeStatusToEnroll(data)
        .then(() => {
          this.successMsg(`${this.$t('admission.enrolled_change')}`)
          this.$root.$emit('row:status')
          this.requestQualified = false
        })
        .catch(this.errMsg)
    },
    makeScheduleMeeting() {
      this.popupActive = false
      this.$root.$emit('row:makeScheduleMeeting', this.params.data.id)
      // this.sheduleMeetingPopupActive = true
    }
  },

  watch: {
    recommendStudentPopupActive(val) {
      if (!val) this.resetRecommendFrom()
    }
  }
}
</script>
<style lang="scss" scoped>
#absoult-popup-status {
  display: block;
  width: fit-content;
  position: inherit;
  height: 15px;
}
</style>
