<template>
  <ListPageWithCreateButton :showCreateBtn="false">
    <template slot="listDataContent">
      <transition-group class="list-transition" name="list-enter-up" tag="ul">
        <li
          v-for="(assessment, index) in assessments"
          :key="assessment.teacher_id"
          class="mb-12 relative"
          :style="[{transitionDelay: index * 0.1 + 's'}]">
          <vx-card>
            <vs-dropdown
              class="action-buttons-tasks-principal"
              vs-trigger-click>
              <vs-button
                radius
                color="#999"
                type="flat"
                icon="more_vert"></vs-button>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  @click="
                    getSingleTeacherassessments(assessment.class_teacher_id)
                  ">
                  <feather-icon
                    class="mr-2"
                    icon="EyeIcon"
                    svgClasses="h-4 w-4" />
                  <span>{{ $t('assessments.view_assessment') }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>

            <!-- -- -- -- -- -- -- -- -- Content -- -- -- -- -- -- -- ---->
            <div
              class="min_height_110 vx-row items-center p-1 pt-1 pb-1 md:p-1">
              <!-- Name -->
              <div class="vx-col w-full xl:w-1/3 md:w-1/2 xl:mb-0 mb-4">
                <h5 class="mb-3 inline-block">{{ assessment.teacher }}</h5>
                <h6 class="mb-4">
                  {{ assessment.assessments.counter }}
                  {{ $t('basic.tasks') }}
                </h6>
              </div>
              <div class="vx-col w-full xl:w-1/3 md:w-1/2 xl:mb-0 mb-4">
                <h6 class="mb-4">
                  {{ assessment.class }}
                </h6>
                <h6 class="mb-4">
                  {{ assessment.subject }}
                </h6>
              </div>

              <div class="vx-col w-full xl:w-1/3 md:w-1/2 xl:mb-0 mb-4">
                <h6 class="mb-4 text-success">
                  {{ assessment.assessments.assessed }}
                  {{ $t('assessments.student_assessed') }}
                </h6>
                <h6 class="mb-4 text-warning">
                  {{ assessment.assessments.to_be_assess }}
                  {{ $t('assessments.student_need_to_be_assess') }}
                </h6>
                <h6 class="mb-4 text-danger">
                  {{ assessment.assessments.delayed }}
                  {{ $t('assessments.delayed') }}
                </h6>
              </div>
            </div>
          </vx-card>
        </li>
      </transition-group>
      <!-- Pagination  -->
      <template v-if="totalPages > 1">
        <vs-pagination
          :total="totalPages"
          :max="checkDeviceType ? 5 : 7"
          v-model="currentPage" />
      </template>
      <!-- <template v-if="assessments.length < 1"> -->
      <template v-if="assessments.length == 0">
        <p class="text-center">{{ $t('basic.no_data_available') }}</p>
      </template>
    </template>
    <!--    <template slot="rightSide">-->
    <!--      <RoundFilter-->
    <!--          :filter_date="true"-->
    <!--          :filters_prop="filters"-->
    <!--          @dateChanged="getDate"-->
    <!--          @refilter="refilter"-->
    <!--          ref="roundFilter"-->
    <!--          :loading="loadingFilter"-->
    <!--      >-->
    <!--      </RoundFilter>-->
    <!--    </template>-->
  </ListPageWithCreateButton>
</template>
<script>
// Components
import RoundFilter from '@/components/general/RoundFilter'

// APIs
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const assessmentsRepo = RepositoryFactory.get(
  'schoolPrincipalRepository',
  'assessmentsRepo'
)
const assignmentsRepo = RepositoryFactory.get(
  'schoolPrincipalRepository',
  'assignmentsRepo'
)

import {commonDataMixin} from '@/mixins.js'

export default {
  name: 'principal_tasks',
  components: {
    RoundFilter
  },
  mixins: [commonDataMixin],
  data() {
    return {
      /////////////////////////
      // round Filter
      /////////////////////////
      assessments: [],
      filters: [
        {
          id: 1,
          name: this.$t('basic.academic_year'),
          payload_name: 'academic_year_id',
          onChangeFireAction: true,

          by: []
        },

        {
          id: 2,
          name: this.$t('basic.divisions'),
          payload_name: 'division_ids',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 3,
          name: this.$t('basic.sections'),
          payload_name: 'section_ids',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 4,
          name: this.$t('basic.grades'),
          payload_name: 'grade_ids',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 5,
          name: this.$t('basic.classes'),
          payload_name: 'class_ids',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 6,
          name: this.$t('basic.subject'),
          payload_name: 'subjects',
          by: []
        },
        {
          id: 7,
          name: this.$t('assessments.assessed'),
          payload_name: 'assess_or_not_assess',
          singleSeletable: true,
          by: [
            {
              id: 'all',
              name: this.$t('assessments.all'),
              checked: false
            },
            {
              id: 'assess',
              name: this.$t('assessments.assessed'),
              checked: false
            },
            {
              id: 'not_assess',
              name: this.$t('assessments.not_assessed'),
              checked: false
            }
          ]
        },
        {
          id: 8,
          name: this.$t('basic.teacher'),
          payload_name: 'teacher_ids',
          by: []
        }
      ],
      filterPayload: {
        school: [],
        division_ids: [],
        section_ids: [],
        grade_ids: [],
        class_ids: [],
        teacher_ids: [],
        subjects: []
      },
      checked_filters: null,
      loadingFilter: false,
      /////////////////////////
      // Pagination
      /////////////////////////
      totalPages: 0,
      currentPage: 1,
      countPerPage: 10,
      fromDate: '',
      toDate: ''
    }
  },
  watch: {
    'filterPayload.dateIn'(v) {
      // this.configDatepickerTo.minDate = `${(v).split('-')[0]}-${(v).split('-')[1]}-${Number((v).split('-')[2]) + 1}`
      this.configDatepickerTo.minDate = v
    },
    currentPage(v) {
      this.getPrincipalTasks(v)
    },
    filterPayload: {
      deep: true,
      immediate: true,
      handler() {
        this.getPrincipalTasks()
      }
    }
  },
  methods: {
    ///////////////////////////////
    // loading
    /////////////////////////////
    loading() {
      this.VSLoading()
    },
    stopLoading() {
      this.stopVSLoading()
    },
    ///////////////////////////////
    // Dealing with Data
    /////////////////////////////
    resetList() {
      this.loading()
      this.assessments = []
      // this.isOnlineclasses = false
    },
    setList(data) {
      this.stopLoading()
      this.assessments = data.data
      this.setPagination(data)
    },
    setPagination(data) {
      this.totalPages = data.total_pages
      this.countPerPage = data.per_page
      this.currentPage = data.current_page
    },
    refilter(checked) {
      this.currentPage = 1
      checked.map((item) => {
        this.filterPayload[item.name] = item.checked
      })
    },
    resetFilterData() {
      this.filterPayload.dateIn = ''
      this.filterPayload.dateOut = ''
      this.resetStatusCardsData()
      this.refilter()
    },
    // Date Filter
    changeDate() {
      this.refilter(this.checked_filters)
    },

    //---- List
    getPrincipalTasks() {
      const {school, ...filters} = this.filterPayload
      this.resetList()
      assessmentsRepo
        .getPrincipalAssessments(this.currentPage, filters)
        .then((res) => {
          this.setList(res.data)
        })
        .catch(() => {
          this.stopLoading()
        })
    },
    editAssessment(data) {
      $sharedEvent.fire('openCreateTaskModal', data)
    },
    getSingleTeacherassessments(id) {
      this.$router.push({
        name: 'principal_teacher_assessments',
        params: {
          id
        }
      })
    },
    getDate(val) {
      const dates = val.split('to')
      this.filterPayload.start_date = dates[0]
      this.filterPayload.end_date = dates[1]
      this.getPrincipalTasks()
    },
    ///////////////////////////////////
    // get Filters Data
    ///////////////////////////////////
    getFiltersData() {
      this.filters[1]['by'] = []
      this.filters[2]['by'] = []
      this.filters[3]['by'] = []
      this.filters[4]['by'] = []
      this.filters[5]['by'] = []
      const schools = [this.filterPayload.school]
      this.getFilterData({schools}).then((res) => {
        res.divisions.map((el) => {
          this.filters[1]['by'].push({
            id: el.id,
            name: el.name,
            checked: false
          })
        })
      })
      assignmentsRepo.getAllTeacherRole().then((res) => {
        res.data.map((el) => {
          this.filters[7]['by'].push({
            id: el.id,
            name: el.name,
            checked: false
          })
        })
      })
    },
    getFilteredSectionsAndSubjects(chosen) {
      this.loadingFilter = true

      this.filters[2]['by'] = []
      this.filters[3]['by'] = []
      this.filters[4]['by'] = []
      this.filters[5]['by'] = []
      const data = {
        divisions: chosen.division_ids,
        schools: chosen.school
      }
      this.getFilterData(data)
        .then((res) => {
          res.sections.map((el) => {
            this.filters[2]['by'].push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
          res.divisions_subjects.map((el) => {
            this.filters[5]['by'].push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
        })
        .finally(() => {
          this.loadingFilter = false
        })
    },
    getFilteredGrades(chosen) {
      this.loadingFilter = true

      this.filters[3]['by'] = []
      this.filters[4]['by'] = []
      const data = {
        sections: chosen.section_ids,
        divisions: chosen.division_ids,
        schools: chosen.school
      }
      this.getFilterData(data)
        .then((res) => {
          res.grades.map((el) => {
            this.filters[3]['by'].push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
        })
        .finally(() => {
          this.loadingFilter = false
        })
    },
    getFilteredClasses(chosen) {
      this.loadingFilter = true

      this.filters[4]['by'] = []
      const data = {
        sections: chosen.section_ids,
        divisions: chosen.division_ids,
        schools: chosen.school,
        grades: chosen.grade_ids
      }
      this.getFilterData(data)
        .then((res) => {
          res.grades_classes.map((el) => {
            this.filters[4]['by'].push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
        })
        .finally(() => {
          this.loadingFilter = false
        })
    }
  },

  created() {
    this.filterPayload.school = this.authUser.school_id
    this.$root.$on('onChange:division_ids', this.getFilteredSectionsAndSubjects)
    this.$root.$on('onChange:section_ids', this.getFilteredGrades)
    this.$root.$on('onChange:grade_ids', this.getFilteredClasses)
    this.getFiltersData()
  },
  beforeDestroy() {
    this.$root.$off('onChange:division_ids')
    this.$root.$off('onChange:section_ids')
    this.$root.$off('onChange:grade_ids')
  }
}
</script>
<style lang="scss" scoped>
.action-buttons-tasks-principal {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
