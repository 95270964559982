<template>
  <div>
    <ListPageWithCreateButton :showCreateBtn="false">
      <template slot="listDataContent">
        <UsersLosgsTable
          :headers_prop="headers"
          :list_url_prop="`/mobile/logs/${$route.params.id}`">
        </UsersLosgsTable>
      </template>
    </ListPageWithCreateButton>
  </div>
</template>

<script>
import UsersLosgsTable from '@/components/general/datatable/DataTable2.vue'
export default {
  components: {
    UsersLosgsTable
  },
  data() {
    return {
      headers: [
        {
          minWidth: 90,
          headerName: this.$t('basic.type'),
          field: 'type'
        },
        {
          minWidth: 150,
          headerName: this.$t('form.date'),
          field: 'date'
        },
        {
          minWidth: 150,
          headerName: this.$t('form.time'),
          field: 'time'
        }
      ]
    }
  }
}
</script>
