import boundariesAndDescriptors from './pages/list'
import createBoundary from './pages/createBouandary'
import editBoundary from './pages/editBouandary'

export default [
  {
    path: 'boundaries-and-descriptors',
    name: 'boundariesAndDescriptors',
    component:boundariesAndDescriptors,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        {
          title: 'boundariesAndDescriptors.boundariesAndDescriptors',
          url: '',
          active: true
        }
        ],
      rule: 'TeacherSNDSMD',
      pageTitle:'boundariesAndDescriptors.boundariesAndDescriptors'
    }

  },
  {
    path: 'create-boundaries-and-descriptors',
    name: 'create-boundary',
    component:createBoundary,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        {
          title: 'boundariesAndDescriptors.boundariesAndDescriptors',
          url: '/grading/boundaries-and-descriptors',
        },
        {
          title: 'boundariesAndDescriptors.createBoundary',
          url: '',
          active: true
        }],
      rule: 'TeacherSNDSMD',
      pageTitle:'boundariesAndDescriptors.createBoundary'
    }

  },
  {
    path: 'edit-boundaries-and-descriptors/:id',
    name: 'edit-boundary',
    component:editBoundary,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        {
          title: 'boundariesAndDescriptors.boundariesAndDescriptors',
          url: '/grading/boundaries-and-descriptors',
        },
        {
          title: 'boundariesAndDescriptors.editBoundary',
          url: '',
          active: true
        }],
      rule: 'TeacherSNDSMD',
      pageTitle:'boundariesAndDescriptors.editBoundary'
    }

  }
]
