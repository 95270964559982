<template>
  <div>
    <vx-card class="p-relative">
      <!--------------- Action Buttons --------------->
      <vs-dropdown
        class="actionButtons"
        v-if="canTakeActions"
        vs-custom-content
        vs-trigger-click
      >
        <vs-button color="#999" type="flat" icon="more_vert"></vs-button>

        <!-- Start Drop Down -->
        <vs-dropdown-menu>
          <template>
            <vs-dropdown-item @click="copyJoinMeetingLnk()" v-if="!showRecord">
              <span class="flex items-center">
                <feather-icon icon="CopyIcon" svgClasses="h-4 w-4 mr-2" />
                <span>{{ $t("onlineClass.copy_the_join_link") }}</span>
              </span>
            </vs-dropdown-item>
            <template v-else>
              <vs-dropdown-item @click="playRecord()">
                <span class="flex items-center">
                  <feather-icon
                    icon="PlayCircleIcon"
                    svgClasses="h-4 w-4 mr-2"
                  />
                  <span>{{ $t("onlineClass.play_Record") }}</span>
                </span>
              </vs-dropdown-item>

              <vs-dropdown-item @click="copyRecordMeetingLink()">
                <span class="flex items-center">
                  <feather-icon icon="Share2Icon" svgClasses="h-4 w-4 mr-2" />
                  <span>{{ $t("onlineClass.share_the_record") }}</span>
                </span>
              </vs-dropdown-item>
            </template>

            <vs-dropdown-item
              :to="{
                name: 'onlineClassEdit',
                params: { id: this.onlineClass.id },
              }"
              v-if="onlineClass.time_status === 'upcoming'"
            >
              <feather-icon icon="EditIcon" svgClasses="h-4 w-4" class="mr-2" />
              {{ $t("basic.edit") }}
            </vs-dropdown-item>

            <vs-dropdown-item
              @click="archiveClass(onlineClass)"
              v-if="
                showArchiveIcon == true &&
                onlineClass.status !== 'archived' &&
                onlineClass.time_status !== 'ongoing' &&
                is_teacher
              "
            >
              <span class="flex items-center">
                <feather-icon
                  icon="ArchiveIcon"
                  svgClasses="h-4 w-4"
                  class="mr-2"
                />
                <span>{{ $t("basic.archive") }}</span>
              </span>
            </vs-dropdown-item>
          </template>
        </vs-dropdown-menu>
      </vs-dropdown>
      <!------------------ Content  ------------------>
      <div class="vx-row items-center p-1 py-1 md:p-1">
        <!-- Name -->
        <div
          class="vx-col w-full md:w-1/2 xl:mb-0 mb-4"
          :class="
            !is_teacher ? (!is_parent ? 'xl:w-1/3' : 'xl:w-1/3') : 'lg:w-1/5'
          "
        >
          <h5 class="mb-3 text-capitalize">
            {{ onlineClass.subjectName }}
          </h5>
          <p v-if="canSeeClassName">
            {{ onlineClass["class-name"] }}
          </p>
          <p class="text-capitalize" v-if="canSeeTeacherName">
            {{ `${onlineClass["teacher_name"].split(' ').slice(0,2).join(" ")}` }}
          </p>
        </div>
        <!-- Date && Time -->
        <div
          class="vx-col w-full md:w-1/2 xl:mb-0 mb-4"
          :class="userType != 'parent' ? 'lg:w-1/5' : 'xl:w-1/3'"
        >
          <p class="mb-1 text-sm inline-block mr-5">
            <feather-icon icon="CalendarIcon" svgClasses="h-4 w-4 mr-2 " />
            {{ onlineClass.date }}
          </p>
          <!-- Classs start time && period -->
          <p class="text-sm inline-block">
            <template v-if="onlineClass.time_from">
              <feather-icon icon="ClockIcon" svgClasses="h-4 w-4 mr-2 " />
              {{ time_from }}
            </template>

            <span v-if="onlineClass.time_from" class="block py-1">
              <feather-icon icon="ClockIcon" svgClasses="h-4 w-4 mr-2 " />
              {{ onlineClass.classPriod }}
            </span>
          </p>
        </div>
        <!-- If Not recordered (Not Done Yet) -->
        <template v-if="!showRecord">
          <!-- Meetting Links -->
          <div
            class="vx-col w-full md:w-1/2 xl:mb-0 mb-4"
            :class="userType != 'parent' ? 'xl:w-1/3' : ''"
            v-if="userType != 'parent'"
          >
            <div class="w-6/5 m-auto">
              <vs-chip
                class="flex justify-between mb-3"
                :class="`status--dark ml-auto mr-auto float-none`"
                style="cursor: pointer"
                v-if="is_teacher"
              >
                <span class="text-left">
                  {{ $t("onlineClass.run_the_meeting") }}
                </span>
                <div v-if="onlineClass.status !== 'archived'">
                  <vx-tooltip
                    :text="$t('onlineClass.run_meeting')"
                    class="text-right inline-block"
                  >
                    <span
                      class="no_style"
                      @click="check_start_end_time(onlineClass.id)"
                    >
                      <feather-icon
                        icon="VideoIcon"
                        title="run"
                        svgClasses="h-4 w-4 cursor-pointer pl-1"
                      />
                    </span>
                  </vx-tooltip>
                </div>
              </vs-chip>

              <template v-else>
                <vs-chip
                  class="flex justify-between"
                  :class="`status--dark ml-auto mr-auto float-none`"
                  style="cursor: pointer"
                >
                  <span class="text-left">{{
                    $t("onlineClass.students_online_class_link")
                  }}</span>
                  <span>
                    <button @click="runMeeting()" class="no_style">
                      <vx-tooltip
                        :text="$t('onlineClass.run_meeting')"
                        class="text-right inline-block"
                      >
                        <feather-icon
                          v-if="onlineClass.status !== 'archived'"
                          icon="VideoIcon"
                          title="run"
                          svgClasses="h-4 w-4  pl-1  cursor-pointer"
                        />
                      </vx-tooltip>
                    </button>
                    <vx-tooltip
                      text=" braincert run meeting "
                      class="text-right inline-block ml-2"
                      v-if="use_braincert"
                    >
                      <feather-icon
                        icon="StarIcon"
                        title="run"
                        svgClasses="h-4 w-4 cursor-pointer"
                        @click="braincertCreateMeeting"
                      />
                    </vx-tooltip>
                  </span>
                </vs-chip>
              </template>
            </div>
          </div>
          <!-- Status -->
          <div
            class="vx-col w-full md:w-1/2 xl:mb-0 my-3"
            :class="userType != 'parent' ? 'xl:w-1/4' : 'xl:w-1/3'"
          >
            <p class="text-center flex justify-between">
              <!-- Time Status -->
              <span
                v-if="onlineClass.time_status"
                :class="`status2--${
                  onlineClass.time_status === 'done'
                    ? 'ended'
                    : onlineClass.time_status
                } `"
                >{{
                  onlineClass.time_status === "done"
                    ? "closed"
                    : onlineClass.time_status
                }}</span
              >
              <!-- default -->
              <vs-chip
                :class="`status--${onlineClass.status} mb-0 lg:ml-2`"
                v-if="onlineClass.time_status !== 'closed'"
                >{{ onlineClass.status }}</vs-chip
              >
            </p>
          </div>
        </template>

        <!-- recordered -->
        <template v-else>
          <!-- duration -->
          <div
            class="vx-col w-full md:w-1/2 xl:mb-0 mb-4"
            :class="userType != 'parent' ? 'xl:w-1/4' : 'xl:w-1/6 p-0'"
          >
            <vs-chip
              class="status--dark mx-auto flex justify-between float-none"
            >
              <span class="text-capitalize">{{ $t("basic.duration") }}</span>
              <span> {{ onlineClass.classPriod }}</span>
            </vs-chip>
          </div>
          <!-- download && play -->
          <div
            class="
              vx-col
              w-1/2
              xl:w-1/6
              md:w-1/4
              xl:mb-0
              mb-4
              flex
              justify-around
            "
            v-if="userType !== 'parent'"
          >
            <vx-tooltip
              :text="$t('onlineClass.play_Record')"
              class="text-right inline-block"
            >
              <feather-icon
                @click="playRecord"
                icon="PlayCircleIcon"
                :title="$t('onlineClass.play_Record')"
                svgClasses="h-5 w-5   cursor-pointer"
              />
            </vx-tooltip>
          </div>
          <!-- Status -->
          <div
            class="vx-col w-1/2 md:w-1/4 xl:mb-0 mb-4 my-2"
            :class="userType != 'parent' ? 'xl:w-1/6' : 'xl:w-1/6'"
          >
            <!-- default -->
            <vs-chip
              :class="`status--${onlineClass.status} mb-0 `"
              class="ml-auto mr-0 block xl-float-none float-right"
              >{{
                onlineClass.status === "closed"
                  ? $t("form.conceled")
                  : onlineClass.status
              }}</vs-chip
            >
          </div>
        </template>
      </div>
      <div :class="!is_teacher? 'text-right' : 'text-left'">
        <p v-if="onlineClass.type === 'Schoolia'" class="py-2">
          {{ $t("onlineClass.online_meeting") }}
        </p>
        <p v-if="onlineClass.type === 'S. Schoolia'" class="py-2">
          {{ $t("onlineClass.scheduled_meeting") }}
        </p>

        <p v-if="onlineClass.type === 'Co Schoolia'" class="py-2">
          {{ $t("onlineClass.co_meeting") }}
        </p>
        <p v-if="onlineClass.type === 'Co S. Schoolia'" class="py-2">
          {{ $t("onlineClass.scheduled_co_meeting") }}
        </p>
      </div>
      <!-- -------------------- play Record Popup ----------------- -->
      <template v-if="playActive">
        <!-- show records list  -->
        <vs-popup
          class="holamundo show-records-list py-10"
          :title="playRecordPopupTitle"
          :active.sync="playActive"
        >
          <!-- Loader -->
          <div
            ref="recordsLoader"
            id="recordsLoader"
            class="vs-con-loading__container"
            v-if="loadingRecords"
          ></div>
          <template v-else>
            <p v-if="!recordes.length">
              {{ $t("onlineClass.meeting_isnot_recorded") }}
            </p>
            <template v-else>
              <div
                class="btn-group-vertical"
                v-for="(record, i) in recordes"
                :key="i"
              >
                <vs-button
                  type="flat"
                  @click="
                    showRecordFrame({
                      ...record,
                      name: `${changeTime12Formate(
                        record.start_time
                      )} - ${changeTime12Formate(record.end_time)}`,
                    })
                  "
                  >{{
                    `${changeTime12Formate(record.start_time)}
               - ${changeTime12Formate(record.end_time)}`
                  }}</vs-button
                >
              </div>
            </template>
          </template>
          <!-- Show Iframe -->
          <vs-popup
            class="play-record-iframe"
            :title="`${playRecordPopupTitle} - recordTitle`"
            :active.sync="showIframe"
          >
            <iframe width="100%" height="550" :src="playRecordLink"></iframe>
          </vs-popup>
        </vs-popup>
      </template>
    </vx-card>
  </div>
</template>
<script>
import VxTooltip from '@/layouts/components/vx-tooltip/VxTooltip.vue'
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'
import { setupAxiosConfig } from '@/axios'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const TeacherRepository = RepositoryFactory.get(
  'TeachersModule',
  'OnlineClass'
)
const BraincertRepository = RepositoryFactory.get(
  'AdmissionModule',
  'braincert'
)

// mixins
import { authUserMixin } from '@/mixins.js'

export default {
  name: 'class-card',
  props: {
    showArchiveIcon: {
      type: Boolean,
      default: true
    },
    onlineClass_prop: {
      type: Object,
      required: true
    },

    userType: {
      type: String,
      default: () => {
        const authUser = JSON.parse(window.localStorage.getItem('authUser')),
          userRule = authUser.userRole
        return ['Teacher', 'teaher'].some((el) => userRule.includes(el))
          ? 'teacher'
          : userRule
      }
    }
  },
  components: {
    VxTooltip,
    videoPlayer,
    setupAxiosConfig
  },
  mixins: [authUserMixin],
  data () {
    return {
      onlineClass: this.onlineClass_prop,
      playActive: false,
      userRule: '',
      use_braincert: false,
      braincert_class_id: '',
      braincert_class_lanuchURL: '',
      startMeetingLink: '',
      // Record
      loadingRecords: false,
      showIframe: false,
      recordTitle: '',
      playRecordLink: '',
      recordes: []
    }
  },
  created () {},
  computed: {
    windowLink () {
      return `${window.location.origin}/#`
    },
    time_from () {
      let time = this.onlineClass.time_from.split(':')
      time.pop()
      time = time.join(':')
      return time
    },

    // MEETINGS Links
    studentMettingLink () {
      return `${this.windowLink}/bbb/join/${this.onlineClass.id}`
    },
    teacherMettingLink () {
      return `${this.windowLink}/bbb/create/${this.onlineClass.id}`
    },
    playRecordPopupTitle () {
      return `${this.onlineClass.subjectName}  ( ${this.onlineClass['class-name']} )`
    },
    // playLink () {
    //   return `https://records.schoolia-eg.com/?meetingId=${this.onlineClass.internal_meeting_id}`
    // },
    // downloadLink () {
    //   return `https://records.schoolia-eg.com/?meetingId=${this.onlineClass.internal_meeting_id}`
    // },
    playerOptions () {
      return {
        height: '660',
        fluid: true,
        autoplay: false,
        muted: false,
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        // sources:`https://records.schoolia-eg.com/?meetingId=${this.onlineClass.id}`,
        sources: {
          type: 'video/mp4',
          src: 'https://records.schoolia-eg.com/?meetingId=bb867fd15124ec4fa5629fa4eb9b507768dca83c-1601301472424'
        },
        poster: 'logo.png'
      }
    },
    // userRule () {
    //   const authUser = JSON.parse(window.localStorage.getItem('authUser'))
    //   return  authUser.userRole

    // },
    canEdit () {
      return this.checkUserRole('Teacher')
    },
    canTakeActions () {
      // return this.checkUserRole('Teacher') && (this.startMeetingLink || this.onlineClass.time_status !== 'ongoing')
      return (
        this.checkUserRole('Teacher') ||
        (this.checkUserRole('Student') && this.showRecord)
      )
    },
    canSeeClassName () {
      return (
        this.checkUserRole('Teacher') || this.checkUserRole('SchoolPrincipal')
      )
    },
    canSeeTeacherName () {
      return (
        this.checkUserRole('SchoolPrincipal') ||
        this.checkUserRole('Student') ||
        this.checkUserRole('Parent')
      )
    },
    canRunClasses () {
      return this.checkUserRole('Teacher')
    },
    showRecord () {
      return (
        this.onlineClass.time_status === 'done' ||
        this.onlineClass.time_status === 'closed'
      )
    }
  },
  watch: {
    showIframe (v) {
      if (!v) this.resetRecordFrame()
    }
  },
  methods: {
    ////////////////////////////
    // loading
    ////////////////////////////
    async startRecordsloading () {
      await (this.loadingRecords = true)
      this.VsLoadingContained(this.$refs.recordsLoader)
    },
    async endRecordsloading () {
      await this.stopVsLoadingContained(this.$refs.recordsLoader)
      this.loadingRecords = false
    },
    ////////////////////////////
    // APIs
    ////////////////////////////
    getStartMeetingLink () {
      return new Promise((resolve, reject) => {
        TeacherRepository.getStartMeetingLink(this.onlineClass.id)
          .then((res) => resolve(res))
          .catch((err) => {
            this.errMsg(err)
            reject(err)
          })
      })
    },
    getJoinMeetingLink () {
      return new Promise((resolve, reject) => {
        TeacherRepository.getJoinMeetingLink(this.onlineClass.id)
          .then((res) => resolve(res))
          .catch((err) => {
            this.errMsg(
              this.$t('onlineClass.student_err')
            )
            reject(err)
          })
      })
    },
    check_start_end_time (id) {
      const data = new FormData()
      data.append('id', id)
      TeacherRepository.check_start_end_time(data).then((res) => {
        if (res.data.is_allowed) {
          // this.$router.push(`/bbb/create/${id}`)
          this.runMeeting()
        } else {
          this.errMsg(
            this.$t('onlineClass.kindly_wait_until_the_meeting_start_time')
          )
        }
      })
    },
    getOnlineClassRecords () {
      this.startRecordsloading()

      return new Promise((resolve, reject) => {
        TeacherRepository.getOnlineClassRecords(this.onlineClass.id)
          .then((res) => {
            this.recordes = res.data
            resolve(this.recordes)
          })
          .catch((err) => {
            this.errMsg(err)
            reject(err)
          })
          .finally(this.endRecordsloading)
      })
    },
    /////////////////////////////////
    // Meeting
    ////////////////////////////////
    copyJoinMeetingLnk () {
      this.getJoinMeetingLink()
        .then((res) => {
          navigator.clipboard.writeText(res)
          this.successMsg(this.$t('basic.Text_copied_successfully'))
        })
        .catch((err) => {
          navigator.clipboard.writeText('')
        })
    },
    async copyRecordMeetingLink () {
      const copyProgress = async () => {
        await navigator.clipboard.writeText(
          this.recordes.map((e) => e.url).join('\n')
        )
        await this.successMsg(this.$t('basic.Text_copied_successfully'))
      }
      if (!this.recordes.length) await this.getOnlineClassRecords().then((res) => copyProgress())
      else copyProgress()
    },
    runMeeting () {
      const isTeacher = this.checkUserRole('Teacher')
      if (isTeacher) {
        this.getStartMeetingLink().then((res) => {
          this.startMeetingLink = res
          window.open(res, '_blank')
        })
      } else {
        this.getJoinMeetingLink().then((res) => {
          window.open(res, '_blank')
        })
      }
    },
    // Record
    playRecord () {
      this.playActive = true
      this.getOnlineClassRecords()
    },
    downloadRecord () {
      const link = `https://records.schoolia-eg.com/?meetingId=${this.onlineClass.internal_meeting_id}`,
        name = `${this.onlineClass.subjectName}-${this.onlineClass['class-name']}-${this.onlineClass.date}`
      // this.startDownloadExporting(link, name, true)
      window.open(link, name)
    },
    showRecordFrame (record) {
      this.recordTitle = record.name
      this.playRecordLink = record.url
      this.showIframe = true
    },
    resetRecordFrame () {
      this.recordTitle = ''
      this.playRecordLink = ''
    },

    /////////////////////////////////////
    // Braincert API
    ////////////////////////////////////

    // Handel Time Formate
    changeTime12Formate (time) {
      const hh = time.split(':')[0],
        hh_num = Number(hh) - 12 || 12,
        hh_num_str = `${hh_num}`,
        mm = time.split(':')[1]

      if (parseInt(hh) >= 12) return `${hh_num_str.length < 2 ? `0${hh_num}` : hh_num}:${mm}PM`
      else return `${hh}:${mm}AM`
    },

    // Braincert Request

    // schedule Class ( Get class ID )
    schedule () {
      // Handle Payload
      const scheduleData = {
        title: this.onlineClass['class-name'],
        timezone: 24,
        start_time: this.changeTime12Formate(this.onlineClass.time_from), // Format : 10:30AM || 00:20 PM
        end_time: this.changeTime12Formate(this.onlineClass.time_to),
        date: this.onlineClass.date, // Formate : 2014-08-15
        formate: 'json'
      }
      // Make request
      return BraincertRepository.braincertRequest('schedule', scheduleData)
        .then((res) => {
          if (res.status !== 'error') {
            // Push to meeting page if  Get Class Id
            this.braincert_class_id = res.class_id
          }
        })
        .catch((err) => console.error('braincert schedule', err))
    },

    // getclasslaunch ( get meeting url )
    getLunchURL (class_id) {
      const // Get User data
        authUser = JSON.parse(localStorage.getItem('authUser')),
        userRole = authUser.userRole,
        user = {
          id: authUser.uid,
          name: authUser.displayName,
          isTeacher: this.is_teacher
        },
        // LunchURL Payload
        getURL_Payload = {
          userId: user.id,
          userName: user.name,
          isTeacher: user.isTeacher ? 1 : 0, // 0 for Student, 1 for teacher
          class_id,
          lessonName: this.onlineClass['class-name'],
          courseName: this.onlineClass.subjectName
        }

      BraincertRepository.braincertRequest('getclasslaunch', getURL_Payload)
        .then((res) => {
          if (res.status !== 'error') {
            this.braincert_class_lanuchURL = res.launchurl
            this.$router.push({
              name: user.isTeacher
                ? 'braincertLunchClass'
                : 'braincertJoinClass',
              params: { class_id, lunchURL: res.launchurl }
            })
          }
        })
        .catch((err) => console.error('braincert classlaunch', err))
    },
    braincertCreateMeeting () {
      // this.schedule()
      // this.getLunchURL(this.braincert_class_id)
      this.$router.push({
        name: 'braincertLunchClass',
        params: { class_id: this.onlineClass.id }
      })
    },

    /////////////////////////////////
    // Actions APIs
    ////////////////////////////////
    cancelClass () {
      TeacherRepository.cancel(this.onlineClass.id).then((res) => {
        this.onlineClass.status = 'canceled'
        this.$emit('reloadData', true)
      })
      this.$forceUpdate()
    },
    activeClass () {
      TeacherRepository.active(this.onlineClass.id).then((res) => {
        this.$emit('reloadData', true)
      })
      this.$forceUpdate()
    },
    deletelClass () {
      TeacherRepository.delete(this.onlineClass.id).then((res) => {
        this.$emit('reloadData', true)
      })
      this.$forceUpdate()
    },
    archiveClass (onlineClass) {
      TeacherRepository.archive(onlineClass.id).then((res) => {
        this.$emit('reloadData', true)
        this.$emit('counter', true)
      })
    }
  }
}
</script>
<style lang="scss">
.con-vs-chip.flex.justify-between {
  .text-chip.vs-chip--text {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
iframe {
  border: 1px solid #ccc;
}

.show-records-list {
  > .vs-popup > .vs-popup--content {
    min-height: 100px;
  }
}
.play-record-iframe .vs-popup--content {
  padding: 0;
  margin: auto;
}
</style>
