
<template>
  <vx-card class="p-6 pt-6 pb-6 md:pl-10 md:pr-16">
    <div class="vx-row mb-6">
      <div class="vx-col md:w-1/4 sm:w-full w-full mb-2">
        <strong>{{ $t("subject.Name") }}:</strong>
      </div>
      <div class="vx-col md:w-3/4 sm:w-full w-full">
        <div>{{ Subject.name }}</div>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col md:w-1/4 sm:w-full w-full mb-2">
        <strong>{{ $t("subject.nickname") }}:</strong>
      </div>
      <div class="vx-col md:w-3/4 sm:w-full w-full">
        <div>{{ Subject.name }} - {{ Subject.division }}</div>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col md:w-1/4 sm:w-full w-full mb-2">
        <strong>{{ $t("subject.code") }}:</strong>
      </div>
      <div class="vx-col md:w-3/4 sm:w-full w-full">
        <div>{{ Subject.code }}</div>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col md:w-1/4 sm:w-full w-full mb-2">
        <strong>{{ $t("subject.division") }}:</strong>
      </div>
      <div class="vx-col md:w-3/4 sm:w-full w-full">
        <div>{{ Subject.division }}</div>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col md:w-1/4 sm:w-full w-full mb-2">
        <strong>
          <span>{{ $t("subject.status") }}</span>
        </strong>
      </div>
      <div class="vx-col md:w-3/4 sm:w-full w-full">
        <vs-chip :class="`status--${Subject.status.toLowerCase()}`">
          <span>{{ Subject.status }}</span>
        </vs-chip>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col md:w-1/4 sm:w-full w-full mb-2">
        <strong>
          <span>{{ $t("subject.isRequired") }}</span>
        </strong>
      </div>
      <div class="vx-col md:w-3/4 sm:w-full w-full">
        <div v-if="Boolean(Subject.is_optional)">
          <vs-chip  :class="`status--danger`">
            <span>{{ $t("basic.no") }}</span>
          </vs-chip>
        </div>
        <div v-else>
          <vs-chip :class="`status--success`">
            <span>{{ $t("basic.yes") }}</span>
          </vs-chip>
        </div>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col md:w-1/4 sm:w-full w-full mb-2">
        <strong>{{ $t("subject.grades") }}:</strong>
      </div>
      <div class="vx-col md:w-3/4 sm:w-full w-full">
        <div>
          <div
            v-if="Subject.gradesName.length === 0"
            class="vx-col md:w-3/4 w-full"
          >
            <span class="text-danger">{{
              $t("subject.there_are_no_subjects")
            }}</span>
          </div>
          <div
            v-if="Subject.gradesName.length !== 0"
            class="vx-col md:w-3/4 w-full"
          >
            <span v-for="(grade, index) in Subject.gradesName" :key="index">{{
              `${grade}${index === Subject.gradesName.length - 1 ? "" : " - "}`
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </vx-card>
</template>


<script>
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const SubjectsRepository = RepositoryFactory.get('AcademicModule', 'Subjects')

export default {
  name: 'Create',
  data () {
    return {
      nameExist: false,
      name: '',
      error: false,
      saveAgain: true,
      issueSubmitted: false,
      subjects: [
        { name: 'ALkI' },
        { name: 'mathl9' },
        { name: 'sciencekk39' },
        { name: 'arabic5' },
        { name: 'math8' },
        { name: 'science8' }
      ],
      arrGrads: [],
      schools: [],
      divisions: [],
      grads: [],
      Subject: {
        name: '',
        divisions: null,
        grades: []
      }
    }
  },
  created () {
    this.getDropDownData()
    this.id = this.$route.params.id
    this.getSubjectData(this.id)
  },
  methods: {
    getSubjectData (id) {
      SubjectsRepository.getSubjectData(id).then((res) => {
        this.Subject = res.data
      })
    },
    //get greades of spacific division
    getGrades (id) {
      let Grads = []
      SubjectsRepository.getGrades(id).then((res) => {
        this.grads = []
        this.grads = res.data
        Grads = res.data
      })
      return Grads
    },
    getDivsGrades (divisions) {
      SubjectsRepository.getDivsGrades(divisions).then((res) => {
        // this.round.grades=res.data;
        this.grads = res.data
      })
    },
    //when selected division is one get all greades related to it else
    //select all division of this grade
    changeDivision (event) {
      const divCount = this.Subject.divisions
      //this.grades=[]
      if (divCount == null) {
        this.getDropDownData()
      } else {
        this.getGrades(this.Subject.divisions)
      }
    },
    getDropDownData () {
      SubjectsRepository.getDropDownData().then((res) => {
        //  this.schools = res.schools
        this.grads = ['empty']
        this.divisions = res.divisions
      })
    }
  }
}
</script>

<style  scoped>
.background-none {
  background-color: transparent !important;
  color: rgb(160, 152, 152);
  cursor: pointer;
}
</style>
