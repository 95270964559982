
import addTeacher from './pages/Create'

const routes = [
  {
    path: 'add-teacher-snd',
    name: 'add-teacher-snd',
    component: addTeacher,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        { title: 'teachersGateway.list_teachers', url: '/teachers-gateway/' },
        {
          title: 'teachersGateway.addTeacher',
          active: true
        }
      ],
      hideVerticalMenu:false,
      pageTitle: 'teachersGateway.addTeacher',
      rule:'SNDSMD'
    }
  }
]
export default routes
