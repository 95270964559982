import academicYears from './academicYears'
import Subjects from './Subjects'
import syllabus from './syllabus'
import Classes from './classes'
import assignTeacher from './assignTeacher'

export default {
  academicYears,
  Subjects,
  syllabus,
  Classes,
  assignTeacher
}
