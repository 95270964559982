import FileUploader from '@/modules/unit-planner/components/uploadButton/uploadButton.vue'
import Service from '@/modules/unit-planner/services/unit-planner'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import eventBus from '../../eventBus'

import _ from 'lodash'

export default {
  components: { FileUploader, 'v-select': vSelect },
  name: 'ReviewTask',
  data () {
    return {
      // modalProps: {
      //   width: '1050px',
      //   height: 'auto',
      //   minHeight: 500,
      //   scrollable: true
      // },
      modalName: 'ReviewTask',
      restService: Service,
      students: [],
      currentStudent: null,
      currentTask: null,
      tasks: [],
      studentCategory: [
        { id: 1, name: 'Empowerer list' },
        { id: 2, name: 'Weak list' }
      ],
      markAs: [
        { id: 1, name: 'self motivator' },
        { id: 2, name: 'Self Power' }
      ],
      teacherFeedBack: [
        { id: 1, name: 'Not Meeting' },
        { id: 2, name: 'Good' }
      ],
      task: null,
      taskTypesEnum: {
        done_on_time: 'Done ON Time',
        done_but_late: 'Done But Late',
        delayed_done: 'Delayed Done',
        delayed: 'Delayed'
      },
      studentTaskDetails: null,
      currentSection: null,
      criteria: {},
      selectedCriteria: null,
      pypCriteria: [],
      grades: [1, 2, 3, 4, 5, 6, 7],
      selectedGrade: null,
      savingData: {
        mark_as: null,
        student_category: null,
        teacher_feedback: null,
        approved_feedback: null,
        criteria: null,
        notes: [
          { note: '', sent_to: 'parent' },
          { note: '', sent_to: 'student' }
        ]
      },
      taskId: '',
      imgBaseUrl: this.institutionAssetsLink,
      customEvents: [{ eventName: 'openReviewTaskModal', callback: this.loadData }],
      tasksList: {
        action: 'assessment-task',
        icon: 'icon-cloud-lightning',
        // id: 34,
        parent_key: 'teacher-tasks',
        title: 'Assessment Task'
      }
    }
  },
  created () {
    ////////////////////////////////////////////////////
    //**          Custom Event Listener           ** //
    //////////////////////////////////////////////////
    if (this.$route.query.task) {
      this.taskId = this.$route.query.task
      this.loadData()
    }
  },
  beforeDestroy () {
    this.$validator.reset()
    this.$validator.pause()
  },
  destroyed () {
    ////////////////////////////////////////////////////
    //**      Remove Custom Event Listener        ** //
    //////////////////////////////////////////////////
    this.customEvents.forEach(function (customEvent) {
      // eslint-disable-next-line no-undef
      $sharedEvent.off(customEvent.eventName, customEvent.callback)
    })
  },
  methods: {
    showModal () {
      this.$modal.show(this.modalName)
    },
    hideModal () {
      this.$modal.hide(this.modalName)
    },
    loadData () {
      this.resetProps()
      this.restService
        .getSingleTask(this.taskId)
        .then(res => {
          console.log(res)
          this.task = { ...res.data }
          this.currentSection = this.task.section.short_name
          this.task.criteria = this.task.criteria.map(item => {
            return { key: item.key, title: `${item.key} - ${item.title}` }
          })
          // this.tasks = data.tasks
          this.currentTask = res.data

          if (this.currentSection === 'MYP') {
            this.filterMYPCriteria()
          } else if (this.currentSection === 'AMERICAN') {
            this.grades = _.range(1, 21)
          }
          this.getTaskStudents()
          this.showModal()
        })
        .catch(err => {})
    },
    resetProps () {
      this.task = null
      this.currentStudent = null
      this.selectedCriteria = null
      this.savingData = {
        mark_as: null,
        student_category: null,
        teacher_feedback: null,
        approved_feedback: null,
        criteria: null,
        notes: [
          { note: '', sent_to: 'parent' },
          { note: '', sent_to: 'student' }
        ]
      }
      this.studentTaskDetails = null
    },
    getTaskStudents () {
      //** Show Loader **//
      this.$vs.loading()
      this.restService
        .getSubmittedStudents(this.task.id)
        .then(res => {
          const newArray = []
          let seq = 0
          res.data.forEach(item => {
            const obj = {
              ...item.student,
              taskId: item.id,
              has_assess: item.has_assess,
              namePrev: `${item.student.name} ${
                item.has_assess ? ' (Assessed)' : ''
              }`,
              seq: seq++
            }
            newArray.push(obj)
          })
          this.students = newArray

          //** Hide Loader **//
          this.$vs.loading.close()
        })
        .catch(error => {
          //** Hide Loader **//
          this.$vs.loading.close()

          this.errMsg(error.data.message)
        })
    },
    getStudentTaskDetails () {
      //** Show Loader **//
      this.$vs.loading()

      this.restService
        .getStudentTaskDetails(this.currentStudent.taskId)
        .then(res => {
          this.studentTaskDetails = res.data
          this.savingData.notes[1].note = this.studentTaskDetails.assess
            ? this.studentTaskDetails.assess.note
            : ''
          /* debugger */
          //** Hide Loader **//
          this.$vs.loading.close()
        })
        .catch(error => {
          //** Hide Loader **//
          this.$vs.loading.close()

          this.errMsg(error.data.message)
        })
    },
    filterMYPCriteria () {
      this.savingData.criteria = this.criteria
      this.task.criteria.forEach(item => {
        this.$set(this.savingData.criteria, item.key, '')
      })
    },
    changeTask () {
      this.resetProps()
      this.task = this.currentTask
      this.getTaskStudents()
    },
    mappingData () {
      const data = {
        mark_as: this.savingData.mark_as ? this.savingData.mark_as.name : '',
        student_category: this.savingData.student_category
          ? this.savingData.student_category.name
          : '',
        /*teacher_feedback: this.savingData.teacher_feedback.name,*/
        approved_feedback: this.savingData.approved_feedback,
        notes: this.savingData.notes.filter(data => data.note),
        criteria: this.savingData.criteria
      }
      return data
    },
    async validation () {
      const validData = await this.$validator.validateAll()

      let validCriteria = true

      if (
        this.savingData.criteria &&
        typeof this.savingData.criteria === 'object'
      ) {
        Object.keys(this.savingData.criteria).forEach(key => {
          if (!this.savingData.criteria[key]) {
            validCriteria = false
          }
        })
        if (!validCriteria) {
          this.warningMsg(
            this.$t('assignment.please_set_all_criteria_marks'),
            this.$t('form.validation')
          )
        }
      }

      if (validCriteria && validData) {
        return true
      } else {
        return false
      }
    },
    goToNextStudent () {
      this.currentStudent.has_assess = 1
      const nextStudent = this.students.find(item => {
        if (item.seq > this.currentStudent.seq && !item.has_assess) {
          return item
        } else if (!item.has_assess) {
          return item
        }
      })
      this.currentStudent = nextStudent
      this.savingData = {
        mark_as: null,
        student_category: null,
        teacher_feedback: null,
        approved_feedback: null,
        criteria: {},
        notes: [
          { note: '', sent_to: 'parent' },
          { note: '', sent_to: 'student' }
        ]
      }
      this.getStudentTaskDetails()
    },
    async save () {
      const isValidData = await this.validation()
      /* debugger */
      if (!this.currentStudent.has_assess && isValidData) {
        //** Show Loader **//
        this.$vs.loading()
        this.restService
          .assessStudentTask(this.currentStudent.taskId, this.mappingData())
          .then(() => {
            this.successMsg(
              this.$t('form.saved_successfully'),
              this.$t('assessments.task_assessment')
            )

            this.goToNextStudent()
            //** Hide Loader **//
            this.$vs.loading.close()
          })
          .catch(error => {
            //** Hide Loader **//
            this.$vs.loading.close()

            this.errMsg(error.data.message)
          })
      }
    },
    cancel () {
      this.resetProps()
      eventBus.$emit('backToAllTasksList', this.tasksList)
    },
    reducePYPData (data) {
      this.savingData.criteria = [data.key]
    },
    selectGrade (data) {
      this.savingData.criteria = [data]
    }
  },
  computed: {
    ...mapGetters({ loggedInUser: 'getLoggedInUserForPlanner' }),
    notDoneLabel () {
      const dueDate = `${this.task.assessment_date} ${this.task.assessment_time}`
      if (this.formatDateTime(new Date()) > dueDate) {
        return `${this.task.statistics.delayed} ${this.$t(
          'basic.student_delayed'
        )}`
      } else {
        return `${this.task.statistics.not_answered_yet} ${this.$t(
          'basic.student_not_answered_yet'
        )}`
      }
    }
  }
}
