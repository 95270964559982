export default {
  SubjectsCreate: 'Create Subject',
  code: 'Code',
  AcademicYear: 'Academic Year',
  CreatedBy: 'Created By',
  division: 'Division',
  online: 'Online',
  Status: 'Status',
  status: 'Status',
  Actions: 'Actions',
  Subjects: 'Subjects',
  Name: 'Name',
  name: 'Name',
  ChooseDivision: 'Choose Division',
  AllDivisions: 'All Divisions',
  ChooseGrads: 'Choose Grade',
  AllGrades: 'All Grades',
  SubjectsShow: 'Show Subject',
  SubjectsList: 'Subjects List',
  subjects: 'Subjects',
  SubjectsEdit: 'Edit Subjects',
  nickname: 'Nickname',
  grades: 'Grades',
  this_subject_already_exists: 'This Subject Already Exists',
  new_subject_has_been_added_successfully:
    'New Subject  has been added successfully',
  subject_has_not_been_saved__this_division_assigned_subjects_before:
    'Subject has not been saved , this division assigned subjects before',
  The_subject_name_you_are_trying_to_add_is_already_in_use_for_the_same_division:
    'The subject name you are trying' +
    ' to' +
    ' add is already in use for the same division',
  this_subject_used_in_syllbus_so_couldent_update_subject:
    'This subject used in syllabus so could not update subject',
  subject_has_not_been_saved: 'You Need To Choose One Grade At Least',
  Subject_has_not_been_saved_please_complete_your_data:
    'Subject has not been saved please complete your data',

  there_are_no_subjects: 'There are no subjects',
  there_are_not_subjects_in_this_grade: 'There are not subjects in this grade',
  isRequired: 'Is required',
  in_use: 'In use',
  there_are_no_terms: 'There are no terms',
  this_subject_has_not_strands: "this subject hasn't strands",
  strands: 'strands',
  strand: 'strand',
  has_strands: 'has strands',
  has_not_strands: "hasn't strands"
}
