<template>
  <vx-card class="p-3 pt-6 pb-6 md:p-6">
    <form class="vx-roW w-full" @submit="submitForm" @keyup.enter="submitForm">
      <div class="vx-col w-full md:w-1/2 mb-5">
        <label>{{ $t("form.name") }} <sup class="text-danger">*</sup></label>
        <vs-input
          v-model="user.name"
          class="w-full"
          name="name"
          v-validate="'required'"
        />
        <span class="text-danger text-sm">{{
          errors.first("name") | text_formatter
        }}</span>
      </div>
      <div class="vx-col w-full md:w-1/2 mb-6">
        <label>{{ $t("form.email") }} <sup class="text-danger">*</sup></label>
        <vs-input
          v-model="user.email"
          class="w-full"
          name="email"
          v-validate="'required|email'"
        />
        <span class="text-danger text-sm">{{
          errors.first("email") | text_formatter
        }}</span>
      </div>
      <!-- Start Add role -->
      <div
        class="vx-col w-full md:w-1/2 mb-6"
        :class="{ 'flex flex-warp ': userId }"
      >
        <label :class="{ 'w-1/5': userId }"
          >{{ $t("basic.role") }} <sup class="text-danger">*</sup></label
        >
        <template v-if="userId">
          <p class="w-1/2 font-bold">
            {{ user.role_name || user.roleName }}
          </p>
        </template>
        <template v-else>
          <v-select
            class="w-full select-large"
            :clearable="false"
            v-model="user.role_id"
            appendToBody
            v-validate="'required'"
            name="role"
            label="display_name"
            :options="roles"
            :reduce="(role) => role.id"
          />
          <span class="text-danger text-sm">{{ errors.first("role") }}</span>
        </template>
      </div>

      <!-- End Add role -->
      <div class="vx-col w-full flex flex-wrap justify-between">
        <vs-button
          class="mr-3"
          :to="{ name: 'UsersList' }"
          type="border"
          color="warning"
          >{{ $t("form.cancel") }}</vs-button
        >
        <vs-button class="ml-3" @click="submitForm">{{
          $t("form.save")
        }}</vs-button>
      </div>
    </form>
  </vx-card>
</template>
<script>
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const UserManagementRepository = RepositoryFactory.get(
  'ConfigurationModule',
  'userManagement'
)
export default {
  name: 'userForm',
  data () {
    return {
      user: {
        name: null,
        email: null,
        role_id:null
      },
      roles:[]
    }
  },
  computed:{
    userId () {
      return this.$route.params.id || null
    }
  },
  mounted () {
    this.getAllRoles()
  },
  methods: {

    /////////////////
    // API
    /////////////////
    getAllRoles () {
      UserManagementRepository.getAllRoles().then((res) => {
        this.roles = res.data
      })
    },
    getUserData () {
      UserManagementRepository.getUsersRole(this.userId)
        .then(res => {
          this.user = res.data
        })
    },
    createUser () {
      UserManagementRepository.addUser(this.user)
        .then(() => {
          this.successMsg(this.$t('form.saved_successfully_default_password_12345678'))
          this.$router.push({ name: 'UsersList' })
        })
        .catch((err) => this.errMsg(err))
    },
    updateUser () {
      UserManagementRepository.editUsersRole(this.userId, this.user)
        .then((res) => {
          this.successMsg(this.$t('form.saved_successfully'))
          this.$router.push({ name: 'UsersList' })
        }, (error) => {
          this.errMsg(error)
        })

    },
    /////////////////
    // Submit
    /////////////////
    submitForm () {
      this.$validator
        .validate()
        .then((valid) => {
          if (valid) {
            if (this.userId) this.updateUser()
            else this.createUser()
          } else this.errMsg()
        })
        .catch(this.errMsg)
    }
  },
  created () {
    if (this.userId) this.getUserData()
  }
}
</script>
