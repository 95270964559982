import AssignmentGradesModal from './components/assignment-grades-modal/assignment-grades-modal.vue'
import {
  RepositoryFactory
} from '@/Repositories/RepositoryFactory'

export default {
  components: {  AssignmentGradesModal },
  name: 'assignments',
  props: ['params', 'student'],
  data () {
    return {
      StudentRepository: RepositoryFactory.get('AssignmentsModule', 'studentAssignments'),
      assignments: [],
      customEvents: [
        {
          eventName: 'changeModalData',
          callback: this.updateGrid
        }
      ],
      /////////////////////////
      // Pagination
      /////////////////////////
      totalPages: 0,
      currentPage: 1,
      countPerPage: 10
    }
  },
  computed: {

    is_touch_device () {
      return this.$store.state.is_touch_device
    }
  },
  watch: {
    'student' (val) {
      if (this.checkUserRole('Parent')) this.getOneStudentAssignments(val.user_id, this.currentPage)
    },
    currentPage (v) {
      if (this.checkUserRole('Parent')) this.getOneStudentAssignments(this.student.user_id, v)
      else this.getAssignments(v)
    }
  },
  mounted () {
    if (this.checkUserRole('Student')) this.getAssignments()
    else if (this.checkUserRole('Parent')) this.getOneStudentAssignments(this.student.user_id)

  },
  created () {
    ////////////////////////////////////////////////////
    //**          Custom Event Listener           ** //
    //////////////////////////////////////////////////
    this.customEvents.forEach(function (customEvent) {
      // eslint-disable-next-line no-undef
      $sharedEvent.listen(customEvent.eventName, customEvent.callback)
    })
  },

  destroyed () {
    ////////////////////////////////////////////////////
    //**      Remove Custom Event Listener        ** //
    //////////////////////////////////////////////////
    this.customEvents.forEach(function (customEvent) {
      // eslint-disable-next-line no-undef
      $sharedEvent.off(customEvent.eventName, customEvent.callback)
    })
  },
  methods: {
    /////////////////////////
    // API
    ////////////////////////
    assignmentsRequest (endpoint, user_id = null, page = null) {
      //** Show Loader **//
      this.VSLoading()
      this.StudentRepository[endpoint](page, user_id)
        .then(res => {

          this.assignments = res.data.sort((a, b) => b.id - a.id)
          this.setIntervalToCheckDates()
          if (res.per_page) this.setPagination(res)
        })
        .catch(err => {
          console.error(err)
          //** Hide Loader **//


          this.errMsg(err, this.$t('form.loading_data_error'))

        })
        //** Hide Loader **//
        .finally(this.stopVSLoading)
    },
    getAssignments (page = null) {

      this.assignmentsRequest('getStudentAssignments', null, page)
    },
    getOneStudentAssignments (user_id, page) {
      this.assignmentsRequest('getOneStudentAssignments', user_id, page)
    },
    getOneStudentTasks (user_id) {
      this.assignmentsRequest('getOneStudentTasks', user_id)
    },

    openAssignmentModal (data) {
      this.$router.push({
        name: 'answer_assignemnt',
        params: {
          id: data.id
        }
      })
      // $sharedEvent.fire('openAssignmentModal', data)
    },
    openGradesModal (data) {

      if (this.checkUserRole('Student')) this.$router.push({
        name: 'show-grade',
        params: {
          id: data.id
        }
      })
      else if (this.checkUserRole('Parent')) window.$sharedEvent.fire('openAssigmentGradesModal', data)
    },
    setIntervalToCheckDates () {
      this.checkDates()
      setInterval(() => {
        this.checkDates()
      }, 300000)
    },
    checkDates () {
      const currentDate = this.currentDate
      this.assignments.forEach(assignment => {
        // calc dates //
        assignment.daysLeft = this.daysBetweenDates(new Date(`${assignment.assignment_date} ${assignment.assignment_time}`), currentDate)
        // Format Date //
        //assignment.datetime_launch = this.formatDate(assignment.datetime_launch)
        assignment.assignment_date = this.formatDate(assignment.assignment_date)
        if (assignment.task) {
          //** check for task answer delayed or not */
          const dueDate = `${assignment.assignment_date.split('-').reverse().join('-')} ${assignment.assignment_time}`
          if (this.formatDateTime(new Date(assignment.task.submit_date)) <= dueDate) {
            assignment.daysLeftAction = 'submitted'
            assignment.daysLeftText = 'Submitted'
          } else {
            const delayedDays = this.daysBetweenDates(new Date(assignment.task.submit_date), new Date(assignment.assignment_date.split('-').reverse().join('-')))
            // console.log('dleayed date', new Date(assignment.task.submit_date), new Date(assignment.assignment_date.split('-').reverse().join('-')))
            // debugger
            assignment.daysLeftAction = 'submitted'
            assignment.daysLeftText = `Done but Late (${delayedDays})`
          }
        } else if (assignment.daysLeft > 1) {
          assignment.daysLeftAction = 'spare-time'
          assignment.daysLeftText = `${assignment.daysLeft} Days to submit`
        } else if (assignment.daysLeft === 1 || assignment.daysLeft === 0) {
          assignment.daysLeftAction = 'take-action'
          assignment.daysLeftText = 'Time to take action'
        } else if (assignment.daysLeft < 1) {
          assignment.daysLeftAction = 'delayed-time'
          assignment.daysLeftText = `${assignment.daysLeft} Days Late`
        }
      })
    },
    updateGrid (item) {
      const ItemIndex = this.assignments.findIndex(assignment => assignment.id === item.id)
      this.$set(this.assignments, ItemIndex, item)
    },
    canAnswer (task) {
      console.log(task)
      if (task.task && task.task.status.key === 'draft') {
        if (task.daysLeftAction === 'delayed-time' && !task.is_disable_task_date) {
          return true
        } else if (task.daysLeftAction === 'delayed-time' && task.is_disable_task_date) {
          return false
        } else {
          return true
        }
      } else if (task.status.key === 'active' && !task.task) {
        if (task.daysLeftAction === 'delayed-time' && !task.is_disable_task_date) {
          return true
        } else if (task.daysLeftAction === 'delayed-time' && task.is_disable_task_date) {
          return false
        } else {
          return true
        }
      }
    },
    canShowGrades (task) {
      if (task.task && task.task.status.key === 'active' && task.task.submit_date) {
        if (task.task.assess) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    /////////////////////////
    // Pagination
    /////////////////////////
    setPagination (data) {

      this.totalPages = data.last_page
      this.countPerPage = data.per_page
      this.currentPage = data.current_page
    }
  }
}
