<template>
  <div>
      <HodForm :teacher_id="teacher_id"></HodForm>
  </div>
</template>
<script>
import HodForm from '../../../components/HodForm.vue'

export default {
  name:'CreateHod',
  components:{
    HodForm
  },
  created () {

  },
  data () {
    return {
      teacher_id: this.$route.params.id
    }
  }
}
</script>
