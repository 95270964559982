<template>
  <div>
    <vx-card class="p-3 pt-6 pb-6 md:p-6">
      <form @submit.prevent="formSubmitted">
        <!-- Start Name -->
        <div class="vx-row mb-6">
          <div class="vx-col lg:w-1/4 w-full">
            <label>
              {{ $t("basic.name") }}
              <sup class="text-danger">*</sup>
            </label>
          </div>
          <div class="vx-col lg:w-1/2 w-full">
            <vs-input
              v-model="material_form.name"
              name="Name"
              v-validate="{
                required: true,
              }"
              class="w-full"
              :placeholder="`${$t('basic.name')}`"
              type="text"
            />
            <div class="text-danger text-sm text-left">
              {{ errors.first("Name") }}
            </div>
          </div>
        </div>
        <!-- End Name -->

        <!-- Start Subject -->
        <div class="vx-row mb-6">
          <div class="vx-col lg:w-1/4 w-full">
            <label>
              {{ $t("basic.subject") }}
              <sup class="text-danger">*</sup>
            </label>
          </div>
          <div class="vx-col lg:w-1/2 w-full">
            <v-select
              closeOnSelect
              :placeholder="`${$t('basic.subject')}`"
              v-model="material_form.subject_id"
              name="Subject"
              v-validate="'required'"
              class="w-full"
              :options="subjects"
              label="name"
              :reduce="(name) => name.id"
              :disabled="edit_id"
            />
            <div class="text-danger text-sm text-left">
              {{ errors.first("Subject") }}
            </div>
          </div>
        </div>
        <!-- End Subject -->

        <!-- Start Type -->
        <div class="vx-row mb-6">
          <div class="vx-col lg:w-1/4 w-full">
            <label>
              {{ $t("basic.type") }}
              <sup class="text-danger">*</sup>
            </label>
          </div>
          <div class="vx-col lg:w-1/2 w-full">
            <v-select
              multiple
              :placeholder="`${$t('basic.type')}`"
              v-model="material_form.types"
              v-validate="'required'"
              name="Type"
              class="w-full"
              :options="Alltypes"
              label="name"
              :reduce="(name) => name.id"
            />
            <div class="text-danger text-sm text-left">
              {{ errors.first("Type") }}
            </div>
          </div>
        </div>
        <!-- End Type -->

        <!-- Start Upload  -->
        <div class="vx-row mb-6">
          <div class="vx-col lg:w-1/4 w-full" v-if="!edit_id">
            <label>
              {{ $t("exam.upload_attachment") }}
              <sup class="text-danger">*</sup>
            </label>
          </div>
          <div class="vx-col lg:w-3/4 w-full">
            <upload-button
              :numberProp="'50'"
              v-if="!edit_id"
              @uploadedFiles="uploadedFiles"
              class="ml-4"
            ></upload-button>

            <div class="my-2 vx-col w-full" v-if="material_form.files">
              <div class="flex flex-row flex-wrap justify-start">
                <div
                  v-for="(file, idx) in material_form.files"
                  :key="file.document"
                >
                  <feather-icon
                    v-if="!edit_id"
                    @click="deleteDocument(idx, file)"
                    class="mr-2 remove-student-attachment"
                    icon="XIcon"
                    svgClasses="h-5 w-5"
                  />
                  <file-previewer
                    :url="file.document"
                    :urlType="file.type"
                    class="ml-1 my-4 mr-4"
                  ></file-previewer>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Upload -->

        <!-- Actions -->
        <div class="flex flex-wrap justify-between items-center mt-10">
          <vs-button
            color="warning"
            type="border"
            class="inline-block"
            @click="back()"
            >{{ $t("form.cancel") }}</vs-button
          >
          <vs-button class="inline-block" @click="formSubmitted()">
            {{ $t("form.submit") }}
          </vs-button>
        </div>
      </form>
    </vx-card>
  </div>
</template>

<script>
import uploadButton from '@/modules/unit-planner/components/uploadButton/uploadButton.vue'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'

const TeacherRepository = RepositoryFactory.get(
  'TeachersModule',
  'teaching_materials'
)

import { commonDataMixin } from '@/mixins.js'
export default {
  components: {
    uploadButton
  },
  mixins: [commonDataMixin],
  props: ['edit_id', 'material_formProp'],
  data () {
    return {
      material_form: this.material_formProp,

      Alltypes: [],
      subjects: [],
      files: ''
    }
  },

  mounted () {
    this.getAllSubjects()
    this.getTypes()
  },

  methods: {
    getAllSubjects () {
      TeacherRepository.getAllSubjects().then((res) => {
        this.subjects = res.data
      })
    },
    getTypes () {
      TeacherRepository.getTypes().then((res) => {
        this.Alltypes = res.data
      })
    },

    uploadedFiles (data) {
      data.map((item) => {
        item.name = item.attachment
        item.document = item.attachment_url
        delete item.attachment
        delete item.attachment_url
      })

      this.material_form.files.push(...data)
    },
    async deleteDocument (index, file) {
      const { document } = file
      const data = {
        file_path: document
      }
      const isDeletedFromS3 = await this.deleteDocumentFromS3(data)
      if (isDeletedFromS3) {
        this.material_form.files.splice(index, 1)
        if (this.material_form.files.length <= 0) {
          this.files = ''
        }
      } else {
        this.errMsg(this.$t('form.failed_to_delete_documment'))
      }
    },

    formSubmitted () {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          if (!this.edit_id) {
            this.addMaterial()
          } else {
            this.updateMaterial()
          }
        } else {
          this.errMsg(
            this.$t(
              'teachingMaterials.material_has_not_been_saved_please_complete_your_data'
            )
          )
        }
      })
    },
    addMaterial () {
      TeacherRepository.addMaterial(this.material_form).then((res) => {
        this.successMsg(res.message)
        this.$router.push({ name: 'TeachingMaterials' })
      }).catch(err => this.errMsg(err.data.message))
    },
    updateMaterial () {
      delete this.material_form.auth_user
      delete this.material_form.code
      delete this.material_form.created_at
      delete this.material_form.id
      delete this.material_form.is_shared_before
      delete this.material_form.classes
      delete this.material_form.created_by
      delete this.material_form.status
      delete this.material_form.subject_name
      TeacherRepository.update(this.edit_id, this.material_form).then((res) => {
        this.successMsg(res.message)
        this.$router.push({ name: 'TeachingMaterials' })
      })
    },
    back () {
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
.remove-student-attachment svg:hover {
  background: #fff;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  stroke: #000;
}
</style>
