export default {
  chat: 'chat',
  result_show_limit: 'Results will show after typing more then two characters',
  chats: 'chats',
  contacts: 'contacts',
  Start_Conversation: 'Start Conversation',
  class_name: 'class name',
  click_here_for_contact_info: 'click here for contact info',
  seen:'seen',
  delivered_but_not_seen_yet: 'delivered but not seen yet',
  type_your_message: 'Type Your Message',
  this_message_was_deleted: 'this message was deleted',
  you_deleted_this_message: 'you deleted this message',
  create_chat_group: 'create chat group',
  create_a_room: 'create_a_room',
  new_group: 'new group',
  add_group_members: 'add group members',
  group_title: 'group title',
  search_by_name:'Search by name',
  search_by_class: 'Search by class',
  members: 'members',
  participants: 'participants',
  group_admin: 'group admin',
  reconnect: 'reconnect',
  system_not_connected: 'system not connected',
  something_went_wrong_check_your_connection_and:'Something went wrong check your connection and',
  created_group: 'created group',
  add_participant: 'add participant',
  group: 'group',
  groups:'groups',
  group_is_created: 'group is created',
  you_cannot_send_message_to_this_group_because_you_are_no_longer_a_participant:'  you can\'t send message to this group because you are no longer a participant',
  make_group_admin:'make group admin',
  make: 'Make',
  admin:'admin',
  an_admin: 'an admin',
  dismiss_as_admin: 'dismiss as admin',
  exit_group:'exit group',
  exit: 'exit ',
  only_admins_can_send_messages: 'only admins can send messages',
  group_settings: 'group settings',
  you_allowed_only_admin_to_send_messages_to_this_group:'you allowed only admin to send messages to this group',
  you_allowed_all_participants_to_send_messages_to_this_group:'you allowed all participants to send messages to this group',
  you_are_no_longer_an_admin: 'you are no longer an admin',
  you_are_now_an_admin: 'you are now an admin',
  is_now_a_group_admin:'is now a group admin',
  is_no_longer_a_group_admin: 'is no longer a group admin',
  suggested_users: 'suggested users',
  moderator: 'moderator',
  member: 'member',
  left_group: 'left group',
  delete_message: 'delete message',
  message_deleted:'message deleted',
  delete_group: 'delete group',
  group_is_deleted: 'group_is_deleted',
  messages_and_attachment_files_will_be_deleted_permanently: 'messages and attachment files will be deleted permanently'


}
