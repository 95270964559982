import {mapGetters} from 'vuex'
import ModuleMixins from '../../mixins'

export default {
  name: 'unitsMaterialization',
  mixins: [ModuleMixins],
  data () {
    return {
      unitsData: {
        key_concepts: [],
        related_concepts: [],
        atl_skills: [],
        learner_profile: []
      },
      groupedDataArray: [],
      groupBuyId: null,
      groupByItems: null,
      groupedDataObj: null,
      openAllSections: false
    }
  },
  created () {

  },
  mounted () {
  },
  destroyed () {
  },
  methods: {
    groupedCount (data) {
      //** Loop over Object Keys and return data Counted With Key Name **//
      const newArr = []
      for (const [key, value] of Object.entries(data)) {
        const obj = {name: key, count: value.length}
        newArr.push(obj)
      }
      return newArr
    },

    filterAndGroupHelper () {

      this.groupedDataArray = []
      this.groupBuyId = null
      this.groupByItems = null
      this.groupedDataObj = null

      // Loop Over Unites To use Unit Id To Filter Data **//
      this.defaultUnits.forEach(unit => {

        /*debugger*/
        //** Step 1 Filter & Group Data By Unit Id **//
        const filterBy = {key: 'unitId', value: unit.id}
        this.groupBuyId = {
          key_concepts: this.arrGroupBy(this.getGroupedUnitsDetails.key_concepts, 'unitId', filterBy),
          related_concepts: this.arrGroupBy(this.getGroupedUnitsDetails.related_concepts, 'unitId', filterBy),
          atl_skills: this.arrGroupBy(this.getGroupedUnitsDetails.atl_skills, 'unitId', filterBy),
          learner_profile: this.arrGroupBy(this.getGroupedUnitsDetails.learner_profile, 'unitId', filterBy)
        }

        //** Step 2 **//
        //** Group (Filtered & Grouped Data By Unit Id) By Every Selected Item on 4 Approach **//
        this.groupByItems = {
          key_concepts: this.arrGroupBy(this.groupBuyId.key_concepts[`${unit.id}`], 'name'),
          related_concepts: this.arrGroupBy(this.groupBuyId.related_concepts[`${unit.id}`], 'name'),
          atl_skills: this.arrGroupBy(this.groupBuyId.atl_skills[`${unit.id}`], 'name'),
          learner_profile: this.arrGroupBy(this.groupBuyId.learner_profile[`${unit.id}`], 'name')
        }

        //** Step 3  **//
        //** Creat Final Object With GroupedData & Count  then push it to Array **//
        this.groupedDataObj = {
          unitId: unit.id,
          name: unit.name,
          data: {
            keyConcepts: this.groupedCount(this.groupByItems.key_concepts),
            relatedConcepts: this.groupedCount(this.groupByItems.related_concepts),
            atlSkills: this.groupedCount(this.groupByItems.atl_skills),
            learnerProfile: this.groupedCount(this.groupByItems.learner_profile)
          }
        }
        this.groupedDataArray.push(this.groupedDataObj)
      })


      return this.groupedDataArray
    }
  },
  computed: {
    // ** Get Years Group With Data ** //
    ...mapGetters({poiYearsGroup: 'getPoiYearsGroup'}),

    //** Get All POI Units **//
    ...mapGetters({poiUnits: 'getPoiAllUnits'}),

    //** Get Default Units Data {title,desc} **//
    ...mapGetters({defaultUnits: 'getDefaultUnits'}),

    //** Get Calculated Poi Units To use It with  POI Materialization **//
    ...mapGetters({getGroupedUnitsDetails: 'getGroupedUnitsDetails'}),

    materializationGrouped () {
      return this.filterAndGroupHelper()
    }
  }
}
