
<template>
  <vx-card class="p-3 pt-6 pb-6 md:p-6">
    <div class="vx-row mb-6">
      <div class="vx-col sm:w-1/4 w-full">
        <label>
          {{ $t("subject.Name") }}
          <sup class="text-danger">*</sup>
        </label>
      </div>
      <!---------------------------------------------->
      <div class="vx-col sm:w-1/2 w-full">
        <vs-input
          :placeholder="`${$t('subject.Name')}`"
          v-model="Subject.subject_name"
          name="subjectName"
          v-validate="'required'"
          class="selectExample w-full"
        />
        <span
          v-if="issueSubmitted && errors.has('subjectName')"
          class="text-danger text-sm"
          >{{ errors.first("subjectName") | text_formatter }}</span
        >
        <span v-if="nameExist" class="text-danger text-sm">
          {{ $t("subject.this_subject_already_exists") }}</span
        >
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col sm:w-1/4 w-full">
        <label>
          {{ $t("subject.ChooseDivision") }}
          <sup class="text-danger">*</sup>
        </label>
      </div>
      <div class="vx-col sm:w-1/2 w-full">
        <v-select
          closeOnSelect
          @input="changeDivision"
          :placeholder="`${$t('subject.AllDivisions')}`"
          class="selectExample w-full"
          v-model="Subject.divisions"
          name="Division"
          v-validate="'required'"
          :options="divisions"
          label="long_name"
          :reduce="(long_name) => long_name.id"
        />
        <span
          v-if="issueSubmitted && errors.has('Division')"
          class="text-danger text-sm"
          >{{ errors.first("Division") | text_formatter }}</span
        >
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col sm:w-1/4 w-full">
        <label>
          {{ $t("subject.ChooseGrads") }}
          <sup class="text-danger">*</sup>
        </label>
      </div>
      <div class="vx-col sm:w-1/2 w-full">
        <v-select
          multiple
          :placeholder="`${$t('subject.AllGrades')}`"
          v-model="Subject.grades"
          class="selectExample w-full"
          name="Grad"
          v-validate="'required'"
          :options="grads"
          @input="checkDiff"
          label="name"
          :reduce="(name) => name.id"
          :disabled="!Subject.divisions"
        />
        <span
          v-if="issueSubmitted && errors.has('Grad')"
          class="text-danger text-sm"
          >{{ errors.first("Grad") | text_formatter }}</span
        >
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col sm:w-1/4 w-full">
        <label>
          {{ $t("subject.isRequired") }}
          <sup class="text-danger">*</sup>
        </label>
      </div>
      <div class="vx-col sm:w-1/2 w-full">
        <vs-radio
          class="mr-8"
          v-model="Subject.is_optional"
          :vs-value="false"
          >{{ $t("basic.yes") }}</vs-radio
        >
        <vs-radio class="mr-8" v-model="Subject.is_optional" :vs-value="true">{{
          $t("basic.no")
        }}</vs-radio>
      </div>
    </div>
    <div class="flex flex-wrap justify-between items-center">
      <vs-button
        color="warning"
        @click="$router.push({ name: 'SubjectsList' })"
        type="border"
        >{{ $t("basic.cancel") }}</vs-button
      >

      <vs-button @click="saveSubject">{{ $t("basic.submit") }}</vs-button>
    </div>
  </vx-card>
</template>


<script>
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const SubjectsRepository = RepositoryFactory.get('AcademicModule', 'Subjects')

import {commonArrayMethodMixin} from '@/mixins'

export default {
  name: 'edit-subject',
  mixins:[commonArrayMethodMixin],
  components: {},
  data () {
    return {
      id: '',
      allGrads: false,
      nameExist: false,
      name: '',
      inStrtDivision: '',
      error: false,
      saveAgain: true,
      issueSubmitted: false,
      subjects: [],
      arrGrads: [],
      schools: [],
      divisions: [],
      grads: [],
      oldGrades:[],
      Subject: {
        id: '',
        subject_id: '',
        divisions: null,
        grades: []
      }
    }
  },
  created () {
    this.getDropDownData()
    this.id = this.$route.params.id
    this.Subject.id = this.id
    this.getSubjectData(this.id)
  },
  methods: {
    checkDiff () {
      const diff = this.arr_diff(this.Subject.grades, this.oldGrades)
      let isOldSubjectChanged = false
      diff.forEach(element => {
        if (this.oldGrades.includes(Number(element))) isOldSubjectChanged = true
      })

      if (isOldSubjectChanged) {
        this.$nextTick(() => {
          this.Subject.grades = [...new Set([...this.oldGrades, ...this.Subject.grades])]
          this.errMsg(this.$t('Syllabus.you_couldnt_uncheck_choosen_subjects'))
        })
      }
    },

    saveSubject () {
      this.issueSubmitted = true
      this.$validator.validate().then((valid) => {
        if (valid) {
          if (this.saveAgain == true) {
            SubjectsRepository.update(this.id, this.Subject).then(
              (res) => {
                this.nameExist = false
                this.$router.push({ name: 'SubjectsList' })
                this.successMsg(
                  this.$t('subject.new_subject_has_been_added_successfully')
                )

                this.saveAgain = false
              },
              (error) => {
                if (error.data.status == 'exists') {
                  this.errMsg(
                    this.$t(
                      'subject.subject_has_not_been_saved__this_division_assigned_subjects_before'
                    ),
                    this.$t('basic.exists')
                  )
                } else if (error.data.status == 'not avilable') {
                  this.errMsg(
                    this.$t(
                      'subject.this_subject_used_in_syllbus_so_couldent_update_subject'
                    ),
                    this.$t('basic.not_available')
                  )
                } else {
                  this.errMsg(this.$t('subject.subject_has_not_been_saved'))
                }
              }
            )
          }
        } else {
          this.errMsg(
            this.$t(
              'subject.Subject_has_not_been_saved_please_complete_your_data'
            )
          )
        }
      })
    },
    getSubjectData (id) {
      SubjectsRepository.getSubjectData(id).then((res) => {
        this.Subject = res.data
        this.Subject.subject_name = res.data.name
        this.oldGrades = res.data.grades
        // this.grads = res.data.grades
        this.getGrades(this.Subject.divisions)
        this.inStrtDivision = res.data.divisions
      })
    },
    //get greades of spacific division
    getGrades (id) {
      let Grads = []
      SubjectsRepository.getGrades(id).then((res) => {
        this.grads = []

        this.allGrads = false
        this.grads = res.data
        Grads = res.data
      })
      return Grads
    },
    getDivsGrades (divisions) {
      SubjectsRepository.getDivsGrades(divisions).then((res) => {
        // this.round.grades=res.data;
        this.grads = res.data
      })
    },
    //when selected division is one get all greades related to it else
    //select all division of this grade
    changeDivision (event) {
      const divCount = this.Subject.divisions

      //this.Subject.grades=[]
      if (divCount == null) {
        this.getDropDownData()
      } else {
        if (this.Subject.divisions != this.inStrtDivision) {
          this.Subject.grades = []
        }
        this.getGrades(this.Subject.divisions)
      }
    },
    getDropDownData () {
      SubjectsRepository.getDropDownData().then((res) => {
        //  this.schools = res.schools
        this.grads = ['empty']
        this.divisions = res.divisions
      })
    }
  },
  watch: {
    allGrads (val) {
      if (val) this.Subject.grades = this.grads
      else this.Subject.grades = []
    }
  }
}
</script>

<style  scoped>
.background-none {
  background-color: transparent !important;
  color: rgb(160, 152, 152);
  cursor: pointer;
}
</style>
