<template >
  <separatedPage :componentName="dynamicComponent"></separatedPage>
</template>
<script>
import separatedPage from './Components/SeparatedPage.vue'
import reportCards from './Components/Left-Sidebar/Report.vue'
export default {
  name:'parent-report-cards',
  components:{
    separatedPage,
    reportCards
  },
  data: () => ({
    dynamicComponent:reportCards
  })
}
</script>
<style lang="">
</style>
