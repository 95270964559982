<template>
  <ListPageWithCreateButton :showCreateBtn="false">
    <template slot="listDataContent">
      <transition-group class="list-transition" name="list-enter-up" tag="ul">
        <li
          v-for="(val, i) in teachersList"
          :key="val.user_id"
          class="mb-16"
          :style="[{transitionDelay: i * 0.1 + 's'}]">
          <teacher-card :teacher="val"></teacher-card>
        </li>
      </transition-group>
      <div v-if="teachersList.length > 0 && totalPages > 1">
        <vs-pagination
          :total="totalPages"
          :max="countPerPage"
          v-model="currentPage"
          class="mt-6" /></div
    ></template>
    <template slot="rightSide">
      <TeachersFilter
        :filters_prop="custom_filter"
        @refilter="setFilters"
        :loading="loadingFilter">
        <template slot="extra_content">
          <div class="w-full mb-10">
            <vs-input
              :label-placeholder="$t('form.search_by_name')"
              v-model="searchContent"
              class="w-full"
              name="email"
              icon="icon icon-search"
              icon-pack="feather"
              @keyup.enter="getTeacherList()" />
            <span class="text-sm text-danger" v-if="errors.first('email')">{{
              errors.first('email') | text_formatter
            }}</span>
          </div>
        </template>
      </TeachersFilter></template
    >
  </ListPageWithCreateButton>
</template>

<script>
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const teacherPrincipalRepo = RepositoryFactory.get(
  'teachersGatewayModule',
  'teacherPrincipal'
)
import TeachersFilter from '@/components/general/RoundFilter'
import {commonDataMixin} from '@/mixins.js'
import teacherCard from '../components/teacher-card.vue'

export default {
  mixins: [commonDataMixin],
  components: {
    TeachersFilter,
    teacherCard
  },
  data() {
    return {
      // List Data
      teachersList: [],
      sidebar_filter_choosen: {},
      sideFilters: {
        school: [],
        academic_years: [],
        divisions: [],
        sections: [],
        grades: [],
        classes: [],
        subject_ids: []
      },
      subjects: [],
      schools: [],
      custom_filter: [
        {
          id: 1,
          name: this.$t('basic.academic_year'),
          payload_name: 'academic_years',
          onChangeFireAction: true,
          by: []
        },

        {
          id: 2,
          name: this.$t('basic.divisions'),
          payload_name: 'divisions',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 3,
          name: this.$t('basic.sections'),
          payload_name: 'sections',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 4,
          name: this.$t('basic.grades'),
          payload_name: 'grades',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 5,
          name: this.$t('basic.classes'),
          payload_name: 'classes',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 6,
          name: this.$t('basic.subject'),
          payload_name: 'subject_ids',
          by: []
        }
      ],
      // -----End Headers Of Table -----
      // Search
      searchContent: '',
      searchResult: null,
      loading: false,
      loadingFilter: false,
      /////////////////////////
      // Pagination
      /////////////////////////
      totalPages: 0,
      currentPage: 1,
      countPerPage: 10
    }
  },
  watch: {
    searchContent(v) {
      if (!v) this.searchResult = null
    },
    currentPage() {
      this.getTeacherList()
    },
    sideFilters: {
      deep: true,
      handler() {
        this.getTeacherList()
      }
    }
  },

  methods: {
    setFilters(val) {
      this.currentPage = 1
      val.map((item) => {
        this.sideFilters[item.name] = item.checked
      })
    },
    getTeacherList() {
      this.VSLoading()
      const params = {
        page: this.currentPage,
        teacher: this.searchContent,
        ...this.sideFilters
      }
      teacherPrincipalRepo
        .getTeacherList(params)
        .then((res) => {
          const {data, ...pagination} = res.data
          this.setPagination(pagination)
          this.teachersList = data
        })
        .catch((err) => {
          console.log('err', err)
        })
        .finally(() => this.stopVSLoading())
    },
    setPagination(data) {
      this.totalPages = data.total_pages || data.last_page
      this.countPerPage = data.per_page
      this.currentPage = data.current_page
    },
    ///////////////////////////////////
    // search
    ///////////////////////////////////
    search() {
      this.loading = true

      teacherPrincipalRepo
        .search(this.searchContent)
        .then((res) => {
          this.searchResult = res.data
        })
        .catch(this.errMsg)
        .finally(() => {
          this.loading = false
        })
    },
    ///////////////////////////////////
    // get Filters Data
    ///////////////////////////////////
    getFiltersData() {
      this.custom_filter[1]['by'] = []
      this.custom_filter[2]['by'] = []
      this.custom_filter[3]['by'] = []
      this.custom_filter[4]['by'] = []
      this.custom_filter[5]['by'] = []
      const schools = [this.sideFilters.school]
      this.getFilterData({schools}).then((res) => {
        res.divisions.map((el) => {
          this.custom_filter[1]['by'].push({
            id: el.id,
            name: el.name,
            checked: false
          })
        })
      })
      this.getAllAcademic().then((res) => {
        res.map((el) => {
          this.custom_filter[0]['by'].push({
            id: el._id,
            name: el.name,
            checked: false
          })
        })
      })
    },
    getFilteredSectionsAndSubjects(chosen) {
      this.loadingFilter = true
      this.custom_filter[2]['by'] = []
      this.custom_filter[3]['by'] = []
      this.custom_filter[4]['by'] = []
      this.custom_filter[5]['by'] = []
      const data = {
        divisions: chosen.divisions,
        schools: chosen.school
      }
      this.getFilterData(data)
        .then((res) => {
          res.sections.map((el) => {
            this.custom_filter[2]['by'].push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
          res.divisions_subjects.map((el) => {
            this.custom_filter[5]['by'].push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
        })
        .finally(() => {
          this.loadingFilter = false
        })
    },
    getFilteredGrades(chosen) {
      this.loadingFilter = true
      this.custom_filter[3]['by'] = []
      this.custom_filter[4]['by'] = []
      const data = {
        sections: chosen.sections,
        divisions: chosen.divisions,
        schools: chosen.school
      }
      this.getFilterData(data)
        .then((res) => {
          res.grades.map((el) => {
            this.custom_filter[3]['by'].push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
        })
        .finally(() => {
          this.loadingFilter = false
        })
    },
    getFilteredClasses(chosen) {
      this.loadingFilter = true
      this.custom_filter[4]['by'] = []
      const data = {
        sections: chosen.sections,
        divisions: chosen.divisions,
        schools: chosen.school,
        grades: chosen.grades
      }
      this.getFilterData(data)
        .then((res) => {
          res.grades_classes.map((el) => {
            this.custom_filter[4]['by'].push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
        })
        .finally(() => {
          this.loadingFilter = false
        })
    }
  },
  created() {
    this.sideFilters.school = this.authUser.school_id
    this.$root.$on('onChange:divisions', (data) => {
      this.getFilteredSectionsAndSubjects(data)
    })
    this.$root.$on('onChange:sections', (data) => {
      this.getFilteredGrades(data)
    })
    this.$root.$on('onChange:grades', (data) => {
      this.getFilteredClasses(data)
    })
    this.getFiltersData()
    this.getTeacherList()
  },
  beforeDestroy() {
    this.$root.$off('onChange:divisions')
    this.$root.$off('onChange:sections')
    this.$root.$off('onChange:grades')
  }
}
</script>
