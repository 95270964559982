<template>
  <div>
    <vx-card class="p-relative">
      <!--------------- Action Buttons --------------->
      <vs-dropdown
        class="actionButtons"
        v-if="canEdit"
        vs-custom-content
        vs-trigger-click
      >
        <vs-button color="#999" type="flat" icon="more_vert"></vs-button>

        <vs-dropdown-menu>
          <vs-dropdown-item @click="copyJoinMeetingLnk()">
            <span class="flex items-center">
              <feather-icon icon="CopyIcon" svgClasses="h-4 w-4 mr-4" />
              <span>{{ $t("onlineClass.copy_the_join_link") }}</span>
            </span>
          </vs-dropdown-item>
          <vs-dropdown-item @click="deleteQuickMeeting">
            <span class="flex items-center">
              <feather-icon
                icon="TrashIcon"
                svgClasses="h-4 w-4"
                class="mr-2"
              />
              <span>{{ $t("basic.delete") }}</span>
            </span>
          </vs-dropdown-item>
        </vs-dropdown-menu>
      </vs-dropdown>

      <!------------------ Content  ------------------>
      <div class="min_height_110 vx-row items-center p-1 pt-1 pb-1 md:p-1">
        <!-- Name -->
        <div class="vx-col w-full lg:w-1/3 md:w-1/2 lg:mb-0 mb-4">
          <div class="flex lg:flex-col justify-between align-center flex-row">
            <h5 class="mb-2">
              {{ $t("onlineClass.quickMeetings") }} {{ quickMeeting.id }}
            </h5>
            <p
              class="text-capitalized"
              v-if="canSeeTeacherName && quickMeeting.teacher"
            >
              {{ quickMeeting.teacher }}
            </p>
          </div>
        </div>
        <!-- Date && Time -->
        <div class="vx-col w-full lg:w-1/3 md:w-1/2 lg:mb-0 mb-4">
          <p class="mb-1 text-sm xl:block inline-block xl:mr-0 mr-5">
            <feather-icon icon="CalendarIcon" svgClasses="h-4 w-4 mr-2 " />
            {{ quickMeeting.created_at }}
          </p>
        </div>
        <!-- If Not recordered -->
        <template v-if="quickMeeting.time_status !== 'done'">
          <!-- Meetting Links -->
          <div class="vx-col w-full lg:w-1/3 md:w-1/2 lg:mb-0 mb-4">
            <div class="w-6/5 m-auto">
              <vs-chip
                class="flex justify-between mb-3"
                :class="`status--dark ml-auto mr-auto float-none`"
                style="cursor: pointer"
              >
                <span class="text-left">{{
                  $t("onlineClass.quickMeeting_link") | text_formatter
                }}</span>
                <div>
                  <!-- <vx-tooltip
                    v-clipboard="meetingLink"
                    v-clipboard:success="onCopy"
                    :text="$t('onlineClass.clickCopy')"
                    class="text-right inline-block"
                  >
                    <feather-icon
                      icon="CopyIcon"
                      title="view"
                      svgClasses="h-4 w-4 mr-4 hover:text-primary cursor-pointer"
                    />
                  </vx-tooltip> -->
                  <vx-tooltip
                    :text="$t('onlineClass.run_meeting')"
                    class="text-right inline-block"
                  >
                    <button @click="runMeeting" class="no_style">
                      <feather-icon
                        icon="PlayCircleIcon"
                        title="run"
                        svgClasses="h-4 w-4   cursor-pointer"
                      />
                    </button>
                  </vx-tooltip>
                </div>
              </vs-chip>

              <!-- <vs-chip
                class="flex justify-between"
                :class="`status--dark ml-auto mr-auto float-none`"
                style="cursor: pointer"
              >
                <span class="text-left">{{
                  $t("onlineClass.students_online_class_link")
                }}</span>

                <vx-tooltip
                  v-clipboard="meetingLink"
                  v-clipboard:success="onCopy"
                  :text="$t('onlineClass.clickCopy')"
                  class="text-right"
                >
                  <feather-icon
                    icon="CopyIcon"
                    title="copy"
                    svgClasses="h-4 w-4  cursor-pointer"
                  />
                </vx-tooltip>
              </vs-chip> -->
            </div>
          </div>
        </template>
      </div>
      <!-- -------------------- play Record Popup ----------------- -->
      <template v-if="playActive">
        <vs-popup
          class="holamundo"
          :title="playRecordPopupTitle"
          :active.sync="playActive"
        >
          <iframe
            width="100%"
            height="550"
            :src="`https://records.schoolia-eg.com/?meetingId=${quickMeeting.internal_meeting_id}`"
          ></iframe>
        </vs-popup>
      </template>
    </vx-card>
  </div>
</template>
<script>

import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
import axios from 'axios'

const TeacherRepository = RepositoryFactory.get(
  'TeachersModule',
  'OnlineClass'
)
export default {
  name: 'quick-meeting-card',
  props: {
    quickMeetingProp: {
      type: Object,
      required: true
    },
    userType: {
      type: String,
      default: () => {
        const authUser = JSON.parse(window.localStorage.getItem('authUser')),
          userRule = authUser.userRole
        return ['Teacher', 'teaher'].some(el => userRule.includes(el)) ? 'teacher' : userRule
      }
    }
  },

  data () {
    return {
      quickMeeting: this.quickMeetingProp,
      playActive: false,
      startMeetingLink:''

    }
  },
  created () {
  },
  computed: {
    institutionLink () {
      // return this.$store.state.institutionLink
      return `${window.location.origin}`

    },
    createLink () {
      return `${this.institutionLink}#/quick-meeting/create/${this.quickMeeting.id}`
    },
    joinLink () {
      return `${this.institutionLink}#/quick-meeting/join/${this.quickMeeting.id}`

    },
    playRecordPopupTitle () {

      return `${this.quickMeeting.id}  ( ${this.quickMeeting.id})`
    },
    // playLink () {
    //   return `https://records.schoolia-eg.com/?meetingId=${this.quickMeeting.internal_meeting_id}`
    // },
    // downloadLink () {
    //   return `https://records.schoolia-eg.com/?meetingId=${this.quickMeeting.internal_meeting_id}`
    // },
    playerOptions () {
      return {
        height: '660',
        fluid: true,
        autoplay: false,
        muted: false,
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        // sources:`https://records.schoolia-eg.com/?meetingId=${this.onlineClass.id}`,
        // sources:{ type: 'video/mp4', src: 'https://records.schoolia-eg.com/?meetingId=bb867fd15124ec4fa5629fa4eb9b507768dca83c-1601301472424' },
        poster: 'logo.png'
      }
    },
    canEdit () {
      return this.checkUserRole('Teacher')

    },
    canSeeClassName () {
      return this.checkUserRole('Teacher') || this.checkUserRole('SchoolPrincipal')
    },
    canSeeTeacherName () {
      return this.checkUserRole('SchoolPrincipal')
    }

  },
  watch: {},
  methods: {
    ////////////////////////////
    // APIs
    ////////////////////////////
    getStartMeetingLink () {
      return new Promise((resolve, reject) => {
        axios
          .get(`/bbb-integration/start-quick-meeting/${this.quickMeeting.id}`)
          .then((res) => resolve(res))
          .catch((err) => {
            this.errMsg(err)
            reject(err)
          })
      })
    },
    getJoinMeetingLink () {
      return new Promise((resolve, reject) => {
        axios
          .get(`/bbb-integration/join-quick-meeting/${this.quickMeeting.id}`)
          .then((res) => resolve(res))
          .catch((err) => {
            this.errMsg(err)
            reject(err)
          })
      })
    },
    check_start_end_time (id) {
      const data = new FormData()
      data.append('id', id)
      TeacherRepository.check_start_end_time(data).then((res) => {
        if (res.data.is_allowed) {
          // this.$router.push(`/bbb/create/${id}`)
          this.runMeeting()
        } else {
          this.errMsg(res.data.message)
        }
      })
    },
    /////////////////////////////////
    // Meeting
    ////////////////////////////////
    copyJoinMeetingLnk () {
      this.getJoinMeetingLink()
        .then((res) => {
          navigator.clipboard.writeText(res)
          this.successMsg(this.$t('basic.Text_copied_successfully'))
        })
        .catch((err) => {
          navigator.clipboard.writeText('')
        })
    },


    runMeeting () {
      const isTeacher = this.checkUserRole('Teacher')
      if (isTeacher) {
        this.getStartMeetingLink()
          .then((res) => {
            this.startMeetingLink = res
            window.open(res, '_blank')
          })
      } else {
        this.getJoinMeetingLink()
          .then((res) => {
            window.open(res, '_blank')
          })
      }
    },
    playRecord () {
      this.playActive = true
    },
    downloadRecord () {
    },
    /////////////////////////////////
    // Actions APIs
    ////////////////////////////////
    deleteQuickMeeting () {
      TeacherRepository.deleteQuickMeeting(this.quickMeeting.id)
        .then((res) => {
          this.$emit('reloadData', true)
        })
      this.$forceUpdate()
    }
  }
}
</script>
<style lang="scss">
.con-vs-chip.flex.justify-between {
  .text-chip.vs-chip--text {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

iframe {
  border: 1px solid #ccc;
}
</style>
