<template>
  <div>
    <vs-card class="p-3 py-6 md:p-6">
      <form
        class="flex justify-between flex-col"
        @submit.prevent="submitForm"
        @keyup.enter="submitForm">
        <div class="mb-4 mt-auto w-full vx-row items-center mx-auto">
          <!-- ////////////////////////////////
                  Full Name
          ///////////////////////////////////// -->
          <!-- In English -->
          <vs-divider class="text-capitalize vx-col w-full">
            {{ $t('form.fullName') }} {{ $t('form.In_English') }}</vs-divider
          >
          <template>
            <!-- First name -->
            <div class="mb-6 vx-col lg:w-1/4 md:w-1/2 w-full">
              <vs-input
                v-model="info.first_name"
                class="w-full my-2"
                name="firstName"
                :placeholder="$t('basic.first_name')"
                v-validate="{
                  required: true,
                  alpha_spaces: true,
                  regex: /^[a-zA-Z\s]*$/
                }" />
              <span class="text-danger text-sm">{{
                errors.first('firstName')
              }}</span>
            </div>
            <!-- Middle name -->
            <div class="mb-6 vx-col lg:w-1/4 md:w-1/2 w-full">
              <vs-input
                v-model="info.middle_name"
                name="middlName"
                class="w-full my-2"
                :placeholder="$t('basic.second_name')"
                v-validate="{
                  required: true,
                  alpha_spaces: true,
                  regex: /^[a-zA-Z\s]*$/
                }" />
              <span class="text-danger text-sm">{{
                errors.first('middlName')
              }}</span>
            </div>
            <!-- Last name -->
            <div class="mb-6 vx-col lg:w-1/4 md:w-1/2 w-full">
              <vs-input
                v-model="info.last_name"
                name="lastName"
                class="w-full my-2"
                :placeholder="$t('basic.third_name')"
                v-validate="{
                  required: true,
                  alpha_spaces: true,
                  regex: /^[a-zA-Z\s]*$/
                }" />
              <span class="text-danger text-sm">{{
                errors.first('lastName')
              }}</span>
            </div>
            <!-- Forth  name -->
            <div class="mb-6 vx-col lg:w-1/4 md:w-1/2 w-full">
              <vs-input
                v-model="info.forth_name"
                name="forthName"
                class="w-full my-2"
                :placeholder="$t('basic.forth_name')"
                v-validate="{
                  required: true,
                  alpha_spaces: true,
                  regex: /^[a-zA-Z\s]*$/
                }" />
              <span class="text-danger text-sm">{{
                errors.first('forthName')
              }}</span>
            </div>
          </template>

          <vs-divider class="text-capitalize vx-col w-full">
            {{ $t('form.fullName') }} {{ $t('form.In_Arabic') }}</vs-divider
          >
          <!-- In Arabic -->
          <template>
            <!-- First name -->
            <div class="mb-6 vx-col lg:w-1/4 md:w-1/2 w-full">
              <vs-input
                v-model="info.ar_first_name"
                class="w-full my-2"
                name="arabic_FirstName"
                :placeholder="$t('basic.first_name')"
                v-validate="{
                  required: true,
                  alpha_spaces: true,
                  regex: /^[\u0600-\u06FF-\s]*$/
                }" />
              <span class="text-danger text-sm">{{
                errors.first('arabic_FirstName') | text_formatter
              }}</span>
            </div>
            <!-- Middle name -->
            <div class="mb-6 vx-col lg:w-1/4 md:w-1/2 w-full">
              <vs-input
                v-model="info.ar_middle_name"
                name="arabic_middlName"
                class="w-full my-2"
                :placeholder="$t('basic.second_name')"
                v-validate="{
                  required: true,
                  alpha_spaces: true,
                  regex: /^[\u0600-\u06FF-\s]*$/
                }" />
              <span class="text-danger text-sm">{{
                errors.first('arabic_middlName') | text_formatter
              }}</span>
            </div>
            <!-- Last name -->
            <div class="mb-6 vx-col lg:w-1/4 md:w-1/2 w-full">
              <vs-input
                v-model="info.ar_last_name"
                name="arabic_lastName"
                class="w-full my-2"
                :placeholder="$t('basic.third_name')"
                v-validate="{
                  required: true,
                  alpha_spaces: true,
                  regex: /^[\u0600-\u06FF-\s]*$/
                }" />
              <span class="text-danger text-sm">{{
                errors.first('arabic_lastName') | text_formatter
              }}</span>
            </div>
            <!-- Forth  name -->
            <div class="mb-6 vx-col lg:w-1/4 md:w-1/2 w-full">
              <vs-input
                v-model="info.ar_forth_name"
                name="arabic_forthName"
                class="w-full my-2"
                :placeholder="$t('basic.forth_name')"
                v-validate="{
                  required: true,
                  alpha_spaces: true,
                  regex: /^[\u0600-\u06FF-\s]*$/
                }" />
              <span class="text-danger text-sm">{{
                errors.first('arabic_forthName') | text_formatter
              }}</span>
            </div>
          </template>

          <vs-divider class="text-capitalize vx-col w-full">
            {{ $t('form.student_information') }}
          </vs-divider>
          <!-- ////////////////////////////////
                      Other  details
          ///////////////////////////////////// -->
          <!-- Nationality -->
          <div class="my-2 vx-col lg:w-1/2 w-full">
            <label
              >{{ $t('profile.Nationality')
              }}<sup class="text-danger">*</sup></label
            >
            <v-select
              multiple
              :closeOnSelect="true"
              v-model="info.nationality"
              :options="nationalities"
              label="name"
              :reduce="(name) => name.id"
              v-validate="'required'"
              name="nationality"
              class="w-full mb-2"
              :disabled="nationalities ? false : true" />

            <span class="text-danger text-sm">{{
              errors.first('nationality')
            }}</span>
          </div>
          <!-- Student ID -->
          <div class="my-2 vx-col lg:w-1/2 w-full">
            <!-- Student Id  -->
            <label
              >{{ $t('basic.student_national_id')
              }}<sup class="text-danger">*</sup></label
            >
            <vs-input
              type="text"
              v-model="info.student_id"
              class="w-full mb-2"
              name="studentID"
              v-validate="'required'"
              :placeholder="
                $t('admission.Located_at_the_student_birth_certificate')
              " />
            <span class="text-danger text-sm">{{
              errors.first('studentID')
            }}</span>
          </div>
          <!-- Student Code -->
          <div class="my-2 vx-col lg:w-1/2 w-full">
            <!-- Student Code  -->
            <label>{{ $t('form.code') }}<sup class="text-danger">*</sup></label>
            <vs-input
              type="text"
              v-model="info.code"
              class="w-full mb-2"
              name="studentCode"
              v-validate="'required'" />
            <span class="text-danger text-sm">{{
              errors.first('studentCode')
            }}</span>
          </div>
          <!-- Gender -->
          <div class="vx-col md:w-1/2 w-full my-2">
            <label class
              >{{ $t('basic.gender') }}<sup class="text-danger">*</sup></label
            >
            <v-select
              v-model.number="info.gender"
              name="gender"
              class="w-full mb-2"
              v-validate="'required'"
              :options="genders"
              label="name"
              :reduce="(name) => name.id" />
            <span class="text-danger text-sm">{{
              errors.first('gender')
            }}</span>
          </div>
          <!-- Birth date -->
          <div class="my-2 vx-col md:w-1/2 w-full">
            <label
              >{{ $t('basic.birth_date') }}
              <sup class="text-danger">*</sup></label
            >
            <flat-pickr
              class="w-full mb-2"
              :config="configdatePicker"
              v-model="info.birthingDate"
              v-validate="'required'"
              name="birthDate" />
            <span class="text-danger text-sm">{{
              errors.first('birthDate')
            }}</span>
          </div>
          <!-- Religion -->
          <div class="vx-col lg:w-1/2 w-full my-2">
            <label class
              >{{ $t('profile.Religion')
              }}<sup class="text-danger">*</sup></label
            >
            <v-select
              v-model.number="info.religion"
              name="religion"
              class="w-full mb-2"
              v-validate="'required'"
              :options="religions"
              label="name"
              :reduce="(name) => name.id" />
            <span class="text-danger text-sm">{{
              errors.first('religion')
            }}</span>
          </div>
          <!-- Other Religion -->
          <div class="vx-col lg:w-1/2 w-full my-2" v-if="info.religion < 0">
            <label class
              >{{ $t('basic.other_religion')
              }}<sup class="text-danger">*</sup></label
            >
            <vs-input
              name="religion"
              class="w-full mb-2"
              v-model="info.other_religion"
              v-validate="'required'"
              :placeholder="$t('form.please_enter_or_religion')"></vs-input>
          </div>
          <!-- academicYears -->
          <div class="my-2 vx-col lg:w-1/2 w-full">
            <label
              >{{ $t('basic.academic_year')
              }}<sup class="text-danger">*</sup></label
            >
            <v-select
              :closeOnSelect="true"
              v-model="info.Academic_id"
              :options="allAcademicYears"
              label="name"
              :reduce="(option) => option._id"
              v-validate="'required'"
              name="academicYears"
              class="w-full mb-2"
              :disabled="allAcademicYears ? false : true" />

            <span class="text-danger text-sm">{{
              errors.first('academicYears')
            }}</span>
          </div>

          <!-- Divisions -->
          <div class="my-2 vx-col lg:w-1/2 w-full">
            <label
              >{{ $t('basic.divisions')
              }}<sup class="text-danger">*</sup></label
            >
            <v-select
              :closeOnSelect="true"
              v-model="info.division_id"
              :options="divisions"
              label="long_name"
              :reduce="(item) => item.id"
              v-validate="'required'"
              name="divisions"
              class="w-full mb-2"
              @input="getGrades(info.division_id), (info.grade_id = '')"
              :disabled="divisions ? false : true" />

            <span class="text-danger text-sm">{{
              errors.first('divisions')
            }}</span>
          </div>
          <!-- Grades -->
          <div class="my-2 vx-col lg:w-1/2 w-full">
            <label
              >{{ $t('basic.grade') }}<sup class="text-danger">*</sup></label
            >
            <v-select
              :closeOnSelect="true"
              v-model="info.grade_id"
              :options="grades"
              label="name"
              :reduce="(name) => name.id"
              v-validate="'required'"
              name="grade"
              class="w-full mb-2"
              :disabled="grades && info.division_id ? false : true"
              @input="getClassByGradeId()" />

            <span class="text-danger text-sm">{{ errors.first('grade') }}</span>
          </div>

          <!-- Class group -->
          <div class="my-2 vx-col lg:w-1/2 w-full" v-if="isEdit">
            <label>{{ $t('basic.class_group') }}</label>
            <v-select
              :closeOnSelect="true"
              v-model="info.class_id"
              :options="classes"
              label="name"
              :reduce="(name) => name.id"
              class="w-full mb-2"
              :disabled="
                grades && info.division_id && info.grade_id ? false : true
              " />
          </div>
          <!--  username -->
          <div class="my-2 vx-col lg:w-1/2 w-full" v-if="info.username">
            <label>
              {{ $t('basic.username') }}
            </label>
            <vs-input v-model="info.username" readonly class="w-full" />
          </div>
          <!--  Password -->
          <div class="my-2 vx-col lg:w-1/2 w-full">
            <label
              >{{ $t('form.password') }}
              <sup class="text-danger" v-if="!isEdit">*</sup>
            </label>

            <vx-input-group class="w-full mb-2 items-stretch">
              <vs-input
                v-model="password"
                class="w-full h-full"
                name="password"
                v-validate="{required: !isEdit, min: 7}" />
              <!-- Add Another -->
              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button
                    type="border"
                    class="text-sm p-2"
                    @click="generatePassword">
                    {{ $t('form.generate_password') }}
                  </vs-button>
                </div>
              </template>
            </vx-input-group>

            <span class="text-danger text-sm" v-if="errors.has('password')">{{
              errors.first('password') | text_formatter
            }}</span>
          </div>

          <!--
          ---------------- Contact Information -----------------
        -->
          <vs-divider class="text-capitalize vx-col w-full">
            {{ $t('form.contact_information') }}</vs-divider
          >

          <!-- Student Email -->
          <div class="my-2 vx-col lg:w-1/2 w-full">
            <label
              >{{ $t('form.student_email')
              }}<sup class="text-danger">*</sup></label
            >
            <vs-input
              v-model="info.official_email"
              name="studentEmail"
              class="w-full mb-2"
              v-validate="{
                required: true,
                email: true
              }" />
            <span class="text-danger text-sm">{{
              errors.first('studentEmail')
            }}</span>
          </div>
          <!-- father_official_email -->
          <div class="my-2 vx-col lg:w-1/2 w-full">
            <label
              >{{ $t('form.father_official_email')
              }}<sup class="text-danger">*</sup></label
            >
            <vs-input
              v-model="info.father_official_email"
              name="fatheOfficialEmail"
              class="w-full mb-2"
              :placeholder="$t('form.father_official_email')"
              v-validate="{
                required: true,
                email: true
              }" />
            <span class="text-danger text-sm">{{
              errors.first('fatheOfficialEmail')
            }}</span>
          </div>
          <!-- mother_official_email -->
          <div class="my-2 vx-col lg:w-1/2 w-full">
            <label
              >{{ $t('form.mother_official_email')
              }}<sup class="text-danger">*</sup></label
            >
            <vs-input
              v-model="info.mother_official_email"
              name="motherOfficialEmail"
              class="w-full mb-2"
              :placeholder="$t('form.mother_official_email')"
              v-validate="{
                required: true,
                email: true
              }" />
            <span class="text-danger text-sm">{{
              errors.first('motherOfficialEmail')
            }}</span>
          </div>

          <!-- mailing_email -->
          <div class="my-2 vx-col lg:w-1/2 w-full">
            <label
              >{{ $t('form.mailing_email')
              }}<sup class="text-danger">*</sup></label
            >
            <vs-input
              v-model="info.mailing_email"
              name="maillingEmail"
              class="w-full mb-2"
              :placeholder="$t('form.mailing_email')"
              v-validate="'required|email'" />
            <span class="text-danger text-sm">{{
              errors.first('maillingEmail')
            }}</span>
          </div>

          <div
            class="my-2 vx-col lg:w-1/2 w-full"
            v-if="Object.values(studentInfo).length > 0">
            <vs-checkbox
              v-model="info.resend_mail"
              name="resend_email"
              class="mb-3"
              >{{ $t('form.resend_email') }}
            </vs-checkbox>

            <span class="text-danger text-sm">{{
              errors.first('resend_email') | text_formatter
            }}</span>
          </div>

            <!-- ////////////////////////////////
          Full Name
          ///////////////////////////////////// -->
          <!-- In English -->
          <vs-divider class="text-capitalize vx-col w-full">
            {{ $t('form.fullName') }} {{ $t('form.parents_info') }}</vs-divider
          >
          <template>
            <!-- First name -->
            <div class="mb-6 vx-col lg:w-1/4 md:w-1/2 w-full">
              <vs-input
                v-model="info.father_first_name"
                class="w-full my-2"
                name="fatherFirstName"
                :placeholder="$t('basic.father_first_name')"
                v-validate="{
                  required: true,
                  alpha_spaces: true,
                  regex: /^[a-zA-Z\s]*$/
                }" />
              <span class="text-danger text-sm">{{
                errors.first('fatherFirstName')
              }}</span>
            </div>
            <!-- Middle name -->
            <div class="mb-6 vx-col lg:w-1/4 md:w-1/2 w-full">
              <vs-input
                v-model="info.father_middle_name"
                name="fatherMiddleName"
                class="w-full my-2"
                :placeholder="$t('basic.father_second_name')"
                v-validate="{
                  required: true,
                  alpha_spaces: true,
                  regex: /^[a-zA-Z\s]*$/
                }" />
              <span class="text-danger text-sm">{{
                errors.first('fatherMiddleName')
              }}</span>
            </div>
            <!-- Last name -->
            <div class="mb-6 vx-col lg:w-1/4 md:w-1/2 w-full">
              <vs-input
                v-model="info.father_last_name"
                name="fatherLastName"
                class="w-full my-2"
                :placeholder="$t('basic.father_third_name')"
                v-validate="{
                  required: true,
                  alpha_spaces: true,
                  regex: /^[a-zA-Z\s]*$/
                }" />
              <span class="text-danger text-sm">{{
                errors.first('fatherLastName')
              }}</span>
            </div>
            <!-- Forth  name -->
            <div class="mb-6 vx-col lg:w-1/4 md:w-1/2 w-full">
              <vs-input
                v-model="info.father_forth_name"
                name="fatherForthName"
                class="w-full my-2"
                :placeholder="$t('basic.father_forth_name')"
                v-validate="{
                  required: true,
                  alpha_spaces: true,
                  regex: /^[a-zA-Z\s]*$/
                }" />
              <span class="text-danger text-sm">{{
                errors.first('fatherForthName')
              }}</span>
            </div>
          </template>
          <template>
            <!-- First name -->
            <div class="mb-6 vx-col lg:w-1/4 md:w-1/2 w-full">
              <vs-input
                v-model="info.mother_first_name"
                class="w-full my-2"
                name="motherFirstName"
                :placeholder="$t('basic.mother_first_name')"
                v-validate="{
                  required: true,
                  alpha_spaces: true,
                  regex: /^[a-zA-Z\s]*$/
                }" />
              <span class="text-danger text-sm">{{
                errors.first('motherFirstName')
              }}</span>
            </div>
            <!-- Middle name -->
            <div class="mb-6 vx-col lg:w-1/4 md:w-1/2 w-full">
              <vs-input
                v-model="info.mother_middle_name"
                name="motherMiddleName"
                class="w-full my-2"
                :placeholder="$t('basic.mother_second_name')"
                v-validate="{
                  required: true,
                  alpha_spaces: true,
                  regex: /^[a-zA-Z\s]*$/
                }" />
              <span class="text-danger text-sm">{{
                errors.first('motherMiddleName')
              }}</span>
            </div>
            <!-- Last name -->
            <div class="mb-6 vx-col lg:w-1/4 md:w-1/2 w-full">
              <vs-input
                v-model="info.mother_last_name"
                name="motherLastName"
                class="w-full my-2"
                :placeholder="$t('basic.mother_third_name')"
                v-validate="{
                  required: true,
                  alpha_spaces: true,
                  regex: /^[a-zA-Z\s]*$/
                }" />
              <span class="text-danger text-sm">{{
                errors.first('motherLastName')
              }}</span>
            </div>
            <!-- Forth  name -->
            <div class="mb-6 vx-col lg:w-1/4 md:w-1/2 w-full">
              <vs-input
                v-model="info.mother_forth_name"
                name="motherForthName"
                class="w-full my-2"
                :placeholder="$t('basic.mother_forth_name')"
                v-validate="{
                  required: true,
                  alpha_spaces: true,
                  regex: /^[a-zA-Z\s]*$/
                }" />
              <span class="text-danger text-sm">{{
                errors.first('motherForthName')
              }}</span>
            </div>
          </template>
          <template>
            <!-- Key -->
            <div class="mb-6 vx-col lg:w-1/4 md:w-1/2 w-full">
              <v-select
                  v-model="info.father_official_mobile.code"
                  name="father_mobile_code"
                  class="w-full"
                  v-validate="'required'"
                  :options="mobile_keys"
                />

                <span class="text-danger text-sm">
                  {{ errors.first("father_mobile_code") | text_formatter }}
                </span>
            </div>

            <div class="mb-6 vx-col lg:w-1/4 md:w-1/2 w-full">
               <vs-input
                  v-model="info.father_official_mobile.number"
                  class="w-full"
                  :placeholder="$t('basic.father_official_mobile')"
                  name="father_official_mobile"
                  v-validate="'required|numeric|length:8'"
                />

                <span class="text-danger text-sm">
                  {{ errors.first("father_official_mobile") | text_formatter }}
                </span>
            </div>
            <!-- Key -->
            <div class="mb-6 vx-col lg:w-1/4 md:w-1/2 w-full">
              <v-select
                  v-model="info.mother_official_mobile.code"
                  name="mother_mobile_code"
                  class="w-full"
                  v-validate="'required'"
                  :options="mobile_keys"
                />

                <span class="text-danger text-sm">
                  {{ errors.first("mother_mobile_code") | text_formatter }}
                </span>
            </div>

            <div class="mb-6 vx-col lg:w-1/4 md:w-1/2 w-full">
               <vs-input
                  v-model="info.mother_official_mobile.number"
                  :placeholder="$t('basic.mother_official_mobile')"
                  class="w-full"
                  name="mother_official_mobile"
                  v-validate="'required|numeric|length:8'"
                />

                <span class="text-danger text-sm">
                  {{ errors.first("mother_official_mobile") | text_formatter }}
                </span>
            </div>
          </template>

          <vs-divider class="text-capitalize vx-col w-full">
            {{ $t('form.marital_status') }}</vs-divider
          >

          <!-- Maritual Status -->
          <div class="my-2 vx-col lg:w-1/2 w-full">
            <label>{{ $t('form.parents_marital_status') }}</label>
            <v-select
              :closeOnSelect="true"
              v-model.number="info.marital_status.status"
              :options="allMaritalStatus"
              label="name"
              :reduce="(status) => status.id"
              v-validate="'required'"
              name="maritalStatus"
              class="w-full mb-2"
              @input="assignResponsablity()"
              :disabled="allMaritalStatus ? false : true" />
            <span class="text-danger text-sm">{{
              errors.first('maritalStatus')
            }}</span>
          </div>
          <!-- who_is_having_custody -->
          <div class="my-2 vx-col lg:w-1/2 w-full">
            <label>{{ $t('form.who_is_having_custody') }}</label>
            <v-select
              :closeOnSelect="true"
              v-model.number="info.responsible"
              :options="allCustodyOwners"
              label="name"
              :reduce="(status) => status.flag"
              v-validate="'required'"
              name="custodyOwners"
              class="w-full mb-2"
              :disabled="allCustodyOwners ? false : true" />
            <span class="text-danger text-sm">{{
              errors.first('custodyOwners')
            }}</span>
          </div>
          <div class="w-full vx-col" v-if="info.responsible === 'custody'">
            <div class="vx-row">
              <!-- custody_official_email -->

              <div class="my-2 vx-col lg:w-1/2 w-full">
                <label
                  >{{ $t('form.custody_email')
                  }}<sup class="text-danger">*</sup></label
                >
                <vs-input
                  v-model="info.marital_status.official_email"
                  name="custodyEmail"
                  class="w-full mb-2"
                  :placeholder="$t('form.email')"
                  v-validate="{
                    required: true,
                    email: true
                  }" />
                <span class="text-danger text-sm">{{
                  errors.first('custodyEmail')
                }}</span>
              </div>
              <!-- custody relationship -->

              <div class="my-2 vx-col lg:w-1/2 w-full">
                <label
                  >{{ $t('form.relationships')
                  }}<sup class="text-danger">*</sup></label
                >
                <v-select
                  :closeOnSelect="true"
                  v-model="info.marital_status.relationship_id"
                  :options="relationshipsWithoutParents"
                  label="name"
                  :reduce="(name) => name.id"
                  v-validate="'required'"
                  name="relationship"
                  class="w-full mb-2"
                  :disabled="relationships ? false : true" />

                <span class="text-danger text-sm">{{
                  errors.first('relationship')
                }}</span>
              </div>
            </div>
          </div>

          <vs-divider class="text-capitalize vx-col w-full">
            {{ $t('form.student_documents') }}</vs-divider
          >
          <!-- Upload File -->
          <div class="my-2 vx-col lg:w-1/2 w-full">
            <label class="w-full">{{ $t('exam.upload_attachment') }}</label>
            <vs-input
              type="file"
              accept="image/*,.doc,.docx,application/msword,.pdf,application/pdf;capture=camera"
              multiple
              @change="uploadDoc($event)"
              v-model="documents"
              class="w-full mb-2"
              ref="upload_file" />
          </div>
          <div class="my-2 vx-col w-full" v-if="info.documents.length">
            <div class="flex flex-row flex-wrap">
              <div
                v-for="(file, idx) in info.documents"
                :key="file.attachment_url">
                <feather-icon
                  @click="deleteDocument(idx, file)"
                  class="mr-2 remove-student-attachment"
                  icon="XIcon"
                  svgClasses="h-5 w-5" />

                <file-previewer
                  :url="file.document"
                  :urlType="file.type"
                  class="ml-1 my-4 mr-4"></file-previewer>
              </div>
            </div>
          </div>

          <vs-divider class="text-capitalize vx-col w-full">
            {{ $t('form.sibling_information') }}</vs-divider
          >

          <!-- Have Siblings -->
          <div class="my-2 vx-col lg:w-1/2 w-full">
            <label>{{ $t('form.have_siblings') }}</label>
            <ul class="vx-row mt-2">
              <li class="vx-col w-1/2">
                <vs-radio
                  v-model="info.has_sibling"
                  :vs-value="true"
                  name="has_sibling"
                  vs-name="has_sibling"
                  >{{ $t('basic.yes') }}</vs-radio
                >
              </li>
              <li class="vx-col w-1/2">
                <vs-radio
                  v-model="info.has_sibling"
                  :vs-value="false"
                  name="has_sibling"
                  vs-name="has_sibling"
                  >{{ $t('basic.no') }}</vs-radio
                >
              </li>
            </ul>
          </div>
          <!-- Siblings -->

          <!-- <div class="vx-col w-full">
          <div class="vx-row"> -->

          <div class="my-2 vx-col lg:w-1/2 w-full">
            <div v-if="info.has_sibling">
              <div
                v-for="(sib, index) in info.siblings"
                :key="index"
                class="mb-4">
                <vx-input-group
                  prependClasses="border border-solid border-grey border-r-0">
                  <vs-input
                    v-model="info.siblings[index]"
                    :name="`sibling`"
                    class="w-full"
                    :placeholder="$t('form.sibling_id')"
                    v-validate="'required'" />
                  <template slot="append">
                    <div class="append-text btn-addon">
                      <vs-button
                        v-if="info.siblings.length > 1"
                        type="border"
                        color="danger"
                        icon-pack="feather"
                        icon="icon-minus"
                        @click="removeSibling(index)"></vs-button>
                      <vs-button
                        v-if="index == info.siblings.length - 1"
                        type="border"
                        @click="addSibling(index)"
                        icon-pack="feather"
                        icon="icon-plus"></vs-button>
                    </div>
                  </template>
                </vx-input-group>
                <span class="text-danger text-sm">{{
                  errors.first(`sibling`)
                }}</span>
              </div>
            </div>
          </div>
          <!-- </div>
          </div> -->
        </div>
        <!-- Actions -->
        <div class="flex flex-wrap justify-between items-center mt-auto mb-0">
          <vs-button class="mt-5 md:w-auto md:mr-0 ml-auto" @click="submitForm">
            {{ $t('form.submit') }}
          </vs-button>
        </div>
      </form>
    </vs-card>
  </div>
</template>
<script>
// Components
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import vSelect from 'vue-select'
import fileUploader from '@/modules/unit-planner/components/uploadButton/uploadButton.vue'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const addStudentRepository = RepositoryFactory.get(
  'studentAffairsRepository',
  'addStudent'
)

// import getData from './form/common_data_mixin'

import {commonDataMixin, checkValidationMixin} from '@/mixins.js'

export default {
  name: 'student-personal-info',
  props: {
    studentInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isShow: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  // mixins:[getData()],
  components: {flatPickr, vSelect, fileUploader},
  mixins: [commonDataMixin, checkValidationMixin],

  data() {
    return {
      ///////////////////////////
      // studentPersonalInfo
      ///////////////////////////
      studentPersonalInfo: {
        nationalId: null,
        first_name: null,
        middle_name: null,
        last_name: null,
        forth_name: null,
        father_first_name: null,
        father_middle_name: null,
        father_last_name: null,
        father_forth_name: null,
        mother_first_name: null,
        mother_middle_name: null,
        mother_last_name: null,
        mother_forth_name: null,
        ar_first_name: null,
        ar_middle_name: null,
        ar_last_name: null,
        ar_forth_name: null,
        birthingDate: null,
        gender: null,
        religion: null,
        other_religion: null,
        official_email: null,
        father_official_email: null,
        mother_official_email: null,
        custody_official_email: null,
        responsible: null,
        mailing_email: null,
        has_sibling: false,
        marital_status: {
          status: null
          // custody_owner:1
        },
        nationality: [],
        siblings: [''],
        documents: [],
        class_id: '',
        father_official_mobile: {
          number: '',
          code: '011'
        },
        mother_official_mobile: {
          number: '',
          code: '011'
        },
      },
      configdatePicker: {
        maxDate: this.currentDate
      },
      nationalities: [],
      religions: [],
      divisions: [],
      grades: [],
      relationships: [],
      documents: null,
      allAcademicYears: [],
      allMaritalStatus: [],
      allCustodyOwners: [],
      genders: [],
      classes: [],
      password: null
    }
  },
  watch: {
    studentInfo(val) {
      if (Object.values(val).length) {
        this.mappingEditData(Object.assign({}, val))
      }
    }
  },
  computed: {
    info() {
      return this.studentPersonalInfo
    },
    documentsNames() {
      if (this.info.documents.length > 0)
        return this.info.documents
          .map((doc) => {
            return doc.name
          })
          .join(', ')
      return ''
    },
    getMaritalStatusName() {
      if (this.info.marital_status.status) {
        return this.allMaritalStatus.filter(
          (item) => item.id === this.info.marital_status.status
        )[0].flag
      }
    },
    isEdit() {
      return Boolean(this.$route.params.id)
    }
  },
  methods: {
    clickOnFile() {
      this.$refs.upload_file.click()
    },
    // Sibiling Actions
    addSibling(index) {
      if (this.info.siblings[index] === '') {
        return this.errMsg()
      }
      this.info.siblings.push('')
    },
    removeSibling(index) {
      this.info.siblings.splice(index, 1)
    },
    //  Upload Docs
    uploadDoc(e) {
      const files = e.target.files
      files.forEach((file) => {
        this.checkTextImage2MBFileValid(file).then(() => {
          const data = new FormData()
          data.append('attachments[0]', file)
          this.commonUploadFiles(data)
            .then((res) => {
              const doc = res[0]
              doc.name = doc.attachment
              doc.document = doc.attachment_url
              delete doc.attachment
              delete doc.attachment_url
              this.info.documents.push(doc)
              this.successMsg(
                this.$t('form.your_document_uploaded_successfully')
              )
            })
            .then(() => (this.renderExamFile = true))
        })
      })
    },
    assignResponsablity() {
      this.getCustodyOwner().then((res) => {
        this.info.responsible = ''
        if (this.getMaritalStatusName === 'married')
          this.info.responsible = 'father'
        else if (this.getMaritalStatusName === 'widowed_father') {
          this.allCustodyOwners = res.filter((item) => item.flag !== 'mother')
          return true
        } else if (this.getMaritalStatusName === 'widowed_mother') {
          this.allCustodyOwners = res.filter((item) => item.flag !== 'father')
          return true
        }
      })
    },
    mappingData() {
      this.studentPersonalInfo.school_id = this.authUser.school_id
      const time = new Date(this.studentPersonalInfo.birthingDate)
      this.studentPersonalInfo.birth_date = time.getTime()
      // delete this.studentPersonalInfo.birthingDate
      if (!this.studentPersonalInfo.has_sibling)
        delete this.studentPersonalInfo.siblings
      this.studentPersonalInfo.documents =
        this.studentPersonalInfo.documents.map((item) => {
          delete item.attachment_url
          delete item.attachment
          return item
        })
      return this.studentPersonalInfo
    },
    mappingEditData(newStudent) {
      this.studentPersonalInfo = newStudent
      this.getGrades(this.studentPersonalInfo.division_id).then(() => {
        this.getClassByGradeId(this.studentPersonalInfo.grade_id)
      })

      this.studentPersonalInfo.birthingDate = this.formatDate(
        newStudent.birth_date
      )
        .split('-')
        .reverse()
        .join('-')
      this.studentPersonalInfo.gender = +this.studentPersonalInfo.gender
      this.studentPersonalInfo.religion = +this.studentPersonalInfo.religion
      if (!newStudent.has_sibling) this.studentPersonalInfo.siblings = ['']
      if (newStudent.responsible === 'custody') {
        this.studentPersonalInfo.marital_status.official_email =
          newStudent.marital_status.custody.official_email
        this.studentPersonalInfo.marital_status.relationship_id =
          newStudent.marital_status.custody.relationship_id
      }
      this.studentPersonalInfo.documents =
        this.studentPersonalInfo.documents.map((item) => {
          delete item.url
          return item
        })
      this.studentPersonalInfo.resend_mail = false
      delete this.studentPersonalInfo.gradeId
      delete this.studentPersonalInfo.divisionId
    },
    async deleteDocument(index, file) {
      const {document} = file
      const data = {
        file_path: document
      }
      const isDeletedFromS3 = await this.deleteDocumentFromS3(data)
      if (isDeletedFromS3) {
        this.deleteMsg(this.$t('form.Delete_Document_Successfully'))
        this.info.documents.splice(index, 1)
      } else {
        this.errMsg(this.$t('form.failed_to_delete_documment'))
      }
    },
    generatePassword() {
      this.password = this.generateRandomPassword()
    },

    /*---------------------
     submit
    -----------------------*/
    submitForm() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          const payload = this.mappingData()
          if (this.password) payload.password = this.password
          this.$emit('formSubmited', payload)
        } else {
          this.errMsg()
        }
      })
    },
    getClassByGradeId() {
      addStudentRepository
        .getClassByGradeId(this.studentPersonalInfo.grade_id)
        .then((res) => {
          this.classes = res.data
        })
    }
  },
  created() {
    this.getNationalities()
    this.getDivisionsWithoutBranchId()
    this.getReligions()
    this.getRelationships()
    this.getGenders()
    this.getCustodyOwner()
    this.getMaritalStatus()
    this.getAllAcademic()
    this.getMobileKeys()
  },
  mounted() {}
}
</script>
<style lang="scss">
.remove-student-attachment {
  top: 32px;
  left: 90px;
  z-index: 20;
  cursor: pointer;
  svg {
    stroke: red;
    stroke-width: 2px;
  }
}
</style>
<style scoped>
.vx-input-group .prepend-text.btn-addon .vs-button,
.vx-input-group .append-text.btn-addon .vs-button {
  padding: 10px !important;
}
</style>
