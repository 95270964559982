<template>
  <ListPageWithCreateButton :showCreateBtn="false">
    <template slot="listDataContent">
      <UsersLosgsTable
        :headers_prop="headers"
        :list_url_prop="`/mobile/logs`"
        :side_filters="sideFilters"
        :list_items="searchResult"
        :loading="loading" />
    </template>
    <template v-slot="rightSide">
      <TeachersFilter
        :filters_prop="custom_filter"
        @refilter="setFilters"
        :loading="loadingFilter"
        :side_filters="sideFilters">
        <template slot="extra_content">
          <div class="w-full mb-10">
            <vs-input
              :label-placeholder="$t('form.search_by_name_email_or_ID')"
              v-model="searchContent"
              v-validate="{email: searchContent.includes('@')}"
              class="w-full"
              name="email"
              icon="icon icon-search"
              icon-pack="feather"
              @keyup.enter="search" />
            <span class="text-sm text-danger" v-if="errors.first('email')">{{
              errors.first('email') | text_formatter
            }}</span>
          </div>
        </template>
      </TeachersFilter>
    </template>
  </ListPageWithCreateButton>
</template>

<script>
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const userLogsRepo = RepositoryFactory.get('ConfigurationModule', 'userLogs')
import TeachersFilter from '@/components/general/RoundFilter'
import UsersLosgsTable from '@/components/general/datatable/DataTable2.vue'

export default {
  components: {
    UsersLosgsTable,
    TeachersFilter
  },
  data() {
    return {
      loading: false,
      searchResult: null,
      searchContent: '',
      loadingFilter: false,
      roles: [],
      sideFilters: {
        roles: []
      },
      custom_filter: [
        {
          id: 1,
          name: this.$t('basic.role'),
          payload_name: 'roles',
          by: []
        }
      ],
      headers: [
        {
          minWidth: 90,
          headerName: this.$t('basic.ID'),
          field: 'id'
        },
        {
          minWidth: 250,
          headerName: this.$t('form.name'),
          field: 'name'
        },
        {
          minWidth: 290,
          headerName: this.$t('form.email'),
          field: 'email'
        },
        {
          minWidth: 190,
          headerName: this.$t('usersLogs.role'),
          field: 'role'
        },
        {
          minWidth: 190,
          headerName: this.$t('usersLogs.last_login_date'),
          field: 'last_login_date'
        },
        {
          minWidth: 190,
          headerName: this.$t('usersLogs.last_logout_date'),
          field: 'last_logout_date'
        },
        {
          minWidth: 140,
          headerName: this.$t('AcademicYears.Actions'),
          field: 'transactions',
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            actions_prop: [
              {
                title: this.$t('main.show'),
                route_name: 'showUserLogs',
                getParams: (data) => ({id: data.id}),
                icon: 'EyeIcon',
                navigate_action: true
              }
            ]
          }
        }
      ]
    }
  },
  methods: {
    // Data Filter
    getFiltersData() {},
    // get All roles
    getAllRoles() {
      userLogsRepo.getAllroles().then((res) => {
        this.roles = res
        this.roles.map((el) => {
          this.custom_filter[0]['by'].push({
            id: el.name,
            name: el.name,
            checked: false
          })
        })
      })
    },
    search() {
      this.loading = true
      userLogsRepo
        .search(this.searchContent, this.sideFilters)
        .then((res) => {
          this.searchResult = res.data
        })
        .catch(this.errMsg)
        .finally(() => {
          this.loading = false
        })
    },
    setFilters(val) {
      val.map((item) => {
        this.sideFilters[item.name] = item.checked
      })
    }
  },
  watch: {
    searchContent(v) {
      if (!v) this.searchResult = null
    }
  },
  created() {
    this.getAllRoles()
    this.getFiltersData()
  }
}
</script>
