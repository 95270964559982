import Calendar from './pages/calendar.vue'


export default [
  {
    path: '',
    name: 'calendar',
    component: Calendar,
    meta: {
      breadcrumb: [],
      pageTitle: 'calendar.calendar',
      rule: 'landing'
    }
  }
]
