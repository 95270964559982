<template>
  <div>
    <form-wizard
      ref="checkoutWizard"
      color="rgba(var(--vs-primary), 1)"
      :title="null"
      :subtitle="null"
      :finishButtonText="edit_id ? $t('form.update') : $t('form.submit')"
      :startIndex="0"
      @on-complete="formSubmitted"
    >
      <!-- tab 1 Settings -->
      <tab-content
        :title="$t('basic.settings')"
        class="mb-5 pl-0 pr-0"
        :before-change="validateStep1"
      >
        <vx-card :title="$t('basic.settings')" class="p-3 pt-6 pb-6 md:p-6">
          <form data-vv-scope="step-1">
            <div class="vx-row w-full">
              <!-- schools -->
              <div
                class="vx-col lg:w-3/4 mb-5 w-full"
                v-if="schools.length > 1"
              >
                <label class="w-full">
                  {{ $t("form.choose_schools") }}
                  <sup class="text-danger">*</sup>
                </label>
                <v-select
                  label="name"
                  :reduce="(name) => name.id"
                  :options="schools"
                  v-model="role.schools"
                  class="w-full"
                  vs-name="schools"
                  name="schools"
                  v-validate="'required'"
                  data-vv-scope="step-1"
                  multiple
                  :closeOnSelect="false"
                  @input="onChangeSchools"
                />
                <span
                  v-show="errors.has('step-1.schools')"
                  class="text-danger text-sm"
                  >{{ errors.first("step-1.schools") | text_formatter }}</span
                >
              </div>
              <!-- Division -->
              <div class="vx-col lg:w-3/4 mb-5 w-full">
                <label class="w-full">
                  {{ $t("form.choose_divisions") }}
                  <sup class="text-danger">*</sup>
                </label>

                <v-select
                  label="long_name"
                  :reduce="(long_name) => long_name.id"
                  :options="divisions"
                  v-model="role.divisions"
                  class="w-full"
                  vs-name="divisions"
                  name="divisions"
                  v-validate="'required'"
                  data-vv-scope="step-1"
                  multiple
                  :closeOnSelect="false"
                  @input="onChangeDivisions"
                />
                <span
                  v-show="errors.has('step-1.divisions')"
                  class="text-danger text-sm"
                  >{{ errors.first("step-1.divisions") | text_formatter }}</span
                >
              </div>
              <!-- sections -->
              <div class="vx-col lg:w-3/4 mb-5 w-full">
                <label>
                  {{ $t("form.choose_sections") }}
                  <sup class="text-danger">*</sup>
                </label>

                <v-select
                  label="name"
                  :reduce="(name) => name.id"
                  :options="sections"
                  v-model="role.sections"
                  class="w-full"
                  vs-name="sections"
                  name="sections"
                  v-validate="'required'"
                  data-vv-scope="step-1"
                  multiple
                  :closeOnSelect="false"
                />

                <span
                  v-show="errors.has('step-1.sections')"
                  class="text-danger text-sm"
                  >{{ errors.first("step-1.sections") | text_formatter }}</span
                >
              </div>
              <!-- Name -->
              <div class="vx-col lg:w-3/4 mb-5 w-full">
                <label>
                  {{ $t("basic.name") }}
                  <sup class="text-danger">*</sup>
                </label>
                <vs-input
                  v-model="role.name"
                  class="w-full"
                  vs-name="name"
                  name="name"
                  v-validate="'required'"
                />
                <span v-show="nameError" class="text-danger text-sm">{{
                  $t("basic.this_name_used_before")
                }}</span>
                <span
                  v-show="errors.has('step-1.name')"
                  class="text-danger text-sm"
                  >{{ errors.first("step-1.name") | text_formatter }}</span
                >
              </div>
              <!-- description -->
              <div class="vx-col lg:w-3/4 mb-5 w-full">
                <label>{{ $t("basic.description") }}</label>
                <vs-textarea
                  rows="5"
                  v-model="role.description"
                  class="w-full"
                  vs-name="description"
                />
              </div>
            </div>
          </form>
        </vx-card>
      </tab-content>
      <!-- tab 2 perimissions  -->
      <tab-content :title="$t('basic.permissions')" class="mb-5 pl-0 pr-0">
        <vx-card :title="$t('basic.permissions')" class="p-3 pt-6 pb-6 md:p-6">
          <form data-vv-scope="step-2">
            <div class="vx-row">
              <div class="vx-col w-full md:w-1/3 hidden"></div>
              <div class="vx-col w-full md:w-2/3">
                <ul>
                  <li v-show="permissionsError" class="text-danger text-sm">
                    {{ $t("config.please_add_permissions_to_role") }}
                  </li>
                  <li>
                    <vs-checkbox
                      v-model="role.permissions"
                      vs-value="all"
                      class="mb-3"
                      @change="permissionsSelectAll"
                      >{{ $t("basic.selectAll") }}</vs-checkbox
                    >
                  </li>
                  <li v-for="(val, i) in permissions" :key="i" class="mb-3">
                    <vs-checkbox
                      v-model="role.permissions"
                      :vs-value="val.id"
                      >{{ val.name }}</vs-checkbox
                    >
                  </li>
                </ul>
              </div>
            </div>
          </form>
        </vx-card>
      </tab-content>
      <!-- tab 2 perimissions  -->
      <!-- <tab-content :title="$t('basic.preview')" class="mb-5 pl-0 pr-0">
        <vx-card :title="$t('basic.preview')" class="p-3 pt-6 pb-6 md:p-6">
          <ul>
            <li>
              <p>
                {{role.name}}
              </p>
            </li>
            <li>
              <p>{{role.description}}</p>
            </li>
            <li>
              <p>{{role.description}}</p>
            </li>
          </ul>
        </vx-card>
      </tab-content>-->
    </form-wizard>
  </div>
</template>
<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import vSelect from 'vue-select'
import { Validator } from 'vee-validate'
/*const dict = {
  custom: {
    schools: {
      required: 'This is a required Data'
    },
    divisions: {
      required: 'This is a required Data'
    },
    sections: {
      required: 'This is a required Data'
    },
    name: {
      required: 'This is a required Data'
    }


  }
}*/

// Validator custom messages
//Validator.localize('en', dict)

// Data
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const UserManagementRepository = RepositoryFactory.get(
  'ConfigurationModule',
  'userManagement'
)


export default {
  name:'roles-form',
  components:  {
    FormWizard,
    TabContent,
    vSelect,
    Validator
  },
  props:{

    role_prop:{
      type:Object,
      required:true
    }


  },

  data () {
    return {
      one_school_id:null,
      showErrorsTab0:false,
      permissions:[],

      role:{},
      nameError:false,
      permissionsError:false,
      schools: [],
      divisions: [],
      sections: [],
      first_time_edit:false


    }
  },
  computed:{
    edit_id () {
      return this.$route.params.id
    }

  },

  async  created () {
    if (this.edit_id) await this.getEditData()
    await this.getDropdowndata()
    this.role = this.role_prop
    this.getPermission()
  },

  watch:{ },
  methods: {


    //-------------- Api ---------------------
    getDropdowndata () {
      UserManagementRepository.getDropDownData().then(res => {
        this.schools = res.schools

        this.checkIfOneSchool()
      }).catch(err => {
        // console.error('api error', err.message)
      })
    },

    getSchoolDivisions () {
      UserManagementRepository.getSchoolDivisions(this.role.schools).then(res => {
        this.divisions = res.data
        if (this.edit_id) {
          this.getDivisionsSections()
          this.first_time_edit = false
        }
      }).catch(err => {
        // console.error('api error', err.message)
      })
    },
    getDivisionsSections () {

      const divisions = { divIds: this.role.divisions }
      UserManagementRepository.getDivisionsSections(divisions)
        .then(res => {
          this.sections = res.data
        }).catch(err => {
        // console.error('api error', err.message)
        })
    },
    getEditData () {
      UserManagementRepository.getRoles(this.edit_id)
        .then(res => {
          this.role = res.data

        }).catch(err => {
        // console.error('api error', err.message)
        })

    },

    getRoles () {
      UserManagementRepository.getRoles(this.edit_id)
        .then(res => {
          this.role = res.data
        // this.role.schools = res.data.schools
        }).catch(err => {
        // console.error('api error', err.message)
        })
      UserManagementRepository.getDropDownData()
        .then(res => {
          this.schools = res.schools

          if (this.schools.length < 2) {
            this.one_school_id = this.schools[0].id
            this.role.schools = [this.schools[0].id]

          }

        })
      UserManagementRepository.getSchoolDivisions(this.role.schools)
        .then(res => {
          this.divisions = res.data
        })
      UserManagementRepository.getDivisionsSections({ divIds: this.role.divisions })
        .then(res => {
          this.sections = res.data
        })
    },

    getPermission () {
      UserManagementRepository.getPermission().then(res => {
        this.permissions = res
      })
    },
    // ----------------- checking ------------

    checkIfOneSchool () {
      if (this.schools.length < 2) {
        this.one_school_id = this.schools[0].id
        if (this.role.schools) this.role.schools = [this.schools[0].id]
        else this.role.school = this.schools[0].id

        this.getSchoolDivisions()
      }
    },

    // ----------------- On Change  ------------
    onChangeSchools () {

      if (!this.first_time_edit) {
        this.role.divisions = []
        this.role.sections = []
      } else {
        this.first_time_edit = false
      }

      this.getSchoolDivisions()
    },
    onChangeDivisions () {

      if (!this.first_time_edit) {
        this.role.sections = []
      } else {
        this.first_time_edit = false
      }
      this.getDivisionsSections()
    },
    // ----------------- permissionsSelectAll ------------
    permissionsSelectAll () {
      if (event.target.checked) {
        this.role.permissions = this.permissions.map(el => el.id)

      } else {
        this.role.permissions = []
      }
    },
    // ----------------- Validation ------------
    validateStep1 () {

      return new Promise((resolve, reject) => {
        this.$validator.validateAll('step-1').then(result => {
          if (result) {
            resolve(true)


          } else {
            const hasErrors =
            this.$validator.errors.items.filter(
              (error)  => error.field === 'schools' ||
              error.field === 'divisions' ||
                error.field === 'name' ||
              error.field === 'sections'
            ).length !== 0
            if (hasErrors) {
              reject('correct all values')
              this.errMsg()
            }


            this.showErrorsTab0 = true
          }
        })
      })
    },
    // SubmitForm
    formSubmitted () {
      this.$emit('formSubmitted', true)
      if (!this.edit_id) {
        UserManagementRepository.createRole(this.role)
          .then(res =>         {
            this.$router.push({ name: 'Roles' })
          }, (error) => {
            if (error.errors['permissions'])  this.permissionsError = true
            if (error.errors['name'])  this.nameError = true
            this.errMsg()
          })
      } else {
        UserManagementRepository.editRole(this.edit_id, this.role)
          .then(res =>         {
            this.$router.push({ name: 'Roles' })
          }, (error) => {
            if (error.errors['permissions'])  this.permissionsError = true
            if (error.errors['name'])  this.nameError = true
            this.errMsg()
          })

      }


    }
  }
}
</script>
<style lang="scss">
.vue-form-wizard .wizard-tab-content {
  padding: 30px 0 10px;
}
</style>
