<template>
  <div>
    <!-- Loader -->
    <div
      id="loadDialog"
      v-show="loadDialog"
      ref="loadDialog"
      class="vs-con-loading__container"></div>

    <!-- Add grades Popup -->
    <form @submit.prevent="submitAddGrades" @keyup.enter="submitAddGrades">
      <!-- Grade Data -->
      <div class="vx-row w-full m-auto mb-10">
        <!-- Name -->
        <div class="vx-col lg:w-1/2 w-full mb-4">
          <label>{{ $t('form.name') }} <sup class="text-danger">*</sup></label>
          <vs-input
            v-model="newGrade.name"
            class="w-full"
            v-validate="'required'"
            name="name" />
          <span class="text-danger text-sm">{{ errors.first('name') }}</span>
        </div>
        <!-- Date -->
        <div class="vx-col lg:w-1/2 w-full mb-4">
          <label>{{ $t('form.date') }} <sup class="text-danger">*</sup> </label>

          <flatPickr
            class="w-full block"
            v-model="newGrade.date"
            v-validate="'required'"
            name="date" />
          <span class="text-danger text-sm">{{ errors.first('date') }}</span>
        </div>
        <!-- Subject -->
        <div class="vx-col lg:w-1/2 w-full mb-4">
          <label
            >{{ $t('basic.subject') }} <sup class="text-danger">*</sup></label
          >
          <v-select
            v-model="newGrade.subject_id"
            :options="subjects"
            label="name"
            :reduce="(f) => f.id"
            v-validate="'required'"
            name="subject"
            class="w-full"
            :showLoadingIcon="loadingSubjects"
            :disabled="!subjects.length"
            @input="getCriterias()" />
          <span class="text-danger text-sm">{{ errors.first('subject') }}</span>
        </div>
        <!-- Criteria -->
        <div class="vx-col lg:w-1/2 w-full mb-4" v-if="isMYPSection">
          <label
            >{{ $t('exam.choose_criteria') }}
            <sup class="text-danger">*</sup>
          </label>

          <v-select
            v-model="newGrade.criteria"
            :options="cirterias"
            :reduce="(e) => e.key"
            label="title"
            name="criteria"
            class="w-full"
            :disabled="!cirterias.length"
            :showLoadingIcon="loadingCriteria"
            multiple
            v-validate="'required'" />
          <span class="text-danger text-sm" v-if="errors.has('criteria')">{{
            $t('exam.criteria_is_required')
          }}</span>
        </div>

        <!-- Type -->
        <div class="vx-col lg:w-1/2 w-full mb-4">
          <label>{{ $t('basic.type') }} <sup class="text-danger">*</sup></label>
          <v-select
            v-model="newGrade.type"
            :options="filtratedResourcesTypes"
            label="name"
            :reduce="(c) => c.key"
            v-validate="'required'"
            name="type"
            class="w-full"
            :showLoadingIcon="loadTypes" />
          <span class="text-danger text-sm">{{ errors.first('type') }}</span>
        </div>
        <template v-if="isExam">
          <!-- Points -->
          <div class="vx-col lg:w-1/2 w-full mb-4">
            <label
              >{{ $t('exam.points') }} <sup class="text-danger">*</sup></label
            >
            <vs-input
              v-model="newGrade.points"
              class="w-full"
              type="number"
              v-validate="'required|numeric|max_value:100|min_value:1'"
              name="points"
              :max="100"
              :min="1"
              :description-text="`${$t('exam.Must_be_between')} 1-100`" />
            <span class="text-danger text-sm">{{
              errors.first('points')
            }}</span>
          </div>
          <div
            class="vx-col lg:w-1/2 w-full mb-4 flex flex-wrap"
            v-if="showExamTypes">
            <label class="vx-col w-full mb-3">
              {{ $t('exam.exam_type') }}<sup class="text-danger">*</sup>
            </label>
            <div class="vx-col mb-5 sm:w-1/2 w-full">
              <vs-radio
                v-model="newGrade.exam_type"
                v-validate="'required'"
                vs-name="exam_type"
                name="exam_type"
                vs-value="normal"
                >{{ $t('exam.normal') }}</vs-radio
              >
            </div>
            <div class="vx-col mb-5 sm:w-1/2 w-full">
              <vs-radio
                v-model="newGrade.exam_type"
                v-validate="'required'"
                vs-name="exam_type"
                name="exam_type"
                vs-value="final"
                >{{ $t('exam.final') }}</vs-radio
              >
            </div>
            <div class="vx-col w-full mb-5">
              <span class="text-danger">{{
                errors.first('exam_type') | text_formatter
              }}</span>
            </div>
          </div>
        </template>
      </div>
      <!-- students score  -->
      <div class="lg:w-4/5 m-auto w-full">
        <studentGradeTable
          :students="updateScoreStudentsData"
          :criteria="newGrade.criteria"
          v-if="showStudentGradeTable"
          class="w-full"
          :showPoints="submitStudentsPoints"
          :maxPoints="Number(newGrade.points)" />
      </div>
      <!-- Btns -->
      <div class="flex justify-between items-center">
        <vs-button
          class="mt-2"
          type="border"
          color="danger"
          @click="closeAddGrade"
          >{{ $t('basic.cancel') }}
        </vs-button>
        <vs-button class="mt-2 mx-2" @click="submitAddGrades"
          >{{ $t('form.submit') }}
        </vs-button>
      </div>
    </form>
  </div>
</template>
<script>
// mixins
import {commonDataMixin} from '@/mixins.js'
import gradingMixins from '../grading.mixins'
import studentGradeTable from './StudentGradeTable.vue'

// Repos
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const GradebookRepo = RepositoryFactory.get('GradingModule', 'gradebook'),
  ExamRepo = RepositoryFactory.get('ExamModule', 'Exam')
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  name: 'add-assessment-grade',
  props: {
    students: {
      type: Array,
      require: true
    },
    resource: {
      type: Object,
      required: false
    }
  },
  // mixin
  components: {flatPickr, studentGradeTable},
  provide() {
    return {parentValidator: this.$validator}
  },
  mixins: [commonDataMixin, gradingMixins],
  data() {
    return {
      /////////////////////
      // loading
      /////////////////////
      loadingSubjects: false,
      loadingCriteria: false,
      addNewGradePopup: false,
      /////////////////////
      // Data
      /////////////////////
      cirterias: [],
      updateScoreStudentsData: [],
      filtratedResourcesTypes: [],
      resourcesTypesIds: {},
      /////////////////////
      // add grade
      /////////////////////
      newGrade: {},
      validGrade: false,
      showExamTypes: true
    }
  },

  computed: {
    validateNewGrade() {
      return !this.errors.any()
    },
    defaultGradeObj() {
      return {
        // class_ids:[this.selectedClass.id],
        hide_from_grading: false,
        subject_id: null,
        points: null,
        type: 'exam',
        exam_type: 'normal',
        students: [],
        name: null,
        date: null,
        criteria: []
      }
    },
    submitStudentsPoints() {
      return this.isExam
    },
    isExam() {
      return !this.newGrade.type.key && this.newGrade.type === 'exam'
      // return  ['exam', 'mid_exam', 'final_exam', 3, 4].some(e => e === this.newGrade.type)
    },
    isUpdate() {
      return Boolean(this.resource?.id)
    },
    isUpdateExam() {
      return this.isUpdate && this.resource.typeFlag.includes('exam')
    },
    showStudentGradeTable() {
      return this.newGrade.criteria && this.newGrade.criteria.length
    },
    isOtherTypes() {
      return Boolean(this.newGrade.type.key)
    }
  },
  methods: {
    //////////////////////////
    // APIs
    //////////////////////////

    // criatiras
    async getCriterias() {
      if (!this.selectedClass.grade_id) {
        this.errMsg(this.$t('form.the_grade_id_is_missed'))
        return
      }
      if (this.isDPSection || this.isAmerican) {
        this.cirterias = this.handleCriteria()
        return
      }

      // --- Continue
      const payload = {
        gradeId: this.selectedClass.grade_id,
        subjectId: this.newGrade.subject_id,
        classIds: [this.selectedClass.id]
      }
      this.loadingCriteria = true
      ExamRepo.getCriterias(payload)
        .then((res) => {
          this.cirterias = this.handleCriteria(res.data)
          if (this.isPYPSection) this.newGrade.criteria = this.cirterias
        })
        .catch(this.errMsg)
        .finally(() => {
          this.loadingCriteria = false
        })
    },
    // Subjects
    getSubjects() {
      this.loadingSubjects = true

      this.getSubjectOfClass(this.selectedClass.id)
        .then((res) => {
          this.$store.commit('setSubjects', res)
        })
        .catch(this.errMsg)
        .finally(() => {
          this.loadingSubjects = false
        })
    },
    //----------- Exam
    // Add
    async addExamGradesManual(payload) {
      payload = this.handleExamPayload(payload)
      // --- Continue
      this.startLoadingDialog()
      await GradebookRepo.addExamGradesManual(payload)
        .then((res) => {
          this.successMsg(this.$t('form.saved_successfully'))
          this.$emit('reloadGrades')
          this.closeAddGrade()
        })
        .catch(this.errMsg)
        .finally(this.endLoadingDialog)
    },
    // Get
    async getExamGradesManual(examId) {
      // --- Continue
      this.startLoadingDialog()
      await GradebookRepo.getExamGradesManual(examId)
        .then((res) => {
          this.handleAssignUpdateData(res.data)
          this.newGrade.type = 'exam'
        })
        .catch(this.errMsg)
        .finally(this.endLoadingDialog)
    },
    // Update
    async updateExamGradesManual(payload) {
      payload = this.handleExamPayload(payload)

      // --- Continue
      this.startLoadingDialog()
      await GradebookRepo.updateExamGradesManual(this.newGrade.id, payload)
        .then((res) => {
          this.successMsg(this.$t('form.saved_successfully'))
          this.$emit('reloadGrades')
          this.closeAddGrade()
        })
        .catch(this.errMsg)
        .finally(this.endLoadingDialog)
    },
    //----- Grading Other Types
    // Add
    async addGradeTypeDetails(payload) {
      payload = this.handleGradingTypePayload(payload)

      // --- Continue
      this.startLoadingDialog()
      await GradebookRepo.addGradeTypeDetails(payload)
        .then((res) => {
          this.successMsg(this.$t('form.saved_successfully'))
          this.$emit('reloadGrades')
          this.closeAddGrade()
        })
        .catch(this.errMsg)
        .finally(this.endLoadingDialog)
    },
    // Get
    async showGradeTypeDetail(gradingTypeId) {
      // --- Continue
      this.startLoadingDialog()
      await GradebookRepo.showGradeTypeDetail(gradingTypeId)
        .then((res) => {
          this.handleAssignUpdateData(res.data)
          this.newGrade.type = res.data.type_id
        })
        .catch(this.errMsg)
        .finally(this.endLoadingDialog)
    },
    async updateGradeTypeDetails(payload) {
      payload = this.handleGradingTypePayload(payload)

      // --- Continue
      this.startLoadingDialog()
      await GradebookRepo.updateGradeTypeDetails(this.newGrade.id, payload)
        .then((res) => {
          this.successMsg(this.$t('form.saved_successfully'))
          this.$emit('reloadGrades')
          this.closeAddGrade()
        })
        .catch(this.errMsg)
        .finally(this.endLoadingDialog)
    },
    /////////////////////////
    //  Data
    /////////////////////////
    async handleAssignUpdateData(data) {
      // await (this.newGrade = data)
      for (const key in data) {
        await (this.newGrade[key] = data[key])
      }
      if (data.criteria.length) {
        this.newGrade.criteria = data.criteria.map((e) => e.key)
      }
      const updateStudentGrades = (studentId) =>
        data.students.find((s) => {
          return this.isOtherTypes
            ? s.user_id === studentId
            : s.student_id === studentId
        }) || {}
      this.updateScoreStudentsData = window._.cloneDeep(
        this.students.map((s) => {
          const grade = updateStudentGrades(s.user_id),
            student = {
              id: s.code,
              userId: s.user_id,
              name: s.name,
              score: grade.criteria
            }

          if (this.isExam) {
            student.points = grade.score
          }

          return student
        })
      )

      if (data.type?.key) this.newGrade.type = data.type.key
    },
    async startViewScenario() {
      if (!this.subjects.length) await this.getSubjects()
      this.newGrade.subject_id = this.resource.subject?.id

      await this.getCriterias()
      if (this.isUpdateExam) await this.getExamGradesManual(this.resource.id)
      else await this.showGradeTypeDetail(this.resource.id)
    },
    handleNewGradePayload() {
      const payload = window._.cloneDeep(this.newGrade)

      if (this.isPYPSection || this.isMYPSection)
        payload.criteria = this.newGrade.criteria
      if (this.isDPSection || this.isAmerican) payload.criteria = []
      payload.students = []

      payload.class_ids = [this.selectedClass.id]

      return payload
    },
    handlePayloadStudentsGrades() {
      const students = []
      this.updateScoreStudentsData.map((e) => {
        students.push({
          student_id: e.userId,
          user_id: e.userId,
          score: e.points || null,
          is_attended: true,
          criteria: this.isMYPSection
            ? e.score
            : Array.isArray(e.score)
            ? e.score
            : [e.score]
        })
        // payload.students[e.userId] = {score :e.score}
      })
      return students
    },
    handleExamPayload(payload) {
      payload.students = this.handlePayloadStudentsGrades()
      // payload.exam_type = payload.type === 'mid_exam' ? 'normal' : 'final'
      // payload.type = 'exam'
      return payload
    },
    handleGradingTypePayload(payload) {
      payload.type_id = this.resourcesTypesIds[payload.type]
      delete payload.type
      payload.class_id = this.selectedClass.id
      payload.students = this.handlePayloadStudentsGrades()
      return payload
    },
    //////////////////////////////
    // Scenarios
    //////////////////////////////
    async StartCreateScenario() {
      this.updateScoreStudentsData = window._.cloneDeep(
        this.students.map((s) => {
          const student = {
            id: s.code,
            userId: s.user_id,
            name: s.name,
            score: this.isMYPSection ? {} : null
          }

          if (this.isExam) student.points = null
          return student
        })
      )
      if (!this.subjects.length) await this.getSubjects()
      this.newGrade.subject_id = this.selectedSubjectId
      this.getCriterias()
    },
    startAddNewGrade() {
      if (this.isDPSection || this.isAmerican)
        this.cirterias = this.newGrade.criteria = this.handleCriteria()

      if (this.isUpdate) this.startViewScenario()
      else this.StartCreateScenario()

      this.getCurrentAcademicYear()
    },
    resetAddGrade() {
      this.newGrade = Object.assign({}, this.defaultGradeObj)
      this.updateScoreStudentsData = []
      this.errors.clear()
    },
    closeAddGrade() {
      this.resetAddGrade()
      this.$emit('closeAddGrade')
    },

    ///////////////////////
    // Submit
    //////////////////////

    submitAddGrades() {
      const isStudentNotHaveScore = this.updateScoreStudentsData.some(
        (e) => !e.score || e.score === {}
      )
      if (isStudentNotHaveScore) {
        this.errMsg(this.$t('grading.please_enter_all_students_score'))
      }
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          const payload = this.handleNewGradePayload()
          if (this.isExam) {
            if (this.isUpdate) this.updateExamGradesManual(payload)
            else this.addExamGradesManual(payload)
          } else if (this.isUpdate) this.updateGradeTypeDetails(payload)
          else this.addGradeTypeDetails(payload)
        } else this.errMsg()
      })
    }
  },
  watch: {
    'errors.items'(v) {
      if (v.length > 0) this.validGrade = false
      else this.validGrade = true
    },
    newGrade: {
      handler(v) {
        this.showExamTypes = v.type === 'exam'
      },
      deep: true
    }
  },

  async created() {
    this.newGrade = Object.assign({}, this.defaultGradeObj)
    this.startAddNewGrade()
    this.getTypes().then((res) => {
      this.resourcesTypes.forEach((e) => {
        if (
          e.key !== 'assignment' &&
          e.key !== 'task' &&
          !e.key.includes('exam')
        ) {
          this.filtratedResourcesTypes.push(e)
        }
        this.resourcesTypesIds[e.key] = e.id
      })

      this.filtratedResourcesTypes.push(
        {key: 'exam', name: this.text_formatter(this.$t('basic.exam'))}
        // {key:'assignment', name:this.text_formatter(this.$t('basic.assignment'))}
      )
    })
  }
}
</script>
