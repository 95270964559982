import axios from 'axios'
const resource = '/teachers'

export default {
  getAllExam (params) {
    return axios.get(`${resource}/exams?${params}`)
  },
  getExamTypes () {
    return axios.get(`${resource}/exam_types`)
  },
  getAllExamsCount () {
    return axios.get(`${resource}/exams/counts`)
  },
  getAllClasses () {
    return axios.get(`${resource}/classes`)
  },
  submitAnswer (exam_id, data) {
    return axios.post(`student/exams/${exam_id}/submit`, data)
  },
  commonUploadFiles (data) {
    return axios.post('common/upload-files', data)
  },
  getExam (exam_id) {
    return axios.get(`teachers/exams/${exam_id}`)
  },
  getAllQuestionTypes (auto_grading) {
    return axios.get(`teachers/question_types${auto_grading}`)
  },
  getAllDivisions (diviionsIds) {
    return axios.post('division/schoolDivisions', diviionsIds)
  },
  getSingleStudentGrade (student_id, exam_id) {
    return axios.get(`teachers/student/${student_id}/exam/${exam_id}`)
  },
  changeStatus (exam_id, status) {
    return axios.get(`/teachers/exams/change-status/${exam_id}/${status}`)
  },
  getAllStudentExams (current_page) {
    return axios.get(`student/exams?page=${current_page}`)
  },
  deleteExamDocumentFromDatabase (data) {
    return axios.post('teachers/delete-Paper-attachment', data)
  },
  deleteExamDocumentFromS3 (data) {
    return axios.post('common/delete-file', data)
  },
  createExam (data) {
    return axios.post('teachers/exams', data)
  },
  getAllGrades () {
    return axios.get('teachers/grades')
  },
  getFilteredClasses (grade_id) {
    return axios.get(`${resource}/grade/${grade_id}/classes`)
  },
  getFilteredSubjects (classes_ids) {
    return axios.post(`${resource}/subjects`, classes_ids)
  },
  getCriterias ({
    gradeId = '',
    subjectId = '',
    classIds = []
  }) {
    return axios.get(`${resource}/exams/grade/${gradeId}/subject/${subjectId}/criterias`, {
      params: {
        classIds
      }
    })
  },
  getExamCriteria (examID) {
    return axios.get(`${resource}/exams/${examID}/criterias`)
  },
  getAllStudents (class_id) {
    return axios.post(`${resource}/exam/students`, class_id)
  },
  getStudents (examId) {
    return axios.get(`teachers/exams/${examId}/exam-students`)
  },
  getExamData (examId) {
    return axios.get(`teachers/exams/${examId}`)
  },
  getStudentExam (studentID) {
    return axios.get(`teachers/exam-students/${studentID}`)
  },
  getAnswersCriterias (examId) {
    return axios.get(`teachers/exams/${examId}/criterias`)
  },
  submitAssess (studentID, data) {
    return axios.post(`teachers/exam-tasks/${studentID}/assess`, data)
  },
  startExam (studentID, data) {
    return axios.post(`student/exams/${studentID}/start`, data)
  },
  getExamInStudent (examId) {
    return axios.get(`student/exams/${examId}`)
  },
  startTeacherExam (examId, date) {
    return axios.post(`teachers/exams/${examId}/start`, {date})
  },
  OpenTeacherExam (examId, openTill) {
    return axios.Axios.post(`teachers/exams/${examId}/open`, {
      open_till: openTill
    })
  },
  forceSubmissionTeacher (examId, exam) {
    return axios.post(`teachers/exams/${examId}/force-submit`, exam)
  },
  deleteDocumentFromDatabase (data) {
    return axios.post('teachers/delete-Question-attachment', data)

  },
  deleteDocumentFromS3 (data) {
    return axios.post('common/delete-file', data)
  },
  getAllgradingTypes () {
    return axios.get('/teachers/exam_grading_types')
  },
  updateExam (examId, exam) {
    return axios.patch(`teachers/exams/${examId}`, exam)
  },
  check15Min (data) {
    return axios.post('teachers/exams/overlapping/check', data)
  },
  downloadPdf (params) {
    return axios.post(`teachers/exams/pdf?${params}`)
  },
  downloadCsv (params) {
    return axios.post(`teachers/exams/excel?${params}`)
  },
  getDailyAgenda (data) {
    return axios.get('teachers/exams/daily_agenda', {
      params: {
        ...data
      }}
    )
  },
  getExamStatus () {
    return axios.get('teachers/exam_statuses')
  }

}
