<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-6" :class="{'lg:w-3/4': !fullWidth}">
      <slot name="leftSide"></slot>
    </div>
    <div
      class="vx-col w-full"
      :class="{'lg:w-1/4': !fullWidth}"
      v-if="this.$slots.rightSide">
      <slot name="rightSide"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ListPageBasic',
  props: {
    syncReduceButton: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    fullWidth() {
      return (
        !this.$slots.rightSide ||
        (Boolean(this.$slots.rightSide) &&
          this.syncReduceButton &&
          this.isReduceButton)
      )
    },
    isReduceButton() {
      return this.$store.state.reduceButton
    }
  }
}
</script>
