/**
** this compoent is for only the horizontal single selectable filters

* filters ex: [
{
name:'classes',
payload_name:'class',
onChangeFireAction:true, // fire @change
by:[{name:'class 1',id:1}] // options
}]

* track the changes by reFilter custom event  with value: {filterName || payload_name , optionId }
*/
<template>
  <vx-card
    ref="navFilter"
    :title="title || null"
    class="user-list-filters mb-8"
    :subtitle="subtitle || null"
    :actionButtons="actionButtons || null"
    :actionButtonsColor="actionButtonsColor || null"
    :noRadius="noRadius || null"
    :cardBorder="cardBorder || null"
    :collapseAction="collapseAction || null"
    :refreshContentAction="refreshContentAction || null"
    :removeCardAction="removeCardAction || null"
  >
    <div class="vx-row items-center">
      <div
        class="vx-col mb-4 md:w-1/4 sm:w-1/2 w-full"
        :class="{ 'md:mb-0': filters.length < 5 }"
        v-for="(filter, i) in filters"
        :key="`filter_${i}`"
      >
        <label class="text-sm opacity-75">{{ filter.name }}</label>
        <v-select
          clearable
          label="name"
          :options="filter.by"
          :disabled="!filter.by.length || loading"
          v-model="filtersChoosen[filter.payload_name]"
          @input="reFilter($event, filter)"
        />
      </div>
      <div
        class="vx-col mb-4 w-full md:w-1/2"
        :class="{ 'md:mb-0  ': filters.length < 5 }"
        v-if="$slots.extraFilters"
      >
        <slot name="extraFilters"></slot>
      </div>
    </div>
    <div
      ref="loader"
      id="loader"
      class="vs-con-loading__container"
      v-show="loading"
    ></div>
  </vx-card>
</template>
<script>

export default {
  name: 'nav-filter',

  props: {
    title: {
      type: String,
      default: ''
    },
    // filters ex: [{name:'classes',payload_name:'class',by:[{name:'class 1',id:1}]}]
    filters: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: true
    },
    subtitle: String,
    actionButtons: {
      type: Boolean,
      default: false
    },
    actionButtonsColor: {
      type: String,
      default: 'success'
    },
    noRadius: {
      default: false,
      type: Boolean
    },
    cardBorder: {
      default: false,
      type: Boolean
    },
    collapseAction: {
      default: false,
      type: Boolean
    },
    refreshContentAction: {
      default: false,
      type: Boolean
    },
    removeCardAction: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      filtersChoosen: {},
      allOption: [
        {
          name: this.$t('basic.All'),
          id: null
        }
      ]
    }
  },
  watch: {
    loading (v) {
      if (v) this.startLoading()
      else this.endLoading()
    },
    filters: {
      handler (v) {
        this.filters.forEach(f => {
          if (!f.by.length) delete this.filtersChoosen[f.payload_name]

        })
      },
      deep: true
    }
  },
  methods: {
    /////////////////////////
    // Loading
    /////////////////////////
    // Table Data
    async startLoading () {
      this.VsLoadingContained(this.$refs.loader)
    },
    endLoading () {
      this.stopVsLoadingContained(this.$refs.loader)
    },
    /////////////////////////
    // Refilter
    /////////////////////////
    reFilter (option, filter) {
      const filterName = filter.payload_name || filter.name
      // this.filtersChoosen[filterName] = option || {}
      this.$emit('reFilter', this.filtersChoosen)
      if (filter.onChangeFireAction) this.$root.$emit(`onChange:${filterName}`, this.filtersChoosen)

    }
  },
  mounted () {
    this.startLoading()
  }

}
</script>
<style scoped>
.filter-div {
  min-width: 30%;
}
</style>
