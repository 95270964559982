<template>
  <div>
    <h4>
      {{ $t("main.welcome") }}
      {{ authUser.displayFirstAndSecondName }}
      <vs-button v-if="JSON.parse(userData).teacher_parent" @click="act({name: authUser.displayFirstAndSecondName ,id:JSON.parse(userData).teacher_parent})" class="ml-2">{{$t('teachersGateway.my_parent_profile')}} </vs-button>
    </h4>
  </div>
</template>

<script>

import { commonDataMixin, authUserMixin } from "@/mixins.js";
export default {
  mixins: [commonDataMixin, authUserMixin],
  data () {
    return {
      userData: localStorage.getItem('authUser')
    }
  },
  methods: {
    act(data){
      this.startActAs(data);
    }
  },
  created () {
  },
  beforeDestroy() {
  },
}
</script>

<style lang="scss" scoped>
.active .vx-card {
  -webkit-box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
  box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
}
</style>
