<!--
    File Name: GoogleMapInfoWindow.vue
    Description: Google map Basicwith info window
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent -->
<template>
  <div class="mt-1">
    <!-- Append Button -->
    <vx-input-group class="mb-6">
      <gmap-autocomplete
        @place_changed="setPlace"
        class="vs-inputx vs-input--input normal h-full"
        ref="autocomplete"
        placeholder="Search only by places"
      ></gmap-autocomplete>

      <template slot="append">
        <div class="append-text btn-addon">
          <vs-button
            color="primary"
            type="border"
            @click="getGoogleCurrent()"
            >{{ $t("basic.use_current_location") }}</vs-button
          >
        </div>
      </template>
    </vx-input-group>
    <!-- /Append Button -->

    <vs-alert
      color="danger"
      :active="mapErrorAlert"
      icon-pack="feather"
      icon="icon-info"
      class="mt-0 mb-6"
      >{{ $t("basic.No_details_available_for_input") }}</vs-alert
    >
    <gmap-map :center="center" :zoom="15" style="width: 100%; height: 500px">
      <gmap-info-window
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="infoWinOpen"
        @closeclick="infoWinOpen = false"
        >{{ infoContent }}</gmap-info-window
      >
      <gmap-marker
        :key="i"
        v-for="(m, i) in markers"
        :position="m.position"
        :clickable="true"
        :draggable="true"
        @drag="updateCoordinates"
        @click="toggleInfoWindow(m, i)"
      ></gmap-marker>
      <!-- @click="tesst(m)" -->
      <!-- v-model="new_marker" -->
    </gmap-map>
  </div>
</template>

<script>
import * as VueGoogleMaps from 'vue2-google-maps'
import Vue from 'vue'
import _ from 'lodash'
import { mapState } from 'vuex'
import { gmapApi } from 'vue2-google-maps'
export default {
  data () {
    return {
      center: { lat: 47.376332, lng: 8.547511 },
      infoContent: '',
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      lat:0,

      lon:0,
      //optional: offset infowindow so it visually sits nicely on top of our marker
      infoOptions: {
        pixelOffset: { width: 0, height: -35 }
      },
      position: {},
      locationText:''
    }
  },
  props: ['markers', 'setPlace', 'mapErrorAlert', 'loaded'],
  methods: {

    updateCoordinates (location) {

      this.$emit('updateLocation', location)
      // const geocoder = new VueGoogleMaps.maps.Geocoder()
      // geocoder.geocode({ 'latLng': event.latLng }, (result, status) => {
      //   if (status === VueGoogleMaps.maps.GeocoderStatus.OK) {
      //     // accessing autocomplete reference and populating the address
      //     this.$refs.autocomplete.$refs.input.value = result[0].formatted_address
      //   }
      // })
    },
    toggleInfoWindow (marker, idx) {
      this.infoWindowPos = marker.position
      this.infoContent = marker.infoText
      this.center = this.markers[0].position
      if (this.currentMidx === idx) {
        //check if its the same marker that was selected if yes toggle
        this.infoWinOpen = !this.infoWinOpen
      } else {

        //if different marker set infowindow to open and reset current marker index
        this.infoWinOpen = true
        this.currentMidx = idx
      }
    },

    asd () {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.showPosition)
      } else {
        this.error = this.$t('form.geolocation_is_not_supported')

      }
    },
    getGoogleCurrent () {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = parseFloat(position.coords.latitude),
            lng = parseFloat(position.coords.longitude)
          this.markers[0].position.lat = lat
          this.markers[0].position.lng = lng
          this.locationText = `${lat},${lng}`
        },
        (error) => {
        }
      )
    },
    showPosition (position) {
      this.lat = parseFloat(position.coords.latitude)
      this.lon = parseFloat(position.coords.longitude)
    }

  },
  async mounted () {
    if (await this.loaded)  this.center = this.markers[0].position


  },
  watch: {
    // 'center' (val) {
    //   this.locationText = `${val.lat},${val.lng}`

    // }
    locationText (v) {
      this.$refs.autocomplete.$refs.input.value = v
    }
  }

}

</script>
