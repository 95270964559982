<template>
  <div>
    <vx-card class="p-3 pt-6 pb-6 md:p-6">
      <form @submit.prevent="formSubmitted">
        <!-- Divisions -->
        <div class="lg:w-2/3 mb-5 w-full">
          <label class="inline-block mb-1">{{ $t("form.divisions") }}</label>
          <sup class="text-danger">*</sup>
          <v-select
            label="long_name"
            :reduce="(name) => name.id"
            :options="divisions"
            v-model="division_id"
            class="w-full select-large"
            name="Divisions"
            v-validate="'required'"
            @input="getSectionsBydivisionId"
          />
          <span class="text-danger text-sm w-full">{{
            errors.first("Divisions")
          }}</span>
        </div>

        <!-- Sections -->
        <div class="lg:w-2/3 mb-5 w-full">
          <label class="inline-block mb-1">{{ $t("basic.sections") }}</label>
          <sup class="text-danger">*</sup>
          <v-select
            :disabled="division_id.length <= 0"
            label="name"
            :reduce="(name) => name.id"
            :options="sections"
            v-model="sectionCoodinatorForm.section_id"
            class="w-full select-large"
            name="Sections"
            v-validate="'required'"
          />

          <span class="text-danger text-sm w-full">{{
            errors.first("Sections")
          }}</span>
        </div>

        <!-- Start Is section coodinator Teacher -->
        <div class="vx-row mt-5 items-center">
          <!-- Start label hod Teacher-->
          <div class="vx-col lg:w-1/4 w-full">
            <label>
              {{ $t("teachersGateway.is_teacher_section_coodinator") }}
              <sup class="text-danger">*</sup>
            </label>
          </div>
          <!-- End label hod Teacher -->
          <div class="w-full lg:w-2/5 text-center">
            <ul class="vx-row mt-2">
              <li class="vx-col w-1/2 lg:w-1/3">
                <vs-radio
                  v-model="sectionCoodinatorForm.role"
                  :vs-value="'TeacherSectionCoodinator'"
                  name="role"
                  >{{ $t("teachersGateway.yes") }}</vs-radio
                >
              </li>
              <li class="vx-col w-1/2 lg:w-1/3">
                <vs-radio
                  v-model="sectionCoodinatorForm.role"
                  :vs-value="'SectionCoodinator'"
                  name="role"
                  >{{ $t("teachersGateway.no") }}</vs-radio
                >
              </li>
            </ul>
          </div>
        </div>
        <!-- End Is section coodinator Teacher -->

        <!-- Actions -->
        <div class="flex flex-wrap justify-between items-center mt-10">
          <vs-button
            color="warning"
            type="border"
            class="inline-block"
            @click="back()"
            >{{ $t("form.cancel") }}</vs-button
          >
          <vs-button class="inline-block" @click="formSubmitted()">
            {{ $t("form.submit") }}
          </vs-button>
        </div>
      </form>
    </vx-card>
  </div>
</template>

<script>
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const addTeacherRepo = RepositoryFactory.get(
  'teachersGatewayModule',
  'addTeacher'
)
import { commonDataMixin } from '@/mixins.js'
export default {
  mixins: [commonDataMixin],
  props: {
    teacher_id: {
      type: [String, Number],
      default: () => {
        return ''
      }
    }
  },
  data () {
    return {
      sectionCoodinatorForm: {
        user_id:this.teacher_id,
        section_id:'',
        role :'SectionCoodinator'
      },
      division_id: '',
      divisions: [],
      sections:[]
    }
  },
  methods: {
    createSectionCoodinator () {
      addTeacherRepo
        .createSectionCoodinator(this.sectionCoodinatorForm)
        .then((res) => {
          this.$router.push('/teachers-gateway')
          this.successMsg(res.status)
        })
        .catch((err) => {
          this.errMsg(err.data.message)
        })
    },
    getSectionsBydivisionId () {
      this.sectionCoodinatorForm.section_id = ''
      this.VSLoading()
      addTeacherRepo
        .getSectionsBydivisionId(this.division_id)
        .then((res) => {
          this.sections = res.data
          this.stopVSLoading()
        })
        .catch((err) => {
          this.errMsg(err.data.message)
          this.stopVSLoading()
        })
    },

    formSubmitted () {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.createSectionCoodinator()
        } else {
          this.errMsg(this.$t(
            'teachersGateway.teacher_has_not_been_saved_please_complete_your_data'
          ))
        }
      })
    },
    back () {
      this.$router.back()
    }
  },
  mounted () {
    this.getDivisionsWithoutBranchId() //from mixins
  }
}
</script>
