<!-- //////////////////////////////

status obj :{
  id:0,
  name:"", //name
  title :"", // title ( for just displaying)
  color:'',
  value:'',
  count:0
}
props : status_prop
emits : filter-change

//////////////////////////////
-->
<template>
  <div
    class="justify-between flex align-center w-full flex-wrap"
    :class="{ 'lg:flex-no-wrap': status.length < 5 }"
  >
    <div
      class="mb-4 flex-1"
      v-for="(val, i) in status"
      :key="val.id || i"
    >
      <button
        @click="getFilter(val)"
        class="w-full no_style block pl-1 pr-1"
        :class="{ active: (val.value || val.id) === selected }"
        :title="val.title ? val.title : val.name"
      >
        <vx-card class="overflow-hidden small-card">
          <div slot="no-body">
            <div class="p-4" :class="''">
              <div class="flex justify-between flex-row-reverse items-center">
                <feather-icon
                  :icon="val.icon"
                  class="p-2 inline-flex rounded-full"
                  :class="`status--${
                    val.color
                      ? val.color
                      : val.value
                      ? val.value
                      : val.name.includes(' ')
                      ? val.name.toLowerCase().replaceAll(' ', '_')
                      : val.name.toLowerCase()
                  }`"
                ></feather-icon>
                <div v-if="val.sub && val.sub.length > 0">
                  <div
                    class="truncate"
                    v-for="(item, itemIndex) in val.sub"
                    :key="itemIndex"
                  >
                    <p class="mb-1 mr-4 font-bold inline-block">
                      {{ item.count || 0 }}
                    </p>
                    <span>{{
                      item.title ? item.title : item.name | text_formatter
                    }}</span>
                  </div>
                </div>
                <div class="truncate" v-else>
                  <h3 class="mb-1 font-bold">
                    {{ val.count || 0 }}
                  </h3>
                  <span>{{
                    val.title ? val.title : val.name | text_formatter
                  }}</span>
                </div>
              </div>
              <div class="truncate mt-2" v-if="val.sub && val.sub.length > 0">
                <h6 class="font-bold">
                  <span>{{
                    val.title ? val.title : val.name | text_formatter
                  }}</span>
                </h6>
              </div>
            </div>
          </div>
        </vx-card>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'multi-data-status-counter',
  props: {
    status_prop: {
      type: Array,
      required: true
    },
    selected_prop: {}
  },
  watch: {
    status_prop (newValue) {
      this.status = newValue
    },
    selected_prop (newSelect) {
      this.selected = newSelect
    }
  },
  data () {
    return {
      status: this.status_prop,
      selected: null
    }
  },
  methods: {
    getFilter (val) {
      if ((val.value || val.id) === this.selected) {
        this.selected = null
        this.$emit('filter-change', '')
      } else {
        this.selected = val.value || val.id || val.name
        this.$emit('filter-change', val.value || val.name)
      }
    }
  },
  computed: {
    card_width () {
      return `${this.status_prop.length / 100}%`
    }
  },
  created () {
    if (this.status_prop.find((el) => el.active === true)) {
      const defaultActiveStatus = this.status_prop.find(
        (el) => el.active === true
      )
      this.selected = defaultActiveStatus.value || defaultActiveStatus.id
    }
  }
}
</script>
<style lang="scss">
.status_card {
  cursor: pointer;
}
.vx-card {
  cursor: pointer;
  .active & {
    box-shadow: 0 14px 14px 0 rgba(0, 0, 0, 0.1),
      0 5px 5px 0 rgba(0, 0, 0, 0.11) !important;
    filter: drop-shadow(0 10px 10px rgba(0, 0, 0, 0.1));
    transform: translateY(-5px);
  }
}
.small-card {
  min-width: 100px;

  .feather-icon {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .truncate {
    span {
      text-transform: capitalize;
      color: #6e6d6d;
    }
  }
}
@media (min-width: 450px) {
  .counter_block {
    width: 144px;
  }
  .small-card {
    width: 100%;
  }
}
</style>
