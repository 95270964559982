<template>
  <ListPageWithCreateButton :showCreateBtn="false">
    <template slot="listDataContent">
      <transition-group class="list-transition" name="list-enter-up" tag="ul">
        <li
          v-for="(assignment, index) in assignments"
          :key="index"
          class="mb-12 relative"
          :style="[{transitionDelay: index * 0.1 + 's'}]">
          <vx-card class="my-2">
            <vs-dropdown
              class="action-buttons-assignment-principal"
              vs-trigger-click>
              <vs-button
                radius
                color="#999"
                type="flat"
                icon="more_vert"></vs-button>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  @click="
                    getSingleTeacherAssignments(assignment.class_teacher_id)
                  ">
                  <span>
                    <feather-icon
                      class="mr-2"
                      icon="EyeIcon"
                      svgClasses="h-4 w-4" />
                    <span>{{ $t('assessments.view_assignments') }}</span>
                  </span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
            <div
              class="min_height_110 vx-row items-center p-1 pt-1 pb-1 md:p-1">
              <!-- Name -->
              <div class="vx-col w-full xl:w-1/3 md:w-1/2 xl:mb-0 mb-4">
                <h5 class="mb-4 inline-block">{{ assignment.teacher }}</h5>
                <h6 class="mb-4">
                  {{ assignment.assignmentss.counter }}
                  {{ $t('basic.assignments') }}
                </h6>
              </div>
              <div class="vx-col w-full xl:w-1/3 md:w-1/2 xl:mb-0 mb-4">
                <h6 class="mb-4">
                  {{ assignment.class }}
                </h6>
                <h6 class="mb-4">
                  {{ assignment.subject }}
                </h6>
              </div>

              <div class="vx-col w-full xl:w-1/3 md:w-1/2 xl:mb-0 mb-4">
                <h6 class="mb-4 text-success">
                  {{ assignment.assignmentss.assessed }}
                  {{ $t('assessments.student_assessed') }}
                </h6>
                <h6 class="mb-4 text-warning">
                  {{ assignment.assignmentss.to_be_assess }}
                  {{ $t('assessments.student_need_to_be_assess') }}
                </h6>
                <h6 class="mb-4 text-danger">
                  {{ assignment.assignmentss.delayed }}
                  {{ $t('assessments.delayed') }}
                </h6>
              </div>
            </div>
          </vx-card>
        </li>
      </transition-group>
      <!-- Pagination  -->
      <template v-if="totalPages > 1">
        <vs-pagination
          :total="totalPages"
          :max="checkDeviceType ? 5 : 7"
          v-model="currentPage" />
      </template>
      <!-- <template v-if="assignments.length < 1"> -->
      <template v-if="assignments.length == 0">
        <p class="text-center">{{ $t('basic.no_data_available') }}</p>
      </template></template
    >
    <template slot="rightSide">
      <RoundFilter
        :filter_date="true"
        :filters_prop="filters"
        @dateChanged="getDate"
        @refilter="refilter"
        ref="roundFilter"
        :loading="loadingFilter">
        <template slot="extra_content">
          <div class="w-full mb-10">
            <vs-input
              :label-placeholder="$t('form.search_by_teacher_name')"
              v-model="searchContent"
              v-validate="{email: searchContent.includes('@')}"
              class="w-full"
              name="email"
              icon="icon icon-search"
              icon-pack="feather"
              @keyup.enter="search" />
            <span class="text-sm text-danger" v-if="errors.first('email')">{{
              errors.first('email') | text_formatter
            }}</span>
          </div>
        </template>
      </RoundFilter>
    </template>
  </ListPageWithCreateButton>
</template>
<script>
// Components
import RoundFilter from '@/components/general/RoundFilter'

// APIs
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const assignmentsRepo = RepositoryFactory.get(
  'schoolPrincipalRepository',
  'assignmentsRepo'
)

// Datepicker
import {commonDataMixin} from '@/mixins.js'

export default {
  name: 'principal_assignments',
  components: {
    RoundFilter
  },
  mixins: [commonDataMixin],
  data() {
    return {
      searchContent: '',
      /////////////////////////
      // round Filter
      /////////////////////////
      assignments: [],
      filters: [
        {
          id: 1,
          name: this.$t('basic.academic_year'),
          payload_name: 'academic_year_id',
          onChangeFireAction: true,

          by: []
        },

        {
          id: 2,
          name: this.$t('basic.divisions'),
          payload_name: 'division_ids',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 3,
          name: this.$t('basic.sections'),
          payload_name: 'section_ids',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 4,
          name: this.$t('basic.grades'),
          payload_name: 'grade_ids',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 5,
          name: this.$t('basic.classes'),
          payload_name: 'class_ids',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 6,
          name: this.$t('basic.subject'),
          payload_name: 'subjects',
          by: []
        },
        {
          id: 7,
          name: this.$t('assessments.assessed'),
          payload_name: 'assess_or_not_assess',
          singleSeletable: true,
          by: [
            {
              id: 'all',
              name: this.$t('assessments.all'),
              checked: false
            },
            {
              id: 'assess',
              name: this.$t('assessments.assessed'),
              checked: false
            },
            {
              id: 'not_assess',
              name: this.$t('assessments.not_assessed'),
              checked: false
            }
          ]
        },
        {
          id: 8,
          name: this.$t('basic.teacher'),
          payload_name: 'teacher_ids',
          by: []
        }
      ],
      filterPayload: {
        school: [],
        division_ids: [],
        section_ids: [],
        grade_ids: [],
        class_ids: [],
        teacher_ids: [],
        subjects: [],
        teacher: ''
      },
      checked_filters: null,
      loadingFilter: false,
      /////////////////////////
      // Pagination
      /////////////////////////
      totalPages: 0,
      currentPage: 1,
      countPerPage: 10,
      fromDate: '',
      toDate: ''
    }
  },
  watch: {
    'filterPayload.dateIn'(v) {
      // this.configDatepickerTo.minDate = `${(v).split('-')[0]}-${(v).split('-')[1]}-${Number((v).split('-')[2]) + 1}`
      this.configDatepickerTo.minDate = v
    },
    currentPage(v) {
      this.getPrincipalAssignemts(v)
    },
    filterPayload: {
      deep: true,
      immediate: true,
      handler() {
        this.getPrincipalAssignemts()
      }
    }
  },
  methods: {
    ///////////////////////////////
    // loading
    /////////////////////////////
    loading() {
      this.$vs.loading()
    },
    stopLoading() {
      this.$vs.loading.close()
    },
    ///////////////////////////////
    // Dealing with Data
    /////////////////////////////
    resetList() {
      this.loading()
      this.assignments = []
      // this.isOnlineclasses = false
    },
    setList(data) {
      this.stopLoading()
      this.assignments = data.data
      this.setPagination(data)
    },
    setPagination(data) {
      this.totalPages = data.total_pages
      this.countPerPage = data.per_page
      this.currentPage = data.current_page
    },
    refilter(checked) {
      this.currentPage = 1
      checked.map((item) => {
        this.filterPayload[item.name] = item.checked
      })
    },

    resetFilterData() {
      this.filterPayload.dateIn = ''
      this.filterPayload.dateOut = ''
      this.resetStatusCardsData()
      this.refilter()
    },

    // Date Filter
    changeDate() {
      this.refilter(this.checked_filters)
    },
    ///////////////////////////////////
    // get Filters Data
    ///////////////////////////////////
    getFiltersData() {
      this.filters[1]['by'] = []
      this.filters[2]['by'] = []
      this.filters[3]['by'] = []
      this.filters[4]['by'] = []
      this.filters[5]['by'] = []
      const schools = [this.filterPayload.school]
      this.getFilterData({schools}).then((res) => {
        res.divisions.map((el) => {
          this.filters[1]['by'].push({
            id: el.id,
            name: el.name,
            checked: false
          })
        })
      })
      assignmentsRepo.getAllTeacherRole().then((res) => {
        res.data.map((el) => {
          this.filters[7]['by'].push({
            id: el.id,
            name: el.name,
            checked: false
          })
        })
      })
    },
    getFilteredSectionsAndSubjects(chosen) {
      this.loadingFilter = true

      this.filters[2]['by'] = []
      this.filters[3]['by'] = []
      this.filters[4]['by'] = []
      this.filters[5]['by'] = []
      const data = {
        divisions: chosen.division_ids,
        schools: chosen.school
      }
      this.getFilterData(data)
        .then((res) => {
          res.sections.map((el) => {
            this.filters[2]['by'].push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
          res.divisions_subjects.map((el) => {
            this.filters[5]['by'].push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
        })
        .finally(() => {
          this.loadingFilter = false
        })
    },
    getFilteredGrades(chosen) {
      this.loadingFilter = true

      this.filters[3]['by'] = []
      this.filters[4]['by'] = []
      const data = {
        sections: chosen.section_ids,
        divisions: chosen.division_ids,
        schools: chosen.school
      }
      this.getFilterData(data)
        .then((res) => {
          res.grades.map((el) => {
            this.filters[3]['by'].push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
        })
        .finally(() => {
          this.loadingFilter = false
        })
    },
    getFilteredClasses(chosen) {
      this.loadingFilter = true

      this.filters[4]['by'] = []
      const data = {
        sections: chosen.section_ids,
        divisions: chosen.division_ids,
        schools: chosen.school,
        grades: chosen.grade_ids
      }
      this.getFilterData(data)
        .then((res) => {
          res.grades_classes.map((el) => {
            this.filters[4]['by'].push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
        })
        .finally(() => {
          this.loadingFilter = false
        })
    },

    //---- List
    getPrincipalAssignemts() {
      const {school, ...filters} = this.filterPayload
      this.resetList()
      assignmentsRepo
        .getPrincipalAssignemts(this.currentPage, filters)
        .then((res) => {
          this.setList(res.data)
        })
        .catch(() => {
          this.stopLoading()
        })
    },

    ///////////////////////////////
    // Round Filter
    /////////////////////////////

    editAssessment(data) {
      $sharedEvent.fire('openCreateTaskModal', data)
    },
    getSingleTeacherAssignments(id) {
      this.$router.push({
        name: 'principal_teacher_assignments',
        params: {
          id
        }
      })
    },
    getDate(val) {
      const dates = val.split('to')
      this.filterPayload.start_date = dates[0]
      this.filterPayload.end_date = dates[1]
      this.getPrincipalAssignemts()
    },
    search() {
      this.filterPayload.teacher = this.searchContent
    }
  },

  created() {
    this.filterPayload.school = this.authUser.school_id
    this.$root.$on('onChange:division_ids', this.getFilteredSectionsAndSubjects)
    this.$root.$on('onChange:section_ids', this.getFilteredGrades)
    this.$root.$on('onChange:grade_ids', this.getFilteredClasses)
    this.getFiltersData()
    // this.getPrincipalAssignemts()
  },
  beforeDestroy() {
    this.$root.$off('onChange:division_ids')
    this.$root.$off('onChange:section_ids')
    this.$root.$off('onChange:grade_ids')
  }
}
</script>
<style lang="scss">
.action-buttons-assignment-principal {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
