<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full mb-12">
        <h3>Exams</h3>
      </div>
      <ul
        class="vx-col w-full list-none"
        :class="{ 'lg:w-full': !this.$store.state.reduceButton }"
      >
        <li
          class="mb-8"
          v-for="(val, i) in exams"
          :key="val.id"
          :style="[{ transitionDelay: i * 0.1 + 's' }]"
        >
          <exam-card :exam="val"></exam-card>
        </li>
        <template v-if="exams.length > 0 && totalPages > 1">
          <vs-pagination
            :total="totalPages"
            :max="countPerPage"
            v-model="currentPage"
            class="mt-6"
          />
        </template>
      </ul>
    </div>
  </div>
</template>
<script>
import ExamCard from '@/modules/exam/components/ExamCard'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const ExamRepo = RepositoryFactory.get('ExamModule', 'Exam')

export default {
  components: {
    ExamCard
  },
  data () {
    return {
      exams: [],
      /////////////////////////
      // Pagination
      /////////////////////////
      totalPages: 0,
      currentPage: 1,
      countPerPage: 10

    }
  },
  watch: {
    currentPage () {
      this.getAllExams()
    }
  },
  created () {
    this.getAllExams()
  },
  methods: {
    /////////////////////////////
    // APIs
    /////////////////////////////

    getAllExams () {
      this.VSLoading()
      ExamRepo.getAllStudentExams(this.currentPage)
        .then(res => {
          console.log('exams', res)
          this.exams = res.data
          this.setPagination(res.pagination_data)
        })
        .catch(err => {
          console.log('err', err)
        })
        .finally(() => this.stopVSLoading())
    },
    ////////////////////////////
    // Pagination
    ////////////////////////////
    setPagination (data) {
      this.totalPages = data.total_pages || data.last_page
      this.countPerPage = data.per_page
      this.currentPage = data.current_page
    }
  }
}
</script>
