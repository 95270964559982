import { render, staticRenderFns } from "./ExamForm.vue?vue&type=template&id=2268e2b2"
import script from "./ExamForm.vue?vue&type=script&lang=js"
export * from "./ExamForm.vue?vue&type=script&lang=js"
import style0 from "./ExamForm.vue?vue&type=style&index=0&id=2268e2b2&prod&lang=scss&scope=true"
import style1 from "./ExamForm.vue?vue&type=style&index=1&id=2268e2b2&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports