<template>
  <div id="invoice-page">
    <vx-card id="invoice-container">
      <!-- INVOICE METADATA -->
      <div
        class="
          flex flex-wrap
          md:justify-between
          justify-center
          items-center
          md:flex-row
          flex-col
          leading-loose
          p-base
        "
      >
        <div class="mt-base">
          <img src="@/assets/images/logo/logo.png" :alt="siteTitle" id="logo" />
        </div>
        <div class="md:text-right text-center md:mt-0 mt-6">
          <h3>{{ exam_prop.name }}</h3>
          <div class="invoice__invoice-detail mt-6">
            <!-- <h6 class="text-uppercase">{{ $t("exam.exam_no") }}</h6>
            <p>{{ exam_prop.id }}</p> -->
            <h6 class="mt-4 text-uppercase">{{ $t("exam.exam_date") }}</h6>
            <p>{{ exam_date }}</p>
          </div>
        </div>
      </div>
      <!-- Exam Questions -->
      <div class="p-base">
        <!-- INVOICE TASKS TABLE -->
        <div class="p-5 border">
          <div
            v-for="(question, i) in exam_prop.questions"
            :key="i"
            class="m-base"
          >
            <h6>
              <b>{{ $t("exam.question") }} {{ i + 1 }}</b>
            </h6>
            <p v-html="question.description" />
            <ol>
              <div
                v-if="question.type_id != 1 && question.type_id != 4"
                class="p-5 border"
              >
                <p v-for="(ans, ansIndex) in question.answers" :key="ansIndex">
                  <span class="answerView" v-html="ans.description" />
                  <span v-if="ans.is_correct">
                    -- <vs-icon icon="done" color="green"></vs-icon
                  ></span>
                </p>
              </div>
              <div v-if="question.type_id == 4">
                {{ question.answers[0].is_correct }}
              </div>
            </ol>
          </div>
          <div>
            <div class="w-full mt-4" v-if="examType === 'paper'">
              <single-file-previewer
                :fileUrl="exam_prop.extra_doc_link"
                :fileType="exam_prop.extra_doc_type"
              ></single-file-previewer>

              <vs-button
                @click="downloadFile(exam_prop.extra_doc_link)"
                class="my-base mx-auto block"
              >
                {{ $t("basic.Download_file") }}
              </vs-button>
            </div>
          </div>
        </div>
      </div>
      <!-- INVOICE FOOTER -->
      <div class="text-right p-base">
        <p class="mb-4"></p>
        <p >
          <span v-if="edit_mood && examType === 'digital'" class="mr-8">
            {{ $t("exam.questions") }}:
            <span class="font-semibold">{{ exam_prop.questions.length }}</span>
          </span>
          <span>
            {{ $t("form.score") }}:
            <span class="font-semibold">{{
              totalPoints ? totalPoints : total_points
            }}</span>
          </span>
        </p>
      </div>
    </vx-card>
  </div>
</template>
<script>
// API
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const ExamRepo = RepositoryFactory.get('ExamModule', 'Exam')

export default {
  name: 'exam-preview',
  props: {
    exam_prop: {
      type: Object,
      required: true
    },
    edit_mood: {
      type: Boolean
    },
    totalPoints: {
      type: [Number, String]
    },
    isExamForm: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data () {
    return {
      grading_types_all: [],
      exams_types: []
    }
  },
  computed: {
    exam_date () {
      return new Date(this.exam_prop.date)
        .toDateString()
        .split(' ')
        .slice(1, 4)
        .join(' ')
    },
    total_points () {
      if (this.exam_prop) {
        const examGrade = this.edit_mood
          ? this.isExamForm
            ? this.exam_prop.exam_grading_type_id
            : this.exam_prop.grading_type.id
          : this.exam_prop.exam_grading_type_id
        if (examGrade === this.getExamPointsTypeId('Points_Per_Question')) {
          return this.exam_prop.questions
            .map((question) => {
              return question.points
            })
            .reduce((accumulator, currentPoint) => accumulator + currentPoint)
        } else {
          return this.exam_prop.total_points
        }
      } else {
        return 0
      }
    },
    examType () {
      if (this.exam_prop && this.exams_types.length) {
        const examType = this.edit_mood
          ? this.isExamForm
            ? this.exam_prop.type_id
            : this.exam_prop.type.id
          : this.exam_prop.type_id
        return this.exams_types
          .filter((item) => item.id === examType)[0]
          .type.toLowerCase()
      } else {
        return ''
      }
    }
  },
  methods: {
    //------------ Get All grading Types ----------
    getAllgradingTypes () {
      return new Promise((resolve, reject) => {
        ExamRepo.getAllgradingTypes()
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    //------------ Get All Exam Types ----------
    getExamTypes () {
      return new Promise((resolve, reject) => {
        ExamRepo.getExamTypes()
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    getExamPointsTypeId (val) {
      return this.grading_types_all.filter((item) => item.type === val)[0].id
    }
  },
  async created () {
    await this.getAllgradingTypes().then((res) => {
      this.grading_types_all = res
    })
    await this.getExamTypes().then((res) => {
      this.exams_types = res
    })
  }
}
</script>
<style lang="scss">
#invoice-page {
  h6 {
    font-size: 0.8rem;
    margin-bottom: 10px;

    b {
      text-transform: uppercase;
      color: #626262;
    }
  }
  #logo {
    max-width: 250px;
  }
}

.answerView {
  p {
    display: inline;
  }
}
</style>
