<template>
  <div class="announcement-form">
    <vx-card class="p-3 pt-6 pb-6 md:p-6">
      <form>
        <div class="flex justify-end mb-4">
          <feather-icon icon="EditIcon" class="w-5 h-5 mr-1 text-success" @click="editMode = true" v-if="editMode ===
           false">
          </feather-icon>
        </div>
        <div class="vx-row mb-10">
          <div class="vx-col md:w-1/2 w-full ">
            <label>{{ $t('myDay.release_time_myday') }}:</label>
            <flat-pickr
              :disabled="editMode == false"
              v-model="details.release_time_myday"
              name="from_time"
              class="w-full md:mt-0"
              :config="{...configTimePicker}"
            />
          </div>
          <div class="vx-col md:w-1/2 w-full ">
            <label>{{ $t('myDay.last_previous_days') }}:</label>
            <vs-input
              :disabled="editMode == false"
              name="last_previous_days"
              v-validate="'required|numeric|min_value:0'"
              v-model="details.last_previous_days"
              class="w-full"
              placeholder="0"
            />
            <span class="text-danger w-full">{{
                errors.first("last_previous_days") | text_formatter
              }}</span>
          </div>
        </div>
        <div>
          <div class="flex mb-4">
            <vs-switch v-model="details.myday_for_parent_for_web"  :disabled="editMode == false"/>
            <label>{{ $t("myDay.myday_for_parent_for_web") }}</label>
          </div>
        </div>
        <div>
          <div class="flex mb-4">
            <vs-switch v-model="details.myday_for_parent_for_mobile" :disabled="editMode == false"/>
            <label>{{ $t("myDay.myday_for_parent_for_mobile") }}</label>
          </div>
        </div>
        <div>
          <div class="flex mb-4">
            <vs-switch v-model="details.myday_for_student_for_web" :disabled="editMode == false"/>
            <label>{{ $t("myDay.myday_for_student_for_web") }}</label>
          </div>
        </div>
        <div>
          <div class="flex mb-4">
            <vs-switch v-model="details.myday_for_student_for_mobile" :disabled="editMode == false"/>
            <label>{{ $t("myDay.myday_for_student_for_mobile") }}</label>
          </div>
        </div>
        <div>
          <div class="flex mb-4">
            <vs-switch v-model="details.teacher_add_note_for_web_and_mobile" :disabled="editMode == false"/>
            <label>{{ $t("myDay.teacher_add_note_for_web_and_mobile") }}</label>
          </div>
        </div>
<!--        <div>
          <div class="flex mb-4">
            <vs-switch v-model="details.section_coordinator_add_note_for_web_and_mobile" :disabled="editMode == false"/>
            <label>{{ $t("myDay.section_coordinator_add_note_for_web_and_mobile") }}</label>
          </div>
        </div>-->
        <div>
          <div class="flex mb-4">
            <vs-switch v-model="details.teacher_section_coordinator_add_note_for_web_and_mobile" :disabled="editMode == false"/>
            <label>{{ $t("myDay.teacher_section_coordinator_add_note_for_web_and_mobile") }}</label>
          </div>
        </div>
<!--        <div>
          <div class="flex mb-4">
            <vs-switch v-model="details.grade_coordinator_add_note_for_web_and_mobile" :disabled="editMode == false"/>
            <label>{{ $t("myDay.grade_coordinator_add_note_for_web_and_mobile") }}</label>
          </div>
        </div>-->
        <div>
          <div class="flex mb-4">
            <vs-switch v-model="details.teacher_grade_coordinator_add_note_for_web_and_mobile" :disabled="editMode == false"/>
            <label>{{ $t("myDay.teacher_grade_coordinator_add_note_for_web_and_mobile") }}</label>
          </div>
        </div>
        <div v-if="editMode === true" class="flex flex-wrap justify-end items-center mt-10">
          <vs-button class="inline-block" @click="formSubmitted()">
            {{ $t("form.submit") }}
          </vs-button>
        </div>
      </form>
    </vx-card>
  </div>
</template>

<script>
import moment from 'moment'
import { commonDataMixin } from '@/mixins'

import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const myDayRepository = RepositoryFactory.get(
  'myDay',
  'myDay'
)
export default {
  mixins: [commonDataMixin],
  data () {
    return {
      configTimePicker:{
        enableTime: true,
        noCalendar: true,
        dateFormat: 'H:i'
      },
      editMode: false,
      details: {
        myday_for_parent_for_web: false,
        myday_for_parent_for_mobile: false,
        myday_for_student_for_web: false,
        myday_for_student_for_mobile: false,
        teacher_add_note_for_web_and_mobile: false,
        section_coordinator_add_note_for_web_and_mobile: false,
        teacher_section_coordinator_add_note_for_web_and_mobile: false,
        grade_coordinator_add_note_for_web_and_mobile: false,
        teacher_grade_coordinator_add_note_for_web_and_mobile: false,
        release_time_myday: moment().format('HH:MM'),
        last_previous_days: 5
      }
    }
  },
  methods:{
    formSubmitted () {
      this.editMode = false
      this.loading = true
      myDayRepository.updateSettings(this.details).then(res => {
        this.successMsg(res)
      }).finally(() => this.loading = false)
    },
    getAllSettings () {
      myDayRepository.getAllSettings().then(res => {
        this.details = res.data
      })
    }
  },
  created () {
    this.getAllSettings()
  }
}
</script>
