<template>
  <div>
    <form
      @submit.prevent="submitForm"
      @keyup.enter="submitForm"
    >
      <div class="mb-4 mt-auto w-full items-center mx-auto">
        <div class="my-2 vx-col w-full mb-2">
          <label
          >{{ $t("basic.academic_year")
            }}<sup class="text-danger">*</sup></label
          >
          <v-select
            :closeOnSelect="true"
            v-model="qualifiedData.Academic_id"
            :options="allAcademicYears"
            label="name"
            :reduce="(option) => option._id"
            v-validate="'required'"
            name="academic_year_id"
            class="w-full"
            :disabled="allAcademicYears ? false : true"
          />

          <span class="text-danger text-sm">{{
              errors.first("academic_year_id")
            }}</span>
        </div>
        <div class="my-2 vx-col  w-full mb-2">
          <label>
            Official Email
            <span class="text-danger">*</span>
          </label>
          <div class="repeater">
            <vs-input
              type="email"
              v-model="qualifiedData.official_email"
              class="w-full"
              name="official_email"
              v-validate="'required|email'"
            />
          </div>
          <span class="text-danger text-sm">{{ errors.first('official_email') }}</span>
        </div>
        <div class="my-2 vx-col  w-full mb-2">
          <label>
            Code
            <span class="text-danger">*</span>
          </label>
          <div class="repeater">
            <vs-input
              type="text"
              v-model="qualifiedData.code"
              class="w-full"
              name="code"
              v-validate="'required'"
            />
          </div>
          <span class="text-danger text-sm">{{ errors.first('code') }}</span>
        </div>
      </div>
      <!-- Actions -->
      <div class="flex flex-wrap justify-between items-center mt-auto mb-0">
        <vs-button class="mt-5 md:w-auto md:mr-0 ml-auto" @click="submitForm">
          {{ $t("form.submit") }}
        </vs-button>
      </div>
    </form>
  </div>
</template>
<script>

// import getData from './form/common_data_mixin'

import { commonDataMixin, checkValidationMixin } from '@/mixins'
export default {
  name: 'Qualified-Data',
  mixins: [commonDataMixin, checkValidationMixin],

  data () {
    return {
      qualifiedData: {
        Academic_id: '',
        official_email: '',
        code: ''
      }
    }
  },
  watch: {
  },
  computed: {
  },
  methods: {
    submitForm () {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.$emit('formSubmited', this.qualifiedData)
        } else {
          this.errMsg()
        }
      })
    }
  },
  created () {
    this.getAllAcademic()
  },
  mounted () {}
}
</script>
<style lang="scss">
.remove-student-attachment {
  top: 32px;
  left: 90px;
  z-index: 20;
  cursor: pointer;
  svg {
    stroke: white;
    stroke-width: 2px;
  }
}
</style>
