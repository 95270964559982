import assignmentForm from './components/assignmentForm/assignmentForm'
import assignment_list from './Pages/list'
import assessAssignment from './Pages/assess-assignment'

export default [
  {
    path: '/teacher/assignment/create',
    name: 'assignment_create',
    i18n: 'basic.assignment',
    component: assignmentForm,
    meta: {
      breadcrumb: [
        {
          title: 'basic.dashboard',
          url: 'landing/home'
        },
        {
          title: 'basic.assignments',
          url: '/teacher/assignments'
        },
        {
          title: 'basic.create',
          url: null,
          active: true
        }
      ],
      pageTitle: 'assignment.CreateAssignment',
      rule: 'Teacher'
    }
  },
  // All
  {
    path: '/teacher/assignments',
    name: 'assignments',
    component: assignment_list,
    meta: {
      breadcrumb: [
        {
          title: 'basic.dashboard',
          url: 'landing/home'
        },
        {
          title: 'basic.assignments',
          url: null,
          active: true
        }
      ],
      pageTitle: 'basic.assignments',
      rule: 'Teacher'
    }
  },
  {
    path: '/teacher/assignment/:id/assess',
    name: 'assess_assignment',
    component: assessAssignment,
    meta: {
      breadcrumb: [
        {
          title: 'basic.dashboard',
          url: 'landing/home'
        },
        {
          title: 'basic.assignments',
          url: '/teacher/assignments'
        },
        {
          title: 'basic.assess',
          url: '',
          active: true
        }
      ],
      pageTitle: 'assignment.assess_assignment',
      rule: 'Teacher'
    }
  },
  //   // Edit
  {
    path: '/teacher/assignment/edit/:assignment_id',
    name: 'assignment_edit',
    i18n: 'basic.assignment',
    // props: true,
    component: assignmentForm,
    meta: {
      breadcrumb: [
        {
          title: 'basic.dashboard',
          url: 'landing/home'
        },
        {
          title: 'basic.assignments',
          url: '/teacher/assignments'
        },
        {
          title: 'basic.edit',
          url: null,
          active: true
        }
      ],
      pageTitle: 'assignment.EditAssignment',
      rule: 'Teacher'
    }
  }
]
