<template>
  <classForm></classForm>
</template>
<script>
import classForm from './components/ClassForm.vue'
export default {
  name:'upgrade-class',
  components:{classForm}
}
</script>
