<template>
  <vs-card class="px-10">
    <div class="flex flex-no-wrap justify-between">
      <section class="flex flex-no-wrap">
        <img class="w-32 h-32 rounded-lg" :src="userInfo.image_url ? userInfo.image_url: avatar" alt="" width="384" height="512">
        <div class="flex flex-col justify-between">
          <p class="text-lg font-semibold ml-5">{{ userInfo.name }}</p>
          <p class="text-lg font-semibold ml-5">{{ userInfo.code }}</p>
          <p class="text-lg font-semibold ml-5">{{ userInfo.divisions? userInfo.divisions.name: ''}} - {{ userInfo.section?userInfo.section.name: '' }} - {{userInfo.class?userInfo.class.name: ''}}</p>
        </div>
      </section>
      <div class="flex flex-col justify-between mr-2 text-center pt-4">
        <div class="ml-8">
            <span class="text-center">
              <feather-icon icon="DollarSignIcon" class="p-2 rounded-lg" style="background: #dcf0f5"/>
            </span>
            <div class="mt-4"><h5 class="mb-0 text-primary"> {{ userInfo.total_amount }} {{ $t('wallet.currency') }}</h5><small>{{ $t('wallet.currentFund') }}</small></div></div>
      </div>
    </div>
  </vs-card>
</template>
<script>
export default {
  props: ['userInfo'],
  name: 'userInfo',
  data () {
    return {
      avatar: require('@/assets/images/avatar.png')
    }
  }
}
</script>
