import showCanteen from './pages/canteen/show'
import createCanteen from './pages/canteen/create'
import editCanteen from './pages/canteen/edit'
import userList from './pages/user/list'
import createCanteenUser from './pages/user/create'
import editCanteenUser from './pages/user/edit'
import categoryList from './pages/category/list'
import createCanteenCategory from './pages/category/create'
import editCanteenCategory from './pages/category/edit'
import createCategoryItem from './pages/item/create'
import showCategoryItem from './pages/item/list'
import editItem from './pages/item/edit'
import TransactionCategoryItem from './pages/transaction/list'
import canteenItemsList from './pages/item/mainList'

export default [
  {
    path: '',
    name: 'allCanteen',
    component: showCanteen,
    props: true,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        {
          title: 'canteen.canteen',
          active: true
        }
      ],
      pageTitle: 'canteen.canteen',
      rule: 'TeacherSNDSMD'
    }
  },
  {
    path: 'create-canteen',
    name: 'create-canteen',
    component: createCanteen,
    props: true,
    meta: {
      breadcrumb: [

        { title: 'home', url: '/home' },
        {
          title: 'canteen.canteen',
          url: '/canteen'
        },
        {
          title: 'canteen.createCanteen',
          active: true
        }
      ],
      pageTitle: 'canteen.createCanteen',
      rule: 'TeacherSNDSMD'
    }
  },
  {
    path: 'edit-canteen/:id',
    name: 'edit-canteen',
    component: editCanteen,
    props: true,
    meta: {
      breadcrumb: [

        { title: 'home', url: '/home' },
        {
          title: 'canteen.canteen',
          url: '/canteen'
        },
        {
          title: 'canteen.editCanteen',
          active: true
        }
      ],
      pageTitle: 'canteen.editCanteen',
      rule: 'TeacherSNDSMD'
    }
  },
  {
    path: 'canteen-users/:id',
    name: 'canteen-users',
    component: userList,
    props: true,
    meta: {
      breadcrumb: [

        { title: 'home', url: '/home' },
        {
          title: 'canteen.canteen',
          url: '/canteen'
        },
        {
          title: 'canteen.userList',
          active: true
        }
      ],
      pageTitle: 'canteen.userList',
      rule: 'TeacherSNDSMD'
    }
  },
  {
    path: 'create-canteen-user/:id',
    name: 'create-canteen-user',
    component: createCanteenUser,
    props: true,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        {
          title: 'canteen.canteen',
          url: '/canteen'
        },
        {
          title: 'canteen.userList',
          url: '/canteen/canteen-users/:id'
        },
        {
          title: 'canteen.createUser',
          active: true
        }
      ],
      pageTitle: 'canteen.createUser',
      rule: 'TeacherSNDSMD'
    }
  },
  {
    path: 'canteen-users/:id/edit-canteen-user/:user_id',
    name: 'edit-canteen-user',
    component: editCanteenUser,
    props: true,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        {
          title: 'canteen.canteen',
          url: '/canteen'
        },
        {
          title: 'canteen.userList',
          url: '/canteen/canteen-users/:id'
        },
        {
          title: 'canteen.editUser',
          active: true
        }
      ],
      pageTitle: 'canteen.editUser',
      rule: 'TeacherSNDSMD'
    }
  },
  {
    path: 'canteen-categories/:id',
    name: 'canteen-categories',
    component: categoryList,
    props: true,
    meta: {
      breadcrumb: [
        {
          title: 'home',
          url: '/home'
        },
        {
          title: 'canteen.canteen',
          url: '/canteen'
        },
        {
          title: 'canteen.categoryList',
          active: true
        }
      ],
      pageTitle: 'canteen.categoryList',
      rule: 'TeacherSNDSMD'
    }
  },
  {
    path: 'create-canteen-category/:id',
    name: 'create-canteen-category',
    component: createCanteenCategory,
    props: true,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        {
          title: 'canteen.canteen',
          url: '/canteen'
        },
        {
          title: 'canteen.categoryList',
          url: '/canteen/canteen-categories'
        },
        {
          title: 'canteen.createCategory',
          url: 'canteen/create-canteen-category',
          active: true
        }
      ],
      pageTitle: 'canteen.createCategory',
      rule: 'TeacherSNDSMD'
    }
  },
  {
    path: 'edit-canteen-category/:id',
    name: 'edit-canteen-category',
    component: editCanteenCategory,
    props: true,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        {
          title: 'canteen.canteen',
          url: '/canteen'
        },
        {
          title: 'canteen.categoryList',
          url: '/canteen/canteen-categories'
        },
        {
          title: 'canteen.editCategory',
          url: 'canteen/edit-canteen-category',
          active: true
        }
      ],
      pageTitle: 'canteen.editCategory',
      rule: 'TeacherSNDSMD'
    }
  },
  {
    path: 'create-category-item/:id',
    name: 'create-category-item',
    component: createCategoryItem,
    props: true,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        {
          title: 'canteen.canteen',
          url: '/canteen'
        },
        {
          title: 'canteen.categoryList',
          url: '/canteen/canteen-categories'
        },
        {
          title: 'canteen.createItem',
          active: true
        }
      ],
      pageTitle: 'canteen.createItem',
      rule: 'TeacherSNDSMD'
    }
  },
  {
    path: 'canteen-categories/:id/show-category-item/:item_id',
    name: 'show-category-item',
    component: showCategoryItem,
    props: true,
    meta: {
      breadcrumb: [
        {
          title: 'home',
          url: '/home'
        },
        {
          title: 'canteen.canteen',
          url: '/canteen'
        },
        {
          title: 'canteen.categoryList',
          url: '/canteen/canteen-categories/:id',
          active: true
        },
        {
          title: 'canteen.categoryItem',
          active: true
        }
      ],
      pageTitle: 'canteen.categoryItem',
      rule: 'TeacherSNDSMD'
    }
  },
  {
    path: 'create-canteen-item/:id',
    name: 'create-canteen-item',
    component: createCategoryItem,
    props: true,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        {
          title: 'canteen.canteen',
          url: '/canteen'
        },
        {
          title: 'canteen.createItem',
          url: 'canteen/create-canteen-item',
          active: true
        }
      ],
      pageTitle: 'canteen.createItem',
      rule: 'TeacherSNDSMD'
    }
  },
  {
    path: 'edit-canteen-item/:id',
    name: 'edit-canteen-item',
    component: editItem,
    props: true,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        {
          title: 'canteen.canteen',
          url: '/canteen'
        },
        {
          title: 'canteen.editItem',
          url: 'canteen/edit-canteen-item',
          active: true
        }
      ],
      pageTitle: 'canteen.editItem',
      rule: 'TeacherSNDSMD'
    }
  },
  {
    path: 'canteen-items/:id',
    name: 'canteen-items',
    component: canteenItemsList,
    props: true,
    meta: {
      breadcrumb: [
        {
          title: 'home',
          url: '/home'
        },
        {
          title: 'canteen.canteen',
          url: '/canteen'
        },
        {
          title: 'canteen.categoryItem',
          active: true
        }
      ],
      pageTitle: 'canteen.categoryItem',
      rule: 'TeacherSNDSMD'
    }
  },
  {
    path: 'transaction',
    name: 'canteen-transaction',
    component: TransactionCategoryItem,
    props: true,
    meta: {
      breadcrumb: [

        { title: 'home', url: '/home' },
        {
          title: 'canteen.canteen',
          url: '/canteen'
        },
        {
          title: 'canteen.transaction',
          active: true
        }
      ],
      pageTitle: 'canteen.transaction',
      rule: 'TeacherSNDSMD'
    }
  }
]
