export default {
  academic: 'Academic',
  syllabusList: 'Syllabus List',
  division: 'Division',
  grade: 'Grade',
  name: 'Name',
  created_by: 'Created By',
  academicYear: 'Academic Year',
  syllabusCode: 'Syllabus Code',
  subjects: 'Subjects',
  online: 'Online',
  status: 'Status',
  actions: 'Actions',
  syllabusCreate: 'Create a new syllabus',
  syllabusShow: 'Syllabus Information',
  showSyllabus: 'Syllabus Code',
  divisionsGrades: 'Division & Grades',
  semestersSubjects: 'Semesters & Subjects',
  assignTeachers: 'Assign Teachers',
  divisions: 'Divisions',
  selectDivisions: 'Select Divisions',
  grades: 'Grades',
  selectGrades: 'Select Grades',
  semesters: 'Semesters',
  selectSemesters: 'Select Semesters',
  terms: 'Terms',
  selectTerms: 'Select Terms',
  subjects: 'Subjects',
  selectSubjects: 'Select Subjects',
  teachers: 'Teachers',
  selectTeacher: 'Select a teacher',
  noSubjects: 'Select at least 1 subject',
  noTeachers: 'All the teachers are assigned',
  syllabusEdit: 'Edit Syllabus',
  usedIn: 'Used in'
}
