<template>
  <div>
    <academic-year-form></academic-year-form>
  </div>
</template>
<script>
import academicYearForm from './components/academicYearForm'

export default {
  name:'CreateMasterYears',
  components:{
    academicYearForm

  },
  created () {

  },
  data () {
    return {

    }
  }
}
</script>
