export default {
  grading: 'grading',
  rounds: 'rounds',
  grading_rounds: 'grading rounds',
  create_grading_round_system: 'create grading round system',
  grading_round_system: 'grading round system',
  create_round: 'create round',
  rounds_manager: 'rounds manager',
  the_rounds_manager: 'the rounds manager',
  covered_period: 'Covered period',
  display_date: 'Display date',
  submission_date: 'Submission date',
  submission_date_closing_date: 'Submission/Closing date',
  release_date: 'Release date',
  manually_upload_the_report_cards: 'Manually upload the report cards',
  include_the_assignment_into_the_report_card:
    'include "the assignment" into the report card.',
  include_the_assessment_task_into_the_report_card:
    'Include “the assessment task” into the report card',
  include_the_exams_into_the_report_card:
    'Include “the exams” into the report card',
  include_the_attendance_into_the_report_card:
    'Include “the attendance” into the report card',
  include_the_classwork_into_the_report_card:
    'Include “the classwork” into the report card',
  enable_teachers_to_update_the_report_card_and_the_student_general_review:
    'Enable teachers to update the report card and the  “student general review”',
  enable_homeroom_to_update_the_learner_profile_and_atl:
    'Enable homeroom to update The Learner profile and ATL',
  enable_the_section_coordinator_to_manage_the_round:
    'Enable the section coordinator to manage the round',
  auto_release_of_the_report_card_when_reach__the_release_date_banned_report_cards_are_not_included_in_this_action:
    'Auto release of the report card when reach  the release date.  “ banned report cards are not included in this action”',
  name_of_the_round: 'Name of the round',
  max_40_characters: 'Max. 40 Characters',
  all_changes_will_be_lost_Are_you_sure:
    'All changes will be lost Are you sure ?',
  gradebook: 'Gradebook',
  grading_settings: ' grading system settings',
  grading_system: ' grading system',
  possible_points: 'Possible points',
  remove_from_gradebook: 'remove from gradebook',
  select_the_class_first: 'select the class first',
  select_the_subject_first: 'select the subject first',
  summery: 'summary',
  update_grades: 'update grades',
  grades: 'grades',
  set_grade_to_all_students: 'Set grade to all students',
  average_points: 'Average points',
  student_rank: 'student rank',
  student_total_score: 'student total score',
  total_score: 'total score',
  points: 'points',
  add_assessment_grade: 'add assessment grade',
  update_assessment_grade: 'Update assessment grade',
  score: 'score',
  score_is_required: 'score is required',
  update_student_card: 'update student card',
  please_enter_all_students_score: 'all students score is required',
  rank: 'rank',
  total: 'total',
  manual_grade: 'manual grade',
  hide_from_gradebook: 'hide from gradebook',
  allocation: 'allocation',
  allocations: 'allocations',
  create_allocation: 'create allocation',
  edit_allocation: 'edit allocation',
  If_not_applied_any_assessments_allocations_then_Schoolia_by_default_is_applying_the_grades_on_averages_basis_of_the_final_exam:
    'If not applied any assessments allocations then Schoolia by default is applying the grades on averages basis of the final exam',
  select_term_period: 'select term period',
  the_term_period_is_required: 'the term period is required',
  theres_no_subjects_in_this_period: 'there are no subjects in this period',
  title_and_weight_are_required: 'title and weight are required',
  apply_credit_hours: 'Apply Credit hours',
  include_in_allocation: 'Include in allocation',
  display_in_Report_card: 'Display in Report card',
  apply_and_display_The_general_average_grade:
    'Apply and display The general average grade ',
  you_must_enter_the_subject_weight_at_least_or_inactivate_credit_hours:
    "you must enter  one subject's weight at least or inactivate credit hours",
  select_the_period_and_grade_to_get_the_subjects:
    'select the period and grade to get the subjects',
  the_allocation_must_have_at_least_one_type_and_the_total_weights_must_be_equal_100:
    'the allocation must have at least one type and the total weights must be equal 100',
  must_be_one_of: 'must be one of',
  assessment_models: 'assessment models',
  the_subjects_of_the_selected_period: 'the subjects of the selected period',
  the_subject_weight_must_be_one_of: "the subject's weight must be one of",
  show_allocation: 'show allocation',
  attended: 'attended',
  show_in_gradebook: 'show in gradebook',
  the_control: 'the control',
  create_round_for_report_card: 'create round for report card',
  edit_round: 'edit round',
  title_for_the_report_card: 'Title for the report card',
  the_title_will_be_displayed_on_the_top_of_the_report_card:
    'The title will be displayed on the top of the report card ',
  choose_allocation: 'Choose allocation',
  set_as: 'Set as ',
  normal_report_card: 'Normal report card',
  final_report_card: 'Final report card ',
  link_with_term: 'Link with term  ',
  allow_section_coordinator_to_update_a_general_comment:
    'allow section coordinator to update a general comment',
  allow_teachers_to_update_a_subject_based_comment:
    'allow teachers to update a subject based comment',
  display_the_boundaries_and_descriptors_in_the_report_card:
    'display the boundaries and descriptors in the report card',
  display_the_local_grade_in_the_report_card:
    'display the local grade in the report card',
  display_the_achieved_grade_beside_the_boundary:
    'display the achieved grade beside the boundary',
  include_ATL_in_the_report_card: 'include ATL in the report card',
  include_IDU_in_the_report_card: 'include IDU in the report card',
  include_the_participation_in_the_report_card:
    'include the participation in the report card',
  include_CAS_TOK_EE_in_the_report_card:
    'include CAS TOK EE in the report card',
  new_round_for_report_card: 'new round for report card',
  date_to_activate_the_function_of_Update_report_card:
    'Date to activate the function of Update report card’',
  date_to_deactivate_the_function_of_Update_report_card:
    'Date to deactivate the function of Update report card’',
  if_not_then_section_coordinators_will_manage_it:
    'If not, then section coordinators will manage it.',
  be_sure_that_the_local_grade_is_already_built_in_the_boundaries:
    'Be sure that the local grade is already built in the boundaries’',
  then_teacher_must_update_the_ATL_for_each_student_subject_exclusive_for_IB:
    ' Then teacher must update the ATL for each student/subject’    exclusive for IB',
  then_the_related_teachers_must_update_it__for_each_student_exclusive_for_IB:
    ' Then the related teacher/s must update it  for each student’    exclusive for IB',
  then_the_related_teacher_must_update_it_for_each_student_exclusive_for_IB:
    'Then the related teacher/s must update it  for each student’    exclusive for IB',
  exclusive_for_IB: 'exclusive for IB’',
  no_active_round_is_available_yet: 'No active round is available yet  ..!!',
  student_final_outcome: 'Student final outcome',
  assessments_and_grades: 'Assessments and grades',
  days_to_close_the_review_period: 'days to close the review period',
  achievements: 'Achievements',
  breakdown: 'breakdown',
  local_grade: 'Local grade',
  boundaries: 'Boundaries',
  percentage: 'Percentage',
  click_to_save_after_teacher_review: 'Click to save after teacher review ',
  click_to_Create_the_report_card: 'Click to Create the report card',
  the_created_report_cards_will_automatically_be_saved_inside_the_inventory:
    'the created report cards will automatically be saved inside the inventory.    ',
  print_report_card: 'print report card',
  comments_and_feedback: 'Comments and feedback ',
  special_updates: 'special updates',
  teacher_comment: 'Teacher comment ',
  section_coordinator_comment: 'Section coordinator comment',
  remove_from_grade_book: 'Remove from grade book ',
  add_to_grade_book: 'Add to grade book',
  save_assessments_and_grades_first: 'save assessments and grades first',
  not_available_for_this_time: 'Not available for this time ',
  write_a_comment_Maximum_400_Characters:
    'Write a comment  Maximum 400 Characters ',
  write_a_comment_Maximum_700_Characters:
    'Write a comment  Maximum 700 Characters ',
  save_comment: 'save comment',
  update_ATL: 'Update ATL ',
  update_The_Engagement_PYP_ATL: 'Update The Engagement & PYP ATL  ',
  update_IDU_SAA: 'Update IDU & SAA ',
  update_The_participation: 'Update The participation  ',
  update_the_CAS_TOK_EE: 'Update the CAS & TOK & EE',
  grade: 'Grade',
  descriptor: 'descriptor',
  type_the_teacher_name: 'Type the teacher name',
  type_the_teacher_comment: 'Type the teacher comment',
  achievements_levels: 'Achievements levels  ',
  final_grade: 'Final Grade',
  CAS: 'CAS',
  CAS_progress: 'CAS progress',
  overall_progress: 'Overall  progress',
  planned_outcomes: 'Planned outcomes ',
  completed_outcomes: 'Completed outcomes',
  hours: 'Hours',
  creativity: 'Creativity',
  activity: 'Activity',
  service: 'Service',
  comment: 'Comment',
  CAS_coordinator: 'CAS Coordinator',
  extended_essay: 'Extended Essay',
  supervisor: 'Supervisor',
  toK_paper: 'toK paper',
  teacher: 'teacher',
  title: 'title',
  edits_will_be_applied_only_on_the_new_generated_report_cards:
    'Edits will be applied only on the new generated report cards ',
  the_grading_allocations: 'The grading allocations',
  grading_allocations: 'Grading allocations',
  the_rounds_control: 'The rounds control',
  add_allocation: 'Add Allocation',
  date_to_activate_the_function_of_Update_student_card:
    'Date to activate the function of Update student card’',
  display_the_achieved_grade_next_to_the_boundary:
    'Display the achieved grade next to the boundary',
  no_resources_assessed: ' no resources assessed',
  strands_approach: 'strands approach',
  assessments: 'assessments',
  other_assessments: 'other assessments',
  include_the_report_card_intro: 'Include the report card intro',
  include_the_IB_Learner_profile: 'include the IB Learner profile',
  include_the_approach_to_learning: 'include the approach to learning',
  include_the_advisors_comment: 'include the advisors comment',
  include_subject_Percentage: 'include Subject Percentage',
  include_subject_Weight: 'include Subject Weight',
  this_subject_hasnt_any_strands_yet_kindly_go_to_the_subjects_department_to_handle_it:
    "This subject hasn't any strands yet, kindly go to the subject's department to handle it.",
  final_outcome: 'final outcome',
  include_poi: 'include POI',
  update_poi: 'update POI',
  select_units: 'select units',
  this_grade_has_not_POI_Units: "this grade hasn't POI units",
  Credits: 'Credits',
  notes: 'notes',
  back_to_gradebook: 'back to gradebook',
  click_to_add_it_to_the_inventory: 'Click to add it to the inventory',
  for_all_students: 'for all students',
  for_all_subjects: 'for all subjects'
}
