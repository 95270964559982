import axios from 'axios'
import store from '@/store/store'
const resource = '/student'
export default {
  getdata (student_id) {
    // if (store.getters['auth/authUser'].student) student_id = store.getters['auth/authUser'].student.id

    return axios.get(`${resource}/${student_id || store.getters['auth/authUser'].uid}/studentDetails`)
  },
  getDataByAppId (app_id) {
    return axios.get(`student/${app_id}/ApplicationDetails`)
  },
  getRound (round_id) {
    return axios.get(`/round/${round_id}`)
  },
  getStudents () {
    return axios.get('/studentSearch')
  },
  getAllSubjects () {
    return axios.get('/getAllSubjects')
  },

  // Attendance Component
  getAttendanceReport (user_id) {
    return axios.get(`${resource}/attendance-report/${user_id}`)
  },

  // Assignments Component
  getAllAssignments (student_id) {
    return axios.get(`/teachers/${student_id}/student/aassignments`)
  },
  getSingleAssignment (assignment_id) {
    return axios.get(`/student/find-assignment-student?id=${assignment_id}`)
  },
  getStudentSubjects (id) {
    return axios.get(`/student/${id}/subjects`)
  },
  filterAssignments (id, filters) {
    console.log('payload:', filters)
    return axios.post(`teachers/student/${id}/assignments`, filters)
  },
  // Online classes
  getOnlineClasses (student_id) {
    // if (store.getters['auth/authUser'].student) student_id = store.getters['auth/authUser'].student.id

    return axios.get(`/student/${student_id || store.getters['auth/authUser'].uid}/online-classes`)
  },
  getClassSubjects (class_id, grade_id) {
    return axios.post(`${class_id}/${grade_id}/student/subjects`)
  },
  // Online classes filter
  filterOnlineClasses (filters) {
    return axios.post('teachers/student_onlineClasses', filters)
  },
  //Stduent Tasks
  async getStudentTasks (page) {
    return await axios.get(`/planner/student-assessments?page=${page || 1}`)
  },
  async getOneStudentTasks (page, user_id) {
    return await axios.get(
      `/planner/student-assessments?user_id=${user_id}${
        page ? `'?page=${page}'` : ''
      }`
    )
  },
  async getSingleTask (taskID) {
    return await axios.get(`/planner/find-assessment-student?id=${taskID}`)
  },
  async createStudentTaskAnswer (taskId, data) {
    return await axios.post(`/planner/assessments/${taskId}/tasks`, data)
  },
  async updateStudentTaskAnswer (taskId, data) {
    return await axios.patch(`/planner/assessment-tasks/${taskId}`, data)
  },

  // Online classes filter
  StudentReports (student_id) {
    return axios.get(`report-cards/${student_id}`)
  },
  async validateURL (urlTest) {
    return await axios.get({ url: urlTest, baseURL: '' })
  },
  // teaching_materials
  getTeachingMaterialListStudents (filters) {
    return axios.get('/materials/student', {
      params: {
        ...filters
      }
    })
  },
  count () {
    return axios.get('/materials/student/counter')
  },
  getStudentSubjectsWithoutId () {
    return axios.get('/student/subjects')
  }
}
