<template >
  <separatedPage :componentName="dynamicComponent"></separatedPage>
</template>
<script>
import separatedPage from './Components/SeparatedPage.vue'
import Assignments from '@/modules/assignment/v1/card/assignments.vue'

export default {
  name:'parent-assignments',
  components:{
    separatedPage,
    Assignments
  },
  data: () => ({
    dynamicComponent:Assignments
  })
}
</script>
<style lang="">
</style>
