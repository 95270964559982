<template>
  <div>
    <!-- Cards -->
    <div class="flex justify-between flex-row items-center flex-wrap">
      <div v-for="val in status" :key="val.id">
        <small-card
          :title="val.title"
          :count="val.count"
          :link="val.link"
          :icon="val.icon"
        ></small-card>
      </div>
    </div>
  </div>
</template>
<script>
import SmallCard from '@/components/general/SmallCards.vue'
export default {
  props:{
    status:{
      type:Array,
      required:true
    }
  },
  components:{
    SmallCard
  }
}
</script>
