<template>
  <div id="simple-calendar-app">
    <div class="vx-card no-scroll-content">
      <calendar-view
        ref="calendar"
        :displayPeriodUom="calendarView"
        :showDate="showDate"
        :events="calendarEvents"
        :eventTop="windowWidth <= 400 ? '2rem' : '2.5rem'"
        @click-event="onEventClick"
        @click-date="showDayDetails"
        eventBorderHeight="0px"
        eventContentHeight="1.65rem"
        class="theme-default"
      >
        <div slot="header" class="mb-10">
          <div class="vx-row no-gutter">
            <!-- Month Name -->
            <div
              class="
                vx-col
                items-center
                sm:flex
                vx-col
                sm:w-1/3
                w-full
                sm:my-0
                my-3
                flex
                sm:justify-start
                justify-center
              "
            >
              <!-- Add new event button --
              <vs-button
                icon-pack="feather"
                icon="icon-plus"
                @click="promptAddNewEvent(new Date())"
                >Add</vs-button
              > -->
              <!-- Events Labels -->
              <div class="vx-row sm:flex mt-4">
                <div class="vx-col w-full flex">
                  <!-- Labels -->
                  <div class="flex flex-wrap sm:justify-start justify-center">
                    <div
                      v-for="(label, index) in calendarLabels"
                      :key="'label_' + index"
                      class="flex items-center mr-4 mb-2"
                    >
                      <div
                        class="h-3 w-3 inline-block rounded-full mr-2"
                        :class="'bg-' + label.color"
                      ></div>
                      <span>{{ label.name | text_formatter }}</span>
                    </div>
                    <!-- <div class="flex items-center mr-4 mb-2">
                      <div
                        class="
                          h-3
                          w-3
                          inline-block
                          rounded-full
                          mr-2
                          bg-primary
                        "
                      ></div>
                      <span>None</span>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>

            <!-- Current Month -->
            <div
              class="
                vx-col
                sm:w-1/3
                w-full
                sm:my-0
                my-3
                flex
                sm:justify-end
                justify-center
                order-last
              "
            >
              <div class="flex items-center">
                <feather-icon
                  :icon="$vs.rtl ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
                  @click="updateMonth(-1)"
                  svgClasses="w-5 h-5 m-1"
                  class="cursor-pointer bg-primary text-white rounded-full"
                />
                <div id="calendarDatePicker">
                  <datepicker
                    :language="$vs.rtl ? langHe : langEn"
                    name="datepicker"
                    v-model="showDate"
                    ref="calendarDatePicker"
                  ></datepicker>
                  <span class="mx-3 text-xl font-medium whitespace-no-wrap">
                    {{ showDate | month }}
                  </span>
                </div>
                <feather-icon
                  :icon="$vs.rtl ? 'ChevronLeftIcon' : 'ChevronRightIcon'"
                  @click="updateMonth(1)"
                  svgClasses="w-5 h-5 m-1"
                  class="cursor-pointer bg-primary text-white rounded-full"
                />
              </div>
            </div>
            <!-- calendarViews  -->
            <div class="vx-col sm:w-1/3 w-full flex justify-center">
              <template v-for="(view, index) in calendarViewTypes">
                <vs-button
                  v-if="calendarView === view.val"
                  :key="String(view.val) + 'filled'"
                  type="filled"
                  class="p-3 md:px-8 md:py-3"
                  :class="{
                    'border-l-0 rounded-l-none': index,
                    'rounded-r-none': calendarViewTypes.length !== index + 1,
                  }"
                  @click="calendarView = view.val"
                  >{{ view.label }}
                </vs-button>
                <vs-button
                  v-else
                  :key="String(view.val) + 'border'"
                  type="border"
                  class="p-3 md:px-8 md:py-3"
                  :class="{
                    'border-l-0 rounded-l-none': index,
                    'rounded-r-none': calendarViewTypes.length !== index + 1,
                  }"
                  @click="calendarView = view.val"
                  >{{ view.label }}</vs-button
                >
              </template>
            </div>
          </div>
        </div>
      </calendar-view>
      <!-- Loader -->
      <div
        v-show="showCalendarLoader"
        ref="calendarLoader"
        id="calendarLoader"
        class="vs-con-loading__container absolute_w_full"
      ></div>
    </div>

    <!-- ADD EVENT -->
    <vs-prompt
      class="calendar-event-dialog"
      title="Add Event"
      accept-text="Add Event"
      @accept="addEvent"
      :is-valid="validForm"
      :active.sync="activePromptAddEvent"
    >
      <div class="calendar__label-container flex">
        <vs-chip
          v-if="labelLocal != 'none'"
          class="text-white"
          :class="'bg-' + labelColor(labelLocal)"
          >{{ labelLocal }}</vs-chip
        >

        <vs-dropdown
          vs-custom-content
          vs-trigger-click
          class="ml-auto my-2 cursor-pointer"
        >
          <feather-icon
            icon="TagIcon"
            svgClasses="h-5 w-5"
            class="cursor-pointer"
            @click.prevent
          ></feather-icon>

          <vs-dropdown-menu style="z-index: 200001">
            <vs-dropdown-item
              v-for="(label, index) in calendarLabels"
              :key="'cLabels_' + index"
              @click="labelLocal = label.value"
            >
              <div
                class="h-3 w-3 inline-block rounded-full mr-2"
                :class="'bg-' + label.color"
              ></div>
              <span>{{ label.text }}</span>
            </vs-dropdown-item>

            <!-- <vs-dropdown-item @click="labelLocal = 'none'">
              <div
                class="h-3 w-3 mr-1 inline-block rounded-full mr-2 bg-primary"
              ></div>
              <span>None</span>
            </vs-dropdown-item> -->
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <vs-input
        name="event-name"
        v-validate="'required'"
        class="w-full"
        label-placeholder="Event Title"
        v-model="title"
      ></vs-input>
      <div class="my-4">
        <small class="date-label">Start Date</small>
        <datepicker
          :language="$vs.rtl ? langHe : langEn"
          name="start-date"
          v-model="startDate"
          :disabled="disabledFrom"
        ></datepicker>
      </div>
      <div class="my-4">
        <small class="date-label">End Date</small>
        <datepicker
          :language="$vs.rtl ? langHe : langEn"
          :disabledDates="disabledDatesTo"
          name="end-date"
          v-model="endDate"
        ></datepicker>
      </div>
      <vs-input
        name="event-url"
        v-validate="'url'"
        class="w-full mt-6"
        label-placeholder="Event URL"
        v-model="url"
        :color="!errors.has('event-url') ? 'success' : 'danger'"
      ></vs-input>
    </vs-prompt>

    <!-- EDIT EVENT -->
    <vs-prompt
      class="calendar-event-dialog"
      title="Edit Event"
      accept-text="Submit"
      cancel-text="Remove"
      button-cancel="border"
      @cancel="removeEvent"
      @accept="editEvent"
      :is-valid="validForm"
      :active.sync="activePromptEditEvent"
    >
      <div class="calendar__label-container flex">
        <vs-chip
          v-if="labelLocal != 'none'"
          class="text-white"
          :class="'bg-' + labelColor(labelLocal)"
          >{{ labelLocal }}</vs-chip
        >

        <vs-dropdown vs-custom-content class="ml-auto my-2 cursor-pointer">
          <feather-icon
            icon="TagIcon"
            svgClasses="h-5 w-5"
            @click.prevent
          ></feather-icon>

          <vs-dropdown-menu style="z-index: 200001">
            <vs-dropdown-item
              v-for="(label, index) in calendarLabels"
              :key="index"
              @click="labelLocal = label.value"
            >
              <div
                class="h-3 w-3 inline-block rounded-full mr-2"
                :class="'bg-' + label.color"
              ></div>
              <span>{{ label.text }}</span>
            </vs-dropdown-item>

            <!-- <vs-dropdown-item @click="labelLocal = 'none'">
              <div
                class="h-3 w-3 mr-1 inline-block rounded-full mr-2 bg-primary"
              ></div>
              <span>None</span>
            </vs-dropdown-item> -->
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <vs-input
        name="event-name"
        v-validate="'required'"
        class="w-full"
        label-placeholder="Event Title"
        v-model="title"
      ></vs-input>
      <div class="my-4">
        <small class="date-label">Start Date</small>
        <datepicker
          :language="$vs.rtl ? langHe : langEn"
          :disabledDates="disabledDatesFrom"
          name="start-date"
          v-model="startDate"
        ></datepicker>
      </div>
      <div class="my-4">
        <small class="date-label">End Date</small>
        <datepicker
          :language="$vs.rtl ? langHe : langEn"
          :disabledDates="disabledDatesTo"
          name="end-date"
          v-model="endDate"
        ></datepicker>
      </div>
      <vs-input
        name="event-url"
        v-validate="'url'"
        class="w-full mt-6"
        label-placeholder="Event URL"
        v-model="url"
        :color="!errors.has('event-url') ? 'success' : 'danger'"
      ></vs-input>
    </vs-prompt>

    <!-- SHOW EVENT -->
    <!-- :background-color-popup="`rgba(var(${showEventInfo.colorHex}),2)`" -->
    <vs-popup
      class="holamundo"
      :class="`headerColor-${showEventInfo.color}`"
      :title="showEventInfo.name"
      :active.sync="showEvent"
    >
      <div class="flex flex-wrap justify-between items-center">
        <template v-for="(value, key, i) in showEventInfo">
          <p
            :key="i"
            class="mb-3 w-full"
            :class="{
              'md:w-1/2 ': !FullWidthRow(showEventInfo, key),
            }"
            v-if="value && !isHiddenKey(key)"
          >
            <template>
              <strong
                class="inline-block"
                :class="!FullWidthRow(showEventInfo, key) ? ' w-1/4' : ''"
                >{{ key | text_formatter }}
              </strong>
              :
              <span
                class="mx-2"
                :class="key === 'type' ? `status2--${showEventInfo.color}` : ''"
              >
                <!-- Links -->

                <a
                  v-if="key === 'link'"
                  :href="value"
                  :class="`status-text--${showEventInfo.color}`"
                  @click="closeAllPopUps()"
                >
                  {{ $t("basic.here") }}
                </a>
                <template v-else-if="['due_date', 'due_time'].includes(key)">
                  {{ value }}
                </template>

                <!-- Other keys  -->
                <template v-else>{{ value | text_formatter }}</template>
              </span>
            </template>
          </p>
        </template>
      </div>

      <!-- <vs-chip :class="`ml-auto status2--${showEventInfo.color}`">{{
        showEventInfo.type
      }}</vs-chip> -->
    </vs-popup>

    <!-- SHOW Day EVENT -->
    <vs-popup
      class="holamundo"
      :title="showDayInfo.dateTitle"
      :active.sync="showDayEvents"
    >
      <div class="flex flex-wrap justify-between items-center">
        <template v-if="showDayInfo.events.length">
          <!-- Labels && events counter -->

          <p class="text-sm w-full flex flex-wrap justify-between mb-6">
            <span>
              <vs-chip
                v-for="(label, i) in calendarLabels"
                :key="i"
                :class="`status--${label.color} mr-2`"
              >
                {{ label.name | text_formatter }}
                ({{
                  showDayInfo.events.filter((e) => e.type === label.name)
                    .length
                }})
              </vs-chip>
            </span>

            <span>
              {{ showDayInfo.events.length }}
              {{ $t("calendar.events") | text_formatter }}
            </span>
          </p>
          <!-- List of Events -->
          <!-- <vx-timeline :data="showDayInfo.events" /> -->

          <ul class="vx-timeline w-full">
            <li
              v-for="(item, i) in showDayInfo.events"
              :key="i"
              class="w-full cursor-pointer"
              @click="showEventDetails(item)"
            >
              <div class="timeline-icon" :class="`bg-${item.color}`">
                <feather-icon
                  :icon="item.icon || 'ActivityIcon'"
                  svgClasses="text-white stroke-current w-5 h-5"
                />
              </div>
              <div class="timeline-info">
                <p class="font-semibold" v-if="item.title">
                  <feather-icon
                    v-if="item.isContinuedEvent && !item.isStarContinuedEvent"
                    icon="ChevronsLeftIcon"
                    svgClasses=" w-4 h-5 opacity-75"
                  />

                  <span class="opacity-75 text-sm">
                    {{ item.time | text_formatter }} -
                  </span>
                  <span>{{ item.name || item.title | text_formatter }}</span>
                  <span class="mx-3">
                    <feather-icon
                      v-if="item.isContinuedEvent && !item.isEndContinuedEvent"
                      icon="ChevronsRightIcon"
                      svgClasses=" w-4 h-5 opacity-75"
                  /></span>
                </p>
                <p class="activity-desc text-sm">
                  <template v-if="item.isContinuedEvent">
                    {{
                      `${item.start_date_formate_dd_mm_yy}
                  ${item.start_time_12Formate} &xrarr;  ${item.end_date_formate_dd_mm_yy} ${item.end_time_12Formate} `
                    }}</template
                  >
                  <template v-else-if="item.end_time !== item.start_time">{{
                    `${item.start_time_12Formate} &xrarr; ${item.end_time_12Formate}`
                  }}</template>
                  <!-- <vx-tooltip
                    :text="`${$t('basic.startAt')} ${
                      item.start_date_formate_dd_mm_yy
                    }`"
                  >
                    <feather-icon
                      v-if="item.isContinuedEvent && !item.isStarContinuedEvent"
                      icon="ChevronsLeftIcon"
                      svgClasses=" w-4 h-5 opacity-75"
                    />
                  </vx-tooltip>

                  <vx-tooltip
                    :text="`${$t('basic.endAt')} ${
                      item.end_date_formate_dd_mm_yy
                    }`"
                  >
                    <feather-icon
                      v-if="item.isContinuedEvent && !item.isEndContinuedEvent"
                      icon="ChevronsRightIcon"
                      svgClasses=" w-4 h-5 opacity-75"
                    /> </vx-tooltip -->
                </p>
              </div>

              <small class="text-grey activity-e-time" v-if="item.time">{{
                (item.type || item.time) | text_formatter
              }}</small>
            </li>
          </ul>
          <!-- <p
            v-for="(event, i) in showDayInfo.events"
            :key="i"
            class="mb-2 w-full cursor-pointer text-left event-list-item"
            @click="showEventDetails(event)"
          >
            <span :class="`status2--${event.color} `">{{
              event.start_time_12Formate | text_formatter
            }}</span>
            <span :class="`status-text--${event.color} `">{{
              event.type | text_formatter
            }}</span>
            <span> {{ event.name | text_formatter }}</span>
          </p> -->
        </template>
        <template v-else>
          <p>
            {{ $t("calendar.there_is_no_events_at_this_day") | text_formatter }}
          </p>
        </template>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { CalendarView, CalendarViewHeader } from 'vue-simple-calendar'
require('vue-simple-calendar/static/css/default.css')

import Datepicker from 'vuejs-datepicker'
import { en, he } from 'vuejs-datepicker/src/locale'

import VxTimeline from '@/components/timeline/VxTimeline.vue'

// APIs
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const EventsRepository = RepositoryFactory.get('CalendarModule', 'events')

// Mixins
import {authUserMixin} from '@/mixins.js'


export default {
  name: 'calendar',
  components: {
    CalendarView,
    CalendarViewHeader,
    Datepicker,
    VxTimeline
  },
  mixins:[authUserMixin],
  data () {
    return {
      showDate: new Date(),
      disabledFrom: false,
      id: 0,
      title: '',
      startDate: '',
      endDate: '',
      labelLocal: 'none',

      langHe: he,
      langEn: en,

      url: '',
      calendarView: 'month',

      activePromptAddEvent: false,
      activePromptEditEvent: false,

      calendarViewTypes: [
        {
          label: 'Month',
          val: 'month'
        },
        {
          label: 'Week',
          val: 'week'
        },
        {
          label: 'Year',
          val: 'year'
        }
      ],
      ///////////////////////
      // Events
      //////////////////////
      calendarLabels: [],
      allEvents: [],
      custmizedEvents:[],
      eventsByDays:[],
      showCalendarLoader: false,
      /////////////////////////
      //  Show Event
      /////////////////////////
      showEvent: false,
      showEventInfo: {
        name: '',
        color: '',
        due_date: '',
        due_time: '',
        by: '',
        subject: '',
        url: '',
        type: '',
        colorHex: ''
      },
      //////////////////////
      // Show daysEvent
      ////////////////////////
      showDayEvents: false,
      showDayInfo: { date: '', events: [], dateTitle: '' }
    }
  },
  computed: {
    validForm () {
      return (
        this.title !== '' &&
        this.startDate !== '' &&
        this.endDate !== '' &&
        Date.parse(this.endDate) - Date.parse(this.startDate) >= 0 &&
        !this.errors.has('event-url')
      )
    },
    disabledDatesTo () {
      return { to: new Date(this.startDate) }
    },
    disabledDatesFrom () {
      return { from: new Date(this.endDate) }
    },

    labelColor () {
      return label => {
        if (label === 'business') return 'success'
        else if (label === 'work') return 'warning'
        else if (label === 'personal') return 'danger'
        else if (label === 'none') return 'primary'
      }
    },
    windowWidth () {
      return this.$store.state.windowWidth
    },
    calendarEvents () {
      return ['week', 'day'].includes(this.calendarView) ? this.allEvents : this.custmizedEvents
    }
  },
  watch: {
    showEvent (v) {
      if (!v) this.resetshowEventInfo()
    },
    showDay (v) {
      if (!v) this.resetshowDayInfo()
    }
  },
  methods: {
    /////////////////////////
    // Loading
    ////////////////////////
    async startLoding () {
      await (this.showCalendarLoader = true)
      this.VSLoading(this.$refs.calendarLoader)
    },
    async endLoading () {
      await this.stopVSLoading(this.$refs.calendarLoader)
      this.showCalendarLoader = false
    },

    /////////////////////////
    // UI
    ////////////////////////
    FullWidthRow (obj, key) {
      return key === 'name' || (obj.isContinuedEvent && key === 'due_date')
    },
    isHiddenKey (key) {
      return ['color', 'colorHex', 'isContinuedEvent'].includes(key)
    },
    /////////////////////////
    // APIs
    ////////////////////////
    getEvents () {
      this.startLoding()
      EventsRepository.getEvents()
        .then(res => {
          const events = res.data.data,
            labelsColors = ['danger', 'warning', 'dark', 'RGB', 'success'],
            eventsStartDates = [... new Set(events.map(e => this.formatDate2(e.start_date)))],
            continuedEventDatesRanges = []


          // Map Labels
          this.calendarLabels =
           [... new Set(events.map(e => e.type))]
             .sort()
             .map((e, i) => {
               const color =
                e === 'exam' ? 'danger' : e === 'round' ? 'dark' : labelsColors.filter(e => !['dark', 'danger'].includes(e))[i]

               return {
                 name: e,
                 color,
                 colorHex: `--vs-${color}`
               }
             })

          // Sort Events
          // events.sort(
          //   (a, b) => new Date(a.start_date).getTime() -
          //     new Date(b.start_date).getTime()
          // )

          // Cutomize events


          events.forEach((e, i) => {

            //-------------- Fixed duplicated Ids ------
            e.item_id = e.id
            e.id =  i + 1

            //--------------  continued Event ----------
            e.isContinuedEvent =  Boolean(e.end_date && e.end_date.split(' ')[0] !== e.start_date.split(' ')[0])
            e.continuedEventDates = e.isContinuedEvent ? this.dateBetween2Dates(e.start_date, e.end_date, true) : []

            // gathering all continuous events date ranges
            if (e.continuedEventDates.length > 1)  continuedEventDatesRanges.push(...e.continuedEventDates.slice(1))


            //--------------  Dates ----------
            e.startDate = e.start_date
            e.start_date_formate_dd_mm_yy = this.formatDate2(e.start_date)
            e.end_date = e.end_date || e.start_date
            e.endDate = e.end_date
            e.end_date_formate_dd_mm_yy = this.formatDate2(e.end_date)

            //--------------- times ----------
            e.start_time = new Date(e.start_date).toTimeString()
            e.end_time = new Date(e.end_date).toTimeString()
            e.start_time_12Formate = this.changeTime12Formate(e.start_time)
            e.end_time_12Formate = this.changeTime12Formate(e.end_time)
            e.time = e.start_time_12Formate

            //--------------  Due Dates && Times ----------

            e.due_date = `${e.start_date_formate_dd_mm_yy} ${e.isContinuedEvent ? ` ${e.start_time_12Formate} - ${ e.end_date_formate_dd_mm_yy} ${e.end_time_12Formate}` : '' }`
            e.due_time = !e.isContinuedEvent ? `${e.start_time_12Formate } ${ e.start_time_12Formate !== e.end_time_12Formate  ? `- ${ e.end_time_12Formate}` : ''}` : ''

            //--------------  titles ----------
            e.name = e.title
            e.name_with_time = `${e.start_time_12Formate} ${e.name}`
            e.title = e.name_with_time

            //--------------  colors ----------
            const label = this.calendarLabels.find(c => c.name === e.type)

            e.color = label.color
            e.colorHex = label.colorHex
            e.classes = [`status2--${label.color}`, 'text-left ']

            //------------------  Type --------------
            e.type = e.type

            //--------------  More Events  ----------
            e.dayMoreEvents = e.dayMoreEvents || false

            //------------------ Events Links --------------
            const resource = `${window.location.origin}/#`
            //--  Exam
            if (e.type === 'exam') {
              if (this.is_student) e.link = `${resource}/student/exam/${e.item_id}`
              if (this.is_teacher) e.link = `${resource}/teacher/exam/${e.item_id}`
            }
            //--  Assignment
            else if (e.type === 'assignment') {

              if (this.is_student) e.link = `${resource}/student/assignment/${e.item_id}`
            } else if (e.type === 'assessment') {

              if (this.is_student) e.link = `${resource}/student/task/${e.item_id}`
            }

            //-- OnlineCLasses
            else if (e.type === 'online_class') {
              if (this.is_student) e.link =  `${resource}/bbb/join/${e.item_id}`
              if (this.is_teacher) e.link =  `${resource}/bbb/create/${e.item_id}`
            }


          })
          this.allEvents = events


          /***
            ** Map Events By days :
            * 1- Get all events dates
            * 2- Get all date's events
            *  3- Map new object {
            *      date, // date in dd_mm_yyy formate
                  events, // all date's events without (+1 more) customization
                  eventsCustomized // first event with (+1 more) customization
                  }

            ////////////////////////////////////
            ** (+1 more ) senarios  :
            ////////////////////////////////////
            ** Show only if the date has more than 2 events

            **Main Senario :
            * show 1 event ( mostly first one ) && hide the rest && show
            * +( hidden events length ) more

            ** Have Continued Event senarios:
            ** Tips for this case  :
                - Continued Events always will show even more than 2
                - moreObj in this case always take the normal events length

            * 1- Get all continued events which started on the date
            * 2- filter && get the normal events
            * 3- if it has normal events
            *   3.1  if has continuedEventsStart then eventsCustomized = continuedEventsStart && moreObj
            *   3.2 else set eventsCustomized = [moreObj(events.length)]
            * 4- if it hasn't normal events but has continuedEventsStart
            *   4.1 set eventsCustomized = continuedEventsStart
            * -----------------------------------------
           ***/
          eventsStartDates.forEach(date => {
            let  events = this.getDayEvents(date)

            const
              inContinuedEventDatesRanges = continuedEventDatesRanges.includes(date),
              moreObj = (remainingEventsLength) => {
                return  {
                  title:`+${remainingEventsLength} ${this.$t('basic.more')}`,
                  dayMoreEvents:true,
                  classes:['moreEvents'],
                  startDate :new Date(`${date.split('-').reverse().join('-')} 23:59:00`)}

              }

            let eventsCustomized = []

            if (events.length > 2) {
              if (inContinuedEventDatesRanges) {
                const
                  continuedEventsStart = events.filter(el => el.isContinuedEvent && el.start_date_formate_dd_mm_yy === date)

                events =  events.filter(el => !el.isContinuedEvent)

                if (events.length > 0) {
                  if (continuedEventsStart.length) eventsCustomized = [...continuedEventsStart, moreObj(events.length)]
                  else eventsCustomized = [moreObj(events.length)]
                } else if (!events.length && continuedEventsStart.length)  eventsCustomized = continuedEventsStart

              } else eventsCustomized = [events[0], moreObj(events.length - 1)]

            } else eventsCustomized = events

            this.eventsByDays.push({
              date,
              events,
              eventsCustomized
            })
          })

          this.eventsByDays.forEach(day => { this.custmizedEvents.push(...day.eventsCustomized) })

          // Remove Duplicated Events
          this.custmizedEvents = this.custmizedEvents.filter((event, index, arr) => arr.findIndex(t => (t.id === event.id && t.title === event.title &&  t.startDate === event.startDate && t.type === event.type)) === index)

        })
        .finally(this.endLoading)
    },
    //////////////////////////////////
    // Show && Add && Remove Events
    /////////////////////////////////

    addEvent () {
      const obj = {
        title: this.title,
        startDate: this.startDate,
        endDate: this.endDate,
        label: this.labelLocal,
        url: this.url
      }
      obj.classes = `event-${this.labelColor(this.labelLocal)}`
    },
    updateMonth (val) {
      this.showDate = this.$refs.calendar.getIncrementedPeriod(val)
    },
    clearFields () {
      this.title = this.endDate = this.url = ''
      this.id = 0
      this.labelLocal = 'none'
    },
    promptAddNewEvent (date) {
      this.disabledFrom = false
      this.addNewEventDialog(date)
    },
    addNewEventDialog (date) {
      this.clearFields()
      this.startDate = date
      this.endDate = date
      this.activePromptAddEvent = true
    },
    openAddNewEvent (date) {
      this.disabledFrom = true
      this.addNewEventDialog(date)
    },
    openEditEvent (event) {
      const e = this.events.find(e => e.id === event.id)
      this.id = e.id
      this.title = e.title
      this.startDate = e.startDate
      this.endDate = e.endDate
      this.url = e.url
      this.labelLocal = e.label
      this.activePromptEditEvent = true
    },
    editEvent () {
      const obj = {
        id: this.id,
        title: this.title,
        startDate: this.startDate,
        endDate: this.endDate,
        label: this.labelLocal,
        url: this.url
      }
      obj.classes = `event-${this.labelColor(this.labelLocal)}`
      // this.$store.dispatch('calendar/editEvent', obj)
    },
    removeEvent () {},
    eventDragged (event, date) {},
    ///////////////////////////
    // Info Popups
    //////////////////////////
    onEventClick (event) {
      event = event.originalEvent || event

      if (event.dayMoreEvents || event.classes.includes('moreEvents')) this.showDayDetails(event.startDate)
      else this.showEventDetails(event)
    },
    closeAllPopUps () {
      this.showEvent =  this.showDayEvents = false
    },
    ///////////////////////
    // Show Event popup
    ////////////////////////
    showEventDetails (event) {

      event = event.originalEvent || event
      const e = this.allEvents.find(e => e.id === event.id  && e.type === event.type && e.title === event.title)

      this.showEventInfo.name = `${e.title}`
      this.showEvent = true
      this.showEventInfo.color = e.color
      this.showEventInfo.colorHex = e.colorHex
      this.showEventInfo.due_date = e.due_date
      this.showEventInfo.due_time = e.due_time
      this.showEventInfo.by = e.by || ''
      this.showEventInfo.subject = e.subject || ''
      this.showEventInfo.link = e.link || ''
      this.showEventInfo.needHandleBeforGo = e.needHandleBeforGo || false
      this.showEventInfo.type = e.type || ''
      this.showEventInfo.isContinuedEvent = e.isContinuedEvent
    },
    resetObj (obj) {
      for (const info in obj) obj[info] = ''
    },
    resetshowEventInfo () {
      this.resetObj(this.showEventInfo)
    },
    //////////////////////////
    // Get Events of Date
    /////////////////////////
    getDayEvents (date) {

      const
        isDateFormated = date.toString().includes('-'),
        currentDate =  isDateFormated ? date : this.formatDate2(date),
        events = this.allEvents.filter(
          (e) => {

            if (e.isContinuedEvent) return e.continuedEventDates.includes(currentDate)
            else return (e.start_date_formate_dd_mm_yy === currentDate)
          }
        )

      return events
    },
    /////////////////////
    // Show Date popup
    /////////////////////
    showDayDetails (date) {
      this.showDayEvents = true
      this.showDayInfo.events = this.getDayEvents(date)
      this.showDayInfo.events.forEach(e => {
        e.isStarContinuedEvent = e.isContinuedEvent && this.formatDate2(date) === e.start_date_formate_dd_mm_yy
        e.isEndContinuedEvent = e.isContinuedEvent && this.formatDate2(date) === e.end_date_formate_dd_mm_yy
      })
      this.showDayInfo.date = date
      this.showDayInfo.dateTitle = new Date(date).toDateString()
    },
    resetshowDayInfo () {
      this.resetObj(this.showDayInfo)
    }

  },
  created () {
    this.getEvents()
  },
  beforeDestroy () {
    this.closeAllPopUps()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/simple-calendar.scss";
@import "@/assets/scss/vuexy/components/vxTimeline.scss";

[dir="ltr"] #simple-calendar-app .theme-default {
  .cv-weeks .cv-week {
    .cv-event {
      font-size: 0.8rem;
    }
  }
  .cv-day {
    &.today .cv-day-number {
      margin: 0;
      border-radius: 0;
      text-align: left;
    }
    .cv-day-number {
      padding: 0.5rem;
      height: 28px;
      background: #f7f7f7;
      width: 100%;
      box-shadow: 0 0 13px -2px #ccc;
    }
  }
}
#calendarDatePicker {
  position: relative;
  * {
    cursor: pointer;
  }
  .vdp-datepicker {
    position: absolute;
    inset: 0;

    input {
      opacity: 0;
    }
    &__calendar {
      // left: inherit;
      right: 0;
    }
  }
}
.event-list-item {
  transition: all 0.4s ease;

  padding: 5px 0;

  &:hover {
    background-color: rgba(#ccc, 0.2);
    .time {
    }
  }
}
</style>
