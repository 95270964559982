import vSelect from 'vue-select'
import Service from '../../services/poi'
import ModuleMixins from '../../mixins'
import {mapGetters} from 'vuex'

export default {
  name: 'filterBar',
  mixins: [ModuleMixins],
  components: {
    'v-select': vSelect
  },
  data () {
    return {
      restService: Service,
      cloneModalName: 'cloneAcademicYear',
      yearGroup: [],
      academicYears: [],
      currentAcademicYear: this.$store.getters.getCurrentAcademicYear,
      selectedAcademicYear: null,
      selectedYearGroup: null,
      selectedData: {
        academicYear: null,
        yearGroup: null
      }
    }
  },
  mounted () {
    this.defaultData()
    this.getDropDownData()
  },
  methods: {
    defaultData () {
      this.selectedAcademicYear = this.cloneItem(this.storeSelectedAcademicYear.id)
      this.selectedYearGroup = this.cloneItem(this.$store.getters.getSelectedYearGroup.id)
    },
    canEdit (academicYearId) {
      const isEditable = this.futureAcademicYears.find(item => item.id === academicYearId)
      if (isEditable) {
        return true
      } else {
        return false
      }
    },
    setSelectedData () {
      this.selectedData.yearGroup = this.cloneItem(this.yearGroup.find(item => item.id === this.selectedYearGroup))
      this.selectedData.academicYear = this.cloneItem(this.academicYears.find(item => item.id === this.selectedAcademicYear))

      //** Set Selected Academic Year  **//
      this.$store.dispatch('setSelectedAcademicYear', this.selectedData.academicYear)

      //** Set Selected Year Group   **//
      this.$store.dispatch('setSelectedYearGroup', this.selectedData.yearGroup)

    },
    setDataToStore (data) {
      //** set current selected year & group **//
      this.setSelectedData()
      // ** Replace Current POI with Selected Academic Year POI ** //
      this.$store.dispatch('replacePoi', this.cloneItem(data))
    },
    async getAcademicYearData () {
      this.$vs.loading()
      await this.restService.getAcademicYearData({academic_year_id: this.selectedAcademicYear})
        .then(res => {
          //** check if academic years group can edit based on current or future dates **//
          const data = res.data.map(item => {
            item['isEditable'] = this.canEdit(item.academic_year_id)
            return item
          })
          this.setDataToStore(data)
        })
        .catch(err => console.error(err))
      this.$vs.loading.close()
    },
    openCloneModal () {
      //** Call store Action to open Modal (Create Group) **//
      this.$store.dispatch('manageModal', {
        name: this.cloneModalName,
        show: true,
        data: null
      })
    },
    getDropDownData () {
      ///*** Years Group DropDown **//
      // Clone Year Group Options to us it in dropdown **//
      this.yearGroup = this.cloneItem(this.$store.getters.getYearsGroupOptions)

      // add Option to select all **//
      this.yearGroup.unshift({id: -1, name: 'All'})

      ///*** Academic Years DropDown **//
      // Clone Academic Years Options to us it in dropdown **//
      this.academicYears = this.cloneItem(this.$store.getters.getAcademicYearsOptions)
    },
    generatePdf () {
      this.$emit('create-pdf')
    }
  },
  computed: {
    ...mapGetters({futureAcademicYears: 'getFutureAcademicYearsOptions'}),
    ...mapGetters({storeSelectedAcademicYear: 'getSelectedAcademicYear'})
  }
}
