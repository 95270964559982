<template>
  <div>
      <SectionCoodinatorForm :teacher_id="teacher_id"></SectionCoodinatorForm>
  </div>
</template>
<script>
import SectionCoodinatorForm from '../../../components/SectionCoodinatorForm.vue'

export default {
  name:'CreateSectionCoodinator',
  components:{
    SectionCoodinatorForm
  },
  created () {

  },
  data () {
    return {
      teacher_id: this.$route.params.id
    }
  }
}
</script>
