import TopNavbar from '@/layouts/components/navbar/TheNavbarVertical.vue'
import { mapGetters } from 'vuex'
import Service from '../../services/unit-planner'
import UnitFeatures from '../unit-features/unit-features.vue'
import UnitFeaturesMyp from '../unit-features-myp/unit-featuresMyp.vue'
import UnitFeaturesOverview from '../unit-features-overview/unit-features-overview.vue'
import TeachersCollaborators from '../teachers-collaborators/teachers-collaborators.vue'
import TeachersDiscussions from '../discussions/discussions.vue'
import PlannerFilters from '../planner-filters/planner-filters.vue'
import TeachersAssessment from '../teachers-assessment/teachers-assessment.vue'
import TeachersAssignment from '../teachers-assignment/teacher-assignment.vue'
import TeacherStudentDirectory from '@/modules/Teachers/pages/dashboard/students-directory/all-students/List.vue'
import ReviewTask from '../../modals/review-tasks/review-tasks.vue'
import eventBus from '../../eventBus'

export default {
  components: {
    TopNavbar,
    UnitFeatures,
    UnitFeaturesMyp,
    UnitFeaturesOverview,
    TeachersCollaborators,
    TeachersDiscussions,
    PlannerFilters,
    TeachersAssessment,
    ReviewTask,
    TeachersAssignment,
    TeacherStudentDirectory
  },
  name: 'plannerResources',
  data () {
    return {
      restService: Service,
      unitPlannerResources: null,
      menusData: [],
      newMenuObj: null,
      currentMenuData: null,
      currentSection: null,
      sectionInfo: {},
      statusCard: [],
      teacherUnits: [],
      teacher_unit_id: null,
      assessment:{
        action: 'single-task',
        icon: 'icon-cloud-lightning',
        // id: 34,
        parent_key: 'teacher-tasks',
        title: 'Assessment Task'
      },
      enableGetSingleTask: false
    }
  },
  watch:{
    '$route.query': {
      handler (newTask, oldtask) {
        if (oldtask && newTask.task !== oldtask.task) {
          this.$router.go()
        }
      },
      deep: true,
      immediate: true
    }
  },
  async created () {

    if (this.currentUnit && this.currentUnit.id) {
      this.teacher_unit_id = this.currentUnit.id
    }
    if (parseInt(this.$route.query.task) > 0 && !this.enableGetSingleTask) {
      if (parseInt(this.$route.query.unit_id) > 0 && (!this.currentUnit || this.currentUnit.id !== parseInt(this.$route.query.unit_id))) {
        const unit_id = this.$route.query.unit_id
        //** Show Loader **//
        this.$vs.loading()
        //** Get Menus Of OutLines **//
        await this.restService
          .getSingleUint(unit_id)
          .then(res => {
            const obj = this.mappingUnitData(res.data)
            this.$store.dispatch('createUnit', obj)
            this.$router.go()
          })
          .catch(err => console.error(err))
          //** Hide Loader **//
        this.$vs.loading.close()

      }
      this.routeToSingleTask()
    }
    this.loadData()
  },
  mounted () {
    eventBus.$on('openSingleTask', (data) => {
      this.enableGetSingleTask = true
      this.goToMenuAction(data)
    })
    eventBus.$on('backToAllTasksList', (data) => {
      this.goToMenuAction(data)
    })
  },
  methods: {
    routeToSingleTask () {
      this.enableGetSingleTask = true
      this.goToMenuAction(this.assessment)
    },
    loadData () {
      if (this.currentUnit) {
        this.getMenus()
        this.setSectionInfo()
        this.setStatusCardsInfo()
      }
    },
    async getMenus () {
      const type = this.$route.query.type
      //** Show Loader **//
      this.$vs.loading()
      //** Get Menus Of OutLines **//
      await this.restService
        .getUintMenus(type)
        .then(res => {
          this.menusData.push(...res.data)
        })
        .catch(err => console.error(err))
      //** Hide Loader **//
      this.$vs.loading.close()
    },
    goToMenuAction (data) {
      if (this.enableGetSingleTask) {
        this.enableGetSingleTask = false
      } else {
        const {type} = this.$route.query
        this.$router.push({ query: {type}})
      }
      this.currentSection = data.parent_key
      this.currentMenuData = data.action
      switch (data.parent_key) {
      case 'unit-outlines':
        this.getOutLineMenuFeatures(data)
        break
      case 'teaching-tools':
        this.getTeachersMenuData(data)
        break
      case 'teacher-tasks':
        //this.getTeachersMenuData(data);
        break
      }
    },
    getOutLineMenuFeatures (data) {

      //** Show Loader **//
      this.$vs.loading()
      //** Get Menus Features **//
      this.restService
        .getMenuFeaturesData(this.teacher_unit_id, data.action)
        .then(res => {
          const Obj = {
            menuKey: data.action,
            menuId: data.id,
            teacherUnitId: this.teacher_unit_id,
            data: this.cloneItem(res.data)
          }
          this.currentMenuData = Object.assign({}, this.currentMenuData, Obj)
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          //** Hide Loader **//
          this.$vs.loading.close()
          this.currentMenuData = null
          this.errMsg(err.data.message, this.$t('form.loading_data_error'))

        })
    },
    getTeachersMenuData (data) {
      //** Show Loader **//
      this.$vs.loading()
      this.restService
        .getTeacherMenuData(this.teacher_unit_id, data.action)
        .then(res => {
          // debugger;
          const Obj = {
            menuKey: data.action,
            menuId: data.id,
            teacherUnitId: this.teacher_unit_id,
            data: this.cloneItem(res)
          }
          this.currentMenuData = Object.assign({}, this.currentMenuData, Obj)
          console.log('this.currentMenuData', this.currentMenuData)
          //** Hide Loader **//
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          //** Hide Loader **//
          this.$vs.loading.close()

          this.currentMenuData = null
          this.errMsg(err.data.message, this.$t('form.loading_data_error'))

        })
      //** Hide Loader **//
      this.$vs.loading.close()
    },
    setSectionInfo () {
      this.sectionInfo = {
        header: this.currentUnit.name,
        items: [
          { title: 'Grade', value: this.currentUnit.gradeValue },
          {
            title: 'Start Date',
            value: this.formatDate(this.currentUnit.startDate)
          },
          {
            title: 'End Date',
            value: this.formatDate(this.currentUnit.endDate)
          },
          { title: 'Subject', value: this.currentUnit.subjectsValue },
          { title: 'Duration', value: this.currentUnit.hours },
          { title: 'Weeks', value: this.currentUnit.weeks }
        ]
      }
    },
    setStatusCardsInfo () {
      this.statusCard = [
        {
          name: 'All units',
          value: 'all-units',
          color: 'primary',
          icon: 'StarIcon',
          count: 15
        },
        {
          name: 'Draft Units',
          value: 'draft-units',
          color: 'RGB',
          icon: 'ClipboardIcon',
          count: 10
        },
        {
          name: 'Archived Units',
          value: 'archived-units',
          color: 'danger',
          icon: 'BoxIcon',
          count: 3
        },
        {
          name: 'Closed Units',
          value: 'closed-units',
          color: 'warning',
          icon: 'WatchIcon',
          count: 1
        }
      ]
    },
    mappingUnitData (data) {
      const obj = {
        id: data.id,
        name: data.name,
        grade: data.grade,
        gradeValue: data.grade.name,
        subjectsValue: data.subjects.map(item => item.name).join(', '),
        subjects: data.subjects,
        section: data.section.name,
        sectionObj: data.section,
        type: data.type,
        startDate: data.start_date,
        endDate: data.end_date,
        weeks: data.academic_weeks,
        hours: data.academic_hours,
        status: data.status.name,
        description: data.description,
        is_stand_alone: data.is_stand_alone,
        unit_academic_id: data.unit_academic_id
      }
      return obj
    },
    onFilterAction (data) {
      console.log('onFilterAction', data)
    },
    contentClicked () {
      $sharedEvent.fire('contentClicked')
    }
  },
  computed: {
    ...mapGetters({ currentUnit: 'getCreatedUnit' }),
    is_MYP () {
      return this.$route.query.type === 'myp'
    },
    is_DP () {
      return this.$route.query.type === 'dp'
    }
  }
}
