  <template>
  <div id="page-user-list" ref="printMe">
    <div class="vx-card p-6">
      <div class="flex flex-wrap items-center justify-between">
        <!-- ITEMS PER PAGE -->

        <div class="flex-grow">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2"
                >{{
                  currentPage * paginationPageSize - (paginationPageSize - 1)
                }}
                -
                {{
                  list_length - currentPage * paginationPageSize > 0
                    ? currentPage * paginationPageSize
                    : list_length
                }}
                of {{ list_length }}</span
              >
              <!-- <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" /> -->
            </div>
          </vs-dropdown>
        </div>
        <!-- <vs-button
          icon-pack="feather"
          icon="icon-plus-circle"

          color="primary"
          type="filled"
        >Take Attendance</vs-button>-->

        <!-- Centerd Content -->
        <div class="flex-grow">
          <slot name="center_content"></slot>
        </div>
        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
        <!-- <vs-input
          class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
          v-model="searchQuery"
          @input="updateSearchQuery"
          placeholder="Search..."
        /> -->
        <!-- <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Export as CSV</vs-button> -->

        <!-- ACTION - DROPDOWN -->
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32"
          >
            <span class="mr-2 leading-none">Actions</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item @click="exportToExcel(csv_url)">
              <span class="flex items-center">
                <feather-icon
                  icon="SaveIcon"
                  svgClasses="h-4 w-4"
                  class="mr-2"
                />
                <span>CSV</span>
              </span>
            </vs-dropdown-item>
            <vs-dropdown-item v-if="addTeacherNote" @click="$emit('addNote')">
              <span class="flex items-center">
                <feather-icon
                  icon="EditIcon"
                  svgClasses="h-4 w-4"
                  class="mr-2"
                />
                <span>Add Note</span>
              </span>
            </vs-dropdown-item>
            <!-- TODO:: PDF BTN  -->
            <!-- <vs-dropdown-item @click="printMe">
              <span class="flex items-center">
                <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
                <span>PDF</span>
              </span>
            </vs-dropdown-item>-->
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="headers"
        :defaultColDef="defaultColDef"
        :rowData="list"
        rowSelection="multiple"
        rowMultiSelectWithClick="true"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl"
        @grid-ready="onGridReady"
        @row-selected="getSelectedRows"
        @first-data-rendered="onFirstDataRendered"
      ></ag-grid-vue>
      <vs-pagination :total="totalPages" :max="7" v-model="currentPage" />
    </div>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'

// Cell Renderer
import CellRendererStatus from '@/components/general/datatable/cell-renderer/CellRendererStatus'
import CellRendererActions from '../components/CellRendererActions'
import axios from 'axios'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const DataTableRepository = RepositoryFactory.get('General', 'dataTable')
const AllStudentsRepository = RepositoryFactory.get(
  'TeachersModule',
  'StudentDirectory'
)
export default {
  name: 'DataTable',
  props: {
    addTeacherNote: {
      type: Boolean,
      default: false
    },
    csv_url: {
      type: String
    },
    side_filters: {
      type: Object,
      required: false
    },
    filter_type: {
      type: String,
      required: false
    },
    headers_prop: {
      type: Array,
      required: true
    },
    list_url_prop: {
      type: String,
      required: true
    },
    export_prop: {
      type: Boolean,
      default: true
    }
  },
  components: {
    AgGridVue,
    vSelect,

    // Cell Renderer
    CellRendererStatus,
    CellRendererActions
  },
  data () {
    return {
      headerTitle: [],
      headerVal: [],
      headers: this.headers_prop,
      list_url: this.list_url_prop,
      selected_data: [],
      original_list: [],
      list: [],
      export_file: this.export_prop,
      searchQuery: '',
      // AgGrid
      gridColumnApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
        editable: false,
        filter: true
      },
      totalPages: 0,
      currentPage: 1,
      list_length: 0,
      // pdf print
      printElement: null,
      printElementDimension:{
        width: null,
        height: null
      },
      pdfOptions:{
        orientation: 'portrait',
        unit: 'pt',
        fileName:'Generated Report',
        format:{}
      },
      // Cell Renderer Components
      components: {
        CellRendererStatus,
        CellRendererActions
      }
    }
  },
  watch: {
    roleFilter (obj) {
      this.setColumnFilter('role', obj.value)
    },
    statusFilter (obj) {
      this.setColumnFilter('status', obj.value)
    },
    isVerifiedFilter (obj) {
      const val =
        obj.value === 'all' ? 'all' : obj.value === 'yes' ? 'true' : 'false'
      this.setColumnFilter('is_verified', val)
    },
    departmentFilter (obj) {
      this.setColumnFilter('department', obj.value)
    },
    currentPage (page) {
      const page_link = `?page=${page}`
      this.getListData(page_link)
    },
    filter_type (newVal) {
      // this.setColumnFilter('status', newVal)
    },
    side_filters (newVal) {
      if (newVal.length === 0) this.getListData('')
      else {
        AllStudentsRepository.getFilteredStudents('', newVal).then((res) => {
          this.original_list = res.students.data
          this.list = res.students.data
          this.totalPages = res.students.last_page
          this.paginationPageSize = res.students.per_page
          this.currentPage = res.students.current_page
          this.list_length = res.students.total
        })
      }
    }
  },
  computed: {
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    }
  },
  methods: {
    formatJson (filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => {
        // Add col name which needs to be translated
        // if (j === 'timestamp') {
        //   return parseTime(v[j])
        // } else {
        //   return v[j]
        // }

        return v[j]
      })
      )
    },    /////////start PDF ////////////

    async printMe () {
      // convert from px to point //

      this.printElementDimension = {
        width: (this.printElement.clientWidth * 0.75),
        height: (this.printElement.clientHeight * 0.75)
      }

      this.pdfOptions.format = this.printElementDimension

      this.pdfBase64 = await this.html2PdfBase64(this.printElement, this.pdfOptions)
    },
    ///////End PDF ////////////
    ///////////start CSV////////////
    exportToExcel (url) {
      axios.get(url).then(res => {
        this.$emit('startDownload', res)
      })
      // this.headerTitle = []
      // this.headerVal = []
      // import('@/vendor/Export2Excel').then((excel) => {
      //   this.headers.forEach((element) => {
      //     if (element.headerName != 'Actions') {
      //       this.headerTitle.push(element.headerName)
      //       this.headerVal.push(element.field)
      //     }
      //   })
      //   const list = this.list
      //   const data = this.formatJson(this.headerVal, list)
      //   excel.export_json_to_excel({
      //     header: this.headerTitle,
      //     data
      //   })
      //   this.clearFields()
      // })
    },
    ///////////End CSV////////////
    setColumnFilter (column, val) {
      const filter = this.gridApi.getFilterInstance(column)
      let modelObj = null
      if (val !== 'all') {
        modelObj = { type: 'memberOf', filter: val }
      }

      filter.setModel(modelObj)
      this.gridApi.onFilterChanged()
    },
    resetColFilters () {
      // Reset Grid Filter
      this.gridApi.setFilterModel(null)
      this.gridApi.onFilterChanged()

      // Reset Filter Options
      this.roleFilter = this.statusFilter = this.isVerifiedFilter = this.departmentFilter = {
        label: 'All',
        value: 'all'
      }

      this.$refs.filterCard.removeRefreshAnimation()
    },
    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
    },
    // Get Selected Data
    onGridReady (params) {
      this.gridApi = params.api
      this.columnApi = params.columnApi
    },
    getSelectedRows () {
      const data = this.gridApi.getSelectedRows()
      this.selected_data = data.map((elm) => elm.id)
    },
    // Fit columns width
    onFirstDataRendered () {
      this.gridApi.sizeColumnsToFit()
    },
    getListData (page) {
      AllStudentsRepository.getFilteredStudents(page, this.side_filters).then((res) => {
        this.original_list = res.students.data
        this.list = res.students.data
        this.totalPages = res.students.last_page
        this.paginationPageSize = res.students.per_page
        this.currentPage = res.students.current_page
        this.list_length = res.students.total
      })
    },
    getListDataAfterChange () {
      AllStudentsRepository.getFilteredStudents(
        `?page=${this.currentPage}`, this.side_filters
      ).then((res) => {
        this.original_list = res.students.data
        this.list = res.students.data
        this.totalPages = res.students.last_page
        this.paginationPageSize = res.students.per_page
        this.currentPage = res.students.current_page
        this.list_length = res.students.total
      })
    },
    clearFields () {
      this.fileName = ''
      this.cellAutoWidth = true
      this.selectedFormat = 'xlsx'
    },
    changeStatusFilter (filter) {
      this.list = this.original_list.filter((item) => {
        return item.status.toLowerCase() === filter.toLowerCase()
      })
    }
  },
  mounted () {
    this.printElement = this.$refs.printMe

    this.gridApi = this.gridOptions.api

    this.gridColumnApi = this.gridOptions.columnApi

    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(
        '.ag-header-container'
      )
      header.style.left = `-${String(
        Number(header.style.transform.slice(11, -3)) + 9
      )}px`
    }
  },
  created () {
    this.getListData('')
    this.$root.$on('row:status', this.getListDataAfterChange)
    this.$root.$on('row:filter', this.changeStatusFilter)
  }
}
</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
