<template>
  <vx-card class="py-6 md:p-6">
    <form-wizard
      :start-index="0"
      ref="class-form-wizard"
      color="rgba(var(--vs-primary), 1)"
      :title="null"
      :subtitle="null"
      :finishButtonText="submitText"
      @on-complete="submit">
      <!-- tab Basic Info content -->
      <tab-content
        :title="`${$t('classes.BasicInformation')}`"
        class="mb-5"
        :before-change="validateStep1">
        <form
          @submit.stop="validateStep1"
          @keyup.enter="validateStep1"
          data-vv-scope="step-1">
          <!-- Name -->
          <div class="vx-row">
            <div class="vx-col sm:w-1/4 w-full mt-5">
              <label class="text-sm">{{ $t('classes.Name') }}</label>
            </div>
            <div class="vx-col sm:w-1/2 w-full mt-5">
              <vs-input class="w-full" v-model="classObj.name" />
            </div>
          </div>
          <!-- Divisions -->
          <div class="vx-row">
            <div class="vx-col sm:w-1/4 w-full mt-5">
              <label class="text-sm">{{ $t('classes.Divisions') }}</label>
              <span class="text-danger" v-if="!isUpgradeAction">*</span>
            </div>
            <div class="vx-col sm:w-1/2 w-full mt-5">
              <template v-if="isUpgradeAction">
                <p>{{ classObj.divisionName }}</p>
              </template>
              <template v-else>
                <v-select
                  @input="getGrades"
                  class="selectExample w-full"
                  v-model="classObj.division"
                  name="selected_divisions"
                  v-validate="'required'"
                  label="long_name"
                  :reduce="(division) => division.id"
                  :options="divisions_all"
                  :showLoadingIcon="divisionLoading" />
                <span class="text-danger text-sm">{{
                  errors.first('step-1.selected_divisions') | text_formatter
                }}</span>
              </template>
            </div>
          </div>
          <!-- Grades -->
          <div class="vx-row">
            <div class="vx-col sm:w-1/4 w-full mt-5">
              <label class="text-sm">{{ $t('classes.grades') + ' ' }}</label>
              <span class="text-danger" v-if="!isUpgradeAction">*</span>
            </div>
            <div class="vx-col sm:w-1/2 w-full mt-5">
              <template v-if="isUpgradeAction">
                <p>{{ classObj.gradeName }}</p>
              </template>
              <template v-else>
                <v-select
                  class="selectExample w-full"
                  v-model="classObj.grade"
                  name="selected_grades"
                  v-validate="'required'"
                  label="name"
                  :reduce="(grade) => grade.id"
                  :options="grades_all"
                  :showLoadingIcon="gradeLoading" />

                <span class="text-danger text-sm">{{
                  errors.first('step-1.selected_grades') | text_formatter
                }}</span>
              </template>
            </div>
          </div>
          <!-- Classes -->
          <div class="vx-row">
            <div class="vx-col sm:w-1/4 w-full mt-5">
              <label class="text-sm">{{
                isUpgradeAction
                  ? $t('classes.oldClasses')
                  : $t('classes.classes')
              }}</label>
              <span class="text-danger">*</span>
            </div>
            <div class="vx-col sm:w-1/2 w-full mt-5">
              <template v-if="isUpgradeAction">
                <v-select
                  class="selectExample w-full"
                  v-model="oldClass"
                  name="selected_classes"
                  v-validate="'required'"
                  label="name"
                  :options="oldClasses"
                  @change="getStudentsInOldClass"
                  :showLoadingIcon="classLoading" />
              </template>
              <template v-else>
                <v-select
                  class="selectExample w-full"
                  v-model="oldClass"
                  name="selected_classes"
                  v-validate="'required'"
                  :disabled="!classes_all.length > 0"
                  label="name"
                  :options="classes_all"
                  :showLoadingIcon="classLoading" />
              </template>

              <span class="text-danger text-sm"
                >{{ errors.first('step-1.selected_classes') | text_formatter }}
              </span>
            </div>
          </div>
        </form>
      </tab-content>
      <!-- tab Upgade content -->
      <tab-content
        :title="$t('classes.upgradeStudents')"
        class="mb-5"
        :before-change="validationUpgardeStep"
        v-if="isUpgradeAction">
        <div data-vv-scope="step-upgrade">
          <!-- Counts -->
          <div
            class="flex flex-wrap justify-between my-5 text-center"
            v-if="oldClassStudents.data.length > 0">
            <div class="sm:w-1/3 w-full my-5 mx-auto">
              <feather-icon
                icon="UsersIcon"
                svgClasses="h-5 w-5"
                class="mr-3" />
              <strong class="mr-3">{{ oldClassStudents.allCounts }}</strong>
              {{ $t('basic.students') }}
            </div>
            <!--            <div class="sm:w-1/3 w-full my-5">-->
            <!--              <feather-icon-->
            <!--                icon="UsersIcon"-->
            <!--                svgClasses="h-5 w-5"-->
            <!--                class="mr-3" />-->
            <!--              <strong class="mr-3"> {{ oldClassStudents.males }}</strong>-->
            <!--              {{ $t('basic.males') }}-->
            <!--            </div>-->
            <!--            <div class="sm:w-1/3 w-full my-5">-->
            <!--              <feather-icon-->
            <!--                icon="UsersIcon"-->
            <!--                svgClasses="h-5 w-5"-->
            <!--                class="mr-3" />-->
            <!--              <strong class="mr-3">{{ oldClassStudents.females }}</strong-->
            <!--              >{{ $t('basic.females') }}-->
            <!--            </div>-->
          </div>
          <!-- Table -->
          <vs-table :data="oldClassStudents.data" class="w-full mx-auto">
            <template slot="thead">
              <vs-th>{{ $t('form.ID') }}</vs-th>
              <vs-th>{{ $t('form.name') }}</vs-th>
              <vs-th>
                <span>{{ $t('form.status') }}</span>
                <vs-checkbox v-model="upgradeAll">
                  {{ $t('classes.upgrade_all') }}
                </vs-checkbox>
              </vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].id">{{ data[indextr].id }}</vs-td>

                <vs-td :data="data[indextr].first_name"
                  >{{ data[indextr].first_name }}
                  {{ data[indextr].middle_name }}
                  {{ data[indextr].last_name }}</vs-td
                >

                <vs-td>
                  <vs-radio
                    class="mx-3"
                    v-for="status in upgardeStatus"
                    :color="status.color"
                    :key="status.id"
                    v-model="data[indextr].upgrade_status"
                    :vs-value="status"
                    :name="`student_id_${data[indextr].id}`"
                    :vs-name="`student_id_${data[indextr].id}`"
                    @change="checkChangeUpgradeStatus(status.id, data[indextr])"
                    >{{ status.name | text_formatter }}</vs-radio
                  >
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </tab-content>
      <!-- tab Assign Students content -->
      <tab-content
        :title="$t('classes.AssignStudents')"
        class="mb-5"
        :before-change="validateAssignStep">
        <div data-vv-scope="step-assignStudents">
          <div
            class="flex justify-end items-center mb-5 w-full"
            v-if="unassignedStudents.length > 0">
            <vs-button
              class="m-2"
              color="warning"
              type="line"
              @click="unselectAllStudents"
              >{{ $t('classes.Unselect_all_students') }}</vs-button
            >
            <vs-button class="m-2" type="line" @click="selectAllStudents"
              >{{ $t('classes.select_all_students') }}
            </vs-button>
          </div>
          <vs-table
            :data="unassignedStudents"
            v-model="selectedUnassignedStudents"
            class="w-full mx-auto"
            multiple>
            <template slot="thead">
              <vs-th>{{ $t('form.ID') }}</vs-th>
              <vs-th>{{ $t('form.name') }}</vs-th>
              <vs-th>{{ $t('form.status') }}</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].id">{{ data[indextr].id }}</vs-td>

                <vs-td :data="data[indextr].first_name"
                  >{{ data[indextr].first_name }}
                  {{ data[indextr].middle_name }}
                  {{ data[indextr].last_name }}</vs-td
                >

                <vs-td :data="data[indextr].is_assign">{{
                  checkIsAssigned(data[indextr].is_assign)
                }}</vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </tab-content>
      <!-- tab Overview content -->
      <tab-content :title="`${$t('classes.overview')}`" class="mb-5">
        <!-- Counts -->
        <div
          class="flex flex-wrap justify-between my-5 text-center"
          v-if="allStudentsOfTheClass.data.length > 0">
          <div class="sm:w-1/3 w-full my-5 mx-auto">
            <feather-icon icon="UsersIcon" svgClasses="h-5 w-5" class="mr-3" />
            <strong class="mr-3">{{ allStudentsOfTheClass.allCounts }}</strong>
            {{ $t('basic.students') }}
          </div>
          <!--          <div class="sm:w-1/3 w-full my-5">-->
          <!--            <feather-icon icon="UsersIcon" svgClasses="h-5 w-5" class="mr-3" />-->
          <!--            <strong class="mr-3"> {{ allStudentsOfTheClass.males }}</strong>-->
          <!--            {{ $t('basic.males') }}-->
          <!--          </div>-->
          <!--          <div class="sm:w-1/3 w-full my-5">-->
          <!--            <feather-icon icon="UsersIcon" svgClasses="h-5 w-5" class="mr-3" />-->
          <!--            <strong class="mr-3">{{ allStudentsOfTheClass.females }}</strong-->
          <!--            >{{ $t('basic.females') }}-->
          <!--          </div>-->
        </div>
        <!-- Table -->
        <vs-table :data="allStudentsOfTheClass.data" class="w-full mx-auto">
          <template slot="thead">
            <vs-th>{{ $t('form.ID') }}</vs-th>
            <vs-th>{{ $t('form.name') }}</vs-th>
            <vs-th>{{ $t('form.official_email') }}</vs-th>
            <vs-th>{{ $t('form.Unassign') }}</vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].id">{{ data[indextr].id }}</vs-td>

              <vs-td :data="data[indextr].first_name"
                >{{ data[indextr].first_name }}
                {{ data[indextr].middle_name }}
                {{ data[indextr].last_name }}</vs-td
              >

              <vs-td :data="data[indextr].official_email">{{
                data[indextr].official_email
              }}</vs-td>
              <vs-td>
                <vs-button
                  @click="
                    removeStudentFromTheClass(
                      data[indextr].id,
                      data[indextr].notUpgradedYet,
                      indextr
                    )
                  "
                  color="danger"
                  >{{ $t('basic.remove') }}</vs-button
                >
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </tab-content>
    </form-wizard>
    <div
      ref="cardLoader"
      id="cardLoader"
      class="vs-con-loading__container"
      v-show="loading"></div>
  </vx-card>
</template>
<script>
import {FormWizard, TabContent} from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const ClassesRepository = RepositoryFactory.get('AcademicModule', 'Classes')

const DivisionRepository = RepositoryFactory.get(
  'ConfigurationModule',
  'division'
)

export default {
  name: 'classForm',
  components: {
    FormWizard,
    TabContent
  },
  data() {
    return {
      //////////////////////////
      //  loading
      //////////////////////////
      divisionLoading: false,
      gradeLoading: false,
      classLoading: false,
      loading: false,
      //////////////////////////
      //  Data
      //////////////////////////
      divisions_all: [],
      grades_all: [],
      classes_all: [],
      //////////////////////////
      //  Old Classes
      //////////////////////////
      oldClasses: [],
      oldClassStudents: {
        data: [],
        allCounts: 0,
        males: 0,
        females: 0
      },
      oldClass: null,
      //////////////////////////
      //  Upgrade Students
      //////////////////////////
      upgardeStatus: [],
      newStudents: [],
      upgradeStatusObj: {},
      upgradeAll: false,
      selectedUpgradeStudents: [],
      //////////////////////////
      //  unassignedStudents
      /////////////////////////
      unassignedStudents: [],
      selectedUnassignedStudents: [],

      //////////////////////////
      //  Class Data
      //////////////////////////
      allStudentsOfTheClass: {
        data: [],
        allCounts: 0,
        males: 0,
        females: 0
      },
      classObj: {
        id: 0,
        students: [],
        AcademicYear: null,
        division: null,
        grade: null,
        code: null,
        name: null
      }
    }
  },
  computed: {
    classId() {
      return this.$route.params.id || null
    },
    isUpgradeAction() {
      return (
        this.classId &&
        (this.$route.params.type === 'upgrade' ||
          this.$route.path.includes('upgrade'))
      )
    },
    submitText() {
      return this.isUpgradeAction
        ? this.$t('main.upgrade')
        : this.classId
        ? this.$t('form.update')
        : this.$t('form.submit')
    }
  },
  methods: {
    /////////////////////////////
    // Loading
    ////////////////////////////
    startLoading() {
      this.loading = true
      this.VSLoading(this.$refs.cardLoader)
    },
    endLoading() {
      this.stopVSLoading(this.$refs.cardLoader)
      this.loading = false
    },
    /////////////////////////////
    // Show Students
    ////////////////////////////
    setStudentsofClass(data, obj) {
      this[obj].data = data.data
      this[obj].allCounts =
        data.data.length || data.Students || data.students || 0
      this[obj].males =
        data.data.filter((e) => e.gender == '9').length ||
        data.Males ||
        data.males ||
        0
      this[obj].females =
        data.data.filter((e) => e.gender == '10').length ||
        data.Females ||
        data.females ||
        0
    },

    ////////////////////////////
    // APIs
    //////////////////////////
    getClassData() {
      this.startLoading()
      ClassesRepository.showClass(this.classId)
        .then((res) => {
          // console.log('showClass', res)
          this.classObj = res.data
          this.getGrades()
        })
        .catch(this.errMsg)
        .finally(() => this.endLoading())
    },
    getDivisions() {
      this.divisionLoading = true
      DivisionRepository.getdata()
        .then((res) => {
          this.divisions_all = res.data.data
        })

        .finally(() => {
          this.divisionLoading = false
        })
    },
    getGrades() {
      this.gradeLoading = true
      this.grades_all = []
      DivisionRepository.getGrades(this.classObj.division)
        .then((res) => {
          this.grades_all = res.data
          if (this.upgradeClass) this.getOldClasses()
          if (this.classId && !this.upgradeClass) this.getAvailableclasses()
        })
        .catch(this.errMsg)
        .finally(() => {
          this.gradeLoading = false
        })
    },
    //--------- Get Classes
    getOldClasses() {
      this.classLoading = true
      this.oldClasses = []
      ClassesRepository.getOldClasses(this.classObj.grade)
        .then((res) => {
          this.oldClasses = res.data
          this.classLoading = false
        })
        .catch(this.errMsg)
    },
    getAvailableclasses() {
      this.all_classes = null
      ClassesRepository.getClasses(this.classObj.division, this.classObj.grade)
        .then((res) => {
          this.all_classes = res.data
        })
        .catch(this.errMsg)
    },
    //---------- Upgrade
    getUpgradeStatus() {
      ClassesRepository.getUpgradeStatus().then((res) => {
        this.upgardeStatus = res.data
        this.upgardeStatus.forEach((status) => {
          if (status.flag === 'upgrade') {
            status.color = 'success'
            this.upgradeStatusObj = status
          }
          if (status.flag === 'upgrade_and_unassign') status.color = 'warning'
          if (status.flag === 'repeat' || status.flag === 'Repeat')
            status.color = 'danger'
        })
      })
    },
    async upgradeClass() {
      const payload = {}
      this.upgardeStatus.forEach((status) => {
        payload[status.flag] =
          this.oldClassStudents.data
            .filter(
              (s) => s.upgrade_status && s.upgrade_status.flag === status.flag
            )
            ?.map((s) => s.id) || []
      })

      payload.old_class_id = this.oldClass.id
      this.newStudents = payload.upgrade
      return await ClassesRepository.upgradeClass(this.classId, payload)
        .then(async (res) => {
          await this.assignStudentsToTheClass(payload.upgrade)
          await this.getStudentsInOldClass()
        })
        .catch(this.errMsg)
    },
    // ---------- Students
    getNotAssignStudents() {
      this.startLoading()

      const gradeId = this.classObj.grade,
        divisionId = this.classObj.division
      ClassesRepository.getNotAssignStudents(gradeId, divisionId)
        .then((res) => {
          this.unassignedStudents = res.data
        })
        .catch(this.errMsg)
        .finally(() => this.endLoading())
    },

    getStudentsInOldClass() {
      this.startLoading()

      const classId = this.oldClass.id,
        gradeId = this.oldClass.grade.id,
        divisionId = this.oldClass.division_id
      ClassesRepository.studentInClass(classId, gradeId, divisionId)
        .then((res) => {
          if (this.isUpgradeAction)
            res.data.forEach((s) => {
              s.notUpgradedYet = true
            })
          this.setStudentsofClass(res, 'oldClassStudents')
        })
        .catch(this.errMsg)
        .finally(() => this.endLoading())
    },
    getAllStudentsthatAssignedToTheClass() {
      this.startLoading()

      const classId = this.classId,
        gradeId = this.classObj.grade,
        divisionId = this.classObj.division
      ClassesRepository.studentInClass(classId, gradeId, divisionId)
        .then((res) => {
          if (this.selectedUpgradeStudents.length) {
            res.data.push(...this.selectedUpgradeStudents)
          }

          this.setStudentsofClass(res, 'allStudentsOfTheClass')
        })
        .catch(this.errMsg)
        .finally(() => this.endLoading())
    },
    assignStudentsToTheClass(newStudentsIds = this.newStudents) {
      ClassesRepository.assignStudentsToTheClass(this.classId, newStudentsIds)
        .then((res) => {
          // this.successMsg(this.$t('form.saved_successfully'))
          this.newStudents = []
          this.selectedUnassignedStudents = []
          this.getAllStudentsthatAssignedToTheClass()
        })
        .catch(this.errMsg)
      this.getNotAssignStudents()
    },
    removeStudentFromTheClass(
      studentId,
      studentNotUpgradedYet = null,
      studentIndex = null
    ) {
      if (this.isUpgradeAction && studentNotUpgradedYet) {
        this.selectedUpgradeStudents.splice(
          this.selectedUpgradeStudents.findIndex((s) => s.id === studentId),
          1
        )
        this.allStudentsOfTheClass.data.splice(studentIndex, 1)
        this.oldClassStudents.data.find(
          (s) => s.id === studentId
        ).upgrade_status = null
        return
      }
      this.startLoading()

      ClassesRepository.removeStudentFromClass(studentId)
        .then((res) => {
          this.successMsg(this.$t('form.saved_successfully'))
          this.getAllStudentsthatAssignedToTheClass()
          this.getNotAssignStudents()
        })
        .catch(this.errMsg)
        .finally(() => this.endLoading())
    },
    updateNewStudents() {
      ClassesRepository.updateNewStudents(this.classObj.id, this.classObj)
        .then(() => {
          this.successMsg(
            this.$t(
              'classes.class_has_been_upgraded_with_New_Students_successfully'
            ),
            this.$t('basic.updated')
          )
          this.$router.push({name: 'ClassesList'})
        })
        .catch(this.errMsg)
    },
    ////////////////////////////
    // Assign Student
    ///////////////////////////
    checkIsAssigned(typeData) {
      if (typeData == 1) return this.$t('classes.assigned_student')
      return this.$t('classes.not_assigned_student')
    },
    selectAllStudents() {
      this.selectedUnassignedStudents = this.unassignedStudents || []
    },
    unselectAllStudents() {
      this.selectedUnassignedStudents = []
    },
    checkChangeUpgradeStatus(statusId, student) {
      const selectedStudents = this.selectedUpgradeStudents
      if (statusId === 1) selectedStudents.push(student)
      else if (selectedStudents.length) {
        selectedStudents.splice(
          selectedStudents.findIndex((e) => e.id === student.id),
          1
        )
      }
    },
    ////////////////////////////
    // Submit
    ///////////////////////////
    validateStep(stepNum) {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll(`step-${stepNum}`).then((result) => {
          if (result) resolve(true)
          else {
            this.errMsg()
            reject('correct all values')
          }
        })
      })
    },
    validateStep1() {
      return this.validateStep(1)
    },
    validateStep2() {
      return this.validateStep(2)
    },
    validationUpgardeStep() {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll('step-upgrade').then(async (result) => {
          if (result) {
            const oldClassStudents = this.oldClassStudents.data,
              haveStudents = oldClassStudents.length > 0,
              isStudentUpgraded = oldClassStudents.some((s) => s.upgrade_status)

            if (haveStudents && !isStudentUpgraded) {
              this.errMsg(
                this.$t(
                  'classes.please_update_at_least_one_student_upgrade_status'
                )
              )
              reject('correct all values')
            } else {
              await this.getNotAssignStudents()
              resolve(true)
            }
          } else {
            this.errMsg()
            reject('correct all values')
          }
        })
      })
    },
    validateAssignStep() {
      return new Promise((resolve, reject) => {
        this.$validator
          .validateAll('step-assignStudents')
          .then(async (result) => {
            if (result) {
              if (this.selectedUnassignedStudents.length) {
                const studentsId = this.selectedUnassignedStudents.map(
                  (s) => s.id
                )
                await this.assignStudentsToTheClass(studentsId)
              }
              await this.getAllStudentsthatAssignedToTheClass()
              resolve(true)
            } else {
              this.errMsg()
              reject('correct all values')
            }
          })
      })
    },
    async submit() {
      if (this.classId) {
        this.classObj.students = this.allStudentsOfTheClass.data.map(
          (e) => e.id
        )
        if (this.isUpgradeAction) {
          this.upgradeClass().then(this.updateNewStudents)
        } else await this.updateNewStudents()
      }
    }
  },
  async created() {
    this.getDivisions()

    if (this.classId) this.getClassData()
    if (this.isUpgradeAction) {
      this.getUpgradeStatus()
    }
  },
  watch: {
    'classObj.oldClassId'(v) {
      this.getStudentsInClass(v)
    },
    upgradeAll(v) {
      if (v) {
        this.oldClassStudents.data.forEach((s) => {
          s.upgrade_status = this.upgradeStatusObj
        })
        this.selectedUpgradeStudents = this.oldClassStudents.data
      } else {
        this.oldClassStudents.data.forEach((s) => {
          s.upgrade_status = null
        })
        this.selectedUpgradeStudents = []
      }
    }
  }
}
</script>
