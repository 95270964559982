import {RepositoryFactory} from '@/Repositories/RepositoryFactory'

export default {
  name: 'taskGrade',

  data () {
    return {
      StudentRepository: RepositoryFactory.get('StudentsModule', 'student'),

      modalProps: {
        width: '40%',
        height: 'auto',
        minHeight:250,
        scrollable: true
      },
      modalName: 'taskGrade',
      task:null,
      taskId: this.$route.params.id,
      section:null
    }
  },
  created () {
    this.StudentRepository.getSingleTask(this.taskId).then((res) => {
      this.loadData(res.data)
    }).catch((err) => {
      console.log(err)
    })
  },
  computed: {
    selectedCriteria () {
      if (this.task.class_teacher.section.name == 'PYP') {
        const criteria = this.task.criteria.filter((criteria) => {
          if (this.task.task.assess.criteria.includes(criteria.key)) {
            return criteria
          }
        })
        return criteria
      } else {
        return this.task.task.assess.criteria
      }
    }
  },
  methods:{
    loadData (data) {
      /* debugger */
      this.task = data
      this.section = data.task.assess.section.name
    },
    cancel () {
      if (this.isMobileWebView) {
        this.$router.push({
          name: 'student_tasks'

        })
      } else this.$router.push({
        name: 'BackToProfile',
        params: {
          id: this.authUser.uid
        }
      })
    }
  }
}
