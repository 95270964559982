<template>
  <div>
    <!-- Show Reflections -->
    <transition-group class="list-transition" name="list-enter-up" tag="ul">
      <template v-if="reflectionslength">
        <li
          v-for="(val, i) in reflections"
          :key="val.id"
          :style="[{ transitionDelay: i * 0.1 + 's' }]"
        >
          <reflection-card
            :refelect_prop="val"
            :index="i + 1"
            @reply="openReply"
            @getReflections="getReflections"
          >
          </reflection-card>
        </li>
      </template>
    </transition-group>
    <vs-card>
      <!-- ////////////////////
            Post
     ///////////////////////// -->
      <form
        @submit.prevent="submitReflection"
        class="my-6 md:w-2/3 mx-auto"
        v-if="is_student && reflectionslength < 3"
        id="reply_form"
        ref="postForm"
      >
        <div class="mb-5">
          <label>{{ postLableText }}</label>
          <vx-input-group>
            <vs-textarea
              :placeholder="$t('cas.insert_some_words')"
              v-model="postReflectionBody"
              class="w-full m-0 bg-white"
              name="description"
              v-validate="'required'"
              rows="5"
            />
            <!-- Add Another -->
            <template slot="append">
              <div class="append-text btn-addon">
                <vs-button @click="submitReflection">
                  {{ $t("basic.post") }}
                </vs-button>
              </div>
            </template>
          </vx-input-group>
          <span class="text-sm">{{ errors.first("description") }}</span>
        </div>
      </form>

      <!-- ////////////////////
             reply
      ///////////////////////// -->

      <form
        @submit.prevent="submitReply"
        class="my-6 md:w-2/3 mx-auto"
        v-if="is_openReply"
        ref="replyForm"
      >
        <div class="mb-5">
          <label>{{ $t("basic.reply") }}</label>
          <vx-input-group>
            <vs-textarea
              :placeholder="$t('cas.insert_some_words')"
              v-model="replyReflectionBody"
              class="w-full m-0 bg-white"
              name="description"
              v-validate="'required'"
              rows="5"
            />
            <!-- Add Another -->
            <template slot="append">
              <div class="append-text btn-addon">
                <vs-button @click="submitReply">
                  {{ $t("basic.post") }}
                </vs-button>
              </div>
            </template>
          </vx-input-group>
          <span class="text-sm">{{ errors.first("description") }}</span>
        </div>
      </form>
    </vs-card>
  </div>
</template>
<script>
// components
import ReflectionCard from './components/ReflectionCard'
// APIs
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const CasRepository = RepositoryFactory.get(
  'CASRepository',
  'TaskRepository'
)

// Mixins
import mixins from '../../mixins'


export default {
  name: 'reflections',
  props: {
    task_prop: {
      type: Object,
      required: true
    }

  },
  mixins:[mixins],
  components: {
    ReflectionCard
  },
  data () {
    return {
      ///////////////////////
      // Post Form
      /////////////////////
      bodyText:'',
      ///////////////////////
      // Task
      /////////////////////
      task:this.task_prop,
      /////////////////////
      // Reflections
      ///////////////////////
      reflectionslength:0,
      reflections:[],
      /////////////////////
      // Post Reflection
      ///////////////////////
      is_postReflection:false,
      postReflectionBody:'',
      /////////////////////
      // Reply  Reflection
      ///////////////////////
      is_replytReflection:false,
      replyReflectionBody:'',
      replyReflectionID:null
    }
  },

  computed: {
    postLableText () {
      let text = ''
      switch (this.reflectionslength) {
      case 0:
        text = this.$t('cas.first_reflection')

        break
      case 1:
        text = this.$t('cas.second_reflection')

        break
      case 2:
        text = this.$t('cas.Third_Final_reflection')
        break
      }
      return text
    },
    is_openReply () {
      return  this.is_coordinator &&
      this.is_replytReflection &&
     this.reflectionslength > 0
    }

  },
  watch: {
  },
  methods: {
    /////////////////////////////////
    //  Data
    ////////////////////////////////

    resetPost () {
      this.is_postReflection = false

      this.postReflectionBody = ''
      this.$refs.postForm.reset()
      this.reflections = []

    },

    resetReply () {
      this.is_replytReflection = false
      this.replyReflectionBody = ''
      this.replyReflectionID = null
      this.reflections = []

    },
    /////////////////////////////////
    //  APIs
    ////////////////////////////////
    createReflection () {
      this.loading()
      CasRepository.createReflection(this.postReflectionBody, this.task.id)
        .then(res => {
          this.successMsg(this.$t('cas.you_reflection_is_saved'))
          this.resetPost()
          this.getReflections()
        })
        .catch(() => this.serveErr())
        .finally(() => this.stopLoading())
    },
    replyReflection () {
      this.loading()
      CasRepository.replyReflection(this.replyReflectionID, this.replyReflectionBody)
        .then(res => {
          this.successMsg(res.message)
          this.resetReply()
          this.getReflections()
        })
        .catch(() => this.serveErr())
        .finally(() => this.stopLoading())
    },
    getReflections () {
      this.reflections = []
      this.loading()
      CasRepository.getReflection(this.task.id)
        .then(res => {
          this.reflections = res.data
          this.reflectionslength = res.data.length
          this.is_postReflection = this.is_student && (res.data.length < 3  || false)
          this.is_replytReflection = this.is_coordinator && res.data.length < 3 && res.data.length < 0
        })
        .catch()
        .finally(() => this.stopLoading())
    },
    /////////////////////////////
    // Reply
    ///////////////////////////
    openReply (reflection_id) {

      if (this.is_coordinator) {
        this.is_replytReflection = true
        this.replyReflectionID = reflection_id

      }
    },
    /////////////////////////////
    // Submit Form
    ///////////////////////////
    submitReflection () {
      this.$validator.validate()
        .then((valid) => {
          if (valid) this.createReflection()
          else this.errMsg()
        })
        .then(() => {
          this.$refs.postForm.reset()
          this.$validator.reset()
        })
        .catch(() => this.errMsg())
    },
    submitReply () {
      this.$validator.validate()
        .then((valid) => {
          if (valid) this.replyReflection()
          else this.errMsg()

        })
        .then(() => {
          this.$refs.replyForm.reset()
          this.$validator.reset()
        })
        .catch(() => this.errMsg())
    }

  },
  created () {
    this.getReflections()


  }

}
</script>
<style lang="scss" >
</style>
