<template>
  <div id="casTaskCard">
    <vx-card class="p-relative mb-8" :class="{ 'mt-3': hasUpdates }">
      <!--------------- hasUpdates Notification --------------->
      <vs-chip
        transparent
        v-if="hasUpdates"
        class="hasUpdates shadow-inner"
        color="dark"
      >
        <div class="h-2 w-2 rounded-full mr-1 bg-danger"></div>
        <span class="text-capitalize">{{ $t("cas.new_updates") }}</span>
      </vs-chip>

      <vs-dropdown
        class="cas-action-Buttons text-capitalize"
        vs-custom-content
        vs-trigger-click
      >
        <vs-button color="#999" type="flat" icon="more_vert"></vs-button>
        <vs-dropdown-menu>
          <vs-dropdown-item
            @click="goTo('showTask', { id: task.id })"
            v-if="canOpen"
          >
            <feather-icon icon="EyeIcon" svgClasses="h-4 w-4" class="mr-2" />
            {{ $t("basic.open_view") }}
          </vs-dropdown-item>

          <!--///////////////////
                  If Coordinator
           ////////////////////////-->
          <template v-if="is_coordinator">
            <vs-dropdown-item @click="setInterview">
              <feather-icon icon="EditIcon" svgClasses="h-4 w-4" class="mr-2" />
              {{
                task.interview_count === 3
                  ? $t("cas.show_student_interviews")
                  : $t("cas.Set_Interview_or_note")
              }}
            </vs-dropdown-item>
            <vs-dropdown-item
              v-if="showAllowUploadFinal"
              @click="ConfirmAllowUploadFinalReport"
            >
              <feather-icon
                icon="CheckIcon"
                svgClasses="h-4 w-4"
                class="mr-2"
              />
              {{ $t("cas.allow_Upload_Final_Report") }}
            </vs-dropdown-item>
            <!-- <vs-dropdown-item @click="SendMessage">
              <feather-icon
                icon="MessageSquareIcon"
                svgClasses="h-4 w-4"
                class="mr-2"
              />
              {{ $t("cas.Send_message") }}
            </vs-dropdown-item> -->
          </template>

          <!--/////////////////////
                  If Student
           /////////////////////-->

          <template v-else>
            <vs-dropdown-item
              @click="archiveTask"
              v-if="task.status !== 'archived' && is_student && !finishedCAS"
            >
              <span class="flex items-center">
                <feather-icon
                  icon="ArchiveIcon"
                  svgClasses="h-4 w-4"
                  class="mr-2"
                />
                <span>{{ $t("basic.archive") }}</span>
              </span>
            </vs-dropdown-item>
          </template>
        </vs-dropdown-menu>
      </vs-dropdown>

      <!------------------ Content  ------------------>
      <div class="min_height_110 vx-row flex items-center p-1 pt-1 pb-1 md:p-1">
        <!-- Name -->
        <div class="vx-col w-full xl:w-1/3 xl:mb-0 mb-4 text-capitalize">
          <h5 class="mb-6 text-capitalize">
            {{ task.name }}
          </h5>

          <p class="text-xs flex flex-wrap justify-between items-center">
            <span class="w-full flex flex-wrap justify-between items-center">
              <span
                v-for="(v, i) in task_criterias"
                :key="i"
                :class="`mt-1 mr-3 status-fill--${task.criterias_symbol[
                  i
                ].toLowerCase()}`"
              >
                {{ v === "Action" ? "activity" : v}}
              </span>
            </span>

            <!-- <span
              v-if="
                canSeeCompletedHours && task.total_numer_houres_for_criatria
              "
              class="mx-auto text-capitalize"
            >
              {{ `${task.total_numer_houres_for_criatria}` }}
              {{ $t("basic.hours") }}
            </span> -->
            <!-- Validation status -->
            <span
              class="w-auto text-xs mt-3 color-white"
              :class="`status-text-border--${task.validation.toLowerCase()}`"
            >
              {{ task.validation.replaceAll("_", " ") }}
            </span>
          </p>
        </div>
        <!-- Date && Time -->
        <div class="vx-col w-full xl:w-1/4 xl:mx-0 xl:px-0 xl:mb-0 mb-4">
          <p class="mb-1 text-sm inline-block mr-5 text-capitalize">
            <feather-icon icon="CalendarIcon" svgClasses="h-4 w-4 mr-2 " />
            {{ $t("basic.startAt") }}: {{ task.start_date }}
          </p>
          <p class="mb-1 text-sm inline-block mr-5 text-capitalize">
            <feather-icon icon="CalendarIcon" svgClasses="h-4 w-4 mr-2 " />
            {{ $t("basic.endAt") }}: {{ task.end_date }}
          </p>
          <p class="text-sm inline-block text-capitalize">
            <feather-icon icon="ClockIcon" svgClasses="h-4 w-4 mr-2 " />
            {{ task.time }} {{ $t("basic.hours") }}
          </p>
        </div>
        <!-- Student Data || sudent Notes -->
        <div
          class="
            vx-col
            w-full
            xl:w-1/5
            xl:mx-0
            xl:px-0
            flex
            xl:flex-col
            flex-row
            md:w-1/2
          "
          :class="{ 'md:mb-0 mb-4': !is_student }"
        >
          <template v-if="!is_student">
            <p class="mb-1 text-sm block mr-5 text-capitalize">
              {{ task.student_name }}
            </p>

            <p class="mb-2 text-sm block mr-5 text-capitalize">
              {{ task.student_code }}
            </p>
            <p class="xl:mb-0 mb-4 text-sm block mr-5">
              <span class="text-capitalize bg-warning text-white py-1 px-2">
                {{ task.student_class }} </span
              >
            </p>
          </template>
        </div>
        <!-- Status -->
        <div class="vx-col w-full xl:w-1/5 md:w-1/2 text-center text-sm">
          <ul
            class="
              flex-wrap flex
              justify-between
              items-center
              lg:flex-col
              flex-row
            "
          >
            <li
              class="lg:w-full w-auto mb-1"
              :class="`status2--${task.status_cas_symbol.toLowerCase()}`"
            >
              {{ task[`${current_locale}.status_cas`] }}
            </li>

            <li
              class="lg:w-full w-auto mb-1 color-white"
              :class="`status-text-border--${task.timeStatus.toLowerCase()}`"
            >
              {{ task.timeStatus }}
            </li>

            <template v-if="task['remind-time']">
              <!-- ------------------
                  Evaluation
             ---------------------  -->
              <template
                v-if="
                  (task.submit && task.submit.includes('accept')) ||
                  task['remind-time'].toLowerCase().includes('approved')
                "
              >
                <li
                  :class="`lg:w-full w-auto text-xs md:mb-0 mb-1
              status3--${task.submit.toLowerCase().replaceAll(' ', '_')}`"
                >
                  {{
                    this.task.submit === "accepted" ||
                    task["remind-time"].toLowerCase().includes("approved")
                      ? $t("cas.approved")
                      : $t("cas.not_approved")
                  }}
                </li>
              </template>
              <!-- ------------------
                  submittion
             ---------------------  -->
              <template v-else>
                <li
                  class="lg:w-full w-auto text-xs md:mb-0 mb-1 status3--blue"
                  v-if="task['remind-time'] === 'submit_student'"
                >
                  {{ $t("cas.submitted") }}
                </li>
                <li
                  class="lg:w-full w-auto text-xs md:mb-0 mb-1 status3--blue"
                  v-if="task['remind-time'] === 'submited'"
                >
                  {{ $t("cas.submitted") }}
                </li>
                <!-- Upcomging -->
                <li
                  class="
                    lg:w-full
                    w-auto
                    text-xs
                    md:mb-0
                    mb-1
                    status3--upcoming
                  "
                  v-if="parseInt(task['remind-time']) > 0"
                >
                  {{ parseInt(task["remind-time"]) }}
                  {{ $t("cas.days_to_submit") }}
                </li>
                <!-- Dlayed -->
                <li
                  class="lg:w-full w-auto text-xs md:mb-0 mb-1 status3--delayed"
                  v-else-if="parseInt(task['remind-time']) < 0"
                >
                  {{ parseInt(task["remind-time"]) * -1 }}
                  {{ $t("cas.days_delayed") }}
                </li>
                <li
                  class="lg:w-full w-auto text-xs md:mb-0 mb-1 status3--delayed"
                  v-else-if="task['remind-time'] === 'Delayed Not Approved'"
                >
                  {{ task["remind-time"] }}
                </li>
                <li
                  class="lg:w-full w-auto text-xs md:mb-0 mb-1 status3--dark"
                  v-else-if="task['remind-time'] === 'Not Approved'"
                >
                  {{ task["remind-time"] }}
                </li>

                <!-- time_to_take_action -->
                <li
                  class="lg:w-full w-auto text-xs md:mb-0 mb-1 status3--ongoing"
                  v-else-if="parseInt(task['remind-time']) === 0"
                >
                  {{ $t("cas.time_to_take_action") }}
                </li>
              </template>
            </template>
          </ul>
        </div>
      </div>
    </vx-card>
  </div>
</template>
<script>
// APIs
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const CasRepository = RepositoryFactory.get('CASRepository', 'TaskRepository')

// Mixins
import mixins from '../../../mixins'

export default {
  name: 'task-card',
  props: {
    task_prop: {
      type: Object,
      required: true
    }
  },
  mixins: [mixins],
  components: {},
  data () {
    return {
      task: this.task_prop,
      userRule: '',
      setInterviews: true
    }
  },

  computed: {
    task_criterias () {
      return this.task.translations_criterias.map((el) => {
        return el.find((e) => e.locale === this.current_locale).name
      })
    },

    task_student_id () {
      return this.task.student_id
    },

    canSeeCompletedHours () {
      return this.is_coordinator
    },
    canSeeStudentData () {
      return this.is_coordinator
    },
    canOpen () {
      return (
        this.is_coordinator ||
        (this.is_student &&
          (this.finishedCAS || this.task.validation === 'validated'))
      )
    },
    hasUpdates () {
      return this.is_coordinator && !this.task.is_coordinator_show_updated
    },
    showAllowUploadFinal: {
      get () {
        return !this.task.check_allow_upload_final_report
      },
      set (v) {
        this.task.check_allow_upload_final_report = !v
      }
    }
  },
  watch: {},
  methods: {
    /////////////////////////////////
    // Actions APIs
    ////////////////////////////////
    setInterview () {
      const payload = {
        student_id: this.task.student_id,
        task_id: this.task.id,
        student_code: this.task.student_code
      }
      this.$emit('setInterview', payload)
    },
    // FinalReport
    allowToUploadFinalReport () {
      this.loading()
      CasRepository.allowUploadFinalReport(this.student_id)
        .then((res) => {
          this.showAllowUploadFinal = false
          this.successMsg(
            `${this.task.student_name} ${this.$t(
              'cas.can_upload_final_report'
            )}`
          )
        })
        .catch((err) => this.errMsg(err))
        .finally(() => this.stopLoading())
    },
    checkAllowToUploadFinalReport () {
      CasRepository.checkAllowUploadFinalReport(this.task.student_id).then(
        (res) => {
          // alert(res.message.is_allow)
          this.showAllowUploadFinal = !res.message.is_allow
        }
      )
    },

    checkStudentInterviews () {
      CasRepository.getTeacherInterviews(this.task.student_code)
        .then((res) => {
          this.setInterviews = res.data.length < 3
        })
        .catch()
    },
    // Chat
    SendMessage () {},
    // Archive Task
    archiveTask () {
      CasRepository.archiveTask(this.task.id).then((res) => {
        this.$emit('reloadData', true)
      })
    },
    /////////////////////////////////
    // ConfirmAllowUploadFinalReport
    ////////////////////////////////
    ConfirmAllowUploadFinalReport () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: this.$t('cas.Upload_Final_Report'),
        text: `${this.$t(
          'cas.Student_has_completed_the_CAS_program'
        )} ${this.$t('cas.Kindly_let_the_student_upload_his_final_report')} `,
        accept: () => {
          this.allowToUploadFinalReport()
        }
      })
    }
  },
  created () {
    this.userRule = this.$acl.get[0].toLowerCase()
    if (this.is_coordinator) {
      // this.checkAllowToUploadFinalReport()
      // this.checkStudentInterviews()
    }
  }
}
</script>
<style lang="scss" >
.cas-action-Buttons {
  position: absolute;
  top: 0;
  right: 0;
}
.con-vs-chip.flex.justify-between {
  .text-chip.vs-chip--text {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
iframe {
  border: 1px solid #ccc;
}
#casTaskCard {
  .hasUpdates {
    position: absolute;
    right: 9px;
    top: -15px;
  }
}

span.color-white , li.color-white {
  color: #fff !important;
}
</style>
