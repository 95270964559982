import Vue from 'vue'
import { AclInstaller, AclCreate, AclRule } from 'vue-acl'
import router from '@/router'

Vue.use(AclInstaller)

let initialRole = 'visitor'

const teacher = new AclRule('Arabic Teacher')
  .or('Homeroom Teacher')
  .or('PE Teacher')
  .or('Co - Teacher')
  .or('German Teacher')
  .or('Math Teacher')
  .or('Religion Teacher')
  .or('Art Teacher')
  .or('Science Teacher')
  .or('Quran Teacher')
  .or('English Teacher')
  .or('Humanities Teacher')
  .or('MYP Math Teacher')
  .or('English and Humanities Teacher')
  .or('ICT Teacher')
  .or('MYP English Teacher')
  .or('French Teacher')
  .or('Germany Teacher')
  .or('It Teacher')
  .or('Music Teacher')
  .or('teacher')
  .or('Technicion Lab Teacher')
  .or('Social Teacher')
  .or('Head of Math and science  Department')
  .or('English /Humanities Teacher')
  .or('Head of Individual and society')
  .or('PYP Coordinator Assistant')
  .or('PYP Coordinator')
  .or('MYP Admin Assistant')
  .or('Assistant Principal')
  .or('Head of learning support')
  .or('School Counsellor')
  .or('Subject Leader')
  .or('School Deputy & Head of Arabic Department')
  .or('MYP Coordinator')
  .or('Head of English Department for MYP')
  // .or('School Principal')
  .or('Physics Teacher')
  .or('Vice Principal')
  .or('Key Stage')
  .or('Head of Department')
  .or('Head of German')
  .or('American Coordinator')
  .or('Coodinator')
  .or('Key Stage Teacher')
  .or('Head of Individual and society Teacher')
  .or('School Counsellor Teacher')
  .or('Subject Leader Teacher')
  .or('Head of Department Teacher')
  .or('Head of English Department for MYP Teacher')
  .or('PoiSectionCoordinator')
  .or('TeacherHOD')
  .or('TeacherSectionCoodinator')
  .or('TeacherGradeCoodinator')

const teacherWithoutHOD = new AclRule('Arabic Teacher')
  .or('Homeroom Teacher')
  .or('PE Teacher')
  .or('Co - Teacher')
  .or('German Teacher')
  .or('Math Teacher')
  .or('Religion Teacher')
  .or('Art Teacher')
  .or('Science Teacher')
  .or('Quran Teacher')
  .or('English Teacher')
  .or('Humanities Teacher')
  .or('MYP Math Teacher')
  .or('English and Humanities Teacher')
  .or('ICT Teacher')
  .or('MYP English Teacher')
  .or('French Teacher')
  .or('Germany Teacher')
  .or('It Teacher')
  .or('Music Teacher')
  .or('teacher')
  .or('Technicion Lab Teacher')
  .or('Social Teacher')
  .or('Head of Math and science  Department')
  .or('English /Humanities Teacher')
  .or('Head of Individual and society')
  .or('PYP Coordinator Assistant')
  .or('PYP Coordinator')
  .or('MYP Admin Assistant')
  .or('Assistant Principal')
  .or('Head of learning support')
  .or('School Counsellor')
  .or('Subject Leader')
  .or('School Deputy & Head of Arabic Department')
  .or('MYP Coordinator')
  .or('Head of English Department for MYP')
  .or('Physics Teacher')
  .or('Vice Principal')
  .or('Key Stage')
  .or('Head of Department')
  .or('Head of German')
  .or('American Coordinator')
  .or('Coodinator')
  .or('Key Stage Teacher')
  .or('Head of Individual and society Teacher')
  .or('School Counsellor Teacher')
  .or('Subject Leader Teacher')
  .or('Head of Department Teacher')
  .or('Head of English Department for MYP Teacher')
  .or('PoiSectionCoordinator')
  .or('TeacherSectionCoodinator')
  .or('TeacherGradeCoodinator')


const super_visor = new AclRule('PYP Coordinator')
  .or('MYP Coordinator')
  .or('PYP Coordinator Assistant')
  .or('Co - Teacher')
  .or('SMD')
  .or('Vice Principal')
  .or('MYP Admin Assistant')
  .or('Assistant Principal')
  .or('Head of Department')
  .or('Head of German')
  .or('Head of learning support')
  .or('School Deputy & Head of Arabic Department')
  .or('Head of English Department for MYP')
  .or('School Principal')
  .or('Head of German')
  .or('Principal-coordinator')
  .or('Head of Department Teacher')
  .or('Head of English Department for MYP Teacher')

const teacherSND =
  new AclRule('School Administrative Deputy')
    .or('Studient Affaires')
    .or('Admission Assistant')
    .or('PR Coordinator')
    .or('AdmmissionStudentAttendence')

const userInfo = JSON.parse(localStorage.getItem('authUser'))
if (userInfo && userInfo.userRole) initialRole = userInfo.userRole
export default new AclCreate({
  initial: initialRole,
  notfound: '/pages/not-authorized',
  router,
  acceptLocalRules: true,
  globalRules: {
    SMD: new AclRule('SMD').generate(),
    SMDPOICoordinator: new AclRule('SMD').or('PoiSectionCoordinator').generate(),
    SNDPOICoordinator: new AclRule('SND').or('PoiSectionCoordinator').generate(),
    SNDSMDPOICoordinator: new AclRule('SND').or('SMD').or('PoiSectionCoordinator').generate(),
    SNDSMD: new AclRule('SND').or('SMD').generate(),
    SND: new AclRule('SND').generate(),
    Student: new AclRule('Student').or('student').generate(),
    Teacher: teacher.generate(),
    TeacherWithoutHOD: teacherWithoutHOD.generate(),
    Parent: new AclRule('parent').generate(),
    landing: teacher
      .or('Principal-coordinator')
      .or('SND')
      .or('SMD')
      .or('Student')
      .or('student')
      .or('parent')
      .or('School Principal')
      .or('Coodinator')
      .or('SectionCoodinator')
      .or('PoiSectionCoordinator')
      .or('School Administrative Deputy')
      .or('Studient Affaires')
      .or('Admission Assistant')
      .or('PR Coordinator')
      .or('AdmmissionStudentAttendence')
      .or('HOD')
      .or('TeacherHOD')
      .or('TeacherSectionCoodinator')
      .or('GradeCoodinator')
      .or('TeacherGradeCoodinator')
      .generate(),
    //todo fix this typo in above rule

    gradebookPrincipalCoordinators:
      new AclRule('Principal-coordinator')
        .or('School Principal')
        .or('Coodinator')
        .or('SectionCoodinator')
        .or('PoiSectionCoordinator')
        .or('School Administrative Deputy')
        .or('TeacherSectionCoodinator')
        .or('GradeCoodinator')
        .or('TeacherGradeCoodinator')
        .generate(),
    //todo fix this typo in above rule
    Profile: teacher
      .or('SND')
      .or('SMD')
      .or('Student')
      .or('student')
      .generate(),
    visitor: new AclRule('visitor').generate(),
    visitorOrAuth: teacher
      .or('SND')
      .or('SMD')
      .or('visitor')
      .or('Student')
      .or('student')
      .or('parent')
      .generate(),
    Coordinator: super_visor
      .or('Student')
      .or('student')
      .generate(),

    SchoolPrincipal: new AclRule('School Principal').or('SectionCoodinator').or('HOD').or('TeacherHOD').or('TeacherSectionCoodinator').or('GradeCoodinator').or('TeacherGradeCoodinator').generate(), // to display OverView
    CasCoordinator: new AclRule('Coodinator').generate(), // to display cas
    SectionsCoodinator: new AclRule('SectionCoodinator').or('PoiSectionCoordinator').or('TeacherSectionCoodinator').or('GradeCoodinator').or('TeacherGradeCoodinator').generate(), //To  display agenda
    POISectionsCoodinator:new AclRule('PoiSectionCoordinator').or('SND').generate(), //To  display POI
    PrincipalCoordinator: new AclRule('School Principal').or('Coodinator').generate(),
    SchoolPrincipalSMDSND: new AclRule('School Principal').or('SND').or('SMD').generate(),
    SchoolPrincipalSMD: new AclRule('School Principal').or('SMD').generate(),
    SchoolPrincipalSND: new AclRule('School Principal').or('SND').generate(),
    TeacherSND: teacherSND.or('SND').generate(),
    TeacherSNDSMD: teacherSND.or('SND').or('SMD').generate(),
    HOD: new AclRule('HOD').generate(),
    TeacherHOD: new AclRule('TeacherHOD').generate(),
    TeacherSectionCoodinator:new AclRule('TeacherSectionCoodinator').generate(),
    GradeCoodinator:new AclRule('GradeCoodinator').generate(),
    TeacherGradeCoodinator: new AclRule('TeacherGradeCoodinator').generate(),
    // TODO FIX TEACHER ROLES
    SMDSNDPrincipalTeacherHomeroomSectionCoordinator: teacher.or('SND').or('SMD').or('School Principal').or('SectionCoodinator').or('HOD').generate(),
    PrincipalTeacherHomeroomSectionCoordinator: teacher.or('School Principal').or('SectionCoodinator').or('HOD').generate(),
    SNDTeacher: teacher.or('SND').generate()

  }
})
