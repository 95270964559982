<template>
  <listPageComponent
    createRoutName="gradingCreateRound"
    :createBtnName="$t('grading.new_round_for_report_card')"
    :showCreateBtn="canCreateRound">
    <template slot="listDataContent">
      <!-- ////////////////////////////////////
            Status Counter && Create Button
        ////////////////////////////////////// -->
      <!-- <StatusCounter
        @filter-change="changeCounterFilter"
        :status_prop="statistics"
        class="w-full"
        ref="statusCounter"
      /> -->

      <!-- ////////////////////////////////////
            Content
        ////////////////////////////////////// -->
      <div class="w-full mb-3">
        <!-- ////////////////////////////////////
                    List Data
        ////////////////////////////////////// -->
        <transition-group class="list-transition" name="list-enter-up" tag="ul">
          <template v-if="filtrated_data.length > 0">
            <li
              v-for="(val, i) in filtrated_data"
              :key="val.id"
              :style="[{transitionDelay: i * 0.1 + 's'}]"
              class="mb-3">
              <grading-round-card
                :round="val"
                :index="i + 1"
                @changeStatus="changeStatus" />
            </li>
          </template>
        </transition-group>
        <!-- ////////////////////////////////////
                    Pagination
        ////////////////////////////////////// -->
        <template v-if="filtrated_data.length > 0 && totalPages > 1">
          <vs-pagination
            :total="totalPages"
            :max="countPerPage"
            v-model="currentPage"
            class="mt-6" />
        </template>
        <!-- ////////////////////////////////////
                    No Data
        ////////////////////////////////////// -->
        <template v-if="filtrated_data.length < 1">
          <p class="text-center">{{ $t('basic.no_data_available') }}</p>
        </template>
      </div>
    </template>

    <template slot="rightSide">
      <round-filter
        :filters_prop="filters"
        @refilter="refilter"
        @resetAll="refilter"
        ref="roundFilter"
        :loading="loadingFilter" />
    </template>
  </listPageComponent>
</template>
<script>
// APIs
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const GradingRepository = {
  rounds: RepositoryFactory.get('GradingModule', 'rounds'),
  allocations: RepositoryFactory.get('GradingModule', 'allocations')
}

// Components
import listPageComponent from '@/components/listPageGridSystem/withCreateButton'
import RoundFilter from '@/components/general/RoundFilter' //Right-sidebar
import StatusCounter from '@/components/general/StatusTabs'
import {commonDataMixin} from '@/mixins.js'
import gradingRoundCard from './components/gradingRoundCard.vue'

export default {
  name: 'grading-rounds',
  components: {
    listPageComponent,
    StatusCounter,
    RoundFilter,
    gradingRoundCard
  },
  mixins: [commonDataMixin],
  data() {
    return {
      ///////////////////////
      // Status Counter
      ///////////////////////
      statistics: [
        {
          name: 'active',
          count: 0
        }
      ],
      /////////////////////////
      // Pagination
      /////////////////////////
      totalPages: 0,
      currentPage: 1,
      countPerPage: 10,
      ///////////////////////
      // Data
      ///////////////////////
      allocations: [],
      ///////////////////////
      // Right Filters
      ///////////////////////
      filters: [
        {
          id: 1,
          name: this.$t('basic.academic_year'),
          payload_name: 'academic_years',
          by: []
        },
        {
          id: 2,
          name: this.$t('basic.schools'),
          payload_name: 'schools',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 3,
          name: this.$t('basic.divisions'),
          payload_name: 'divisions',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 4,
          name: this.$t('basic.sections'),
          payload_name: 'sections',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 4,
          name: this.$t('basic.status'),
          payload_name: 'status',
          singleSeletable: false,
          by: [
            {
              name: this.$t('status.active'),
              id: 'active',
              checked: false
            },
            {
              name: this.$t('status.upcoming'),
              id: 'upcoming',
              checked: false
            },
            {
              name: this.$t('status.ongoing'),
              id: 'ongoing',
              checked: false
            },
            {
              name: this.$t('status.archived'),
              id: 'archive',
              checked: false
            },
            {
              name: this.$t('status.closed'),
              id: 'closed',
              checked: false
            }
          ]
        }
      ],
      filtrated_data: [],
      checked_data: [],
      filter_Payload: {
        sections: null,
        divisions: null,
        schools: null,
        status: null,
        academic_years: null
      },
      loadingFilter: false
    }
  },
  computed: {
    isCurrentAcademicYear() {
      return (
        Boolean(this.currentAcademicYear?._id) || this.currentAcademicYearId
      )
    },
    canCreateRound() {
      return this.isCurrentAcademicYear && Boolean(this.allocations.length)
    }
  },
  watch: {
    currentPage(v) {
      this.tasksFilter(v)
    }
  },
  methods: {
    ////////////////////////////
    // APIs
    ////////////////////////////
    //-------- Filter
    getAllGradingRounds() {
      this.VSLoading()
      GradingRepository.rounds
        .getAllGradingRounds(this.filter_Payload, this.currentPage)
        .then((res) => {
          this.filtrated_data = res.data.data
          this.setPagination(res.data)
        })
        .catch()
        .finally(() => this.stopVSLoading())
    },
    getAllocations() {
      GradingRepository.allocations
        .getAllAllocations()
        .then((res) => {
          this.allocations = res.data.data
        })
        .catch()
    },
    changeStatus(round) {
      if (!round) return
      this.VSLoading()
      GradingRepository.rounds
        .changeStatus(round.id, round.newStatus)
        .then((res) => {
          this.getAllGradingRounds()
        })
        .catch(this.errMsg)
        .finally(() => this.stopVSLoading())
    },
    // get Filters Data
    getFiltersData() {
      this.loadingFilter = true
      this.getCurrentAcademicYear()
      this.getAllAcademic().then((res) => {
        res.map((el) => {
          this.filters[0].by.push({
            name: el.name,
            id: el._id,
            checked: el._id === this.currentAcademicYear._id
          })
        })
      })
      this.getFilterData()
        .then((res) => {
          res.schools.map((el) => {
            this.filters[1]['by'].push({
              id: el.id,
              name: el.branch_name,
              checked: false
            })
          })
        })
        .finally(() => {
          this.loadingFilter = false
        })
    },
    getDivision(chosen) {
      this.loadingFilter = true
      this.filters[2].by = []

      this.getFilterData({schools: chosen.schools})
        .then((res) => {
          res.divisions.map((el) => {
            this.filters[2]['by'].push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
        })
        .finally(() => {
          this.loadingFilter = false
        })
    },
    getSection(chosen) {
      const data = {
        schools: chosen.schools,
        divisions: chosen.divisions
      }
      this.getFilterData(data).then((res) => {
        res.sections.map((el) => {
          this.filters[3]['by'].push({
            id: el.id,
            name: el.name,
            checked: false
          })
        })
      })
    },
    ////////////////////////////
    // RoundFilter
    ////////////////////////////
    refilter(checked) {
      const is_checked = checked && checked.length > 0
      for (const key in this.filter_Payload) {
        this.filter_Payload[key] = null
      }
      if (is_checked) {
        checked.forEach((el) => {
          if (this.filter_Payload.hasOwnProperty(el.name)) {
            this.filter_Payload[el.name] = el.checked
          }
        })
      }
      this.getAllGradingRounds()
    },
    ////////////////////////////
    // Set Status && Counts
    ////////////////////////////
    setStatusCounts() {},
    changeCounterFilter() {}
  },
  beforeDestroy() {
    this.$root.$off('onChange:schools')
    this.$root.$off('onChange:divisions')
    this.$root.$off('onChange:sections')
  },
  created() {
    this.getAllGradingRounds()
    this.getFiltersData()
    this.getAllocations()
    this.$root.$on('onChange:schools', this.getDivision)
    this.$root.$on('onChange:divisions', this.getSection)
    this.$root.$on('onChange:sections', this.getGrade)
  }
}
</script>
<style lang="scss"></style>
