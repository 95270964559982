<template>
  <ListPageWithCreateButton
    createRoutName="RolesCreate"
    :createBtnName="$t('config.createRole')">
    <template slot="listDataContent">
      <data-table
        :csv_url="'role/export'"
        :pdf_url="'roles/pdf'"
        :total="1"
        :headers_prop="headers"
        list_url_prop="/role" />
    </template>
    <template v-slot="rightSide">
      <!--      <round-filter-->
      <!--        :filters_prop="filters"-->
      <!--        :filters_choosen_prop="filter_choosen"-->
      <!--        :rounds_prop="rounds"-->
      <!--        active_prop.sync="true" />-->
    </template>
  </ListPageWithCreateButton>
</template>
<script>
import DataTable from '@/components/general/datatable/DataTable2'
import RoundFilter from '@/components/general/RoundFilter.vue'
export default {
  components: {
    DataTable,
    RoundFilter
  },
  data() {
    return {
      headers: [
        {
          minWidth: 160,
          headerName: this.$t('basic.ID'),

          field: 'id',
          width: 150,
          headerCheckboxSelectionFilteredOnly: true
        },
        {
          minWidth: 160,
          headerName: this.$t('config.role'),
          field: 'name',
          width: 200
        },
        // {
        //   minWidth: 160,
        //   headerName: this.$t('config.users'),
        //   field: 'permissions.length'
        // },
        {
          minWidth: 160,
          headerName: this.$t('config.status'),
          field: 'status',
          width: 290,
          cellRendererFramework: 'CellRendererStatus',
          cellRendererParams: {
            status_prop: this.status
          }
        },
        {
          minWidth: 160,
          filter: false,
          headerName: this.$t('config.actions'),
          field: 'actions',
          width: 240,
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            actions_prop: [
              {
                title: this.$t('basic.edit'),
                dropDown: false,
                parameter_name: 'id',
                icon: 'Edit3Icon',
                navigate_action: true,
                route_name: 'RoleEdit'
              },
              {
                dropDown: true,
                dropDown_items: [
                  {
                    title: this.$t('status.active'),
                    parameter_name: 'id',
                    icon: 'CheckSquareIcon',
                    action: 'Active',
                    resource: 'role/:id/status/',
                    showIf: (data) => data.status !== 'Active'
                  },
                  {
                    title: this.$t('basic.suspend'),
                    parameter_name: 'id',
                    icon: 'PowerIcon',
                    action: 'Suspended',
                    resource: 'role/:id/status/',
                    showIf: (data) => data.status !== 'Suspended'
                  },
                  {
                    title: this.$t('status.archive'),
                    parameter_name: 'id',
                    icon: 'ArchiveIcon',
                    action: 'Archived',
                    resource: 'role/:id/status/',
                    showIf: (data) => data.status !== 'Archived'
                  },
                  {
                    title: this.$t('status.close'),
                    parameter_name: 'id',
                    icon: 'SlashIcon',
                    action: 'Closed',
                    resource: 'role/:id/status/',
                    showIf: (data) => data.status !== 'Closed'
                  }
                ]
              }
            ]
          }
        }
      ],
      status: [
        {
          id: 0,
          title: this.$t('status.new'),
          value: 'new',
          icon: 'StarIcon',
          count: '22',
          link: '#!'
        },
        {
          id: 1,
          title: this.$t('status.shortlisted'),
          value: 'shortlisted',
          icon: 'ClipboardIcon',
          count: '22',
          link: '#!'
        },
        {
          id: 2,
          title: this.$t('status.on_assessment'),
          value: 'on_assessment',
          icon: 'UserCheckIcon',
          count: '22',
          link: '#!'
        },
        {
          id: 3,
          title: this.$t('status.waiting_list'),
          value: 'w_list',
          icon: 'WatchIcon',
          count: '22',
          link: '#!'
        },
        {
          id: 4,
          title: this.$t('status.disqualified'),
          value: 'disqualified',
          icon: 'XCircleIcon',
          count: '22',
          link: '#!'
        },
        {
          id: 5,
          title: this.$t('status.qualified'),
          value: 'qualified',
          icon: 'CheckCircleIcon',
          count: '22',
          link: '#!'
        }
      ],
      filters: [
        {
          id: 1,
          name: this.$t('basic.schools'),

          by: [
            {
              id: 1,
              name: 'Filter 1'
            },
            {
              id: 2,
              name: 'Filter 2'
            },
            {
              id: 3,
              name: 'Filter 3'
            }
          ]
        },
        {
          id: 2,
          name: 'Division',
          by: [
            {
              id: 1,
              name: 'Filter 1'
            },
            {
              id: 2,
              name: 'Filter 2'
            },
            {
              id: 3,
              name: 'Filter 3'
            }
          ]
        },
        {
          id: 3,
          name: this.$t('basic.sections'),
          by: [
            {
              id: 1,
              name: 'Filter 1'
            },
            {
              id: 2,
              name: 'Filter 2'
            },
            {
              id: 3,
              name: 'Filter 3'
            }
          ]
        },
        {
          id: 4,
          name: this.$t('basic.schools'),

          by: [
            {
              id: 1,
              name: 'Filter 1'
            },
            {
              id: 2,
              name: 'Filter 2'
            },
            {
              id: 3,
              name: 'Filter 3'
            }
          ]
        },
        {
          id: 5,
          name: 'Role Type',
          by: [
            {
              id: 1,
              name: 'Filter 1'
            },
            {
              id: 2,
              name: 'Filter 2'
            },
            {
              id: 3,
              name: 'Filter 3'
            }
          ]
        },
        {
          id: 6,
          name: 'Teacher Roles',
          by: [
            {
              id: 1,
              name: 'Filter 1'
            },
            {
              id: 2,
              name: 'Filter 2'
            },
            {
              id: 3,
              name: 'Filter 3'
            }
          ]
        },
        {
          id: 7,
          name: this.$t('basic.gender'),
          by: [
            {
              id: 1,
              name: 'Filter 1'
            },
            {
              id: 2,
              name: 'Filter 2'
            },
            {
              id: 3,
              name: 'Filter 3'
            }
          ]
        }
      ],
      filter_choosen: [],
      rounds: [
        {
          id: 1,
          name: 'Roles',
          start: '5 March 2020',
          end: '5 March 2020'
        },
        {
          id: 2,
          name: 'Roles',
          start: '5 March 2020',
          end: '5 March 2020'
        }
      ]
    }
  },
  methods: {}
}
</script>
