
export default {
  name: 'imagePreview',
  data () {
    return {
      modalProps: {
        width: '61%',
        height: 'auto',
        minHeight:450,
        scrollable: true
      },
      modalName: 'imagePreviewModal',
      image:null,
      customEvents: [{eventName: 'openImagePreviewModal', callback: this.loadData}]
    }
  },
  created () {
    ////////////////////////////////////////////////////
    //**          Custom Event Listener           ** //
    //////////////////////////////////////////////////
    this.customEvents.forEach(function (customEvent) {
      // eslint-disable-next-line no-undef
      $sharedEvent.listen(customEvent.eventName, customEvent.callback)
    })
  },
  destroyed () {
    ////////////////////////////////////////////////////
    //**      Remove Custom Event Listener        ** //
    //////////////////////////////////////////////////
    this.customEvents.forEach(function (customEvent) {
      // eslint-disable-next-line no-undef
      $sharedEvent.off(customEvent.eventName, customEvent.callback)
    })
  },
  methods:{
    showModal () {
      this.$modal.show(this.modalName)
    },
    hideModal () {
      this.$modal.hide(this.modalName)
    },
    loadData (image) {
      /* debugger */
      this.resetProps()
      this.image = image
      this.showModal()
    },
    cancel () {
      this.resetProps()
      this.hideModal()
    },
    resetProps () {
      this.menuId = null
      this.newFeatureData = null
    }
  }

}
