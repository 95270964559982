import Service from '../../services/unit-planner'
import VxTooltip from '@/layouts/components/vx-tooltip/VxTooltip.vue'
import eventBus from '../../eventBus'


export default {
  props: ['teacherUnitId'],
  components: {
    VxTooltip
  },
  data () {
    return {
      tasksData: [],
      restService: Service,
      currentTaskToArchive: {},
      customEvents: [
        {eventName: 'updateAssessmentGridData', callback: this.updateGrid},
        { eventName: 'refreshTeacherAssessment', callback: this.fetchTeacherAssessment }
      ],
      /////////////////////////
      // Pagination
      /////////////////////////
      totalPages:0,
      currentPage:1,
      countPerPage:10,
      assessment:{
        action: 'single-task',
        icon: 'icon-cloud-lightning',
        // id: 34,
        parent_key: 'teacher-tasks',
        title: 'Assessment Task'
      }
    }
  },
  watch: {
    currentPage (v) {
      this.fetchTeacherAssessment(v)
    }
  },

  created () {
    ////////////////////////////////////////////////////
    //**          Custom Event Listener           ** //
    //////////////////////////////////////////////////
    this.customEvents.forEach(function (customEvent) {
      // eslint-disable-next-line no-undef
      $sharedEvent.listen(customEvent.eventName, customEvent.callback)
    })
  },
  computed:{
    checkDate () {
      return (date) => {
        const lunchDate = new Date(date), dateNow = new Date()
        const diffTime = Math.ceil((dateNow - lunchDate) / 1000 * 60)
        if (diffTime >= 0) return true
        else return false
      }
    }
  },
  mounted () {
    this.fetchTeacherAssessment()
  },
  destroyed () {
    ////////////////////////////////////////////////////
    //**      Remove Custom Event Listener        ** //
    //////////////////////////////////////////////////
    this.customEvents.forEach(function (customEvent) {
      // eslint-disable-next-line no-undef
      $sharedEvent.off(customEvent.eventName, customEvent.callback)
    })
  },
  methods: {
    updateGrid (data) {
      const itemIndex = this.tasksData.findIndex(item => item.id === data.id)
      if (itemIndex !== -1) {
        /*case update*/
        this.tasksData.splice(itemIndex, 1, data)
      } else {
        this.tasksData.unshift(data)
      }
    },
    archiveTask () {
      this.$vs.loading()
      //** Archive Task **//
      this.restService.archiveAssessmentTask(this.currentTaskToArchive.id, {status : this.currentTaskToArchive.status})
        .then(res => {
          this.updateGrid(res.data)
          //** Hide Loader **//
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Archive',
            text: 'Task Archived Successfully',
            color: 'success',
            position: 'bottom-right'
          })
        })
        .catch(err => {
          console.error(err)
          //** Hide Loader **//
          this.$vs.loading.close()

          this.errMsg(err.data.message, this.$t('form.loading_data_error'))

        })

    },
    changeArchiveStatus (taskId, status) {
      this.currentTaskToArchive.id = taskId
      this.currentTaskToArchive.status = status
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Confirm',
        text: `${this.$t('basic.are_you_sure_to')}  ${status === 'archive' ? 'archive' : 'active' } ${this.$t('basic.this_task')}?.`,
        accept:this.archiveTask
      })
    },
    editAssessment (data) {
      $sharedEvent.fire('openCreateTaskModal', data)
    },
    showAssessment (data) {
      $sharedEvent.fire('openPreviewTaskModal', data)
    },
    deleteAssessment (id) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: this.$t('basic.confirm_msg'),
        accept: () => this.handleDeleteAction(id)
      })
    },
    handleDeleteAction (id) {
      this.loading = false
      this.$vs.loading()
      this.restService.deleteAssessment(id).then(() => {
        this.$vs.loading.close()
        //* remove item from list */
        this.tasksData = this.tasksData.filter((task) => {
          return task.id !== id
        })
        this.$vs.notify({
          color: 'success',
          title: this.$t('basic.delete'),
          text: 'deleted successfully'
        })
      })
    },
    fetchTeacherAssessment (page) {
      this.$vs.loading()
      //** Get IB types **//
      this.restService
        .getTeacherAssessments(this.teacherUnitId, page)
        .then(res => {
          this.tasksData = res.data.sort((a, b) => b.id - a.id)
          //** Hide Loader **//
          this.$vs.loading.close()

          //** Set Pagination */
          this.setPagination(res)
        })
        .catch(err => {
          console.error(err)
          //** Hide Loader **//
          this.$vs.loading.close()
          this.errMsg(err.data.message, this.$t('form.loading_data_error'))

        })
    },
    assessmentStudentsTask (data) {
      // const tasksWithAnswers = this.tasksData.filter(item =>  item.statistics.has_answer)
      const query = this.$route.query
      this.$router.replace({query:{...query, task:data.id}})
      eventBus.$emit('openSingleTask', this.assessment)
    },
    /////////////////////////
    // Pagination
    /////////////////////////
    setPagination (data) {
      this.totalPages = data.total_pages || data.last_page
      this.countPerPage = data.per_page
      this.currentPage = data.current_page
    }
  }
}
