import AllStudentsList from './pages/List'
import create_student from './sub_modules/smd/pages/create_student'
import edit_student from './sub_modules/smd/pages/edit_student'
import AllParentsList from './pages/ListAllParents'
import EditParentsList from './sub_modules/smd/pages/edit_parents'


export default [

  {
    path: '/student-affairs/all-students',
    name: 'all-students',
    component: AllStudentsList,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },

        // {
        //   title: 'StudentAffairs.student_affairs',
        //   url: '/student-affairs'
        // },
        {
          title: 'StudentAffairs.allStudents',
          url: '/student-affairs/all-students',
          active: true
        }
      ],
      pageTitle: 'StudentAffairs.allStudents',
      rule:'TeacherSNDSMD'
    }
  },
  {
    path: '/student-affairs/add-student',
    name: 'create-students',
    component: create_student,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        {
          title: 'StudentAffairs.allStudents',
          url: '/student-affairs/all-students',
          active: true
        },
        {
          title: 'StudentAffairs.createStudent',
          url: '/student-affairs/add-student',
          active: true
        }
      ],
      pageTitle: 'StudentAffairs.createStudent',
      rule:'TeacherSNDSMD'
    }
  },
  {
    path: '/student-affairs/edit-student/:id',
    name: 'edit-student',
    component: edit_student,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        {
          title: 'StudentAffairs.allStudents',
          url: '/student-affairs/all-students',
          active: true
        },
        {
          title: 'StudentAffairs.editStudent',
          url: '/student-affairs/edit-student/:id',
          active: true
        }
      ],
      pageTitle: 'StudentAffairs.editStudent',
      rule:'TeacherSNDSMD'
    }
  },
  //All Parents
  {
    path: '/student-affairs/all-parents',
    name: 'all-parents',
    component: AllParentsList,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        {
          title: 'StudentAffairs.allparents',
          active: true
        }
      ],
      pageTitle: 'StudentAffairs.allparents',
      rule:'TeacherSNDSMD'
    }
  },
  {
    path: '/student-affairs/edit-parents/:id',
    name: 'edit-parents',
    component: EditParentsList,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        {
          title: 'StudentAffairs.allparents',
          url: '/student-affairs/all-parents'
        },
        {
          title: 'StudentAffairs.edit_parent',
          active: true
        }
      ],
      pageTitle: 'StudentAffairs.edit_parent',
      rule:'TeacherSNDSMD'
    }
  }
]
