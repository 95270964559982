import axios from 'axios'

const resource = '/canteen'
export default {
  // Canteen
  getCanteenList (page) {
    return axios.get(`${resource}?page=${page}`)
  },
  addNewCanteen (payload) {
    return axios.post(`${resource}/store`, payload)
  },
  showCanteenDetails (canteenId) {
    return axios.get(`${resource}/show/${canteenId}`)
  },
  editCanteen (canteenId, payload) {
    return axios.post(`${resource}/update/${canteenId}`, payload)
  },

  archiveCanteen (canteenId, payload) {
    return axios.post(`canteen/change-status/${canteenId}`, payload)
  },

  // Category
  addNewCategory (canteenId, payload) {
    return axios.post(`${resource}/${canteenId}/category/store`, payload)
  },
  showCategory (canteenId, categoryId) {
    return axios.get(`${resource}/${canteenId}/category/show/${categoryId}`)
  },
  editCategory (canteenId, categoryId, payload) {
    return axios.post(`${resource}/${canteenId}/category/update/${categoryId}`, payload)
  },
  getAllCategoryWithoutPagination (canteenId) {
    return axios.get(`canteen/${canteenId}/category/without-paginate`)
  },
  // Item
  addNewItem (payload) {
    return axios.post(`${resource}/items/store`, payload)
  },
  showItemDetails (itemId) {
    return axios.get(`canteen/items/show/${itemId}`)
  },
  editItem (itemId, payload) {
    return axios.post(`canteen/items/update/${itemId}`, payload)
  },

  // User

  getAllUsers (id) {
    return axios.get(`${resource}/users/${id}`)
  },
  getAllTypes () {
    return axios.get(`${resource}/users/types`)
  },
  addUser (payload) {
    return axios.post(`${resource}/users/store`, payload)
  },
  showUserData (canteenId, userId) {
    return axios.get(`${resource}/users/${canteenId}/show/${userId}`)
  },
  editUserData (userId, payload) {
    return axios.post(`${resource}/users/update/${userId}`, payload)
  }
}
