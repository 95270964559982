import axios from 'axios'

const resource = '/institution'
const common = '/common'
export default {
  createInstitution (instData) {
    return axios.post(`${resource}/edit`, instData)
  },

  getInstitution () {
    return axios.get(`${resource}`)
  },
  institutiondata () {
    return axios.get('/institutiondata') 
  },
  uploadeImage (imageData, config) {
    return axios.post(`${common}/UploadeImage`, imageData, config)
  },
  updateImage (imageData, config) {
    return axios.post(`${common}/updateImage`, imageData, config)
  }

}
