<template >
  <div>
    <vs-card class="p-3 pt-6 pb-6 md:p-6">
      <form @submit="submitData">
        <div class="vx-row">
          <!-------------------------- Name -------------------------->

          <div class="vx-col lg:w-1/2 md:w-1/2 w-full mb-6">
            <label>
              {{ $t("InstitutionDetails.Name") }}
              <sup class="text-danger">*</sup>
            </label>
            <vs-input name="LongName" v-validate="'required|min:2|max:40|regex:^[a-zA-Z_][a-zA-Z0-9_]+( [a-zA-Z0-9_]+)*$'" class="w-full"
              :placeholder="`${$t('InstitutionDetails.LongName')}`" v-model="allNameData.longName" />
            <span v-if="issueSubmitted && errors.has('LongName')" class="text-danger text-sm">{{ errors.first("LongName")
              | text_formatter }}</span>
          </div>

          <div class="vx-col lg:w-1/2 md:w-1/2 w-full mb-6">
            <label>{{ $t("InstitutionDetails.ShortName") }}
              <sup class="text-danger">*</sup>
            </label>
            <vs-input name="ShortName" v-validate="'required|min:2|max:15|regex:^[a-zA-Z_][a-zA-Z0-9_]+( [a-zA-Z0-9_]+)*$'
              " class="w-full" :placeholder="`${$t('InstitutionDetails.ShortName')}`"
              v-model="allNameData.ShortName" />

            <span v-if="issueSubmitted && errors.has('ShortName')" class="text-danger text-sm">{{
              errors.first("ShortName") | text_formatter }}</span>
          </div>

          <!-- <div class="vx-col sm:w-1/2 w-full mb-2">
          <label>
            {{ $t("InstitutionDetails.BranchName") }}
            <sup class="text-danger">*</sup>
          </label>
          <vs-input
            name="BranchName"
            v-validate="'required'"
            class="w-full"
            v-model="allNameData.BrnchName"
          />
          <span
            v-if="issueSubmitted && errors.has('BranchName')"
            class="text-danger text-sm"
            >{{ errors.first("BranchName") | text_formatter }}</span
          >
          <div v-if="branchNameError" class="text-danger text-sm">
            {{ $t("InstitutionDetails.BranchExist") }}
          </div>
        </div> -->

          <div class="vx-col md:w-1/2 w-full mb-6">
            <label>
              {{ $t("InstitutionDetails.user") }}
              <sup class="text-danger">*</sup>
            </label>
            <v-select label="name" :reduce="(user) => user.id" :options="users" multiple v-model="user" name="user"
              class="w-full" v-validate="'required'" :placeholder="`${$t('main.AutoComplete')}`">
            </v-select>
            <span v-if="issueSubmitted && errors.has('user')" class="text-danger text-sm">{{ errors.first("user") |
              text_formatter }}</span>
          </div>

          <!-------------------------- /Name -------------------------->
        </div>
        <!-------------------------- Address -------------------------->
        <div class="vx-row">
          <!-- Text Address -->
          <div class="vx-col lg:w-1/2 md:w-1/2 w-full mb-6">
            <label>
              {{ $t("InstitutionDetails.Address") }}
              <sup class="text-danger">*</sup>
            </label>
            <vs-textarea name="address" v-validate="'required'" class="w-full m-0"
              :placeholder="`${$t('InstitutionDetails.Address')}`" v-model="allAddressData.desc" />
            <span v-if="issueSubmitted && errors.has('address')" class="text-danger text-sm">{{ errors.first("address") |
              text_formatter }}</span>
          </div>
          <!-- /Text Address -->
          <!---  GPSAddress --->
          <div class="vx-col lg:w-1/2 md:w-1/2 w-full mb-6 flex items-center">
            <label>
              <button @click.prevent="googleAddress()" class="no_style flex items-center">
                <img src="@/assets/images/file-icons/GPS.png" :alt="`${$t('main.GoogleMap')}`" height="30" width="30"
                  v-validate="'image'" name="logoImage" data-vv-as="image" class="inline-flex mr-3" />
                <span>
                  {{ $t("InstitutionDetails.ClickForGPSAddress") }}
                  <sup class="text-danger">*</sup>
                </span>
              </button>
            </label>

            <div v-if="googleError" class="text-danger text-sm">
              {{ $t("InstitutionDetails.ClickForGPSAddress") }}
              <span class="text-danger">*</span>
            </div>
          </div>

          <GoogleMapInfoWindow @updateLocation="updateLocation" v-if="loaded" :markers="markers" :setPlace="setPlace"
            :loaded="loaded" class="vx-col w-full mb-6" ref="googleMap" :mapErrorAlert="mapErrorAlert" />

          <!---  /GPSAddress --->
        </div>
        <!-------------------------- /Address -------------------------->
        <!-------------------------- UploadLogo -------------------------->
        <vs-divider position="center">{{
          $t("InstitutionDetails.Logo")
        }}</vs-divider>

        <div class="vx-row">
          <div class="vx-col md:w-1/3 w-full mb-3">
            <label class="mb-2 block">
              {{ $t("InstitutionDetails.UploadLogo") }}
              <sup class="text-danger">*</sup>
            </label>
            <vs-input
              type="file"
              class="w-full"
              accept=".png,.jpg,.jpeg;capture=camera"
              v-model="logoFile"
              @change="onChange($event, 'logo')"
            ></vs-input>
            <div
              id="preview"
              class="mt-3"
              v-if="imageUrl"
              style="max-height: 160px; width: auto; max-width: 222px"
            >
              <img
                :alt="`${$t('basic.Logo')}`"
                :src="imageUrl"
                class="h-full w-full"
              />
              <!-- <vs-button
                color="danger"
                type="border"
                size="small"
                class="w-full"
                @click="deleteFile(logo)"
                >{{ $t("form.delete") }}</vs-button
              > -->
            </div>
          </div>

          <span v-if="issueSubmitted && errors.has('logoImage')" class="text-danger text-sm w-full">{{
            errors.first("logoImage") | text_formatter }}</span>
        </div>
        <!-------------------------- /UploadLogo -------------------------->
        <!-------------------------- Contacts -------------------------->
        <vs-divider position="center">{{
          $t("InstitutionDetails.Contacts")
        }}</vs-divider>
        <!-- General -->
        <div class="vx-row">
          <div class="vx-col mb-2 w-full">
            <label>
              {{ $t("InstitutionDetails." + contacts.tel[0].type) }}
            </label>
          </div>
          <div class="vx-col lg:w-1/4 md:w-1/2 w-full mb-4">
            <!---key---str2.concat(str1)-->
            <div class="vx-row mb-4">
              <div class="vx-col md:w-1/3 w-full md:pr-0">
                <vs-select autocomplete v-model="contacts.tel[0].key" name="General_tel_key" class="select-small w-full">
                  <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in tel_keys"
                    class="w-full" />
                </vs-select>
                <span v-if="issueSubmitted && errors.has('General_tel_key')" class="text-danger text-sm">{{
                  errors.first("General_tel_key") | text_formatter }}</span>
              </div>
              <div class="vx-col md:w-2/3 w-full md:pl-1 md:mt-0 mt-3">
                <vs-input name="GeneralTelephone" v-validate="'numeric|length:8'" :placeholder="$t('main.Tel')"
                  v-model="contacts.tel[0].number" class="w-full"></vs-input>
                <span v-if="issueSubmitted && errors.has('GeneralTelephone')" class="text-danger text-sm">{{
                  errors.first("GeneralTelephone") | text_formatter }}</span>
              </div>
            </div>
          </div>

          <div class="vx-col lg:w-1/4 md:w-1/2 w-full mb-4">
            <vs-input name="GeneralFax" v-validate="'min:2|max:15|regex:^[0-9\-\+]*$'" :placeholder="$t('main.Fax')"
              v-model="contacts.fax[0].number" class="w-full"></vs-input>
            <span v-if="issueSubmitted && errors.has('GeneralFax')" class="text-danger text-sm">{{
              errors.first("GeneralFax") | text_formatter }}</span>
          </div>
          <div class="vx-col lg:w-1/4 md:w-1/2 w-full mb-4">
            <div class="vx-row mb-4">
              <div class="vx-col md:w-1/3 w-full md:pr-0">
                <vs-select autocomplete v-model="contacts.mobile[0].key" :value="mobile_keys[0]" name="General_mobile_key"
                  class="select-small w-full">
                  <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in mobile_keys"
                    class="w-full" />
                </vs-select>
                <span v-if="issueSubmitted && errors.has('General_mobile_key')" class="text-danger text-sm">{{
                  errors.first("General_mobile_key") | text_formatter
                }}</span>
              </div>
              <div class="vx-col md:w-2/3 w-full md:pl-1 md:mt-0 mt-3">
                <vs-input name="GeneralMobile" v-validate="'numeric|length:8'" :placeholder="$t('main.Mobile')"
                  v-model="contacts.mobile[0].number" class="w-full"></vs-input>
                <span v-if="issueSubmitted && errors.has('GeneralMobile')" class="text-danger text-sm">{{
                  errors.first("GeneralMobile") | text_formatter }}</span>
              </div>
            </div>
          </div>
          <div class="vx-col lg:w-1/4 md:w-1/2 w-full mb-4">
            <vs-input name="GeneralEmail" v-validate="'email'" :placeholder="`${$t('main.Email')}`"
              v-model="contacts.email[0].email" class="w-full"></vs-input>
            <span v-if="issueSubmitted && errors.has('GeneralEmail')" class="text-danger text-sm">{{
              errors.first("GeneralEmail") | text_formatter }}</span>
          </div>
        </div>
        <!-- / General -->
        <!-- Accounting -->
        <div class="vx-row">
          <div class="vx-col mb-2 w-full">
            <label>
              {{ $t("InstitutionDetails." + contacts.tel[3].type) }}
            </label>
          </div>
          <div class="vx-col lg:w-1/4 md:w-1/2 w-full mb-4">
            <div class="vx-row mb-4">
              <div class="vx-col md:w-1/3 w-full md:pr-0">
                <vs-select autocomplete v-model="contacts.tel[3].key" name="AccountingTelkey" class="select-small w-full">
                  <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in tel_keys"
                    class="w-full" />
                </vs-select>
                <span v-if="issueSubmitted && errors.has('AccountingTelkey')" class="text-danger text-sm">{{
                  errors.first("AccountingTelkey") | text_formatter }}</span>
              </div>
              <div class="vx-col md:w-2/3 w-full md:pl-1 md:mt-0 mt-3">
                <vs-input name="AccountingTel" v-validate="'numeric|length:8'" :placeholder="`${$t('main.Tel')}`"
                  v-model="contacts.tel[3].number" class="w-full"></vs-input>
                <span v-if="issueSubmitted && errors.has('AccountingTel')" class="text-danger text-sm">{{
                  errors.first("AccountingTel") | text_formatter }}</span>
              </div>
            </div>
          </div>
          <div class="vx-col lg:w-1/4 md:w-1/2 w-full mb-4">
            <vs-input name="AccountingFax" v-validate="'min:2|max:15|regex:^[0-9\-\+]*$'"
              :placeholder="`${$t('main.Fax')}`" v-model="contacts.fax[3].number" class="w-full"></vs-input>
            <span v-if="issueSubmitted && errors.has('AccountingFax')" class="text-danger text-sm">{{
              errors.first("AccountingFax") | text_formatter }}</span>
          </div>

          <div class="vx-col lg:w-1/4 md:w-1/2 w-full mb-4">
            <div class="vx-row mb-4">
              <div class="vx-col md:w-1/3 w-full md:pr-0">
                <vs-select autocomplete v-model="contacts.mobile[3].key" name="Accounting_mobile_key"
                  class="select-small w-full">
                  <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in mobile_keys"
                    class="lg:w-1/4 md:w-1/2 w-full" />
                </vs-select>
                <span v-if="issueSubmitted && errors.has('Accounting_mobile_key')" class="text-danger text-sm">{{
                  errors.first("Accounting_mobile_key") | text_formatter
                }}</span>
              </div>
              <div class="vx-col md:w-2/3 w-full md:pl-1 md:mt-0 mt-3">
                <vs-input name="AccountingMobile" v-validate="'numeric|length:8'" :placeholder="`${$t('main.Mobile')}`"
                  v-model="contacts.mobile[3].number" class="w-full"></vs-input>
                <span v-if="issueSubmitted && errors.has('AccountingMobile')" class="text-danger text-sm">{{
                  errors.first("AccountingMobile") | text_formatter }}</span>
              </div>
            </div>
          </div>
          <div class="vx-col lg:w-1/4 md:w-1/2 w-full mb-4">
            <vs-input name="AccountingEmail" v-validate="'email'" :placeholder="`${$t('main.Email')}`"
              v-model="contacts.email[3].email" class="w-full"></vs-input>
            <span v-if="issueSubmitted && errors.has('AccountingEmail')" class="text-danger text-sm">{{
              errors.first("AccountingEmail") | text_formatter }}</span>
          </div>
        </div>
        <!-- /Accounting -->
        <!-- Reception -->
        <div class="vx-row">
          <div class="vx-col mb-2 w-full">
            <label>
              {{ $t("InstitutionDetails." + contacts.tel[1].type) }}
            </label>
          </div>
          <div class="vx-col lg:w-1/4 md:w-1/2 w-full mb-4">
            <div class="vx-row mb-4">
              <div class="vx-col md:w-1/3 w-full md:pr-0">
                <vs-select autocomplete v-model="contacts.tel[1].key" name="Reception_tel_key"
                  class="select-small w-full">
                  <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in tel_keys"
                    class="lg:w-1/4 md:w-1/2 w-full" />
                </vs-select>
                <span v-if="issueSubmitted && errors.has('Reception_tel_key')" class="text-danger text-sm">{{
                  errors.first("Reception_tel_key") | text_formatter
                }}</span>
              </div>
              <div class="vx-col md:w-2/3 w-full md:pl-1 md:mt-0 mt-3">
                <vs-input name="ReceptionTel" v-validate="'numeric|length:8'" :placeholder="$t('main.Tel')"
                  v-model="contacts.tel[1].number" class="w-full"></vs-input>
                <span v-if="issueSubmitted && errors.has('ReceptionTel')" class="text-danger text-sm">{{
                  errors.first("ReceptionTel") | text_formatter }}</span>
              </div>
            </div>
          </div>
          <div class="vx-col lg:w-1/4 md:w-1/2 w-full mb-4">
            <vs-input name="ReceptionFax" v-validate="'min:2|max:15|regex:^[0-9\-\+]*$'" :placeholder="$t('main.Fax')"
              v-model="contacts.fax[1].number" class="w-full"></vs-input>
            <span v-if="issueSubmitted && errors.has('ReceptionFax')" class="text-danger text-sm">{{
              errors.first("ReceptionFax") | text_formatter }}</span>
          </div>
          <div class="vx-col lg:w-1/4 md:w-1/2 w-full mb-4">
            <div class="vx-row mb-4">
              <div class="vx-col md:w-1/3 w-full md:pr-0">
                <vs-select autocomplete v-model="contacts.mobile[1].key" name="ReceptionTelkey"
                  class="select-small w-full">
                  <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in mobile_keys"
                    class="w-full" />
                </vs-select>
                <span v-if="issueSubmitted && errors.has('ReceptionTelkey')" class="text-danger text-sm">{{
                  errors.first("ReceptionTelkey") | text_formatter }}</span>
              </div>
              <div class="vx-col md:w-2/3 w-full md:pl-1 md:mt-0 mt-3">
                <vs-input name="ReceptionMobile" v-validate="'numeric|length:8'" :placeholder="`${$t('main.Mobile')}`"
                  v-model="contacts.mobile[1].number" class="w-full"></vs-input>
                <span v-if="issueSubmitted && errors.has('ReceptionMobile')" class="text-danger text-sm">{{
                  errors.first("ReceptionMobile") | text_formatter }}</span>
              </div>
            </div>
          </div>
          <div class="vx-col lg:w-1/4 md:w-1/2 w-full mb-4">
            <vs-input name="ReceptionEmail" v-validate="'email'" :placeholder="$t('main.Email')"
              v-model="contacts.email[1].email" class="w-full"></vs-input>
            <span v-if="issueSubmitted && errors.has('ReceptionEmail')" class="text-danger text-sm">{{
              errors.first("ReceptionEmail") | text_formatter }}</span>
          </div>
        </div>
        <!-- /Reception -->
        <!-- Admission -->
        <div class="vx-row">
          <div class="vx-col mb-2 w-full">
            <label>
              {{ $t("InstitutionDetails." + contacts.tel[2].type) }}
            </label>
          </div>
          <div class="vx-col lg:w-1/4 md:w-1/2 w-full mb-4">
            <div class="vx-row mb-4">
              <div class="vx-col md:w-1/3 w-full md:pr-0">
                <vs-select autocomplete v-model="contacts.tel[2].key" name="AdmissionTelkey" class="select-small w-full">
                  <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in tel_keys"
                    class="w-full" />
                </vs-select>
                <span v-if="issueSubmitted && errors.has('AdmissionTelkey')" class="text-danger text-sm">{{
                  errors.first("AdmissionTelkey") | text_formatter }}</span>
              </div>
              <div class="vx-col md:w-2/3 w-full md:pl-1 md:mt-0 mt-3">
                <vs-input name="AdmissionTel" v-validate="'numeric|length:8'" :placeholder="`${$t('main.Tel')}`"
                  v-model="contacts.tel[2].number" class="w-full"></vs-input>
                <span v-if="issueSubmitted && errors.has('AdmissionTel')" class="text-danger text-sm">{{
                  errors.first("AdmissionTel") | text_formatter }}</span>
              </div>
            </div>
          </div>
          <div class="vx-col lg:w-1/4 md:w-1/2 w-full mb-4">
            <vs-input name="AdmissionFax" v-validate="'min:2|max:15|regex:^[0-9\-\+]*$'"
              :placeholder="`${$t('main.Fax')}`" v-model="contacts.fax[2].number" class="w-full"></vs-input>
            <span v-if="issueSubmitted && errors.has('AdmissionFax')" class="text-danger text-sm">{{
              errors.first("AdmissionFax") | text_formatter }}</span>
          </div>
          <div class="vx-col lg:w-1/4 md:w-1/2 w-full mb-4">
            <div class="vx-row mb-4">
              <div class="vx-col md:w-1/3 w-full md:pr-0">
                <vs-select autocomplete v-model="contacts.mobile[2].key" name="AdmissionTelkey"
                  class="select-small w-full">
                  <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in mobile_keys"
                    class="w-full" />
                </vs-select>
                <span v-if="issueSubmitted && errors.has('AdmissionTelkey')" class="text-danger text-sm">{{
                  errors.first("AdmissionTelkey") | text_formatter }}</span>
              </div>
              <div class="vx-col md:w-2/3 w-full md:pl-1 md:mt-0 mt-3">
                <vs-input name="AdmissionMobile" v-validate="'numeric|length:8'" :placeholder="`${$t('main.Mobile')}`"
                  v-model="contacts.mobile[2].number" class="w-full"></vs-input>
                <span v-if="issueSubmitted && errors.has('AdmissionMobile')" class="text-danger text-sm">{{
                  errors.first("AdmissionMobile") | text_formatter }}</span>
              </div>
            </div>
          </div>
          <div class="vx-col lg:w-1/4 md:w-1/2 w-full mb-4">
            <vs-input name="AdmissionEmail" v-validate="'email'" :placeholder="`${$t('main.Email')}`"
              v-model="contacts.email[2].email" class="w-full"></vs-input>
            <span v-if="issueSubmitted && errors.has('AdmissionEmail')" class="text-danger text-sm">{{
              errors.first("AdmissionEmail") | text_formatter }}</span>
          </div>
        </div>
        <!-- /Admission -->
        <!-------------------------- / Contacts -------------------------->
        <!-------------------------- Internet -------------------------->
        <vs-divider position="center">{{
          $t("InstitutionDetails.Internet")
        }}</vs-divider>

        <div class="vx-row mb-4">
          <div class="vx-col lg:w-1/3 md:w-1/2 w-full mb-4">
            <vs-input class="w-full" name="website" v-validate="'min:10|url'"
              :label="`${$t('InstitutionDetails.website')}`" v-model="website" type="url" />
            <span v-if="websiteError" class="text-danger text-sm">{{
              $t("form.please_enter_valid_website")
            }}</span>
            <span v-if="issueSubmitted && errors.has('website')" class="text-danger text-sm">{{ errors.first("website") |
              text_formatter }}</span>
          </div>
          <div class="vx-col lg:w-1/3 md:w-1/2 w-full mb-4">
            <vs-input class="w-full" :label="`${$t('InstitutionDetails.Facebook')}`" v-validate="'min:10|url'"
              name="Facebook" v-model="Facebook" type="url" />
            <span v-if="FacebookError" class="text-danger text-sm">{{
              $t("form.please_enter_valid_facebook_url")
            }}</span>

            <span v-if="issueSubmitted && errors.has('Facebook')" class="text-danger text-sm">{{ errors.first("Facebook")
              | text_formatter }}</span>
          </div>
          <div class="vx-col lg:w-1/3 md:w-full w-full mb-4">
            <vs-input class="w-full" :label="`${$t('InstitutionDetails.Instagram')}`" v-validate="'min:10|url'"
              name="Instagram" v-model="Instagram" type="url" />
            <span v-if="InstagramError" class="text-danger text-sm">{{
              $t("form.please_enter_valid_Instagram_url")
            }}</span>

            <span v-if="issueSubmitted && errors.has('Instagram')" class="text-danger text-sm">{{
              errors.first("Instagram") | text_formatter }}</span>
          </div>
        </div>
        <!-------------------------- / Internet -------------------------->
        <div class="vx-row">
          <div class="vx-col w-full pt-4 flex items-center justify-between">
            <vs-button @click="back()" color="warning" type="border">{{
              $t("InstitutionDetails.Cancel")
            }}</vs-button>

            <vs-button @click.stop="submitData()">{{
              $t("InstitutionDetails.Save")
            }}</vs-button>
          </div>
        </div>
      </form>
    </vs-card>
  </div>
</template>
<script>
import GoogleMapInfoWindow from '../components/GoogleMapInfoWindow.vue'
import { commonDataMixin, checkValidationMixin } from '@/mixins.js'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const BranchRepository = RepositoryFactory.get('ConfigurationModule', 'branch')
export default {
  name: 'Create',
  components: { GoogleMapInfoWindow },
  mixins: [commonDataMixin, checkValidationMixin],
  data() {
    return {
      mobile_keys: [],
      tel_keys: [],
      branchNameError: false,
      shortNamError: false,
      longNameError: false,
      FacebookError: false,
      InstagramError: false,
      websiteError: false,
      googleError: false,
      users: [],
      user: null,
      allNameData: { longName: null, ShortName: null, BrnchName: null },
      allAddressData: { desc: null },

      locales: this.$store.getters['main/locales'],
      error: '',
      lat: '',
      lon: '',
      logo: '',
      image: null,
      imageUrl: null,
      logoFile: null,
      contacts: {
        tel: [
          { key: '', number: '', type: this.$t('InstitutionDetails.General') },
          { key: null, number: '', type: this.$t('InstitutionDetails.Reception') },
          { key: null, number: '', type: this.$t('InstitutionDetails.Admission') },
          { key: null, number: '', type: this.$t('InstitutionDetails.Accounting') }
        ],
        fax: [
          { number: '', type: this.$t('InstitutionDetails.General') },
          { number: '', type: this.$t('InstitutionDetails.Reception') },
          { number: '', type: this.$t('InstitutionDetails.Admission') },
          { number: '', type: this.$t('InstitutionDetails.Accounting') }
        ],
        mobile: [
          { key: '', number: '', type: this.$t('InstitutionDetails.General') },
          { key: null, number: '', type: this.$t('InstitutionDetails.Reception') },
          { key: null, number: '', type: this.$t('InstitutionDetails.Admission') },
          { key: null, number: '', type: this.$t('InstitutionDetails.Accounting') }
        ],
        email: [
          { email: '', type: this.$t('InstitutionDetails.General') },
          { email: '', type: this.$t('InstitutionDetails.Reception') },
          { email: '', type: this.$t('InstitutionDetails.Admission') },
          { email: '', type: this.$t('InstitutionDetails.Accounting') }
        ]
      },
      loaded: false,
      markers: [{ position: { lat: null, lng: null }, infoText: 'Marker 1' }],
      website: null,
      Facebook: null,
      Instagram: null,
      AllData: {},
      currentPlace: null,
      branch_id: null,
      issueSubmitted: false,
      mapErrorAlert: false
    }
  },
  methods: {
    getUsers() {
      BranchRepository.getUsers().then((res) => {
        this.users = res.users
      })
    },
    updateLocation(loc) {
      if (loc) {
        this.mapErrorAlert = false
        const
          lat = parseFloat(loc.latLng.lat()),
          lng = parseFloat(loc.latLng.lng())

        this.markers[0].position.lat = lat
        this.markers[0].position.lng = lng
        this.currentPlace = loc
        if (this.loaded) this.autocompleteValue = `${lat},${lng}`
      } else {
        this.mapErrorAlert = true

      }

    },
    async setPlace(place) {

      if (place.geometry) {
        this.mapErrorAlert = false
        this.currentPlace = place
        this.markers[0].position.lat = parseFloat(place.geometry.location.lat())
        this.markers[0].position.lng = parseFloat(place.geometry.location.lng())
        if (await this.loaded) this.$refs.googleMap.center = this.markers[0].position
        this.autocompleteValue = this.currentPlace.formatted_address
      } else if (place.latLng) {
        this.mapErrorAlert = false
      } else {
        this.mapErrorAlert = true

      }


    },
    //for google map
    async googleAddress() {
      if (await this.loaded) this.loaded = false
      else {
        await (this.loaded = true)
        if (this.currentPlace) {
          if (this.currentPlace.formatted_address) this.setPlace(this.currentPlace)
          else this.updateLocation(this.currentPlace)


        } else if (!this.markers[0].position.lat) {
          /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          if (navigator.geolocation) if (await this.loaded) this.$refs.googleMap.getGoogleCurrent()
          else this.error = this.$t('form.geolocation_is_not_supported')
        }
      }
      return this.loaded
    },
    getGoogleCurrent() {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.markers[0].position.lat = parseFloat(position.coords.latitude)
          this.markers[0].position.lng = parseFloat(position.coords.longitude)
        },
        (error) => {
        }
      )
    },
    autoCompleteShowLatLng() {
      this.autocompleteValue = `${this.markers[0].position.lat},${this.markers[0].position.lng}`
    },

    //add data
    submitData() {
      this.issueSubmitted = true
      this.branchNameError = false
      this.shortNamError = false
      this.longNameError = false
      this.FacebookError = false
      this.InstagramError = false
      this.websiteError = false
      this.googleError = false

      this.$validator.validate()
        .then((valid) => {
          if (valid) {
            if (!this.markers[0].position.lat || !this.markers[0].position.lng) {
              this.errMsg(this.$t('InstitutionDetails.please_enter_he_GPS_Address'))
              return
            } else if (!this.logo) {
              this.errMsg(this.$t('InstitutionDetails.please_upload_the_logo_files'))
              return
            }
            this.AllBranchLocalesData()
            BranchRepository.editBranch(this.branch_id, this.AllData)
              .then(() => {
                this.successMsg(this.$t('InstitutionDetails.branch_has_been_edited_successfully'))
                this.$router.push({ name: 'BranchDetails' })

              },
                (error) => {
                  if (error?.errors) {

                    if (error.errors['en.short_name']) {
                      this.shortNamError = true
                    }
                    if (error.errors['en.branch_name']) {
                      this.branchNameError = true
                    }
                    if (error.errors['en.long_name']) {
                      this.longNameError = true
                    }
                    if (error.errors['website']) {
                      this.websiteError = true
                    }
                    if (error.errors.lat) {
                      this.googleError = true
                    }
                    if (error.errors.facebook) {
                      this.FacebookError = true
                    }
                    if (error.errors.instagram) {
                      this.InstagramError = true
                    }
                  }


                  this.errMsg(error)
                })
              .catch(this.errMsg)
          } else this.errMsg()
        })
        .catch(this.errMsg)
    },
    AllBranchLocalesData() {
      this.locales.forEach((key, locale) => {
        this.$set(this.AllData, key, {
          short_name: this.allNameData.ShortName,
          long_name: this.allNameData.longName,
          branch_name: this.allNameData.BrnchName,
          address: this.allAddressData.desc
        })
      })
      this.AllData['fr'] = {
        short_name: this.allNameData.ShortName,
        long_name: this.allNameData.longName,
        branch_name: this.allNameData.BrnchName,
        address: this.allAddressData.desc
      }
      this.$set(this.AllData, 'lat', this.markers[0].position.lat)
      this.$set(this.AllData, 'long', this.markers[0].position.lng)
      this.$set(this.AllData, 'contacts', this.contacts)
      this.$set(this.AllData, 'users', this.user)
      this.$set(this.AllData, 'website', this.website)
      this.$set(this.AllData, 'facebook', this.Facebook)
      this.$set(this.AllData, 'instagram', this.Instagram)
      const logo = this.logo
      this.$set(this.AllData, 'logo', logo)
    },
    showPosition(position) {
      this.markers[0].position.lat = position.coords.latitude
      this.markers[0].position.lng = position.coords.longitude
    },
    onChange(e) {
      const file = e.target.files[0]
      this.checkImageValid(file)
        .then(res => {
          this.image = file


          //////////////////////////////////////
          const formData = new FormData()
          formData.append('image', e.target.files[0])
          formData.append('directory', 'branch/logo')
          const config = {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
          //////////////////////////////

          const imageDara = this.logo
          formData.append('imageData', imageDara)
          BranchRepository.uploadeImage(formData, config)
            .then((response) => {
              this.logo = `branch/logo/${response.data}`
              this.imageUrl = URL.createObjectURL(file)
            })
        })
    },
    loadeBranch(branch_id) {
      this.VSLoading()
      BranchRepository.getBranch(branch_id)
        .then((res) => {

          // users
          this.user = res.data.users
          if (res.data.users) {
            if (Array.isArray(res.data.users)) this.users.push(...res.data.users)
            else this.users.push(res.data.users)
          }
          // Name
          this.allNameData.ShortName = res.data[this.current_locale]?.short_name
          this.allNameData.longName = res.data[this.current_locale]?.long_name
          this.allNameData.BrnchName = res.data[this.current_locale]?.branch_name

          this.contacts = res.data.contacts
          this.website = res.data.website
          this.Facebook = res.data.facebook
          this.Instagram = res.data.instagram

          // Logo
          this.logo = res.data.logo && res.data.logo.includes('branch/logo') ? res.data.logo : null
          this.imageUrl = this.logo ? res.data.imageURL : null

          // Address
          this.allAddressData.desc = res.data[this.current_locale]?.address
          if (res.data.lat && res.data.long) {
            this.markers[0].position.lat = res.data.lat
            this.markers[0].position.lng = res.data.long
            // this.loaded = true
            this.autoCompleteShowLatLng()

          }
          this.stopVSLoading()
        })
    },
    back() {
      this.$router.back()
    },
    // delete file
    deleteFile(url) {
      this.deleteDocumentFromS3({ file_path: url }).then(() => {
        url = null
      })
    }
  },
  computed: {
    coordinates() {
      return `${this.lat}, ${this.lng}`
    },
    autocompleteValue: {
      get() {
        return this.$refs.googleMap.locationText
      },
      async set(v) {
        if (await this.loaded) this.$refs.googleMap.locationText = v
        else this.$refs.googleMap.locationText = ''
      }
    }
  },
  created() {
    this.branch_id = this.$route.params.id
    this.getUsers()
    this.loadeBranch(this.branch_id)
    this.getTelKeys()
    this.getMobileKeys()
  },
  watch: {
    lat(v) {
      this.markers[0].position.lat = v
    },
    lon(v) {
      this.markers[0].position.lng = v
    },
    'this.markers'() {
      if (!this.currentPlace.formatted_address) this.autoCompleteShowLatLng()
    },
    'allNameData.longName'(v) {
      this.allNameData.BrnchName = v

    }
  }
}
</script>
<style  scoped>
a:active,
a:visited,
a:hover,
a {
  cursor: pointer;
  color: black;
}

.selectExample {
  margin-left: 9px;
}
</style>
