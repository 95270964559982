<template>
  <ListPageWithCreateButton
    createRoutName="create-academic-year"
    :showCreateBtn="checkUserRole('SMD')"
    :createBtnName="$t('AcademicYears.AcademicYearsCreate')">
    <template slot="listDataContent">
      <data-table
        :csv_url="'/academic/export'"
        :pdf_url="'/academic/pdf'"
        :headers_prop="headers"
        list_url_prop="/academic/academic-years">
      </data-table>
    </template>
  </ListPageWithCreateButton>
</template>

<script>
import DataTable from '@/components/general/datatable/DataTable2.vue'

export default {
  name: 'ListAcademicYear',
  components: {DataTable},

  data() {
    return {
      headers: [
        {
          minWidth: 160,
          headerName: this.$t('AcademicYears.AcademicYear'),
          field: 'name',
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true
        },

        {
          minWidth: 160,
          headerName: this.$t('AcademicYears.DateOfCreation'),
          field: 'created_at'
        },
        {
          minWidth: 160,
          headerName: this.$t('subject.Status'),
          field: 'status',
          width: 240,
          cellRendererFramework: 'CellRendererStatus',
          cellRendererParams: {
            status_prop: this.status
          }
        },
        {
          minWidth: 160,
          headerName: this.$t('AcademicYears.Actions'),
          field: 'transactions',
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            actions_prop: [
              {
                dropDown: true,
                dropDown_items: [
                  {
                    showIf: (data) =>
                      this.checkUserRole('SMD') &&
                      data.status !== 'Active' &&
                      data.status !== 'Closed',
                    title: this.$t('basic.edit'),
                    route_name: 'edit-academic-year',
                    resource: 'academic/academic-years',
                    getParams: (data) => ({id: data._id}),
                    icon: 'Edit3Icon',
                    navigate_action: true
                  },
                  {
                    showIf: (data) => this.checkUserRole('SMD'),
                    title: this.$t('basic.show'),
                    route_name: 'show-academic-year',
                    getParams: (data) => ({id: data._id}),
                    icon: 'EyeIcon',
                    navigate_action: true
                  },
                  {
                    title: this.$t('AcademicYears.go_to_sub'),
                    route_name: 'subAcademicYearsList',
                    getParams: (data) => ({id: data._id}),
                    icon: 'ArrowRightIcon',
                    navigate_action: true
                  },

                  {
                    showIf: (data) =>
                      data.status === 'New' && this.checkUserRole('SMD'),
                    title: this.$t('basic.delete'),
                    requestType: 'delete',
                    resource: (data) => `academic/academic-years/${data._id}`,
                    action: 'delete',
                    msg: this.$t('AcademicYears.delete_msg'),
                    icon: 'XIcon',
                    color: 'danger'
                  }
                ]
              }
            ]
          }
        }
      ]
    }
  }
}
</script>
