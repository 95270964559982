<template>
  <ListPageWithCreateButton
    :createRoute="{
      name: 'create-canteen-category',
      params: {id: this.$route.params.id}
    }"
    :showCreateBtn="checkUserRole('SND')"
    :createBtnName="$t('canteen.createCategory')">
    <template slot="listDataContent">
      <CanteenUserTable
        :headers_prop="headers"
        :list_url_prop="`canteen/${$route.params.id}/category`"
        :loading="loading" />
    </template>
  </ListPageWithCreateButton>
</template>

<script>
import CanteenUserTable from '@/components/general/datatable/DataTable2.vue'

import {commonDataMixin} from '@/mixins'

export default {
  components: {
    CanteenUserTable
  },
  mixins: [commonDataMixin],
  data() {
    return {
      headers: [
        {
          minWidth: 170,
          headerName: this.$t('basic.code'),
          field: 'code'
        },
        {
          minWidth: 250,
          headerName: this.$t('canteen.category'),
          field: 'name'
        },
        {
          minWidth: 160,
          headerName: this.$t('canteen.items'),
          field: 'canteen_items_count'
        },
        {
          minWidth: 95,
          headerName: this.$t('basic.status'),
          field: 'status',
          cellRendererFramework: 'CellRendererStatus'
        },
        {
          minWidth: 120,
          headerName: this.$t('basic.actions'),
          filter: false,
          field: 'actions',

          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            actions_prop: [
              {
                dropDown: true,
                dropDown_items: [
                  {
                    title: this.$t('canteen.addItem'),
                    parameter_name: 'id',
                    icon: 'PlusIcon',
                    navigate_action: true,
                    route_name: 'create-category-item',
                    resource: '/canteen/create-category-item/:id'
                  },
                  {
                    title: this.$t('basic.update'),
                    icon: 'Edit3Icon',
                    actionEmitter: 'goToEdit',
                    actionEmitterParams: ['id']
                  },
                  {
                    title: this.$t('canteen.showItems'),
                    getParams: (data) => ({item_id: data.id}),
                    icon: 'EyeIcon',
                    navigate_action: true,
                    route_name: 'show-category-item'
                  },
                  {
                    title: this.$t('basic.archive'),
                    parameter_name: 'id',
                    icon: 'ArchiveIcon',
                    action: 'Archived',
                    resource: `canteen/${this.$route.params.id}/category/change-status/:id`,
                    requestType: 'post',
                    requestPayload: {status: 'archive'},
                    showIf: (data) => data.status !== 'Archived'
                  }
                ]
              }
            ]
          }
        }
      ],
      searchResult: [
        {
          code: 5,
          category_code: 1,
          category_name: 'category One',
          category_items: 20,
          status: 'Active'
        }
      ],
      loading: false
    }
  },
  methods: {
    goToEdit(categoryId) {
      this.$router.push({
        name: 'edit-canteen-category',
        params: {id: categoryId},
        query: {canteen_id: this.$route.params.id}
      })
    }
  },
  watch: {},
  computed: {},
  beforeDestroy() {
    this.$root.$off('goToEdit')
  },
  created() {
    this.$root.$on('goToEdit', (data) => this.goToEdit(data.id))
  }
}
</script>

<style lang="scss" scoped>
.active .vx-card {
  -webkit-box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
  box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
}
</style>
