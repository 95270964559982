<template>
  <div>
    <roles-form :role_prop.sync="role" :edit_id="edit_role_id"></roles-form>
  </div>
</template>
<script>
import RolesForm from './components/RolesForm'

export default {
  components:{
    RolesForm

  },
  created () {
    this.edit_role_id = this.$route.params.id
  },
  data () {
    return {
      edit_role_id:null,
      role:{
        schools:[],
        // branchs:[],
        divisions:[],
        sections:[],
        permissions:[],
        name:'',
        description:null,
        user_type:null
      }
    }
  }
}
</script>
