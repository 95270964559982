
<template>
  <div id="list_datatable" class="w-full">
    <div class="vx-card p-4 mb-3 px-6"  v-for="(day, key) in allDays" :key="key"  @click="goToDetaildDay(day)">
      <div class=" flex justify-between items-center">
        <p>
          <feather-icon
            icon="CalendarIcon"
            class="mr-4"
        />
          <span> {{Object.keys(day)[0]}} </span>
        </p>
        <span>
           <feather-icon
             icon="EyeIcon"
             class="text-primary"
             @click="goToDetaildDay(day)"
           />
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'

const ParentRepository = RepositoryFactory.get('ParentRepository', 'parent')
export default {
  name: 'myDayParent',
  props:{
    student: {
      type: Object,
      required: true
    }
  },
  components: {

  },
  created () {
    this.getDataOfDays()
  },
  data () {
    return {
      allDays: []
    }
  },

  computed: {
    userId () {
      return this.student.user_id || this.$route.params.user_id
    }
  },
  watch: {

  },
  methods: {
    getDataOfDays () {
      this.VSLoading()
      ParentRepository.getMyDayData(this.userId).then(res => {
        console.log(res)
        this.allDays = res
      }).finally(() =>  this.stopVSLoading())
    },
    goToDetaildDay (day) {
      localStorage.setItem('studentDay', this.student.user_id)
      this.$router.push({name: 'student_myDay_details',   params: {details: { day:day , student: this.student} }})
    }
  }
}
</script>
