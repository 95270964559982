<template>
  <div>
    <!--  Filters -->
    <vx-card
      class="mb-10"
      :title="`${$t('basic.filters')}`"
      v-if="showTable"
      collapseAction>
      <div class="vx-row items-center">
        <div
          class="vx-col w-full"
          :class="hasFiltersData ? 'lg:w-1/2 md:w-2/3' : 'md:w-1/2'">
          <date-filter
            @refilter="dateRefilter"
            :dates.sync="dateFilter"
            :show-label="false" />
        </div>

        <v-select
          :options="subjects"
          label="name"
          v-model="selectedSubject"
          class="vx-col w-full md:mb-0 mb-4"
          :placeholder="this.$t('form.choose_subject')"
          :clearable="false"
          :showLoadingIcon="loadingSubjects"
          :disabled="loadingTable"
          :class="hasFiltersData ? 'lg:w-1/4 md:w-1/3 ' : 'md:w-1/3'" />
        <div
          class="vx-col flex w-full items-center justify-between"
          :class="hasFiltersData ? 'lg:w-1/4 lg:mt-0 md:mt-4' : 'md:w-1/6'">
          <vs-button
            @click="resetFilters"
            class=""
            :disabled="loadingTable"
            type="border"
            v-if="hasFiltersData">
            {{ $t('form.reset') }}
          </vs-button>
          <vs-button
            @click="refilter"
            class="mr-0 ml-auto"
            :disabled="loadingTable">
            {{ $t('form.done') }}
          </vs-button>
        </div>
      </div>
    </vx-card>
    <!-- Table -->
    <vx-card class="mb-10">
      <vs-table :data="data" id="gradebook" :hoverFlat="true" v-if="showTable">
        <template slot="header">
          <vs-row class="items-center">
            <vs-col
              s-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="3"
              vs-sm="12">
              <h4 class="mb-2">{{ selectedClass.name }}</h4>
            </vs-col>
            <!-- Filters resources -->
            <vs-col
              s-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="5"
              vs-sm="12"
              class="smd:my-0 my-3">
              <template v-if="!loadingTable">
                <div
                  class="flex flex-wrap lg:flex-no-wrap items-center lg:justify-center lg:px-3">
                  <!-- <vs-checkbox
                  v-for="(filter, i) in resourcesTypes"
                  :key="i"
                  :vs-value="filter"
                  v-model="selectedResourceFilter"
                  class="mb-2 w-auto"
                  >{{ filter.name }} ({{ filter.count || 0 }})
                </vs-checkbox> -->
                  <!-- <vs-checkbox
                  v-if="showSelectedAllTypesFilter"
                  v-model="selectedAllTypesFilter"
                  class="mb-2 lg:w-auto md:w-full md:block"
                  >{{ $t("basic.all") }} ({{ allResources.length || 0 }})
                </vs-checkbox> -->
                  <label class="mr-4">{{ $t('basic.show') }} : </label>
                  <v-select
                    :options="modifiedResourcesTypes"
                    label="title_with_count"
                    v-model="selectedResourceFilter"
                    class="lg:mb-0 mb-2 lg:w-2/3 w-full"
                    @input="handleTableData()"
                    :showLoadingIcon="loadTypes" />
                </div>
              </template>
            </vs-col>
            <!-- Search && actions -->
            <vs-col s-type="flex" vs-lg="4" vs-sm="12">
              <div class="flex items-center lg:justify-end">
                <vs-input
                  class="lg:w-5/6 w-full lg:mr-2"
                  :placeholder="$t('form.search_by_student_name_or_ID')"
                  v-model="searchContent"
                  @keyup="searchTable"
                  v-if="Boolean(data.length)" />
                <vs-dropdown
                  vs-trigger-click
                  class="cursor-pointer"
                  :class="{actionButtons: windowWidth <= 900}">
                  <vx-tooltip
                    :text="$t('basic.actions')"
                    v-if="windowWidth <= 900">
                    <!-- <span>{{ $t("basic.actions") }}</span> -->
                    <feather-icon
                      :icon="'SettingsIcon'"
                      svgClasses="h-5 w-5 hover:text-primary cursor-pointer"></feather-icon>
                    <!-- <vs-button
                      radius
                      color="#999"
                      type="flat"
                      icon="more_vert"
                    ></vs-button> -->
                  </vx-tooltip>
                  <div
                    v-else
                    class="p-3 rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium">
                    <span class="mr-2 leading-none text-sm">{{
                      $t('basic.actions')
                    }}</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </div>
                  <vs-dropdown-menu>
                    <!-- Add Assessment Grade -->
                    <vs-dropdown-item @click="activeAddNewGradePopup = true">
                      <span class="flex items-center">
                        <feather-icon
                          icon="PlusCircleIcon"
                          svgClasses="h-4 w-4"
                          class="mr-2" />
                        <span>{{ $t('grading.add_assessment_grade') }}</span>
                      </span>
                    </vs-dropdown-item>
                    <template v-if="data.length">
                      <!-- Summery  -->
                      <vs-dropdown-item @click="activeSummeryPopup">
                        <span class="flex items-center">
                          <feather-icon
                            :icon="'BarChart2Icon'"
                            svgClasses="h-4 w-4"
                            class="mr-2" />
                          <span>{{ $t('grading.summery') }}</span>
                        </span>
                      </vs-dropdown-item>
                      <!-- CSV -->
                      <vs-dropdown-item
                        @click="exportCSV($t('grading.gradebook'))">
                        <span class="flex items-center">
                          <feather-icon
                            icon="SaveIcon"
                            svgClasses="h-4 w-4"
                            class="mr-2" />
                          <span>CSV</span>
                        </span>
                      </vs-dropdown-item>
                    </template>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </div>
            </vs-col>
          </vs-row>
        </template>
        <!-- Header -->
        <template slot="thead" v-if="Boolean(data.length)">
          <vs-th
            v-for="(tr, indextr) in headers"
            :key="indextr"
            :sort-key="tr.field"
            :class="{
              table__header: !tr.field.includes('empty'),
              'table__header--empty': tr.field.includes('empty'),
              'text-right': tr.field === 'name',
              'text-center': tr.field !== 'name'
            }"
            class="text-xs">
            <vx-tooltip
              v-if="!tr.field.includes('empty')"
              :text="tr.title || '-' | text_formatter">
              <div :class="{truncate: tr.field !== 'name'}">
                <span>
                  {{ tr.title || '--' | text_formatter }}
                </span>
              </div>
            </vx-tooltip>
            <!-- resource Actions -->
            <vx-tooltip
              :text="$t('basic.actions')"
              class="resource-actions"
              v-if="
                tr.field && !tr.field.includes('empty') && tr.field !== 'name'
              ">
              <vs-dropdown vs-trigger-click class="cursor-pointer">
                <feather-icon
                  :icon="'MoreVerticalIcon'"
                  svgClasses="h-5 w-5 hover:text-primary cursor-pointer"></feather-icon>

                <vs-dropdown-menu>
                  <vs-dropdown-item @click="hideResource(tr.field)">
                    <span class="flex items-center">
                      <feather-icon
                        icon="EyeOffIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2" />
                      <span>{{ $t('grading.hide_from_gradebook') }}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="updateGrade(tr.field)">
                    <span class="flex items-center">
                      <feather-icon
                        icon="EditIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2" />
                      <span>{{ $t('grading.update_grades') }}</span>
                    </span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </vx-tooltip>
          </vs-th>
        </template>
        <!-- Rows -->
        <template slot-scope="{data}" v-if="Boolean(data.length)">
          <vs-tr
            v-for="(tr, indextr) in data"
            :data="tr"
            :key="indextr"
            :class="{'resource-info-row': Boolean(tr.resourceInfo_field)}"
            class="text-xs">
            <vs-td
              v-for="(row_data, field, indx) in tr"
              :key="indx"
              :class="setCellClass(tr, field, indextr, row_data)"
              class="text-xs">
              <!-- Data -->
              <template v-if="field === 'name'">
                <vx-tooltip :text="row_data">
                  {{ row_data.split(' ').slice(0, 2).join(' ') }}
                </vx-tooltip>
              </template>
              <template v-else>
                <span>
                  {{ row_data }}
                </span>
              </template>
            </vs-td>

            <!-- student data action col -->
            <vs-td v-if="!tr.resourceInfo_field" class="text-center">
              <vx-tooltip :text="$t('basic.actions')" class="text-center">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                  <feather-icon
                    :icon="'MoreVerticalIcon'"
                    svgClasses="h-5 w-5 hover:text-primary cursor-pointer"></feather-icon>

                  <vs-dropdown-menu>
                    <!-- Chat with Student  -->
                    <vs-dropdown-item @click="goToChat(tr, indextr)">
                      <span class="flex items-center">
                        <feather-icon
                          icon="MessageSquareIcon"
                          svgClasses="h-4 w-4"
                          class="mr-2" />
                        <span>{{ $t('basic.chat') }}</span>
                      </span>
                    </vs-dropdown-item>
                    <!-- Update student scores -->
                    <vs-dropdown-item
                      @click="UpdateStudentScorePopup(tr, indextr)">
                      <span class="flex items-center">
                        <feather-icon
                          icon="Edit2Icon"
                          svgClasses="h-4 w-4"
                          class="mr-2" />
                        <span>{{ $t('grading.update_student_card') }}</span>
                      </span>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vx-tooltip>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <template v-else>
        <p v-if="!selectedClass.id" class="text-center">
          {{ $t('grading.select_the_class_first') }}
        </p>
      </template>
      <!-- Loader -->
      <div
        ref="tableLoader"
        id="tableLoader"
        class="vs-con-loading__container"
        v-if="loadingTable"></div>
    </vx-card>
    <!-- Popups -->
    <template>
      <!-- Summery Popup -->
      <vs-popup
        :title="`${selectedClass.name} - ${$t('grading.summery')}`"
        :active.sync="summeryPopup"
        fullscreen
        id="summery-popup"
        v-if="summeryPopup">
        <label>{{ $t('form.choose_subject') }}</label>
        <v-select
          label="name"
          v-model="selectedSubject"
          :options="resourcesSubjects"
          :disabled="loadingTable" />
        <summeryTable :selectedSubject="selectedSubject" />
      </vs-popup>

      <!-- Update grades Popup -->
      <vs-prompt
        :title="`${selectedClass.name} - ${$t('basic.update')} ${$t(
          'grading.grades'
        )}`"
        :active.sync="activeUpdateGradePopup"
        :buttons-hidden="true"
        type="confirm"
        class="fullscreen">
        <div class="con-exemple-prompt">
          <!-- TODO: justify-between in  arrows case -->
          <div class="flex mb-6">
            <!--            <vs-button-->
            <!--              radius-->
            <!--              color="primary"-->
            <!--              type="border"-->
            <!--              icon="keyboard_arrow_left"-->
            <!--              :disabled="selectedResource.seq === 1"-->
            <!--              @click.stop="getPrev('resource')"-->
            <!--            ></vs-button>-->

            <div class="w-full">
              <h4 class="text-center">
                {{ selectedResource.name }}
                <small class="block text-sm mt-2 opacity-75"
                  >({{ selectedResource.type }})
                </small>
              </h4>
            </div>
            <!--            <vs-button-->
            <!--              radius-->
            <!--              color="primary"-->
            <!--              type="border"-->
            <!--              icon="keyboard_arrow_right"-->
            <!--              :disabled="selectedResource.seq === allResources.length"-->
            <!--              @click.stop="getNext('resource')"-->
            <!--            ></vs-button>-->
          </div>
          <update-assessment-scores
            :selectedResource="selectedResource"
            :gradings="gradings"
            :students="students"
            @closeUpdateGrades="closeUpdateGrades"
            @reloadGrades="getGradingsOfClass" />
        </div>
      </vs-prompt>

      <!--  Manual Assessment -->
      <vs-prompt
        :title="`${selectedClass.name} - ${
          isUpdateManualResource
            ? $t('grading.update_assessment_grade')
            : $t('grading.add_assessment_grade')
        }`"
        :active.sync="activeAddNewGradePopup"
        :buttons-hidden="true"
        type="confirm"
        class="fullscreen">
        <div class="con-exemple-prompt">
          <add-assessment-grade
            :students="students"
            :resource="selectedManualResource"
            @closeAddGrade="closeManualAssessment"
            @reloadGrades="getGradingsOfClass" />
        </div>
      </vs-prompt>

      <!-- Update Student Scores -->
      <!-- <vs-prompt
        :title="`${selectedClass.name} - ${$t('basic.update')} ${$t(
          'grading.grades'
        )}`"
        :active.sync="activeUpdateStudentScorePopup"
        :buttons-hidden="true"
        type="confirm"
        class="fullscreen"
      >
        <div class="con-exmple-prompt">
          <!-- Student info  --
          <div class="flex justify-between mb-6">
            <vs-button
              radius
              color="primary"
              type="border"
              icon="keyboard_arrow_left"
              :disabled="selectedStudent.seq === 1"
              @click.stop="getPrev('student')"
            ></vs-button>

            <div class="text-center">
              <h4>
                <span class="mr-4"> {{ selectedStudent.name }}</span>
                <small class="opacity-75 block my-2">
                  {{ $t("basic.ID") }} : {{ selectedStudent.student_id }}</small
                >
              </h4>
              <p class="status2--success block w-auto mx-auto">
                {{ $t("grading.total_score") | text_formatter }} :
                {{ selectedStudent.total_score }}
              </p>
            </div>
            <vs-button
              radius
              color="primary"
              type="border"
              icon="keyboard_arrow_right"
              :disabled="selectedStudent.seq === students.length"
              @click.stop="getNext('student')"
            ></vs-button>
          </div>
          <update-student-scores
            :selectedStudent="selectedStudent"
            @closeUpdateStudentGrades="closeUpdateStudentScorePopup"
            @reloadGrades="getGradingsOfClass"
          />
        </div>
      </vs-prompt> -->

      <vs-prompt
        :active.sync="activeUpdateStudentScorePopup"
        :buttons-hidden="true"
        :title="`${$t('grading.update_student_card')}`">
        <div class="con-exemple-prompt">
          <div class="p-5">
            <h4 v-if="!isRoundActive" class="text-danger text-center">
              <span> {{ $t('grading.no_active_round_is_available_yet') }}</span>
            </h4>
          </div>
        </div>
      </vs-prompt>
    </template>
  </div>
</template>
<script>
// mixins
import {commonDataMixin} from '@/mixins.js'
import gradingMixins from '../../grading.mixins'

// Repos
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const GradebookRepo = RepositoryFactory.get('GradingModule', 'gradebook'),
  GradingRoundRepo = RepositoryFactory.get('GradingModule', 'rounds')

// plugins
import moment from 'moment'

// components
import summeryTable from './SummeryTable'
import addAssessmentGrade from '../AddAssessmentGrade'
import UpdateAssessmentScores from '../UpdateAssessmentScores'
import UpdateStudentScores from '../UpdateStudentScores'
import DateFilter from '../../../../components/general/DateFilter'

export default {
  name: 'gradebookTable',

  components: {
    summeryTable,
    addAssessmentGrade,
    UpdateAssessmentScores,
    UpdateStudentScores,
    DateFilter
  },
  mixins: [commonDataMixin, gradingMixins],
  data() {
    return {
      /////////////////////
      //  Table
      /////////////////////
      headers: [
        {
          title: 'ID',
          field: 'student_id'
        },
        {
          title: 'name',
          field: 'name'
        }
      ],
      resourcesRows: [],
      studentsRows: [],
      originalData: [],
      data: [],
      loadingTable: false,
      /////////////////////
      // search
      /////////////////////
      searchContent: '',
      searchResultData: [],
      /////////////////////
      // Data
      /////////////////////
      gradings: [],
      students: [],
      resourcesSubjects: [],
      resourcesSubjectsDetails: [],
      loadingSubjects: false,
      /////////////////////
      // Summery
      /////////////////////
      summeryPopup: false,

      /////////////////////
      // update grade
      /////////////////////
      activeUpdateGradePopup: false,
      selectedResource: {},
      selectedManualResource: {},
      /////////////////////
      // add grade
      /////////////////////
      activeAddNewGradePopup: false,
      ////////////////////////
      // Update student grade
      ////////////////////////
      activeUpdateStudentScorePopup: false,
      /////////////////////
      // Filters
      /////////////////////
      dateFilter: {},
      selectedResourceFilter: {},
      selectedAllTypesFilter: true,
      modifiedResourcesTypes: []
    }
  },
  watch: {
    // selectedResourceFilter: {
    //   handler (v) {
    //     this.handleTableData()
    //     if (v && v.length === this.resourcesTypes.length) this.selectedAllTypesFilter = true
    //   },
    //   deep: true
    // },
    // 'selectedSubjectId' (v) {
    //   this.getGradingsOfClass()
    //   // this.$store.commit('setSelectedSubjectId', v)
    //   this.checkRoundOfClass()

    // },
    selectedSubject(v) {
      this.selectedSubjectId = v.id || null
    },
    'selectedClass.id'(v) {
      this.resetAllData()
      if (v) {
        this.selectedSubject = {}
        this.selectedSubjectId = null
        this.getClassSubjects()
      }
    },
    searchContent(v) {
      if (!v) this.data = this.originalData
    },

    summeryPopup(v) {
      if (!v) this.selectedSubject = {}
    },
    activeUpdateStudentScorePopup(v) {
      if (!v) this.closeUpdateStudentScorePopup()
    }
  },
  computed: {
    showTable() {
      return Boolean(this.selectedClass) && Boolean(this.selectedClass.id)
    },

    isUpdateManualResource() {
      return this.selectedManualResource.id
    },
    hasFiltersData() {
      return (
        Boolean(this.dateFilter.start_date) ||
        Boolean(this.dateFilter.end_date) ||
        Boolean(this.selectedSubjectId)
      )
    },

    showSelectedAllTypesFilter() {
      const selected = this.selectedResourceFilter
      return selected && selected.length === this.resourcesTypes.length
    },
    isRoundActive: {
      get() {
        return Boolean(this.activeRound.id)
      },
      set(v) {}
    }
  },
  methods: {
    /////////////////////////
    // Loading
    /////////////////////////
    // Table
    async startLoadingTable() {
      await (this.loadingTable = true)
      this.VsLoadingContained(this.$refs.tableLoader)
    },
    endLoadingTable() {
      this.stopVsLoadingContained(this.$refs.tableLoader)
      this.loadingTable = false
    },

    /////////////////////////
    // APIs
    /////////////////////////
    // getGradesB of Class
    getGradingsOfClass() {
      // ---- Abort
      if (!this.selectedClass?.id) return

      // --- Continue
      this.data = this.headers = []
      this.startLoadingTable()
      const payload = {
        ...this.dateFilter,
        subject_id: this.selectedSubjectId
      }
      GradebookRepo.getGradingsOfClass(this.selectedClass.id, payload)
        .then((res) => {
          this.handleAllData(res)
        })
        .catch(this.errMsg)
        .finally(this.endLoadingTable)
    },
    // Hide Grade
    async changeGradeStatus(resource, hideFor = null, status = null) {
      const payload = {
        resource_type:
          hideFor === 'student' ? resource.gradeType : resource.typeFlag,
        resource_id: Number(resource.id),
        status: status || 'hidden'
      }

      // --- Continue
      this.startLoadingTable()
      await GradebookRepo.changeGradeStatus(payload)
        .then((res) => {
          this.successMsg(this.$t('form.saved_successfully'))

          this.getGradingsOfClass()
        })
        .catch(this.errMsg)
        .finally(this.endLoadingTable)
    },
    //get class subjects
    getClassSubjects() {
      if (this.selectedClass.id) {
        this.resetSubjects()
        this.loadingSubjects = true
        this.getSubjectOfClass(this.selectedClass.id)
          .then((res) => {
            const data = res.data || res
            this.handleSubjectsData(data)
            this.refilter()
          })
          .finally(() => {
            this.loadingSubjects = false
          })
      }
    },
    checkRoundOfClass() {
      // ---- Abort
      if (!this.selectedClass?.id) return
      this.resetActiveRound()
      GradingRoundRepo.checkRoundOfClass(
        this.selectedClass.id,
        this.selectedSubjectId
      ).then((res) => {
        const hasRound = Boolean(
          res.status !== 'error' && res.data !== 'not exist'
        )

        this.$store.commit('setActiveRound', hasRound ? res.data : {})
      })
    },
    /////////////////////////
    // Table Helpers
    /////////////////////////
    generateResourcesRow(key, arr, extraText = '') {
      if (!arr.length) return
      const obj = {}
      arr.map((r) => {
        obj[`${r.typeFlag}_${r.id}${extraText ? `_${extraText}` : ''}`] = r[key]
      })
      return obj
    },

    /////////////////////////
    // Reset Data
    /////////////////////////
    resetTableData() {
      this.headers = []
      this.data = []
      this.originalData = []
      this.studentsRows = []
      this.resourcesRows = []
      this.modifiedResourcesTypes = []
    },
    resetAllData() {
      this.resetTableData()
      this.resetMainData()
      this.resourcesSubjects = []
      this.resourcesSubjectsDetails = []
      this.selectedResourceFilter = {}
      this.gradeBookTableData = {}
    },
    resetActiveRound() {
      this.$store.commit('setActiveRound', {})
      this.isRoundActive = false
    },
    /////////////////////////
    // Handling
    /////////////////////////
    handleAllData(data) {
      this.allData = data
      // Resources
      this.exams = this.allData?.exam || []
      this.assignments = this.allData?.assignment || []
      this.assessments = this.allData?.assessment || []
      this.otherTypesGrades = this.allData?.grading_types_details || []
      this.gradings = this.allData?.gradings || []

      this.resourcesTypes.map((f) => {
        if (data[f.value || f.key])
          f.count = data[f.value || f.key]?.length || 0
        else
          f.count =
            this.otherTypesGrades.filter((e) => e.type.key === f.key)?.length ||
            0
        f.title_with_count = `${f.name} (${f.count})`
      })

      // Students
      this.students =
        this.allData?.students?.sort((a, b) => a.code > b.code) || []
      this.students.map((s, i) => {
        s.seq = i + 1
      })
      this.$store.commit('setStudentsOfSelectedClass', this.students)
      this.gradeBookTableData.exams = this.exams
      this.gradeBookTableData.assignments = this.assignments
      this.gradeBookTableData.assessments = this.assessments
      this.gradeBookTableData.otherTypesGrades = this.otherTypesGrades

      this.handleTableData()
    },
    //  Resources
    handleResourcesInfo() {
      const resources = [],
        commonProps = (e) => ({
          dateWithoutTime: e.date.split(' ')[0],
          allCriterias: this.handleCriteria(e.criteria),
          allCriteriasKeys:
            e.criteria
              ?.map((c) => c.key)
              .join('-')
              .toUpperCase() || '-',
          subjectName: e.subject.name,
          subjectId: e.subject.id,
          dateFormatted: new moment(e.date).format('D-MMM')
        })
      // Assignments
      if (this.assignments?.length)
        resources.push(
          ...this.assignments.map((e) => {
            e.typeFlag = 'assignment'
            e.type = this.$t('basic.assignment')
            e.typeShort = 'Assi'
            e.gradeType = 'assess_assignment_task'
            const common = commonProps(e)
            e = Object.assign(common, e)
            return e
          })
        )
      // Tasks
      if (this.assessments?.length)
        resources.push(
          ...this.assessments.map((e) => {
            e.typeFlag = 'assessment'
            e.type = this.$t('assessments.assessment')
            e.typeShort = 'Asse'
            e.gradeType = 'assess_assessment_task'
            const common = commonProps(e)
            e = Object.assign(common, e)
            return e
          })
        )
      // Exams
      if (this.exams?.length)
        resources.push(
          ...this.exams.map((e) => {
            //e.exam_type === 'normal' ? 'mid_exam' : e.exam_type
            e.typeFlag = 'exam'
            e.type = this.$t('basic.exam')
            e.typeShort = `E ( ${e.exam_type} )`
            e.gradeType = 'exam_student'
            const common = commonProps(e)
            e = Object.assign(common, e)
            return e
          })
        )
      // Others Assessments
      if (this.otherTypesGrades?.length)
        resources.push(
          ...Array.from(
            this.otherTypesGrades.map((e) => {
              e.typeObj = e.typeObj || e.type
              e.typeFlag =
                e.typeObj.key === 'assignment' ? 'assig_manual' : e.typeObj.key
              e.type = `${e.typeObj.name}`
              e.typeShort = e.typeObj.name
              e.gradeType = 'other_type'
              e.status = 'manuel_grading'
              const common = commonProps(e)
              e = Object.assign(common, e)
              return e
            })
          )
        )
      this.allResources = resources.sort((a, b) =>
        new moment(a.date).diff(b.date)
      )
      // TODO Need More Enhancement
      this.resourcesSubjects = this.allResources
        .map((e) => e.subject)
        .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i)
      if (!this.subjects.length) this.handleSubjectsData(this.resourcesSubjects)
      // this.resourcesSubjects.map(s => {
      //   s.count =
      //     this.allResources.filter(r => r.subject.id === s.id)?.length || 0
      //   s.title_with_count = `${s.name} (${s.count})`
      // })
      this.allResources.map((r, i) => {
        r.seq = i + 1
      })
      this.handleResourcesTypes()
      return this.allResources
    },

    handleResourcesInfoRow(resourceType = null, subject = null) {
      const resourcesMatched = this.filterResources(resourceType, subject),
        resourcesInfo = resourcesMatched || [],
        rightEmptyCells = this.emptyCells(3, true),
        leftEmptyCells = this.emptyCells(1),
        resourcesHeader = {
          ...leftEmptyCells,
          resourceInfo_field: this.$t('basic.name'),
          ...this.generateResourcesRow('name', resourcesInfo),
          ...rightEmptyCells
        },
        resourcesHeaderArr = () => {
          const arr = []
          for (const key in resourcesHeader) {
            if (Object.hasOwnProperty.call(resourcesHeader, key)) {
              const el = resourcesHeader[key]
              arr.push({
                title: el,
                field: key.includes('resourceInfo_field')
                  ? 'name'
                  : `${key}_score`
              })
            }
          }
          return arr
        },
        lastResourceLeftHeaders = () => {
          let obj = {}
          if (!this.isMYPSection) obj.id_field = this.$t('basic.ID')
          else obj = leftEmptyCells
          return obj
        },
        lastResourceRightHeaders = () => {
          let obj = {}
          if (!this.isMYPSection) {
            obj.rank_field = this.$t('grading.rank')
            obj.totalScore_field = this.$t('grading.total')
            obj.more_field = this.$t('basic.more')
          } else obj = rightEmptyCells
          return obj
        },
        resourcesInfoTable = [
          // name row
          // ...resourcesHeader,
          // Date row
          {
            ...leftEmptyCells,
            resourceInfo_field: this.$t('basic.date'),
            ...this.generateResourcesRow('dateFormatted', resourcesInfo),
            ...rightEmptyCells
          },
          // Type row
          {
            ...leftEmptyCells,
            resourceInfo_field: this.$t('basic.type'),
            ...this.generateResourcesRow('typeShort', resourcesInfo),
            ...rightEmptyCells
          },
          // Subject row
          {
            ...leftEmptyCells,
            resourceInfo_field: this.$t('basic.subject'),
            ...this.generateResourcesRow('subjectName', resourcesInfo),
            ...rightEmptyCells
          },
          // points row
          {
            ...lastResourceLeftHeaders(),
            resourceInfo_field: this.$t('grading.possible_points'),
            ...this.generateResourcesRow('points', resourcesInfo),
            ...lastResourceRightHeaders()
          }
        ]

      //  Add Criteria Row
      if (this.isMYPSection)
        resourcesInfoTable.push({
          id_field: this.$t('basic.ID'),
          resourceInfo_field: this.$t('exam.criteria'),
          ...this.generateResourcesRow('allCriteriasKeys', resourcesInfo),
          rank_field: this.$t('grading.rank'),
          totalScore_field: this.$t('grading.total'),
          more_field: this.$t('basic.more')
        })

      this.gradeBookTableData.headers = this.headers = resourcesInfo.length
        ? resourcesHeaderArr()
        : []
      this.resourcesRows = resourcesInfoTable
      return resourcesInfo.length ? resourcesInfoTable : []
    },
    // Students
    handleStudentsData(resourceType = null, date = null) {
      if (!this.allResources.length) return []

      const getGrade = (resourcesId, userId) =>
          this.gradings.find(
            (g) =>
              g.original_resource_id === resourcesId && g.user_id === userId
          ),
        // Filter Resources
        resourcesFilters =
          this.allResources.filter(
            (f) =>
              (resourceType && resourceType === f.typeFlag) ||
              (date && new Date(date) === new Date(f.date))
          ) || [],
        resourcesMatched =
          resourceType || date ? resourcesFilters : this.allResources,
        // map resources for sorting
        sortGradesStudentResources = (userId) => {
          const data = resourcesMatched.map((s) => {
            try {
              const grade = getGrade(s.id, userId),
                gradeScore = (g) => {
                  return g.original_criteria
                    ? g.original_criteria[0] ||
                        Object.values(g.original_criteria).join('-')
                    : g.score !== null
                    ? g.score
                    : g.label
                }

              return {
                score:
                  !grade || (grade.label === null && grade.score === null)
                    ? 'N-A'
                    : gradeScore(grade),
                typeFlag: s.typeFlag,
                id: s.id
              }
            } catch (err) {
              console.error(err)
            }
          })
          return data
        },
        generateScoresByUserId = (userId) => {
          try {
            return this.generateResourcesRow(
              'score',
              sortGradesStudentResources(userId),
              'score'
            )
          } catch (err) {
            console.error(err)
          }
        },
        data = this.students.map((s) => {
          return {
            id: s.code,
            name: s.name,
            ...generateScoresByUserId(s.user_id),
            rank: s.rank ? `${s.rank}/${this.students.length}` : 0,
            total_score: s.total_score || 0
          }
        })
      this.studentsRows = data
      return data
    },
    // Handle Table Data Rows
    handleTableData() {
      this.data = this.originalData = []
      const selected = this.selectedResourceFilter
      if (!selected.key || selected.key === 'all') {
        this.originalData = [
          ...this.handleResourcesInfoRow(),
          ...this.handleStudentsData()
        ]
      } else if (selected.count) {
        // selected.map(f => {
        //   if (f.count) {
        //     this.originalData.push(
        //       ...this.handleResourcesInfoRow(f.value),
        //       ...this.handleStudentsData(f.value)
        //     )
        //   }
        // })

        this.originalData = [
          ...this.handleResourcesInfoRow(selected.key),
          ...this.handleStudentsData(selected.key)
        ]
      }
      this.data = this.originalData
      this.gradeBookTableData.data = this.data
    },
    // handle ResourcesTypes
    handleResourcesTypes() {
      const resourcesCount = this.allResources?.length || 0,
        examsCount = this.exams.length
      this.modifiedResourcesTypes = [
        {
          name: this.$t('basic.all'),
          key: 'all',
          count: resourcesCount,
          title_with_count: `${this.$t('basic.all')} (${resourcesCount})`
        },
        {
          name: this.$t('basic.exams'),
          key: 'exam',
          count: examsCount,
          title_with_count: `${this.$t('basic.exams')} (${examsCount})`
        },
        ...this.resourcesTypes.filter((r) => !r.key.includes('exam'))
      ]

      this.selectedResourceFilter = this.modifiedResourcesTypes[0]
      this.gradeBookTableData.modifiedResourcesTypes =
        this.modifiedResourcesTypes
      this.gradeBookTableData.selectedResourceFilter =
        this.selectedResourceFilter
    },
    // handle subjects data
    handleSubjectsData(data) {
      if (!Array.isArray(data)) data = Object.values(data)

      const firstSubjectId = data[0]?.id || null
      this.$store.commit('setSubjects', data)
      this.selectedSubjectId = firstSubjectId
      this.selectedSubject = data[0]
    },
    /////////////////////////
    // Filters
    /////////////////////////
    filterResources(resourceType = null, subject = null) {
      const resources = this.handleResourcesInfo(),
        resourcesFilters =
          resources.filter(
            (f) =>
              (resourceType && resourceType === f.typeFlag) ||
              (subject && subject === f.subject.id)
          ) || []

      return resourceType || subject ? resourcesFilters : resources
    },
    dateRefilter(dates) {
      this.dateFilter = dates
    },
    refilter() {
      if (this.hasFiltersData) {
        this.getGradingsOfClass()
        this.checkRoundOfClass()
      } else this.handleTableData()
    },
    resetFiltersData() {
      this.dateFilter.start_date = this.dateFilter.end_date = null
      this.selectedSubjectId = this.subjects[0].id
    },
    resetFilters() {
      if (!this.hasFiltersData) return
      this.resetFiltersData()
      this.getGradingsOfClass()
    },
    resetSubjects() {
      this.$store.commit('setSubjects', [])
    },
    /////////////////////////
    // Resources Actions
    /////////////////////////

    getResource(id, type) {
      try {
        const types = {
            assignment: this.assignments.sort((a, b) => a.id - b.id),
            assessment: this.assessments.sort((a, b) => a.id - b.id),
            exam: this.exams.sort((a, b) => a.id - b.id),
            others: this.otherTypesGrades.sort((a, b) => a.id - b.id)
          },
          resourceArr = types[type] || types.others
        return this.getSingleData(id, 'id', resourceArr)
      } catch (err) {
        console.error('getResource', err)
      }
    },
    getOriginalResource(fieldName) {
      const tr = {
        id: Number(fieldName.split('_')[1]) || Number(fieldName.split('_')[2]),
        type: fieldName.split('_')[0]
      }
      return this.getResource(tr.id, tr.type)
    },
    hideResource(fieldName) {
      const originalResource = this.getOriginalResource(fieldName)
      this.changeGradeStatus(originalResource)
    },
    /////////////////////////
    // Search
    /////////////////////////
    searchTable() {
      this.data = [...this.resourcesRows]
      const search = this.searchContent.toLowerCase()
      let searchResult = []
      if (search) {
        if (
          Number(search) ||
          Number(search.replace('s-', '')) ||
          search.includes('s-')
        ) {
          // const studentId = Number(search.replace('s-', ''))
          // searchResult = this.getSingleData(studentId, 'id', this.studentsRows)
          searchResult = this.studentsRows.filter((s) => s.id.includes(search))
          if (searchResult) this.data.push(...searchResult)
        } else {
          searchResult = this.studentsRows.filter((s) =>
            s.name.includes(this.text_formatter(search))
          )
          if (searchResult) this.data.push(...searchResult)
        }
      } else this.data = this.originalData
    },
    /////////////////////////
    // Summery
    /////////////////////////
    activeSummeryPopup() {
      this.summeryPopup = true
      this.selectedSubject = this.resourcesSubjects[0]
    },
    /////////////////////////
    // Update grade
    /////////////////////////

    updateGrade(fieldName) {
      const resource = this.getOriginalResource(fieldName),
        isManual = resource.status === 'manuel_grading'
      if (isManual) {
        this.selectedManualResource = resource
        this.activeAddNewGradePopup = true
      } else {
        this.selectedResource = resource
        this.activeUpdateGradePopup = true
      }
    },
    closeUpdateGrades() {
      this.selectedResource = {}
      this.activeUpdateGradePopup = false
    },

    /////////////////////////
    // Manual Assessment
    /////////////////////////
    closeManualAssessment() {
      this.selectedManualResource = {}
      this.activeAddNewGradePopup = false
    },

    /////////////////////////
    // Students Actions
    /////////////////////////
    getStudent(id, index) {
      // console.log('index', index)
      // console.log('id', id)
      // console.log(' this.students', this.students)
      //
      // console.log(
      //   "this.getSingleData(id, 'code', this.students)",
      //   this.getSingleData(id, 'code', this.students)
      // )
      const checkFirstEl = this.students[0]?.code === id,
        checkLastEl = this.students[this.students.length - 1]?.code === id
      if (checkFirstEl) return this.students[0]
      if (checkLastEl) return this.students[this.students.length - 1]
      return this.getSingleData(id, 'code', this.students)
    },
    goToChat(tr, index) {
      const student = this.getStudent(tr.id, index)

      this.$router.push({
        name: 'chat',
        params: {
          userID: student.user_id
        }
      })
    },
    goToUpdateStudentCard() {
      // const data = {
      //   selectedClass:this.selectedClass,
      //   selectedStudent:this.selectedStudent,
      //   selectedSubjectId:this.selectedSubjectId,
      //   StudentsOfSelectedClass:this.StudentsOfSelectedClass,
      //   round:this.activeRound
      // }
      this.$router.push({
        name: 'updateStudentCard',
        params: {
          studentId: this.selectedStudent.student_id,
          userId: this.selectedStudent.user_id,
          subjectId: this.selectedSubjectId,
          classId: this.selectedClass.id,
          divisionId: this.selectedClass.division_id,
          sectionId: this.selectedClass.section_id,
          gradeId: this.selectedClass.grade_id
        },
        query: {
          studentName: this.selectedStudent?.name || '',
          className: this.selectedClass.name,
          subjectName: this.selectedSubject.name
        }
      })
    },
    // Update Student grade
    UpdateStudentScorePopup(tr, index) {
      this.$store.commit('setSelectedStudent', this.getStudent(tr.id, index))
      if (this.isRoundActive) this.goToUpdateStudentCard()
      else this.activeUpdateStudentScorePopup = true
    },
    closeUpdateStudentScorePopup() {
      this.$store.commit('setSelectedStudent', {})
      this.activeUpdateStudentScorePopup = false
    },

    /////////////////////////////
    //  Next Prev Actions
    //////////////////////////
    getPrev(type = 'student') {
      if (type === 'student')
        this.selectedStudent = this.students.find(
          (s) => s.seq === this.selectedStudent.seq - 1
        )
      if (type === 'resource')
        this.selectedResource = this.students.find(
          (s) => s.seq === this.selectedResource.seq - 1
        )
    },
    getNext(type = 'student') {
      if (type === 'student')
        this.selectedStudent = this.students.find(
          (s) => s.seq === this.selectedStudent.seq + 1
        )
      if (type === 'resource')
        this.selectedResource = this.students.find(
          (s) => s.seq === this.selectedResource.seq + 1
        )
    },
    /////////////////////////////
    //  set Store data
    //////////////////////////
    setStoredGardeBookTableData() {
      this.headers = this.gradeBookTableData.headers
      this.data = this.gradeBookTableData.data
      this.modifiedResourcesTypes =
        this.gradeBookTableData.modifiedResourcesTypes
      this.selectedResourceFilter =
        this.gradeBookTableData.selectedResourceFilter
      this.students = this.studentsOfSelectedClass

      this.exams = this.gradeBookTableData.exams
      this.assignments = this.gradeBookTableData.assignments
      this.assessments = this.gradeBookTableData.assessments
      this.otherTypesGrades = this.gradeBookTableData.otherTypesGrades
    }
  },
  mounted() {},
  created() {
    this.getTypes()
    // this.$store.commit('TOGGLE_REDUCE_BUTTON', true)
    if (this.gradeBookTableData?.data?.length)
      this.setStoredGardeBookTableData()
    else {
      this.selectedClass = {}
      this.selectedSubjectId = null
      this.selectedSubject = {}
    }
  },
  beforeDestroy() {
    this.$store.commit('TOGGLE_REDUCE_BUTTON', false)
  },
  beforeRouteUpdate(to, from, next) {
    // console.log('beforeRouteUpdate to', to)
    // console.log('beforeRouteUpdate from', from)
    return next(false)
  }
}
</script>
<style lang="scss">
@import './gradingTable.scss';

#summery-popup {
  .vs-popup--content {
    min-height: 100%;
  }
}

.vs-dialog {
  .vs-con-table .vs-con-tbody {
    max-height: 100%;
  }
}

.v-select ul.vs__dropdown-menu {
}
</style>
