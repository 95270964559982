export default {
  announcements:'announcements',
  create_announcement:'Create Announcement',
  your_announcemnt:'Your Announcemnt ...',
  creator_by:'Creator By',
  title:'Title',
  launch_date:'Launch Date',
  launch_time:'Launch time',
  announcement_has_not_been_saved_please_complete_your_data :'Announcement has not been saved please complete your data',
  title_of_announcement:'Title of announcement',
  app_announcements:'App Announcements',
  announcement_for_teacher: 'Announcement For Teacher'
}
