export default {
  currentLocale: 'en',
  locales: ['ar', 'en'],
  // Main Info
  //TODO : exchange direct assets , logo links with  institutionAssetsLink && institutionLogo
  appName: 'lernit',
  appLink: 'https://demo.edulab-eg.tech/',
  appLogo: 'logo.png',
  institutionName: 'lernit',
  institutionShortName: '',
  institutionLongName: '',
  institutionLink: 'https://demo.edulab-eg.tech/',
  institutionAssetsLink: 'https://assets.lernitapp.com',
  institutionLogo: 'logo.png',
  institutionFavicon: 'favicon.png',
  institutionDriverLink: '',
  institutionApiRes: {},
  // AcademicYear
  currentAcademicYearId: '',
  currentAcademicYearData: ''
}
