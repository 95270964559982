<template>
  <vs-card class="py-4" v-if="Object.values(assignment).length">
    <div class="vx-row assignment" v-if="!isWaitingFeedback">
      <div
        class="
          vx-col
          w-full
          flex
          md:justify-around
          justify-center
          items-baseline
          flex-wrap
          md:flex-row
          flex-col
        "
      >
        <p class="text-base">
          {{ $t("basic.by") }}: {{ assignment.user.name }}
        </p>
        <p class="text-base">{{ assignment.division_subject.name }}</p>
        <p class="text-base">{{ assignment.type }}</p>
        <p v-if="assignment.diff_days > 0" class="text-success text-base">
          {{ assignment.diff_days }} {{ $t("assignment.days_to_submit") }}
        </p>
        <p v-else-if="assignment.diff_days < 0" class="text-danger text-base">
          {{ assignment.diff_days }} {{ $t("assignment.days_late") }}
        </p>
        <p class="text-warning text-base" v-else>
          {{ $t("assignment.time_to_take_action") }}
        </p>
        <p class="flex items-center text-base">
          {{ $t("assignment.cut_off_date") }}:
          {{ formatDate(assignment.assignment_date) }}
          <vx-tooltip
            class="mx-4"
            :text="$t('assignment.cant_answer_after_due_time')"
            position="top"
          >
            <feather-icon
              class="w-6 text-danger"
              icon="LockIcon"
              v-if="assignment.is_disable_task_date"
            />
          </vx-tooltip>
        </p>
      </div>
      <div class="vx-col w-full">
        <vs-divider />
      </div>
      <div class="vx-col w-full md:px-12">
        <br />
        <h3 class="font-bold">{{ assignment.title }}</h3>
        <br />
        <p class="text-base">
          {{ assignment.description }}
        </p>
        <br />

        <div v-if="assignment.attachments.length">
          <file-previewer
            class="my-4 mx-4"
            v-for="(file, idx) in assignment.attachments"
            :key="idx"
            :url="file.attachment_url"
            :urlType="file.type"
          ></file-previewer>
        </div>
      </div>
      <div class="vx-col w-full">
        <vs-divider />
      </div>
      <div
        class="
          px-6
          vx-col
          w-full
          flex
          justify-between
          items-baseline
          flex-wrap
          text-base
        "
      >
        <p class="w-full md:w-1/3">
          {{ $t("assignment.discipline_score") }}
          0
        </p>
        <div class="flex justify-between flex-col md:flex-row w-2/3">
          <p class="mx-2">
            {{ assignment.statistics.assignments }}
            {{ $t("basic.assignments") }} :
          </p>
          <p class="mx-2">
            {{ assignment.statistics.done_on_time }}
            {{ $t("assignment.done_on_time") }}
            {{ doneOnTime }}%
          </p>
          <p class="mx-2">
            {{ assignment.statistics.done_but_late }}
            {{ $t("assignment.done_but_late") }}
            {{ doneButLate }}%
          </p>
          <p class="mx-2">
            {{ assignment.statistics.delayed_done }}
            {{ $t("assignment.delayed") }}
            {{ delayed }}%
          </p>
        </div>
      </div>
      <div class="vx-col w-full">
        <vs-divider />
      </div>
      <div class="vx-col w-full md:px-12">
        <vs-textarea
          v-model="assignmentAnswer.answer"
          class="w-full"
          height="10rem"
          :label="
            showGradeMode
              ? $t('assignment.your_answer')
              : $t('assignment.write_your_answer')
          "
          :disabled="showGradeMode"
        />

        <div class="w-full mt-6" v-if="assignment.have_comments">
          <vs-textarea
            v-model="assignmentAnswer.comment"
            height="10rem"
            :label="
              showGradeMode
                ? $t('assignment.your_comment')
                : $t('assignment.write_your_comment')
            "
            class="w-full"
            :disabled="showGradeMode"
          />
        </div>

        <div class="vx-col w-full">
          <div
            v-for="(file, idx) in assignmentAnswer.attachments"
            :key="file.attachment_url"
          >
            <feather-icon
              @click="removeAttachment(file, idx)"
              class="mr-2 remove-attachment"
              icon="XIcon"
              svgClasses="h-5 w-5"
            />
            <file-previewer
              :url="file.attachment_url"
              :urlType="file.type"
              class="my-4 mx-4"
            ></file-previewer>
          </div>
          <file-uploader
            class="hidden"
            ref="file_uploader"
            @uploadedFiles="setUploadedFiles"
          ></file-uploader>
        </div>

        <div
          class="flex md:justify-end justify-center"
          v-if="answerMode && assignment.have_attachments"
        >
          <vs-dropdown vs-trigger-click>
            <vs-button color="gray" icon="upload_file"> Upload</vs-button>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="openUploadModel">
                <span>{{ $t("assignment.from_device") }}</span>
              </vs-dropdown-item>
              <!-- <vs-dropdown-item>
                <span>{{ $t('assignment.from_drive') }}</span>
              </vs-dropdown-item> -->
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
      </div>

      <div class="vx-col w-full md:mt-12" v-if="showTeacherFeedBack">
        <vs-divider> {{ $t("assignment.teacher_feedback") }} </vs-divider>
      </div>

      <div class="vx-col w-full md:px-12 mt-4" v-if="showTeacherFeedBack">
        <div class="flex items-center" v-if="assignment.task.assess.note">
          <vs-avatar
            class="font-bold text-2xl mr-2"
            color="primary"
            size="large"
            :text="teacherName"
          />
          <div
            class="border border-primary rounded-lg p-4 vx-col w-full md:w-1/2"
          >
            {{ assignment.task.assess.note }}
          </div>
        </div>
        <div class="vx-row mt-8" v-if="showGradeMode">
          <template v-if="currentSection === 'MYP'">
            <div
              class="vx-col md:w-1/4 w-full"
              v-for="item in selectedCriteria"
              :key="item.key"
            >
              <div class="vs-input--label">
                {{ item.key + " - " + item.title }}
              </div>
              <div class="content-box w-full my-1">{{ item.value }}</div>
            </div>
          </template>
          <template v-else-if="currentSection === 'PYP'">
            <div
              class="vx-col w-full"
              v-for="item in selectedCriteria"
              :key="item.key"
            >
              <div class="content-box w-full my-1">
                {{ item.key + " - " + item.title }}
              </div>
            </div>
          </template>
          <template v-else>
            <div
              class="vx-col w-full"
              v-for="item in selectedCriteria"
              :key="item.key"
            >
              <div class="content-box w-full my-1">{{ item }}</div>
            </div>
          </template>
        </div>
      </div>

      <div
        class="
          vx-col
          w-full
          flex
          justify-around
          mt-12
          flex-wrap
          md:flex-row
          flex-col-reverse
        "
      >
        <vs-button
          @click="cancel"
          type="border"
          class="mx-2 mt-3"
          color="warning"
          >{{ $t("basic.cancel") }}</vs-button
        >
        <vs-button
          v-if="showDraft"
          @click="save('draft')"
          class="mx-2 mt-3"
          color="gray"
          :disabled="isFromSubmitted"
          >{{ $t("form.save_as_draft") }}</vs-button
        >
        <vs-button
          v-if="answerMode"
          @click="save('active')"
          :disabled="isFromSubmitted"
          class="mx-2 mt-3"
          >{{ $t("form.submit_answer") }}</vs-button
        >
      </div>
    </div>
    <div class="text-center" v-else>
      <h3>
        {{
          $t(
            "assignment.you_cant_access_this_assignment_now_wait_teacher_feedback"
          )
        }}
      </h3>
      <div
        class="
          vx-col
          w-full
          flex
          justify-around
          mt-12
          flex-wrap
          md:flex-row
          flex-col-reverse
        "
      >
        <vs-button
          @click="cancel"
          type="border"
          class="mx-2 mt-3"
          color="warning"
          >{{ $t("basic.cancel") }}</vs-button
        >
      </div>
    </div>
  </vs-card>
</template>
<script>
import FileUploader from '@/modules/unit-planner/components/uploadButton/uploadButton.vue'
import vSelect from 'vue-select'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const AssignmentStudentRepository = RepositoryFactory.get(
  'AssignmentsModule',
  'studentAssignments'
)

export default {
  components: {
    FileUploader,
    'v-select': vSelect
  },
  props: {
    assignmentItem: {
      required: true,
      type: [Object]
    }
  },
  name: 'assigmentModal',
  data () {
    return {
      modalName: 'assigmentModal',
      assignment: {},
      assignmentAnswer: {
        attachments: [],
        answer: ''
      },
      isFromSubmitted: false,
      studentNote: '',
      selectedFeedback: null,
      imgBaseUrl: this.institutionAssetsLink,
      uploadedFiles: []
    }
  },
  watch: {
    assignmentItem (val) {
      if (val && val.id) {
        this.loadData(val)
      }
    }
  },
  computed: {
    isWaitingFeedback () {
      return (
        this.assignment.task?.submit_date &&
        this.assignment.task?.status.key === 'active' &&
        !this.assignment.task?.is_able_to_answer &&
        !this.assignment.task?.assess?.is_final
      )
    },
    teacherName () {
      const names = this.assignment.user.name.split(' ')
      return `${names[0]} ${names[1]}`
    },
    doneOnTime () {
      return (
        this.toPercent(
          this.assignment.statistics.done_on_time,
          this.assignment.statistics.assignments
        ) || 0
      )
    },
    doneButLate () {
      return (
        this.toPercent(
          this.assignment.statistics.done_but_late,
          this.assignment.statistics.assignments
        ) || 0
      )
    },
    delayed () {
      return (
        this.toPercent(
          this.assignment.statistics.delayed,
          this.assignment.statistics.assignments
        ) || 0
      )
    },
    showDraft () {
      return (
        !this.assignment.task ||
        (this.assignment.task?.status.key !== 'active' &&
          !this.assignment.task?.assess)
      )
    },
    answerMode () {
      return !this.assignment.task?.assess?.is_final
    },
    showGradeMode () {
      return Boolean(this.assignment.task?.assess?.is_final)
    },
    showTeacherFeedBack () {
      return this.assignment.task?.assess
    },
    currentSection () {
      return this.assignment.class_teacher[0].section.short_name
    },
    selectedCriteria () {
      if (this.currentSection === 'PYP') {
        const criteria = this.assignment.criteria.filter((criteria) => {
          if (this.assignment.task.assess.criteria.includes(criteria.key)) {
            return criteria
          }
        })
        return criteria
      } else {
        return this.assignment.task.assess.criteria
      }
    }
  },
  methods: {
    openUploadModel () {
      this.$refs.file_uploader.click()
    },
    loadData (data) {
      this.resetProps()
      this.assignment = { ...data }
      if (data.task) {
        this.assignmentAnswer = {
          attachments: data.task.attachments,
          answer: data.task.answer,
          comment: data.task.comments.comment
        }
      }
    },
    resetProps () {
      this.assignment = {}
      this.selectedFeedback = null
      this.studentNote = ''
      this.assignmentAnswer = {
        attachments: [],
        answer: ''
      }
    },
    submitAnswer (statusValue) {
      if (!this.canAnswer(statusValue)) {
        return
      }
      const msg = statusValue === 'draft' ? 'Drafted' : 'Submitted'
      //** Show Loader **//
      this.$vs.loading()
      const data = {
        ...this.assignmentAnswer,
        status: statusValue
      }
      AssignmentStudentRepository.createStudentAssignmentAnswer(
        this.assignment.id,
        data
      )
        .then(() => {
          this.$vs.loading.close()
          this.cancel()
          this.$vs.notify({
            title: 'Submitting Answer',
            text: `Answer ${msg} Successfully`,
            color: 'success',
            position: 'bottom-right'
          })
        })
        .catch((err) => {
          //** Hide Loader **//\
          this.isFromSubmitted = false
          this.$vs.loading.close()
          this.errMsg(err)
        })

    },
    updateAnswer (statusValue) {
      if (!this.canAnswer(statusValue)) {
        return
      }
      //** Show Loader **//
      this.$vs.loading()

      const data = {
        ...this.assignmentAnswer,
        status: statusValue
      }
      AssignmentStudentRepository.updateStudentAssignmentAnswer(
        this.assignment.task.id,
        data
      )
        .then(() => {
          this.$vs.loading.close()
          this.cancel()
          this.$vs.notify({
            title: 'Updating Answer',
            text: 'Answer Updated Successfully',
            color: 'success',
            position: 'bottom-right'
          })
        })
        .catch((err) => {
          this.isFromSubmitted = false
          console.error(err)
          this.$vs.loading.close()
          this.errMsg(err)
        })
    },
    setUploadedFiles (files) {
      this.assignmentAnswer.attachments.push(...files)
    },
    canAnswer () {
      if (
        !this.assignmentAnswer.answer &&
        !this.assignmentAnswer.attachments.length
      ) {
        this.$vs.notify({
          title: 'Validation',
          text: 'Please Answer',
          color: 'warning',
          position: 'bottom-right'
        })
        return false
      } else {
        return true
      }
    },
    save (statusValue) {
      if (this.isFromSubmitted) return
      this.isFromSubmitted = true
      if (this.assignment.task) {
        this.updateAnswer(statusValue)
      } else {
        this.submitAnswer(statusValue)
      }
    },
    cancel () {
      this.resetProps()
      if (this.isMobileWebView) {
        this.$router.push({
          name: 'student_assignments'

        }) 
      } else this.$router.push({
        name: 'BackToProfile',
        params: {
          id: this.authUser.uid
        }
      })
    },
    removeAttachment (file, index) {
      //** Show Loader **//
      this.$vs.loading()
      const attachment = {
          file_path: file.attachment_url
        },
        file_attachment_url_split = file.attachment_url.split('/'),
        payload = {
          attachments: [
            {
              attachment:
                file_attachment_url_split[file_attachment_url_split.length - 1],
              attachment_url: attachment.file_path
            }
          ]
        }
      AssignmentStudentRepository.deleteAssignmentsAttachments(payload)
        .then(() => {
          this.assignmentAnswer.attachments.splice(index, 1)
          //** Hide Loader **//
          this.$vs.loading.close()
        })
        .catch((err) => {
          //** Hide Loader **//
          this.$vs.loading.close()

          this.$vs.notify({
            title: 'Deletion Error',
            text: err.data.message,
            color: 'danger',
            position: 'bottom-right'
          })
        })
    }
  },
  created () {
    // alert('assignment-model')
  }
}
</script>
<!--
<style lang="scss" src="./assignment-modal.scss"></style>
-->
<style lang="scss" scoped>
.assignment {
  .assignment-title {
    color: #0a22b3;
  }

  .remove-attachment {
    top: 34px;
    left: 90px;
    z-index: 20;
    cursor: pointer;
    svg {
      stroke: black;
      stroke-width: 2px;
    }
    &:hover {
      border: 1px solid var(--vs-primary);
      border-radius: 50%;
      width: 15px;
      height: 15px;
      background-color: red;
    }
  }
  .content-box {
    min-height: 38px;
    height: auto;
    border-radius: 5px;
    padding: 7px;
    border: 1px solid rgba(var(--vs-primary), 0.7);
    font-weight: 500;
    font-size: 13px;
  }
  .teacher-note {
    min-height: 105px !important;
    max-height: 250px;
    height: 100%;
    overflow-y: scroll;
  }
}
</style>
