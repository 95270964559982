<template>
  <canteen-form @formSubmited = "addCanteen"/>
</template>

<script>
import canteenForm from '@/modules/canteen/pages/canteen/components/canteenForm'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const canteenRepository = RepositoryFactory.get(
  'canteenRepository',
  'canteenRepository'
)
export default {
  name: 'create',
  components:{
    canteenForm
  },
  methods: {
    addCanteen (payload) {
      this.VSLoading()
      canteenRepository.addNewCanteen(payload).then(() => {
        this.$router.push({name:'allCanteen'})
        this.stopVSLoading()
      }).catch((err) => {
        this.errMsg(err)
      })
    }
  },
  created () {
  }
}
</script>

<style scoped>

</style>
