<template>
  <ListPageWithCreateButton
    createRoutName="create-Classes"
    :showCreateBtn="checkUserRole('SND')"
    :createBtnName="$t('classes.ClassCreate')">
    <template slot="listDataContent">
      <data-table
        :csv_url="'/classes/export'"
        :pdf_url="'class-group/pdf'"
        :headers_prop="headers"
        list_url_prop="/classes/filter"
        :side_filters="filter_choosen"
    /></template>
    <template slot="rightSide">
      <round-filter
        :filters_prop="filters"
        @refilter="refilter"
        @resetAll="resetFilter"
        :loading="loadingFilters" />
    </template>
  </ListPageWithCreateButton>
</template>

<script>
import DataTable from '@/components/general/datatable/DataTable2.vue'
import RoundFilter from '@/components/general/RoundFilter' //Right-sidebar
import {commonDataMixin} from '@/mixins.js'

export default {
  name: 'ClassesList',
  mixins: [commonDataMixin],
  components: {DataTable, RoundFilter},
  data() {
    return {
      filter_choosen: {
        schools: [],
        academics: [],
        sections: [],
        grades: [],
        divisions: []
      },
      headers: [
        {
          minWidth: 130,
          headerName: this.$t('basic.ID'),

          field: 'id',
          checkboxSelection: true,
          width: 200,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true
        },

        {
          minWidth: 130,
          headerName: this.$t('classes.code'),
          field: 'code',
          headerCheckboxSelectionFilteredOnly: true
        },
        {
          minWidth: 160,
          headerName: this.$t('classes.Name'),
          field: 'name',
          width: 200,
          headerCheckboxSelectionFilteredOnly: true
        },
        {
          minWidth: 160,
          headerName: this.$t('classes.AcademicYear'),
          field: 'academicYear.name'
        },
        {
          minWidth: 160,
          headerName: this.$t('classes.Students'),
          field: 'number_of_students'
        },
        /* {
          headerName: this.$t("classes.average"),
          field: "number_of_students",
        },*/
        // { headerName: this.$t('RoundManager.CreatedBy'), field: 'owner.name', width:290 },
        // { headerName: this.$t('RoundManager.Contact'), field: 'contacts.email.0.email', width:390 },
        {
          minWidth: 160,
          headerName: this.$t('subject.Status'),
          field: 'status',
          width: 240,
          cellRendererFramework: 'CellRendererStatus',
          cellRendererParams: {
            status_prop: this.status
          }
        }
      ],

      filters: [
        {
          id: 0,
          name: this.$t('basic.academic_year'),
          payload_name: 'academics',
          by: []
        },
        {
          id: 1,
          name: this.$t('basic.schools'),
          payload_name: 'schools',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 2,
          name: this.$t('basic.divisions'),
          payload_name: 'divisions',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 3,
          name: this.$t('basic.sections'),
          payload_name: 'sections',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 4,
          name: this.$t('basic.grades'),
          payload_name: 'grades',
          by: []
        }
      ],
      export_file: false,
      loadingFilters: false
    }
  },
  methods: {
    ////////////////////////////
    // Round Filter
    ///////////////////////////
    updateFilters() {
      this.getAllAcademic().then((res) => {
        res.map((el) => {
          this.filters[0]['by'].push({
            id: el._id,
            name: el.name,
            checked: false
          })
        })
      })
      this.getFilterData().then((res) => {
        res.schools.map((el) => {
          this.filters[1]['by'].push({
            id: el.id,
            name: el.branch_name,
            checked: false
          })
        })
      })
    },
    getDivision(chosen) {
      this.loadingFilters = true
      const data = {
        schools: chosen.schools
      }
      this.filters[2]['by'] = []
      this.filters[3]['by'] = []
      this.filters[4]['by'] = []
      this.getFilterData(data)
        .then((res) => {
          res.divisions.map((el) => {
            this.filters[2]['by'].push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
        })
        .finally(() => {
          this.loadingFilters = false
        })
    },
    getSections(chosen) {
      this.loadingFilters = true
      this.filters[3]['by'] = []
      this.filters[4]['by'] = []
      const data = {
        schools: chosen.schools,
        divisions: chosen.divisions
      }
      this.getFilterData(data)
        .then((res) => {
          res.sections.map((el) => {
            this.filters[3]['by'].push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
        })
        .finally(() => {
          this.loadingFilters = false
        })
    },
    getGrades(chosen) {
      this.loadingFilters = true
      this.filters[4]['by'] = []
      const data = {
        schools: chosen.schools,
        divisions: chosen.divisions,
        sections: chosen.sections
      }
      this.getFilterData(data)
        .then((res) => {
          res.grades.map((el) => {
            this.filters[4]['by'].push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
        })
        .finally(() => {
          this.loadingFilters = false
        })
    },

    refilter(filters) {
      if (filters.length > 0) {
        filters.forEach((el) => {
          if (!Array.isArray(el.checked)) el.checked = [el.checked]
        })
        filters.forEach((filter) => {
          this.filter_choosen[filter.name] = filter.checked
        })
      }
    },

    resetFilter(filter) {
      this.filters[2]['by'] = []
      this.filters[3]['by'] = []
      this.filters[4]['by'] = []
    },

    ///////////////////////////////////
    // Hide , show datatble Actions
    ///////////////////////////////////
    toggleDatatbleActionsCol() {
      const actionsCol = {
        minWidth: 160,
        headerName: this.$t('subject.Actions'),
        field: 'transactions',
        width: 240,
        cellRendererFramework: 'CellRendererActions',
        cellRendererParams: {
          actions_prop: [
            // {title: 'Report', route_name:'update-round-manager', parameter_name:"id", icon:'Edit3Icon', navigate_action:false},
            // {title: 'RevokeAccess', route_name:'show-round-manager', parameter_name:"id", icon: 'Edit3Icon', navigate_action:false},
            {
              title: this.$t('main.Edit'),
              route_name: 'edit-Classes',
              parameter_name: 'id',
              icon: 'Edit3Icon',
              navigate_action: true
            },
            {
              title: this.$t('main.upgrade'),
              route_name: 'upgrade-Classes',
              parameter_name: 'id',
              icon: 'ChevronsUpIcon',
              navigate_action: true,
              showIf: (data) => data.upgradable
            },
            {
              dropDown: true,
              dropDown_items: [
                {
                  title: this.$t('status.active'),
                  parameter_name: 'id',
                  icon: 'CheckSquareIcon',
                  action: 'Active',
                  resource: ':id/classstatus',
                  showIf: (data) => data.status !== 'Active'
                },
                {
                  title: this.$t('basic.suspend'),
                  parameter_name: 'id',
                  icon: 'PowerIcon',
                  action: 'Suspended',
                  resource: ':id/classstatus',
                  showIf: (data) => data.status !== 'Suspended'
                },
                {
                  title: this.$t('status.archive'),
                  parameter_name: 'id',
                  icon: 'ArchiveIcon',
                  action: 'Archived',
                  resource: ':id/classstatus',
                  showIf: (data) => data.status !== 'Archived'
                },
                {
                  title: this.$t('status.close'),
                  parameter_name: 'id',
                  icon: 'SlashIcon',
                  action: 'Closed',
                  resource: ':id/classstatus',
                  showIf: (data) => data.status !== 'Closed'
                }
              ]
            }
          ]
        }
      }
      // if SND
      if (!this.checkUserRole('SMD')) this.headers.push(actionsCol)
    }
  },
  created() {
    this.toggleDatatbleActionsCol()
    this.$root.$on('onChange:schools', (v) => {
      this.getDivision(v)
    })
    this.$root.$on('onChange:divisions', (v) => {
      this.getSections(v)
    })
    this.$root.$on('onChange:sections', (v) => {
      this.getGrades(v)
    })

    this.updateFilters()
  },
  beforeDestroy() {
    this.$root.$off('onChange:schools')
    this.$root.$off('onChange:divisions')
    this.$root.$off('onChange:sections')
  }
}
</script>

<style scoped lang="scss"></style>
