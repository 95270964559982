import axios from 'axios'
const resource = ''

export default {


  getNationalities () {
    return axios.get(`${resource}/mobile/nationalities`)
  },
  getReligions () {
    return axios.get(`${resource}/mobile/religions`)
  },
  getLanguages () {
    return axios.get(`${resource}/mobile/languages`)
  },
  getRelationships () {
    return axios.get(`${resource}/mobile/relationships`)
  },
  getCountries () {
    return axios.get(`${resource}/mobile/countries`)
  },
  getCitiesOfCountery (country_id) {
    return axios.get(`${resource}/mobile/cities`,  {
      params: {
        countryId: country_id
      }
    })
  },
  getBranches () {
    return axios.get(`${resource}/branches`)
  },

  getGrades (id) {
    return axios.get(`/division/${id}/grades`)
  },
  ////////////////////////////
  // Divisions
  ///////////////////////////
  // old one
  getDivisions (branch_id) {
    return axios.post('/division/schoolDivisions', {
      params: {
        branchId: branch_id
      }
    })
  },
  // return Paginated data
  getDivisionsWithoutBranchId () {
    return axios.get('division')
  },
  // Return unpaginated data
  getDivisionsWithoutPagination () {
    return axios.get('/division/without-pagination')
  },

  ////////////////////////////
  // Contacts keys
  ///////////////////////////
  getTelKeys () {
    return axios.get('/configuration/search/telephone')
  },
  getMobileKeys () {
    return axios.get('/configuration/search/mobile')

  },
  getFaxKeys () {
    return axios.get('/configuration/search/fax')

  },

  getAllClasses () {
    return axios.get('/class')
  },
  getAllSections () {
    return axios.get('/section')
  },
  getAllAcademic () {
    return axios.get('/academic')
  },
  getAllStatus () {
    return axios.get('/general-status')
  },
  getStudentTypes () {
    return axios.get('/student-type')
  },
  getCurrentAcadmicYear () {
    return axios.get('/academic/currentActive')
  },
  getCurrentMasterAcademicYear () {
    return axios.get('/academic/current_active_parent_details')
  },

  getMaritalStatus () {
    return axios.get('student/static-data/marital_status')
  },
  getCustodyOwner () {
    return axios.get('student/static-data/responsible')
  },
  getGenders () {
    return axios.get('student/static-data/genders')
  },

  //////////////////////////////
  // Upload Files
  //////////////////////////////
  commonUploadFiles (data) {
    return axios.post('common/upload-files', data)
  },
  deleteDocumentFromS3 (data) {
    return axios.post('common/delete-file', data)
  },
  //////////////////////////////
  // FilterData
  //////////////////////////////
  getFilterData (data) {
    return axios.get('common/filters-data', {
      params :{
        ...data
      }
    })
  },
  //////////////////////////////
  // get subject of class
  //////////////////////////////
  getSubjectOfClass (classId) {
    return axios.get(`/teachers/${classId}/subjects`)
  }

}
