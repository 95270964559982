<template>
  <vx-card class="p-relative mt-8">
    <div class="min_height_110 p-1 pt-1 pb-1 md:p-1">
      <h6 class="mb-8">
        <b>{{ interview.body }}</b>
      </h6>
      <p
        class="flex flex-wrap text-sm justify-between w-full md:flex-row flex-col"
      >
        <span class="flex-1 md:mb-0 mb-2">
          <!--////////////////////////
          Reflection Label
         ////////////////////////////-->
          <span class="text-capitalize" v-if="index === 1">
            {{ $t("cas.first_interview") }}
          </span>
          <span class="text-capitalize" v-if="index === 2">
            {{ $t("cas.second_interview") }}
          </span>
          <span class="text-capitalize" v-if="index === 3">
            {{ $t("cas.Third_Final_Interview") }}
          </span>
        </span>
        <span class="flex flex-row md:w-2/3 flex-wrap text-sm justify-between">
          <!--////////////////////////
                Student Name
          ////////////////////////////-->

          <span class="flex items-center md:mb-0 mb-2" v-if="is_coordinator">
            <feather-icon
              icon="UserIcon"
              svgClasses="h-5 w-5 text-warning stroke-current"
            />
            <span class="ml-2"> {{ interview.student_name }}</span>
          </span>
          <!--////////////////////////
                Coordinator Name
           ////////////////////////////-->
          <span class="flex items-center md:mb-0 mb-2">
            <feather-icon
              icon="UserIcon"
              svgClasses="h-5 w-5 text-primary stroke-current"
            />
            <span class="ml-2"
              >{{ $t("basic.by") }} {{ interview.teacher_name }}</span
            >
          </span>
          <!--////////////////////////
                    Date
            ////////////////////////////-->
          <span class="flex items-center md:mb-0 mb-2">
            <feather-icon
              icon="CalendarIcon"
              svgClasses="h-5 w-5 text-primary stroke-current"
            />
            <span class="ml-2">{{
              new Date(interview.created_at).toDateString()
            }}</span>
          </span>
          <!--////////////////////////
                    Student Class
            ////////////////////////////-->
          <span class="flex items-center bg-warning text-white">
            <span class="mx-2">{{ interview.student_class }}</span>
          </span>
        </span>
      </p>
    </div>
  </vx-card>
</template>
<script>
import mixins from '../../../mixins'
export default {
  name:'interview-card',
  props:['data', 'index'],
  mixins:[mixins],
  data () {
    return {
      interview:{}
    }
  },
  created () {
    this.interview = this.data
  }
}
</script>
