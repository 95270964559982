import {mapGetters} from 'vuex'
import Unit from '../unit/unit.vue'
import ModuleMixins from '../../mixins'
import CustomizeUnitModal from '../../modals/customize-unit/customize-unit.vue'
import CreateYearGroupModal from '../../modals/create-year-group/create-year-group.vue'
import UnitsMaterialization from '../units-materialization/units-materialization.vue'
import FilterBar from '../filter-bar/filter-bar.vue'
import unitMaterializationModal from '../../modals/unit-materialization/unit-materialization.vue'
import CloneAcademicYearModal from '../../modals/clone-academic-year/clone-academic-year.vue'
import Service from '../../services/poi'
import PoiPdfTable from '../pdf-table/pdf-table.vue'


export default {
  name: 'poiContainer',
  mixins: [ModuleMixins],
  components: {
    Unit,
    CustomizeUnitModal,
    CreateYearGroupModal,
    UnitsMaterialization,
    FilterBar,
    unitMaterializationModal,
    CloneAcademicYearModal,
    PoiPdfTable
  },
  data () {
    return {
      yearGroupModal: 'createYearGroup',
      materializationModalName: 'unitMaterializationModal',
      currentAcademicYear: null,
      yearGroupToDelete: null,
      cloneModalName: 'cloneAcademicYear',
      showHistoricalData: false,
      restService: Service,
      printElement: null,
      printElementDimension: {
        width: null,
        height: null
      },
      pdfOptions: {
        orientation: 'portrait',
        unit: 'pt',
        fileName: this.$t('poi.poi_generated_report'),
        format: {}
      }
    }
  },
  created () {

  },
  mounted () {
    // this.contentFullWidth()
    this.loadData()
    this.printElement = this.$refs.pdfTable
  },
  destroyed () {
    this.$store.dispatch('resetPoiYearsGroup', [])
  },
  methods: {
    async loadData () {
      //**  Show Loader **//
      this.$vs.loading()

      //** Get Academic Years, Set Default **//
      await this.restService.getAcademicYears()
        .then(res => {

          this.currentAcademicYear = res.data.currentAcademicYear

          //** Set Default Academic Year **//
          this.$store.dispatch('setCurrentAcademicYear', this.currentAcademicYear)

          //** Set selected Academic Year as Current first Time **//
          this.$store.dispatch('setSelectedAcademicYear', this.currentAcademicYear)


          //** Set  Academic Years  to use it on dropdowns **//
          this.$store.dispatch('setAcademicYearsOptions', res.data.academicYears)


          //** Filter To Get Future Academic  **//
          let data
          //** filter Dates to get future dates **//
          data = res.data.academicYears.filter(item => {
            if (this.getDateOnly(item.start_date) >= this.getDateOnly(this.currentAcademicYear.end_date)) {
              return item
            }
          })

          //** push current academic Year **//
          data.push(this.currentAcademicYear)

          //** Set  Future Academic Years  to use it on dropdowns **//
          this.$store.dispatch('setFutureAcademicYearsOptions', data)

        })
        .catch(err => console.error(err))

      //** Get YearsGroup, Set Default **//
      await this.restService.getYearsGroup()
        .then(res => {
          //** Set  Years Group  to use it on dropdowns **//
          this.$store.dispatch('setYearsGroupOptions', res.data)
        })
        .catch(err => console.error(err))

      //** Load Current Academic Year data **//
      await this.restService.getAcademicYearData({academic_year_id: this.currentAcademicYear})
        .then(res => {
          //** check if academic years group can edit based on current or future dates **//
          const data = res.data.map(item => {
            item['isEditable'] = this.canEdit(item.academic_year_id)
            return item
          })
          // ** Set New Data To store ** //
          this.$store.dispatch('replacePoi', this.cloneItem(data))
        })
        .catch(err => console.error(err))


      //** Get Default Units **//
      await this.restService.getDefaultUnits()
        .then(res => {
          // ** Get POI For Current Academic Year  ** //
          this.$store.dispatch('defaultPoiUnit', this.cloneItem(res.data))
        })
        .catch(err => console.error(err))


      this.$nextTick(() => {
        this.printElement = this.$refs.pdfTable
      })


      //**  Hide Loader **//
      this.$vs.loading.close()
    },
    canEdit (academicYearId) {
      const isEditable = this.futureAcademicYears.find(item => item.id === academicYearId)
      if (isEditable) {
        return true
      } else {
        return false
      }
    },
    addEditYearGroup (data = null) {
      //** Call store Action to open Modal (Create Group) **//
      this.$store.dispatch('manageModal', {
        name: this.yearGroupModal,
        show: true,
        data
      })
    },
    updateStore () {
      const data = this.cloneItem(this.allData)
        .filter(item => item.id !== this.yearGroupToDelete.id && item.academic_year_id === this.yearGroupToDelete.academic_year_id)

      // ** set new data to store  ** //
      this.$store.dispatch('replacePoi', this.cloneItem(data))
        .then(() => {
          this.successMsg(this.$t('poi.removed_successful'), this.$t('poi.year_group'))

        })
    },
    async removeYearGroup () {
      //**  Show Loader **//
      this.VSLoading()

      await this.restService.deleteYearGroup(this.yearGroupToDelete.id)
        .then(res => {
          this.updateStore()
        })
        .catch(err => console.error(err))

      //**  Hide Loader **//
      this.stopVSLoading()
    },
    removeGroupConfirmation (groupData) {
      this.yearGroupToDelete = groupData
      this.$vs.dialog({
        color: 'danger',
        title: this.$t('poi.year_group_deletion_warning'),
        text: `${this.$t('poi.all_data_will_be_removed_are_you_sure')} ?`,
        accept: this.removeYearGroup
      })
    },
    materializationModal (group) {
      this.$store.dispatch('manageModal', {
        name: this.materializationModalName,
        show: true,
        data: {groupData: group}
      })
    },
    openCloneModal () {
      //** Call store Action to open Modal (Create Group) **//
      this.$store.dispatch('manageModal', {
        name: this.cloneModalName,
        show: true,
        data: null
      })
    },
    contentFullWidth () {
      const content = document.querySelector('#content-area')
      if (content.classList.contains('content-area-reduced')) {
        content.classList.replace('content-area-reduced', 'content-area-lg')
        document.querySelector('#btnVNavMenuMinToggler').click()
      }
    },
    async generatePdf () {
      //**  Show Loader **//
      this.$vs.loading()

      // convert from px to point //
      this.printElementDimension = {
        width: ((this.printElement.clientWidth) * 0.75),
        height: (this.printElement.clientHeight * 0.75)
      }

      this.pdfOptions.format = this.printElementDimension

      this.pdfBase64 = await this.html2PdfBase64(this.printElement, this.pdfOptions)

      //**  Hide Loader **//
      this.$vs.loading.close()
    }
  },
  computed: {
    // ** Using Store Getter Instead Of Current Locale Object ** //
    // ** To Make it ReActive Object throw Module ** //
    ...mapGetters({poiYearsGroup: 'getFilteredPoiYearsGroup'}),

    ...mapGetters({allData: 'getPoiYearsGroup'}),

    ...mapGetters({defaultUnits: 'getDefaultUnits'}),

    ...mapGetters({filterBarState: 'filterBarState'}),

    ...mapGetters({futureAcademicYears: 'getFutureAcademicYearsOptions'}),

    ...mapGetters({selectedAcademicYears: 'getSelectedAcademicYear'}),

    isEditableAcademicYear () {
      if (this.selectedAcademicYears) {
        return this.canEdit(this.selectedAcademicYears.id)
      }
    }

  }
}
