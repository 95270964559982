
<template>
  <vx-card>
    <div class="vx-row mb-6">
      <div class="vx-col sm:w-1/6 w-full">
       <!--- <vs-chip class="ml-20 ag-grid-cell-chips text-lg bg-success text-white pr-3 py-2">
          <span>{{syllabus.status}}</span>
        </vs-chip>-->
      </div>
      <div class="vx-col sm:w-1/6 w-full"></div>
      <div class="vx-col sm:w-1/6 w-full"></div>
      <div class="vx-col sm:w-1/6 w-full"></div>
      <div class="vx-col sm:w-1/6 w-full"></div>
      <div class="vx-col sm:w-1/6 w-full">
       <!---- <vs-chip
          :class="`ag-grid-cell-chips text-lg text-white pr-3 py-2 ${syllabus.online !== 0 ? 'bg-success' : 'bg-danger'}`"
        >
          <span>{{syllabus.online !== 0 ? "Live" : "Not Live"}}</span>
        </vs-chip>-->
      </div>
    </div>

    <div class="vx-row mb-6 text-center">
      <div class="vx-col sm:w-1/4 w-full"></div>
      <div class="vx-col sm:w-1/4 w-full">
        <span>{{$t('division.Name')}}</span>
      </div>
      <div class="vx-col sm:w-1/4 w-full">
        <div>{{section.name}}</div>
      </div>
    </div>
    <div class="vx-row mb-6 text-center">
      <div class="vx-col sm:w-1/4 w-full"></div>
      <div class="vx-col sm:w-1/4 w-full">
        <span>{{$t('division.DivisionName')}}</span>
      </div>
      <div class="vx-col sm:w-1/4 w-full">
        <div>{{section.division_name}} </div>
      </div>
    </div>
    <div class="vx-row mb-6 text-center">
      <div class="vx-col sm:w-1/4 w-full"></div>
      <div class="vx-col sm:w-1/4 w-full">
        <span>{{$t('division.Principal')}}</span>
      </div>
      <div class="vx-col sm:w-1/4 w-full">
        <div>{{section.director_name}}</div>
      </div>
    </div>
    <div class="vx-row mb-6 text-center">
      <div class="vx-col sm:w-1/4 w-full"></div>
      <div class="vx-col sm:w-1/4 w-full">
        <span>{{$t('Syllabus.grade')}}</span>
      </div>
      <div class="vx-col sm:w-1/4 w-full">
        <div :key="index"
              v-for="(item,index) in section.grades" >
              <div >{{ index+1 }} - {{item.name}}</div>

              </div>
      </div>
    </div>
  </vx-card>
</template>


<script>
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const DivisionRepository = RepositoryFactory.get(
  'ConfigurationModule',
  'division'
)

export default {
  name: 'Show',
  data () {
    return {
      id: '',

      section: {}
    }
  },
  created () {
    this.id = this.$route.params.id
    this.getsectionData(this.id)
  },
  methods: {
    getsectionData (id) {
      DivisionRepository.GetSection(id).then(res => {
        this.section = res.data
      })
    }
  }
}
</script>

<style  scoped>
.background-none {
  background-color: transparent !important;
  color: rgb(160, 152, 152);
  cursor: pointer;
}
</style>
