<template>
  <div>
    <vx-card title>
      <!--add emails-->

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/3 w-full"></div>
        <div class="vx-col sm:w-1/3 w-full">
          <div class="con-img ml-3">
            <img
              :src="student.photo"
              alt="user-img"
              width="40"
              height="40"
              class="rounded-full shadow-md cursor-pointer block"
            />
          </div>
        </div>
      </div>

      <div class="vx-row mb-6 justify-center">
        <h4>{{ student.full_name }}</h4>
      </div>

      <div class="vx-row mb-6 justify-center">
        <h6>{{ student.selected_division }}</h6>
        -
        <h6>{{ student.selected }}</h6>
        -
        <h6>{{ student.academic_year }}</h6>
      </div>

      <div class="vx-row mb-6 justify-center">
        <h6>Chronological Age: {{ student.chronological_age }}</h6>
      </div>

      <div class="vx-row mb-10 justify-center">
        <h6>Developmental Age: {{ student.developmental_age }}</h6>
      </div>

      <div class="vx-row mb-10 justify-center"></div>
      <div class="vx-row mb-6 mt-10 justify-center">
        <vs-chip class="ag-grid-cell-chip pl-6 pr-6 pt-4 pb-4">
          <h6>
            {{
              status_list.filter((status) => {
                return status.value == evaluation.application.status;
              })[0].text
            }}
          </h6>
        </vs-chip>
      </div>

      <div class="vx-row mb-6 mt-10 items-center">
        <div class="vx-col sm:w-3/9">
          <h6>{{ $t("evaluation.actions") }}</h6>
        </div>
        <div class="vx-col sm:w-4/7">
          <vs-select
            autocomplete
            :placeholder="`${$t('evaluation.actions')}`"
            class="selectExample"
            @change="
              $emit('update:evaluation:application', evaluation.application)
            "
            v-model="evaluation.application.status"
            :disabled="status_list.length > 0 ? false : true"
          >
            <vs-select-item
              autocomplete
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item, index) in status_list"
            />
          </vs-select>
        </div>
      </div>

      <div v-show="showMeetingModal" class="vx-row mb-6 mt-10 justify-center">
        <div class="vx-col sm:w pt-3" v-if="showMeetingModal">
          <flat-pickr
            @on-change="
              $emit('update:evaluation:application', evaluation.application)
            "
            :config="configdateTimePicker"
            v-model="evaluation.application.meeting_timestamp"
            placeholder="Date Time"
            name="meetingDate"
            v-validate="'required'"
          />
        </div>
        <span
          v-if="issueSubmitted && errors.has('meetingDate')"
          class="text-danger text-sm"
          >{{ errors.first("meetingDate") }}</span
        >
        <div class="vx-col sm:w pt-3">
          <vs-select
            autocomplete
            :placeholder="`${$t('evaluation.type')}`"
            class="selectExample"
            @change="
              $emit('update:evaluation:application', evaluation.application)
            "
            v-model="evaluation.application.type"
            :disabled="status_list.length > 0 ? false : true"
          >
            <vs-select-item
              @change="
                $emit('update:evaluation:application', evaluation.application)
              "
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item, index) in status_list"
            />
          </vs-select>
        </div>
        <div class="vx-col sm:w pt-3">
          <vs-select
            autocomplete
            :placeholder="`${$t('evaluation.gate')}`"
            class="selectExample"
            @change="
              $emit('update:evaluation:application', evaluation.application)
            "
            v-model="evaluation.application.gate"
            :disabled="status_list.length > 0 ? false : true"
          >
            <vs-select-item
              @change="
                $emit('update:evaluation:application', evaluation.application)
              "
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item, index) in status_list"
            />
          </vs-select>
        </div>
        <div class="vx-col sm:w pt-3">
          <vs-select
            autocomplete
            :placeholder="`${$t('evaluation.building')}`"
            class="selectExample"
            @change="
              $emit('update:evaluation:application', evaluation.application)
            "
            v-model="evaluation.application.building"
            :disabled="status_list.length > 0 ? false : true"
          >
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item, index) in status_list"
            />
          </vs-select>
        </div>
      </div>

      <!--      <div class="vx-row">-->

      <!--        <div class="vx-col sm:w-3/3 w-full ml-auto">-->
      <!--          <vs-button class="mr-3 mb-2" @click="">{{ $t("basic.submit") }}</vs-button>-->
      <!--          <vs-button color="warning" type="border" class="mb-2">{{ $t("basic.close") }}</vs-button>-->
      <!--        </div>-->
      <!--      </div>-->

      <!-- CODE -->
    </vx-card>
  </div>
</template>
<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const EvaluationRepository = RepositoryFactory.get('AdmissionModule', 'evaluations')
export default {
  components:{
    flatPickr
  },
  props:{
    type:{
      type:String
    },
    issueSubmitted:{
      type:Boolean
    }
  },
  data () {
    return {
      configdateTimePicker: {
        enableTime: true,
        dateFormat: 'd-m-Y H:i'
      },

      student:{
        photo:require('@/assets/images/avatar.png'),
        full_name: 'Karim Fawzy',
        selected_division: 'British',
        selected: 'GCSE',
        academic_year: '2018/2019',
        chronological_age: '6Y-3M-12D',
        developmental_age: '6Y-3M-12D'
      },
      status_list:[
        {text:this.$t('evaluation.OnAssessment'), value:2},
        {text:this.$t('evaluation.ScheduleMeeting'), value: 5}
      ],
      evaluation: {
        application: {
          status: 2,
          id: '1',
          meeting_timestamp: '',
          type: '',
          gate: '',
          building: ''
        },
        evaluation_notes: [
          {
            user_type: 'registerer',
            notes: ['']
          },
          {
            user_type: 'assessor',
            notes: ['']
          },
          {
            user_type: 'principal',
            notes: ['']
          },
          {
            user_type: 'director',
            notes: ['']
          }
        ],

        evaluation_data: [
          {
            user_type: 'registerer',
            entry_assessment: ''
          },
          {
            user_type: 'assessor',
            entry_assessment: '',
            observations: '',
            reading: '',
            writing: '',
            oral_fluency: ''
          },
          {
            user_type: 'principal',
            entry_assessment: '',
            observations: '',
            reading: '',
            writing: '',
            oral_fluency: '',
            principal_review: ''
          },
          {
            user_type: 'director',
            entry_assessment: '',
            observations: '',
            reading: '',
            writing: '',
            oral_fluency: '',
            principal_review: '',
            director_review: ''
          }
        ],
        criteria_ratings:
          []
      },
      showMeetingModal: false
    }
  },
  mounted () {

    this.getData(this.$route.params.id)


  },
  methods: {
    getData (id) {

      EvaluationRepository.getAppDetails(id).then(res => {
        //this.student=res.data.applicant
        this.student.full_name = res.data.applicant.name
      })

    },
    removeNote (index) {
      this.evaluation.notes.splice(index + 1)
    },
    addNote () {
      if (this.evaluation.notes[0] !== '') {
        this.evaluation.notes.push(this.evaluation.notes[0])
        this.evaluation.notes[0] = ''
      }
    }
  },
  computed : {
    addedNotes () {
      return this.evaluation.notes.length > 1 ? this.evaluation.notes.slice(1, this.evaluation.notes.length) : []
    },
    evalApplication (evaluation_application) {
      if (this.type == 'edit') return this.$attrs['evaluation:application']
    }

  },
  watch:{
    'evaluation.application.status' (val) {
      if (val == 5) this.showMeetingModal = true
      else this.showMeetingModal = false
    },
    'evalApplication' (app) {
      if (this.type == 'edit') this.evaluation.application = app
    }
  }
}
</script>

<style scoped>
.background-none {
  background-color: transparent !important;
}
.justify-center {
  justify-content: center;
}
</style>
