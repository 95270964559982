export default {
  e_exam: 'E-Exam',
  paper_exam: 'paper exam',
  choose_class: 'choose class',
  choose_grade: 'choose grade',
  choose_subject: 'choose subject',
  choose_division: 'choose division',
  choose_school: 'choose division',
  choose_date: 'choose date',
  choose_type: 'choose type',
  class: 'class',
  grade: 'grade',
  subject: 'subject',
  school: 'school',
  division: 'division',
  classes: 'classes',
  grades: 'grades',
  subjects: 'subjects',
  schools: 'schools',
  divisions: 'divisions',
  date: 'date',
  type: 'type',
  tag: 'tag',
  select_date: 'select date',
  select_all: 'select all',
  submit: 'submit',
  update: 'update',
  allowed_time: 'Allowed Time',
  time_based: 'time based',
  score: 'score',
  publish_automatically: 'Publish automatically',
  auto_publish: 'auto publish',
  add_another: 'add another',
  remove: 'remove',
  duration: 'duration',
  reset: 'reset',
  reset_all: 'reset all',
  question: 'question',
  enter_question_title: 'enter question title',
  description: 'description',
  title:'title',
  time: 'time',
  submission: 'Submission',
  time_period: 'Time Period',
  fixed_time: 'fixed time',
  start_date:'start date',
  end_date:'end date',
  start_time:'start time',
  end_time: 'end time',
  fixed_period: 'fixed period',
  open_time: 'Open time',
  save_as_draft_and_close: 'Save as draft and close',
  done: 'done',
  add:'add',
  cancel: 'cancel',
  choose_subjects: 'subjects',
  choose_schools: 'choose schools',
  choose_divisions: 'choose divisions',
  choose_sections:'choose sections',
  choose_branches: 'choose branches',
  choose_time:'choose time'


}
