import axios from 'axios'

const resource = '/round'
export default {
  getDropDownData () {
    return axios.get(`${resource}/create`)

  },

  getUsers () {
    return axios.get('/search')
  },

  createRound (payload) {
    return axios.post(`${resource}/store`, payload)
  },

  editRound (id, payload) {
    return axios.put(`${resource}/${id}`, payload)
  },


  getGrades (id) {
    return axios.get(`/division/${id  }/grades`)
  },
  getDivsGrades (payload) {
    return axios.post('/division/getDevsGrades', payload)
  },
  getSchoolDivisions (payload) {
    return axios.post('/division/schoolDivisions', payload)
  },
  getDivisionsSections (payload) {
    return axios.post('/section/sectionsInDivisions', payload)
  },
  getPermission () {
    return axios.get('role/getPermission')
  },
  createRole (payload) {
    return axios.post('/role', payload)
  },
  editRole (id, payload) {
    return axios.put(`role/${id}`, payload)
  },
  getRoles (id) {
    return axios.get(`role/${id}`)
  },

  changeStatus (id, status) {
    return axios.get(`role/${id}/status/${status}`)
  },
  getAllRoles () {
    return axios.get('/role/admin-roles')
  },
  getAllUsers () {
    return axios.get('user/employee')
  },
  getAllUserNotHaverRoles () {
    return axios.get('/users')
  },
  createUsersRole (payload) {
    return axios.post('user/role', payload)
  },
  editUsersRole (id, payload) {
    return axios.put(`user/${id}/role`, payload)
  },
  getUsersRole (id) {
    return axios.get(`user/${id}/role`)
  },
  changeUserStatus (id, status) {
    return axios.get(`user/${id}/role/status/${status}`)
  },
  addUser (payload) {
    return axios.post('/user/create', payload)
  }
}
