<template>
  <div>
    <div class="mb-4 w-full" v-if="$slots.topRow">
      <slot name="topRow"></slot>
    </div>
    <!-- Create button Row -->
    <div class="vx-row justify-start items-stretch" id="listPageWithCreate">
      <div
        class="w-full vx-col"
        :class="{'md:w-3/4': showCreateBtn}"
        v-if="$slots.leftSideButtonContent">
        <slot name="leftSideButtonContent"></slot>
      </div>
      <div
        class="w-full vx-col mb-4"
        :class="{
          'md:w-1/4': $slots.leftSideButtonContent
        }"
        v-if="showCreateBtn">
        <vs-button
          v-if="route"
          class="block h-full border-box-shadow"
          :class="
            $slots.leftSideButtonContent
              ? 'w-full px-2'
              : `w-full md:w-auto ${$vs.rtl ? 'ml-0 mr-auto' : 'mr-0 ml-auto'}`
          "
          id="CreateBtn"
          :to="route"
          >{{ createBtnName || this.$t('basic.create') }}
        </vs-button>
        <slot v-else name="rightSideButtonContent"></slot>
      </div>
    </div>
    <vs-divider class="" />
    <div class="mt-4">
      <BasicListPage :syncReduceButton="syncReduceButton">
        <template slot="leftSide">
          <!-- List Data -->
          <div class="block w-full">
            <slot name="listDataContent"></slot>
          </div>
        </template>
        <!-- Right Sidebar -->
        <template slot="rightSide" v-if="$slots.rightSide">
          <slot name="rightSide"></slot>
        </template>
      </BasicListPage>
    </div>
  </div>
</template>
<script>
import BasicListPage from './Basic.vue'
export default {
  name: 'ListPageWithCreateButtonSeparateRow',
  components: {BasicListPage},
  props: {
    showCreateBtn: {
      type: Boolean,
      default: true
    },
    syncReduceButton: {
      type: Boolean,
      default: true
    },
    createRoutName: {
      type: String
    },
    createRoute: {
      type: Object
    },
    createBtnName: {
      type: String
    }
  },
  computed: {
    route() {
      return this.createRoute?.name
        ? this.createRoute
        : this.createRoutName
        ? {name: this.createRoutName}
        : null
    }
  }
}
</script>
<style>
#CreateBtn {
  min-height: 55px;
}
</style>
<style scoped>
@media (min-width: 768px) {
  #listPageWithCreate .md\:w-1\/6 {
    width: 12%;
  }
  #listPageWithCreate .md\:w-5\/6 {
    width: 87%;
  }
}
</style>
