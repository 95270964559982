<template>
  <div>
    <div
      ref="assignmentForm"
      id="assignmentForm"
      class="vs-con-loading__container"
      v-if="assignmentLoading"
    />
    <vx-card v-if="taskItems && !loadingData" class="create-unit vs-modal">
      <form @submit.prevent="submitForm">
        <div class="vx-row mb-6">
          <vs-divider class="vx-col mb-4 w-full">
            {{ $t('assignment.assignment_setting') }}
          </vs-divider>
          <div class="vx-col mb-4 lg:w-1/2 w-full">
            <div class="vs-input--label">
              {{ $t('form.choose_subject') }} <sup class="text-danger">*</sup>
            </div>
            <v-select
              v-model="taskItems.division_subject_id"
              v-validate="'required'"
              :class="{ 'validation-error': errors.has('subjects') }"
              :clearable="true"
              :closeOnSelect="true"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              :options="subjects"
              label="name"
              name="subjects"
              @input="getTeacherClasses"
            />
            <p v-show="errors.has('subjects')" class="text-danger text-sm">
              {{ $t('form.subjects_is_required') }}
            </p>
          </div>
          <div class="vx-col mb-4 lg:w-1/2 w-full">
            <div class="vs-input--label">
              {{ $t('assignment.choose_classes') }}
              <sup class="text-danger">*</sup>
            </div>
            <v-select
              v-model="taskItems.class_teacher_id"
              v-validate="'required'"
              multiple
              :class="{ 'validation-error': errors.has('class') }"
              :clearable="true"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              :options="filteredClasses"
              :disabled="!classes.length"
              label="name"
              name="class-room"
              @input="chooseClass"
            />
            <!-- <vs-input
              v-model="taskItems.class_teacher_id"
              class="hidden"
              v-validate="'required'"
            /> -->
            <span v-if="errors.has('class-room')" class="text-danger text-sm">{{
              errors.first('class-room') | text_formatter
            }}</span>
          </div>

          <div class="vx-col mb-4 lg:w-1/2 w-full">
            <div class="vs-input--label">
              {{ $t('assignment.Summative_Formative') }}
              <sup class="text-danger">*</sup>
            </div>
            <v-select
              v-model="taskItems.is_formative"
              v-validate="'required'"
              :class="{ 'validation-error': errors.has('Summative/Formative') }"
              :clearable="true"
              :closeOnSelect="true"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              :options="summative_formative"
              label="summative_formative"
              name="summative_formative"
            />
            <p
              v-show="errors.has('Summative/Formative')"
              class="text-danger text-sm"
            >
              {{ $t('assignment.Summative_Formative_Is_Required') }}
            </p>
          </div>
          <div class="vx-col mb-4 lg:w-1/2 w-full">
            <div class="vs-input--label">
              {{ $t('basic.Types') }} <sup class="text-danger"> *</sup>
            </div>
            <v-select
              v-model="taskItems.type"
              v-validate="'required'"
              :class="{ 'validation-error': errors.has('types') }"
              :clearable="true"
              :closeOnSelect="true"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              :options="types"
              label="types"
              name="types"
            />

            <p v-show="errors.has('types')" class="text-danger text-sm">
              {{ $t('assignment.types_is_required') }}
            </p>
          </div>

          <div
            class="vx-col mb-4 w-full"
            v-if="
              taskItems.class_teacher_id.length > 0 && isCurrentSection('MYP')
            "
          >
            <div class="vs-input--label">
              {{ $t('assignment.Choose_Assess_Criteria') }}
              <sup class="text-danger">*</sup>
            </div>
            <v-select
              v-model="taskItems.criteria"
              class="w-1/2"
              v-validate="'required'"
              :class="{ 'validation-error': errors.has('criteria') }"
              :clearable="true"
              :closeOnSelect="false"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              :options="currentCriteria"
              multiple
              label="title"
              name="criteria"
            />
            <p v-show="errors.has('criteria')" class="text-danger text-sm">
              {{ $t('assignment.Criteria_Is_Required') }}
            </p>
          </div>

          <!-- <div class="vx-col mb-4 lg:w-1/2 w-full">
            <div class="vs-input--label mb-2">
              {{ $t('basic.students') }} <sup class="text-danger">*</sup>
            </div>
            <div class="flex">
              <vs-radio
                vs-value="students"
                vs-name="student type"
                v-model="taskItems.student_type"
                color="primary"
                class="mt-2 mr-6"
              >
                {{ $t('basic.students') }}
              </vs-radio>

              <vs-radio
                vs-value="breakout_groups"
                vs-name="student type"
                disabled
                v-model="taskItems.student_type"
                color="primary"
                class="mt-2 mr-6"
              >
                {{ $t('basic.breakout_groups') }}
              </vs-radio>
            </div>

            <p v-show="errors.has('student type')" class="text-danger text-sm">
              {{ errors.first('student type') }}
            </p>
          </div> -->

          <div class="vx-col mb-4 lg:w-1/2 w-full">
            <div class="vs-input--label">
              {{ $t('basic.students') }} <sup class="text-danger">*</sup>
            </div>
            <v-select
              v-model="taskItems.student_ids"
              v-validate="'required'"
              :class="{ 'validation-error': errors.has('students') }"
              :clearable="true"
              :showAllSelected="true"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              :options="students"
              label="name"
              multiple
              :disabled="!students.length"
              name="students"
            />

            <p v-show="errors.has('students')" class="text-danger text-sm">
              {{ $t('assignment.At_least_one_student_is_required') }}
            </p>
          </div>

          <vs-divider class="vx-col mb-4 w-full">
            {{ $t('assignment.assignment_details') }}
          </vs-divider>
          <div class="vx-col mb-4 w-full">
            <div class="vs-input--label">
              {{ $t('basic.title') }} <sup class="text-danger">*</sup>
            </div>
            <vs-input
              v-model="taskItems.title"
              v-validate="{ required: true, max: 150 }"
              :class="{ 'validation-error': errors.has('title') }"
              class="w-full mb-2"
              max="150"
              name="title"
            />
            <p v-show="errors.has('title')" class="text-danger text-sm">
              {{ errors.first('title') }}
            </p>
          </div>

          <div class="vx-col mb-4 w-full">
            <div class="vs-input--label">
              {{ $t('basic.description') }} <sup class="text-danger">*</sup>
            </div>
            <div class="vx-col w-full">
              <vs-textarea
                v-model="taskItems.description"
                v-validate="'required'"
                :class="{ 'validation-error': errors.has('description') }"
                class="w-full mb-0"
                label=""
                name="description"
              />
            </div>
            <span
              v-if="errors.has('description')"
              class="text-danger text-sm w-full"
            >
              {{ errors.first('description') }}
            </span>
          </div>

          <div class="vx-col w-full">
            <vs-row class="my-2" vs-w="12">
              <upload-button @uploadedFiles="uploadedFiles"></upload-button>
              <vs-col
                class="my-1 flex-col"
                v-if="taskItems.attachments.length"
                vs-justify="start"
                vs-lg="12"
                vs-sm="12"
                vs-type="flex"
                vs-xs="12"
              >
                <div class="w-full flex flex-row flex-wrap">
                  <div v-for="(file, idx) in taskItems.attachments" :key="idx">
                    <feather-icon
                      @click="removeAttachment(file, idx)"
                      class="mr-2 remove-attachment"
                      icon="XIcon"
                      svgClasses="h-5 w-5"
                    />
                    <file-previewer
                      :url="file.attachment_url"
                      :urlType="file.type"
                      class="ml-1 my-4 mr-4"
                    ></file-previewer>
                  </div>
                </div>
              </vs-col>
            </vs-row>
          </div>

          <vs-divider class="vx-col mb-4 w-full">
            {{ $t('assignment.submission_details') }}
          </vs-divider>

          <div class="vx-col mb-4 lg:w-1/2 w-full">
            <div class="vs-input--label mb-2">
              {{ $t('assignment.submission_type') }}
              <sup class="text-danger">*</sup>
            </div>
            <div class="flex">
              <vs-radio
                :vs-value="false"
                vs-name="submission type"
                v-model="taskItems.is_multiple"
                color="primary"
                class="mt-2 mr-6"
              >
                {{ $t('assignment.single_submission') }}
              </vs-radio>

              <vs-radio
                :vs-value="true"
                vs-name="submission type"
                v-model="taskItems.is_multiple"
                color="primary"
                class="mt-2 mr-6"
              >
                {{ $t('assignment.multiple_submission') }}
              </vs-radio>
            </div>

            <p
              v-show="errors.has('submission type')"
              class="text-danger text-sm"
            >
              {{ errors.first('submission type') }}
            </p>
          </div>

          <div class="vx-col my-4 w-full lg:flex justify-between">
            <div class="vx-row w-full">
              <div class="w-full vx-col mb-4 lg:m-0 lg:w-1/6">
                <div class="vs-input--label mb-2">
                  {{ $t('assignment.launch_date') }}
                  <sup class="text-danger">*</sup>
                </div>
              </div>
              <div class="w-full vx-col mb-4 lg:m-0 lg:w-1/3 inline-block">
                <!-- assignment_date -->
                <flat-pickr
                  v-model="taskItems.launch_date"
                  v-validate="'required'"
                  @on-change="resetMinimumDueDate"
                  :config="configLunchDate"
                  class="w-full"
                  :disabled="editmode"
                  :name="$t('assignment.launch_date')"
                  :placeholder="$t('assignment.launch_date')"
                />
                <span
                  v-if="errors.has($t('assignment.launch_date'))"
                  class="text-danger text-sm w-full"
                  >{{ errors.first($t('assignment.launch_date')) }}</span
                >
              </div>
              <div class="w-full vx-col mb-4 lg:m-0 lg:w-1/3 inline-block">
                <!-- assignment_time -->
                <flat-pickr
                  v-model="taskItems.launch_time"
                  v-validate="'required'"
                  :config="configLunchTime"
                  class="w-full"
                  :disabled="!taskItems.launch_date || editmode"
                  :name="$t('assignment.launch_time')"
                  :placeholder="$t('assignment.launch_time')"
                />
                <span
                  v-if="errors.has($t('assignment.launch_time'))"
                  class="text-danger text-sm w-full"
                  >{{ errors.first($t('assignment.launch_time')) }}</span
                >
              </div>
            </div>
          </div>

          <div class="vx-col my-4 w-full lg:flex justify-between">
            <div class="vx-row w-full">
              <div class="w-full vx-col mb-4 lg:m-0 lg:w-1/6">
                <div class="vs-input--label mb-2">
                  {{ $t('basic.due_date') }}
                  <sup class="text-danger">*</sup>
                </div>
              </div>
              <div
                class="w-full vx-col mb-4 lg:m-0 inline-block"
                :class="{ 'lg:w-1/4': showAgenda, 'lg:w-1/3': !showAgenda }"
              >
                <!-- assignment_date -->
                <flat-pickr
                  v-model="taskItems.assignment_date"
                  v-validate="'required'"
                  :config="configDueDate"
                  class="w-full"
                  :name="$t('basic.due_date')"
                  :placeholder="$t('basic.due_date')"
                  :disabled="!taskItems.launch_date"
                  @on-change="getDailyAgenda"
                />
                <span
                  v-if="errors.has($t('basic.due_date'))"
                  class="text-danger text-sm w-full"
                  >{{ errors.first($t('basic.due_date')) }}</span
                >
              </div>
              <div
                class="w-full vx-col mb-4 lg:m-0 inline-block"
                :class="{ 'lg:w-1/4': showAgenda, 'lg:w-1/3': !showAgenda }"
              >
                <!-- assignment_time -->
                <flat-pickr
                  v-model="taskItems.assignment_time"
                  v-validate="'required'"
                  :config="configDueTime"
                  class="w-full"
                  :name="$t('basic.due_time')"
                  :placeholder="$t('basic.due_time')"
                  :disabled="!taskItems.assignment_date"
                />
                <span
                  v-if="errors.has($t('basic.due_time'))"
                  class="text-danger text-sm w-full"
                  >{{ errors.first($t('basic.due_time')) }}</span
                >
              </div>
              <div class="w-full vx-col mb-4 lg:m-0 lg:w-1/4" v-if="showAgenda">
                <vs-button
                  type="line"
                  @click="showDailyAgenda = !showDailyAgenda"
                  >{{ $t('assignment.show_agenda') }}</vs-button
                >
              </div>
            </div>
          </div>

          <div class="px-2 vx-col w-full" v-if="showDailyAgenda">
            <vs-table class="mt-8" :data="dailyAgenda">
              <template slot="header">
                <h3>
                  {{ $t('assignment.assignment_daily_agenda_for') }} -
                  {{ taskItems.assignment_date }}
                </h3>
              </template>
              <template slot="thead">
                <vs-th> {{ $t('basic.assignment') }} </vs-th>
                <vs-th> {{ $t('basic.class') }} </vs-th>
                <vs-th> {{ $t('basic.subject') }} </vs-th>
                <vs-th> {{ $t('assignment.teacher') }} </vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr v-for="(day, index) in dailyAgenda" :key="index">
                  <vs-td :data="day.title">
                    {{ day.title }}
                  </vs-td>
                  <vs-td :data="day.class_teacher[0].class.name">
                    {{ day.class_teacher[0].class.name }}
                  </vs-td>

                  <vs-td :data="day.division_subject.name">
                    {{ day.division_subject.name }}
                  </vs-td>

                  <vs-td :data="day.user.name">
                    {{ day.user.name }}
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
          <vs-divider class="vx-col mb-4 w-full">
            {{ $t('assignment.another_details') }}
          </vs-divider>

          <div class="vx-col w-full">
            <ul class="con-s">
              <li class="switch-container">
                <vs-checkbox v-model="taskItems.have_comments" />
                <label for="">
                  {{ $t('assignment.Activate_student_Comments') }}
                </label>
              </li>
              <li class="switch-container">
                <vs-checkbox v-model="taskItems.have_attachments" />
                <label for="">
                  {{ $t('assignment.Allow_uploading_document') }}
                </label>
              </li>
              <li class="switch-container">
                <vs-checkbox v-model="taskItems.is_disable_task_date" />
                <label for="">
                  {{
                    $t(
                      'assignment.Prevent_students_of_submitting_this_task_after_passing_due_date'
                    )
                  }}</label
                >
              </li>
              <li class="switch-container">
                <vs-checkbox
                  name="grade_book"
                  v-model="taskItems.hide_from_grading"
                />
                <label for="grade_book">
                  {{
                    $t(
                      'assignment.assessment_to_be_included_into_the_grade_book'
                    )
                  }}</label
                >
              </li>
            </ul>
          </div>
          <div
            class="
              vx-col
              mt-6
              w-full
              flex flex-wrap
              items-center
              justify-center
              flex-col
              md:flex-row md:justify-between
            "
          >
            <vs-button
              class="mb-2 md:mb-0"
              color="warning"
              type="border"
              @click="returnTolistPage"
              >{{ $t('form.cancel') }}
            </vs-button>
            <!-- <vs-button
              class="mb-2 md:mb-0"
              @click="saveAsDraft"
              color="gray"
              v-if="taskItems.status.key != 'active'"
              >{{ $t('form.save_as_draft') }}
            </vs-button> -->
            <vs-button
              type="filled"
              :disabled="isFormSubmitted"
              @click="submitForm()"
              class="mb-2 md:mb-0"
              >{{ $t('form.launch') }}
            </vs-button>
          </div>
        </div>
      </form>
    </vx-card>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import VxTooltip from '@/layouts/components/vx-tooltip/VxTooltip.vue'
import { Validator } from 'vee-validate'
import uploadButton from '@/modules/unit-planner/components/uploadButton/uploadButton.vue'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'

const AssignmentRepository = RepositoryFactory.get(
  'AssignmentsModule',
  'assignments'
)

export default {
  name: 'assignment-form',
  components: {
    Validator,
    Datepicker,
    flatPickr,
    uploadButton,
    VxTooltip
  },
  data () {
    return {
      isFormSubmitted: false,
      configDueDate: {
        minDate: null,
        dateFormat: 'Y-m-d'
      },
      configLunchDate: {
        minDate: null,
        dateFormat: 'Y-m-d'
      },
      configLunchTime: {
        minDate: null,
        enableTime: true,
        time_24hr: true,
        noCalendar: true,
        dateFormat: 'H:i'
      },
      configDueTime: {
        minDate: null,
        enableTime: true,
        time_24hr: true,
        noCalendar: true,
        dateFormat: 'H:i'
      },
      updateDueTimeInterval: null,
      updateTimeInterval: null,

      editmode: false,
      firstTime: false,
      assignmentLoading: false,
      assessmentEditID: null,

      loadingData: false,
      datePickerSettings: {
        format: 'dd/MM/yyyy',
        disabledDates: {
          to: null, // Disable all dates up to specific date
          from: null // Disable all dates after specific date
        },
        dateValue: null
      },
      ////task data
      datetimeLaunchCheck: false,
      classes: [],
      summative_formative: ['Summative', 'Formative'],
      labels: [' Conceptual', 'Factual'],
      types: [' Quiz', 'Homework', 'Essay', 'Paper', 'Test', 'Project'],
      students: [],
      grades: [],
      poiFocus: [],
      subjects: [],
      dailyAgenda: [],
      currentCriteria: [],
      selectedCriteria: null,
      datetime_launch: false,
      showDailyAgenda: false,
      currentSection: null,
      taskItems: {
        student_type: 'students',
        is_multiple: false,
        title: '',
        type: 'Homework',
        label: '',
        attachments: [],
        have_comments: true,
        have_attachments: true,
        hide_from_grading: true,
        is_disable_task_date: false,
        // datetime_launch: '',
        is_formative: 'Formative',
        description: '',
        class_teacher_id: [],
        division_subject_id: '',
        student_ids: [],
        assignment_time: '23:30',
        assignment_date: '',
        launch_date: '',
        launch_time: '',
        status: '',
        criteria: [],
        style: 'normal'
      }
    }
  },
  computed: {
    showAgenda () {
      return (
        this.taskItems.assignment_date && this.taskItems.class_teacher_id.length
      )
    },
    filteredClasses () {
      if (this.taskItems.class_teacher_id.length) {
        const classesSectionIds = this.taskItems.class_teacher_id.map(
          (section) => section.section.id
        )
        return this.classes.filter((item) => classesSectionIds.includes(item.section.id)
        )
      } else {
        return this.classes
      }
    },
    daysDiff () {
      return this.daysBetweenDates(
        new Date(
          `${this.taskItems.assignment_date} ${this.taskItems.assignment_time}`
        ),
        this.currentDate
      )
    }
  },
  watch: {
    'taskItems.datetime_launch' () {
      this.datetime_launch = true
    },
    'taskItems.launch_date' (val) {
      if (val === this.dateNow() && !this.editmode) {
        this.$set(this.configLunchTime, 'minDate', this.currentTime())
      } else {
        clearInterval(this.updateTimeInterval)
        this.$set(this.configLunchTime, 'minDate', null)
      }
    },
    'taskItems.is_multiple' (val) {
      if (!this.firstTime) {
        this.resetMinimumDueDate('', this.taskItems.launch_date)
      }
    },
    'taskItems.assignment_date' (val) {
      if (val === this.dateNow() && !this.firstTime) {
        this.resetMinimumDueTime()
      } else {
        this.$set(this.configDueTime, 'minDate', null)
      }
    }
  },
  methods: {
    //////////////////////////////////////
    // Get Selet data
    /////////////////////////////////////
    async getStudentsOfTeacherClass (classIds) {
      const { grade_id, division_id } = classIds[0]
      const data = {
        class_ids: classIds.map((item) => item.class.id),
        grade_ids: [grade_id],
        division_id,
        subject_id: this.taskItems.division_subject_id.subject_id
      }
      return new Promise((resolve, reject) => {
        AssignmentRepository.getStudentsOfTeacherClass(data)
          .then((res) => {
            const data = [...res.data]
            this.taskItems.student_ids = []
            this.students = []
            this.students.push(...data)
            this.taskItems.student_ids.push(...data)
            resolve(data)
          })
          .catch((err) => {
            console.error(err)
            reject(err)
          })
      })
    },
    getTeacherClasses () {
      const body = {
        subject_id: this.taskItems.division_subject_id.subject_id
      }

      //** Show Loader **//
      if (!this.editmode) {
        this.taskItems.class_teacher_id = []
        this.$vs.loading()
      }
      //** Get Grades Subjects **//
      return new Promise((resolve, reject) => {
        AssignmentRepository.getTeacherClasses(body)
          .then((res) => {
            this.classes = res.data.map((item) => {
              return {
                id: item.id,
                name: item.class.name,
                section: item.section,
                ...item
              }
            })
            if (this.classes.length === 1) {
              this.taskItems.class_teacher_id = this.classes
              this.chooseClass(this.classes)
            }
            resolve(this.classes)
          })
          .catch((err) => {
            console.error(err)
            reject(err)
          })
          .finally(() => {
            if (!this.editmode) this.$vs.loading.close()
          })
      })
    },
    getTeachersSubjects () {
      return new Promise((resolve, reject) => {
        AssignmentRepository.getTeachersSubjects()
          .then((res) => {
            const subjects = res.data
            this.subjects = subjects
            if (subjects.length === 1) {
              const singleSubject = subjects[0]
              this.taskItems.division_subject_id = singleSubject
              this.getTeacherClasses(singleSubject)
            }
            resolve(subjects)
          })
          .catch((err) => {
            console.error(err)
            reject(err)
          })
      })
    },
    chooseClass (data) {
      const dataDate = {
        assignment_date: this.taskItems.assignment_date,
        class_ids: this.taskItems.class_teacher_id.map((item) => item.class.id)
      }
      AssignmentRepository.getDailyAgenda(dataDate)
        .then((res) => {
          this.dailyAgenda = res.data
        })
        .catch((err) => {
          console.log(err)
        })
      this.changeCriteria()
      this.getStudentsOfTeacherClass(data)
    },
    changeCriteria () {
      return new Promise((resolve) => {
        this.currentCriteria = this.taskItems.division_subject_id.criteria.map(
          (item) => {
            return {
              key: item.key,
              count: item.count,
              title: `${item.key.toUpperCase()} - ${item.title} (${
                item.count
              }) `
            }
          }
        )
        if (this.isCurrentSection('PYP')) {
          this.taskItems.criteria = this.currentCriteria
        }
        resolve(this.currentCriteria)
      })
    },
    getDailyAgenda (selectedDates, dateStr) {
      const data = {
        assignment_date: dateStr,
        class_ids: this.taskItems.class_teacher_id.map((item) => item.class.id)
      }
      AssignmentRepository.getDailyAgenda(data)
        .then((res) => {
          this.dailyAgenda = res.data
        })
        .catch((err) => {
          console.log(err)
        })
    },

    //////////////////////////////////////
    // Change Date Method
    /////////////////////////////////////
    dateNow () {
      return this.fixDate(new Date())
    },
    currentTime () {
      let date = new Date()
      date = `${date.getHours()}:${date.getMinutes()}`
      return date
    },
    addMintuesToCurrentTime (number) {
      let time = new Date()
      time.setMinutes(time.getMinutes() + number)
      time = `${time.getHours()}:${time.getMinutes()}`
      return time
    },
    addMintuesToSpecificDate (dateTime, number) {
      let time = new Date(dateTime)
      time.setMinutes(time.getMinutes() + number)
      time = `${time.getHours()}:${time.getMinutes()}`
      return time
    },
    resetMinimumDueDate (selectedDates, dateStr, instance) {
      if (!this.firstTime) {
        const date = new Date(dateStr)
        if (Boolean(this.taskItems.is_multiple) === false) {
          date.setDate(date.getDate() + 1)
          this.taskItems.assignment_date = this.fixDate(date)
        } else {
          date.setDate(date.getDate() + 10)
          this.taskItems.assignment_date = this.fixDate(date)
        }
        this.$set(this.configDueDate, 'minDate', dateStr)
      }
    },
    async assignDefaultValueToLaunchDate () {
      this.$set(this.configLunchDate, 'minDate', this.dateNow())
      this.taskItems.launch_date = this.dateNow()
      this.resetMinimumDueDate('', this.dateNow())
    },
    assignDefaultValueToLaunchTime () {
      this.$set(this.configLunchTime, 'minDate', this.currentTime())
      this.taskItems.launch_time = this.addMintuesToCurrentTime(0)

      this.updateTimeInterval = setInterval(() => {
        if (
          this.taskItems.launch_date === this.dateNow() &&
          !this.checkTimeDiff(`${this.dateNow()} ${this.taskItems.launch_time}`)
        ) {
          this.$set(this.configLunchTime, 'minDate', this.currentTime())
          this.taskItems.launch_time = this.addMintuesToCurrentTime(0)

          if (this.taskItems.assignment_date === this.dateNow()) this.resetMinimumDueTime()
        }
      }, 1000)
    },
    resetMinimumDueTime (time = '') {
      const defaultValue = this.addMintuesToSpecificDate(
        `${this.taskItems.launch_date} ${this.taskItems.launch_time}`,
        10
      )
      this.$set(this.configDueTime, 'minDate', defaultValue)
      this.taskItems.assignment_time = defaultValue
    },
    handleDueTimeInEdit (time = '') {
      this.updateDueTimeInterval = setInterval(() => {
        if (
          !this.checkTimeDiff(
            `${this.dateNow()} ${this.taskItems.assignment_time}`
          )
        ) {
          this.$set(
            this.configDueTime,
            'minDate',
            this.addMintuesToCurrentTime(5)
          )
          this.taskItems.assignment_time = this.addMintuesToCurrentTime(10)
        } else this.$set(this.configDueTime, 'minDate', this.currentTime(5))
      }, 1000)
    },
    async setDateDefaultValue () {
      await this.assignDefaultValueToLaunchDate()
      this.assignDefaultValueToLaunchTime()
    },

    //////////////////////////////////////
    // Upload Methods
    /////////////////////////////////////
    async removeAttachment (attachment, index) {
      const file = {
          file_path: `${this.institutionAssetsLink}${attachment.attachment}`
        },
        payload = {
          attachments: [
            {
              attachment: attachment.attachment,
              attachment_url: file.file_path
            }
          ]
        }
      AssignmentRepository.deleteFile(payload)
        .then((res) => {
          this.taskItems.attachments = this.taskItems.attachments.filter(
            (a) => a.attachment !== attachment.attachment
          )
        })
        .catch((error) => {
          console.log(error)
        })
    },
    uploadedFiles (data) {
      this.taskItems.attachments.push(...data)
    },

    //////////////////////////////////////
    // Get Single Assignment & mapping data
    /////////////////////////////////////
    getTeacherSingleAssignment (id) {
      this.assignmentLoading = true
      this.VSLoading(this.$refs.assignmentForm)
      AssignmentRepository.getTeacherSingleAssignment(id).then((res) => {
        this.mappingData(res.data)
      })
    },
    mappingData ({ class_teacher, ...data }) {
      this.$set(this.configLunchDate, 'minDate', null)
      this.$set(this.configDueTime, 'minDate', null)
      this.$set(this.configDueDate, 'minDate', data.launch_date)

      this.taskItems = {
        ...data,
        hide_from_grading: !data.hide_from_grading,
        student_type: 'students',
        class_teacher_id: [],
        is_formative: data.is_formative ? 'Formative' : 'Summative',
        division_subject_id: data.division_subject
      }
      this.getTeachersSubjects().then(() => {
        const singleSubject = this.subjects.find(
          (item) => Number(item.id) === Number(data.division_subject.id)
        )
        this.taskItems.division_subject_id = singleSubject
        this.changeCriteria().then((allCriteria) => {
          const criteriaKeys = this.taskItems.criteria.map((obj) => obj.key)
          this.taskItems.criteria = allCriteria.filter((item) => criteriaKeys.includes(item.key)
          )
        })
      })

      this.getTeacherClasses().then(() => {
        const classessIds = class_teacher.map((item) => item.id)

        const class_teacher_id = this.classes.filter((item) => classessIds.includes(item.id)
        )
        this.taskItems.class_teacher_id = class_teacher_id

        // get Students
        this.getStudentsOfTeacherClass(this.taskItems.class_teacher_id).then(
          (res) => {
            this.taskItems.student_ids = []
            res.forEach((item) => {
              if (data.student_ids.includes(item.id)) {
                this.taskItems.student_ids.push(item)
              }
            })
          }
        )
      })

      this.stopVSLoading(this.$refs.assignmentForm)
      this.assignmentLoading = false
      this.$nextTick(() => {
        // if (data.assignment_date === this.dateNow()) {
        this.firstTime = false
        // this.handleDueTimeInEdit()
        // }
        // else if (daysDiff < 0) {
        //   console.log('1', this.taskItems.assignment_date)

        //   this.$set(this.configDueDate, 'minDate', this.dateNow())
        //   this.$set(this.taskItems, 'assignment_date', this.dateNow())
        //   this.handleDueTimeInEdit()
        // }
      })
    },

    //////////////////////////////////////
    // Submission Methods
    /////////////////////////////////////
    submitForm () {
      if (this.isFormSubmitted) return
      this.isFormSubmitted = true
      if (this.editmode) {
        this.editAssignment({})
      } else {
        this.submit({})
      }
    },
    submit ({ status = 'active' }) {
      const validData = this.$validator.validateAll()
      if (validData) {
        const data = {
          ...this.taskItems,
          class_teacher_ids: this.taskItems.class_teacher_id.map(
            (item) => item.id
          ),
          hide_from_grading: !this.taskItems.hide_from_grading,
          division_subject_id: this.taskItems.division_subject_id.id,
          student_ids: this.taskItems.student_ids.map((student) => student.id),
          status,
          is_formative: this.taskItems.is_formative !== 'Summative'
        }
        data.criteria = data.criteria.length
          ? data.criteria.map((item) => item.key)
          : []
        AssignmentRepository.saveAssignment(data)
          .then(() => {
            this.successMsg()
            this.returnTolistPage()
          })
          .catch((error) => {
            this.isFormSubmitted = false
            this.errMsg(error)
          })
      } else {
        this.isFormSubmitted = false
        this.errMsg()
      }
    },
    editAssignment ({ status = 'active' }) {
      const validData = this.$validator.validateAll()
      if (validData) {
        const data = {
          ...this.taskItems,
          class_teacher_ids: this.taskItems.class_teacher_id.map(
            (item) => item.id
          ),
          hide_from_grading: !this.taskItems.hide_from_grading,
          division_subject_id: this.taskItems.division_subject_id.id,
          student_ids: this.taskItems.student_ids.map((student) => student.id),
          status,
          is_formative: this.taskItems.is_formative !== 'Summative',
          criteria: this.taskItems.criteria.length
            ? this.taskItems.criteria.map((item) => item.key)
            : [],
          _method: 'patch'
        }

        AssignmentRepository.upadateAssignment(data)
          .then(() => {
            this.successMsg()
            this.returnTolistPage()
          })
          .catch((error) => {
            this.isFormSubmitted = false
            this.errMsg(error)
          })
      } else {
        this.isFormSubmitted = false
        this.errMsg()
      }
    },
    saveAsDraft () {
      if (!this.editmode) {
        this.submit({ status: 'draft' })
      } else {
        const validData = this.$validator.validateAll()
        if (validData) {
          //** Show Loader **//
          this.$vs.loading()
          const data = {
            ...this.taskItems,
            _method: 'patch',
            class_teacher_ids: this.taskItems.class_teacher_id.map(
              (item) => item.id
            ),
            division_subject_id: this.taskItems.division_subject_id.id,
            student_ids: this.taskItems.student_ids.map(
              (student) => student.id
            ),
            status: 'draft',
            is_formative: this.taskItems.is_formative !== 'Summative'
          }
          data.criteria = data.criteria
            ? data.criteria.map((item) => item.key)
            : []
          AssignmentRepository.upadateAssessmentTask(
            data,
            this.assessmentEditID
          )
            .then(() => {
              this.$vs.notify({
                title: 'Update',
                text: 'Task Update Successfully',
                color: 'success',
                position: 'bottom-right'
              })
              this.$vs.loading.close()
            })
            .catch((error) => {
              this.$vs.notify({
                title: 'Error',
                text: error.data.message,
                color: 'danger',
                position: 'bottom-right'
              })
            })
        }
      }
    },

    //////////////////////////////////////
    // Reset & cancelation methods
    /////////////////////////////////////
    resetProps () {
      this.taskItems = {
        title: '',
        student_type: 'students',
        is_multiple: false,
        style: 'normal',
        type: '',
        label: '',
        attachments: [],
        have_comments: true,
        have_attachments: true,
        hide_from_grading: true,
        is_disable_task_date: false,
        datetime_launch: '',
        is_formative: 'Summative',
        description: '',
        class_teacher_id: [],
        division_subject_id: '',
        student_ids: [],
        assignment_time: '',
        assignment_date: '',
        launch_time: '',
        launch_date: '',
        status: '',
        criteria: []
      }
    },
    returnTolistPage () {
      this.$router.push({
        name: 'assignments'
      })
    },
    isCurrentSection (section) {
      return this.taskItems.class_teacher_id.some(
        (item) => item.section.short_name === section
      )
    }
  },
  created () {
    if (this.$route.path.includes('edit')) {
      this.editmode = true
      this.firstTime = true
      this.getTeacherSingleAssignment(this.$route.params.assignment_id)
    } else {
      this.getTeachersSubjects()
      this.setDateDefaultValue()
    }
  },

  beforeDestroy () {
    clearInterval(this.updateTimeInterval)
    clearInterval(this.updateDueTimeInterval)
  }
}
</script>
<style lang="scss" scoped src="./create-assignment-homework.scss"></style>
