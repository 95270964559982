var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({direction: _vm.$vs.rtl ? 'rtl' : 'ltr'})},[(_vm.actions.length)?[_vm._l((_vm.actions),function(action,j){return [(
          !action.dropDown && (action.showIf ? action.showIf(_vm.rowData) : true)
        )?_c('vx-tooltip',{key:`id_${j}`,attrs:{"text":_vm._f("text_formatter")((typeof action.title === 'function'
            ? action.title(_vm.rowData)
            : action.title))}},[_c('feather-icon',{attrs:{"icon":_vm.getActionIcon(action) || action.icon,"title":_vm._f("text_formatter")((typeof action.title === 'function'
              ? action.title(_vm.rowData)
              : action.title)),"svgClasses":"h-5 w-5 mr-4 hover:text-primary cursor-pointer"},on:{"click":function($event){action.no_action
              ? ''
              : action.callbackAction
              ? action.callbackAction()
              : _vm.makeAction(action)}}})],1):_vm._e()]}),_vm._l((_vm.actions),function(action,i){return [(
          action.dropDown && (action.showIf ? action.showIf(_vm.rowData) : true)
        )?_c('vx-tooltip',{key:i,attrs:{"text":_vm._f("text_formatter")((typeof action.title === 'function'
            ? action.title(_vm.rowData)
            : action.title || _vm.$t('basic.actions')))}},[_c('vs-dropdown',{staticClass:"cursor-pointer",attrs:{"vs-trigger-click":""}},[_c('feather-icon',{attrs:{"icon":'MoreVerticalIcon',"svgClasses":"h-5 w-5 mr-4 hover:text-primary cursor-pointer"}}),_c('vs-dropdown-menu',[(action.dropDown_items)?[_vm._l((_vm.dropdown_actions),function(v,i){return [(v.showIf ? v.showIf(_vm.rowData) : true)?_c('vs-dropdown-item',{key:i,on:{"click":function($event){return _vm.makeAction(v)}}},[_c('span',{staticClass:"flex items-center"},[(v.icon)?_c('feather-icon',{staticClass:"mr-2",attrs:{"icon":_vm.getActionIcon(v) || v.icon,"svgClasses":"h-4 w-4"}}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm._f("text_formatter")(v.name || (typeof v.title === 'function' ? v.title(_vm.rowData) : v.title) || v.action)))])],1)]):_vm._e()]})]:_vm._e()],2)],1)],1):_vm._e()]})]:[_c('vx-tooltip',{attrs:{"text":_vm.$t('basic.no_available_actions')}},[_c('feather-icon',{attrs:{"icon":"MoreHorizontalIcon","svgClasses":"h-5 w-5 mr-4 hover:text-primary cursor-pointer"}})],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }