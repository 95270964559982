// paths
// /teacher/:id/profile'
export const teacher_paths = {

  profile: '/teacher/profile',
  dashboard: '/teacher/dashboard',
  assignment_list: '/teacher/assignments',
  assignment_create: '/teacher/assignment/create',
  assignment_edit: '/teacher/assignment/edit/:id',
  assess_assignment: '/teacher/assignment/:id/assess',
  exam_list: '/teacher/exams',
  exam_create: '/teacher/exam/create',
  exam_edit: '/teacher/exam/edit/:id',
  exam_show: '/teacher/exam/:id',
  exam_assessment: '/teacher/exam/assess-student/:id',
  exam_running: '/teacher/exam/running/:id',
  onlineClass_list: '/teacher/onlineClass',
  quickMeetingList: '/teacher/quick-meetings',
  onlineClass_create: '/teacher/onlineClass/create',
  onlineClass_Schedule :'/teacher/onlineClass/schedule',
  co_online_class :'/teacher/onlineClass/co-online-class',
  Schedule_co_online_class :'/teacher/onlineClass/schedule-co-online-class',
  onlineClass_edit: 'edit-onlineClass/:id',
  zoom_meeting: '/teacher/zoom-meeting',
  repository: '/teacher/repository',
  students_directory: '/teacher/students-directory/all-students',
  attendance: '/teacher/attendance/list',
  planner: '/teacher/planner',

  teaching_materials : '/teacher/teaching-materials',
  create_teaching_materials :'/teacher/teaching-materials/create',
  share_teaching_materials:'/teacher/teaching-materials/share/:id',
  edit_teaching_materials : '/teacher/teaching-materials/edit/:id'
}
// Pages Name with Icons , title , url , i18n
export const teacher_pages = {


  // ----------------------------------
  // Dashboard
  // ----------------------------------
  dashboard: {
    url: teacher_paths.dashboard,
    slug: 'dashboard',
    name: 'basic.dashboard',
    title: 'basic.dashboard',
    icon: 'HomeIcon',
    i18n: 'basic.dashboard',
    is_bookmarked: false
  },
  // assignment
  assignment_list: {
    url: teacher_paths.assignment_list,
    slug: 'assignment_list',
    name: 'basic.assignments',
    title: 'basic.assignments',
    i18n: 'basic.assignments',
    icon: 'EditIcon',
    is_bookmarked: false
  },
  assignment_create: {
    url: teacher_paths.assignment_create,
    slug: 'assignment_create',
    name: 'basic.assignment_create',
    title: 'basic.assignment_create',
    i18n: 'basic.assignment_create',
    icon: 'EditIcon',
    is_bookmarked: false
  },

  // Exams
  exam_list: {
    url: teacher_paths.exam_list,
    slug: 'exam_list',
    name: 'basic.exams',
    title: 'basic.exams',
    i18n: 'basic.exams',
    icon: 'FileTextIcon',
    is_bookmarked: false
  },
  exam_create: {
    url: teacher_paths.exam_create,
    slug: 'exam_create',
    name: 'basic.exam_create',
    title: 'basic.exam_create',
    i18n: 'basic.exam_create',
    icon: 'FileTextIcon',
    is_bookmarked: false
  },
  // OnlineClasses
  onlineClass_list: {
    url: teacher_paths.onlineClass_list,
    slug: 'onlineClass_list',
    name: 'onlineClasses',
    title: 'basic.onlineClasses',
    i18n: 'basic.onlineClass',
    icon: 'FileTextIcon',
    is_bookmarked: false
  },
  // quickMeetingList
  quickMeetingList: {
    url: teacher_paths.quickMeetingList,
    slug: 'quick-meeting-list',
    name: 'quickMeetingList',
    title: 'basic.quickMeeting',
    i18n: 'basic.quickMeeting',
    icon: 'UsersIcon',
    is_bookmarked: false
  },
  onlineClass_create: {
    url: teacher_paths.onlineClass_create,
    slug: 'onlineClass_create',
    name: 'basic.create_onlineClass',
    title: 'basic.create_onlineClass',
    i18n: 'basic.create_onlineClass',
    icon: 'FileTextIcon',
    is_bookmarked: false
  },

  // ----------------------------------
  // Profile
  // ----------------------------------
  profile: {
    url: teacher_paths.profile,
    slug: 'profile',
    name: 'profile.profile',
    title: 'profile.Profile',
    i18n: 'profile.Profile',
    icon: 'UserIcon',
    is_bookmarked: false
  },

  repository: {
    url: teacher_paths.repository,
    slug: 'teacher-repository',
    name: 'TeacherRepository',
    title: 'repository',
    icon: 'CalendarIcon',
    i18n: 'basic.my_drive',
    is_bookmarked: true
  },
  students_directory: {
    url: teacher_paths.students_directory,
    slug: 'students-directory',
    name: 'StudentsDirectory',
    title: 'students_directory',
    icon: 'UserIcon',
    i18n: 'basic.students_directory',
    is_bookmarked: true
  },
  attendance: {
    url: teacher_paths.attendance,
    slug: 'teacher-attendance',
    name: 'basic.attendance',
    title: 'basic.attendance',
    i18n: 'basic.attendance',
    icon: 'EditIcon',
    is_bookmarked: false
  },
  planner: {
    url: teacher_paths.planner,
    slug: 'teacher-planner',
    name: 'Unit Planner',
    title: 'Unit Planner',
    icon: 'MapIcon',
    is_bookmarked: true
  },

  teaching_materials: {
    url: teacher_paths.teaching_materials,
    slug: 'TeachingMaterials',
    name: 'teachingMaterials.teaching_materials',
    title: 'teachingMaterials.teaching_materials',
    i18n: 'teachingMaterials.teaching_materials',
    icon: 'BookIcon',
    is_bookmarked: false
  }
}

// All Pages Info
export const teacher_pages_all = [
  teacher_pages.dashboard,
  teacher_pages.assignment_list,
  teacher_pages.assignment_create,
  teacher_pages.exam_list,
  teacher_pages.exam_create,
  teacher_pages.profile,
  teacher_pages.repository,
  teacher_pages.students_directory,
  teacher_pages.attendance,
  teacher_pages.planner,
  teacher_pages.teaching_materials
]
