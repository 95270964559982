<template>
  <div>
    <announcementCard></announcementCard>
  </div>
</template>

<script>
import announcementCard from '../../../components/announcementCard'
export default {
  components: {
    announcementCard
  }
}
</script>
