export default {
  // Family Profile
  fullname: 'Full Name',
  birthday: 'Birthday',
  nationality: 'Nationality',
  gender: 'Gender',
  telephones: 'Telephones',
  religion: 'Religion',
  email: 'Email',
  address: 'Address',
  work_details: 'Work Details',

  // Attendance
  days: 'Days',
  academic_days: 'Academic Days',
  times: 'Times',
  discipline_score: 'Discipline Score',
  absent_times: 'Absent Times',
  authorized_absent: 'Authorized Absent',
  missed_first_bell: 'Missed First Bell',
  today_status: 'Today Status',
  monthly_report: 'Monthly Report',
  general_report: 'General Report',

  // Assignments
  single: 'Single',
  group: 'Group',

  //Profile
  no_assignment_available: 'There are no assignments available',
  classwork: 'classwork',
  gradebook: 'gradebook',
  privateContent: 'private content',
  repository: 'repository',
  results: 'results',
  timetable: 'Timetable',
  documents_resources: 'Documents & Resources',
  fees_payment: 'Fees & Payment',
  emergency_contacts: 'Emergency Contacts',
  martial_status: ' Martial Status',
  siblings:'Siblings'

}
