<template>
  <div>
    <div class="vx-row mb-3">
      <div
        class="
          vx-col
          xl:w-1/5
          lg:w-1/4
          md:w-1/3
          sm:w-1/2
          w-full
          mr-0
          ml-auto
          block
        "
        v-if="announcement_for_teacher"
      >
        <vs-button class="mr-0 ml-auto block" :to="{ name: 'teacherAnnouncementCreate' }">{{
          $t("basic.create")
        }}</vs-button>
      </div>
    </div>
      <announcementList :showRoute="'show-announcement-teacher'"></announcementList>
  </div>
</template>

<script>
import announcementList from '../../../components/announcementList'
export default {
  components :{
    announcementList
  },
  data () {

    return {

      announcement_for_teacher: 0

    }

  },

  created () {

    this.$store.dispatch('main/getInstitutionData').then(res => this.announcement_for_teacher = res.announcement_for_teacher)

  }
}
</script>
