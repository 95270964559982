import ModuleMixins from '../../mixins'
import Service from '../../services/poi'

export default {
  name: 'unitMaterialization',
  mixins: [ModuleMixins],
  data () {
    return {
      restService:Service,
      modalProps: {
        width: '1024px',
        height: 'auto',
        scrollable: true
      },
      modalName: 'unitMaterializationModal',
      groupData: null,
      unitsDetails: null,
      groupBuyId: null,
      groupByItems:null,
      KeyConcepts:null,
      learnerProfile: null,
      notFoundKeyConcepts:null,
      notFoundLearnerProfile:null

    }
  },
  mounted () {
    const self = this

    /// ** Interact Modals & other actions throw vuex ** ///
    /// ** this case to listen on vuex action to open modal from other components ** ///
    this.storeUnSubscriber = this.$store.subscribeAction({
      before: (action, state) => {
        // if you need to implement before action
        // it's default behavior
      },
      after: (action, state) => {
        if (action.type === 'manageModal' && action.payload.name === self.modalName) {
          if (action.payload.show === true) {
            this.loadData(action.payload.data.groupData)
          } else if (action.payload.show === false) {
            this.hideModal()
          }
        }
      },
      error: (action, state, error) => {
        this.hideModal()
        console.error(error)
      }
    })

  },
  destroyed () {
    /// ** Stop VueX Actions Subscriber to prevent memory leak and Errors **///
    this.storeUnSubscriber()
  },
  methods: {
    showModal () {
      this.$modal.show(this.modalName)
    },
    hideModal () {
      this.$modal.hide(this.modalName)
    },
    loadData (data) {
      this.groupData = data
      this.getKeyConcepts();
      this.getKeyLearnerProfile();
      this.filterData();
      this.groupHelper();
      this.showModal();
    },
    getKeyConcepts () {
      this.restService.getKeyConcepts()
        .then(res => this.keyConcepts = res.data)
        .catch(err => console.error(err))
    },
    getKeyLearnerProfile () {
      this.restService.getLearnerProfile()
        .then(res => this.learnerProfile = res.data)
        .catch(err => console.error(err))
    },
    filterData () {
      const data = this.cloneItem(this.$store.getters.getGroupedUnitsDetails)
      let keyConceptsIds, learnerProfileIds;

      data.key_concepts = data.key_concepts.filter(item => item.groupId === this.groupData.id)
      data.related_concepts = data.related_concepts.filter(item => item.groupId === this.groupData.id)
      data.atl_skills = data.atl_skills.filter(item => item.groupId === this.groupData.id)
      data.learner_profile = data.learner_profile.filter(item => item.groupId === this.groupData.id)

      //** Get Not Selected KeyConcepts **//
      learnerProfileIds = data.learner_profile.map(item => item.id)
      this.notFoundLearnerProfile = this.learnerProfile.filter(item => {
        if (!learnerProfileIds.includes(item.id)) {
          return item
        }
      })

      //** Get Not Selected KeyConcepts **//
      keyConceptsIds = data.key_concepts.map(item => item.id)
      this.notFoundKeyConcepts = this.keyConcepts.filter(item => {
        if (!keyConceptsIds.includes(item.id)) {
          return item
        }
      })
      this.unitsDetails = data
    },
    groupedCount (data) {
      //** Loop over Object Keys and return data Counted With Key Name **//
      const newArr = []
      for (const [key, value] of Object.entries(data)) {
        if (value.length) {
          const obj = {name: value[0].name, count: value.length}
          newArr.push(obj)
        }

      }
      return newArr
    },
    groupHelper () {
      this.groupBuyId = null
      this.groupByItems = null

      //** Step 1 Filter & Group Data By approach Item Id **//
      this.groupBuyId = {
        key_concepts: this.arrGroupBy(this.unitsDetails.key_concepts, 'id'),
        related_concepts: this.arrGroupBy(this.unitsDetails.related_concepts, 'id'),
        atl_skills: this.arrGroupBy(this.unitsDetails.atl_skills, 'id'),
        learner_profile: this.arrGroupBy(this.unitsDetails.learner_profile, 'id')
      }

      //** Step 2 **//
      //** Group (Filtered & Grouped Data By approach Items) By Every Selected Item on 4 Approach **//
      this.groupByItems = {
        key_concepts: this.groupedCount(this.groupBuyId.key_concepts),
        related_concepts: this.groupedCount(this.groupBuyId.related_concepts),
        atl_skills: this.groupedCount(this.groupBuyId.atl_skills),
        learner_profile: this.groupedCount(this.groupBuyId.learner_profile)
      }
    }
  },
  computed: {
  }
}
