<template>
  <vx-card class="p-3 pt-6 pb-6 md:p-6 text-center">
    <img
      src="@/assets/images/pages/maintenance-2.png"
      alt="graphic-maintenance"
      class="mx-auto mb-6"
    />
    <h2 class="mb-4" v-if="featureName">
      {{ ($t("basic.hello_from") + " " + featureName) | text_formatter }}
    </h2>
    <p>
      {{ $t("basic.This_feature_is_still_under_development") | text_formatter }}
    </p>
  </vx-card>
</template>
<script>
export default {
  name:'under-development',
  props:['featureName']
}
</script>
