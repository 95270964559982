<!-- ----------------------------------------------------------------------------
    File Name: ChatLog.vue
    Description: Chat Application - Log of chat
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
----------------------------------------------------------------------------= -->

<template>
  <div
    id="component-chat-log"
    :class="{ 'm-8': chatData.length }"
    v-if="chatData.length"
  >
    <!-- <vs-divider v-if="isGroup" class="msg-time">
      <span
        >{{ groupAdmin.name }} {{ $t("chat.created_group") }}
        {{ groupTitle }}</span
      >
    </vs-divider> -->
    <template v-for="(msg, index) in chatData">
      <div v-if="msg.content" class="msg-grp-container" :key="index">
        <!-- If is_default_message -->
        <span v-if="msg.is_default_message" class="default-message">
          {{ msg.content | text_formatter }}
        </span>

        <template v-else>
          <!-- If previous msg is older than current time -->
          <template v-if="chatData[index - 1]">
            <vs-divider
              v-if="!isSameDay(msg.created_at, chatData[index - 1].created_at)"
              class="msg-time"
            >
              <span>{{ toDate(msg.created_at) }}</span>
            </vs-divider>
            <div
              class="spacer mt-8"
              v-if="!hasSentPreviousMsg(chatData[index - 1].isSent, msg.isSent)"
            ></div>
          </template>

          <div
            class="flex items-start"
            :class="[{ 'flex-row-reverse': msg.isSent }]"
          >
            <!-- Show avatar || hide (mr) depend on the previous msg if it from same user or not  -->
            <template v-if="chatData[index - 1]">
              <template v-if="showSenderImage(chatData[index - 1], msg)">
                <vs-avatar
                  size="40px"
                  class="
                    border-2
                    shadow
                    border-solid border-white
                    m-0
                    flex-shrink-0
                  "
                  :class="msg.isSent ? 'sm:ml-5 ml-4' : 'sm:mr-5 mr-4'"
                  :src="senderImg(msg.isSent)"
                />
              </template>
              <div class="mr-16" v-else></div>
            </template>
            <!-- if first msg =>  show avatar -->
            <template v-if="index == 0">
              <vs-avatar
                size="40px"
                class="
                  border-2
                  shadow
                  border-solid border-white
                  m-0
                  flex-shrink-0
                "
                :class="msg.isSent ? 'sm:ml-5 ml-4' : 'sm:mr-5 mr-4'"
                :src="senderImg(msg.isSent)"
              />
            </template>

            <div
              class="
                msg
                break-words
                relative
                shadow-md
                rounded
                py-3
                px-4
                mb-2
                rounded-lg
                max-w-sm
              "
              :class="
                msg.isSent
                  ? 'bg-primary-gradient text-white'
                  : 'border border-solid border-transparent bg-white'
              "
            >
              <!-- Group sender name  -->
              <strong
                class="text-sm block text-left"
                v-if="showMsgSenderName(chatData, msg, index)"
              >
                {{ msg.sender_name }}
              </strong>
              <!--////////////////////////////
                  // Msg Content
             ///////////////////////////// -->
              <!-- If not deleted  -->
              <template v-if="!msg.is_deleted">
                <!-- Message Actions -->
                <vx-tooltip :text="$t('basic.more')" class="actionButtons">
                  <vs-dropdown
                    vs-trigger-click
                    class="cursor-pointer"
                    v-if="msg.isSent"
                  >
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    <vs-dropdown-menu>
                      <vs-dropdown-item @click="deleteMessage(msg.id)">
                        <span class="flex items-center">
                          <feather-icon
                            icon="XIcon"
                            svgClasses="h-4 w-4"
                            class="mr-2"
                          />
                          <span>{{ $t("chat.delete_message") }}</span>
                        </span>
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </vx-tooltip>

                <!-- MSG Content -->
                <span
                  class="block flex items-center msg__content"
                  :class="isGroup ? 'my-1' : 'mb-2'"
                >
                  <template v-if="msg.attachment">
                    <a :href="msg.url" target="_blank" class="no_style">
                      <feather-icon
                        icon="ArrowDownCircleIcon"
                        class="cursor-pointer p-0"
                        :svgClasses="`h-6 w-6 ${$vs.rtl ? 'ml-3' : 'mr-3'}`"
                      />{{ msg.content.toString() }}
                    </a>
                  </template>
                  <pre
                    v-else
                    v-html="textToHyperlink(msg.content.toString())"
                  ></pre>
                </span>
                <!-- MSG Time && isSeen  -->
                <span class="text-xs block text-right">
                  <span style="opacity: 0.8">
                    {{ getMsgDate(msg.created_at) }}
                  </span>
                  <template v-if="msg.isSent && !isGroup">
                    <vx-tooltip
                      :text="
                        msg.is_seen
                          ? $t('chat.seen')
                          : $t('chat.delivered_but_not_seen_yet')
                      "
                      position="bottom"
                      class="inline-block"
                    >
                      <feather-icon
                        icon="CheckIcon"
                        class="cursor-pointer p-0"
                        :class="{ 'opacity-50': !msg.is_seen }"
                        svgClasses="h-4 w-4"
                      />

                      <feather-icon
                        icon="CheckIcon"
                        class="cursor-pointer p-0 -ml-2"
                        :class="{ 'opacity-50': !msg.is_seen }"
                        svgClasses="h-4 w-4"
                      />
                    </vx-tooltip>
                  </template>
                </span>
              </template>
              <!-- If  deleted  -->
              <span v-else class="opacity-50">
                <!-- <feather-icon
                  icon="XIcon"
                  class="cursor-pointer p-0"
                  svgClasses="h-4 w-4"
                /> -->
                <template v-if="msg.isSent">
                  {{ $t("chat.you_deleted_this_message") }}
                </template>
                <template v-else>
                  {{ $t("chat.this_message_was_deleted") }}
                </template>
              </span>
            </div>
          </div>
        </template>
      </div>
    </template>

    <!-- <vs-button
      radius
      type="border"
      icon-pack="feather"
      icon="icon-chevron-down"
      id="btn-scrollToBottom"
      @click="scrollToBottom"
    ></vs-button> -->
  </div>
</template>

<script>
import mixins from '../chat.mixins'

export default {
  name: 'chat-log',
  mixins: [mixins],

  props: {
    userId: {
      type: Number,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    msg: {
      type: Array,
      required: true
    },
    firstTimeLoading: {
      type: Boolean,
      default: true
    },
    sendNewMessage: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      showFullTime: false,
      selectedMsg:null
    }
  },
  computed: {
    msgs () {
      const msgs = Array.from(this.msg)
      return msgs
    },
    chatData () {
      // console.table('this.msgs[0]', this.msgs[0])
      // if (this.msgs.length && (this.msgs[0].contact || this.msgs[0].group ) && ((this.msgs[0].contact?.id || this.msgs[0].group?.id) === this.userId)) return this.msgs

      const msgs = Array.from(this.msg)
      return msgs || []
    },
    activeUserImg () {
      return this.activeUser.photoURL || ''
    },
    senderImg () {
      return (isSentByActiveUser) => {
        if (isSentByActiveUser) return this.activeUserImg
        else return this.user.image
      }
    },
    hasSentPreviousMsg () {
      return (last_sender, current_sender) => last_sender === current_sender
    }

  },

  methods: {
    isSameDay (time_to, time_from) {
      const date_time_to = new Date(Date.parse(time_to))
      const date_time_from = new Date(Date.parse(time_from))
      return date_time_to.getFullYear() === date_time_from.getFullYear() &&
          date_time_to.getMonth() === date_time_from.getMonth() &&
          date_time_to.getDate() === date_time_from.getDate()
    },
    toDate (time) {
      const locale = this.current_locale
      const date_obj = new Date(Date.parse(time))
      const monthName = date_obj.toLocaleString(locale, {
        month: 'short'
      })
      return `${date_obj.getDate()} ${monthName}`
    },

    scrollToBottom () {
      this.$nextTick(() => {
        this.$parent.$el.scrollTop = this.$parent.$el.scrollHeight
      })
    },
    showSenderImage (lastMsg, currentMsg) {
      if (!lastMsg.is_default_message) {
        return !this.hasSentPreviousMsg(lastMsg.sender_id, currentMsg.sender_id) ||
            !this.isSameDay(currentMsg.created_at, lastMsg.created_at)
      } else return true
    },

    showMsgSenderName (chatData, msg, index) {
      const prevMsg = chatData[index - 1]

      if (this.isGroup) {
        return !msg.isSent
        && !msg.is_default_message
        && index > 1
        && msg.sender_id
        && msg.sender_name
        && !prevMsg.is_default_message ? (prevMsg.sender_id !== msg.sender_id) : !msg.isSent
      } else return false
    },
    /////////////////////////////
    // Get MsgDate
    ////////////////////////////
    getMsgDate (date, type = '') {
      const
        msgDate = new Date(date),
        dateString = msgDate.toLocaleString() // "1/29/2021, 1:50:29 AM"
      // is_today = (new Date().getDate() === msgDate.getDate())

      if (type === 'full') return dateString.replace(',', ' ')
      else {
        const time = dateString.split(',')[1] || ''
        return `${time.split(':').splice(0, 2).join(':') || ''} ${time.split(' ')[2] || ''}`
      }
    },
    /////////////////////////////
    // DeleteMessege
    ////////////////////////////
    deleteMessage (msgId) {
      this.selectedMsg = msgId
      this.dangerDialog(
        this.$t('basic.confirm'),
        `${this.$t('chat.delete_message')}?`,
        this.confirmDeleteMsg, null,
        this.endDeleteMsg)
    },
    confirmDeleteMsg () {
      this.$emit('deleteMsg', this.selectedMsg)
    },
    endDeleteMsg () {
      this.selectedMsg = null
    }


  },
  updated () {
    if (this.firstTimeLoading || this.sendNewMessage) this.scrollToBottom()

  },
  mounted () {
    this.scrollToBottom()
  },
  created () {

  }
}
</script>
<style scoped>
.actionButtons {
  left: -20px;
  top: 1px;
  opacity: 0.8;

  width: fit-content;
  transition: left 0.2s ease;
}
.msg:hover .actionButtons {
  left: 3px;
}
</style>
