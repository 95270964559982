
<template>
  <vx-card class="p-3 pt-6 pb-6 md:p-6">
    <div class="mt-5">
      <form-wizard
        ref="new-user-wizard"
        color="rgba(var(--vs-primary), 1)"
        :title="null"
        :subtitle="null"
        :finishButtonText="userId ? $t('form.update') : $t('form.submit')"
        :startIndex="0"
        @on-change="validateStep"
        @on-complete="formSubmitted"
      >
        <!-- @on-complete="formSubmitted" -->
        <!-- Step 1 -->
        <!-- <tab-content :title="`${$t('config.Settings')}`" class="mb-5"> -->
        <!-- <div class="vx-row" v-if="schools.length > 1">
            <div class="vx-col sm:w-1/5 w-full mt-5">
              <label class="text-sm">{{ $t("basic.schools") + " " }}</label>
              <sup class="text-danger">*</sup>
            </div>
            <div class="vx-col sm:w-3/5 w-full mt-5">
              <v-select
                label="name"
                :closeOnSelect="false"
                :reduce="(school) => school.id"
                v-model="users_role.school"
                v-validate="'required'"
                class="w-full"
                name="school"
                :options="schools"
                @input="getSchoolDivisions"
                :disabled="!schools.length > 0"
              />

              <span v-if="showErrorsTab0" class="text-danger text-sm">{{
                errors.first("school") | text_formatter
              }}</span>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col sm:w-1/5 w-full mt-5">
              <label>{{ $t("config.divisions") + " " }}</label>
              <sup class="text-danger">*</sup>
            </div>

            <div class="vx-col sm:w-3/5 w-full mt-5">
              <v-select
                multiple
                label="long_name"
                :closeOnSelect="false"
                :reduce="(long_name) => long_name.id"
                v-model="users_role.divisions"
                v-validate="'required'"
                class="w-full"
                name="division"
                :options="divisions"
                @input="getDivisionsSections"
              />
              <span v-if="showErrorsTab0" class="text-danger text-sm">{{
                errors.first("division") | text_formatter
              }}</span>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col sm:w-1/5 w-full mt-5">
              <label>{{ $t("config.sections") + " " }}</label>
              <span class="text-danger">*</span>
            </div>

            <div class="vx-col sm:w-3/5 w-full mt-5">
              <v-select
                multiple
                label="name"
                :closeOnSelect="false"
                :reduce="(name) => name.id"
                v-model="users_role.sections"
                v-validate="'required'"
                class="w-full"
                name="section"
                :options="sections"
              />
              <span v-if="showErrorsTab0" class="text-danger text-sm">{{
                errors.first("section") | text_formatter
              }}</span>
            </div>
          </div> -->
        <!-- <div class="vx-row">
            <div class="vx-col sm:w-1/5 w-full mt-5">
              <label>{{$t('config.type') + " "}}</label>
              <span class="text-danger">*</span>
            </div>
            <div class="vx-col sm:w-3/5 w-full mt-5">
              <v-select
                :placeholder="$t('config.selected_type')"
                closeOnSelect
                label="name"
                :reduce="type => type.id"
                v-model="users_role.type"
                v-validate="'required'"
                class="w-full"
                name="selected_type"
                :options="user_types"
              />
              <span
                v-if="showErrorsTab0"
                class="text-danger text-sm"
              >{{ errors.first('selected_type')| text_formatter  }}</span>
            </div>
          </div>-->
        <!-- </tab-content> -->

        <!-- Step 2 -->
        <tab-content :title="`${$t('config.roles')}`" class="mb-5">
          <div class="vx-row">
            <div class="vx-col sm:w-1/5 w-full mt-5">
              <label>{{ $t("config.users") + " " }}</label>
              <sup class="text-danger">*</sup>
            </div>

            <div class="vx-col sm:w-3/5 w-full mt-5">
              <!-- <v-select
                multiple
                label="name"
                :closeOnSelect="false"
                :reduce="name => name.id"
                v-model="users_role.user"
                v-validate="'required'"
                class="w-full"
                name="users"
                :options="users"
              />-->
              <v-select
                label="name"
                :reduce="(user) => user.id"
                v-model="users_role.user"
                v-validate="'required'"
                class="w-full"
                name="users"
                :disabled="!users.length > 0 || userId"
                :options="users"
              />

              <span v-if="showErrorsTab1" class="text-danger text-sm">{{
                errors.first("users") | text_formatter
              }}</span>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col sm:w-1/5 w-full mt-5">
              <label class="text-sm">{{ $t("config.Roles") + " " }}</label>
              <sup class="text-danger">*</sup>
            </div>
            <div class="vx-col sm:w-3/5 w-full mt-5">
              <v-select
                label="name"
                :reduce="(user) => user.id"
                v-model="users_role.role"
                v-validate="'required'"
                class="w-full"
                name="role"
                :disabled="!roles.length > 0"
                :options="roles"
              />

              <span v-if="showErrorsTab1" class="text-danger text-sm">{{
                errors.first("role") | text_formatter
              }}</span>
            </div>
          </div>
          <!-- <div class="vx-row">
            <div class="vx-col sm:w-1/5 w-full mt-5">
              <label>{{$t('config.firstName')}}</label>
              <span class="text-danger">*</span>
            </div>
            <div class="vx-col sm:w-3/5 w-full mt-5">
              <vs-input
                @keypress="acceptOnlyLetters"
                @keydown.space="allowOnlyOneSpace"
                v-model="users_role.firstName"
                class="w-full"
                name="first_name"
                :placeholder="$t('config.firstName')"
                v-validate="'required'"
              />
              <span
                v-if="showErrorsTab1"
                class="text-danger text-sm"
              >{{ errors.first('first_name')| text_formatter  }}</span>
              <span
                v-if="!errors.first('fir st_name')"
                class="text-danger text-sm"
              >{{ extraValidationErrors.first_name }}</span>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col sm:w-1/5 w-full mt-5">
              <label>{{$t('config.lastName')}}</label>
              <span class="text-danger">*</span>
            </div>
            <div class="vx-col sm:w-3/5 w-full mt-5">
              <vs-input
                @keypress="acceptOnlyLetters"
                @keydown.space="allowOnlyOneSpace"
                v-model="users_role.lastName"
                class="w-full"
                name="last_name"
                :placeholder="$t('config.lastName')"
                v-validate="'required'"
              />
              <span
                v-if="showErrorsTab1"
                class="text-danger text-sm"
              >{{ errors.first('last_name')| text_formatter  }}</span>
              <span
                v-if="!errors.first('la| text_formatter st_name')"
                class="text-danger text-sm"
              >{{ extraValidationErrors.last_name }}</span>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col sm:w-1/5 w-full mt-5">
              <label>{{$t('config.credentials')}}</label>
              <span class="text-danger">*</span>
            </div>
            <div class="vx-col sm:w-3/5 w-full mt-5">
              <vs-input
                v-model="users_role.email"
                class="w-full"
                name="email"
                :placeholder="$t('config.email')"
                v-validate="'required|email'"
              />
              <span v-if="showErrorsTab1" class="text-danger text-sm">{{ errors.first('email')| text_formatter  }}</span>
              <div class="vx-row mt-4">
                <div class="vx-col lg:w-1/4 md:w-1/2 w-full mb-4 pr-0">
                  <vs-select
                    v-validate="'required'"
                    :placeholder="$t('config.phone_key')"
                    v-model="users_role.phone_key"
                    name="phone_key"
                    class="select-small w-full"
                  >
                    <vs-select-item
                      :key="index"
                      :value="item.value"
                      :text="item.text"
                      v-for="(item, index) in phone_keys"
                      class="w-full"
                    />
                  </vs-select>
                  <span
                    v-if="showErrorsTab1"
                    class="text-danger text-sm"
                  >{{ errors.first('phone_key')| text_formatter  }}</span>
                </div>
                <div class="vx-col lg:w-3/4 md:w-1/2 w-full mb-4 pl-1">
                  <vs-input
                    name="phone"
                    v-validate="'required|numeric|length:8'"
                    :placeholder="$t('config.phone')"
                    v-model="users_role.phone"
                    class="w-full"
                  ></vs-input>
                  <span
                    v-if="!errors.first('ph| text_formatter one_key')"
                    class="text-danger text-sm"
                  >{{ errors.first('phone')| text_formatter  }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col sm:w-1/5 w-full mt-5">
              <label>{{$t('config.gender')}}</label>
              <span class="text-danger">*</span>
            </div>
            <div class="vx-col sm:w-3/5 w-full mt-5">
              <ul class="vx-row mt-2">
                <li class="vx-col w-1/2">
                  <vs-radio
                    v-validate="'required'"
                    v-model="users_role.gender"
                    vs-value="male"
                    name="gender"
                  >Male</vs-radio>
                </li>
                <li class="vx-col w-1/2">
                  <vs-radio
                    v-validate="'required'"
                    v-model="users_role.gender"
                    vs-value="female"
                    name="gender"
                  >Female</vs-radio>
                </li>
              </ul>
              <span v-if="showErrorsTab1" class="text-danger text-sm">{{ errors.first('gender')| text_formatter  }}</span>
            </div>
          </div>
          Admin Role
          <div v-if="users_role.type === 1">
            <div class="vx-row">
              <div class="vx-col sm:w-1/5 w-full mt-5">
                <label>{{$t('config.role')}}</label>
                <span class="text-danger">*</span>
             </div>
              <div class="vx-col sm:w-3/5 w-full mt-5">
                <v-select
                  :placeholder="$t('config.selectRole')"
                  closeOnSelect
                  label="name"
                  :reduce="role => role.id"
                  v-model="users_role.role"
                  v-validate="'required'"
                  class="w-full"
                  name="role"
                  :options="roles"
                />
                <span
                  v-if="showErrorsTab1"
                  class="text-danger text-sm"
                >{{ errors.first('role')| text_formatter  }}</span>
              </div>
            </div>
          </div>

          Teaching Role
          <div v-if="users_role.type === 2">
            <div class="vx-row">
              <div class="vx-col sm:w-1/5 w-full mt-5">
                <label>{{$t('config.subjects')}}</label>
                <span class="text-danger">*</span>
              </div>
              <div class="vx-col sm:w-3/5 w-full mt-5">
                <v-select
                  multiple
                  :closeOnSelect="false"
                  :placeholder="$t('config.selectSubjects')"
                  label="name"
                  :reduce="subject => subject.id"
                  v-model="users_role.subjects"
                  v-validate="'required'"
                  class="w-full"
                  name="selected_subjects"
                  :options="subjects"
                />
                <span
                  v-if="showErrorsTab1"
                  class="text-danger text-sm"
                >{{ errors.first('selected_subjects')| text_formatter  }}</span>
              </div>
            </div>

            <div class="vx-row">
              <div class="vx-col sm:w-1/5 w-full mt-5">
                <label>{{$t('config.console')}}</label>
                <span class="text-danger">*</span>
              </div>
              <div class="vx-col sm:w-3/5 w-full mt-5">
                <v-select
                  :placeholder="$t('config.selectConsole')"
                  closeOnSelect
                  label="name"
                  :reduce="(console => console.id)"
                  v-model="users_role.console"
                  v-validate="'required'"
                  class="w-full"
                  name="selected_console"
                  :options="consoles"
                />
                <span
                  v-if="showErrorsTab1"
                  class="text-danger text-sm"
                >{{ errors.first('selected_console')| text_formatter  }}</span>
              </div>
            </div>
          </div>-->
        </tab-content>

        <!-- Step 3 -->
        <tab-content :title="`${$t('config.preview')}`" class="mb-5">
          <!-- <div class="vx-row mb-6">
            <div class="vx-col md:w-1/4 w-full">
              <strong>
                <span>{{ $t("basic.school") }}</span>
              </strong>
            </div>
            <div class="vx-col md:w-3/4 w-full" v-if="users_role.school">
              <span>{{
                `${schools.find((el) => el.id === users_role.school).name}`
              }}</span>
            </div>
          </div> -->
          <!-- <div class="vx-row mb-6">
            <div class="vx-col md:w-1/4 w-full">
              <strong>
                <span>{{$t('config.school')}}</span>
              </strong>
            </div>
            <div class="vx-col md:w-3/4 w-full" v-if="users_role.school">
              <span>{{schools.filter(school => school.id === users_role.school)[0].name}}</span>
            </div>
          </div>-->

          <!-- <div class="vx-row mb-6">
            <div class="vx-col md:w-1/4 w-full">
              <strong>
                <span>{{$t('config.branches')}}</span>
              </strong>
            </div>
            <div class="vx-col md:w-3/4 w-full">
              <span
                v-for="(branch, index) in (branches.filter(branch => users_role.school.includes(branch.id)))"
                :key="branch.id"
              >{{ index === users_role.school.length - 1 ? branch.name : branch.name + " - " }}</span>
            </div>
          </div>-->

          <!-- <div class="vx-row mb-6">
            <div class="vx-col md:w-1/4 w-full">
              <strong>
                <span>{{ $t("config.divisions") }}</span>
              </strong>
            </div>
            <div class="vx-col md:w-3/4 w-full" v-if="users_role.divisions">
              <span
                v-for="(division, index) in divisions.filter((division) =>
                  users_role.divisions.includes(division.id)
                )"
                :key="division.id"
                >{{
                  index === users_role.divisions.length - 1
                    ? division.long_name
                    : division.long_name + " - "
                }}</span
              >
            </div>
          </div> -->

          <!-- <div class="vx-row mb-6">
            <div class="vx-col md:w-1/4 w-full">
              <strong>
                <span>{{ $t("config.sections") }}</span>
              </strong>
            </div>
            <div class="vx-col md:w-3/4 w-full" v-if="users_role.sections">
              <span
                v-for="(section, index) in sections.filter((section) =>
                  users_role.sections.includes(section.id)
                )"
                :key="section.id"
                >{{
                  index === users_role.sections.length - 1
                    ? section.name
                    : section.name + " - "
                }}</span
              >
            </div>
          </div> -->
          <div class="vx-row mb-6">
            <div class="vx-col md:w-1/4 w-full">
              <strong>
                <span>{{ $t("config.users") }}</span>
              </strong>
            </div>
            <!-- <div class="vx-col md:w-3/4 w-full" v-if="users_role.user">
              <span
                v-for="(user, index) in (users.find(user => user.id === users_role.user))"
                :key="user.id"
              >{{ index === users_role.users.length - 1 ? user.name : user.name + " - " }}</span>
            </div>-->
            <div class="vx-col md:w-3/4 w-full" v-if="users_role.user">
              <span>{{
                users.find((user) => user.id === users_role.user).name
              }}</span>
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col md:w-1/4 w-full">
              <strong>
                <span>{{ $t("config.role") }}</span>
              </strong>
            </div>
            <div class="vx-col md:w-3/4 w-full" v-if="users_role.role">
              <span>
                {{
                  users_role.roleName ||
                  roles.find((role) => role.id === users_role.role).name
                }}
              </span>
            </div>
          </div>

          <!-- Admin Role -->
          <!-- <div v-if="users_role.type === 1">
            <div class="vx-row mb-6">
              <div class="vx-col md:w-1/4 w-full">
                <strong>
                  <span>{{$t('config.role')}}</span>
                </strong>
              </div>
              <div class="vx-col md:w-3/4 w-full" v-if="users_role.role">
                <span>{{roles.filter(role => role.id === users_role.role)[0].name}}</span>
              </div>
            </div>
          </div>-->

          <!-- Teaching Role -->
          <!-- <div v-if="users_role.type === 2">
            <div class="vx-row mb-6">
              <div class="vx-col md:w-1/4 w-full">
                <strong>
                  <span>{{$t('config.subjects')}}</span>
                </strong>
              </div>
              <div class="vx-col md:w-3/4 w-full">
                <span
                  v-for="(subject, index) in (subjects.filter(subject => users_role.subjects.includes(subject.id)))"
                  :key="subject.id"
                >{{ index === users_role.subjects.length - 1 ? subject.name : subject.name + " - " }}</span>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col md:w-1/4 w-full">
                <strong>
                  <span>{{$t('config.console')}}</span>
                </strong>
              </div>
              <div class="vx-col md:w-3/4 w-full" v-if="users_role.console">
                <span>{{consoles.filter(console => console.id === users_role.console)[0].name}}</span>
              </div>
            </div>
          </div>-->
        </tab-content>
      </form-wizard>
    </div>
  </vx-card>
</template>


<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import vSelect from 'vue-select'
// Data
import {getDropdownMultiSelectData_Mixin } from '../../common_data'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const UserManagementRepository = RepositoryFactory.get(
  'ConfigurationModule',
  'userManagement'
)

export default {
  name: 'assignRoleToUserForm',
  props:{

    // users_role_prop:{
    //   type:Object,
    //   required:true
    // },


  },
  components: {
    FormWizard,
    TabContent,
    'v-select': vSelect
  },
  mixins:[getDropdownMultiSelectData_Mixin(UserManagementRepository, 'users_role')],
  async created () {
    // this.users_role = this.users_role_prop
    if (this.userId) await this.getUsersRole()
    // await this.getDropdowndata()
    this.getAllRoles()
    this.getAllUsers()

  },
  data () {
    return {
      extraValidationErrors: {
        first_name: null,
        last_name: null
      },
      showErrorsTab0: false,
      showErrorsTab1: false,
      users_role: {
        school: null,
        divisions: [],
        sections: [],
        user:null,
        role:null
      },
      roles:[],
      users:[]

    }
  },

  methods: {
    validateStep (prevIndex, nextIndex) {
      const validateStep0 = () => {
        const hasErrors =
          this.$validator.errors.items.filter(
            (error) => error.field === 'school' ||
              error.field === 'branch' ||
              error.field === 'division' ||
              error.field === 'section' ||
              error.field === 'selected_type'
          ).length !== 0
        if (hasErrors) this.$refs['new-user-wizard'].changeTab(nextIndex, prevIndex)
        this.showErrorsTab0 = true
      }
      const validateStep1 = () => {
        const hasErrors =
          this.$validator.errors.items.filter((error) => {
            if (this.users_role.type === 1) return (
              error.field === 'first_name' ||
              error.field === 'last_name' ||
              error.field === 'email' ||
              error.field === 'phone' ||
              error.field === 'gender' ||
              error.field === 'role' ||
              error.field === 'users'
            )
            else return (
              error.field === 'first_name' ||
              error.field === 'last_name' ||
              error.field === 'email' ||
              error.field === 'phone' ||
              error.field === 'gender' ||
              error.field === 'selected_subjects' ||
              error.field === 'selected_console' ||
              error.field === 'role' ||
              error.field === 'users'
            )
          }).length !== 0
        if (hasErrors) this.$refs['new-user-wizard'].changeTab(nextIndex, prevIndex)
        this.showErrorsTab1 = true
      }
      if (nextIndex > prevIndex) {
        this.$validator.validate().then((valid) => {
          switch (prevIndex) {
          case 0:
            validateStep0()
            break
          case 1:
            validateStep1()
            break
          }
        })
      }
    },

    acceptOnlyLetters (newChar) {
      const field_name = newChar.originalTarget.name
      this.extraValidationErrors[field_name] = null
      const charCode = newChar.charCode
      // Upper Case Letters
      if (charCode >= 65 && charCode <= 90) return true

      // Lower Case Letters
      if (charCode >= 97 && charCode <= 122) return true

      // Space Charachter
      if (charCode === 32) return true
      this.extraValidationErrors[field_name] =
       this.$t('config.letters_and_spaces_are_the_only_allowed_characters')
      newChar.preventDefault()
    },

    allowOnlyOneSpace (space) {
      const field_name = space.originalTarget.name
      this.extraValidationErrors[field_name] = null
      const value = space.originalTarget.value
      if (value.length !== 0 && value[value.length - 1] !== ' ') return true
      this.extraValidationErrors[field_name] =        this.$t('config.only_one_space_is_allowed')

      space.preventDefault()
    },

    getAllRoles () {
      UserManagementRepository.getAllRoles()
        .then(res => {
          this.roles = res.data
        })
    },
    getAllUsers () {

      if (this.userId) {
        UserManagementRepository.getAllUsers()
          .then(res => {
            this.users = res.users

          })
      } else {
        UserManagementRepository.getAllUserNotHaverRoles().then(res => {
          this.users = res.users

        })
      }
    },
    async getUsersRole () {
      await UserManagementRepository.getUsersRole(this.userId)
        .then(res => {
          this.users_role = res.data
          this.users_role.user = res.data.id
        })
      if (!this.users_role.school) this.users_role.school = this.one_school_id
    },
    create () {
      UserManagementRepository.createUsersRole(this.users_role)
        .then((res) => {
          this.successMsg(this.$t('form.saved_successfully'))
          this.$router.push({ name: 'UsersList' })
        }, (error) => {
          this.errMsg(error)
        })
    },
    edit () {
      UserManagementRepository.editUsersRole(this.userId, this.users_role)
        .then((res) => {
          this.successMsg(this.$t('form.saved_successfully'))
          this.$router.push({ name: 'UsersList' })
        }, (error) => {
          this.errMsg(error)
        })
    },
    formSubmitted () {
      if (this.userId) this.edit()
      else this.create()
    }

  },
  computed: {
    userId () {
      return this.$route.params.id || null
    }
  },
  watch:{
    'users_role.role' (v) {
      if (this.users_role.roleName) this.users_role.roleName = this.roles.find((role) => role.id === v).name

    }
  }

}
</script>

<style>
</style>
