export default {
  classes: 'Classes',
  AcademicYear: 'Academic Year',
  academicYear: 'Academic Year',
  selectDivisions: 'select Divisions',
  Divisions: 'Divisions',
  ClassesList: 'Classes List',
  ClassesShow: 'Classes Show',
  ClassesCreate: 'Create Class',
  ClassCreate: 'Create Class ',
  ClassesEdit: 'Classes Edit',
  division: 'division',
  Status: 'Status',
  Actions: 'Actions',
  Name: 'Name',
  code: 'Code',
  average: 'Average',
  Students: 'Students',
  ChooseDivision: 'ChooseDivision',
  AllDivisions: 'All Divisions',
  ChooseGrads: 'ChooseGrads',
  AllGrads: 'All Grads',
  BasicInformation: 'Basic Information',
  AssignStudents: 'Assign Students',
  search: 'search',
  selectGrades: 'select Grades',
  grades: 'grades',
  overview: 'overview',
  actions: 'Actions',
  selectclasses: 'select classes',
  Customize: 'Customize'
}
