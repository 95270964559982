export default {
  school: 'School',
  first_name: 'First name',
  middle_name: 'Middle name',
  last_name: 'Last name',
  birth_date: 'Birth date',
  religion: 'Religion',
  nationality: 'Nationality',
  address_street: 'Address street',
  address_country: 'Address country',
  address_city: 'Address city',
  address_district: 'Address district',
  mobile_num: 'Mobile number',
  tel_num: 'Landline',
  student_email: 'Student email',
  official_email: 'Official email',
  work_email: 'Work email',
  id_num: 'ID number',
  division: 'Division',
  grade: 'Grade',
  school_year: 'School year',
  work_moblie_num: 'Work mobile number',
  work_tel_num: 'Work telephone number',
  syllabus_code: 'Syllabus Code',
  selected_divisions: 'Selected Divisions',
  selected_grades: 'Selected Grades',
  selected_semesters: 'Selected Semesters',
  selected_subjects: 'Selected Subjects',
  selected_teachers: 'Selected Teachers',
  selectedRecurrent_classes: 'selected Recurrent classes',
  start_date: 'Start Date',
  end_date: 'End Date',
  week_start_day: 'Week Start Day',
  holidays_public_name_0: 'Holidays Public Name',
  terms_PublicName_0: 'Term Public Name',
  terms_StartDate_0: 'Terms Start Date',
  terms_EndDate_0: 'terms End Date',
  term_start_date_0: 'Terms Start Date',
  term_end_date_0: 'terms End Date',
  term_public_name_0: 'Term Public Name',
  term_start_date_1: 'Terms Start Date',
  term_end_date_1: 'terms End Date',
  term_public_name_1: 'Term Public Name',
  term_start_date_2: 'Terms Start Date',
  term_end_date_2: 'terms End Date',
  term_public_name_2: 'Term Public Name',
  term_start_date_3: 'Terms Start Date',
  term_end_date_3: 'terms End Date',
  term_public_name_3: 'Term Public Name',
  term_start_date_4: 'Terms Start Date',
  term_end_date_4: 'terms End Date',
  term_public_name_4: 'Term Public Name',
  term_start_date_5: 'Terms Start Date',
  term_end_date_5: 'terms End Date',
  term_public_name_5: 'Term Public Name',
  term_start_date_6: 'Terms Start Date',
  term_end_date_6: 'terms End Date',
  term_public_name_6: 'Term Public Name',
  vacations_StartDate_0: 'vacation Start Date',
  vacations_EndDate_0: 'vacations End Date',
  vacations_PublicYear_0: 'vacations Public Year',
  vacation_start_date_0: 'Vacation Start Date',
  vacation_end_date_0: 'Vacation End Date',
  vacation_public_name_0: 'Vacation Public Name',
  vacation_start_date_1: 'Vacation Start Date',
  vacation_end_date_1: 'Vacation End Date',
  vacation_public_name_1: 'Vacation Public Name',
  vacation_start_date_2: 'Vacation Start Date',
  vacation_end_date_2: 'Vacation End Date',
  vacation_public_name_2: 'Vacation Public Name',
  vacation_start_date_3: 'Vacation Start Date',
  vacation_end_date_3: 'Vacation End Date',
  vacation_public_name_3: 'Vacation Public Name',
  vacation_start_date_4: 'Vacation Start Date',
  vacation_end_date_4: 'Vacation End Date',
  vacation_public_name_4: 'Vacation Public Name',
  vacation_start_date_5: 'Vacation Start Date',
  vacation_end_date_5: 'Vacation End Date',
  vacation_public_name_5: 'Vacation Public Name',
  vacation_start_date_6: 'Vacation Start Date',
  vacation_end_date_6: 'Vacation End Date',
  vacation_public_name_6: 'Vacation Public Name',
  holidays_date_0: 'holidays date',
  holidays__public_name_0: 'Holidays Public Name',
  holidays__end_date_0: 'Holidays End Date',
  holidays__start_date_0: 'Holidays Start Date',
  holidays__public_name_1: 'Holidays Public Name',
  holidays__end_date_1: 'Holidays End Date',
  holidays__start_date_1: 'Holidays Start Date',
  holidays__public_name_2: 'Holidays Public Name',
  holidays__end_date_2: 'Holidays End Date',
  holidays__start_date_2: 'Holidays Start Date',
  holidays__public_name_3: 'Holidays Public Name',
  holidays__end_date_3: 'Holidays End Date',
  holidays__start_date_3: 'Holidays Start Date',
  holidays__public_name_4: 'Holidays Public Name',
  holidays__end_date_4: 'Holidays End Date',
  holidays__start_date_4: 'Holidays Start Date',
  holidays__public_name_5: 'Holidays Public Name',
  holidays__end_date_5: 'Holidays End Date',
  holidays__start_date_5: 'Holidays Start Date',
  holidays__public_name_6: 'Holidays Public Name',
  holidays__end_date_6: 'Holidays End Date',
  holidays__start_date_6: 'Holidays Start Date',
  General_mobile_key: 'key',
  General_tel_key: 'key',
  AccountingTelkey: 'key',
  Accounting_mobile_key: 'key',
  Reception_tel_key: 'key'
}