import {RepositoryFactory} from '@/Repositories/RepositoryFactory'

export default {
  name: 'tasks',
  props:['params', 'student'],

  components: {},
  data () {
    return {
      StudentRepository: RepositoryFactory.get('StudentsModule', 'student'),
      tasks: [],
      customEvents: [{eventName: 'changeTaskData', callback: this.updateGrid}],
      /////////////////////////
      // Pagination
      /////////////////////////
      totalPages:0,
      currentPage:1,
      countPerPage: 10  }
  },
  computed: {
    is_student () {
      return this.$acl.check('Student')
    },
    is_parent () {
      return this.$acl.check('Parent')
    }
  },
  watch: {
    'student' (val) {
      if (this.is_parent) this.getOneStudentTasks(val.user_id)
    },
    currentPage (v) {
      this.getTasks(v)
      if (this.is_parent) this.getOneStudentTasks(this.student.user_id, v)
    }
  },
  created () {
    ////////////////////////////////////////////////////
    //**          Custom Event Listener           ** //
    //////////////////////////////////////////////////
    this.customEvents.forEach(function (customEvent) {
      // eslint-disable-next-line no-undef
      $sharedEvent.listen(customEvent.eventName, customEvent.callback)
    })
  },
  mounted () {

    if (this.is_student) this.getTasks()
    else if (this.is_parent) this.getOneStudentTasks(this.student.user_id)

  },
  destroyed () {
    ////////////////////////////////////////////////////
    //**      Remove Custom Event Listener        ** //
    //////////////////////////////////////////////////
    this.customEvents.forEach(function (customEvent) {
      // eslint-disable-next-line no-undef
      $sharedEvent.off(customEvent.eventName, customEvent.callback)
    })
  },
  methods: {
    /////////////////////////
    // API
    ////////////////////////
    tasksRequest (endpoint, user_id = null, page = null) {
      //** Show Loader **//
      this.$vs.loading()
      this.StudentRepository[endpoint](page, user_id)
        .then(res => {
          this.tasks = res.data.sort((a, b) => b.id - a.id)
          this.checkDates()
          if (res.per_page) this.setPagination(res)
        })
        .catch(err => {
          console.error(err)

          this.errMsg(err.data.message, this.$t('form.loading_data_error'))

        })
        //** Hide Loader **//
        .finally(() => this.$vs.loading.close())

    },
    getTasks (page) {
      this.tasksRequest('getStudentTasks', null, page || this.currentPage)
    },
    getOneStudentTasks (user_id) {
      this.tasksRequest('getOneStudentTasks', user_id)
    },
    getSingleTask (data) {
      this.$router.push({name:'answer_task', params:{id:data.id}})
    },
    openGrade (data) {
      this.$router.push({name:'task-grade', params:{id:data.id}})
    },
    checkDates () {
      const currentDate = this.currentDate
      this.tasks.forEach(task => {
        // calc dates //
        task.daysLeft = this.daysBetweenDates(new Date(`${task.assessment_date} ${task.assessment_time}`), currentDate)
        console.log('task.daysLeft', task.daysLeft)
        // Format Date //
        //task.datetime_launch = this.formatDate(task.datetime_launch)
        // task.assessment_date = this.formatDate(task.assessment_date)
        if (task.task) {
          //** check for task answer delayed or not */
          const dueDate = `${task.assessment_date.split('-').reverse().join('-')} ${task.assessment_time}`
          console.log('format date', this.formatDateTime(new Date(task.task.submit_date)), dueDate)
          if (this.formatDateTime(new Date(task.task.submit_date)) <= dueDate) {
            task.daysLeftAction = 'submitted'
            task.daysLeftText = 'Submitted'
          } else {
            const delayedDays = this.daysBetweenDates(new Date(task.task.submit_date), new Date(task.assessment_date.split('-').reverse().join('-')))
            console.log('delayed date', new Date(task.task.submit_date), new Date(task.assessment_date.split('-').reverse().join('-')))
            // debugger
            task.daysLeftAction = 'submitted'
            task.daysLeftText = `Done but Late (${delayedDays})`
          }
        } else if (task.daysLeft > 1) {
          task.daysLeftAction = 'spare-time'
          task.daysLeftText = `${task.daysLeft} Days to submit`
        } else if (task.daysLeft === 1 || task.daysLeft === 0) {
          task.daysLeftAction = 'take-action'
          task.daysLeftText = 'Time to take action'
        } else if (task.daysLeft < 1) {
          task.daysLeftAction = 'delayed-time'
          task.daysLeftText = `${task.daysLeft} Days Late`
        }

        console.log('task:', task)
      })
    },
    updateGrid (item) {
      const ItemIndex = this.tasks.findIndex(task => task.id === item.id)
      this.$set(this.tasks, ItemIndex, item)
    },
    canAnswer (task) {
      if (task.task && task.task.status.key === 'draft') {
        if (task.daysLeftAction === 'delayed-time' && !task.is_disable_task_date) {
          return true
        } else if (task.daysLeftAction === 'delayed-time' && task.is_disable_task_date) {
          return false
        } else {
          return true
        }
      } else if (task.status.key === 'active' && !task.task) {
        if (task.daysLeftAction === 'delayed-time' && !task.is_disable_task_date) {
          return true
        } else if (task.daysLeftAction === 'delayed-time' && task.is_disable_task_date) {
          return false
        } else {
          return true
        }
      }
    },
    canShowGrades (task) {
      if (task.task && task.task.status.key === 'active' && task.task.submit_date) {
        if (task.task.assess) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    isLate (task) {
      if (task.daysLeft >= 0) return false
      else return true
    },
    /////////////////////////
    // Pagination
    /////////////////////////
    setPagination (data) {

      this.totalPages =  data.last_page
      this.countPerPage = data.per_page
      this.currentPage = data.current_page
    }
  }
}

