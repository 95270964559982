<template>
  <ListPageWithCreateButton
    :createBtnName="$t('assignment.Create_Assignment')"
    createRoutName="assignment_create"
  >
    <template v-slot:leftSideButtonContent v-if="!isMobileWebView">
      <status-counter
        :status_prop="status"
        @filter-change="changeFilter"
        ref="status_counter"
      ></status-counter>
    </template>

    <template v-slot:listDataContent>
      <div class="mt-5">
        <transition-group
          class="list-transition mt-6"
          name="list-enter-up"
          tag="ul"
          v-if="assignments.length"
        >
          <li
            class="mb-12"
            v-for="(assignment, i) in assignments"
            :key="assignment.id"
            :style="[{ transitionDelay: i * 0.1 + 's' }]"
          >
            <assignment-card
              :assignment_prop="assignment"
              @statusChanged="statusChanged"
            />
          </li>
        </transition-group>
        <vx-card v-else>
          <div class="text-center">
            <p>
              {{ $t("assignment.no_assignments_found") }}
            </p>
          </div>
        </vx-card>
      </div>
      <template v-if="assignments.length > 0 && totalPages > 1">
        <vs-pagination
          :total="totalPages"
          :max="countPerPage"
          v-model="currentPage"
          class="mt-6"
        />
      </template>
    </template>
    <template v-slot:rightSide v-if="!isMobileWebView">
      <sidebar-filter
        ref="sidebarFilter"
        :filters_prop="filters"
        :active_prop="true"
        @refilter="setFilters"
      >
        <template v-slot:extra_content>
          <vs-input
            icon="search"
            :placeholder="$t('assignment.search_student_by_id_or_name')"
            v-model="searchData"
            class="w-full"
            @input="fetchSearch(searchData)"
          />
        </template>
      </sidebar-filter>
    </template>
  </ListPageWithCreateButton>
</template>

<script>
import ListPageWithCreateButton from '@/components/listPageGridSystem/withCreateButton'
import StatusCounter from '@/components/general/multiDataStatusCounter'
import SidebarFilter from '@/components/general/RoundFilter.vue'
import assignmentCard from '../../../components/AssignmentCard.vue'
import _ from 'lodash'

import { RepositoryFactory } from '@/Repositories/RepositoryFactory'

const AssignmentRepository = RepositoryFactory.get(
  'AssignmentsModule',
  'assignments'
)

export default {
  name: 'assignmentsList',
  components: {
    ListPageWithCreateButton,
    StatusCounter,
    SidebarFilter,
    assignmentCard
  },
  data () {
    return {
      assignments: [],
      filters: [
        {
          id: 1,
          name: this.$t('basic.Class'),
          payload_name: 'class_ids',
          by: []
        },
        {
          id: 2,
          name: this.$t('basic.subjects'),
          payload_name: 'division_subject_ids',
          by: []
        },
        {
          id: 3,
          name: this.$t('basic.type'),
          payload_name: 'type',
          by: [
            { name: this.$t('assignment.quiz'), checked: false, id: 'quiz' },
            {
              name: this.$t('assignment.homework'),
              checked: false,
              id: 'homework'
            },
            { name: this.$t('assignment.essay'), checked: false, id: 'essay' },
            { name: this.$t('assignment.paper'), checked: false, id: 'paper' },
            { name: this.$t('assignment.test'), checked: false, id: 'test' },
            {
              name: this.$t('assignment.project'),
              checked: false,
              id: 'project'
            }
          ]
        },
        {
          id: 4,
          name: this.$t('basic.status'),
          payload_name: 'status',
          singleSeletable: true,
          by: [
            {
              name: this.$t('basic.active'),
              checked: false,
              id: 'active'
            },
            {
              name: this.$t('basic.archived'),
              checked: false,
              id: 'archived'
            }
          ]
        }
      ],
      searchData: '',
      topFilter: '',
      sideFilters: {
        class_ids: [],
        division_subject_ids: [],
        type: [],
        status: []
        // assignmentType: [],
        // due_date: []
      },
      searchParam: '',
      /////////////////////////
      // Pagination
      /////////////////////////
      totalPages: 0,
      currentPage: 1,
      countPerPage: 10,
      /////////////////////////
      // Status Filters
      /////////////////////////
      all_assignments_tasks: 0,
      all_assignments_tasks_studens: 0,
      full_assess_tasks: 0,
      full_assess_students: 0,
      need_assess_tasks: 0,
      need_assess_students: 0,
      late_assessment_tasks: 0,
      late_assessment_students: 0
    }
  },
  watch: {
    currentPage (v) {
      this.getTeacherAssignments()
    },
    filtered_statues (v) {
      this.getTeacherAssignments()
    },
    sideFilters: {
      deep: true,
      handler (v) {
        this.getTeacherAssignments()
      }
    }
  },
  computed: {
    status () {
      const status = [
        {
          icon: 'EyeIcon',
          value: '',
          name: this.$t('assignment.all_assignments'),
          color: 'blue',
          sub: [
            {
              name: this.$t('assignment.all_tasks'),
              count: this.all_assignments_tasks
            },
            {
              name: this.$t('basic.students'),
              count: this.all_assignments_tasks_studens
            }
          ]
        },
        {
          icon: 'ActivityIcon',
          value: 'need_assess',
          color: 'dark',
          name: this.$t('assignment.need_to_be_assessed'),
          sub: [
            {
              name: this.$t('basic.tasks'),
              count: this.need_assess_tasks
            },
            {
              name: this.$t('basic.students'),
              count: this.need_assess_students
            }
          ]
        },
        {
          icon: 'CheckIcon',
          value: 'late_assessment',
          color: 'warning',
          name: this.$t('assignment.late_assessments'),
          sub: [
            {
              name: this.$t('basic.tasks'),
              count: this.late_assessment_tasks
            },
            {
              name: this.$t('basic.students'),
              count: this.late_assessment_students
            }
          ]
        },
        {
          icon: 'ActivityIcon',
          value: 'full_assess',
          color: 'success',
          name: this.$t('assignment.fully_assessed'),
          sub: [
            {
              name: this.$t('basic.tasks'),
              count: this.full_assess_tasks
            },
            {
              name: this.$t('basic.students'),
              count: this.full_assess_students
            }
          ]
        }
      ]
      return status
    },
    filtered_statues () {
      let filterName = ''
      let filterValue = ''
      switch (this.topFilter) {
      case 'full_assess':
        filterName = 'full_assess'
        filterValue = 1
        break
      case 'late_assessment':
        filterName = 'late_assessment'
        filterValue = 1
        break

      case 'need_assess':
        filterName = 'need_assess'
        filterValue = 1
        break
      default:
        filterName = ''
        filterValue = ''
        break
      }
      const filter = {}
      filter[filterName] = filterValue
      return filter
    }
  },
  methods: {
    /////////////////////////
    // Loading
    ////////////////////////
    startLoading () {
      this.VSLoading()
    },
    stoptLoading () {
      this.stopVSLoading()
    },

    /////////////////////////
    // Fetch Data
    ////////////////////////
    getTeacherAssignments (search = '') {
      // this.$nextTick(() => {
      this.startLoading()
      const param = {
        page: this.currentPage,
        search,
        ...this.filtered_statues,
        // search:this.searchData,
        ...this.sideFilters
      }
      AssignmentRepository.getTeacherAssignments(param)
        .then(res => {
          this.assignments = res.data
          this.setPagination(res)
        })
        .catch(err => {
          console.log('err', err)
        })
        .finally(() => this.stoptLoading())
      // })
    },


    /////////////////////////
    // Filter methods
    ////////////////////////
    changeFilter (val) {
      this.topFilter = val
    },
    setFilters (val) {
      val.map(item => {
        this.sideFilters[item.name] = item.checked
      })
    },
    fetchSearch: _.debounce(function (str) {
      if (str.length >= 3) {
        // eslint-disable-next-line no-invalid-this
        this.getTeacherAssignments(str)
      }
    }, 300),
    getFiltersData () {
      AssignmentRepository.getTeacherClassesForFilters().then(res => {
        res.data.map(el => {
          this.filters[0]['by'].push({
            id: el.id,
            name: el.name,
            checked: false
          })
        })
      })
      AssignmentRepository.getTeachersSubjects().then(res => {
        res.data.map(el => {
          this.filters[1]['by'].push({
            id: el.id,
            name: el.name,
            checked: false
          })
        })
      })
      AssignmentRepository.getAssignmentStatusCount().then(res => {
        const data = res.data
        this.all_assignments_tasks = data.all_assignments_tasks
        this.all_assignments_tasks_studens = data.students
        this.late_assessment_students = data.late_assessment.students
        this.late_assessment_tasks = data.late_assessment.tasks
        this.need_assess_students = data.need_assess.students
        this.need_assess_tasks = data.need_assess.tasks
        this.full_assess_students = data.full_assess.students
        this.full_assess_tasks = data.full_assess.tasks
      })
    },

    /////////////////////////
    // Action method
    ////////////////////////
    statusChanged () {
      this.getTeacherAssignments()
    }
  },
  created () {
    this.getFiltersData()
    this.getTeacherAssignments()
  }
}
</script>

<style lang="scss" scoped></style>
