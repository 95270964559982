import {mapGetters} from 'vuex'

export default {
  name: 'unit',
  props: ['unitData', 'groupData'],
  data () {
    return {
      pageTitle: 'Units',
      modalName: 'customizeUnitModal'
    }
  },
  mounted () {
    //this.$store.dispatch("yourMutation",'Data');
  },
  destroyed () {
    //this.$store.dispatch("yourMutation",'Data');
  },
  methods: {
    openModal () {
      this.$store.dispatch('manageModal', {
        name: this.modalName,
        show: true,
        data: {groupData: this.groupData, unitDetails: this.unitDetails}
      })
    },
    closeModal () {
      this.$store.dispatch('manageModal', {name: this.modalName, show: false})
    }
  },
  computed: {
    /*anotherExampleForUnitDetails(){
      let yearGroup = this.$store.getters.getPoiYearsGroup.map(items => items.units)[0];
      let data = yearGroup.find(units => units.unitGuid === this.unitData.unitGuid )
      return data;
    },*/
    unitDetails () {
      return this.$store.getters.getPoiYearUnitById(this.groupData.id, this.unitData.id)
    },
    ...mapGetters({futureAcademicYears: 'getFutureAcademicYearsOptions'})
  }
}
