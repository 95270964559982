<template>
  <div>
    <vx-card class="p-relative">
      <vs-row>
        <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="1">
          <h4
            class="mb-4 w-full"
            v-if="showIdentity"
          >
            {{ student.id }}
          </h4>
          <h4 class="mb-4 w-full" v-else>****</h4>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="3">
          <h4
            class="mb-4 w-full"
            v-if="showIdentity"
          >
            {{ student.student.name }}
          </h4>
          <h4 class="mb-4 w-full" v-else>**** *** *****</h4>
        </vs-col>
        <vs-col 
          vs-type="flex" 
          vs-justify="center" 
          vs-align="center" 
          vs-w="2">
          <span v-if="!student.submitted_at">
            <vs-chip transparent color="danger">
              {{ $t("basic.not_answered") }}
            </vs-chip>
          </span>
          <span v-else-if="exam.is_auto_grading && isGraded">
            <vs-chip transparent color="success" class="mr-5">
              {{ student.score }} {{ $t("exam.out_of") }} {{ exam.total_points }}
            </vs-chip>
          </span>
        </vs-col>
        <vs-col 
          vs-w="5" 
          vs-align="center" 
          v-if="exam.is_auto_grading">
          <div class="flex justify-end items-center">
            <div>
              <vs-button
                class="mr-2"
                @click.stop="openGradeModal(student)"
                v-if="isGraded && !isAssessed"
              >
                {{ $t("exam.examAssess") }}
              </vs-button>
            </div>
            <div v-if="isAssessed">
              <div v-if="isMYP">
                <vs-chip
                  transparent
                  color="success"
                  v-for="(assessment , assessIndex) in student.assessment_detailed" :key="assessIndex"
                  class="mr-5"
                >
                  {{ assessment.key }} -
                  {{ assessment.title }} 
                  ({{ assessment.value }})
                </vs-chip>
              </div>
              <vs-chip transparent color="success" v-else class="mr-5">
                {{ $t("exam.assessment_is") }}
                {{ assessmentData }}
              </vs-chip>
            </div>
          </div>
        </vs-col>
        <vs-col vs-w="5" vs-align="center" v-else>
          <div class="flex justify-end items-center">
            <vs-button
              class="mr-2"
              @click.stop="openGradeModal(student)"
              v-if="student.submitted_at && !isGraded && !isAssessed"
            >
              {{ $t("exam.examAssess") }}
            </vs-button>
            <div v-if="isGraded && isAssessed">
              <vs-chip transparent color="success" class="mr-5">
                {{ student.score }} {{ $t("exam.out_of") }} {{ exam.total_points }}
              </vs-chip>
              <div v-if="isMYP">
                <vs-chip
                  transparent
                  color="success"
                  v-for="(assessment , assessIndex) in student.assessment_detailed" :key="assessIndex"
                  class="mr-5"
                >
                  {{ assessment.key }} - {{ assessment.title }} ({{ assessment.value }})
                </vs-chip>
              </div>
              <vs-chip transparent color="success" v-else class="mr-5">
                {{ $t("exam.assessment_is") }}
                {{ assessmentData }}
              </vs-chip>
            </div>
          </div>
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>
<script>
import Axios from 'axios'
export default {
  name: 'assess-card',
  props: {
    student: {
      type: Object,
      required: true
    },
    exam: {
      type: Object
    },
    showIdentity: {
      type: Boolean
    }
  },
  data () {
    return {
      examId: this.$route.params.id
    }
  },
  computed: {
    isGraded () {
      return Boolean(this.student.graded_at)
    },
    isAssessed () {
      return Boolean(this.student.has_assess)
    },
    isMYP () {
      return this.exam.grade ? this.exam.grade.section.name === 'MYP' : ''
    },
    isPYP () {
      return this.exam.grade ? this.exam.grade.section.name === 'PYP' : ''
    },
    assessmentData () {
      return this.isPYP ? this.student.assessment.split('"')[1] : this.student.assessment.split('"')[0]
    }
  },
  methods: {
    openGradeModal (student) {
      if (student.submitted_at) {
        $sharedEvent.fire('openGradeModal', this.student.id)
      } else {
        this.$vs.notify({
          title: 'Not Available',
          text: 'You can\'t grade this student before he submit exam first ',
          color: 'danger',
          position: 'bottom-right'
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.examTypeIcon {
  font-size: 20px;
}

.examnamecard {
  font-size: 12px;
  font-weight: bold;
}

.examCardMiniText {
  font-size: 8px;
}
</style>
