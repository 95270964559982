export default {
  name: 'GenericMenu',
  props: ['menusData'],
  data () {
    return {
      menus: null,
      activeMenu: null
    }
  },
  created () {
    this.menus = this.menusData
  },
  destroyed () {
  },
  methods: {
    action (item) {
      this.activeMenu = item.action
      this.$emit('menu-action', item)
    }
  }
}
