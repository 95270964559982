<!-- =========================================================================================
    File Name: ChatContact.vue
    Description: Chat contact - single component for chat
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div
    class="chat__contact flex items-center px-4 py-2"
    :class="{ 'bg-primary-gradient text-white shadow-lg': isActiveChatUser }"
  >
    <!-- Image -->
    <div class="contact__avatar mr-1">
      <vs-avatar
        class="border-2 border-solid border-white"
        :src="contact.image"
        size="42px"
        v-if="contact.image"
      />
      <vs-avatar
        class="border-2 border-solid border-white"
        :icon="ischatContactGroup ? 'people_alt' : 'person'"
        size="42px"
        v-else
      />
    </div>
    <div
      class="
        contact__container
        w-full
        flex
        items-center
        justify-between
        overflow-hidden
      "
    >
      <!--////////////////////////////
          // LEFT SIDE
      ///////////////////////////// -->
      <div class="contact__info flex flex-col truncate w-5/6">
        <h5
          class="font-semibold truncate"
          :class="{ 'text-white': isActiveChatUser }"
        >
          <span class="truncate">
            {{ contact.name | text_formatter }}
            {{ contact.parent_middle_name || "" | text_formatter }}
            {{ contact.parent_last_name || "" | text_formatter }}
          </span>
        </h5>
        <!-- if chats contact -->
        <template v-if="chatDetails">
          <span class="inline-block">
            <!--////////////////////////////
                  // Msg Content
             ///////////////////////////// -->
            <!-- If not deleted  -->
            <template v-if="!chatDetails.is_deleted">
              <!-- is Seen -->
              <template v-if="chatDetails.isSent && !ischatContactGroup">
                <feather-icon
                  icon="CheckIcon"
                  class="cursor-pointer p-0"
                  :class="
                    chatDetails.is_seen
                      ? `text-${isActiveChatUser ? 'white' : 'primary'}`
                      : 'opacity-50'
                  "
                  svgClasses="h-4 w-4"
                />

                <feather-icon
                  icon="CheckIcon"
                  class="cursor-pointer p-0 -ml-2"
                  :class="
                    chatDetails.is_seen
                      ? `text-${isActiveChatUser ? 'white' : 'primary'}`
                      : 'opacity-50'
                  "
                  svgClasses="h-4 w-4"
                />
              </template>
              <template v-if="chatDetails.attachment">
                <feather-icon
                  icon="FileIcon"
                  class="cursor-pointer p-0"
                  svgClasses="h-4 w-4 "
                />
              </template>
              <template v-if="showSenderName">
                {{ chatDetails.sender_name }} :
              </template>
              <template v-if="chatDetails.is_deleted">
                <template v-if="chatDetails.isSent">
                  {{ $t("chat.you_deleted_this_message") }}
                </template>
                <template v-else>
                  {{ $t("chat.this_message_was_deleted") }}
                </template>
              </template>
              <template v-else>
                {{ chatDetails.content }}
              </template>
            </template>

            <!-- If  deleted  -->
            <span v-else class="opacity-50">
              <feather-icon
                icon="XIcon"
                class="cursor-pointer p-0"
                svgClasses="h-4 w-4"
              />
              <template v-if="isActiveUserSender">
                {{ $t("chat.you_deleted_this_message") }}
              </template>
              <template v-else>
                {{ $t("chat.this_message_was_deleted") }}
              </template>
            </span>
          </span>
        </template>
        <!-- If contacts -->
        <span v-else>
          <template v-if="contact.roles && contact.roles.length">
            <span
              v-for="role in contact.roles"
              :key="role.id"
              class="inline-block"
            >
              {{ role.display_name }}
            </span>
          </template>
          <template v-else-if="contact.role_name">
            <span class="inline-block">
              {{ contact.role_name.replaceAll("(", " - ").replaceAll(")", "") }}
            </span>
          </template>
          <span v-if="contact.code"> - {{ contact.code }} </span>
          <span v-if="contact.class_name"> - {{ contact.class_name }} </span>

          <!-- <span>{{ contact.message }}</span> -->
        </span>
      </div>

      <!--////////////////////////////
         // Right Side
        ///////////////////////////// -->
      <div
        class="chat__contact__meta flex self-start flex-col items-end w-1/6"
        v-if="chatDetails || contact.last_message_time"
      >
        <span class="whitespace-no-wrap">{{
          toDate(contact.last_message_time || chatDetails.created_at)
        }}</span>

        <vs-chip v-if="isNotSeen" class="p-0" color="primary"> </vs-chip>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from '../chat.mixins'
export default {
  name:'chat-contact',
  mixins:[mixins],
  props: {
    contact          : { type: Object,  required: true },
    isActiveChatUser : { type: Boolean },
    lastMessaged     : { type: String,  default : '' },
    showLastMsg      : { type: Boolean, default: false },
    unseenMsg        : { type: Number,  default : 0 }
  },
  computed:{
    chatDetails () {
      const chatDetails = this.contact['chat_details']
      if (chatDetails?.content &&  chatDetails.content.constructor !== String)  chatDetails.content = ''
      if (this.ischatContactGroup) {
        const
          userName = this.activeUser.displayName,
          msgText =  chatDetails.content,
          isDefault = chatDetails.is_default_message,
          hasAdminName = msgText ? msgText.includes(userName) : false

        if (msgText && isDefault && hasAdminName) {
          chatDetails.content = msgText.replace(userName, this.$t('basic.you'))
        }


      }
      return chatDetails
    },
    isActiveUserResiver () {
      return this.chatDetails && (this.chatDetails.sender_id == this.contact.id)
    },
    isActiveUserSender () {
      return this.chatDetails &&  !(this.chatDetails.sender_id == this.contact.id)
    },

    isNotSeen () {
      return (this.isActiveUserResiver) && !this.chatDetails.is_seen && !this.ischatContactGroup
    },
    ischatContactGroup () {
      return Boolean(this.contact.group_id)
    },
    showSenderName () {
      return this.ischatContactGroup && !this.chatDetails.isSent && !this.chatDetails.is_default_message
    }

  },

  methods : {

    toDate (time) {
      const locale = 'en-us',
        date_obj =  new Date(time),
        dateString = date_obj.toLocaleString(),
        monthName = date_obj ? date_obj.toLocaleString(locale, {
          month: 'short'
        }) : '',
        is_today = (new Date().getDate() === date_obj.getDate())
      if (is_today)  {
        const time = dateString.split(',')[1] || ''
        return `${time.split(':').splice(0, 2).join(':') || ''} ${time.split(' ')[2] || ''}`
      } else return `${date_obj.getDate() || '' } ${monthName }`
    }

  }
}
</script>
<style lang="scss">
</style>

