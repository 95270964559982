export default {
  AcademicYears: 'Academic Years',
  academic: 'Academic',
  AcademicYearsCreate: 'Create Academic Year',
  StartEndDates: 'Start & End Dates',
  StartDate: 'Start Date',
  EndDate: 'End Date',
  WeekStartDay: 'Week Start Day',
  SetTerms: 'Set Terms',
  Term: 'Term',
  Semester: 'Semester',
  PublicName: 'Public Name',
  SetVacations: 'Set Vacations',
  VacationName: 'Vacation Name',
  HolidayName: 'Holiday Name',
  PublicYear: 'Public Year',
  SetSpecialHoliday: 'Set Special Holiday',
  ChooseDate: 'Choose Date',
  DataSettings: 'Data & Settings',
  DivisionsCustomization: 'Divisions Customization',
  AllowDivisionToSetSpecialStartDate:
    'Allow division to set special start date',
  AllowDivisionToSetSpecialEndDate: 'Allow division to set special end date',
  AllowDivisionToSetSpecialDaysAndHours:
    'Allow division to set special days and hours',
  AllowDivisionToSetSpecialTerms: 'Allow division to set special terms',
  AllowDivisionToSetSpecialHolidaysAndVacations:
    'Allow division to set special holidays and vacations',
  TermsVacations: 'Terms & Vacations',
  CreatedBy: 'Created By',
  AcademicYear: 'Academic Year',
  DateOfCreation: 'Date Of Creation',
  Status: 'Status',
  Actions: 'Actions',
  AcademicYearsEdit: 'Edit Academic Years',
  AcademicYearsShow: 'Show Academic Years',
  Academic_Days: 'Academic Days',
  Special_Vacations: 'Special Vacations',
  Days_Special_Vacations: 'Days Special Vacations',
  With_semester: 'With semester',
  Without_semester: 'Without semester',
  date_should_be_equal_or_later_than_today:
    'Date should be equal or later than today',
  subAcademicYear: 'Sub Academic Year',
  SubAcademicYearsCreate: 'Create Sub Academic Years',
  divisions: 'Division',
  divisionsAndClass: 'Divisions & Classes',
  class: 'Class',
  section: 'Section',
  subAcademicYearsCreate: 'Create Sub Academic Year',
  subAcademicYearsEdit: 'Edit Sub Academic Years',
  subAcademicYearsShow: 'Show Sub Academic Years',
  chooseSections: 'Choose Sections',
  allSections: 'All Sections',
  exist_active_academic_year: 'Exist Active Academic Year',
  active_academic_must_be_start_date_of_current_year:
    'Active Academic must be start date of current year',
  the_selected_row_was_successfully_updated:
    'The selected row was successfully updated',
  days_hours: 'Days & Hours',
  first_bell: 'First Bell',
  last_bell: 'Last Bell',

  you_should_select_in_section:
    'This level of configuration already exists You should select in sections',
  you_should_select_in_grad:
    'This level of configuration already exists You should select in grades',
  you_should_change_grad:
    'This level of configuration already exists You should change grade',
  grad: 'Grade',
  go_to_sub: 'Go To Subs',
  remove_semester: 'remove Semester',
  add_semesters: 'add semester',
  academicDays: 'Academic Days',
  totalDays: 'Total Days',
  totalHoures: 'Total Houres',
  academicweeks: 'Working Weeks',
  totalWeekends: 'total Weekends',
  specialHolidays: 'Special Holidays',
  overview: 'Overview',
  strands: 'strands',
  strands_subjects: 'strands subjects',
  add_subject_strands: 'add subject strands',
  edit_subject_strands: 'add strands subjects',
  add_strands: 'add strands',
  delete_msg:
    ' There is no "undo action" for this action.!! \n' +
    '\n' +
    ' All sub academic years will be automatically deleted, including all other SNDs.\n' +
    '\n' +
    'Are you sure ? '
}
