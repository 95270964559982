import moment from 'moment'

export default { 
// #TODO: use moment js in global mixin
  methods :{
    dateNow () {
      return moment().format('YYYY-MM-DD')
    }
      
  }

}
