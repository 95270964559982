<template>
  <vx-card class="p-3 pt-6 pb-6 md:p-6">
    <div class="vx-row mb-2" v-for="(detail, i) in motherData" :key="i">
      <div class="vx-col md:w-1/4 w-full mb-2">
        <h6>{{ $t(`StudentProfile.${detail["i18n"]}`) }}</h6>
      </div>

      <div class="vx-col md:w-3/4 w-full mb-2">
        <label>{{ detail.value }}</label>
      </div>
    </div>
  </vx-card>
</template>

<script>
export default {
  name: 'mother',
  props: {
    component_data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      student:this.component_data,
      motherData:[],
      notFound:this.$t('basic.data_is_not_available')

    }
  },
  computed: {
    mother_details () {
      if (this.student.parents_data) {
        if (this.student.parents_data[1].birth_date) return this.student.parents_data[1]
        else  return this.student.mother
      } else {
        return this.student.mother
      }
    },
    fullName () {
      return `${this.mother_details.first_name} ${this.mother_details.middle_name || ''} ${this.mother_details.last_name || ''}`
    },
    mobile_key_name () {
      return `${this.mother_details.contact_mobile ? 'contact_mobile' : 'mobile'}`
    },
    mobile () {
      if (this.mother_details.contact_mobile) return this.mother_details.contact_mobile
      else return `${this.mother_details[this.mobile_key_name].code}${this.mother_details[this.mobile_key_name].number}`
    },
    telephone_key_name () {
      return `${this.mother_details.contact_telephone ? 'contact_telephone' : 'telephone'}`
    },
    telephone () {
      if (this.mother_details.contact_telephone) return this.mother_details.contact_telephone
      else return `${this.mother_details[this.telephone_key_name].code}${this.mother_details[this.telephone_key_name].number}`
    },
    work_mobile () {
      const data = this.mother_details.work_mobile
      if (data) {
        if (typeof data !== 'object') return data
        else return `${this.mother_details.work_mobile.code}${this.mother_details.work_mobile.number}`
      }
    },
    religion () {
      if (this.student.farther_religion) return this.student.farther_religion.translations.find(el => el.locale === this.current_locale).name
      else return this.notFound
    },
    city () {
      if (this.student.farther_city) return this.student.farther_city.translations.find(el => el.locale === this.current_locale).name
      else return this.notFound
    },
    current_locale () {
      return this.$store.getters['main/currentLocale']
    },
    nationality () {
      if (this.student.farther_nationality) return this.student.farther_nationality.join(' ')
      else return this.notFound
    },
    district () {
      if (this.mother_details.ditsrtict) return this.mother_details.ditsrtict
      else return this.mother_details.district
    },
    email () {
      if (this.mother_details.contact_email_official) return this.mother_details.contact_email_official
      else return this.mother_details.email
    },
    birthDate () {
      if (this.mother_details.birth_date.includes('-')) return  this.mother_details.birth_date
      else return Number(this.mother_details.birth_date)
    }

  },
  created () {
    const  birthDate = this.birthDate,
      birthDate_Text = `${new Date(birthDate).getDate() }/${new Date(birthDate).getMonth() + 1}/${new Date(birthDate).getFullYear()}`

    // Add the data
    this.motherData.push({title: this.$t('basic.fullname'), value: this.fullName})
    this.motherData.push({title: this.$t('basic.birthday'), value: (birthDate ? birthDate_Text : this.notFound)})
    this.motherData.push({title: this.$t('basic.nationality'), value: this.nationality})
    // this.motherData.push({title: this.$t('basic.gender'), value: `${this.mother_details.gender}`})
    this.motherData.push({title: this.$t('basic.telephones'), value: `${this.mobile} - ${this.telephone} - ${this.work_mobile}`})
    this.motherData.push({title: this.$t('basic.religion'), value: this.religion})
    this.motherData.push({title: this.$t('basic.email'), value:this.email})
    this.motherData.push({title: this.$t('basic.address'), value: `${this.city}, ${this.district}, ${this.mother_details.street}`})
    this.motherData.push({title: this.$t('basic.work_details'), value: `${this.mother_details.occupation || this.notFound}`})

    // Set the i18n of each element by removing spaces and putting _ (Add the i18n keys to StudentProfile file)
    this.motherData.map(data => {

      data['i18n'] = data.title.toLowerCase().replace(/ /g, '_')
      return data
    })
    console.log('this.motherData', this.motherData)


  }
}
</script>
