<template>
  <div>
    <div class="file-upload flex items-center">
      <label v-if="!success" class="upload-label" for="upload">
        <div class="upload-container">
          <span>Upload</span>
          <feather-icon class="m-1" icon="UploadCloudIcon" />
        </div>
      </label>
      <input
        id="upload"
        ref="file"
        :accept="(accept ? accept : 'all') + ';capture=camera'"
        :text="text ? text : $t('basic.upload_file')"
        class="file-upload__input"
        multiple
        name="file-upload"
        type="file"
        v-on:change="submitFiles()"
      />
      <span class="ml-4">
        {{
          `${$t("basic.max_size_to_upload_is")} ${numberProp} ${$t("basic.mb")}`
        }}</span
      >
    </div>
    <div v-if="success" style="width: 10rem; overflow: hidden">
      <vs-progress color="primary" indeterminate>primary</vs-progress>
    </div>
    <p>{{ files }}</p>
  </div>
</template>

<script>
import unitPlannerSources from '../../services/unit-planner.js'

export default {
  props: {
    accept: {},
    text: { type: String },
    multiple: { type: Boolean },
    numberProp: {
      type: String,
      default: () => '15'
    }
  },
  data () {
    return {
      files: '',
      image4: null,
      success: false
    }
  },

  methods: {
    submitFiles () {
      this.success = true

      const formData = new FormData()

      this.$refs.file.files.forEach((file, index) => {
        formData.append(`attachments[${index}]`, file)
      })

      unitPlannerSources
        .uploadFiles(formData)
        .then(response => {
          this.success = false
          this.$emit('uploadedFiles', response)
        })
        .catch(err => {
          this.success = false
          console.error(err)
          //** Hide Loader **//
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Uploading Error',
            text: err.data.message,
            color: 'danger',
            position: 'bottom-right'
          })
        })
      /* console.log("dddddddddd", formData);
       console.log("fffffffffffffffff", this.files);*/
    },
    click () {
      this.$refs.file.click()
    },
    handleFilesUpload () {
      this.files = this.$refs.file.files
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-label {
  display: flex;
  justify-content: center;
  width: 110px;
  height: 43px;
  border-radius: 5px;
  z-index: 200;
  cursor: pointer;
}

.upload-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  background-color: rgba(var(--vs-primary), 1);
  color: white;
  font-size: 15px;
  font-weight: 500;
  z-index: 1;
}

.file-upload {
  position: relative;
  display: inline-block;

  img {
    width: 2rem;
    display: inline-block;
    float: left;
    margin-right: 1rem;
    margin-top: -0.4rem;
  }
}

.file-upload__input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  font-size: 16px;
  width: 0;
  height: 100%;
  opacity: 0;
}

.loading {
  position: relative;

  animation: loading 500ms infinite linear;

  display: inline;
  position: absolute;
  left: 13px;
  top: 0;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
