<template>
  <div id="agendaCard">
    <vx-card class="p-relative mb-8">
      <div
        class="
          min_height_110
          grid
          md:grid-cols-12
          grid-cols-2
          p-1
          pt-1
          pb-1
          md:p-1
        "
      >
        <!-- Name -->
        <div class="text-capitalize col-span-3">
          <h5 class="text-capitalize">
            {{ agenda.name }}
          </h5>
        </div>

        <div class="text-capitalize col-span-3 flex flex-col">
          <p class="text-sm flex flex-wrap justify-between items-center">
            <span class="ml-2"
              >{{ $t("basic.by") }} {{ agenda.coordinatorName }}</span
            >
          </p>
          <p class="text-sm inline-block mt-4 text-capitalize">
            <feather-icon icon="CalendarIcon" svgClasses="h-4 w-4 mr-2 " />
            {{ agenda.createdAt }}
          </p>
        </div>
        <!-- Date && Time -->
        <div class="col-span-5">
          <p
            class="
              text-sm
              w-full
              md:w-2/3
              flex flex-wrap
              justify-between
              items-center
              mt-4
              md:mt-0
            "
          >
            <span class="ml-2">{{ classesNames }}</span>
          </p>
        </div>
        <!-- Date && Time -->
        <div class="col-span-1 mt-4 md:mt-0 flex flex-wrap justify-end">
          <feather-icon
            icon="DownloadIcon"
            svgClasses="h-6 w-6 hover:text-primary  mr-4"
            @click.stop="downloadFile"
          />
          <feather-icon
            @click.stop="deleteItem"
            v-if="is_coordinator"
            icon="MinusCircleIcon"
            svgClasses="h-6 w-6 mr-2 hover:text-danger"
          />
        </div>
      </div>
    </vx-card>
  </div>
</template>
<script>
// APIs
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const AgendaRepository = RepositoryFactory.get(
  'AgendaRepository',
  'AgendaRepository'
)

// Mixins
import mixins from '../../mixins'

export default {
  name: 'agenda-card',
  props: {
    agenda_prop: {
      type: Object,
      required: true
    }
  },
  mixins: [mixins],
  components: {},
  data () {
    return {
      agenda: this.agenda_prop,
      userRule: ''
    }
  },

  computed: {
    canDelete () {
      return this.is_coordinator
    },
    classesNames () {
      return this.agenda_prop.classesName.join(' , ')
    }
  },
  watch: {},
  methods: {
    //Preview
    preview () {
      // console.log(this.$refs.preview)
      // this.$refs.preview.click()
    },
    deleteAgandaItem () {
      const data = {
        id: this.agenda.id
      }
      this.loading()
      AgendaRepository.deleteAgenda(data)
        .then(res => {
          this.$emit('deleteItem', data.id)
          this.successMsg(`${this.$t('agenda.deleted_successfully')}`)
        })
        .catch(err => this.errMsg(err))
        .finally(() => this.stopLoading())
    },

    /////////////////////////////////
    // deleteItem
    ////////////////////////////////
    deleteItem () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('agenda.delete_selected_item'),
        text: `${this.$t('agenda.are_you_sure_you_want_delete_this_item')}`,
        accept: () => {
          this.deleteAgandaItem()
        }
      })
    },

    toDataURL (requestUrl) {
      const proxyUrl = 'https://cors-anywhere.herokuapp.com/'
      return fetch(requestUrl)
        .then(res => {
          return res.blob()
        })
        .then(blob => {
          return URL.createObjectURL(blob)
        })
    },
    async downloadFile () {
      this.VSLoading()
      const a = document.createElement('a')
      a.href = await this.toDataURL(this.agenda.document)
      a.download = 'Attachment'
      a.target = '_blank'
      document.body.appendChild(a)
      a.click()
      this.stopVSLoading()
      document.body.removeChild(a)
    }
  },
  created () {
    this.userRule = this.$acl.get[0].toLowerCase()
    if (this.is_coordinator) {
      // this.checkAllowToUploadFinalReport()
      // this.checkStudentInterviews()
    }
  }
}
</script>
<style lang="scss">
.con-vs-chip.flex.justify-between {
  .text-chip.vs-chip--text {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

iframe {
  border: 1px solid #ccc;
}
#agendaCard {
  .hasUpdates {
    position: absolute;
    right: 9px;
    top: -15px;
  }
}
</style>
