<template>
  <vx-card class="p-3 pt-6 pb-6 md:p-6">
    <div class="mt-5">
      <form-wizard
        color="rgba(var(--vs-primary), 1)"
        :title="null"
        :subtitle="null"
        finishButtonText="Submit"
        ref="edit-syllabus-wizard"
        @on-change="validateStep"
        @on-complete="formSubmitted">
        <!-- @on-complete="formSubmitted" -->
        <tab-content :title="`${$t('Syllabus.divisionsGrades')}`" class="mb-5">
          <div class="vx-row">
            <div class="vx-col sm:w-1/5 w-full mt-5">
              <label>{{ $t('Syllabus.academicYear') }}</label>
            </div>
            <div class="vx-col sm:w-3/5 w-full mt-5">
              <v-select
                label="name"
                :reduce="(a) => a._id"
                v-model="new_syllabus.academic_year_id"
                v-validate="'required'"
                class="w-full"
                name="academic_year"
                :clearable="false"
                :disabled="!allAcademicYears.length"
                :loading="loadingAcademicYears"
                :options="allAcademicYears" />
              <span v-if="showErrorsTab0" class="text-danger text-sm">{{
                errors.first('academic_year') | text_formatter
              }}</span>
            </div>
          </div>
          <!-- tab 1 content -->
          <div class="vx-row">
            <div class="vx-col sm:w-1/5 w-full mt-5">
              <label>{{ $t('Syllabus.divisions') + ' ' }}</label>
              <span class="text-danger">*</span>
            </div>
            <div class="vx-col sm:w-3/5 w-full mt-5">
              <v-select
                closeOnSelect
                :clearable="false"
                label="long_name"
                :reduce="(division) => division.id"
                :placeholder="syllabusInfo.divisionName"
                v-model="new_syllabus.division"
                v-validate="'required'"
                class="w-full"
                name="selected_divisions"
                :loading="loadingDivisions"
                @input="availableGrades"
                :options="divisions_all"
                disabled />
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col sm:w-1/5 w-full mt-5">
              <label>{{ $t('Syllabus.grades') + ' ' }}</label>
              <span class="text-danger">*</span>
            </div>
            <div class="vx-col sm:w-3/5 w-full mt-5">
              <v-select
                :clearable="false"
                closeOnSelect
                :placeholder="syllabusInfo.gradeName"
                label="name"
                :reduce="(grade) => grade.id"
                v-model="new_syllabus.grade"
                v-validate="'required'"
                class="w-full"
                name="selected_grades"
                :loading="loadingGrades"
                @input="getNextStepData()"
                :options="grades_all"
                disabled />
              <span v-if="showErrorsTab0" class="text-danger text-sm">{{
                errors.first('selected_grades') | text_formatter
              }}</span>
            </div>
          </div>
        </tab-content>

        <!-- tab 2 content -->
        <tab-content
          :title="`${$t('Syllabus.semestersSubjects')}`"
          class="mb-5">
          <div class="vx-row">
            <div class="vx-col sm:w-1/5 w-full mt-5">
              <label>{{ $t('Syllabus.terms') + ' ' }}</label>
              <span class="text-danger">*</span>
            </div>
            <div
              class="vx-col sm:w-2/3 w-full mt-5"
              v-if="new_syllabus.with_semester == 'without_semester'">
              <v-select
                @option:selecting="showTerm"
                multiple
                :closeOnSelect="false"
                :clearable="false"
                :placeholder="$t('Syllabus.selectTerms')"
                label="name"
                :reduce="(semester) => semester.id"
                v-model="new_syllabus.semesters"
                v-validate="'required'"
                class="w-full"
                :loading="loadingTerms"
                name="selected_semesters"
                :options="semesters_all"
                disabled />
            </div>
            <!--------------------------==========with semester=============================
            ------------------selectStyle----------------------------------------------------------->

            <div class="vx-col sm:w-3/5 w-full mt-5" v-else>
              <select
                v-model="new_syllabus.semesters"
                class="w-full select_optgroup"
                :class="{placeholder: !new_syllabus.semesters}"
                disabled>
                <option
                  class="placeholder"
                  disabled
                  selected
                  hidden
                  :value="null">
                  {{ $t('Syllabus.selectTerms') }}
                </option>

                <optgroup
                  :label="semester_item.name"
                  :key="name"
                  v-for="(semester_item, name) in semesters_arr">
                  <option
                    :key="index"
                    :value="`${item.id},${semester_item.id}`"
                    v-for="(item, index) in semester_item.termsData">
                    {{ item.name }}
                  </option>
                </optgroup>
              </select>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col sm:w-1/5 w-full mt-5">
              <label>{{ $t('Syllabus.subjects') + ' ' }}</label>
              <span class="text-danger">*</span>
            </div>
            <div class="vx-col sm:w-3/5 w-full mt-5">
              <v-select
                multiple
                :placeholder="$t('Syllabus.selectSubjects')"
                :closeOnSelect="false"
                :clearable="false"
                label="name"
                :reduce="(subject) => subject.id"
                v-model="new_syllabus.subjects"
                v-validate="'required'"
                class="w-full"
                name="selected_subjects"
                :loading="loadingSubjects"
                @input="checkDiff()"
                :options="subjects_all">
                <template #no-options>{{
                  $t('subject.there_are_not_subjects_in_this_grade')
                }}</template>
              </v-select>
            </div>
          </div>
        </tab-content>
      </form-wizard>
    </div>
  </vx-card>
</template>

<script>
import {FormWizard, TabContent} from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import vSelect from 'vue-select'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const SyllabusRepository = RepositoryFactory.get('AcademicModule', 'syllabus')
const AcademicYearRepository = RepositoryFactory.get(
  'AcademicModule',
  'academicYears'
)
const DivisionRepository = RepositoryFactory.get(
  'ConfigurationModule',
  'division'
)

import {commonArrayMethodMixin, commonDataMixin} from '@/mixins.js'
export default {
  mixins: [commonArrayMethodMixin, commonDataMixin],
  data() {
    return {
      syllabusInfo: {},
      new_syllabus: {
        academic_year: null,
        division: null,
        grade: null,
        terms: null,
        with_semester: null,
        semesters: [],
        subjects: []
      },
      term_name: '',
      current_academic_year: {},
      semesters_arr: [],
      divisions_all: [],
      grades_all: [],
      semesters_all: [],
      subjects_all: [],
      oldSubject: [],
      showErrorsTab0: false,
      showErrorsTab1: false,
      showErrorsTab2: false,
      ////////////////////////
      // Loading
      ////////////////////////
      loadingAcademicYears: false,
      loadingDivisions: false,
      loadingGrades: false,
      loadingSubjects: false,
      loadingTerms: false
    }
  },
  components: {
    FormWizard,
    TabContent,
    'v-select': vSelect
  },
  watch: {
    // 'new_syllabus.subjects' (newValue, oldValue) {
    //   console.log('watch')
    //   this.oldSubject = oldValue
    // }
    'new_syllabus.semesters'(data) {
      if (data == null) {
        this.term_name = ''
      }
    }
  },
  created() {
    const syllabus_id = this.$route.params.id
    this.getSyllabus(syllabus_id)
    this.getAllAcademicYears()
    this.getDivisions()
  },

  methods: {
    checkDiff() {
      const diff = this.arr_diff(this.new_syllabus.subjects, this.oldSubject)
      let isOldSubjectChanged = false
      diff.forEach((element) => {
        if (this.oldSubject.includes(Number(element)))
          isOldSubjectChanged = true
      })

      if (isOldSubjectChanged) {
        this.new_syllabus.subjects = [
          ...new Set([...this.oldSubject, ...this.new_syllabus.subjects])
        ]
        this.errMsg(this.$t('Syllabus.you_couldnt_uncheck_choosen_subjects'))
      }
    },
    ////////////////////////////
    // Api
    ////////////////////////////
    getSyllabus(id) {
      SyllabusRepository.get(id).then((res) => {
        if (res.data.terms && res.data.term_id.length > 0) {
          const term = res.data.terms.find((data) => {
            return data.id == res.data.term_id[0]
          })
          term ? (this.term_name = term.name) : ''
        } else {
          this.term_name = ''
        }
        this.new_syllabus.academic_year_id = res.data.academic_year_data._id
        this.AcadmicYearInfo()
        this.syllabusInfo = res.data
        this.new_syllabus.division = res.data.division_id
        this.new_syllabus.grade = res.data.grade.id
        this.new_syllabus.with_semester = res.data.with_semester

        if (res.data.with_semester === 'without_semester') {
          this.new_syllabus.semesters = res.data.terms.map((term) => term.id)
        } else {
          this.new_syllabus.semesters = `${res.data.term_id[0]},${res.data.semester_id[0]}`
        }

        const subjecyIds = res.data.Subjects.map((item) => item.id)
        this.new_syllabus.subjects = subjecyIds
        this.oldSubject = subjecyIds
        this.availableGrades()
        this.availableSubjects()
        // this.getTermsWithGradeAndDivisionIds()
      })
    },
    getAllAcademicYears() {
      this.loadingAcademicYears = true
      this.getAllAcademic().finally(() => {
        this.loadingAcademicYears = false
      })
    },
    getDivisions() {
      this.loadingDivisions = true
      //  this.new_syllabus.academic_year = this.current_academic_year;
      DivisionRepository.getdata()
        .then((res) => {
          this.divisions_all = res.data.data
        })
        .finally(() => {
          this.loadingDivisions = false
        })
    },
    availableGrades() {
      this.loadingGrades = true
      DivisionRepository.getGrades(this.new_syllabus.division)
        .then((res) => {
          this.grades_all = res.data
        })
        .finally(() => {
          this.loadingGrades = false
        })
    },
    availableSubjects() {
      this.loadingSubjects = true
      SyllabusRepository.getGradesSubjectsActive(this.new_syllabus.grade)
        .then((res) => {
          this.subjects_all = res.status === 'error' ? [] : res.data
        })
        .finally(() => {
          this.loadingSubjects = false
        })
    },
    getTermsWithGradeAndDivisionIds() {
      const data = {
        division_id: this.new_syllabus.division,
        grade_id: this.new_syllabus.grade
      }
      SyllabusRepository.getTermsWithGradeAndDivisionIds(data).then(
        this.handleTermsData
      )
    },
    AcadmicYearInfo() {
      AcademicYearRepository.AcadmicYearInfo(
        this.new_syllabus.academic_year_id
      ).then((res) => {
        // if (res.data == 'you couldn\'t change') {
        //   this.$vs.notify({
        //     color: 'danger',
        //     title: 'not the same current active acadmic year',
        //     text: 'you could not change academic year '
        //   })
        //   // this.$router.push({ name: 'SyllabusList' })
        // } else
        // if (res.data.with_semester != 'with_semester') {
        //   this.new_syllabus.academic_year_id = res.data._id
        // }
        this.current_academic_year = res.data
        this.handleTermsData(res)
      })
    },
    /////////////////////////
    // Handle Data
    /////////////////////////
    handleTermsData(res) {
      if (res.data.with_semester === 'without_semester') {
        this.semesters_all = res.terms
      } else {
        this.semesters_arr = res.semestersData
      }
    },
    // ///////////////////////
    // Submit
    /////////////////////////
    formSubmitted() {
      this.showErrorsTab1 = true
      const payload = {
        academic_year_id: this.current_academic_year._id,
        division_id: this.new_syllabus.division,
        grade_id: this.new_syllabus.grade,
        subjects_id: this.new_syllabus.subjects,
        term_id:
          typeof this.new_syllabus.semesters === 'string'
            ? [this.new_syllabus.semesters]
            : this.new_syllabus.semesters,
        with_semester: this.new_syllabus.with_semester,
        term_name: this.term_name
      }
      this.$validator.validate().then((valid) => {
        if (valid) {
          SyllabusRepository.update(this.$route.params.id, payload)
            .then((res) => {
              if (res.status == 'exist') {
                this.$vs.notify({
                  color: 'warning',
                  title: 'Exist',
                  text: `${res.data}`
                })
              } else {
                this.$router.push({name: 'SyllabusList'})
                this.successMsg()
              }
            })
            .catch(this.errMsg)
        } else {
          this.errMsg()
        }
      })
    },
    validateStep(prevIndex, nextIndex) {
      const validateStep0 = () => {
        const hasErrors =
          this.$validator.errors.items.filter(
            (error) =>
              error.field === 'syllabus_code' ||
              error.field === 'selected_divisions' ||
              error.field === 'selected_grades'
          ).length !== 0

        if (hasErrors)
          this.$refs['edit-syllabus-wizard'].changeTab(nextIndex, prevIndex)
        this.showErrorsTab0 = true
      }

      const validateStep1 = () => {
        const hasErrors =
          this.$validator.errors.items.filter(
            (error) =>
              error.field === 'selected_semesters' ||
              error.field === 'selected_subjects'
          ).length !== 0
        if (hasErrors)
          this.$refs['edit-syllabus-wizard'].changeTab(nextIndex, prevIndex)
        this.showErrorsTab1 = true
      }

      if (nextIndex > prevIndex) {
        this.$validator.validate().then((valid) => {
          switch (prevIndex) {
            case 0:
              validateStep0()
              break
            case 1:
              validateStep1()
              break
          }
        })
      }
    },
    getNextStepData() {
      this.availableSubjects()
      if (!this.semesters_all.length || !this.semesters_arr.length) {
        this.AcadmicYearInfo()
      }
    },

    showTerm(term) {
      this.term_name = term.name
    }
  }
}
</script>
