<template>
  <sideBar
    :filters_prop="custom_filter"
    @refilter="setFilters"
    :loading="loadingFilter">
    <template slot="extra_content">
      <div class="w-full mb-10">
        <vs-input
          :label-placeholder="$t('form.search_by_name')"
          v-model="searchContent"
          class="w-full"
          name="email"
          icon="icon icon-search"
          icon-pack="feather"
          @change="setFilters" />
        <span class="text-sm text-danger" v-if="errors.first('email')">{{
          errors.first('email') | text_formatter
        }}</span>
      </div>
    </template>
  </sideBar>
</template>

<script>
import sideBar from '@/components/general/RoundFilter'
import {commonDataMixin} from '@/mixins.js'

export default {
  name: 'FilterBar',
  components: {sideBar},
  mixins: [commonDataMixin],
  data() {
    return {
      //////////////////////////
      // Filters
      /////////////////////////
      sideFilters: {
        divisions: null,
        grades: null,
        status: null,
        has_strands: null,
        name: null
      },
      subjects: [],
      schools: [],
      custom_filter: [
        {
          name: this.$t('basic.divisions'),
          payload_name: 'divisions',
          onChangeFireAction: true,
          by: []
        },
        {
          name: this.$t('basic.sections'),
          payload_name: 'section_ids',
          onChangeFireAction: true,
          by: []
        },
        {
          name: this.$t('basic.grades'),
          payload_name: 'grades',
          by: []
        },
        {
          name: this.$t('basic.status'),
          payload_name: 'status',
          by: [
            {id: 'Active', name: this.$t('status.active'), checked: false},
            {
              id: 'Suspended',
              name: this.$t('status.suspended'),
              checked: false
            }
          ]
        },
        {
          name: this.$t('subject.strands'),
          payload_name: 'has_strands',
          by: [
            {id: true, name: this.$t('subject.has_strands'), checked: false},
            {
              id: false,
              name: this.$t('subject.has_not_strands'),
              checked: false
            }
          ]
        }
      ],

      // Search
      searchContent: '',
      loadingFilter: false
    }
  },
  computed: {
    schoolID() {
      return this.authUser.school_id
    }
  },

  methods: {
    //////////////////////
    // Helpers
    /////////////////////
    setFilters(val) {
      // to enable watcher when typing on the search input
      this.sideFilters["name"] = this.searchContent;

      if (Array.isArray(val)) {
        val.map((item) => {
          this.sideFilters[item.name] = item.checked
        });
      }
      this.reloadData()
    },
    search() {
      this.sideFilters.name = this.searchContent
      if (this.searchContent.length >= 3 || !this.searchContent) {
        this.reloadData()
      }
    },
    reloadData() {
      this.$emit('reloadData', this.sideFilters)
    },
    //////////////////////
    // APIs
    /////////////////////
    getFiltersData() {
      this.loadingFilter = true
      this.getFilterData({schools: [this.schoolID]})
        .then((res) => {
          res.divisions.map((el) => {
            this.custom_filter[0].by.push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
        })
        .catch(this.errMsg)
        .finally(() => {
          this.loadingFilter = false
        })
    },
    getSections(chosen) {
      this.loadingFilter = true
      const data = {
        schools: [this.schoolID],
        divisions: chosen.divisions
      }
      this.custom_filter[1].by = []
      this.custom_filter[2].by = []
      this.getFilterData(data)
        .then((res) => {
          res.sections.map((el) => {
            this.custom_filter[1].by.push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
        })
        .catch(this.errMsg)
        .finally(() => {
          this.loadingFilter = false
        })
    },
    getGrades(chosen) {
      this.loadingFilter = true
      this.custom_filter[2].by = []
      const data = {
        schools: [this.schoolID],
        divisions: chosen.divisions,
        sections: chosen.sections
      }
      this.getFilterData(data)
        .then((res) => {
          res.grades.map((el) => {
            this.custom_filter[2].by.push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
        })
        .catch(this.errMsg)
        .finally(() => {
          this.loadingFilter = false
        })
    }
  },
  created() {
    this.getFiltersData()

    this.$root.$on('onChange:divisions', (v) => {
      this.getSections(v)
    })
    this.$root.$on('onChange:sections', (v) => {
      this.getGrades(v)
    })
  },
  beforeDestroy() {
    this.$root.$off('onChange:divisions')
    this.$root.$off('onChange:grades')
  }
}
</script>

<style scoped></style>
