<template>
  <vx-card class="p-3 pt-6 pb-6 md:p-6">
    <div class="mt-5">
      <form-wizard
        ref="new-syllabus-wizard"
        color="rgba(var(--vs-primary), 1)"
        :title="null"
        :subtitle="null"
        finishButtonText="Submit"
        @on-complete="formSubmitted"
        @on-change="validateStep">
        <tab-content :title="`${$t('Syllabus.divisionsGrades')}`" class="mb-5">
          <!-- tab 1 content -->
          <div class="vx-row">
            <div class="vx-col sm:w-1/5 w-full mt-5">
              <label>{{ $t('Syllabus.academicYear') }}</label>
            </div>
            <div class="vx-col sm:w-3/5 w-full mt-5">
              <v-select
                label="name"
                :reduce="(a) => a._id"
                v-model="new_syllabus.academic_year_id"
                v-validate="'required'"
                class="w-full"
                name="academic_year"
                :clearable="false"
                :disabled="!allAcademicYears.length"
                :loading="loadingAcademicYears"
                :options="allAcademicYears" />
              <span v-if="showErrorsTab0" class="text-danger text-sm">{{
                errors.first('academic_year') | text_formatter
              }}</span>
            </div>
            <!--            <div class="vx-col sm:w-3/5 w-full mt-5">-->
            <!--              {{ current_academic_year.name }}-->
            <!--            </div>-->
          </div>
          <!-- <div class="vx-row">
            <div class="vx-col sm:w-1/3 w-full mt-5">
              <label>{{$t('Syllabus.syllabusCode') + " "}}</label>
              <span class="text-danger">*</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full mt-5">
              <vs-input
                v-validate="'required'"
                name="syllabus_code"
                class="w-full"
                v-model="new_syllabus.code"
              />
              <span
                v-if="showErrorsTab0"
                class="text-danger text-sm"
              >{{ errors.first('syllabus_code') }}</span> | text_formatter
            </div>
          </div>-->
          <div class="vx-row">
            <div class="vx-col sm:w-1/5 w-full mt-5">
              <label class="text-sm">{{
                $t('Syllabus.divisions') + ' '
              }}</label>
              <span class="text-danger">*</span>
            </div>
            <div class="vx-col sm:w-3/5 w-full mt-5">
              <v-select
                :placeholder="$t('Syllabus.selectDivisions')"
                closeOnSelect
                :clearable="false"
                label="long_name"
                :reduce="(division) => division.id"
                v-model="new_syllabus.division"
                v-validate="'required'"
                class="w-full"
                name="selected_divisions"
                :options="divisions_all"
                :loading="loadingDivisions"
                @input="availableGrades" />
              <span v-if="showErrorsTab0" class="text-danger text-sm">{{
                errors.first('selected_divisions') | text_formatter
              }}</span>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col sm:w-1/5 w-full mt-5">
              <label>{{ $t('Syllabus.grades') + ' ' }}</label>
              <span class="text-danger">*</span>
            </div>
            <div class="vx-col sm:w-3/5 w-full mt-5">
              <v-select
                closeOnSelect
                :clearable="false"
                :placeholder="$t('Syllabus.selectGrades')"
                :disabled="new_syllabus.division === null"
                label="name"
                :reduce="(grade) => grade.id"
                v-model="new_syllabus.grade"
                v-validate="'required'"
                class="w-full"
                name="selected_grades"
                :loading="loadingGrades"
                :options="grades_all"
                @input="getNextStepData()" />
              <span v-if="showErrorsTab0" class="text-danger text-sm">{{
                errors.first('selected_grades') | text_formatter
              }}</span>
            </div>
          </div>
        </tab-content>

        <!-- tab 2 content -->
        <tab-content
          :title="`${$t('Syllabus.semestersSubjects')}`"
          class="mb-5">
          <div class="vx-row">
            <div class="vx-col sm:w-1/5 w-full mt-5">
              <label>{{ $t('Syllabus.terms') + ' ' }}</label>
              <span class="text-danger">*</span>
            </div>
            <div
              class="vx-col sm:w-3/5 w-full mt-5"
              v-if="current_academic_year.with_semester == 'without_semester'">
              <v-select
                multiple
                @option:selecting="showTerm"
                :closeOnSelect="true"
                :clearable="false"
                :placeholder="$t('Syllabus.selectSemesters')"
                label="name"
                :reduce="(semesters_all) => semesters_all.id"
                v-model="new_syllabus.semesters"
                v-validate="'required'"
                class="w-full"
                :loading="loadingTerms"
                name="selected_semesters"
                :options="semesters_all" />
              <span v-if="showErrorsTab1" class="text-danger text-sm">{{
                errors.first('selected_semesters') | text_formatter
              }}</span>
            </div>
            <!--------------------------------------with semester -----------------------
            ------------------selectStyle----------------------------------------------------------->

            <div class="vx-col sm:w-3/5 w-full mt-5" v-else>
              <select
                v-model="new_syllabus.semesters"
                class="w-full select_optgroup"
                :class="{placeholder: !new_syllabus.semesters}">
                <option
                  class="placeholder"
                  disabled
                  selected
                  hidden
                  :value="null">
                  {{ $t('Syllabus.selectTerms') }}
                </option>

                <optgroup
                  :label="semester_item.name"
                  :key="name"
                  v-for="(semester_item, name) in semesters_arr">
                  <option
                    :key="index"
                    :value="`${item.id},${semester_item.id}`"
                    v-for="(item, index) in semester_item.termsData">
                    {{ item.name }}
                  </option>
                </optgroup>
              </select>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col sm:w-1/5 w-full mt-5">
              <label>{{ $t('Syllabus.subjects') + ' ' }}</label>
              <span class="text-danger">*</span>
            </div>
            <div class="vx-col sm:w-3/5 w-full mt-5">
              <v-select
                multiple
                :placeholder="$t('Syllabus.selectSubjects')"
                :closeOnSelect="false"
                :clearable="false"
                label="name"
                :reduce="(subject) => subject.id"
                v-model="new_syllabus.subjects"
                v-validate="'required'"
                class="w-full"
                name="selected_subjects"
                :loading="loadingSubjects"
                :options="subjects_all">
                <template #no-options>{{
                  $t('subject.there_are_not_subjects_in_this_grade')
                }}</template>
              </v-select>
              <span v-if="showErrorsTab1" class="text-danger text-sm">{{
                errors.first('selected_subjects') | text_formatter
              }}</span>
            </div>
          </div>
        </tab-content>
      </form-wizard>
    </div>
  </vx-card>
</template>

<script>
import {FormWizard, TabContent} from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import vSelect from 'vue-select'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
import {commonDataMixin} from '@/mixins.js'
const AcademicYearRepository = RepositoryFactory.get(
  'AcademicModule',
  'academicYears'
)
const SyllabusRepository = RepositoryFactory.get('AcademicModule', 'syllabus')
const DivisionRepository = RepositoryFactory.get(
  'ConfigurationModule',
  'division'
)

export default {
  name: 'Create',
  components: {
    FormWizard,
    TabContent,
    'v-select': vSelect
  },
  mixins: [commonDataMixin],
  data() {
    return {
      value2: ['4'],
      new_syllabus: {
        academic_year_id: null,
        division: null,
        grade: null,
        semesters: null,
        subjects: null,
        code: null,
        terms_arr: []
      },
      current_academic_year: [],
      divisions_all: [],
      grades_all: [],
      semesters_all: [],
      semesters_arr: [],
      subjects_all: [],
      term_name: '',
      showErrorsTab0: false,
      showErrorsTab1: false,
      showErrorsTab2: false,
      ////////////////////////
      // Loading
      ////////////////////////
      loadingAcademicYears: false,
      loadingDivisions: false,
      loadingGrades: false,
      loadingSubjects: false,
      loadingTerms: false
    }
  },

  methods: {
    ////////////////////////////
    // Api
    ////////////////////////////
    activeAcadmicYear() {
      AcademicYearRepository.activeAcadmicYear().then((res) => {
        this.current_academic_year = res.data
        if (res.data.with_semester !== 'with_semester') {
          this.new_syllabus.academic_year_id = res.data._id
        }
      })
    },
    getAllAcademicYears() {
      this.loadingAcademicYears = true
      this.getAllAcademic().finally(() => {
        this.loadingAcademicYears = false
      })
    },
    getDivisions() {
      this.loadingDivisions = true
      //  this.new_syllabus.academic_year = this.current_academic_year;
      DivisionRepository.getdata()
        .then((res) => {
          this.divisions_all = res.data.data
        })
        .finally(() => {
          this.loadingDivisions = false
        })
    },
    availableGrades() {
      this.loadingGrades = true
      DivisionRepository.getGrades(this.new_syllabus.division)
        .then((res) => {
          this.grades_all = res.data
        })
        .finally(() => {
          this.loadingGrades = false
        })
    },
    availableSubjects() {
      this.loadingSubjects = true
      SyllabusRepository.getGradesSubjectsActive(this.new_syllabus.grade)
        .then((res) => {
          this.subjects_all = res.status === 'error' ? [] : res.data
        })
        .finally(() => {
          this.loadingSubjects = false
        })
    },
    getTermsWithGradeAndDivisionIds() {
      const data = {
        division_id: this.new_syllabus.division,
        grade_id: this.new_syllabus.grade
      }
      SyllabusRepository.getTermsWithGradeAndDivisionIds(data).then(
        this.handleTermsData
      )
    },
    AcadmicYearInfo() {
      AcademicYearRepository.AcadmicYearInfo(
        this.new_syllabus.academic_year_id
      ).then((res) => {
        // if (res.data == 'you couldn\'t change') {
        //   this.$vs.notify({
        //     color: 'danger',
        //     title: 'not the same current active acadmic year',
        //     text: 'you could not change academic year '
        //   })
        //   // this.$router.push({ name: 'SyllabusList' })
        // } else
        if (res.data.with_semester != 'with_semester') {
          this.new_syllabus.academic_year_id = res.data._id
        }
        this.current_academic_year = res.data
        this.handleTermsData(res)
      })
    },
    /////////////////////////
    // Handle Data
    /////////////////////////
    handleTermsData(res) {
      if (res.data.with_semester === 'without_semester') {
        this.semesters_all = res.terms
      } else {
        this.semesters_arr = res.semestersData
      }
    },
    // ///////////////////////
    // Submit
    /////////////////////////
    validateStep(prevIndex, nextIndex) {
      const validateStep0 = () => {
        const hasErrors =
          this.$validator.errors.items.filter(
            (error) =>
              error.field === 'syllabus_code' ||
              error.field === 'selected_divisions' ||
              error.field === 'selected_grades'
          ).length !== 0

        if (hasErrors)
          this.$refs['new-syllabus-wizard'].changeTab(nextIndex, prevIndex)
        this.showErrorsTab0 = true
      }

      const validateStep1 = () => {
        const hasErrors =
          this.$validator.errors.items.filter(
            (error) =>
              error.field === 'selected_semesters' ||
              error.field === 'selected_subjects'
          ).length !== 0
        if (hasErrors)
          this.$refs['new-syllabus-wizard'].changeTab(nextIndex, prevIndex)
        this.showErrorsTab1 = true
      }

      if (nextIndex > prevIndex) {
        this.$validator.validate().then((valid) => {
          switch (prevIndex) {
            case 0:
              validateStep0()
              break
            case 1:
              validateStep1()
              break
          }
        })
      }
    },
    formSubmitted() {
      // this.new_syllabus.academic_year_id = this.current_academic_year._id
      this.showErrorsTab1 = true
      const payload = {
        academic_year_id: this.current_academic_year._id,
        division_id: this.new_syllabus.division,
        with_semester: this.current_academic_year.with_semester,
        // semester_id: "1",
        grade_id: this.new_syllabus.grade,
        subjects_id: this.new_syllabus.subjects,
        term_id: this.new_syllabus.semesters,
        term_name: this.term_name
      }
      this.$validator.validate().then((valid) => {
        if (valid) {
          SyllabusRepository.create(payload)
            .then((res) => {
              if (res.status === 'exist')
                this.warningMsg(`${res.data}`, this.$t('basic.exist'))
              else {
                this.$router.push({name: 'SyllabusList'})
                this.successMsg(
                  `${this.$t(
                    'syllabus.new_syllabus_has_been_created_successfully_with_code'
                  )}: ${res.data.code}`
                )
              }
            })
            .catch(this.errMsg)
        } else this.errMsg()
      })
    },
    getNextStepData() {
      this.availableSubjects()
      this.AcadmicYearInfo()
    },
    showTerm(term) {
      this.term_name = term.name
    }
  },
  watch: {
    'new_syllabus.semesters'(data) {
      if (data == null) {
        this.term_name = ''
      }
    }
  },

  created() {
    this.activeAcadmicYear()
    this.getAllAcademicYears()
    this.getDivisions()
  }
}
</script>

<style scoped>
.background-none {
  background-color: transparent !important;
  color: rgb(160, 152, 152);
  cursor: pointer;
}
.selectStyle select {
  background: transparent;
  width: 100%;
  padding: 0 2px;
  font-size: 1em;
  background: none;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  height: 100px;
  margin: 20px;
}
.selectStyle {
  width: 100%;
  height: 25px;
}
optgroup[label] {
  color: grey;
  font-style: inherit;
  font-weight: 300;
  text-shadow: none;
}
</style>
