import axios from 'axios'
const api_key = 'JDLVI0KVwQ09jbKmZC2Y',
  braincert_baseURL = 'https://api.braincert.com'


export default {

  // Handel Time Formate
  changeTime12Formate (time) {
    const hh = time.split(':')[0],
      hh_num = Number(hh) - 12 || 12,
      hh_num_str = `${hh_num}`,
      mm = time.split(':')[1]

    if (parseInt(hh) >= 12) return `${hh_num_str.length < 2 ? `0${hh_num}` : hh_num}:${mm}PM`
    else return `${hh}:${mm}AM`
  },
  braincertRequest (name, params) {
    return axios.post(
      `${braincert_baseURL}/v2/${name}?apikey=${ api_key}`,
      { }, {
        url:braincert_baseURL,
        params,
        headers: {
          'Accept': 'application/json',
          'Content-Type':'application/json'
        }
      }
    )
  },
  // schedule Class ( Get class ID )
  schedule (name, time_from, time_to, date) {
    const scheduleData = {
      title: name,
      timezone: 24,
      start_time: this.changeTime12Formate(time_from), // Format : 10:30AM || 00:20 PM
      end_time: this.changeTime12Formate(time_to),
      date, // Formate : 2014-08-15
      formate: 'json'
    }
    return this.braincertRequest('schedule', scheduleData)
  },
  // getclasslaunch ( get meeting url )
  getLunchURL (braincert_class_id, name, subject) {
    const // Get User data
      authUser = JSON.parse(localStorage.getItem('authUser')),
      userRole = authUser.userRole,
      user = {
        id: authUser.uid,
        name: authUser.displayName,
        isTeacher:
            userRole.includes('teacher') || userRole.includes('Teacher')
      },
      // LunchURL Payload
      getURL_Payload = {
        userId: user.id,
        userName: user.name,
        isTeacher: user.isTeacher ? 1 : 0, // 0 for Student, 1 for teacher
        braincert_class_id,
        lessonName: name,
        courseName:    subject     }

    return this.braincertRequest('getclasslaunch', getURL_Payload)

  }
}
