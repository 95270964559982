import announcementList from './pages/list.vue'
import announcementShow from '../../components/announcementShow.vue'


export default [
  // list

  {
    path: '/parent/announcement',
    name: 'announcementList',
    i18n: 'announcement.announcements',
    component: announcementList,
    meta: {
      breadcrumb: [
        {
          title: 'basic.dashboard',
          url: 'landing/home'
        },
        {
          title: 'basic.list',
          active: true
        }
      ],
      pageTitle: 'announcement.announcements',
      rule: 'Parent'
    }
  },
  // show
  {
    path: '/parent/announcement/show/:id',
    name: 'show-announcement-parants',
    i18n: 'announcement.announcements',
    component: announcementShow,
    meta: {
      breadcrumb: [
        {
          title: 'basic.dashboard',
          url: 'landing/home'
        },
        {
          title: 'basic.list',
          url: '/parent/announcement'
        },
        {
          title: 'main.show',
          active: true
        }
      ],
      pageTitle: 'announcement.announcements',
      rule: 'Parent'
    }
  }


]
