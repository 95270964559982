import {mapGetters} from 'vuex'
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker'
import ModuleMixins from '../../mixins'
import Service from '../../services/poi'


export default {
  name: 'customizeUnitModal',
  mixins: [ModuleMixins],
  components: {
    'v-select': vSelect,
    Datepicker
  },
  data () {
    return {
      restService: Service,
      storeUnSubscriber: null,
      modalProps: {
        width: '600px',
        height: 'auto',
        scrollable: true
      },
      modalName: 'customizeUnitModal',
      datePickerSettings: {
        format: 'd MMM',
        disabledDates: {
          to: null, // Disable all dates up to specific date
          from: null // Disable all dates after specific date
        },
        dateValue: null
      },
      prevStartDate: null,
      prevEndDate: null,
      keyConcepts: [],
      relatedConceptsSubjects: [],
      relatedConcepts: [],
      atlSkills: [],
      learnerProfile: [],
      unitItems: {
        central_idea: '',
        key_concepts: [],
        related_concepts: [],
        atl_skills: [],
        learner_profile: [],
        lines_of_inquiry: '',
        start_date: null,
        end_date: null,
        is_disabled: false,
        is_year_long: true,
        extra_sections: []
      },
      unitDetails: null,
      yearGroupDetails: null,
      isValidData: true,
      validation: {
        startDate: null,
        endDate: null,
        extraSectionTitle: null,
        yearLong: null
      }
    }
  },
  mounted () {
    this.storeSubscriberLogic()
  },
  destroyed () {
    /// ** Stop VueX Actions Subscriber to prevent memory leak and Errors **///
    this.storeUnSubscriber()
  },
  methods: {
    showModal () {
      this.$modal.show(this.modalName)
    },
    hideModal () {
      this.$modal.hide(this.modalName)
    },
    storeSubscriberLogic () {
      const self = this
      /// ** Interact Modals & other actions throw vuex ** ///
      /// ** this case to listen on vuex action to open modal from other components ** ///
      this.storeUnSubscriber = this.$store.subscribeAction({
        before: (action, state) => {
          // if you need to implement before action
          // it's default behavior
        },
        after: (action, state) => {
          if (action.type === 'manageModal' && action.payload.name === self.modalName) {
            if (action.payload.show === true) {
              this.loadData(action.payload.data)
            } else if (action.payload.show === false) {
              this.cancel()
            }
          }
        },
        error: (action, state, error) => {
          this.cancel()
        }
      })
    },
    async getDropDownsData () {
      //** Show Loader **//
      this.$vs.loading()

      //** Get KeyConcepts **//
      await this.restService.getKeyConcepts()
        .then(res => this.keyConcepts = res.data)
        .catch(err => console.error(err))

      //** Get RelatedConcepts Subjects**//
      await this.restService.getRelatedConcepts()
        .then(res => this.relatedConceptsSubjects = res.data)
        .catch(err => console.error(err))

      //** Get ATL Skills **//
      await this.restService.getAtlSkills()
        .then(res => this.atlSkills = res.data)
        .catch(err => console.error(err))

      //** Get ATL Skills **//
      await this.restService.getLearnerProfile()
        .then(res => this.learnerProfile = res.data)
        .catch(err => console.error(err))

      //** Hide Loader **//
      this.$vs.loading.close()
    },
    async getRelatedConceptsData (item) {
      this.$vs.loading()

      await this.restService.getRelatedConceptsData(item.id)
        .then(res => this.relatedConcepts = res.data)
        .catch(err => console.error(err))

      this.$vs.loading.close()
    },
    loadData (data) {
      this.resetProps()
      // binding Data **//
      this.datePickerSettings.disabledDates = {
        from: new Date(this.selectedAcademicYear.end_date), // Disable all dates after specific date
        to: new Date(this.selectedAcademicYear.start_date) // Disable all dates up to specific date
      }
      this.datePickerSettings.dateValue = new Date(this.selectedAcademicYear.start_date)
      this.unitDetails = this.cloneItem(data.unitDetails)
      this.yearGroupDetails = this.cloneItem(data.groupData)
      this.unitItems = this.cloneItem(data.unitDetails.items)
      this.getDropDownsData()
        .then(() => {
          this.showModal()
        })
    },
    resetProps () {
      this.unitItems = {
        central_idea: '',
        key_concepts: [],
        related_concepts: [],
        atl_skills: [],
        learner_profile: [],
        lines_of_inquiry: '',
        start_date: null,
        end_date: null,
        is_disabled: false,
        is_year_long: false,
        extra_sections: []
      }
      this.unitDetails = null
      this.yearGroupDetails = null
      this.validation = {
        startDate: null,
        endDate: null,
        extraSectionTitle: null,
        yearLong: null
      }
      this.isValidData = true
    },
    addExtraSection () {
      this.unitItems.extra_sections.push({name: '', value: ''})
    },
    removeExtraSection (index) {
      this.$delete(this.unitItems.extra_sections, index)
    },
    checkDate () {
      /* debugger */
      //** check if other units mark as year long **//
      const isYearLongFound = this.gradeUnitsDates.find(item => item.is_year_long === true)

      if (!this.unitItems.is_year_long) {
        //** Validation for year long with other units **//
        if (isYearLongFound && (this.unitDetails.id !== isYearLongFound.id)) {
          this.validation.yearLong = this.$t('poi.you_cant_select_more_than_unit_as_year_long')
        } else {
          //** when set year long get we set endDate from academic end date **//
          this.prevStartDate = this.unitItems.start_date
          this.prevEndDate = this.unitItems.end_date
          this.unitItems.start_date = new Date(this.selectedAcademicYear.start_date)
          this.unitItems.end_date = new Date(this.selectedAcademicYear.end_date)
          this.validation.yearLong = null
        }
      } else {
        this.unitItems.start_date = this.prevStartDate
        this.unitItems.end_date = this.prevEndDate
        this.validation.yearLong = null
      }
      //** Validate dates **//
      // this.dateValidation()
    },
    dateValidation () {
      const startDate = this.getDateOnly(this.unitItems.start_date)
      const endDate = this.getDateOnly(this.unitItems.end_date)

      //** Check if start Date not on another unit date range **//
      if (this.unitItems.start_date) {
        this.gradeUnitsDates.forEach(item => {
          if (item.end_date && (this.unitDetails.id !== item.id) && !item.is_year_long) {
            if ((startDate <= this.getDateOnly(item.end_date)) && (startDate >= this.getDateOnly(item.start_date))) {
              this.validation.startDate = this.$t('poi.date_range_taken_before_on_another_unit')
            } else {
              this.validation.startDate = null
            }
          }
        })
      }
      //** Check if End Date Greater That start Date **//
      if (this.unitItems.start_date && this.unitItems.end_date) {
        if (endDate <= startDate) {
          this.validation.endDate = this.$t('poi.end_date_must_be_greater_than_start_date')
        } else if (endDate > startDate) {
          this.validation.endDate = null
        }
      }
    },
    extraSectionsValidation () {
      for (const item of this.unitItems.extra_sections) {
        if (!item.name) {
          this.validation.extraSectionTitle = this.$t('poi.this_filed_is_required')
          break
        } else if (item.name) {
          this.validation.extraSectionTitle = null
        }
      }
    },
    checkValidation () {
      this.isValidData = true
      //** run Validation Methods **//
      this.extraSectionsValidation()
      this.dateValidation()

      //** check validation **//
      for (const [key, value] of Object.entries(this.validation)) {
        if (value) {
          this.isValidData = false
        }
      }
      return this.isValidData
    },
    prepareSavingData () {
      let savingObj
      this.unitDetails.items = this.cloneItem(this.unitItems)
      savingObj = {
        description: this.unitDetails.description,
        central_idea: this.unitDetails.items.central_idea,
        key_concepts: this.unitDetails.items.key_concepts.map(item => item.id),
        related_concepts: this.unitDetails.items.related_concepts.map(item => item.id),
        atl_skills: this.unitDetails.items.atl_skills.map(item => item.id),
        learner_profile: this.unitDetails.items.learner_profile.map(item => item.id),
        lines_of_inquiry: this.unitDetails.items.lines_of_inquiry,
        extra_sections: this.unitDetails.items.extra_sections,
        is_disabled: this.unitDetails.items.is_disabled,
        start_date: (this.unitDetails.items.start_date) ? this.unitDetails.items.start_date.split('T')[0] : null,
        end_date: (this.unitDetails.items.end_date) ? this.unitDetails.items.end_date.split('T')[0] : null,
        is_year_long: this.unitDetails.items.is_year_long
      }
      return savingObj
    },
    storeSaving (unitData) {
      const data = {unitDetails: unitData, groupDetails: this.yearGroupDetails}
      this.$store.dispatch('modifyPoiUnit', data)
      this.successMsg(this.$t('poi.saved_successfully'), this.$t('poi.unit_modification'))

      this.cancel()
    },
    save () {
      this.$validator.validateAll().then(result => {
        if (result && this.checkValidation()) {
          this.restService.updateYearGroupUnit(this.unitDetails.unit_academic_id, this.prepareSavingData())
            .then(res => {
              this.storeSaving(res.data)
            })
            .catch(err => {
              console.error(err)
              this.errMsg(err.message)

            })
        } else {
          this.errMsg(this.$t('poi.invalid_data_please_correct_it'), this.$t('form.validation_error'))

        }
      })
    },
    cancel () {
      this.resetProps()
      this.hideModal()
    }
  },
  computed: {
    gradeUnitsDates () {
      const yearGroupData = this.$store.getters.getPoiYearGroupByGradeId(this.yearGroupDetails.id, this.yearGroupDetails.grade_id)
      return yearGroupData.units.map(item => {
        return {
          id: item.id,
          start_date: item.items.start_date,
          end_date: item.items.end_date,
          is_year_long: item.items.is_year_long
        }
      })
    },
    ...mapGetters({selectedAcademicYear: 'getSelectedAcademicYear'})
  }
}
