<template>
  <div>
    <template v-if="material_form">
      <teaching-materials-form
        :material_formProp="material_form"
        :edit_id="edit_id"
      >
      </teaching-materials-form>
    </template>
  </div>
</template>
<script>
import TeachingMaterialsForm from './components/teaching-materials-form'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'

const TeacherRepository = RepositoryFactory.get(
  'TeachersModule',
  'teaching_materials'
)

export default {
  components: {
    TeachingMaterialsForm
  },
  mounted () {
    this.VSLoading()
    this.show()
    this.stopVSLoading()
  },
  data () {
    return {
      material_form: null,
      edit_id: this.$route.params.id
    }
  },
  methods: {
    show () {
      this.VSLoading()
      TeacherRepository.view(this.edit_id).then((res) => {
        res.data.types =  res.data.types.map(item => item.id)
        res.data.files.forEach(element => {
          element.name = element.attachment
          element.document = element.attachment_url
          delete element.attachment
          delete element.attachment_url
        })
        this.material_form = res.data
      }).finally(() => {
        this.stopVSLoading()
      })
    }
  }
}
</script>
