export default {
  boundariesAndDescriptors: 'Boundaries And Descriptors',
  boundaries: 'Boundaries',
  descriptor: 'descriptor',
  min: 'Min',
  max: 'Max',
  local_grade: 'Local Grade',
  createBoundary: 'Create Boundary',
  editBoundary: 'Edit Boundary',
  boundariesLimites: 'Boundaries limits',
  add_limit: ' add limit',
  addBoundariesAndDescriptorsSuccessfully:
    'Add Boundary And Descriptor Successfully',
  areYouSureToDeleteThisLimit: 'Are You Sure To Delete This Limit',
  removeLimitSuccessfully: 'Removed Successfully',
  editSuccessfully: 'Edit Successfully',
  show_descriptor: 'show descriptor',
  show_local_grade: 'show local grade',
  grade_weight: 'Grade weight',
  show_grade_weight: 'show grade weight',
  all_limits: 'all limits',
  apply_grade_weight: ' Apply grade weight'
}
