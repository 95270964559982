<template>
  <vx-card class="relative">
    <slot name="actionButtons"></slot>
    <slot name="iconButtons"></slot>
    <div class=" vx-row justify-between w-full">
      <div class="vx-col w-full md:w-1/3">
        <slot name="first"></slot>
      </div>
      <div class="vx-col w-full md:w-1/3">
        <slot name="second"></slot>
      </div>
      <div class="vx-col w-full md:w-1/3">
        <slot name="third"></slot>
      </div>
    </div>
  </vx-card>
</template>
<script>
export default {
  name: 'grid-card'
}
</script>
