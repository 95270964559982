<template>
  <div>
    <vx-card ref="assignmentCard" headerBackgroundColor="#f9f9f9">
      <!-- Header -->
      <template v-slot:header>
        <!-- Row 1-->
        <div
          class="
            flex flex-wrap
            md:justify-between
            justify-center
            items-center
            md:flex-row
            flex-col
            text-sm
            mb-5
            vx-card__header--top
          "
        >
          <!--subject-->
          <div class="flex-1">
            <h6 v-if="assignment.division_subject">
              {{ assignment.division_subject.name }}
            </h6>
          </div>

          <!--  Date -->
          <div class="flex-1 text-center md:my-0 my-3">
            <p v-if="assignment.assignment_date || assignment.assignment_time">
              <feather-icon
                icon="CalendarIcon"
                class="mx-1 hover:text-primary"
                svg-classes="w-4 h-4"
              />
              {{ $t("basic.due_date") }}
              {{
                `${assignment.assignment_date || ""} ${
                  assignment.assignment_time || ""
                }`
              }}
            </p>
          </div>
          <!--     statistics     -->
          <div class="flex-1">
            <p v-if="assignment.statistics" class="text-xs text-center">
              <span class="mb-4">
                {{ assignment.statistics.done_on_time }}
                {{ $t("assessments.done_on_time") }} ({{ doneOnTime }}%)
              </span>
              <span class="md:mx-4 mx-2 mb-4">
                {{ assignment.statistics.done_but_late }}
                {{ $t("assessments.done_but_late") }} ({{ doneButLate }}%)
              </span>
              <span class="mb-4">
                {{ assignment.statistics.delayed }}
                {{ $t("assessments.delayed") }} ({{ delayed }}%)
              </span>
            </p>
          </div>
        </div>
        <!--   Row 2 -->
        <div class="flex items-center justify-between flex-wrap">
          <!--  Title -->
          <div class="md:w-1/3 w-full flex flex-wrap mb-3">
            <h4 class="text-capitalize truncate">
              {{ assignment.title || "" }}
            </h4>
            <vx-tooltip
              :text="
                assignmentDetailsMode ? $t('basic.hide') : $t('basic.show')
              "
              v-if="assignment.title || assignment.desc"
            >
              <feather-icon
                @click="toggleAssignmentDetailsMode()"
                :icon="assignmentDetailsMode ? 'EyeOffIcon' : 'EyeIcon'"
                class="mx-4 hover:text-primary"
                svg-classes="w-4 h-4"
              />
            </vx-tooltip>
          </div>
          <!-- Classes && students -->
          <div
            class="
              md:w-2/3
              w-full
              mb-3
              flex
              items-center
              md:justify-end md:flex-no-wrap
              flex-wrap
            "
            v-if="classesHasAnswers.length"
          >
            <!-- classes -->
            <div class="mr-2 flex lg:w-1/4">
              <feather-icon
                icon="UsersIcon"
                class="mr-2"
                svg-classes="w-4 h-4"
              />
              <div v-if="classesHasAnswers.length > 1">
                <v-select
                  closeOnSelect
                  label="name"
                  :options="classesHasAnswers"
                  v-model="classSelected"
                  @input="changeClasses"
                  class="select-small w-full"
                />
              </div>
              <template v-else>
                <p>{{ classSelected.name }}</p>
              </template>
            </div>
            <!-- answers ( students ) -->
            <div
              class="mr-4 flex sm:w-2/3 lg:w-1/2 w-full"
              v-if="classSelected"
            >
              <feather-icon
                icon="UserIcon"
                class="mr-2"
                svg-classes="w-4 h-4"
              />
              <template
                v-if="classSelected.answers && classSelected.answers.length"
              >
                <v-select
                  v-if="hasMultiAnswers"
                  :placeholder="`${
                    studentsNotAssesed.length
                      ? $t('assignment.assess_student') +
                        ' (' +
                        studentsNotAssesed.length +
                        ')'
                      : $t('assessments.all_are_assessed')
                  }`"
                  clearable
                  label="student_name_assessed"
                  :options="classSelected.answers"
                  v-model="studentSelected"
                  @change="changeStudent"
                  :showLoadingIcon="loadingStudents"
                  class="w-full"
                  :disabled="loadingAssignment"
                  id="student-select"
                >
                  <template v-slot:option="option">
                    <span
                      :class="{
                        'text-danger': Boolean(option.has_assess),
                      }"
                    >
                      {{ option.student_name
                      }}{{
                        Boolean(option.has_assess)
                          ? $t("assessments.assessed")
                          : ""
                      }}
                    </span>
                  </template>
                </v-select>
                <p v-else>
                  {{ classSelected.answers[0].student.name }}
                </p>
              </template>

              <p v-else>{{ $t("assignment.has_no_answers") }}</p>
            </div>
            <!-- Actions on student -->
            <div v-if="studentSelected && studentSelectedParentId" class="">
              <vs-dropdown vs-trigger-click class="cursor-pointer">
                <span class="flex items-center justify-end">
                  <span>{{ $t("basic.more") }}</span>
                  <feather-icon
                    icon="ChevronDownIcon"
                    class="ml-1 hover:text-primary"
                    svg-classes="w-4 h-4"
                  />
                </span>
                <vs-dropdown-menu class="w-48">
                  <!-- <vs-dropdown-item @click="activeFeedbackMode">
                    <feather-icon
                      icon="EditIcon"
                      class="mx-1 hover:text-primary"
                      svg-classes="w-4 h-4"
                    />
                    {{
                      $t('assignment.feedback_to_student') | text_formatter
                    }}</vs-dropdown-item
                  > -->
                  <vs-dropdown-item
                    v-if="studentSelectedParentId"
                    :to="{
                      name: 'chat',
                      params: { user_id: studentSelectedParentId },
                    }"
                  >
                    <feather-icon
                      icon="MessageSquareIcon"
                      class="cursor-pointer"
                    />
                    {{
                      $t("assignment.message_parent") | text_formatter
                    }}</vs-dropdown-item
                  >
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </div>
        </div>
      </template>
      <!-- Body  -->

      <div
        class="assignment__body flex items-center flex-col justify-center"
        v-if="assignment"
      >
        <div class="w-full">
          <!-- Assignment Details -->
          <template v-if="assignmentDetailsMode">
            <!-- title -->
            <!-- <h5>{{ assignment.title }}</h5> -->
            <p class="my-5">{{ assignment.description }}</p>
            <!-- Attachment -->
            <div
              class="w-full flex flex-row"
              v-if="assignment.attachments.length"
            >
              <file-previewer
                class="ml-1 my-4 mr-4"
                v-for="(file, idx) in assignment.attachments"
                :key="idx"
                :url="file.attachment_url"
                :urlType="file.type"
              ></file-previewer>
            </div>
            <!--     statistics     -->
            <div v-if="assignment.statistics" class="md:w-2/3">
              <p class="mb-4 text-sm">
                <span>
                  {{ assignment.statistics.done_on_time }}
                  {{ $t("basic.students") }}
                  {{ $t("assessments.done_on_time") }}
                  ({{ doneOnTime }}%)
                </span>
                <vs-progress
                  :height="7"
                  :percent="doneOnTime"
                  color="success"
                  class="my-2"
                ></vs-progress>
              </p>
              <p class="mb-4 text-sm">
                <span>
                  {{ assignment.statistics.done_but_late }}
                  {{ $t("basic.students") }}
                  {{ $t("assessments.done_but_late") }} ({{ doneButLate }}%)
                </span>
                <vs-progress
                  :height="7"
                  :percent="doneButLate"
                  color="warning"
                  class="my-2"
                ></vs-progress>
              </p>
              <p class="mb-4 text-sm">
                <span>
                  {{ assignment.statistics.delayed }} {{ $t("basic.students") }}
                  {{ $t("assessments.delayed") }}
                  ({{ delayed }}%)
                </span>
                <vs-progress
                  :height="7"
                  :percent="delayed"
                  color="danger"
                  class="my-2"
                ></vs-progress>
              </p>
            </div>
          </template>
          <!-- Answer Details -->
          <template v-if="assignmentAssessMode">
            <p v-if="showDefaultAssessText" class="text-center">
              {{ $t("assignment.Choose_student_to_assess") }}
            </p>
            <template v-else>
              <template v-if="studentSelectedAnswerDetails">
                <!-- Student Staticts -->
                <div class="mb-10">
                  <p class="text-center mb-2">
                    <span
                      >{{ studentSelectedAnswerDetails.sumTotal }}
                      {{ $t("assignment.assigned_tasks") }}</span
                    >
                  </p>
                  <p class="flex flex-wrap justify-center text-center">
                    <span
                      v-for="(
                        count, i
                      ) in studentSelectedAnswerDetails.tasksCount"
                      :key="i"
                      class="mx-2"
                    >
                      {{ count.total }} {{ count.type | text_formatter }} ({{
                        count.percentage
                      }}%)</span
                    >
                  </p>
                </div>
                <!-- details -->
                <div class="mb-10" v-if="studentSelectedTask">
                  <div class="mb-5">
                    <div class="flex justify-between">
                      <p></p>
                      <p class="text-bold text-sm">
                        {{ $t("basic.submitted_on") }} :
                        {{ studentSelectedTask.submit_date }}
                      </p>
                    </div>

                    <vs-textarea
                      v-model="studentSelectedTask.answer"
                      height="10rem"
                      class="w-full mb-5"
                      readonly
                      :label="$t('assignment.student_answer')"
                    />
                  </div>

                  <!-- Attachment -->
                  <div
                    class="w-full flex flex-row mb-5"
                    v-if="studentSelectedTask.attachments.length"
                  >
                    <file-previewer
                      class="ml-1 my-4 mr-4"
                      v-for="(file, idx) in studentSelectedTask.attachments"
                      :key="idx"
                      :url="file.attachment_url"
                      :urlType="file.type"
                    ></file-previewer>
                  </div>
                  <!-- Comments  -->
                  <div class="mb-5" v-if="showComments">
                    <label>{{ $t("assignment.student_comment") }}</label>
                    <template
                      v-for="(comment, i) in studentSelectedTask.comments"
                    >
                      <p class="w-full" v-if="comment.comment" :key="i">
                        {{ comment.updated_at }} : {{ comment.comment }}
                      </p>
                    </template>
                  </div>
                </div>
                <!-- Assess Area  -->
                <vs-divider />
                <div class="mb-10">
                  <!-- Give Grade -->

                  <div class="mb-5">
                    <div v-if="assignmentSection === 'PYP'">
                      <template v-if="hasAssesed && asessedTaskCriteria.length">
                        <div
                          v-for="item in asessedTaskCriteria"
                          :key="item.key"
                          class="vx-col px-3 w-full mt-2"
                        >
                          <div class="vs-input--label">
                            {{ $t("basic.criteria") }}
                          </div>
                          <div class="content-box w-full">{{ item }}</div>
                        </div>
                      </template>
                      <template v-else>
                        <label>
                          {{ $t("exam.choose_criteria") }}
                          <sup class="text-danger">*</sup>
                        </label>
                        <v-select
                          :options="assignmentCriteria"
                          v-validate="'required'"
                          :class="{
                            'validation-error': errors.has('criteria'),
                          }"
                          @input="reducePYPData"
                          label="title"
                          name="criteria"
                          v-model="selectedCriteria"
                        />
                        <span
                          v-show="errors.has('criteria')"
                          class="text-danger text-sm"
                        >
                          {{ $t("exam.criteria_is_required") }}
                        </span>
                      </template>
                    </div>

                    <div
                      v-if="
                        assignmentSection === 'AMERICAN' ||
                        assignmentSection === 'DP'
                      "
                    >
                      <template v-if="hasAssesed && asessedTaskCriteria.length">
                        <div
                          v-for="item in asessedTaskCriteria"
                          :key="item.key"
                          class="vx-col px-3 w-full mt-2"
                        >
                          <div class="vs-input--label">
                            {{ $t("basic.grade") }}
                          </div>
                          <div class="content-box w-full">{{ item }}</div>
                        </div>
                      </template>
                      <template v-else>
                        <label class="vs-input--label">
                          {{ $t("form.select_grade") }}
                          <sup class="text-danger">*</sup>
                        </label>
                        <v-select
                          :options="assignmentCriteria"
                          :class="{ 'validation-error': errors.has('grade') }"
                          min="1"
                          v-validate="{ required: true }"
                          @input="selectGrade"
                          v-model="selectedCriteria"
                          name="grade"
                        />
                        <span
                          v-show="errors.has('grade')"
                          class="text-danger text-sm"
                        >
                          {{ $t("exam.criteria_is_required") }}
                        </span>
                      </template>
                    </div>

                    <div v-if="assignmentSection === 'MYP'">
                      <template v-if="hasAssesed && asessedTaskCriteria.length">
                        <div class="vx-row">
                          <div
                            class="vx-col lg:w-1/4 w-full"
                            v-for="item in asessedTaskCriteria"
                            :key="item.key"
                          >
                            <div class="vs-input--label">{{ item.title }}</div>
                            <div class="content-box w-full">
                              {{ item.value }}
                            </div>
                          </div>
                        </div>
                      </template>
                      <div class="vx-row mb-2" v-else>
                        <div
                          class="vx-col px-3 md:w-1/4 w-full"
                          v-for="(item, idx) in assignmentCriteria"
                          :key="idx"
                        >
                          <div class="vx-col w-full mt-2">
                            <vs-input
                              v-model="selectedCriteria[item.key]"
                              :label="item.title"
                              class="w-full"
                              :name="item.key"
                              :class="{
                                'validation-error': errors.has(item.key),
                              }"
                              min="1"
                              v-validate="{
                                required: true,
                                min_value: 1,
                                max_value: 8,
                                numeric: true,
                              }"
                              placeholder="set mark"
                            />
                            <p
                              class="text-danger text-sm"
                              v-show="errors.has(item.key)"
                            >
                              {{ errors.first(item.key) }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- <p v-if="hasAssesed">
                      <strong>{{ $t("form.grade") }}</strong
                      >:
                      <span class="text-success">{{ selectedGrade }}</span>
                    </p>
                    <template v-else
                      ><label>
                        {{ $t("assignment.select_grade") }}
                        <sup class="text-danger">*</sup>
                      </label>
                      <v-select
                        :options="grades"
                        min="1"
                        v-validate="{ required: true }"
                        v-model="selectedGrade"
                        name="grade"
                      />
                      <span
                        v-if="errors.has('grade')"
                        class="text-danger text-sm"
                      >
                        {{ errors.first("grade") }}
                      </span>
                    </template> -->
                  </div>
                  <!-- Give Feedback -->
                  <div class="mb-5" ref="feedbackDiv" id="feedbackDiv">
                    <label class="w-full mb-2">{{
                      $t("assignment.feedback")
                    }}</label>
                    <div class="flex md:flex-no-wrap flex-wrap">
                      <vs-textarea
                        v-model="feedbackToStudent"
                        height="7rem"
                        class="md:w-1/2 w-full mb-2 md:mr-2"
                        :label="$t('assessments.ToStudent')"
                        :readonly="hasAssesed"
                      />
                      <vs-textarea
                        v-model="feedbackToParent"
                        height="7rem"
                        class="md:w-1/2 w-full mb-2"
                        :label="$t('assessments.ToParent')"
                        :readonly="hasAssesed"
                      />
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </template>
        </div>
      </div>

      <!-- Footer -->
      <template v-slot:footer>
        <div
          v-if="showAssassActions"
          class="
            flex flex-wrap
            md:justify-between
            justify-center
            items-center
            md:flex-row
            flex-col-reverse
          "
        >
          <vs-button
            :to="{ name: 'assignments' }"
            class="mt-3"
            color="warning"
            type="border"
            >{{ $t("basic.cancel") }}</vs-button
          >
          <vs-button
            @click="returnAssignmentBack"
            v-if="assignment.is_multiple"
            class="mt-3"
            color="gray"
            >{{ $t("assignment.return_it_back") }}</vs-button
          >
          <vs-button @click="submitAssignmentAsses" class="mt-3">{{
            $t("assignment.submit_assessment")
          }}</vs-button>
        </div>
      </template>

      <!--  Loader  -->
      <div
        ref="assignmentLoader"
        id="assignmentLoader"
        class="vs-con-loading__container"
        v-if="loadingAssignment"
      />
    </vx-card>
  </div>
</template>
<script>
// import reviewAssignment from './components/review-assignments/review-assignments.vue'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
// import VueSelect from 'vue-select'

const Assignments = RepositoryFactory.get('AssignmentsModule', 'assignments')

export default {
  name: 'assignment-assess',
  // components:{VueSelect},
  data () {
    return {
      //////////////////////
      // assignment
      /////////////////////
      assignment: {},
      assignmentStudentsCount: 0,
      assignmentClasses: [],
      grades: [1, 2, 3, 4, 5, 6, 7],
      criterias: [],

      //////////////////////
      // Grade && C
      /////////////////////
      selectedCriteria: {},
      selectedGrade: null,

      //////////////////////
      // give feedback
      /////////////////////
      feedbackToStudent: '',
      feedbackToParent: '',
      showFeedbackForm: false,
      //////////////////////
      // Classes
      /////////////////////
      classesHasAnswers: [],
      classSelected: {},
      //////////////////////
      // Students
      /////////////////////
      studentsAnswers: [],
      studentSelected: null,
      studentSelectedAnswerDetails: null,
      studentSelectedTask: {},
      studentsNotAssesed: [],
      studentsAssesed: [],

      //////////////////////
      // Modes
      /////////////////////
      assignmentDetailsMode: false,
      assignmentAssessMode: false,
      //////////////////////
      // loaders
      /////////////////////
      loadingStudents: true,
      loadingAssignment: false,
      loadingAnswer: false,
      loadigngClasses: false
    }
  },
  computed: {
    assignmentId () {
      return this.$route.params.id
    },
    doneOnTime () {
      return (
        this.toPercent(
          this.assignment.statistics.done_on_time,
          this.assignmentStudentsCount
        ) || 0
      )
    },
    doneButLate () {
      return (
        this.toPercent(
          this.assignment.statistics.done_but_late,
          this.assignmentStudentsCount
        ) || 0
      )
    },
    delayed () {
      return (
        this.toPercent(
          this.assignment.statistics.delayed,
          this.assignmentStudentsCount
        ) || 0
      )
    },
    showDefaultAssessText () {
      return !this.loadingAssignment && !this.studentSelected
    },

    studentSelectedParentId () {
      return (
        this.studentSelected?.father?.id ||
        this.studentSelected?.mother?.id ||
        null
      )
    },
    showFeedback () {
      return (
        this.assignmentAssessMode &&
        (this.studentSelectedTask?.student_feedback || this.showFeedbackForm)
      )
    },
    showComments () {
      return (
        this.assignmentAssessMode &&
        this.studentSelectedTask?.comments.some(c => c.comment)
      )
    },
    showAssassActions () {
      return (
        this.studentSelected &&
        this.assignmentAssessMode &&
        (!this.studentSelectedTask?.assess ||
          !this.studentSelectedTask?.assess.is_final) &&
        !this.loadingAssignment
      )
    },
    hasAssesed () {
      return !this.showAssassActions
    },
    assignmentSection () {
      return this.assignment?.section[0].short_name || null
    },
    assignmentCriteria () {
      return this.criterias || []
    },
    asessedTaskCriteria () {
      return this.studentSelectedTask?.assess?.criteria || []
    },
    hasMultiAnswers () {
      if (this.classSelected.answers.length > 1) {
        return true
      } else if (this.classSelected.answers.length === 1) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.studentSelected = this.classSelected.answers[0]
        // this.changeStudent();
        this.getAnswerDetails()
        return false
      } else {
        return false
      }
    }
  },
  created () {
    this.getAssignmentData()
  },
  methods: {
    ////////////////////////
    // Loading
    ///////////////////////
    // Assignment
    async startLoadingAssignment () {
      await (this.loadingAssignment = true)
      if (!this.assignment) await this.VSLoading(this.$refs.assignmentLoader)
      else if (this.$refs.assignmentCard) this.$refs.assignmentCard.refreshcard()
    },
    async endLoadingAssignment () {
      await this.stopVSLoading(this.$refs.loadingAssignment)
      if (this.$refs.assignmentCard) await this.$refs.assignmentCard.removeRefreshAnimation()
      this.loadingAssignment = false
    },

    // Students
    async startLoadingStudents () {
      await (this.loadingStudents = true)
    },
    async endLoadingStudents () {
      this.loadingStudents = false
    },

    // classes
    async startLoadingClasses () {
      await (this.loadingClasses = true)
      this.VSLoading(this.$refs.loadingClasses)
    },
    async endLoadingClasses () {
      // await this.stopVSLoading(this.$refs.loadingClasses)
      // this.loadingClasses = false
    },
    ////////////////////////
    // APIs
    ///////////////////////
    //get assignment
    getAssignmentData () {
      // Abort
      if (!this.assignmentId) return
      // Continue
      this.startLoadingAssignment()
      Assignments.getTeacherSingleAssignment(this.assignmentId)
        .then(res => {
          this.activeAssignmentMode()
          this.handleAssignmentData(res.data)
          this.getAssignmentAnswers()
          this.handleCriteria(res.data.criteria)
        })
        .catch(this.errMsg)
        .finally(this.endLoadingAssignment)
    },
    // Get All Answers
    getAssignmentAnswers () {
      // Abort
      if (!this.assignmentId) return
      // Continue
      this.startLoadingStudents()
      Assignments.getSubmittedStudents(this.assignmentId)
        .then(res => this.handleStudentData(res.data))
        .catch(this.errMsg)
        .finally(this.endLoadingStudents)
    },
    // Get one answer
    getAnswerDetails () {
      // Abort
      if (!this.studentSelected) return
      // Continue
      this.startLoadingAssignment()
      Assignments.getStudentTaskDetails(this.studentSelected.id)
        .then(res => {
          this.handleAnswer(res.data)
        })
        .catch(this.errMsg)
        .finally(this.endLoadingAssignment)
    },
    // Assess task
    assessStudentTask (data) {
      // Abort
      if (!this.studentSelected) {
        this.errMsg(this.$t('assignment.Choose_student_to_assess'))
        return
      }
      // Continue
      this.startLoadingAssignment()
      return Assignments.assessStudentTask(this.studentSelected.id, data)
        .then(() => {
          this.successMsg()
          this.changeToNextAnswer()
        })
        .catch(this.errMsg)
        .finally(this.endLoadingAssignment)
    },

    /////////////////////////
    // Handle data
    /////////////////////////
    // Asssingment data
    handleAssignmentData (data) {
      this.assignment = data
      this.assignmentStudentsCount = data.student_ids.length
      this.assignmentClasses = data.class_teacher.map(e => e.class)
    },
    handleCriteria (data) {
      if (this.assignmentSection === 'MYP') {
        this.criterias = data.map(item => {
          item.title = `${item.key} - ${item.title}`
          return item
        })
      } else if (this.assignmentSection === 'PYP') {
        this.criterias = data
      } else if (this.assignmentSection === 'DP') {
        this.criterias = Array.from(Array(7), (e, i) => i + 1)
      } else if (this.assignmentSection === 'AMERICAN') {
        this.criterias = Array.from(Array(20), (e, i) => i + 1)
      }
    },
    /////////////////////
    // Students
    handleStudentData (data) {
      let seq = 0
      this.studentsAnswers = data.map(a => {
        a.student_name = a.student.name
        a.student_name_assessed = `${a.student.name} ${
          a.has_assess ? `(${this.$t('assessments.assessed')})` : ''
        }`
        a.seq = seq++
        return a
      })
      this.handleClasses()
      this.handleAssessedStudents()
    },
    handleAssessedStudents () {
      this.studentsNotAssesed = this.studentsAnswers.filter(a => !a.has_assess)
      this.studentsAssesed = this.studentsAnswers.filter(a => a.has_assess)
    },
    /////////////////////
    // Classes

    handleAssignmentClasses () {
      this.classesHasAnswers = []
      this.assignmentClasses.forEach(c => {
        const classAnswers =
            this.studentsAnswers.filter(a => a.student.class.id === c.id) || [],
          hasAnswers = Boolean(classAnswers.length)
        if (hasAnswers) {
          c.answers = classAnswers
          this.classesHasAnswers.push(c)
        }
      })
    },
    handleDefaultClass () {
      this.classSelected = this.classesHasAnswers[0] || {}
      if (this.classSelected && this.classSelected.answers.length === 1) {
        this.studentSelected = this.classSelected.answers[0]
        this.getAnswerDetails()
      }
    },
    handleClasses () {
      if (!this.assignmentClasses.length) return
      this.handleAssignmentClasses()
      this.handleDefaultClass()
    },
    /////////////////////
    // Answer
    handleAnswer (data) {
      this.studentSelectedAnswerDetails = data
      this.studentSelectedTask = data.task
      this.activeAssesMode()
      this.handleShowAssess()
    },
    /////////////////////
    // Assessment
    handleAssessObj (is_final) {
      const payload = {
        notes: [],
        criteria:
          this.assignmentSection === 'MYP'
            ? this.selectedCriteria
            : this.criteria,
        is_final
      }
      if (this.feedbackToParent) payload.notes.push({
        note: this.feedbackToParent,
        sent_to: 'parent'
      })
      if (this.feedbackToStudent) payload.notes.push({
        note: this.feedbackToStudent,
        sent_to: 'student'
      })
      return payload
    },
    handleShowAssess () {
      if (!this.studentSelectedTask.assess) return
      const assess = this.studentSelectedTask.assess
      this.criteria = assess.criteria
      this.feedbackToStudent = assess.note || ''
      this.feedbackToParent = assess.note_parent || ''
      if (this.feedbackToParent || this.feedbackToStudent) this.activeFeedbackMode()
    },
    handleAssesStudentSelected () {
      this.studentSelected.has_assess = 1
      this.studentSelected.student_name_assessed = `${
        this.studentSelected.student_name
      } (${this.$t('assessments.assessed')})`
    },
    /////////////////////////
    // Modes
    /////////////////////////
    toggleAssignmentDetailsMode () {
      if (this.assignmentDetailsMode) this.activeAssesMode()
      else this.activeAssignmentMode()
    },
    activeAssignmentMode () {
      this.assignmentDetailsMode = true
      this.assignmentAssessMode = false
    },
    activeAssesMode () {
      this.assignmentAssessMode = true
      this.assignmentDetailsMode = false
    },
    async activeFeedbackMode () {
      await (this.showFeedbackForm = true)
      this.$refs.feedbackDiv.scrollIntoView({ behavior: 'smooth' })
    },
    /////////////////////////
    // Reset Data
    /////////////////////////
    resetStudents () {
      this.studentSelected = null
      this.resetStudentAnswer()
    },
    resetStudentAnswer () {
      this.studentSelectedAnswerDetails = this.studentSelectedTask = null
    },
    resetAssessArea () {
      this.resetGrade()
      this.resetFeedback()
    },
    resetGrade () {
      this.criteria = this.selectedGrade = null
    },
    resetFeedback () {
      this.feedbackToStudent = ''
      this.feedbackToParent = ''
      this.showFeedbackForm = false
    },
    /////////////////////////
    // OnChange
    /////////////////////////
    changeClasses () {
      this.resetStudents()
      this.activeAssesMode()
    },
    async changeStudent () {
      await this.resetStudentAnswer()
      this.resetAssessArea()
      if (this.studentSelected?.id) this.getAnswerDetails()
    },
    async changeToNextAnswer () {
      this.handleAssesStudentSelected()
      this.handleAssessedStudents()

      const nextStudent = this.studentsAnswers.find(item => {
          if (item.seq > this.studentSelected.seq && !item.has_assess) return item
          else if (!item.has_assess) return item
        }),
        nextStudentClassId = nextStudent?.student?.class?.id || null,
        sameClass = nextStudent
          ? this.classSelected.id === nextStudentClassId
          : null
      if (nextStudent) {
        if (sameClass) this.studentSelected = nextStudent
        else {
          this.classSelected = find(c => c.id === nextStudentClassId)
          this.studentSelected = nextStudent
        }
      }
      if (this.studentSelected?.id) {
        this.resetAssessArea()
        await this.resetStudentAnswer()
        await this.getAnswerDetails()
      }
    },

    /////////////////////////
    // Moved Methods
    /////////////////////////
    reducePYPData (data) {
      this.criteria = [data.key]
    },
    selectGrade (data) {
      this.criteria = [data]
    },
    /////////////////////////
    // Submit
    /////////////////////////
    returnAssignmentBack () {
      const valid = Boolean(this.feedbackToStudent)
      if (valid) {
        const payload = this.handleAssessObj(false)
        this.assessStudentTask(payload)
      } else this.errMsg()
    },
    submitAssignmentAsses () {
      this.$validator.validateAll().then(valid => {
        if (valid) {
          const payload = this.handleAssessObj(true)
          this.assessStudentTask(payload)
        } else this.errMsg()
      })
    }
  }
}
</script>
<style scoped>
.assignment__body {
  min-height: 200px;
}

#student-select .vs__search::placeholder {
  color: #ddd;
}
#student-select .vs__search::-webkit-input-placeholder {
  /* Edge */
  color: #ddd;
}
#student-select .vs__search:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ddd;
}

.content-box {
  min-height: 38px;
  height: auto;
  border-radius: 5px;
  padding: 7px;
  border: 1px solid rgba(var(--vs-primary), 0.7);
  font-weight: 500;
  font-size: 13px;
}
</style>
<style lang="scss">
#loadingClasses {
  .vs-con-loading__container {
    position: absolute;
    inset: 0;
  }
}
#student-select .vs__search {
  $placeholderColor: rgb(95, 95, 95);
  &::placeholder {
    color: $placeholderColor;
  }
  &::-webkit-input-placeholder {
    /* Edge */
    color: $placeholderColor;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $placeholderColor;
  }
}
</style>
