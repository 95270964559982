<template>
  <div>
    <vx-card class="p-relative">
      <h6 class="text-primary">Exam Theatre & Sound Manipulation</h6>
      <hr class="m-2" />
      <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-chip :class="`status--${exam.status.status} `">
            <p>{{ exam.status.status }}</p>
          </vs-chip>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-chip class="status--dark mx-auto flex justify-between float-none">
            <p>01:25:13</p>
          </vs-chip>
        </vs-col>
        <vs-row class="runungExamInfFont">
          <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="6">
            <p>Start the exam</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1">
            :
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="5">
            00 Students
          </vs-col>
        </vs-row>
        <vs-row class="runungExamInfFont">
          <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="6">
            <p>Submitted the exam</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1">
            :
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="5">
            {{ exam.submittedStudentsCount }} Students
          </vs-col>
        </vs-row>
        <vs-row class="runungExamInfFont">
          <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="6">
            <p>On Going</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1">
            :
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="5">
            00 Students
          </vs-col>
        </vs-row>
        <vs-row
          class="runungExamInfFont mt-2"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
        >
          <vs-chip style="width: 100%" class="status--dark">
            <a class="no_style">
              <vx-tooltip
                @click="startExam"
                text="Start Eexam"
                class="text-right inline-block"
              >
                <feather-icon
                  icon="PlayCircleIcon"
                  title="Start Exam"
                  svgClasses="h-5 w-5   cursor-pointer"
                />
              </vx-tooltip>
            </a>
            <span style="margin-left: 10px">Start The exam</span>
          </vs-chip>
        </vs-row>
        <vs-row
          class="runungExamInfFont mt-2"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
        >
          <vs-chip style="width: 100%" class="status--dark">
            <a class="no_style">
              <vx-tooltip
                text="Open the exam for new students"
                class="text-right inline-block"
                @click="OpenExam"
              >
                <feather-icon
                  icon="UnlockIcon"
                  title="Open the exam for new students"
                  svgClasses="h-5 w-5   cursor-pointer"
                />
              </vx-tooltip>
            </a>
            <span style="margin-left: 10px">Open the exam</span>
          </vs-chip>
        </vs-row>
        <vs-row
          class="runungExamInfFont mt-2"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
        >
          <vs-chip style="width: 100%" class="status--dark">
            <a class="no_style">
              <vx-tooltip
                text="Force Submission for students"
                class="text-right inline-block"
                @click="forceSubmission"
              >
                <feather-icon
                  icon="LockIcon"
                  title="Force Submission for students"
                  svgClasses="h-5 w-5   cursor-pointer"
                />
              </vx-tooltip>
            </a>
            <span style="margin-left: 10px">Force Submission</span>
          </vs-chip>
        </vs-row>
      </vs-row>
    </vx-card>
  </div>
</template>

<script>
import Axios from 'axios'
import moment from 'moment'
// API
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const ExamRepo = RepositoryFactory.get('ExamModule', 'Exam')


export default {
  props: {
    exam: {
      type: Object,
      required: true
    }
  },
  methods: {
    startExam () {
      ExamRepo.startTeacherExam(this.exam.id, moment().format('YYYY-MM-DD HH:MM'))

        .then(res => {
          console.log('res', res)
          this.successMsg(this.$t('exam.Exam_Started'))
        })
        .catch(err => {
          this.errMsg(err.message)

        })
    },
    OpenExam () {
      ExamRepo.OpenTeacherExam(this.exam.id,  new Date())

        .then(res => {
          console.log('res', res)
          this.successMsg(this.$t('exam.Exam_Opened'))
        })
        .catch(err => {
          this.errMsg(err.message)

        })
    },
    forceSubmission () {
      ExamRepo.forceSubmissionTeacher(this.exam.id, this.exam)
        .then(res => {
          console.log('res', res)
          this.successMsg(this.$t('exam.Exam_Submitted'))
        })
        .catch(err => {
          this.errMsg(err.message)

        })
    }
  }
}
</script>

<style lang="scss">
.runungExamInfFont {
  font-size: 10px;
  text-align: left !important;
  float: left;
}
</style>
