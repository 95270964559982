<template>
  <div>
    <teacherParentForm :teacher_id="teacher_id"></teacherParentForm>
  </div>
</template>
<script>
import teacherParentForm from '../../../components/teacherParentForm.vue'

export default {
  name:'CreateHod',
  components:{
    teacherParentForm
  },
  created () {

  },
  data () {
    return {
      teacher_id: this.$route.params.id
    }
  }
}
</script>
