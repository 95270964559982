import { render, staticRenderFns } from "./canteenCard.vue?vue&type=template&id=5880b1cc&scoped=true"
import script from "./canteenCard.vue?vue&type=script&lang=js"
export * from "./canteenCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5880b1cc",
  null
  
)

export default component.exports