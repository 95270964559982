export default {
  createCanteen: 'Add Canteen',
  ifCreateCanteenUser:'The canteen manager can create other users',
  name:'Name',
  userType:'User Type',
  status: 'Status',
  userList: 'All Users',
  createUser: 'Add User',
  suspend: 'Suspend',
  createCategory: 'Ceate Category',
  categoryList: 'All Categories',
  categoryName: 'Category Name',
  items: 'Items',
  category: 'Category',
  showItems: 'Show Items',
  addItem: 'Add Item',
  itemName: 'Item Name',
  price: 'Price',
  categoryItem: 'All Items'
}
