<template>
  <div>
    <vs-card class="p-3 py-6 md:p-6">
      <form
        class="flex justify-between flex-col"
        @submit.prevent="submitForm"
        @keyup.enter="submitForm"
      >
        <div class="mb-4 mt-auto w-full vx-row items-center mx-auto">
          <div class="my-2 vx-col w-full mb-2">
            <label
              >{{ $t("basic.academic_year")
              }}<sup class="text-danger">*</sup></label
            >
            <v-select
              :closeOnSelect="true"
              v-model="certificate.academic_year_id"
              :options="allAcademicYears"
              label="name"
              :reduce="(option) => option._id"
              v-validate="'required'"
              name="academic_year_id"
              class="w-full mb-2"
              :disabled="allAcademicYears ? false : true"
            />

            <span class="text-danger text-sm">{{
              errors.first("academic_year_id")
            }}</span>
          </div>
          <!--choose term -->
          <div class="my-2 vx-col w-full mb-2">
            <label
              >{{ $t("Syllabus.terms") }}<sup class="text-danger">*</sup></label
            >
            <v-select
              :closeOnSelect="true"
              v-model="certificate.term"
              :options="allTerms"
              :reduce="(option) => option"
              v-validate="'required'"
              name="term"
              class="w-full mb-2"
            />

            <span class="text-danger text-sm">{{ errors.first("term") }}</span>
          </div>
          <!-- choose term -->

          <!--student_id -->
          <div class="my-2 vx-col w-full mb-2">
            <label
              >{{ $t("StudentAffairs.student_id")
              }}<sup class="text-danger">*</sup></label
            >
            <vs-input
              type="text"
              v-model="certificate.code"
              class="w-full"
              name="code"
              v-validate="'required'"
            />
            <span class="text-danger text-sm">{{ errors.first("code") }}</span>
          </div>
          <!-- student_id -->
          <!--Start Upload File -->
          <div class="my-2 vx-col w-full">
            <label class="w-full">{{ $t("exam.upload_attachment") }}</label>
            <vs-input
              type="file"
              accept=".pdf;capture=camera"
              @change="uploadDoc($event)"
              v-model="documents"
              class="w-full mb-2"
              ref="upload_file"
            />
          </div>
          <div class="my-2 vx-col w-full" v-if="certificate.documents">
            <div>
              <feather-icon
                @click="deleteDocument()"
                class="mr-2 remove-student-attachment"
                icon="XIcon"
                svgClasses="h-5 w-5"
              />
              <file-previewer
                :url="certificate.documents.document"
                :urlType="
                  certificate.documents.type === 'application/pdf'
                    ? 'pdf'
                    : certificate.documents.type
                "
                class="ml-1 my-4 mr-4"
              ></file-previewer>
            </div>
          </div>
          <!--End Upload File -->
        </div>
        <!-- Actions -->
        <div class="flex flex-wrap justify-between items-center mt-auto mb-0">
          <vs-button class="mt-5 md:w-auto md:mr-0 ml-auto" @click="submitForm">
            {{ $t("form.submit") }}
          </vs-button>
        </div>
      </form>
    </vs-card>
  </div>
</template>
<script>

// import getData from './form/common_data_mixin'

import { commonDataMixin, checkValidationMixin } from '@/mixins'
let fd = '' 
export default {
  name: 'add-certificate-term-form',
  mixins: [commonDataMixin, checkValidationMixin],

  data () {
    return {
      allTerms: [
        'Q1',
        'Q2',
        'Q3',
        'Q4',
        'Trimester 1',
        'Trimester 2',
        'Trimester 3',
        'Trimester 4',
        'Term 1',
        'Term 2',
        'Term 3',
        'Term 4'
      ],
      term : this.$route.query.term,
      certificate:{
        academic_year_id: '',
        user_id: '',
        term: '',
        documents: ''
      },
      documents: ''
    }
  },
  watch: {
  },
  computed: {
  },
  methods: {
    submitForm () {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          fd.append('academic_year_id', this.certificate.academic_year_id)
          fd.append('code', this.certificate.code)
          fd.append('term', this.certificate.term)
          this.$emit('formSubmited', fd)
        } else {
          this.errMsg()
        }
      }).then(() => {
        fd.remove('academic_year_id')
        fd.remove('user_id')
        fd.remove('term_id')
        fd.remove('file')
      })
    },

    // To Select File
    clickOnFile () {
      this.$refs.upload_file.click()
    },

    //  Upload Docs
    uploadDoc (e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      if (files[0].type != 'application/pdf') {
        this.errMsg(this.$t('form.upload_pdf_only'))
        this.certificate.documents = ''
        this.documents = ''
      } else {
        this.certificate.documents = files[0]
        fd.append('file', files[0], files[0].name)
      }
    },
    //  Delete Docs
    async deleteDocument () {
      fd.delete('file')
      this.documents = ''
      this.certificate.documents = ''
    }
  },
  created () {
    fd = new FormData()
    this.getAllAcademic()
  },
  mounted () {}
}
</script>
<style lang="scss">
.remove-student-attachment {
  top: 32px;
  left: 90px;
  z-index: 20;
  cursor: pointer;
  svg {
    stroke: white;
    stroke-width: 2px;
  }
}
</style>
