export default {
  teachers :'teachers gateway',
  list_teachers : 'All Teachers',
  addTeacher : 'Add teacher',
  // Start teacher form for Add & Edit
  full_name_en :'Full Name EN',
  full_name_ar :'Full Name Ar',
  first_name :'First Name',
  second_name :'Second Name',
  third_name :'Third Name',
  fourth_name :'Fourth Name',
  gender :'Gender',
  birth_date : 'Birth Date',
  email :'Email',
  phone:'Phone',
  mobile:'Mobile',
  address:'Address',
  landline:'Landline',
  gmail :'Gmail',
  status:'Status',
  image :'Image',
  official_phone :'Official Phone',
  Divisions_Sections :'divisions/sections',
  divisions :'Divisions',
  sections:'Sections',
  is_homeroom_teacher :'Is Homeroom Teacher',
  yes :'Yes',
  no:'No',
  show_single_Teacher:'Show Single Teacher',
  fullName:'Full Name',
  suspend_teacher: 'Suspend Teacher',
  edit_teacher:'Edit Teacher',
  teacher_has_not_been_saved_please_complete_your_data: ' teacher has not been saved please complete your data',
  subjects:'Subjects',
  spoken_langs:'languages',
  you_cant_repeat_same_value: 'You can\'t repeat same value',
  official_mail :'Official Email',
  send_credentials :'Credentials',
  copy_email:'Copy Email',
  copy_student_email :'Copy Student Email',
  copy_mother_email :'Copy Mother Email',
  copy_father_email :'Copy Father Email',
  create_hod:'Create HOD',
  is_HOD_teacher :'Is HOD Teacher',
  teacher_role:'Teacher Role',
  create_section_coodinator:'Create Section Coodinator',
  section_coodinator:'Section Coodinator',
  is_teacher_section_coodinator :'Is Teacher Section Coodinator',
  create_grade_coodinator:'Create Grade Coodinator',
  grade_coodinator:'Grade Coodinator',
  is_teacher_grade_coodinator :'Is Teacher Grade Coodinator',
  back_to_teacher:'Back To Teacher',
  edit_section_coodinator:'Edit Section Coodinator',
  create_teacher_parent: 'Create Teacher Parent',
  my_parent_profile: 'My Parent Profile',
  submit_teacher_parent:'you want to change your parent email, Are you sure',
  addCoordinators: 'Add Coordinators'
}
