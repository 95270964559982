<template>
  <div id="reflectionCard">
    <vx-card class="p-relative mb-8">
      <vs-dropdown
        v-if="is_student && !this.refelect.reply"
        class="cas-action-Buttons text-capitalize"
        vs-custom-content
        vs-trigger-click
      >
        <vs-button color="#999" type="flat" icon="more_vert"></vs-button>
        <vs-dropdown-menu>
          <vs-dropdown-item @click="deleteReflection()">
            <feather-icon icon="Trash2Icon" svgClasses="h-4 w-4" class="mr-2" />
            {{ $t("basic.delete") }}
          </vs-dropdown-item>

          <vs-dropdown-item @click="activePrompt = true">
            <feather-icon icon="Edit2Icon" svgClasses="h-4 w-4" class="mr-2" />
            {{ $t("basic.edit") }}
          </vs-dropdown-item>
        </vs-dropdown-menu>
      </vs-dropdown>

      <!-- propemt for edit -->
      <vs-prompt
        :title="$t('basic.edit')"
        @accept="editReflection()"
        :active.sync="activePrompt"
      >
        <div class="con-exemple-prompt">
          <vs-textarea
            placeholder="repleaction"
            v-model="descption"
            height=" 200px"
          />
        </div>
      </vs-prompt>
      <!--//////////////////
            Reflection
       /////////////////////// -->
      <div class="min_height_110 p-1 pt-1 pb-1 md:p-1">
        <h6 class="mb-8">
          <b>{{ refelect.body }}</b>
        </h6>
        <p
          class="
            flex flex-wrap
            text-sm
            justify-between
            w-full
            md:flex-row
            flex-col
          "
        >
          <span class="flex-1 md:mb-0 mb-2">
            <!--////////////////////////
                 Reflection Label
         ////////////////////////////-->
            <span class="text-capitalize" v-if="index === 1">
              {{ $t("cas.first_reflection") }}
            </span>
            <span class="text-capitalize" v-if="index === 2">
              {{ $t("cas.second_reflection") }}
            </span>
            <span class="text-capitalize" v-if="index === 3">
              {{ $t("cas.Third_Final_reflection") }}
            </span>
          </span>
          <span class="flex flex-row w-auto flex-wrap text-sm justify-between">
            <!--////////////////////////
                Student Name
          ////////////////////////////-->
            <span
              class="flex items-center md:mb-0 mb-2 mr-5"
              v-if="is_coordinator"
            >
              <feather-icon
                icon="UserIcon"
                svgClasses="h-5 w-5 text-warning stroke-current"
              />
              <span class="ml-2"> {{ refelect.student_name }}</span>
            </span>

            <!--////////////////////////
                    Date
            ////////////////////////////-->
            <span class="flex items-center md:mb-0 mb-2 mr-5">
              <feather-icon
                icon="CalendarIcon"
                svgClasses="h-5 w-5 text-primary stroke-current"
              />
              <span class="ml-2">{{
                new Date(refelect.created_at_reflection).toDateString()
              }}</span>
            </span>
            <!--////////////////////////
                    Student Class
            ////////////////////////////-->
            <span
              class="flex items-center bg-warning text-white"
              :class="{ 'md:mb-0 mb-2 mr-5': canReplay }"
            >
              <span class="mx-2">{{ refelect.student_class }}</span>
            </span>
            <!--////////////////////////
                    Replay
            ////////////////////////////-->
            <span v-if="canReplay">
              <vx-tooltip :text="$t('basic.reply')">
                <feather-icon
                  icon="MessageSquareIcon"
                  svgClasses="h-4 w-4"
                  class="mr-2"
                  @click="reply"
                />
              </vx-tooltip>
            </span>
          </span>
        </p>
      </div>
      <!--//////////////////
            Replay
       /////////////////////// -->
      <div class="min_height_110 p-5 border mt-6" v-if="refelect.reply">
        <h6 class="mb-8">
          <b>{{ refelect.reply }}</b>
        </h6>
        <p class="text-sm flex flex-wrap text-sm justify-between w-full">
          <!--////////////////////////
                Coordinator Name
           ////////////////////////////-->
          <span
            class="flex items-center md:mb-0 mb-2"
            v-if="is_student && refelect.coordinator_reply_name"
          >
            <feather-icon
              icon="UserIcon"
              svgClasses="h-5 w-5 text-primary stroke-current"
            />
            <span class="ml-2">
              {{ $t("basic.by") }} {{ refelect.coordinator_reply_name }}
            </span>
          </span>
          <!--////////////////////////
                    Date
            ////////////////////////////-->
          <span class="flex items-center mr-0 ml-auto">
            <feather-icon
              icon="CalendarIcon"
              svgClasses="h-5 w-5 text-primary stroke-current"
            />
            <span class="ml-2">{{
              new Date(refelect.reply_date).toDateString()
            }}</span>
          </span>
        </p>
      </div>
    </vx-card>
  </div>
</template>
<script>
// APIs
// import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
// const CasRepository = RepositoryFactory.get(
//   'CASRepository',
//   'TaskRepository'
// )
// APIs
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const CasRepository = RepositoryFactory.get('CASRepository', 'TaskRepository')

import VxTooltip from '@/layouts/components/vx-tooltip/VxTooltip.vue'

// Mixins
import mixins from '../../../mixins'

export default {
  name: 'reflection-card',
  props: {
    refelect_prop: {
      type: Object,
      required: true
    },

    index: {
      type: Number
    }
  },
  mixins: [mixins],
  components: { VxTooltip },
  data () {
    return {
      activePrompt: false,
      descption: '',
      refelect: this.refelect_prop
    }
  },
  computed: {
    canReplay () {
      return this.is_coordinator && !this.refelect.reply
    }
  },
  methods: {
    reply () {
      this.$emit('reply', this.refelect.id)
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
    },
    // delete
    async deleteReflection () {
      const payload = {
        reflection_id: this.refelect.id
      }
      await CasRepository.deleteReflection(payload).then((res) => {
        this.successMsg(res.message)
      })
      this.$emit('getReflections', true)
    },

    // edit
    async editReflection () {
      const payload = {
        reflection_id: this.refelect.id,
        body: this.descption
      }
      await CasRepository.editReflection(payload).then((res) => {
        this.successMsg(res.message)
      })
      this.$emit('getReflections', true)
    }
  },
  mounted () {
    this.descption = this.refelect.body
  }
}
</script>
<style lang="scss" >
.con-vs-chip.flex.justify-between {
  .text-chip.vs-chip--text {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
iframe {
  border: 1px solid #ccc;
}
#reflectionCard {
  .hasUpdates {
    position: absolute;
    right: 0px;
    top: -15px;
  }
}
</style>
