import ParentProfile from './pages/profile/Profile.vue'
import completeProfileData from './pages/profile/completeProfileData.vue'
import completeCustodyData from './pages/profile/completeCustodyData.vue'
import studentReportCards from './pages/profile/ReportCards.vue'
import studentTasks from './pages/profile/Tasks.vue'
import OnlineClasses from './pages/profile/OnlineClasses.vue'
import myDayParent from './pages/profile/myDayParent.vue'
import myDayDetails from './pages/profile/myDayDetails.vue'
import Assignments from './pages/profile/Assignments.vue'

export default [
  {
    path: 'profile',
    name: 'ParentProfile',
    component: ParentProfile,
    meta: {
      breadcrumb: [{ title: 'home', url: '/home' }],
      pageTitle: 'config.profile',
      hideVerticalMenu:true,
      rule: 'Parent'
    }
  },
  {
    path: 'completeProfileData',
    name: 'completeParentProfileData',
    component: completeProfileData,
    meta: {
      breadcrumb: [],
      pageTitle: 'completeProfileData.completeProfileData',
      // hideVerticalMenu:true,
      rule: 'Parent'
    }
  },
  {
    path: 'completeCustodyData',
    name: 'completeParentCustodyData',
    component: completeCustodyData,
    meta: {
      breadcrumb: [],
      pageTitle: 'completeProfileData.completeChildrenData',
      // hideVerticalMenu:true,
      rule: 'Parent'
    }
  },
  // ----------------------------------------------------------
  //  Get separated my report card
  // ----------------------------------------------------------
  {
    path: 'report-cards/:child_id',
    name: 'parent_reportCards',
    component: studentReportCards,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        // { title: 'config.parent', url: '/parent' },
        // { title: 'config.StudentProfile', url: 'parent/profile/:id', active: true },
        { title: 'inventory.my_report_card', url: '', active: true }
      ],
      pageTitle: 'inventory.my_report_card',
      rule: 'Parent'
    }
  },
  // ----------------------------------------------------------
  //  Get separated tasks
  // ----------------------------------------------------------
  {
    path: 'tasks/:child_id',
    name: 'parent_tasks',
    component: studentTasks,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        // { title: 'config.parent', url: '/parent' },
        // { title: 'config.StudentProfile', url: 'parent/profile/:id', active: true },
        { title: 'basic.tasks', url: '', active: true }
      ],
      pageTitle: 'basic.tasks',
      rule: 'Parent'
    }
  },
  // ----------------------------------------------------------
  //  Get separated onlineClass
  // ----------------------------------------------------------
  {
    path: 'onlineClass/:child_id',
    name: 'parent_onlineClass',
    component:  OnlineClasses,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        // { title: 'config.parent', url: '/parent' },
        // { title: 'config.StudentProfile', url: 'parent/profile/:id', active: true },
        { title: 'basic.onlineClass', url: '', active: true }
      ],
      pageTitle: 'basic.onlineClass',
      rule: 'Parent'
    }
  },
  {
    path: 'myDay/:child_id',
    name: 'parent_myDay',
    component:  myDayParent,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        // { title: 'config.parent', url: '/parent' },
        // { title: 'config.StudentProfile', url: 'parent/profile/:id', active: true },
        { title: 'myDay.myDay', url: '', active: true }
      ],
      pageTitle: 'myDay.myDay',
      rule: 'Parent'
    }
  },

  {
    path: 'myDay-details',
    name: 'parent_myDay_details',
    props: true,
    component:  myDayDetails,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        // { title: 'config.parent', url: '/parent' },
        // { title: 'config.StudentProfile', url: 'parent/profile/:id', active: true },
        { title: 'Details', url: '', active: true }
      ],
      pageTitle: 'Details',
      rule: 'Parent'
    }
  },
  // ----------------------------------------------------------
  //  Get separated assignments
  // ----------------------------------------------------------
  {
    path: 'assignments/:child_id',
    name: 'parent_assignments',
    component:  Assignments,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        // { title: 'config.parent', url: '/parent' },
        // { title: 'config.StudentProfile', url: 'parent/profile/:id', active: true },
        { title: 'basic.assignments', url: '', active: true }
      ],
      pageTitle: 'basic.assignments',
      rule: 'Parent'
    }
  }
]
