import StudentProfile from './pages/student/NewProfile.vue'
import studentRunningExam from '@/modules/exam/sub/running.vue'
import singleTask from './pages/student/Components/Left-Sidebar/singleTask/singleTask'
import taskGrade from './pages/student/Components/Left-Sidebar/taskGrade/taskGrade'
import examGrade from '@/modules/exam/sub/showGrade'
import studentAssignmentsRoutes from '@/modules/assignment/studentAssignment.routes.js'
import announcementShow from '@/modules/announcement/components/announcementShow.vue'
import studentOnlineClass from './pages/student/onlineClasses.vue'
import studentReportCards from './pages/student/ReportCards.vue'
import studentAssignments from './pages/student/Assignments.vue'
import studentTasks from './pages/student/Tasks.vue'
import myDay from './pages/student/myDayParent'
import myDayDetails from './pages/student/myDayDetails'
//import StudentCreate from './pages/division/Create.vue'
//import StudentEdit from './pages/division/Edit.vue'
export default [
  {
    path: 'StudentProfile/:user_id',
    name: 'StudentProfile',
    component: StudentProfile,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        // { title: 'config.student', url: '/student' },
        {
          title: 'StudentAffairs.allStudents',
          url: '/student-affairs/all-students'
        },
        { title: 'config.StudentProfile', url: 'student/StudentProfile/:id', active: true }
      ],
      pageTitle: 'config.StudentProfile',
      hideVerticalMenu:true,
      rule: 'Profile'
    }
  },
  {
    path: 'profile/:id',
    name: 'BackToProfile',
    component: StudentProfile,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' }
        // { title: 'config.student', url: '/student' },

        // { title: 'config.StudentProfile', url: 'student/profile/:id', active: true }
      ],
      pageTitle: 'config.StudentProfile',
      hideVerticalMenu:true,
      rule: 'Student'
    }
  },
  {
    path: 'exam/:id',
    name: 'ExamRunningStudent',
    component: studentRunningExam,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        // { title: 'config.student', url: '/student' },

        { title: 'config.StudentProfile', url: 'student/profile/:id', active: true }
      ],
      pageTitle: 'config.StudentProfile',
      hideVerticalMenu:true,
      rule: 'Student'
    }
  },
  {
    path: 'exam/:id/grade',
    name: 'ExamGradeStudent',
    component: examGrade,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        // { title: 'config.student', url: '/student' },

        { title: 'config.StudentProfile', url: 'student/profile/:id', active: true }
      ],
      pageTitle: 'exam.grade_exam',
      rule: 'Student'
    }
  },
  // ----------------------------------------------------------
  //  Get Single Assignemnt
  // ----------------------------------------------------------

  ...studentAssignmentsRoutes,
  // ----------------------------------------------------------
  //  Get Single Task
  // ----------------------------------------------------------

  {
    path: 'task/:id',
    name: 'answer_task',
    component: singleTask,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' }
        // { title: 'config.student', url: '/student' },
      ],
      pageTitle: 'assessments.answer_task',
      // hideVerticalMenu:true,
      rule: 'Student'
    }
  },
  {
    path: 'task/:id/grade',
    name: 'task-grade',
    component: taskGrade,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' }
        // { title: 'config.student', url: '/student' },
      ],
      pageTitle: 'assessments.task_grade',
      // hideVerticalMenu:true,
      rule: 'Student'
    }
  },
  {
    path: 'announcement/show/:id',
    name: 'show-announcement-student',
    component: announcementShow,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        // { title: 'config.student', url: '/student' },

        { title: 'config.StudentProfile', url: 'student/profile/:id', active: true }
      ],
      pageTitle: 'main.show',
      rule: 'Student'
    }
  },
  // ----------------------------------------------------------
  //  Get separated onlineClass
  // ----------------------------------------------------------
  {
    path: 'onlineClass',
    name: 'student_onlineClasses',
    component: studentOnlineClass,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        // { title: 'config.student', url: '/student' },
        { title: 'config.StudentProfile', url: 'student/profile/:id', active: true },
        { title: 'onlineClass.onlineClasses', url: '', active: true }
      ],
      pageTitle: 'onlineClass.onlineClasses',
      rule: 'Student'
    }
  },
  // ----------------------------------------------------------
  //  Get separated my report card
  // ----------------------------------------------------------
  {
    path: 'report-cards',
    name: 'student_reportCards',
    component: studentReportCards,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        // { title: 'config.student', url: '/student' },
        // { title: 'config.StudentProfile', url: 'student/profile/:id', active: true },
        { title: 'inventory.my_report_card', url: '', active: true }
      ],
      pageTitle: 'inventory.my_report_card',
      rule: 'Student'
    }
  },

  // ----------------------------------------------------------
  //  Get separated assignment
  // ----------------------------------------------------------
  {
    path: 'assignments',
    name: 'student_assignments',
    component: studentAssignments,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        // { title: 'config.student', url: '/student' },
        // { title: 'config.StudentProfile', url: 'student/profile/:id', active: true },
        { title: 'basic.assignments', url: '', active: true }
      ],
      pageTitle: 'basic.assignments',
      rule: 'Student'
    }
  },
  // ----------------------------------------------------------
  //  Get separated tasks
  // ----------------------------------------------------------
  {
    path: 'tasks',
    name: 'student_tasks',
    component: studentTasks,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        // { title: 'config.student', url: '/student' },
        // { title: 'config.StudentProfile', url: 'student/profile/:id', active: true },
        { title: 'basic.tasks', url: '', active: true }
      ],
      pageTitle: 'basic.tasks',
      rule: 'Student'
    }
  },
  // ----------------------------------------------------------
  //  Get My day
  // ----------------------------------------------------------
  {
    path: 'my-day/:user_id',
    name: 'student_myDay',
    component:  myDay,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        // { title: 'config.parent', url: '/parent' },
        // { title: 'config.StudentProfile', url: 'parent/profile/:id', active: true },
        { title: 'myDay.myDay', url: '', active: true }
      ],
      pageTitle: 'myDay.myDay',
      rule: 'Student'
    }
  },
  {
    path: 'myDay-details',
    name: 'student_myDay_details',
    props: true,
    component:  myDayDetails,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        // { title: 'config.parent', url: '/parent' },
        // { title: 'config.StudentProfile', url: 'parent/profile/:id', active: true },
        { title: 'Details', url: '', active: true }
      ],
      pageTitle: 'Details',
      rule: 'Student'
    }
  }
]
