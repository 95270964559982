import myDaySettings from './pages/setting'


export default [
  {
    path: '',
    name: 'myDay',
    component: myDaySettings,
    props: true,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        {
          title: 'myDay.myDay',
          active: true
        }
      ],
      pageTitle: 'myDay.myDay',
      rule: 'TeacherSNDSMD'
    }
  }
]
