

<template>
  <div class="add_student">
    <studentForm @formSubmited="AddStudent" />
    <!-- Copy Student data Dialog -->
    <vs-prompt
      @accept="copyStudentData"
      :buttons-hidden="true"
      :active.sync="showCopyStudentData"
      :title="$t('StudentAffairs.student_credentials')"
      color="success"
      type="confirm"
    >
      <p class="mb-3">
        {{
          this.$t("StudentAffairs.please_copy_following_data") | text_formatter
        }}
      </p>
      <p class="mb-2">
        <strong>{{ $t("basic.username") }}</strong>
        <span class="mx-3">{{ dataToCopy.username }}</span>
      </p>
      <p class="mb-2">
        <strong>{{ $t("form.password") }}</strong>
        <span class="mx-3">{{ dataToCopy.password }}</span>
      </p>
      <!-- Btns -->
      <div class="text-right mt-6">
        <vs-button class="mt-2 mx-2" @click="copyStudentData" color="success"
          >{{ $t("basic.copy") }}
        </vs-button>
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import studentForm from '../../../components/StudentForm.vue'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const addStudentRepository = RepositoryFactory.get('studentAffairsRepository', 'addStudent')


export default {
  components: {
    studentForm
  },
  data () {
    return {
      showCopyStudentData:false,
      dataToCopy:{
        name:null,
        official_email:null,
        username:null,
        password:null
      }
    }
  },
  computed: {},
  watch: {},
  methods: {
    AddStudent (payload) {
      addStudentRepository.AddStudent(payload).then((res) => {
        this.successMsg(this.$t('sudentAffairs.Add_student_successfully'))
        if (res.data.username) {
          payload.username = res.data.username
          this.dataToCopy.username = res.data.username
          this.dataToCopy.password = payload.password
          this.showCopyStudentData = true 
        } else this.$router.push({name:'all-students'})
      }).catch((err) => {
        this.errMsg(err)
        console.log(err)
      })
    },
    copyStudentData () {
      const text = `${this.$t('basic.username')} :   ${this.dataToCopy.username} \n${this.$t('form.password')} :   ${this.dataToCopy.password} `
      navigator.clipboard.writeText(text)
      this.successMsg(this.$t('form.Copied_success'))
      this.$router.push({name:'all-students'})
    }
  },
  created () {}
}
</script>
<style lang="scss">
</style>
