<template>
  <div>
    <vx-card class="p-3 py-6 md:p-4">
      <h4 class="text-primary">{{ exam_prop.name }}</h4>
      <hr />
      <!-- Title -->
      <h6 class="text-primary mt-5 mb-5" v-if="exam_prop.type.type != 'Paper'">
        {{ exam_prop.questions[currentQuestion].title }}
      </h6>
      <!-- answers  -->
      <vx-card v-if="exam_prop.type.type != 'Paper'" class="p-2 mb-5">
        <!-- descrption -->
        <vs-row v-if="exam_prop.type.type != 'Paper'" class="mb-4">
          <h4 v-html="exam_prop.questions[currentQuestion].description"></h4>
        </vs-row>
        <div
          class="w-full"
          v-if="exam_prop.questions[currentQuestion].type.type == 'Essay'"
        >
          <vs-textarea
            v-model="answers[currentQuestion].answers[0]"
            @input="checkLength"
            color="primary"
            class="mt-2"
            name="answer"
          ></vs-textarea>
        </div>

        <vs-row
          v-for="ans in exam_prop.questions[currentQuestion].answers"
          :key="ans.description"
        >
          <vs-col w="12">
            <vs-checkbox
              v-if="
                exam_prop.questions[currentQuestion].type.type ==
                'Multiple Choices'
              "
              color="primary"
              :vs-value="ans.id"
              :vs-name="'answer_' + currentQuestion"
              v-model="answers[currentQuestion].answers"
              class="mt-2"
              ><span v-html="ans.description"></span
            ></vs-checkbox>
            <vs-radio
              :vs-value="ans.id"
              :vs-name="'answer_' + currentQuestion"
              v-model="answers[currentQuestion].answers[0]"
              v-if="
                exam_prop.questions[currentQuestion].type.type ==
                'Single Choice'
              "
              color="primary"
              class="mt-2"
              ><span v-html="ans.description"></span
            ></vs-radio>
          </vs-col>

          <vs-row
            v-if="
              exam_prop.questions[currentQuestion].type.type == 'True or False'
            "
          >
            <vs-radio
              v-model="answers[currentQuestion].answers[0]"
              :vs-value="true"
              :vs-name="'answer_' + currentQuestion"
              color="primary"
              class="mt-2"
              ><span v-html="true"></span
            ></vs-radio>
          </vs-row>
          <vs-row
            v-if="
              exam_prop.questions[currentQuestion].type.type == 'True or False'
            "
          >
            <vs-radio
              v-model="answers[currentQuestion].answers[0]"
              :vs-value="false"
              :vs-name="'answer_' + currentQuestion"
              color="primary"
              class="mt-2"
              ><span v-html="false"></span
            ></vs-radio>
          </vs-row>
        </vs-row>
        <!-- <hr class="mt-4 mb-4" /> -->
        <div
          class="w-full mt-4"
          v-if="
            exam_prop.questions[currentQuestion].attachments[0].url &&
            exam_prop.questions[currentQuestion].attachments[0].url !=
              this.institutionAssetsLink
          "
        >
          <div v-if="renderExamFile">
            <single-file-previewer
              :fileUrl="exam_prop.questions[currentQuestion].attachments[0].url"
              :fileType="
                exam_prop.questions[currentQuestion].attachments[0].type
              "
              class="ml-1 my-4 mr-4"
            ></single-file-previewer>
          </div>
        </div>
      </vx-card>
      <div
        v-if="exam_prop.type.type != 'Paper'"
        class="
          flex
          w-full
          flex-wrap
          md:justify-between
          justify-center
          items-center
          md:flex-row
          flex-col
        "
      >
        <vs-button
          @click="currentQuestion--"
          v-if="currentQuestion > 0 && canMove"
          class="mt-5 mr-auto ml-0"
          type="border"
          >{{ $t("basic.Previous") }}
        </vs-button>

        <vs-button
          @click="validateSingleAnswer()"
          v-if="currentQuestion < exam_prop.questions.length - 1 && !canMove"
          class="mt-5 ml-auto mr-0"
          >{{ $t("basic.next") }}</vs-button
        >
        <vs-button
          @click="nextQuestion()"
          v-if="currentQuestion < exam_prop.questions.length - 1 && canMove"
          class="mt-5 ml-auto mr-0"
          >{{ $t("basic.next") }}</vs-button
        >
        <vs-button
          :disabled="isWordsOverLimit"
          @click="submit"
          v-if="currentQuestion == exam_prop.questions.length - 1"
          class="mt-5 ml-auto mr-0"
          >{{ $t("form.submit") }}</vs-button
        >
      </div>
      <div class="flex flex-wrap">
        <span v-if="!canMove">
          {{
            $t(
              "exam.note_you_will_not_be_able_to_get_back_to_previous_questions"
            )
          }}
        </span>
      </div>
      <div class="w-full mt-4 pb-8" v-if="exam_prop.type.type == 'Paper'">
        <vs-col
          class="my-1 flex-col"
          v-if="exam_prop.extra_doc_link"
          vs-justify="start"
          vs-lg="12"
          vs-sm="12"
          vs-type="flex"
          vs-xs="12"
        >
          <div class="w-full flex flex-row flex-wrap">
            <single-file-previewer
              :fileUrl="exam_prop.extra_doc_link"
              :fileType="exam_prop.extra_doc_type"
              class="ml-1 my-4 mr-4"
            ></single-file-previewer>
          </div>
        </vs-col>
        <vx-input-group class="w-full">
          <vs-input
            type="text"
            v-model="attachments[0].attachment_url"
            class="w-full"
            readonly
          />
          <template slot="append">
            <div class="append-text btn-addon">
              <vs-input
                type="file"
                @change="uploadExamDoc"
                accept="application/msword,application/pdf;capture=camera"
                v-model="file"
                class="w-full display_as_button"
              />
              <vs-button>
                <feather-icon
                  icon="UploadIcon"
                  svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
                />
                {{ $t("basic.upload") }}
              </vs-button>
            </div>
          </template>
        </vx-input-group>
        <div class="w-full">
          <vs-button
            :aria-disabled="disableSubmission"
            @click="submit"
            class="mt-5 ml-auto mr-0"
            >{{ $t("form.submit") }}</vs-button
          >
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import moment from 'moment'
import Axios from 'axios'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const ExamRepo = RepositoryFactory.get('ExamModule', 'Exam')

export default {
  name: 'exam-preview',
  props: {
    exam_prop: {
      type: Object,
      required: true
    },
    edit_mood: {
      type: Boolean
    }
  },
  data () {
    return {
      currentQuestion: 0,
      radio: null,
      singleChoice: null,
      renderExamFile: true,
      disableSubmission: false,
      isWordsOverLimit: false,
      questions: [],
      answers: [],
      attachments: [
        {
          attachment_url: ''
        }
      ],
      exam_end_time:null,
      before_exam_end_time:null

    }
  },
  created () {
    this.questions = this.exam_prop.questions
    this.computeAnswers()
    /*
    * This Code to check the end time of exam and force submit the answers
    * and notice the student before the exam with 5 minuts
    *
    * in date object you will notice ( - 1) in months only because index of month in js start from zero
    *
    * */
    const dateNow = new Date()
    let examEndDate = null
    let examBefor5Min = null
    const date = {
      fullYear:Number(this.exam_prop.date.slice(0, 4)),
      month:Number(this.exam_prop.date.slice(5, 7)) - 1,
      day:Number(this.exam_prop.date.slice(8, 10)),
      hours:Number(this.exam_prop.duration_fixed_time_end_time.slice(0, 2)),
      minutes:Number(this.exam_prop.duration_fixed_time_end_time.slice(3, 5)),
      seconds: 0
    }
    examEndDate = new Date(date.fullYear, date.month, date.day, date.hours, date.minutes - 1, 55)
    examBefor5Min = new Date(date.fullYear, date.month, date.day, date.hours, date.minutes - 5, date.seconds)
    const waitEndTime = examEndDate.getTime() - dateNow.getTime()
    const waitBefore5Min = examBefor5Min.getTime() - dateNow.getTime()
    if (waitEndTime >= 0) {
      this.exam_end_time = setTimeout(() => {
        this.errMsg(this.$t('exam.the_exam_time_has_been_finished_your_answers_submitted'))
        this.answerExam(true)
      }, waitEndTime)
    }
    if (waitBefore5Min >= 0) {
      this.before_exam_end_time = setTimeout(() => {
        this.warningMsg(this.$t('exam.there_are_only_5_min_to_finish_the_exam'))
      }, waitBefore5Min)
    }
  },
  beforeDestroy () {
    clearTimeout(this.exam_end_time)
    clearTimeout(this.before_exam_end_time)
  },
  computed: {
    exam_date () {
      return new Date(this.exam_prop.date)
        .toDateString()
        .split(' ')
        .slice(1, 4)
        .join(' ')
    },
    canMove () {
      return Boolean(
        this.exam_prop
          .allow_answering_questions_in_any_order_and_backword_forword
      )
    }
    // answers () {
    //   const answers = this.exam_prop.questions.map(q => {
    //     const obj = {
    //       id: q.id,
    //       answers: []
    //     }
    //     return obj
    //   })
    //   return answers
    // }
  },
  methods: {
    answerExam (forceSubmit = false) {
      const data = {
        date: moment().format('YYYY-MM-DD HH:mm'),
        questions: this.answers,
        attachments: this.attachments
      }
      ExamRepo.submitAnswer(this.exam_prop.id, data)
        .then(() => {
          if (forceSubmit) this.successMsg()
          if (this.exam_prop.is_auto_grading && this.exam_prop.show_result_to_student) {
            this.$router.push({
              name:'ExamGradeStudent',
              params:{
                id:this.exam_prop.id
              }
            })
          } else {
            this.$router.go(-1)
          }
        })
        .catch((err) => {
          this.errMsg(err)
        })
    },
    uploadExamDoc (e) {
      this.disableSubmission = true
      const data = new FormData()
      data.append('attachments[0]', e.target.files[0])
      const self = this
      Axios.post('common/upload-files', data).then((res) => {
        self.attachments = res
        this.$vs.notify({
          title: 'Success',
          text: 'Your document uploaded successfully',
          color: 'green'
        })
        this.successMsg(this.$t('form.your_document_uploaded_successfully'))
        this.disableSubmission = false
      })
    },
    uploadExamQuestionDoc (questionIndex, e) {
      const data = new FormData()
      data.append('attachments[0]', e.target.files[0])
      const self = this
      Axios.post('common/upload-files', data).then((res) => {
        self.amswers[questionIndex].attachments = res
        this.successMsg(this.$t('form.your_document_uploaded_successfully'))
        this.renderExamFile = false
        this.renderExamFile = true
      })
    },
    computeAnswers () {
      let answers = []
      if (this.exam_prop.questions) {
        answers = this.exam_prop.questions.map((q) => {
          const obj = {
            id: q.id,
            answers: []
          }
          return obj
        })
      }
      this.answers = answers
    },
    checkLength (event) {
      const words = event.split(' ').filter((item) => item !== '')
      const examLimit = this.exam_prop.questions[this.currentQuestion]
        .answer_words_limit
      if (examLimit) {
        let wordCount = 0
        for (let i = 0; i < words.length; i++) {
          if (words[i] !== '' && words[i] !== ' ') {
            wordCount++
          }
        }
        if (examLimit < wordCount) {
          this.isWordsOverLimit = true
          this.errMsg(`You Couldn't write more than ${examLimit} words`)
        } else {
          this.isWordsOverLimit = false
        }
      } else {
        this.isWordsOverLimit = false
        return false
      }
    },
    validateAllAnswers () {
      return new Promise(resolve => {
        let validate = true
        this.answers.forEach(element => {
          if (!element.answers.length) validate = false
        })
        resolve(validate)
      })
    },
    validateSingleAnswer () {
      let validate = true
      if (!this.answers[this.currentQuestion].answers.length) validate = false
      if (!validate) this.openConfirm()
      else if (!this.isWordsOverLimit) this.checkLastQuestion()
      else return false
    },
    notifyUnanswered () {
      this.warningMsg('Please, fill the missing answer first')
    },
    async submit () {
      let isValide
      if (this.canMove) {
        isValide = await this.validateAllAnswers()
        if (!isValide) {
          this.openConfirm()
        } else {
          this.answerExam()
        }
      } else {
        this.validateSingleAnswer()
      }
    },
    openConfirm () {
      this.$vs.dialog({
        type:'confirm',
        color: 'warning',
        title: 'Confirm',
        text: 'Are you sure you want to leave a unanswer question',
        accept: this.canMove ? this.answerExam : this.checkLastQuestion,
        cancel:this.notifyUnanswered,
        acceptText: 'Confirm'
      })
    },
    checkLastQuestion () {
      if (this.currentQuestion === this.answers.length - 1) {
        this.answerExam()
      } else {
        this.nextQuestion()
      }
    },
    nextQuestion () {
      if (!this.isWordsOverLimit) {
        this.currentQuestion++
      } else {
        return false
      }
    }

  },
  watch: {
    exam_prop () {
      this.computeAnswers()
    },
    currentQuestion () {
      this.renderExamFile = false
      setTimeout(() => {
        this.renderExamFile = true
      }, 100)
    }
  }

  // mounted () {
  //   this.$emit('setAppClasses', 'invoice-page')
  // }
}
</script>

<style lang="scss">
#invoice-page {
  h6 {
    font-size: 0.8rem;
    margin-bottom: 10px;
    b {
      text-transform: uppercase;
      color: #626262;
    }
  }
}
.answerView {
  p {
    display: inline;
  }
}
</style>
