<template>
  <div>
    <vs-card class="p-3 py-6 md:p-6 mx-auto">
      <div>
        <div class="vx-row w-full lg:w-4/5">
          <div class="vx-col w-full mt-8">
            <div class="vx-row">
              <h6 class="vx-col w-full md:w-1/4 my-2 lg:m-0 lg:w-1/5">
                {{ $t('basic.divisions') }}
              </h6>
              <p class="vx-col w-full md:w-3/4 my-2 lg:m-0 lg:w-4/5">
                {{ syllbus.divisionName }}
              </p>
            </div>
          </div>
          <div class="vx-col w-full mt-8">
            <div class="vx-row">
              <h6 class="vx-col w-full md:w-1/4 my-2 lg:m-0 lg:w-1/5">
                {{ $t('basic.grade') }}
              </h6>
              <p class="vx-col w-full md:w-3/4 my-2 lg:m-0 lg:w-4/5">
                {{ syllbus.gradeName }}
              </p>
            </div>
          </div>
          <div class="vx-col w-full mt-8">
            <div class="vx-row">
              <h6 class="vx-col w-full md:w-1/4 my-2 lg:m-0 lg:w-1/5">
                {{ $t('basic.subject') }}
              </h6>
              <p class="vx-col w-full md:w-3/4 my-2 lg:m-0 lg:w-4/5">
                {{ getSubjectName(details_ids.subject_id) }}
              </p>
            </div>
          </div>
        </div>
        <form @submit.prevent="submitForm" @keyup.enter="submitForm">
          <div class="vx-row w-full lg:w-4/5 mt-8 items-center">
            <h6 class="vx-col w-full sm:w-1/4 my-2 lg:m-0 lg:w-1/5">
              {{ $t('basic.date') }}
            </h6>

            <div class="vx-col lg:mb-0 mb-4 lg:w-2/5 w-full block">
              <flatPickr
                class="w-full"
                v-model="start_date"
                :config="config.config_start_date"
                @on-change="resetMinimumDate"
                :placeholder="$t('basic.startDate')" />
            </div>
            <div class="vx-col lg:w-2/5 w-full">
              <flatPickr
                class="w-full"
                v-model="end_date"
                :config="config.config_end_date"
                :placeholder="$t('basic.endDate')" />
            </div>
          </div>

          <div
            class="vx-row w-full lg:w-4/5 mt-8 items-center"
            v-for="(classroom, index) in classes"
            :key="index">
            <div class="vx-col w-full my-2 lg:m-0 lg:w-1/5">
              <h6>{{ classroom.name }}</h6>
            </div>
            <div class="vx-col w-full my-2 lg:m-0 lg:w-2/5">
              <vselect
                :options="all_teachers"
                :reduce="(option) => option.id"
                label="name"
                v-validate="'required'"
                v-model="classroom.teacher.teacher_id"
                :name="`teacher for ${classroom.name} `"
                :placeholder="$t('Syllabus.assign_teacher')" />
              <span class="text-danger mt-4 text-sm">{{
                errors.first(`teacher for ${classroom.name} `)
              }}</span>
            </div>
            <div class="vx-col w-full my-2 lg:m-0 lg:w-2/5">
              <vselect
                :options="all_teachers"
                :reduce="(option) => option.id"
                label="name"
                v-model="classroom.co_teacher.teacher_id"
                name="coteachers"
                @input="checkTeacherId(index)"
                :placeholder="$t('Syllabus.assign_Co_teacher')" />
              <span class="text-danger text-sm">{{
                errors.first('coteachers')
              }}</span>
            </div>
          </div>
          <div class="mt-12 flex justify-end">
            <vs-button @click.native="submitForm" class="mb-5">
              {{ $t('basic.submit') }}
            </vs-button>
          </div>
        </form>
      </div>
    </vs-card>
  </div>
  <!-- </modal> -->
</template>

<script>
import vSelect from 'vue-select'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'

const SyllabusRepository = RepositoryFactory.get('AcademicModule', 'syllabus')
const AssignTeacherRepository = RepositoryFactory.get(
  'AcademicModule',
  'assignTeacher'
)
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  components: {
    vselect: vSelect,
    flatPickr
  },
  data() {
    return {
      classroom: {},
      syllbus: {},
      classes: [],
      all_teachers: [],
      teacher_types: [],
      details_ids: {
        syllabus_id: Number(this.$route.params.id),
        subject_id: Number(this.$route.query.subject_id),
        division_id: null,
        grade_id: null
      },
      start_date: '',
      end_date: '',
      config: {
        config_start_date: {},
        config_end_date: {}
      }
    }
  },
  computed: {
    getSubjectName() {
      return (id) => {
        return this.syllbus.Subjects?.filter((subject) => subject.id === id)[0]
          .name
      }
    }
  },
  methods: {
    getSyllabus(id) {
      this.VSLoading()
      SyllabusRepository.get(id)
        .then((res) => {
          const data = res.data
          this.syllbus = data

          let termStartDate = null
          let termEndDate = null
          const haveTerms = data['terms'].length
          if (data.with_semester === 'with_semester') {
            if (haveTerms && data['terms'][0]['termsData'].length) {
              termStartDate = data['terms'][0]['termsData'][0]['start_date']
              termEndDate = data['terms'][0]['termsData'][0]['end_date']
            }
          } else {
            termStartDate = haveTerms ? data['terms'][0]['start_date'] : null
            termEndDate = haveTerms ? data['terms'].at(-1)['end_date'] : null
          }
          this.details_ids.division_id = data.division_id
          this.details_ids.grade_id = data.grade.id
          this.start_date = termStartDate
          this.end_date = termEndDate

          this.$set(this.config.config_start_date, 'minDate', termStartDate)
          this.$set(this.config.config_start_date, 'maxDate', termEndDate)
          this.$set(this.config.config_end_date, 'minDate', termStartDate)
          this.$set(this.config.config_end_date, 'maxDate', termEndDate)
        })
        .then(() => {
          this.getTeacherClasses()
        })
        .catch(this.errMsg)
    },
    getTeacherClasses() {
      const data = {
        ...this.details_ids
      }
      AssignTeacherRepository.getTeacherClasses(data)
        .then((res) => {
          const classes = res.data
          const classIds = []
          this.classes = classes.map((item) => {
            item.teacher = {
              teacher_id: null,
              teacher_type_id: null
            }
            item.co_teacher = {
              teacher_id: null,
              teacher_type_id: null
            }
            item.class_id = item.id
            classIds.push(item.id)
            delete item.id
            return item
          })
          this.getAssignedTeachers(classIds)
        })
        .catch((err) => {
          this.errMsg(err)
        })
        .finally(() => {
          this.stopVSLoading()
        })
    },
    resetMinimumDate(selectedDates, dateStr) {
      this.$set(this.config.config_end_date, 'minDate', dateStr)
    },
    getAllTeacher() {
      AssignTeacherRepository.getAllTeacher().then((res) => {
        // TODO: Remove Array's Filter after fix duplicated data from backend
        // TODO : it takes 25669ms => 26 second for 200 rows
        this.all_teachers = res.data.filter(
          (v, i, a) => a.findIndex((t) => t.id === v.id) === i
        )
      })
    },
    getTeacherTypes() {
      AssignTeacherRepository.getTeacherTypes().then((res) => {
        this.teacher_types = res.data
      })
    },
    getAssignedTeachers(classIds) {
      const data = {
        ...this.details_ids,
        class_ids: [...classIds]
      }
      AssignTeacherRepository.getAssignedTeachers(data).then((res) => {
        const data = res.data,
          startDate = res.data[0][classIds[0]][0].start_date,
          endDate = res.data[0][classIds[0]][0].end_date
        if (startDate) this.start_date = startDate
        if (endDate) this.end_date = endDate
        // if (startDate && endDate) {
        //   this.$set(this.config.config_start_date, 'minDate', startDate)
        //   this.$set(this.config.config_start_date, 'maxDate', endDate)
        //   this.$set(this.config.config_end_date, 'minDate', startDate)
        //   this.$set(this.config.config_end_date, 'maxDate', endDate)
        // }

        classIds.forEach((item) => {
          data.forEach((obj) => {
            if (obj[item] !== undefined) {
              const classArr = [...obj[item]]
              classArr.forEach((element) => {
                const classIndex = this.classes.findIndex(
                  (class_obj) => class_obj.class_id === item
                )
                if (
                  this.getTeacherType(element.teacher_type_id) === 'teacher'
                ) {
                  this.classes[classIndex].teacher.teacher_id =
                    element.teacher_id
                  this.classes[classIndex].teacher.teacher_type_id =
                    element.teacher_type_id
                } else {
                  this.classes[classIndex].co_teacher.teacher_id =
                    element.teacher_id
                  this.classes[classIndex].co_teacher.teacher_type_id =
                    element.teacher_type_id
                }
              })
            }
          })
        })
      })
    },
    getTeacherTypeId(type) {
      return this.teacher_types.find((item) => item.flag === type).id
    },
    getTeacherType(id) {
      return this.teacher_types.find((item) => item.id === id).flag
    },
    mappingData() {
      this.classes.forEach((item) => {
        item.teacher.teacher_type_id = this.getTeacherTypeId('teacher')
        item.co_teacher.teacher_type_id = this.getTeacherTypeId('co-teacher')
      })
      const data = {
        ...this.details_ids,
        start_date: this.start_date,
        end_date: this.end_date,
        data: [...this.classes]
      }
      return data
    },
    checkTeacherId(index) {
      const teacherId = this.classes[index].teacher.teacher_id,
        coTeacherId = this.classes[index].co_teacher.teacher_id

      if (teacherId === coTeacherId) {
        this.classes[index].co_teacher.teacher_id = ''
        this.errMsg()
      }
    },
    submitForm() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          const payload = this.mappingData()
          this.assignTeacher(payload)
        } else {
          this.errMsg()
        }
      })
    },
    assignTeacher(data) {
      AssignTeacherRepository.assignTeacher(data)
        .then((res) => {
          this.successMsg(res.message)
          this.$router.push({
            name: 'syllabus_assign_teacher',
            params: {
              id: this.details_ids.syllabus_id
            }
          })
        })
        .catch(this.errMsg)
    }
  },
  created() {
    this.getSyllabus(this.details_ids.syllabus_id)
    this.getTeacherTypes()
    this.getAllTeacher()
  }
}
</script>

<!--
<style lang="scss" src="./assign-teacher-modal.scss"></style>
<script src="./assign-teacher-modal.js"></script>
-->
