<template >
  <vx-card>
    <vs-dropdown
      class="actionButtons text-capitalize cursor-pointer"
      vs-custom-content
      vs-trigger-click
    >
      <vs-button color="#999" type="flat" icon="more_vert"></vs-button>
      <vs-dropdown-menu>
        <vs-dropdown-item @click="goToEdit">
          <feather-icon icon="EditIcon" svgClasses="h-4 w-4 mr-1" />{{
            $t("basic.edit")
          }}
        </vs-dropdown-item>
        <!-- <vs-dropdown-item @click="goToShow">
            <feather-icon icon="EyeIcon" svgClasses="h-4 w-4 mr-1" />{{
              $t("basic.show")
            }}</vs-dropdown-item
          > -->
        <vs-dropdown-item
          @click="changeStatus('active')"
          v-if="showActiveAction"
        >
          <feather-icon icon="CheckIcon" svgClasses="h-4 w-4 mr-1" />
          {{ $t("status.active") }}
        </vs-dropdown-item>
        <vs-dropdown-item
          @click="changeStatus('archive')"
          v-if="showArchiveAction"
        >
          <feather-icon icon="ArchiveIcon" svgClasses="h-4 w-4 mr-1" />
          {{ $t("status.archived") }}</vs-dropdown-item
        >
        <vs-dropdown-item
          @click="changeStatus('closed')"
          v-if="round.time_status !== 'closed'"
        >
          <feather-icon icon="SlashIcon" svgClasses="h-4 w-4 mr-1" />
          {{ $t("status.close") }}
        </vs-dropdown-item>
      </vs-dropdown-menu>
    </vs-dropdown>
    <div class="vx-row w-full items-center">
      <div class="w-full vx-col lg:w-1/4 lg:mb-0 mb-2">
        <h5>{{ round.title }}</h5>
      </div>
      <div class="w-full vx-col lg:w-1/6 md:w-1/3 lg:mb-0 mb-2">
        <p>
          <span>{{ roundGrades }}</span>
        </p>
      </div>
      <div class="w-full vx-col lg:w-1/4 md:w-1/3 md:mb-0 mb-2">
        <p class="flex items-center">
          <feather-icon icon="UserIcon" svgClasses="h-4 w-4 mr-1" />
          <span class="mx-2"> {{ round.user_name }} </span>
        </p>
      </div>
      <div class="w-full vx-col lg:w-1/3 md:w-1/3 md:mb-0 mb-2">
        <p class="flex items-center justify-between">
          <span class="flex items-center">
            <feather-icon icon="CalendarIcon" svgClasses="h-4 w-4 mr-1" />
            <span class="mx-2 text-sm">
              {{ round.start_date }} <br />
              {{ round.end_date }}
            </span>
          </span>
          <span :class="`status--${roundStatus} mb-0 lg:ml-2 text-sm block`">{{
            roundStatus === "archive" ? $t("status.archived") : roundStatus
          }}</span>
        </p>
      </div>
    </div>
  </vx-card>
</template>
<script>
export default {
  name:'grading-round-card',
  props:['round'],
  computed:{
    roundGrades () {
      const grades = this.round.grading_management
      return grades.map(e => `${e.division_name} - ${e.section_name} - ${e.grade_name}`).join(',')
    },
    showArchiveAction () {
      return this.round.status !== 'archive' && this.round.status !== 'closed'
    },
    roundStatus () {
      return this.round.status === 'archive' ? this.round.status : this.round.time_status
    },
    showActiveAction () {
      return this.round.time_status === 'archive' || this.round.status === 'archive'
    }
  },
  methods: {
    goToEdit () {
      this.$router.push({name:'gradingEditRound', params:{id:this.round.id}})
    },
    goToShow () {
      this.$router.push({name:'showround', params:{id:this.round.id}})
    },
    changeStatus (newStatus) {
      this.$emit('changeStatus', {id:this.round.id, newStatus})
    }
  }
}
</script>

