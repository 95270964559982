<template>
  <category-form @formSubmited = "addCategory"/>
</template>

<script>
import categoryForm from '@/modules/canteen/pages/category/components/categoryForm'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const canteenRepository = RepositoryFactory.get(
  'canteenRepository',
  'canteenRepository'
)
export default {
  name: 'create',
  components:{
    categoryForm
  },
  data () {
    return {
      canteenId: this.$route.params.id
    }
  },
  methods: {
    addCategory (payload) {
      canteenRepository.addNewCategory(this.canteenId, payload).then(() => {
        this.successMsg(this.$t('canteen.addCategorySuccessfully'))
        this.$router.go(-1)
        this.stopVSLoading()
      }).catch((err) => {
        this.errMsg(err)
      })
    }
  }
}
</script>

<style scoped>

</style>
