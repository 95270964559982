import axios from 'axios'

const resource = '/parent'
export default {
  getAllParents () {
    return axios.get(`${resource}`)
  },
  search (data) { // search by username , email or Id of student
    return axios.post(`parent/search?search=${data}`)
  }
}
