<template>
  <vx-card class="mt-4">
    <vs-table
      :data="data"
      :search="Boolean(data.length)"
      id="gradebook"
      :hoverFlat="true"
      v-if="showTable"
    >
      <!-- Header -->
      <template slot="header" v-if="Boolean(data.length)">
        <vs-row class="items-center">
          <vs-col
            s-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="3"
            vs-sm="12"
          >
            <vs-button
              type="border"
              @click="exportCSV($t('grading.summery'), 'summery')"
              >{{ $t("main.Export") }} CSV
            </vs-button>
          </vs-col>
        </vs-row>
      </template>
      <template slot="thead" v-if="Boolean(data.length)">
        <vs-th
          v-for="(tr, indextr) in headers"
          :key="indextr"
          :sort-key="tr.field_name"
          :class="{
            table__header: tr.title,
            'text-right': tr.field_name === 'name',
            'text-center': tr.field_name !== 'name',
          }"
          class="text-sm"
        >
          {{ tr.title }}
        </vs-th>
      </template>
      <!-- Rows -->
      <template slot-scope="{ data }" v-if="Boolean(data.length)">
        <vs-tr
          v-for="(tr, indextr) in data"
          :data="tr"
          :key="indextr"
          :class="{ 'resource-info-row': Boolean(tr.resourceInfo_field) }"
          class="text-sm"
        >
          <vs-td
            v-for="(row_data, field_name, indx) in tr"
            :key="indx"
            :class="setCellClass(tr, field_name, indextr, row_data)"
          >
            <!-- Data -->
            <span>
              {{ row_data }}
            </span>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <template v-else>
      <p v-if="!selectedSubject.id" class="text-center">
        {{ $t("grading.select_the_subject_first") }}
      </p>
      <!-- Loader -->
      <div
        ref="tableLoader"
        id="tableLoader"
        class="vs-con-loading__container"
        v-if="loadingTable"
      ></div>
    </template>
  </vx-card>
</template>
<script>
// mixins

// Repos
import {
  RepositoryFactory
} from '@/Repositories/RepositoryFactory'
const GradebookRepo = RepositoryFactory.get('GradingModule', 'gradebook')

// plugins
import moment from 'moment'
import gradingMixins from '../../grading.mixins'

export default {
  name:'summeryTable',
  props:{

    selectedSubject:{
      type:Object, // name,id
      required:true
    }

  },
  mixins:[gradingMixins],
  data () {
    return {
      /////////////////////
      //  Table
      /////////////////////
      headers:[
        {
          title:'ID',
          field:'student_id'
        },
        {
          title:'name',
          field:'name'
        }
      ],
      selectedStudent:{},
      data:[],

      selectedFilter:[],
      loadingTable:false,
      /////////////////////
      // Data
      /////////////////////
      resources:[],
      possiblePoints:{},
      avgPoints:[]

    }
  },
  watch:{

    'selectedSubject.id' () {
      this.getGradingsOfSubject()
    }
  },
  computed:{
    showTable () {
      return Boolean(this.selectedSubject) && Boolean(this.selectedSubject.id) && !this.loadingTable
    }
  },
  methods: {
    /////////////////////////
    // Loading
    /////////////////////////
    // Table Data
    async startLoadingTable () {
      await (this.loadingTable = true)
      this.VsLoadingContained(this.$refs.tableLoader)

    },
    endLoadingTable () {
      this.stopVsLoadingContained(this.$refs.tableLoader)
      this.loadingTable = false
    },
    /////////////////////////
    // APIs
    /////////////////////////
    // getGradesB of Class
    getGradingsOfSubject () {
      // ---- Abort
      if (!this.selectedClass?.id || !this.selectedSubject.id) return

      // --- Continue
      this.resetMainData()
      this.startLoadingTable()
      GradebookRepo.getGradingsOfSubject(this.selectedClass.id, this.selectedSubject.id)
        .then(res => {
          this.handleAllData(res)
        })
        // .catch(this.errMsg)
        .finally(this.endLoadingTable)
    },
    /////////////////////////
    // Table Helpers
    /////////////////////////
    generateResourcesRow (key, arr, extraText = '') {
      if (!arr.length) return
      const obj = {}
      arr.map((r) => {
        obj[`${r.key}${extraText ? `_${extraText}` : ''}`] = r[key]
      })
      return obj
    },


    /////////////////////////
    // Handling
    /////////////////////////
    handleAllData (data) {
      this.allData = data
      this.students = this.allData.students.sort((a, b) => a.student_id - b.student_id)
      this.possiblePoints = this.allData.possible_points
      for (const key in data.possible_points) {
        this.possiblePoints[key.replace('grading_type_detail_', '')] = data.possible_points[key]
      }
      this.avgPoints = this.allData.class_grading_avg_points
      this.gradings = this.allData.gradings
      this.resources.map(f => {
        f.possiblePoints = this.possiblePoints[f.key]
        const classGradingAvgPoints = this.avgPoints.find(e => e.resource_type === f.key)
        f.avgPoints = classGradingAvgPoints ? classGradingAvgPoints.avg_total_score : '-'
      })
      this.handleTableData()
    },

    handleResourcesInfoRow () {
      const
        rightEmptyCells = this.emptyCells(2, true),
        leftEmptyCells = this.emptyCells(1),
        resourcesHeader = {
          ...leftEmptyCells,
          resourceInfo_field:this.$t('basic.type'),
          ...this.generateResourcesRow('name', this.resources),
          ...rightEmptyCells
        },
        resourcesHeaderArr = () => {
          const arr = []
          for (const key in resourcesHeader) {
            if (Object.hasOwnProperty.call(resourcesHeader, key)) {
              const el = resourcesHeader[key]
              arr.push({title:el, field_name:key.includes('resourceInfo_field') ? 'name' : `${key}_score`})
            }
          }
          return arr
        },

        resourcesInfoTable =
        [
          // points row
          {
            ...leftEmptyCells,
            resourceInfo_field:this.$t('grading.possible_points'),
            ...this.generateResourcesRow('possiblePoints', this.resources),
            ...rightEmptyCells
          },
          // average points row
          {
            id_field:this.$t('basic.ID'),
            resourceInfo_field:this.$t('grading.average_points'),
            ...this.generateResourcesRow('avgPoints', this.resources),
            rank_field:this.$t('grading.rank'),
            totalScore_field:this.$t('grading.total')
          }
        ]


      this.headers = resourcesHeaderArr()

      return resourcesInfoTable
    },
    // Students
    handleStudentsData () {

      const
        getGrade = (resourceType, userId) => this.gradings.find(g => g.resource_type.includes(resourceType) && g.user_id === userId),

        // map resources for sorting
        sortGradesStudentResources = (userId) => {
          const
            data =  this.resources.map(s => {
              try {
                const grade = getGrade(s.key, userId)

                return {
                  score: Boolean(!grade) || (grade.total_score ===  null)  ? 'N/A' :  grade.total_score,
                  key:s.typeFlag || s.key
                }


              } catch (err) {
                console.error(err)
              }

            })
          return data
        },
        generateScoresByUserId = (userId) => {
          try {
            return this.generateResourcesRow('score', sortGradesStudentResources(userId), 'score')
          } catch (err) {
            console.error(err)
          }

        },
        data = this.students.map(s => {
          return {
            id:s.code,
            name:s.name,
            ...generateScoresByUserId(s.user_id),
            rank:s.rank ? `${s.rank }/${this.students.length}` : 0,
            total_score:s.total_score || 0
          }
        })

      return data
    },

    // Handle Table Data Rows
    handleTableData () {
      this.data = this.StudentGrades = [
        ...this.handleResourcesInfoRow(),
        ...this.handleStudentsData()
      ]

    },
    // handle types
    handleResourcesTypes () {
      this.resources = [
        {
          name:this.$t('basic.exams'),
          key:'exam'

        },
        {
          name:this.$t('basic.tasks'),
          key:'assessment'

        },
        {
          name:this.$t('basic.assignments'),
          key:'assignment'

        }
        // ...this.resourcesTypes.filter(r => !r.key.includes('exam') && r.key !== 'task')
      ]
    }


  },
  created () {
    if (this.selectedSubject.id) this.getGradingsOfSubject()
    this.getTypes()
    this.handleResourcesTypes()
  }

}
</script>
<style lang="scss">
@import "./gradingTable.scss";

.vs-popup #gradebook .vs-con-tbody {
  max-height: 58vh;
}
</style>
