<template>
  <ListPageWithCreateButton :showCreateBtn="false">
    <template slot="listDataContent">
      <reportCardTable
        :loading="loading"
        :list_url_prop="`grading/inventories/user-list?user=${userId}`"
        :headers_prop="headers">
        <template></template>
      </reportCardTable>
    </template>
  </ListPageWithCreateButton>
</template>

<script>
import reportCardTable from '@/components/general/datatable/DataTable2.vue'
import {commonDataMixin} from '@/mixins'

export default {
  name: 'all-report-card',
  components: {
    reportCardTable
  },
  props: {
    student: {
      type: Object,
      required: true
    }
  },
  mixins: [commonDataMixin],
  data() {
    return {
      headers: [
        {
          minWidth: 150,
          headerName: this.$t('basic.academic_year'),
          field: 'academic_year.name'
        },
        {
          minWidth: 150,
          headerName: this.$t('basic.division'),
          field: 'student.division.name'
        },
        {
          minWidth: 150,
          headerName: this.$t('basic.class'),
          field: 'student.class.name'
        },
        {
          minWidth: 150,
          headerName: this.$t('Syllabus.terms'),
          field: 'term'
        },
        {
          minWidth: 150,
          headerName: this.$t('basic.type'),
          field: 'type'
        },
        {
          minWidth: 150,
          headerName: this.$t('basic.status'),
          field: 'status',
          cellRendererFramework: 'CellRendererStatus',
          cellRendererParams: {
            status_prop: this.status
          }
        },
        {
          minWidth: 200,
          headerName: this.$t('form.file'),
          title: this.$t('form.file'),
          field: 'transactions',
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            actions_prop: [
              {
                title: this.$t('form.file'),
                icon: 'DownloadIcon',
                //navigate_action: true
                color: 'danger',
                actionEmitter: 'download',
                showIf: (data) => data.status !== 'banned',
                actionEmitterParams: ['media_url']
              }
            ]
          }
        }
      ],
      loading: false
    }
  },
  computed: {
    userId() {
      return this.student.user_id || this.$route.params.child_id
    }
  },
  methods: {},
  created() {
    this.$root.$on('download', (data) => this.downloadFile(data.media_url))
  },
  beforeDestroy() {
    this.$root.$off('download')
  },
  watch: {}
}
</script>
