export default {
  S: 'S',
  Round: 'Round',
  TotalDays: 'Total Days',
  From: 'From',
  To: 'To',
  CreatedBy: 'Created By',
  Contact: 'Contact',
  Status: 'Status',
  Actions: 'Actions',
  ShowSSR: 'Show SSR',
  UploadPhotoMask: 'Upload Photo Mask',
  SetSubmissionTimeframe: 'Set Submission Time frame',
  ShowEarlyBid: 'Show Early Bid',
  GreetingMessage: 'Greeting Message',
  ShowFees: 'Show Fees',
  ShowMedicalSurvey: 'Show Medical Survey',
  ScoreManagement: 'Score Management',
  ScoreSchema: 'Select Score Schema',
  Features: 'Features',
  AllSchools: 'All Schools',
  AllGrads: 'All Grades',
  AllDivisions: 'All Divisions',
  Start: 'Start',
  End: 'End',
  ChooseSchool: 'Choose School',
  RoundDates: 'Round Dates',
  ChooseDivision: 'Choose Division',
  ChooseGrads: 'Choose Grades',
  RoundManager: 'Round Manager',
  ContactPerson: 'Contact Person',
  RoundContacts: 'Round Contacts'
}
