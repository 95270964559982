import axios from 'axios'
const resource = '/grading/report-card'

export default {
  createReportCard(payload) {
    /* payload
    {
    "user_id":1615,
    "grading_round_id":4,
    "total_score":9,
    "subject_id":7,
    "total_score_percentage":15,
    "boundary_id":1,
    "criteria":["O"] // for only myp defult []
}*/
    return axios.post(`${resource}`, payload)
  },
  uploadReportCarde(gradingRoundId, payload) {
    /* payload
     * formData ( user_id , file)
     */
    return axios.post(`${resource}/${gradingRoundId}`, payload)
  },
  getStudentAllFinalOutcome(userId, gradingRoundId) {
    return axios.get(`${resource}/student/${userId}/${gradingRoundId}`)
  },
  getReportCardDataOfSubject(userId, gradingRoundId, subjectId) {
    return axios.get(
      `${resource}/data/${userId}/${gradingRoundId}/${subjectId}`
    )
  },
  getATLOptions() {
    return axios.get(`${resource}/atl_option`)
  },
  getATLCategories() {
    return axios.get(`${resource}/atl_category`)
  },
  saveTeacherComment(payload) {
    // payload :{user_id,round_id,subject_id,note}
    return axios.post(`${resource}/note/teacher`, payload)
  },
  saveCoordinatorComment(payload) {
    // payload :{user_id,round_id,note}
    return axios.post(`${resource}/apply/note/section_coordinator`, payload)
  },
  saveATLUpdates(payload) {
    /*
    {
    "user_id":244,
    "round_id":6,
    "subject_id":1,
    "atl_data":[
        {
            "atl_category_id":1,
            "atl_option_id":3
        }
      ]
    }
    */

    return axios.post(`${resource}/atl_data`, payload)
  },
  saveIDUUpdates(payload) {
    /*
    payload
     "user_id":244,
    "round_id":6,
    "subject_id":1,
    "data":[
        {
            "name":"asddddd",
            "comment":"dddd",
            "type":"IDU",
            "a":5,
            "b":4,
            "c":3,
            "d":6,
            "final_grade":7
        }]}
        */
    return axios.post(`${resource}/idu_data`, payload)
  },
  saveParticipationUpdates(payload) {
    /*
    payload
     "user_id":244,
    "round_id":6,
    "subject_id":1,
    participation_grade_id:2
        */
    return axios.post(`${resource}/participation-grade`, payload)
  },
  getParticipationOptions() {
    return axios.get(`${resource}/participation-grade`)
  },
  getCASOverallOptions() {
    return axios.get('grading/cas-tok-ee/cas-overall')
  },
  getCASPlannedOptions() {
    return axios.get('grading/cas-tok-ee/planned-completed')
  },
  saveCASUpdates(payload) {
    /**
    {
    "user_id":1615,
    "round_id":9,
    "cas_progress_id":1,
    "cas_overall_progress_id":3,
    "cas_planned_outcome_id":1,
    "cas_completed_outcome_id":2,
    "cas_creativity_hour":"19:41:29",
    "cas_activity_hour":"17:41:29",
    "cas_service_hour":"16:41:29",

    "cas_comment":"999999999",
    "cas_coordinator":"hebaaaa",
    "extended_essay_title":"uuuu",
    "extended_essay_supervisor":"ooooooo",
    "tok_paper_title":"iuuui",
    "tok_paper_supervisor":"rrrrr"

      }
    */
    return axios.post('grading/cas-tok-ee/', payload)
  },
  saveReportCardSubjectStrands(payload) {
    /*
    * Payload: {
    "grading_round_id":4,
    "subject_id":1,
    "user_id":1627,
    "strands":[
        {
            "strand_id":1,
            "assement":"vvvvv",
            "other_assement":"kkk"
        }
    ]
}*/
    return axios.post(`${resource}/store-strand-assesment`, payload)
  },

  savePOI(payload) {
    /*
     * payload :{
        round_id
      user_id
      subject_id
      units : [1, 2,3]
     */
    return axios.post(`${resource}/unit-academic`, payload)
  },
  getPOIUnitsOfRoundGrade(grade_id, round_id) {
    return axios.get(`${resource}/${grade_id}/${round_id}/unit-academic`)
  },
  savePOIUnitsOfRoundGrade(grade_id, round_id, units) {
    return axios.post(`${resource}/${grade_id}/${round_id}/unit-academic`, {
      unit_academic_ids: units
    })
  },
  getClassFinalOutcomesofSubject(classId, roundId, subjectId) {
    return axios.get(`${resource}/classes/${classId}/${roundId}/${subjectId}`)
  }
}
