export default {
  exam_settings: 'Exam Settings',
  basic_information: 'basic information',
  questions: 'Questions',
  question: 'Question',
  questions_type: 'questions type',
  questions_answers_type:'questions and answers type',
  preview: 'Preview',
  students_missed_the_exam:'students missed the exam',
  all_students_attended: 'all students attended',
  show_grades_and_insights: 'Show Grades and Insights',
  days_to_start: 'Days to Start',
  question_form:'question form',
  allocate_more_time_to_this_question: 'Allocate more time to This question',
  Force_respondent_to_answer_this_question_when_first_time_displayed: 'Force respondent to answer this question when first time displayed',
  exam_allotted_time_is_30_minutes: 'Exam allotted time is 30 minutes',
  allocate_more_points_to_this_question:'Allocate more points to this question ',
  student_achievement: 'Student achievement ',
  exam_no:'Exam NO.',
  exam_date: 'Exam date',
  exam_is_saved: 'Exam Is saved',
  exam_is_archived:'Exam Is archived',
  exam_is_unarchive: 'Exam Is unarchive',
  CreateExam: 'Create Exam',
  EditExam: 'Edit Exam',
  digital_answer: 'digital answer',
  auto_grading: 'Auto Grading',
  grading_type: 'Grading type',
  manual_grading: 'Manual grading',
  exam_will_run_in_the_exact_selected_time:'Exam will run in the exact selected time',

  exam_will_run_at_anytime_in_the_selected_date:'Exam will run at anytime in the selected date.',

  exam_is_open_to_solve_at_anytime_starting_from_the_start_date_for_07_academic_days:'exam is open to solve at anytime starting from the start date for 07 academic days',
  access_attendees:'Access & Attendees',
  questions_manager:'Questions Manager',
  exam_info: 'exam info',
  exam_access_type: 'Exam access Type',
  choose_exam_attendees: 'Choose Exam attendees',
  use_same_access_for_the_students: 'Use same access for the students',
  create_private_access_code: 'Create private access code',
  hide_students_identities: 'Hide Students identities',
  students_will_access_the_exam_during: 'Students will access the exam during',
  after_exam_start_time: 'after exam start time',
  if_disabled_the_exam_will_let_students_access_the_exam_in_5_minutes_after_exam_start_tim:'if disabled, the exam will let students access the exam in 5 minutes after exam start time',
  this_feature_is_not_valid_for_the_exam_with_fixed_period:'This feature is not valid for the exam with fixed period ',
  exam_name:'Exam Name',
  auto_exam_name:'Auto Exam Name',
  generate_auto_name:'Generate Auto Name',
  reset_to_manual_name: 'reset to manual Name',
  choose_display_language: 'Choose Display Language',
  randomize_questions: 'Randomize questions',
  has_assessment: 'has assesment',
  show_results_to_student: 'Show results to  student',
  show_results_to_parent: 'Show results to  parent',

  lockdown_functionality: 'lockdown functionality',

  show_attempt_counts: 'Show attempt counts ',

  allow_review_answers_after_finishing_the_test: 'Allow review answers after finishing the test',

  set_passing_score: 'Set passing score ',
  present_all_questions_of_the_exam_to_the_student:'Present All questions of the exam to the student',
  allow_answering_questions_in_any_order_and_jumping_back_and_forth_between_questions:'Allow answering questions in any order and jumping back and forth between questions.',
  set_instructions_for_the_exam: 'Set Instructions for the exam',
  upload_extra_document: 'Upload extra document',
  give_title_for_the_question:'Give title for the question',
  edit_question_title: 'edit question title',
  add_from_question_bank: 'Add from question bank',
  answer_limit: 'answer limit',
  customize_number_of_words: 'Customize number of words',
  open_limit: 'open limit',
  question_bank: 'question bank',
  add_question_to_my_questions_bank:'Add question to my questions bank',
  none_of_them :'none of them'
}
