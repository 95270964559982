import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import {AgGridVue} from 'ag-grid-vue'
import SettingsModal from '../../modals/setting/setting.vue'
import ActionsCellRender from '../cellRender/cell-render.vue'

export default {
  name: 'GridWrapper',
  components: {
    AgGridVue,
    SettingsModal,
    ActionsCellRender
  },
  props: ['gridData', 'paginationData'],
  data () {
    return {
      gridSettings_test: {
        columnDefs: [
          {headerName: 'Make', field: 'make'},
          {headerName: 'Model', field: 'model'},
          {headerName: 'Price', field: 'price'},
          {headerName: 'Company', field: 'company'},
          {headerName: 'Release Date', field: 'release_data'},
          {
            headerName: 'Action',
            field: 'value',
            renderActions: {
              type: 'span',
              text: '',
              eventName: 'action',
              iconClass: ['feather', 'icon-edit'],
              inlineStyle: 'font-size:20px; color:#968cf4;'
            },
            cellRenderer: this.cellFunction
          }

        ],
        selectedColumns: null,
        rowData: [
          {make: 'Toyota', model: 'Celica', price: 35000, company: 'BMW', release_data: '2011', value: ''},
          {make: 'Ford', model: 'Mondeo', price: 32000, company: 'Toyota', release_data: '2015', value: ''},
          {make: 'Porsche', model: 'Boxter', price: 72000, company: 'Hunda', release_data: '2020', value: ''}
        ],
        compactViewColumns: ['make', 'price', 'model', 'release_data'],
        standardViewColumns: ['make', 'price', 'model', 'value'],
        frameworkComponents: {
          ActionsCellRender
        }
      },
      gridSettings: {},
      columnDefs: null,
      rowData: null,
      currentView: 'standard',
      gridApi: null,
      columnApi: null,
      components:{
        ActionsCellRender
      },
      customEvents: [
        {eventName: 'loadGridSettingsModalData', callback: this.changeSettings},
        {eventName: 'action', callback: this.cellActions}
      ],
      currentPage:1
    }
  },
  watch:{
    currentPage (v) {
      this.$emit('paginate', v)
    }
  },
  created () {
    ////////////////////////////////////////////////////
    //**          Custom Event Listener           ** //
    //////////////////////////////////////////////////
    this.customEvents.forEach(function (customEvent) {
      // eslint-disable-next-line no-undef
      $sharedEvent.listen(customEvent.eventName, customEvent.callback)
    })

    this.mapCellFunction()
  },
  beforeMount () {
    this.loadGridData(this.gridData)
  },
  mounted () {

  },
  destroyed () {
    ////////////////////////////////////////////////////
    //**      Remove Custom Event Listener        ** //
    //////////////////////////////////////////////////
    this.customEvents.forEach(function (customEvent) {
      // eslint-disable-next-line no-undef
      $sharedEvent.off(customEvent.eventName, customEvent.callback)
    })
  },
  methods: {
    mapCellFunction () {
      this.gridSettings = this.gridData
      this.gridSettings.columnDefs.forEach(item => {
        if (item.cellRenderer === 'cellFunction') {
          item.cellRenderer = this.cellFunction
        }
      })
    },
    onGridReady (params) {
      this.gridApi = params.api
      this.columnApi = params.columnApi
    },
    standardView () {
      this.columnDefs = this.gridSettings.columnDefs.filter(item => {
        if (this.gridSettings.standardViewColumns.includes(item.field)) {
          return item
        }
      })
      this.currentView = 'standard'
      this.gridSettings.selectedColumns = this.columnDefs
    },
    compactView () {
      this.columnDefs = this.gridSettings.columnDefs.filter(item => {
        if (this.gridSettings.compactViewColumns.includes(item.field)) {
          return item
        }
      })
      this.currentView = 'compact'
      this.gridSettings.selectedColumns = this.columnDefs
    },
    detailedView () {
      this.columnDefs = this.gridSettings.columnDefs
      this.currentView = 'detailed'
      this.gridSettings.selectedColumns = this.columnDefs
    },
    loadGridData (data = null) {
      this.rowData = this.gridSettings.rowData
      this.rowData.forEach(item => {
        item.guid = this.uniqueID()
      })
      this.standardView()
      this.setPagination(this.paginationData)
    },
    openModal () {
      // eslint-disable-next-line no-undef
      $sharedEvent.fire('openGridSettingsModal', this.gridSettings)
    },
    changeSettings (data) {
      this.gridSettings = data
      this.columnDefs = this.gridSettings.selectedColumns
      this.rowData = this.gridSettings.rowData
    },
    test () {
      this.columnDefs.push({headerName: 'Release Dare', field: 'release_data'})
    },
    hide () {
      this.columnApi.setColumnsVisible(['price'], false) //In that case we show them
    },
    cellActions (data) {
      // alert(JSON.stringify(data))
    },
    cellFunction (params) {

      const container = document.createElement('div')
      container.classList.add('actions-container')

      //** loop over actions to set prop on it **//
      params.colDef.renderActions.forEach(action => {

        //** Set visibility based on role **//
        if (action.visibilityRole && typeof (action.visibilityRole) === 'function') {

          const data = params.data[params.colDef.field]

          if (action.visibilityRole(data)) {
            const element = document.createElement(action.type)

            action.attributes.forEach(att => {
              const value = (att.appendField) ? params.data[params.colDef.field] : att.value
              element.setAttribute(att.key, value)
            })

            //** Set Class list **//
            if (action.classList.length) {
              element.classList.add(...action.classList)
            }

            //** Set Class list based on role **//
            if (action.classRole && typeof (action.classRole) === 'function') {
              const data = params.data[params.colDef.field]
              element.classList.value = action.classRole(data)
            }

            //** render field value text instead of text **//
            if (action.renderFieldValue) {
              element.innerText = params.data[params.colDef.field]
            } else {
              //** render text instead of field value **//
              element.innerText = (action.text) ? action.text : ''
            }

            //** set event on element to fire custom event when event fired **//
            if (action.hasEvent && action.hasEvent.eventType && action.hasEvent.eventName) {
              element.addEventListener(action.hasEvent.eventType, function (event) {
                // eslint-disable-next-line no-undef
                $sharedEvent.fire(action.hasEvent.eventName, {data:params.data, event})
              })
            }

            container.appendChild(element)
          }
        } else {
          //** Show Action all time If visibilityRole Not found or set **//
          const element = document.createElement(action.type)

          action.attributes.forEach(att => {
            const value = (att.appendField) ? params.data[params.colDef.field] : att.value
            element.setAttribute(att.key, value)
          })

          //** Set Class list **//
          if (action.classList.length) {
            element.classList.add(...action.classList)
          }

          //** Set Class list based on role **//
          if (action.classRole && typeof (action.classRole) === 'function') {
            const data = params.data[params.colDef.field]
            element.classList.value = action.classRole(data)
          }

          //** render field value text instead of text **//
          if (action.renderFieldValue) {
            element.innerText = params.data[params.colDef.field]
          } else {
            //** render text instead of field value **//
            element.innerText = (action.text) ? action.text : ''
          }

          //** set event on element to fire custom event when event fired **//
          if (action.hasEvent && action.hasEvent.eventType && action.hasEvent.eventName) {
            element.addEventListener(action.hasEvent.eventType, function (event) {
              // eslint-disable-next-line no-undef
              $sharedEvent.fire(action.hasEvent.eventName, {data:params.data, event})
            })
          }
          container.appendChild(element)
        }
      })
      return container
    }
  }
}
