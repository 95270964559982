<template>
  <div class="w-full mb-10">
    <!-- Start Card Count  -->
    <template>
      <div class="w-full">
        <StatusCounter :status_prop="status" ref="status_counter" />
      </div>
    </template>

    <div class="vx-row mb-3">
      <!-- End Card Count  -->
      <div class="vx-col lg:w-3/4 w-full">
        <transition-group class="list-transition" name="list-enter-up" tag="ul">
          <li
            v-for="(material, index) in materials"
            :key="index"
            :style="[{ transitionDelay: index * 0.1 + 's' }]"
          >
            <matrial-card
              :materialProp="material"
              :getTeachingMaterialListStudents="getTeachingMaterialListStudents"
            ></matrial-card>
          </li>
        </transition-group>

        <template v-if="materials.length > 0 && totalPages > 1">
          <vs-pagination
            :total="totalPages"
            :max="countPerPage"
            v-model="currentPage"
            class="mt-6"
          />
        </template>
      </div>

      <div class="vx-col lg:w-1/4 w-full">
        <round-filter :filters_prop="custom_filter" @refilter="setFilters">
        </round-filter>
      </div>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const StudentRepository = RepositoryFactory.get('StudentsModule', 'student')
const TeacherRepository = RepositoryFactory.get(
  'TeachersModule',
  'teaching_materials'
)
import StatusCounter from '@/components/general/StatusCounter'
import matrialCard from './components/matrial-card.vue'
import roundFilter from '@/components/general/RoundFilter'
import { commonDataMixin } from '@/mixins.js'

export default {
  mixins: [commonDataMixin],
  components: {
    matrialCard,
    StatusCounter,
    roundFilter
  },
  props: {
    student: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      materials: [],
      topFilter: '',
      counts: {},
      sideFilters: {
        subjects: [],
        creators: [],
        types: []
      },
      custom_filter: [
        {
          id: 1,
          name: this.$t('basic.types'),
          payload_name: 'types',
          by: []
        },
        {
          id: 2,
          name: this.$t('basic.subjects'),
          payload_name: 'subjects',
          by: []
        },
        {
          id: 3,
          name: this.$t('basic.creators'),
          payload_name: 'creators',
          by: []
        }
      ],
      /////////////////////////
      // Pagination
      /////////////////////////
      totalPages: 0,
      currentPage: 1,
      countPerPage: 10
    }
  },
  watch: {
    currentPage () {
      this.getTeachingMaterialListStudents()
    },
    sideFilters: {
      deep: true,
      handler () {
        this.getTeachingMaterialListStudents()
      }
    }
  },

  computed: {
    // Start Staues Of Top Cards
    status () {
      const status = [
        {
          icon: 'EyeIcon',
          value: 'all',
          name: this.$t('basic.All'),
          count: this.counts.all
        },
        {
          icon: 'SmileIcon',
          value: 'documents',
          name: this.$t('teachingMaterials.Documents'),
          count: this.counts.Document,
          color: 'black'
        },

        {
          icon: 'BookOpenIcon',
          value: 'books',
          name: this.$t('teachingMaterials.Books'),
          count: this.counts.Book,
          color: 'black'
        },
        {
          icon: 'Volume2Icon',
          value: 'audio',
          name: this.$t('teachingMaterials.Audio'),
          count: this.counts.Audio,
          color: 'black'
        },
        {
          icon: 'VideoIcon',
          value: 'video',
          name: this.$t('teachingMaterials.Video'),
          count: this.counts.Video,
          color: 'black'
        },
        {
          icon: 'AirplayIcon',
          value: 'presentation',
          name: this.$t('teachingMaterials.Presentation'),
          count: this.counts.Presentation,
          color: 'black'
        }
      ]
      return status
    }
  },

  methods: {
    setFilters (val) {
      val.map((item) => {
        this.sideFilters[item.name] = item.checked
      })
    },
    setPagination (data) {
      this.totalPages = data.total_pages || data.last_page
      this.countPerPage = data.per_page
      this.currentPage = data.current_page
    },
    getFiltersData () {
      TeacherRepository.getTypes().then((res) => {
        res.data.map((el) => {
          this.custom_filter[0]['by'].push({
            id: el.id,
            name: el.name,
            checked: false
          })
        })
      })
      StudentRepository.getStudentSubjects(this.student.id).then((res) => {
        res.map((el) => {
          this.custom_filter[1]['by'].push({
            id: el.id,
            name: el.name,
            checked: false
          })
        })
      })
      TeacherRepository.teachersOfSection().then((res) => {
        res.data.data.map((el) => {
          this.custom_filter[2]['by'].push({
            id: el.id,
            name: el.name,
            checked: false
          })
        })
      })
    },
    getTeachingMaterialListStudents () {
      this.VSLoading()
      const params = {
        ...this.sideFilters,
        page: this.currentPage
      }
      StudentRepository.getTeachingMaterialListStudents(params)
        .then((res) => {
          this.materials = res.data.data
          this.setPagination(res.data)
        })
        .finally(() => {
          this.stopVSLoading()
        })
    },
    counter () {
      StudentRepository.count().then((res) => {
        this.counts = res.data
      })
    }
  },
  created () {
    this.getFiltersData()
    this.getTeachingMaterialListStudents()
    this.counter()
  }
}
</script>
