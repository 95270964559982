import Chat from './pages/Chat.vue'

export default [
  {
    path: '',
    name: 'chat',
    component: Chat,
    props: true,
    meta: {
      breadcrumb: [],
      pageTitle: 'chat.chat',
      rule: 'landing'
    }
  }
]
