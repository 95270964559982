/*=========================================================================================
  File Name: moduleAuthMutations.js
  Description: Auth Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from 'axios'
export default {
  setAccessToken (state, payload) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${payload.access_token}`
    localStorage.setItem('access_token', payload.access_token)
    state.access_token = payload.access_token
  },

  setRefreshToken (state, payload) {
    state.refresh_token = payload.refresh_token
    localStorage.setItem('refresh_token', payload.refresh_token)
  },
  setLastRoute (state, payload) {
    state.last_route = payload.last_route
    localStorage.setItem('last_route', payload.last_route)
  },

  setUser (state, payload) {
    const user = {}
    user['uid'] = payload.user.id
    user['displayName'] = payload.user.name
    user['displayFirstAndSecondName'] = `${ user['displayName'].split(' ')[0]}${ user['displayName'].split(' ').length > 2 ? ` ${  user['displayName'].split(' ')[1]}` : ''} `
    user['email'] = payload.user.email
    user['school_id'] = payload.user.school_id
    user['userRole'] = payload.user.role_name
    user['role_display_name'] = payload.user.role_display_name
    user['student'] = payload.user.student
    user['DP'] = payload.user.flag
    user['status'] = payload.user.status
    user['image'] = payload.user.image
    user['isAuthenticated'] = true
    user['teacher_parent'] = payload.user.teacher_parent
    if (['parent', 'Parent'].includes(payload.user.role_name)) {
      user['isParentCompletedProfile'] = payload.user.is_completed
      user['isParentResponsible'] = false
    }
    state.authUser = user
    localStorage.setItem('authUser', JSON.stringify(state.authUser))
    localStorage.setItem('logout-login-event', 'login')

  },

  clearAuthData (state, payload) {
    localStorage.removeItem('authUser')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('access_token')
    localStorage.removeItem('last_route')
    localStorage.removeItem('actAsOriginalAuthUser')
    localStorage.setItem('logout-login-event', 'logout')

    state.authUser = null
    state.actAsOriginalAuthUser = null
    state.access_token = null
    state.refresh_token = null
    state.last_route = null
    state.isUserLoggedIn = false

  },

  // Act AS
  setActAsOriginalAuthUser (state, payload) {
    state.actAsOriginalAuthUser = payload
    localStorage.setItem('actAsOriginalAuthUser', JSON.stringify(payload))

  },
  resetAuthUser (state, payload) {
    state.authUser = payload
    localStorage.setItem('authUser', JSON.stringify(payload))
  },
  clearActAsData (state, payload) {

    localStorage.removeItem('actAsOriginalAuthUser')
    state.actAsOriginalAuthUser = null

  },
  updateUserProfilePhoto (state, payload) {
    const authUser = JSON.parse(localStorage.getItem('authUser'))
    authUser.image = payload
    localStorage['authUser'] = JSON.stringify(authUser)

    state.authUser.image = payload
  }

}
