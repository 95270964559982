import axios from 'axios'

const resource = 'parent-profile'
export default {
  getParentdata () {
    return axios.get(`${resource}`)
  },
  getChildernsDayData (id) {
    return axios.get(`${resource}/${id}`)
  },
  getStudentdata (id) {
    return axios.get(`student/${id}/studentDetails`)
  },
  // Assignments Component
  getAllAssignments (student_id) {
    return axios.get(`/teachers/${student_id}/student/aassignments`)
  },
  filterAssignments (id, filters) {
    return axios.post(`teachers/student/${id}/assignments`, filters)
  },
  getAllSubjects () {
    return axios.get('/getAllSubjects')
  },
  getParentStudents () {
    return axios.get('parent/students')
  },
  getRound (round_id) {
    return axios.get(`/round/${round_id}`)
  },
  // Complete parent info
  getParentData () {
    return axios.get('/student/parentInfo')
  },
  saveParentData (payload) {
    return axios.post('/student/parentInfo', payload)
  },
  // complete custody data
  getParentChildren () {
    return axios.get('/student/parent/students')
  },
  getParentStudentData (studentId) {
    return axios.get(`/student/${studentId}/parent/student-info`)
  },
  getMaritalStatus () {
    return axios.get('/student/static-data/marital_status')
  },
  getGenders () {
    return axios.get('/student/static-data/genders')
  },
  getCustodyOwners () {
    return axios.get('student/static-data/custody_owners')
  },
  getMedicalChildSuffers () {
    return axios.get('/student/static-data/medical')
  },
  saveParentStudentInfo (payload) {
    return axios.post('/student/parent/student-info', payload)
  },
  getSingleParentsById (id) { //this is funcation in snd parnts
    return axios.get(`/student/parentInfo/${id}`)
  },
  updateParentById (id, payload) { //this is funcation in snd parnts
    return axios.post(`/student/parentInfo/${id}`, payload)
  },
  getMyDayData (id) {
    return axios.get(`/parent-profile/${id}`)
  }
}
