<template>
  <div>
    <vx-card class="p-3 pt-6 pb-6 md:p-6">
      <!-- Class -->
      <div class="vx-col lg:w-2/3 mb-5 w-full">
        <label class="w-full">{{ $t("form.choose_class") }}</label>
        <v-select
          closeOnSelect
          label="name"
          :reduce="(name) => name.id"
          :options="classes"
          v-model="onlineClass.class_id"
          class="w-full select-large"
          name="Class"
          v-validate="'required'"
          @input="changeClasses"
        />

        <span v-if="errors.has('Class')" class="text-danger text-sm w-full">{{
          errors.first("Class")
        }}</span>
      </div>

      <!-- Subject -->
      <div class="vx-col lg:w-2/3 mb-5 w-full" v-if="onlineClass.class_id">
        <label class="w-full">{{ $t("form.choose_subject") }}</label>
        <v-select
          closeOnSelect
          label="name"
          :reduce="(name) => name.id"
          :options="subjects"
          v-model="onlineClass.subject_id"
          class="w-full select-large"
          name="subject"
          v-validate="'required'"
        />

        <span v-if="errors.has('subject')" class="text-danger text-sm w-full">{{
          errors.first("subject")
        }}</span>
      </div>

      <!-- Date -->
      <div class="vx-col mb-5 w-full">
        <label class="w-full">
          {{
            inSchedulePages
              ? $t("form.choose_many_dates")
              : $t("form.choose_date")
          }}
        </label>
        <div class="vx-row">
          <div class="vx-col lg:w-2/3">
            <flat-pickr
              v-model="onlineClass.date"
              class="w-full"
              name="date"
              :config="configdatePicker"
              v-validate="'required'"
              @on-change="chaeckDailyAgenda"
            />
          </div>

          <div class="vx-col lg:w-1/3" v-if="onlineClass.date">
            <vs-button
              type="line"
              @click="showDailyAgenda = !showDailyAgenda"
              >{{ $t("onlineClass.daily_agenda") }}</vs-button
            >
          </div>
        </div>
        <span v-if="errors.has('date')" class="text-danger text-sm w-full">{{
          errors.first("date")
        }}</span>
      </div>

      <!-- Time -->
      <div class="vx-col lg:w-2/3 items-center mb-5 w-full flex flex-wrap">
        <div class="mt-3 mr-6 flex-1 flex items-center flex-wrap">
          <label class="w-1/4">{{ $t("form.start_time") }}</label>
          <flat-pickr
            v-model="onlineClass.time_from"
            name="time"
            :config="configTimePicker"
            v-validate="'required'"
            class="w-3/4"
          />
          <flat-pickr
            :placeholder="$t('form.end_time')"
            v-model="onlineClass.time_to"
            name="time"
            class="mb-3 flex-1 d-none hidden"
            :config="configTimePickerTo"
            v-validate="'required'"
          />
        </div>

        <div class="mt-3 flex-1 flex items-center flex-wrap">
          <label class="w-1/4">{{ $t("form.duration") }}</label>
          <v-select
            label="name"
            :reduce="(dur) => dur.value"
            :options="durations"
            v-model="onlineClass.duration"
            name="subject"
            v-validate="'required'"
            class="w-3/4"
          />
        </div>
        <span v-if="errors.has('time')" class="text-danger text-sm w-full">{{
          errors.first("time")
        }}</span>
      </div>

      <!-- recording meeting -->
      <div class="my-5 flex-1 flex items-center flex-wrap">
        <label class="lg:w-1/6 md:w-1/4 w-1/2">{{
          $t("onlineClass.recording_meeting")
        }}</label>
        <vs-switch
          class="lg:w-1/8"
          v-model="onlineClass.auto_start_recording"
        />
      </div>

      <!-- waiting list meeting -->
      <div class="my-6 flex-1 flex items-center flex-wrap">
        <label class="lg:w-1/6 md:w-1/4 w-1/2">{{
          $t("onlineClass.waiting")
        }}</label>
        <vs-switch

          class="lg:w-1/8"
          v-model="askmodartor"
        />
      </div>


      <div class="py-2" v-if="showDailyAgenda">
        <vs-table class="mt-8" :data="dailyAgenda">
          <template slot="thead">
            <vs-th> {{ $t("basic.subject") }} </vs-th>
            <vs-th> {{ $t("basic.teacher") }} </vs-th>
            <vs-th> {{ $t("basic.date") }} </vs-th>
            <vs-th> {{ $t("basic.from") }} </vs-th>
            <vs-th> {{ $t("basic.to") }} </vs-th>
          </template>

          <template>
            <vs-tr v-for="(day, index) in dailyAgenda" :key="index">
              <vs-td>
                {{ day.subjectName }}
              </vs-td>
              <vs-td>
                {{ day.teacher_name }}
              </vs-td>
              <vs-td>
                {{ day.date }}
              </vs-td>
              <vs-td>
                {{ day.time_from }}
              </vs-td>
              <vs-td>
                {{ day.time_to }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>

      <!-- Save -->

      <div
        class="
          flex flex-wrap
          md:justify-between
          justify-center
          items-center
          md:flex-row
          flex-col
          mt-10
        "
      >
        <vs-button
          color="warning"
          type="border"
          :to="{ name: 'onlineClasses' }"
          class="mt-5"
          >{{ $t("form.cancel") }}</vs-button
        >

        <vs-button @click="formSubmitted" class="mt-5">{{
          this.edit_id ? $t("form.update") : $t("form.save")
        }}</vs-button>
      </div>
    </vx-card>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const TeacherRepository = {
  BasicInfo: RepositoryFactory.get('TeachersModule', 'BasicInfo'),
  OnlineClass: RepositoryFactory.get('TeachersModule', 'OnlineClass')
}

export default {
  name: 'class-form',
  props: {
    onlineClass_prop: {
      type: Object,
      required: true
    },
    edit_id: {}
  },
  components: {
    flatPickr
  },
  mounted () {
    if (this.onlineClass.guest_policy === 'ASK_MODERATOR') {
      this.askmodartor = true
    } else {
      this.askmodartor = false
    }
    this.getClasses()

    if (this.edit_id) {
      this.getSubjects()
      this.onlineClass.duration = this.calculateDuration()
    } else {
      this.onlineClass.time_from = this.currentTime
      this.onlineClass.time_to = this.getEndTimefromDuration()
    }

    this.configTimePicker.minDate = this.minDate
    this.configdatePicker.minDate = this.currentDate
  },
  computed: {
    inSchedulePages () {
      return this.$route.path.includes('schedule')
    },

    // to Convert Value Of datePiker TO Array
    dateOnLineClass () {
      return this.onlineClass.date.split(',').map((item) => {
        item = item.trim()
        return item
      })
    },
    currentTime () {
      return `${new Date().getHours()}:${new Date().getMinutes()}`
    },
    currentDate () {
      const today = new Date().toLocaleDateString().split('/')
      return `${today[2]}-${today[0]}-${today[1]}`
    },
    minDate () {
      return !this.edit_id
        ? this.currentTime
        : this.minTime(this.onlineClass.date)
    },
    durations () {
      const min = 30,
        adapter = 15,
        max = 180,
        arr = []

      for (let i = min; i <= max; i += adapter) {
        const hrs = parseInt(i / 60),
          mins = i % 60
        arr.push({
          name: `${
            hrs
              ? `${hrs} ${
                hrs > 1 ? this.$t('basic.hours') : this.$t('basic.hour')
              }`
              : ''
          } ${mins ? `${mins} ${this.$t('basic.minutes')}` : ''}`,
          value: i
        })
      }
      return arr
    }
  },
  data () {
    return {
      askmodartor:true,
      dailyAgenda: [],
      showDailyAgenda: false,
      onlineClass: this.onlineClass_prop,
      configdatePicker: {
        minDate: this.currentDate,
        maxDate: null,
        dateFormat: 'Y-m-d',
        mode: this.$route.path.includes('schedule') ? 'multiple' : 'single'
      },

      configTimePicker: {
        enableTime: true,
        enableSeconds: false,
        noCalendar: true,
        time_24hr: false,
        minDate: this.minDate
      },
      configTimePickerTo: {
        enableTime: true,
        enableSeconds: false,
        noCalendar: true,
        time_24hr: false,
        minDate: null
      },
      subjects: [],
      classes: [],
      guestPolices: [
        {
          name: this.$t('onlineClass.always_accept'),
          value: 'ALWAYS_ACCEPT'
        },
        {
          name: this.$t('onlineClass.ask_moderator'),
          value: 'ASK_MODERATOR'
        }
      ],
      error: false
    }
  },

  methods: {
    chaeckDailyAgenda (first, dates) {
      const param = {
        class_id: this.onlineClass.class_id,
        dates: dates.split(', ')
      }
      TeacherRepository.OnlineClass.dailyAgenda(param).then((res) => {
        this.dailyAgenda = res.data
      })
    },
    ///////////////////////////////////
    // APIs
    ///////////////////////////////////
    // Get Classes
    getClasses () {
      TeacherRepository.BasicInfo.getClasses().then((res) => {
        this.classes = res.data
      })
    },
    // Get Class subjects
    getSubjects () {
      TeacherRepository.BasicInfo.getClassSubjects(
        this.onlineClass.class_id
      ).then((res) => {
        this.subjects = res.data
      })
    },
    // Create Class
    createClass (payload) {
      TeacherRepository.OnlineClass.create(payload).then(
        (res) => {
          this.$router.push('/teacher/onlineClass/')
          this.successMsg(res.message, 'Success')
        },
        (err) => {
          this.errMsg(err.data.message, 'field')
        }
      )
    },
    // UpdateClass
    updateClass (payload) {
      TeacherRepository.OnlineClass.edit(this.edit_id, payload).then(
        (res) => {
          this.$router.push('/teacher/onlineClass/')
          this.successMsg(res.message, 'Success')
        },
        (err) => {
          this.errMsg(err.data.message, 'field')
        }
      )
    },

    ///////////////////////////////////
    // On Change
    ///////////////////////////////////
    changeClasses () {
      this.onlineClass.subject_id = null
      this.getSubjects()
    },
    routerPush (onlineClass_id) {
      if (this.onlineClass.immediateClass) this.$router.push(`/bbb/create/${onlineClass_id}`)
      else this.$router.push({ name: 'onlineClasses' })
    },
    ///////////////////////////////////
    // run_immediately
    ///////////////////////////////////
    run_immediately () {
      const today = new Date()
      this.onlineClass.date = today

      this.onlineClass.time_from = today.getTime() + 60000 * 3
      this.onlineClass.immediateClass = true
      this.formSubmitted()
    },

    ///////////////////////////////////
    //Handle Times
    ///////////////////////////////////

    // Get End Time from Duration
    getEndTimefromDuration () {
      const startTime = this.onlineClass.time_from,
        startTimeHrs = parseInt(startTime.split(':')[0]),
        startTimeMins = parseInt(startTime.split(':')[1]),
        /////////////////////
        duration = this.onlineClass.duration,
        durationHrs = parseInt(duration / 60),
        durationMins = duration % 60,
        endTime = `${startTimeHrs + durationHrs}:${
          startTimeMins + durationMins
        }`

      return endTime
    },

    // Get Duration from End Time
    calculateDuration () {
      const startTime = this.onlineClass.time_from,
        endTime = this.onlineClass.time_to,
        /////////////////////
        startTimeHrs = parseInt(startTime.split(':')[0]),
        startTimeMins = parseInt(startTime.split(':')[1]),
        /////////////////////
        endTimeHrs = parseInt(endTime.split(':')[0]),
        endTimeMins = parseInt(endTime.split(':')[1]),
        /////////////////////
        durationHrs = parseInt(endTimeHrs - startTimeHrs),
        durationMins = parseInt(endTimeMins - startTimeMins),
        duration = durationHrs * 60 + durationMins,
        durationSelect = this.durations.find((d) => d.value === duration).value

      return durationSelect
    },
    // Min Time
    minTime (date) {
      if (new Date(date) > new Date()) return null
      else return this.currentTime
    },
    // validate TimeTo
    validateTimeTo () {
      const time_from = this.onlineClass.time_from,
        time_from_hrs = parseInt(time_from.split(':')[0]),
        //////////////////
        time_to = this.onlineClass.time_to,
        time_to_hrs = parseInt(time_to.split(':')[0])

      if (time_to_hrs > time_from_hrs) return true
      else return false
    },
    ///////////////////////////////////
    // SubmitForm
    ///////////////////////////////////
    formSubmitted () {
      this.$emit('formSubmitted', true)
      this.$validator
        .validate()
        .then((valid) => {
          const isTimeToValid = this.validateTimeTo()

          if (valid && isTimeToValid) {
            console.log('valid && isTimeToValid', valid && isTimeToValid)
            const ///////////// StartTime ////////////////
              timeFrom = this.onlineClass.time_from,
              timeFromSplit = timeFrom.split(':'),
              timeFromHrs = `${
                timeFromSplit[0].length < 2
                  ? `0${timeFromSplit[0]}`
                  : `${timeFromSplit[0]}`
              }`,
              timeFromMins = `${
                timeFromSplit[1].length < 2
                  ? `:0${timeFromSplit[1]}`
                  : `:${timeFromSplit[1]}`
              }`,
              timeFromSecs = `${
                timeFromSplit.length < 3 ? ':00' : `:${timeFromSplit[2]}`
              }`,
              timeFromHandeled = `${timeFromHrs}${timeFromMins}${timeFromSecs}`,
              ///////////// End Time ////////////////
              timeTo = this.onlineClass.time_to,
              timeToSplit = timeTo.split(':'),
              timeToHrs = `${
                timeToSplit[0].length < 2
                  ? `0${timeToSplit[0]}`
                  : `${timeToSplit[0]}`
              }`,
              timeToMins = `${
                timeToSplit[1].length < 2
                  ? `:0${timeToSplit[1]}`
                  : `:${timeToSplit[1]}`
              }`,
              timeToSecs = `${
                timeToSplit.length < 3 ? ':00' : `:${timeToSplit[2]}`
              }`,
              timeToHandeled = `${timeToHrs}${timeToMins}${timeToSecs}`,
              ///////////// Payload ////////////////
              payload = {
                dates: this.dateOnLineClass,
                time_from: timeFromHandeled,
                time_to: timeToHandeled,
                is_auto_run: this.onlineClass.is_auto_run,
                is_muted: this.onlineClass.is_muted,
                class_id: this.onlineClass.class_id,
                subject_id: this.onlineClass.subject_id,
                immediateClass: this.onlineClass.immediateClass,
                duration: this.onlineClass.duration,
                type: this.onlineClass.type,
                guest_policy: this.onlineClass.guest_policy,
                auto_start_recording: this.onlineClass.auto_start_recording
              }

            if (!this.edit_id) {
              this.createClass(payload)
            } else {
              this.updateClass(payload)
            }
          } else if (!valid) {
            this.errMsg(
              this.$t(
                'onlineClass.online_Class_has_not_been_saved_please_complete_your_data'
              ),
              'field'
            )
          } else {
            this.errMsg(
              this.$t('onlineClass.not_valid_time_to_perform_this_action'),
              'field'
            )
          }
        })
        .catch(this.errMsg)
    }
  },
  watch: {
    'askmodartor' (val) {
      if (val) {
        this.onlineClass.guest_policy = 'ASK_MODERATOR'
      } else {
        this.onlineClass.guest_policy = 'ALWAYS_ACCEPT'
      }
    },
    'onlineClass.time_from' (val) {
      const x = new Date()
      x.setHours(parseInt(val.split(':')[0]))
      x.setMinutes(parseInt(val.split(':')[1]))
      x.setSeconds(parseInt(val.split(':')[2]))

      this.configTimePickerTo.minDate = x

      const hrs_string = val.split(':')[0],
        hrs = parseInt(hrs_string)
      // this.onlineClass.time_to = val.replace(hrs_string, `${hrs_string !== '00' ? '' : '0'}${(hrs).toString()}`)
      this.onlineClass.time_to = this.getEndTimefromDuration()

      // Check validate Time To
    },
    'onlineClass.date' (val) {
      this.configTimePicker.minDate = this.minTime(val)
    },
    'onlineClass.duration' (v) {
      this.onlineClass.time_to = this.getEndTimefromDuration()
    }
  }

}
</script>
