/*-------------------------------------------------------------------------------------------
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
-------------------------------------------------------------------------------------------=*/

import Vue from 'vue'
import App from './App.vue'
require('dotenv').config()

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css' // Vuesax
Vue.use(Vuesax)

// use lodash over system as global //
window._ = require('lodash')

// axios
import axios, {setupAxiosConfig} from './axios.js'

Vue.prototype.$http = axios

///////////////////////////////////////////////////
//** Custom Event Wrapper Shared Over System ** //
//////////////////////////////////////////////////
window.$sharedEvent = new (class {
  constructor() {
    this.vue = new Vue()
  }

  fire(event, data = null) {
    this.vue.$emit(event, data)
  }

  listen(event, callback) {
    this.vue.$on(event, callback)
  }

  off(event, callback) {
    this.vue.$off(event, callback)
  }
})()

setupAxiosConfig()

// Theme Configurations
import '../themeConfig.js'

// ACL
import acl from './acl/acl'

// Globally Registered Components
import './globalComponents.js'

// Styles: SCSS
import './assets/scss/main.scss'

// Tailwind
import '@/assets/css/main.css'

// Vue Router
import router from './router'

// Vuex Store
import store from './store/store'

// i18n
import i18n from './i18n/i18n'

// Vuexy Admin Filters
import './filters/filters'

// Clipboard
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

// Tour
import VueTour from 'vue-tour'
Vue.use(VueTour)
require('vue-tour/dist/vue-tour.css')

// DatePicker
import flatpickrD from 'flatpickr'
import FlatPickr from 'vue-flatpickr-component'
require('flatpickr/dist/flatpickr.css')
// flatePicker Localizations  :
// https://github.com/flatpickr/flatpickr/tree/master/src/l10n
// FlatPickr.localize(english)
import {english} from 'flatpickr/dist/l10n/default.js'
// window.Date.prototype.toLocaleString = (locale) => locale || 'en-us'
// flatpickrD.localize(english)
const defaultFlatPickerConfig = {
  // default options here
  dateFormat: 'Y-m-d',
  locale: english,
  disableMobile: false,
  defaultDate: new Date().toLocaleString('en-US')
  // minDate: (date) => new Date(date).toLocaleString('en-us'),
  // formatDate:(date) => new Date(date).toLocaleString('en-us')
}
flatpickrD.setDefaults(defaultFlatPickerConfig)
Vue.use(FlatPickr)
// VueJs Modal
import VModal from 'vue-js-modal'
Vue.use(VModal)

import {EventEmitter} from 'events'

//Implement Vue Mixins //
import './mixins'

export const EV = new EventEmitter()

// VeeValidate
import en_attributes from './validation-attributes-translation/en_attributes'
import ar_attributes from './validation-attributes-translation/ar_attributes'
import fr_attributes from './validation-attributes-translation/fr_attributes'

import ar from 'vee-validate/dist/locale/ar'
import en from 'vee-validate/dist/locale/en'
import fr from 'vee-validate/dist/locale/fr'

import VeeValidate from 'vee-validate'
Vue.use(VeeValidate, {
  locale: store.getters['main/currentLocale'],
  dictionary: {
    en: {
      messages: en.messages,
      attributes: en_attributes
    },
    ar: {
      messages: ar.messages,
      attributes: ar_attributes
    },
    fr: {
      messages: fr.messages,
      attributes: fr_attributes
    }
  }
})

// Google Maps
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    // Add your API key here
    key: 'AIzaSyD-NQM8NtsBw7wXBRXi7K7iH0goni0pCN4',
    libraries: 'places' // This is required if you use the Auto complete plug-in
  }
})

// Vuejs - Vue wrapper for hammerjs
import {VueHammer} from 'vue2-hammer'
Vue.use(VueHammer)

// Feather font icon
require('./assets/css/iconfont.css')

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

//////////////// socket io /////////////////
import VueSocketIOExt from 'vue-socket.io-extended'
import {io} from 'socket.io-client'
const socket = io(process.env.VUE_APP_MESSENGER_APP_BASE_URL)
// const socket = io('https://messenger.schoolia-eg.com/')
// const socket = io('http://127.0.0.1:3010/')

Vue.use(VueSocketIOExt, socket)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  acl,

  beforeCreate() {
    // store.dispatch('main/getLocales') // TODO: fix locale
    // LanguageControl.reloadCashedLanguage()

    ////////////////////////////////
    // Get assets
    ////////////////////////////
    store.dispatch('main/getInstitutionData').then((res) => {
      this.$vs.theme({primary: res.brandColor})
      this.getCurrentAcademicYear().then(this.handleSetCurrentAcademicYear)
    })
  },
  render: (h) => h(App)
}).$mount('#app')
