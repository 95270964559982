import axios from 'axios'

const resource = '/chatting',
  socketResource = `${process.env.VUE_APP_MESSENGER_APP_BASE_URL}/api/chat`
// socketResource = 'https://messenger.schoolia-eg.com/api/chat'
// socketResource = 'http://127.0.0.1:3010/api/chat'
export default {
  getContacts (groupID, name, classes_ids, roles_name, page) {
    const path = groupID
      ? `${resource}/group/contact/${groupID}`
      : `${resource}/contacts`
    return axios.get(path, {
      params: {
        name: name || null,
        classes_ids: classes_ids || null,
        page: page || null,
        roles_name: roles_name || null
      }
    })
  },

  getContact (id) {
    return axios.get(`${resource}/contact/${id}`)
  },
  getChatContacts () {
    return axios.get(`${resource}`)
  },
  getChatMessages (params) {
    // Payload :  {user_id, group_id,id (lastMsgId), direction :"down"||"up",limit:25}
    return axios.get(`${resource}/messages`, { params })
  },

  getChatMessagesForAuthUser () {
    return axios.get(`${resource}/auth-user-unseen-messages-count`)
  },

  saveMessage (payload) {
    return axios.post(`${resource}/send-message`, payload)
    // payload = { receiver_id, content, attachment}
  },

  async uploadFiles (files) {
    return await axios.post('/common/upload-files', files)
  },
  getChatNoifiations () {
    return axios.get(`${resource}/check-notification`)
  },

  async deleteMessage (msgId) {
    return await axios.delete(`${resource}/${msgId}`)
  },


  //////////////////////
  // Socket
  //////////////////////
  saveMessageNode (payload) {
    return axios.post(`${socketResource}/send-message`, payload)
    // payload = { receiver_id, content, attachment}
  },

  updateIsSeen (payload) {
    return axios.post(`${socketResource}/update-is-seen`, payload)
    // payload = { receiver_id, content, attachment}
  },
  saveGroupMessageNode (payload) {
    return axios.post(`${socketResource}/group-send-message`, payload)
    // payload = { receiver_id, content, attachment}
  }
}
