export default {
  add: 'add',
  criteria: 'Criteria',
  assign: 'Assign',
  assignment: 'Assignment',
  assignments: 'Assignments',
  exam: 'Exam',
  exams: 'Exams',
  submit: 'submit',
  allSchools: 'All schools',
  allDivisions: 'All divisions',
  admission: 'admission',
  dashboard: 'dashboard',
  console: 'console',
  create: 'create',
  delete: 'delete',
  update: 'update',
  date_created: 'Date Created',
  schools: 'Schools',
  divisions: 'Divisions',
  status: 'Status',
  change_status: ' change Status',
  suspend: 'Suspend',
  activate: 'Activate',
  suspended: 'suspended',
  active: 'Active',
  error: 'errors',
  confirm: 'Confirm',
  confirm_msg: 'Are you sure ?',
  error_validation: 'Validation error',
  already_exist: 'Already exist  ',
  profile: 'Profile',
  single: 'Single',
  group: 'group',
  due: 'due',
  next: 'Next',
  show: 'Show',
  edit: 'Edit',
  edit_status: 'Edit status',
  duplicate: 'Duplicate',
  insights: 'insights',
  archive: 'Archive',
  unarchive: 'Unarchive',
  hours: 'hours',
  minutes: 'minutes',
  students: 'students',
  configuration: 'Configuration',
  upload: 'upload',
  words: 'words',
  settings: 'Settings',
  permissions: 'Permissions',
  preview: 'Preview',
  name:'name',
  description: 'description',
  selectAll: 'select all',
  onlineClass:'online Class',
  create_onlineClass:'Create an online Class',
  create_quick_meeting:'Create quick meeting',
  edit_onlineClass:'Edit an online Class',
  onlineClasses: 'online Classes'
}
