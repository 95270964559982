<template>
  <div>
    <addOrEditBoundaryForm @submitForm="submitForm" :edit="false"/>
  </div>
</template>

<script>
import addOrEditBoundaryForm from '../components/createForm'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const boundariesAndDescriptors = RepositoryFactory.get(
  'boundariesAndDescriptors',
  'boundariesAndDescriptors'
)
export default {
  name: 'createBoundary',
  components:{
    addOrEditBoundaryForm
  },
  methods: {
    submitForm (payload) {
      this.VSLoading()
      boundariesAndDescriptors.addNewBoundary(payload).then(() => {
        this.successMsg(this.$t('boundariesAndDescriptors.addBoundariesAndDescriptorsSuccessfully'))
        this.$router.push({name : 'boundariesAndDescriptors'})
      }).catch((err) => {
        this.errMsg(err)
      }).finally(() => {
        this.stopVSLoading()
      })
    }
  }
}
</script>

<style scoped>

</style>
