import axios from 'axios'

const resource = 'Principal'

export default {
    // Filter onlineClasses && QuickMeeting via same endpoint
    filterBoth(currentPage, payload) {
        return axios.post(`${resource}/onlineClasses?page=${currentPage}`, payload)
    },
    // Get Filters
    getAllDivision() {
        return axios.get(`division`)
    },
    getAllSections() {
        return axios.get(`section/sections`)
    },
    getAllTeachers() {
        return axios.get(`${resource}/teachers`)
    },
    getAllClasses() {
        return axios.get('teachers/classes')
    },
    getAllTeacherRole () {
      return axios.get('common/teacher-role')
    },
    getPrincipalAssessments(page, payload) {
        return axios.get(`assessments-principal?page=${page}`, { params: {...payload } })
    },
    getTeacherassessments(class_teacher_id) {
        return axios.get(`classTeacher-assessments-principal`, {
            params: {
                class_teacher_id: class_teacher_id
            }
        })
    },
}
