<template >
  <div>
    <EditParentsForm :edit_id="edit_id "/>
  </div>
</template>

<script>
import EditParentsForm from '@/modules/parent/pages/profile/completeProfileData'
export default {
  components:{
    EditParentsForm
  },
  created () {
    this.edit_id = this.$route.params.id
  },
  data () {
    return {
      edit_id:null
    }
  },
  methods: {

  }
}
</script>
