import { render, staticRenderFns } from "./ShowSection.vue?vue&type=template&id=7b1e1460&scoped=true"
import script from "./ShowSection.vue?vue&type=script&lang=js"
export * from "./ShowSection.vue?vue&type=script&lang=js"
import style0 from "./ShowSection.vue?vue&type=style&index=0&id=7b1e1460&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b1e1460",
  null
  
)

export default component.exports