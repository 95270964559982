<template>
  <ListPageWithCreateButton :showCreateBtn="false">
    <template slot="listDataContent">
      <vs-prompt
        @cancel="globalNoteData.note = ''"
        @accept="confirmAddNote"
        :active.sync="showGlobalNoteData"
        title="Note"
        type="confirm">
        <div class="con-exemple-prompt">
          <div class="my-2 vx-col w-full mb-2">
            <vs-textarea
              type="text"
              v-model="globalNoteData.note"
              class="w-full" />
          </div>
        </div>
      </vs-prompt>
      <StudentAffairsTable
        :addTeacherNote="addTeacherNote"
        @startDownload="startDownload"
        @addNote="addNote"
        :csv_url="'student/student/export'"
        :filter_type="filterType"
        :side_filters="sideFilters"
        ref="AttendanceTable"
        total="1"
        :headers_prop="headers"
        :list_url_prop="`/student/filters`">
        <template slot="center_content"></template>
      </StudentAffairsTable>
    </template>
    <template slot="rightSide">
      <student-affairs-filter
        @changeSideFilter="changeSideFilter"
        :filters_prop="filters"
        :filters_choosen_prop="filter_choosen"
        :rounds_prop="rounds"
        active_prop.sync="true"></student-affairs-filter>
    </template>
  </ListPageWithCreateButton>
</template>

<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine'
import {mapGetters} from 'vuex'
import StudentAffairsTable from '../components/StudentAffairsTable'
import StudentAffairsFilter from '../components/StudentAffairsFilter'
import StatusCounter from '@/components/general/StatusCounter'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const TeacherRepository = {
  BasicInfo: RepositoryFactory.get('TeachersModule', 'BasicInfo'),
  OnlineClass: RepositoryFactory.get('TeachersModule', 'OnlineClass'),
  StudentDirectory: RepositoryFactory.get('TeachersModule', 'StudentDirectory')
}
const myDayRepository = RepositoryFactory.get('myDay', 'myDay')
// const RoundRepository = RepositoryFactory.get('AdmissionModule', 'rounds')
// const AllStudentsRepository = RepositoryFactory.get('AdmissionModule', 'allStudents')

export default {
  components: {
    StatusCounter,
    StudentAffairsTable,
    StudentAffairsFilter,
    StatisticsCardLine
  },
  computed: {
    ...mapGetters('auth', ['authUser']),
    filters() {
      const custom_filter = [
        {
          id: 1,
          name: this.$t('basic.classes'),
          by: []
        }
      ]

      // Fill all the classes
      // eslint-disable-next-line vue/no-async-in-computed-properties
      TeacherRepository.BasicInfo.getClasses().then((res) => {
        const all_classes = res.data
        all_classes.map((el) => {
          custom_filter[0]['by'].push({
            id: el.id,
            name: el.name,
            checked: false
          })
        })
      })

      return custom_filter
    }
  },
  data() {
    return {
      addTeacherNote: false,
      sideFilters: {
        divisions: [],
        sections: [],
        grades: [],
        classes: []
      },
      globalNoteData: {
        classes: [],
        note: ''
      },
      showGlobalNoteData: false,
      activeTab: '',
      filterType: '',
      all_sections_count: [],
      headers: [
        {
          minWidth: 95,
          headerName: this.$t('basic.ID'),

          field: 'student_id'
        },
        {
          minWidth: 200,
          headerName: this.$t('StudentAffairs.fullName'),
          valueGetter:
            '`${data.student.first_name} ${data.student.middle_name} ${data.student.last_name}`'
        },
        {
          minWidth: 95,
          headerName: this.$t('StudentAffairs.classGroup'),
          field: 'student.classGroupName'
        },
        {
          minWidth: 150,
          headerName: this.$t('basic.actions'),
          filter: false,
          field: 'actions',
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            actions_prop: [
              {
                title: this.$t('StudentAffairs.show_student_profile'),
                parameter_name: 'id',
                icon: 'EyeIcon',
                navigate_action: true,
                route_name: 'StudentProfile',
                params_name: 'id',
                showIf: () => !window.location.host.includes('cpis')
              },
              {
                title: this.$t('basic.chat'),
                parameter_name: 'user_id',
                icon: 'MessageSquareIcon',
                navigate_action: true,
                route_name: 'chat',
                params_name: 'userID',
                showIf: () => !window.location.host.includes('cpis')
              },
              {
                title: this.$t('basic.copy'),
                parameter_name: 'official_email',
                icon: 'ClipboardIcon',
                copy_action: true,
                showIf: () => !window.location.host.includes('cpis')
              },
              {
                title: this.$t('StudentAffairs.add_note'),
                parameter_name: 'id',
                icon: 'EditIcon',
                dialog_action: true,
                dialog_name: 'studentAddNote',
                showIf: () => this.addTeacherNote
              }
            ]
          }
        }
      ],
      filter_choosen: [],
      rounds: [
        {
          id: 1,
          name: 'American KG 03',
          start: '5 March 2020',
          end: '5 March 2020'
        },
        {
          id: 2,
          name: 'American KG 04',
          start: '5 March 2020',
          end: '5 March 2020'
        }
      ]
    }
  },
  methods: {
    changeFilter(filter) {
      let id = 0
      switch (filter.toLowerCase()) {
        case 'pyp':
          id = 1
          break
        case 'dp':
          id = 3
          break
        case 'myp':
          id = 2
          break
        case 'american': // It is still Amreicam in the back-end
          id = 4
          break
        case 'allstudents':
          id = 5
          break
      }
      this.changeSideFilter('', id)
    },
    startDownload(downloadLink) {
      this.$vs.notify({
        color: 'success',
        text: 'Started Downloading'
      })
      const downloadUrl = downloadLink
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', 'exported_data.csv')
      document.body.appendChild(link)
      link.click()
      link.remove()
    },
    changeSideFilter(selected_filters, selected_section) {
      const adjustData = (fils) => {
        return {
          classes: fils[0].by.filter((el) => el.checked).map((el) => el.id)
        }
      }
      if (!selected_section) this.sideFilters = adjustData(selected_filters)
      else
        this.sideFilters = {
          classes: this.sideFilters.classes
        }
    },
    addNote() {
      this.showGlobalNoteData = true
    },
    confirmAddNote() {
      this.globalNoteData.classes = this.sideFilters.classes
        ? this.sideFilters.classes
        : []
      TeacherRepository.StudentDirectory.addGeneralNote(
        this.globalNoteData
      ).then((res) => {
        this.globalNoteData.note = ''
      })
    },
    IfShowNote() {
      const role = JSON.parse(localStorage.getItem('authUser')).userRole
      myDayRepository.getAllSettings().then((res) => {
        if (
          (role == 'TeacherSectionCoodinator' &&
            res.data.teacher_section_coordinator_add_note_for_web_and_mobile) ||
          (role == 'teacher' && res.data.teacher_add_note_for_web_and_mobile) ||
          (role == 'TeacherGradeCoodinator' &&
            res.data.teacher_grade_coordinator_add_note_for_web_and_mobile) ||
          (role == 'SectionCoodinator' &&
            res.data.section_coordinator_add_note_for_web_and_mobile)
        ) {
          this.addTeacherNote = true
        }
      })
    }
  },
  created() {
    const filters = {
      divisions: [],
      sections: [],
      grades: [],
      classes: []
    }
    this.IfShowNote()
  }
}
</script>

<style lang="scss" scoped>
.active .vx-card {
  -webkit-box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
  box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
}
</style>
