export default {
  assessments: 'Assessments',
  assessment: 'Assessment',
  teacher_assessments: 'Teacher Assessments',
  Update_Task: 'Update Task',
  Create_Task: 'Create Task',
  back_to_tasks_list: 'Back to tasks list',
  answer_task: 'Answer Task',
  task_grade: 'Task Grade',
  students_done_on_time: 'Students Done on Time',
  students_done_but_late: 'Students Done But Late',
  students_delayed: 'Students Delayed',
  view_assessment: 'View assessment',
  assessed: 'Assessed',
  to_assess: 'to assess',
  delayed: 'Delayed',
  submit_assessment: 'Submit Assessment',
  summative_formative: 'Summative/Formative',
  assessment_students_tasks: 'Assessment Students Tasks',
  task_assessment: 'Task Assessment',
  not_assessed: 'Not Assessed',
  all: 'All',
  student_assessed: 'Students assessed',
  student_need_to_be_assess: 'Need to assess',
  view_assignments: 'View assignments',
  done_on_time: ' Done on Time',
  done_but_late: ' Done But Late',
  ToStudent:'To student',
  ToParent: 'To parent',
  all_are_assessed: 'all are assessed',
  no_students_assessed:'no students assessed'

}
