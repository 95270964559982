<!--

** Props : filters_prop - Rounds Data - SyncWithReduceButton
---------------------------------------------------------------
All Filters is checkboxes ( multi select)
If you want a  radio button ( single select), add  "singleSeletable:true" as key
in the object of the single selectable filter

SyncWithReduceButton: if true it will be showing with the left vertical Nav Menu
show && vice versa.

for emit event on change checked, just add   onChangeFireAction:true at the
object of the filter

** filters_prop : EX
     [
        {
          name: 'Types',
          payload_name:'type',
          onChangeFireAction:true, // fire @change checkbox
          singleSeletable:true, // Radio Button ( singe select)

          by: [
            {
             name:this.$t('basic.on_paper'),
              id: 0,
              text_color:'red', // if need to change option's text color
              checked: false  // Its important for Gathering the checked data,
              disabled:false // disabled filter
            },
            {
             name:this.$t('basic.online'),
              id: 1,
              checked: false
            }
          ]
        }
      ]


----------------------------------------------------------------
** Slots :
- extra_content ( Directly Above the Filters  )
- customFilters
----------------------------------------------------------------
-->
<template>
  <div>
    <!-- Collaped Button {for collapse Navbar mode} -->
    <div id="round_filter_buttons" v-if="this.$store.state.reduceButton">
      <vs-button color="primary" type="filled" @click="activeSidebar">
        {{ $t('basic.filters') }}
        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
      </vs-button>
    </div>

    <!-- Filter Sidebar  -->
    <div id="round_filter" :class="{'mb-10': isMobileWebView}" v-else>
      <!-- filters  -->
      <vs-sidebar
        static-position
        default-index="1"
        color="primary"
        class="items-no-padding vs-sidebar-rounded p-0"
        spacer
        hidden-background>
        <!----------------------------------------------
                  Rounds Dropdown (Header)
        ------------------------------------------------>
        <template v-if="rounds && rounds.length > 1">
          <vs-select
            v-model="activeRoundIndex"
            class="w-full select-large bg-primary text-color-base"
            id="round_filter_select"
            v-validate="'required'">
            <vs-select-item
              :key="round.id"
              :value="index"
              :text="round.name"
              v-for="(round, index) in rounds"
              class="w-full" /> </vs-select
        ></template>
        <!-------------------------------------------------
              Content ( Round Info && Links && Filters )
        --------------------------------------------------->
        <div id="round_filter_content" class="p-5 bg-white">
          <template v-if="restRoundDays">
            <div class="mt-3 mb-5">
              <p class="text-sm">
                <span>
                  <strong>
                    {{ $t('basic.Academic_Day') }}
                    {{ this.currentDateString.split(' ').slice(1).join(' ') }}
                  </strong>
                </span>
              </p>
              <p class="text-sm">
                {{ $t('basic.Day') }} {{ $t('basic.Academic_Day') }}
                {{ restRoundDays }} {{ $t('basic.out_of') }}
                {{ round.total_days }} {{ $t('basic.days') }}
              </p>
            </div>
          </template>
          <!--------- extra_content Slot ( Links )  --------->
          <div
            class="mb-5 block"
            :class="restRoundDays ? 'mt-0' : 'mt-3'"
            v-if="$slots.extra_content">
            <slot name="extra_content"></slot>
          </div>
          <!--------- Filters  --------->
          <form @submit="refilter()" class="mt-5 block">
            <div class="flex justify-between align-middle">
              <h6 class="font-bold">{{ $t('basic.filters') }}</h6>
              <button
                type="reset"
                class="bg-transparent border-0 text-primary"
                @click="resetAll"
                v-if="
                  !loading && (filters_choosen.length > 0 || dataRange !== '')
                ">
                {{ $t('basic.reset_all') }}
              </button>
            </div>
            <vs-divider />
            <!-- Choosen -->
            <div class="my-3 d-block" v-if="filters_choosen.length > 0">
              <vs-chip
                @click="remove(choose)"
                v-for="(choose, i) in filters_choosen"
                :closable="!choose.disabled"
                :key="i"
                >{{ choose.name }}
              </vs-chip>
            </div>

            <!-- options -->
            <vs-collapse
              accordion
              :class="{
                'mb-10':
                  (windowWidth > 990 && !$slots.customFilters) ||
                  windowWidth > 990
              }">
              <!-- loader -->
              <div
                ref="filterLoader"
                id="filterLoader"
                class="vs-con-loading__container"
                v-show="showLoader"></div>
              <!-- if filter has aptions -->
              <template v-for="filter in filters">
                <vs-collapse-item :key="filter.id" v-if="filter.by.length > 0">
                  <div slot="header">{{ filter.name }}</div>
                  <ul>
                    <li
                      v-for="(filter_by, index) in filter.by"
                      :key="index"
                      class="mb-2"
                      :class="{'flex items-center mb-2': filter_by.count}">
                      <!-- if options is multi selectble -->
                      <template v-if="!filter.singleSeletable">
                        <vs-checkbox
                          class="p-0 m-0 flex items-center"
                          :class="{'float-left': filter_by.count}"
                          :style="`color:${filter_by.text_color}`"
                          :vs-value="filter_by"
                          :checked="filter_by.checked"
                          v-model="checked_filters"
                          @change="update(filter_by, filter.name)"
                          :disabled="filter_by.disabled || false"
                          >{{ filter_by.name }}
                        </vs-checkbox>
                        <!-- COUNT -->
                        <vs-chip class="counter" v-if="filter_by.count"
                          >{{ filter_by.count }}
                        </vs-chip>
                      </template>
                      <!-- if options is single selectble -->
                      <template v-else>
                        <vs-radio
                          :class="{'float-left': filter_by.count}"
                          :style="`color:${filter_by.text_color}`"
                          :vs-value="filter_by"
                          :checked="filter_by.checked"
                          v-model="checked_filters_singleSelect"
                          :name="filter.name.toLowerCase()"
                          :vs-name="filter.name.toLowerCase()"
                          @change="update(filter_by, filter.name)"
                          :disabled="filter_by.disabled"
                          >{{ filter_by.name }}
                        </vs-radio>
                        <!-- COUNT -->
                        <vs-chip class="counter" v-if="filter_by.count"
                          >{{ filter_by.count }}
                        </vs-chip>
                      </template>
                    </li>
                  </ul>
                </vs-collapse-item>
              </template>
              <vs-collapse-item v-if="filter_date">
                <div slot="header">{{ $t('basic.date_range') }}</div>
                <template>
                  <div>
                    <flat-pickr
                      class="w-full"
                      :config="configdatePicker"
                      v-model="dataRange"
                      name="date" />
                    <span
                      v-if="errors.has('date')"
                      class="text-danger text-sm w-full"
                      >{{ errors.first('date') }}</span
                    >
                  </div>
                </template>
              </vs-collapse-item>
            </vs-collapse>

            <!--------- CustomeFilers Slot ( Links )  --------->
            <div class="my-2 px-4" v-if="$slots.customFilters">
              <slot name="customFilters"></slot>
            </div>
            <!------------ Done Btn  ------------>
            <vs-button
              @click="refilter()"
              class="mt-10 w-full block mt-auto mb-0"
              :disabled="false"
              >{{ $t('basic.done') }}</vs-button
            >
          </form>
        </div>
      </vs-sidebar>
    </div>
  </div>
</template>
<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
export default {
  name: 'round-filter',
  props: {
    filters_prop: {
      type: Array,
      required: true
    },
    rounds: {
      type: Array
    },
    SyncWithReduceButton: {
      type: Boolean,
      default: true
    },
    filter_date: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  components: {
    flatPickr
  },
  data() {
    return {
      filters: this.filters_prop,
      activeRoundIndex: 1,
      checked_filters_singleSelect: null,
      filters_choosen: [],
      round: {},
      dataRange: '',
      configdatePicker: {
        mode: 'range',
        dateFormat: 'Y-m-d'
      },
      refilter_checked: null,
      refilter_checked_objects: {},
      showLoader: false
    }
  },
  computed: {
    activeRoundID() {
      if (this.rounds) return this.rounds[this.activeRoundIndex].id
    },

    restRoundDays() {
      return (this.rounds && this.rounds[0]) || this.round?.to
        ? new Date(this.round?.to || this.rounds[0]?.to).getDate() -
            new Date().getDate()
        : false
    },
    hasCheckedFilters() {
      return !!(
        this.filters_choosen.length > 0 ||
        this.checked_filters_singleSelect ||
        this.getDate.length > 0
      )
    },
    disabledFilters() {
      const options = []
      this.filters.map((e) => {
        options.push(...e.by.filter((s) => s.disabled))
      })
      return options
    },
    disabledCheckedFilters() {
      const options = []
      this.filters.map((e) => {
        if (!e.singleSeletable) options.push(...e.by.filter((s) => s.disabled))
      })
      return options
    },
    disabledSingleSelectFilters() {
      const options = []
      this.filters.map((e) => {
        if (e.singleSeletable) options.push(...e.by.filter((s) => s.disabled))
      })
      return options
    },
    checked_filters: {
      get() {
        const options = []
        this.filters.map((e) => {
          if (!e.singleSeletable) options.push(...e.by.filter((s) => s.checked))
        })
        return options
      },
      set(v) {}
    }
  },
  watch: {
    filters_prop: {
      handler(v) {
      console.log("filters updated");
        this.filters = v
        this.updateFilter_choosen()
      },
      deep: true
    },
    dataRange(v) {
      this.$emit('dateChanged', v)
    },
    loading(v) {
      if (v) this.startLoading()
      else this.endLoading()
    },
    filters_choosen(v) {
      // if (!v.length) this.refilter()
    }
  },

  methods: {
    ///////////////////////////////
    // loading
    ///////////////////////////////
    startLoading() {
      this.showLoader = true
      this.VSLoading(this.$refs.filterLoader)
    },
    endLoading() {
      this.stopVSLoading(this.$refs.filterLoader)
      this.showLoader = false
    },
    ////////////////////////////////////////
    // Uncheck Filters (removed from filter_choosen)
    /////////////////////////////////////////
    remove(choose) {
      this.filters_choosen.splice(this.filters_choosen.indexOf(choose), 1)
      this.checked_filters.splice(
        this.checked_filters.find((el) => el.checked === false),
        1
      )

      // Reset the checked value of removed
      this.filters_prop.map((fil) =>
        fil.by.map((fil_by) => {
          if (fil_by.id === choose.id) {
            fil_by.checked = false
          }
          return fil_by
        })
      )
      this.checked_filters_singleSelect = null
      this.updateFilter_choosen()
    },
    ////////////////////////////////////////////////////
    // Update Filters ( Added to filter_choosen)
    /////////////////////////////////////////////////////
    update(val, name) {
      // Rest checked
      const parentFilter = this.filters.filter((el) => el.name === name)[0]
      const subFilter = parentFilter.by[parentFilter.by.indexOf(val)]

      // reset checked singleSelectable case
      if (parentFilter.singleSeletable) {
        parentFilter.by.forEach((el) => {
          el.checked = false
        })
      }

      subFilter.checked = !subFilter.checked
      this.updateFilter_choosen()
      if (parentFilter.onChangeFireAction) {
        this.$root.$emit(
          `onChange:${parentFilter.payload_name}`,
          this.refilter_checked_objects()
        )
      }
    },
    // updateFilter_choosen
    updateFilter_choosen() {
      // Get Choosen Filter
      const chips = []
      this.filters.map((temp) => {
        const x = temp.by.filter((fby) => fby.checked === true)
        if (x.length !== 0)
          x.map((temp) => {
            chips.push(temp)
          })
      })

      const checked = this.filters.map((el) => {
        if (!el.singleSeletable) {
          return {
            name: el.payload_name || el.name.toLowerCase(),
            checked: el.by
              .filter((temp) => temp.checked === true)
              .map((temp) => temp.id)
          }
        } else {
          const is_checked = el.by.some((temp) => temp.checked === true),
            checked_Ids = is_checked
              ? el.by.find((temp) => temp.checked === true).id
              : ''
          return {
            name: el.payload_name || el.name.toLowerCase(),
            checked: checked_Ids
          }
        }
      })
      this.refilter_checked = checked
      this.refilter_checked_objects = () => {
        const obj = {}
        checked.forEach((e) => {
          obj[e.name] = e.checked
        })
        return obj
      }

      this.filters_choosen = chips
    },
    refilter() {
      this.$emit('refilter', this.refilter_checked)
    },
    ////////////////////////////////////////////////////
    //  Rest All Filters ( Empty filter_choosen)
    /////////////////////////////////////////////////////

    resetAll() {
      // console.log('reset')
      this.dataRange = ''
      // Uncheck all filters
      if (this.hasCheckedFilters) {
        this.$emit('resetAll', true)

        // Reset checked filters
        this.filters.map((el) =>
          el.by.map((fil) => {
            fil.checked = false
            return fil
          })
        )
        this.filters_choosen = []
        // this.checked_filters = []
        this.checked_filters_singleSelect = []

        // TODO: (WIP) Handle disabled filter senario
        this.filters_choosen = [...this.disabledFilters]
        this.checked_filters = [...this.disabledCheckedFilters]
        this.checked_filters_singleSelect = [
          ...this.disabledSingleSelectFilters
        ]
        this.dataRange = ''
        this.updateFilter_choosen()
        this.refilter()
      }
    },
    ////////////////////////////////////////////////////
    //  Active||Show Sidebar
    /////////////////////////////////////////////////////
    activeSidebar(id) {
      if (this.SyncWithReduceButton)
        this.$store.commit('TOGGLE_REDUCE_BUTTON', false)
    }
  },
  created() {
    this.updateFilter_choosen()

    if (this.rounds) {
      if (this.rounds.length === 1) this.round = this.rounds[0]
    }
  }
}
</script>
<style lang="scss">
#round_filter {
  .vs-collapse {
    position: relative;
    .vs-con-loading__container {
      position: absolute;
      inset: 0;
    }
  }
  .con-vs-chip.counter {
    margin: 0 5px;
    padding: 0;
    > .vs-chip--text {
      margin: 0;
    }
  }
  .vs-checkbox-default {
    .vs-checkbox {
      max-width: 20px !important;
      max-height: 20px !important;
      flex: 1;
    }
    .con-slot-label {
      flex: 2;
    }
  }
}
</style>
