<template>
  <div>
    <template v-if="onlineClass">
      <class-form
        :onlineClass_prop.sync="onlineClass"
        :edit_id="edit_id"
      ></class-form>
    </template>
  </div>
</template>
<script>
import ClassForm from './components/OnlineClassForm'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const TeacherRepository = RepositoryFactory.get(
  'TeachersModule',
  'OnlineClass'
)
export default {
  components:{
    ClassForm

  },
  created () {

    this.edit_id = this.$route.params.id
    this.show()

  },
  data () {
    return {
      edit_id:null,
      onlineClass:null
    }
  },

  methods: {

    // show
    show () {
      TeacherRepository.show(this.edit_id).then(res => {
        this.onlineClass =  res.data
      })
    }
  }
}
</script>
