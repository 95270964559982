<template>
  <div>
    <a :href="link" class="no_style">
      <vx-card class="overflow-hidden small-card mb-5">
        <div slot="no-body">
          <div
            class="p-4"
            :class="'flex justify-between flex-row-reverse items-center'"
          >
            <feather-icon
              :icon="icon"
              class="p-2 inline-flex rounded-full"
              :class="
                'status--' +
                title
                  .toString()
                  .toLowerCase()
                  .replace(' ', '_')
                  .replace('.', '')
              "
            ></feather-icon>
            <div class="truncate">
              <h3 class="mb-1 font-bold">{{ count }}</h3>
              <span>{{ title.replace("_", " ") }}</span>
            </div>
          </div>
        </div>
      </vx-card>
    </a>
  </div>
</template>
<script>
export default {
  name: 'small-card',
  props: {
    icon: {
      type: String,
      required: true
    },
    count: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    }
  }
}
</script>
<style lang="scss" scoped>
.vxcard {
  cursor: pointer;
  .active & {
    box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.2);
  }
}
.small-card {
  .feather-icon {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .truncate {
    span {
      text-transform: capitalize;
      color: #6e6d6d;
    }
  }
}
</style>
