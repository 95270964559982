<template>
  <div id="list_datatable" class="w-full">
    <div class="vx-row">
      <div
        class="vx-col xl:w-1/5 lg:w-1/4 md:w-1/3 sm:w-1/2 w-full mr-0 ml-auto block">
        <vs-button
          class="mr-0 ml-auto block"
          :to="{path: 'evaluation-card/create/' + 1}"
          >{{ $t('evaluation.NewEvaluation') }}</vs-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EvaluationCardList',
  data() {
    return {}
  }
}
</script>

<style scoped lang="scss">
#round_buttons {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  min-width: 155px;
  height: fit-content;
  z-index: 999;
  transform: rotate(90deg) translateY(-140px);
  button {
    display: inline-block;
    margin-left: 6px;
  }
}
</style>
