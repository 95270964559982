export default {
  academic: 'Academic',
  syllabusList: 'Syllabus List',
  division: 'Division',
  grade: 'Grade',
  name: 'Name',
  created_by: 'Created By',
  academicYear: 'Academic Year',
  syllabusCode: 'Syllabus Code',
  subjects: 'Subjects',
  online: 'Online',
  status: 'Status',
  actions: 'Actions',
  syllabusCreate: 'Create a new syllabus',
  syllabusShow: 'Syllabus Information',
  showSyllabus: 'Syllabus Code',
  divisionsGrades: 'Division & Grades',
  semestersSubjects: 'Semesters & Subjects',
  assignTeachers: 'Assign Teachers',
  divisions: 'Divisions',
  selectDivisions: 'Select Divisions',
  grades: 'Grades',
  selectGrades: 'Select Grades',
  semesters: 'Semesters',
  selectSemesters: 'Select Semesters',
  terms: 'Terms',
  selectTerms: 'Select Terms',
  selectSubjects: 'Select Subjects',
  teachers: 'Teachers',
  selectTeacher: 'Select a teacher',
  noSubjects: 'Select at least 1 subject',
  noTeachers: 'All the teachers are assigned',
  syllabusEdit: 'Edit Syllabus',
  usedIn: 'Used in',
  assign_teacher: 'Assign teacher',
  customize_teacher: 'Customize teacher',
  Assign_Students:'Assign Students',
  new_syllabus_has_been_created_successfully_with_code:'New syllabus has been created successfully with code',
  customize_teachers: 'Customize Teachers',
  edit_syllabus: 'Edit Syllabus',
  not_used_in_any_classes: 'Not used in any classes',
  customize_students:'Customize Students',
  assign_student:'Assign Student',
  customization_data:'Customization Data',
  is_assign:'Is Assign',
  assigned:'Assigned',
  not_assigned:'Not Assigned',
  you_couldnt_uncheck_choosen_subjects: 'You couldn\'t uncheck the chosen subjects',
  you_couldnt_uncheck_choosen_students: 'You couldn\'t uncheck the chosen students',
  delete_syllbus:'Delete Syllabus',
  be_care_full_this_syllbus_will_deleted_permanently:'Be care full this syllabus will deleted permanently',
  are_you_sure_you_want_delete_this_syllbus: 'Are you sure you want delete this syllabus.',
  syllabus_deleted_successfully: 'Syllabus deleted successfully',
  syllabus_info: 'Syllabus Information',
  assign_Co_teacher: 'Assign Co teacher'
}
