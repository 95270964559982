<template>
  <vx-card class="p-3 pt-6 pb-6 md:p-6">
    <div class="vx-row mb-2">
      <div class="vx-col md:w-1/3 w-full mb-2">
        <label>{{ $t(`StudentProfile.${attendance_data[0]['i18n']}`) }}</label>
      </div>

      <div class="vx-col md:w-2/3 w-full mb-2">
        <label><strong>{{ attendance_data[0]['value']['status'] }}</strong></label>
      </div>
    </div>

    <div class="vx-row mb-2">
      <vs-divider>{{ $t(`StudentProfile.${attendance_data[1]['i18n']}`) }}</vs-divider>
      <div class="vx-col md:w-1/3 w-full mb-2" v-for="(data, index) in attendance_data[1].value" :key="index">
        <label>{{ `${Object.keys(data)[0]}: ${data[Object.keys(data)[0]]}` }}</label>
      </div>
      <div class="vx-col md:w-1/3 w-full mb-2" v-if="attendance_data[1].value.length === 0">
        <label>There are no available data for this month</label>
      </div>
    </div>

    <vs-divider>{{ $t(`StudentProfile.${attendance_data[2]['i18n']}`) }}</vs-divider>

    <div class="vx-row mb-2" v-for="(data, index) in attendance_data[2].value" :key="index">
      <div class="vx-col md:w-1/3 w-full mb-2">
        <label>{{ data[0] }}</label>
      </div>
      <div class="vx-col md:w-2/3 w-full mb-2">
        <div class="vx-row">
          <div class="vx-col md:w-1/3 w-full mb-2" v-for="(status, index) in data[1]" :key="index">
            <label>{{ `${Object.keys(status)[0]}: ${status[Object.keys(status)[0]]}` }}</label>
          </div>
        </div>
      </div>
    </div>

    <div class="vx-row mb-2" v-if="attendance_data[2].value.length === 0">
      <div class="vx-col md:w-1/3 w-full mb-2">
        <label>There are no previously recorded data</label>
      </div>

    </div>
  </vx-card>
</template>

<script>
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const StudentRepository = RepositoryFactory.get('StudentsModule', 'student')

export default {
  name: 'Attendance',
  props: {
    student: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      user_id: this.student.user_id,
      attendance_data: [
        {title: 'Today Status', value: ''},
        {title: 'Monthly Report', value: ''},
        {title: 'General Report', value: ''}
      ]
    }
  },
  created () {
    const attendanceData = [
      {title: 'Today Status', value: ''},
      {title: 'Monthly Report', value: ''},
      {title: 'General Report', value: ''}
    ]
    StudentRepository.getAttendanceReport(this.user_id).then(res => {
      const data = res.data
      console.log(data)
      attendanceData[0].value = data['today_status']
      attendanceData[1].value = data['monthly_report']
      attendanceData[2].value = data['general_report']

      // Set the i18n of each element by removing spaces and putting _ (Add the i18n keys to StudentProfile file)
      attendanceData.map(data => {
        data['i18n'] = data.title.toLowerCase().replaceAll(' ', '_')
        return data
      })
      this.attendance_data = attendanceData
    })
  }
}
</script>
