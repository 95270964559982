<template>
  <ListPageWithCreateButton :showCreateBtn="false">
    <template slot="leftSideButtonContent">
      <StatusCounter
        @filter-change="changeFilter"
        :status_prop="statistics_data" />
    </template>
    <template slot="listDataContent">
      <transition-group class="list-transition" name="list-enter-up" tag="ul">
        <template v-if="filtrated_data.length > 0">
          <li
            class="mb-10"
            v-for="(val, i) in filtrated_data"
            :key="val.id"
            :style="[{transitionDelay: i * 0.1 + 's'}]">
            <OnlineClassCard
              :showArchiveIcon="false"
              v-if="isOnlineclasses"
              :onlineClass_prop="val"
              userType="principal"></OnlineClassCard>
            <QuickMeetingCard v-else :quickMeetingProp="val"></QuickMeetingCard>
          </li>
        </template>
      </transition-group>
      <!-- Pagination  -->
      <template v-if="filtrated_data.length > 0">
        <vs-pagination
          :total="totalPages"
          :max="countPerPage"
          v-model="currentPage"
          v-if="totalPages > 1" />
      </template>
      <template v-if="filtrated_data.length < 1">
        <p class="text-center">{{ $t('basic.no_data_available') }}</p>
      </template>
    </template>
    <template slot="rightSide">
      <RoundFilter
        :filters_prop="filters"
        @refilter="refilter"
        @resetAll="resetFilterData"
        ref="roundFilter">
        <!-- DatePicker Filter  -->
        <template slot="customFilters">
          <p class="vs-collapse-item--header mb-2">
            {{ $t('basic.date') }}
          </p>
          <div class="w-full">
            <label>{{ $t('basic.startDate') }}</label>
            <vx-input-group class="w-full">
              <flat-pickr
                icon-pack="feather"
                icon="icon-user"
                @on-change="changeDate"
                :config="configDatepickerFrom"
                v-model="filterPayload.dateIn"
                name="start_date"
                class="w-full" />

              <template slot="append" v-if="filterPayload.dateIn">
                <div class="append-text btn-addon">
                  <vs-button
                    type="border"
                    icon-pack="feather"
                    icon="icon-x"
                    @click="filterPayload.dateIn = ''"></vs-button>
                </div>
              </template>
            </vx-input-group>
          </div>
          <div class="w-full mt-5">
            <label>{{ $t('basic.endDate') }}</label>

            <vx-input-group class="w-full">
              <flat-pickr
                icon-pack="feather"
                icon="icon-user"
                @on-change="changeDate"
                :config="configDatepickerTo"
                v-model="filterPayload.dateOut"
                name="end_date"
                class="w-full" />
              <template slot="append" v-if="filterPayload.dateOut">
                <div class="append-text btn-addon">
                  <vs-button
                    type="border"
                    icon-pack="feather"
                    icon="icon-x"
                    @click="filterPayload.dateOut = ''"></vs-button>
                </div>
              </template>
            </vx-input-group>
          </div>
        </template>
      </RoundFilter>
    </template>
  </ListPageWithCreateButton>
</template>
<script>
// Components
import RoundFilter from '@/components/general/RoundFilter'
import OnlineClassCard from '@/modules/onlineClass/components/ClassCard'
import QuickMeetingCard from '@/modules/onlineClass/components/QuickMeetingCard'
import StatusCounter from '@/components/general/StatusCounter'

// APIs
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const virtualClassesRepo = RepositoryFactory.get(
  'schoolPrincipalRepository',
  'virtualClasses'
)

// Datepicker
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  name: 'virtualClasses',
  components: {
    RoundFilter,
    StatusCounter,
    OnlineClassCard,
    QuickMeetingCard,
    flatPickr
  },
  data() {
    return {
      /////////////////////////
      // Status Counter
      /////////////////////////
      statistics_data: [
        {
          id: 1,
          icon: 'EyeIcon',
          color: 'primary',
          value: 'online_classes',
          name: this.$t('onlineClass.onlineClasses'),
          active: true,
          count: 0
        },
        {
          id: 2,
          icon: 'SmileIcon',
          value: 'Today',
          color: 'success',
          name: this.$t('onlineClass.todayClasses'),
          count: 0
        },
        {
          id: 3,
          icon: 'ClockIcon',
          value: 'ongoing',
          name: this.$t('onlineClass.ongoingClasses'),
          count: 0
        },
        {
          id: 4,
          icon: 'CheckCircleIcon',
          value: 'Closed',
          color: 'dark',
          name: this.$t('onlineClass.closedClasses'),
          count: 0
        },
        {
          id: 5,
          icon: 'UsersIcon',
          value: 'QuickMeeting',
          color: 'RGB',
          name: this.$t('onlineClass.quickMeetings'),
          count: 0
        }
      ],

      /////////////////////////
      // OnlinClasses
      /////////////////////////
      onlineClasses: [],
      isOnlineclasses: true,

      /////////////////////////
      // QuickMeetings
      /////////////////////////
      quickMeetings: [],

      /////////////////////////
      // DatePicker
      /////////////////////////
      configDatepickerFrom: {
        dateFormat: 'Y-m-d'
      },
      configDatepickerTo: {
        dateFormat: 'Y-m-d',
        minDate: null
      },

      /////////////////////////
      // round Filter
      /////////////////////////
      filtrated_data: [],
      filters: [
        {
          name: this.$t('basic.class'),
          forOnlineClass: true,
          by: []
        },
        {
          name: this.$t('basic.subject'),
          forOnlineClass: true,
          by: []
        },
        {
          name: this.$t('basic.teacher'),
          by: []
        }
      ],
      filterPayload: {
        classes: [],
        subjects: [],
        teachers: [],
        dateIn: '', //2020-12-24
        dateOut: '', //2020-12-24
        status: 'online_classes'
      },
      checked_filters: null,
      /////////////////////////
      // Pagination
      /////////////////////////
      totalPages: 0,
      currentPage: 1,
      countPerPage: 10
    }
  },
  methods: {
    ///////////////////////////////
    // loading
    /////////////////////////////
    loading() {
      this.VSLoading()
    },
    stopLoading() {
      this.stopVSLoading()
    },
    ///////////////////////////////
    // Dealing with Data
    /////////////////////////////
    resetList() {
      this.loading()
      this.filtrated_data = []
      // this.isOnlineclasses = false
    },
    setList(data) {
      this.stopLoading()
      this.filtrated_data = data.data
      this.setPagination(data)
    },
    setPagination(data) {
      this.totalPages = data.total_pages
      this.countPerPage = data.per_page
      this.currentPage = data.current_page
    },
    setListFirstTime(data) {
      if (this.isOnlineclasses) {
        if (!this.onlineClasses.length) {
          this.onlineClasses = data.data
        }
      } else if (!this.quickMeetings.length) {
        this.quickMeetings = data.data
      }
      this.setPagination(data)
    },
    setOnlineClasses(data) {
      this.isOnlineclasses = true
      this.resetFilters()
      if (data) this.setList(data)
    },
    setQuickMeetings(data) {
      this.isOnlineclasses = false
      // Reset Round Filters
      this.resetFilterData()
      this.resetFilters()
      if (data) this.setList(data)
    },

    ///////////////////////////////
    // APIs
    //////////////////////////////
    //---- Get Filters
    getAllClasses() {
      virtualClassesRepo.getAllClasses().then((res) =>
        res.data.map((el) => {
          this.filters[0].by.push({
            name: el.name,
            id: el.id,
            checked: false
          })
        })
      )
    },
    getAllSubjects() {
      virtualClassesRepo.getAllSubjects().then((res) =>
        res.data.map((el) => {
          this.filters[1].by.push({
            name: el.name,
            id: el.id,
            checked: false
          })
        })
      )
    },
    getAllTeachers() {
      virtualClassesRepo.getAllTeacherRole().then((res) =>
        res.data.map((el) => {
          this.filters[2].by.push({
            name: el.name,
            id: el.id,
            checked: false
          })
        })
      )
    },

    //---- List
    filterBoth() {
      this.resetList()
      virtualClassesRepo
        .filterBoth(this.currentPage, this.filterPayload)
        .then((res) => {
          this.setListFirstTime(res.data)
          this.setList(res.data)
        })
        .catch(() => {
          this.stopLoading()
        })
    },
    getQuickMeetings() {
      this.resetList()
      virtualClassesRepo
        .getQuickMeetings()
        .then((res) => {
          this.quickMeetings = res.data
        })
        .catch(() => {
          this.stopLoading()
        })
    },

    //--------- Statistics
    getStatistics() {
      virtualClassesRepo
        .getStatistics()
        .then((res) => this.setCounters(res.data))
    },
    ///////////////////////////////
    // Round Filter
    /////////////////////////////

    setFilters() {
      if (this.isOnlineclasses) {
        this.getAllClasses()
        this.getAllSubjects()
      }
      this.getAllTeachers()
    },
    resetFilters() {
      if (!this.isOnlineclasses) {
        this.filters.forEach((el) => {
          if (el.forOnlineClass) el.by = []
        })
      }
    },
    refilter(checked = []) {
      // console.error('checked', checked)
      this.checked_filters = checked

      if (checked.length > 0) {
        this.filterPayload.classes =
          checked[0] && checked[0].checked ? checked[0].checked : []
        this.filterPayload.subjects = checked[1] ? checked[1].checked : []
        this.filterPayload.teachers = checked[2] ? checked[2].checked : []
      }
      this.filterBoth()
    },
    resetFilterData() {
      this.filterPayload.dateIn = ''
      this.filterPayload.dateOut = ''
      this.refilter()
    },
    // Date Filter
    changeDate() {
      this.refilter(this.checked_filters)
    },

    ///////////////////////////////
    // Status Filter
    /////////////////////////////
    setCounters(data) {
      // const OC = this.onlineClasses,
      //   QM = this.quickMeetings

      // this.statistics_data.forEach(el => {
      //   if (el.value === 'online_classes') el.count = OC.length
      //   else if (el.value === 'QuickMeeting') el.count = QM.length
      //   else if (el.value === 'Today') el.count = OC.filter(el => el.is_today).length
      //   else if (el.value === 'ongoing') el.count = OC.filter(el => el.time_status === 'ongoing').length
      //   else if (el.value === 'Closed') el.count = OC.filter(el => el.time_status === 'done').length
      // })

      this.statistics_data.forEach((el) => {
        el.count = data[el.value]
      })
    },
    changeFilter(v) {
      this.filterPayload.status = v
      if (!v) {
        this.$refs.roundFilter.resetAll()
        this.setOnlineClasses()
      } else if (v === 'QuickMeeting') {
        this.setQuickMeetings()
      } else {
        this.setOnlineClasses()
      }
      this.currentPage = 1
      this.filterBoth()
    }
  },
  watch: {
    isOnlineclasses(v) {
      this.resetFilters()
      this.setFilters()
    },
    'filterPayload.dateIn'(v) {
      // this.configDatepickerTo.minDate = `${(v).split('-')[0]}-${(v).split('-')[1]}-${Number((v).split('-')[2]) + 1}`
      this.configDatepickerTo.minDate = v
    },
    currentPage(v) {
      this.filterBoth(v)
    }
  },
  created() {
    this.setFilters()
    this.setOnlineClasses()
    this.filterBoth()
    this.getStatistics()
  },
  mounted() {}
}
</script>
