export default {
  assignment_is_saved: 'assignment Is saved',
  add_to_assignment: 'add to assignment',
  checklist: 'checklist',
  due_date: 'Due Date',
  CreateAssignment: 'Create assignment',
  EditAssignment: 'EditAssignment',
  assignment_type: 'assignment type',
  on_paper: 'on paper',
  online: 'online',
  attach_files_from_drive: 'attach files from drive',
  All_Assignments: 'All Assignments',
  Active_Assignments: 'Active Assignments',
  Drafted_Assignments: 'Drafted Assignments',
  Archived_Assignments: 'Archived Assignments',
  assignments: 'Assignments',
  set_dae_and_time_to_launch: 'Set date and time to launch',
  Schedule_Launch: 'Schedule Launch',
  At_least_one_student_is_required: 'At least one student is required ',
  All_Students_Is_Required: 'All Students Is Required',
  Summative_Formative_Is_Required: ' Summative/Formative Is Required',
  Update_Assignment: 'Update Assignment',
  Create_Assignment: 'Create Assignment',
  Create_Assignment_homework: 'Create Assignment homework',
  Choose_Assess_Criteria: 'Choose Assess Criteria',
  Criteria_Is_Required: 'Criteria Is Required',
  Summative_Formative: 'Summative/Formative',
  types_is_required: ' Types Is Required',
  Activate_student_Comments: 'Activate student Comments',
  Allow_uploading_document: 'Allow uploading document',
  Prevent_students_of_submitting_this_task_after_passing_due_date:
    'Prevent students of submitting this task after passing due date',
  assess_assignment: 'Assess Assignment',
  assignment_name: 'Assignment Name',
  review_teacher_grade: 'Review Teacher Grade',
  please_set_all_criteria_marks: 'Please Set All Criteria Marks',
  summative: 'summative',
  formative: 'Formative',
  students_information: 'Students Information',
  assignment_details: 'Assignment Details',
  assignment_setting: 'Assignment Setting',
  submission_details: 'Submission Details',
  submission_type: 'Submission Type',
  single_submission: 'Single Submission',
  multiple_submission: 'Multiple Submission',
  launch_date: 'Launch Date',
  launch_time: 'Launch time',
  another_details: 'Another Details',
  search_student_by_id_or_name: 'Search student by ID or name',
  all_assignments: 'All assignments',
  all_tasks: 'All Tasks',
  need_to_be_assessed: 'Need to be assessed',
  late_assessments: 'Late assessments',
  fully_assessed: 'Fully assessed',
  quiz: 'Quiz',
  homework: 'Homework',
  essay: 'Essay',
  paper: 'Paper',
  test: 'Test',
  project: 'Project',
  submit_the_answer: 'Submit the answer',
  show_feedBack: 'Show feedBack',
  assessment_to_be_included_into_the_grade_book:
    'Assessment to be included into the grade book',
  assignment_daily_agenda: 'Assignment daily agenda',
  assignment_daily_agenda_for: 'Assignment daily agenda for',
  teacher: 'Teacher',
  show_agenda: 'Show agenda',
  choose_subject: 'Choose subject',
  choose_classes: 'Choose classes',
  cut_off_date: 'Cutoff date',
  days_to_submit: 'Days to submit',
  time_to_take_action: 'Time to take action',
  days_late: 'Days Late',
  cant_answer_after_due_time: 'Can\'t answer after Due time',
  write_your_answer: 'write your answer',
  write_your_comment: 'write your comment',
  from_device: 'From device',
  from_drive: 'From drive',
  Choose_student_to_assess: 'Choose student to assess',
  assess_student: 'assess student',
  has_no_answers: 'has no answers',
  feedback_to_student: 'Feedback to student',
  message_parent: 'Message parent',
  student_answer: 'student\'s answer',
  assigned_tasks: 'Assigned Tasks',
  return_it_back: 'Return it back',
  submit_assessment: 'Submit assessment',
  feedback: 'feedback',
  select_grade: 'select grade',
  student_comment: 'student\'s comment',
  select_criteria: 'Select criteria',
  send_feedback_to_parent: 'send the feedback to the parent',
  teacher_feedback: 'Teacher Feedback',
  discipline_score: 'Discipline score',
  done_on_time: 'done on time',
  done_but_late: 'done but late',
  delayed: 'delayed',
  your_answer: 'Your answer',
  your_comment: 'Your Comment',
  waiting_feedback: 'Waiting Feedback',
  submission: 'Submission',
  upcoming: 'Upcoming',
  late: 'Late',
  you_cant_access_this_assignment: 'You can\'t access this assignment',
  multiple_answers: 'Multiple answers',
  change_status_successfully_to: 'change status successfully to',
  not_assessed: 'Not assessed',
  time_passed: 'Time passed',
  submitted: 'submitted',
  cant_access_access_after_due_date: 'can\'t access access after due date',
  assignment_deleted_successfully: 'Assignment deleted successfully',
  wait_for_feed_back: 'wait for feed back',
  no_assignments_found: 'No assignments found',
  you_cant_access_this_assignment_now_wait_teacher_feedback:
    'you can\'t access this assignment now wait teacher feedback'
}
