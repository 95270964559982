export function getDropdownMultiSelectData_Mixin (repository, dataObj) {
  return {
    data () {
      return {
        schools: [],
        divisions: [],
        sections: [],
        one_school_id: null
      }
    },
    created () {
      // this.getDropdowndata()
    },

    methods: {
      checkIfOneSchool () {
        if (this.schools.length < 2) {
          this.one_school_id = this.schools[0].id
          if (this[dataObj].schools) this[dataObj].schools = [this.schools[0].id]
          else this[dataObj].school = this.schools[0].id

          this.getSchoolDivisions()
        }
      },
      getDropdowndata () {
        // this.getSchoolDivisions()
        repository.getDropDownData().then(res => {
          this.schools = res.schools

          this.checkIfOneSchool()
          if (this.edit_id) {
            this.getSchoolDivisions()
          }
        })
      },
      getSchoolDivisions () {

        if (!this.edit_id) {
          this[dataObj].divisions = []
          this[dataObj].sections = []
        }
        repository.getSchoolDivisions(this[dataObj].schools ? this[dataObj].schools : this[dataObj].school).then(res => {
          this.divisions = res.data
          if (this.edit_id) {
            this.getDivisionsSections()
          }
        })
      },

      getDivisionsSections () {
        if (!this.edit_id) {
          this[dataObj].sections = []
        }

        const divisions = { divIds: this[dataObj].divisions }

        repository.getDivisionsSections(divisions).then(res => {
          this.sections = res.data
        })
      }
    }
  }
}
