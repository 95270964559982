<template>
  <div>
    <class-form :onlineClass_prop="onlineClass"></class-form>
  </div>
</template>
<script>
import ClassForm from './components/OnlineClassForm'

export default {
  components:{
    ClassForm

  },
  created () {

  },
  data () {
    return {
      onlineClass:{
        type:'S. Schoolia',
        date:'',
        time_from:null,
        time_to:null,
        is_auto_run:true,
        is_muted:false,
        class_id:0,
        subject_id:0,
        immediateClass:false,
        duration:90,
        auto_start_recording :false,
        guest_policy:'ASK_MODERATOR'
        // is_special_access_code:false,
        // access_code:null,
      }
    }
  }
}
</script>
