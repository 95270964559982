<template>
  <academic-year-form
    :edit_Subid_forsub="edit_Subid_forsub"
    :edit_id_masterforsub="edit_id_masterforsub"
    :onlyShow="true" />
</template>
<script>
import academicYearForm from './components/academicYearForm'

export default {
  name: 'EditSubMasterYears',
  components: {
    academicYearForm
  },
  created() {
    this.edit_id_masterforsub = this.$route.params.id
    this.edit_Subid_forsub = this.$route.params.idSub
  },

  data() {
    return {
      edit_id_masterforsub: null,
      edit_Subid_forsub: null
    }
  },
  methods: {}
}
</script>

<style scoped>
.background-none {
  background-color: transparent !important;
  color: rgb(160, 152, 152);
  cursor: pointer;
}
</style>
