<template>
  <!-- announcement -->
  <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer"  @click="readAnnouncements()">
    <vx-tooltip :text="$t('announcement.announcements')" position="bottom">
      <feather-icon
        :badge="read"
        icon="Volume2Icon"
        class="cursor-pointer mt-1 mr-2"
        svgClasses="h-7 w-7 cursor-pointer hover:text-primary"
        :title="$t('announcement.announcements')"

      />
    </vx-tooltip>

    <vs-dropdown-menu
      class="notification-dropdown dropdown-custom vx-navbar-dropdown"
    >
      <div class="notification-top text-center p-5 bg-primary text-white">
        <p class="opacity-80">{{ $t("announcement.app_announcements") }}</p>
      </div>

      <component
        :is="scrollbarTag"
        ref="mainSidebarPs"
        class="scroll-area--nofications-dropdown p-0"
        :settings="settings"
        :key="$vs.rtl"
      >
        <ul class="bordered-items" id="contianter-notification">
          <li
            v-for="(announcement, index) in announcements"
            :key="index"
            class="
              flex
              justify-between
              px-4
              pt-4
              pb-2
              notification
              cursor-pointer
            "
            @click="goTOSinglePage(announcement)"
          >
            <div class="flex items-start p-relative">
              <feather-icon
                icon="Volume2Icon"
                svgClasses="stroke-current mr-1 h-5 w-5 text-primary"
              ></feather-icon>
              <div>
                <span class="font-bold block notification-title text-primary">{{
                  announcement.title
                }}</span>
                <!-- <small v-html="announcement.description" :class="'font-medium'">{{
                  announcement.description | truncate(15) | tailing('...')
                }}</small> -->
                &nbsp;
              </div>
            </div>
            <small :class="['font-normal', `t-1  whitespace-no-wrap `]">{{
              dateToYMD(announcement.created_at)
            }}</small>
          </li>
        </ul>
      </component>
      <h6
        class="py-3 text-center show-more"
        @click="goToShowALl()"
        v-if="!checkUserRole('student')"
      >
        Show More
      </h6>
    </vs-dropdown-menu>
  </vs-dropdown>
</template>

<script>
import moment from 'moment'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const announcementRepository = RepositoryFactory.get(
  'announcementRepository',
  'announcement'
)
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import VxTooltip from '@/layouts/components/vx-tooltip/VxTooltip.vue'

export default {
  components: {
    VuePerfectScrollbar,
    VxTooltip
  },
  data () {
    return {
      read:null,
      announcements: [],
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      }
    }
  },
  computed: {
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    readAnnouncements () {
      if (this.read) {
        this.read = 0
        announcementRepository.read().then((res) => {
          console.log(res)
        })
      }
    },
    dateToYMD (date) {
      return moment(String(date)).format('DD/MM/YYYY')
    },
    getAnnouncements () {
      announcementRepository.ListAllAnnouncement().then((res) => {
        this.announcements = res.data.data
        if (res.data.have_new_announcement) {
          this.read = true
        }
      })
    },
    goTOSinglePage (announcement) {
      this.$router.push({
        path: `/${this.authUser.userRole}/announcement/show/${announcement.id}`
      })
    },
    goToShowALl () {
      this.$router.push({ path: `/${this.authUser.userRole}/announcement` })
    }
    ////////////////////////////////
    // Go To Route
    ////////////////////////////////
  },

  created () {
    this.getAnnouncements()
  }
}
</script>

<style lang="scss" scoped>
.show-more {
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  &:hover {
    color: rgba(var(--vs-primary), 1);
  }
}
</style>
