export default {
  classes: 'Classes',
  AcademicYear: 'Academic Year',
  academicYear: 'Academic Year',
  selectDivisions: 'select Divisions',
  Divisions: 'Divisions',
  ClassesList: 'Classes List',
  ClassesShow: 'Classes Show',
  ClassesCreate: 'Create Class',
  ClassCreate: 'Create Class ',
  ClassesEdit: 'Classes Edit',
  ClassesUpgrade: 'Classes Upgrade',
  division: 'division',
  Status: 'Status',
  Actions: 'Actions',
  Name: 'Name',
  code: 'Code',
  average: 'Average',
  Students: 'Students',
  ChooseDivision: 'Choose Division',
  AllDivisions: 'All Divisions',
  ChooseGrads: 'Choose Grads',
  AllGrads: 'All Grads',
  BasicInformation: 'Basic Information',
  AssignStudents: 'Assign Students',
  search: 'search',
  selectGrades: 'select Grades',
  grades: 'grades',
  overview: 'overview',
  actions: 'Actions',
  selectClasses: 'select classes',
  Customize: 'Customize',
  all_students_are_assigned_to_classes: 'All students are assigned to classes',
  new_class: 'New Class',
  recurrent_class: 'Recurrent Class',
  Unselect_all_students: 'Unselect All Students',
  select_all_students: 'Select All Students',
  you_changed_type_Of_Class: 'you Changed Type Of Class',
  new_class_has_been_created_successfully_and_completed_overview:
    'New class has been created successfully and completed overview',

  please_add_student_to_this_class: 'Please add student to this class',
  new_class_has_been_created_successfully:
    'New class has been created successfully',

  please_fill_in_the_missing_data: 'Please fill in the missing data',

  you_do_not_assign_student: "You don't assign student",
  you_do_not_add_student_from_this_old_class_to_recurrent:
    "you don't add Student from This Old Class to Recurrent",
  new_student: 'New Student',
  recurrent_student: 'Recurrent student',
  assigned_student: 'Assigned Student',
  not_assigned_student: 'not Assigned student',

  no_student_in_this_class_you_may_changed_division_or_grade:
    'no student in this class you may changed division or grade',

  you_dont_remove_any_student_from_class:
    'you dont remove any student from class',
  not_contain_students: 'not contain students',
  this_class: 'this class',
  become_not_contain_students: 'become not contain students',
  your_student_class_updated_now_if_you_want_to_remove_any_student_check:
    'your student class updated now if you want to remove any student check',
  class_has_been_updated_with_New_Students_successfully:
    'class has been updated with New Students successfully',
  oldClasses: 'Old Classes',
  upgradeStudents: 'upgrade students',
  please_update_all_students_upgrade_status:
    "please update all student's upgrade status",
  please_update_at_least_one_student_upgrade_status:
    'please update at least one student upgrade status',
  class_has_been_upgraded_with_New_Students_successfully:
    'class has been upgraded with New Students successfully',
  upgrade_all: 'upgrade all'
}
