export default {
  state: {
    createdUnit: null,
    updatedUnit: null,
    plannerModalData: null,
    currentAcademicYearPlanner: null,
    loggedInUser:null,
    modal: {}
  },
  mutations: {
    createUnit (state, payload) {
      state.createdUnit = payload
    },
    updateUnit (state, payload) {
      state.updatedUnit = payload
    },
    currentAcademicYearPlanner (state, payload) {
      state.currentAcademicYearPlanner = payload
    },
    setLoggedInUser (state, payload) {
      state.loggedInUser = payload
    },
    resetPlanner (state, payload) {
      state.plannerModalData = payload
    },
    manageModal (state, payload) {
      state.modal = payload
    }
  },
  actions: {
    createUnit ({commit, state}, payload) {
      localStorage.setItem("currentUint", JSON.stringify(payload));
      commit('createUnit', payload)
    },
    currentAcademicYearPlanner ({commit, state}, payload) {
      localStorage.setItem("currentAcademicYear", JSON.stringify(payload));
      commit('currentAcademicYearPlanner', payload)
    },
    setLoggedInUser ({commit, state}, payload) {
      localStorage.setItem("loggedInUser", JSON.stringify(payload));
      commit('setLoggedInUser', payload)
    },
    resetPlanner ({commit}, payload) {
      commit('resetPlanner', payload)
    },
    manageModal ({commit}, payload) {
      commit('manageModal', payload)
    }
  },
  getters: {
    getCreatedUnit (state) {
      const data = (state.createdUnit) ? state.createdUnit : JSON.parse(localStorage.getItem("currentUint"));
      return data;
    },
    getUpdatedUnit (state) {
      return state.updatedUnit
    },
    getCurrentAcademicYearPlanner (state) {
      const data = (state.currentAcademicYearPlanner) ? state.currentAcademicYearPlanner : JSON.parse(localStorage.getItem("currentAcademicYear"));
      return data;
    },
    getLoggedInUserForPlanner (state) {
      const data = (state.loggedInUser) ? state.loggedInUser : JSON.parse(localStorage.getItem("loggedInUser"));
      return data;
    }
  }
}
