var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.modalProps)?_c('modal',{attrs:{"adaptive":true,"clickToClose":false,"height":_vm.modalProps.height,"minHeight":_vm.modalProps.minHeight,"name":_vm.modalName,"scrollable":_vm.modalProps.scrollable,"width":_vm.modalProps.width}},[_c('div',{staticClass:"review-exam-modal vs-modal"},[_c('div',{staticClass:"modal-header"},[_c('h4',[_vm._v("Exam Grade")])]),(Boolean(Object.values(_vm.exam).length))?_c('div',{staticClass:"modal-body"},[(_vm.exam.type.type !== 'Paper')?_c('vs-row',{staticClass:"mb-2"},[(_vm.exam.grading_type.type != 'Points_Per_Question')?_c('vs-col',{staticClass:"mb-4",attrs:{"vs-w":"12"}},[_c('div',[_c('vx-input-group',[_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:(`required|max_value:${_vm.exam.points}|numeric`),expression:"`required|max_value:${exam.points}|numeric`"}],staticClass:"w-full",attrs:{"name":`exam points`,"placeholder":`maximum point is ${_vm.exam.points}`},model:{value:(_vm.exam.score),callback:function ($$v) {_vm.$set(_vm.exam, "score", $$v)},expression:"exam.score"}}),_c('template',{slot:"append"},[_c('div',{staticClass:"append-text bg-primary"},[_c('span',[_vm._v(_vm._s(_vm.$t('exam.out_of'))+" "+_vm._s(_vm.exam.points))])])])],2),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(_vm.errors.first(`exam points`)))])],1)]):_vm._e(),_vm._l((_vm.questions),function(question,idx){return _c('vs-col',{key:idx,attrs:{"vs-w":"12"}},[_c('div',{staticClass:"flex justify-between flex-wrap items-baseline"},[_c('h4',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(question.title)+" : "),_c('span',{staticClass:"font-bold inline-block",domProps:{"innerHTML":_vm._s(question.description)}})]),_c('div',[(_vm.exam.grading_type.type == 'Points_Per_Question')?_c('vx-input-group',[_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:({
                    required: true,
                    max_value: question.points,
                    numeric: true
                  }),expression:"{\n                    required: true,\n                    max_value: question.points,\n                    numeric: true\n                  }"}],staticClass:"w-full",attrs:{"disabled":Boolean(_vm.exam.is_auto_grading),"name":`question${idx + 1}`,"placeholder":`maximum point is ${question.points}`},model:{value:(question.score),callback:function ($$v) {_vm.$set(question, "score", $$v)},expression:"question.score"}}),_c('template',{slot:"append"},[_c('div',{staticClass:"append-text bg-primary"},[_c('span',[_vm._v(_vm._s(_vm.$t('exam.out_of'))+" "+_vm._s(question.points))])])])],2):_vm._e(),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(_vm.errors.first(`question${idx + 1}`)))])],1)]),(question.type.type === 'Essay')?_c('div',{staticClass:"w-full mt-4"},[_c('vs-textarea',{attrs:{"disabled":""},model:{value:(question.answers[0]),callback:function ($$v) {_vm.$set(question.answers, 0, $$v)},expression:"question.answers[0]"}})],1):_vm._e(),(
              question.has_answered_correctly &&
                question.type.type !== 'Essay'
            )?_c('vs-list',_vm._l((question.answers),function(answer,ansx){return _c('div',{key:ansx},[_c('vs-list-item',{attrs:{"color":"success"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"flex items-baseline"},[_c('vs-icon',{staticClass:"mr-2",attrs:{"icon":"verified_user","color":(answer.has_choosen && answer.is_correct) ||
                        (question.has_answered_correctly &&
                          question.type.type === 'True or False')
                          ? 'success'
                          : 'dark'}}),(question.type.type === 'True or False')?_c('p',{staticClass:"font-bold",class:question.has_answered_correctly ? 'text-success' : ''},[_vm._v(" "+_vm._s(answer.is_correct ? 'True' : 'False')+" ")]):_c('p',{staticClass:"font-bold",class:answer.is_correct ? 'text-success' : '',domProps:{"innerHTML":_vm._s(answer.description)}})],1)]},proxy:true}],null,true)})],1)}),0):(
              !question.has_answered_correctly &&
                question.type.type !== 'Essay'
            )?_c('div',[_c('vs-list',_vm._l((question.answers),function(answer,ansx){return _c('div',{key:ansx},[_c('vs-list-item',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"flex items-baseline"},[(
                          (answer.has_choosen && !answer.is_correct) ||
                            (!question.has_answered_correctly &&
                              question.type.type === 'True or False')
                        )?_c('vs-icon',{staticClass:"mr-2",attrs:{"icon":"close","color":"danger"}}):_c('vs-icon',{staticClass:"mr-2",attrs:{"icon":"verified_user","color":answer.has_choosen
                            ? answer.is_correct
                              ? 'success'
                              : 'danger'
                            : 'dark'}}),(question.type.type === 'True or False')?_c('p',{staticClass:"font-bold",class:question.has_answered_correctly
                            ? 'text-success'
                            : 'text-danger'},[_vm._v(" "+_vm._s(!answer.is_correct ? 'True' : 'False')+" ")]):_c('p',{staticClass:"font-bold",class:{
                          'text-danger':
                            answer.has_choosen && !answer.is_correct,
                          'text-success':
                            answer.is_correct && answer.has_choosen
                        },domProps:{"innerHTML":_vm._s(answer.description)}})],1)]},proxy:true}],null,true)})],1)}),0),_c('div',{staticClass:"mt-2 flex"},[_c('vs-card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h6',[_vm._v(_vm._s(_vm.$t('basic.correct_answer'))+" :")])]),_c('div',[_c('ul',{staticClass:"list-disc"},_vm._l((question.answers),function(answer,ansx){return _c('li',{key:ansx,staticClass:"deco"},[(
                          !question.has_answered_correctly &&
                            question.type.type == 'True or False'
                        )?_c('div',[_c('p',{staticClass:"font-bold"},[_vm._v(" "+_vm._s(answer.is_correct ? 'True' : ' False')+" ")])]):(
                          answer.is_correct &&
                            question.type.type != 'True or False'
                        )?_c('div',[_c('p',{staticClass:"font-bold",domProps:{"innerHTML":_vm._s(answer.description)}})]):_vm._e()])}),0)])])],1)],1):_vm._e(),(_vm.questions.length - 1 > idx)?_c('hr',{staticClass:"text-grey my-4"}):_vm._e()],1)})],2):_c('vs-row',[_c('vs-col',{staticClass:"mb-4",attrs:{"vs-w":"12"}},[_c('label',{staticClass:"vx-col w-full"},[_vm._v(_vm._s(_vm.$t('exam.total_points')))]),_c('vx-input-group',[_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:(`required|max_value:${_vm.exam.points}|numeric`),expression:"`required|max_value:${exam.points}|numeric`"}],staticClass:"w-full",attrs:{"name":`exam points`,"placeholder":`maximum point is ${_vm.exam.points}`},model:{value:(_vm.exam.score),callback:function ($$v) {_vm.$set(_vm.exam, "score", $$v)},expression:"exam.score"}}),_c('template',{slot:"append"},[_c('div',{staticClass:"append-text bg-primary"},[_c('span',[_vm._v(_vm._s(_vm.$t('exam.out_of'))+" "+_vm._s(_vm.exam.points))])])])],2),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(_vm.errors.first(`exam points`)))])],1),_c('vs-col',{attrs:{"vs-w":"12"}},[(
              _vm.exam.extra_doc_link &&
                _vm.exam.extra_doc_link != _vm.institutionAssetsLink
            )?_c('div',{staticClass:"w-full mt-4"},[_c('center',[_c('embed',{attrs:{"src":_vm.exam.attachments[0].attachment,"width":"100%","height":"500"}})]),_c('a',{ref:"file",staticClass:"mt-4",staticStyle:{"display":"block"},attrs:{"href":_vm.exam.attachments[0].attachment,"download":""}},[_c('vs-button',{staticClass:"mb-base mr-0 ml-auto"},[_vm._v(_vm._s(_vm.$t('form.download_file')))])],1)],1):_vm._e()])],1),_c('div',{staticClass:"modal-header"},[_c('h4',[_vm._v(_vm._s(_vm.$t('exam.exam_assessment')))])]),_c('div',{staticClass:"mt-4 mb-12"},[_c('vs-row',{staticClass:"mb-2",attrs:{"vs-w":"12"}},[_c('vs-col',{staticClass:"flex-col px-3",attrs:{"vs-justify":"start","vs-lg":"12","vs-sm":"12","vs-type":"flex","vs-xs":"12"}},[(_vm.currentSection == 'PYP')?_c('div',{staticClass:"vx-col w-full"},[_c('div',{staticClass:"vs-input--label"},[_vm._v(" "+_vm._s(_vm.$t('exam.choose_criteria'))+" ")]),_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:{ 'validation-error': _vm.errors.has('criteria') },attrs:{"clearable":false,"closeOnSelect":true,"dir":_vm.$vs.rtl ? 'rtl' : 'ltr',"options":_vm.criterias,"label":"title","name":"criteria"},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_c('span',[_vm._v(_vm._s(option.title)+" - "+_vm._s(option.key))])]}},{key:"option",fn:function(option){return [_c('div',{staticClass:"flex justify-start align-center"},[_c('span',[_vm._v(_vm._s(option.title)+" - "+_vm._s(option.key))])])]}}],null,false,2381148024),model:{value:(_vm.selectedCriteria),callback:function ($$v) {_vm.selectedCriteria=$$v},expression:"selectedCriteria"}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('criteria')),expression:"errors.has('criteria')"}],staticClass:"text-danger text-sm"},[_vm._v(" "+_vm._s(_vm.$t('exam.criteria_is_required'))+" ")])],1):(
                _vm.currentSection === 'AMERICAN' || _vm.currentSection === 'DP'
              )?_c('div',{staticClass:"vx-col"},[_c('div',{staticClass:"vs-input--label"},[_vm._v(_vm._s(_vm.$t('form.select_grade')))]),_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],class:{ 'validation-error': _vm.errors.has('grade') },attrs:{"clearable":false,"closeOnSelect":true,"dir":_vm.$vs.rtl ? 'rtl' : 'ltr',"options":_vm.criterias,"min":"1","name":"title","label":"title"},model:{value:(_vm.selectedCriteria),callback:function ($$v) {_vm.selectedCriteria=$$v},expression:"selectedCriteria"}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('grade')),expression:"errors.has('grade')"}],staticClass:"text-danger text-sm"},[_vm._v(" "+_vm._s(_vm.$t('form.grade_is_required'))+" ")])],1):_vm._e(),(_vm.currentSection === 'MYP')?[_c('vs-row',{staticClass:"mb-2",attrs:{"vs-w":"12"}},_vm._l((_vm.criterias),function(item,idx){return _c('vs-col',{key:idx,staticClass:"flex-col mr-2",attrs:{"vs-justify":"start","vs-lg":"4","vs-sm":"4","vs-type":"flex","vs-xs":"6"}},[_c('div',{staticClass:"vx-col w-full mt-2"},[_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:({
                        required: true,
                        min_value: 1,
                        max_value: 8,
                        numeric: true
                      }),expression:"{\n                        required: true,\n                        min_value: 1,\n                        max_value: 8,\n                        numeric: true\n                      }"}],staticClass:"w-full",class:{ 'validation-error': _vm.errors.has(item.key) },attrs:{"label":item.title,"name":item.key,"min":"1","placeholder":"set mark"},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has(item.key)),expression:"errors.has(item.key)"}],staticClass:"text-danger text-sm"},[_vm._v(" "+_vm._s(_vm.errors.first(item.key))+" ")])],1)])}),1)]:_vm._e()],2),_c('vs-col',{staticClass:"flex-col px-3 mt-4",attrs:{"vs-justify":"start","vs-lg":"12","vs-sm":"12","vs-type":"flex","vs-xs":"12"}},[_c('div',{staticClass:"w-full"},[_c('vs-textarea',{staticClass:"w-full student-note",attrs:{"label":_vm.$t('basic.send_message_to_student')},model:{value:(_vm.teacherComment),callback:function ($$v) {_vm.teacherComment=$$v},expression:"teacherComment"}})],1)])],1)],1)],1):_vm._e()]),_c('div',{staticClass:"modal-footer"},[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col flex w-full justify-center mb-5"},[_c('vs-button',{staticClass:"mx-2",attrs:{"color":"warning","type":"border"},on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.$t('basic.close')))]),_c('vs-button',{on:{"click":function($event){return _vm.submitForm()}}},[_vm._v(" Submit")])],1)])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }