<template>
  <div>
    <users-form></users-form>
  </div>
</template>
<script>
import assignRoleToUserForm from './components/assignRoleToUserForm.vue'
export default {
  name: 'AssignRoleToUser',
  components: {
    assignRoleToUserForm
  },
  data () {
    return {

    }
  }
}
</script>
