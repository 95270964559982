<template>
  <ListPageWithCreateButton
    createRoutName="addCertificateWithTerm"
    :createBtnName="$t('inventory.uploadReportCard')">
    <template slot="leftSideButtonContent">
      <!-- <router-link class="vx-col lg:w-1/7" :to="{name: 'addCertificate'}"><vs-button
               color="primary"
               class="w-100"
             >  {{$t('inventory.finalReportCard')}}
             </vs-button>
             </router-link> -->
    </template>
    <template slot="listDataContent">
      <inventory-table
        :headers_prop="headers"
        list_url_prop="/grading/inventories"
        :side_filters="sideFilters"
        :released="true"
        :banned="true"
        @selectedData="getSelected"
        @released="released"
        @banned="banned"
        @remove="showRemovePopup"
        :reCallRequest="reCallRequest"
        :list_items="searchResult">
        <template slot="center_content">
          <!--  TODO: add the appropriate export element -->
          <!--            <h3>Center Content</h3>-->
        </template>
      </inventory-table>
    </template>
    <template slot="rightSide">
      <RoundFilter
        :filters_prop="custom_filter"
        @refilter="setFilters"
        @resetAll="restSideFilters"
        :loading="loadingFilter">
        <template slot="extra_content">
          <div class="w-full mb-10">
            <vs-input
              :label-placeholder="$t('form.search_by_name_email_or_ID')"
              v-model="searchContent"
              v-validate="{email: searchContent.includes('@')}"
              class="w-full"
              name="email"
              icon="icon icon-search"
              icon-pack="feather"
              @change="setFilters" />
            <span class="text-sm text-danger" v-if="errors.first('email')">{{
              errors.first('email') | text_formatter
            }}</span>
          </div>
        </template>
      </RoundFilter>
    </template>
    <vs-prompt
      @accept="remove"
      :active.sync="removeMulti"
      title="Remove"
      type="confirm"
      accept-text="Remove"
      color="danger">
      <div class="con-exemple-prompt">
        <p>{{ $t('inventory.areYouSureToDeleteThoseRows') }}</p>
      </div>
    </vs-prompt>
  </ListPageWithCreateButton>
</template>

<script>
import InventoryTable from '@/components/general/datatable/DataTable2.vue'
import RoundFilter from '@/components/general/RoundFilter'
import {commonDataMixin} from '@/mixins'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const inventoryRepository = RepositoryFactory.get(
  'inventoryRepository',
  'inventoryRepository'
)
export default {
  name: 'walletList',
  components: {
    InventoryTable,
    RoundFilter
  },
  mixins: [commonDataMixin],
  data() {
    return {
      searchContent: '',
      headers: [
        {
          minWidth: 160,
          headerName: '',
          checkboxSelection: true,
          width: 150,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true
        },
        {
          minWidth: 150,
          headerName: this.$t('basic.ID'),
          field: 'student.code'
        },
        {
          minWidth: 250,
          headerName: this.$t('basic.name'),
          field: 'student.name'
        },
        {
          minWidth: 150,
          headerName: this.$t('basic.academic_year'),
          field: 'academic_year.name'
        },
        {
          minWidth: 150,
          headerName: this.$t('basic.division'),
          field: 'student.division.name'
        },
        {
          minWidth: 150,
          headerName: this.$t('basic.class'),
          field: 'student.class.name'
        },
        {
          minWidth: 150,
          headerName: this.$t('Syllabus.terms'),
          field: 'term'
        },
        {
          minWidth: 150,
          headerName: this.$t('basic.type'),
          field: 'type'
        },
        {
          minWidth: 150,
          headerName: this.$t('basic.status'),
          field: 'status',
          cellRendererFramework: 'CellRendererStatus',
          cellRendererParams: {
            status_prop: this.status
          }
        },
        {
          minWidth: 150,
          headerName: this.$t('basic.actions'),
          filter: false,
          field: 'actions',
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            actions_prop: [
              {
                dropDown: true,
                dropDown_items: [
                  {
                    title: this.$t('basic.delete'),
                    parameter_name: 'id',
                    color: 'danger',
                    requestType: 'delete',
                    resource: '/grading/inventories/:id',
                    icon: 'TrashIcon'
                  },
                  {
                    title: this.$t('status.ban'),
                    parameter_name: 'id',
                    icon: 'PowerIcon',
                    requestType: 'post',
                    color: 'warning',
                    msg:this.$t('status.you_are_about_to_ban_the_selected')+' '+this.$t('inventory.reportCard'),
                    requestPayload: {status: 'banned'},
                    resource: '/grading/inventories/:id/update-status',
                    showIf: (data) => data.status !== 'banned'
                  },
                  {
                    title: this.$t('status.release'),
                    parameter_name: 'id',
                    icon: 'CheckIcon',
                    requestType: 'post',
                    color: 'primary',
                    requestPayload: {status: 'released'},
                    msg:this.$t('status.you_are_about_to_release_the_selected')+' '+this.$t('inventory.reportCard'),
                    resource: '/grading/inventories/:id/update-status',
                    showIf: (data) => data.status === 'banned'
                  },
                  {
                    title: this.$t('basic.download'),
                    icon: 'DownloadIcon',
                    actionEmitter: 'download',
                    actionEmitterParams: ['media_url']
                  }
                ]
              }
            ]
          }
        }
      ],
      loading: false,
      // filters
      loadingFilter: false,
      searchContent: '',
      searchResult: null,
      custom_filter: [
        {
          id: 1,
          name: this.$t('basic.academic_year'),
          payload_name: 'academic_year_id',
          onChangeFireAction: true,
          singleSeletable: false,
          by: []
        },
        {
          id: 8,
          name: this.$t('basic.schools'),
          payload_name: 'schools',
          onChangeFireAction: false,
          by: []
        },
        {
          id: 2,
          name: this.$t('basic.divisions'),
          payload_name: 'divisions',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 3,
          name: this.$t('basic.sections'),
          payload_name: 'sections',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 4,
          name: this.$t('basic.status'),
          payload_name: 'status',
          singleSeletable: false,
          by: [
            {
              id: 'released',
              name: this.$t('status.released'),
              checked: false
            },
            {
              id: 'banned',
              name: this.$t('status.banned'),
              checked: false
            }
          ]
        },
        {
          id: 5,
          name: this.$t('basic.type'),
          payload_name: 'type',
          singleSeletable: false,
          by: [
            {
              id: 'uploaded',
              name: this.$t('inventory.uploaded'),
              checked: false
            },
            {
              id: 'grade book',
              name: this.$t('grading.gradebook'),
              checked: false
            }
          ]
        },
        {
          id: 6,
          name: this.$t('basic.grades'),
          payload_name: 'grades',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 7,
          name: this.$t('basic.class_groups'),
          payload_name: 'classes',
          by: []
        }
      ],
      sideFilters: {
        general_search: '',
        schools: [],
        schools: [],
        divisions: [],
        sections: [],
        grades: [],
        classes: []
      },
      selectedRows: [],
      reCallRequest: false,
      removeMulti: false
    }
  },

  methods: {
    setFilters(val) {
      this.sideFilters["general_search"] = this.searchContent;
      val.map((item) => {
        if (item.checked) {
          this.sideFilters[item.name] = item.checked
        }
      })
    },
    restSideFilters() {
      this.custom_filter[0].by = []
      this.custom_filter[1].by = []
      this.custom_filter[2].by = []
      this.custom_filter[3].by = []
      this.custom_filter[4].by = [
        {
          id: 'released',
          name: this.$t('status.released'),
          checked: false
        },
        {
          id: 'banned',
          name: this.$t('status.banned'),
          checked: false
        }
      ]
      this.custom_filter[5].by = [
        {
          id: 'uploaded',
          name: this.$t('inventory.uploaded'),
          checked: false
        },
        {
          id: 'gradebook',
          name: this.$t('grading.gradebook'),
          checked: false
        }
      ]
      this.custom_filter[6].by = []
      this.custom_filter[7].by = []
      this.sideFilters = {
        schools: [],
        divisions: [],
        sections: [],
        grades: [],
        classes: []
      }
      this.getFiltersData()
    },
    getFiltersData() {
      this.getFilterData().then((res) => {
        res.schools.map((el) => {
          this.custom_filter[1]['by'].push({
            id: el.id,
            name: el.branch_name,
            checked: false
          })
        })
      })
      this.getAllAcademic().then((res) => {
        res.map((el) => {
          this.custom_filter[0]['by'].push({
            id: el._id,
            name: el.name,
            checked: false
          })
        })
      })
    },
    getFilteredDivisions(chosen) {
      this.loadingFilter = true
      this.custom_filter[2]['by'] = []
      this.custom_filter[3]['by'] = []
      this.getFilterData({schools: chosen.schools})
        .then((res) => {
          res.divisions.map((el) => {
            this.custom_filter[2]['by'].push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
        })
        .finally(() => {
          this.loadingFilter = false
        })
    },
    getFilteredSectionsAndSubjects(chosen) {
      this.loadingFilter = true
      this.custom_filter[3]['by'] = []
      const data = {
        divisions: chosen.divisions,
        schools: chosen.schools
      }
      this.getFilterData(data)
        .then((res) => {
          res.sections.map((el) => {
            this.custom_filter[3]['by'].push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
        })
        .finally(() => {
          this.loadingFilter = false
        })
    },
    getFilteredGrades(chosen) {
      this.loadingFilter = true
      this.custom_filter[6].by = []
      const data = {
        schools: chosen.schools,
        divisions: chosen.divisions,
        sections: chosen.sections
      }
      this.getFilterData(data)
        .then((res) => {
          res.grades.map((el) => {
            this.custom_filter[6]['by'].push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
        })
        .finally(() => {
          this.loadingFilter = false
        })
    },
    getFilteredClasses(chosen) {
      this.loadingFilter = true
      this.custom_filter[7].by = []
      const data = {
        schools: chosen.schools,
        divisions: chosen.divisions,
        sections: chosen.sections,
        grades: chosen.grades
      }
      this.getFilterData(data)
        .then((res) => {
          res.grades_classes.map((el) => {
            this.custom_filter[7]['by'].push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
        })
        .finally(() => {
          this.loadingFilter = false
        })
    },
    getSelected(data) {
      this.selectedRows = data
    },
    banned() {
      this.reCallRequest = false
      inventoryRepository
        .updateStatus({ids: this.selectedRows, status: 'banned'})
        .then((res) => {
          this.reCallRequest = true
          this.selectedRows = []
          this.successMsg(res.status)
        })
    },
    released() {
      this.reCallRequest = false
      inventoryRepository
        .updateStatus({ids: this.selectedRows, status: 'released'})
        .then((res) => {
          this.reCallRequest = true
          this.selectedRows = []
          this.successMsg(res.status)
        })
    },
    showRemovePopup() {
      this.removeMulti = true
    },
    remove() {
      this.reCallRequest = false
      inventoryRepository
        .deleteMultiInventory({ids: this.selectedRows})
        .then((res) => {
          this.reCallRequest = true
          this.selectedRows = []
          this.successMsg(res.status)
        })
    },

  },
  created() {
    this.$root.$on('onChange:schools', this.getFilteredDivisions)
    this.$root.$on('onChange:divisions', this.getFilteredSectionsAndSubjects)
    this.$root.$on('onChange:sections', this.getFilteredGrades)
    this.$root.$on('onChange:grades', this.getFilteredClasses)
    this.$root.$on('download', (data) => {
      const urlArr = data.media_url.split('/')
      this.downloadFile(data.media_url, urlArr[urlArr.length - 1])
    })
    this.getFiltersData()
  },
  watch: {
    searchContent(v) {
      if (!v) this.searchResult = null
    }
  },
  computed: {},
  beforeDestroy() {
    this.$root.$off('onChange:schools')
    this.$root.$off('onChange:divisions')
    this.$root.$off('onChange:sections')
    this.$root.$off('onChange:grades')
    this.$root.$off('download')
  }
}
</script>

<style lang="scss" scoped>
.active .vx-card {
  -webkit-box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
  box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
}
</style>
