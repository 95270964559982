var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadDialog),expression:"loadDialog"}],ref:"loadDialog",staticClass:"vs-con-loading__container",attrs:{"id":"loadDialog"}}),_c('vs-table',{staticClass:"m-auto my-10",class:{ 'no-data': !_vm.data.length },attrs:{"data":_vm.data,"hoverFlat":true,"noDataText":!_vm.loadDialog ? _vm.$t('assessments.no_students_assessed') : '',"id":"student-score-table"},scopedSlots:_vm._u([{key:"default",fn:function({ data }){return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr,attrs:{"data":tr}},[_vm._l((tr),function(row_data,field,indx){return [(
              field !== 'score' &&
              field !== 'grade_id' &&
              field !== 'criteria'
            )?_c('vs-td',{key:indx,class:{ 'text-center': field !== 'name' }},[(field === 'showInGrading')?_c('vs-checkbox',{attrs:{"vs-value":tr.showInGrading,"disabled":!tr.showInGrading},model:{value:(tr.showInGrading),callback:function ($$v) {_vm.$set(tr, "showInGrading", $$v)},expression:"tr.showInGrading"}}):_c('span',{staticClass:"text-center"},[_vm._v(" "+_vm._s(row_data))])],1):_vm._e()]}),_c('vs-td',[_c('div',{},[(_vm.isPYPSection)?[_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:({
                  required: !Boolean(tr.score),
                }),expression:"{\n                  required: !Boolean(tr.score),\n                }"}],staticClass:"w-full",attrs:{"options":tr.criteria,"label":"title","reduce":(c) => c.key,"placeholder":_vm._f("text_formatter")(_vm.$t('form.grade')),"name":`${tr.name}_grade`},model:{value:(tr.score),callback:function ($$v) {_vm.$set(tr, "score", $$v)},expression:"tr.score"}})]:(_vm.isMYPSection)?_vm._l((tr.criteria),function(cr,i){return _c('div',{key:i,staticClass:"flex items-center",class:{ 'mb-3': tr.criteria.length }},[_c('label',{staticClass:"w-1/5 mr-2"},[_vm._v(_vm._s(cr.key))]),_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:({
                    required: true,
                    numeric: true,
                    max_value: 8,
                    min_value: 0,
                  }),expression:"{\n                    required: true,\n                    numeric: true,\n                    max_value: 8,\n                    min_value: 0,\n                  }"}],staticClass:"w-4/5",attrs:{"type":"number","max":8,"min":"0","placeholder":_vm._f("text_formatter")(_vm.$t('form.grade')),"name":`${tr.name}_grade`},model:{value:(tr.score[cr.key]),callback:function ($$v) {_vm.$set(tr.score, cr.key, $$v)},expression:"tr.score[cr.key]"}})],1)}):(_vm.isDPSection || _vm.isAmerican)?[_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:({ required: !Boolean(tr.score) }),expression:"{ required: !Boolean(tr.score) }"}],staticClass:"w-full",attrs:{"options":tr.criteria,"placeholder":_vm._f("text_formatter")(_vm.$t('form.grade')),"name":`${tr.name}_grade`,"value":tr.score},model:{value:(tr.score),callback:function ($$v) {_vm.$set(tr, "score", $$v)},expression:"tr.score"}})]:_vm._e(),_c('span',{staticClass:"text-danger text-sm block w-full"},[_vm._v(_vm._s(_vm._f("text_formatter")(_vm.errors.first(`${tr.name}_grade`))))])],2)])],2)})}}])},[(_vm.data.length)?_c('template',{slot:"thead"},[_c('vs-th'),_vm._l((_vm.headers),function(tr,indextr){return _c('vs-th',{key:indextr,staticClass:"table__header",class:{ 'text-center': tr.field !== 'name' },attrs:{"sort-key":tr.field}},[_vm._v(" "+_vm._s(tr.title)+" ")])})],2):_vm._e()],2),_c('div',{staticClass:"flex justify-between items-center"},[_c('vs-button',{staticClass:"mt-2",attrs:{"type":"border","color":"danger"},on:{"click":_vm.closeUpdateStudentGrades}},[_vm._v(_vm._s(_vm.$t("basic.cancel"))+" ")]),(_vm.data.length)?_c('vs-button',{staticClass:"mt-2 mx-2",on:{"click":_vm.submitUpdateStudentGrades}},[_vm._v(_vm._s(_vm.$t("form.submit"))+" ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }