import axios from 'axios'
const resource = 'grading/round'
export default {
  getAllGradingRounds (filtersPayload, page) {
    return axios.get(`${resource}`, {params:{...filtersPayload, page}})
  },
  createGradingRound (payload) {
    return axios.post(`${resource}`, payload)
  },
  showGradingRound (roundId) {
    return axios.get(`${resource}/${roundId}`)
  },
  UpdateGradingRound (roundId, payload) {
    return axios.put(`${resource}/${roundId}`, payload)
  },
  changeStatus (id, newStatus) {
    return axios.get(`${resource}/change-status/${id}/${newStatus}`)
  },
  checkRoundOfClass (classId, subjectId) {
    return axios.get(`${resource}/class/${classId}/${subjectId}`)
  }
}
