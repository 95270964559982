export default {
  name: 'singleFilePreviewer',
  props: {
    fileType: { type: String },
    fileUrl: { type: String }
  },
  data () {
    return {
      mimeTypes: {
        png: 'image',
        jpeg: 'image',
        jpg: 'image',
        mp4: 'video',
        pdf: 'pdf',
        docx: 'wordFile',
        doc: 'wordFile',
        xlsx: 'excelFile',
        xls: 'excelFile',
        ppt: 'powerPointFile',
        pptx: 'powerPointFile'
      },
      type: null,
      url: null
    }
  },
  Watch: {
    fileUrl: {
      Handler: 'loadData',
      Immediate: true
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    loadData () {
      this.type = this.mimeTypes[this.fileType]
      this.url = this.fileUrl
    }
  }
}
