<template>
  <section class="tasks">
    <assignment-modal :assignmentItem="assignment"></assignment-modal>
  </section>
</template>
<script>
import AssignmentModal from '../components/assignment-modal/assignment-modal.vue'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const StudentRepository = RepositoryFactory.get('StudentsModule', 'student')
export default {
  components: {
    AssignmentModal
  },
  data () {
    return {
      assignment: {},
      assignmentId: this.$route.params.id
    }
  },
  created () {
    this.getSingleAssignment()
  },
  watch: {
    $route (newVal, oldVal) {
      if (newVal.name !== oldVal.name) this.updateAssignment(newVal.params.id)
    }
  },
  methods: {
    getSingleAssignment () {
      this.VSLoading()
      StudentRepository.getSingleAssignment(this.assignmentId)
        .then(res => {
          this.assignment = res.data
        })
        .finally(() => {
          this.stopVSLoading()
        })
    },
    updateAssignment (toParams) {
      this.assignmentId = toParams
      this.getSingleAssignment()
    }
  },
  beforeRouteUpdate (to, from, next) {
    const fromParams = from.params.id
    const toParams = to.params.id
    if (fromParams !== toParams && from.name === to.name) {
      this.updateAssignment(toParams)
      next()
    }
  }
}
</script>
<style lang="scss" src="./assignments.scss"></style>
