import axios from 'axios'

export default {
  search (data, payload) { // search by username , email or Id
    return axios.post(`/mobile/logs?search=${data}`, payload)
  },
  getAllroles () {
    return axios.get('/mobile/logs/roles')

  }
}
