<template >
  <separatedPage :componentName="dynamicComponent"></separatedPage>
</template>
<script>
import separatedPage from './Components/SeparatedPage.vue'
import mydayParent from './Components/Left-Sidebar/myDayParent'
export default {
  name:'myDayParent',
  components:{
    separatedPage,
    mydayParent
  },
  data: () => ({
    dynamicComponent:mydayParent
  })
}
</script>
<style lang="">
</style>
