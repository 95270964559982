<template>
  <div >
    <div class="vx-row">
    <div class="vx-col text-danger mb-base  text-center md:w-3/3 w-full"  v-if="errors" >
      <ul class="list-group-item text-center" >
       <li v-if="errorsArr=='status'"> * error you should add time of meeting </li>
      </ul>
    </div>
    </div>
  <div class="vx-row">
    <div class="vx-col md:w-1/3 w-full mb-base">
      <left-form type="edit" :evaluation:application.sync="evaluation.application"></left-form>
    </div>
    <!-- HORIZONTAL LAYOUT -->
    <div class="vx-col md:w-2/3 w-full mb-base">
      <right-form type="edit" @saveEvaluation="saveEvaluation" :evaluation:criteria_ratings.sync="evaluation.criteria_ratings" :evaluation:evaluation_data.sync="evaluation.evaluation_data" :evaluation:evaluation_notes.sync="evaluation.evaluation_notes"></right-form>
    </div>
  </div>
  </div>
</template>

<script>

import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const EvaluationRepository = RepositoryFactory.get('AdmissionModule', 'evaluations')
import RightForm from './components/right-form'
import LeftForm from './components/left-form'
import moment from 'moment'

export default {
  name: 'Edit',
  components: {

    RightForm,
    LeftForm
  },
  data () {
    return {
      errorsArr:[],
      evaluation:{

        application: {
          status: 2,
          id: '1',
          meeting_timestamp: '',
          type: '',
          gate: '',
          building: ''
        },
        evaluation_notes: [
          {
            user_type: 'registerer',
            notes: ['']
          },
          {
            user_type: 'assessor',
            notes: ['']
          },
          {
            user_type: 'principal',
            notes: ['']
          },
          {
            user_type: 'director',
            notes: ['']
          }
        ]
      },
      issueSubmitted: false,
      current_locale: this.$store.getters['main/currentLocale']
    }
  },
  created () {
    this.getData()


  },
  methods: {
    format_date (value) {
      if (value) {
        return moment(String(value)).format('DD-MM-YYYY h:mm')
      }
    },
    addEmail () {


    },

    removeEmail (index) {
      this.round.contacts.email.splice(index + 1)
    },

    getData () {
      EvaluationRepository
        .getEvaluation(this.$route.params.id)
        .then(res => {
          this.evaluation = res.data

          if (res.data.application.meeting_timestamp != null && res.data.application.status == 5) {
            this.evaluation.application.meeting_timestamp = this.format_date(res.data.application.meeting_timestamp)

          }
        })
    },
    saveEvaluation () {
      // this.issueSubmitted = true
      this.$validator.validate().then(valid => {
        if (valid) {
          EvaluationRepository
            .createEvaluation(this.evaluation)
            .then(res => {
              this.$router.push({name:'applications-list'})
              this.$vs.notify({
                color: 'success',
                title: 'Success',
                text: 'New evaluation has been added successfully'
              })
            },    (error) => {

              this.errorsArr = []
              if (error.status) {
                this.errorsArr = 'status'
              }

            })
        }
      })
    }
  },
  computed:{

  }
}
</script>

<style scoped>
.background-none {
  background-color: transparent !important;
}
</style>
