export default {
  SubjectsCreate: 'Create Subject',
  code: 'Code',
  AcademicYear: 'Academic Year',
  CreatedBy: 'Created By',
  division: 'Division',
  online: 'Online',
  Status: 'Status',
  status: 'Status',
  Actions: 'Actions',
  Subjects: 'Subjects',
  Name: 'Name',
  name: 'Name',
  ChooseDivision: 'Choose Division',
  AllDivisions: 'All Divisions',
  ChooseGrads: 'Choose Grade',
  AllGrads: 'All Grads',
  SubjectsShow: 'Show Subject',
  SubjectsList: 'Subjects List',
  subjects: 'Subjects',
  SubjectsEdit: 'Edit Subjects',
  nickname: 'Nickname',
  grades: 'Grades'
}
