import SMDRepositories from './subModules/smd/Repositories'
import SNDRepositories from './subModules/snd/Repositories'
const resource = 'grading'
import axios from 'axios'
export default {
  ...SMDRepositories,
  ...SNDRepositories,
  common: {
    getGradingTypes () {
      return axios.get(`${resource}/types`)
    }
  }
}
