export default {
  Profile: 'Profile',
  Academic: 'Academic',
  Attendance: 'Attendance',
  Finance: 'Finance',
  Healthcare: 'Healthcare',
  FullName: 'Full Name',
  BirthDate: 'Birth Date',
  Nationality: 'Nationality',
  Gender: 'Gender',
  Telephones: 'Telephones',
  Religion: 'Religion',
  Email: 'Email',
  Address: 'Address',
  ForEmergency: 'For Emergency',
  AttendanceStatus: 'Attendance Status',
  AcademicYearYeargroup: 'Academic Year    Year/group',
  DivisionGrade: 'Division-Grade',
  StudentID: 'Student ID',
  Name: 'Name',
  FatherFullName: 'Father Full  Name',
  WorkDetails: 'Work Details',
  Assign: 'Assign',
  Search: 'Search',
  Student: 'Student',
  Father: 'Father',
  Siblings: 'Siblings',
  Mother: 'Mother',
  AssignSibling: 'Assign Sibling',
  AssignAlumni: 'Assign Alumni',
  Emergency: 'Emergency',
  SendNotification: 'Send Notification',
  EmailStudent: 'Email Student ',
  EmailCustody: 'Email Custody ',
  EmailParents: 'Email Parents ',
  EditInformation: 'Edit Information',
  Certificates: 'Certificates',
  Documents: 'Documents',
  PrintProfile: 'Print Profile',
  CreatePDF: 'Create PDF',
  RevokeAccess: 'Revoke Access',
  MyProfile: 'My Profile',
  Active: 'Active',
  Alumni: 'Alumni',
  Sibling: 'Sibling',
  ChildProfile: 'Child profile',
  Admission: 'Admission',
  SchoolFees: 'School Fees',
  EmailSchool: 'Email School',
  Settings: 'Settings ',
  Logout: 'Logout',
  SiblingFullName: 'Sibling FullName',
  MotherFullName: 'Mother FullName',
  expandMore: 'expand more',
  calender: 'calender'

}
