<template>
  <ListPageWithCreateButton
    createRoutName="add-teacher"
    :showCreateBtn="this.checkUserRole('SND')"
    :createBtnName="$t('teachersGateway.addTeacher')">
    <template slot="listDataContent">
      <teacherListTable
        :import_users="'teacher/import'"
        :reset_paswwords="'teacher/reset-password'"
        :export_csv_method="'post'"
        :export_pdf_method="'post'"
        :pdf_url="'teacher/pdf'"
        :csv_url="'teacher/CSV'"
        :headers_prop="headers"
        :list_url_prop="`/teacher/filter`"
        ref="teacherGatewayTable"
        :side_filters="sideFilters"
        total="1"
        :floatingFilter="false"
        :list_items="searchResult"
        :loading="loading" />
    </template>
    <template slot="rightSide">
      <TeachersFilter
        :filters_prop="custom_filter"
        @refilter="setFilters"
        :loading="loadingFilter">
        <template slot="extra_content">
          <div class="w-full mb-10">
            <vs-input
              :label-placeholder="$t('form.search_by_name_email_or_ID')"
              v-model="searchContent"
              v-validate="{email: searchContent.includes('@')}"
              class="w-full"
              name="email"
              icon="icon icon-search"
              icon-pack="feather"
              @change="setFilters" />
            <span class="text-sm text-danger" v-if="errors.first('email')">{{
              errors.first('email') | text_formatter
            }}</span>
          </div>
        </template>
      </TeachersFilter>
    </template>
  </ListPageWithCreateButton>
</template>

<script>
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const addTeacherRepo = RepositoryFactory.get(
  'teachersGatewayModule',
  'addTeacher'
)

import TeachersFilter from '@/components/general/RoundFilter'
import teacherListTable from '@/components/general/datatable/DataTable2.vue'
import {commonDataMixin, authUserMixin} from '@/mixins.js'

export default {
  mixins: [commonDataMixin, authUserMixin],
  data() {
    return {
      sidebar_filter_choosen: {},
      // -----Start Headers Of Table -----
      headers: [
        {
          minWidth: 95,
          headerName: this.$t('basic.ID'),
          field: 'id'
        },
        {
          minWidth: 200,
          headerName: this.$t('form.fullName'),
          valueGetter:
            '`${data.first_name} ${data.second_name} ${data.third_name}`'
        },
        {
          minWidth: 130,
          headerName: this.$t('basic.division'),
          field: 'division_name'
        },
        {
          minWidth: 130,
          headerName: this.$t('basic.mobile'),
          field: 'phone'
        },
        {
          minWidth: 240,
          headerName: this.$t('teachersGateway.teacher_role'),
          field: 'role_name'
        },
        {
          minWidth: 110,
          headerName: this.$t('basic.status'),
          field: 'status',
          cellRendererFramework: 'CellRendererStatus'
        }
      ],
      sideFilters: {
        school: [],
        academic_year_id: '',
        general_search: '',
        division_id: [],
        section_id: [],
        status: [],
        gender: [],
        subject_ids: []
      },
      subjects: [],
      schools: [],
      custom_filter: [
        {
          id: 1,
          name: this.$t('basic.academic_year'),
          payload_name: 'academic_year_id',
          onChangeFireAction: true,
          singleSeletable: false,
          by: []
        },
        {
          id: 8,
          name: this.$t('basic.schools'),
          payload_name: 'school',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 2,
          name: this.$t('basic.divisions'),
          payload_name: 'division_id',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 3,
          name: this.$t('basic.sections'),
          payload_name: 'section_id',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 4,
          name: this.$t('basic.status'),
          payload_name: 'status',
          by: [
            {
              id: 'active',
              name: this.$t('active'),
              checked: false
            },
            {
              id: 'suspended',
              name: this.$t('suspend'),
              checked: false
            },
            {
              id: 'archived',
              name: this.$t('archived'),
              checked: false
            }
          ]
        },
        {
          id: 5,
          name: this.$t('basic.subject'),
          payload_name: 'subject_ids',
          by: []
        },
        {
          id: 6,
          name: this.$t('basic.gender'),
          payload_name: 'gender',
          by: []
        },
        {
          id: 7,
          name: this.$t('admission.spoken_language'),
          payload_name: 'spoken_language_ids',
          by: []
        }
      ],
      // -----End Headers Of Table -----
      // Search
      searchContent: '',
      searchResult: null,
      loading: false,
      loadingFilter: false
    }
  },
  watch: {
    searchContent(v) {
      if (!v) this.searchResult = null
    }
  },

  methods: {
    setFilters(val) {
      // to enable watcher when typing on the search input
      this.sideFilters["general_search"] = this.searchContent;

      if (Array.isArray(val)) {
        val.map((item) => {
          this.sideFilters[item.name] = item.checked
        });
      }
    },
    getFilteredDivisions(school_id) {
      this.loadingFilter = true

      this.custom_filter[2]['by'] = []
      this.custom_filter[3]['by'] = []
      this.custom_filter[5]['by'] = []

      this.getFilterData({schools: school_id})
        .then((res) => {
          res.divisions.map((el) => {
            this.custom_filter[2]['by'].push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
        })
        .finally(() => {
          this.loadingFilter = false
        })
    },
    getFilteredSectionsAndSubjects(grade_ids) {
      this.loadingFilter = true

      this.custom_filter[3]['by'] = []
      this.custom_filter[5]['by'] = []
      const data = {
        divisions: grade_ids,
        schools: this.sideFilters.school
      }
      this.getFilterData(data)
        .then((res) => {
          res.sections.map((el) => {
            this.custom_filter[3]['by'].push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
          res.divisions_subjects.map((el) => {
            this.custom_filter[5]['by'].push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
        })
        .finally(() => {
          this.loadingFilter = false
        })
    },
    // getFilteredSubjects () {
    // this.loadingFilter = true

    //   this.custom_filter[5]['by'] = []
    //   const data = {
    //     divisions:this.sideFilters.grade_ids,
    //     schools:this.sideFilters.school
    //   }
    //   this.getFilterData(data).then((res) => {
    //     res.divisions_subjects.map((el) => {
    //       this.custom_filter[5]['by'].push({
    //         id: el.id,
    //         name: el.name,
    //         checked: false
    //       })
    //     })
    //   })
    // .finally(() => { this.loadingFilter = false })
    // },


    ///////////////////////////////////
    // Hide , show datatble Actions
    ///////////////////////////////////
    toggleDatatbleActionsCol() {
      const actionsCol = {
        minWidth: 150,
        headerName: this.$t('basic.actions'),
        filter: false,
        field: 'actions',

        cellRendererFramework: 'CellRendererActions',
        cellRendererParams: {
          actions_prop: [
            {
              dropDown: true,
              dropDown_items: [
                {
                  title: this.$t('basic.edit'),
                  parameter_name: 'id',
                  icon: 'EditIcon',
                  navigate_action: true,
                  route_name: 'edit-teacher',
                  showIf: (data) => data.status === 'active'
                },
                {
                  title: this.$t('subject.Subjects'),
                  parameter_name: 'user_id',
                  icon: 'EyeIcon',
                  navigate_action: true,
                  route_name: 'teacherSubjects'
                },
                {
                  title: this.$t('basic.suspended'),
                  parameter_name: 'id',
                  icon: 'ClipboardIcon',
                  action: 'suspended',
                  resource: '/teacher/changestatus/:id',
                  color: 'danger',
                  showIf: (data) => data.status !== 'suspended'
                },
                {
                  title: this.$t('basic.archived'),
                  parameter_name: 'id',
                  icon: 'CheckIcon',
                  action: 'archived',
                  resource: '/teacher/changestatus/:id',
                  color: 'danger',
                  showIf: (data) => data.status !== 'archived'
                },
                {
                  title: this.$t('basic.active'),
                  parameter_name: 'id',
                  icon: 'CheckIcon',
                  action: 'active',
                  resource: '/teacher/changestatus/:id',
                  color: 'danger',
                  showIf: (data) => data.status !== 'active'
                },
                {
                  title: this.$t('teachersGateway.send_credentials'),
                  parameter_name: 'id',
                  icon: 'ApertureIcon',
                  resource: '/teacher/send-one/:id',
                  color: 'danger',
                  showIf: (data) =>
                    data.status !== 'archived' && data.status !== 'suspended'
                },
                {
                  title: this.$t('teachersGateway.create_hod'),
                  getParams: (data) => ({id: data.user_id}),
                  icon: 'PlusCircleIcon',
                  navigate_action: true,
                  route_name: 'create-hod',
                  showIf: (data) =>
                    data.status !== 'archived' && data.status !== 'suspended'
                  // showIf: (data) => data.role_name !== 'HOD' && data.role_name !== 'TeacherHOD'
                },
                {
                  title: this.$t('teachersGateway.create_teacher_parent'),
                  getParams: (data) => ({id: data.user_id}),
                  icon: 'PlusCircleIcon',
                  navigate_action: true,
                  route_name: 'create-teacher-parent',
                  showIf: (data) =>
                    data.status !== 'archived' && data.status !== 'suspended'
                  // showIf: (data) => data.role_name !== 'HOD' && data.role_name !== 'TeacherHOD'
                },
                {
                  title: this.$t('teachersGateway.section_coodinator'),
                  getParams: (data) => ({id: data.user_id}),
                  icon: 'PlusCircleIcon',
                  navigate_action: true,
                  route_name: 'create-section-coodinator',
                  showIf: (data) =>
                    data.status !== 'archived' && data.status !== 'suspended'
                  // showIf: (data) => data.role_name !== 'SectionCoodinator'
                },

                // {
                //   title: this.$t('teachersGateway.section_coodinator'),
                //   getParams: (data) => ({ id: data.user_id }),
                //   icon: 'EditIcon',
                //   navigate_action: true,
                //   route_name: 'edit-section-coodinator'
                //   // showIf: (data) => data.role_name !== 'SectionCoodinator'
                // },
                {
                  title: this.$t('teachersGateway.grade_coodinator'),
                  getParams: (data) => ({id: data.user_id}),
                  icon: 'PlusCircleIcon',
                  navigate_action: true,
                  route_name: 'create-grade-coodinator',
                  showIf: (data) =>
                    data.status !== 'archived' && data.status !== 'suspended'
                },
                {
                  title: this.$t('teachersGateway.back_to_teacher'),
                  resource: (data) => `hod/set-role/${data.user_id}/teacher`,
                  icon: 'SkipBackIcon',
                  color: 'danger',
                  requestType: 'post',
                  showIf: (data) => data.role_name !== 'teacher'
                },
                {
                  title: this.$t('teachersGateway.copy_email'),
                  icon: 'CopyIcon',
                  color: 'danger',
                  actionEmitter: 'copyEmail',
                  actionEmitterParams: [
                    'official_email',
                    'reset_password',
                    'school_name',
                    'first_name',
                    'second_name'
                  ]
                },
                {
                  title: this.$t('basic.act_as'),
                  icon: 'VideoIcon',
                  actionEmitter: 'actAs',
                  actionEmitterParams: [
                    'user_id',
                    'first_name',
                    'fourth_name',
                    'third_name',
                    'second_name'
                  ],
                  showIf: () =>
                    this.checkUserRole('SND') ||
                    this.checkUserRole('SchoolPrincipal')
                }
              ]
            }
          ]
        }
      }
      // if SND
      if (!this.checkUserRole('SMD')) this.headers.push(actionsCol)
    },
    getFiltersData() {
      this.getFilterData().then((res) => {
        res.schools.map((el) => {
          this.custom_filter[1]['by'].push({
            id: el.id,
            name: el.branch_name,
            checked: false
          })
        })
      })
      this.getAllAcademic().then((res) => {
        res.map((el) => {
          this.custom_filter[0]['by'].push({
            id: el._id,
            name: el.name,
            checked: false
          })
        })
      })
      this.getLanguages().then((res) => {
        res.map((el) => {
          this.custom_filter[7]['by'].push({
            id: el.id,
            name: el.name,
            checked: false
          })
        })
      })
      this.getGenders().then((res) => {
        res.map((el) => {
          this.custom_filter[6]['by'].push({
            id: el.name.toLowerCase(),
            name: el.name,
            checked: false
          })
        })
      })
    },
    copyEmail(data) {
      const text = `${data.official_email}`
      navigator.clipboard.writeText(text)
      this.successMsg('Copied sccuess')
    }
  },
  created() {
    this.$root.$on('copyEmail', (data) => this.copyEmail(data))

    this.$root.$on('onChange:school', (v) => {
      this.getFilteredDivisions(v.school)
    })
    this.$root.$on('onChange:division_id', (v) => {
      this.getFilteredSectionsAndSubjects(v.division_id)
      // this.getFilteredSubjects()
    })
    this.getFiltersData()

    this.$root.$on('actAs', (data) => {
      data.name = `${this.$t('main.teacher')} - ${data.first_name} ${
        data.second_name
      } ${data.third_name}`
      data.id = data.user_id
      this.startActAs(data)
    })
  },
  beforeDestroy() {
    this.$root.$off('onChange:school')
    this.$root.$off('onChange:division_id')
    this.$root.$off('copyEmail')
    this.$root.$off('actAs')
  },
  mounted() {
    this.toggleDatatbleActionsCol()
  },
  components: {
    teacherListTable,
    TeachersFilter
  }
}
</script>
