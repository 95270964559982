<template>
  <div>
    <h1>{{ $t('profile.Profile') }} Page</h1>
    <side-menus-container :callBack="testCallback" :menus.sync="menusData" :info.sync="sectionInfo"></side-menus-container>
  </div>
</template>
<script>
export default {
  name:'profile',
  data () {
    return {
      menusData:[
        {
          header:'OverView',
          items:[
            {
              title:'OverView',
              icon:'icon-activity',
              action:'overView'
            },
            {
              title:'Reflecting and Planning',
              icon:'icon-cloud-lightning',
              action:'reflecting'
            },
            {
              title:'Designing',
              icon:'icon-trending-up',
              action:'Designing'
            },
            {
              title:'Planing',
              icon:'icon-map',
              action:'planing'
            }
          ]
        },
        {
          header:'Planner',
          items:[
            {
              title:'OverView',
              icon:'icon-activity',
              action:'overView1'
            },
            {
              title:'Reflecting and Planning',
              icon:'icon-cloud-lightning',
              action:'reflecting1'
            },
            {
              title:'Designing',
              icon:'icon-trending-up',
              action:'designing1'
            },
            {
              title:'Planing',
              icon:'icon-map',
              action:'planing1'
            }
          ]
        },
        {
          header:'Teaching Tools',
          items:[
            {
              title:'Teaching Collage',
              icon:'icon-activity',
              action:'overView2'
            },
            {
              title:'Teaching and Planning',
              icon:'icon-cloud-lightning',
              action:'reflecting2'
            },
            {
              title:'Online Teaching',
              icon:'icon-trending-up',
              action:'designing2'
            }
          ]
        }
      ],
      sectionInfo:{
        header:'Sounds Manipulation',
        items:[
          {title:'Name', value:'Ayman Elkhouly'},
          {title:'Grade', value:'MYP'},
          {title:'Age Group', value:'From 20 To 30'},
          {title:'Subject', value:'Teacher'},
          {title:'Weeks Numbers', value:'5 Weeks'}
        ]

      }
    }
  },
  methods:{
    testCallback (data) {
    }
  }
}
</script>
