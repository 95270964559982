export default {
  add: 'Add',
  criteria: 'criteria',
  assign: 'assign',
  assignment: 'assignment',
  assignments: 'assignments',
  assignment_create: 'create Assignment',
  exam_create: 'create exam',
  exam: 'exam',
  exams: 'exams',
  submit: 'submit',
  allSchools: 'All schools',
  allDivisions: 'All divisions',
  admission: 'admission',
  dashboard: 'dashboard',
  console: 'console',
  create: 'create',
  delete: 'delete',
  update: 'update',
  date_created: 'Date Created',
  schools: 'Schools',
  divisions: 'Divisions',
  status: 'Status',
  change_status: ' change Status',
  suspend: 'Suspend',
  activate: 'Activate',
  suspended: 'suspended',
  active: 'Active',
  error: 'error',
  confirm: 'Confirm',
  confirm_msg: 'Are you sure ?',
  error_validation: 'Validation error',
  already_exist: 'Already exist  ',
  profile: 'Profile',
  single: 'Single',
  group: 'group',
  due: 'due',
  next: 'Next',
  show: 'Show',
  edit: 'Edit',
  edit_status: 'Edit status',
  duplicate: 'Duplicate',
  insights: 'insights',
  archive: 'Archive',
  archived: 'Archived',
  unarchive: 'Unarchive',
  hours: 'hours',
  minutes: 'minutes',
  students: 'students',
  configuration: 'Configuration',
  upload: 'upload',
  words: 'words',
  settings: 'Settings',
  permissions: 'Permissions',
  preview: 'Preview',
  name: 'Name',
  description: 'description',
  selectAll: 'select all',
  onlineClass: 'online classes',
  quickMeeting: 'quick meetings',
  create_onlineClass: 'Create an online Class',
  create_quick_meeting: 'Create quick meeting',
  edit_onlineClass: 'Edit an online Class',
  onlineClasses: 'online Classes',
  teacher: 'teacher',
  show_archived_classes: 'show archived classes',
  show_all_classes: 'show all classes',
  no_data_available: 'No data Available',
  loading_data: 'Loading Data',
  filters: 'filters',
  school: 'School',
  upload_file: 'upload file',
  upload_logo: 'upload logo',
  upload_favicon: 'upload favicon',
  download_pdf: 'download pdf',
  upload_pdf: 'upload pdf',
  print_pdf: 'print pdf',
  repo: 'Teacher Repository',
  my_drive: 'my Drive',
  reset: 'reset',
  No_details_available_for_input: 'No details available for input',
  get_current_location: 'get current location',
  use_current_location: 'use current location',
  feature_not_included: 'This feature is not included in this version',
  save: 'save',
  done: 'done',
  labels: 'labels',
  label: 'label',
  save_and_close: 'save and close',
  publish: 'publish',
  have: 'have',
  has: 'has',
  in: 'in',
  and: 'and',
  students_directory: 'students directory',
  parent: 'Parent Profile',
  attendance_not_taken: 'Attendance was not taken for this day',
  attendance: 'Attendance',
  class_attendance: 'Class Attendance',
  classes_list: 'Classes List',
  duration: 'duration',
  accept_only: 'accept only',
  max_size: 'Max Size',
  logout: 'logout',
  Notifications: 'Notifications',
  app_notifications: 'App Notifications',
  new: 'new',
  here: 'here',
  SchoolPrincipal: 'School Principal',
  virtualClasses: 'virtual Classes',
  startDate: 'start Date',
  endDate: 'end Date',
  subject: 'subject',
  class: 'class',
  date: 'date',
  discard: 'Discard',
  open_view: 'Open/view',
  startAt: 'start at',
  endAt: 'end at',
  by: 'by',
  for: 'for',
  post: 'post',
  Something_went_wrong_Please_try_again:
    'Something went wrong. Please try again',
  replay: 'replay',
  reply: 'reply',
  zoomMeeting: 'Zoom Meeting',
  createZoomMeeting: 'Create Zoom Meeting',
  only: 'only',
  only_pdf_is_allowed: 'only PDF is allowed',
  sections: 'Sections',
  type2: 'type 2',
  type1: 'type 1',
  teacher_assignemts: 'teacher assignments',
  period: 'Period',
  email: 'email',
  childern: 'children',
  answered: 'Answered',
  not_answered: 'Not answered',
  correct_answer: 'Correct Answer',
  hour: 'hour',
  no_questions: 'No questions',
  endTime: 'End time',
  startTime: 'Start time',
  title: 'title',
  Types: 'Types',
  assess: 'Assess',
  of_days_late: 'of days late',
  Download_file: 'Download file',
  Delete_file: 'Delete file',
  Previous: 'Previous',
  questions: 'Questions',
  no: 'No',
  yes: 'yes',
  success: 'success',
  Error: 'error',
  errors: 'errors',
  row: 'row',
  the_selected_row_was_successfully: 'The selected row was successfully ',
  row_uptaded: 'Row uptaded',
  Text_copied_successfully: 'Text copied successfully',
  All: 'All',
  Upcoming: 'Upcoming',
  Today: 'Today',
  Finished: 'Finished',
  Draft: 'Draft',
  Something_went_wrong_check_your_connection:
    'Something went wrong check your connection',
  contacts: 'contacts',
  created_at: 'Created at',
  about: 'about',
  you: 'you',
  reset_all: 'Reset All',
  accept: 'accept',
  cancel: 'cancel',
  back: 'back',
  Academic_Day: 'Academic Day',
  Day: 'Day',
  out_of: 'out of',
  days: 'days',
  Filters: 'Filters',
  date_range: 'Date Range',
  more: 'more',
  remove: 'Remove',
  removed: 'removed',
  added: 'added',
  add_text_before_save: 'Add text before save',
  available: 'available',
  exists: 'exists',
  exist: 'Exist',
  males: 'Males',
  females: 'Females',
  type: 'type',
  updated: 'updated',
  actions: 'actions',
  grades: 'grades',
  hello_from: 'Hello from',
  This_feature_is_still_under_development:
    ' This feature is still under development',
  tasks: 'tasks',
  task: 'task',
  no_available_actions: 'no available actions',
  classes: 'classes',
  Classes: 'Classes',
  ongoing: 'On-Going',
  time: 'Time',
  division: 'division',
  grade: 'Grade',
  lock: 'lock',
  username: 'Username',
  country: 'Country',
  role: 'Role',
  verified: 'Verified',
  department: 'Department',
  ID: 'ID',
  you_are_about_to: 'You are about to change the status to',
  gender: 'gender',
  male: 'male',
  female: 'female',
  gate: 'gate',
  building: 'building',
  meeting_type: 'Meeting Type',
  persons: 'Persons',
  quick_or_detailed: 'quick or detailed',
  mobile: 'Mobile',
  class_groups: 'Class Groups',
  types: 'types',
  enrolment_year: 'Enrolment year',
  Upload: 'Upload',
  primary: 'primary',
  change: 'Change',
  recover_success: 'Recover Success',
  Please_check_your_mail_inbox: 'Please, check your mail inbox',
  school_icon: 'School Icon',
  Logo: 'Logo',
  subjects: 'Subjects',
  on_paper: 'On Paper',
  online: 'Online',
  dates: 'dates',
  today: 'today',
  this_week: 'this week',
  this_month: 'this month',
  data_is_not_available: 'Data is not available',
  no_data_found: 'No data found',
  birthday: 'Birthday',
  nationality: 'Nationality',
  telephones: 'Telephones',
  religion: 'Religion',
  address: 'Address',
  work_details: 'Work Details',
  age: 'age',
  class_group: 'Class Group',
  student_information: 'Student Information',
  family_profile: 'Family Profile',
  fees_payment: 'Fees & Payment',
  documents_resources: 'Documents & Resources',
  tasks_and_assessments: 'Tasks and Assessments',
  exams_and_results: 'Exams and Results',
  tools: 'Tools',
  classwork_and_behaviour: 'Classwork and behaviour',
  results_base: 'Results Base',
  grade_book: 'Grade Book',
  report_card: 'Report Card',
  timetable: 'Timetable',
  agenda: 'Agenda',
  online_classes: 'Online Classes',
  private_content: 'Private Content',
  repository: 'Repository',
  my_day: 'my day',
  academic_year: 'Academic Year',
  year_groups: 'Year Groups',
  clone: 'clone',
  history: 'History',
  add_new_year_group: 'add New Year Group',
  add_new_group: 'add New Group',
  open_all: 'Open All',
  from: 'from',
  to: 'to',
  nickname: 'nickname',
  student_name: 'student name',
  print_PDF: 'Print PDF',
  percentage: 'Percentage',
  credits: 'credits',
  total: 'Total',
  final_GPA: 'Final GPA',
  powered_by_schoolia: 'Powered By Schoolia',
  launch_date: 'Launch Date',
  due_date: 'Due Date',
  due_time: 'Due time',
  launched: 'Launched',
  report_card_q: 'Report Card Q',
  final_transcript: 'Final transcript',
  Student_ID: 'Student ID',
  birth_date: 'Birth Date',
  first_name: 'First Name',
  middle_name: 'Middle Name',
  last_name: 'Last Name',
  forth_name: 'Forth Name',
  father_s: "father's",
  family: 'family',
  trimester: 'Trimester',
  close: 'close',
  teacher_note: 'Teacher Note',
  task_attachments: 'Task Attachments',
  task_description: 'Task Description',
  student_attachments: 'Student Attachments',
  student_answer: 'Student Answer',
  student_comment: 'Student Comment',
  student_on_the_time: 'student On The Time',
  student_done_but_late: 'student done but late',
  student_done_On_time: 'student done On Time',
  submitted_on: 'Submitted On',
  student_not_done_yet: 'student not done yet',
  send_message_to_student: 'Send Message To Student',
  send_message_to_parent: 'Send Message To parent',
  student_delayed: 'student delayed',
  student_not_answered_yet: 'student not answered yet',
  schedule: 'Schedule',
  schedule_onlineClass: 'Schedule An online Class',
  no_results_found_for: ' No results found for',
  others: 'others',
  student_national_id: 'Student National ID',
  third_name: 'Third Name',
  second_name: 'Second Name',
  other_religion: 'Other Religion',
  breakout_groups: 'Breakout Groups',
  Class: 'Class',
  clear_all: 'Clear all',
  book: 'Book',
  share: 'Share',
  download: 'download',
  creators: 'Creators',
  default: 'Default',
  copy: 'copy',
  official_email: 'Official Email',
  lunched_at: 'Launched at',
  chat: 'Chat',
  code: 'Code',
  not_available: 'not available',
  utilities: 'Utilities',
  max_size_to_upload_is: 'Max size to upload is',
  mb: 'MB',
  selected: 'selected',
  all: 'All',
  hide: 'hide',
  act_as: 'act as',
  you_are_acting_as: 'you are acting as',
  now: 'now',
  you_are_about_to_enter_as: ' you are about to entre as',
  you_did_not_choose_a_user: 'you did not choose a user',
  exit_act_as: 'exit act as',
  COPYRIGHT: 'COPYRIGHT',
  all_rights_reserved: ' All rights Reserved',
  software_Designed_and_operated_by: 'Software Designed and operated by',
  with: 'with',
  list: 'list',
  filter_by: 'filter by',
  no_assignments: 'No assignment for this day',
  no_assessments: 'No assessments for this day',
  no_exams: 'No exams for this day',
  all_report_card: 'All Report Cards',
  are_you_sure_to: 'Are you sure to',
  this_task: 'this task',
  please_login_here_using_schoolia_credentials_email_and_password:
    'Please login here using schoolia credentials (email and password)',
  access_drive: 'Access drive',
  please_be_patient_your_access_link_will_be_here_very_soon:
    'please be patient, your access link will be here very soon',
  here_we_go: 'here we go !',
  file_preview: 'File Preview',
  configurations: 'Configurations',
  powered_by: 'Powered by',
  page: 'page',
  of: 'of',
  save_pdf: 'save pdf',
  save_to_review: 'save to review',
  descriptions: 'descriptions',
  all_descriptions: 'all descriptions',
  add_description: ' add description',
  student: 'Student',
  father_first_name: 'Father first name',
  father_second_name: 'Father second name',
  father_third_name: 'Father third name',
  father_forth_name: 'Father forth name',
  mother_first_name: 'Mother first name',
  mother_second_name: 'Mother second name',
  mother_third_name: 'Mother third name',
  mother_forth_name: 'Mother forth name',
  father_official_mobile: 'Father official mobile',
  mother_official_mobile: 'Mother official mobile',
  parents_info: 'Parents Info',
}
