<!-- =========================================================================================
    File Name: ChatNavbar.vue
    Description: Chat Application - Chat navbar
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div
    v-if="userId != null"
    class="chat__header cursor-pointer"
    @click.stop="$emit('showProfileSidebar', userId)"
  >
    <vs-navbar class="p-4 flex navbar-custom w-full" color="white" type="flat">
      <div class="relative flex mr-4">
        <feather-icon
          icon="MenuIcon"
          class="mr-4 cursor-pointer"
          v-if="isSidebarCollapsed"
          @click.stop="$emit('openContactsSidebar')"
        />
        <vx-tooltip :text="$t('chat.click_here_for_contact_info')">
          <vs-avatar
            class="m-0 border-2 border-solid border-white"
            size="40px"
            :src="chatUser.image"
            v-if="chatUser.image"
          />
          <vs-avatar
            class="m-0 border-2 border-solid border-white"
            :icon="isGroup ? 'people_alt' : 'person'"
            size="40px"
            v-else
          />
        </vx-tooltip>

        <!-- <div
          class="h-3 w-3 border-white border border-solid rounded-full absolute right-0 bottom-0"
          :class="'bg-' + getStatusColor(false)"
        ></div> -->
      </div>
      <div class="truncate">
        <h6>
          {{ chatUser.name }}
          <template v-if="!isGroup">
            {{ chatUser.parent_middle_name || "" | text_formatter }}
            {{ chatUser.parent_middle_name || "" | text_formatter }}
          </template>
        </h6>
        <p v-if="!isGroup">
          <template v-if="hasRoles">
            <span v-for="(role, i) in chatUser.roles" :key="'role' + i">
              {{ role.display_name | text_formatter }}
              {{
                role.description !== role.display_name
                  ? "- " + role.description
                  : "" | text_formatter
              }}
            </span>
          </template>
          <template v-else-if="hasOneRole">
            <span class="inline-block">
              {{
                chatUser.role_name.replaceAll("(", " - ").replaceAll(")", "")
              }}
            </span>
          </template>
          <span v-if="chatUser.code"> - {{ chatUser.code }} </span>
          <span v-if="chatUser.class_name"> - {{ chatUser.class_name }} </span>
        </p>
        <p v-else :title="groupMembersNames">
          <template v-if="groupMembers.length">
            <span v-for="(member, i) in groupMembers" :key="i">
              {{ member.name.split(" ")[0] | text_formatter }}
              {{ groupMembers.length > i + 1 ? "," : "" }}
            </span>
          </template>
        </p>
      </div>
      <vs-spacer></vs-spacer>
      <!-- <feather-icon icon="StarIcon" class="cursor-pointer" :svgClasses="[{'text-warning stroke-current': isPinnedLocal}, 'w-6', 'h-6']" @click.stop="isPinnedLocal = !isPinnedLocal"></feather-icon> -->
    </vs-navbar>
  </div>
</template>

<script>
import VxTooltip from '@/layouts/components/vx-tooltip/VxTooltip.vue'
import mixins from '../chat.mixins'

export default {
  name:'chat-navbar',
  mixins:[mixins],
  props: {
    userId: {
      type: Number,
      required: true
    },
    user: {
      type: Object,
      required: true
    },

    isPinnedProp: {
      type: Boolean,
      required: true,
      defaut:false
    },
    isSidebarCollapsed: {
      type: Boolean,
      required: true
    }
  },
  components:{ VxTooltip},
  computed: {
    isPinnedLocal: {
      get () {
        return this.isPinnedProp
      },
      set (val) {
        const chatData = this.$store.state.chat.chatDataOfUser
        if (chatData) {
          const payload = { id: this.userId, value: val }
          this.$store.dispatch('chat/toggleIsPinned', payload)
            .then(() => { this.$emit('toggleIsChatPinned', val) })
            .catch((err) => { console.error(err) })
        } else {
          this.$emit('toggleIsChatPinned', val)
        }
      }
    },

    getStatusColor () {
      return (isActiveUser) => {
        const userStatus = this.getUserStatus(isActiveUser)

        if (userStatus === 'online') {
          return 'success'
        } else if (userStatus === 'do not disturb') {
          return 'danger'
        } else if (userStatus === 'away') {
          return 'warning'
        } else {
          return 'grey'
        }
      }
    },
    groupMembersNames () {
      return this.groupMembers.map((e) => e.name).join(' , ')
    }


  },

  methods: {
    getUserStatus (isActiveUser) {
      return isActiveUser ? this.$store.state.AppActiveUser.status : this.chatUser.status
    }
  }
}

</script>

