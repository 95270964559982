export default {
  attendance: 'Attendance',
  attendanceConsole: 'Attendance Console',
  insights: 'Insights',
  settings: 'Settings',
  attendanceRegisterationPeriod: 'Attendance Registeration Period',
  giveAccessTo: 'Give Access To Attendance',
  turnToUsualAbsenceAfter: 'Turn To Usual Absence After',
  onMonthlyBasis: 'On Monthly Basis',
  maxDaysToSendReason: 'Max Days To Send Reason For Absence',
  daysAfterAbsencsDate: 'Days After Absence Date',
  turnToContinuousDaysAfter: 'Turn To Continuous Days Being Absence After',
  continuousDays: 'Continuous Days',
  maxAcceptedAbsenceDays: 'Max Accepted Absence Days Per Month',
  days: 'Days',
  maxAcceptedAbsenceTerm: 'Max Accepted Absence Days Per Term',
  perTerm: 'Per Term',
  accpetTakingAttendanceDuringEClass: 'Accept Taking Attendance During E-Class',
  letTeachersUseTheApp: 'Let Teachers Use The App To Take Attendance',
  takeAttendance: 'Take Attendance',
  byStudent: 'Taking Attendance By Student',
  academicDate: 'Academic Date',
  studentId: 'Student ID',
  studentName: 'Student Name',
  division: 'Division',
  grade: 'Grade',
  class: 'Class',
  status: 'Status',
  name: 'Name',
  discipline: 'Discipline',
  school: 'School',
  classCode: 'Class Code',
  className: 'Class Name',
  noClasses: 'Please select at least 1 class'
}
