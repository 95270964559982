import axios from 'axios'

class UnitPlannerRestResource {
    resource = '/planner';

    constructor () {}

    /////////////////////////////////////////////////////////////////////////////////
    //** Getters **/////////////////////////////////////////////////////////////////
    async getSectionsOfIB () {
      return await axios.get(`${this.resource}/sections?status=active&has_grades=true`)
    }

    async getIBGrades (iBId) {
      return await axios.get(`${this.resource}/sections/${iBId}/grades`)
    }

    async getGradesSubject (gradeId) {
      return await axios.get(`${this.resource}/grades/${gradeId}/subjects/unit`)
    }

    async getActiveUnitsByGrade (gradeId) {
      return await axios.get(`${this.resource}/grades/${gradeId}/active-units`)
    }

    async getUintMenus (type) {
      return await axios.get(`${this.resource}/unit-sidemenus?type=${type}`)
    }

    async getMenuFeaturesData (id, key) {
      return await axios.get(`${this.resource}/teacher-units/${id}/outlines?key=${key}`)
    }

    async getTeacherUnits (academicYear, page = null) {
      return await axios.get(
        `${this.resource}/teacher-units`, {
          params: {
            academic_year_id: academicYear,
            page
          }}
      )
    }

    async teacherSearch (unitId, searchValue) {
      return await axios.get(
        `${this.resource}/teacher-units/${unitId}/teachers?division_name=IB&search_value=${searchValue}`
      )
    }

    async getTeacherMenuData (unitId, menuKey) {
      return await axios.get(
        `${this.resource}/teacher-units/${unitId}/teaching-tools?key=${menuKey}`
      )
    }

    async getSubmittedStudents (taskId) {
      return await axios.get(`${this.resource}/assessments/${taskId}/task-students`)
    }

    async getStudentTaskDetails (taskId) {
      return await axios.get(`${this.resource}/assessment-tasks/${taskId}`)
    }

    async getSingleUint (unitId) {
      return await axios.get(`${this.resource}/get-teacher-unit?id=${unitId}`)
    }

    /////////////////////////////////////////////////////////////////////////////////
    //** Modification **///////////////////////////////////////////////////////////
    async createTeacherUnit (data) {
      return await axios.post(`${this.resource}/teacher-units`, data)
    }

    async updateTeacherUnit (data, id) {
      return await axios.patch(`${this.resource}/teacher-units/${id}`, data)
    }

    async addExtraFeatureToOutline (menuId, data) {
      return await axios.post(
        `${this.resource}/unit-outlines/${menuId}/extra-features`,
        data
      )
    }

    async addCommentToOutlineFeature (featureId, data) {
      return await axios.post(
        `${this.resource}/outline-features/${featureId}/comments`,
        data
      )
    }

    //** ُEdit Unit Data(Stand Alone) **//
    async updateYearGroupUnit (id, data) {
      return await axios.patch(`/poi/unit-academics/${id}/edit`, data)
    }

    async changeUnitStatus (unitId, status) {
      return await axios.patch(
        `${this.resource}/teacher-units/${unitId}/change-status`,
        status
      )
    }

    async addTeachersToUnit (unitId, teachers) {
      return await axios.post(
        `${this.resource}/teacher-units/${unitId}/teachers`,
        teachers
      )
    }

    async changeTeacherStatus (teacherId, data) {
      return await axios.post(
        `${this.resource}/teaching-teacher-units/${teacherId}/change-status`,
        data
      )
    }

    async changeTeacherRole (teacherId, data) {
      return await axios.post(
        `${this.resource}/teaching-teacher-units/${teacherId}/change-role`,
        data
      )
    }

    async uploadFiles (files) {
      return await axios.post('/common/upload-files', files)
    }

    async deleteFile (file) {
      return await axios.post('/common/delete-file', file)
    }
    async deleteAssignmentsAttachments (data) {
      return await axios.post('/teachers/assignments/attachment/delete', data)
    }
    async deleteAssessmentAttachments (data) {
      return await axios.post('/planner/assessments/attachment/delete', data)

    }
    async createTeacherPost (data) {
      return await axios.post(`${this.resource}/unit-teaching-discussions`, data)
    }

    async addCommentOnPost (postId, data) {
      return await axios.post(`${this.resource}/unit-teaching-discussions/${postId}/comments`, data)
    }

    async getSingleTask (id) {
      return await axios.get(`${this.resource}/find-assessment-Teacher?id=${id}`)
    }

    async assessStudentTask (taskId, data) {
      return await axios.post(`${this.resource}/assessment-tasks/${taskId}/assess`, data)
    }


    /////////////////////////////////////////////////////////////////////////////////
    //** DropDowns Data POI **///////////////////////////////////////////////////////////
    async getCurrentAcademicYear () {
      return await axios.get('/poi/academic-years?current=true')
    }

    async getKeyConcepts () {
      return await axios.get('/poi/approaches/key_concepts')
    }

    async getAtlSkills () {
      return await axios.get('/poi/approaches/atl_skills')
    }

    async getLearnerProfile () {
      return await axios.get('/poi/approaches/learner_profile')
    }

    async getRelatedConcepts () {
      return await axios.get('/poi/approaches/related_concepts')
    }

    async getRelatedConceptsData (id) {
      return await axios.get(`/poi/approaches/related_concepts/${id}`)
    }


    async getTeacherClasses () {
      return await axios.get('/planner/teacher-classes')
    }

    async getDivisionSubjects (teacherClassId) {
      /*return await axios.get(`/planner/teacher-classes/${teacherClassId}/subjects`);*/
      return await axios.get(`/planner/teacher-classes/${teacherClassId}/subject-criterias`)
    }

    /////////////////////////////////////
    async getStudentsOfTeacherClass (id) {
      return await axios.get(`/planner/teacher-classes/${id}/students`)
    }

    async createAssessmentTask (task) {
      return await axios.post('/planner/assessments', task)
    }

    async getTeacherAssessments (id, page) {
      return await axios.get(
        `/planner/teacher-units/${id}/teacher-tasks?key=assessment-task&page=${page || 1}`
      )
    }
    async deleteAssessment (id) {
      return await axios.delete(
        `/planner/assessments/${id}`
      )
    }

    async archiveAssessmentTask (id, data) {
      return await axios.patch(`/planner/assessments/${id}/change-status`, data)
    }

    async upadateAssessmentTask (task, id) {
      return await axios.patch(`/planner/assessments/${id}`, task)
    }

    async getUsersToInvite (id) {
      return await axios.get(`/planner/teacher-units/grade/user-role/${id}?sectionCoordinator=true`)
    }

    /////////////////////////////////////
    async getTeacherAssignments () {
      return await axios.get(
        '/teachers/assignments'
      )
    }
}

//** Singleton Service **//
const unitPlannerSources = new UnitPlannerRestResource()

export default unitPlannerSources
