export default {
  Config: 'System Configuration',
  Key: 'Key',
  Director: 'Director',
  Principal: 'Principal',
  grades: 'Grades',
  Name: 'Name',
  Supervisor: 'Supervisor',
  schoolBranch: 'Branch Name',
  SelectBranch: 'Select Branch',
  email: 'Email',
  status: 'Status',
  DivisionName: 'Division Name'
}
