export default {
  S: 'S',
  Round: 'Round',
  TotalDays: 'Total Days',
  From: 'From',
  To: 'To',
  CreatedBy: 'Created By',
  Contact: 'Contact',
  Status: 'Status',
  Actions: 'Actions',
  ShowSSR: 'Show SSR',
  UploadPhotoMask: 'Upload Photo Mask',
  SetSubmissionTimeframe: 'Set Submission Time frame',
  ShowEarlyBid: 'Show Early Bid',
  GreetingMessage: 'Greeting Message',
  ShowFees: 'Show Fees',
  ShowMedicalSurvey: 'Show Medical Survey',
  ScoreManagement: 'Score Management',
  ScoreSchema: 'Select Score Schema',
  Features: 'Features',
  AllSchools: 'All Schools',
  AllGrads: 'All Grades',
  AllDivisions: 'All Divisions',
  Start: 'Start',
  End: 'End',
  ChooseSchool: 'Choose School',
  RoundDates: 'Round Dates',
  ChooseDivision: 'Choose Division',
  ChooseGrads: 'Choose Grades',
  RoundManager: 'Round Manager',
  ContactPerson: 'Contact Person',
  AllManagers: 'All Managers',
  AllPersons: 'All Persons',
  RoundContacts: 'Round Contacts',
  active:'active',
  timeStatus: 'time Status',
  end_this_round:'end this round',
  close_this_round: 'close this round',
  please_select_score_manager_schema: 'please Select Score Manager Schema',
  new_round_has_been_added_successfully: 'New round has been added successfully',
  round_has_been_edited_successfully: 'Round has been edited successfully',
  choose_school_first: 'choose school first',
  choose_division_first:'choose division first'
}
