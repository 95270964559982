<template>
  <div>
    <!-- Collaped Button {for collapse Navbar mode} -->
    <div id="round_filter_buttons" v-if="this.$store.state.reduceButton">
      <vs-button color="primary" type="filled" @click="activeSidebar">
        Rounds
        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
      </vs-button>
    </div>

    <!-- Filter Sidebar  -->
    <div id="round_filter" v-else>
      <vs-sidebar
        static-position
        default-index="1"
        color="primary"
        class="items-no-padding vs-sidebar-rounded p-0"
        spacer
        hidden-background
        v-model="active_prop"
      >
        <!-- Dropdown -->
        <vs-select
          v-model="activeRoundIndex"
          class="w-full select-large bg-primary text-color-base"
          id="round_filter_select"
          v-validate="'required'"
        >
          <vs-select-item
            :key="round.id"
            :value="index"
            :text="round.name"
            v-for="(round, index) in rounds"
            class="w-full"
          />
        </vs-select>
        <!-- Filter content -->

        <div id="round_filter_content" class="p-5 bg-white">
          <!-- <p class="flex justify-between align-middle text-sm">
            <span>
              <strong>
                Start/End:
                {{ rounds[activeRoundIndex].start }}- {{ rounds[activeRoundIndex].end }}
              </strong>
            </span>
            <span>
              <strong>{{(parseInt(rounds[activeRoundIndex].start))}} of {{parseInt(rounds[activeRoundIndex].end)-parseInt(rounds[activeRoundIndex].start)}}</strong>
            </span>
          </p>
          <ul class="mt-4 text-sm">
            <li>
              <p>
                <a href="#!">
                  All applications
                  <span>(834)</span>
                </a>
              </p>
            </li>
            <li>
              <p>
                <a href="#!">
                  Archived applications
                  <span>(245)</span>
                </a>
              </p>
            </li>
            <li>
              <p>
                <a href="#!">
                  Switched applications
                  <span>(245)</span>
                </a>
              </p>
            </li>
          </ul>
          <p class="text-right">
            <a href="#!" class="text-black text-sm text-underline">Reports & Dashboard</a>
          </p> -->

          <!-- Filters -->
          <form class="mt-5" action>
            <div class="flex justify-between align-middle">
              <h6 class="font-bold">{{ $t("basic.Filters") }}</h6>
              <button
                type="reset"
                class="bg-transparent border-0 text-primary"
                @click="resetAllFilters"
              >
                {{ $t("basic.reset_all") }}
              </button>
            </div>
            <vs-divider />
            <!-- Choosen -->
            <div class="mb-3 d-block" v-if="filters_choosen.length > 0">
              <vs-chip
                @click="remove(choose)"
                v-for="(choose, i) in filters_choosen"
                closable
                :key="i"
                >{{ choose.name }}</vs-chip
              >
            </div>
            <!-- options -->
            <vs-collapse accordion>
              <vs-collapse-item v-for="filter in filters_prop" :key="filter.id">
                <div slot="header">{{ filter.name }}</div>
                <ul>
                  <li v-for="(filter_by, i) in filter.by" :key="i" class="mb-2">
                    <vs-checkbox
                      :vs-value="filter_by"
                      :checked="filter_by.checked"
                      v-model="filters_choosen"
                      @change="
                        update(filter.name, filter_by.name, filter_by.checked)
                      "
                    >
                      {{ filter_by.name }}
                    </vs-checkbox>
                  </li>
                </ul>
              </vs-collapse-item>
            </vs-collapse>
            <slot name="customFilters"></slot>
          </form>
        </div>
      </vs-sidebar>
    </div>
  </div>
</template>
<script>


export default {
  name: 'attendance-filter',
  props: {
    filters_prop: {
      type: Array,
      required: true
    },

    rounds_prop: {
      type: Array,
      required: true
    },
    filters_choosen_prop: {
      type: Array
    },
    active_prop: {
      type: Boolean
    }
  },

  data () {
    return {
      filters_choosen: this.filters_choosen_prop,
      filters: this.filters_prop,
      rounds: this.rounds_prop,
      activeRoundIndex: 0,
      filterBydate:'',
      filters_out: {
        divisions: [],
        grades: [],
        classes: []
      }
    }
  },
  computed: {
    selected_filters () {
      let matched = null
      this.filters_choosen.forEach(val => {
        matched = this.filters.filter(el => el.value === val)
      })
      return matched
    },
    activeRoundID () {
      return this.rounds[this.activeRoundIndex].id
    }
  },
  watch: {
    filters_prop (newVal, oldVal) {
      this.filters_choosen = []
      // To add the already checked filters to the filters_choosen list
      this.filters_prop.map(temp => {
        const x = temp.by.filter(fby => fby.checked === true)
        if (x.length !== 0) x.map(temp => {
          this.filters_choosen.push(temp)
        })
      })
    },
    filters_choosen () {
      this.$emit('changeSideFilter', this.filters)
    }
  },

  methods: {
    resetAllFilters () {
      this.filters.map(fil => fil.by.map(el => {
        el.checked = false
        return el
      })
      )
      this.filters_choosen = []
      this.$emit('resetFilters', this.filters)
    },
    // Remove Elemets to Array & Reset Counter
    remove (choose) {
      this.filters_choosen.splice(this.filters_choosen.indexOf(choose), 1)
      // Reset the checked value of removed
      this.filters_prop.map(fil => fil.by.map(fil_by => {
        if (fil_by === choose) {
          fil_by.checked = false
        }
        return fil_by
      })
      )
    },
    // Update filters_choosen
    update (key, sub_key, value) {
      this.filters
        .filter(el => el.name === key)[0]
        .by.filter(el => el.name === sub_key)[0].checked = !value
    },
    activeSidebar (id) {
      this.$store.commit('TOGGLE_REDUCE_BUTTON', false)
      // this.roundActiveId = id
    }
  },
  created () {
    // To add the already checked filters to the filters_choosen list
    this.filters_prop.map(temp => {
      const x = temp.by.filter(fby => fby.checked === true)
      if (x.length !== 0) x.map(temp => {
        this.filters_choosen.push(temp)
      })
    })
  }
}
</script>
