export default {
  agenda: 'Agenda',
  are_you_sure_you_want_delete_this_item: 'Are you sure you want delete this item',
  delete_selected_item: 'Delete selected item',
  addAgenda: 'Add Agenda',
  deleted_successfully: 'Deleted successfully',
  UploadAgenda: 'Upload Agenda',
  file_name: 'File Name',
  adding_a_agenda_task:'Adding a agenda task',
  adding_successfully: 'Adding Successfully',
  failed_to_save_agenda: 'Failed to save agenda',
  agenda_has_not_been_saved_please_complete_your_data:'Agenda has not been saved please complete your data'
}
