export default {
  Admission: 'Admission',
  ActiveRounds: 'Active Rounds',
  Applications: 'applications',
  OnAssessment: 'On Assessment',
  FutureCandidates: 'Future Candidates',
  RoundManager: 'Round Manager',
  ScoreManager: 'Score Manager',
  NewRound: 'New Round',
  editRound: 'Edit Round',
  registration: 'Registration',
  AdmissionManager: 'Admission manager',
  show_scheduler_meetings: 'show Schedular Meetings',
  scheduler_meetings: 'Schedular Meetings',
  Subscribers: 'Subscribers',
  enrolled_change_error: 'You cannot change status of enrolled student',
  religion: 'religion',
  nationality: 'Nationality',
  spoken_language: 'Spoken language',
  second_language: 'Second language',
  alumni: 'Alumni',
  siblings: 'siblings',
  transportation: 'Transportation',
  bus_Required: 'Bus Required',
  no_bus_required: 'No Bus Required',
  special_needs: 'Special Needs',
  has_special_needs: 'has Special Needs',
  has_not_special_needs: 'hasn\'t Special Needs',
  has_alumni: 'has Alumni',
  has_not_alumni: 'hasn\'t Alumni',
  has_siblings: 'has siblings',
  has_not_siblings: 'hasn\'t siblings',
  application_ID: 'application ID',
  Located_at_the_student_birth_certificate:
    'Located at the student birth certificate',
  rounds: 'Rounds',
  recommend_student: 'Recommend student',
  student_recommend_successfully: 'Student recommend successfully',
  recommended_by: 'Recommended by',
  recommend: 'Recommend',
  change_division: 'Change division',
  select_division: 'Select division',
  select_grades: 'Select grades',
  enrolled_change: 'Status changed to Enrolled Successfully',
  view_status :'view status',
  view:'viewed',
  notview:'not viewed',
  Accepted:'Accepted',
  Registered:'Registered',
  accept :'Accept',
  register :'register',
  notAccept:'Not Accept',
  roundName: 'round name'

}
