// APIs
import {
  RepositoryFactory
} from '@/Repositories/RepositoryFactory'
const CasRepository = RepositoryFactory.get(
  'CASRepository',
  'TaskRepository'
)


export default {
  props: ['student'],
  data () {
    return {
      'criterias': [],
      'outcomes': [],
      'indicator': ['green', 'blue', 'red', 'orange'],
      'validation': ['validated', 'not_valiated', 'not_validated_yet'],
      'timeStatus': ['upcoming', 'closed', 'ongoing'],
      ////////////////////////////
      // is_student_FinishedCas
      ////////////////////////////
      'finishedCAS': false,
      ////////////////////////////
      // allowedUploadFinal
      ////////////////////////////
      'allowUploadFinal': false,
      'finalReportFileURL': null,
      'finalReportFileName': null

    }
  },
  computed: {


    is_DP () {
      return this.authUser.DP
    },
    is_student () {
      return this.authUser.userRole === 'student' || this.$acl.check('Student')
    },
    is_student_profile () {
      return this.$route.name === 'BackToProfile' || this.$route.name === 'StudentProfile'
    },
    is_coordinator () {
      return this.$acl.check('CasCoordinator')
    },
    student_id () {
      return this.is_student ? this.authUser.uid : (this.task ? this.task.student_id : null)
    }

  },
  methods: {
    ///////////////////////////////
    // APIs
    /////////////////////////////
    getCriteria () {
      return  CasRepository.getCriteria()
        .then(res => {
          const data = res.data
          this.criterias = data.map((el) => {
            return {
              name: el[this.current_locale],
              id: el.id,
              symbol: el.symbol,
              count: 0
            }
          })

        })
        .catch()
    },
    getOutcomes () {
      return CasRepository.getOutcomes()
        .then(res => {
          const data = res.data
          this.outcomes = data.map((el) => {
            return {
              name: el[this.current_locale],
              id: el.id,
              symbol: el.symbol,
              count: 0
            }
          })
        })
        .catch()
    },
    checkAllowToUploadFinalReport (student_user_id) {
      const stduentId = this.student_id ? this.student_id : student_user_id
      CasRepository.checkAllowUploadFinalReport(stduentId)
        .then(res => {
          let fileName = ''

          this.allowUploadFinal = res.message.is_allow
          this.finalReportFileURL = res.message.final_report
          fileName = res.message.final_report ? res.message.final_report.split('.') : ''
          fileName = fileName ? `${fileName[fileName.length - 2] }.${ fileName[fileName.length - 1]}` : ''
          this.finalReportFileName = fileName
          this.finishedCAS = !!this.finalReportFileURL
        })

    },

    /////////////////////////////
    // loading
    /////////////////////////////
    loading () {
      this.$vs.loading()
    },
    stopLoading () {
      this.$vs.loading.close()
    },
    ////////////////////////////////
    // Notify
    ////////////////////////////////
    // success msg
    successMsg (msg) {
      this.$vs.notify({
        title: 'success',
        text: msg ? msg : this.$t('form.the_data_is_submitted'),
        color: 'success',
        iconPack: 'feather',
        icon: 'icon-check-circle'
      })
    },
    // err msg
    errMsg (err = null) {
      this.$vs.notify({
        title: 'Error',
        text: err ? (err.data ? err.data.message : err.message) : `${this.$t('form.Enter_Required_Data')}!`,
        color: 'danger',
        iconPack: 'feather',
        icon: 'icon-x-circle'
      })
    },
    serveErr () {
      this.errMsg({
        message: this.$t('basic.Something_went_wrong_Please_try_again')
      })
    },

    ////////////////////////////////
    // Go To Route
    ////////////////////////////////
    goTo (name, params = {}) {
      const activeRoute = {
        name,
        params
      }

      if (this.is_student || this.is_student_profile) {
        this.$root.$emit('changeActiveRoute', activeRoute)

      } else if (params) this.$router.push(activeRoute)
      else this.$router.push({
        name
      })
    }
  }
}
