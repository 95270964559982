<template>
  <user-form @formSubmited = "addUser"/>
</template>

<script>
import userForm from '@/modules/canteen/pages/user/components/userForm'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const canteenRepository = RepositoryFactory.get(
  'canteenRepository',
  'canteenRepository'
)

export default {
  name: 'create',
  components:{
    userForm
  },
  methods: {
    addUser (payload) {
      this.VSLoading()
      canteenRepository.addUser(payload).then(() => {
        this.successMsg(this.$t('canteen.addUserSuccessfully'))
        this.$router.go(-1)
        this.stopVSLoading()
      }).catch((err) => {
        this.errMsg(err)
      })
    }
  }
}
</script>

<style scoped>

</style>
