// Profile
import Profile from './pages/profile/Profile.vue'

import unitPlanner from '../unit-planner/components/planner-container/planner-container.vue'

// Dashborad || Console
import Dashboard from './pages/dashboard/Dashboard.vue'
import ExamCreate from './pages/dashboard/exam/Create.vue'
import ExamEdit from './pages/dashboard/exam/Edit.vue'
import ExamList from './pages/dashboard/exam/List.vue'
import ExamShow from './pages/dashboard/exam/Show.vue'
import ExamRunning from './pages/dashboard/exam/running.vue'
import examAssessment from './pages/dashboard/exam/examAssessment.vue'
import onlineClassCreate from './pages/dashboard/onlineClass/Create.vue'
import onlineClassSchedule from './pages/dashboard/onlineClass/Schedule.vue'
import Schedule_co_online_class from './pages/dashboard/onlineClass/Schedule_co_online_class.vue'
import co_online_class from './pages/dashboard/onlineClass/co_online_class.vue'
import onlineClassEdit from './pages/dashboard/onlineClass/Edit.vue'
import onlineClassList from './pages/dashboard/onlineClass/List.vue'
// import zoomMeeting from './pages/dashboard/onlineClass/Meeting.vue'
import quickMeetingList from './pages/dashboard/quickMeeting/List.vue'
import TeacherRepository from './pages/dashboard/repository/Repository'
import TeacherStudentDirectory from './pages/dashboard/students-directory/all-students/List.vue'
import TeacherAttendanceList from './pages/dashboard/attendance/TakeAttendance.vue'
import AssignmentRoutes from '@/modules/assignment/assignment.routes.js'

import TeachingMaterialsList from './pages/dashboard/teaching-materials/list.vue'
import TeachingMaterialsCreate from './pages/dashboard/teaching-materials/create.vue'
import TeachingMaterialsShare from './pages/dashboard/teaching-materials/share.vue'
import TeachingMaterialsEdit from './pages/dashboard/teaching-materials/Edit.vue'

// Paths && Pages
import {
  teacher_paths
} from './paths'
import AllStudentsList from './pages/dashboard/students-directory/all-students/List'

// const pages = menu.pages

export default [
  // =============================================================================
  // Profile
  // =============================================================================
  {
    path: teacher_paths.profile,
    name: 'Profile',
    component: Profile,
    meta: {
      breadcrumb: [
        {
          title: 'profile.profile',
          url: teacher_paths.profile
        }

      ],
      pageTitle: 'profile.Profile',
      rule: 'Teacher'
    }
  },

  // =============================================================================
  // Console || Dashboard
  // =============================================================================
  {
    path: teacher_paths.dashboard,
    name: 'basic.dashboard',
    component: Dashboard,
    meta: {
      breadcrumb: [
        {
          title: 'basic.dashboard',
          url: teacher_paths.dashboard
        }
      ],
      pageTitle: 'basic.dashboard',
      rule: 'Teacher'
    }
  },

  // =============================================================================
  // assignment
  // =============================================================================
  ...AssignmentRoutes,
  // Assess

  // All
  // {
  //   path: teacher_paths.assignment_list,
  //   name: 'assignments',
  //   component: AssignmentList,
  //   meta: {
  //     breadcrumb: [
  //       {
  //         title: 'basic.dashboard',
  //         url: teacher_paths.dashboard

  //       },
  //       {
  //         title: 'basic.assignments',
  //         url: null,
  //         active: true
  //       }
  //     ],
  //     pageTitle: 'basic.assignments',
  //     rule: 'Teacher'
  //   }

  // },
  // =============================================================================
  // exams
  // =============================================================================
  // Create
  {
    path: teacher_paths.exam_create,
    name: 'exam_create',
    i18n: 'basic.create',

    component: ExamCreate,
    meta: {
      breadcrumb: [
        {
          title: 'home',
          url: teacher_paths.dashboard,
          i18n: 'basic.dasboard'
        },
        {
          title: 'exams',
          i18n: 'basic.exams',
          url: teacher_paths.exam_list
        },
        {
          title: 'create',
          i18n: 'basic.create',
          url: null,
          active: true
        }
      ],
      pageTitle: 'Create Exam',
      i18n: 'exam.CreateExam',
      rule: 'Teacher'
    }
  },
  // edit
  {
    path: teacher_paths.exam_edit,
    name: 'exam_edit',
    i18n: 'basic.edit',

    component: ExamEdit,
    meta: {
      breadcrumb: [
        {
          title: 'home',
          url: teacher_paths.dashboard,
          i18n: 'basic.dashboard'
        },
        {
          title: 'exams',
          i18n: 'basic.exams',
          url: teacher_paths.exam_list
        },
        {
          title: 'edit',
          i18n: 'basic.edit',
          url: null,
          active: true
        }
      ],
      pageTitle: 'edit Exam',
      i18n: 'exam.EditExam',
      rule: 'Teacher'
    }
  },
  // All
  {
    path: teacher_paths.exam_list,
    name: 'exams',
    i18n: 'basic.exams',
    component: ExamList,
    meta: {
      breadcrumb: [
        {
          title: 'home',
          url: teacher_paths.profile,
          i18n: 'basic.profile'

        },
        {
          title: 'exams',
          i18n: 'basic.exams',
          url: null,
          active: true
        }
      ],
      pageTitle: 'exams',
      i18n: 'basic.exams',
      rule: 'Teacher'
    }
  },
  // Show
  {
    path: teacher_paths.exam_show,
    name: 'exam_show',
    i18n: 'basic.show',
    component: ExamShow,
    meta: {
      breadcrumb: [

        {
          title: 'exams',
          i18n: 'basic.exams',
          url: teacher_paths.exam_show,
          active: true
        }
      ],
      pageTitle: 'show',
      i18n: 'basic.show',
      rule: 'Teacher'
    }
  },

  // Show
  {
    path: teacher_paths.exam_running,
    name: 'exam_running',
    i18n: 'basic.show',
    component: ExamRunning,
    meta: {
      breadcrumb: [

        {
          title: 'exams',
          i18n: 'basic.exams',
          url: teacher_paths.exam_running,
          active: true
        }
      ],
      pageTitle: 'Running',
      i18n: 'basic.running',
      rule: 'Teacher'
    }
  },
  // Exam assessment
  {
    path: teacher_paths.exam_assessment,
    name: 'exam_assessment',
    i18n: 'exam.examAssessment',
    component: examAssessment,
    meta: {
      breadcrumb: [
        {
          title: 'home',
          url: teacher_paths.dashboard,
          i18n: 'basic.dashboard'
        }, {
          title: 'exams',
          i18n: 'basic.exams',
          url: teacher_paths.exam_list
        },
        {
          title: 'exam.examAssessment',
          i18n: 'exam.examAssessment',
          url: teacher_paths.exam_running,
          active: true
        }
      ],
      pageTitle: 'exam.examAssessment',
      i18n: 'exam.examAssessment',
      rule: 'Teacher'
    }
  },
  // =============================================================================
  // onlineClass
  // =============================================================================
  // Create
  {
    path: teacher_paths.onlineClass_create,
    name: 'onlineClass_create',

    component: onlineClassCreate,
    meta: {
      breadcrumb: [
        {
          title: 'home',
          url: teacher_paths.dashboard,
          i18n: 'basic.dasboard'
        },
        {
          title: 'basic.onlineClasses',
          url: teacher_paths.onlineClass_list
        },
        {
          title: 'basic.create',
          url: null,
          active: true
        }
      ],
      pageTitle: 'onlineClass.online_meeting',
      i18n: 'basic.create_onlineClass ',
      rule: 'Teacher'
    }
  },
  //Schedule
  {
    path: teacher_paths.onlineClass_Schedule,
    name: 'onlineClass_schedule',
    component: onlineClassSchedule,
    meta: {
      breadcrumb: [
        {
          title: 'home',
          url: teacher_paths.dashboard,
          i18n: 'basic.dasboard'
        },
        {
          title: 'onlineClass.onlineClasses',
          url: teacher_paths.onlineClass_list
        },
        {
          title: 'basic.create',
          url: null,
          active: true
        }
      ],
      pageTitle: 'onlineClass.scheduled_meeting',
      i18n: 'onlineClass.scheduled_meeting',
      rule: 'Teacher'
    }
  },
  //Co_online_class
  {
    path: teacher_paths.co_online_class,
    name: 'co_online_class',
    component: co_online_class,
    meta: {
      breadcrumb: [
        {
          title: 'home',
          url: teacher_paths.dashboard,
          i18n: 'basic.dasboard'
        },
        {
          title: 'onlineClass.onlineClasses',
          url: teacher_paths.onlineClass_list
        },
        {
          title: 'basic.create',
          url: null,
          active: true
        }
      ],
      pageTitle: 'onlineClass.co_meeting',
      i18n: 'onlineClass.co_meeting ',
      rule: 'Teacher'
    }
  },

  //Schedule Co online class
  {
    path: teacher_paths.Schedule_co_online_class,
    name: 'Schedule_co_online_class',
    component: Schedule_co_online_class,
    meta: {
      breadcrumb: [
        {
          title: 'home',
          url: teacher_paths.dashboard,
          i18n: 'basic.dasboard'
        },
        {
          title: 'onlineClass.onlineClasses',
          url: teacher_paths.onlineClass_list
        },
        {
          title: 'basic.create',
          url: null,
          active: true
        }
      ],
      pageTitle: 'onlineClass.scheduled_co_meeting',
      i18n: 'onlineClass.scheduled_co_meeting ',
      rule: 'Teacher'
    }
  },
  //zzom
  // {
  //   path: teacher_paths.zoom_meeting,
  //   name: 'zoomMeeting',

  //   component: zoomMeeting,
  //   meta: {
  //     breadcrumb: [
  //       {
  //         title: 'home',
  //         url: teacher_paths.dashboard,
  //         i18n: 'basic.dasboard'
  //       },
  //       {
  //         title: 'basic.onlineClasses',
  //         url: teacher_paths.zoom_meeting
  //       }
  //     ],
  //     rule: 'Teacher'
  //   }
  // },
  // edit
  {
    path: teacher_paths.onlineClass_edit,
    name: 'onlineClassEdit',
    i18n: 'basic.edit',

    component: onlineClassEdit,
    meta: {
      breadcrumb: [
        {
          title: 'home',
          url: teacher_paths.dashboard,
          i18n: 'basic.dashboard'
        },
        {
          title: 'basic.onlineClasses',
          url: teacher_paths.onlineClass_list
        },
        {
          title: 'basic.edit',

          active: true
        }
      ],
      pageTitle: 'basic.edit_onlineClass',
      rule: 'Teacher'
    }
  },
  // All
  {
    path: teacher_paths.onlineClass_list,
    name: 'onlineClasses',
    i18n: 'basic.onlineClasses',
    component: onlineClassList,
    meta: {
      breadcrumb: [
        {
          title: 'home',
          url: teacher_paths.profile,
          i18n: 'basic.profile'

        },
        {
          title: 'basic.onlineClasses',
          url: null,
          active: true
        }
      ],
      pageTitle: 'basic.onlineClasses',
      rule: 'Teacher'
    }
  },

  //quick meetings
  {
    path: teacher_paths.quickMeetingList,
    name: 'onlineClasses',
    i18n: 'basic.onlineClasses',
    component: quickMeetingList,
    meta: {
      breadcrumb: [
        {
          title: 'home',
          url: teacher_paths.profile,
          i18n: 'basic.profile'

        },
        {
          title: 'basic.quickMeeting',
          url: null,
          active: true
        }
      ],
      pageTitle: 'basic.quickMeeting',
      rule: 'Teacher'
    }
  },


  // ===============================================================================
  // Repository
  // ===============================================================================

  {
    path: teacher_paths.repository,
    name: 'teacherRepository',
    i18n: 'basic.profile',
    component: TeacherRepository,
    meta: {
      breadcrumb: [
        {
          title: 'home',
          url: teacher_paths.repository,
          i18n: 'basic.profile'

        }
      ],
      pageTitle: 'basic.my_drive',
      rule: 'Teacher'
    }
  },
  // {
  //   path: 'https://drive.nvis.schoolia-eg.com/login?redirect_url=/apps/files/',
  //   name: 'RepoLink'
  // },
  // ===============================================================================
  // studentsdirectory
  // ===============================================================================

  {
    path: teacher_paths.students_directory,
    name: 'students_directory',
    i18n: 'basic.profile',
    component: TeacherStudentDirectory,
    meta: {
      breadcrumb: [
        {
          title: 'home',
          url: teacher_paths.students_directory,
          i18n: 'basic.profile'

        }
      ],
      pageTitle: 'basic.students_directory',
      rule: 'Teacher'
    }
  },
  // =============================================================================
  // Attendance
  // =============================================================================

  {
    path: teacher_paths.attendance,
    name: 'teacher_attendance',
    component: TeacherAttendanceList,
    meta: {
      breadcrumb: [
        {
          title: 'basic.dashboard',
          url: teacher_paths.dashboard
        },
        {
          title: 'basic.class_attendance',
          url: null,
          active: true
        }
      ],
      pageTitle: 'basic.class_attendance',
      rule: 'Teacher'
    }

  },


  // ===============================================================================
  // Unit Planner
  // ===============================================================================

  {
    path: teacher_paths.planner,
    name: 'Unit Planner',
    i18n: 'basic.profile',
    component: unitPlanner,
    meta: {
      breadcrumb: [
        {
          title: 'home',
          url: teacher_paths.planner,
          i18n: 'Unit Planner'

        }
      ],
      pageTitle: 'Unit Planner',
      rule: 'Teacher'
    }
  },


  // ===============================================================================
  // teaching materials
  // ===============================================================================

  //list
  {
    path: teacher_paths.teaching_materials,
    name: 'TeachingMaterials',
    component: TeachingMaterialsList,
    meta: {
      breadcrumb: [
        {
          title: 'home',
          url: teacher_paths.profile,
          i18n: 'basic.profile'
        },
        {
          title: 'teachingMaterials.teaching_materials',
          url: null,
          active: true
        }
      ],
      pageTitle: 'teachingMaterials.teaching_materials',
      rule: 'Teacher'
    }
  },
  // Create
  {
    path: teacher_paths.create_teaching_materials,
    name: 'TeachingMaterialsCreate',
    component: TeachingMaterialsCreate,
    meta: {
      breadcrumb: [
        {
          title: 'home',
          url: teacher_paths.profile,
          i18n: 'basic.profile'
        },
        {
          title: 'teachingMaterials.teaching_materials',
          url:  teacher_paths.teaching_materials
        },
        {
          title: 'basic.create',
          url: null,
          active: true
        }
      ],
      pageTitle: 'teachingMaterials.create_matiral',
      rule: 'Teacher'
    }
  },

  // share
  {
    path: teacher_paths.share_teaching_materials,
    name: 'TeachingMaterialsShare',
    component: TeachingMaterialsShare,
    meta: {
      breadcrumb: [
        {
          title: 'home',
          url: teacher_paths.profile,
          i18n: 'basic.profile'
        },
        {
          title: 'teachingMaterials.teaching_materials',
          url:  teacher_paths.teaching_materials
        },
        {
          title: 'basic.share',
          url: null,
          active: true
        }
      ],
      pageTitle: 'basic.share',
      rule: 'Teacher'
    }
  },

  // Edit
  {
    path: teacher_paths.edit_teaching_materials,
    name: 'TeachingMaterialsEdit',
    component: TeachingMaterialsEdit,
    meta: {
      breadcrumb: [
        {
          title: 'home',
          url: teacher_paths.profile,
          i18n: 'basic.profile'
        },
        {
          title: 'teachingMaterials.teaching_materials',
          url:  teacher_paths.teaching_materials
        },
        {
          title: 'basic.edit',
          url: null,
          active: true
        }
      ],
      pageTitle: 'basic.edit',
      rule: 'Teacher'
    }
  }

]
