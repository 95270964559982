<template>
  <div>
    <div class="flex flex-wrap items-center justify-between">
      <vs-button
        class="mb-base item-self-justify-right"
        icon-pack="feather"
        icon="icon icon-file"
        @click="printInvoice"
        >Print</vs-button
      >
    </div>
    <exam-preview :exam_prop="exam" :edit_mood="true"></exam-preview>
  </div>
</template>

<script>

import ExamPreview from '@/modules/exam/components/ExamPreview'
// API
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const ExamRepo = RepositoryFactory.get('ExamModule', 'Exam')


export default{
  data () {
    return {
      exam:{}
    }
  },
  computed: {
    exam_id () {
      return this.$route.params.id
    }
  },
  methods: {
    printInvoice () {
      window.print()
    },
    getExam () {
      ExamRepo.getExam(this.exam_id)
        .then(res => {
          console.log('exam', res)
          this.exam = res.data
        }).catch(err => {
          console.log('err', err)
        })
    }
  },
  components: {ExamPreview},
  created () {
    this.getExam()
  },
  mounted () {
    this.$emit('setAppClasses', 'invoice-page')
  }
}
</script>

<style lang="scss">
@media print {
  .invoice-page {
    h6 {
      b {
        text-transform: uppercase;
        color: #626262;
      }
    }
    * {
      visibility: hidden;
    }

    #content-area {
      margin: 0 !important;
    }

    .vs-con-table {
      .vs-con-tbody {
        overflow: hidden !important;
      }
    }

    #invoice-container,
    #invoice-container * {
      visibility: visible;
    }
    #invoice-container {
      position: absolute;
      left: 0;
      top: 0;
      box-shadow: none;
    }
  }
}
@page {
  size: auto;
}
</style>
