<template>
  <div>
    <vs-card class="p-3 py-6 md:p-6">
<!--      <h4 class="mb-4 text-gray text-center">Canteen One</h4>-->
      <form
        class="flex justify-between flex-col"
        @submit.prevent="submitForm"
        @keyup.enter="submitForm"
      >
        <div class="mb-4 mt-auto w-full vx-row items-center mx-auto">

          <!--Start Type -->
          <div class="my-2 vx-col  w-full">
            <label>{{ $t("form.type") }}
              <sup class="text-danger">*</sup>
            </label>
            <v-select
              multiple
              :closeOnSelect="true"
              v-model="user.type_ids"
              :options="types"
              label="type"
              :reduce="(name) => name.id"
              v-validate="'required'"
              name="type"
              class="w-full mb-2"
            />

            <span class="text-danger text-sm">{{
                errors.first("type")
              }}</span>
          </div>
          <!--End Type -->

          <!--First Name -->
          <div class="my-2 vx-col  w-full">
            <label>{{ $t("form.name") }}<sup class="text-danger">*</sup></label>
            <vs-input
              type="text"
              v-model="user.name"
              class="w-full mb-2"
              name="name"
              v-validate="'required'"
            />
            <span class="text-danger text-sm">{{
                errors.first("name")
              }}</span>
          </div>
          <!-- End first Name -->

          <!-- Start mobile -->
          <div class="vx-col w-full">
            <label>
              {{ $t("teachersGateway.mobile") }}
              <sup class="text-danger">*</sup>
            </label>
          </div>
          <div class="vx-col w-full">
            <div class="vx-row">
              <vs-input
                v-model="user.phone_number"
                :name="`phone_number`"
                v-validate="'required|numeric|length:11'"
                class="w-full vx-col"
                :placeholder="`${$t('teachersGateway.mobile')}`"
                type="text"
              />
            </div>

            <div class="w-full">
                <span class="text-danger text-sm text-center">{{
                    errors.first(`phone_number`)
                  }}</span>
            </div>
          </div>
          <!-- End mobile  -->

          <!--email -->
          <div class="my-2 vx-col  w-full">
            <label>{{ $t("form.email") }}<sup class="text-danger">*</sup></label>
            <vs-input
              type="text"
              v-model="user.email"
              class="w-full mb-2"
              name="email"
              v-validate="'required|email'"
            />
            <span class="text-danger text-sm">{{
                errors.first("email")
              }}</span>
          </div>
          <!-- End email -->

          <!--Password -->
          <div class="my-2 vx-col  w-full" v-if="!ifEdit || !this.$route.params.id">
            <label>{{ $t("form.password") }}<sup class="text-danger">*</sup></label>
            <vs-input
              type="password"
              v-model="user.password"
              class="w-full mb-2"
              name="password"
              v-validate="'required'"
            />
            <span class="text-danger text-sm">{{
                errors.first("password")
              }}</span>
          </div>
          <!-- End Password -->


          <!--Start Gender -->
          <div class="my-2 vx-col  w-full">
            <label>{{ $t("form.gender") }}<sup class="text-danger">*</sup></label>
            <div class="xl:mt-6">
              <vs-radio v-model="user.gender" vs-value="male" class="mr-4">Male</vs-radio>
              <vs-radio v-model="user.gender" vs-value="female" class="mr-4"
              >Female</vs-radio
              >
            </div>
            <span class="text-danger text-sm">{{
                errors.first("last_name")
              }}</span>
          </div>
          <!-- End Gender -->


        </div>
        <!-- Actions -->
        <div class="flex flex-wrap justify-between items-center mt-auto mb-0">
          <vs-button class="mt-5 md:w-auto md:mr-0 ml-auto" @click="submitForm">
            {{ $t("form.submit") }}
          </vs-button>
        </div>
      </form>
    </vs-card>
  </div>
</template>
<script>
// Components
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import vSelect from 'vue-select'
import fileUploader from '@/modules/unit-planner/components/uploadButton/uploadButton.vue'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const canteenRepository = RepositoryFactory.get(
  'canteenRepository',
  'canteenRepository'
)
// import getData from './form/common_data_mixin'

import { commonDataMixin, checkValidationMixin } from '@/mixins'

export default {
  name: 'canteen-user-info',
  props: {
    studentInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    ifEdit: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  mixins: [commonDataMixin, checkValidationMixin],
  // mixins:[getData()],
  components: { flatPickr, vSelect, fileUploader },

  data () {
    return {
      user: {
        name: '',
        email: '',
        phone_number: '',
        password: '',
        canteen_id: this.$route.params.id,
        school_id: JSON.parse(window.localStorage.getItem('authUser')).school_id,
        type_ids: [],
        gender: 'male'
      },
      types: [],
      password:null
    }
  },
  watch: {
  },
  computed: {
    isEdit () {
      return Boolean(this.$route.params.id)
    }
  },
  methods: {
    getAllTypes () {
      canteenRepository.getAllTypes().then(res => {
        this.types = res.data
      })
    },
    fillData () {
      this.VSLoading()
      if (this.ifEdit) {
        canteenRepository.showUserData(this.$route.query.canteen_id, this.$route.params.id).then(res => {
          this.user = {
            ...res.data,
            type_ids: res.data.types.map(item => item.type_id),
            canteen_id: this.$route.query.canteen_id,
            school_id: JSON.parse(window.localStorage.getItem('authUser')).school_id
          }
          this.stopVSLoading()
        })
      }
    },
    check (array, index) {
      const splitted = array.split('.')
      const arr = this[splitted[0]][splitted[1]]
      const unique = new Set(arr)
      if (unique.size !== arr.length) {
        this[splitted[0]][splitted[1]][index] = ''
        this.errMsg(this.$t('teachersGateway.you_cant_repeat_same_value'))
      }
    },
    addMobile () {
      if (this.user.mobile[this.user.mobile.length - 1] !== '') {
        this.user.mobile.push('')
      }
    },
    removeMobile (indexOfmobile) {
      this.user.mobile.splice(indexOfmobile, 1)
    },
    clickOnFile () {
      this.$refs.upload_file.click()
    },

    generatePassword () {
      this.password = this.generateRandomPassword()
    },
    /*---------------------
     submit
    -----------------------*/
    submitForm () {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.$emit('formSubmited', this.user)
        } else {
          this.errMsg()
        }
      })
    }
  },
  created () {
    this.getAllTypes()
    this.fillData()
  },
  mounted () {}
}
</script>
<style lang="scss">
.remove-student-attachment {
  top: 32px;
  left: 90px;
  z-index: 20;
  cursor: pointer;
  svg {
    stroke: red;
    stroke-width: 2px;
  }
}
</style>
