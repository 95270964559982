<template>
  <div v-if="exam">
    <examGrade :exam="exam" />
  </div>
</template>
<script>
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const TeacherRepository = {
  BasicInfo: RepositoryFactory.get('TeachersModule', 'BasicInfo'),
  Exam: RepositoryFactory.get('ExamModule', 'Exam')
}
export default {
  name: 'show-grade',
  components:{
    examGrade: () => import('../components/examGradeModalInStudent')
  },
  data () {
    return {
      exam: null,
      exam_id: this.$route.params.id,
      student_id: this.$store.getters['auth/authUser'].uid
    }
  },
  methods: {
    getSingleExam () {
      new Promise(resolve => {
        TeacherRepository.Exam.getSingleStudentGrade(
          this.student_id,
          this.exam_id
        ).then((res) => {
          this.exam = res
          if (!this.exam.exam.submitted_at) {
            this.errMsg(this.$t('exam.cant_access_unanswer_exam'))
            this.backToStudentProfile()
          }

          resolve(res)
        })
      })
    },
    backToStudentProfile () {
      this.$router.push({
        name: 'BackToProfile',
        params: {
          id: this.authUser.uid
        }
      })
    }

  },
  async created () {
    await this.getSingleExam()
  }
}
</script>
