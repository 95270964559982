<template>
  <div>
    <exam-form :exam_prop.sync="exam_new" :edit_mood="false" />
  </div>
</template>
<script>
import ExamForm from '@/modules/exam/components/ExamForm'
export default {
  components:  {ExamForm},

  data () {
    return {
      exam_new:{

        // Info
        id :0,
        general_status:'active', // active || draft || suspend || archive
        occurrence_status:null,
        status_id: '1',

        points:50,
        step: 0,

        // General
        division_id:null,
        grade_id:null,
        class_id:null,
        subject_id:null,
        type_id:1, // 1=> Digital,2 => PDF  ,3 => oral,4 => Paper
        is_digital_answer:true,
        is_auto_grading:true,
        duration_type: {
          type: 'fixed time',
          id: 1
        }, // 1=>fixed time , 2=> period time , 3=> open time


        // Access Attendees
        is_private_access:false,
        hide_students_identities:false,
        is_access_exam_period:true,
        access_exam_period_hours:0, // Missed
        access_exam_period_minutes:0, // Missed
        attendees:[], // Missed

        // Questions Manager

        questions:[
          {
            // Basic
            type_id:1,   // 1=> Essay , 2=> Multiple answers , 3 => single Answer, 4=>True/False

            is_question_bank:false,
            from_question_bank:null,
            title:'Question 1',
            description:null,
            force_answer_when_first_time:false,
            is_extra_time:false,
            extra_time:0,
            points:50,

            // Essay
            answer_words_limit:null,
            accept_upload_files:false,


            // Multiple Choices && Single Choice && (True, False {only 2 answers})
            answers:[
              {
                description:null,
                is_correct:false

              }
            ],
            is_randomise_answers:false          }
        ],


        // Configuration
        name:'',
        show_display_languag:true, // Missed
        is_randomize_questions:true,
        is_lockdown_functionality:true,
        show_attempt_counts:true,
        show_result_to_student:true,
        show_result_to_parent:true,
        present_all_questions_to_student:true,
        allow_review_answers_after_finish:true,
        allow_answering_questions_in_any_order_and_backword_forword:true,
        set_instructions:true,
        set_passing_score:true,
        passing_score:0,
        auto_publish:true, // in case E-EXAM "Digital , paper , pdf  Exam"
        extra_doc:null,
        extra_doc_url:null,


        statistics:{
          students_attendded_count:null,
          achivement:null
        }


      }
    }
  }
}
</script>
