<template>
  <vx-card class="p-3 pt-6 pb-6 md:p-6">
    <div class="vx-row">
      <div class="vx-col w-full mb-base">
        <!-- Registeration Period -->
        <div class="vx-row mb-6 items-center">
          <div class="vx-col md:w-1/3 w-full mb-2">
            <label>{{ $t("Attendance.attendanceRegisterationPeriod") }}</label>
          </div>

          <div class="vx-col md:w-1/3 w-full ">
            <label>From:</label>
            <flat-pickr
              v-model="newAttendance.from"
              v-validate="'required'"
              name="from_time"
              class="w-full md:mt-0"
              :config="{...configTimePicker,maxDate:newAttendance.to}"
            />

            <span class="text-danger w-full">{{
              errors.first("from_time")|text_formatter
            }}</span>
<!--            <span-->
<!--              v-if="!errors.first('from_hours')"-->
<!--              class="text-danger w-full"-->
<!--              >{{ errors.first("from_minutes") |text_formatter}}</span-->
<!--            >-->
          </div>
          <div class="vx-col md:w-1/3 w-full md:mt-0 mt-3  ">
            <label>To:</label>

            <flat-pickr
              v-model="newAttendance.to"
              v-validate="{required:true}"
              name="to_time"
              class="w-full md:mt-0"
              :config="{...configTimePicker,minDate:newAttendance.from}"
            />
            <span class="text-danger w-full">{{
              errors.first("to_time") |text_formatter
            }}</span>
<!--            <span v-if="!errors.first('to_hours')" class="text-danger w-full">{{-->
<!--              errors.first("to_minutes") |text_formatter-->
<!--            }}</span>-->
          </div>
        </div>

        <!-- Turn to usual -->
        <div class="vx-row mb-6 items-center">
          <div class="vx-col md:w-1/3 w-full">
            <label>{{ $t("Attendance.turnToUsualAbsenceAfter") }}</label>
          </div>
          <div class="vx-col md:w-1/3 w-full">
            <vs-input
              name="usual_absence_after"
              v-validate="'required|numeric|max_value:100|min_value:0'"
              @keypress="acceptOnlyNumbers"
              v-model="newAttendance.usual_absence_after"
              class="w-full"
              placeholder="0"
              icon-after="true"
              icon-pack="feather"
              icon="icon-percent"
            />
            <span class="text-danger w-full">{{
              errors.first("usual_absence_after") | text_formatter
            }}</span>
          </div>
          <div class="vx-col md:w-1/3 w-full">
            <span>{{ $t("Attendance.onMonthlyBasis") }}</span>
          </div>
        </div>

        <!-- Turn to recent usual -->
        <div class="vx-row mb-6 items-center">
          <div class="vx-col sm:w-1/3 w-full">
            <span>{{ $t("Attendance.turnToRecentUsualAbsenceAfter") }}</span>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input
              name="recent_usual_absence_after"
              v-validate="'required|numeric|max:3'"
              @keypress="acceptOnlyNumbers"
              v-model="newAttendance.recent_usual_absence_after"
              class="w-full"
              placeholder="0"
              icon-after="true"
              icon-pack="feather"
              icon="icon-hash"
            />
            <span class="text-danger w-full">{{
              errors.first("recent_usual_absence_after") | text_formatter
            }}</span>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <span>{{ $t("Attendance.daysBeingAbsent") }}</span>
          </div>
        </div>

        <!-- Present improvement meter -->
        <div class="vx-row mb-6 items-center">
          <div class="vx-col sm:w-1/3 w-full">
            <span>{{ $t("Attendance.presentImprovement") }}</span>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input
              name="present_improvement_meter"
              v-validate="'required|numeric|max:3'"
              @keypress="acceptOnlyNumbers"
              v-model="newAttendance.present_improvement_meter"
              class="w-full"
              placeholder="0"
              icon-after="true"
              icon-pack="feather"
              icon="icon-hash"
            />
            <span class="text-danger w-full">{{
              errors.first("present_improvement_meter") | text_formatter
            }}</span>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <span>{{ $t("Attendance.days") }}</span>
          </div>
        </div>

        <!-- Max days to send reason -->
        <div class="vx-row mb-6 items-center">
          <div class="vx-col md:w-1/3 w-full">
            <label>{{ $t("Attendance.maxDaysToSendReason") }}</label>
          </div>
          <div class="vx-col md:w-1/3 w-full">
            <vs-input
              name="days_reason_absence"
              v-validate="'required|numeric|max:3'"
              @keypress="acceptOnlyNumbers"
              v-model="newAttendance.days_reason_absence"
              class="w-full"
              placeholder="0"
              icon-after="true"
              icon-pack="feather"
              icon="icon-hash"
            />
            <span class="text-danger w-full">{{
              errors.first("days_reason_absence") | text_formatter
            }}</span>
          </div>
          <div class="vx-col md:w-1/3 w-full">
            <span>{{ $t("Attendance.daysAfterAbsencsDate") }}</span>
          </div>
        </div>

        <!-- Turn off  -->
        <!-- <div class="vx-row mb-6 items-center">
          <div class="vx-col md:w-1/3 w-full">
            <label>{{$t('Attendance.turnToContinuousDaysAfter')}}</label>
          </div>
          <div class="vx-col md:w-1/3 w-full">
            <vs-input
              name="continuous_days_absence"
              v-validate="'required'"
              @keypress="acceptOnlyNumbers"
              v-model="newAttendance.continuous_days_absence"
              class="w-full"
              placeholder="0"
              icon-after="true"
              icon-pack="feather"
              icon="icon-hash"
            />
            <span class="text-danger w-full">{{ errors.first('continuous_days_absence') }}</span>
          </div>
          <div class="vx-col md:w-1/3 w-full">
            <span>{{$t('Attendance.continuousDays')}}</span>
          </div>
        </div> -->

        <!-- Max Accepted Absence Days -->
        <!-- <div class="vx-row mb-6 items-center">
          <div class="vx-col md:w-1/3 w-full">
            <label>{{$t('Attendance.maxAcceptedAbsenceDays')}}</label>
          </div>
          <div class="vx-col md:w-1/3 w-full">
            <vs-input
              name="accepted_absence_month"
              v-validate="'required'"
              @keypress="acceptOnlyNumbers"
              v-model="newAttendance.accepted_absence_month"
              class="w-full"
              placeholder="0"
              icon-after="true"
              icon-pack="feather"
              icon="icon-hash"
            />
            <span class="text-danger w-full">{{ errors.first('accepted_absence_month') }}</span>
          </div>
          <div class="vx-col md:w-1/3 w-full">
            <label>{{$t('Attendance.days')}}</label>
          </div>
        </div> -->

        <!-- Max Accepted Absence Terms -->
        <!-- <div class="vx-row mb-6 items-center">
          <div class="vx-col md:w-1/3 w-full">
            <span>{{$t('Attendance.maxAcceptedAbsenceTerm')}}</span>
          </div>
          <div class="vx-col md:w-1/3 w-full">
            <vs-input
              name="accepted_absence_term"
              v-validate="'required'"
              @keypress="acceptOnlyNumbers"
              v-model="newAttendance.accepted_absence_term"
              class="w-full"
              placeholder="0"
              icon-after="true"
              icon-pack="feather"
              icon="icon-percent"
            />
            <span class="text-danger w-full">{{ errors.first('accepted_absence_term') }}</span>
          </div>
          <div class="vx-col md:w-1/3 w-full">
            <span>{{$t('Attendance.perTerm')}}</span>
          </div>
        </div> -->

        <!-- Accept taking attendance -->
        <div class="vx-row mb-6 items-center">
          <div class="vx-col md:w-1/3 w-full">
            <label>{{
              $t("Attendance.accpetTakingAttendanceDuringEClass")
            }}</label>
          </div>
          <div class="vx-col md:w-1/3 w-full">
            <vs-switch
              color="success"
              v-model="newAttendance.take_attendance_eclass"
            >
              <span slot="on">Accept</span>
            </vs-switch>
          </div>
          <div class="vx-col md:w-1/3 w-full">
            <span></span>
          </div>
        </div>

        <!-- Let teachers use the app -->
        <div class="vx-row mb-6 items-center">
          <div class="vx-col md:w-1/3 w-full">
            <label>{{ $t("Attendance.letTeachersUseTheApp") }}</label>
          </div>
          <div class="vx-col md:w-1/3 w-full">
            <vs-switch
              color="success"
              v-model="newAttendance.teacher_take_attendance"
            >
              <span slot="on">Accept</span>
            </vs-switch>
          </div>
          <div class="vx-col md:w-1/3 w-full">
            <span></span>
          </div>
        </div>
        <div class="vx-row mb-6 items-center">
          <div class="vx-col sm:w-1/6 w-full">  <vs-button :to="{name:'ConsoleHome'}" type="border" color="warning">{{
              $t("form.cancel")
            }}</vs-button></div>
          <div class="vx-col sm:w-1/6 w-full"></div>
          <div class="vx-col sm:w-1/6 w-full"></div>
          <div class="vx-col sm:w-1/6 w-full"></div>
          <div class="vx-col sm:w-1/6 w-full"></div>
          <div class="vx-col sm:w-1/6 w-full">
            <vs-button @click="modifySettings()" type="filled">{{
              $t("form.submit")
            }}</vs-button>
          </div>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const SettingsRepository = RepositoryFactory.get('Attendance', 'settings')

export default {
  components: {
    'v-select': vSelect
  },
  inject: ['$validator'],
  computed: {
    ...mapGetters('auth', ['authUser'])
  },
  data () {
    return {
      teachers_all: [],
      newAttendance: {
        from: '',
        to: '',
        usual_absence_after: '',
        // accepted_absence_month: '',
        //accepted_absence_term: '',
        days_reason_absence: '',
        //continuous_days_absence: '',
        recent_usual_absence_after: '',
        present_improvement_meter: '',
        take_attendance_eclass: false,
        teacher_take_attendance: false,
        school_id: ''
      },
      configTimePicker: {
        enableTime: true,
        noCalendar: true
      }
    }
  },
  watch:{
    // 'newAttendance.from' () {
    //   this.newAttendance.to = null
    // }
  },
  methods: {
    acceptOnlyNumbers (newChar) {
      const field_name = newChar.originalTarget.name
      // this.extraValidationErrors[field_name] = null;
      const charCode = newChar.charCode
      // Numbers Characters
      if (charCode >= 48 && charCode <= 57) return true
      // this.extraValidationErrors[field_name] =
      //   "Only Numbers Are Allowed in this field";
      newChar.preventDefault()
    },
    modifySettings () {
      this.$validator.validate().then((valid) => {
        if (valid) {
          this.newAttendance.school_id = this.authUser.school_id
          SettingsRepository.modifyAttendance(this.newAttendance).then(
            (res) => {
              this.$vs.notify({
                title: 'Successfully!',
                text: 'Settings has been modified successfully',
                iconPack: 'feather',
                icon: 'icon-check-circle',
                color: 'success'
              })
              setTimeout(() => {
                this.$router.push('/attendance/attendance-console')
              }, 1300)
            }
          )
        } else {
          this.$vs.notify({
            title: 'Failed!',
            text: 'Please fill in the required fields',
            iconPack: 'feather',
            icon: 'icon-x-circle',
            color: 'danger'
          })
        }
      })
    }
  },
  created () {
    SettingsRepository.getTeachers(this.authUser.school_id).then((res) => {
      this.teachers_all = res.response
    })

    SettingsRepository.getAttendance(this.authUser.school_id)
      .then((res) => {
        this.newAttendance['school_id'] = this.authUser.school_id
        this.newAttendance['from'] = res.response['from']
        this.newAttendance['to'] = res.response['to']
        this.newAttendance['usual_absence_after'] =
          res.response['usual_absence_after']
        // this.newAttendance['accepted_absence_month'] =
        //   res.response['accepted_absence_month']
        this.newAttendance['days_reason_absence'] =
          res.response['days_reason_absence']
        // this.newAttendance['accepted_absence_term'] =
        //   res.response['accepted_absence_term']
        // this.newAttendance['continuous_days_absence'] =
        //   res.response['continuous_days_absence']
        this.newAttendance['take_attendance_eclass'] =
          res.response['take_attendance_eclass']
        this.newAttendance['teacher_take_attendance'] =
          res.response['teacher_take_attendance']
        this.newAttendance['recent_usual_absence_after'] =
          res.response['recent_usual_absence_after']
        this.newAttendance['present_improvement_meter'] =
          res.response['present_improvement_meter']
      })
      .catch((err) => {
        this.newAttendance['school_id'] = ''
        this.newAttendance['from'] = ''
        this.newAttendance['to'] = ''
        this.newAttendance['usual_absence_after'] = ''
        //this.newAttendance['accepted_absence_month'] = ''
        this.newAttendance['days_reason_absence'] = ''
        // this.newAttendance['accepted_absence_term'] = ''
        //this.newAttendance['continuous_days_absence'] = ''
        this.newAttendance['take_attendance_eclass'] = false
        this.newAttendance['teacher_take_attendance'] = false
        this.newAttendance['recent_usual_absence_after'] = ''
        this.newAttendance['present_improvement_meter'] = ''
      })
  }
}
</script>
