<template>
  <div>
    <assignRoleToUserForm></assignRoleToUserForm>
  </div>
</template>
<script>
import assignRoleToUserForm from './components/assignRoleToUserForm.vue'
export default {
  name: 'AssignRoleToUser',
  components: {
    assignRoleToUserForm
  },
  data () {
    return {

    }
  }
}
</script>
