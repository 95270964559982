import {
  RepositoryFactory
} from '@/Repositories/RepositoryFactory'
const assignmentsRepo = RepositoryFactory.get('schoolPrincipalRepository', 'assignmentsRepo')

export default {

  data () {
    return {
      tasksData: [],
      teacherId: this.$route.params.id
    }
  },
  created () {
    this.fetchTeacherAssignment()
  },
  methods: {
    updateGrid (data) {
      const itemIndex = this.tasksData.findIndex(item => item.id === data.id)
      if (itemIndex !== -1) {
        /*case update*/
        this.tasksData.splice(itemIndex, 1, data)
      } else {
        this.tasksData.unshift(data)
      }
    },

    fetchTeacherAssignment () {
      this.VSLoading()

      //** Get IB types **//
      assignmentsRepo
        .getTeacherAssignments(this.teacherId)
        .then(res => {
          // console.log('assignments', res)
          this.tasksData = res.data.sort((a, b) => b.id - a.id)
          //** Hide Loader **//
        })
        .catch(err => {
          // console.error(err)
          // //** Hide Loader **//
          this.errMsg(err.data.message, this.$t('form.loading_data_error'))

        })
        .finally(() => this.stopVSLoading())
    }

  }
}
