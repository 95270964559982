<template>
  <div class="vx-card p-3 pt-6 pb-6 md:p-6">
    <div class="vx-row mt-5 mb-6">
      <div class="vx-col w-full text-center">
        <h4>
          {{ $t("main.Welcome") }}
          {{ $store.getters["auth/authUser"].displayName }}
        </h4>
      </div>
    </div>

    <div class="vx-row mb-5">
      <div class="vx-col sm:w-3/4 w-full mx-auto">
        <vs-alert active="true" class="text-center py-4">
          <p>
            {{
              $t(
                "basic.please_login_here_using_schoolia_credentials_email_and_password"
              )
            }}
          </p>

          <vs-button
            v-bind:href="institutionDriverLink"
            target="_blank"
            class="mt-6 mx-auto block"
            color="primary"
            type="filled"
            :disabled="!institutionDriverLink"
            >{{ $t("basic.access_drive") }}
          </vs-button>
          <p v-if="loadingLink" class="mt-2 block w-full text-sm">
            <span class="text-dark" v-if="!institutionDriverLink">{{
              $t(
                "basic.please_be_patient_your_access_link_will_be_here_very_soon"
              ) | text_formatter
            }}</span>
            <span v-else class="text-success">{{
              $t("basic.here_we_go") | text_formatter
            }}</span>
          </p>
        </vs-alert>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loadingLink:false
    }
  },
  methods: {},
  watch:{
    institutionDriverLink (v) {
      if (v) setTimeout(() => { this.loadingLink = false }, 1500)
    }
  },
  created () {
    if (!this.institutionDriverLink) this.loadingLink = true
  },
  computed: {
    // driverLink () {
    //   const subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false
    //   if (!subdomain) {
    //     return ''
    //   }
    //   return `https://drive.${subdomain}.schoolia-eg.com/login?redirect_url=/apps/files/`
    // }
  }
}
</script>

<style lang="scss" scoped>
.active .vx-card {
  -webkit-box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
  box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
}
</style>
