<template>
  <div id="list_datatable w-full">
    <div class="vx-row mb-2">
      <!-- Create Student -->
      <div
        class="vx-col w-full flex justify-end my-4"
        v-if="checkUserRole('SND')"
      >
        <vs-button color="success" :to="{ name: 'create-canteen-user', params: {id: this.$route.params.id } }">
          {{ $t("canteen.createUser") }}
        </vs-button>
      </div>
    </div>
    <div class="vx-row">
      <div
        class="vx-col w-full"
      >
        <CanteenUserTable
          :headers_prop="headers"

          :loading="loading"
          :list_items="searchResult"
        >
        </CanteenUserTable>
        <!--          :list_url_prop="`/canteen/users/${$route.params.id}`"-->
      </div>
    </div>
  </div>
</template>

<script>

import CanteenUserTable from '@/components/general/datatable/DataTable2.vue'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const canteenRepository = RepositoryFactory.get(
  'canteenRepository',
  'canteenRepository'
)
import { commonDataMixin } from '@/mixins'

export default {
  components: {
    CanteenUserTable
  },
  mixins: [commonDataMixin],
  data () {
    return {
      headers: [
        {
          minWidth: 170,
          headerName: this.$t('basic.code'),
          field: 'id'
        },
        {
          minWidth: 250,
          headerName: this.$t('canteen.name'),
          field: 'name'
        },
        {
          minWidth: 160,
          headerName: this.$t('canteen.userType'),
          field: 'types'
        },
        {
          minWidth: 95,
          headerName: this.$t('basic.status'),
          field: 'status',
          cellRendererFramework: 'CellRendererStatus'
        },
        {
          minWidth: 120,
          headerName: this.$t('basic.actions'),
          filter: false,
          field: 'actions',

          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            actions_prop: [
              {
                dropDown: true,
                dropDown_items: [
                  {
                    title: this.$t('basic.update'),
                    icon: 'Edit3Icon',
                    actionEmitter: 'goToEdit',
                    actionEmitterParams: ['id']
                  },
                  {
                    title: this.$t('basic.suspend'),
                    parameter_name: 'id',
                    icon: 'SuspendIcon',
                    action: 'Suspend',
                    resource: 'canteen/users/change-status/:id',
                    requestType: 'post',
                    requestPayload: { status: 'suspend' },
                    showIf: (data) => data.status !== 'Suspend'
                  }
                ]
              }
            ]
          }
        }
      ],
      searchResult: [],
      loading:false
    }
  },
  watch:{
  },
  methods: {
    getAllUsers (id) {
      canteenRepository.getAllUsers(id).then(res => {
        this.searchResult = res.data.data.map(item => {
          return {...item,
            types: item.types.map(type => {
              return type.type
            })}
        })
      })
    },
    goToEdit (userId) {
      this.$router.push({name: 'edit-canteen-user', params: {user_id: userId, id: this.$route.params.id}, query:{canteen_id : this.$route.params.id} })
    }
  },
  computed: {
  },
  beforeDestroy () {
    this.$root.$off('goToEdit')
  },
  created () {
    this.getAllUsers(this.$route.params.id)
    this.$root.$on('goToEdit', (data) => this.goToEdit(data.id))
  }
}
</script>

<style lang="scss" scoped>
.active .vx-card {
  -webkit-box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
  box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
}
</style>
