<template>
  <div>
    <div v-if="allowed" class="vx-row">
      <div
        class="vx-col w-full mb-5"
        :class="{ 'lg:w-3/4': !this.$store.state.reduceButton }"
        v-if="allowed"
      >
        <exam-preview :exam_prop="exam" :edit_mood="true"></exam-preview>
      </div>
      <!-- <div class="vx-col lg:w-1/4 w-full">
        <ExamOutline class="mb-2" :exam="exam" />
      </div> -->
    </div>
    <div v-if="!allowed">
      <vx-card>
        <h1 class="text-center" style="text-transform: none">{{ msg }}</h1>
      </vx-card>
    </div>
  </div>
</template>

<script>
import ExamPreview from '../components/answerExamInStudent'
import ExamOutline from '../components/ExamOutlineInStudent'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const TeacherRepository = {
  Exam: RepositoryFactory.get('ExamModule', 'Exam')
}
import moment from 'moment'

export default {
  data () {
    return {
      exam: {},
      allowed: false,
      msg: this.$t('exam.Not_allowed_to_access_exam_at_current_time'),
      fetchStartExam:null
    }
  },
  computed: {
    exam_id () {
      return this.$route.params.id
    }
  },
  async  created () {
    document.addEventListener('visibilitychange', this.focusChanged)
    let examDate = null
    const dateNow = new Date()
    await this.startExam().catch((date) => {
      examDate = new Date(date.fullYear, date.month, date.day, date.hours, date.minutes, date.seconds)
      const waitTime = examDate.getTime() - dateNow.getTime()
      if (waitTime >= 0) {
        this.fetchStartExam = setTimeout(() => {
          this.startExam().then(() => {
            this.successMsg(this.$t('exam.the_exam_has_been_started_you_can_answered_it_now'))
          })
        }, waitTime)
      }
    })

  },
  beforeDestroy () {
    clearTimeout(this.fetchStartExam)
    document.removeEventListener('visibilitychange', this.focusChanged)
  },
  methods: {
    focusChanged () {
      console.log('changed')
    },
    printInvoice () {
      window.print()
    },
    startExam () {
      return new Promise((resolve, reject) => {
        const data = {
          date: moment().format('YYYY-MM-DD HH:mm')
        }
        TeacherRepository.Exam.startExam(this.exam_id, data)
          .then((res) => {
            const hasAccess = res.assign_exam === 'true' 
            this.msg = res.message
            if (hasAccess) {
              this.getExam()
              resolve()
            } else {
              const date = {
                fullYear:Number(res.data.date.slice(0, 4)),
                month:Number(res.data.date.slice(5, 7)) - 1,
                day:Number(res.data.date.slice(8, 10)),
                hours:Number(res.data.duration_fixed_time_start_time.slice(0, 2)),
                minutes:Number(res.data.duration_fixed_time_start_time.slice(3, 5)),
                seconds: 0
              }
              this.allowed = false
              reject(date)
            }
          })
          .catch((err) => {
            // console.log('err', err)
            this.msg = err.data.msg
            reject()
          })
      })
    },

    getExam () {
      TeacherRepository.Exam.getExamInStudent(this.exam_id)
        .then((res) => {
          this.exam = res.data
          this.exam.questions.forEach((question) => {
            if (question.attachments == undefined) {
              question.attachments = [
                {
                  url: this.institutionAssetsLink,
                  type: null,
                  attachment: null
                }
              ]
            }
          })
          this.allowed = true

        })
        .catch((err) => {
          console.log('err', err)
          this.allowed = false
        })
    }
  },
  components: { ExamPreview, ExamOutline },

  mounted () {
    this.$emit('setAppClasses', 'invoice-page')
  }
}
</script>

<style lang="scss">
@media print {
  .invoice-page {
    h6 {
      b {
        text-transform: uppercase;
        color: #626262;
      }
    }
    * {
      visibility: hidden;
    }

    #content-area {
      margin: 0 !important;
    }

    .vs-con-table {
      .vs-con-tbody {
        overflow: hidden !important;
      }
    }

    #invoice-container,
    #invoice-container * {
      visibility: visible;
    }
    #invoice-container {
      position: absolute;
      left: 0;
      top: 0;
      box-shadow: none;
    }
  }
}
@page {
  size: auto;
}
</style>
