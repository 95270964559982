<template>
  <div class="pb-10 matiral-card">
    <grid-card-4>
      <template v-slot:actionButtons>
        <vs-dropdown
          class="matiralactionButtons text-capitalize cursor-pointer"
          vs-custom-content
          vs-trigger-click
        >
          <vs-button color="#999" type="flat" icon="more_vert"></vs-button>
          <vs-dropdown-menu>
            <vs-dropdown-item
              :to="{
                name: 'TeachingMaterialsShare',
                params: {
                  id: materialProp.id,
                },
              }"
              v-if="materialProp.status === 'active'"
            >
              <feather-icon
                icon="Share2Icon"
                svgClasses="text-blue h-4 w-4 mr-1"
              />
              {{ $t("basic.share") }}
            </vs-dropdown-item>

            <vs-dropdown-item
              v-if="
                materialProp.auth_user === 'uploader' &&
                materialProp.status === 'active'
              "
              :to="{
                name: 'TeachingMaterialsEdit',
                params: {
                  id: materialProp.id,
                },
              }"
            >
              <feather-icon
                icon="Edit2Icon"
                svgClasses="text-blue h-4 w-4 mr-1"
              />
              {{ $t("basic.edit") }}
            </vs-dropdown-item>

            <vs-dropdown-item @click="downloadFiles()" s>
              <feather-icon
                class="inline-block"
                icon="DownloadCloudIcon"
                svgClasses="text-blue h-4 w-4 mr-1"
              />
              {{ $t("basic.download") }}</vs-dropdown-item
            >
            <template>

              <vs-dropdown-item
                v-if="
                  materialProp.status === 'active' &&
                  !materialProp.is_shared_before
                "
                @click="changeStatus('archive')"
              >
                <feather-icon
                  class="inline-block"
                  icon="ArchiveIcon"
                  svgClasses="text-blue h-4 w-4 mr-1"
                />
                {{ $t("basic.archive") }}</vs-dropdown-item
              >
            </template>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template v-slot:first>
        <div class="pb-3">
          <h5 class="pb-2 ">{{ materialProp.name }}</h5>
          <p>{{ materialProp.subject_name }}</p>
          <div class="py-3">
            <vs-chip :class="`status--${materialProp.status} mb-6`">
              <span>{{ materialProp.status }}</span>
            </vs-chip>
          </div>
        </div>
      </template>
      <template v-slot:second>
        <div class="pb-3">
          <h6 class="pb-3">
            <feather-icon icon="UserIcon" svgClasses="text-blue h-4 w-4 mr-1" />
            {{ materialProp.created_by.name }}
          </h6>
          <div class="mb-2 text-sm">
            <feather-icon
              icon="CalendarIcon"
              svgClasses=" h-4 w-4 mr-1"
            />
            <span>{{ $t("basic.date") }} : {{ materialProp.created_at }} </span>
          </div>
        </div>
      </template>
      <template v-slot:third>
        <div class="pb-3">
          <div>
            <div v-for="oneClass in materialProp.classes" :key="oneClass.id">
              <vs-chip
                class="status--primary status-text--blue inline-block my-1"
              >
                <span>{{ oneClass.name }}</span>
              </vs-chip>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:fourth>
        <div v-for="type in materialProp.types" :key="type.id">
          <vs-chip class="status--black status-text--blue block">
            <span>{{ type.name }}</span>
          </vs-chip>
        </div>
      </template>
    </grid-card-4>
  </div>
</template>

<script>
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const TeacherRepository = RepositoryFactory.get(
  'TeachersModule',
  'teaching_materials'
)
export default {
  props: ['materialProp', 'getTeachingMaterialList'],

  methods: {
    changeStatus (status) {
      TeacherRepository.status(this.materialProp.id, status).then((res) => {
        this.successMsg(res.message)
        this.getTeachingMaterialList()
      })
    },
    downloadFiles () {
      this.materialProp.files.forEach((element) => {
        this.downloadFile(element.attachment_url)
      })
    }
  }
}
</script>
<style lang="scss">
.matiralactionButtons {
  position: absolute;
  top: 0;
  right: 0;
}
.vs-dropdown--menu {
  min-width: 10em !important;
}
</style>
