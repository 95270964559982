
<template>
    <academic-year-form :edit_id="edit_id "></academic-year-form>
</template>
<script>

import academicYearForm from './components/academicYearForm'

export default {
  name:'EditMasterYears',
  components: {
    academicYearForm
  },
  created () {
    this.edit_id = this.$route.params.id

  },

  data () {
    return {
      edit_id:null
    }
  },
  methods: {

  }
}
</script>

<style  scoped>
.background-none {
  background-color: transparent !important;
  color: rgb(160, 152, 152);
  cursor: pointer;
}
</style>
