import Details from './pages/institution-details/Details.vue'
import AddBranch from './pages/add-branch/AddBranch'
import Create from './pages/institution-details/Create.vue'
import InstitutionEdit from './pages/institution-details/Create.vue'
import InstitutionShow from './pages/institution-details/Create.vue'
import DivisionCreate from './pages/division/Create.vue'
import DivisionEdit from './pages/division/Edit.vue'
import DivisionDetails from './pages/division/Details.vue'
import SectionCreate from './pages/division/CreateSection.vue'
import BranchDetails from './pages/add-branch/Details.vue'
import BranchEdit from './pages/add-branch/Edit.vue'
import SectionDetails from './pages/division/ListSections.vue'
import ShowSection from './pages/division/ShowSection'
import SectionEdit from './pages/division/EditSection'
import RolesList from './pages/user-management/roles/List.vue'
import RolesCreate from './pages/user-management/roles/Create.vue'
import RolesEdit from './pages/user-management/roles/Edit.vue'
import UsersList from './pages/user-management/users/List.vue'
import UsersCreate from './pages/user-management/users/Create.vue'
import UsersAssignRole from './pages/user-management/users/AssignRole.vue'
import UsersEdit from './pages/user-management/users/Edit.vue'
import UsersEditAssignRole from './pages/user-management/users/EditAssignRole.vue'
import PoiContainer from '../poi/components/poi-container/poi-container.vue'
import usersLogs from '@/modules/users-logs/users-logs.routes' // from user-logs modules

export default [
  ...usersLogs,
  /*{
   path: 'institution-details',
    name: 'InstitutionDetails',
    component: Details,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        { title: 'config.Config', url: '/system-configurations' },
        { title: 'config.InstitutionDetails', url: '/system-configurations/institution-details', active:true }
      ],
      pageTitle: 'config.InstitutionDetails',
     rule:'SNDSMD'
    }
  },*/
  {
    path: 'edit-section/:id',
    name: 'SectionEdit',
    component: SectionEdit,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        //   { title: 'config.Config', url: '/system-configurations' },
        {
          title: 'config.SectionEdit',
          url: '/system-configurations/EditSection',
          active: true
        }
      ],
      pageTitle: 'config.EditSection',
      rule:'SND'
    }
  },
  {
    path: 'show-section/:id',
    name: 'ShowSection',
    component: ShowSection,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        //   { title: 'config.Config', url: '/system-configurations' },
        {
          title: 'config.ShowSection',
          url: '/system-configurations/ShowSection',
          active: true
        }
      ],
      pageTitle: 'config.ShowSection',
      rule:'SND'
    }
  },
  {
    path: 'section-details',
    name: 'SectionDetails',
    component: SectionDetails,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        //   { title: 'config.Config', url: '/system-configurations' },
        {
          title: 'config.SectionDetails',
          url: '/system-configurations/section-details',
          active: true
        }
      ],
      pageTitle: 'config.SectionDetails',
      rule:'SND'
    }
  },
  {
    path: 'add-branch',
    name: 'AddBranch',
    component: AddBranch,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        //  { title: 'config.Config', url: '/system-configurations' ,  active:false},
        {
          title: 'config.BranchDetails',
          url: '/system-configurations/branch-details'
        },
        {
          title: 'main.create',
          url: '/system-configurations/add-branch',
          active: true
        }
      ],
      pageTitle: 'config.AddBranch',
      rule:'SMD'
    }
  },
  {
    path: 'branch-details',
    name: 'BranchDetails',
    component: BranchDetails,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        //   { title: 'config.Config', url: '/system-configurations' },
        {
          title: 'config.BranchDetails',
          url: '/system-configurations/branch-details',
          active: true
        }
      ],
      pageTitle: 'config.BranchDetails',
      rule:'SMD'
    }
  },
  {
    path: 'poi-details',
    name: 'PoiContainer',
    component: PoiContainer,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        {
          title: 'poi.poiDetails',
          url: '/system-configurations/poi-details',
          active: true
        }
      ],
      pageTitle: 'config.POI',
      rule: 'SNDPOICoordinator'
    }
  },
  {
    path: 'branch-edit/:id',
    name: 'BranchEdit',
    component: BranchEdit,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        //  { title: 'config.Config', url: '/system-configurations' },
        {
          title: 'config.BranchDetails',
          url: '/system-configurations/branch-details'
        },
        {
          title: 'main.Edit',
          url: '/system-configurations/branch-details',
          active: true
        }
      ],
      pageTitle: 'config.BranchEdit',
      rule:'SMD'
    }
  },
  {
    path: 'institution-details',
    name: 'InstitutionDetails',
    component: Create,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        // { title: 'config.Config', url: '/system-configurations' },
        {
          title: 'config.InstitutionDetails',
          url: '/system-configurations/institution-details',
          active: true
        }
      ],
      pageTitle: 'config.InstitutionDetails',
      rule:'SMD'
    }
  },
  {
    path: 'institution-details/edit',
    name: 'InstitutionEdit',
    component: InstitutionEdit,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        // { title: 'config.Config', url: '/system-configurations' },
        {
          title: 'config.InstitutionDetails',
          url: '/system-configurations/institution-details',
          active: true
        }
      ],
      pageTitle: 'config.InstitutionEdit',
      rule:'SMD'
    }
  },
  {
    path: 'institution-details/show',
    name: 'InstitutionShow',
    component: InstitutionShow,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        // { title: 'config.Config', url: '/system-configurations' },
        {
          title: 'config.InstitutionDetails',
          url: '/system-configurations/institution-details',
          active: true
        }
      ],
      pageTitle: 'config.InstitutionShow',
      rule:'SMD'
    }
  },

  {
    path: 'division/Create',
    name: 'DivisionCreate',
    component: DivisionCreate,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        // { title: 'config.Config', url: '/system-configurations' },
        {
          title: 'config.DivisionDetails',
          url: '/system-configurations/division-details',
          active: true
        },
        {
          title: 'main.create',
          url: '/system-configurations/division-details',
          active: true
        }
      ],
      pageTitle: 'config.DivisionCreate',
      rule:'SND'
    }
  },
  {
    path: 'division/edit/:id',
    name: 'DivisionEdit',
    component: DivisionEdit,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        // { title: 'config.Config', url: '/system-configurations' },
        {
          title: 'config.DivisionDetails',
          url: '/system-configurations/division-details',
          active: true
        },
        {
          title: 'main.Edit',
          url: '/system-configurations/division-details',
          active: true
        }
      ],
      pageTitle: 'config.DivisionEdit',
      rule:'SND'
    }
  },
  {
    path: 'division-details',
    name: 'DivisionDetails',
    component: DivisionDetails,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        //{ title: 'config.Config', url: '/system-configurations' },
        {
          title: 'config.DivisionDetails',
          url: '/system-configurations/division-details',
          active: true
        }
      ],
      pageTitle: 'config.DivisionDetails',
      rule:'SND'
    }
  },
  /* ,
  {
    path: 'division/section/create',
    name: 'CreateSection',
    component: CreateSection,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        { title: 'config.Config', url: '/system-configurations' },
        { title: 'config.DivisionEdit', url: '/system-configurations/division-details', active:true }
      ],
      pageTitle: 'config.CreateSection',
     rule:'SNDSMD'
    }
  } /*/

  {
    path: 'section/create/:id',
    name: 'SectionCreate',
    component: SectionCreate,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        // { title: 'config.Config', url: '/system-configurations' },
        {
          title: 'config.DivisionDetails',
          url: '/system-configurations/division-details'
        },
        {
          title: 'main.create',
          url: '/system-configurations/division-details',
          active: true
        }
      ],
      pageTitle: 'config.SectionCreate',
      rule:'SND'
    }
  },
  // ------------------ user management ------------------
  // roles
  {
    path: 'user-management/roles',
    name: 'Roles',
    component: RolesList,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        {
          title: 'config.roles',
          url: '/system-configurations/user-management/roles',
          active: true
        }
      ],
      pageTitle: 'config.roles',
      rule:'SMD'
    }
  },
  {
    path: 'user-management/roles/create',
    name: 'RolesCreate',
    component: RolesCreate,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        {
          title: 'config.roles',
          url: '/system-configurations/user-management/roles'
        },
        {
          title: 'basic.create',
          url: '/system-configurations/user-management/roles/create',
          active: true
        }
      ],
      pageTitle: 'config.createRole',
      rule:'SMD'
    }
  },
  // {
  //   path: 'user-management/roles/edit/:id',
  //   name: 'RolesEdit',
  //   component:  RolesEdit,
  //   meta: {
  //     breadcrumb: [
  //       { title: 'home', url: '/home' },
  //       { title: 'config.roles', url: '/system-configurations/user-management/roles' },
  //       { title: 'basic.edit', url: '/system-configurations/user-management/roles/edit/:id', active:true}

  //     ],
  //     pageTitle: 'config.editRole',
  //    rule:'SNDSMD'
  //   }
  // },
  {
    path: 'user-management/roles/edit/:id',
    name: 'RoleEdit',
    component: RolesEdit,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        {
          title: 'config.roles',
          url: '/system-configurations/user-management/roles'
        },
        {
          title: 'basic.edit',
          url: '/system-configurations/user-management/roles/edit/:id',
          active: true
        }
      ],
      pageTitle: 'config.editRole',
      rule:'SMD'
    }
  },

  // users
  {
    path: 'user-management/users',
    name: 'UsersList',
    component: UsersList,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        {
          title: 'config.users',
          url: '/system-configurations/user-management/users',
          active: true
        }
      ],
      pageTitle: 'config.users',
      rule:'SMD'
    }
  },
  // Create
  {
    path: 'user-management/users/create',
    name: 'UsersRoleCreate',
    component: UsersCreate,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        {
          title: 'config.users',
          url: '/system-configurations/user-management/users'
        },
        {
          title: 'basic.create',
          url: '',
          active: true
        }
      ],
      pageTitle: 'config.add_user',
      rule:'SMD'
    }
  },
  {
    path: 'user-management/users/edit/:id',
    name: 'UserEdit',
    component: UsersEdit,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        {
          title: 'config.users',
          url: '/system-configurations/user-management/users'
        },
        {
          title: 'basic.edit',
          url: null,
          active: true
        }
      ],
      pageTitle: 'config.editUser',
      rule:'SMD'
    }
  },
  // Assign Role
  {
    path: 'user-management/users/assignRole',
    name: 'UsersAssignRole',
    component: UsersAssignRole,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        {
          title: 'config.users',
          url: '/system-configurations/user-management/users'
        },
        {
          title: 'config.assign_role',
          url: '',
          active: true
        }
      ],
      pageTitle: 'config.assign_role_to_user',
      rule:'SMD'
    }
  },
  {
    path: 'user-management/users/assignRole/:id',
    name: 'UsersRoleEdit',
    component: UsersEditAssignRole,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' },
        {
          title: 'config.users',
          url: '/system-configurations/user-management/users'
        },
        {
          title: 'config.assign_role',
          url: '',
          active: true
        },
        {
          title: 'basic.edit',
          url: null,
          active: true
        }
      ],
      pageTitle: 'config.assign_role_to_user',
      rule:'SMD'
    }
  }

]
