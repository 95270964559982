<template>
  <div id="list_datatable" class="w-full">
    <div class="vx-row mb-3">
      <div
        class="
          vx-col
          xl:w-1/5
          lg:w-1/4
          md:w-1/3
          sm:w-1/2
          w-full
          mr-0
          ml-auto
          block
        "
      >
        <vs-button class="mr-0 ml-auto block" :to="{ name: 'DivisionCreate' }"
          >{{ $t('config.DivisionCreate') }}
        </vs-button>
      </div>
    </div>

    <div class="vx-row">
      <div :class="'vx-col w-full ' + (activeSidebar ? 'lg:w-3/3' : '')">
        <data-table
          :csv_url="'division/exportDivision'"
          :pdf_url="'divisions-file/pdf'"
          :headers_prop="headers"
          list_url_prop="division"
          endPointParams="index=all"
        >
          <template slot="center_content">
            <!--  TODO: add the appropriate export element -->
            <!-- <h3>Center Content</h3> -->
          </template>
        </data-table>
      </div>

      <!-- Filter Sidebar -->

      <transition name="slide-fade">
        <div class="vx-col lg:w-1/3 w-full" v-if="activeSidebar === true">
          <round-filter
            :filters_prop="filters"
            :filters_choosen_prop="filter_choosen"
            :rounds_prop="rounds"
            :active_prop.sync="activeSidebar"
          ></round-filter>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import DataTable from '@/components/general/datatable/DataTable2.vue'
import RoundFilter from '@/components/general/RoundFilter.vue'
import StatusTabs from './StatusTabs.vue'

export default {
  name: 'DivisionManager',
  components: { DataTable, RoundFilter, StatusTabs },
  comments: [DataTable],

  data () {
    return {
      downloadLink: '',
      headers: [
        {
          minWidth: 130,
          headerName: this.$t('RoundManager.S'),
          field: 'id',
          checkboxSelection: true,
          width: 200,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true
        },
        // { headerName: this.$t('RoundManager.Round'), field: 'round', width:200},
        {
          minWidth: 160,
          headerName: this.$t('division.Name'),
          // field: 'long_name',
          valueGetter:
            'data.long_name === "AMERICAN" ? "AMERICAN" :  data.long_name'
        },
        ///{ headerName: this.$t('RoundManager.From'), field: 'from', width:290 },

        {
          minWidth: 120,
          headerName: this.$t('division.Key'),
          field: 'key',
          width: 240
          //  cellRendererFramework: 'CellRendererStatus',
          // cellRendererParams : {
          //   status_prop:this.status
          // }
        },
        {
          minWidth: 130,
          headerName: this.$t('division.schoolBranch'),
          field: 'schoolName',
          width: 490
        },
        {
          minWidth: 160,
          headerName: this.$t('division.Principal'),
          field: 'principaldata',
          width: 290
        },
        {
          minWidth: 160,
          headerName: this.$t('division.email'),
          field: 'email',
          width: 290
        },
        {
          minWidth: 130,
          headerName: this.$t('division.status'),
          field: 'status',
          width: 290,
          valueGetter: 'data.status === "activate" ? "Active" : "Deactive"',
          cellRendererFramework: 'CellRendererStatus'
        },
        {
          minWidth: 160,
          filter: false,
          headerName: this.$t('basic.actions'),
          field: 'status',
          width: 240,
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            actions_prop: [
              {
                title: this.$t('main.Edit'),
                route_name: 'DivisionEdit',
                parameter_name: 'id',
                icon: 'Edit3Icon',
                navigate_action: true
              },
              {
                title: this.$t('main.addSection'),
                route_name: 'SectionCreate',
                parameter_name: 'id',
                icon: 'BoxIcon',
                navigate_action: true
              },

              {
                title: this.$t('status.activate'),
                action: 'activate',
                resource: data => `division/activate/${data.id}`,
                parameter_name: 'id',
                icon: 'CheckSquareIcon',
                navigate_action: false,
                showIf: data => data.status !== 'activate'
              },
              {
                title: this.$t('status.deactivate'),
                action: 'deactivate',
                resource: data => `division/deactivate/${data.id}`,
                parameter_name: 'id',
                icon: 'SlashIcon',
                navigate_action: false,
                showIf: data => data.status !== 'deactivate'
              }
            ],
            list_prop: 'list'
          }
        }
      ],
      filter_choosen: [],
      activePrompt: false,
      selectedUsers: [],
      export_file: false,
      activeSidebar: false
    }
  },
  methods: {}
}
</script>

<style scoped lang="scss">
#round_buttons {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  min-width: 155px;
  height: fit-content;
  z-index: 999;
  transform: rotate(90deg) translateY(-140px);
  button {
    display: inline-block;
    margin-left: 6px;
  }
}
</style>
