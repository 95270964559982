<template>
  <ListPageWithCreateButton :showCreateBtn="false">
    <template slot="listDataContent">
      <ParentsTable
        :general_search_prop="searchContent"
        :reset_paswwords="'parents/reset-password'"
        :export_csv_method="'post'"
        :export_pdf_method="'post'"
        :csv_url="'/parent/export'"
        :pdf_url="'/parent/pdf'"
        :headers_prop="headers"
        :list_url_prop="`/parent`"
        :side_filters="sideFilters"
        :list_items="searchResult" />
    </template>
    <template slot="rightSide">
      <ParentsAFilter
        :filters_prop="custom_filter"
        @refilter="setFilters"
        @resetAll="restSideFilters"
        :loading="loadingFilter">
        <template slot="extra_content">
          <div class="w-full mb-10">
            <vs-input
              :label-placeholder="$t('form.search_by_name_email_or_ID')"
              v-model="searchContent"
              @change="setFilters"
              v-validate="{email: searchContent.includes('@')}"
              class="w-full"
              name="email"
              icon="icon icon-search"
              icon-pack="feather"/>
            <span class="text-sm text-danger" v-if="errors.first('email')">{{
              errors.first('email') | text_formatter
            }}</span>
          </div>
        </template>
      </ParentsAFilter>
    </template>
  </ListPageWithCreateButton>
</template>
<script>
import ParentsAFilter from '@/components/general/RoundFilter'
import ParentsTable from '@/components/general/datatable/DataTable2.vue'
import {commonDataMixin, authUserMixin} from '@/mixins'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const allParenrReop = RepositoryFactory.get(
  'studentAffairsRepository',
  'allParents'
)

export default {
  mixins: [commonDataMixin, authUserMixin],

  components: {
    ParentsTable,
    ParentsAFilter
  },
  data() {
    return {
      searchContent: '',
      searchResult: null,
      custom_filter: [
        {
          id: 1,
          name: this.$t('basic.academic_year'),
          payload_name: 'academic_year_id',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 8,
          name: this.$t('basic.schools'),
          payload_name: 'school',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 2,
          name: this.$t('basic.divisions'),
          payload_name: 'division_id',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 3,
          name: this.$t('basic.sections'),
          payload_name: 'section_id',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 4,
          name: this.$t('basic.status'),
          payload_name: 'status',
          by: [
            {
              id: 'active',
              name: this.$t('active'),
              checked: false
            },
            {
              id: 'suspended',
              name: this.$t('suspend'),
              checked: false
            },
            {
              id: 'closed',
              name: this.$t('closed'),
              checked: false
            },
            {
              id: 'leaver',
              name: this.$t('leaver'),
              checked: false
            }
          ]
        },

        {
          id: 5,
          name: this.$t('basic.type'),
          payload_name: 'type',
          by: [
            {
              id: 'father',
              name: this.$t('father'),
              checked: false
            },
            {
              id: 'mother',
              name: this.$t('mother'),
              checked: false
            },
            {
              id: 'other',
              name: this.$t('Custody'),
              checked: false
            }
          ]
        }
      ],

      headers: [
        {
          minWidth: 95,
          headerName: this.$t('basic.ID'),
          field: 'user_id'
        },
        {
          minWidth: 250,
          headerName: this.$t('StudentAffairs.fullName'),
          field: 'full_name'
        },
        {
          minWidth: 160,
          headerName: this.$t('basic.mobile'),
          valueGetter: '`${data.mobile.code}${data.mobile.number} `'
        },
        {
          minWidth: 250,
          headerName: this.$t('basic.email'),
          valueGetter: '`${data.official_email} `'
        },
        {
          minWidth: 350,
          headerName: this.$t('StudentAffairs.student_name'),
          valueGetter(data) {
            return data.data.parant_students
              .map((item) => item.name)
              .join(' , ')
          }
        },
        {
          minWidth: 130,
          headerName: this.$t('StudentAffairs.student_id'),
          valueGetter(data) {
            return data.data.parant_students.map((item) => item.id).join(' , ')
          }
        },
        {
          minWidth: 120,
          headerName: this.$t('basic.status'),
          field: 'status',
          cellRendererFramework: 'CellRendererStatus'
        },
        {
          minWidth: 140,
          headerName: this.$t('AcademicYears.Actions'),
          field: 'transactions',
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            actions_prop: [
              {
                title: this.$t('main.Edit'),
                route_name: 'edit-parents',
                getParams: (data) => ({id: data.user_id}),
                icon: 'Edit3Icon',
                navigate_action: true
              },
              {
                dropDown: true,
                dropDown_items: [
                  {
                    title: this.$t('teachersGateway.copy_email'),
                    icon: 'CopyIcon',
                    color: 'danger',
                    actionEmitter: 'copyEmail',
                    actionEmitterParams: [
                      'official_email',
                      'full_name',
                      'school_name',
                      'reset_password'
                    ]
                  },
                  {
                    title: this.$t('basic.chat'),
                    icon: 'MessageSquareIcon',
                    color: 'danger',
                    actionEmitter: 'goToChat',
                    actionEmitterParams: ['user_id']
                  },
                  {
                    title: this.$t('basic.suspend'),
                    parameter_name: 'user_id',
                    icon: 'ClipboardIcon',
                    action: 'suspended',
                    resource: '/user/:id/role/status',
                    color: 'danger',
                    showIf: (data) => data.status === 'active'
                  },
                  {
                    title: this.$t('basic.active'),
                    parameter_name: 'user_id',
                    icon: 'CheckIcon',
                    action: 'active',
                    resource: '/user/:id/role/status',
                    color: 'danger',
                    showIf: (data) => data.status !== 'active'
                  },
                  {
                    title: this.$t('basic.act_as'),
                    icon: 'VideoIcon',
                    actionEmitter: 'actAs',
                    actionEmitterParams: ['user_id', 'full_name'],
                    showIf: () =>
                      this.checkUserRole('SND') ||
                      this.checkUserRole('SchoolPrincipal')
                  }
                ]
              }
            ]
          }
        }
      ],
      sideFilters: {
        school: [],
        academic_year_id: '',
        general_search: '',
        division_id: [],
        section_id: [],
        status: []
      },
      loadingFilter: false
    }
  },
  methods: {
    setFilters(val) {
      // to enable watcher when typing on the search input
      this.sideFilters["general_search"] = this.searchContent;

      if (Array.isArray(val)) {
        val.map((item) => {
          this.sideFilters[item.name] = item.checked
        });
      }
    },
    getFiltersData() {

      this.getFilterData().then((res) => {
        res.schools.map((el) => {
          this.custom_filter[1]['by'].push({
            id: el.id,
            name: el.branch_name,
            checked: false
          })
        })
      })
      this.getAllAcademic().then((res) => {
        res.map((el) => {
          this.custom_filter[0]['by'].push({
            id: el._id,
            name: el.name,
            checked: false
          })
        })
      })
    },
    getFilteredDivisions(chosen) {
      this.loadingFilter = true
      this.custom_filter[2]['by'] = []
      this.custom_filter[3]['by'] = []
      this.custom_filter[5]['by'] = []

      this.getFilterData({schools: chosen.school})
        .then((res) => {
          res.divisions.map((el) => {
            this.custom_filter[2]['by'].push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
        })
        .finally(() => {
          this.loadingFilter = false
        })
    },
    getFilteredSectionsAndSubjects(chosen) {
      this.loadingFilter = true

      this.custom_filter[3]['by'] = []
      this.custom_filter[5]['by'] = []
      const data = {
        divisions: chosen.division_id,
        schools: chosen.school
      }
      this.getFilterData(data)
        .then((res) => {
          res.sections.map((el) => {
            this.custom_filter[3]['by'].push({
              id: el.id,
              name: el.name,
              checked: false
            })
          })
        })
        .finally(() => {
          this.loadingFilter = false
        })
    },
    copyEmail(data) {
      const text = `${data.official_email}`
      navigator.clipboard.writeText(text)
      this.successMsg('Copied sccuess')
    },
    goToChat(data) {
      this.$router.push({
        name: 'chat',
        params: {
          userID: data.user_id
        }
      })
    }
  },
  created() {
    this.$root.$on('copyEmail', (data) => this.copyEmail(data))
    this.$root.$on('goToChat', (data) => this.goToChat(data))
    this.$root.$on('onChange:school', this.getFilteredDivisions)
    this.$root.$on('onChange:division_id', this.getFilteredSectionsAndSubjects)
    this.$root.$on('actAs', (data) => {
      data.id = data.user_id
      data.name = `${this.$t('main.parent')} - ${data.full_name}`
      this.startActAs(data)
    })
    this.getFiltersData()
  },
  watch: {
    searchContent(v) {
      if (!v) this.searchResult = null
    }
  },
  beforeDestroy() {
    this.$root.$off('onChange:school')
    this.$root.$off('onChange:division_id')
    this.$root.$off('copyEmail')
    this.$root.$off('goToChat')
    this.$root.$off('actAs')
  }
}
</script>
