export default {
  myDay: 'My Day',
  myday_for_parent_for_web: 'Enable My Day For Parent On Web',
  myday_for_parent_for_mobile: 'Enable My Day For Parent On Mobile',
  myday_for_student_for_web: 'Enable My Day For Student On Web',
  myday_for_student_for_mobile: 'Enable My Day For Student On Mobile.',
  teacher_add_note_for_web_and_mobile: 'Enable The Feature ‘Add Note’ For The Teacher Console On Web And Mobile',
  section_coordinator_add_note_for_web_and_mobile: 'Enable The Feature ‘Add Note’ For The Section Coordinator On Web' +
    ' And Mobile',
  teacher_section_coordinator_add_note_for_web_and_mobile: 'Enable The Feature ‘Add Note’ For The Teacher Section' +
    ' Coordinator On Web And Mobile',
  grade_coordinator_add_note_for_web_and_mobile: 'Enable The Feature ‘Add Note’ For The Grades Coordinator On Web' +
    ' And Mobile',
  teacher_grade_coordinator_add_note_for_web_and_mobile: 'Enable The Feature ‘Add Note’ For The Teacher Grades' +
    ' Coordinator On Web And Mobile',
  release_time_myday: 'Time To Release My Day',
  last_previous_days: 'Last Previous Days',
  attendance_summery : 'Attendance Summery',
  todo_list: 'TODO List',
  latest_assessments: 'Latest Assessments',
  in_scope_of: 'In Scope Of',
  previous_academic_days: 'Previous Academic Days'

}
