<template>
  <vx-card class="academic-year-form p-3 pt-6 pb-6 md:p-6">
    <div class="mt-5">
      <form-wizard ref="academic-year-wizard" color="rgba(var(--vs-primary), 1)" :title="null" :subtitle="null"
        :finishButtonText="onlyShow ? $t('basic.done') : $t('form.submit')" @on-complete="formSubmitted">
        <!-- tab 1 content This is Tab For Sub Academic Years Only -->
        <tab-content v-if="subAdimicYearsFrom" :title="`${$t('AcademicYears.divisionsAndClass')}`" class="mb-5"
          :before-change="validationStep1">
          <form @submit.prevent="false" @keyup.enter="validationStep1" data-vv-scope="step-1">
            <!-- Start Division -->
            <div class="vx-row mt-5 items-center">
              <div class="vx-col lg:w-1/5 w-full">
                <label>
                  {{ $t('AcademicYears.divisions') }}
                  <sup class="text-danger">*</sup>
                </label>
              </div>
              <div class="vx-col lg:w-4/5 w-full mt-3">
                <v-select class="w-full select-large" :clearable="false" appendToBody
                  v-model="academicYearCalc.division_id" v-validate="'required'" name="divisions" label="long_name"
                  :options="divisions" :disabled="onlyShow" @input="
                    getSectionsByDivisionsID(academicYearCalc.division_id)
                    ">
                </v-select>

                <span v-if="hasDivision" class="text-danger text-sm text-center">
                  {{ $t('AcademicYears.you_should_select_in_section') }}
                </span>
                <span class="text-danger text-sm text-center">{{
                  errors.first('step-1.divisions')
                }}</span>
              </div>
            </div>
            <!-- End Division -->

            <!-- Start Section -->
            <div class="vx-row mt-5 items-center">
              <div class="vx-col lg:w-1/5 w-full">
                <label>
                  {{ $t('AcademicYears.section') }}
                </label>
              </div>
              <div class="vx-col lg:w-4/5 w-full mt-3">
                <v-select :disabled="onlyShow || !academicYearCalc.division_id" class="w-full select-large"
                  :clearable="false" appendToBody v-model="academicYearCalc.section_id" label="long_name"
                  :options="sections" @input="getGradBySectionsID(academicYearCalc.section_id)">
                </v-select>
                <span v-if="hasSections" class="text-danger text-sm text-center">
                  {{ $t('AcademicYears.you_should_select_in_grad') }}
                </span>
              </div>
            </div>
            <!-- End Section -->

            <!-- Start Grad -->
            <div class="vx-row mt-5 items-center">
              <div class="vx-col lg:w-1/5 w-full">
                <label>
                  {{ $t('AcademicYears.grad') }}
                </label>
              </div>
              <div class="vx-col lg:w-4/5 w-full mt-3">
                <v-select :disabled="onlyShow || !academicYearCalc.section_id" class="w-full select-large"
                  :clearable="false" appendToBody v-model="academicYearCalc.grade_id" label="name" :options="grads"
                  @input="gradsCheck(academicYearCalc.grade_id)">
                </v-select>
                <span v-if="hasGrad" class="text-danger text-sm text-center">
                  {{ $t('AcademicYears.you_should_change_grad') }}
                </span>
              </div>
            </div>
            <!-- End Grad -->
          </form>
        </tab-content>

        <!-- tab 2 content -->

        <tab-content :title="`${$t('AcademicYears.StartEndDates')}`" class="mb-5" :before-change="validationStep2">
          <form @submit.prevent="false" @keyup.enter="validationStep2" data-vv-scope="step-2">
            <div class="vx-row mt-5 items-center">
              <div class="vx-col lg:w-1/5 w-full">
                <label>
                  {{ $t('AcademicYears.StartDate') }}
                  <sup class="text-danger">*</sup>
                </label>
              </div>
              <div class="vx-col lg:w-4/5 w-full mt-3">
                <flat-pickr :disabled="onlyShow" @on-change="onAcademicStartDateChange" :config="minAcademic"
                  v-model="academicYearCalc.start_date" name="Start Date" v-validate="'required'" class="w-full" />
                <div class="text-danger text-sm">
                  {{ errors.first('step-2.Start Date') }}
                </div>
              </div>
            </div>

            <div class="vx-row mt-5 items-center">
              <div class="vx-col lg:w-1/5 w-full">
                <label>
                  {{ $t('AcademicYears.EndDate') }}
                  <sup class="text-danger">*</sup>
                </label>
              </div>
              <div class="vx-col lg:w-4/5 w-full mt-3">
                <flat-pickr @on-change="onAcademicEndDateChange" :disabled="onlyShow
                    ? true
                    : edit_id
                      ? false
                      : !academicYearCalc.start_date
                  " :config="config.EndAcademicYear" v-model="academicYearCalc.end_date" class="w-full" name="end_date"
                  v-validate="'required'" />
                <span class="text-danger text-sm text-center">{{
                  errors.first('step-2.end_date')
                }}</span>
              </div>
            </div>

            <div class="vx-row mt-5 items-center">
              <div class="vx-col lg:w-1/5 w-full">
                <label>
                  {{ $t('AcademicYears.WeekStartDay') }}
                  <sup class="text-danger">*</sup>
                </label>
              </div>
              <div class="vx-col lg:w-4/5 w-full mt-3">
                <v-select :disabled="onlyShow" class="w-full select-large" :clearable="false" appendToBody
                  v-model="academicYearCalc.week_start_day" v-validate="'required'" name="week_start_day" label="text"
                  :reduce="(option) => option.value" :options="week_days">
                </v-select>
                <span class="text-danger text-sm text-center">{{
                  errors.first('step-2.week_start_day')
                }}</span>
              </div>
            </div>
          </form>
        </tab-content>

        <!-- tab 3 content -->
        <tab-content :title="`${$t('AcademicYears.days_hours')}`" class="mb-5" :before-change="validationStep3">
          <form @submit.prevent="false" @keyup.enter="validationStep3" data-vv-scope="StepDay">
            <div v-for="(item, index) in academicYearCalc.days_hours" :key="index">
              <div class="vx-row mb-6">
                <div class="mb-4 vx-col lg:w-1/5 w-full flex flex-wrap items-center">
                  <vs-switch v-model="item.status" :disabled="onlyShow" />
                  <label class="ml-6">{{ item.day_name }}</label>
                </div>
                <div
                  class="mb-4 vx-col lg:w-2/5 md:w-1/2 lg:justify-evenly justify-between w-full flex flex-wrap items-center">
                  <label> {{ $t('AcademicYears.first_bell') }}</label>
                  <flat-pickr :disabled="onlyShow" :config="config.configTime" type="time" v-model="item.first_bill"
                    :name="item.day_name + ' first bell'" v-validate="'required'" />
                  <div v-if="item.status == true" class="text-danger text-sm w-full text-center mt-1">
                    {{
                      errors.first('StepDay.' + item.day_name + ' first bell')
                    }}
                  </div>
                </div>

                <div
                  class="mb-4 vx-col lg:w-2/5 md:w-1/2 w-full flex lg:justify-evenly justify-between flex-wrap items-center">
                  <label>{{ $t('AcademicYears.last_bell') }}</label>
                  <flat-pickr :disabled="onlyShow" type="time" :config="{
                    ...config.configTime,
                    minDate: addOneHour(item.first_bill)
                  }" v-model="item.last_bill" :name="item.day_name + ' last bell'" v-validate="'required'" />
                  <div v-if="item.status == true" class="text-danger text-sm w-full text-center mt-1">
                    {{
                      errors.first('StepDay.' + item.day_name + ' last bell')
                    }}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </tab-content>

        <!-- tab 4 content -->
        <tab-content :title="`${$t('AcademicYears.TermsVacations')}`" class="mb-5" :before-change="validationStep4">
          <form @submit.prevent="false" @keyup.enter="validationStep4" data-vv-scope="step-4">
            <!-- <div class="vx-row mb-5 mt-5">
<vs-divider position="left">
{{ $t("AcademicYears.SetTerms") }}
<sup class="text-danger">*</sup>
</vs-divider>
</div> -->

            <!---Start Semeter swtich---->
            <!-- <div class="mt-5">
<ul class="centerx vx-row">
<li class="vx-col lg:w-1/5 md:w-1/2 w-full mb-5">
<vs-radio
v-model="academicYearCalc.with_semester"
vs-value="with_semester"
>{{ $t("AcademicYears.With_semester") }}</vs-radio
>
</li>
<li class="vx-col lg:w-1/4 md:w-1/2 w-full mb-5">
<vs-radio
v-model="academicYearCalc.with_semester"
vs-value="without_semester"
>{{ $t("AcademicYears.Without_semester") }}</vs-radio
>
</li>
</ul>
</div> -->
            <!--- End Semeter swtich --->

            <!--- Without semester---->
            <div v-if="academicYearCalc.with_semester === 'without_semester'">
              <div class="vx-row mb-5 mt-5" :key="indexOfTerm" v-for="(term, indexOfTerm) in academicYearCalc.terms">
                <vs-divider position="left">
                  <label>
                    {{ $t('AcademicYears.Term') }} {{ indexOfTerm + 1 }}</label>
                </vs-divider>

                <div class="vx-col lg:w-1/4 w-full my-2">
                  <label>
                    {{ $t('AcademicYears.StartDate') }}
                    <sup class="text-danger">*</sup>
                  </label>
                  <flat-pickr :disabled="onlyShow" :config="{
                    minDate:
                      indexOfTerm === 0
                        ? academicYearCalc.start_date
                        : addOneDay(
                          academicYearCalc.terms[indexOfTerm - 1].end_date
                        ),

                    maxDate: academicYearCalc.end_date
                  }" :placeholder="`${$t('AcademicYears.StartDate')}`" v-model="term.start_date" class="w-full"
                    :name="`Term Start Date ${indexOfTerm + 1}`" v-validate="'required'" />
                  <div class="text-danger text-sm w-full" style="min-height: 20px;">
                    {{
                      errors.first(`step-4.Term Start Date ${indexOfTerm + 1}`)
                    }}
                  </div>
                </div>
                <div class="vx-col lg:w-1/4 w-full my-2">
                  <label>
                    {{ $t('AcademicYears.EndDate') }}
                    <sup class="text-danger">*</sup>
                  </label>
                  <flat-pickr :config="{
                    minDate: term.start_date,
                    maxDate: academicYearCalc.end_date
                  }" :placeholder="`${$t('AcademicYears.EndDate')}`" v-model="term.end_date" :disabled="onlyShow || !term.start_date"
                    :name="`Term End Date ${indexOfTerm + 1}`" v-validate="'required'" class="w-full" />
                  <span class="text-danger text-sm w-full text-center" style="min-height: 20px;">{{
                    errors.first(`step-4.Term End Date ${indexOfTerm + 1}`)
                  }}</span>
                </div>
                <div class="vx-col lg:w-1/4 w-full my-2">
                  <label>
                    {{ $t('AcademicYears.PublicName') }}
                    <sup class="text-danger">*</sup>
                  </label>
                  <vs-input :readonly="onlyShow" type="text" :placeholder="`${$t('AcademicYears.PublicName')}`"
                    :name="`Term Public Name ${indexOfTerm + 1}`" v-validate="'required|alpha_spaces'"
                    v-model="term[current_locale].public_name" class="w-full" />
                  <span class="text-danger text-sm w-full text-center" style="min-height: 20px;">{{
                    errors.first(`step-4.Term Public Name ${indexOfTerm + 1}`)
                  }}</span>
                </div>
                <div class="vx-col lg:w-1/4 w-full my-2 flex" :style="'align-items: center'">
                  <vs-button v-if="!onlyShow &&
                      (indexOfTerm ||
                        (!indexOfTerm && academicYearCalc.terms.length > 1))
                      " color="danger" type="border" class="md:ml-0 mr-0 inline-block" @click="removeTerm(indexOfTerm)" icon-pack="feather"
                    icon="icon-minus"></vs-button>
                  <vs-button v-if="!onlyShow &&
                    indexOfTerm == academicYearCalc.terms.length - 1 && isNewTermButtonEnabled(indexOfTerm)
                    " color="primary" type="border" @click="addTerm(indexOfTerm)" icon-pack="feather" icon="icon-plus"
                    class="ml-5 mr-0 inline-block"></vs-button>
                </div>
              </div>
            </div>
            <!--- /Without semester --->

            <!--- With semester --->
            <div v-if="academicYearCalc.with_semester == 'with_semester'">
              <div v-for="(semester, semesterIndex) in academicYearCalc.semesters" :key="semesterIndex">
                <div class="vx-row mb-1 mt-6">
                  <div class="vx-col lg:w-1/5 w-full mb-4">
                    <h6>
                      {{ $t('AcademicYears.Semester') }} {{ semesterIndex + 1 }}
                    </h6>
                  </div>

                  <!-- Start Semester Start Date-->
                  <div class="vx-col md:w-1/4 lg:w-1/3 mb-4 w-full">
                    <flat-pickr :disabled="onlyShow" :config="{
                      minDate:
                        semesterIndex === 0
                          ? academicYearCalc.start_date
                          : addOneDay(
                            academicYearCalc.semesters[semesterIndex - 1]
                              .end_date
                          ),

                      maxDate: academicYearCalc.end_date
                    }" :placeholder="`${$t('AcademicYears.StartDate')}`" :name="`Semester Start Date ${semesterIndex + 1}`" class="w-full"
                      v-validate="'required'" v-model="semester.start_date" />
                    <span class="text-danger text-sm w-full text-center">{{
                      errors.first(
                        `step-4.Semester Start Date ${semesterIndex + 1}`
                      )
                    }}</span>
                  </div>
                  <!-- End Semester Start Date-->

                  <!-- Start Semester End Date-->
                  <div class="vx-col md:w-1/4 lg:w-1/3 mb-4 w-full">
                    <flat-pickr :config="{
                          minDate: semester.start_date,
                          maxDate: academicYearCalc.end_date
                        }" :disabled="onlyShow || !semester.start_date" :placeholder="`${$t('AcademicYears.EndDate')}`"
                      :name="`Semester End Date ${semesterIndex + 1}`" class="w-full" v-validate="'required'"
                      v-model="semester.end_date" />
                    <span class="text-danger text-sm w-full text-center">{{
                      errors.first(
                        `step-4.Semester End Date ${semesterIndex + 1}`
                      )
                    }}</span>
                  </div>

                  <!-- End Semester End Date-->
                </div>

                <!-- Start Add semester -->
                <div :key="termIndex" v-for="(term, termIndex) in semester.terms">
                  <div class="pl-10">
                    <p>{{ $t('AcademicYears.Term') }} {{ termIndex + 1 }}</p>
                  </div>
                  <div class="vx-row mb-5 mt-5 pl-10">
                    <div class="vx-col md:w-1/4 lg:w-1/4 w-full mb-4">
                      <label>
                        {{ $t('AcademicYears.StartDate') }}
                        <sup class="text-danger">*</sup>
                      </label>
                      <flat-pickr :config="{
                        minDate:
                          termIndex === 0
                            ? semester.start_date
                            : addOneDay(
                              semester.terms[termIndex - 1].end_date
                            ),

                        maxDate: semester.end_date
                      }" :disabled="onlyShow || !semester.start_date || !semester.end_date
  " :placeholder="`${$t('AcademicYears.StartDate')}`" v-model="term.start_date" class="w-full"
                        :name="`Terms Start Date ${termIndex + 1}`" v-validate="'required'" />
                      <span class="text-danger text-sm w-full text-center">{{
                        errors.first(`step-4.Terms Start Date ${termIndex + 1}`)
                      }}</span>
                    </div>

                    <div class="vx-col md:w-1/4 lg:w-1/4 w-full mb-4">
                      <label>
                        {{ $t('AcademicYears.EndDate') }}
                        <sup class="text-danger">*</sup>
                      </label>
                      <flat-pickr :config="{
                        minDate: term.start_date,
                        maxDate: semester.end_date
                      }" :disabled="onlyShow ||
  !semester.start_date ||
  !term.start_date ||
  !semester.end_date
  " :placeholder="`${$t('AcademicYears.EndDate')}`" v-model="term.end_date" :name="`Term End Date ${termIndex + 1}`"
                        v-validate="'required'" class="w-full" />
                      <span class="text-danger text-sm w-full text-center">{{
                        errors.first(`step-4.Term End Date ${termIndex + 1}`)
                      }}</span>
                    </div>

                    <div class="vx-col md:w-1/3 lg:w-1/4 w-full mb-4">
                      <label>
                        {{ $t('AcademicYears.PublicName') }}
                        <sup class="text-danger">*</sup>
                      </label>
                      <vs-input :readonly="onlyShow" type="text" :placeholder="`${$t('AcademicYears.PublicName')}`"
                        :name="`Term Public Name ${termIndex + 1}`" v-validate="'required|alpha_spaces'"
                        v-model="term[current_locale].public_name" class="w-full" />
                      <span class="text-danger text-sm w-full text-center">{{
                        errors.first(`step-4.Term Public Name ${termIndex + 1}`)
                      }}</span>
                    </div>

                    <div class="vx-col md:w-1/6 lg:w-1/4 w-full mb-4 flex">
                      <vs-button v-if="!onlyShow &&
                          (termIndex ||
                            (!termIndex && semester.terms.length > 1))
                          " color="danger" type="border" class="md:ml-0 ml-auto mr-0 inline-block"
                        @click="removeTermInSemester(semester, termIndex)" icon-pack="feather"
                        icon="icon-minus"></vs-button>

                      <vs-button v-if="!onlyShow && termIndex == semester.terms.length - 1
                        " color="primary" type="border" @click="addTermInSemester(semester, termIndex)" icon-pack="feather" icon="icon-plus"
                        class="ml-5 mr-0 inline-block"></vs-button>
                    </div>
                  </div>
                </div>
                <!-- End Add semester -->

                <div class="text-right">
                  <div class="my-3 inline-block" v-if="semesterIndex ||
                    (!semesterIndex && academicYearCalc.semesters.length > 1)
                    ">
                    <vs-button @click="removeSemester(semesterIndex)" color="danger" type="line">
                      <span>{{ $t('AcademicYears.remove_semester') }} </span>
                    </vs-button>
                  </div>
                  <div class="inline-block" v-if="semesterIndex == academicYearCalc.semesters.length - 1
                    ">
                    <vs-button @click="addSemester(semesterIndex)" color="primary" type="line">
                      <span>{{ $t('AcademicYears.add_semesters') }} </span>
                    </vs-button>
                  </div>
                </div>
              </div>
            </div>
            <!----- /With semester ----->

            <!--------------- Set Vacations --------------->
            <div class="vx-row mb-5 mt-5">
              <vs-divider position="left">
                {{ $t('AcademicYears.SetVacations') }}
              </vs-divider>
            </div>

            <div class="vx-row mb-5 mt-5" :key="indexOfvacations" v-for="(vacation, indexOfvacations) in academicYearCalc.vacations">
              <div class="vx-col lg:w-1/4 w-full mb-4">
                <flat-pickr :disabled="onlyShow" :config="{
                  minDate:
                    indexOfvacations === 0
                      ? academicYearCalc.start_date
                      : addOneDay(
                        academicYearCalc.vacations[indexOfvacations - 1]
                          .end_date
                      ),

                  maxDate: academicYearCalc.end_date
                }" :placeholder="`${$t('AcademicYears.StartDate')}`" v-model="vacation.start_date" class="w-full" />
              </div>

              <div class="vx-col lg:w-1/4 w-full mb-4">
                <flat-pickr :config="{
                  minDate: vacation.start_date,
                  maxDate: academicYearCalc.end_date
                }" :disabled="onlyShow || !vacation.start_date" :placeholder="`${$t('AcademicYears.EndDate')}`"
                  v-model="vacation.end_date" class="w-full" />
              </div>

              <div class="vx-col lg:w-1/4 w-full mb-4">
                <vs-input :readonly="onlyShow" type="text" :placeholder="`${$t('AcademicYears.PublicName')}`"
                  v-model="vacation[current_locale].public_name" class="w-full" />
              </div>

              <div class="vx-col lg:w-1/4 w-full mb-4 flex">
                <vs-button v-if="!onlyShow &&
                  (indexOfvacations ||
                    (!indexOfvacations &&
                      academicYearCalc.vacations.length > 1))
                  " color="danger" type="border" class="md:ml-0 ml-auto mr-0 inline-block" @click="removeVacation(indexOfvacations)"
                  icon-pack="feather" icon="icon-minus"></vs-button>

                <vs-button v-if="!onlyShow &&
                  indexOfvacations == academicYearCalc.vacations.length - 1 && isNewVacationButtonEnabled(indexOfvacations)
                  " color="primary" type="border" class="ml-5 mr-0 inline-block" @click="addVacation(indexOfvacations)"
                  icon-pack="feather" icon="icon-plus"></vs-button>
              </div>
            </div>

            <!--------------- /Set Vacations --------------->

            <!--------------- Set Special Holiday --------------->
            <div class="vx-row mb-5 mt-5">
              <vs-divider position="left">
                {{ $t('AcademicYears.SetSpecialHoliday') }}
              </vs-divider>
            </div>

            <div class="vx-row mb-5 mt-5" :key="'e' + indexOfHoliday"
              v-for="(holiday, indexOfHoliday) in academicYearCalc.holidays">
              <div class="vx-col lg:w-1/4 w-full mb-4">
                <flat-pickr :disabled="onlyShow" :config="{
                  minDate:
                    indexOfHoliday === 0
                      ? academicYearCalc.start_date
                      : addOneDay(
                        academicYearCalc.holidays[indexOfHoliday - 1]
                          .end_date
                      ),

                  maxDate: academicYearCalc.end_date,

                  disable: disabledVacationsDays,
                  dateFormat: 'Y-m-d'
                }" :placeholder="`${$t('AcademicYears.ChooseDate')}`" v-model="holiday.start_date" class="w-full" />
              </div>

              <!-- <div class="vx-col lg:w-1/4 w-full mb-4">-->
              <!-- <flat-pickr
:disabled="onlyShow"-->
              <!-- :config="{-->
              <!-- minDate: holiday.start_date,-->
              <!-- maxDate: academicYearCalc.end_date,-->
              <!-- disable: disabledVacationsDays,-->
              <!-- dateFormat: 'Y-m-d'-->
              <!-- }"-->
              <!-- :disabled="!holiday.start_date"-->
              <!-- :placeholder="`${$t('AcademicYears.ChooseDate')}`"-->
              <!-- v-model="holiday.end_date"-->
              <!-- class="w-full" />-->
              <!-- </div>-->

              <div class="vx-col lg:w-1/4 w-full mb-4">
                <vs-input :readonly="onlyShow" class="w-full" type="text"
                  :placeholder="`${$t('AcademicYears.PublicName')}`" v-model="holiday[current_locale].public_name" />
              </div>

              <div class="vx-col lg:w-1/4 w-full mb-4 flex">
                <vs-button v-if="!onlyShow &&
                  (indexOfHoliday ||
                    (!indexOfHoliday && academicYearCalc.holidays.length > 1))
                  " color="danger" type="border" class="md:ml-0 ml-auto mr-0 inline-block" @click="removeHoliday(indexOfHoliday)"
                  icon-pack="feather" icon="icon-minus"></vs-button>

                <vs-button v-if="!onlyShow &&
                  indexOfHoliday == academicYearCalc.holidays.length - 1
                  " color="primary" type="border" @click="addHoliday(indexOfHoliday)" icon-pack="feather" icon="icon-plus"
                  class="ml-5 mr-0 inline-block"></vs-button>
              </div>
            </div>

            <!--------------- /Set Special Holiday --------------->
          </form>
        </tab-content>

        <!-- tab 5 content -->
        <tab-content :title="`${$t('AcademicYears.overview')}`" class="mb-5">
          <div class="vx-row my-6">
            <div class="vx-col lg:w-1/3 md:w-1/3 sm:w-1/2 w-full mb-8">
              <h5>{{ AcademicDays }} {{ $t('AcademicYears.totalDays') }}</h5>
            </div>
            <div class="vx-col lg:w-1/3 md:w-1/3 sm:w-1/2 w-full mb-8">
              <h5>
                {{ totalAcademicWeeks }} {{ $t('AcademicYears.academicweeks') }}
              </h5>
            </div>

            <div class="vx-col lg:w-1/3 md:w-1/3 sm:w-1/2 w-full mb-8">
              <h5>
                {{ specialVacations }}
                {{ $t('AcademicYears.Days_Special_Vacations') }}
              </h5>
            </div>

            <div class="vx-col lg:w-1/3 md:w-1/3 sm:w-1/2 w-full mb-8">
              <h5>
                {{ specialHolidays }} {{ $t('AcademicYears.specialHolidays') }}
              </h5>
            </div>

            <div class="vx-col lg:w-1/3 md:w-1/3 sm:w-1/2 w-full mb-8">
              <h5>
                {{ totalWeekends }} {{ $t('AcademicYears.totalWeekends') }}
              </h5>
            </div>

            <div class="vx-col lg:w-1/3 md:w-1/3 sm:w-1/2 w-full mb-8">
              <h5>{{ studyingDays }} {{ $t('AcademicYears.academicDays') }}</h5>
            </div>
          </div>
          <!-- <vs-table :data="culculateForsingleTerm">
<template slot="thead">
<vs-th> Terms & semesters </vs-th>
<vs-th> Start/End </vs-th>
<vs-th> Academic days </vs-th>
<vs-th> Academic weeks </vs-th>
<vs-th> Working days </vs-th>
<vs-th> Academic hours </vs-th>
</template>

<template slot-scope="{ data }">
<vs-tr :key="indextr" v-for="(tr, indextr) in data">
<vs-td>
{{ data[indextr].name }}
</vs-td>
<vs-td>
{{ data[indextr].start_end }}
</vs-td>
<vs-td>
{{ data[indextr].AcademicDays }}
</vs-td>
<vs-td> {{ data[indextr].AcademicWeeks }} </vs-td>

<vs-td> </vs-td>
<vs-td> </vs-td>
</vs-tr>
</template>
</vs-table> -->
          <!-- <vs-table :data="toloalOfTermsHolidays" class="pt-10">
<template slot="thead">
<vs-th> Vacations & holidays </vs-th>
<vs-th>Start/End </vs-th>
<vs-th> Duration </vs-th>
</template>

<template slot-scope="{ data }">
<vs-tr :key="indextr" v-for="(tr, indextr) in data">
<vs-td>
{{ data[indextr].name }}
</vs-td>

<vs-td>
{{ data[indextr].start_end }}
</vs-td>
<vs-td>
{{ data[indextr].Academic_Vacations }}
</vs-td>
</vs-tr>
</template>
</vs-table> -->
        </tab-content>
      </form-wizard>
    </div>
  </vx-card>
</template>

<script>
/*
academicYearCalc = academic_year_withRole in edit
academicYearCalc = academic_year in create
*/

import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
import moment from 'moment'
import DataTable from '@/components/general/datatable/DataTable.vue'
import vSelect from 'vue-select'

const AcademicYearRepository = RepositoryFactory.get(
  'AcademicModule',
  'academicYears'
)

export default {
  name: 'AcadmicYearForm',
  components: {
    FormWizard,
    TabContent,
    flatPickr,
    DataTable,
    'v-select': vSelect
  },
  data() {
    return {
      saveAgain: true,
      academic_year_withRole: {},
      academic_year_withRole_sub: {},

      academic_year: {
        division_id: '',
        section_id: '',
        grade_id: '',
        start_date: '',
        end_date: '',
        with_semester: 'without_semester',
        week_start_day: '',
        days_hours: [
          {
            day_name: 'monday',
            status: true,
            first_bill: '09:00',
            last_bill: '16:00'
          },
          {
            day_name: 'tuesday',
            status: true,
            first_bill: '09:00',
            last_bill: '16:00'
          },
          {
            day_name: 'wednesday',
            status: true,
            first_bill: '09:00',
            last_bill: '16:00'
          },
          {
            day_name: 'thursday',
            status: true,
            first_bill: '09:00',
            last_bill: '16:00'
          },
          {
            day_name: 'friday',
            status: false,
            first_bill: '09:00',
            last_bill: '16:00'
          },
          {
            day_name: 'saturday',
            status: false,
            first_bill: '09:00',
            last_bill: '16:00'
          },
          {
            day_name: 'sunday',
            status: true,
            first_bill: '09:00',
            last_bill: '16:00'
          }
        ],

        terms: [
          {
            start_date: '',
            end_date: ''
          }
        ],
        semesters: [
          {
            start_date: '',
            end_date: '',
            terms: [{ start_date: '', end_date: '' }]
          }
        ],

        vacations: [
          {
            start_date: '',
            end_date: ''
          }
        ],
        holidays: [
          {
            start_date: '',
            end_date: ''
          }
        ]
      },
      // ---------- Start dateTimePickerConfig -------
      config: {
        StartAcademicYear: {
          minDate: null
        },
        EndAcademicYear: {
          minDate: null, //MinDate Over Wirted In Method
          maxDate: null
        },
        configTime: {
          enableTime: true,
          enableSeconds: false,
          noCalendar: true
        }
      },
      // ---------- End dateTimePickerConfig -------
      week_days: [],

      //----- sub ----
      divisions: [],
      sections: [],
      grads: [],
      hasDivision: '',
      hasSections: '',
      hasGrad: '',

      StartEndAcademic: '',
      EndEndAcademic: ''
    }
  },
  created() {
    this.VSLoading()

    this.getDivisions()

    if (this.edit_id || this.id_Create_sub || this.edit_Subid_forsub) {
      this.loadData()
    }
    if (this.$route.params.idSub) {
      this.loadSubData()
    }

    if (!this.edit_id && !this.$route.params.idSub) {
      {
        this.$set(this.academicYearCalc.terms[0], `${this.current_locale}`, {
          public_name: ''
        })
        this.$set(
          this.academicYearCalc.semesters[0]['terms'][0],
          `${this.current_locale}`,
          { public_name: '' }
        )
        this.$set(this.academicYearCalc.holidays[0], `${this.current_locale}`, {
          public_name: ''
        })
        this.$set(
          this.academicYearCalc.vacations[0],
          `${this.current_locale}`,
          {
            public_name: ''
          }
        )
      }
    }
    this.stopVSLoading()
    this.week_days = this.$t('main.week_days')
  },

  methods: {
    addOneHour(hour) {
      return moment(hour, 'HH:mm a').add(1, 'hours').format('HH:mm a')
    },
    addOneDay(day) {
      return moment(day).add(1, 'days').format('YYYY-MM-DD')
    },

    getSectionsByDivisionsID(obj) {
      return new Promise((resove, reject) => {
        this.VSLoading()
        this.hasDivision = obj.has_customize_academic

        this.academicYearCalc.section_id = ''
        this.hasSections = false
        this.academicYearCalc.grade_id = ''
        this.hasGrad = false

        AcademicYearRepository.getSectionByDivision(
          this.$route.params.id,
          obj.id
        ).then((res) => {
          this.sections = res.data
          resove(res.data)
          this.stopVSLoading()
        })
      })
    },
    getGradBySectionsID(obj) {
      this.VSLoading()
      return new Promise((resolve, reject) => {
        this.hasDivision = false
        this.academicYearCalc.grade_id = ''
        this.hasGrad = false

        this.hasSections = obj.has_customize_academic
        AcademicYearRepository.getGrads(
          this.$route.params.id,
          this.academicYearCalc.division_id.id,
          obj.id
        ).then((res) => {
          this.grads = res.data
          this.stopVSLoading()
          resolve(res.data)
        })
      })
    },
    gradsCheck(obj) {
      this.hasGrad = obj.has_customize_academic
      this.hasSections = false
    },
    //getDivions in sub
    getDivisions() {
      return new Promise((resolve, reject) => {
        AcademicYearRepository.getDivisions(this.$route.params.id).then(
          (res) => {
            this.divisions = res.data
            resolve(res.data)
          }
        )
      })
    },
    //Total Of Academics Day of one Term
    AcademicDaysforSingleTerm(start_date, end_date) {
      let AcademicDays = 0
      if (start_date !== '' && end_date !== '') {
        const start = moment(start_date)
        const end = moment(end_date)
        AcademicDays = end.diff(start, 'days') + 1
      }
      return AcademicDays
    },

    //CalcuLate Vacation For One Term
    calculatSpecialVacations(start_date_vacation, end_date_vacation) {
      let vacations = 0
      this.academicYearCalc.vacations.forEach((vacation) => {
        if (vacation.end_date !== '' && vacation.start_date !== '')
          vacations +=
            moment(end_date_vacation).diff(
              moment(start_date_vacation),
              'days'
            ) + 1
      })
      return vacations
    },

    loadData() {
      AcademicYearRepository.get(this.$route.params.id).then((res) => {
        this.academic_year_withRole = res.data[this.authUser.userRole]

        this.StartEndAcademic = res.data.SMD.start_date //forSub
        this.EndEndAcademic = res.data.SMD.end_date //forSub
      })
    },
    loadSubData() {
      AcademicYearRepository.getSingleSub(
        this.edit_id_masterforsub,
        this.edit_Subid_forsub
      ).then((res) => {
        const division_id = res.data.division_id
        const section_id = res.data.section_id
        const grade_id = res.data.grade_id

        this.academic_year_withRole_sub = {
          ...res.data['SND'],
          division_id,
          section_id,
          grade_id
        }
        this.getDivisions()
          .then((res) => {
            this.academic_year_withRole_sub.division_id = res.find((item) => {
              return item.id === division_id
            })
          })
          .then(() => {
            this.getSectionsByDivisionsID({ id: division_id }).then((res) => {
              this.academic_year_withRole_sub.section_id = res.find((item) => {
                return item.id === section_id
              })
            })
          })
          .then(() => {
            if (section_id) {
              this.getGradBySectionsID({ id: section_id }).then((res) => {
                this.academic_year_withRole_sub.grade_id = res.find((item) => {
                  return item.id === grade_id
                })
              })
            }
          })
      })
    },

    // STEP 1
    validationStep1() {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll('step-1').then((result) => {
          if (
            result &&
            !this.hasDivision &&
            !this.hasSections &&
            !this.hasGrad
          ) {
            window.scrollTo(0, 0)
            resolve(true)
          } else {
            reject('error validation')
            this.errMsg()
          }
        })
      })
    },

    // STEP2
    validationStep2() {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll('step-2').then((result) => {
          if (result) {
            resolve(true)
          } else {
            reject('error validation')
            this.errMsg()
          }
        })
      })
    },

    // STEP3
    validationStep3() {
      return new Promise((resolve, reject) => {

        this.$validator.validateAll('StepDay').then((result) => {

          let selectAtLeastOneDay = this.academic_year.days_hours.some(obj => obj.status);
          if(!selectAtLeastOneDay){
            reject('You must select at least one day!')
            this.errMsg('You must select at least one day!')
          }else{
            if (result) {
              resolve(true)
            } else {
              reject('error validation')
              this.errMsg()
            }
          }

        })
      })
    },

    // STEP4
    validationStep4(displayToastError = true) {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll('step-4').then((result) => {
          if (result) {
            resolve(true)
          } else {
            reject('error validation');
            if(displayToastError)
              this.errMsg()
          }
        })
      })
    },

    resteValues() {
      this.academicYearCalc.terms.forEach((item) => {
        item.start_date = ''
        item.end_date = ''
        item[this.current_locale].public_name = ''
      })
      this.academicYearCalc.vacations.forEach((item) => {
        item.start_date = ''
        item.end_date = ''
        item[this.current_locale].public_name = ''
      })
      this.academicYearCalc.holidays.forEach((item) => {
        item.start_date = ''
        item.end_date = ''
        item[this.current_locale].public_name = ''
      })

      this.academicYearCalc.semesters.forEach((itemSemster) => {
        itemSemster.start_date = ''
        itemSemster.end_date = ''
        itemSemster.terms.forEach((item) => {
          item.start_date = ''
          item.end_date = ''
          item[this.current_locale].public_name = ''
        })
      })
    },
    onAcademicEndDateChange() {
      this.resteValues()
    },
    // ---- date Slected From Start Academic Year ----
    onAcademicStartDateChange(selectedDates, dateStr) {
      this.resteValues()

      const oldDate = new Date(dateStr)
      const newDate = oldDate.setDate(oldDate.getDate() + 1)

      if (this.edit_id) {
        // Edit Master
        const oldDate = new Date(this.academicYearCalc.start_date)
        const newDate = oldDate.setDate(oldDate.getDate() + 1)
        this.$set(this.config.EndAcademicYear, 'minDate', newDate)
      } else if (this.id_Create_sub) {
        // Create Sub
        this.$set(this.config.EndAcademicYear, 'minDate', newDate)
        this.$set(this.config.EndAcademicYear, 'maxDate', this.EndEndAcademic)
      } else {
        // Edit Master

        this.$set(this.config.EndAcademicYear, 'minDate', newDate) //min Time of End date Academic Year
      }
    },

    // ------------ Start Submitted form For Edit And Create ----------
    formSubmitted() {
      if (this.onlyShow) {
        this.$router.push({ name: 'subAcademicYearsList' })
        return
      }
      this.$emit('formSubmitted', true)
      this.$validator.validate().then((valid) => {
        if (valid) {
          const payload = {
            division_id: this.academicYearCalc.division_id
              ? this.academicYearCalc.division_id.id
              : null,
            section_id: this.academicYearCalc.section_id
              ? this.academicYearCalc.section_id.id
              : null,
            grade_id: this.academicYearCalc.grade_id
              ? this.academicYearCalc.grade_id.id
              : null,

            days_hours: this.academicYearCalc?.days_hours,
            end_date: this.academicYearCalc.end_date,
            holidays: this.academicYearCalc.holidays,
            semesters: this.academicYearCalc.semesters,
            start_date: this.academicYearCalc.start_date,
            studying_days: this.studyingDays,
            terms: this.academicYearCalc.terms,
            total_academic_weeks: this.totalAcademicWeeks,
            total_special_holidays: this.specialHolidays,
            total_special_vacations: this.specialVacations,
            vacations: this.academicYearCalc.vacations,
            week_start_day: this.academicYearCalc.week_start_day,
            with_semester: this.academicYearCalc.with_semester,
            working_days: this.AcademicDays
          }
          if (this.edit_id) {
            this.formSubmittedEdit(payload) // edit Master
          } else if (this.edit_id_masterforsub && this.edit_Subid_forsub) {
            //edit sub
            this.formSubmittedEditSub(payload)
          } else if (this.id_Create_sub) {
            this.formSubmittedCreateSub(payload) // crate sub
          } else {
            this.formSubmittedCreate(payload) // Create master
          }
        } else if (!valid) {
          this.errMsg(
            this.$t(
              'onlineClass.online_Class_has_not_been_saved_please_complete_your_data'
            ),
            'field'
          )
        }
      })
    },

    //Submitted Create Master
    formSubmittedCreate(payload) {
      // This Delete Becuse its mongo Data base
      delete payload.division_id
      delete payload.section_id
      delete payload.grade_id

      AcademicYearRepository.create(payload).then(
        () => {
          this.$router.push({ name: 'AcademicYearsList' })
          this.successMsg(
            'New academic year has been added successfully',
            'Success'
          )
        },
        (error) => {
          if (error.data.status === 'exists') {
            this.errMsg(
              'Academic Year Exists With Same Start and End year ',
              'error'
            )
          }
        }
      )
    },

    //Submitted Edit Master
    formSubmittedEdit(payload) {
      // This Delete Becuse its mongo Data base

      delete payload.division_id
      delete payload.section_id
      delete payload.grade_id

      const data = { payload }
      data[this.authUser.userRole] = { ...payload }

      AcademicYearRepository.update(this.edit_id, data).then(
        () => {
          this.$router.push({ name: 'AcademicYearsList' })
          this.successMsg(
            'academic year has been edited successfully',
            'Success'
          )
        },
        (error) => {
          if (error.data.status === 'exists') {
            this.errMsg(
              'Academic Year Exists With Same Start and End year',
              'error'
            )
          }
        }
      )
    },

    // create Sub
    formSubmittedCreateSub(payload) {
      AcademicYearRepository.createSub(this.id_Create_sub, payload).then(
        () => {
          this.$router.push({ name: 'subAcademicYearsList' })
          this.successMsg(
            'academic year has been created successfully',
            'Success'
          )
        },
        (error) => {
          if (error.data.status === 'exists') {
            this.errMsg(
              'Academic Year Exists With Same Start and End year',
              'error'
            )
          }
        }
      )
    },

    // Edit Sub
    formSubmittedEditSub(payload) {
      const data = {
        division_id: payload.division_id,
        section_id: payload.section_id,
        grade_id: payload.grade_id
      }
      data[this.authUser.userRole] = { ...payload }

      AcademicYearRepository.EditSub(
        this.edit_id_masterforsub,
        this.edit_Subid_forsub,
        data
      ).then(
        () => {
          this.$router.push({ name: 'subAcademicYearsList' })
          this.successMsg(
            'academic year has been edited successfully',
            'Success'
          )
        },
        (error) => {
          if (error.data.status === 'exists') {
            this.errMsg(
              'Academic Year Exists With Same Start and End year',
              'error'
            )
          }
        }
      )
    },

    // ------------ End Submitted form For Edit And Create ----------

    // ------------ Start AddTerm & removeTerm For Edit And Create ---------
    addTerm(indexOfTerm) {
      // this.validationStep4(false)
        let academicDaysIsAvailable = this.academicYearCalc.terms[indexOfTerm].end_date < this.academicYearCalc.end_date
        console.log("cann add term" + academicDaysIsAvailable);


      if (
        this.academicYearCalc.terms[indexOfTerm].start_date !== '' &&
        this.academicYearCalc.terms[indexOfTerm].end_date !== '' &&
        this.academicYearCalc.terms[indexOfTerm][this.current_locale]
          .public_name !== ''
      ) {
        const obj = {
          start_date: '',
          end_date: ''
        }
        obj[this.current_locale] = {
          public_name: ''
        }
        if (academicDaysIsAvailable) {
          this.academicYearCalc.terms.push(obj)
        }else
          this.errMsg("Can't add another term academic year days have been already used up.")
      }
    },
    removeTerm(index) {
      this.academicYearCalc.terms.splice(index, 1)
    },
    // ------------ End AddTerm & removeTerm For Edit And Create ---------

    addSemester(semesterIndex) {
      const last_academic_year = this.academicYearCalc.semesters[semesterIndex]
      const empty_fields_semesters = last_academic_year.terms.filter((term) => {
        return (
          term.start_date === '' ||
          term.end_date === '' ||
          term[this.current_locale].public_name === ''
        )
      })
      if (!empty_fields_semesters.length) {
        const semester = {
          terms: [{}],
          start_date: '',
          end_date: ''
        }
        semester.terms[0][this.current_locale] = {
          public_name: ''
        }

        this.academicYearCalc.semesters.push(semester)
      }
    },

    addTermInSemester(semester, termIndex) {
      if (
        semester.terms[termIndex].start_date !== '' &&
        semester.terms[termIndex].end_date !== '' &&
        semester.terms[termIndex].public_name !== ''
      ) {
        const obj = {
          start_date: '',
          end_date: ''
        }
        obj[this.current_locale] = {
          public_name: ''
        }
        semester.terms.push(obj)
      }
    },

    removeTermInSemester(semester, termIndex) {
      semester.terms.splice(termIndex, 1)
    },

    isNewTermButtonEnabled(indexOfTerm){
      return this.academicYearCalc.terms[indexOfTerm].start_date && this.academicYearCalc.terms[indexOfTerm].end_date && this.academicYearCalc.terms[indexOfTerm][this.current_locale].public_name;
    },

    isNewVacationButtonEnabled(indexOfTerm){
      return this.academicYearCalc.vacations[indexOfTerm].start_date && this.academicYearCalc.vacations[indexOfTerm].end_date && this.academicYearCalc.vacations[indexOfTerm][this.current_locale].public_name;
    },

    // ------------ Start Remove Semester For Edit And Create ----------
    removeSemester(index) {
      this.academicYearCalc.semesters.splice(index, 1)
    },
    // ------------ End Remove Semester For Edit And Create ----------

    // ------------ Start add Vacation Semester For Edit And Create ----------

    addVacation(indexOfvacations) {
      if (
        this.academicYearCalc.vacations[indexOfvacations].start_date !== '' &&
        this.academicYearCalc.vacations[indexOfvacations].end_date !== '' &&
        this.academicYearCalc.vacations[indexOfvacations][this.current_locale]
          .public_name !== ''
      ) {
        const vacation = {
          start_date: '',
          end_date: ''
        }
        vacation[this.current_locale] = {
          public_name: ''
        }
        this.academicYearCalc.vacations.push(vacation)
      }
    },
    removeVacation(index) {
      this.academicYearCalc.vacations.splice(index, 1)
    },

    // ------------ End add Vacation For Edit And Create ----------

    // ------------ Start Remove Semester For Edit And Create ----------
    addHoliday(indexOfHoliday) {
      if (
        this.academicYearCalc.holidays[indexOfHoliday].start_date !== '' &&
        this.academicYearCalc.holidays[indexOfHoliday].end_date !== '' &&
        this.academicYearCalc.holidays[indexOfHoliday][this.current_locale]
          .public_name !== ''
      ) {
        const holiday = {
          start_date: '',
          end_date: ''
        }
        holiday[this.current_locale] = {
          public_name: ''
        }
        this.academicYearCalc.holidays.push(holiday)
      }
    },

    removeHoliday(index) {
      this.academicYearCalc.holidays.splice(index, 1)
    }
  },
  props: {
    edit_id: {},
    id_Create_sub: {},
    edit_id_masterforsub: {},
    edit_Subid_forsub: {},
    onlyShow: { type: Boolean, default: false }
  },
  computed: {
    disabledVacationsDays() {
      const dates = []
      this.academicYearCalc.vacations.forEach((vaction) => {
        const now = moment(vaction.start_date).clone()
        while (now.isSameOrBefore(moment(vaction.end_date))) {
          dates.push(now.format('YYYY-MM-DD'))
          now.add(1, 'days')
        }
      })
      return dates
    },
    // culculateForsingleTerm () {
    // if (this.academicYearCalc.with_semester === 'without_semester') {
    // const newArr = this.academicYearCalc.terms.map((item) => {
    // item.start_end = `${item.start_date} / ${item.end_date}`
    // item.name = item[this.current_locale].public_name

    // item.AcademicDays = this.AcademicDaysforSingleTerm(
    // item.start_date,
    // item.end_date
    // )
    // item.AcademicWeeks = Math.round(item.AcademicDays / 7)

    // return item
    // })
    // return newArr
    // } else {
    // const sss = this.academicYearCalc.semesters.map((item) => {
    // console.log(item.terms)
    // return item
    // })
    // return sss
    // }
    // },

    toloalOfTermsHolidays() {
      const newArr = [
        ...this.academicYearCalc.vacations,
        ...this.academicYearCalc.holidays
      ]
      return newArr.map((item) => {
        item.start_end = `${item.start_date} / ${item.end_date}`
        item.name = item[this.current_locale].public_name

        item.Academic_Vacations = this.calculatSpecialVacations(
          item.start_date,
          item.end_date
        )

        return item
      })
    },

    academicYearCalc() {
      if (this.edit_id) {
        return this.academic_year_withRole
      } else if (this.$route.params.idSub) {
        return this.academic_year_withRole_sub
      } else {
        return this.academic_year
      }
    },
    subAdimicYearsFrom() {
      return this.$route.path.includes('/sub-academic-years/')
    },

    AcademicDays() {
      let AcademicDays = 0
      if (
        this.academicYearCalc.start_date !== '' &&
        this.academicYearCalc.end_date !== ''
      ) {
        const start = moment(this.academicYearCalc.start_date)
        const end = moment(this.academicYearCalc.end_date)
        AcademicDays = end.diff(start, 'days') + 1
      }
      return AcademicDays
    },

    //Total Of specialVacations
    specialVacations() {
      let vacations = 0
      if (this.academicYearCalc.vacations) {
        this.academicYearCalc.vacations.forEach((vacation) => {
          if (vacation.end_date && vacation.start_date)
            vacations +=
              moment(vacation.end_date).diff(
                moment(vacation.start_date),
                'days'
              ) + 1
        })
      }

      return vacations
    },

    //Total Of Holiday
    specialHolidays() {
      let holidays = 0
      if (this.academicYearCalc.holidays) {
        this.academicYearCalc.holidays.forEach((holiday) => {
          // Make Holiday just 1day
          holiday.end_date = holiday.start_date
          if (holiday.start_date && holiday.end_date)
            holidays +=
              moment(holiday.end_date).diff(
                moment(holiday.start_date),
                'days'
              ) + 1
        })
      }

      return holidays
    },

    // total Academic Weeks
    totalAcademicWeeks() {
      const weeksCount = Math.round(this.AcademicDays / 7)
      return weeksCount
    },

    // weekend of one week
    weekendIsoWeekDays() {
      const days = this.academicYearCalc?.days_hours
        .filter((day) => {
          return !day.status
        })
        .map((day) => {
          return day.day_name
        })
      return days.length
    },
    // total Weekends
    totalWeekends() {
      const Weekends = this.weekendIsoWeekDays * this.totalAcademicWeeks
      return Weekends
    },
    //Total Of studyingDays
    studyingDays() {
      const days =
        this.AcademicDays -
        (this.specialVacations + this.specialHolidays + this.totalWeekends)
      return days
    },
    //houres in one week
    houresInOneWeek() {
      const days = this.academicYearCalc?.days_hours
        .filter((day) => {
          return day.status
        })
        .map((day) => {
          const startTime = moment(day.first_bill, 'HH:mm a')
          const endTime = moment(day.last_bill, 'HH:mm a')
          const houresInDay = moment.duration(endTime.diff(startTime))
          const hours = parseInt(houresInDay.asHours())

          return hours
        })
      const add = (a, b) => a + b
      const sum = days.reduce(add)
      return sum
    },
    // total studing weeks
    studyingweeks() {
      return this.studyingDays / 7
    },
    //total houres
    totalHoures() {
      return this.houresInOneWeek * this.studyingweeks
    },

    minAcademic() {
      const config = {
        minDate: null,
        maxDate: null
      }

      if (this.edit_id) {
        config.minDate = this.academicYearCalc.start_date // Edit Master
      } else if (this.edit_id_masterforsub && this.edit_Subid_forsub) {
        config.minDate = this.StartEndAcademic // Edit Sub
        config.maxDate = this.EndEndAcademic
      } else if (this.id_Create_sub) {
        config.minDate = this.StartEndAcademic // Create Sub
        config.maxDate = this.EndEndAcademic
      } else {
        config.minDate = this.currentDate //create master
      }

      return config
    }
  }
}
</script>

<style>
.academic-year-form .background-none {
  background-color: transparent !important;
  color: rgb(160, 152, 152);
  cursor: pointer;
}

.academic-year-form .background-primary {
  background-color: rgba(var(--vs-primary), 1) !important;
  cursor: pointer;
  border-radius: 4px;
  padding: 3px !important;
  font-size: 20px;
  color: #ffffff;
}

.academic-year-form .th .sort-th,
.academic-year-form th .vs-table-text {
  font-size: 15px;
  /* color: #002452; */
  font-weight: bold;
}
</style>

