import Home from './Home'

export default [
  {
    path: 'home',
    name: 'Home',
    component: Home,
    meta: {
      breadcrumb: [{ title: 'home', url: '/home' }],
      pageTitle: 'Welcome',
      rule: 'landing'
    }
  }
]
