<template>
  <div class="vx-row">
    <div class="vx-col md:w w-full mb-base">
      <vx-card class="p-3 pt-6 pb-6 md:p-6">
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <label>{{ $t('Attendance.academicDate') }}</label>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <span v-if="!show_datepicker">{{ attendance_date }}</span>
            <datepicker
              @selected="changeTheDate"
              @closed="setTheDate"
              v-if="show_datepicker"
              :disabled-dates="disabled_dates"
              v-model="attendance_date"
              format="yyyy-MM-dd"></datepicker>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-button
              class="ml-auto mr-0 block"
              v-if="!show_datepicker"
              @click="requestConfirmation()"
              color="primary"
              type="filled"
              >{{ $t('Attendance.Change_Academic_Date') }}
            </vs-button>
            <!-- <vs-button
              class="ml-auto mr-0 block"
              v-if="show_datepicker"
              @click="changeTheDate()"
              color="success"
              type="filled"
            >Set The Academic Date</vs-button> -->
          </div>
        </div>
        <!-- If Attendance By Student -->
        <div v-if="attendance_by_student">
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
              <label>{{ $t('Attendance.studentId') }}</label>
            </div>
            <div class="vx-col sm:w-1/3 w-full">
              <vx-input-group
                prependClasses="border border-solid border-grey border-r-0">
                <vs-input
                  @focus="show_card = false"
                  @keydown.enter="getStudent(selected_student_id)"
                  v-model="selected_student_id"
                  :placeholder="$t('Attendance.studentId')"
                  class="w-full"
                  name="student_id" />
                <template slot="append">
                  <div class="append-text btn-addon">
                    <vs-button
                      @click="getStudent(selected_student_id)"
                      type="border"
                      icon-pack="feather"
                      icon="icon-search"></vs-button>
                  </div>
                </template>
              </vx-input-group>
            </div>
            <div class="vx-col sm:w-1/3 w-full"></div>
          </div>
          <div class="vx-row mb-6" v-if="show_card">
            <div class="vx-col sm:w-3/3 mr-auto ml-auto w-full">
              <div v-if="selected_student !== null">
                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/3 w-full">
                    <label>{{ $t('Attendance.studentId') }}</label>
                  </div>
                  <div class="vx-col sm:w-1/3 w-full">
                    {{ selected_student.code }}
                  </div>
                </div>
                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/3 w-full">
                    <label>{{ $t('Attendance.studentName') }}</label>
                  </div>
                  <div class="vx-col sm:w-1/3 w-full">
                    <span>{{
                      `${selected_student.first_name} ${selected_student.middle_name} ${selected_student.last_name}`
                    }}</span>
                  </div>
                </div>
                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/3 w-full">
                    <label>{{ $t('Attendance.division') }}</label>
                  </div>
                  <div class="vx-col sm:w-1/3 w-full">
                    <span>{{ selected_student.division }}</span>
                  </div>
                </div>
                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/3 w-full">
                    <label>{{ $t('Attendance.grade') }}</label>
                  </div>
                  <div class="vx-col sm:w-1/3 w-full">
                    <span>{{ selected_student.grade }}</span>
                  </div>
                </div>
                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/3 w-full">
                    <label>{{ $t('Attendance.class') }}</label>
                  </div>
                  <div class="vx-col sm:w-1/3 w-full">
                    <span>{{ selected_student.class }}</span>
                  </div>
                </div>
                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/3 w-full">
                    <label>{{ $t('Attendance.status') }}</label>
                  </div>
                  <div class="vx-col sm:w-1/3 w-full">
                    <vs-chip
                      v-if="!changing_status"
                      :class="`status--${selected_student.attendance_status.status_title
                        .toLowerCase()
                        .replaceAll(' ', '_')}`"
                      >{{
                        selected_student.attendance_status === null
                          ? 'Undefined'
                          : selected_student.attendance_status.status_title
                      }}
                    </vs-chip>
                    <vs-chip
                      v-if="
                        !changing_status &&
                        selected_student.attendance_status.id &&
                        selected_student.attendance_status.flag.toLowerCase() ==
                          'authorized absent' &&
                        selected_student.attendance_status.status_detail_id
                      "
                      :class="`status--${authorized_details_all
                        .find(
                          (item) =>
                            item.detail_id ===
                            selected_student.attendance_status.status_detail_id
                        )
                        .title.toLowerCase()
                        .replaceAll(' ', '_')}`"
                      >{{
                        selected_student.attendance_status === null
                          ? 'Undefined'
                          : selected_student.attendance_status.status_title.toLowerCase() ==
                            'authorized absent'
                          ? authorized_details_all.find(
                              (item) =>
                                item.detail_id ===
                                selected_student.attendance_status
                                  .status_detail_id
                            ).title
                          : ''
                      }}
                    </vs-chip>
                    <vs-chip
                      v-else-if="
                        !changing_status &&
                        selected_student.attendance_status.id &&
                        selected_student.attendance_status.flag.toLowerCase() ==
                          'present' &&
                        selected_student.attendance_status.status_detail_id
                      "
                      :class="`status--${present_details_all
                        .find(
                          (item) =>
                            item.detail_id ===
                            selected_student.attendance_status.status_detail_id
                        )
                        .title.toLowerCase()
                        .replaceAll(' ', '_')}`"
                      >{{
                        selected_student.attendance_status === null
                          ? 'Undefined'
                          : selected_student.attendance_status.status_title.toLowerCase() ==
                            'present'
                          ? present_details_all.find(
                              (item) =>
                                item.detail_id ===
                                selected_student.attendance_status
                                  .status_detail_id
                            ).title
                          : ''
                      }}
                    </vs-chip>
                    <v-select
                      v-model="selected_student.attendance_status"
                      v-if="changing_status"
                      :clearable="false"
                      :get-option-label="(option) => option.status_title"
                      :options="attendance_status_all"></v-select>
                    <v-select
                      v-if="
                        changing_status &&
                        selected_student.attendance_status.id &&
                        selected_student.attendance_status.flag.toLowerCase() ==
                          'authorized absent'
                      "
                      class="mt-4"
                      :placeholder="$t('Attendance.status')"
                      closeOnSelect
                      :clearable="false"
                      appendToBody
                      v-model="
                        selected_student.attendance_status.status_detail_id
                      "
                      :reduce="(option) => option.detail_id"
                      v-validate="'required'"
                      name="selected_status_details"
                      :get-option-label="(option) => option.title"
                      :options="authorized_details_all">
                      <template v-slot:no-options>
                        <em style="opacity: 0.75">{{
                          $t('Attendance.noClasses')
                        }}</em>
                      </template>
                    </v-select>
                    <v-select
                      v-else-if="
                        changing_status &&
                        selected_student.attendance_status.id &&
                        selected_student.attendance_status.flag.toLowerCase() ==
                          'present'
                      "
                      class="mt-4"
                      :placeholder="$t('Attendance.status')"
                      closeOnSelect
                      :clearable="false"
                      appendToBody
                      v-model="
                        selected_student.attendance_status.status_detail_id
                      "
                      :reduce="(option) => option.detail_id"
                      v-validate="'required'"
                      name="selected_status_details"
                      label="title"
                      :options="present_details_all">
                      <template v-slot:no-options>
                        <em style="opacity: 0.75">{{
                          $t('Attendance.noClasses')
                        }}</em>
                      </template>
                    </v-select>
                  </div>
                  <div class="vx-col sm:w-1/3 ml-auto mr-0 block">
                    <vs-button
                      class="ml-auto mr-0 block"
                      @click="changeStatus"
                      v-if="changing_status"
                      type="filled"
                      color="primary"
                      >{{ $t('basic.change') }}
                    </vs-button>
                  </div>
                </div>
                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/3 ml-auto mr-0 block">
                    <vs-button
                      class="ml-auto mr-0 block"
                      v-if="!changing_status"
                      @click="changing_status = true"
                      type="filled"
                      color="primary"
                      >{{
                        selected_student.attendance_status === null
                          ? 'Take Attendance'
                          : 'Change Status'
                      }}
                    </vs-button>

                    <vs-button
                      class="ml-auto mr-0 block"
                      v-if="changing_status"
                      type="border"
                      color="warning"
                      @click="changing_status = false"
                      >{{ $t('form.cancel') }}
                    </vs-button>
                  </div>
                </div>
              </div>
              <div v-else>
                <vs-alert
                  :title="$t('Attendance.No_such_student')"
                  color="danger"
                  active="true"
                  >{{ $t('Attendance.There_is_no_student_with_this_ID') }}:
                  {{ selected_student_id }}
                </vs-alert>
              </div>
            </div>
          </div>
        </div>

        <!-- If Attendance By Class -->
        <div v-if="!attendance_by_student">
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
              <label>{{ $t('Attendance.className') }}</label>
            </div>
            <div class="vx-col sm:w-1/3 w-full">
              <v-select
                :placeholder="$t('Attendance.className')"
                :clearable="false"
                closeOnSelect
                label="name"
                :reduce="(el) => el.name"
                v-model="selected_class_name"
                v-validate="'required'"
                class="w-full"
                name="selected_claa_name"
                :options="classes_all"
                @input="getClass(selected_class_name)" />
            </div>
            <div class="vx-col sm:w-1/3 w-full"></div>
          </div>
          <div class="vx-row mb-6" v-if="show_card">
            <div class="vx-col ml-auto mr-0 auto sm:w-1/4 w-full">
              <vs-button
                class="ml-auto mr-0 block"
                v-if="selected_class !== null"
                @click="allStudentsAttended()"
                color="primary"
                type="filled"
                >{{ $t('Attendance.all_Students_are_present') }}
              </vs-button>
            </div>
          </div>
          <div class="vx-row mb-6" v-if="show_card">
            <div class="vx-col sm:w-3/3 w-full ml-auto mr-auto">
              <div v-if="selected_class !== null">
                <vs-table
                  stripe
                  :hoverFlat="true"
                  :noDataText="$t('Attendance.noClasses')"
                  :data="selected_class">
                  <template slot="thead">
                    <vs-th>{{ $t('form.ID') }}</vs-th>
                    <vs-th>{{ $t('form.name') }}</vs-th>
                    <vs-th>{{ $t('Attendance.attendance_register') }}</vs-th>
                    <vs-th>{{ $t('form.status') }}</vs-th>
                    <vs-th>{{ $t('Attendance.attendance') }}</vs-th>
                  </template>
                  <template slot-scope="{data}">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                      <vs-td
                        class="vx-col sm:w-1/6 w-full mt-5"
                        :data="data[indextr]"
                        >{{ selected_class[indextr].code }}
                      </vs-td>
                      <vs-td
                        class="vx-col sm:w-1/6 w-full mt-5"
                        :data="data[indextr]"
                        >{{
                          `${selected_class[indextr].first_name} ${selected_class[indextr].middle_name} ${selected_class[indextr].last_name}`
                        }}
                      </vs-td>
                      <vs-td
                        class="vx-col sm:w-1/6 w-full mt-5"
                        :data="data[indextr]"
                        >{{
                          selected_class[indextr].attendance_status === null
                            ? 'Undefined'
                            : 'Done'
                        }}
                      </vs-td>
                      <vs-td
                        class="vx-col sm:w-1/6 w-full mt-5"
                        :data="data[indextr]">
                        <vs-chip
                          :class="`status--${selected_class[
                            indextr
                          ].attendance_status.status_title
                            .toLowerCase()
                            .replaceAll(' ', '_')}`"
                          >{{
                            selected_class[indextr].attendance_status === null
                              ? 'Undefined'
                              : selected_class[indextr].attendance_status
                                  .status_title
                          }}
                        </vs-chip>
                        <vs-chip
                          v-if="
                            selected_class[indextr].attendance_status.id &&
                            selected_class[
                              indextr
                            ].attendance_status.flag.toLowerCase() ==
                              'authorized absent' &&
                            selected_class[indextr].attendance_status
                              .status_detail_id
                          "
                          :class="`status--${authorized_details_all
                            .find(
                              (item) =>
                                item.detail_id ===
                                selected_class[indextr].attendance_status
                                  .status_detail_id
                            )
                            .title.toLowerCase()
                            .replaceAll(' ', '_')}`"
                          >{{
                            selected_class[indextr].attendance_status === null
                              ? 'Undefined'
                              : selected_class[
                                  indextr
                                ].attendance_status.flag.toLowerCase() ==
                                'authorized absent'
                              ? authorized_details_all.find(
                                  (item) =>
                                    item.detail_id ===
                                    selected_class[indextr].attendance_status
                                      .status_detail_id
                                ).title
                              : ''
                          }}
                        </vs-chip>
                        <vs-chip
                          v-else-if="
                            selected_class[indextr].attendance_status.id &&
                            selected_class[
                              indextr
                            ].attendance_status.flag.toLowerCase() ==
                              'present' &&
                            selected_class[indextr].attendance_status
                              .status_detail_id
                          "
                          :class="`status--${present_details_all
                            .find(
                              (item) =>
                                item.detail_id ===
                                selected_class[indextr].attendance_status
                                  .status_detail_id
                            )
                            .title.toLowerCase()
                            .replaceAll(' ', '_')}`"
                          >{{
                            selected_class[indextr].attendance_status === null
                              ? 'Undefined'
                              : selected_class[
                                  indextr
                                ].attendance_status.flag.toLowerCase() ==
                                'present'
                              ? present_details_all.find(
                                  (item) =>
                                    item.detail_id ===
                                    selected_class[indextr].attendance_status
                                      .status_detail_id
                                ).title
                              : ''
                          }}
                        </vs-chip>
                      </vs-td>
                      <vs-td
                        class="vx-col sm:w-2/6 w-full mt-5"
                        :data="data[indextr]">
                        <v-select
                          :placeholder="$t('Attendance.status')"
                          closeOnSelect
                          :clearable="false"
                          appendToBody
                          :value="selected_class[indextr].attendance_status"
                          @input="
                            (attendance_status) =>
                              updateStatus(indextr, attendance_status)
                          "
                          v-validate="'required'"
                          :get-option-label="(option) => option.status_title"
                          name="selected_new_status"
                          :options="attendance_status_all">
                          <template v-slot:no-options>
                            <em style="opacity: 0.75">{{
                              $t('Attendance.noClasses')
                            }}</em>
                          </template>
                        </v-select>
                        <v-select
                          v-if="
                            selected_class[indextr].attendance_status.id &&
                            selected_class[
                              indextr
                            ].attendance_status.flag.toLowerCase() ==
                              'authorized absent'
                          "
                          class="mt-4"
                          :placeholder="$t('Attendance.status')"
                          closeOnSelect
                          :clearable="false"
                          appendToBody
                          :value="
                            selected_class[indextr].attendance_status
                              .status_detail_id
                          "
                          @input="
                            (status_detail_id) =>
                              updateStatusDetails(indextr, status_detail_id)
                          "
                          :reduce="(option) => option.detail_id"
                          v-validate="'required'"
                          name="selected_status_details"
                          :get-option-label="(option) => option.title"
                          :options="authorized_details_all">
                          <template v-slot:no-options>
                            <em style="opacity: 0.75">{{
                              $t('Attendance.noClasses')
                            }}</em>
                          </template>
                        </v-select>
                        <v-select
                          v-else-if="
                            selected_class[indextr].attendance_status.id &&
                            selected_class[
                              indextr
                            ].attendance_status.flag.toLowerCase() == 'present'
                          "
                          class="mt-4"
                          :placeholder="$t('Attendance.status')"
                          closeOnSelect
                          :clearable="false"
                          appendToBody
                          :value="
                            selected_class[indextr].attendance_status
                              .status_detail_id
                          "
                          @input="
                            (status_detail_id) =>
                              updateStatusDetails(indextr, status_detail_id)
                          "
                          :reduce="(option) => option.detail_id"
                          v-validate="'required'"
                          name="selected_status_details"
                          :get-option-label="(option) => option.title"
                          :options="present_details_all">
                          <template v-slot:no-options>
                            <em style="opacity: 0.75">{{
                              $t('Attendance.noClasses')
                            }}</em>
                          </template>
                        </v-select>
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
                <div class="vx-row mb-6 mt-6" v-if="selected_class !== null">
                  <div class="vx-col sm:w-1/4">
                    <vs-button type="border" color="warning" @click="cancel()"
                      >{{ $t('form.cancel') }}
                    </vs-button>
                  </div>
                  <div class="vx-col sm:w-1/4 ml-auto mr-0 block">
                    <vs-button
                      class="ml-auto mr-0 block"
                      @click="submitAttendance()"
                      type="filled"
                      color="primary"
                      >{{ $t('form.submit') }}
                    </vs-button>
                  </div>
                </div>
              </div>
              <div v-else>
                <vs-alert title="No such class" color="danger" active="true"
                  >{{ $t('Attendance.there_are_no_classes_with_this_code') }}:
                  {{ selected_class_name }}
                </vs-alert>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
import vSelect from 'vue-select'
import {mapGetters} from 'vuex'
// import router from '@/router'
const ConsoleRepository = RepositoryFactory.get(
  'Attendance',
  'attendanceConsole'
)
export default {
  components: {
    Datepicker,
    'v-select': vSelect
  },
  computed: {
    ...mapGetters('auth', ['authUser']),
    classId() {
      return (
        this.selected_student?.class_id ||
        this.classes_all.find((el) => el.name === this.selected_class_name)
          ?.id ||
        null
      )
    }
  },
  data() {
    return {
      new_attendance: {},
      attendance_date: '',
      status_all: [],
      authorized_details_all: [],
      present_details_all: [],
      attendance_status_all: [],
      attendance_by_student: false,
      selected_student_id: '',
      selected_class_name: '',
      classes_all: [],
      show_card: false,
      selected_student: null,
      selected_class: [],
      changing_status: false,
      new_status: null,
      show_datepicker: false,
      disabled_dates: {}
    }
  },

  methods: {
    timeNow() {
      const today = new Date()
      return `${today.getHours()}:${today.getMinutes()}`
    },
    allStudentsAttended() {
      const presentStatus = this.attendance_status_all.find(
        (item) => item.flag === 'Present'
      )

      const presentStatusDetail = this.present_details_all.find(
        (item) => item.title === 'Present'
      )

      this.selected_class = this.selected_class.map((temp) => {
        temp.attendance_status = presentStatus
        temp.attendance_status.status_detail_id = presentStatusDetail.detail_id
        return temp
      })
    },
    getStudent(id) {
      ConsoleRepository.getStudent(id, this.attendance_date)
        .then((res) => {
          if (res.student === 'Not found') {
            this.selected_student = null
          } else {
            res.student.attendance_status =
              res.student.attendance_status === 'unfinished'
                ? null
                : res.student.attendance_status
            this.selected_student = res.student

            if (!this.selected_student.attendance_status) {
              this.selected_student.attendance_status = {
                status_title: 'Undefined',
                status_details: null
              }
            } else {
              const attendance_status = this.attendance_status_all.find(
                (item) =>
                  item.id === this.selected_student.attendance_status.status_id
              )
              if (
                attendance_status.flag.toLowerCase() === 'authorized absent' ||
                attendance_status.flag.toLowerCase() === 'present'
              ) {
                this.selected_student.attendance_status = {
                  id: attendance_status.id,
                  flag: attendance_status.flag,
                  status_title: attendance_status.status_title,
                  status_detail_id:
                    this.selected_student.attendance_status.status_details.id
                }
              }
            }
          }
          this.show_card = true
          this.selected_class_name = this.selected_student?.class || ''
        })
        .catch((err) => {
          console.log(err)
          this.errMsg(
            this.$t('Attendance.there_is_no_student_with_this_name'),
            this.$t('Attendance.student_not_found')
          )

          this.selected_student = null
          this.$vs.loading.close()
        })
    },
    getClass(code) {
      ConsoleRepository.getClassStudents(code, this.attendance_date)
        .then((res) => {
          if (res.students.length === 0) {
            this.selected_class = null
          } else {
            this.selected_class = res.students
            this.selected_class.map((student) => {
              const attendance_status = this.attendance_status_all.find(
                (item) => item.id === student.attendance_status.status_id
              )

              if (student.attendance_status === 'unfinished') {
                student.attendance_status = {
                  status_title: 'Undefined',
                  status_details: null
                }
              } else if (
                attendance_status.flag.toLowerCase() === 'authorized absent' ||
                attendance_status.flag.toLowerCase() === 'present'
              ) {
                student.attendance_status = {
                  id: attendance_status.id,
                  flag: attendance_status.flag,
                  status_title: attendance_status.status_title,
                  status_detail_id: student.attendance_status.status_details.id
                }
              } else {
                student.attendance_status = attendance_status
              }
              return student
            })
          }
          this.show_card = true
        })
        .catch((err) => {
          this.errMsg(
            this.$t('Attendance.there_are_no_classes_with_this_name'),
            this.$t('Attendance.class_not_found')
          )
          this.selected_class = null
          this.$vs.loading.close()
        })
    },
    updateStatus(indextr, attendance_status) {
      this.selected_class[indextr].attendance_status = attendance_status
      if (
        this.selected_class[indextr].attendance_status.flag.toLowerCase() !==
          'authorized absent' &&
        this.selected_class[indextr].attendance_status.flag.toLowerCase() !==
          'present'
      ) {
        this.selected_class[indextr].attendance_status = Object.keys(
          this.selected_class[indextr].attendance_status
        ).reduce((object, key) => {
          if (key !== 'status_detail_id') {
            object[key] = this.selected_class[indextr].attendance_status[key]
          }
          return object
        }, {})
      }
    },
    updateStatusDetails(indextr, status_detail_id) {
      this.selected_class[indextr].attendance_status = {
        ...this.selected_class[indextr].attendance_status
      }
      this.selected_class[indextr].attendance_status.status_detail_id =
        status_detail_id
    },
    changeStatus() {
      const payload = {
        students: [this.new_attendance],
        class_id: this.classId
      }
      payload.students[0]['attendance'] =
        this.selected_student.attendance_status.id
      payload.students[0]['status_detail_id'] =
        this.selected_student.attendance_status.status_detail_id || null
      payload.students[0]['date'] = this.attendance_date
      payload.students[0]['studentId'] = this.selected_student.user_id
      payload.students[0]['class_id'] = this.classId // Make sure to be modified in the future with class_id
      payload.students[0]['attendance_time'] = this.timeNow()

      ConsoleRepository.changeAttendanceStatus(payload)
        .then((res) => {
          this.successMsg(res.message, this.$t('Attendance.attendance_updated'))
          this.$router.push('/attendance/attendance-console')
        })
        .catch((err) => {
          this.errMsg(err.data.message)
        })
      // this.changing_status = false
    },
    submitAttendance() {
      // this.checkCloseAttendance()

      const payload = {
        students: [],
        class_id: this.classes_all.filter(
          (el) => el.name === this.selected_class_name
        )[0].id,
        date: this.attendance_date
      }

      this.selected_class.map((temp) => {
        payload.students.push({
          student_id: temp.user_id,
          class_id: this.classId,
          attendance: temp.attendance_status.id,
          flag: temp.attendance_status.flag,
          status_detail_id: temp.attendance_status.status_detail_id || null,
          date: this.attendance_date,
          attendance_time: this.timeNow()
        })
      })

      const allSet =
        payload.students.filter((student) => {
          if (
            student.flag &&
            student.flag.toLowerCase() !== 'authorized absent' &&
            student.flag.toLowerCase() !== 'present'
          ) {
            return Boolean(
              this.attendance_status_all.find(
                (item) => item.flag === student.flag
              )
            )
          } else return student.status_detail_id !== null
        }).length === payload.students.length

      if (allSet) {
        ConsoleRepository.changeAttendanceStatus(payload)
          .then((res) => {
            this.successMsg(
              res.message,
              this.$t('Attendance.attendance_updated')
            )
            this.show_card = false
            this.selected_student_id = ''
            this.selected_class_name = ''
            this.$router.push('/attendance/attendance-console')
            // router.push('/attendance/attendance-console')
          })
          .catch((err) => {
            this.errMsg(err.data.message)
          })
      } else {
        this.warningMsg(
          this.$t('Attendance.all_student_should_have_a_status'),
          this.$t('Attendance.attendance_update_failed')
        )
      }
    },
    requestConfirmation() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm',
        text: this.$t('Attendance.do_you_confirm_changing_the_academic_date'),
        accept: this.acceptConfirmation
      })
    },
    acceptConfirmation() {
      this.show_card = false
      this.show_datepicker = true
    },
    changeTheDate(val) {
      this.attendance_date = val
      this.selected_student = null
      this.selected_class = []
    },
    setTheDate() {
      const current_date = this.attendance_date
      const dd = String(current_date.getDate()).padStart(2, '0')
      const mm = String(current_date.getMonth() + 1).padStart(2, '0')
      const yyyy = current_date.getFullYear()
      this.attendance_date = `${yyyy}-${mm}-${dd}`
      this.show_datepicker = false
      this.getClass(this.selected_class_name)
    },
    cancel() {
      this.show_card = false
      this.selected_class = []
      this.$router.push('/attendance/attendance-console')
    }
  },
  created() {
    ConsoleRepository.getAllFilterClasses().then((res) => {
      this.classes_all = res.data
    })

    const today = new Date()
    const dd = String(today.getDate()).padStart(2, '0')
    const mm = String(today.getMonth() + 1).padStart(2, '0')
    const yyyy = today.getFullYear()
    this.attendance_date = `${yyyy}-${mm}-${dd}`
    this.disabled_dates.from = today

    ConsoleRepository.getStatus().then((res) => {
      let all_status = res.response.map((state) => {
        return {
          status_title: state.en.title,
          id: state.id,
          flag: state.flag
        }
      })
      const all_authorized_details = res.response
        .filter((state) => state.flag.toLowerCase() === 'authorized absent')[0]
        ['details'].map((detail) => {
          return {title: detail.en.title, detail_id: detail.id}
        })
      const all_present_details = res.response
        .filter((state) => state.flag.toLowerCase() === 'present')[0]
        ['details'].map((detail) => {
          return {title: detail.en.title, detail_id: detail.id}
        })
      all_status = all_status.filter(
        (state) =>
          state.flag.toLowerCase() !== 'usual absent' &&
          state.flag.toLowerCase() !== 'disciplined' &&
          state.flag.toLowerCase() !== 'improvement in attendance' &&
          state.flag.toLowerCase() !== 'not recorded' &&
          state.flag.toLowerCase() !== 'recently usual absent' &&
          state.flag.toLowerCase() !== 'used to miss the first bell'
      )
      this.attendance_status_all = all_status
      this.authorized_details_all = all_authorized_details
      this.present_details_all = all_present_details
    })
    if (!this.$route.params.class_name) {
      this.attendance_by_student =
        this.$route.params.student_attendance === undefined
          ? false
          : Number(this.$route.params.student_attendance) === 1
    } else {
      this.selected_class_name = this.$route.params.class_name
      this.getClass((this.$route.params.class_name = this.selected_class_name))
    }
  }
}
</script>
