<template >
  <div>
    <vs-card class="p-3 pt-6 pb-6 md:p-6">
      <div class="vx-row w-full">
        <!-- <div class="vx-col mb-6 w-full">
          <label>
            <label>{{ $t("division.SelectBranch") }}</label>
            <sup class="text-danger">*</sup>
          </label>
          <v-select
            :placeholder="`${$t('division.schoolBranch')}`"
            class="w-full"
            v-validate="'required'"
            name="school"
            v-model="division.division_data.school_id"
            :options="schools"
            label="short_name"
            :reduce="(short_name) => short_name.id"
          />
          <span
            v-if="issueSubmitted && errors.has('school')"
            class="text-danger text-sm"
            >{{ errors.first("school") | text_formatter }}</span
          >
        </div> -->
        <!------------------------------------------->

        <div class="vx-col md:w-1/2 mb-6 w-full">
          <label>
            <label>{{ $t("division.Name") }}</label>
            <sup class="text-danger">*</sup>
          </label>
          <vs-input
            name="name"
            v-validate="
              'required|min:2|max:40|regex:^[a-zA-Z_][a-zA-Z0-9_]+( [a-zA-Z0-9_]+)*$'
            "
            :placeholder="`${$t('division.Name')}`"
            v-model="division.division_data.en.long_name"
            class="w-full"
          />
          <span
            v-if="issueSubmitted && errors.has('name')"
            class="text-danger text-sm"
            >{{ errors.first("name") | text_formatter }}</span
          >
        </div>
        <!---------------------------------------------->
        <div class="vx-col md:w-1/2 mb-6 w-full">
          <label>
            <label>{{ $t("division.email") }}</label>
            <sup class="text-danger">*</sup>
          </label>

          <vs-input
            name="email"
            v-validate="'required|min:2|max:50|email'"
            :placeholder="`${$t('division.email')}`"
            v-model="division.division_data.email"
            class="w-full"
          />
          <span
            v-if="issueSubmitted && errors.has('email')"
            class="text-danger text-sm"
            >{{ errors.first("email") | text_formatter }}</span
          >
        </div>
        <!------------------------------------------------------->

        <div class="vx-col md:w-1/2 mb-6 w-full">
          <label>
            <label>{{ $t("division.Key") }}</label>
            <sup class="text-danger">*</sup>
          </label>

          <vs-input
            name="Key"
            v-validate="
              'required|min:2|max:15|regex:^[a-zA-Z0-9_]+( [a-zA-Z0-9_]+)*$'
            "
            :placeholder="`${$t('division.Key')}`"
            v-model="division.division_data.key"
            class="w-full"
          />
          <span
            v-if="issueSubmitted && errors.has('Key')"
            class="text-danger text-sm"
            >{{ errors.first("Key") | text_formatter }}</span
          >
        </div>
        <!---------------------------------------------->
        <div class="vx-col md:w-1/2 mb-6 w-full">
          <label>
            <label>{{ $t("division.Director") }}</label>
            <sup class="text-danger">*</sup>
          </label>
          <v-select
            :placeholder="`${$t('division.Director')}`"
            v-model="division.division_data.director_id"
            name="director"
            v-validate="'required'"
            class="w-full"
            :options="users"
            label="name"
            :reduce="(name) => name.id"
          />
          <!-- <vs-select
            autocomplete
            :placeholder="`${$t('divisionadd.Director')}`"
            v-model="division.division_data.director_id"
            name="director"
            v-validate="'required'"
            class="w-full"
            :disabled="users.length > 0 ? false : true"
          >
            <vs-select-item
              :key="index"
              v-for="(item, index) in users"
              :value="item.id"
              :text="item.name"
            />
          </vs-select> -->
          <span
            v-if="issueSubmitted && errors.has('director')"
            class="text-danger text-sm"
            >{{ errors.first("director") | text_formatter }}</span
          >
        </div>

        <!------------------------------------------>
        <div class="vx-col md:w-1/2 mb-6 w-full">
          <label>
            <label>{{ $t("division.Principal") }}</label>
            <sup class="text-danger">*</sup>
          </label>
          <v-select
            :closeOnSelect="false"
            :placeholder="`${$t('division.Principal')}`"
            class="w-full"
            v-model="division.principals"
            multiple
            autocomplete
            name="Principal"
            v-validate="'required'"
            :options="users"
            label="name"
            :reduce="(name) => name.id"
          />
          <!-- <vs-select
            :placeholder="`${$t('division.Principal')}`"
            class="w-full"
            v-model="division.principals"
            multiple
            autocomplete
            name="Principal"
            v-validate="'required'"
            :disabled="users.length > 0 ? false : true"
          >
            <vs-select-item
              :key="index"
              :value="item.id"
              :text="item.name"
              v-for="(item, index) in users"
              :placeholder="`${$t('main.AutoComplete')}`"
            />
          </vs-select> -->
          <span
            v-if="issueSubmitted && errors.has('Principal')"
            class="text-danger text-sm"
            >{{ errors.first("Principal") | text_formatter }}</span
          >
        </div>

        <!---------------------------------------------->
        <div class="vx-col md:w-1/2 mb-6 w-full">
          <label>
            <label>{{ $t("division.Supervisor") }}</label>
            <sup class="text-danger">*</sup>
          </label>
          <v-select
            :closeOnSelect="false"
            :placeholder="`${$t('division.Supervisor')}`"
            class="w-full"
            v-model="division.supervisors"
            name="Supervisor"
            v-validate="'required'"
            multiple
            :options="users"
            label="name"
            :reduce="(name) => name.id"
          />
          <!-- <vs-select
            :placeholder="`${$t('division.Supervisor')}`"
            class="w-full"
            v-model="division.supervisors"
            multiple
            autocomplete
            name="Supervisor"
            v-validate="'required'"
            :disabled="users.length > 0 ? false : true"
          >
            <vs-select-item
              :key="index"
              :value="item.id"
              :text="item.name"
              v-for="(item, index) in users"
              :placeholder="`${$t('main.AutoComplete')}`"
            />
          </vs-select> -->
          <span
            v-if="issueSubmitted && errors.has('Supervisor')"
            class="text-danger text-sm"
            >{{ errors.first("Supervisor") | text_formatter }}</span
          >
        </div>

        <!-------------------------------------------->

        <div class="vx-col mb-6 w-full">
          <label>
            <label>{{ $t("division.grades") }}</label>
            <sup class="text-danger">*</sup>
          </label>
          <vx-input-group class="w-full">
            <vs-input
              :placeholder="`${$t('division.grades')}`"
              v-model="division.grades[0].name"
              class="w-full"
              name="grade"
              v-validate="'required'"
              @keyup.enter="addGrade"
            ></vs-input>
            <template slot="append">
              <div class="append-text btn-addon">
                <vs-button
                  @click="addGrade"
                  icon-pack="feather"
                  icon="icon-plus"
                  color="primary"
                  type="border"
                ></vs-button>
              </div>
            </template>
          </vx-input-group>

          <span
            v-if="issueSubmitted && errors.has('grade')"
            class="text-danger text-sm"
            >{{ errors.first("grade") | text_formatter }}</span
          >
        </div>

        <!--        added division.grades -->
        <div
          class="vx-col mb-6 w-full"
          :key="index"
          v-for="(grade, index) in addedGrade"
        >
          <vx-input-group class="w-full">
            <vs-input
              :placeholder="`${$t('division.grades')}`"
              v-model="grade.name"
            ></vs-input>
            <template slot="append">
              <div class="append-text btn-addon">
                <vs-button
                  @click="removeGrade(index)"
                  icon-pack="feather"
                  icon="icon-minus"
                  color="danger"
                  type="border"
                ></vs-button>
              </div>
            </template>
          </vx-input-group>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full pt-4 flex justify-between">
          <vs-button
            color="warning"
            type="border"
            class="mb-2"
            @click="back()"
            >{{ $t("division.cancel") }}</vs-button
          >
          <vs-button @click.stop="submitData()">{{
            $t("InstitutionDetails.Save")
          }}</vs-button>
        </div>
      </div>
    </vs-card>
  </div>
</template>
<script>
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const DivisionRepository = RepositoryFactory.get(
  'ConfigurationModule',
  'division'
)
import vSelect from 'vue-select'


export default {
  name: 'Create',
  components:{vSelect},
  data () {
    return {
      saveAgain: true,
      locales: this.$store.getters['main/locales'],
      schools: [],
      users: [],
      issueSubmitted: false,
      division: {
        division_data: {
          key: '',
          email: '',
          director_id: null,
          en: {
            long_name: '',
            short_name: ''
          }
        },
        supervisors: [],
        principals: [],
        grades: [{ name: '' }]
      }
    }
  },
  methods: {
    addGrade () {
      if (this.division.grades[0].name !== '') {
        this.division.grades.push({ ...this.division.grades[0] })
        this.division.grades[0].name = ''
      }
    },
    removeGrade (index) {
      this.division.grades.splice(index + 1)
    },
    getUsers () {
      DivisionRepository.getUsers().then((res) => {
        this.users = res.users
      })
    },
    getdata () {
      DivisionRepository.getdata().then((res) => {
      })
    },
    geschools () {
      DivisionRepository.getschools().then((res) => {
        this.schools = res.schools.filter(el => el[this.current_locale]).map(el => el[this.current_locale])
        if (this.schools.length < 2) this.division.division_data.school_id = this.schools[0].id
      })
    },
    createDivision () {
      this.VSLoading()
      this.division.division_data.school_id = this.authUser.school_id

      DivisionRepository.createDevision(this.division)
        .then((res) => {
          this.successMsg(this.$t('division.new_division_has_been_added_successfully'))
          this.$router.push({ name: 'DivisionDetails' })

        })
        .catch(err => this.errMsg(err))
        .finally(this.stopVSLoading)
    },
    submitData () {
      this.issueSubmitted = true
      this.$validator.validate()
        .then((valid) => {
          if (valid) {
            if (this.saveAgain == true) this.createDivision()
            this.saveAgain = false
          }
        })
    },
    makeid (length) {
      let result = ''
      const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        )
      }
      this.division.division_data.key = result
      return result
    },
    back () {
      this.$router.back()
    }
  },
  computed: {
    addedGrade () {
      return this.division.grades.length > 1
        ? this.division.grades.slice(1, this.division.grades.length)
        : []
    }
  },
  created () {
    this.makeid(2)
    this.geschools()
    this.getUsers()
  }
}
</script>
<style  scoped>
a {
  cursor: pointer;
}
.background-none {
  background-color: transparent !important;
}
</style>
