<template>
  <div id="page-user-list">
    <div class="vx-card p-6">
      <div class="flex flex-wrap items-center justify-between">
        <!-- ITEMS PER PAGE -->

        <div
          class="
            p-4
            border border-solid
            d-theme-border-grey-light
            rounded-full
            d-theme-dark-bg
            cursor-pointer
            flex
            items-center
            justify-between
            font-medium
          "
        >
          <span class="mr-2"
            >{{ currentPage * paginationPageSize - (paginationPageSize - 1) }}
            -
            {{
              list_length - currentPage * paginationPageSize > 0
                ? currentPage * paginationPageSize
                : list_length
            }}
            of {{ list_length }}</span
          >
          <!-- <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" /> -->
        </div>

        <!-- <vs-button
          icon-pack="feather"
          icon="icon-plus-circle"

          color="primary"
          type="filled"
        >Take Attendance</vs-button>-->
        <vs-spacer></vs-spacer>
        <div class="flex-grow w-1/3">
          <template v-if="checkUnfinishedClasses">
            <v-select
              :options="unfinished_classes"
              @input="selectUnfinishedClass"
              v-model="unfinishedClass"
              label="name"
              :reduce="(el) => el.name"
              :placeholder="$t('Attendance.unfinished_Classes')"
              :loading="loadingClasses"
            >
              <template #no-options>
                {{ $t("Attendance.There_are_no_unregistered_classes") }}
              </template>
              <template #option="option">
                <div class="vx-row pt-2 pb-2">
                  <label>{{ option.name }} ({{ option.count }})</label>
                  <vs-spacer></vs-spacer>
                  <!-- <vs-chip>{{ $t("Attendance.unfinished") }}</vs-chip> -->
                </div>
              </template>
            </v-select>
          </template>
          <vs-chip
            class="p-3"
            :color="unfinished_classes.includes('create') ? 'danger' : ''"
            v-else
          >
            {{ unfinished_classes }}
          </vs-chip>
        </div>
        <vs-spacer></vs-spacer>
        <vs-spacer></vs-spacer>
        <vs-dropdown vs-trigger-click>
          <div
            class="
              p-3
              shadow-drop
              rounded-lg
              d-theme-dark-light-bg
              cursor-pointer
              flex
              items-end
              justify-center
              text-lg
              font-medium
              w-32
            "
          >
            <span class="mr-2 leading-none">Actions</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item @click="exportCSV">
              <span class="flex items-center">
                <feather-icon
                  icon="DownloadIcon"
                  svgClasses="h-4 w-4"
                  class="mr-2"
                />
                <span>&nbsp;{{ $t("Attendance.download_csv") }}</span>
              </span>
            </vs-dropdown-item>
            <vs-dropdown-item divider>
              <label>{{ $t("Attendance.Take_Attendance") }}</label>
            </vs-dropdown-item>
            <vs-dropdown-item
              :to="{
                name: 'TakeAttendance',
                params: { student_attendance: 1 },
              }"
            >
              <feather-icon icon="UserIcon" svgClasses="h-4 w-4" class="mr-2" />
              <span> {{ $t("Attendance.By_Student") }}</span>
            </vs-dropdown-item>
            <vs-dropdown-item
              :to="{
                name: 'TakeAttendance',
                params: { student_attendance: 0 },
              }"
            >
              <feather-icon
                icon="UsersIcon"
                svgClasses="h-4 w-4"
                class="mr-2"
              />
              <span> {{ $t("Attendance.By_Class") }}</span>
            </vs-dropdown-item>
            <vs-dropdown-item divider> </vs-dropdown-item>
            <vs-dropdown-item
              @click="submitAttendance"
              v-if="!closedAttendance"
            >
              <span class="flex items-center">
                <feather-icon
                  icon="LockIcon"
                  svgClasses="h-4 w-4"
                  class="mr-2"
                />
                <span> {{ $t("Attendance.close_attendance") }}</span>
              </span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="openAttendance" v-else>
              <span class="flex items-center">
                <feather-icon
                  icon="UnlockIcon"
                  svgClasses="h-4 w-4"
                  class="mr-2"
                />
                <span> {{ $t("Attendance.open_attendance") }}</span>
              </span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>
      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="headers"
        :defaultColDef="defaultColDef"
        :rowData="list"
        rowSelection="multiple"
        rowMultiSelectWithClick="true"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="false"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl"
        @grid-ready="onGridReady"
        @row-selected="getSelectedRows"
        @first-data-rendered="onFirstDataRendered"
      />
      <vs-pagination
        :total="totalPages"
        :max="7"
        v-model="currentPage"
        class="mt-3"
        @change="checkTypeOfPagination()"
      />
    </div>
  </div>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import { mapGetters } from 'vuex'
import VSelect from 'vue-select'
// Cell Renderer
import CellRendererStatus from '@/components/general/datatable/cell-renderer/CellRendererStatus'
import CellRendererActions from '@/components/general/datatable/cell-renderer/CellRendererActions'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
import attendanceConsoleRepository from '../../../Repositories/attendanceConsoleRepository'
const ConsoleRepository = RepositoryFactory.get(
  'Attendance',
  'attendanceConsole'
)
export default {
  name: 'DataTable',
  props: {
    side_filters: {
      required: false
    },
    filter_type: {
      type: String,
      required: false
    },
    headers_prop: {
      type: Array,
      required: true
    },
    list_url_prop: {
      type: String,
      required: true
    },
    export_prop: {
      type: Boolean,
      default: true
    },
    filter_by_date: {
      type: String
    }
  },
  components: {
    AgGridVue,
    VSelect,
    // Cell Renderer
    CellRendererStatus,
    CellRendererActions
  },
  data () {
    return {
      unfinishedClass: '',
      unfinished_classes: [],
      loadingClasses:false,
      headerTitle: [],
      headerVal: [],
      headers: this.headers_prop,
      list_url: this.list_url_prop,
      selected_data: [],
      original_list: [],
      list: [],
      export_file: this.export_prop,
      searchQuery: '',
      // AgGrid
      gridColumnApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
        editable: false
        // filter: true
      },
      totalPages: 0,
      currentPage: 1,
      list_length: 0,
      closedAttendance: false,
      // Cell Renderer Components
      components: {
        CellRendererStatus,
        CellRendererActions
      }
    }
  },
  watch: {
    roleFilter (obj) {
      this.setColumnFilter('role', obj.value)
    },
    statusFilter (obj) {
      this.setColumnFilter('status', obj.value)
    },
    isVerifiedFilter (obj) {
      const val =
        obj.value === 'all' ? 'all' : obj.value === 'yes' ? 'true' : 'false'
      this.setColumnFilter('is_verified', val)
    },
    departmentFilter (obj) {
      this.setColumnFilter('department', obj.value)
    },
    currentPage (page) {
      const page_link = `?page=${page}`
      // this.getListData(page_link)
    },
    filter_type (newVal) {
      this.setColumnFilter('status', newVal)
    },
    side_filters (newVal) {
      if (newVal.length === 0) {
        this.getListData('')
      } else {
        ConsoleRepository.getFilteredStudents({school_id:this.authUser.school_id,
          page:this.page,
          filters:this.side_filters,
          date:this.filter_by_date
        }
        ).then((res) => {
          this.original_list = res.data.data
          this.list = res.data.data
          this.totalPages = res.data.last_page
          this.paginationPageSize = res.data.per_page
          this.currentPage = res.data.current_page
          this.list_length = res.data.total
        })
      }
    },
    filter_by_date (v) {
      if (v) this.getListDataAfterDateChange()
      else this.getListData(`?page=${this.current_page}`)
    }
  },
  computed: {
    ...mapGetters('auth', ['authUser']),
    paginationPageSize: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetPageSize()
        else return 10
      },
      set (v) {
        return v
      }
    },
    checkUnfinishedClasses () {
      return this.unfinished_classes instanceof Array
    }
  },
  methods: {
    formatJson (filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => {
        // Add col name which needs to be translated
        // if (j === 'timestamp') {
        //   return parseTime(v[j])
        // } else {
        //   return v[j]
        // }
        return v[j]
      })
      )
    },
    ///////////start CSV////////////
    exportToExcel () {
      this.headerTitle = []
      this.headerVal = []
      import('@/vendor/Export2Excel').then((excel) => {
        this.headers.forEach((element) => {
          if (element.headerName !== 'Actions') {
            this.headerTitle.push(element.headerName)
            this.headerVal.push(element.field)
          }
        })
        const list = this.list
        const data = this.formatJson(this.headerVal, list)
        excel.export_json_to_excel({
          header: this.headerTitle,
          data
        })
        this.clearFields()
      })
    },
    ///////////End CSV////////////
    setColumnFilter (column, val) {
      const filter = this.gridApi.getFilterInstance(column)
      let modelObj = null
      if (val !== 'all') {
        modelObj = {
          type: 'memberOf',
          filter: val
        }
      }
      filter.setModel(modelObj)
      this.gridApi.onFilterChanged()
    },
    downloadReport () {
      // TODO: assign date to this var it should match this style => [dd.mm.yyyy]
      const date = this.getDate()
      ConsoleRepository.downloadReport(date)
        .then((res) => {
          this.successMsg(this.$t('form.file_was_found_successfully'))
          window.location.href = res.downloadLink
        })
        .catch((err) => {
          this.errMsg(`${err.data.error.message}`)
        })
    },

    exportCSV () {
      // TODO: assign date to this var it should match this style => [dd.mm.yyyy]
      const date = this.filter_by_date || this.getDate()
      ConsoleRepository.exportReportCSV(this.side_filters, date)
        .then((res) => {
          this.successMsg(this.$t('form.file_was_found_successfully'))

          window.location.href = res

        })
        .catch((err) => {
          this.errMsg(`${err.data.error.message}`)

        })
    },

    getDate () {
      // TODO: make it only one instance of date var in the attendance Console.
      const date =  new Date()
      const dd = String(date.getDate()).padStart(2, '0')
      const mm = String(date.getMonth() + 1).padStart(2, '0')
      const yyyy = date.getFullYear()
      return `${yyyy}-${mm}-${dd}`
    },
    resetColFilters () {
      // Reset Grid Filter
      this.gridApi.setFilterModel(null)
      this.gridApi.onFilterChanged()
      // Reset Filter Options
      this.roleFilter = this.statusFilter = this.isVerifiedFilter = this.departmentFilter = {
        label: 'All',
        value: 'all'
      }
      this.$refs.filterCard.removeRefreshAnimation()
    },
    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
    },
    // Get Selected Data
    onGridReady (params) {
      this.gridApi = params.api
      this.columnApi = params.columnApi
    },
    getSelectedRows () {
      const data = this.gridApi.getSelectedRows()
      this.selected_data = data.map((elm) => elm.id)
    },
    // Fit columns width
    onFirstDataRendered () {
      this.gridApi.sizeColumnsToFit()
    },
    getListData (page) {
      this.VSLoading()
      ConsoleRepository.getFilteredStudents({school_id:this.authUser.school_id,
        page,
        filters:this.side_filters,
        date:this.filter_by_date
      })
        .then((res) => {
          this.original_list = res.data.data.map((item) => {
            if (item.status) return item
            else if (item.attendance_status) {
              item.status = item.attendance_status
              return item
            }
          })
          this.list = res.data.data.map((item) => {
            if (item.status) return item
            else if (item.attendance_status) {
              item.status = item.attendance_status
              return item
            }
          })
          this.totalPages = res.data.last_page
          this.paginationPageSize = res.data.per_page
          this.currentPage = res.data.current_page
          this.list_length = res.data.total
        })
        .catch((err) =>  console.log('err', err))
        .finally(this.stopVSLoading)
      // // Initialize array with keys of each column
      // const headerMaxWidth = this.headers.map((header) => {
      //   return header.field
      // })
      // // Fill in all the widths
      // const colWidths = {}
      // headerMaxWidth.map((header) => {
      //   colWidths[header] = []
      // })
      // this.list.map((col) => {
      //   headerMaxWidth.map((headerName) => {
      //     colWidths[headerName].push((`${col[headerName]  }`).length)
      //   })
      // })
      // // Assign the max to minWidth
      // this.headers = this.headers.map(({ minWidth, ...list }) => {
      //   const temp = {
      //     minWidth:
      //       Math.max(...colWidths[list.field]) * 12 > 100
      //         ? Math.max(...colWidths[list.field]) * 12
      //         : 100,
      //     ...list
      //   }
      //   return temp
      // })
    },
    checkTypeOfPagination () {
      if (this.filter_by_date) this.getListDataAfterDateChange()
      else this.getListDataAfterChange()
    },

    getListDataAfterChange () {
      this.VSLoading()
      ConsoleRepository.getFilteredStudents({school_id:this.authUser.school_id,
        page:`?page=${this.currentPage}`,
        filters:this.side_filters,
        date:this.filter_by_date
      }
        // this.authUser.school_id,
        // `?page=${this.currentPage}`,
        // this.side_filters,
        // this.filter_by_date
      )
        .then((res) => {
          this.original_list = res.data.data.map((item) => {
            if (item.status) return item
            else if (item.attendance_status) {
              item.status = item.attendance_status
              return item
            }
          })
          this.list = res.data.data.map((item) => {
            if (item.status) return item
            else if (item.attendance_status) {
              item.status = item.attendance_status
              return item
            }
          })
          this.totalPages = res.data.last_page
          this.paginationPageSize = res.data.per_page
          this.currentPage = res.data.current_page
          this.list_length = res.data.total
        })
        .catch((err) =>  console.log('err', err))
        .finally(this.stopVSLoading)
    },
    getListDataAfterDateChange () {
      this.VSLoading()
      ConsoleRepository.getFilteredStudents(
        {
          school_id:this.authUser.school_id,
          page:`?page=${this.currentPage}`,
          filters:this.side_filters,
          date:this.filter_by_date
        }
        // this.authUser.school_id,
        // `?page=${this.currentPage}`,
        // this.side_filters,
        // this.filter_by_date
      )
        .then((res) => {
          this.original_list = res.data.data.map((item) => {
            if (item.status) return item
            else if (item.attendance_status) {
              item.status = item.attendance_status
              return item
            }
          })
          this.list = res.data.data.map((item) => {
            if (item.status) return item
            else if (item.attendance_status) {
              item.status = item.attendance_status
              return item
            }
          })
          this.totalPages = res.data.last_page
          this.paginationPageSize = res.data.per_page
          this.currentPage = res.data.current_page
          this.list_length = res.data.total
        })
        .catch((err) =>  console.log('err', err))
        .finally(this.stopVSLoading)
    },
    getUnfinishedClasses () {

      this.loadingClasses = true
      ConsoleRepository.getUnfinishedClasses(this.authUser.school_id)
        .then((res) => {
          this.unfinished_classes =  res.message ||
          res.data.filter(el => !el.in_holiday)?.map((el) => ({
            name: el.name,
            count: el.count_unfinshed
          })) || []
          // console.log('unfinished_classes', this.unfinished_classes)
        })
        .catch((err) => {
          this.unfinished_classes = err.data.error.message
        })
        .finally(() => { this.loadingClasses = false })

    },
    selectUnfinishedClass (val) {
      this.$router.push({
        name: 'TakeAttendance',
        params: {
          class_name: val
        }
      })
    },
    // getListDataAfterChange () {
    //   DataTableRepository.listData(
    //     `${this.list_url}?page=${this.currentPage}`
    //   ).then((res) => {
    //     this.original_list = res.data.data
    //     this.list = res.data.data
    //     this.totalPages = res.data.last_page
    //     this.paginationPageSize = res.data.per_page
    //     this.currentPage = res.data.current_page
    //     this.list_length = res.data.total
    //   })
    // },
    clearFields () {
      this.fileName = ''
      this.cellAutoWidth = true
      this.selectedFormat = 'xlsx'
    },
    changeStatusFilter (filter) {
      this.list = this.original_list.filter((item) => {
        return item.status.toLowerCase() === filter.toLowerCase()
      })
    },
    submitAttendance () {
      this.VSLoading()
      attendanceConsoleRepository
        .closeAttendance(this.authUser.school_id)
        .then((res) => {
          const msg = res.response
          if (msg.includes('before'))  this.errMsg(msg)
          else this.successMsg(msg)
          this.closedAttendance = true
        })
        .catch((err) => this.errMsg(err.response))
        .finally(this.stopVSLoading)
    },
    openAttendance () {
      this.VSLoading()
      attendanceConsoleRepository
        .openAttendance(this.authUser.school_id)
        .then((res) => {
          const msg = res.response
          if (msg.includes('before'))  this.errMsg(msg)
          else this.successMsg(msg)
          this.closedAttendance = false
        })
        .catch((err) => this.errMsg(err.response))
        .finally(this.stopVSLoading)

    },
    checkCloseAttendance () {
      attendanceConsoleRepository
        .checkCloseAttendance(this.authUser.school_id)
        .then((res) => {
          this.closedAttendance = res.response
          this.$vs.loading.close()
        })
        .catch((err) => this.errMsg(err.message))
        .finally(this.stopVSLoading)
    }
  },
  mounted () {
    this.gridApi = this.gridOptions.api
    this.gridColumnApi = this.gridOptions.columnApi
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(
        '.ag-header-container'
      )
      header.style.left = `-${String(
        Number(header.style.transform.slice(11, -3)) + 9
      )}px`
    }
  },
  created () {
    this.getListData('')
    this.getUnfinishedClasses()
    this.checkCloseAttendance()
    this.$root.$on('row:status', this.getListDataAfterChange)
    this.$root.$on('row:filter', this.changeStatusFilter)
  }
}
</script>
<style lang="scss">
// #page-user-list {
//   .user-list-filters {
//     .vs__actions {
//       position: absolute;
//       right: 0;
//       top: 50%;
//       transform: translateY(-58%);
//     }
//   }
// }
</style>
