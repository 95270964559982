<template>
  <div class="vx-row">
    <div class="vx-col sm:w-1/2 md:mb-0 mb-4 w-full">
      <label v-if="showLabel">{{ $t("form.start_date") }}</label>
      <flat-pickr
        name="start_Date"
        :placeholder="showLabel ? null : $t('form.start_date') | text_formatter"
        v-model="start_date"
        class="w-full"
        @input="refilter"
        v-validate="withValidation ? 'required' : null"
        :config="{ minDate: minDate, maxDate: maxDate }"
        :disabled="disableDates"
      />
      <span class="text-danger text-sm" v-show="errors.has('start_Date')">
        {{ errors.first("start_Date") | text_formatter }}
      </span>
    </div>
    <div class="vx-col sm:w-1/2 md:mb-0 mb-4 w-full">
      <label v-if="showLabel">{{ $t("form.end_date") | text_formatter }}</label>
      <flat-pickr
        name="end_date"
        :placeholder="showLabel ? null : $t('form.end_date') | text_formatter"
        v-model="end_date"
        :config="{ minDate: start_date || minDate, maxDate: maxDate }"
        class="w-full"
        v-validate="withValidation ? 'required' : null"
        @input="refilter"
        :disabled="disableDates"
      >
      </flat-pickr>
      <span class="text-danger text-sm" v-show="errors.has('end_date')">
        {{ errors.first("end_date") | text_formatter }}
      </span>
    </div>
  </div>
</template>
<script>
// picker
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {

  name: 'date-filter',
  components: {flatPickr},
  props: {
    showLabel: {
      type: Boolean,
      default: true
    },
    dates:{
      type:Object,
      default:null
    },
    withValidation:{
      type: Boolean,
      default: false
    },
    maxDate:{
      type:String,
      default: null
    },
    minDate:{
      type:String,
      default: null
    },
    disableDates:{
      type:Boolean,
      default:false
    },
    setDatesFirstTime:{
      type:Boolean,
      default:false
    }
  },
  data () {
    return {
      start_date: null,
      end_date: null
    }
  },
  watch: {
    start_date (v) {

      if (v && !this.setDatesFirstTime && this.end_date) this.end_date = null
    },
    dates:{
      handler (v) {

        this.start_date = v.start_date
        this.end_date = v.end_date
      },
      deep:true
    }

  },
  methods: {
    refilter () {

      this.$emit('refilter', {
        start_date: this.start_date,
        end_date: this.end_date
      }
      )
    }
  },
  mounted () {
    if (this.dates) {
      this.start_date = this.dates.start_date
      this.end_date = this.dates.end_date
    }
  },
  created () {
    if (this.withValidation) {
      for (const key in this.fields) {
        if (Object.hasOwnProperty.call(this.fields, key)) {
          this.$parent.$validator.fields[key] = this.fields[key]

        }
      }
    }
  }
}
</script>

