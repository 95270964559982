<template>
  <div>
    <form-wizard
      ref="checkoutWizard"
      color="rgba(var(--vs-primary), 1)"
      :title="null"
      :subtitle="null"
      :finishButtonText="edit_mood ? $t('form.update') : $t('form.submit')"
      :startIndex="0"
      @on-complete="formSubmitted"
    >
      <!-- tab 1 basic information content -->
      <tab-content
        :title="$t('exam.basic_information')"
        class="mb-5 pl-0 pr-0"
        :before-change="validationStep1"
      >
        <vx-card
          :title="$t('exam.basic_information')"
          class="p-3 pt-6 pb-6 md:p-6"
        >
          <form
            @submit.prevent="false"
            @keyup.enter="validationStep1"
            data-vv-scope="step-1"
          >
            <div class="vx-row w-full">
              <!-- School -->
              <!-- <div class="vx-col lg:w-3/4 mb-5 w-full">
                <vs-select
                  :label="$t('form.choose_schools')"
                  v-model="exam.school"
                                                  class="w-full mb-3"

                  vs-name="school"
                  v-validate="'required'"
                  @change="exam.division_id = null"
                >
                  <vs-select-item
                    v-for="(item, index) in schools"
                    :key="index"
                    :value="item.id"
                    :text="item.name"
                  />
                </vs-select>
                <span class="text-danger">{{
                  errors.first("step-1.division")
                }}</span>
              </div> -->
              <!-- School -->

              <!-- Division -->
              <!-- <div class="vx-col lg:w-3/4 mb-5 w-full">
                <label                                 class="w-full mb-3"
>
                  {{ $t("form.choose_division") }}
                  <sup class="text-danger">*</sup>
                </label>
                <v-select
                  v-model="exam.division_id"
                                                  class="w-full mb-3"

                  v-validate="'required'"
                  @input="getAllGrades(); resetAllGrades();"
                  :options="divisions"
                  label="long_name"
                  :reduce="item => item.id"
                  :disabled="divisions.length > 0 ? false : true"
                >
                  <template v-slot:option="option">
                    {{ option.long_name }}
                  </template>
                </v-select>
                <span class="text-danger">{{
                  errors.first("step-1.division")
                }}</span>
              </div> -->
              <!-- Grade -->
              <div class="vx-col lg:w-3/4 mb-5 w-full">
                <label class="w-full mb-3">
                  {{ $t("form.choose_grade") }}
                  <sup class="text-danger">*</sup>
                </label>
                <v-select
                  v-model="exam.grade_id"
                  class="w-full"
                  name="grade"
                  v-validate="'required'"
                  @input="
                    getFilteredClasses(exam.grade_id);
                    resetAllClasses();
                  "
                  label="name"
                  :reduce="(item) => item.id"
                  :options="grades"
                  :disabled="grades.length > 0 ? false : true"
                >
                </v-select>
                <span class="text-danger" v-if="errors.has('step-1.grade')">{{
                  errors.first("step-1.grade")
                }}</span>
              </div>
              <!-- Class -->
              <div class="vx-col lg:w-3/4 mb-5 w-full">
                <label class="w-full mb-3">
                  {{ $t("form.choose_class") }}
                  <sup class="text-danger">*</sup>
                </label>

                <v-select
                  v-model="exam.class_ids"
                  class="w-full"
                  name="class"
                  multiple
                  v-validate="'required'"
                  @input="getFilteredSubjects(exam.class_ids)"
                  label="name"
                  :reduce="(item) => item.id"
                  :options="classes"
                  :disabled="classes.length > 0 ? false : true"
                >
                </v-select>
                <span class="text-danger" v-if="errors.has('step-1.class')">{{
                  errors.first("step-1.class")
                }}</span>
              </div>
              <!-- Subject -->
              <div class="vx-col lg:w-3/4 mb-5 w-full">
                <label class="w-full mb-3">
                  {{ $t("form.choose_subject") }}
                  <sup class="text-danger">*</sup>
                </label>
                <v-select
                  v-model="exam.subject_id"
                  class="w-full"
                  v-validate="'required'"
                  name="subject"
                  @input="
                    generateAutoName();
                    getCriterias();
                    getAllStudents();
                  "
                  label="name"
                  :options="subjects"
                  :reduce="(item) => item.id"
                  :disabled="subjects.length > 0 ? false : true"
                >
                </v-select>
                <span class="text-danger" v-if="errors.has('step-1.subject')">{{
                  errors.first("step-1.subject")
                }}</span>
              </div>

              <!-- has assesment cretieria -->
              <!-- <div class="vx-row w-full items-center mb-5 ml-5">
              <div class="vx-col lg:w-2/3 flex w-full">
                <vs-switch v-model="exam.has_assessment" />
                <label class="ml-5">{{ $t("exam.has_assessment") }}</label>
              </div>
            </div> -->

              <!-- Assesment -->
              <div
                v-if="assessments.length && hasAssessment"
                class="vx-col lg:w-3/4 mb-5 w-full"
              >
                <label class="w-full mb-3">
                  {{ $t("exam.choose_assessments") }}
                  <sup class="text-danger">*</sup>
                </label>
                <v-select
                  v-model="exam.criteria"
                  class="w-full"
                  name="criteria"
                  @input="generateAutoName()"
                  multiple
                  v-validate="'required'"
                  label="name"
                  :options="assessments"
                  :reduce="(item) => item.key"
                  :disabled="assessments.length > 0 ? false : true"
                >
                </v-select>
                <span
                  class="text-danger"
                  v-if="errors.has('step-1.criteria')"
                  >{{ errors.first("step-1.criteria") }}</span
                >
              </div>

              <!-- Type -->
              <div class="vx-col lg:w-3/4 mb-5 w-full">
                <div class="vx-row">
                  <div class="w-full vx-col">
                    <label class="w-full mb-3">
                      {{ $t("exam.questions_answers_type") }}
                      <sup class="text-danger">*</sup>
                    </label>
                    <v-select
                      v-model="exam.type_id"
                      class="w-full"
                      label="title"
                      v-validate="'required'"
                      name="questions_answers_type"
                      :options="exams_types"
                      :reduce="(item) => item.id"
                    >
                    </v-select>
                    <span
                      class="text-danger"
                      v-if="errors.has('step-1.questions_answers_type')"
                    >
                      {{
                        errors.first("step-1.questions_answers_type")
                          | text_formatter
                      }}</span
                    >
                  </div>
                  <!-- <div class="w-full vx-col sm:w-1/2 flex items-center mb-5">
                    <vs-switch v-model="exam.is_digital_answer" />
                    <label>{{ $t("exam.digital_answer") }}</label>
                  </div> -->
                </div>
              </div>
              <div class="vx-col lg:w-3/4 mb-5 w-full">
                <div class="vx-row">
                  <div class="w-full vx-col sm:w-1/2">
                    <vs-checkbox v-model="exam.hide_from_grading">
                      {{ $t("exam.include_in_gradebook") }}
                    </vs-checkbox>
                  </div>
                </div>
              </div>

              <!-- grading type -->
              <div class="vx-col mb-5 w-full" v-if="examType === 'Digital'">
                <vs-divider />
                <div class="vx-row vx-col lg:w-3/4 w-full">
                  <label class="w-full vx-col mb-4">
                    <strong>{{ $t("exam.grading_type") }}</strong>
                  </label>
                  <p class="mb-5 w-full vx-col text-sm">
                    "{{
                      $t("exam.Note_than_auto_grading_has_no_essay_question")
                    }}"
                  </p>
                  <div class="vx-col mb-5 sm:w-1/2 w-full">
                    <vs-radio
                      v-model="exam.is_auto_grading"
                      v-validate="'required'"
                      vs-name="grading_type"
                      name="grading_type"
                      @change="getAllQuestionTypes"
                      :vs-value="true"
                      >{{ $t("exam.auto_grading") }}</vs-radio
                    >
                  </div>
                  <div class="vx-col mb-5 sm:w-1/2 w-full">
                    <vs-radio
                      v-model="exam.is_auto_grading"
                      @change="getAllQuestionTypes"
                      vs-name="grading_type"
                      name="grading_type"
                      :vs-value="false"
                      >{{ $t("exam.manual_grading") }}</vs-radio
                    >
                  </div>
                  <div class="vx-col w-full">
                    <span
                      class="text-danger"
                      v-if="errors.has('step-1.grading_type')"
                      >{{
                        errors.first("step-1.grading_type") | text_formatter
                      }}</span
                    >
                  </div>
                </div>
              </div>

              <!-- points type -->
              <div class="vx-col mb-5 w-full" v-if="exam.type_id">
                <vs-divider />
                <div class="vx-row vx-col lg:w-3/4 w-full">
                  <label class="w-full vx-col mb-5">
                    <strong>{{ $t("exam.points_type") }} </strong>
                  </label>
                  <!-- if paper exam (type.id =1) -->
                  <template v-if="examType === 'Digital'">
                    <div
                      v-for="type in grading_types"
                      :key="'grading-' + type.id"
                      class="vx-col mb-5 sm:w-1/2 w-full"
                    >
                      <vs-radio
                        v-model="exam.exam_grading_type_id"
                        v-validate="'required'"
                        vs-name="points_type"
                        name="points_type"
                        :vs-value="type.id"
                        >{{ type.title }}</vs-radio
                      >
                    </div>

                    <div class="vx-col w-full mb-5">
                      <span
                        class="text-danger"
                        v-if="errors.has('step-1.points_type')"
                        >{{
                          errors.first("step-1.points_type") | text_formatter
                        }}</span
                      >
                    </div>
                  </template>
                </div>
                <div
                  v-if="examPointType === 'Total_Points_For_Exam'"
                  class="vx-row items-center mb-5"
                >
                  <label class="vx-col">{{ $t("exam.points") }}</label>
                  <vs-input
                    v-model="exam.points"
                    max="100"
                    min="1"
                    v-validate="'numeric|between:1,100|required'"
                    type="number"
                    name="points"
                    class="vx-col"
                  />
                  <span>{{ $t("exam.Must_be_between") }} 1-100</span>
                  <span
                    class="text-danger w-full mt-4"
                    v-if="errors.has('step-1.points')"
                    >{{ errors.first("step-1.points") | text_formatter }}</span
                  >
                </div>
              </div>

              <!-- Exam type -->
              <div class="vx-col mb-5 w-full">
                <vs-divider />
                <div class="vx-row vx-col lg:w-3/4 w-full">
                  <label class="w-full vx-col mb-5">
                    <strong>{{ $t("exam.exam_type") }} </strong>
                  </label>
                  <!-- if paper exam (type.id =1) -->
                  <template>
                    <div class="vx-col mb-5 sm:w-1/2 w-full">
                      <vs-radio
                        v-model="exam.exam_type"
                        v-validate="'required'"
                        vs-name="exam_type"
                        name="exam_type"
                        vs-value="normal"
                        >{{ $t("exam.normal") }}</vs-radio
                      >
                    </div>
                    <div class="vx-col mb-5 sm:w-1/2 w-full">
                      <vs-radio
                        v-model="exam.exam_type"
                        v-validate="'required'"
                        vs-name="exam_type"
                        name="exam_type"
                        vs-value="final"
                        >{{ $t("exam.final") }}</vs-radio
                      >
                    </div>
                    <div class="vx-col w-full mb-5">
                      <span
                        class="text-danger"
                        v-if="errors.has('step-1.exam_type')"
                        >{{
                          errors.first("step-1.exam_type") | text_formatter
                        }}</span
                      >
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </form>
        </vx-card>
      </tab-content>
      <!-- tab 2 access attendees content -->
      <tab-content
        :title="$t('exam.access_attendees')"
        :before-change="validationStep2"
      >
        <vx-card
          :title="$t('exam.access_attendees')"
          class="p-3 pt-6 pb-6 md:p-6"
        >
          <form
            @submit.prevent="false"
            @keyup.enter="validationStep2"
            data-vv-scope="step-2"
          >
            <!-- Exam access Type  -->
            <div class="vx-row w-full mb-base">
              <label class="vx-col w-full mb-5">
                <strong>{{ $t("exam.exam_access_type") }}</strong>
              </label>
              <!-- <vs-radio
                v-model="exam.is_private_access"
                vs-name="is_private_access"
                :vs-value="false"
                class="vx-col w-full mb-5"
                >{{ $t("exam.use_same_access_for_the_students") }}</vs-radio
              >
              <vs-radio
                v-model="exam.is_private_access"
                vs-name="is_private_access"
                :vs-value="true"
                class="vx-col w-full mb-5"
                >{{ $t("exam.create_private_access_code") }}</vs-radio
              > -->
              <div class="vx-col w-full flex flex-wrap">
                <vs-checkbox
                  class="mb-5"
                  v-model="exam.hide_students_identities"
                  :disabled="exam.is_auto_grading"
                />
                <label class="mb-5">{{
                  $t("exam.hide_students_identities")
                }}</label>
              </div>
              <div class="vx-col w-full flex flex-wrap">
                <vs-checkbox
                  class="mb-5"
                  v-model="exam.is_access_exam_period"
                  @input="assignDefaultValue(exam.is_access_exam_period)"
                />
                <label class="mb-5">{{
                  $t("exam.set_time_for_the_students_to_access_the_exams")
                }}</label>
              </div>
              <div class="w-full vx-col mt-1" v-if="exam.is_access_exam_period">
                <div class="flex items-center flex-wrap w-full">
                  <div class="vx-col md:w-1/2 lg:w-1/4 mt-2">
                    <vs-input-number
                      v-model="exam.access_exam_period_minutes"
                      :label="$t('basic.minutes')"
                      v-validate="'required'"
                      :max="60"
                      placeholder="1"
                      :min="1"
                    />
                  </div>
                  <p class="text-sm text-capitalize">
                    {{
                      $t(
                        "exam.change_the_time_for_students_to_access_the_exam_defautl_time_is_5_minutes"
                      )
                    }}
                  </p>
                  <!-- <div class="flex w-full items-center  ">
                  </div> -->
                  <!-- <div class="w-full vx-col mt-3">
                    <p class="text-sm text-capitalize">
                      {{
                        $t(
                          'exam.change_the_time_for_students_to_access_the_exam_defautl_time_is_5_minutes'
                        )
                      }}
                    </p>
                    <p class="text-sm text-capitalize">
                      {{
                        $t(
                          'exam.this_feature_is_not_valid_for_the_exam_with_fixed_period'
                        )
                      }}
                    </p>
                  </div> -->
                </div>
              </div>
            </div>
            <!-- Choose Exam attendees -->
            <div class="vx-row w-full">
              <vs-divider />
              <label class="vx-col w-full mb-5">
                <strong>{{ $t("exam.choose_exam_attendees") }} *</strong>
              </label>

              <datatable
                :users_prop="students"
                :table_head_prop="students_table_head"
                :multi_select_props="true"
                :show_selected_data="true"
                :set_attendees="setStudents"
                @get_selected="get_selected_attendees"
                class="vx-col w-full mb-5"
              >
              </datatable>
            </div>
          </form>
        </vx-card>
      </tab-content>
      <!-- tab 3 questions manager content -->
      <tab-content
        :title="$t('exam.questions_manager')"
        :before-change="validationStep3"
      >
        <form
          @submit.prevent="false"
          @keyup.enter="validationStep3"
          data-vv-scope="step-3"
        >
          <div v-if="examType === 'Digital'">
            <transition-group name="slide-fade" tag="div">
              <vx-card
                class="p-3 pt-6 pb-6 md:p-6 mb-base"
                v-for="(question, q_index) in exam.questions"
                :key="question.id"
              >
                <!-- question_title  -->
                <div class="title mb-10">
                  <div v-if="question_title_editable">
                    <label> {{ $t("form.enter_question_title") }} </label>
                    <vx-input-group class="mb-base md:w-1/2 w-full">
                      <vs-input
                        v-model="question.title"
                        vs-name="name"
                        v-validate="'required'"
                      />

                      <template slot="append">
                        <div class="append-text btn-addon">
                          <vs-button
                            color="success"
                            type="border"
                            icon="done"
                            @click="question_title_editable = false"
                          ></vs-button>
                        </div>
                      </template>
                    </vx-input-group>
                  </div>
                  <div class="flex flex-wrap items-center" v-else>
                    <h4 class="mr-10">
                      {{
                        question.title ||
                        `${$t("form.question")} ${q_index + 1}`
                      }}
                    </h4>
                    <vs-button
                      @click="question_title_editable = true"
                      type="line"
                      size="small"
                      class="p-3"
                    >
                      <vx-tooltip
                        v-if="question.title"
                        :text="$t('exam.edit_question_title')"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          :title="$t('exam.edit_question_title')"
                          svgClasses="h-4 w-4 hover:text-primary cursor-pointer"
                        />
                      </vx-tooltip>

                      <span v-else
                        >{{ $t("exam.give_title_for_the_question") }}
                      </span>
                    </vs-button>
                  </div>
                </div>
                <!-- question_type -->
                <div class="vx-row items-center mb-5">
                  <label class="vx-col lg:w-1/6 w-full"
                    >{{ $t("exam.questions_type") }}
                    <sup class="text-danger">*</sup>
                  </label>
                  <v-select
                    v-model="question.type_id"
                    label="type"
                    name="question_type"
                    v-validate="'required'"
                    :options="question_type"
                    :reduce="(item) => item.id"
                    class="vx-col lg:w-1/4 w-full"
                    @input="checkAnswersLength(question)"
                  >
                    <span
                      class="text-danger"
                      v-if="errors.has('step-3.question_type')"
                      >{{
                        errors.first("step-3.question_type") | text_formatter
                      }}
                    </span>
                  </v-select>
                </div>
                <!-- Description && answers -->
                <div class="vx-row mb-5" v-if="question.type_id">
                  <!-- Editor && options -->
                  <div
                    class="vx-col w-full"
                    :class="{
                      'md:w-3/4':
                        question_bank.length || question.type_id === 1,
                    }"
                  >
                    <!--  Essay => 1 -->
                    <div
                      class="mb-5 w-full"
                      v-if="question.type_id == 1"
                      id="essay_question"
                    >
                      <!--<vs-textarea
                    v-model="question.description"
                    @change="question.from_question_bank=null"
                    rows="20"
                  /> -->

                      <quill-editor
                        v-model="question.description"
                        v-validate="'required'"
                        class="w-full mb-3"
                        name="description"
                        @change="question.from_question_bank = null"
                      ></quill-editor>
                      <span class="text-danger">{{
                        errors.first("step-3.description")
                      }}</span>
                    </div>

                    <!-- Multiple Choices => 2  -->
                    <div class="mb-2 w-full" v-if="question.type_id == 2">
                      <!-- description -->
                      <div class="mb-3">
                        <quill-editor
                          v-model="question.description"
                          v-validate="'required'"
                          class="w-full"
                          name="description"
                          @change="question.from_question_bank = null"
                        ></quill-editor>
                        <span class="text-danger">{{
                          errors.first("step-3.description")
                        }}</span>
                      </div>
                      <!-- answers -->
                      <ul class="w-full mb-3">
                        <li
                          v-for="(answer, answerIndex) in question.answers"
                          :key="answer + q_index + answerIndex"
                        >
                          <div class="vx-row items-center mb-5">
                            <div class="vx-col md:w-1/6 w-full mb-3">
                              <vs-checkbox
                                v-model="answer.is_correct"
                              ></vs-checkbox>
                            </div>
                            <div class="vx-col md:w-5/6 w-full mb-3">
                              <quill-editor
                                v-validate="'required'"
                                :name="`answer_description_${answerIndex + 1}`"
                                v-model="answer.description"
                                class="w-full mb-3"
                              ></quill-editor>
                              <span
                                class="text-danger"
                                v-if="
                                  errors.has(
                                    `step-3.answer_description_${
                                      answerIndex + 1
                                    }`
                                  )
                                "
                                >{{
                                  errors.first(
                                    `step-3.answer_description_${
                                      answerIndex + 1
                                    }`
                                  ) | text_formatter
                                }}</span
                              >
                            </div>
                          </div>
                          <div
                            class="
                              btn-group
                              ml-auto
                              mr-0
                              d-block
                              mb-5
                              flex
                              items-center
                              justify-end
                            "
                          >
                            <vs-button
                              color="danger"
                              type="border"
                              @click="
                                removeMultipleChoices(q_index, answerIndex)
                              "
                              >{{ $t("form.remove") }}
                            </vs-button>
                            <vs-button
                              color="primary"
                              type="border"
                              v-if="answerIndex + 1 === question.answers.length"
                              @click="addAnotherMultipleChoices(q_index)"
                              >{{ $t("form.add_another") }}</vs-button
                            >
                          </div>
                        </li>
                      </ul>
                    </div>
                    <!-- Single Choice => 3  -->
                    <div class="w-full" v-if="question.type_id == 3">
                      <!-- description -->
                      <div class="mb-3">
                        <quill-editor
                          v-model="question.description"
                          v-validate="'required'"
                          name="question_description"
                          class="w-full mb-3"
                          @change="question.from_question_bank = null"
                        ></quill-editor>
                        <span class="text-danger">{{
                          errors.first("step-3.question_description")
                            | text_formatter
                        }}</span>
                      </div>
                      <!-- answers -->

                      <ul class="w-full mb-3">
                        <li
                          v-for="(answer, answerIndex) in question.answers"
                          :key="'answer' + q_index + answerIndex"
                        >
                          <div class="vx-row items-center mb-5">
                            <div class="vx-col lg:w-1/4 w-full mb-3">
                              <vs-switch
                                @change="resetAllAnswers(q_index, answerIndex)"
                                v-model="answer.is_correct"
                              ></vs-switch>
                            </div>
                            <div class="vx-col lg:w-3/4 w-full mb-3">
                              <quill-editor
                                v-validate="'required'"
                                v-model="answer.description"
                                class="w-full mb-3"
                                :name="`answer_description_${answerIndex + 1}`"
                              ></quill-editor>
                              <span
                                class="text-danger"
                                v-if="
                                  errors.has(
                                    `step-3.answer_description_${
                                      answerIndex + 1
                                    }`
                                  )
                                "
                                >{{
                                  errors.first(
                                    `step-3.answer_description_${
                                      answerIndex + 1
                                    }`
                                  ) | text_formatter
                                }}</span
                              >
                            </div>
                          </div>

                          <div
                            class="
                              btn-group
                              ml-auto
                              mr-0
                              d-block
                              mb-5
                              flex
                              items-center
                              justify-end
                            "
                          >
                            <vs-button
                              color="danger"
                              type="border"
                              @click="
                                removeMultipleChoices(q_index, answerIndex)
                              "
                              >{{ $t("form.remove") }}</vs-button
                            >
                            <vs-button
                              color="primary"
                              type="border"
                              v-if="answerIndex + 1 === question.answers.length"
                              @click="addAnotherMultipleChoices(q_index)"
                              >{{ $t("form.add_another") }}</vs-button
                            >
                          </div>
                        </li>
                      </ul>
                    </div>
                    <!-- True or False => 4 -->
                    <div class="mb-5 w-full" v-if="question.type_id == 4">
                      <ul>
                        <li>
                          <div class="vx-row items-center mb-5">
                            <div class="vx-col md:w-1/6 w-full mb-3">
                              <vs-row>
                                <vs-switch
                                  v-model="question.answers[0].is_correct"
                                />
                                <label>{{
                                  Boolean(question.answers[0].is_correct)
                                }}</label>
                              </vs-row>
                            </div>
                            <div class="vx-col md:w-5/6 w-full mb-3">
                              <quill-editor
                                v-validate="'required'"
                                @change="setAnswer(q_index)"
                                v-model="question.description"
                                :name="`question_description`"
                              ></quill-editor>
                              <span
                                class="text-danger"
                                v-if="errors.has(`step-3.question_description`)"
                                >{{
                                  errors.first(`step-3.question_description`)
                                    | text_formatter
                                }}</span
                              >
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!----question bank && answer limits -->
                  <div
                    class="vx-col w-full md:w-1/4"
                    v-if="question_bank.length || question.type_id === 1"
                  >
                    <template v-if="question_bank.length > 0">
                      <vs-button
                        icon="add"
                        class="mb-5"
                        @click="questionBanPopupActive = true"
                        >{{ $t("exam.add_from_question_bank") }}</vs-button
                      >
                      <vs-popup
                        classContent="popup-example"
                        :title="$t('exam.question_bank')"
                        :active.sync="questionBanPopupActive"
                      >
                        <ul>
                          <li
                            v-for="(q_b, q_b_index) in question_bank"
                            :key="q_b_index"
                            class="mb-5"
                          >
                            <vs-radio
                              v-model="question.from_question_bank"
                              vs-name="question_bank"
                              :vs-value="q_b.id"
                              v-if="q_b.type === question.type_id"
                              @change="question.description = q_b.description"
                            >
                              <p>{{ q_b.description }}</p>
                            </vs-radio>
                          </li>
                          <li>
                            <vs-radio
                              v-model="question.from_question_bank"
                              vs-name="question_bank"
                              :vs-value="null"
                              @change="question.description = null"
                            >
                              <p>{{ $t("exam.none_of_them") }}</p>
                            </vs-radio>
                          </li>
                        </ul>
                        <div class="flex justify-between flex-wrap w-full mb-5">
                          <!-- <vs-button
                          @click="cancelSelectQuestionBank"
                          color="danger"
                          type="filled"
                          class="mb-5"
                        >{{$t('form.cancel')}}</vs-button>-->
                          <vs-button
                            @click="questionBanPopupActive = false"
                            color="primary"
                            type="filled"
                            class="mb-5 mr-0 ml-auto"
                            >{{ $t("form.done") }}</vs-button
                          >
                        </div>
                      </vs-popup>
                    </template>
                    <template v-if="question.type_id === 1">
                      <h5 class="mb-4">{{ $t("exam.answer_limit") }}</h5>
                      <ul>
                        <li
                          v-for="(val, i) in answer_words_limit_counts"
                          :key="i"
                          class="mb-5"
                        >
                          <vs-radio
                            v-model="question.answer_words_limit"
                            :vs-name="`answer_words_limit-${q_index}`"
                            :vs-value="val"
                            >{{
                              `${
                                !val
                                  ? $t("exam.open_limit")
                                  : val + " " + $t("basic.words")
                              }`
                            }}</vs-radio
                          >
                        </li>

                        <li class="mb-5">
                          <div
                            class="mb-5 w-full"
                            v-if="question.customize_words_count"
                          >
                            <vs-input
                              v-model.number="question.answer_words_limit"
                              vs-name="count"
                              type="number"
                              :min="0"
                              placeholder="0"
                              :label="$t('basic.words')"
                              class="mr-5"
                            />
                          </div>
                          <vs-button
                            type="line"
                            class="m-auto block"
                            @click="changeCustomizeStatus(q_index)"
                            >{{
                              $t("exam.customize_number_of_words")
                            }}</vs-button
                          >
                        </li>
                      </ul>
                    </template>
                  </div>
                </div>
                <!-- More options -->
                <!-- <div class="vx-row items-center mb-5">
                <label class="vx-col lg:w-1/3 w-full">{{
                  $t(
                    "exam.Force_respondent_to_answer_this_question_when_first_time_displayed"
                  )
                }}</label>
                <vs-switch v-model="exam.force_answer_when_first_time" />
              </div> -->
                <!-- Question Attachment -->

                <div class="vx-col flex mb-3 w-full flex-warp items-center">
                  <label class="lg:w-1/6 md:w-full">{{
                    $t("exam.upload_attachment")
                  }}</label>
                  <vx-input-group class="md:w-full lg:w-1/2">
                    <vs-input
                      type="text"
                      v-if="
                        renderExamFile && question.attachments[0].attachment_url
                      "
                      v-model="question.attachments[0].attachment_url"
                      class="w-full"
                      readonly
                    />
                    <template slot="append">
                      <div class="append-text btn-addon">
                        <vs-input
                          type="file"
                          accept="image/*,.doc,.docx,application/msword,.pdf,application/pdf;capture=camera"
                          @change="uploadExamQuestionDoc(q_index, $event)"
                          v-model="files[q_index]"
                          class="w-full display_as_button"
                        />
                        <vs-button>
                          <feather-icon
                            icon="UploadIcon"
                            svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
                          />
                          {{ $t("basic.upload") }}
                        </vs-button>
                      </div>
                    </template>
                  </vx-input-group>
                </div>

                <!-- Attachment preview -->
                <div
                  class="w-full mt-4"
                  v-if="
                    question.attachments.length &&
                    question.attachments[0].url &&
                    renderExamFile
                  "
                >
                  <div class="w-full text center">
                    <single-file-previewer
                      :fileUrl="question.attachments[0].url"
                      :fileType="question.attachments[0].type"
                    ></single-file-previewer>
                  </div>

                  <div class="flex justify-center flex-wrap items-center">
                    <vs-button
                      color="danger"
                      class="my-4 mr-8"
                      @click="
                        deleteQuestionDocument(q_index, question.attachments[0])
                      "
                      >{{ $t("basic.Delete_file") }}
                    </vs-button>
                    <a
                      v-if="renderExamFile && question.attachments.length"
                      class="mt-4"
                      target="_blank"
                      ref="file"
                    >
                      <vs-button
                        class="mb-4 mr-0"
                        @click="downloadExamFile(question.attachments[0].url)"
                        >{{ $t("basic.Download_file") }}</vs-button
                      >
                    </a>
                  </div>
                </div>

                <!-- <div class="vx-row items-center mb-5">
                  <label class="vx-col lg:w-1/3 w-full"
                    >Set time to this question</label
                  >
                  <v-select
                    v-model="question.time"
                    :options="timeFrame"
                    placeholder="minutes"
                    class="vx-col lg:w-1/6 xs:w-1/6 w-full"
                  >
                  </v-select>
                </div> -->

                <div
                  v-if="examPointType === 'Points_Per_Question'"
                  class="flex justify-start items-center mt-4 flex-wrap"
                >
                  <label class="lg:w-1/6">{{ $t("exam.points") }}</label>
                  <vs-input
                    v-model="question.points"
                    vs-name="points"
                    class="lg:w-1/4"
                    type="number"
                    min="1"
                  ></vs-input>
                </div>

                <!-- Action buttons -->
                <div
                  class="
                    btn-group
                    ml-auto
                    mr-0
                    d-block
                    mb-5
                    flex
                    items-center
                    justify-end
                  "
                >
                  <vs-button
                    v-if="
                      question.answers.length === 0 &&
                      (parseInt(question.type_id) == 2 ||
                        parseInt(question.type_id) == 3)
                    "
                    color="primary"
                    type="border"
                    @click="addAnotherMultipleChoices(q_index)"
                    >{{ $t("form.add_new_answer") }}</vs-button
                  >
                  <vs-button
                    color="danger"
                    type="border"
                    @click="removeQuestion(q_index)"
                    v-if="exam.questions.length > 1"
                    >{{ $t("form.remove") }}</vs-button
                  >
                </div>

                <!-- /Action buttons -->
              </vx-card>
            </transition-group>
            <vx-card class="p-3 pt-6 pb-6 md:p-6 mb-base">
              <vs-button
                color="primary"
                class="mx-auto block"
                @click="addAnotherQuestion"
                >{{ $t("exam.Add_Question") }}</vs-button
              >
            </vx-card>
          </div>
          <!-- Upload exam attachment  -->
          <vx-card v-if="examType === 'Paper'" class="w-full items-center mb-5">
            <div class="flex flex-wrap mx-auto w-full">
              <label class="md:w-1/2">
                {{ $t("exam.upload_paper_exam") }}
              </label>
              <vx-input-group class="w-full mb-3">
                <vs-input
                  type="text"
                  v-model="exam.extra_doc_link"
                  v-if="renderExamFile"
                  class="w-full"
                  readonly
                />
                <template slot="append">
                  <div class="append-text btn-addon">
                    <vs-input
                      type="file"
                      @change="uploadExamDoc"
                      accept="image/*,.doc,.docx,application/msword,.pdf,application/pdf;capture=camera"
                      v-model="file"
                      class="w-full display_as_button"
                    />
                    <vs-button>
                      <feather-icon
                        icon="UploadIcon"
                        svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
                      />
                      {{ $t("basic.upload") }}
                    </vs-button>
                  </div>
                </template>
              </vx-input-group>
            </div>
            <div class="w-full mt-4" v-if="examType === 'Paper'">
              <div
                class="w-full text center"
                v-if="renderExamFile && exam.extra_doc_link"
              >
                <single-file-previewer
                  :fileUrl="exam.extra_doc_link"
                  :fileType="exam.extra_doc_type"
                ></single-file-previewer>
              </div>

              <div
                class="flex justify-center items-center flex-wrap"
                v-if="renderExamFile && exam.extra_doc_link"
              >
                <vs-button
                  class="my-4 mr-8"
                  color="danger"
                  @click="deleteExamDocumment()"
                  >{{ $t("basic.Delete_file") }}</vs-button
                >
                <a
                  class="mt-4"
                  style="display: block"
                  target="_blank"
                  ref="file"
                >
                  <vs-button
                    class="mb-4 mr-0"
                    @click="downloadExamFile(exam.extra_doc_link)"
                    >{{ $t("basic.Download_file") }}</vs-button
                  >
                </a>
              </div>
            </div>
          </vx-card>
        </form>
      </tab-content>
      <!-- tab 4 configuration content -->
      <tab-content
        :before-change="validationStep4"
        :title="$t('basic.configuration')"
      >
        <vx-card
          :title="$t('basic.configuration')"
          class="p-3 pt-6 pb-6 md:p-6"
        >
          <form
            @submit.prevent="false"
            @keyup.enter="validationStep4"
            data-vv-scope="step-4"
          >
            <!-- Exam Name -->
            <div class="vx-row w-full flex items-center mb-5 mb-10">
              <div class="vx-col lg:w-1/6 flex w-full">
                <label class="mr-5">
                  {{ $t("exam.exam_name") }}
                  <sup class="text-danger">*</sup>
                </label>
              </div>
              <div class="vx-col lg:w-1/2 w-full">
                <vx-input-group class="w-full">
                  <vs-input
                    v-model="exam.name"
                    name="exam_name"
                    v-validate="'required'"
                  />

                  <!-- <template slot="append">
                    <div class="append-text btn-addon">
                      <vs-button
                        @click="resetMaualName"
                        v-if="exam_auto_name"
                        >{{ $t('exam.reset_to_manual_name') }}</vs-button
                      >
                      <vs-button v-else @click="generateAutoName">{{
                        $t('exam.generate_auto_name')
                      }}</vs-button>
                    </div>
                  </template> -->
                </vx-input-group>
                <span
                  class="text-danger w-full"
                  v-if="errors.has('step-4.exam_name')"
                  >{{ errors.first("step-4.exam_name") | text_formatter }}</span
                >
                <!-- /Append Button -->
              </div>
            </div>

            <!-- choose_display_language  -->
            <!-- <div class="vx-row w-full items-center mb-5">
              <div class="vx-col lg:w-2/3 flex w-full">
                <vs-switch v-model="exam.show_display_languag" />
                <label class="ml-5">{{
                  $t("exam.choose_display_language")
                }}</label>
              </div>
            </div> -->
            <!-- randomize_questions  -->
            <div class="vx-row w-full items-center mb-5" v-if="isDigitalExam">
              <div class="vx-col lg:w-2/3 flex w-full">
                <vs-switch v-model="exam.is_randomize_questions" />
                <label class="ml-5">{{ $t("exam.randomize_questions") }}</label>
              </div>
            </div>

            <!-- Lockdown Functionality  -->
            <!-- <div class="vx-row w-full items-center mb-5">
              <div class="vx-col lg:w-2/3 flex w-full">
                <vs-switch v-model="exam.is_lockdown_functionality" />
                <label class="ml-5">{{
                  $t('exam.lockdown_functionality')
                }}</label>
              </div>
            </div> -->

            <!-- Show attempt counts   -->
            <!-- <div class="vx-row w-full items-center mb-5">
              <div class="vx-col lg:w-2/3 flex w-full">
                <vs-switch v-model="exam.show_attempt_counts" />
                <label class="ml-5">{{ $t("exam.show_attempt_counts") }}</label>
              </div>
            </div> -->

            <!-- Show results to parents  -->
            <!-- <div class="vx-row w-full items-center mb-5">
              <div class="vx-col lg:w-2/3 flex w-full">
                <vs-switch v-model="exam.show_result_to_parent" />
                <label class="ml-5">{{
                  $t("exam.show_results_to_parent")
                }}</label>
              </div>
            </div> -->

            <!-- Show results to student  -->
            <div class="vx-row w-full items-center mb-5" v-if="isDigitalExam">
              <div class="vx-col lg:w-2/3 flex w-full">
                <vs-switch v-model="exam.show_result_to_student" />
                <label class="ml-5">{{
                  $t("exam.show_results_to_student")
                }}</label>
              </div>
            </div>

            <!-- Show results to student  -->
            <div
              class="vx-row w-full items-center mb-5"
              v-if="isDigitalExam && exam.show_result_to_student"
            >
              <div class="vx-col lg:w-2/3 flex w-full">
                <vs-switch v-model="exam.is_show_answer" />
                <label class="ml-5">{{
                  $t("exam.show_answers_and_questions")
                }}</label>
              </div>
            </div>

            <!-- present_all_questions_of_the_exam_to_the_student  -->
            <!-- <div class="vx-row w-full items-center mb-5">
              <div class="vx-col lg:w-2/3 flex w-full">
                <vs-switch v-model="exam.present_all_questions_to_student" />
                <label class="ml-5">{{
                  $t('exam.present_all_questions_of_the_exam_to_the_student')
                }}</label>
              </div>
            </div> -->

            <!-- allow_review_answers   -->
            <!-- <div class="vx-row w-full items-center mb-5">
              <div class="vx-col lg:w-2/3 flex w-full">
                <vs-switch v-model="exam.allow_review_answers_after_finish" />
                <label class="ml-5">{{
                  $t("exam.allow_review_answers_after_finishing_the_test")
                }}</label>
              </div>
            </div> -->

            <!-- allow_answering_questions_in_any_order_and_backword_forword   -->
            <div class="vx-row w-full items-center mb-5" v-if="isDigitalExam">
              <div class="vx-col lg:w-2/3 flex w-full">
                <vs-switch
                  v-model="
                    exam.allow_answering_questions_in_any_order_and_backword_forword
                  "
                />
                <label class="ml-5">{{
                  $t(
                    "exam.allow_answering_questions_in_any_order_and_jumping_back_and_forth_between_questions"
                  )
                }}</label>
              </div>
            </div>

            <!-- Set Instructions for the exam    -->
            <!-- <div class="vx-row w-full items-center mb-5">
              <div class="vx-col lg:w-2/3 flex w-full">
                <vs-switch v-model="exam.set_instructions" />
                <label class="ml-5">{{
                  $t("exam.set_instructions_for_the_exam")
                }}</label>
              </div>
            </div> -->

            <!-- Set passing score  -->
            <!-- <div class="vx-row w-full">
              <div
                class="vx-col lg:w-1/4 md:w-1/2 w-full flex items-center mb-5"
              >
                <vs-switch v-model="exam.set_passing_score" />
                <label class="ml-5">{{ $t("exam.set_passing_score") }}</label>
              </div>
              <div
                class="vx-col sm:w-1/3 mb-5 flex sm:ml-0 ml-20"
                v-if="exam.set_passing_score"
              >
                <vs-input-number
                  v-model="exam.passing_score"
                  label="%"
                  :max="100"
                  :min="0"
                  placeholder="0"
                  class="label-right"
                />
                <span class="text-danger block">{{
                  errors.first("step-2.score")
                }}</span>
              </div>
            </div> -->

            <!-- Publish automatically   -->
            <!-- <div class="vx-row w-full items-center mb-5" v-if="exam.type_id != 3">
              <div class="vx-col lg:w-2/3 flex w-full">
                <vs-switch v-model="exam.auto_publish" />
                <label class="ml-5">{{
                  $t("form.publish_automatically")
                }}</label>
              </div>
            </div> -->
            <!-- Time Period -->
            <div class="vx-col mb-5 w-full">
              <vs-divider />
              <label class="w-full mb-3">
                <strong>{{ $t("form.time_period") }}</strong>
              </label>
              <!-- Date -->
              <div class="vx-col w-full space-x-12 my-5">
                <div class="w-1/2 inline-block">
                  <div class="flex">
                    <label class="w-1/2">
                      {{ $t("form.select_date") }}
                      <sup class="text-danger">*</sup>
                    </label>
                    <flat-pickr
                      name="date"
                      v-model="exam.date"
                      v-validate="'required'"
                      @on-change="getDailyAgenda"
                      :config="configdatePicker"
                      class="w-full md:mt-0 flatpickr-input"
                    />
                  </div>
                </div>
                <div class="inline-block">
                  <vs-button
                    type="line"
                    v-if="exam.date"
                    @click="showDailyAgenda = !showDailyAgenda"
                    >{{ $t("exam.exam_daily_agenda") }}</vs-button
                  >
                </div>
                <span class="text-danger" v-if="errors.has('step-4.date')">{{
                  errors.first("step-4.date")
                }}</span>
              </div>

              <!-- Fixed Time -->
              <!--  <div class="w-full vx-row">
               <vs-radio
                    v-model="exam.duration_type_id"
                    vs-name="duration_type"
                    v-validate="'required'"
                    :vs-value="duration_types[0].id"
                    class="w-full vx-col lg:w-1/6 mb-5"
                    >{{ $t('form.fixed_time') }}</vs-radio
                  >
                   </div>-->
              <div class="w-full vx-col mb-5" v-if="exam.duration_type_id == 1">
                <div class="vx-row">
                  <div class="flex w-full items-center vx-col md:w-1/2">
                    <label class="w-1/2"
                      >{{ $t("form.start_time") }}
                      <sup class="text-danger">*</sup></label
                    >
                    <div class="w-full md:mt-0">
                      <flat-pickr
                        v-model="exam.duration_fixed_time_start_time"
                        v-validate="'required'"
                        name="start_time"
                        class="w-full md:mt-0"
                        :config="configTimePicker"
                        @input="check15Min"
                      />

                      <span
                        class="text-danger w-full"
                        v-if="errors.has('step-4.start_time')"
                        >{{
                          errors.first("step-4.start_time") | text_formatter
                        }}</span
                      >
                    </div>
                  </div>
                  <div class="flex w-full items-center vx-col md:w-1/2">
                    <label class="w-1/2"
                      >{{ $t("form.end_time") }}
                      <sup class="text-danger">*</sup></label
                    >
                    <div class="w-full md:mt-0">
                      <flat-pickr
                        v-model="exam.duration_fixed_time_end_time"
                        v-validate="{
                          required: true,
                          is_not: exam.duration_fixed_time_start_time,
                        }"
                        name="end_time"
                        class="w-full md:mt-0"
                        :config="configTimePicker2"
                        @input="check15Min"
                        :disabled="!exam.duration_fixed_time_start_time"
                      />
                      <span
                        class="text-danger w-full"
                        v-if="errors.has('step-4.end_time')"
                        >{{
                          errors.first("step-4.end_time") | text_formatter
                        }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="flex w-full items-center vx-col mt-3"
                :class="`${1 ? 'lg:w-5/6' : 'lg:w-1/3'}`"
              >
                <p class="text-xs text-capitalize">
                  {{ $t("exam.exam_will_run_in_the_exact_selected_time") }}
                </p>
              </div>

              <div class="px-2 vx-row">
                <div
                  class="my-8 vx-col w-full lg:w-7/12"
                  v-if="showDailyAgenda"
                >
                  <div>
                    <vs-table class="mt-8" :data="dailyAgenda">
                      <template slot="header">
                        <h3>
                          {{ $t("exam.exam_daily_agenda") }} - {{ exam.date }}
                        </h3>
                      </template>
                      <template slot="thead">
                        <vs-th> {{ $t("basic.exam") }} </vs-th>
                        <vs-th> {{ $t("basic.class") }} </vs-th>
                        <vs-th> {{ $t("basic.subject") }} </vs-th>
                        <vs-th> {{ $t("teacher") }} </vs-th>
                        <vs-th> {{ $t("time") }} </vs-th>
                      </template>

                      <template slot-scope="{ data }">
                        <vs-tr v-for="(day, index) in dailyAgenda" :key="index">
                          <vs-td :data="day.name">
                            {{ day.name }}
                          </vs-td>
                          <vs-td :data="day.classes[0].name">
                            {{ day.classes[0].name }}
                          </vs-td>

                          <vs-td :data="day.subject.name">
                            {{ day.subject.name }}
                          </vs-td>

                          <vs-td :data="day.teacher.name">
                            {{ day.teacher.name }}
                          </vs-td>

                          <vs-td :data="day.duration_fixed_time_start_time">
                            {{ day.duration_fixed_time_start_time }} -
                            {{ day.duration_fixed_time_end_time }}
                          </vs-td>
                        </vs-tr>
                      </template>
                    </vs-table>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </vx-card>
      </tab-content>
      <!-- tab exam info content -->
      <tab-content :title="$t('exam.exam_preview')">
        <ExamPreview
          v-if="renderPreview"
          :exam_prop="exam"
          :edit_mood="edit_mood"
          :isExamForm="true"
          :totalPoints="totalPoints"
        />
      </tab-content>
      <!-- Footer -->
      <template slot="footer" slot-scope="props">
        <div
          class="
            flex flex-wrap
            md:justify-between
            justify-center
            items-center
            md:flex-row
            flex-col
            mt-6
          "
        >
          <vs-button
            class="mb-5"
            type="border"
            @click.native="props.prevTab()"
            v-if="props.activeTabIndex > 0"
            >{{ $t("basic.Previous") }}</vs-button
          >
          <vs-button
            color="gray"
            v-if="
              props.activeTabIndex > 0 &&
              exam.duration_fixed_time_start_time &&
              !edit_mood
            "
            @click.native="saveAsDraft"
            class="mb-5"
            >{{ $t("form.save_as_draft_and_close") }}</vs-button
          >
          <vs-button
            @click.native="props.nextTab()"
            class="mb-5"
            :class="{ 'ml-auto mr-0': props.activeTabIndex < 1 }"
            >{{
              props.isLastStep ? $t("basic.submit") : $t("basic.next")
            }}</vs-button
          >
        </div>
      </template>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Datatable from '@/components/general/datatable/DataTableWithoutActions'
// import Datepicker from 'vuejs-datepicker'
import FlatPickr from 'vue-flatpickr-component'
// datepicker
import vSelect from 'vue-select'

// Components
import ExamPreview from './ExamPreview.vue'

// Editor
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import { quillEditor } from 'vue-quill-editor'

// API
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const ExamRepo = RepositoryFactory.get('ExamModule', 'Exam')

// mixins
import { checkValidationMixin } from '@/mixins.js'
import examMixin from '../mixin'

export default {
  name: 'exam-form',
  props: {
    edit_mood: {
      type: Boolean
    }
  },
  mixins: [checkValidationMixin, examMixin],
  components: {
    FormWizard,
    TabContent,
    FlatPickr,
    ExamPreview,
    Datatable,
    quillEditor,
    vSelect
  },
  data () {
    return {
      divisions: [],
      grades: [],
      classes: [],
      subjects: [],
      assessments: [],
      exams_types: [],
      setStudents: [],
      question_type: [],
      grading_types_all: [],
      examStatus: [],
      duration_types: [
        {
          type: 'fixed time',
          id: 1
        },
        {
          type: 'period time',
          id: 2
        },
        {
          type: 'open time',
          id: 3
        }
      ],
      renderExamFile: true,
      renderPreview: false,
      exam: {
        duration_type_id: 1,
        exam_type: 'normal',
        hide_from_grading: true,
        allow_answering_questions_in_any_order_and_backword_forword: true,
        duration_fixed_time_start_time: '',
        date: '',
        is_auto_grading: null,
        show_result_to_student: null,
        exam_grading_type_id: null,
        access_exam_period_minutes: null,
        questions: []
      },
      // DATEPICKE
      disabledDates: {
        to: new Date(Date.now()).toLocaleString('en-US')
      },
      configdatePicker: {
        minDate: new Date(),
        maxDate: null,
        dateFormat: 'Y-m-d'
      },
      // attendees table_head_prop
      students: [],
      file: '',
      files: [],
      students_table_head: [
        {
          title: this.$t('basic.ID'),
          value: 'student_id'
        },
        {
          title: this.$t('basic.name'),
          value: 'name'
        },
        {
          title: this.$t('basic.email'),
          value: 'official_email'
        }
      ],
      // exam name
      exam_manual_name: '',
      exam_auto_name: false,
      // show upload input
      show_upload_input: false,
      // question title editable
      question_title_editable: false,
      // question_bank
      questionBanPopupActive: false,
      question_bank: [],
      answer_words_limit: 500,
      // anwer_limit_counts
      answer_words_limit_counts: [null, 100, 200, 400],
      customize_words_count: null,
      mimeTypes: {
        png: 'image',
        jpeg: 'image',
        jpg: 'image',
        mp4: 'video',
        pdf: 'pdf',
        docx: 'wordFile',
        doc: 'wordFile',
        xlsx: 'excelFile',
        xls: 'excelFile',
        ppt: 'powerPointFile',
        pptx: 'powerPointFile'
      },
      showDailyAgenda: false,
      dailyAgenda: [],
      configTimePicker: {
        enableTime: true,
        noCalendar: true,
        minDate: null,
        maxDate: null
      }
    }
  },
  mounted () {
    this.exam.is_auto_grading = false
    if (this.edit_mood) {
      this.getExam()
    } else {
      this.getAllDivisions()
      this.getAllGrades()
    }
    this.getAllgradingTypes()
    this.getAllQuestionTypes()
    this.getExamTypes()
    this.getExamStatus()
    // this.getFilteredClasses()
  },
  computed: {
    isDigitalExam () {
      return this.examType === 'Digital'
    },
    timeCount () {
      return this.time_based_hours * 60 + this.time_based_min
    },
    hasAssessment () {
      let hasAssessment = false
      if (this.classes.length && this.exam.class_ids) {
        hasAssessment =
          this.classes
            .find((x) => x.id === this.exam.class_ids[0])
            .name.slice(0, 3) === 'MYP'
      }
      return hasAssessment
    },
    // configTimePicker () {
    //   const data = {
    //     enableTime: true,
    //     noCalendar: true,
    //     minDate: this.checkCurrentDate,
    //     maxDate: null
    //   }
    //   return data
    // },
    configTimePicker2 () {
      const data = {
        enableTime: true,
        noCalendar: true,
        maxDate: null
      }
      if (this.exam.duration_fixed_time_start_time) {
        data.minDate = this.exam.duration_fixed_time_start_time
      } else {
        data.minDate = this.checkCurrentDate()
      }
      return data
    },
    timeFrame () {
      const frame = [...Array(60).keys()].map((i) => i + 1)
      return frame
    },
    grading_types () {
      return this.exam.is_auto_grading
        ? this.grading_types_all.filter((e) => e.id === 1)
        : this.grading_types_all
    },
    examPointType () {
      if (this.exam.exam_grading_type_id) {
        return this.grading_types_all.filter(
          (item) => item.id === this.exam.exam_grading_type_id
        )[0].type
      }
    },
    examType () {
      if (this.exam.type_id) {
        return this.exams_types.filter(
          (item) => item.id === this.exam.type_id
        )[0].type
      }
    },
    totalPoints () {
      if (
        this.exam.exam_grading_type_id ===
        this.getExamPointsTypeId('Points_Per_Question')
      ) {
        return this.exam.questions
          .map((question) => {
            return question.points
          })
          .reduce((accumulator, currentPoint) => accumulator + currentPoint)
      } else {
        return this.exam.points
      }
    },
    hasExamStarted () {
      const diff = this.daysBetweenDates(
        new Date(
          `${this.exam.date} ${this.exam.duration_fixed_time_start_time}`
        ),
        this.currentDate
      )
      return diff >= 0
    }
  },

  watch: {
    timeCount (val) {
      this.exam.time_based = val
    },
    'exam.type_id' (v) {
      // When I choose paper exam it must be choose total points for exam only.
      if (v === 2) {
        this.exam.exam_grading_type_id = this.getExamPointsTypeId(
          'Total_Points_For_Exam'
        )
        this.exam.is_auto_grading = false
      }
    },
    'exam.is_auto_grading' (val) {
      // When I choose auto grading it must be choose points per question .
      if (val) {
        this.exam.exam_grading_type_id = this.getExamPointsTypeId(
          'Points_Per_Question'
        )
        this.exam.hide_students_identities = false
        this.exam.show_result_to_student = false
      } else {
        // make show_result_to_student by default true if the exam is manual grading
        this.exam.show_result_to_student = true
      }
    },
    'exam.date' (val) {
      if (val === this.dateNow()) {
        this.$set(this.configTimePicker, 'minDate', this.checkCurrentDate())
      } else this.$set(this.configTimePicker, 'minDate', null)
    }
  },
  methods: {
    checkCurrentDate () {
      let date = new Date()
      date.setMinutes(date.getMinutes() + 5)
      date = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds}`
      return date
    },
    assignDefaultValue (val) {
      if (val) this.exam.access_exam_period_minutes = 5
      else this.exam.access_exam_period_minutes = null
    },
    getExamStatus () {
      ExamRepo.getExamStatus().then((res) => {
        this.examStatus = res.data
      })
    },
    getExamStatusId (val) {
      return this.examStatus.filter(
        (item) => item.status.toLowerCase() === val
      )[0].id
    },
    downloadExamFile (url) {
      this.downloadFile(url)
    },
    getDailyAgenda (selectedDates, dateStr) {
      if (!this.hasExamStarted) {
        this.exam.duration_fixed_time_start_time = ''
        this.exam.duration_fixed_time_end_time = ''
      }
      if (
        this.exam.duration_fixed_time_start_time &&
        this.exam.duration_fixed_time_end_time
      ) {
        this.check15Min()
      }
      const data = {
        class_ids: this.exam.class_ids,
        day: dateStr
      }
      ExamRepo.getDailyAgenda(data).then((res) => {
        this.dailyAgenda = res
      })
    },
    getExamPointsTypeId (val) {
      return this.grading_types_all.filter((item) => item.type === val)[0].id
    },
    check15Min () {
      return new Promise((resolve, reject) => {
        const data = {
          duration_fixed_time_start_time:
            this.exam.duration_fixed_time_start_time,
          duration_fixed_time_end_time: this.exam.duration_fixed_time_end_time,
          class_ids: this.exam.class_ids,
          grade_id: this.exam.grade_id,
          exam_id: this.exam.id,
          day: this.exam.date
        }
        if (!this.edit_mood) delete data.exam_id
        if (
          this.exam.duration_fixed_time_start_time &&
          this.exam.duration_fixed_time_end_time
        ) {
          ExamRepo.check15Min(data)
            .then((res) => {
              if (!res.validTime) {
                this.exam.duration_fixed_time_start_time = ''
                this.exam.duration_fixed_time_end_time = ''
                this.showDailyAgenda = true
                this.errMsg(
                  this.$t('exam.there_are_an_exam_already_in_this_time_range')
                )
              }
              resolve(!res.validTime)
            })
            .catch((error) => {
              console.log(error)
              reject(error)
            })
        }
      })
    },

    async deleteQuestionDocument (index, question) {
      const { url, attachment } = question
      const data1 = {
        attachment
      }
      const data2 = {
        file_path: url
      }
      const isDeletedFromS3 = await this.deleteDocumentFromS3(data2)
      if (isDeletedFromS3) {
        this.deleteMsg(this.$t('form.Delete_Document_Successfully'))
        this.$set(this.exam.questions[index].attachments, 0, {})
        this.files[index] = ''
      } else {
        this.errMsg(this.$t('form.failed_to_delete_documment'))
      }
      this.deleteDocumentFromDatabase(data1)
    },
    deleteDocumentFromDatabase (data) {
      return new Promise((resolve, reject) => {
        ExamRepo.deleteDocumentFromDatabase(data)
          .then(() => resolve(true))
          .catch(() => reject(false))
      })
    },
    deleteDocumentFromS3 (data) {
      return new Promise((resolve, reject) => {
        ExamRepo.deleteDocumentFromS3(data)
          .then(() => resolve(true))
          .catch(() => reject(false))
      })
    },
    async deleteExamDocumment () {
      const data1 = {
        extra_doc_name: this.exam.extra_doc_name
      }
      const data2 = {
        file_path: this.exam.extra_doc_link
      }
      const isDeletedFromS3 = await this.deleteExamDocumentFromS3(data2)
      if (isDeletedFromS3) {
        this.deleteMsg(this.$t('form.Delete_Document_Successfully'))
        this.$set(this.exam, 'extra_doc_link', null)
        this.$set(this.exam, 'extra_doc_type', null)
        this.$set(this.exam, 'extra_doc_name', null)
        this.file = ''
      } else {
        this.errMsg(this.$t('form.failed_to_delete_documment'))
      }
      this.deleteExamDocumentFromDatabase(data1)
      this.renderExamFile = false
      this.renderExamFile = true
    },
    deleteExamDocumentFromDatabase (data) {
      return new Promise((resolve, reject) => {
        ExamRepo.deleteExamDocumentFromDatabase(data)

          .then(() => resolve(true))
          .catch(() => reject(false))
      })
    },
    deleteExamDocumentFromS3 (data) {
      return new Promise((resolve, reject) => {
        ExamRepo.deleteExamDocumentFromS3(data)

          .then(() => resolve(true))
          .catch(() => reject(false))
      })
    },
    //------------ Filteration and get matched ----------
    // findMatched (id, parentArray) {
    //   const obj = parentArray.find(el => el.id === id)
    //   return obj

    // },
    checkAnswersLength (question) {
      if (question.answers.length) {
        return true
      } else {
        question.answers.push({ is_correct: false })
      }
    },
    changeCustomizeStatus (index) {
      this.exam.questions[index].customize_words_count =
        !this.exam.questions[index].customize_words_count
      if (
        this.answer_words_limit_counts.indexOf(
          this.exam.questions[index].answer_words_limit
        ) > -1
      ) {
        return true
      } else {
        this.exam.questions[index].answer_words_limit = null
      }
    },
    async getExam () {
      ExamRepo.getExam(this.$route.params.id)
        .then((res) => {
          this.exam = res.data
          // this.exam.division_id = res.data.class.division.id
          this.exam.grade_id = res.data.grade.id
          this.exam.class_ids = res.data.classes.id
          this.exam.type_id = res.data.type.id
          this.exam.duration_type_id = res.data.duration_type.id
          this.exam.status_id = res.data.status.id
          this.exam.points = res.data.total_points
          this.exam.is_auto_grading = Boolean(res.data.is_auto_grading)
          this.exam.students_ids = res.data.students.map((student) => {
            return student.id
          })
          this.exam.questions.forEach((q) => {
            q.type_id = q.type.id
            if (!q.attachments.length) q.attachments[0] = { attachment_url: '  ' }
          })
          this.exam.subject_id = res.data.subject.id
          this.exam.hide_from_grading = !res.data.hide_from_grading
          const criteria = res.data.criteria
          this.$nextTick(() => {
            this.exam.subject_id = res.data.subject.id
            this.exam.criteria = criteria
          })
          this.getCriterias()
          this.getAllDivisions()
          this.resetDateMinimum()
          // this.$set(this.configdatePicker, 'minDate', res.data.date)
          this.getAllGrades().then(() => {
            this.getAllStudents().then(() => {
              this.setStudents = this.students.filter((student) => {
                if (this.exam.students_ids.includes(student.id)) return student
              })
            })
          })
          this.getFilteredSubjects(this.exam.class_ids)
          this.getFilteredClasses(this.exam.grade_id)
          this.getAllQuestionTypes(false)
          this.check15Min()
        })
        .catch((error) => {
          throw error
        })
    },
    //
    resetDateMinimum () {
      const today = this.dateNow()
      this.$set(this.configdatePicker, 'minDate', today)
    },
    //------------ Get All Question Types ----------
    getAllQuestionTypes (state = true) {
      let auto_grading = ''
      if (this.exam.is_auto_grading !== undefined) {
        auto_grading = this.exam.is_auto_grading ? '?is_auto_graded=1' : ''
      }
      ExamRepo.getAllQuestionTypes(auto_grading)
        .then((res) => {
          this.question_type = res.data
          if (state) this.exam.questions = []
        })
        .catch((err) => {
          console.log('err', err)
        })
    },
    //------------ Get All Divisions ----------
    getAllDivisions () {
      ExamRepo.getAllDivisions(['1'])
        .then((res) => {
          this.divisions = res.data
        })
        .catch((err) => {
          console.log('err', err)
        })
    },
    //------------ Get All Exam Types ----------
    getExamTypes () {
      ExamRepo.getExamTypes()
        .then((res) => {
          this.exams_types = res.data
        })
        .catch((err) => {
          console.log('err', err)
        })
    },
    //------------ Get All Grades ----------
    getAllGrades () {
      return new Promise((resolve, reject) => {
        ExamRepo.getAllGrades()
          .then((res) => {
            this.grades = res.data
            resolve(res.data)
          })
          .catch((err) => {
            console.log('err', err)
            resolve(reject)
          })
      })
    },
    getCriterias () {
      this.$set(this.exam, 'criteria', [])
      const payload = {
        gradeId: this.exam.grade_id,
        subjectId: this.exam.subject_id,
        classIds: this.exam.class_ids
      }
      ExamRepo.getCriterias(payload)
        .then((res) => {
          res.data.forEach((ele) => {
            ele['name'] = `${ele['key']}-${ele['title']} ${
              this.exam.class_ids.length === 1 ? `(${ele['count']})` : ''
            }`
          })
          this.assessments = res.data
        })
        .catch((err) => {
          console.log('err', err)
        })
    },
    getAllgradingTypes () {
      ExamRepo.getAllgradingTypes()
        .then((res) => {
          this.grading_types_all = res.data
        })
        .catch((err) => {
          console.log('err', err)
        })
    },
    //------------ Reset All Grades ----------
    resetAllGrades () {
      this.exam.grade_id = null
      this.exam.class_ids = null
      this.exam.subject_id = null
    },
    //------------ Get All classes ----------
    getFilteredClasses (grade_id) {
      ExamRepo.getFilteredClasses(grade_id)
        .then((res) => {
          this.classes = res.data
        })
        .catch((err) => {
          console.log('err', err)
        })
    },
    //------------ Reset All Grades ----------
    resetAllClasses () {
      this.exam.class_ids = null
      this.exam.subject_id = null
    },
    //------------ Get All Subjects ----------
    getFilteredSubjects (class_ids) {
      this.exam.subject_id = null
      this.$set(this.exam, 'criteria', [])
      ExamRepo.getFilteredSubjects({ class_ids })
        .then((res) => {
          this.subjects = res.data
        })
        .catch((err) => {
          console.log('err', err)
        })
    },
    //------------ Reset All Grades ----------
    resetAllSubjects () {
      this.exam.subject_id = null
    },
    //------------ Get All Students ----------
    getAllStudents () {
      const data = {
        class_ids: this.exam.class_ids,
        subject_id: this.exam.subject_id,
        grade_id: this.exam.grade_id,
        division_id: this.grades.find(
          (grade) => grade.id === this.exam.grade_id
        )['division_id']
      }
      return new Promise((resolve, reject) => {
        ExamRepo.getAllStudents({ ...data })
          .then((res) => {
            this.students = []
            const students = res.data
            students.forEach((student) => {
              const studentObj = {
                name: student.fullName,
                id: student.id,
                official_email: student.official_email,
                student_id: student.student_code
              }
              this.students.push(studentObj)
            })
            resolve(true)
          })
          .catch((err) => {
            console.log('err', err)
            reject(err)
          })
      })
    },
    //------------ resetGradsAndClasses ----------
    resetGradsAndClasses () {
      this.exam.grade_id = null
      this.exam.student_class = null
    },
    //------------ Get selected attendees ----------
    get_selected_attendees (val) {
      this.exam.students_ids = val.map((obj) => obj.id)
    },
    // Remove ATTENDES
    remove_student (item) {
      this.exam.students_ids.splice(this.exam.students_ids.indexOf(item), 1)
    },
    // Remove ATTENDES
    uploadExamDoc (e) {
      const file = e.target.files[0]
      this.checkTextImage2MBFileValid(file).then((res) => {
        const data = new FormData()
        data.append('attachments[0]', file)
        const self = this
        ExamRepo.commonUploadFiles(data)
          .then((res) => {
            self.exam.extra_doc_link = res[0].attachment_url
            self.exam.extra_doc_type = res[0].type
            self.exam.extra_doc_name = res[0].attachment

            self.exam.attachment_type = this.mimeTypes[res[0].type]

            this.successMsg(this.$t('form.your_document_uploaded_successfully'))
            this.renderExamFile = false
          })
          .then(() => {
            this.renderExamFile = true
          })
      })
    },
    uploadExamQuestionDoc (questionIndex, e) {
      const file = e.target.files[0]
      this.checkTextImage2MBFileValid(file).then((res) => {
        const data = new FormData()
        data.append('attachments[0]', e.target.files[0])
        const self = this
        ExamRepo.commonUploadFiles(data)
          .then((res) => {
            this.renderExamFile = false
            self.exam.questions[questionIndex].attachments = res
            self.exam.questions[questionIndex].attachments[0].url =
              res[0].attachment_url
            this.successMsg(this.$t('form.your_document_uploaded_successfully'))
          })
          .then(() => (this.renderExamFile = true))
      })
    },

    //------------ Questions ----------

    addAnotherQuestion () {
      const questions = this.exam.questions
      const quest = {
        id: Math.random() * (this.exam.questions.length + 1),
        is_question_bank: false,
        from_question_bank: null,
        extra_doc_link: '',
        title: 'question',
        description: null,
        force_answer_when_first_time: false,
        is_extra_time: false,
        extra_time: 0,
        points: 1,
        attachments: [{ attachment_url: '  ' }],
        // Essay
        answer_words_limit: null,
        accept_upload_files: false,
        customize_words_count: null,

        // Multiple Choices && Single Choice && (True, False {only 2 answers})
        answers: [
          {
            is_correct: false
          }
        ],
        is_randomise_answers: false
      }
      questions.push(quest)
    },
    removeQuestion (i) {
      this.exam.questions.splice(i, 1)
      this.files.splice(i, 1)
    },
    setAnswer (q_index) {
      this.exam.questions[q_index].answers[0].description = 'true or false'
    },
    resetAllAnswers (q_index, answerIndex) {
      if (this.exam.questions[q_index].answers[answerIndex]) {
        this.exam.questions[q_index].answers.forEach((answer, index) => {
          if (index != answerIndex) {
            answer.is_correct = false
          }
        })
      }
    },
    doneSelectQuestionBank () {
      this.questionBanPopupActive = false
    },
    addQuestionBank (i) {
      const question = this.exam.questions[i]
      this.question_bank.push({
        description: question.description,
        type: question.type,
        id: this.question_bank.length
      })
    },

    //------------ Generate Auto Name ----------
    generateAutoName () {
      // this.exam_manual_name = `${this.exam.name}`
      this.exam_auto_name = true
      // this.exam.name = `${this.exam.class_ids} ${this.exam.subject_id}`
    },
    resetMaualName () {
      this.exam.name = ''
      this.exam_auto_name = false
    },
    //------------ Upload  ----------
    successUpload () {
      // alert('successUpload')
    },
    setFileToData (event) {
      this.exam.extra_doc = event.target.files
    },
    deletFile () {
      this.exam.extra_doc = null
    },
    //------------ Validation ----------
    //Error Message

    // STEP 1
    validationStep1 () {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll('step-1').then((result) => {
          if (result) {
            window.scrollTo(0, 0)
            resolve(true)
          } else {
            reject('error validation')
            this.errMsg()
          }
        })
      })
    },
    // STEP2
    validationStep2 () {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll('step-2').then((result) => {
          if (
            result &&
            this.exam.students_ids !== undefined &&
            this.exam.students_ids.length
          ) {
            resolve(true)
          } else {
            reject('error validation')
            this.errMsg()
          }
        })
      })
    },
    // STEP3
    validationStep3 () {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll('step-3').then((result) => {
          let resolved = true
          if (this.examType === 'Digital') {
            if (this.exam.questions.length) {
              this.exam.questions.forEach((q) => {
                if (q.type_id == '2' || q.type_id == '3') {
                  // q.type id ( 2 , 3 ) refere to (multi , single) select question
                  let hasTrue = false
                  q.answers.forEach((answer) => {
                    if (answer.is_correct) {
                      hasTrue = true
                    }
                  })
                  if (!hasTrue || q.answers.length < 2) {
                    resolved = false
                  }
                }
                // This condation for checking the type of exam points and validate it
                // if the type is point per question
                if (this.examPointType === 'Points_Per_Question') {
                  if (!(parseInt(q.points) > 0)) {
                    resolved = false
                  }
                }
                q.points = +q.points
              })
            } else {
              resolved = false
            }
          } else {
            // else  refere to paper exam
            resolved = Boolean(this.exam.extra_doc_link)
          }
          if (result && resolved) {
            this.exam.refresh = true
            resolve(true)
          } else {
            reject('error validation')
            this.warningMsg(
              this.$t('form.Please_fill_all_questions_data_first')
            )
          }
        })
      })
    },
    // STEP4
    async validationStep4 () {
      const checkOverLap = await this.check15Min()
      return new Promise((resolve, reject) => {
        this.$validator.validateAll('step-4').then((result) => {
          if (checkOverLap) reject('error validation')
          if (result) {
            this.renderPreview = true
            resolve(true)
          } else {
            reject('error validation')
            this.errMsg()
          }
        })
      })
    },

    removeMultipleChoices (questionIndex, answerIndex) {
      this.exam.questions[questionIndex].answers.splice(answerIndex, 1)
    },
    addAnotherMultipleChoices (questionIndex) {
      const answer = {
        is_correct: false
      }
      this.exam.questions[questionIndex].answers.push(answer)
    },
    // saveAsDraft
    saveAsDraft () {
      if (this.edit_mood) return this.updateExam('draft')
      this.exam.general_status = 'draft'
      this.exam.status_id = this.getExamStatusId('draft')
      this.exam.questions.map((question) => {
        if (!(Object.values(question.attachments[0]).length > 1)) question.attachments = []
      })
      if (!this.exam.is_access_exam_period) delete this.exam.access_exam_period_minutes
      this.exam.hide_from_grading = !this.exam.hide_from_grading
      ExamRepo.createExam(this.exam)
        .then((res) => {
          this.successMsg(this.$t('exam.Your_Exam_is_saved_as_draft'))
          this.$router.push({ name: 'exams' })
        })
        .catch((err) => {
          console.log('err', err)
          this.errMsg(err)
        })
    },

    preventSubmission (e) {
      e.preventDefault()
    },
    // SubmitForm
    formSubmitted () {
      if (this.edit_mood === false) {
        this.createExam()
      } else {
        this.updateExam('active')
      }
    },
    createExam () {
      this.exam.general_status = 'active' // active || draft || suspend || archive
      this.exam.status_id = this.getExamStatusId('active')
      if (!this.exam.is_access_exam_period) delete this.exam.access_exam_period_minutes
      this.exam.criteria = this.exam.criteria.length ? this.exam.criteria : null
      this.exam.questions.map((question) => {
        if (!(Object.values(question.attachments[0]).length > 1)) question.attachments = []
      })
      this.exam.hide_from_grading = !this.exam.hide_from_grading
      ExamRepo.createExam(this.exam)
        .then(() => {
          this.successMsg(this.$t('exam.your_exam_is_saved'))
          this.$router.push({ name: 'exams' })
        })
        .catch((err) => {
          console.log('err', err)
          this.errMsg(err)
        })
    },
    updateExam (status_id) {
      const exam = this.exam
      ;[
        'class',
        'duration_type',
        'students',
        'status',
        'subject',
        'type',
        'teacher'
      ].forEach((key) => {
        delete exam[key]
      })
      exam.questions.forEach((q) => {
        delete q['type']
        if (q.type_id == 1) {
          delete q['answers']
        }
        if (!q.attachments || !(Object.values(q.attachments[0]).length > 1)) q.attachments = []
      })
      Object.keys(exam).forEach((key) => exam[key] === null && delete exam[key])
      exam.status_id = this.getExamStatusId(status_id)
      if (!exam.is_access_exam_period) delete exam.access_exam_period_minutes
      this.exam.hide_from_grading = !this.exam.hide_from_grading
      ExamRepo.updateExam(this.exam.id, exam)
        .then(() => {
          this.successMsg(this.$t('exam.your_exam_is_saved'))
          this.$router.push({ name: 'exams' })
        })
        .catch((err) => {
          console.log('err', err)
          this.errMsg(err)
        })
    }
  }
}
</script>

<style lang="scss" scope>
[dir="ltr"] .vs-radio--label {
  margin-left: 37px !important;
}
</style>
<style lang="scss">
.vue-form-wizard .wizard-tab-content {
  padding: 30px 0 10px;
}
.display_as_button {
  position: absolute;
  opacity: 0;
  z-index: 1;
}
[dir] .ql-editor {
  height: 100px;
}
#essay_question .ql-editor {
  height: 200px;
}
</style>
