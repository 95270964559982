<template>
  <div>
    <academic-year-form :id_Create_sub="id_Create_sub"></academic-year-form>
  </div>
</template>
<script>
import academicYearForm from './components/academicYearForm'

export default {
  name:'CreateSubMasterYears',
  components:{
    academicYearForm

  },
  created () {
    this.id_Create_sub = this.$route.params.id

  },
  data () {
    return {
      id_Create_sub : null
    }
  }
}
</script>
