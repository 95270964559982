export default {
  S: 'S',
  Name: 'Name',
  Address: 'Address',
  ClickForGPSAddress: 'Use GPS',
  UploadLogo: 'Upload Logo',
  DragAndDrop: 'DRAG & DROP to upload new PNG logo',
  Contacts: 'Branch Contacts',
  details: 'Branch Details',
  General: 'General',
  Reception: 'Reception ',
  Admission: 'Admission',
  Accounting: 'Accounting',
  Submit: 'Submit',
  ShortName: 'Short Name',
  LongName: 'Long Name',
  Description: 'Description',
  Internet: 'Internet',
  website: 'website',
  Facebook: 'Facebook',
  Instagram: 'Instagram',
  ContactGeneral: 'General Contact',
  InstitutionCreate: 'Create New Institution',
  Logo: 'Logo',
  Save: 'Save',
  Edit: 'Edit',
  Cancel: 'Cancel',
  BranchName: 'Branch Name',
  user: 'User',
  pleaseClickForGetYourCurrentLocation: 'please click for get your current location',
  BranchExist: 'branch name has already been taken',
  longNameError: 'long name has already been taken',
  shortNamError: 'short name has already been taken'
}
