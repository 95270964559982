import {mapGetters} from 'vuex'
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker'
import Service from '../../services/unit-planner'


export default {
  name: 'standAloneUnitModal',
  components: {
    'v-select': vSelect,
    Datepicker
  },
  data () {
    return {
      restService: Service,
      storeUnSubscriber: null,
      modalProps: {
        width: '600px',
        height: 'auto',
        scrollable: true
      },
      modalName: 'standAloneUnitModal',
      datePickerSettings: {
        format: 'd MMM',
        disabledDates: {
          to: null, // Disable all dates up to specific date
          from: null // Disable all dates after specific date
        },
        dateValue: null
      },
      prevEndDate: null,
      keyConcepts: [],
      relatedConceptsSubjects: [],
      relatedConcepts: [],
      atlSkills: [],
      learnerProfile: [],
      unitItems: {
        central_idea: '',
        key_concepts: [],
        related_concepts: [],
        atl_skills: [],
        learner_profile: [],
        lines_of_inquiry: '',
        start_date: null,
        end_date: null,
        is_disabled: false,
        is_year_long: true,
        extra_sections: []
      },
      unitDetails: null,
      yearGroupDetails: null,
      isValidData: true,
      validation: {
        startDate: null,
        endDate: null,
        extraSectionTitle: null,
        yearLong: null
      },
      customEvents: [{eventName: 'openStandAloneUnitModal', callback: this.loadData}]
    }
  },
  created () {
    ////////////////////////////////////////////////////
    //**          Custom Event Listener           ** //
    //////////////////////////////////////////////////
    this.customEvents.forEach(function (customEvent) {
      // eslint-disable-next-line no-undef
      $sharedEvent.listen(customEvent.eventName, customEvent.callback)
    })
  },
  mounted () {
    //this.storeSubscriberLogic();
  },
  destroyed () {
    ////////////////////////////////////////////////////
    //**      Remove Custom Event Listener        ** //
    //////////////////////////////////////////////////
    this.customEvents.forEach(function (customEvent) {
      // eslint-disable-next-line no-undef
      $sharedEvent.off(customEvent.eventName, customEvent.callback)
    })
  },

  methods: {
    showModal () {
      this.$modal.show(this.modalName)
    },
    hideModal () {
      this.$modal.hide(this.modalName)
    },
    async getDropDownsData () {
      //** Show Loader **//
      this.$vs.loading()

      //** Get KeyConcepts **//
      await this.restService.getKeyConcepts()
        .then(res => this.keyConcepts = res.data)
        .catch(err => console.error(err))

      //** Get RelatedConcepts Subjects**//
      await this.restService.getRelatedConcepts()
        .then(res => this.relatedConceptsSubjects = res.data)
        .catch(err => console.error(err))

      //** Get ATL Skills **//
      await this.restService.getAtlSkills()
        .then(res => this.atlSkills = res.data)
        .catch(err => console.error(err))

      //** Get ATL Skills **//
      await this.restService.getLearnerProfile()
        .then(res => this.learnerProfile = res.data)
        .catch(err => console.error(err))

      //** Hide Loader **//
      this.$vs.loading.close()
    },
    async getRelatedConceptsData (item) {
      this.$vs.loading()

      await this.restService.getRelatedConceptsData(item.id)
        .then(res => this.relatedConcepts = res.data)
        .catch(err => console.error(err))

      this.$vs.loading.close()
    },
    loadData (data) {

      this.resetProps()
      // binding Data of Dates**//
      this.datePickerSettings.disabledDates = {
        from: new Date(this.selectedAcademicYear.end_date), // Disable all dates after specific date
        to: new Date(this.selectedAcademicYear.start_date) // Disable all dates up to specific date
      }
      this.datePickerSettings.dateValue = new Date(this.selectedAcademicYear.start_date)

      //** Set Data **//
      this.unitDetails = this.cloneItem(data.unitAcademic)

      this.unitItems = {
        /*start_date: this.unitDetails.start_date,
        end_date: this.unitDetails.end_date,
        is_disabled: this.unitDetails.is_disabled,
        is_year_long: this.unitDetails.is_year_long,*/
        start_date: null,
        end_date: null,
        is_disabled: this.unitDetails.is_disabled,
        is_year_long: this.unitDetails.is_year_long,
        key_concepts: this.cloneItem(this.unitDetails.key_concepts.options),
        related_concepts:this.cloneItem(this.unitDetails.related_concepts.options),
        atl_skills: this.cloneItem(this.unitDetails.atl_skills.options),
        learner_profile: this.cloneItem(this.unitDetails.learner_profile.options),
        central_idea: this.unitDetails.central_idea.value,
        lines_of_inquiry: this.unitDetails.lines_of_inquiry.value,
        extra_sections: this.unitDetails.extra_sections
      }

      console.log('this.unitItems>>>', this.unitItems)
      this.getDropDownsData()
        .then(() => {
          this.showModal()
        })
    },
    resetProps () {
      this.unitItems = {
        central_idea: '',
        key_concepts: [],
        related_concepts: [],
        atl_skills: [],
        learner_profile: [],
        lines_of_inquiry: '',
        start_date: null,
        end_date: null,
        is_disabled: false,
        is_year_long: false,
        extra_sections: []
      }
      this.unitDetails = null
      this.validation = {
        startDate: null,
        endDate: null,
        extraSectionTitle: null,
        yearLong: null
      }
      this.isValidData = true
    },
    addExtraSection () {
      this.unitItems.extra_sections.push({name: '', value: ''})
    },
    removeExtraSection (index) {
      this.$delete(this.unitItems.extra_sections, index)
    },
    checkDate () {
      if (!this.unitItems.is_year_long) {
        //** when set year long get we set endDate from academic end date **//
        this.prevEndDate = this.unitItems.end_date
        this.unitItems.end_date = new Date(this.selectedAcademicYear.end_date)
        this.validation.yearLong = null

      } else {
        this.unitItems.end_date = this.prevEndDate
        this.validation.yearLong = null
      }
      //** Validate dates **//
      this.dateValidation()
    },
    dateValidation () {
      const startDate = this.getDateOnly(this.unitItems.start_date)
      const endDate = this.getDateOnly(this.unitItems.end_date)

      //** Check if End Date Greater That start Date **//
      if (this.unitItems.start_date && this.unitItems.end_date) {
        if (endDate <= startDate) {
          this.validation.endDate = 'end date must be greater than start date'
        } else if (endDate > startDate) {
          this.validation.endDate = null
        }
      }
    },
    extraSectionsValidation () {
      for (const item of this.unitItems.extra_sections) {
        if (!item.name) {
          this.validation.extraSectionTitle = 'this filed is required'
          break
        } else if (item.name) {
          this.validation.extraSectionTitle = null
        }
      }
    },
    checkValidation () {
      this.isValidData = true
      //** run Validation Methods **//
      this.extraSectionsValidation()
      this.dateValidation()

      //** check validation **//
      for (const [key, value] of Object.entries(this.validation)) {
        if (value) {
          console.log('Validation Error', value)
          this.isValidData = false
        }
      }
      return this.isValidData
    },
    prepareSavingData () {
      let savingObj
      this.unitDetails.items = this.cloneItem(this.unitItems)
      savingObj = {
        description: this.unitDetails.description,
        central_idea: this.unitDetails.items.central_idea,
        key_concepts: this.unitDetails.items.key_concepts.map(item => item.id),
        related_concepts: this.unitDetails.items.related_concepts.map(item => item.id),
        atl_skills: this.unitDetails.items.atl_skills.map(item => item.id),
        learner_profile: this.unitDetails.items.learner_profile.map(item => item.id),
        lines_of_inquiry: this.unitDetails.items.lines_of_inquiry,
        extra_sections: this.unitDetails.items.extra_sections,
        is_disabled: this.unitDetails.items.is_disabled,
        start_date: (this.unitDetails.items.start_date) ? this.unitDetails.items.start_date.split('T')[0] : null,
        end_date: (this.unitDetails.items.end_date) ? this.unitDetails.items.end_date.split('T')[0] : null,
        is_year_long: this.unitDetails.items.is_year_long
      }
      return savingObj
    },
    storeSaving (unitData) {
      $sharedEvent.fire('updateStandAloneOptions', unitData)
      this.$vs.notify({
        title: 'Unit Modification',
        text: 'Saved Successful',
        color: 'success',
        position: 'bottom-right'
      })
      this.cancel()
    },
    save () {
      this.$validator.validateAll().then(result => {
        if (result && this.checkValidation()) {
          this.restService.updateYearGroupUnit(this.unitDetails.unit_academic_id, this.prepareSavingData())
            .then(res => {
              this.storeSaving(res.data)
            })
            .catch(err => {
              console.error(err)
              this.$vs.notify({
                title: 'Server Error',
                text: err.message,
                color: 'danger',
                position: 'bottom-right'
              })
            })
        } else {
          this.$vs.notify({
            title: 'Validation Error',
            text: 'invalid data please correct it',
            color: 'danger',
            position: 'top-right'
          })
        }
      })
    },
    cancel () {
      this.resetProps()
      this.hideModal()
    }
  },
  computed: {
    ...mapGetters({selectedAcademicYear: 'getCurrentAcademicYearPlanner'})
  }
}
