import axios from 'axios'

const resource = 'grading/boundaries'
export default {
  addNewBoundary(payload) {
    return axios.post(`${resource}/create`, payload)
  },
  showData(id) {
    return axios.get(`${resource}/${id}/show`)
  },
  deleteLimit(boundaryId, limitId) {
    return axios.delete(`${resource}/${boundaryId}/limits/${limitId}`)
  },
  editBoundary(boundaryId, data) {
    return axios.post(`${resource}/${boundaryId}/edit`, data)
  },
  getBoundaryByStudent(studentId, subjectId, payload) {
    // payload : total_score_percentage ( of criteria )
    return axios.post(`${resource}/${studentId}/${subjectId}`, payload)
  },
  getAllBoundaryByStudent(studentId) {
    return axios.get(`${resource}/${studentId}`)
  },
  getDivisionSubjects(gradeIds) {
    return axios.get(`${resource}/grades/subjects`, {
      params: {grades: gradeIds}
    })
  }
}
