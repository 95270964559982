export default {
  wallet: 'wallets',
  division: 'Division',
  section: 'Section',
  grade: 'Grade',
  class: 'Class',
  addFund: 'Add Fund',
  refundFund: 'Refund Fund',
  funds: 'Funds',
  transaction: 'Transactions',
  suspend: 'Suspend',
  addNewFund: 'Add New Fund',
  setDailyLimit: 'Set Daily Limit',
  currency: 'EGP',
  refundAlert: 'You are about refund the amount of',
  value: 'Value',
  fundHistory: 'Fund History',
  addFundSuccessfully: 'Add Fund Successfully',
  refundSuccessfully: 'Refund Successfully',
  limitChangedSuccessfully: 'Limit Changed Successfully',
  currentFund: 'Current Fund',
  totalAmount: 'Total Amount',
  amount: 'Amount',
  creditInWallet: 'Credit In Wallet',
  dailyPocketMoney: 'Daily Pocket Money',
  referenceNumber: 'Reference Number',
  transableType: 'Transable Type',
  change: 'Change',
  copyReferenceNumber: 'Copy Reference Num.'
}
