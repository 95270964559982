import axios from 'axios'

const resource = 'Principal'

export default {

  getQuickMeetings () {
    return axios.post(`${resource}/quickMeetings`)
  },
  getOnlineClasses () {
    return axios.get(`${resource}/onlineClasses`)
  },
  filterOnlineClasses (payload) {
    return axios.post(`${resource}/onlineClasses`, payload)
  },
  // Filter onlineClasses && QuickMeeting via same endpoint
  filterBoth (currentPage, payload) {
    return axios.post(`${resource}/onlineClasses?page=${currentPage}`, payload)
  },
  filterQuickMeetings (payload) {
    return axios.post(`${resource}/quickMeetings`, payload)
  },

  // Get Filters
  getAllSubjects () {
    return axios.get(`${resource}/subjects`)
  },
  getAllTeachers () {
    return axios.get(`${resource}/teachers`)
  },
  getAllClasses () {
    return axios.get('teachers/classes')
  },
  // Get statistics
  getStatistics () {
    return axios.get(`${resource}/online_class/statistics`)
  },
  getAllTeacherRole () {
    return axios.get('common/teacher-role')
  }

}
