export default {
  teaching_materials :'Teaching Material',
  teaching_materials_list :'Teaching material List',
  create_matiral :'Create Teaching material',
  created_by_me : 'created by me',
  shared_with_me : 'shared with me',
  shared_by_me : 'shared by me',
  not_shared_yet : 'not shared yet',
  share_type:'Share type',
  material_has_not_been_saved_please_complete_your_data :'Material has not been saved please complete your data',
  no_sections :' there\'s no teachers in your section',
  no_clasess :'there\'s no class in your section',
  Presentation :'Presentation',
  Video :'Video',
  Audio :'Audio',
  Books :'Books',
  Documents :'Documents'
}
