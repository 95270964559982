export default {
  e_exam: 'E-Exam',
  paper_exam: 'paper exam',
  choose_class: 'choose class',
  choose_grade: 'choose grade',
  choose_subject: 'choose subject',
  choose_division: 'choose division',
  choose_school: 'choose division',
  choose_date: 'choose date',
  choose_type: 'choose type',
  class: 'class',
  grade: 'grade',
  subject: 'subject',
  school: 'school',
  division: 'division',
  classes: 'classes',
  grades: 'grades',
  subjects: 'subjects',
  schools: 'schools',
  divisions: 'divisions',
  date: 'date',
  type: 'type',
  tag: 'tag',
  select_date: 'select date',
  select_all: 'select all',
  submit: 'submit',
  update: 'update',
  save: 'save',
  allowed_time: 'Allowed Time',
  time_based: 'time based',
  score: 'score',
  publish_automatically: 'Publish automatically',
  auto_publish: 'auto publish',
  add_another: 'add another',
  remove: 'remove',
  duration: 'duration',
  reset: 'reset',
  reset_all: 'reset all',
  question: 'question',
  enter_question_title: 'enter question title',
  description: 'description',
  title: 'title',
  time: 'time',
  submission: 'Submission',
  time_period: 'Time Period',
  fixed_time: 'fixed time',
  start_date: 'Start date',
  end_date: 'End date',
  start_time: 'Start time',
  end_time: 'End time',
  fixed_period: 'fixed period',
  open_time: 'Open time',
  save_as_draft_and_close: 'Save as draft and close',
  done: 'done',
  add: 'add',
  cancel: 'cancel',
  choose_subjects: 'choose subjects',
  choose_schools: 'choose schools',
  choose_divisions: 'choose divisions',
  choose_sections: 'choose sections',
  choose_branches: 'choose branches',
  choose_time: 'choose time',
  delete: 'delete',
  discard: 'discard',
  save_as_a_draft_and_close: 'save as a draft and close',
  the_data_is_submitted: 'the data is submitted',
  save_and_close: 'save and close',
  upload_document: 'Upload Document',
  finish_and_submit: 'Finish and submit',
  view_document: 'view document',
  Enter_Required_Data: 'Enter Required Data',
  save_as_draft: 'Save as draft',
  launch: 'launch',
  class_is_required: 'Class Is Required',
  subjects_is_required: 'Subjects Is Required',
  send: 'send',
  add_new_answer: 'Add new answer',
  your_document_uploaded_successfully: 'Your document uploaded successfully',
  image_uploaded_successfully: 'Image uploaded successfully',
  upload_pdf_only: 'You can upload PDF only',
  failed_to_delete_documment: 'failed to delete document',
  Deleted: 'Deleted',
  delete_successfully: 'Deleted Successfully',
  Delete_Document_Successfully: 'Delete Document Successfully',
  Warning: 'Warning',
  Please_fill_all_data_first: 'Please, fill all data first',
  Please_fill_all_questions_data_first: 'Please, fill all questions data first',
  Started_Downloading: 'Started Downloading',
  Uploading_Error: 'Uploading Error',
  ID: 'ID',
  name: 'Name',
  official_email: 'Official Email',
  status: 'Status',
  Unassign: 'Unassign',
  fullName: 'Full name',
  In_Arabic: 'in Arabic',
  file_was_found_successfully: 'file was found Successfully',
  please_enter_valid_website: 'please enter valid website',
  please_enter_valid_facebook_url: 'please enter valid facebook url',
  please_enter_valid_Instagram_url: 'please enter valid Instagram url',
  geolocation_is_not_supported: 'Geolocation is not supported.',
  this_name_used_before: 'this name used before',
  validation_error: 'Validation Error',
  loading_data_error: 'Loading Data Error',
  submit_answer: 'Submit Answer',
  download_file: 'Download file',
  select_grade: 'select grade',
  validation: 'validation',
  saved_successfully: 'Saved Successfully',
  saved_successfully_default_password_12345678: 'Saved successfully default password 12345678',
  first_name: 'First Name',
  middle_name: 'Middle Name',
  last_name: 'last Name',
  birth_date: 'Birth date',
  In_English: 'In English',
  Gender: 'Gender',
  Female: 'Female',
  Male: 'Male',
  Religion: 'Religion',
  Nationality: 'Nationality',
  next: 'next',
  back: 'back',
  address: 'address',
  country: 'country',
  city: 'city',
  district: 'district',
  Street: 'Street',
  mobile: 'Mobile',
  telephone: 'Telephone',
  student_mobile: 'student Mobile',
  student_telephone: 'student telephone',
  student_email: 'student email',
  official_mailing_email: 'Official mailing Email',
  choose_branch: 'choose branch',
  add_school: 'add school',
  school_name: 'school name',
  school_year: 'school year',
  attended: 'attended',
  leavingReason: 'Reason For Leaving',
  father_email: 'Father Email',
  father_official_email: 'Father Official Email',
  education_school: 'Education School',
  education_university: 'Education University',
  occupation: 'Occupation',
  employer: 'Employer',
  work_address: 'Work Address',
  work_mobile: 'Work Mobile',
  work_telephone: 'Work Telephone',
  work_fax: 'Work Fax',
  work_email: 'Work Email',
  school_alumni: 'Are you a school alumni ?',
  mother_email: 'Mother Email',
  mother_official_email: 'Mother Official Email',
  have_siblings: 'Do You Have Siblings in the school?',
  sibling_id: 'Drop the ID here',
  parents_marital_status: 'Parents Marital Status ?',
  who_is_having_custody: 'Who is having the custody ?',
  email: 'Email',
  relationship: 'Relationship',
  gender: 'Gender',
  bus_question: 'Will You Require Bus Transportation ?',
  health_problem_question: 'Permanent Or Recurring Health Problem ?',
  submitted_an_application_before_question:
    'Have You Previously Submitted An Application With Us Before ?',
  describe_health_problem: 'Describe Health Problem',
  address_on_map: 'Address On Map',
  please_upload_documents: 'Please upload the following documents :',
  add_emergency_contact: 'Add Emergency Contact',
  same_address_as_child: 'Same address as my child',
  same_email_as_child: 'Same official mailing email',
  nationalId: 'national ID',
  ClickForGPSAddress: 'Click For GPS Address',
  add_another_sibling: 'Add another sibling',
  document_is_deleted: 'Document is deleted',
  password: 'Password',
  password_confirm: 'Confirm password',
  Login_Success: 'Login Success',
  You_are_authenticated: 'You are authenticated',
  Login_Failed: 'Login Failed',
  register_Failed: 'register Failed',
  Invalid_data: 'Invalid data',
  Register_Success: 'Register Success',
  Recover_Success: 'Recover Success',
  We_Saved_new_password: 'We Saved new password',
  Please_check_your_mail_inbox: 'Please, check your mail inbox',
  Error: 'Error',
  forth_name: 'Fourth name',
  please_enter_or_religion: 'Please enter other religion',
  please_enter_or_language: 'Please enter other language',
  information_degree: 'Information degree',
  relationships: 'relationships',
  custody_email: 'Custody Email',
  student_information: 'Student Information',
  student_documents: 'Student Documents',
  contact_information: 'Contact Information',
  marital_status: 'Marital Status',
  sibling_information: 'Sibling Information',
  select_country_first: 'select country first',
  search_only_by_places: 'Search only by places',
  mailing_email: 'Mailing email',
  code: 'Code',
  choose_many_dates: 'you can choose many dates',
  type_is_required: ' Type Is Required',
  grade_is_required: 'Grade Is Required',
  types_is_required: 'Types Is Required',
  resend_email: 'Resend Email',
  search_by_name_email_or_ID: 'search by name, email or ID',
  search_by_name_or_ID: 'search by name, or ID',
  search_by_name_email: 'search by name, email, role',
  Copied_success: 'Copied success',
  generate_password: 'generate password',
  search_by_name: 'search by name',
  sutudent_info: 'Sutudent Info',
  user_name: 'User Name',
  canteen_access: 'Canteen Access',
  landline: 'Landline',
  reset_paswwords: 'Reset  passwords',
  search_by_student_name_or_ID: 'search by student name or ID',
  the_grade_id_is_missed: 'the_grade_id_is_missed',
  choose_section: 'choose section',
  get_subjects: 'get subjects',
  file: 'File',
  search_by_teacher_name: 'search by teacher name',
  max_40_characters: 'Max. 40 Characters',
  max_60_characters: 'Max. 60 Characters',
  descriptions_is_required: 'descriptions is required',
  configuration: 'configuration',
  search_by_subject_name: 'search by subject name',
  choose_academic_year: 'choose academic year'
}
