import axios from 'axios'

const resource = '/application'
export default {
  getDropDownData () {
    return axios.get(`${resource}/create`)
  },

  getUsers () {
    return axios.get('/search')
  },

  editApplication (id, payload) {
    return axios.put(`${resource}/${id}`, payload)
  },

  getApplication (application_id) {
    return axios.get(`${resource}/${application_id}`)
  },
  getApplications () {
    return axios.get('/application')
  },
  changeStatus (id, status) {
    return axios.get(`${resource}/${id}/status/${status}`)
  },
  changeStatusToEnroll (payload) {
    return axios.get(`${resource}/${payload.appId}/status/${payload.status}?official_email=${payload.official_email}&code=${payload.code}&Academic_id=${payload.Academic_id}`)
  },
  view (id) {
    return axios.get(`${resource}/${id}/view`)
  },
  getApplicationsStatistics () {
    return axios.get(`${resource}/statistics`)
  },
  getRegisteredApplications () {
    return axios.get(`${resource}/registered`)
  },
  delete (id) {
    return axios.get(`${resource}/${id}/delete`)
  },
  enrollStudent (payload) {
    return axios.post('student/register', payload)
  },
  recommendStudent (payload) {
    return axios.post(`${resource}/${payload.id}/recommended`, payload.data)
  },
  changeDivision (payload) {
    return axios.post(
      `${resource}/${payload.id}/update-division-grade`,
      payload
    )
  },
  ///////////////////////
  //filters
  //////////////////////
  getSchools () {
    return axios.get('application/student/schools')
  },
  getSchoolDivsions (payload) {
    return axios.post('application/student/divisions', payload)
  },
  getDivisions () {
    return axios.post('application/student/divisions')
  },
  getGrades (payload) {
    return axios.post('application/student/grades', payload)
  },
  getGender (payload) {
    return axios.post('application/student/gender', payload)
  },
  getReligion (payload) {
    return axios.post('application/student/religion', payload)
  },
  getNationalities (payload) {
    return axios.post('application/student/nationality', payload)
  },
  getLanguage (payload) {
    return axios.post('application/student/language', payload)
  },
  getAllLanguages () {
    return axios.get('mobile/languages')
  },
  getAllRounds () {
    return axios.get('round/without-pagination')
  },
  applicationsFilter (page, payload, search) {
    return axios.post(`${resource}/filter${page ? `${page}&&` : '?'}${search ? `search=${search}` : ''}`, payload)
  },

  applicationsFilterExport (page, payload) {
    return axios.post(`${resource}/filter/export${page ? page : ''}`, payload)
  },
  viewApplications (status_name) {
    return axios.post('/application/viewed/1', { status: [status_name] })
  },
  notViewApplications (status_name) {
    return axios.post('/application/viewed/0', { status: [status_name] })
  },

  ///////////////////////
  // schedule Meeting
  //////////////////////
  getMeetings () {
    return axios.get('/meetings')
  },
  meetings (payload) {
    return axios.post('/meetings', payload)
  },
  meeting_type () {
    return axios.get('/meeting_type')
  },
  gates () {
    return axios.get('/gate')
  },
  buildings () {
    return axios.get('/buildings')
  },
  persons () {
    return axios.get('/search')
  },
  ///////////////////////
  // Subscribers
  //////////////////////
  getSubscribers () {
    return axios.get('admission/Subscribers')
  },

  ///////////////////////
  // PDF
  //////////////////////
  getPDFDownloadLink (filters) {
    const schools = filters.schools ?? [],
      divisions = filters.divisions ?? [],
      grades = filters.grades ?? [],
      gender = filters.gender ?? [],
      religion = filters.religion ?? [],
      nationality = filters.nationality ?? [],
      language = filters.language ?? [],
      second_language = filters.second_language ?? [],
      alumni = filters.alumni ?? [],
      sibling = filters.sibling ?? [],
      bus_required = filters.bus_required ?? [],
      has_special_needs = filters.has_special_needs ?? [],
      status = filters.status ?? [];

      console.log("scools length is : " + schools.length );

      let params =
        (schools.length ? `schools=[${schools}]&` : '') +
        (divisions?.length ? `divisions=[${divisions}]&` : '') +
        (grades.length ? `grades=[${grades}]&` : '') +
        (gender.length ? `gender=[${gender}]&` : '') +
        (religion.length ? `religion=[${religion}]&` : '') +
        (nationality.length ? `nationality=[${nationality}]&` : '') +
        (language.length ? `language=[${language}]&` : '') +
        (second_language.length
          ? `second_language=[${second_language}]&`
          : '') +
        (alumni.length ? `alumni=[${alumni}]&` : '') +
        (sibling.length ? `sibling=[${sibling}]&` : '') +
        (bus_required.length ? `bus_required=[${bus_required}]&` : '') +
        (has_special_needs.length
          ? `has_special_needs=[${has_special_needs}]&`
          : '') +
        (status.length ? `status=["${status}"]` : '')

    // return axios.get('/ApplicationsPDF', { params: { schools,
    //   divisions,
    //   grades,
    //   gender,
    //   religion,
    //   nationality,
    //   language,
    //   second_language,
    //   alumni,
    //   sibling,
    //   bus_required,
    //   has_special_needs,
    //   status} })

    return axios.post('/Applications-PDF', filters)
  },

  filterRegistationTable (page, filters) {
    return axios.post(`application/registered/filter${page ? `${page}&` : '?'}`, filters)
  }
}
