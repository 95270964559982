<template>
  <div id="list_datatable w-full">
    <div class="vx-row">
      <div class="vx-col w-full flex justify-end my-4" v-if="canCreate">
        <vs-button
          color="primary"
          :to="{name: 'create-boundary', params: {create: true}}">
          {{ $t('boundariesAndDescriptors.createBoundary') }}
        </vs-button>
      </div>
      <div
        class="vx-col w-full"
        :class="`${!$store.state.reduceButton ? 'lg:w-3/4' : 'lg:w-4/4'}`">
        <boundariesAndDescriptorsTable
          :headers_prop="headers"
          :list_url_prop="'/grading/boundaries'"
          :loading="loading"
          :side_filters="sideFilters" />
      </div>
      <div class="vx-col lg:w-1/4 w-full">
        <RoundFilter @reloadData="reloadData" />
      </div>
    </div>
    <modal
      :adaptive="true"
      :height="modalProps.height"
      :minHeight="modalProps.minHeight"
      :scrollable="modalProps.scrollable"
      :width="modalProps.width"
      name="detailsBoundaries">
      <boundariesAndDescriptorsTable
        :headers_prop="detailsHeader"
        :list_items="allLimits"
        :loading="loading">
      </boundariesAndDescriptorsTable>
    </modal>
    <!--    <div class="vs-modal"
      @cancel="allLimits = []"
      :aria-hidden='true'
      :active.sync="showPopupDetails"
      title="Details"
    >
      <boundariesAndDescriptorsTable
        :headers_prop="headers"
        :list_url_prop="'/grading/boundaries'"
        :loading="loading"
        :side_filters="sideFilters"
      >
      </boundariesAndDescriptorsTable>
    </div>-->
  </div>
</template>
<script>
import boundariesAndDescriptorsTable from '@/components/general/datatable/DataTable2.vue'
import RoundFilter from '../components/FilterBar'
import {commonDataMixin} from '@/mixins'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const boundariesAndDescriptors = RepositoryFactory.get(
  'boundariesAndDescriptors',
  'boundariesAndDescriptors'
)
export default {
  name: 'boundariesAndDescriptorsList',
  components: {
    boundariesAndDescriptorsTable,
    RoundFilter
  },
  mixins: [commonDataMixin],
  data() {
    return {
      detailsHeader: [
        {
          minWidth: 150,
          headerName: this.$t('boundariesAndDescriptors.min'),
          field: 'min'
        },
        {
          minWidth: 150,
          headerName: this.$t('boundariesAndDescriptors.max'),
          field: 'max'
        },
        {
          minWidth: 150,
          headerName: this.$t('basic.grade'),
          field: 'grade'
        },
        {
          minWidth: 150,
          headerName: this.$t('boundariesAndDescriptors.local_grade'),
          field: 'local_grade'
        },
        {
          minWidth: 500,
          headerName: this.$t('boundariesAndDescriptors.descriptor'),
          field: 'dscriptor'
        }
      ],
      sideFilters: {
        divisions: []
      },
      headers: [
        {
          minWidth: 150,
          headerName: this.$t('basic.academic_year'),
          field: 'academic_year.name'
        },
        {
          minWidth: 150,
          headerName: this.$t('basic.division'),
          field: 'division.name'
        },
        {
          minWidth: 150,
          headerName: this.$t('basic.sections'),
          field: 'section.name'
        },
        {
          minWidth: 150,
          headerName: this.$t('basic.grades'),
          valueGetter(data) {
            return data.data.grades.map((grade) => grade.name).join(' , ')
          }
        },
        {
          minWidth: 150,
          headerName: this.$t('basic.status'),
          field: 'status',
          cellRendererFramework: 'CellRendererStatus',
          cellRendererParams: {
            status_prop: this.status
          }
        },
        {
          minWidth: 150,
          headerName: this.$t('basic.actions'),
          filter: false,
          field: 'actions',
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            actions_prop: [
              {
                dropDown: true,
                dropDown_items: [
                  {
                    title: this.$t('basic.show'),
                    parameter_name: 'id',
                    actionEmitter: 'showDetails',
                    actionEmitterParams: ['limits'],
                    icon: 'EyeIcon'
                  },
                  {
                    title: this.$t('basic.update'),
                    icon: 'Edit3Icon',
                    navigate_action: true,
                    route_name: 'edit-boundary',
                    parameter_name: 'id',
                    resource: '/grading/create-boundaries-and-descriptors/:id'
                  },
                  {
                    title: this.$t('status.suspend'),
                    icon: 'PowerIcon',
                    color: 'warning',
                    requestType: 'post',
                    requestPayload: {status: 'suspended'},
                    showIf: (data) => data.status === 'active',
                    resource: (data) =>
                      `grading/boundaries/${data.id}/update-status`
                  },
                  {
                    title: this.$t('status.activate'),
                    icon: 'CheckIcon',
                    requestType: 'post',
                    showIf: (data) => data.status === 'suspended',
                    requestPayload: {status: 'active'},
                    resource: (data) =>
                      `grading/boundaries/${data.id}/update-status`
                  }
                ]
              }
            ]
          }
        }
      ],
      allLimits: [],
      modalProps: {
        width: '90%',
        height: 'auto',
        minHeight: 250,
        scrollable: true
      },
      loading: false
    }
  },
  methods: {
    showLimitDetails(data) {
      this.allLimits = data.limits
      this.$modal.show('detailsBoundaries')
    },
    reloadData(v) {
      this.sideFilters = v
    }
  },
  watch: {},
  computed: {
    canCreate() {
      return this.currentAcademicYearId && this.checkUserRole('SND')
    }
  },
  beforeDestroy() {
    this.$root.$off('showDetails')
  },
  created() {
    this.$root.$on('showDetails', (data) => this.showLimitDetails(data))
  }
}
</script>

<style lang="scss" scoped>
.active .vx-card {
  -webkit-box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
  box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
}
</style>
