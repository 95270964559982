
export default {
  data () {
    return {}
  },
  computed: {
    authUser () {
      return JSON.parse(window.localStorage.getItem('authUser'))
    },
    is_student () {
      return this.authUser.userRole === 'student' || this.$acl.check('Student')
    },
    is_student_profile () {
      return this.$route.name === 'BackToProfile'
    },
    is_coordinator () {
      return this.$acl.check('SectionsCoodinator')
    },
    current_locale () {
      return this.$store.getters['main/currentLocale']
    }
  },
  methods: {
    /////////////////////////////
    // loading
    /////////////////////////////
    loading () {
      this.VSLoading()
    },
    stopLoading () {
      this.stopVSLoading()
    },
    ////////////////////////////////
    // Notify
    ////////////////////////////////
    // success msg
    // successMsg(msg) {
    //     this.$vs.notify({
    //         title: 'success',
    //         text: msg ? msg : this.$t('form.the_data_is_submitted'),
    //         color: 'success',
    //         iconPack: 'feather',
    //         icon: 'icon-check-circle'
    //     })
    // },
    // // err msg
    // errMsg(err = null) {
    //     this.$vs.notify({
    //         title: 'Error',
    //         text: err ? (err.data ? err.data.message : err.message) : `${this.$t('form.Enter_Required_Data')}!`,
    //         color: 'danger',
    //         iconPack: 'feather',
    //         icon: 'icon-x-circle'
    //     })
    // },
    serveErr () {
      this.errMsg({
        message: this.$t('basic.Something_went_wrong_Please_try_again')
      })
    }

  }
}
