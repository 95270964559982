<template>
  <div>
    <div class="flex justify-end mb-8" v-if="is_coordinator">
      <vs-button @click="showCreatePopup = !showCreatePopup">
        {{ $t("agenda.addAgenda") }}
      </vs-button>
    </div>
    <vs-popup :title="$t('agenda.UploadAgenda')" :active.sync="showCreatePopup">
      <form class="p-3 py-6 md:p-6" @submit.prevent="submitForm">
        <label class="w-full">{{ $t("agenda.file_name") }}</label>
        <vs-input
          type="text"
          v-model="fileName"
          class="w-full"
          v-validate="'required'"
        />

        <label class="w-full">{{ $t("form.choose_class") }}</label>
        <v-select
          closeOnSelect
          label="name"
          :reduce="(name) => name.id"
          :options="classes"
          multiple
          v-model="class_ids"
          class="w-full select-large"
          name="class"
          v-validate="'required'"
        >
        </v-select>

        <div class="file-upload flex items-baseline mt-4">
          <label v-if="!success" class="upload-label" for="upload">
            <div class="upload-container">
              <span>{{ $t("basic.Upload") }}</span>
              <feather-icon class="m-1" icon="UploadCloudIcon" />
            </div>
          </label>
          <label v-if="document" class="upload-label ml-8" for="upload">
            <span>{{ document.name }}</span>
          </label>
          <input
            id="upload"
            ref="file"
            :accept="(accept ? accept : 'all') + ';capture=camera'"
            :text="text ? text : $t('basic.upload_file')"
            class="file-upload__input"
            multiple
            name="file-upload"
            type="file"
            v-on:change="uploadFiles()"
          />
        </div>
        <div class="mt-4"></div>
        <div v-if="success" style="width: 10rem; overflow: hidden">
          <vs-progress color="primary" indeterminate>{{
            $t("basic.primary")
          }}</vs-progress>
        </div>

        <div
          class="
            flex flex-wrap
            md:justify-between
            justify-center
            items-center
            md:flex-row
            flex-col
            mt-10
          "
        >
          <vs-button
            color="warning"
            type="border"
            @click="initForm()"
            class="mt-5"
          >
            {{ $t("form.cancel") }}</vs-button
          >
          <vs-button class="mt-5" @click="submitForm">{{
            $t("form.save")
          }}</vs-button>
        </div>
      </form>
    </vs-popup>
    <div class="vx-row w-full">
      <div class="vx-col w-full">
        <!-- ////////////////////////////////////
            Content
        ////////////////////////////////////// -->
        <div class="w-full mb-3">
          <!-- ////////////////////////////////////
                    List Data
        ////////////////////////////////////// -->
          <transition-group
            class="list-transition"
            name="list-enter-up"
            tag="ul"
          >
            <template v-if="filtrated_data.length > 0">
              <li
                v-for="(val, i) in filtrated_data"
                :key="val.id"
                :style="[{ transitionDelay: i * 0.1 + 's' }]"
              >
                <task-card :agenda_prop="val" @deleteItem="deleteItem">
                </task-card>
              </li>
            </template>
          </transition-group>
          <!-- ////////////////////////////////////
                    Pagination
        ////////////////////////////////////// -->
          <template v-if="filtrated_data.length > 0 && totalPages > 1">
            <vs-pagination
              :total="totalPages"
              :max="countPerPage"
              v-model="currentPage"
              class="mt-6"
            />
          </template>
          <!-- ////////////////////////////////////
                    No Data
        ////////////////////////////////////// -->
          <template v-if="filtrated_data.length < 1">
            <p class="text-center">{{ $t("basic.no_data_available") }}</p>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// APIs
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const agendaRepository = RepositoryFactory.get(
  'AgendaRepository',
  'AgendaRepository'
)

// Mixins
import mixins from '../mixins'

// Components
import FileUploader from '@/modules/unit-planner/components/uploadButton/uploadButton.vue'
import taskCard from './components/TaskCard'

export default {
  props:{
    student: {
      type: Object,
      required: true
    }
  },
  name: 'agendaList',
  mixins: [mixins],
  components: {
    FileUploader,
    taskCard
  },
  data () {
    return {
      //////////////////////////
      /////Create form
      /////////////////////////
      fileName: '',
      document: '',
      class_ids: [],

      showCreatePopup: false,
      success: false,
      accept: {},
      text: {
        type: String
      },

      filtrated_data: [],
      checked_data: [],
      classes: [],
      /////////////////////////
      // Pagination
      /////////////////////////
      totalPages: 0,
      currentPage: 1,
      countPerPage: 10
      ///////////////////////
      // Right Filters
      ///////////////////////
    }
  },
  computed: {},
  watch: {
    currentPage (v) {
      this.getAganda()
    },
    'student.user_id' () {
      this.getAganda()
    }
  },
  methods: {
    ////////////////////////////
    // APIs
    ////////////////////////////
    //-------- Filter
    getAganda () {
      this.loading()
      let fetchFunctionName = ''
      let studentID = null
      const is_parent =
        JSON.parse(localStorage.getItem('authUser')).userRole === 'parent'
      if (this.is_coordinator) {
        fetchFunctionName = 'getCordinatoorAgenda'
      } else if (is_parent) {
        fetchFunctionName = 'getParentAgenda'
        studentID = this.student.user_id
      } else {
        fetchFunctionName = 'getstudentAgenda'
      }
      agendaRepository[fetchFunctionName](this.currentPage, studentID)
        .then(res => {
          console.log(res)
          this.filtrated_data = res.data
          this.setPagination(res.meta)
        })
        .catch()
        .finally(() => this.stopLoading())
    },
    ////////////////////////////
    // Pagination
    ////////////////////////////
    setPagination (data) {
      this.totalPages = data.total_pages || data.last_page
      this.countPerPage = data.per_page
      this.currentPage = data.current_page
    },
    ////////////////////////////
    // upload file
    ////////////////////////////
    initForm () {
      this.success = false
      this.showCreatePopup = false
      this.document = ''
      this.fileName = ''
      this.class_ids = []
    },
    submitForm () {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.success = true
          const formData = new FormData()
          formData.append('document', this.document)
          formData.append('name', this.fileName)
          this.class_ids.forEach((item, index) => {
            formData.append(`class_ids[${index}]`, this.class_ids[index])
          })
          agendaRepository['createAgenda'](formData)
            .then(res => {
              this.filtrated_data.unshift(res.data)
              this.initForm()
              this.successMsg(
                this.$t('agenda.adding_successfully'),
                this.$t('agenda.adding_a_agenda_task')
              )
            })
            .catch(err => {
              this.success = false
              this.errMsg(
                err.data.message,
                this.$t('agenda.failed_to_save_agenda')
              )
            })
        } else {
          this.errMsg(
            this.$t(
              'agenda.agenda_has_not_been_saved_please_complete_your_data'
            )
          )
          // this.$vs.notify({
          //   color: 'danger',
          //   title: 'error',
          //   text: 'Agenda has not been saved please complete your data'
          // })
        }
      })
    },
    uploadFiles () {
      console.log(this.$refs.file.files[0])
      this.document = this.$refs.file.files[0]
    },
    ////////////////////////////
    // delete file
    ////////////////////////////
    deleteItem (item) {
      const index = this.filtrated_data
        .map(obj => {
          return obj.id
        })
        .indexOf(item)
      this.filtrated_data.splice(index, 1)
    },

    getClasses () {
      agendaRepository['getClasses']()
        .then(res => {
          this.classes = res.data
        })
        .catch()
    }
  },
  async created () {
    this.getAganda()
    if (this.is_coordinator) {
      this.getClasses()
    }
  },
  mounted () {
    console.log(this.student.user_id)
  }
}
</script>
<style lang="scss" scoped>
#cas_filter {
  #round_filter_content {
    min-height: fit-content !important;
  }
}

.upload-label {
  display: flex;
  justify-content: center;
  width: 110px;
  height: 43px;
  border-radius: 5px;
  z-index: 200;
  cursor: pointer;
}

.upload-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  background-color: rgba(var(--vs-primary), 1);
  color: white;
  font-size: 15px;
  font-weight: 500;
  z-index: 1;
}

.file-upload {
  position: relative;
  display: inline-block;

  img {
    width: 2rem;
    display: inline-block;
    float: left;
    margin-right: 1rem;
    margin-top: -0.4rem;
  }
}

.file-upload__input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  font-size: 16px;
  width: 0;
  height: 100%;
  opacity: 0;
}

.loading {
  position: relative;

  animation: loading 500ms infinite linear;

  display: inline;
  position: absolute;
  left: 13px;
  top: 0;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
