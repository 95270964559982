<template>
  <div>
    <teacher-form :teacherFormProps.sync="teacher_form"></teacher-form>
  </div>
</template>
<script>
import teacherForm from '../../../components/TeacherForm.vue'

export default {
  name:'CreateTeacher',
  components:{
    teacherForm

  },
  created () {

  },
  data () {
    return {
      teacher_form: {
        first_name: '',
        second_name: '',
        third_name: '',
        fourth_name: '',
        first_name_ar: '',
        second_name_ar: '',
        third_name_ar: '',
        fourth_name_ar: '',
        gender: '',
        birth_date: '',
        phone: [''],
        Address: '',
        landline: '',
        image: '',
        subject_ids:[],
        divisions: [{ division_id: '', section_id: '', sections:[]}],
        is_homeroom_teacher: 0,
        spoken_language_ids:[],
        official_email:''
      }

    }
  }
}
</script>
