export default [
  //////////////////////
  // settings
  /////////////////////
  {
    path: 'settings',
    name: 'settings',
    component: () => import('./pages/Settings.vue'),

    meta: {
      breadcrumb: [{title: 'basic.settings', url: '', active: true}],
      rule: 'SND',
      pageTitle: 'grading.grading_settings'
    }
  },
  //////////////////////
  // allocations
  /////////////////////
  {
    path: 'allocations',
    name: 'allocations',
    component: () => import('./pages/allocations/List.vue'),

    meta: {
      breadcrumb: [{title: 'grading.allocations', url: '', active: true}],
      rule: 'SND',
      pageTitle: 'grading.allocations'
    }
  },
  {
    path: 'allocations/create',
    name: 'createAllocation',
    component: () => import('./pages/allocations/Create.vue'),

    meta: {
      breadcrumb: [
        {title: 'home', url: '', active: true},
        {
          title: 'grading.allocations',
          url: '/grading/allocations',
          active: true
        },
        {title: 'basic.create', url: '', active: true}
      ],
      rule: 'SND',
      pageTitle: 'grading.create_allocation'
    }
  },
  {
    path: 'allocations/:id/edit',
    name: 'editAllocation',
    component: () => import('./pages/allocations/Edit.vue'),

    meta: {
      breadcrumb: [
        {title: 'home', url: '', active: true},
        {
          title: 'grading.allocations',
          url: '/grading/allocations',
          active: true
        },
        {title: 'basic.edit', url: '', active: true}
      ],
      rule: 'SND',
      pageTitle: 'grading.edit_allocation'
    }
  },
  {
    path: 'allocations/:id/show',
    name: 'showAllocation',
    component: () => import('./pages/allocations/Show.vue'),

    meta: {
      breadcrumb: [
        {title: 'home', url: '', active: true},
        {
          title: 'grading.allocations',
          url: '/grading/allocations',
          active: true
        },
        {title: 'basic.show', url: '', active: true}
      ],
      rule: 'SND',
      pageTitle: 'grading.show_allocation'
    }
  },

  //////////////////////
  // Round Manager
  /////////////////////
  {
    path: 'rounds',
    name: 'gradingRounds',
    component: () => import('./pages/roundManager/list.vue'),

    meta: {
      breadcrumb: [{title: 'grading.grading_rounds', url: '', active: true}],
      rule: 'SND',
      pageTitle: 'grading.grading_round_system'
    }
  },
  {
    path: 'rounds/create',
    name: 'gradingCreateRound',
    component: () => import('./pages/roundManager/Create.vue'),

    meta: {
      breadcrumb: [
        {title: 'home', url: ''},
        {title: 'grading.grading_rounds', url: '/grading/rounds'},
        {title: 'basic.create', active: true}
      ],

      rule: 'SND',
      pageTitle: 'grading.create_round_for_report_card'
    }
  },
  {
    path: 'rounds/edit/:id',
    name: 'gradingEditRound',
    component: () => import('./pages/roundManager/Edit.vue'),

    meta: {
      breadcrumb: [
        {title: 'home', url: ''},
        {title: 'grading.grading_rounds', url: '/grading/rounds'},
        {title: 'basic.edit', active: true}
      ],

      rule: 'SND',
      pageTitle: 'grading.edit_round'
    }
  }
]
