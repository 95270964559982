<template>
  <vx-card no-body>
    <vs-list>
      <vs-list-header :color="color" :icon="icon" icon-pack="feather" :title="title"></vs-list-header>
    </vs-list>
    <vue-apex-charts
      type="bar"
      height="350"
      :options="chartData.chartOptions"
      :series="chartData.series"
    ></vue-apex-charts>
  </vx-card>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
export default {
  components: {
    VueApexCharts
  },
  props: {
    title: '',
    icon: '',
    color: '',
    chartData: ''
  },
  data () {
    return {}
  }
}
</script>