export default {
  student_affairs: 'Student Affairs',
  student_name:'Student name',
  student_id:'Student id',
  allStudents: 'All Students',
  fullName: 'Full Name',
  email: 'Email',
  division: 'Division',
  section: 'Section',
  grade: 'Grade',
  classGroup: 'Class Group',
  enter_note: 'Enter Note',
  please_enter_the_note_first:' Please enter the note first',
  the_note_field_must_be_at_least_5_characters:'the note field must be at least 5 characters',
  change_student_status:'Change Student Status',
  you_should_select_one_status: 'You should select one status',
  note_is_added: 'Note is added',
  show_student_profile:'Show Student Profile',
  add_Note:'Add Note',
  suspend_student:'suspend student',
  block_student:'block student',
  active_student: 'active student',
  all_students:'All Students',
  new_students:'New students',
  unassigned_students:'Unassigned students',
  current:'Current',
  leaver:'leaver',
  graduated: 'Graduated',
  add_note:'Add Note',
  createStudent:'Create Student',
  addStudent: 'Add Student',
  school_name: 'School Name',
  editStudent:'Edit Student',
  showStudent:'Show Student',
  student_updated_successfully:'Student updated successfully',
  Add_student_successfully: 'Student added successfully',
  student_credentials: 'student credentials',
  please_copy_following_data: 'please copy the following data',
  allparents:'All parents',
  edit_parent:'Edit Parent'
}
