import { render, staticRenderFns } from "./units-materialization.vue?vue&type=template&id=c3ec7264"
import script from "./units-materialization.js?vue&type=script&lang=js&external"
export * from "./units-materialization.js?vue&type=script&lang=js&external"
import style0 from "./units-materialization.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports