<template>
  <div class="vx-row w-full">
    <div class="vx-col w-full lg:w-3/4">
      <!-- ////////////////////////////////////
            Status Counter && Create Button
        ////////////////////////////////////// -->
      <StatusCounter
        @filter-change="changeCounterFilter"
        :status_prop="statistics"
        class="w-full"
        ref="statusCounter"
      />

      <!-- ////////////////////////////////////
            Content
        ////////////////////////////////////// -->
      <div class="w-full mb-3">
        <!-- ////////////////////////////////////
            interviews Search && Create Data
        ////////////////////////////////////// -->
        <template v-if="is_coordinator && is_interviews">
          <!-- Search -->
          <form
            data-vv-scope="searchInterview"
            @submit.prevent="searchInterview"
            class="my-6 md:w-2/3 mx-auto"
          >
            <div class="mb-5">
              <vx-input-group>
                <vs-input
                  :placeholder="$t('cas.Search_by_Student_ID')"
                  v-model.number="searchStudentCode"
                  class="w-full"
                  name="id"
                  v-validate="'required|numeric'"
                />
                <!-- Add Another -->
                <template slot="append">
                  <div class="append-text btn-addon">
                    <vs-button
                      icon-pack="feather"
                      icon="icon-search"
                      @click="searchInterview"
                    ></vs-button>
                  </div>
                </template>
              </vx-input-group>
              <span class="text-danger text-sm">{{
                errors.first("searchInterview.id")
              }}</span>
            </div>
          </form>
          <!-- Create -->
          <form
            data-vv-scope="postInterview"
            @submit.prevent="submitInterviewPost"
            class="my-6 md:w-2/3 mx-auto"
          >
            <div class="mb-5" v-if="is_postInterviews">
              <label>
                {{ createInterviewLabelText }}
              </label>
              <vx-input-group>
                <vs-textarea
                  :placeholder="$t('cas.insert_some_words')"
                  v-model="createInterviewBody"
                  class="w-full m-0 bg-white"
                  name="description"
                  v-validate="'required'"
                  rows="5"
                />
                <!-- Add Another -->
                <template slot="append">
                  <div class="append-text btn-addon">
                    <vs-button @click="submitInterviewPost">{{
                      $t("basic.post")
                    }}</vs-button>
                  </div>
                </template>
              </vx-input-group>
              <!-- <span class="text-danger text-sm">{{
                errors.first("postInterview.description")
              }}</span> -->
            </div>
          </form>
        </template>

        <!-- ////////////////////////////////////
                    List Data
        ////////////////////////////////////// -->
        <transition-group class="list-transition" name="list-enter-up" tag="ul">
          <template v-if="filtrated_data.length > 0">
            <li
              v-for="(val, i) in filtrated_data"
              :key="val.id"
              :style="[{ transitionDelay: i * 0.1 + 's' }]"
            >
              <task-card
                :task_prop="val"
                @reloadData="reloadData"
                v-if="!is_interviews"
                @setInterview="setInterview"
              ></task-card>
              <interview-card :data="val" :index="i + 1" v-else>
              </interview-card>
            </li>
          </template>
        </transition-group>
        <!-- ////////////////////////////////////
                    Pagination
        ////////////////////////////////////// -->
        <template v-if="filtrated_data.length > 0 && totalPages > 1">
          <vs-pagination
            :total="totalPages"
            :max="countPerPage"
            v-model="currentPage"
            class="mt-6"
          />
        </template>
        <!-- ////////////////////////////////////
                    No Data
        ////////////////////////////////////// -->
        <template v-if="filtrated_data.length < 1">
          <p class="text-center">{{ $t("basic.no_data_available") }}</p>
        </template>
      </div>
    </div>
    <div class="vx-col w-full lg:w-1/4">
      <!-- ////////////////////////////////////
            Create Task Button
        ////////////////////////////////////// -->
      <vs-button
        class="mb-4 md:ml-1 w-full p-6"
        @click="goTo('createTask')"
        v-if="is_student && !allowUploadFinal"
        >{{ $t("cas.createTask") }}
      </vs-button>

      <vs-button
        :disabled="!link"
        class="mb-4 md:ml-1 w-full p-6"
        @click="downloadPdf()"
        v-else
        >download pdf
      </vs-button>

      <!-- ////////////////////////////////////
            Upload Report If is_Student
        ////////////////////////////////////// -->
      <template v-if="allowUploadFinal">
        <vx-card
          class="w-full p-relative upload-button mb-6 text-center"
          v-if="is_student && !finalReportFileURL"
        >
          <input
            type="file"
            @change="uploadReport($event)"
            accept="application/pdf;capture=camera"
            class="opacity-0"
            ref="uploadReport"
          />
          <feather-icon
            icon="AwardIcon"
            class="m-auto mb-3 block rounded-full"
            svgClasses="h-8 w-8 text-success "
          ></feather-icon>
          <p>
            {{
              $t(
                "cas.Congratulation_you_made_it_kindly_upload_your_final_report_from"
              )
            }}
          </p>
          <vs-button
            color="success"
            icon-pack="feather"
            icon="icon-upload"
            class="mt-6 m-auto"
            @click="$refs.uploadReport.click()"
          >
            {{ $t("basic.here") }}
          </vs-button>
          <p class="text-center text-xs text-uppercase mt-2">
            {{ $t("basic.only") }} PDF
          </p>
        </vx-card>
        <!-- if uploaded -->
        <div class="mb-6" v-else-if="finalReportFileURL">
          <vx-card class="">
            <div class="text-center mb-2">
              <img
                src="@/assets/images/pdf_icon.png"
                width="40"
                height="auto"
                class="m-auto mb-4"
              />
              <span class="ml-2 block">{{
                $t("cas.The_CAS_final_report")
              }}</span>
            </div>
            <div class="text-center">
              <p>
                <a
                  :href="finalReportFileURL"
                  target="_blank"
                  class="text-success"
                  >{{ finalReportFileName }}</a
                >
              </p>
            </div>
          </vx-card>
        </div>
      </template>
      <!-- ////////////////////////////////////
                    Filters
        ////////////////////////////////////// -->
      <round-filter
        v-if="!is_interviews"
        :filters_prop="filters"
        @refilter="refilter"
        @resetAll="refilter"
        :SyncWithReduceButton="false"
        ref="roundFilter"
        id="cas_filter"
        class="w-full"
        :class="is_student ? (allowUploadFinal ? 'my-6' : 'mb-6') : 'mb-6'"
      />
      <!-- ////////////////////////////////////
                    Materialization
        ////////////////////////////////////// -->

      <vx-card
        v-if="criterias.length"
        :class="!is_interviews ? 'my-6' : 'mb-6'"
        class="text-center"
      >
        <template slot="no-body">
          <div class="mb-5">
            <h5 class="py-10">
              {{ $t("cas.Materialization") }}
            </h5>
            <!-- {{ totalHours }} -->
            <div class="truncate text-center">
              <h2 class="mb-1 font-bold">{{ criteriaTotalHours }}</h2>
              <span>{{ $t("cas.Achieved_Hours") }}</span>
            </div>
            <!-- CHART -->
            <!-- <vue-apex-charts
              type="radialBar"
              height="160"
              :series="[totalHours]"
              :options="chartOptions"
            /> -->
          </div>
        </template>

        <!-- DATA -->

        <div>
          <p
            class="mb-1 w-5/6 py-2 px-3 mx-auto text-sm"
            v-for="(v, i) in criterias"
            :key="i"
            :class="`status-fill--${v.symbol.toLowerCase()}`"
          >
            {{ v.name === "Action" ? "activity" : v.name }} =
            {{ v.count || 0 }} {{ $t("basic.hours") }}
          </p>
        </div>
      </vx-card>
      <!-- ////////////////////////////////////
                    CAS_Learning_Outcomes
        ////////////////////////////////////// -->
      <vx-card class="my-6" v-if="outcomes.length">
        <div class="px-3">
          <h5 class="pt-4 pb-6">
            {{ $t("cas.Learning_Outcomes") }}
          </h5>
          <p
            class="py-2 mx-auto text-sm flex justify-between"
            v-for="(v, i) in outcomes"
            :key="i"
            :class="{ 'text-danger': !v.count }"
          >
            <span>{{ v.name }} </span>
            <span> ({{ v.count ? v.count : 0 }}) </span>
          </p>
        </div>
      </vx-card>
    </div>
  </div>
</template>
<script>
// APIs
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const CasRepository = RepositoryFactory.get('CASRepository', 'TaskRepository')

// Mixins
import mixins from '../../mixins'

// Components
import RoundFilter from '@/components/general/RoundFilter' //Right-sidebar
import StatusCounter from '@/components/general/StatusTabs'
import TaskCard from './components/TaskCard'
import InterviewCard from './components/InteviewCard'

import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'tasksList',
  mixins: [mixins],
  components: {
    StatusCounter,
    RoundFilter,
    TaskCard,
    VueApexCharts,
    InterviewCard
  },
  data () {
    return {
      link: '',
      ////////////////////////////
      //  interviews
      ////////////////////////////
      is_interviews: false,
      searchStudentCode: null,
      createInterviewStudentID: null,
      createInterviewBody: null,
      interviewsLength: 0,
      is_postInterviews: false,
      ///////////////////////
      // Status Counter
      ///////////////////////
      statistics: [],
      /////////////////////////
      // Pagination
      /////////////////////////
      totalPages: 0,
      currentPage: 1,
      countPerPage: 10,
      ///////////////////////
      // Right Filters
      ///////////////////////
      filters: [
        {
          name: this.$t('cas.Students'),
          payload_name: 'student_id',
          singleSeletable: true,
          by: []
        },
        {
          name: this.$t('cas.Status'),
          payload_name: 'status_cas_id',
          by: []
        },

        {
          name: this.$t('cas.Validation'),
          payload_name: 'validation',

          by: [
            {
              name: this.$t('cas.validated'),
              id: 'validated',
              checked: false
            },
            {
              name: this.$t('cas.not_valiated'),
              id: 'not_valiated',
              checked: false
            },
            {
              name: this.$t('cas.not_validated_yet'),
              id: 'not_validated_yet',
              checked: false
            }
          ]
        },
        {
          name: this.$t('cas.Time_based'),
          payload_name: 'timeStatus',
          by: [
            {
              name: 'upcoming',
              id: 'upcoming',
              checked: false
            },

            {
              name: 'ongoing',
              id: 'ongoing',
              checked: false
            },
            {
              name: 'closed',
              id: 'closed',
              checked: false
            }
          ]
        },
        {
          name: this.$t('cas.Indicators'),
          payload_name: 'indicator',
          by: [
            {
              name: 'green',
              id: 'green',
              text_color: 'green',
              checked: false
            },
            {
              name: 'blue',
              id: 'blue',
              text_color: 'blue',
              checked: false
            },
            {
              name: 'red',
              id: 'red',
              text_color: 'red',
              checked: false
            },
            {
              name: 'orange',
              id: 'orange',
              text_color: 'orange',
              checked: false
            }
          ]
        },
        {
          name: this.$t('cas.Evaluation'),
          payload_name: 'submit',

          by: [
            {
              name: this.$t('cas.approved'),
              id: 'accepted',
              checked: false
            },
            {
              name: this.$t('cas.not_approved'),
              id: 'not_accepted',
              checked: false
            }
          ]
        },
        {
          name: this.$t('basic.classes'),
          payload_name: 'class_ids',
          by: [
            {
              name: this.$t('cas.approved'),
              id: 'accepted',
              checked: false
            },
            {
              name: this.$t('cas.not_approved'),
              id: 'not_accepted',
              checked: false
            }
          ]
        }
      ],
      filtrated_data: [],
      checked_data: [],
      filter_Payload: {
        student_id: '',
        status_cas_id: [],
        criteria_id: [],
        validation: [],
        submit: [],
        timeStatus: [],
        indicator: [],
        class_ids: []
      },
      ///////////////////////
      // Materialization Chart
      ///////////////////////

      chartOptions: {
        plotOptions: {
          radialBar: {
            size: 110,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: '70%'
            },
            track: {
              background: '#bfc5cc',
              strokeWidth: '50%'
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                offsetY: 14,
                color: '#99a2ac',
                fontSize: '2.5rem'
              }
            }
          }
        },
        colors: ['#00db89'],
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: ['#00b5b5'],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
          }
        },
        stroke: {
          lineCap: 'round'
        },
        chart: {
          sparkline: {
            enabled: true
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1
          }
        }
      },
      ///////////////////////
      // counts
      ///////////////////////
      criteriaTotalHours: 0,
      criteriaStudentCounts: [],
      outcomesStudentCounts: []
    }
  },
  computed: {
    createInterviewLabelText () {
      let text = ''
      switch (this.interviewsLength) {
      case 0:
        text = this.$t('cas.first_interview')

        break
      case 1:
        text = this.$t('cas.second_interview')

        break
      case 2:
        text = this.$t('cas.Third_Final_Interview')
        break
      }
      return text
    }
  },
  watch: {
    criterias (v) {
      this.setStatusCounts()
    },
    is_interviews (v) {
      if (v) {
        if (this.is_student) this.getStudentInterviews()
      }
    },
    currentPage (v) {
      this.tasksFilter(v)
    },
    'student.id' () {
      this.refilter(this.filter_Payload)
    }
  },
  methods: {
    downloadPdf () {
      if (this.link) {
        this.downloadFile(this.link)
      }
    },
    getPdfCas () {
      CasRepository.pdfCas().then((res) => {
        this.link = res
      })
    },
    async getAllClasses () {
      await CasRepository.casClasses().then((res) => {
        this.filters.find((el) => el.payload_name === 'class_ids').by =
          res.data.map((el) => {
            return {
              id: el.id,
              name: el.name,
              checked: false
            }
          })
      })
    },

    ////////////////////////////
    // APIs
    ////////////////////////////
    //-------- Filter
    filterRequest (requestName) {
      this.loading()
      CasRepository[requestName](this.currentPage, this.filter_Payload)
        .then((res) => {
          this.filtrated_data = res.data.data
          this.setPagination(res.data)
        })
        .catch()
        .finally(() => this.stopLoading())
    },
    tasksFilter () {
      this.filterRequest('studentTasksFilter')
      // else  this.filterRequest('coordinatorTasksFilter')
    },
    getStatus () {
      CasRepository.getStatus().then((res) => {
        this.filters.find((el) => el.payload_name === 'status_cas_id').by =
          res.data.map((el) => {
            return {
              id: el.id,
              name: el[this.current_locale],
              checked: false
            }
          })
      })
    },
    getStudents () {
      CasRepository.getStudents().then((res) => {
        this.filters.find((el) => el.payload_name === 'student_id').by =
          res.data.map((el) => {
            return {
              id: el.user_id,
              name: `${el.first_name} ${el.middle_name} ${el.last_name}`,
              checked: false
            }
          })
      })
    },

    //-------- interviews
    //For Student
    getStudentInterviews () {
      this.loading()

      CasRepository.getStudentInterviews()
        .then((res) => {
          this.filtrated_data = res.data
        })
        .catch()
        .finally(() => this.stopLoading())
    },

    //For Coordinator
    getTeacherInterviews () {
      this.loading()
      const code = parseInt(this.searchStudentCode)
      CasRepository.getTeacherInterviews(code)
        .then((res) => {
          this.filtrated_data = res.data
          this.interviewsLength = res.data.length
          this.is_postInterviews = res.data.length < 3 || false
        })
        .catch(() => {
          const err = { message: 'The Student is not exist' }

          this.errMsg(err)
        })
        .finally(() => this.stopLoading())
    },
    createInterview () {
      const payload = {
        body: this.createInterviewBody,
        // student_id:this.createInterviewStudentID ,
        student_code: Number(this.searchStudentCode)
      }
      this.loading()
      CasRepository.createInterview(payload)
        .then(() => {
          this.successMsg('Interview Is Posted')
          this.createInterviewBody = ''
        })
        .catch((err) => this.errMsg(err))
        .finally(() => this.stopLoading())
    },

    //------ Criteria Counters
    getCriteriaStudentCounter () {
      return CasRepository.getCriteriaStudentCounter().then((res) => {
        res.data.forEach((el) => {
          this.criterias.find((v) => v.symbol === el['criteria'].symbol).count =
            el.counter
        })

        // Get Total Hours
        this.criteriaTotalHours = this.criterias
          .map((el) => +el.count)
          .reduce((a, b) => a + b, 0)
      })
    },
    getCriteriaCoordinatorCounter () {
      return CasRepository.getCriteriaCoordinatorCounter().then((res) => {
        res.data.forEach((el) => {
          this.criterias.find((v) => v.symbol === el['criteria'].symbol).count =
            el.counter
        })

        // Get Total Hours
        this.criteriaTotalHours = this.criterias
          .map((el) => +el.count)
          .reduce((a, b) => a + b, 0)
      })
    },
    studentCriteriaCount (user_id) {
      return CasRepository.studentCriteriaCount(user_id).then((res) => {
        if (!res.data.length) this.criterias.forEach((el) => {
          el.count = 0
        })
        else {
          res.data.forEach((el) => {
            this.criterias.find(
              (v) => v.symbol === el['criteria'].symbol
            ).count = el.counter
          })
        }
        // Get Total Hours
        this.criteriaTotalHours = this.criterias
          .map((el) => +el.count)
          .reduce((a, b) => a + b, 0)
      })
    },

    //------ Outcomes Counters
    getOutcomesStudentCounter () {
      return CasRepository.getOutcomesStudentCounter().then((res) => {
        res.data.forEach((el) => {
          this.outcomes.find((v) => v.symbol === el['outcome'].symbol).count =
            el.counter
        })
      })
    },
    getOutcomesCoordinatorCounter () {
      return CasRepository.getOutcomesCoordinatorCounter().then((res) => {
        res.data.forEach((el) => {
          this.outcomes.find((v) => v.symbol === el['outcome'].symbol).count =
            el.counter
        })
      })
    },
    studentOutcmesCount (user_id) {
      return CasRepository.studentOutcmesCount(user_id).then((res) => {
        if (!res.data.length) this.outcomes.forEach((el) => {
          el.count = 0
        })
        else {
          res.data.forEach((el) => {
            this.outcomes.find((v) => v.symbol === el['outcome'].symbol).count =
              el.counter
          })
        }
      })
    },
    //------------ final Report
    UploadFinalReport (file) {
      this.loading()
      CasRepository.UploadFinalReport(file)
        .then((res) => {
          // this.allowUploadFinal = res.message.is_allow
          // this.finalReportFileURL = res.message.final_report
          this.successMsg(
            this.$t('cas.your_final_report_uploaded_successfully')
          )
          this.checkAllowToUploadFinalReport()
        })
        .catch(() => this.errMsg({
          message: this.$t('basic.Something_went_wrong_Please_try_again')
        })
        )
        .finally(() => this.stopLoading())
    },

    ////////////////////////////
    // Pagination
    ////////////////////////////
    setPagination (data) {
      this.totalPages = data.total_pages || data.last_page
      this.countPerPage = data.per_page
      this.currentPage = data.current_page
    },

    ////////////////////////////
    // RoundFilter
    ////////////////////////////
    refilter (checked) {
      // console.log(checked)
      this.checked_data = checked
      const is_checked = checked && checked.length > 0

      if (is_checked) {
        checked.forEach((el) => {
          if (this.filter_Payload.hasOwnProperty(el.name)) {
            this.filter_Payload[el.name] = el.checked
          }
        })
      }
      this.tasksFilter()
      if (
        this.is_coordinator &&
        checked.some((el) => el.name === 'student_id')
      ) {
        const student_user_id = checked.find(
          (el) => el.name === 'student_id'
        ).checked

        if (checked.find((el) => el.name === 'student_id').checked != '') {
          this.checkAllowToUploadFinalReport(student_user_id)
          this.studentCriteriaCount(student_user_id)
          this.studentOutcmesCount(student_user_id)
        } else {
          this.allowUploadFinal = false
          this.getCriteriaCoordinatorCounter()
          this.getOutcomesCoordinatorCounter()
        }
      }
    },
    ////////////////////////////
    // Set Status && Counts
    ////////////////////////////
    setStatusCounts () {
      // set statistics
      this.statistics = [
        {
          name: this.$t('cas.cas_overview'),
          id: 0,
          value: 'all',
          icon: 'EyeIcon',
          count: 0,
          active: true
        },
        ...this.criterias.map((el) => {
          const id = el.id
          return {
            name: el.name === 'Action' ? 'activity' : el.name,
            id: el.id,
            value: el.id,
            icon:
              id === 1 ? 'InfoIcon' : id === 2 ? 'ServerIcon' : 'ActivityIcon',
            color: id === 1 ? 'blue' : id === 2 ? 'success' : 'danger'
          }
        }),
        {
          name: this.$t('cas.interviews'),
          value: 'notes_interviews',
          count: 0,
          icon: 'Edit2Icon',
          color: 'warning'
        }
      ]

      // Set Counts
    },
    async changeCounterFilter (v) {
      await this.resetList()

      // Load notes_interviews
      if (v === 'notes_interviews') {
        this.is_interviews = true
        this.filter_Payload.criteria_id = []
        this.$refs.roundFilter.resetAll()
        if (this.is_student) {
          this.getStudentInterviews()
        }
      }

      // Filter By Critica Status
      else if (!v || v === 'all') {
        this.is_interviews = false
        this.filter_Payload.criteria_id = []
        this.tasksFilter()
      } else {
        this.is_interviews = false
        this.filter_Payload.criteria_id = [v]
        this.tasksFilter()
      }
    },
    ////////////////////////////
    // Reload Data
    ////////////////////////////
    resetList () {
      this.currentPage = 1
      this.filtrated_data = []
      this.totalPages = 0
    },
    async reloadData () {
      await this.resetList()
      this.refilter(this.checked_data)
    },
    //////////////////////////////////////////////
    // coordinator : search student  interviews
    /////////////////////////////////////////////
    setInterview (data) {
      const studentCode = data.student_code,
        studentID = data.student_id
      // taskID = data.task_id

      this.is_interviews = true

      this.statistics.forEach((el) => {
        el.active = false
        if (el.value === 'notes_interviews') el.active = true
      })
      this.$refs.statusCounter.selected = 'notes_interviews'
      this.searchStudentCode = studentCode
      this.createInterviewStudentID = studentID
      this.searchInterview()
    },
    async searchInterview () {
      await this.resetList()
      this.$validator.validateAll('searchInterview.*').then((valid) => {
        if (valid) this.getTeacherInterviews()
        else this.errMsg()
      })
    },
    submitInterviewPost () {
      this.$validator
        .validateAll('postInterview.*')
        .then((valid) => {
          if (valid) this.createInterview()
          else this.errMsg()
        })
        .then(() => this.searchInterview())
        .catch(() => this.errMsg())
    },
    ////////////////////////
    // upload Report
    ///////////////////////
    uploadReport (e) {
      const // acceptFileTypes = ['/xlsx', '/csv', '/xls', 'image/jpg', 'image/png', 'application/pdf', 'application/vnd.ms-excel'],
        // Get file
        file = e.target.files[0],
        // Check Validation
        is_fileType_valid = file.type.includes('pdf')
      // is_fileType_valid = (acceptFileTypes.some(el => file.type.includes(el)))

      if (file && is_fileType_valid) {
        const formData = new FormData()

        formData.append('final_report', file)
        formData.append('directory', '/casFinalReports/')
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
        this.UploadFinalReport(formData, config)
      } else this.errMsg({ message: this.$t('basic.only_pdf_is_allowed') })
    }
  },
  async created () {
    await this.getCriteria().then(() => {
      if (this.is_student) {
        this.getCriteriaStudentCounter()
      } else {
        this.getCriteriaCoordinatorCounter()
      }
    })
    await this.getOutcomes().then(() => {
      if (this.is_student) {
        this.getOutcomesStudentCounter()
      } else {
        this.getOutcomesCoordinatorCounter()
      }
    })

    this.getStatus()
    if (this.is_student && this.student_id) this.checkAllowToUploadFinalReport()
    else if (this.is_coordinator) this.getStudents()
  },
  mounted () {
    if (this.is_student) {
      this.filters.pop()
    }
    this.getPdfCas()
    this.getAllClasses()
    this.tasksFilter()
  }
}
</script>
<style lang="scss">
#cas_filter {
  #round_filter_content {
    min-height: fit-content !important;
  }
}
.upload-button {
  position: relative;
  input[type="file"] {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
    cursor: pointer;
  }
}
</style>
