
export default {
  props: [],
  data () {
    return {

    }
  },
  computed: {
    /////////////////////////////
    // Active user
    ////////////////////////////
    activeUser () {
      const activeUser = {...this.authUser}
      if (activeUser.student) activeUser.photoURL = activeUser.student.photo
      activeUser.status = 'online'
      activeUser.id = activeUser.uid

      return activeUser
    },
    /////////////////////////////
    // Active Chat User
    ////////////////////////////
    chatUser () {
      return this.user || this.activeChatUser
    },
    hasRoles () {
      return Boolean(this.chatUser?.roles?.length)
    },
    hasOneRole () {
      return !this.hasRoles && this.chatUser?.role_name
    },
    canCreateDeleteGroup () {
      return this.checkUserRole('SMD')
        || this.checkUserRole('SND')
        || this.checkUserRole('Teacher')
        || this.checkUserRole('SchoolPrincipal')
        || this.checkUserRole('SectionsCoodinator')
    },
    /////////////////////////////
    // Group
    ////////////////////////////

    groupDetails () {
      return this.chatUser?.chat_details?.group || null
    },
    groupTitle () {
      return this.groupDetails?.name || null
    },
    groupID () {
      return this.chatUser?.group_id || this.groupDetails?.id || null
    },
    groupMembersBeforeCustomize () {
      return this.groupDetails?.members || this.chatGroups?.find(g => g.id === this.addMembersGroupID)?.members || null
    },
    groupMembers () {
      const members = this.groupMembersBeforeCustomize
      // if (members) members.forEach(e => { if (e.id === this.activeUser?.id) e.name = this.$t('basic.you') })
      return members
    },
    //TODO Remove sortedGroupMembers WHEN IT STORED FROM BACK
    sortedGroupMembers () {
      return this.groupMembers?.length ? this.groupMembers.sort((a, b) => {
        if (a.user_role && b.user_role) return a.user_role.localeCompare(b.user_role)
      }) : []

    },
    hasMembers () {
      return Boolean(this.groupMembers?.length)
    },
    groupMemberLength () {
      return this.hasMembers ?  this.groupMembers.length : 0
    },
    isGroup () {
      // const
      //   user = this.chatUser ,
      //   hasMembers = Boolean(user?.chat_details?.group?.members ?? user?.chat_details?.members),
      //   hasGroupID = Boolean(user?.group_id || this.activeChatGroupID)
      return this.groupID || this.hasMembers
    },
    groupModerator () {
      return  this.groupMembers.length ? this.groupMembers.find(m => m.user_role === 'a_moderator') : null
    },
    groupAdmins () {
      return  this.groupMembers.length ? this.groupMembers.filter(m => m.user_role === 'admin') : []
    },
    activeUserGroupMemberObj () {
      return (this.activeUser && this.groupMembers.length && this.groupMembers.find(m => m.id === this.activeUser.id)) || {}
    },
    isActiveUserGroupAdmin () {
      return (this.activeUser && this.groupAdmins.length && this.groupAdmins.some(a => a.id === this.activeUser.id)) || false
    },
    isActiveUserAnActiveGroupMember () {
      return this.groupMembers?.some(m => m.id === this.activeUser.id) || false
    },
    isActiveUserGroupModerator () {
      return this.groupModerator?.id === this.activeUser?.id || false
    },
    isActiveUserGroupModeratorOrAdmin () {
      return this.isActiveUserGroupAdmin || this.isActiveUserGroupModerator
    },

    isActiveUserGroupJustMember () {
      return this.activeUserGroupMemberObj.user_role === 'member'
    },
    isOnlyAdminsCanReply () {
      return this.isGroup ? Boolean(this.groupDetails.can_reply) : false
    }

  },
  methods: {
    handleActiveUserName (data, newName = '') {
      if (!data) return
      try {

      // replace admin name with you if  he is an authUser ( messages)
        const
          userName = this.activeUser.displayName,
          userID = this.activeUser.id,
          replacedName = newName || this.$t('basic.you')

        if (Array.isArray(data)) {
          data.forEach(e => {

            // If data is a group Members
            if (e.user_role) { if (e.id === userID) e.name = newName || replacedName }

            // If data is a Messages
            else if (e.is_default_message) {
              const msg = e,
                msgText = msg.content,
                isDefault = msg.is_default_message,
                hasAdminName = msgText.includes(userName)

              if (isDefault && hasAdminName) {
                msg.content = msgText.replace(userName, newName || replacedName)
              }
            }


          })
        } else data = data.replace(userName, replacedName)


        return data
      } catch (err) {
        console.error('handleActiveUserName', err)
      }


    },
    //////////////////////////
    // Check if member
    /////////////////////////
    isAdminOrModerator (userRole) {
      return userRole === 'a_moderator' || userRole === 'admin'
    },
    isNotAdminNorModerator (userRole) {
      return userRole !== 'a_moderator' || userRole !== 'admin'
    },
    isGroupModerator (userRole) {
      return userRole === 'a_moderator'
    },
    isGroupAdmin (userRole) {
      return userRole === 'a_moderator'
    },
    isMemberActiveUser (memberId) {
      return  this.activeUser.id === memberId
    },
    isMemberIsGroupAdminActiveUser (memberId, userRole) {
      return  this.activeUser.id === memberId &&  userRole === 'admin'
    }


  }
}
