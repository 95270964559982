<template>
  <ListPageWithCreateButton
    createRoutName="create-sub-academic-year"
    :showCreateBtn="checkUserRole('SND')"
    :createBtnName="$t('AcademicYears.SubAcademicYearsCreate')">
    <template slot="listDataContent">
      <data-table
        :headers_prop="headers"
        :list_url_prop="`academic/academic-years/${$route.params.id}/sub`">
      </data-table>
    </template>
  </ListPageWithCreateButton>
</template>

<script>
import DataTable from '@/components/general/datatable/DataTable2.vue'

export default {
  name: 'ListAcadimicYear',
  components: {DataTable},
  methods: {
    actions() {
      const showActionObj = {
        title: this.$t('basic.show'),
        route_name: 'show-sub-academic-year',
        getParams: (data) => ({
          id: data.academic_id,
          idSub: data._id
        }),
        icon: 'EyeIcon',
        navigate_action: true
      }
      if (this.checkUserRole('SMD')) {
        this.headers.push({
          minWidth: 140,
          headerName: this.$t('AcademicYears.Actions'),
          field: 'transactions',
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            actions_prop: [showActionObj]
          }
        })
      } else if (this.checkUserRole('SND')) {
        this.headers.push({
          minWidth: 140,
          headerName: this.$t('AcademicYears.Actions'),
          field: 'transactions',
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            actions_prop: [
              {
                title: this.$t('basic.edit'),
                route_name: 'edit-sub-academic-year',
                getParams: (data) => ({
                  id: data.academic_id,
                  idSub: data._id
                }),
                icon: 'Edit3Icon',
                navigate_action: true
              },
              showActionObj,
              {
                title: this.$t('basic.delete'),
                requestType: 'delete',
                resource: (data) =>
                  `academic/academic-years/${data.academic_id}/sub/${data._id}`,
                action: 'delete'
              }
            ]
          }
        })
      }
    }
  },
  data() {
    return {
      headers: [
        {
          minWidth: 180,
          headerName: this.$t('AcademicYears.AcademicYear'),
          field: 'name',
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true
        },
        {
          minWidth: 160,
          headerName: this.$t('AcademicYears.divisions'),
          field: 'division.name'
        },
        {
          minWidth: 160,
          headerName: this.$t('AcademicYears.section'),
          field: 'section.name'
        },
        {
          minWidth: 160,
          headerName: this.$t('AcademicYears.grad'),
          field: 'grade.name'
        },
        {
          minWidth: 190,
          headerName: this.$t('AcademicYears.CreatedBy'),
          field: 'CreatedBy'
        },
        {
          minWidth: 190,
          headerName: this.$t('form.school'),
          field: 'school.name'
        },
        {
          minWidth: 190,
          headerName: this.$t('AcademicYears.DateOfCreation'),
          field: 'created_at'
        },
        {
          minWidth: 160,
          headerName: this.$t('subject.Status'),
          field: 'status',
          width: 240,
          cellRendererFramework: 'CellRendererStatus'
        }
      ]
    }
  },

  mounted() {
    this.actions()
  }
}
</script>
