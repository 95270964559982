import announcementList from './pages/list.vue'
import announcementCreate from '../../components/announcementForm.vue'
import announcementShow from '../../components/announcementShow.vue'


export default [
  // list
  {
    path: '',
    name: 'announcementList',
    i18n: 'announcement.announcements',
    component: announcementList,
    meta: {
      breadcrumb: [
        {
          title: 'basic.dashboard',
          url: 'landing/home'
        },
        {
          title: 'basic.list',
          active: true
        }
      ],
      pageTitle: 'announcement.announcements',
      rule: 'TeacherSNDSMD'
    }
  },
  // show
  {
    path: 'show/:id',
    name: 'show-announcement',
    i18n: 'announcement.announcements',
    component: announcementShow,
    meta: {
      breadcrumb: [
        {
          title: 'basic.dashboard',
          url: 'landing/home'
        },
        {
          title: 'basic.list',
          url: '/announcement'
        },
        {
          title: 'main.show',
          active: true
        }
      ],
      pageTitle: 'main.show',
      rule: 'TeacherSND'
    }
  },
  //Create
  {
    path: 'create',
    name: 'announcementCreate',
    i18n: 'announcement.announcements',
    component: announcementCreate,
    meta: {
      breadcrumb: [
        {
          title: 'basic.dashboard',
          url: 'landing/home'
        },
        {
          title: 'basic.list',
          url: '/announcement'
        },
        {
          title: 'basic.create',
          active: true
        }
      ],
      pageTitle:  'announcement.create_announcement',
      rule: 'TeacherSND'
    }
  }
]
