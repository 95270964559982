// axios
import axios from 'axios'
import store from './store/store'

// import Event from './main'
// console.log(new eventEmiter)
// debugger

const baseUrl = process.env.VUE_APP_AXSIOS_LINK

export default axios.create({
  baseUrl
})

export function getHeaders() {
  const authUser = store.getters['auth/authUser']
  const accessToken = store.getters['auth/accessToken']
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Content-Language': store.getters['main/currentLocale']
    // 'Access-Control-Allow-Origin':'*'
  }
  if (authUser) {
    headers['Authorization'] = `Bearer ${accessToken}`
  }
  return headers
}

function refreshToken() {
  return axios.post('refresh', {
    'refresh_token': store.getters['auth/refreshToken']
  })
}

export function setupAxiosConfig() {
  axios.defaults.baseURL =
    process.env.VUE_APP_AXSIOS_LINK

  // axios.defaults.baseURL = 'http://127.0.0.1:8001/api'

  axios.interceptors.request.use(
    config => {
      // set Headers
      config.headers = getHeaders()
      return config
    },
    error => {
      return Promise.reject(error)
    }
  )

  axios.interceptors.request.use(
    config => {
      // set Headers
      config.headers = getHeaders()
      if (config.method.toLowerCase() != 'get' && !config.url.includes('update-is-seen')) {
        setTimeout(() => {
          const
            appLoaders = document.querySelectorAll('.con-vs-loading'),
            showDefaultLoader = !appLoaders.length
          if (showDefaultLoader) window.$sharedEvent.fire('show-loader')
        }, 300)

      }

      // console.log("how are you");
      // //disable all hyperlinks to prevent to many attempts
      // const links = document.querySelectorAll('a'); // Get all <a> tags on the page

      // links.forEach(link => {
      //   link.addEventListener('click', (event) => {
      //     event.preventDefault(); // Prevent the default hyperlink behavior
      //   });
      // });


      // console.log("config",config)
      return config
    },
    error => {
      window.$sharedEvent.fire(
        'hide-loader'
      )
      return Promise.reject(error)
    }
  )

  axios.interceptors.response.use(
    response => {
      window.$sharedEvent.fire(
        'hide-loader'
      )
      return response ? response.data : ''
    },
    error => {
      const data = error.response || error

      if (data.status == 401) {
        // refreshToken().then(res => {
        //   axios.defaults.headers.common[
        //     'Authorization'
        //   ] = `Bearer ${res.access_token}`
        //   store.commit(
        //     'auth/setAccessToken',
        //     res
        //   )
        //   store.commit(
        //     'auth/setRefreshToken',
        //     res
        //   )
        //   window.location.reload()
        // })
        // // store.commit('main/addErr', data.error.message)
      }
      if (data.status > 403) {
        // store.commit('main/addErr', data.error.message)
      }
      // NProgress.done();
      window.$sharedEvent.fire(
        'hide-loader'
      )

      if (data.status === 470) {
        store.commit(
          'auth/clearAuthData',
          {}
        )
        localStorage.setItem(
          'logout-login-event',
          'logout'
        )
        window.location.reload()
      }

      return Promise.reject(data)
    }
  )
}
