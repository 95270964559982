<template>
  <div id="list_datatable w-full">
    <div class="vx-row">
      <div
        class="vx-col w-full"
      >
        <!--          :headers_prop="headers"-->
        <div class="vx-card mb-2 flex justify-start p-4 flex-wrap lg:justify-around">
          <p class="font-bold mb-2 lg:mb-0">
            {{ $t('wallet.creditInWallet') }}
            :
            <span class="text-primary">{{ allTransactionWallet.data ? allTransactionWallet.data.data[0].total_amount : 0}}</span>
            {{ $t('wallet.currency') }}
          </p>
<!--          <p class="font-bold mb-2 lg:mb-0">
            {{ $t('wallet.dailyPocketMoney') }}
            :
            <span class="text-primary">{{ allTransactionWallet.data.amount ||  0}}</span>
            {{ $t('wallet.currency') }}
          </p>-->
        </div>
        <wallet-table
          :loading="loading"
          :list_items = "allTransactionWallet"
          :headers_prop="headers"
        >
        </wallet-table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import  WalletTable from '@/components/general/datatable/DataTable2.vue'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
import { commonDataMixin } from '@/mixins'
const allWalletRepository = RepositoryFactory.get(
  'walletRepository',
  'walletRepository'
)
export default {
  name: 'wallet',
  components: {
    WalletTable
  },
  props:{
    student: {
      type: Object,
      required: true
    }
  },
  mixins: [commonDataMixin],
  data () {
    return {
      filterTab:{
        id : this.student.user_id
      },
      headers: [
        {
          minWidth: 350,
          headerName: this.$t('basic.date_created'),
          field:'created_at',
          valueGetter (data) {
            return moment(data.data.created_at).format('MMMM Do YYYY, h:mm:ss a')
          }
        },
        {
          minWidth: 200,
          headerName: this.$t('basic.by'),
          field:'transable_type'
        },
        {
          minWidth: 200,
          headerName: this.$t('wallet.amount'),
          field: 'amount'
        },
        {
          minWidth: 200,
          headerName: this.$t('config.type'),
          field: 'action'
        },
        {
          minWidth: 200,
          headerName: this.$t('basic.actions'),
          filter: false,
          field: 'actions',

          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            actions_prop: [
              {
                dropDown: true,
                dropDown_items: [
                  {
                    title: this.$t("wallet.copyReferenceNumber"),
                    icon: "CopyIcon",
                    color: "danger",
                    actionEmitter: "copyReferenceNumber",
                    actionEmitterParams: [
                      "reference_number",
                    ],
                  },
                ]
              }
            ]
          }
        }
      ],
      loading: false,
      allTransactionWallet: {}
    }
  },
  methods: {
    copyReferenceNumber(data) {
      const text = `${data.reference_number}`;
      navigator.clipboard.writeText(text);
      this.successMsg("Copied sccuess");
    },
    getAllTransaction () {
      this.VSLoading()
      allWalletRepository.getAllTransaction(this.filterTab).then(res => {
        this.allTransactionWallet = res.data
      }).finally(() => {
        this.stopVSLoading()
      })
    }
  },
  watch:{
  },
  computed: {
  },
  created () {
    this.$root.$on("copyReferenceNumber", (data) => this.copyReferenceNumber(data));

    this.getAllTransaction()
  },
  beforeDestroy() {
    this.$root.$off("copyReferenceNumber");
  },
}
</script>

<style lang="scss" scoped>
.active .vx-card {
  -webkit-box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
  box-shadow: inset -7px -7px 88px -71px rgba(0, 0, 0, 0.75);
}
</style>
