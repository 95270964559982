/*--------------------------------------------------------------------------------------------
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
--------------------------------------------------------------------------------------------=*/

import Vue from 'vue'
import Router from 'vue-router'

import admission_routes from './modules/admission/admission.routes.js'
import config_routes from './modules/configuration/configuration.routes.js'
import student_routes from './modules/Students/student.routes.js'
import parent_routes from './modules/parent/parent.routes.js'
import scoreManagerRoutes from './modules/scoreManager/scoreManager.routes'
import AcademicRoutes from './modules/academic/academic.routes'
import AttendanceRoutes from './modules/attendance/attendance.routes'
import teacherRoutes from './modules/Teachers/teacher.routes'
import AuthRoutes from './modules/auth/auth.routes'
import PoiRoutes from './modules/poi/poi.routes.js'
import student_affairs_routes from './modules/student-affairs/student-affairs.routes.js'
import unitPlannerRoutes from './modules/unit-planner/unit-planner.routes.js'
import examRoutes from './modules/exam/exam.routes.js'
import SchoolPrincipalRoutes from './modules/school-principal/school-principal.routes'
import CASRoutes from './modules/cas/cas.routes'
import agendaRoutes from './modules/agenda/agenda.routes'
import chatRoutes from './modules/chat/chat.routes'
import calendarRoutes from './modules/calendar/calendar.routes'
import TeacherGetawayRoutes from './modules/teachers-gateway/teachersGateway.routes'
import GradingRoutes from './modules/grading/grading.routes'
import canteen from './modules/canteen/canteen.routes'
import wallet from './modules/wallet/wallet.routes'
import announcement from './modules/announcement/announcement.routes'
import inventory from './modules/inventory/inventory.routes'
import myDay from './modules/myDay/myDay.routes'


Vue.use(Router)
const mainLayout = () => {
  const platform = window.localStorage.getItem('platform')
  if (platform && platform === 'mobile') return import ('@/layouts/full-page/FullPage2.vue')
  else return import('./layouts/main/Main.vue')
}


const router = new Router({
  mode: 'history',
  scrollBehavior () {
    return {
      x: 0,
      y: 0
    }
  },
  routes: [
    {
      // ----------------------------------------------------------
      // MAIN LAYOUT ROUTES
      // ----------------------------------------------------------

      path: '/pages',
      component: () => mainLayout(),
      children: [
        // ----------------------------------------------------------
        // Pages Routes
        // ----------------------------------------------------------
        //  {
        //         path: 'profile',
        //         name: 'page-profile',
        //         component: () => import ('@/core/pages/Profile.vue'),
        //         meta: {
        //           breadcrumb: [
        //             {
        //               title: 'Home',
        //               url: '/'
        //             },
        //             {title: 'Pages'},
        //             {
        //               title: 'Profile',
        //               active: true
        //             }
        //           ],
        //           pageTitle: 'Profile',
        //           rule: 'SNDSMD'
        //         }
        //       },
        // {
        //   path: 'user-settings',
        //   name: 'page-user-settings',
        //   component: () => import ('@/modules/auth/user-settings/UserSettings.vue'),
        //   meta: {
        //     breadcrumb: [
        //       {
        //         title: 'Home',
        //         url: '/'
        //       },
        //       {title: 'Pages'},
        //       {
        //         title: 'User Settings',
        //         active: true
        //       }
        //     ],
        //     pageTitle: 'Settings',
        //     rule: 'SNDSMD'
        //   }
        // },
        //
        // {
        //   path: 'search',
        //   name: 'page-search',
        //   component: () => import ('@/core/pages/Search.vue'),
        //   meta: {
        //     breadcrumb: [
        //       {
        //         title: 'Home',
        //         url: '/'
        //       },
        //       {title: 'Pages'},
        //       {
        //         title: 'Search',
        //         active: true
        //       }
        //     ],
        //     pageTitle: 'Search',
        //     rule: 'SNDSMD'
        //   }
        // }
      ],
      meta: {
        rule: 'visitor'
      }
    },

    // ----------------------------------------------------------
    // Admission LAYOUT ROUTES
    // ----------------------------------------------------------

    {
      path: '/admission',
      component: () => mainLayout(),
      children: admission_routes,
      meta: {
        breadcrumb: [
          {
            title: 'Admission',
            url: '/admission',
            active: true
          }
        ],
        pageTitle: 'Admission',
        rule: 'TeacherSNDSMD'
      }
    },


    // ----------------------------------------------------------
    // Student Affairs LAYOUT ROUTES
    // ----------------------------------------------------------

    {
      path: '/student-affairs',
      component: () => mainLayout(),
      children: student_affairs_routes,
      meta: {
        breadcrumb: [
          {
            title: 'Student',
            url: '/student-affairs',
            active: true
          }
        ],
        pageTitle: 'StudentAffairs.student_affairs',
        rule: 'SNDSMD'
      }
    },
    // ----------------------------------------------------------
    // Academic LAYOUT ROUTES
    // ----------------------------------------------------------
    {
      path: '/academic',
      component: () => mainLayout(),
      children: AcademicRoutes,
      meta: {
        breadcrumb: [
          {
            title: 'Academic',
            url: '/academic',
            active: true
          }
        ],
        pageTitle: 'Academic',
        rule: 'SNDSMD'
      }
    },


    // ----------------------------------------------------------
    // Teacher Gateway LAYOUT ROUTES
    // ----------------------------------------------------------
    {
      path: '/teachers-gateway',
      component: () => mainLayout(),
      children: TeacherGetawayRoutes,
      meta: {
        // breadcrumb: [{ title: 'Teacher-Getway', url: '/Teacher-Getway', active: true }],
        // pageTitle: 'Teacher-Getway',
        rule: 'SNDSMD'
      }
    },

    {
      path: '/apply',
      component: () => import ('./layouts/full-page/FullPage.vue'),
      children: [
        // ----------------------------------------------------------
        // PAGES
        // ----------------------------------------------------------
        {
          path: 'application',
          name: 'apply',
          component: () => import ('./modules/admission/pages/register/Register'),
          meta: {
            breadcrumb: [
              {
                title: 'Admission',
                url: '/admission',
                active: true
              }
            ],
            pageTitle: 'Admission',
            rule: 'SNDSMD'
          }
        },
        {
          path: 'success',
          name: 'apply-success',
          component: () => import ('./modules/admission/pages/register/RegisterSuccess'),
          meta: {
            pageTitle: 'Success',
            rule: 'SNDSMD'
          }
        }
      ]
    },
    {
      path: '/bbb',
      component: () => mainLayout(),
      children: [
        // ----------------------------------------------------------
        // PAGES

        // ----------------------------------------------------------
        {
          path: 'create/:meeting_id',
          name: 'create',
          component: () => import ('./modules/admission/pages/bbb'),
          meta: {
            breadcrumb: [
              {
                title: 'Meeting',
                url: '/admission',
                active: true
              }
            ],
            pageTitle: 'Meeting',
            rule: 'Teacher'
          }
        },
        // ----------------------------------------------------------
        {
          path: 'join/:meeting_id',
          name: 'join',
          component: () => import ('./modules/admission/pages/bbb-join'),
          meta: {
            breadcrumb: [
              {
                title: 'Meeting',
                url: '/admission',
                active: true
              }
            ],
            pageTitle: 'Meeting',
            rule: 'Coordinator'
          }
        }
      ]
    },
    {
      path: '/braincert',
      component: () => mainLayout(),
      children: [
        // ----------------------------------------------------------
        // PAGES

        // ----------------------------------------------------------
        {
          path: 'lunch/:class_id',
          name: 'braincertLunchClass',
          component: () => import ('./modules/admission/pages/braincert.vue'),

          meta: {
            breadcrumb: [
              {
                title: 'Meeting',
                url: '/admission',
                active: true
              }
            ],
            pageTitle: 'Meeting',
            rule: 'Teacher'
          }
        },
        {
          path: 'lunch/:class_id',
          name: 'braincertJoinClass',
          component: () => import ('./modules/admission/pages/braincert.vue'),

          meta: {
            breadcrumb: [
              {
                title: 'Meeting',
                url: '/admission',
                active: true
              }
            ],
            pageTitle: 'Meeting',
            rule: 'Coordinator'
          }
        }
        // ----------------------------------------------------------

      ]
    },
    {
      path: '/quick-meeting',
      component: () => mainLayout(),
      children: [
        // ----------------------------------------------------------
        // PAGES

        // ----------------------------------------------------------
        {
          path: 'create/:meeting_id',
          name: 'create',
          component: () => import ('./modules/admission/pages/bbb-quick-meeting'),
          meta: {
            breadcrumb: [
              {
                title: 'Meeting',
                url: '/admission',
                active: true
              }
            ],
            pageTitle: 'Meeting',
            rule: 'Teacher'
          }
        },
        // ----------------------------------------------------------
        {
          path: 'join/:meeting_id',
          name: 'join',
          component: () => import ('./modules/admission/pages/bbb-join-quick-meeting'),
          meta: {
            breadcrumb: [
              // { title: 'Meeting', url: '/admission', active: true }
            ],
            pageTitle: 'Meeting',
            rule: 'visitorOrAuth',
            hideVerticalMenu: true

          }
        }
      ]
    },


    // =============================================================================
    // POI Routes
    // =============================================================================
    {
      path: '/poi',
      component: () => mainLayout(),
      children: PoiRoutes,
      meta: {
        rule: 'POISectionsCoodinator'
      }
    },
    // =============================================================================
    // Unit Planner Routes
    // =============================================================================
    {
      path: '/planner',
      component: () => import (/* webpackChunkName: "planner" */ './modules/unit-planner'),
      children: unitPlannerRoutes,
      meta: {
        rule: 'Teacher'
      }
    },
    // =============================================================================
    // Reports stand alone component
    // =============================================================================
    {
      path: '/reports',
      component: () => import (/* webpackChunkName: "planner" */ './modules/reports'),
      meta: {
        rule: 'Teacher'
      }
    },
    {
      path: '/grades',
      component: () => import (/* webpackChunkName: "planner" */ './modules/reports/grades'),
      meta: {
        rule: 'Teacher'
      }
    },
    {
      path: '/teacher/zoom-meeting/:id',
      component: () => import (/* webpackChunkName: "planner" */ './modules/Teachers/pages/dashboard/onlineClass/Meeting.vue'),
      meta: {
        rule: 'Teacher'
      }
    },
    {
      path: '/teacher/end-meeting/:id',
      component: () => import (/* webpackChunkName: "planner" */ './modules/Teachers/pages/dashboard/onlineClass/components/endMeeting.vue'),
      meta: {
        rule: 'Teacher'
      }
    },
    {
      path: '/teacher/zoom-meeting/:id',
      component: () => import (/* webpackChunkName: "planner" */ './modules/Teachers/pages/dashboard/onlineClass/Meeting.vue'),
      meta: {
        rule: 'Student'
      }
    },

    // ----------------------------------------------------------
    // Students LAYOUT ROUTES
    // ----------------------------------------------------------

    {
      path: '/student',
      component: () => mainLayout(),
      children: student_routes,
      meta: {
        breadcrumb: [
          {
            title: 'Student',
            url: '/student',
            active: true
          }
        ],
        pageTitle: 'Student',
        rule: 'Profile'
      }
    },
    // ----------------------------------------------------------
    // Parents LAYOUT ROUTES
    // ----------------------------------------------------------

    {
      path: '/parent',
      component: () => mainLayout(),
      children: parent_routes,
      meta: {
        breadcrumb: [
          {
            title: 'config.parent',
            url: '/parent',
            active: true
          }
        ],
        pageTitle: 'config.parent',
        rule: 'Parent'
      }
    },
    // ----------------------------------------------------------
    // Teachers LAYOUT ROUTES
    // ----------------------------------------------------------
    {
      path: '/teacher/',
      component: () => mainLayout(),
      children: teacherRoutes,
      meta: {
        breadcrumb: [
          {
            title: 'teacher',
            url: '/teacher',
            active: true
          }
        ],
        pageTitle: 'basic.teacher',
        rule: 'Teacher'
      }
    },
    // ----------------------------------------------------------
    // score manger LAYOUT ROUTES
    // ----------------------------------------------------------
    {
      path: '/score-manager',
      component: () => mainLayout(),
      children: scoreManagerRoutes,
      meta: {
        breadcrumb: [
          {
            title: 'score-manager',
            url: '/score-manager',
            active: true
          }
        ],
        pageTitle: 'Score-manager',
        rule: 'SNDSMD'
      }
    },
    //=============================
    {
      path: '/system-configurations',
      component: () => mainLayout(),
      children: config_routes,
      meta: {
        breadcrumb: [
          {
            title: 'config.Config',
            url: '/system-configuration',
            active: true
          }
        ],
        pageTitle: 'config.Config',
        rule: 'SNDSMD'
      }
    },
    // ----------------------------------------------------------
    // Attendance LAYOUT ROUTES
    // ----------------------------------------------------------
    {
      path: '/attendance',
      component: () => mainLayout(),
      children: AttendanceRoutes,
      meta: {
        breadcrumb: [
          {
            title: 'Attendance.attendance',
            url: '/attendance',
            active: true
          }
        ],
        pageTitle: 'Attendance.attendance',
        rule: 'SMD'
      }
    },

    // =============================================================================
    // Exams ROUTES
    // =============================================================================
    {
      path: '/exam',
      component: () => mainLayout(),
      children: examRoutes,
      meta: {
        breadcrumb: [
          {
            title: 'Create Exam',
            url: '/exam',
            active: true
          }
        ],
        pageTitle: 'Create Exam',
        rule: 'SNDSMD'
      }
    },
    // =============================================================================
    // Auth ROUTES
    // =============================================================================
    {
      path: '/landing',
      component: () => mainLayout(),
      children: AuthRoutes,
      meta: {
        breadcrumb: [
          {
            title: '',
            url: '/',
            active: true
          }
        ],
        pageTitle: 'Welcome',
        rule: 'landing'
      }
    },
    {
      name: 'login',
      path: '/login',
      component: () => import ('@/modules/auth/Login/Login.vue'),
      meta: {
        pageTitle: 'main.login',
        rule: 'visitor'
      }
    },
    //todo move it into auth module routes
    {
      name: 'login-forgot',
      path: '/auth/forgot-password',
      component: () => import ('@/modules/auth/Login/ForgotPassword.vue'),
      meta: {
        pageTitle: 'main.forgotPassword',
        rule: 'visitor'
      }
    },
    {
      name: 'login-reset-password',
      path: '/auth/reset-password/:token',
      component: () => import ('@/modules/auth/Login/ResetPassword.vue'),
      meta: {
        pageTitle: 'main.resetPassword',
        rule: 'visitor'
      }
    },

    // ----------------------------------------------------------
    // School Principal
    // ----------------------------------------------------------
    {
      path: '/SchoolPrincipal',
      component: () => mainLayout(),
      children: SchoolPrincipalRoutes,
      meta: {
        breadcrumb: [
          {
            title: 'basic.SchoolPrincipal',
            url: '/SchoolPrincipal',
            active: true
          }
        ],
        pageTitle: 'basic.SchoolPrincipal',
        rule: 'SchoolPrincipal'
      }
    },
    // ----------------------------------------------------------
    // CAS Routes
    // ----------------------------------------------------------
    {
      path: '/cas',
      component: () => mainLayout(),
      children: CASRoutes,
      meta: {
        rule: 'Coordinator'
      }
    },

    // ----------------------------------------------------------
    // Inventory Routes
    // ----------------------------------------------------------
    {
      path: '/grading/inventory',
      component: () => mainLayout(),
      children: inventory,
      meta: {
        rule: 'TeacherSNDSMD'
      }
    },
    // ----------------------------------------------------------
    // Agenda Routes
    // ----------------------------------------------------------
    {
      path: '/teacher/agenda',
      component: () => mainLayout(),
      children: agendaRoutes,
      meta: {
        breadcrumb: [
          {
            title: 'agenda.agenda',
            url: '/agenda',
            active: true
          }
        ],
        pageTitle: 'agenda.agenda',
        rule: 'SectionsCoodinator'
      }
    },
    // ----------------------------------------------------------
    // chat Routes
    // ----------------------------------------------------------
    {
      path: '/chat',
      component: () => mainLayout(),
      children: chatRoutes,
      meta: {
        // breadcrumb: [],
        // pageTitle: 'chat.chat',
        rule: 'landing'
      }
    },
    // ----------------------------------------------------------
    // Calender Routes
    // ----------------------------------------------------------
    {
      path: '/calendar',
      component: () => mainLayout(),
      children: calendarRoutes,
      meta: {

        rule: 'landing'
      }
    },
    // ----------------------------------------------------------
    // Grading Routes
    // ----------------------------------------------------------
    {
      path: '/grading',
      component: () => mainLayout(),
      children: GradingRoutes,
      meta: {
        rule: 'SchoolPrincipalSND'
      }
    },

    // ----------------------------------------------------------
    // FULL PAGE LAYOUTS
    // ----------------------------------------------------------
    {
      path: '/pages',
      component: () => import ('@/layouts/full-page/FullPage.vue'),
      children: [
        // ----------------------------------------------------------
        // PAGES
        // ----------------------------------------------------------
        //   {
        //     path: '/callback',
        //     name: 'auth-callback',
        //     component: () => import('@/core/Callback.vue'),
        //     meta: {
        //       rule: 'visitor'
        //     }
        //   },
        //   {
        //     path: 'login',
        //     name: 'page-login',
        //     component: () => import('@/core/auth/login/Login.vue'),
        //     meta: {
        //       rule: 'visitor'
        //     }
        //   },
        // {
        //   path: '/pages/register',
        //   name: 'page-register',
        //   component: () => import('@/core/auth/register/Register.vue'),
        //   meta: {
        //     rule: 'visitor'
        //   }
        // },
        // {
        //   path: '/pages/forgot-password',
        //   name: 'page-forgot-password',
        //   component: () => import('@/core/pages/ForgotPassword.vue'),
        //   meta: {
        //     rule: 'visitor'
        //   }
        // },
        // {
        //   path: '/pages/reset-password',
        //   name: 'page-reset-password',
        //   component: () => import('@/core/pages/ResetPassword.vue'),
        //   meta: {
        //     rule: 'visitor'
        //   }
        // },

        // {
        //   path: '/pages/comingsoon',
        //   name: 'page-coming-soon',
        //   component: () => import('@/core/pages/ComingSoon.vue'),
        //   meta: {
        //     rule: 'visitor'
        //   }
        // },
        {
          path: 'error-404',
          name: 'page-error-404',
          component: () => import ('@/core/pages/Error404.vue'),
          meta: {
            rule: 'visitorOrAuth'
          }
        },
        // {
        //   path: '/pages/error-500',
        //   name: 'page-error-500',
        //   component: () => import('@/core/pages/Error500.vue'),
        //   meta: {
        //     rule: 'visitor'
        //   }
        // },
        {
          path: 'not-authorized',
          name: 'page-not-authorized',
          component: () => import ('@/core/pages/NotAuthorized.vue'),
          meta: {
            rule: 'visitor'
          }
        },
        // {
        //   path: '/pages/maintenance',
        //   name: 'page-maintenance',
        //   component: () => import('@/core/pages/Maintenance.vue'),
        //   meta: {
        //     rule: 'visitor'
        //   }
        // }


        // =============================================================================
        // POI Routes
        // =============================================================================
        {
          path: '/canteen',
          component: () => mainLayout(),
          children: canteen,
          meta: {
            rule: 'TeacherSNDSMD'
          }
        },
        {
          path: '/wallet',
          component: () => mainLayout(),
          children: wallet,
          meta: {
            rule: 'TeacherSNDSMD'
          }
        },
        {
          path: '/announcement',
          component: () => mainLayout(),
          children: announcement,
          meta: {
            rule: 'TeacherSNDSMD'
          }
        },
        {
          path: '/my-day',
          component: () => mainLayout(),
          children: myDay,
          meta: {
            rule: 'TeacherSNDSMD'
          }
        }

      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '/',
      redirect: '/login'
    },
    // Redirect to 404 page, if no match found
    {
      path: '/*',
      redirect: '/pages/error-404'
    }
  ]
})

router.afterEach(() => {

  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }

  let currentPath = new URL(document.URL).pathname;
  let moduleName = currentPath.split("/")[1];
  moduleName = moduleName.replace("-", " ");
  moduleName = moduleName.charAt(0).toUpperCase() + moduleName.slice(1)
  document.title = "Dashboard | " + moduleName;
})
// //
// //
// // global navigation guard
// router.beforeEach((to, from, next) => {
//   if (to.meta.authRequired) {
//     if (!(auth.isAuthenticated())) {
//       router.push({ path: '/pages/login', query: { to: to.path } })
//     }
//   }
//
//   return next()
// })
const routeHistory = []

import store from './store/store'

// global navigation guard to validate authentication on any route
router.beforeEach((to, from, next) => {
  // setTimeout(() => {
  //
  // }, 500);

  // this.$vs.loading().close();

  // set last_route storage item
  store.commit('auth/setLastRoute', {last_route: window.location.hash})

  // Push
  const isHomePage = (from.fullPath === '/' || from.fullPath === '/#' || from.fullPath === '/#/')

  if (isHomePage && to.fullPath === '/login' && store.getters['auth/isUserLoggedIn']) {
    routeHistory.push('/landing/home')
    return next('/landing/home')
  }

  if (['login-forgot', 'login-reset-password'].includes(to.name)) {
    if (store.getters['auth/authUser']) {

      store.commit('auth/clearAuthData', {})

      window.location.reload()
    }
  }
  // if the route we navigate to not requiring authentication like login page
  // if we need a route to be not checked for auth then we add this meta attribute
  if (to.meta.rule === 'visitor' || to.meta.rule === 'visitorOrAuth') return next()
  // if we must check for validation
  else {
    // this isAuthenticated flag in vuex refer to auth status
    // if auth navigate next
    // eslint-disable-next-line no-lonely-if
    if (store.getters['auth/isUserLoggedIn']) {

      if (to.path === '/login') {
        routeHistory.push('/landing/home')
        return next('/landing/home')
      }
      if (to.path === `/login?redirect=${to.path}`) {
        routeHistory.push(`${to.path}`)
        return next(`${to.path}`)
      }

      // if (!store.getters['auth/permissions'].includes(to.meta.permission)){
      //   console.log("errrrr")
      //   next('/pages/not-authorized')
      // }
      routeHistory.push(to.name)
      return next()
    } else { //not auth and we redirect to login page
      // EV.emit('auth:needed')
      return next(`/login?redirect=${to.path}`)
    }
  }
})
// copied : https://stackoverflow.com/questions/59385650/vuejs-browser-caching-and-loading-chunk-failed-issue
router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload()
  }
})
export default router
