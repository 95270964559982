export default {
  scoreManager: 'Score Manager',
  applicableFor: 'Applicable for',
  BasicAssessmentCriteria: 'Basic assessment criteria',
  communAssessmentCriteria: 'Commun assessment criteria',
  newScoreSchema: 'Add new score managment',
  school_director: 'School Director',
  division_principal: 'Division Principal',
  division_supervisor: 'Division Supervisor',
  division_assessor: 'Division Assessor',
  other: 'Other/s',
  academic_qualities: 'Academic qualities',
  candidate_enthusiasm: 'Candidate enthusiasm',
  candidate_sports_skills: 'Candidate sports skills',
  educational_background: 'Educational background',
  leadership_skills: 'Leadership skills',
  parents_culture_fit: 'Parents Culture Fit',
  distance_from_home: 'Distance from home',
  cutome_criteria: 'Your custom Criteria',
  add_criteria_button: 'Add Criteria',
  place_holder_input_add: 'write the name of criteria ',
  schema_made_by: 'Schema made by',
  usage: 'Usage',
  last_date_modification: 'Last date modification',
  list: 'List',
  create: 'Create Score Schema'
}
