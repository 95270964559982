import Service from '../../services/unit-planner'
import ExtraFeatureModal from '../../modals/new-feature/new-feature.vue'
import StandAloneUnitModal from '../../modals/stand-alone-unit/stand-alone-unit.vue'
import {mapGetters} from 'vuex'

export default {
  name: 'UnitFeaturesOverview',
  props: ['data'],
  components: {ExtraFeatureModal, StandAloneUnitModal},
  data () {
    return {
      restService: Service,
      features: null,
      selectedFeature: null,
      unitAcademic: null,
      newCommentObj: {
        GUID: null,
        comment: '',
        user: null
      },
      changedComments: [],
      newFeatureModalEvent: 'openCreateNewFeatureModal',
      customEvents: [
        {eventName: 'addExtraFeature', callback: this.addExtraFeature},
        {eventName: 'updateStandAloneOptions', callback: this.updateStandAlonUnit}
      ]
    }
  },
  created () {
    ////////////////////////////////////////////////////
    //**          Custom Event Listener           ** //
    //////////////////////////////////////////////////
    this.customEvents.forEach(function (customEvent) {
      // eslint-disable-next-line no-undef
      $sharedEvent.listen(customEvent.eventName, customEvent.callback)
    })

    this.loadAndMapData()
  },
  destroyed () {
    ////////////////////////////////////////////////////
    //**      Remove Custom Event Listener        ** //
    //////////////////////////////////////////////////
    this.customEvents.forEach(function (customEvent) {
      // eslint-disable-next-line no-undef
      $sharedEvent.off(customEvent.eventName, customEvent.callback)
    })
  },
  methods: {
    loadAndMapData () {
      this.features = this.data
      this.mappingHelper()
    },
    mappingHelper () {
      this.features.data.unitAcademic.key_concepts['changedComments'] = []
      this.features.data.unitAcademic.related_concepts['changedComments'] = []
      this.features.data.unitAcademic.atl_skills['changedComments'] = []
      this.features.data.unitAcademic.learner_profile['changedComments'] = []
      this.features.data.unitAcademic.central_idea['changedComments'] = []
      this.features.data.unitAcademic.lines_of_inquiry['changedComments'] = []
      this.features.data.unitAcademic.extra_sections.forEach(item => {
        item['changedComments'] = []
      })
      this.unitAcademic = this.features.data.unitAcademic
      console.log('this.unitAcademic', this.unitAcademic)
    },
    setSelectedFeature (feature) {
      this.selectedFeature = feature
    },
    addCommentToFeature () {
      const obj = {...this.newCommentObj}
      obj.user = this.loggedInUser
      this.detectChanges(obj)
      this.selectedFeature.comments.push(obj)
    },
    detectChanges (comment) {
      //**Case Added New Comments **//
      if (!comment.id && !comment.GUID) {
        comment.GUID = this.uniqueID()
        this.selectedFeature.changedComments.push(comment)
      }
      //**Case Updated Comments For First Time**//
      else if (comment.id && !comment.GUID) {
        const foundedItem = this.selectedFeature.changedComments.find(item => item.id === comment.id)
        if (!foundedItem) {
          this.selectedFeature.changedComments.push(comment)
        }
      }
      //**Case Update last Created Comments after saving data again **//
      else if (!comment.id && comment.GUID && this.selectedFeature.changedComments.length === 0) {
        this.selectedFeature.changedComments.push(comment)
      }
      console.log('this.changedComments', this.selectedFeature.changedComments)
    },
    mappingData () {
      return this.selectedFeature.changedComments.map(item => {
        return {
          id: item.id || '',
          ref_type: this.selectedFeature.type,
          teacher_unit_id: this.features.teacherUnitId,
          comment: item.comment
        }
      })
    },
    save () {
      if (this.selectedFeature.changedComments.length) {
        this.restService.addCommentToOutlineFeature(this.selectedFeature.id, {comments: this.mappingData()})
          .then(res => {
            //** Reset Current Array After Saving To detect new changes **//
            this.selectedFeature.changedComments = []

            //** Replace Comments With updated or created comments with Ids from server **//
            this.selectedFeature.comments = [...res.data]

            this.$vs.notify({
              title: 'Modified Comments',
              text: 'Saved Successful',
              color: 'success',
              position: 'bottom-right'
            })
          })
          .catch(err => {
            console.error(err)
            this.$vs.notify({
              title: 'Comments Error',
              text: err.message,
              color: 'danger',
              position: 'bottom-right'
            })
          })
      }
    },
    openAddExtraFeatureModal () {
      // eslint-disable-next-line no-undef
      $sharedEvent.fire(this.newFeatureModalEvent, {...this.features})
    },
    addExtraFeature (data) {
      data['changedComments'] = []
      this.features.data.extra_features.push(data)
    },
    openEditUnitModal () {
      $sharedEvent.fire('openStandAloneUnitModal', this.features.data)
    },
    updateStandAlonUnit (data) {
      this.mapStandAlonUnitOptions(data)
    },
    mapStandAlonUnitOptions (data) {
      const extraSections = this.cloneItem(this.unitAcademic.extra_sections)
      this.unitAcademic.key_concepts.options = data.unitAcademic.key_concepts.options
      this.unitAcademic.related_concepts.options = data.unitAcademic.related_concepts.options
      this.unitAcademic.atl_skills.options = data.unitAcademic.atl_skills.options
      this.unitAcademic.learner_profile.options = data.unitAcademic.learner_profile.options
      this.unitAcademic.central_idea.value = data.unitAcademic.central_idea.value
      this.unitAcademic.lines_of_inquiry.value = data.unitAcademic.lines_of_inquiry.value

      /*this.unitAcademic.extra_sections = data.unitAcademic.extra_sections;*/
      this.unitAcademic.extra_sections.splice(0, this.unitAcademic.extra_sections.length)
      this.unitAcademic.extra_sections.push(...data.unitAcademic.extra_sections)
      this.unitAcademic.extra_sections.forEach(section => {
        const item = extraSections.find(sec => sec.id === section.id)
        if (item) {
          section['changedComments'] =  (item.changedComments) ? item.changedComments : []
          section.comments = item.comments
          /*section.value = item.value*/
        } else {
          section['changedComments'] = []
        }
      })

    }
  },
  watch: {
    data (newValue) {
      this.features = newValue
      this.mappingHelper()
    }
  },
  computed: {
    ...mapGetters({loggedInUser: 'getLoggedInUserForPlanner'})
  }
}
