var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pb-10 matiral-card"},[_c('grid-card-4',{scopedSlots:_vm._u([{key:"actionButtons",fn:function(){return [_c('vs-dropdown',{staticClass:"matiralactionButtons text-capitalize cursor-pointer",attrs:{"vs-custom-content":"","vs-trigger-click":""}},[_c('vs-button',{attrs:{"color":"#999","type":"flat","icon":"more_vert"}}),_c('vs-dropdown-menu',[(_vm.materialProp.status === 'active')?_c('vs-dropdown-item',{attrs:{"to":{
              name: 'TeachingMaterialsShare',
              params: {
                id: _vm.materialProp.id,
              },
            }}},[_c('feather-icon',{attrs:{"icon":"Share2Icon","svgClasses":"text-blue h-4 w-4 mr-1"}}),_vm._v(" "+_vm._s(_vm.$t("basic.share"))+" ")],1):_vm._e(),(
              _vm.materialProp.auth_user === 'uploader' &&
              _vm.materialProp.status === 'active'
            )?_c('vs-dropdown-item',{attrs:{"to":{
              name: 'TeachingMaterialsEdit',
              params: {
                id: _vm.materialProp.id,
              },
            }}},[_c('feather-icon',{attrs:{"icon":"Edit2Icon","svgClasses":"text-blue h-4 w-4 mr-1"}}),_vm._v(" "+_vm._s(_vm.$t("basic.edit"))+" ")],1):_vm._e(),_c('vs-dropdown-item',{attrs:{"s":""},on:{"click":function($event){return _vm.downloadFiles()}}},[_c('feather-icon',{staticClass:"inline-block",attrs:{"icon":"DownloadCloudIcon","svgClasses":"text-blue h-4 w-4 mr-1"}}),_vm._v(" "+_vm._s(_vm.$t("basic.download")))],1),[(
                _vm.materialProp.status === 'active' &&
                !_vm.materialProp.is_shared_before
              )?_c('vs-dropdown-item',{on:{"click":function($event){return _vm.changeStatus('archive')}}},[_c('feather-icon',{staticClass:"inline-block",attrs:{"icon":"ArchiveIcon","svgClasses":"text-blue h-4 w-4 mr-1"}}),_vm._v(" "+_vm._s(_vm.$t("basic.archive")))],1):_vm._e()]],2)],1)]},proxy:true},{key:"first",fn:function(){return [_c('div',{staticClass:"pb-3"},[_c('h5',{staticClass:"pb-2"},[_vm._v(_vm._s(_vm.materialProp.name))]),_c('p',[_vm._v(_vm._s(_vm.materialProp.subject_name))]),_c('div',{staticClass:"py-3"},[_c('vs-chip',{class:`status--${_vm.materialProp.status} mb-6`},[_c('span',[_vm._v(_vm._s(_vm.materialProp.status))])])],1)])]},proxy:true},{key:"second",fn:function(){return [_c('div',{staticClass:"pb-3"},[_c('h6',{staticClass:"pb-3"},[_c('feather-icon',{attrs:{"icon":"UserIcon","svgClasses":"text-blue h-4 w-4 mr-1"}}),_vm._v(" "+_vm._s(_vm.materialProp.created_by.name)+" ")],1),_c('div',{staticClass:"mb-2 text-sm"},[_c('feather-icon',{attrs:{"icon":"CalendarIcon","svgClasses":" h-4 w-4 mr-1"}}),_c('span',[_vm._v(_vm._s(_vm.$t("basic.date"))+" : "+_vm._s(_vm.materialProp.created_at)+" ")])],1)])]},proxy:true},{key:"third",fn:function(){return [_c('div',{staticClass:"pb-3"},[_c('div',_vm._l((_vm.materialProp.classes),function(oneClass){return _c('div',{key:oneClass.id},[_c('vs-chip',{staticClass:"status--primary status-text--blue inline-block my-1"},[_c('span',[_vm._v(_vm._s(oneClass.name))])])],1)}),0)])]},proxy:true},{key:"fourth",fn:function(){return _vm._l((_vm.materialProp.types),function(type){return _c('div',{key:type.id},[_c('vs-chip',{staticClass:"status--black status-text--blue block"},[_c('span',[_vm._v(_vm._s(type.name))])])],1)})},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }