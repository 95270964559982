<template>
  <div>
    <!-- Schedule Meeting popup -->
    <vs-prompt
      :title="$t('main.Schedule_Meeting')"
      @accept="submitScheduleMeeting"
      type="comfirm"
      :is-valid="ScheduleMeetingValidData"
      :active.sync="popupActive"
      @cancel="closeScheduleMeeting"
    >
      <form
        class="con-exemple-prompt"
        @submit="submitScheduleMeeting"
        @keyup.enter="submitScheduleMeeting"
      >
        <div class="mb-5">
          <vs-radio
            v-model="sheduleMeeting.is_quick_meeting"
            :vs-value="1"
            name="is_quick_meeting"
            vs-name="is_quick_meeting"
          >
            {{ $t("evaluation.setup_quick_meeting") }}
          </vs-radio>
          <vs-radio
            v-model="sheduleMeeting.is_quick_meeting"
            :vs-value="0"
            name="is_quick_meeting"
            vs-name="is_quick_meeting"
          >
            {{ $t("evaluation.setup_detailed_meeting") }}
          </vs-radio>
        </div>
        <!-- Date & Time  -->
        <template>
          <div class="mb-3">
            <label
              >{{ $t("form.choose_date") }}<sup class="text-danger">*</sup>
            </label>
            <flat-pickr
              :config="dateTimePickerConfig"
              v-model="sheduleMeeting.date"
              class="w-full"
              name="date"
              v-validate="'required'"
            />
            <span
              v-if="errors.has('date')"
              class="text-danger text-sm w-full"
              >{{ errors.first("date") | text_formatter }}</span
            >
          </div>
          <div class="mb-3">
            <label
              >{{ $t("form.choose_time") }}<sup class="text-danger">*</sup>
            </label>
            <flat-pickr
              :config="configdateTimePicker"
              v-model="sheduleMeeting.time"
              class="w-full"
              name="time"
              v-validate="'required'"
            />
            <span
              v-if="errors.has('time')"
              class="text-danger text-sm w-full"
              >{{ errors.first("time") | text_formatter }}</span
            >
          </div>
        </template>

        <!-- Details   -->
        <template v-if="sheduleMeeting.is_quick_meeting === 0">
          <div class="mb-3">
            <label
              >{{ $t("evaluation.meeting_type")
              }}<sup class="text-danger">*</sup>
            </label>
            <v-select
              :options="meeting_type"
              label="name"
              :reduce="(name) => name.id"
              name="meeting_type"
              closeOnSelect
              v-model="sheduleMeeting.type_id"
              v-validate="'required'"
            ></v-select>
            <span
              v-if="errors.has('meeting_type')"
              class="text-danger text-sm w-full"
              >{{ errors.first("meeting_type") | text_formatter }}</span
            >
          </div>
          <div class="mb-3">
            <label
              >{{ $t("evaluation.gates") }}<sup class="text-danger">*</sup>
            </label>
            <v-select
              :options="gates"
              label="name"
              :reduce="(name) => name.id"
              name="gates"
              closeOnSelect
              v-model="sheduleMeeting.gate_id"
              v-validate="'required'"
            ></v-select>
            <span
              v-if="errors.has('gates')"
              class="text-danger text-sm w-full"
              >{{ errors.first("gates") | text_formatter }}</span
            >
          </div>
          <div class="mb-3">
            <label
              >{{ $t("evaluation.buildings") }}<sup class="text-danger">*</sup>
            </label>
            <v-select
              :options="buildings"
              label="name"
              :reduce="(name) => name.id"
              name="buildings"
              closeOnSelect
              v-model="sheduleMeeting.building_id"
              v-validate="'required'"
            ></v-select>
            <span
              v-if="errors.has('buildings')"
              class="text-danger text-sm w-full"
              >{{ errors.first("buildings") | text_formatter }}</span
            >
          </div>
          <div class="mb-3">
            <label
              >{{ $t("evaluation.persons") }}<sup class="text-danger">*</sup>
            </label>
            <v-select
              :options="persons"
              label="name"
              :reduce="(name) => name.id"
              name="person"
              closeOnSelect
              v-model="sheduleMeeting.user_id"
              v-validate="'required'"
            ></v-select>
            <span
              v-if="errors.has('person')"
              class="text-danger text-sm w-full"
              >{{ errors.first("person") | text_formatter }}</span
            >
          </div>
        </template>
      </form>
    </vs-prompt>
  </div>
</template>
<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import vSelect from 'vue-select'

import { RepositoryFactory } from '@/Repositories/RepositoryFactory'

const ApplicationRepository = RepositoryFactory.get('AdmissionModule', 'applications')

export default {
  name: 'scheduleMeeting',
  components:
  {
    flatPickr,
    vSelect
  },
  props:{
    sheduleMeetingPopupActive :{
      type:Boolean,
      required:true
    },
    applicationId:{
      type:Number
    }
  },
  data () {
    return {
      meeting_type:[],
      gates:[],
      buildings:[],
      persons:[],
      sheduleMeeting:{
        'time':'',
        'date':'',
        'type_id':null,
        'gate_id':null,
        'building_id':null,
        'is_quick_meeting':1,
        'user_id':null

      },
      dateTimePickerConfig: {
        minDate:this.currentDate,
        maxDate: null,
        dateFormat: 'Y-m-d'
      },

      configdateTimePicker: {
        enableTime: true,
        enableSeconds: true,
        noCalendar: true,
        time_24hr:false
      },
      popupActive:this.sheduleMeetingPopupActive

    }
  },
  computed: {
    ScheduleMeetingValidData () {
      if (this.sheduleMeeting.is_quick_meeting === 0) {
        return  Object.values(this.sheduleMeeting).every(x => (x !== null && x !== '')) && !this.errors.any()
      } else {
        return  this.sheduleMeeting.date !== '' && this.sheduleMeeting.time !== '' && !this.errors.any()
      }
    }


  },
  watch: {
    sheduleMeetingPopupActive (val) {
      this.popupActive = val
    },
    popupActive (val) {
      if (!val) this.$emit('closeSheduleMeetingPopup', val)
    }
  },
  methods: {
    //////////////////////////////
    // APIs
    ///////////////////////////////
    getMeeting_type () {
      ApplicationRepository.meeting_type().then(res => {
        this.meeting_type = res.data
      })
        .catch(err =>  console.log('error', err))
    },
    getGates () {
      ApplicationRepository.gates().then(res => {
        this.gates = res.data
      })
        .catch(err =>  console.log('error', err))
    },
    getBuildings () {
      ApplicationRepository.buildings().then(res => {
        this.buildings = res.data
      })
        .catch(err =>  console.log('error', err))
    },
    getPersons () {
      ApplicationRepository.persons().then(res => {
        this.persons = res.users
      })
        .catch(err =>  console.log('error', err))
    },
    submitScheduleMeeting () {

      return new Promise((resolve, reject) => {
        this.$validator.validate().then(result => {
          if (result) {
            this.sheduleMeeting.application_id = this.applicationId

            ApplicationRepository.meetings(this.sheduleMeeting)
              .then(
                res => {
                  this.$root.$emit('sheduleMeetingAdded')
                  this.successMsg(`${this.$t('form.the_data_is_submitted')}`)

                  resolve(true)


                }
              )
              .catch(err => {
                reject('correct all values')
                this.errMsg(err)
              })
          } else {
            reject('correct all values')
          }
        })
      })


    },

    //////////////////////////////
    // Dialog
    //////////////////////////////
    closeScheduleMeeting () {
      this.popupActive = false
    }
  },
  mounted () {

  },
  created () {
    this.getMeeting_type()
    this.getGates()
    this.getBuildings()
    this.getPersons()

  }
}
</script>
