import axios from 'axios'

const resource = '/score-manager'
export default {
  getDropDownData () {
    return axios.get('/round/create')

  },

  getList (page = 1, search = '') {
    return axios.get(`${resource}?page=${page}&search=${search}`)
  },

  create (payload) {
    return axios.post(`${resource}`, payload)
  },

  get (id) {
    return axios.get(`${resource}/${id}`)
  },

  edit (id, payload) {
    return axios.post(`${resource}/${id}`, payload)
  },

  toggleStatus (id) {
    return axios.post(`${resource}/${id}/toggle-status`)
  },  
  delete (id) {
    return axios.delete(`${resource}/${id}`)
  }
}
