export default {
  name: 'assignmentGradesModal',
  data () {
    return {
      modalProps: {
        width: '40%',
        height: 'auto',
        minHeight: 250,
        scrollable: true
      },
      modalName: 'assignmentGradesModal',
      assignment: null,
      section: null,
      customEvents: [
        {
          eventName: 'openAssigmentGradesModal',
          callback: this.loadData
        }
      ]
    }
  },
  props: {
    assignmentItem:{
      required:true
    }
  },
  watch: {
    assignmentItem (val) {
      if (val && val.id) {
        this.loadData(val)
      }
    }
  },
  computed: {
    selectedCriteria () {
      if (this.assignment.class_teacher.section.name == 'PYP') {
        const criteria = this.assignment.criteria.filter((criteria) => {
          if (this.assignment.task.assess.criteria.includes(criteria.key)) {
            return criteria
          }
        })
        return criteria
      } else {
        return this.assignment.task.assess.criteria
      }
    }
  },

  methods: {
    loadData (data) {
      this.assignment = data
      this.section = data.task.assess.section.name
      this.showModal()
    },
    backToStudentProfile () {
      this.$router.push({
        name: 'BackToProfile',
        params: {
          id: this.authUser.uid
        }
      })
    },
    showModal () {
      this.$modal.show(this.modalName)
    },
    hideModal () {
      this.$modal.hide(this.modalName)
    },

    cancel () {
      this.hideModal()
    }

  },
  created () {
    ////////////////////////////////////////////////////
    //**          Custom Event Listener           ** //
    //////////////////////////////////////////////////
    this.customEvents.forEach(function (customEvent) {
      // eslint-disable-next-line no-undef
      window.$sharedEvent.listen(customEvent.eventName, customEvent.callback)

    })
  },
  destroyed () {
    ////////////////////////////////////////////////////
    //**      Remove Custom Event Listener        ** //
    //////////////////////////////////////////////////
    this.customEvents.forEach(function (customEvent) {
      // eslint-disable-next-line no-undef
      window.$sharedEvent.off(customEvent.eventName, customEvent.callback)
    })
  }
}
