<template>
  <vx-card class="p-6" :showLoading="loadingCard">
    <form class="lg:w-2/3 w-full">
      <div class="mb-5">
        <label
          >{{ $t('form.choose_subject') }}
          <sup class="text-danger">*</sup>
        </label>
        <v-select
          :options="subjects"
          label="name"
          :reduce="(s) => s.id"
          v-model="data.subject_id"
          class="w-full"
          :clearable="false"
          :showLoadingIcon="loadingSubjects"
          :disabled="disabledForm"
          v-validate="'required'"
          name="subject"
          @change="changeSubject"
        />
        <span class="text-danger text-sm">{{
          errors.first('subject') | text_formatter
        }}</span>
      </div>
      <div class="mb-5">
        <label>
          {{ $t('basic.add_description') }}
          <sup class="text-danger">*</sup></label
        >
        <div class="mb-5">
          <vx-input-group>
            <vs-input
              v-model="newDescription"
              class="w-full"
              @keyup.enter="addDescriptionRow()"
              :disabled="disabledForm"
            />
            <!-- Add Another -->
            <template v-slot:append>
              <div class="append-text btn-addon">
                <vs-button
                  class="py-1"
                  type="border"
                  icon-pack="feather"
                  icon="icon-plus"
                  @click="addDescriptionRow()"
                />
              </div>
            </template>
          </vx-input-group>
          <!--          <span class="text-danger text-sm "-->
          <!--                v-if="validForm && invalidStrands">{{-->
          <!--              $t('form.descriptions_is_required')-->
          <!--            }}-->
          <!--          </span>-->
        </div>
        <div>
          <label> {{ $t('basic.all_descriptions') }}</label>
          <div
            class="mb-2 flex justify-between items-center border-t-2"
            v-for="(strand, i) in data.strands"
            :key="i"
          >
            <label class="">
              {{ strand.description }}
            </label>
            <vs-button
              type="flat"
              color="danger"
              icon-pack="feather"
              icon="icon-x"
              @click="removeDescriptionRow(i)"
            />
          </div>
        </div>
      </div>

      <!-- Save -->

      <div
        class="flex flex-wrap md:justify-between justify-center items-center md:flex-row flex-col mt-10"
      >
        <vs-button
          color="warning"
          type="border"
          :to="{ name: 'SubjectsList' }"
          class="mt-5"
          >{{ $t('form.cancel') }}</vs-button
        >

        <vs-button
          @click="submit"
          class="vs-con-loading__container mt-5"
          ref="submitLoader"
          id="submitLoader"
          >{{ this.editMode ? $t('form.update') : $t('form.save') }}</vs-button
        >
      </div>
    </form>
  </vx-card>
</template>

<script>
// Repos
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const SubjectsRepo = RepositoryFactory.get('AcademicModule', 'Subjects')

export default {
  name: 'strands-form',
  data: () => ({
    //////////////////////
    // loading
    //////////////////////
    loadingCard: false,
    loadingSubjects: false,
    //////////////////////
    // subjects
    /////////////////////
    subjects: [],
    prevSelectedSubject: null,
    //////////////////////
    // strands
    /////////////////////
    editMode: false,
    data: {
      subject_id: null,
      strands: [], // {description:null}
    },
    newDescription: null,
  }),
  computed: {
    routeSubjectId() {
      return this.$route.query.subject_id
    },
    invalidStrands() {
      return (
        !this.data.strands.length ||
        this.data.strands.some((e) => !e.description)
      )
    },
    isSubjectChanged() {
      return this.data.subject_id !== this.prevSelectedSubject
    },
    disabledForm() {
      return !this.subjects.length || this.loadSubmit
    },
    // validForm () {
    //   return !this.errors.any()
    // }
  },
  watch: {},
  methods: {
    /////////////////////
    // API
    ////////////////////
    getAllSubjects() {
      this.loadingSubjects = true
      SubjectsRepo.getAllSubjects()
        .then((res) => {
          this.subjects = res.data
          if (this.routeSubjectId)
            this.data.subject_id = Number(this.routeSubjectId)
        })
        .catch(this.errMsg)
        .finally(() => {
          this.loadingSubjects = false
        })
    },
    addSubjectStrands() {
      this.startLoadingSubmit()
      SubjectsRepo.addSubjectStrands(this.data)
        .then(() => {
          this.successMsg()
          this.handleAfterSaveData()
        })
        .catch(this.errMsg)
        .finally(this.endLoadingSubmit)
    },
    getSubjectStrands() {
      this.loadingCard = true
      SubjectsRepo.getSubjectStrands(this.data.subject_id)
        .then((res) => {
          this.handleEditScenario(res.data)

          this.handleAfterSaveData()
        })
        .catch(this.errMsg)
        .finally(() => {
          this.loadingCard = false
        })
    },
    updateSubjectStrands() {
      this.startLoadingSubmit()
      SubjectsRepo.updateSubjectStrands(this.data)
        .then(() => {
          this.successMsg()
          this.handleAfterSaveData()
        })
        .catch(this.errMsg)
        .finally(this.endLoadingSubmit)
    },
    ///////////////////////
    // On change
    //////////////////////

    addDescriptionRow() {
      if (!this.newDescription) return
      this.data.strands.unshift({ description: this.newDescription })
      this.newDescription = null
    },
    removeDescriptionRow(i) {
      this.data.strands.splice(i, 1)
    },
    changeSubject() {
      this.resetStrand()
      this.getSubjectStrands()
    },
    ////////////////////////
    // set Data
    ////////////////////////
    handleEditScenario(data) {
      this.data.strands = data
    },
    handleAfterSaveData() {
      this.prevSelectedSubject = this.data.subject_id
      if (this.data.strands.length) this.editMode = true
    },
    ////////////////////////
    // Reset Data
    ////////////////////////
    resetStrand() {
      this.newDescription = null
      this.data.strands = []
      this.editMode = false
    },
    ////////////////////////
    // Submit
    ////////////////////////
    submit() {
      this.$validator.validateAll().then((valid) => {
        if (valid && !this.invalidStrands) {
          if (this.editMode) this.updateSubjectStrands()
          else this.addSubjectStrands()
        } else if (this.invalidStrands)
          this.errMsg(this.$t('form.descriptions_is_required'))
        else this.errMsg()
      })
    },
  },
  created() {
    this.getAllSubjects()
  },
  mounted() {
    if (this.routeSubjectId) {
      this.data.subject_id = this.prevSelectedSubject = this.routeSubjectId
      this.getSubjectStrands()
    }
  },
}
</script>

<style scoped></style>
