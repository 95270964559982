<template>
  <vx-card class="p-3 pt-6 pb-6 md:p-6" :showLoading="loadingCard">
    <!-- Division -->
    <div class="vx-row mb-6">
      <div class="vx-col sm:w-1/4 w-full">
        <label>
          {{ $t('subject.ChooseDivision') }}
          <sup class="text-danger">*</sup>
        </label>
      </div>
      <div class="vx-col sm:w-1/2 w-full">
        <v-select
          closeOnSelect
          @input="changeDivision"
          class="selectExample w-full"
          v-model="boundary.division_id"
          name="Division"
          v-validate="'required'"
          :options="divisions"
          label="long_name"
          :reduce="(long_name) => long_name.en.division_id"
          :showLoadingIcon="loadingDivisions" />
        <span v-if="errors.has('Division')" class="text-danger text-sm">{{
          errors.first('Division') | text_formatter
        }}</span>
      </div>
    </div>
    <!-- Sections -->
    <div class="vx-row mb-6">
      <div class="vx-col sm:w-1/4 w-full">
        <label>
          {{ $t('AcademicYears.chooseSections') }}
          <sup class="text-danger">*</sup>
        </label>
      </div>
      <div class="vx-col sm:w-1/2 w-full">
        <v-select
          closeOnSelect
          @input="changeSections"
          v-model="boundary.section_id"
          class="selectExample w-full"
          name="section"
          v-validate="'required'"
          :options="sections"
          label="name"
          :reduce="(name) => name.id"
          :disabled="!boundary.division_id || !sections.length"
          :showLoadingIcon="loadingSections" />
        <span v-if="errors.has('section')" class="text-danger text-sm">{{
          errors.first('section') | text_formatter
        }}</span>
      </div>
    </div>
    <!-- Grades -->
    <div class="vx-row mb-6">
      <div class="vx-col sm:w-1/4 w-full">
        <label>
          {{ $t('subject.ChooseGrads') }}
          <sup class="text-danger">*</sup>
        </label>
      </div>
      <div class="vx-col sm:w-1/2 w-full">
        <v-select
          closeOnSelect
          multiple
          v-model="boundary.grades"
          class="selectExample w-full"
          name="Grad"
          v-validate="'required'"
          :options="grades"
          label="name"
          :reduce="(name) => name.id"
          :disabled="!boundary.section_id || !grades.length"
          @input="getSubjects"
          :showLoadingIcon="loadingGrades" />
        <span v-if="errors.has('Grad')" class="text-danger text-sm">{{
          errors.first('Grad') | text_formatter
        }}</span>
      </div>
    </div>

    <!--    Subjects -->
    <div class="vx-row mb-6" v-if="isDP">
      <div class="vx-col sm:w-1/4 w-full">
        <label>
          {{ $t('form.choose_subject') }}
          <sup class="text-danger">*</sup>
        </label>
      </div>
      <div class="vx-col sm:w-1/2 w-full">
        <v-select
          closeOnSelect
          multiple
          v-model="boundary.division_subjects"
          class="selectExample w-full"
          name="subjects"
          v-validate="'required'"
          :options="subjects"
          label="name"
          :reduce="(name) => name.id"
          :disabled="!boundary.grades.length || !subjects.length"
          :showLoadingIcon="loadingSubjects" />
        <span v-if="errors.has('subjects')" class="text-danger text-sm">{{
          errors.first('subjects') | text_formatter
        }}</span>
      </div>
    </div>

    <!-- Limits -->
    <div class="vx-row mb-6">
      <vs-divider class="vx-col w-full" />
      <div class="vx-col w-full mt-4 mb-5">
        <h6>{{ $t('boundariesAndDescriptors.boundariesLimites') }}</h6>
      </div>
      <!--    Options -->
      <div class="vx-col w-full mb-4">
        <div class="vx-row mb-4">
          <!--          <div class="vx-col w-full mb-4">-->
          <!--            <label>{{ $t("form.configuration") }}</label>-->
          <!--          </div>-->
          <div class="vx-col lg:w-1/4 md:w-1/3 mb-2 w-full flex">
            <vs-switch v-model="show_dscriptor" />
            <label class="mr-2">
              {{ $t('boundariesAndDescriptors.show_descriptor') }}
            </label>
          </div>
          <div class="vx-col lg:w-1/4 md:w-1/3 mb-2 w-full flex">
            <vs-switch v-model="show_local_grade" />
            <label class="mr-2">
              {{ $t('boundariesAndDescriptors.show_local_grade') }}
            </label>
          </div>
          <div class="vx-col lg:w-1/4 md:w-1/3 mb-2 w-full flex">
            <vs-switch v-model="show_grade_weight" />
            <label class="mr-2">
              {{ $t('boundariesAndDescriptors.apply_grade_weight') }}
            </label>
          </div>
        </div>
      </div>
      <!--  Add limit -->
      <div class="vx-col w-full mb-4">
        <label>
          {{ $t('boundariesAndDescriptors.add_limit') }}
          <sup class="text-danger">*</sup>
        </label>
        <vx-input-group>
          <div class="flex lg:flex-row flex-col mb-2 items-center pr-0">
            <div class="lg:mr-2 mb-1 lg:w-2/12 w-full lg:mb-0 mb-2">
              <vs-input
                :placeholder="`${$t('boundariesAndDescriptors.min')}`"
                name="min"
                type="number"
                v-model="limits.min" />
            </div>
            <div class="lg:mr-2 mb-1 lg:w-2/12 w-full lg:mb-0 mb-2">
              <vs-input
                :placeholder="`${$t('boundariesAndDescriptors.max')}`"
                name="max"
                type="number"
                v-model="limits.max" />
            </div>
            <div class="lg:mr-2 mb-1 lg:w-2/12 w-full lg:mb-0 mb-2">
              <vs-input
                :placeholder="`${$t('basic.grade')}`"
                name="grade"
                v-model="limits.grade" />
            </div>
            <div
              class="lg:mr-2 mb-1 lg:w-2/12 w-full lg:mb-0 mb-2"
              v-if="show_grade_weight">
              <vs-input
                :placeholder="`${$t('boundariesAndDescriptors.grade_weight')}`"
                name="grade_weight"
                v-model="limits.grade_weight"
                type="number" />
            </div>
            <div
              class="lg:mr-2 mb-1 lg:w-4/12 w-full lg:mb-0 mb-2"
              v-if="show_dscriptor">
              <vs-input
                :placeholder="`${$t('boundariesAndDescriptors.descriptor')}`"
                name="descriptor"
                v-model="limits.dscriptor" />
            </div>
            <div
              class="lg:mr-2 mb-1 lg:w-2/12 w-full lg:mb-0 mb-2"
              v-if="show_local_grade">
              <vs-input
                :placeholder="`${$t('boundariesAndDescriptors.local_grade')}`"
                name="local_grade"
                v-model="limits.local_grade" />
            </div>
            <div class="lg:mr-2 mb-1 lg:w-1/12 w-full lg:mb-0 mb-2">
              <vs-button
                color="primary"
                type="border"
                @click="addNewRowOfData()"
                icon-pack="feather"
                icon="icon-plus"
                class="w-full"></vs-button>
            </div>
          </div>

          <!--          <template slot="append">
            <div class="append-text btn-addon sm:w-2/12 w-full">
              <vs-button
                color="primary"
                type="border"
                @click="addNewRowOfData()"
                icon-pack="feather"
                icon="icon-plus"
              ></vs-button>
            </div>
          </template>-->
        </vx-input-group>
      </div>
      <!--  show limits -->
      <div class="vx-col w-full">
        <label class="my-2">{{
          $t('boundariesAndDescriptors.all_limits')
        }}</label>
        <template v-if="boundary.limits.length">
          <vx-input-group
            class="mb-2"
            :key="index"
            v-for="(data, index) in boundary.limits">
            <div class="flex lg:flex-row flex-col w-full items-center">
              <div class="lg:mr-2 mb-1 lg:w-2/12 w-full lg:mb-0 mb-2">
                <vs-input
                  :placeholder="`${$t('boundariesAndDescriptors.min')}`"
                  name="min"
                  v-model="data.min"
                  readonly />
              </div>
              <div class="lg:mr-2 mb-1 lg:w-2/12 w-full lg:mb-0 mb-2">
                <vs-input
                  :placeholder="`${$t('boundariesAndDescriptors.max')}`"
                  name="max"
                  v-model="data.max"
                  readonly />
              </div>
              <div class="lg:mr-2 mb-1 lg:w-2/12 w-full lg:mb-0 mb-2">
                <vs-input
                  :placeholder="`${$t('basic.grade')}`"
                  name="grade"
                  v-model="data.grade"
                  readonly />
              </div>
              <div
                class="lg:mr-2 mb-1 lg:w-2/12 w-full lg:mb-0 mb-2"
                v-if="show_grade_weight">
                <vs-input
                  :placeholder="`${$t(
                    'boundariesAndDescriptors.grade_weight'
                  )}`"
                  name="descriptor"
                  v-model="data.grade_weight"
                  :danger="data.grade_weight === null"
                  type="number"
                  v-validate="'required'" />
              </div>
              <div
                class="lg:mr-2 mb-1 lg:w-4/12 w-full lg:mb-0 mb-2"
                v-if="show_dscriptor">
                <vs-input
                  :placeholder="`${$t('boundariesAndDescriptors.descriptor')}`"
                  name="descriptor"
                  v-model="data.dscriptor"
                  :danger="!data.dscriptor"
                  v-validate="'required'" />
              </div>
              <div
                class="lg:mr-2 mb-1 lg:w-2/12 w-full lg:mb-0 mb-2"
                v-if="show_local_grade">
                <vs-input
                  :placeholder="`${$t('boundariesAndDescriptors.local_grade')}`"
                  name="local_grade"
                  v-model="data.local_grade"
                  :danger="!data.local_grade"
                  v-validate="'required'" />
              </div>
              <div class="lg:mr-2 mb-1 lg:w-1/12 w-full lg:mb-0 mb-2">
                <vs-button
                  v-if="data.hasOwnProperty('id')"
                  color="danger"
                  type="border"
                  @click="openPromptDeleteRow(data.id, index)"
                  icon-pack="feather"
                  icon="icon-minus"></vs-button>
                <vs-button
                  v-else
                  color="danger"
                  type="border"
                  @click="removeRowOfData(index)"
                  icon-pack="feather"
                  icon="icon-minus"></vs-button>
              </div>
            </div>
            <!--          <template slot="append">
            <div class="append-text btn-addon">
              <vs-button
                v-if="data.hasOwnProperty('id')"
                color="danger"
                type="border"
                @click="openPromptDeleteRow(data.id, index)"
                icon-pack="feather"
                icon="icon-minus"
              ></vs-button>
              <vs-button
                v-else
                color="danger"
                type="border"
                @click="removeRowOfData(index)"
                icon-pack="feather"
                icon="icon-minus"
              ></vs-button>
            </div>
          </template>-->
          </vx-input-group>
        </template>
      </div>
    </div>

    <!-- actions -->
    <div class="flex flex-wrap justify-between items-center">
      <vs-button
        color="warning"
        @click="$router.push({name: 'boundariesAndDescriptors'})"
        type="border"
        >{{ $t('basic.cancel') }}</vs-button
      >
      <vs-button
        @click="submitForm($event, true)"
        v-if="showDuplicateBtn"
        color="gray"
        :disabled="isLoading"
        >{{ $t('basic.duplicate') }}
      </vs-button>
      <vs-button @click="submitForm" :disabled="isLoading">{{
        editId ? $t('basic.update') : $t('basic.submit')
      }}</vs-button>
    </div>

    <vs-prompt
      @cancel="
        deleteRowId = ''
        openPrompt = false
      "
      @accept="removeRowOfDataFromServer"
      :active.sync="openPrompt"
      title="Remove"
      type="accept"
      accept-text="Remove">
      <div class="con-exemple-prompt">
        <p>{{ $t('boundariesAndDescriptors.areYouSureToDeleteThisLimit') }}</p>
      </div>
    </vs-prompt>
  </vx-card>
</template>

<script>
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const DivisionRepository = RepositoryFactory.get(
  'ConfigurationModule',
  'division'
)
const boundariesAndDescriptors = RepositoryFactory.get(
  'boundariesAndDescriptors',
  'boundariesAndDescriptors'
)
import {commonDataMixin} from '@/mixins'

export default {
  name: 'Create',
  components: {},
  props: {
    editData: {
      default: false
    }
  },
  mixins: [commonDataMixin],
  data() {
    return {
      ///////////////////
      // Data
      //////////////////
      divisions: [],
      sections: [],
      subjects: [],
      grades: [],
      ///////////////////
      // Boundary
      ///////////////////
      limits: {
        min: null,
        max: null,
        grade: null,
        local_grade: null,
        dscriptor: '',
        grade_weight: null
      },
      deleteRowId: '',
      deleteIndex: '',
      openPrompt: false,
      boundary: {
        division_id: null,
        section_id: null,
        grades: [],
        limits: [],
        division_subjects: []
      },
      editBoundaryData: {},
      /////////////////////////
      // Configuration
      ////////////////////////
      show_dscriptor: true,
      show_local_grade: true,
      show_grade_weight: false,
      /////////////////////////
      // Loading
      ////////////////////////
      loadingCard: false,
      loadingDivisions: false,
      loadingSections: false,
      loadingGrades: false,
      loadingSubjects: false
    }
  },
  created() {
    this.getDivisions()
    // console.log(this.editData)
    if (this.editData) {
      this.fillAllData()
    }
  },
  mounted() {
    // console.log(this.editData)
  },
  computed: {
    isAmerican() {
      return this.boundary.division_id === 2
    },
    isIB() {
      return this.boundary.division_id === 1
    },
    isDP() {
      return this.isIB && this.boundary.section_id === 3
    },
    editId() {
      return this.$route.params.id
    },
    isLoading() {
      return (
        this.loadingCard ||
        this.loadingDivisions ||
        this.loadingSections ||
        this.loadingGrades ||
        this.loadingSubjects
      )
    },

    showDuplicateBtn() {
      return (
        this.editId &&
        (Boolean(
          this.editBoundaryData?.academic_year?.status === 'Active' ||
            this.editBoundaryData?.academic_year?.status === 'active'
        ) ||
          this.currentAcademicYearId ===
            this.editBoundaryData?.academic_year?.id)
      )
    }
  },
  methods: {
    ///////////////////////
    // APIs
    ///////////////////////
    getDivisions() {
      this.loadingDivisions = true

      DivisionRepository.getdata()
        .then((res) => {
          this.divisions = res.data.data
        })
        .catch(this.errMsg)
        .finally(() => {
          this.loadingDivisions = false
        })
    },
    changeDivision() {
      this.boundary.section_id = null

      this.show_grade_weight = this.isAmerican

      this.loadingSections = true
      this.getFilterData({divisions: [this.boundary.division_id]})
        .then((res) => {
          this.sections = res.sections
        })
        .catch(this.errMsg)
        .finally(() => {
          this.loadingSections = false
        })
    },
    changeSections() {
      this.boundary.grades = []
      this.loadingGrades = true
      this.getFilterData({
        divisions: [this.boundary.division_id],
        sections: [this.boundary.section_id]
      })
        .then((res) => {
          this.grades = res.grades
        })
        .catch(this.errMsg)
        .finally(() => {
          this.loadingGrades = false
        })
    },
    getSubjects() {
      if (!this.boundary.grades.length) return
      this.boundary.division_subjects = []
      this.loadingSubjects = true
      boundariesAndDescriptors
        .getDivisionSubjects(this.boundary.grades)
        .then((res) => {
          this.subjects = res.data
          this.handleDivisionSubjects()
        })
        .catch(this.errMsg)
        .finally(() => {
          this.loadingSubjects = false
        })
    },
    create(payload) {
      payload.academic_year_id = this.currentAcademicYearId
      this.VSLoading()
      boundariesAndDescriptors
        .addNewBoundary(payload)
        .then(() => {
          this.successMsg(
            this.$t(
              'boundariesAndDescriptors.addBoundariesAndDescriptorsSuccessfully'
            )
          )
          this.$router.push({name: 'boundariesAndDescriptors'})
        })
        .catch((err) => {
          this.errMsg(err)
        })
        .finally(() => {
          this.stopVSLoading()
        })
    },
    ///////////////////////////
    // Limits
    //////////////////////////
    addNewRowOfData() {
      const requiredData =
          this.limits.min && this.limits.max && this.limits.grade,
        checkLimitsProp = (propName) =>
          (this[`show_${propName}`] && this.limits[propName]) ||
          !this[`show_${propName}`],
        checklocalGrade = checkLimitsProp('local_grade'),
        checkDescriptor = checkLimitsProp('dscriptor'),
        checkGradeWeight = checkLimitsProp('grade_weight')

      if (
        requiredData &&
        checklocalGrade &&
        checkDescriptor &&
        checkGradeWeight
      ) {
        this.boundary.limits.push(this.limits)
        this.limits = {
          min: null,
          max: null,
          grade: null,
          local_grade: null,
          dscriptor: '',
          grade_weight: null
        }
      } else {
        this.errMsg()
      }
    },
    removeRowOfData(index) {
      this.boundary.limits.splice(index, 1)
    },
    openPromptDeleteRow(id, index) {
      this.deleteRowId = id
      this.deleteIndex = index
      this.openPrompt = true
    },
    removeRowOfDataFromServer() {
      this.VSLoading()
      boundariesAndDescriptors
        .deleteLimit(this.$route.params.id, this.deleteRowId)
        .then(() => {
          this.removeRowOfData(this.deleteIndex)
          this.successMsg(
            this.$t('boundariesAndDescriptors.removeLimitSuccessfully')
          )
        })
        .catch((err) => {
          this.errMsg(err)
        })
        .finally(() => {
          this.stopVSLoading()
        })
    },
    ///////////////////////////
    // Submit Data
    //////////////////////////
    async submitForm($event, duplicate = false) {
      await (this.loadingCard = true)
      this.$validator
        .validate()
        .then((valid) => {
          // console.log('ssssss', valid, this.boundary.limits.length > 0)
          if (valid && this.boundary.limits.length > 0) {
            if (!this.show_dscriptor) {
              this.boundary.limits.forEach((e) => {
                e.dscriptor = null
              })
            } else if (this.boundary.limits.some((l) => !l.dscriptor)) {
              this.errMsg()
              return
            }
            if (!this.show_local_grade) {
              this.boundary.limits.forEach((e) => {
                e.local_grade = null
              })
            } else if (this.boundary.limits.some((l) => !l.local_grade)) {
              this.errMsg()
              return
            }
            this.boundary.limits.forEach((b) => {
              b.max = Number(b.max)
              b.min = Number(b.min)
              b.grade = isNaN(b.grade) ? b.grade : Number(b.grade)
              if (!this.show_grade_weight) b.grade_weight = null
            })

            if (duplicate || !this.editId) this.create(this.boundary)
            else
              this.$emit('submitForm', {
                data: this.boundary,
                boundaryId: this.$route.params.id
              })
          } else this.errMsg()
        })
        .catch(this.errMsg)
        .finally(() => {
          this.loadingCard = false
        })
    },
    ////////////////////////////
    // Handle Data
    //////////////////////////
    handleDivisionSubjects() {
      if (!this.boundary.division_subjects.length && !this.isDP) {
        this.boundary.division_subjects = this.subjects.map((e) => e.id)
      }
    },
    // //////////////////////////
    // Edit
    //////////////////////////
    async fillAllData() {
      await (this.loadingCard = true)
      boundariesAndDescriptors
        .showData(this.$route.params.id)
        .then((res) => {
          res.data.limits.map((data) => {
            // console.log(data)
            this.boundary.limits.push({
              ...data,
              min: Number(data.min),
              max: Number(data.max),
              grade_weight:
                data.grade_weight !== null ? Number(data.grade_weight) : null
            })
          })
          this.boundary.division_id = res.data.division.id
          this.boundary.section_id = res.data.section.id
          this.boundary.grades = res.data.grades.map((data) => data.id)
          this.boundary.division_subjects = res.data.division_subjects.map(
            (d) => d.id
          )
          this.editBoundaryData = res.data

          //----- Get sections
          this.loadingSections = true
          this.getFilterData({divisions: [res.data.division.id]})
            .then((res) => {
              this.sections = res.sections
            })
            .finally(() => {
              this.loadingSections = false
            })

          //----- Get Grades
          this.loadingGrades = true
          this.getFilterData({
            divisions: [res.data.division.id],
            sections: [res.data.section.id]
          })
            .then((res) => {
              this.grades = res.grades
            })
            .finally(() => {
              this.loadingGrades = false
            })

          //----- Get Subjects
          this.loadingSubjects = true
          boundariesAndDescriptors
            .getDivisionSubjects(this.boundary.grades)
            .then((res) => {
              this.subjects = res.data
              this.handleDivisionSubjects()
            })
            .finally(() => {
              this.loadingSubjects = false
            })

          //Limits Senaios
          if (this.boundary.limits[0]?.dscriptor === null)
            this.show_dscriptor = false
          if (this.boundary.limits[0]?.local_grade === null)
            this.show_local_grade = false
          if (this.boundary.limits[0]?.grade_weight !== null)
            this.show_grade_weight = true
        })
        .catch(this.errMsg)
        .finally(() => {
          this.loadingCard = false
        })
    }
  },
  watch: {}
}
</script>

<style scoped>
.background-none {
  background-color: transparent !important;
  color: rgb(160, 152, 152);
  cursor: pointer;
}
.pr-0 {
  padding-right: 0 !important;
}
.includeIconOnly {
  width: inherit !important;
}
@media (min-width: 1024px) {
  .includeIconOnly {
    width: auto !important;
  }
}
</style>
