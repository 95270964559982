<template>
  <vs-chip
    :class="`ag-grid-cell-chip
  status--${status}`"
  >
    <!-- :color="chipColor(params.value.toLowerCase())" -->

    <span>{{ status | text_formatter }}</span>
    <!-- status_prop.find(elm=>elm.value===(params.value).toString()).title  -->
    <!-- <span v-for="(status, i) in this.params.status_prop" :key="i">status {{i}}</span> -->
  </vs-chip>
</template>

<script>
export default {
  name: 'CellRendererStatus',
  data () {
    return {}
  },

  computed: {
    status_prop () {
      return this.params.colDef.cellRendererParams.status_prop
    },
    data () {
      return this.params.value
    },
    status () {
      if (this.data) {
        if (isNaN(this.data)) {
          return this.data?.toLowerCase().replace(' ', '_') || this.data.toLowerCase().replaceAll(' ', '_')
        } else  {
          return this.status_prop?.find(elm => elm.value === this.data).title
        }
      }
    }
  //   chipColor() {
  //     return (value) => {
  //       //////////////// Common Status ////////////////
  //       if (value === "active") return "success";
  //       else if (value === "blocked") return "danger";
  //       else if (value === "deactivated") return "warning";
  //       else if (value === "archived") return "gray";
  //       //////////////// Academic Yeat Status ////////////////
  //       else if (value === "new") return "success";
  //       //////////////// Syllabus Status ////////////////
  //       else if (value === "suspended") return "orange";
  //       else if (value === "closed") return "danger";
  //       //////////////// Attendance Status ////////////////
  //       else if (value === "unfinished") return "danger";
  //       else return "primary";
  //     };
  //   },
  //   status_prop() {
  //     return this.params.status_prop;
  //   },
  },
  created () {
  }
}
</script>

