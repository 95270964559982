<template >
  <separatedPage :componentName="dynamicComponent"></separatedPage>
</template>
<script>
import separatedPage from './Components/SeparatedPage.vue'
import tasks from '@/modules/Students/pages/student/Components/Left-Sidebar/tasks/tasks.vue'
export default {
  name:'student-report-cards',
  components:{
    separatedPage,
    tasks
  },
  data: () => ({
    dynamicComponent:tasks
  })
}
</script>
<style lang="">
</style>
