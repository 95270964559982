<template>
  <ListPageWithCreateButton :showCreateBtn="false">
    <template slot="listDataContent">
      <data-table
        v-if="subjectList.length"
        :headers_prop="headers"
        :list_items="subjectList"
        :allowPagination="false"
        :allow_print_CSV="true"
        :CSVFileName="(data) => data.gradeName + ' - Syllabus'"
        list_url_prop="">
        <template slot="center_content"></template>
      </data-table>
    </template>
  </ListPageWithCreateButton>
</template>

<script>
import DataTable from '@/components/general/datatable/DataTable2.vue'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const SyllabusRepository = RepositoryFactory.get('AcademicModule', 'syllabus')

export default {
  name: 'SyllabusAssignTeacherList',
  components: {DataTable},
  data() {
    return {
      headers: [
        {
          minWidth: 100,
          headerName: 'ID',
          // field: 'id',
          field: 'subject_id',
          width: 150,
          headerCheckboxSelectionFilteredOnly: true
        },
        {
          minWidth: 130,
          headerName: this.$t('subject.Subjects'),
          field: 'subjectName',
          width: 200
        },
        // {
        //   minWidth: 130,
        //   headerName: this.$t('Syllabus.syllabusCode'),
        //   field: 'syllabus_id',
        //   width: 200
        // },
        {
          minWidth: 130,
          headerName: this.$t('Syllabus.division'),
          field: 'divisionName'
        },
        {
          minWidth: 130,
          headerName: this.$t('Syllabus.grade'),
          field: 'gradeName'
        },
        // {
        //   minWidth: 130,
        //   headerName: this.$t('Syllabus.is_assign'),
        //   field: 'is_assign',
        //   valueGetter: '`${data.is_assign ? "Assigned" : "Not assigned"}`'
        // },
        {
          minWidth: 130,
          headerName: this.$t('form.start_date'),
          field: 'start_date',
          hide: true,
          suppressToolPanel: true
        },
        {
          minWidth: 130,
          headerName: this.$t('form.end_date'),
          field: 'end_date',
          hide: true,
          suppressToolPanel: true
        },
        {
          minWidth: 150,
          headerName: this.$t('basic.Classes'),
          field: 'teachersClasses',
          hide: true,
          suppressToolPanel: true
          // valueGetter: '`${data. ? "Assigned" : "Not assigned"}`'
        },
        {
          minWidth: 160,
          filter: false,
          headerName: this.$t('Syllabus.actions'),
          field: 'actions',
          width: 240,
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            actions_prop: [
              {
                title: this.$t('Syllabus.assign_teacher'),
                parameter_name: 'syllabus_id',
                icon: 'UserIcon',
                actionEmitter: 'assign_teacher',
                actionEmitterParams: ['id', 'syllabus_id']
              },
              {
                title: this.$t('Syllabus.assign_student'),
                parameter_name: 'syllabus_id',
                icon: 'UsersIcon',
                actionEmitter: 'assign_student',
                actionEmitterParams: ['id', 'syllabus_id']
              }
            ]
          }
        }
      ],
      syllabus_id: this.$route.params.id,
      subjectList: []
    }
  },
  methods: {
    getSyllabus(id) {
      SyllabusRepository.get(id).then((res) => {
        const {Subjects, ...syllabus} = res.data
        Subjects.map((subject) => {
          subject.subjectName = subject.name
          subject.subject_id = subject.id
          subject.divisionName = syllabus.divisionName
          subject.division_id = syllabus.division_id
          subject.gradeName = syllabus.gradeName
          subject.grade_id = syllabus.grade.id
          subject.academic_year_id = syllabus.academic_year_id
          subject.syllabus_id = this.syllabus_id
          const teachersClasses = []
          for (const subjectKey in subject.classes_with_teachers) {
            if (subjectKey !== subject.gradeName) {
              const val = subject.classes_with_teachers[subjectKey],
                teachers = val
                  .map((e) => {
                    subject.start_date = e.start_date
                    subject.end_date = e.end_date
                    return e.teacher_name
                      ? `${e.teacher_name} (${e.teacher_type})`
                      : ''
                  })
                  .filter(Boolean)
              teachersClasses.push(
                `${subjectKey}${
                  teachers.length ? `: ${teachers.join(' , ')}` : ''
                } `
              )
            }
          }
          subject.teachersClasses = teachersClasses.join(' , ')

          return subject
        })
        this.subjectList = Subjects
      })
    },
    goTo(data) {
      this.$router.push({
        name: 'syllabus_customize_teacher',
        params: {
          id: Number(data.syllabus_id)
        },
        query: {
          subject_id: data.id
        }
      })
    },
    goToStudent(data) {
      this.$router.push({
        name: 'syllabus_customize_student',
        params: {
          id: Number(data.syllabus_id)
        },
        query: {
          subject_id: data.id
        }
      })
    }
  },
  created() {
    this.getSyllabus(this.syllabus_id)
    this.$root.$on('assign_teacher', (data) => this.goTo(data))
    this.$root.$on('assign_student', (data) => this.goToStudent(data))
  },
  beforeDestroy() {
    this.$root.$off('assign_student')
    this.$root.$off('assign_teacher')
  }
}
</script>

<style scoped lang="scss"></style>
