<!-- --------------------------------------------------------------------------------------------------
  Modes :-
  -------
  ContactsFocusMode => show Contacts with search && filters
  searchMode => for searching case
  groupMode => for groups modes (createGroupMode && AddGroupMembers)
  createGroupMode => for creating Group case
  addGroupMemberMode => add group mode case
  EndMode => reset All data and end Any activated mode

Register New Mode =>

Data props
1- register modeName at data
2- added it to isModeActive  computed prop
3- if it related to group add it to  isGroupsModeActive computed prop
4- if  need to show teacherClasses or any filter add it to showFilters

computed props
5- if need to display  a title add ot to showModeTitle computed prop
6- when finish add the endModeName() to endMode Method
methods with condition

startModeName () {
  this.modeName = true
  this.ContactsFocusMode()
  this.startGroupMode()   // if it related to group
}
resetModeInfo(){
  // reset all props to initial value
}
endModeName(){
  this.modeName = false
  this.resetModeInfo()
  this.endGroupMode()   // if it related to group
}


-------------------------------------------------------------------------------------------------- -->
<template>
  <div
    id="chat-app"
    class="
      border border-solid
      d-theme-border-grey-light
      rounded
      relative
      overflow-hidden
    "
    :class="{ 'mobile-web-view': isMobileWebView }"
  >
    <vs-sidebar
      class="items-no-padding"
      parent="#chat-app"
      :click-not-close="clickNotClose"
      :hidden-background="clickNotClose"
      v-model="isChatSidebarActive"
      id="chat-list-sidebar"
    >
      <!-- Server Error Warning -->
      <div
        class="w-full bg-warning p-6 text-white cursor-pointer flex"
        @click.stop="reconnectServer"
        v-if="serverError"
      >
        <feather-icon class="mx-3 mr-5" icon="WifiOffIcon" />
        <div>
          <h3 class="text-white mb-2">
            {{ $t("chat.system_not_connected") }} !
          </h3>
          <p>
            {{ $t("chat.something_went_wrong_check_your_connection_and") }}
            {{ $t("chat.reconnect") }}
          </p>
        </div>
      </div>
      <!-- USER PROFILE SIDEBAR -->
      <user-profile
        :active="activeProfileSidebar"
        :userId="userProfileId"
        :user="activeChatUser"
        class="user-profile-container"
        :isLoggedInUser="isLoggedInUserProfileView"
        :isAddGroupMemberMode="addGroupMemberMode"
        @closeProfileSidebar="closeProfileSidebar"
        @toggleUsers="toggleUsers"
        @removeGroupMember="removeGroupMember"
        @addGroupMember="startAddGroupMemberMode"
        @makeGroupAdmin="makeGroupAdmin"
        @dismissGroupAdmin="dismissGroupAdmin"
        @onlyGroupAdminsCanReply="onlyGroupAdminsCanReply"
        @leaveGroup="leaveGroup"
        @deleteGroup="deleteGroup"
        :loading="userProfileLoading"
      />

      <!-- User photo , search , actions , backBtn -->
      <div class="chat__profile-search flex p-4 items-center">
        <!-- Photo && back to chats btn -->
        <div class="relative inline-flex">
          <template v-if="!showBacktoChatsBtn">
            <div class="contact__avatar mr-1">
              <vs-avatar
                class="m-0 border-2 border-solid border-white"
                :src="activeUser.photoURL || activeUser.image"
                size="42px"
              />
            </div>
            <!-- <div
              class="
                h-3
                w-3
                border-white border border-solid
                rounded-full
                absolute
                right-0
                bottom-0
              "
              :class="'bg-' + getStatusColor(true)"
            ></div> -->
          </template>
          <vx-tooltip
            :text="$t('basic.back')"
            class="text-center"
            color="dark"
            v-else
          >
            <feather-icon
              icon="ArrowLeftIcon"
              class="cursor-pointer"
              svgClasses="h-6 w-6 mx-2 cursor-pointer hover:text-primary"
              @click.stop="endMode"
            />
          </vx-tooltip>
        </div>

        <!-- Search  -->
        <div id="suggestInput" class="mx-2 w-full">
          <vx-input-group class="w-full">
            <!-- <template slot="prepend">
              <v-select
                multiple
                :placeholder="`${$t('chat.search_by_class')} ....`"
                v-model="selectedSuggest"
                class="w-full"
                name="suggestSearch"
                :options="suggestList"
                label="name"
                :reduce="(s) => s.id"
                @input="search"
                @focus="startSearch"
              />
            </template> -->
            <vs-input
              icon-no-border
              :icon="searchContactName ? 'icon-edit-2' : 'icon-search'"
              icon-pack="feather"
              :placeholder="`${$t('chat.search_by_name')} ....`"
              v-model="searchContactName"
              v-validate="'alpha_spaces'"
              class="w-full input-rounded-full"
              @focus.stop="startContactsFocusMode"
            />

            <template slot="append">
              <!-- reset search text -->
              <feather-icon
                class="opacity-50"
                svgClasses="h-5 w-5 my-1 cursor-pointer hover:text-primary"
                icon="XIcon"
                v-if="showResetSearchBtn"
                @click.stop="searchContactName = ''"
              />
              <!-- go to search -->
              <feather-icon
                svgClasses="h-4 w-4 m-1 cursor-pointer hover:text-primary"
                icon="SearchIcon"
                v-if="searchContactName && !loadingContacts"
                @click.stop="search"
              />
              <!-- Loader -->

              <div
                v-if="showSearchLoader"
                ref="searchLoader"
                id="searchLoader"
                class="vs-con-loading__container"
              ></div>
            </template>
          </vx-input-group>
        </div>

        <!-- Chat create group Icon -->
        <vx-tooltip
          :text="$t('chat.new_group')"
          class="text-center"
          color="dark"
          v-if="canCreateGroup"
        >
          <!-- <feather-icon
            v-if="showGotoCreateGroupBtn"
            :icon="'ArrowRightIcon'"
            class="cursor-pointer"
            svgClasses="h-6 w-6  cursor-pointer hover:text-primary"
            @click.stop="activeCreateGroupTitleDialog"
          /> -->
          <feather-icon
            :icon="'UsersIcon'"
            class="cursor-pointer"
            svgClasses="h-6 w-6  cursor-pointer hover:text-primary"
            :class="'mx-2'"
            v-if="!createGroupMode"
            @click.stop="startCreateGroupMode"
          />
        </vx-tooltip>
        <!-- add group members Icon -->
        <vx-tooltip
          :text="$t('chat.add_participant')"
          class="text-center"
          color="dark"
          v-if="showGoToAddGroupMembersBtn"
        >
          <feather-icon
            icon="ArrowRightIcon"
            class="cursor-pointer"
            svgClasses="h-6 w-6 mr-3 cursor-pointer hover:text-primary"
            @click.stop="addGroupMembers"
          />
        </vx-tooltip>
        <!-- toggleChatSidebar Btn -->
        <feather-icon
          class="md:inline-flex lg:hidden"
          svgClasses="h-6 w-6 mr-3  cursor-pointer hover:text-primary"
          icon="XIcon"
          @click="toggleChatSidebar(false)"
          v-if="showToggleChatSidebarBtn"
        />
      </div>

      <vs-divider class="d-theme-border-grey-light m-0" />
      <component
        :is="scrollbarTag"
        class="chat-scroll-area"
        :settings="settings"
        :key="$vs.rtl"
        ref="contactsSidebar"
      >
        <!-- TopArea (Mode Title , classes Filter , Selected Contacts) -->
        <div class="flex justify-between items-center flex-wrap">
          <!-- Mode Titles -->
          <div class="mb-3 pt-4 px-6" v-if="showModeTitle">
            <h3 class="text-primary" v-if="addGroupMemberMode">
              <feather-icon
                icon="UserPlusIcon"
                svgClasses="h-6 w-6 mr-4 hover:text-primary cursor-pointer"
              ></feather-icon>
              {{ $t("chat.add_participant") }}
            </h3>
            <h3 class="text-primary" v-if="createGroupMode">
              <feather-icon
                icon="UsersIcon"
                svgClasses="h-6 w-6 mr-4 hover:text-primary cursor-pointer"
              ></feather-icon>
              {{ $t("chat.new_group") }}
            </h3>
          </div>
          <!-- Reset all selected filters -->
          <vs-button
            type="flat"
            v-if="showResetAllBtn"
            @click.stop="resetAll"
            class="block ml-auto mr-4"
            >{{ $t("basic.reset_all") }}
          </vs-button>
        </div>

        <!-- Filters , group selected contacts  -->
        <!--   Users roles-->
        <div class="m-5 w-auto" v-if="isGroupsModeActive">
          <h5>{{ $t("main.roles") }}</h5>
          <div class="flex flex-wrap my-2 w-full">
            <vs-checkbox
              v-model="selectedUsersRoles"
              vs-value="teacher"
              class="mr-1 mb-2 ml-0 flex-1"
            >
              {{ $t("main.teacher") }}
            </vs-checkbox>
            <vs-checkbox
              v-model="selectedUsersRoles"
              vs-value="parent"
              class="mr-1 mb-2 ml-0 flex-1"
            >
              {{ $t("main.parent") }}
            </vs-checkbox>
            <vs-checkbox
              v-model="selectedUsersRoles"
              vs-value="student"
              class="mr-1 mb-2 ml-0 flex-1"
            >
              {{ $t("main.student") }}
            </vs-checkbox>
          </div>
        </div>
        <!-- Filters  -->
        <div v-if="showTopArea" class="m-5 w-auto">
          <template v-if="showFilters">
            <!--   Teacher classes -->
            <h5>{{ $t("basic.classes") }}</h5>
            <ul class="flex flex-wrap my-2 w-full">
              <li v-for="tc in teacherClasses" :key="tc.id" class="mb-2 flex-1">
                <vs-switch
                  color="primary"
                  v-model="selectedTeacherClasses"
                  :vs-value="tc.id"
                  class="mr-1"
                >
                  <span slot="on">{{ tc.name }}</span>
                  <span slot="off">{{ tc.name }}</span>
                </vs-switch>
              </li>
            </ul>
          </template>
          <!--  group selected contacts -->
          <template v-if="selectedGroupContacts.length">
            <div class="flex flex-wrap">
              <vs-chip
                v-for="(selected, i) in selectedGroupContacts"
                closable
                :key="i"
                @click="unselectGroupContact(i)"
                class="text-capitalize float-none"
                >{{ selected.name }}
                {{ selected.class_name ? `( ${selected.class_name} )` : "" }}
              </vs-chip>
            </div>
          </template>

          <!-- Create group next button -->
          <div v-if="showGotoCreateGroupBtn" class="my-5">
            <vs-button
              class="ml-auto mr-0 block"
              type="flat"
              @click.stop="activeCreateGroupTitleDialog"
            >
              {{ $t("basic.next") }}
              <feather-icon
                :icon="'ArrowRightIcon'"
                class="cursor-pointer"
                svgClasses="h-4 w-4  cursor-pointer hover:text-primary"
              />
            </vs-button>
          </div>
          <hr class="mt-5" />
        </div>
        <!-- ACTIVE CHATS LIST -->
        <div
          class="chat__chats-list mb-8"
          v-if="chatContacts.length && showChatContacts"
        >
          <h3 class="text-primary mb-5 pt-8 px-4">{{ $t("chat.chats") }}</h3>

          <transition-group
            class="list-transition chat__active-chats bordered-items"
            name="list-enter-up"
            tag="ul"
          >
            <li
              class="cursor-pointer"
              v-for="(contact, i) in chatContacts"
              :key="i + 1"
              @click.stop="updateActiveChatUser(contact.id, contact.group_id)"
              :style="[{ transitionDelay: i * 0.1 + 's' }]"
            >
              <chat-contact
                showLastMsg
                :contact="contact"
                :isActiveChatUser="
                  isActiveChatUser(contact.id, contact.group_id)
                "
              ></chat-contact>
            </li>
          </transition-group>
        </div>

        <!-- CONTACTS LIST -->
        <div class="chat__contacts" v-if="showContacts">
          <!-- <div class="flex justify-between">
            <h3 class="text-primary mb-5 px-4">
              {{
                createGroupMode
                  ? $t("chat.add_group_members")
                  : $t("chat.contacts")
              }}
            </h3>
          </div> -->
          <h3
            class="text-primary mb-5 pt-8 px-5"
            v-if="!searchMode && !selectedTeacherClasses.length"
          >
            {{ $t("chat.suggested_users") }}
          </h3>

          <transition-group
            class="list-transition chat__contacts bordered-items"
            name="list-enter-up"
            tag="ul"
          >
            <li
              class="cursor-pointer"
              v-for="(contact, i) in shownContacts"
              :key="i + 1"
              :style="[{ transitionDelay: i * 0.1 + 's' }]"
              @click="
                updateActiveChatUser(
                  contact.id || contact.group_id,
                  contact.group_id
                )
              "
              :title="`${
                contact.name +
                  ' ' +
                  (contact.parent_middle_name || '') +
                  ' ' +
                  (contact.parent_last_name || '') +
                  ' ' +
                  contact.hasOwnProperty('role_name') && contact.role_name
                  ? contact.role_name.replaceAll('(', ' - ').replaceAll(')', '')
                  : '' + ' '
              } ${contact.class_name || ''} ${contact.code || ''}`"
            >
              <!-- Group Chat checkbox  -->
              <div
                id="contactCheckbox"
                class="w-full"
                v-if="showContactsCheckboxes"
              >
                <vs-checkbox
                  v-model="selectedContacts"
                  class="ml-6"
                  :vs-value="contact"
                >
                  <chat-contact
                    :createGroupMode="createGroupMode"
                    :contact="contact"
                  ></chat-contact>
                </vs-checkbox>
              </div>
              <chat-contact
                :createGroupMode="createGroupMode"
                :contact="contact"
                class="w-full"
                v-else
              ></chat-contact>
            </li>
          </transition-group>

          <!-- Pagination -->
          <div
            class="flex items-center justify-center my-6 mr-6 w-auto"
            v-if="totalPages > 1 && shownContacts.length"
          >
            <vs-button
              radius
              color="primary"
              type="border"
              icon="keyboard_arrow_left"
              class="mr-5"
              :disabled="currentPage === 1"
              @click.stop="currentPage > 1 ? currentPage-- : false"
            ></vs-button>
            <vs-button
              radius
              color="primary"
              type="border"
              icon="keyboard_arrow_right"
              :disabled="currentPage === totalPages"
              @click.stop="currentPage < totalPages ? currentPage++ : false"
            ></vs-button>
          </div>
        </div>
        <!-- Loading Text  -->
        <template v-if="loadingContacts">
          <!-- Loader -->
          <p class="text-center p-6" v-if="searchMode">
            {{ this.$t("main.Loading") }} {{ this.$t("chat.contacts") }} ....
          </p>
          <div
            ref="contactsLoader"
            id="contactsLoader"
            class="vs-con-loading__container"
            v-else
          ></div>
        </template>
        <template v-else>
          <template v-if="showContacts">
            <p
              class="text-center p-6"
              v-if="searchMode && searchContactName.length < 3"
            >
              {{ this.$t("chat.result_show_limit") }}
            </p>
            <p
              class="text-center p-6"
              v-if="
                ((searchMode && searchContactName.length > 2) || !searchMode) &&
                !shownContacts.length
              "
            >
              {{ $t("basic.no_data_available") }}
            </p>
          </template>

          <p
            class="text-center p-6 cursor-pointer"
            v-else-if="showChatContacts && !chatContacts.length"
            @click.stop="startContactsFocusMode()"
          >
            {{ $t("chat.Start_Conversation") }}
          </p>
        </template>
      </component>
    </vs-sidebar>

    <!-- RIGHT COLUMN -->
    <div
      class="
        chat__bg
        no-scroll-content
        chat-content-area
        border border-solid
        d-theme-border-grey-light
        border-t-0 border-r-0 border-b-0
      "
      :class="{
        'sidebar-spacer--wide': clickNotClose,
        'flex items-center justify-center': !activeChatUserID,
      }"
    >
      <template v-if="activeChatUserID">
        <!-- Chat Navbar  -->
        <div class="chat__navbar">
          <chat-navbar
            :isSidebarCollapsed="!clickNotClose"
            :user-id="activeChatUserID"
            :user="activeChatUser"
            :isPinnedProp="isChatPinned"
            @openContactsSidebar="toggleChatSidebar(true)"
            @showProfileSidebar="showProfileSidebar"
            @toggleIsChatPinned="toggleIsChatPinned"
          ></chat-navbar>
          <!-- Loader -->
          <div
            ref="infoLoader"
            id="infoLoader"
            class="vs-con-loading__container"
            v-show="showInfoLoader"
          ></div>
        </div>
        <!-- Chat messages log  -->
        <component
          :is="scrollbarTag"
          class="
            chat-content-scroll-area
            border border-solid
            d-theme-border-grey-light
          "
          :settings="settings"
          ref="chatLogPS"
          :key="$vs.rtl"
          @ps-scroll-up="messagesAreaScoll"
          @ps-scroll-y="toggleMessageScrollToDownBtn"
        >
          <!-- Loader -->
          <div
            ref="messagesLoader"
            id="messagesLoader"
            class="vs-con-loading__container"
            v-show="showMessagesLoader"
          ></div>
          <!-- Messages Area -->
          <div class="chat__log overflow-hidden w-full" ref="chatLog">
            <chat-log
              :userId="activeChatUserID"
              :user="activeChatUser"
              :msg="chatMessages"
              v-if="activeChatUserID"
              ref="MessageArea"
              :firstTimeLoading="firstTimeLoading"
              :sendNewMessage="sendNewMessage"
              @deleteMsg="deleteMessage"
            ></chat-log>
          </div>
        </component>
        <!-- Scroll to last messages -->
        <vs-button
          class="bg-primary-gradient"
          type="filled"
          radius
          icon-pack="feather"
          icon="icon-chevron-down"
          id="btn-scrollToBottom"
          :class="{ show: showMessagesScrollDownBtn }"
          @click="setScroll"
        ></vs-button>
        <!-- Send Message  -->
        <div
          class="flex items-end sendMsgArea"
          :class="canSendMsg ? 'chat__input bg-white p-4' : 'bg-gray p-6'"
          ref="sendMessageArea"
        >
          <template v-if="canSendMsg">
            <feather-icon
              v-if="isLongNewMessage"
              :icon="floatingSendMsgArea ? 'Minimize2Icon' : 'Maximize2Icon'"
              class="cursor-pointer sendMsgArea--floating_btn"
              svgClasses="h-5 w-5 cursor-pointer hover:text-primary"
              @click="floatingSendMsgArea = !floatingSendMsgArea"
            />
            <!-- Attachment -->
            <div class="">
              <input
                type="file"
                hidden
                ref="attachmentInput"
                accept=".doc,.docx,.xlsx,.csv,.xls,image/*,.pdf,.xlsx,.pptx;capture=camera"
                @change="uploadAttachment"
              />
              <vs-button
                @click="takeAttachmentAction"
                ref="attachmentLoader"
                id="attachmentLoader"
                class="vs-con-loading__container p-2"
                type="flat"
                :disabled="loadingSendMsg"
              >
                <template v-if="!attachment">
                  <vx-tooltip
                    :title="$t('basic.accept_only')"
                    text="PDF, PNG, JPG, XLS, DOC, DOCX, XLSX, PPTX  and (Max Size 2MB)"
                    class="text-center"
                    color="dark"
                    position="right"
                  >
                    <feather-icon
                      icon="PaperclipIcon"
                      class="cursor-pointer"
                      svgClasses="h-6 w-6 cursor-pointer"
                    />
                  </vx-tooltip>
                </template>
                <template v-else>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    svgClasses="h-6 w-6 cursor-pointer"
                  />
                </template>
              </vs-button>
            </div>
            <!-- type Text msg -->
            <textarea
              class="flex-1 mb-0 mx-3 vs-textarea"
              :placeholder="$t('chat.type_your_message')"
              v-model="typedMessage"
              @keyup.enter="sendMsg"
              :readonly="Boolean(attachment) || loadingSendMsg"
              :disabled="loadingSendMsg ? 'disabled' : null"
              @input="resizeSendTextarea()"
              ref="sendMsgTextarea"
              rows="1"
              :style="`resize:${
                typedMessage.length > 50 ? 'vertical' : 'none'
              }`"
            />
            <!-- send btn -->
            <vs-button
              class="vs-con-loading__container p-2"
              @click="sendMsg"
              ref="sendMessageLoader"
              id="sendMessageLoader"
              v-if="typedMessage || attachment || loadingSendMsg"
              type="flat"
            >
              <vx-tooltip
                :text="$t('form.send')"
                class="text-center"
                color="dark"
              >
                <feather-icon
                  icon="SendIcon"
                  class="cursor-pointer"
                  svgClasses="h-6 w-6 cursor-pointer"
                  style="transform: rotate(45deg)"
                />
              </vx-tooltip>
            </vs-button>
          </template>
          <template v-else>
            <p class="text-center w-full">
              <template v-if="this.isOnlyAdminsCanReply">
                {{ $t("chat.only_admins_can_send_messages") | text_formatter }}
              </template>
              <template v-else>
                {{
                  $t(
                    "chat.you_cannot_send_message_to_this_group_because_you_are_no_longer_a_participant"
                  ) | text_formatter
                }}
              </template>
            </p>
          </template>
        </div>
      </template>
      <template v-else>
        <div class="flex flex-col items-center">
          <feather-icon
            icon="MessageSquareIcon"
            class="mb-4 bg-white p-8 shadow-md rounded-full cursor-pointer"
            svgClasses="w-16 h-16"
            @click.stop="toggleChatSidebar(true)"
          ></feather-icon>
          <h4
            class="py-2 px-4 bg-white shadow-md rounded-full cursor-pointer"
            @click.stop="toggleChatSidebar(true)"
          >
            {{ $t("chat.Start_Conversation") }}
          </h4>
        </div>
      </template>
    </div>

    <!-- Group Title Dialog -->
    <vs-prompt
      @cancel="resetGroupInfo"
      @accept="createGroup"
      :buttons-hidden="true"
      :is-valid="Boolean(newGroupName)"
      :active.sync="activeGroupTitleDialog"
      :title="$t('chat.group_title')"
      type="confirm"
      id="createGroupDialog"
    >
      <!-- Loader -->
      <div
        v-show="loadingCreateGroup"
        ref="createGroupLoader"
        id="createGroupLoader"
        class="vs-con-loading__container"
      ></div>
      <div class="con-exemple-prompt">
        <!-- Input -->
        <vs-input
          v-model="newGroupName"
          class="w-full"
          name="title"
          @keyup.enter="startCreateGroup"
        />
      </div>
      <!-- Btns -->
      <div class="text-right mt-6">
        <vs-button
          class="mt-2 mx-2"
          @click="startCreateGroup"
          :disabled="!Boolean(newGroupName)"
          >{{ $t("basic.accept") }}
        </vs-button>
        <vs-button class="mt-2" type="flat" color="danger" @click="endGroupMode"
          >{{ $t("basic.cancel") }}
        </vs-button>
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import ChatContact from '../components/ChatContact'
import ChatLog from '../components/ChatLog'
import ChatNavbar from '../components/ChatNavbar'
import UserProfile from '../components/UserProfile'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import mixins from '../chat.mixins'
import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css'
import {checkValidationMixin, authUserMixin} from '@/mixins'
import _ from 'lodash'
// APIs
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'

const chatRepository = RepositoryFactory.get('ChatRepository', 'chat')
const groupChatRepository = RepositoryFactory.get(
  'ChatRepository',
  'groupChat'
)
const TeacherRepo = RepositoryFactory.get('TeachersModule', 'BasicInfo')

export default {
  name: 'chat',
  mixins: [mixins, checkValidationMixin, authUserMixin],
  props: ['userID'],
  data () {
    return {
      /////////////////////////
      // UI
      /////////////////////////
      active: true,
      isHidden: false,
      activeProfileSidebar: false,
      userProfileId: -1,
      isChatPinned: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.7
      },
      clickNotClose: true,
      isChatSidebarActive: true,
      isLoggedInUserProfileView: false,
      firstTimeLoading: false,
      showMessagesScrollDownBtn: false,
      sendNewMessage: false,
      floatingSendMsgArea:false,
      /////////////////////////////////
      // Refresh && Loading && Errors
      /////////////////////////////////
      refresh: null,
      refreshAfterTime: 1,
      loadingContacts: false,
      serverError: false,
      loadingMessages: false,
      loadingCreateGroup: false,
      loadingInfo: false,
      userProfileLoading: false,
      stopGetMessages: false,
      loadingAttachment: false,
      loadingSendMsg: false,
      /////////////////////////
      // Data
      /////////////////////////
      contacts: [],
      filtratedContacts: [],
      allContacts: [],
      defaultContacts: [],
      chatContacts: [],
      activeChatUserID: null,
      activeChatUser: {},
      chatMessages: [],
      /////////////////////////
      // Search
      /////////////////////////
      searchMode: false,
      searchContactName: '',
      showContacts: false,
      showChatContacts: true,
      suggestList: [
        {
          id: 0,
          name: 'teacher'
        },
        {
          id: -1,
          name: 'SMD'
        }
      ],
      selectedSuggest: [],
      sessionSelectedContacts: [],
      // teacher
      teacherClasses: [],
      selectedTeacherClasses: [],
      filterMode: false,
      selectedUsersRoles: ['teacher', 'parent', 'student'],
      /////////////////////////
      // Filters
      /////////////////////////
      filters: [],
      selectedFilters: [],
      /////////////////////////
      // Send Message
      /////////////////////////
      typedMessage: '',
      attachment: '',
      /////////////////////////
      // Pagination
      /////////////////////////
      pagination: null,
      totalPages: 0,
      currentPage: 1,
      countPerPage: 10,
      defaultTotalPages: 0,
      /////////////////////////
      // Group Chat
      /////////////////////////
      createGroupMode: false,
      activeChatGroupID: null,
      selectedContacts: [],
      newGroupName: '',
      activeGroupTitleDialog: false,
      addGroupMemberMode: false,
      addMembersGroupID: null
    }
  },
  computed: {
    env () {
      return process.env.VUE_APP_AXSIOS_LINK
    },
    chatLastMessagedID () {
      const maxId = Math.max(
        ...this.chatContacts.map((el) => el.chat_details && el.chat_details.id)
      )
      return !isNaN(maxId) && maxId > 0 ? maxId : 0
    },

    chatLastMessaged () {
      return (
        this.chatContacts.find(
          (el) => el.chat_details.id === this.chatLastMessagedID
        ) || {}
      )
    },

    chatUnseenMessagesCount () {
      return this.chatContacts.filter(
        (con) => !con.chat_details.is_seen && con.chat_details.sender_id == con.id
      ).length
    },

    // chatDataOfUser () {
    //   return this.$store.state.chat.chatDataOfUser || {}
    // },

    getStatusColor () {
      return (isActiveUser) => {
        const userStatus = this.getUserStatus(isActiveUser)
        if (userStatus === 'online') {
          return 'success'
        } else if (userStatus === 'do not disturb') {
          return 'danger'
        } else if (userStatus === 'away') {
          return 'warning'
        } else {
          return 'grey'
        }
      }
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    },
    windowWidth () {
      return this.$store.state.windowWidth
    },
    isGroupsModeActive () {
      return this.createGroupMode || this.addGroupMemberMode
    },
    canCreateGroup () {
      return (
        (this.checkUserRole('SMD') ||
          this.checkUserRole('SND') ||
          this.checkUserRole('Teacher') ||
          this.checkUserRole('SchoolPrincipal') ||
          this.checkUserRole('SectionsCoodinator')) &&
        !this.addGroupMemberMode &&
        (!this.searchMode || this.createGroupMode)
      )
    },
    showResetAllBtn () {
      return Boolean(
        this.selectedGroupContacts.length ||
        this.selectedTeacherClasses.length ||
        this.selectedUsersRoles.length < 3
      )
    },
    showBacktoChatsBtn () {
      return this.isModeActive || this.showContacts
    },
    showFilters () {
      return this.isGroupsModeActive && Boolean(this.filters.length)
    },
    showResetSearchBtn () {
      return this.searchContactName && !this.loadingContacts
    },
    showTopArea () {
      return this.showFilters || Boolean(this.selectedGroupContacts.length)
    },
    showContactsCheckboxes () {
      return this.isGroupsModeActive
    },
    showGoToAddGroupMembersBtn () {
      return this.addGroupMemberMode && this.selectedGroupContacts.length
    },
    showSearchLoader () {
      return this.loadingContacts && this.showContacts && this.searchMode
    },
    showMessagesLoader () {
      return this.loadingMessages
    },
    showModeTitle () {
      return this.createGroupMode || this.addGroupMemberMode
    },
    showStartConversationText () {
      return (
        !this.chatContacts.length &&
        this.showChatContacts &&
        !this.loadingContacts
      )
    },

    chatGroups () {
      return this.chatContacts.length
        ? this.chatContacts
          .filter((c) => c.group_id)
          ?.map((c) => c.chat_details.group)
        : []
    },
    selectedGroupContacts: {
      get () {
        return this.selectedContacts.length
          ? [..._.uniqBy(this.selectedContacts, 'id')]
          : []
      },
      set (v) {
        this.selectedContacts = v
      }
    },
    isModeActive () {
      return this.isGroupsModeActive || this.searchMode
    },
    showGotoCreateGroupBtn () {
      return this.createGroupMode && Boolean(this.selectedContacts.length)
    },
    showInfoLoader () {
      return this.loadingInfo
    },
    canSendMsg () {
      if (
        this.activeChatGroupID &&
        this.isActiveUserAnActiveGroupMember &&
        !this.isActiveUserGroupModeratorOrAdmin
      ) return !this.isOnlyAdminsCanReply
      else if (this.activeChatGroupID && !this.isActiveUserAnActiveGroupMember) return false
      else return true
    },
    showContactsResults () {
      return (
        this.searchMode ||
        this.filterMode ||
        this.currentPage > 1 ||
        this.addGroupMemberMode
      )
    },
    shownContacts () {
      return this.showContactsResults ? this.contacts : this.defaultContacts
    },
    showContactsLoader () {
      return (this.createGroupMode && !this.searchMode) || !this.searchMode
    },
    sendTextareaScrollHeight () {
      // console.log(this.$refs.sendMsgTextarea)
      return (this.$refs.sendMsgTextarea?.$el?.scrollHeight / 10) || 1
    },
    showToggleChatSidebarBtn () {
      return !this.clickNotClose
    },
    isLongNewMessage () { return this.typedMessage.length>50 }

  },
  watch: {
    windowWidth () {
      this.setSidebarWidth()
    },

    async activeChatUserID (v) {
      if (v) {
        v = Number(v)
        if (this.activeProfileSidebar && this.userProfileId !== v) this.userProfileId = v
        const matchedChatContact = await this.chatContacts.find(
            (c) => !c.group_id && c.id === v
          ),
          matchedContact = await this.contacts.find((c) => c.id === v),
          matchedChatGroupContact = await this.chatContacts.find(
            (c) => c.group_id === v
          ),
          matched = (await this.activeChatGroupID)
            ? matchedChatGroupContact
            : matchedContact || matchedChatContact

        if (matched?.id) {
          this.activeChatUser = matched
        } else {
          if (this.groupMembers) this.activeChatUser = await this.groupMembers.find(
            (c) => c.id === v
          )
          this.getContact(v).then((res) => {
            this.activeChatUser = res
          })
        }
      }
    },
    currentPage (v) {
      this.getContactsDebounce()
    },

    selectedTeacherClasses (v) {
      if (v.length) {
        this.filterMode = true
        this.getContactsDebounce()
      } else this.filterMode = false
    },

    showContactsCheckboxes (v) {
      this.contacts = v ? this.filtratedContacts : this.allContacts
    },
    searchContactName (v) {
      if (v) this.search()
      else this.endSearch()
    },
    selectedUsersRoles (v) {
      if (v.length) {
        this.filterMode = true
        this.getContactsDebounce()
      } else this.filterMode = false
    },
    floatingSendMsgArea (v) {
      if (v) this.$refs.sendMessageArea.classList.add('sendMsgArea--floating')
      else this.$refs.sendMessageArea.classList.remove('sendMsgArea--floating')
      this.resizeSendTextarea()
    }
  },
  methods: {
    ////////////////////////////////////////
    // loading
    ////////////////////////////////////////
    // Contacts
    async startLoadingContacts () {
      this.resetContactsData()
      this.scrollToTop('contactsSidebar')
      await (this.loadingContacts = true)
      // if (this.searchContactName.length > 2) {

      if (this.showContactsLoader) this.VsLoadingContained(this.$refs.contactsLoader, 'button')
      else this.VsLoadingContained(this.$refs.searchLoader, 'button')
      // }
    },
    async endLoadingContacts () {
      if (this.showContactsLoader) await this.stopVsLoadingContained(this.$refs.contactsLoader, 'button')
      else await this.stopVsLoadingContained(this.$refs.searchLoader)
      this.loadingContacts = false
    },
    // Messages
    async startLoadingMessages () {
      await (this.loadingMessages = true)
      this.VsLoadingContained(this.$refs.messagesLoader)
    },
    endLoadingMessages () {
      this.stopVsLoadingContained(this.$refs.messagesLoader)
      this.loadingMessages = false
    },
    // Create Group
    async startLoadingCreatingGroup () {
      await (this.loadingCreateGroup = true)
      this.VsLoadingContained(this.$refs.createGroupLoader)
    },
    endLoadingCreatingGroup () {
      this.stopVsLoadingContained(this.$refs.createGroupLoader)
      this.loadingCreateGroup = false
    },
    // Info Group
    async startLoadingInfo () {
      await (this.loadingInfo = true)
      this.VsLoadingContained(this.$refs.infoLoader)
    },
    endLoadingInfo () {
      this.stopVsLoadingContained(this.$refs.infoLoader)
      this.loadingInfo = false
    },
    // loadingAttachment
    async startLoadingAttachment () {
      await (this.loadingAttachment = true)
      this.VsLoadingContained('#attachmentLoader')
    },
    endLoadingAttachment () {
      this.stopVsLoadingContained('#attachmentLoader> .con-vs-loading')
      this.loadingAttachment = false
    },
    // loadingSendNewMessage
    async startLoadingSendNewMessage () {
      await (this.loadingSendMsg = true)
      this.VsLoadingContained('#sendMessageLoader')
    },
    endLoadingSendNewMessage () {
      this.stopVsLoadingContained('#sendMessageLoader> .con-vs-loading')
      this.loadingSendMsg = false
    },

    ////////////////////////////////////////
    // APIs
    ////////////////////////////////////////

    // Get contacts
    async getContacts () {
      //------ Abort request
      if (
        this.searchMode &&
        this.searchContactName.length &&
        this.searchContactName.length < 3
      ) return
      if (
        !this.searchMode &&
        !this.filterMode &&
        !this.addGroupMemberMode &&
        this.currentPage === 1 &&
        this.defaultContacts.length
      ) return

      //-------- Start Request
      if (this.showContacts) await this.startLoadingContacts()

      await chatRepository
        .getContacts(
          this.addMembersGroupID,
          this.searchContactName,
          this.selectedTeacherClasses,
          this.selectedUsersRoles,
          this.currentPage
        )
        .then((res) => {
          const data = res.data?.data || res.data
          this.contacts = this.handleContacts(data)
          this.filtratedContacts = this.contacts
          this.allContacts = this.contacts
          this.setPagination(res.data)
          if (
            !this.searchMode &&
            !this.addGroupMemberMode &&
            this.currentPage === 1
          ) this.setDefaultContacts()
          this.serverError = false
        })
        .catch((err) => {
          this.serverError = true
          this.errMsg(
            err?.data?.msg ||
            this.$t('basic.Something_went_wrong_check_your_connection')
          )
        })
        .finally(() => this.endLoadingContacts())
    },

    // Get Contact
    async getContact (id) {
      if (this.activeChatUserID === id) {
        if (!this.loadingInfo) await this.startLoadingInfo()
      }
      return await new Promise((resolve, reject) => {
        chatRepository
          .getContact(id)
          .then((res) => {
            resolve(res.data)
          })
          .catch(reject)
          .finally(() => this.endLoadingInfo())
      })
    },

    // Get chat-contacts
    async getChatContacts () {
      if (this.showChatContacts) await this.startLoadingContacts()
      return await chatRepository
        .getChatContacts()
        .then((res) => {
          res.data = res.data.filter((c) => c.chat_details)
          this.serverError = false
          this.chatContacts = this.removeActiveUserContact(res.data)
          this.sortChatContacts()
        })
        .catch((err) => {
          this.serverError = true
          // console.log(err)
          this.errMsg(
            err?.data?.msg ||
            this.$t('basic.Something_went_wrong_check_your_connection')
          )
        })
        .finally(() => this.endLoadingContacts())
    },

    // Get Chat-Messages
    async getChatMessages (fromMessageId = null, direction = 'up', limit = 20) {
      this.startLoadingMessages()
      this.sendNewMessage = false
      const payload = {
        user_id: this.activeChatGroupID ? null : this.activeChatUserID,
        group_id: this.activeChatGroupID,
        id:fromMessageId,
        direction,
        limit
      }
      return await chatRepository
        .getChatMessages(payload)
        .then((res) => {
          // stop pushing messages if user changed the chat
          if (
            (payload.user_id && payload.user_id !== this.activeChatUserID) ||
            (payload.group_id && payload.group_id !== this.activeChatGroupID)
          ) return
          const data = res.data.filter(
              (m) => m && typeof m === 'object' && m.id
            ),
            userName = this.activeUser.displayName

          data.forEach((msg) => {
            const hasSenderReceiverProp = msg.hasOwnProperty('sender_receiver'),
              isSent = msg.sender_receiver === 'sender',
              isActiveUserSender = msg.sender_id === this.activeUser.uid

            msg.isSent = (hasSenderReceiverProp && isSent) || isActiveUserSender

            // replace admin name with you if  he is an authUser
            // const
            //   msgText = msg.content,
            //   isDefault = msg.is_default_message,
            //   hasAdminName = msgText.includes(userName)

            // if (isDefault && hasAdminName) {
            //   msg.content = msgText.replace(userName, this.$t('basic.you'))
            // }
          })
          // if not first time
          if (fromMessageId) {
            this.firstTimeLoading = false
            if (data.length) {
              // check if same messages
              data.some((m) => {
                if (this.chatMessages.some((e) => e.id === m.id)) {
                  this.stopGetMessages = true
                  return true
                }
              })
            } else this.stopGetMessages = true

            if (!this.stopGetMessages) this.chatMessages.unshift(...this.sortChatMessages(data))
          } else {
            this.chatMessages = this.sortChatMessages(data)
            this.firstTimeLoading = true
            this.stopGetMessages = false
            this.changeSeenChatContact()
          }
        })
        .finally(() => this.endLoadingMessages())
    },

    //send Message
    async sendChatMessage (payload) {
      return await chatRepository
        .saveMessageNode(payload)
        .then(() => {
          this.markSeenMessages(payload.receiver_id)
          this.resetMessageArea()
          this.setScroll()
        })
        .catch(this.errMsg)
    },

    // get Teacher's Classes
    async getTeacherClasses () {
      return await TeacherRepo.getClasses().then((res) => {
        this.teacherClasses = res.data.map((d) => {
          return {
            id: d.id,
            name: d.name
          }
        })
        this.filters.push(...this.teacherClasses)
      })
    },
    // Delete Msg
    async deleteMessage (msgId) {
      if (!msgId) return

      const payload = {
        groupId: this.activeChatGroupID,
        chatId: this.activeChatUserID,
        msgId
      }
      this.UpdateDeleteMsgSocket(payload)

      this.startLoadingMessages()
      return await chatRepository.deleteMessage(msgId)
        .then(() => {

          const payload = {
            groupId: this.activeChatGroupID,
            chatId: this.activeChatUserID,
            msgId
          }

          this.UpdateDeleteMsgSocket(payload)
          this.successMsg(`${this.$t('chat.message_deleted')}`)
        })
        .catch(this.errMsg)
        .finally(this.endLoadingMessages())
    },
    ////////////////////
    // Group

    // Create Group
    async createGroup () {
      this.startLoadingCreatingGroup()
      const group = {
        name: this.newGroupName,
        participant_ids: this.selectedGroupContacts.map((el) => el.id),
        can_reply: 0
      }
      return await groupChatRepository
        .createGroup(group)
        .then((res) => {
          this.endMode()
          this.successMsg(this.$t('chat.group_is_created'))

          // Handle Socket data
          res.data.members = this.handleActiveUserName(
            res.data.members,
            this.activeUser.displayName
          )
          res.data.moderator = res.data.members.find(
            (m) => m.user_role === 'a_moderator'
          )
          this.$socket.client.emit('new_group_created', res.data)
        })
        .catch((err) => this.errMsg(err))
        .finally(() => this.endLoadingCreatingGroup())
    },
    // Group send message
    async sendGroupChatMessage (payload) {
      return await groupChatRepository
        .saveGroupMessageNode(payload)
        .catch(this.errMsg)
    },
    // Add Members
    async addGroupMembersAPI (payload) {
      return await groupChatRepository.addGroupMembers(payload)
    },
    // Remove members
    async removeGroupMembers (payload) {
      return await groupChatRepository.removeGroupMembers(payload)
    },
    // Make Group Admin
    async makeGroupAdmin (member) {
      this.userProfileLoading = true
      return await groupChatRepository
        .makeGroupAdmin([member.id], this.groupID)
        .then(() => {
          this.successMsg(
            `${member.name} ${this.$t('chat.is_now_a_group_admin')}`
          )

          // Update Local
          this.groupMembers.some((m) => {
            if (m.id === member.id) {
              m.user_role = 'admin'
              m.user_role_name = this.$t('chat.admin')
              return true
            }
          })

          // Update socket
          const payload = {
            members_ids: Array.isArray(member.id) ? member.id : [member.id],
            group_id: this.activeChatGroupID,
            makeAdmin: true
          }
          this.updateMembersSocket(payload)
        })
        .catch(this.errMsg)
        .finally(() => {
          this.userProfileLoading = false
        })
    },
    // Dismiss Group Admin
    async dismissGroupAdmin (member) {
      this.userProfileLoading = true

      return await groupChatRepository
        .makeGroupMember([member.id], this.groupID)
        .then(() => {
          this.successMsg(
            `${member.name} ${this.$t('chat.is_no_longer_a_group_admin')}`
          )

          // Update Local
          this.groupMembers.some((m) => {
            if (m.id === member.id) {
              m.user_role = 'member'
              m.user_role_name = this.$t('chat.member')
              return true
            }
          })

          // Update socket
          const payload = {
            members_ids: Array.isArray(member.id) ? member.id : [member.id],
            group_id: this.activeChatGroupID,
            makeAdmin: false
          }
          this.updateMembersSocket(payload)
        })
        .catch(this.errMsg)
        .finally(() => {
          this.userProfileLoading = false
        })
    },
    // Group Can replay option
    async onlyGroupAdminsCanReply (canReplay) {
      if (this.userProfileLoading) return
      this.userProfileLoading = true
      return await groupChatRepository
        .onlyAdminsCanReply(this.groupID, canReplay)
        .then((res) => {
          if (canReplay) this.successMsg(
            this.$t(
              'chat.you_allowed_only_admin_to_send_messages_to_this_group'
            )
          )
          else this.successMsg(
            this.$t(
              'chat.you_allowed_all_participants_to_send_messages_to_this_group'
            )
          )

          //  Update local
          this.activeChatUser.chat_details.group.can_reply =
            res.data.can_reply || canReplay

          // Update Socket
          const payload = {
            groupId: this.groupID,
            onlyAdminsCanreply: canReplay
          }
          this.updateGroupAdminsOnlyCanReplySocket(payload)
        })
        .catch(this.errMsg)
        .finally(() => {
          this.userProfileLoading = false
        })
    },
    // leave Group
    async leaveGroup () {
      this.userProfileLoading = true

      return await groupChatRepository
        .leaveGroup(this.activeChatGroupID)
        .then(() => {
          const msg = `${this.activeUser.displayName} ${this.$t(
            'chat.left_group'
          )}`
          const payload = {
            members_ids: [this.activeUser.id],
            group_id: this.activeChatGroupID,
            addMember: false,
            content: msg
          }

          this.updateMembersSocket(payload)
          this.successMsg(
            `${this.$t('basic.you')} ${this.$t('chat.left_group')}`
          )
          this.activeProfileSidebar = false
        })
        .catch((err) => this.errMsg(err))
        .finally(() => {
          this.userProfileLoading = false
        })
    },
    // delete Group
    async deleteGroup () {
      this.userProfileLoading = true
      return await groupChatRepository.deleteGroup(this.activeChatGroupID)
        .then(() => {

          this.deleteGroupSocket(this.activeChatGroupID)
          this.successMsg(`${this.$t('chat.group_is_deleted')}`)
        })
        .catch(err => this.errMsg(err))
        .finally(() => {
          this.userProfileLoading = false

        })

    },

    ////////////////////////////////////////
    // debounce
    ////////////////////////////////////////
    getContactsDebounce: _.debounce(function () {
      this.getContacts()
    }, 300),

    ///////////////////////////////////
    // chat socket events handling
    ////////////////////////////////////
    //------- Emit
    updateMembersSocket (payload) {
      // Handle Socket
      payload.adminName = this.activeUser.displayName
      this.$socket.client.emit('update_group_members', payload)
    },
    deleteGroupSocket (payload) {
      // Handle Socket
      this.$socket.client.emit('delete_group_socket', payload)
    },
    updateGroupAdminsOnlyCanReplySocket (payload) {
      // Handle Socket
      this.$socket.client.emit('only_group_admins_can_reply', payload)
    },
    UpdateDeleteMsgSocket (payload) {
      this.$socket.client.emit('delete_message', payload)

    },

    //----- Push data from socket
    pushMessage (payload) {
      const receiverId = payload.receiver_id,
        senderId = payload.sender_id,
        groupID = payload.group_id,
        userID = this.activeUser.id,
        isChatGroup = groupID ? this.chatGroups.some((c) => c.id === groupID) : null,
        chatGroup = isChatGroup ? this.chatGroups.find((c) => c.id === groupID) : null,
        isGroupMember =
          chatGroup?.members.some((m) => m.id === userID) || false
      if (
        userID === receiverId ||
        userID === senderId ||
        (isChatGroup && isGroupMember)
      ) this.NewMsg(payload)
    },
    updateIsSeenSocket (payload) {
      // console.group('updateIsSeenSocket')
      // console.log('payload', payload)
      // console.log('this.activeChatUserID', this.activeChatUserID)
      // console.log('payload.receiver_id', payload.receiver_id)
      // console.groupEnd()
      // Mark Chat Messages
      if (this.activeChatUserID === payload.receiver_id) {
        this.chatMessages.forEach((el) => {
          if (el.isSent) el.is_seen = 1
        })
      }

      // Mark ChatContct Last Message
      this.changeSeenChatContact(payload.receiver_id, 'receiver')
    },
    // Group Events
    pushNewCreatedGroup (groupInfo) {
      const groupMembers = groupInfo.members,
        activeUserID = this.activeUser.id,
        groupMembersIDs = groupMembers.map((m) => m.id),
        isActiveUserAMember = groupMembersIDs.some((m) => m === activeUserID)

      if (isActiveUserAMember) {
        const isActiveUserModerator = groupInfo.moderator.id === activeUserID,
          chatContact = _.cloneDeep(this.handleNewGroupObj(groupInfo))
        if (isActiveUserAMember) groupInfo.members = this.handleActiveUserName(
          groupMembers,
          this.$t('basic.you')
        )

        this.chatContacts.unshift(chatContact)

        if (isActiveUserModerator) {
          // Open Created Group
          this.startLoadingInfo()
          this.activeChatUser = {}
          this.activeChatUserID = groupInfo.id
          this.activeChatGroupID = groupInfo.id
          this.updateActiveChatUser(
            this.activeChatUserID,
            this.activeChatGroupID
          )
          this.isActiveChatUser(this.activeChatUserID, this.activeChatGroupID)
          this.chatMessages.push(_.cloneDeep(chatContact.chat_details))
          this.endLoadingInfo()
        }
      }
    },

    pushUpdateGroupMembers (payload) {
      // console.log('pushUpdateGroupMembers')
      const
        isActiveUserAUpdatedMember = payload.members_ids.some(m => m === this.activeUser.id),
        isChatGroup = this.chatGroups.find(c => c && c.id === payload.group_id),
        isMakeAdminCase = payload.hasOwnProperty('makeAdmin')

      if (isActiveUserAUpdatedMember || isChatGroup) {
        if (isMakeAdminCase) {
          if (isActiveUserAUpdatedMember) this.handleUpdateGroupMembers(payload)
        } else this.handleUpdateGroupMembers(payload)
      }
    },
    pushUpateOnlyAdminsCanReply (payload) {
      const targetGroup = this.chatGroups.find(
        (c) => c && c.id === payload.groupId
      )
      if (targetGroup) targetGroup.can_reply = payload.onlyAdminsCanreply
    },
    pushUpateDeleteMsg (payload) {
      const
        targetChatContact = this.chatContacts.find(c => c && c.id === payload.chatId),
        isActiveChat = (payload.chatId === this.activeChatUserID),
        isChatContactLastMsg = targetChatContact.chat_details.id === payload.msgId
      if (targetChatContact) {
        if (isChatContactLastMsg) {
          targetChatContact.chat_details.is_deleted = true
          targetChatContact.chat_details.content = ''
        }
        if (isActiveChat) {
          const msg = this.chatMessages.find(m => m.id === payload.msgId)
          msg.is_deleted = true
          msg.content = ''
        }
      }
    },
    pushDeleteGroup (groupId) {
      const
        targetGroupIndex = this.chatContacts.findIndex(c => c && c.id === groupId),
        isActiveOpenedChat = (this.activeChatGroupID === groupId)
      if (targetGroupIndex > -1) this.chatContacts.splice(targetGroupIndex, 1)
      if (isActiveOpenedChat) {
        this.resetChatsBeforeUpdate()
        this.activeProfileSidebar = false
      }
    },
    ////////////////////////////////////////
    // Handle Contacts
    ////////////////////////////////////////
    removeActiveUserContact (contacts) {
      return contacts.filter((el) => el.id !== this.activeUser.uid)
    },
    replaceContactWithExistChatContact (contacts) {
      let matchedChatContact = {}
      contacts.forEach((c, i) => {
        matchedChatContact =
          this.chatContacts.find((e) => !e.group_id && e.id === c.id) || null
        if (matchedChatContact) contacts[i] = matchedChatContact
      })
      return contacts
    },
    removeGroupsFromContacts (contacts) {
      return contacts.filter((e) => !e.group_id)
    },
    removeGroupMembersFromContacts (contacts) {
      if (this.addGroupMemberMode && this.addMembersGroupID) {
        const members_ids = this.groupMembers.map((m) => m.id)
        return contacts.filter((c) => !members_ids.includes(c.id))
      } else return contacts
    },
    removeSelectedContacts (contacts) {
      if (this.selectedContacts.length) {
        const selected = this.selectedContacts,
          selectedIds = [...new Set(selected.map((s) => s.id))]
        return contacts.filter((c) => !selectedIds.includes(c.id))
      } else return contacts
    },
    handleContacts (contactsData) {
      // Check if contacts is object && convert it To array
      const contacts =
        typeof contactsData === 'object' ? Object.values(contactsData) : contactsData

      let handledData = this.removeActiveUserContact(contacts)

      handledData = this.replaceContactWithExistChatContact(handledData)

      // Customize Contacts in spical Modes
      // TODO REMOVE  removeGroupMembersFromContacts removeGroupsFromContacts
      // TODO AFTER DID IT FROM BACKEND
      // if (this.addGroupMemberMode) handledData = this.removeGroupMembersFromContacts(handledData)

      if (this.createGroupMode) handledData = this.removeGroupsFromContacts(handledData)

      if (this.selectedContacts.length) handledData = this.removeSelectedContacts(handledData)

      return handledData
    },
    setDefaultContacts () {
      this.defaultContacts = this.contacts
      this.defaultTotalPages = this.totalPages
    },
    ////////////////////////////////////////
    // Handle Objs Before push
    ////////////////////////////////////////
    handleMsgObj (data) {
      // console.group('handleMsgObj data')
      // console.log('add Data', data)
      // console.log('data.sender_id', data.sender_id)
      // console.log('data.receiver_id', data.receiver_id)
      // console.log('this.activeUser.id', this.activeUser.id)
      // console.log('senderId === userID', (data.sender_id ===
      // this.activeUser.id))
      // console.log('is_seen', data.is_seen)
      // console.log('data.group_id', data.group_id)
      // console.groupEnd()

      const receiverId = data.receiver_id,
        senderId = data.sender_id,
        userID = this.activeUser.id,
        groupID = data.group_id,
        isSent = data.isSent || userID === senderId,
        contact =
          this.contacts.find((el) => el.id === receiverId) ||
          this.chatContacts.find((el) => el.id === receiverId),
        is_seen = data.is_seen || 0,
        isGroupMsg = Boolean(groupID),
        getGroupMembers =
          isGroupMsg && this.chatGroups.length ? this.chatGroups?.find((c) => c && c.id === groupID)?.members : [],
        senderName =
          isGroupMsg && !isSent ? getGroupMembers?.find((m) => m.id === senderId)?.name : ''

      return {
        id: data.id || this.chatLastMessagedID + 1,
        attachment: data.attachment || '',
        contact,
        content: data.content,
        created_at: data.created_at || this.currentDate,
        isSent,
        is_seen,
        receiver_id: receiverId,
        sender_id: senderId,
        sender_receiver: isSent ? 'sender' : 'receiver',
        url: data.url || '',
        sender_name: senderName,
        group_id: groupID || null
      }
    },
    handleNewGroupObj (group) {
      const id = group.id,
        group_id = id,
        name = group.name,
        moderatorName = group.moderator?.name,
        moderatorID = group.moderator?.id,
        isActiveUserModerator = moderatorID === this.activeUser.id,
        image = group.image || null,
        content = `${
          isActiveUserModerator ? this.$t('basic.you') : moderatorName
        } ${this.$t('chat.created_group')}`,
        is_default_message = true,
        sender_name = moderatorName,
        sender_id = moderatorID,
        receiver_id = null,
        sender_receiver = isActiveUserModerator ? 'sender' : 'receiver',
        is_seen = 0,
        created_at = group.created_at || this.currentDate

      return {
        id,
        group_id,
        name,
        image,
        chat_details: {
          id: this.chatLastMessagedID + 1,
          sender_id,
          sender_name,
          receiver_id,
          sender_receiver,
          is_seen,
          created_at,
          content,
          is_default_message,
          group
        }
      }
    },
    handleUpdateGroupMembers (payload) {
      // console.group('handleUpdateGroupMembers')
      // console.log('payload', payload)

      const groupID = payload.group_id,
        membersIDs = payload.members_ids,
        isRemoveMemberCase = !payload.addMember,
        isChangeGroupAdminCase = payload.hasOwnProperty('makeAdmin'),
        makeGroupAdmin = payload.makeAdmin,
        isActiveGroupChat = this.activeChatGroupID === groupID,
        chatContact =
          this.chatContacts.find((c) => c.group_id === groupID) || null,
        chatContactGroupMembers =
          chatContact?.chat_details?.group?.members || null,
        isChatGroup = Boolean(chatContact),
        activeUserID = this.activeUser.id,
        activeUserName = this.activeUser.displayName,
        isActiveUserAUpdatedMember = membersIDs.some((m) => m === activeUserID),
        isActiveUserAdmin = payload.adminName === activeUserName,
        existGroupMembers = this.groupMembers || chatContactGroupMembers || []

      // console.groupEnd()
      let updatedMembersData = []

      // Make \Dismiss Admin Case
      if (isChangeGroupAdminCase) {
        membersIDs.forEach((m) => {
          const matchedMember = existGroupMembers.find((e) => e.id === m)
          if (matchedMember) {
            matchedMember.user_role = makeGroupAdmin ? 'admin' : 'member'
            matchedMember.user_role_name = makeGroupAdmin ? this.$t('chat.admin') : this.$t('chat.member')
          }
        })
      }
      // Remove Members Case
      else if (isRemoveMemberCase) {
        // Get removed member data
        updatedMembersData = existGroupMembers.filter((m) => {
          return Array.isArray(membersIDs) ? membersIDs.includes(m.id) : membersIDs === m.id
        })

        // Remove Member from group
        membersIDs.forEach((m) => {
          const memberIDIndex = existGroupMembers.indexOf(
            existGroupMembers.find((e) => e.id === m)
          )
          existGroupMembers.splice(memberIDIndex, 1)
        })
      }
      // Add Members Case
      else {
        existGroupMembers.push(...payload.newMembers)
        updatedMembersData = payload.newMembers
      }

      // Hnadle Updated Members Name
      if (isActiveUserAUpdatedMember) updatedMembersData = this.handleActiveUserName(updatedMembersData)
      // const updatedMembersNames = updatedMembersData.map((m) => m.name)

      // Set default message and set it to chatDetails
      const newDefaultMsg = Object.assign({}, payload)
      newDefaultMsg.is_default_message = true
      newDefaultMsg.is_seen = isActiveGroupChat ? 1 : 0
      newDefaultMsg.id = this.chatLastMessagedID + 1
      newDefaultMsg.created_at = `${this.currentDate}`
      // set msg content
      if (isChangeGroupAdminCase) newDefaultMsg.content = `${
        makeGroupAdmin ? this.$t('chat.you_are_now_an_admin') : this.$t('chat.you_are_no_longer_an_admin')
      }`
      // else payload.content = `${isActiveUserAdmin ? this.$t('basic.you') : payload.adminName} ${isRemoveMemberCase ? this.$t('basic.removed') : this.$t('basic.added')} ${updatedMembersNames.join(' , ')}`
      else newDefaultMsg.content = this.handleActiveUserName(
        newDefaultMsg.content
      )

      // If is recently opened ( ActiveChat )
      if (isActiveGroupChat) this.chatMessages.push(newDefaultMsg)

      // If not opened ( ChatContact )
      if (chatContact) {
        chatContact.chat_details.content = newDefaultMsg.content
        chatContact.chat_details.is_default_message =
          newDefaultMsg.is_default_message
        chatContact.chat_details.created_at = newDefaultMsg.created_at
        chatContact.chat_details.id = newDefaultMsg.id
        chatContact.chat_details.group.members = existGroupMembers
      } else {
        const newChatContact = Object.assign({}, payload)
        newChatContact.chat_details = {
          group: {members: existGroupMembers},
          content: newDefaultMsg.content,
          is_default_message: newDefaultMsg.is_default_message,
          created_at: newDefaultMsg.created_at,
          id: newDefaultMsg.id
        }
        newChatContact.id = payload.group_id
        this.chatContacts.unshift(newChatContact)
      }
      return newDefaultMsg
    },
    async NewMsg (data) {
      const handleData = this.handleMsgObj(data),
        have_ChatContact = this.chatContacts.length,
        is_ChatGroup = handleData.group_id,
        isGroupMsg = Boolean(handleData.group_id),
        comparedId = handleData.isSent ? handleData.receiver_id : handleData.sender_id,
        is_ChatContact = !is_ChatGroup ? this.chatContacts.some((con) => con.id === comparedId) : undefined,
        msgChatID = isGroupMsg ? handleData.group_id : comparedId,
        isActiveChat = this.activeChatUserID === msgChatID
      // console.group('NewMsg')
      // console.log('handleData.isSent', handleData.isSent)
      // console.log('handleData.receiver_id', handleData.receiver_id)
      // console.log('handleData.sender_id', handleData.sender_id)
      // console.log('this.activeChatUserID', this.activeChatUserID)
      // console.log('comparedId', comparedId)
      // console.log('msgChatID', msgChatID)
      // console.log('isActiveChat', isActiveChat)
      // console.log('handleData', handleData)
      // console.log('handleData.is_seen', handleData.is_seen)

      if (isActiveChat) {
        this.sendNewMessage = true
        this.activeChatUser.is_seen = 1
        this.chatMessages.push(handleData)
        this.setScroll()
        if (this.activeChatUser.chat_details) this.activeChatUser.chat_details.is_seen = 1
      }

      if (have_ChatContact && (is_ChatContact || is_ChatGroup)) {
        let matchedChatContact = {}

        if (is_ChatGroup) {
          matchedChatContact = this.chatContacts.find(
            (con) => con.group_id === handleData.group_id
          )
          handleData.group = matchedChatContact.chat_details.group
        } else if (is_ChatContact) {
          matchedChatContact = this.chatContacts.find(
            (con) => !con.group_id && con.id === comparedId
          )
        }

        matchedChatContact.chat_details = handleData

        this.sortChatContacts()
        if (isActiveChat) {
          matchedChatContact.is_seen = 1
          if (matchedChatContact.chat_details) matchedChatContact.chat_details.is_seen = 1

          matchedChatContact.chat_details.is_seen = 1
        }
        // console.log('matchedChatContact', matchedChatContact)
      } else {
        let contact = {}

        if (isActiveChat || this.activeChatUser.id === data.sender_id) contact = this.activeChatUser
        else await this.getContact(data.sender_id).then((res) => {
          contact = res
        })
        contact.chat_details = handleData
        contact.chat_details.contact = this.chatContacts.unshift(contact)
        // console.log('contact', contact)
      }
      // console.groupEnd()
    },
    ////////////////////////////////////////
    // Sorting
    ////////////////////////////////////////

    sortChatContacts () {
      return this.chatContacts.sort(
        (a, b) => b.chat_details.id - a.chat_details.id
      )
    },
    sortChatMessages (messages) {
      return messages.sort((a, b) => a.id - b.id)
    },
    ////////////////////////////////////////
    // Get User Data
    ////////////////////////////////////////
    isActiveChatUser (userId, groupId = null) {
      if (this.activeChatGroupID) return groupId === this.activeChatGroupID
      else return !groupId && userId === this.activeChatUserID
    },

    // Update Active ChatLog
    async updateActiveChatUser (contactId, groupId = null) {
      if (
        (this.activeChatUserID && this.activeChatUserID === contactId) ||
        (this.activeChatGroupID && this.activeChatGroupID === groupId)
      ) {
        this.toggleChatSidebar(false)
        return
      }

      if (!this.addGroupMemberMode) {
        await this.resetChatsBeforeUpdate()

        if (!this.isGroupsModeActive) {
          this.toggleChatSidebar(false)
        }
        this.activeChatUserID = contactId
        this.activeChatGroupID = groupId
        this.getChatMessages()
        this.resetMessageArea()
        this.markSeenMessages(contactId)
      }
    },
    toggleUsers (userId) {
      if (userId !== this.activeUser.uid) this.updateActiveChatUser(userId)
    },

    ///////////////////////////////////
    // User Status
    ///////////////////////////////////
    getUserStatus (isActiveUser) {
      return isActiveUser ? this.activeUser.status || 'online' : this.activeChatUser.status
    },

    ////////////////////////////////////////
    // Set && pause refresh
    ////////////////////////////////////////
    setRefresh (methodName, time) {
      if (methodName) {
        this.refresh = setInterval(() => {
          this[methodName]()
        }, time)
      }
    },
    stopRefresh () {
      clearInterval(this.refresh)
      this.refreshAfterTime = 1
    },
    ////////////////////////////////////////
    //  reset && update
    ////////////////////////////////////////
    resetChatsBeforeUpdate () {
      this.chatMessages = []
      this.activeChatUserID = null
      this.activeChatGroupID = null
      this.activeChatUser = {}
      this.sendNewMessage = false
    },
    resetContactsBeforeUpdate () {
      this.resetPagination()
      this.resetContactsData()
    },
    resetPagination () {
      this.totalPages = this.showContactsResults ? 1 : this.defaultTotalPages
      if (this.currentPage > 1) this.currentPage = 1
    },
    resetContactsData () {
      this.contacts = []
      this.filtratedContacts = []
      this.allContacts = []
    },
    reconnectServer () {
      if (this.isModeActive) this.getContactsDebounce()
      this.updateChat()
    },
    updateChat () {
      this.getChatContacts()
      if (this.activeChatUserID) this.getChatMessages()
    },
    resetFilter () {
      this.selectedSuggest = []
      this.selectedTeacherClasses = []
      this.selectedUsersRoles = ['teacher', 'parent', 'student']
    },
    resetSearch () {
      this.searchContactName = ''
      this.resetPagination()
    },
    resetSelected () {
      this.selectedContacts = []
      this.selectedFilters = []
    },
    resetAll () {
      this.resetFilter()
      this.resetSelected()
    },
    // reset Mesages Area
    resetMessageArea () {
      this.attachment = ''
      this.typedMessage = ''
      if (this.$refs.sendMsgTextarea) this.$refs.sendMsgTextarea.rows = 1

    },
    resetGroupInfo () {
      this.selectedGroupContacts = []
      this.newGroupName = ''
    },

    endMode () {
      this.resetAll()
      if (this.searchMode) this.endSearch()
      if (this.isGroupsModeActive) this.endGroupMode()
      this.endContactsFocusMode()
    },

    ////////////////////////////////////////
    // Seen
    ////////////////////////////////////////

    // Change Seen For chatContact
    changeSeenChatContact (contactId, userType) {
      const have_ChatContact = this.chatContacts.length,
        userType_id = userType === 'receiver' ? 'receiver_id' : 'sender_id',
        is_ChatContact = this.chatContacts.some(
          (el) => el.chat_details && el.chat_details[userType_id] == contactId
        )

      if (have_ChatContact && is_ChatContact) {
        this.chatContacts.find(
          (el) => el.chat_details && el.chat_details[userType_id] == contactId
        ).chat_details.is_seen = 1
      }
      if (have_ChatContact && this.is_ChatGroup) {
        const matched = this.chatContacts.find(
          (c) => c.id === (this.activeChatGroupID || this.activeChatUserID)
        )
        matched.is_seen = 1
        matched.chat_details.is_seen = 1
      }
    },
    // // Chnage seen to chat Groups
    // changeSeenChatContact () {
    //   if (this.is_ChatGroup) {
    //     const matched = this.chatContacts.find(
    //       c => c.id === (this.activeChatGroupID || this.activeChatUserID)
    //     )
    //     matched.is_seen = 1
    //     matched.chat_details.is_seen = 1
    //   }
    // },

    // Active User as Sender (Messages)
    markSeenMessages (senderId) {
      const payload = {
        sender_id: senderId,
        receiver_id: this.authUser.uid
      }
      chatRepository.updateIsSeen(payload)
    },

    ////////////////////////////////////////
    // setPagination
    ////////////////////////////////////////
    setPagination (data) {
      this.totalPages =
        data.total_pages || data.totalPages || data.last_page || 0
      this.countPerPage = data.per_page || data.countPerPage || 0
      this.currentPage = data.current_page || data.currentPage || 1
    },

    ////////////////////////////////////////
    // Send Message
    ////////////////////////////////////////

    // Handle Attachment
    uploadAttachment (e) {
      const //  Get file
        file = e.target.files[0],
        fileName = file.name

      this.checkTextImage2MBFileValid(file).then(() => {
        this.attachment = file
        this.typedMessage = fileName
      })
    },

    //Handle payload
    handleMessagePayload () {
      if (this.activeChatGroupID) {
        return {
          content: this.typedMessage,
          attachment: this.attachment,
          receiver_id: this.activeChatGroupID ? null : this.activeChatUserID,
          sender_id: this.activeUser.uid,
          group_id: this.activeChatGroupID
        }
      } else {
        return {
          content: this.typedMessage,
          attachment: this.attachment,
          receiver_id: this.activeChatUserID,
          sender_id: this.activeUser.uid
        }
      }
    },

    //HandleSendMsg
    async handleSendMsg (payload) {

      await this.startLoadingSendNewMessage()
      if (this.activeChatGroupID) await this.sendGroupChatMessage(payload).then(this.resetMessageArea)
      else await this.sendChatMessage(payload).then(this.resetMessageArea)
      this.endLoadingSendNewMessage()
    },
    // Handle Attachment action
    takeAttachmentAction () {
      if (this.attachment) this.resetMessageArea()
      else this.$refs.attachmentInput.click()

    },
    // Send Text Message
    sendTextMsg () {
      if (!this.typedMessage) return
      const payload = this.handleMessagePayload()
      this.handleSendMsg(payload)
    },

    // Send Attachment Message
    sendAttachmentMsg () {
      if (!this.attachment) return

      const formData = new FormData()
      formData.append('attachments[0]', this.attachment)

      const message = this.handleMessagePayload()
      this.startLoadingAttachment()
      // global upload service
      chatRepository
        .uploadFiles(formData)
        .then((res) => {
          message.attachment = res[0].attachment
          this.handleSendMsg(message)
        })
        .catch((err) => {
          this.errMsg(err.data.message, this.$t('form.Uploading_Error'))
        })
        .finally(this.endLoadingAttachment())
    },

    // Send Mesaage Action
    sendMsg (e) {
      if (e.shiftKey) this.resizeSendTextarea()
      if (!this.typedMessage || (this.typedMessage && e.keyCode === 13 && e.shiftKey)) return

      if (this.attachment) this.sendAttachmentMsg()
      else this.sendTextMsg()

    },

    ////////////////////////////////////////
    // ChatPinned
    ////////////////////////////////////////
    toggleIsChatPinned (value) {
      this.isChatPinned = value
    },
    ////////////////////////////////////////
    // UI
    ////////////////////////////////////////
    setSidebarWidth () {
      if (this.windowWidth < 1200) {
        this.isChatSidebarActive = this.clickNotClose = false
      } else {
        this.isChatSidebarActive = this.clickNotClose = true
      }
    },
    toggleChatSidebar (value = false) {
      // if (this.isGroupsModeActive) value = true
      if (!value && this.clickNotClose) return

      this.isChatSidebarActive = value
    },
    setScroll () {
      if (!this.$refs.chatLog || !this.$refs.chatLogPS) return

      let scroll_el = null
      if (this.$refs.chatLogPS) scroll_el = this.$refs.chatLogPS.$el || this.$refs.chatLogPS
      else scroll_el = this.$refs.chatLog

      scroll_el.scrollTop = this.$refs.chatLog.scrollHeight
      this.showMessagesScrollDownBtn = false
    },
    scrollToTop (refElement) {
      if (!this.$refs[refElement]) return
      this.$nextTick(() => {
        this.$refs[refElement].$el.scrollTop = 0
      })
    },
    closeProfileSidebar (value) {
      this.activeProfileSidebar = value
    },
    showProfileSidebar (userId, openOnLeft = false) {
      this.userProfileId = userId
      this.isLoggedInUserProfileView = openOnLeft
      this.activeProfileSidebar = !this.activeProfileSidebar
    },
    // get New Messages on scroll
    messagesAreaScoll (event) {
      const messageArea = event.target,
        firstMessageIsIntoView = messageArea.scrollTop < 80,
        firstMessageId = this.chatMessages[0].id

      if (
        firstMessageIsIntoView &&
        !this.loadingMessages &&
        !this.stopGetMessages
      ) this.getChatMessages(firstMessageId)
    },

    toggleMessageScrollToDownBtn (event) {
      const messageArea = event.target,
        lastMessagesIntoView =
          messageArea.scrollHeight - messageArea.scrollTop > 600
      if (lastMessagesIntoView && this.chatMessages.length > 10) this.showMessagesScrollDownBtn = true
      else this.showMessagesScrollDownBtn = false
    },
    resizeSendTextarea () {
      const target = this.$refs.sendMsgTextarea

      if (target.scrollHeight > 450 && this.typedMessage) return
      if (this.floatingSendMsgArea && this.typedMessage)  target.rows = `${this.isLongNewMessage ? Math.floor((target.scrollHeight / 2) / 10) - 3 : 1}`
      else target.rows = 1


    },
    ////////////////////////////////////////
    // Contacts Focus Mode
    ////////////////////////////////////////
    startContactsFocusMode () {
      this.showContacts = true
      this.showChatContacts = false
      if (!this.shownContacts.length) this.getContactsDebounce()
      this.toggleChatSidebar(true)
    },

    endContactsFocusMode () {
      this.showContacts = false
      this.showChatContacts = true
      this.resetContactsBeforeUpdate()
      this.toggleChatSidebar()
    },
    ////////////////////////////////////////
    // Search Mode
    ////////////////////////////////////////
    // Start
    startSearch () {
      // this.startContactsFocusMode()
      this.searchMode = true
      this.currentPage = 1
    },

    // searching
    search () {
      this.resetContactsBeforeUpdate()

      if (!this.searchMode) this.startSearch()

      if (this.searchContactName < 3) return

      this.$validator.validate().then((valid) => {
        if (valid) this.getContactsDebounce()
      })
    },
    // end
    endSearch () {
      this.searchMode = false
      this.resetSearch()
    },

    //////////////////////////////
    // Chat Group
    //////////////////////////////
    // default Group mode
    startGroupMode () {
      this.startContactsFocusMode()
    },
    endGroupMode () {
      if (this.createGroupMode) this.endCreateGroup()
      if (this.addGroupMemberMode) this.endAddGroupMemberMode()
      this.resetGroupInfo()
    },
    ///////////////////////////
    //  creatGroup Mode
    startCreateGroupMode () {
      this.createGroupMode = true
      this.startGroupMode()
    },
    activeCreateGroupTitleDialog () {
      const selectedContacts = this.selectedGroupContacts
      if (selectedContacts.length) {
        this.activeGroupTitleDialog = true
        this.newGroupName = [...new Set(selectedContacts.map((c) => c.class_name))]
          .filter(Boolean)
          .join(' - ')
      }
    },
    startCreateGroup () {
      if (this.newGroupName || !this.loadingCreateGroup) this.createGroup()
    },

    endCreateGroup () {
      this.activeGroupTitleDialog = false
      this.createGroupMode = false
    },
    unselectGroupContact (index) {
      this.selectedContacts.splice(index, 1)
    },
    ///////////////////////////
    // Dealing with group members
    ///////////////////////////

    // remove Group Member Mode
    removeGroupMember (memberID) {
      this.userProfileLoading = true
      const payload = {
        members_ids: Array.isArray(memberID) ? memberID : [memberID],
        group_id: this.activeChatGroupID
      }
      this.removeGroupMembers(payload)
        .then((res) => {
          // Set Message

          // refresh contacts
          if (this.addGroupMemberMode) this.getContactsDebounce()

          // Handle Socket
          payload.addMember = false
          payload.content = res.data.data
          payload.name = this.activeChatUser.name
          this.updateMembersSocket(payload)

          this.successMsg(this.handleActiveUserName(res.data.data))
        })
        .catch((err) => this.errMsg(err))
        .finally(() => {
          this.userProfileLoading = false
        })
    },
    ///////////////////////////
    // Add Group Member Mode

    startAddGroupMemberMode (groupId) {
      this.addGroupMemberMode = true
      this.addMembersGroupID = groupId
      this.startGroupMode()
      this.activeProfileSidebar = false
      this.userProfileLoading = true

      if (this.shownContacts.length) this.filtratedContacts = this.removeGroupMembersFromContacts(
        this.filtratedContacts
      )
    },
    addGroupMembers () {
      if (!this.addGroupMemberMode || !this.selectedGroupContacts.length) return

      const selectedNames = this.selectedGroupContacts.map((s) => s.name),
        groupName = this.chatUser.name

      this.confirmDialog(
        this.$t('basic.confirm'),
        `${this.$t('basic.add')} ${selectedNames.join(
          ' , '
        )} to ${groupName} ${this.$t('chat.group')}?`,
        this.confirmAddMembers,
        null,
        null,
        this.cancelAddMembers
      )
    },
    confirmAddMembers () {
      const selectedIds = this.selectedGroupContacts.map((s) => s.id),
        payload = {
          members_ids: selectedIds,
          group_id: this.addMembersGroupID
        }

      this.addGroupMembersAPI(payload)
        .then((res) => {
          // Handle Socket
          payload.addMember = true
          payload.newMembers = this.selectedGroupContacts
          payload.content = res.data.data
          payload.name = this.activeChatUser.name

          this.updateMembersSocket(payload)
          // End Mode
          this.endMode()
          this.successMsg(this.handleActiveUserName(res.data.data))
        })
        .catch((err) => this.errMsg(err))
    },
    endAddGroupMemberMode () {
      this.addGroupMemberMode = false
      this.addedGroupMembers = []
      this.addMembersGroupID = null
      this.selectedGroupContacts = []
      this.userProfileLoading = false
      this.resetFilter()
    }
  },
  components: {
    VuePerfectScrollbar,
    ChatContact,
    UserProfile,
    ChatNavbar,
    ChatLog,
    VueSimpleSuggest
  },
  created () {
    this.$socket.client.on(
      `new_message_${this.authUser.uid}`,
      this.pushMessage
    )
    // console.log(this.activeChatGroupID,"activeChatGroupID")
    // this.$socket.client.on(`new_message_group_${this.activeChatGroupID}`, this.pushMessage)
    this.$socket.client.on('new_message_group', this.pushMessage)
    this.$socket.client.on(
      `update_is_seen_${this.authUser.uid}`,
      this.updateIsSeenSocket
    )
    this.$socket.client.on('new_group_created', this.pushNewCreatedGroup)
    this.$socket.client.on('update_group_members', this.pushUpdateGroupMembers)
    this.$socket.client.on('only_group_admins_can_reply', this.pushUpateOnlyAdminsCanReply)
    this.$socket.client.on('delete_message', this.pushUpateDeleteMsg)
    this.$socket.client.on('delete_group_socket', this.pushDeleteGroup)
    this.setSidebarWidth()
    if (this.is_teacher) this.getTeacherClasses()
    if (this.userID) this.updateActiveChatUser(this.userID)
  },
  sockets: {
    connect () {}
  },
  beforeDestroy () {},
  mounted () {
    this.getChatContacts()
  }
}
</script>
<style lang="scss">
@import "src/assets/scss/vuexy/apps/chat.scss";
@import "src/assets/scss/vuexy/extraComponents/autocomplete";
#chat-app.mobile-web-view .chat-content-area {
  height: 90vh;
}
</style>
<style scoped>
#chat-app .chat-content-area {
  min-width: inherit;
}
.vs-textarea {
  min-width: auto;
}
.vs-textarea:focus {
  resize: inherit !important;
}
</style>
