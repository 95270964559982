export default {
  S: 'S',
  Name: 'Name',
  Address: 'Address',
  ClickForGPSAddress: 'Use GPS',
  UploadLogo: 'Upload Logo',
  DragAndDrop: 'DRAG & DROP to upload new PNG logo',
  Contacts: 'Branch Contacts',
  details: 'Branch Details',
  General: 'General',
  Reception: 'Reception ',
  Admission: 'Admission',
  Accounting: 'Accounting',
  Submit: 'Submit',
  ShortName: 'Short Name',
  LongName: 'Long Name',
  Description: 'Description',
  Internet: 'Internet',
  website: 'website',
  Facebook: 'Facebook',
  Instagram: 'Instagram',
  ContactGeneral: 'General Contact',
  InstitutionCreate: 'Create New Institution',
  Logo: 'Logo',
  Save: 'Save',
  Edit: 'Save and Close',
  Cancel: 'Cancel',
  BranchName: 'Branch Name',
  user: 'User',
  pleaseClickForGetYourCurrentLocation:
    'please click for get your current location',
  BranchExist: 'branch name has already been taken',
  longNameError: 'long name has already been taken',
  shortNamError: 'short name has already been taken',
  choose_primary_color: 'choose primary color ',
  confirm_to_save_changes: 'confirm to save changes ',
  SaveAndClose: 'Save And Close',
  favicon: 'favicon',
  logo_icon: 'logo icon',
  branch_has_been_edited_successfully: 'Branch has been edited successfully',
  new_branch_has_been_added_successfully:'New branch has been added successfully',
  new_section_has_been_added_successfully:'New section has been added successfully',
  the_section_has_been_edited_successfully:'The section has been edited successfully',
  institution_info_has_been_added_successfully:'Institution info has been added successfully',
  please_upload_the_logo_files : 'please upload the logo\'s files',
  please_enter_he_GPS_Address : 'please enter the GPS Address'
}
