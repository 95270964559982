import answerAssignment from '@/modules/assignment/sub_modules/student/pages/answerStudent.vue'

export default [
  // ----------------------------------------------------------
  //  Get Single Assignemnt
  // ----------------------------------------------------------

  {
    path: 'assignment/:id',
    name: 'answer_assignemnt',
    component: answerAssignment,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' }
        // { title: 'config.student', url: '/student' },
      ],
      pageTitle: 'config.answer_assignemnt',
      // hideVerticalMenu:true,
      rule: 'Student'
    }
  },
  // ----------------------------------------------------------
  //  Get Single Assignemnt
  // ----------------------------------------------------------

  {
    path: 'assignment/:id/grade',
    name: 'show-grade',
    component: answerAssignment,
    meta: {
      breadcrumb: [
        { title: 'home', url: '/home' }
        // { title: 'config.student', url: '/student' },
      ],
      pageTitle: 'config.assignemnt_grade',
      // hideVerticalMenu:true,
      rule: 'Student'
    }
  }
]
