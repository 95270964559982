<template >
  <separatedPage :componentName="dynamicComponent"></separatedPage>
</template>
<script>
import separatedPage from './Components/SeparatedPage.vue'
import assignments from '@/modules/assignment/sub_modules/student/pages/list'
export default {
  name:'student-report-cards',
  components:{
    separatedPage,
    assignments
  },
  data: () => ({
    dynamicComponent:assignments
  })
}
</script>
<style lang="">
</style>
