<template>
  <div>
    <addOrEditBoundaryForm @submitForm="submitForm" :editData="true" />
  </div>
</template>

<script>
import addOrEditBoundaryForm from '../components/createForm'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const boundariesAndDescriptors = RepositoryFactory.get(
  'boundariesAndDescriptors',
  'boundariesAndDescriptors'
)
export default {
  name: 'editBoundary',
  components: {
    addOrEditBoundaryForm
  },
  methods: {
    submitForm(payload) {
      this.VSLoading()
      boundariesAndDescriptors
        .editBoundary(payload.boundaryId, payload.data)
        .then(() => {
          this.successMsg(this.$t('boundariesAndDescriptors.editSuccessfully'))
          this.$router.push({name: 'boundariesAndDescriptors'})
        })
        .catch((err) => {
          this.errMsg(err)
        })
        .finally(() => {
          this.stopVSLoading()
        })
    }
  }
}
</script>

<style scoped></style>
