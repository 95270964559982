export default {
  canteen: 'canteens',
  createCanteen: 'Add Canteen',
  editCanteen: 'Edit Canteen',
  ifCreateCanteenUser:'The canteen manager can create other users',
  name:'Name',
  userType:'User Type',
  status: 'Status',
  userList: 'All Users',
  createUser: 'Add User',
  editUser: 'Edit User',
  suspend: 'Suspend',
  createCategory: 'Ceate Category',
  editCategory: 'Edit Category',
  categoryList: 'All Categories',
  categoryName: 'Category Name',
  items: 'Items',
  category: 'Category',
  showItems: 'Show Items',
  createItem: 'Create Item',
  addItem: 'Add Item',
  editItem: 'Edit Item',
  itemName: 'Item Name',
  price: 'Price',
  categoryItem: 'All Items',
  store: 'Store',
  salePrice: 'Sales Price',
  transaction: 'Transactions',
  quantity: 'Quantity',
  addCanteenSuccessfully: 'Canteen Added Successfully',
  editCanteenSuccessfully: 'Canteen Updated Successfully',
  addCategorySuccessfully: 'Category Added Successfully',
  editCategorySuccessfully: 'Category Edited Successfully',
  addItemSuccessfully: 'Item Added Successfully',
  editItemSuccessfully: 'Item Edited Successfully',
  addUserSuccessfully: 'User Added Successfully',
  editUserSuccessfully: 'User Edited Successfully'
}
