import vSelect from 'vue-select'
import RadialProgressBar from 'vue-radial-progress'


export default {
  name: 'PlannerFilters',
  props: [],
  components:{
    'v-select': vSelect,
    RadialProgressBar
  },
  data () {
    return {
      AllClasses: [],
      selectClass: ''
    
    }
  },
  watch: {
    
  },
  created () {
   
  },
  methods:{
    applyAction (data) {
    }
  }
}
