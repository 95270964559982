import axios from 'axios'

const resource = '/academic/academic-years'
export default {
  create(payload) {
    return axios.post(`${resource}`, payload)
  },
  get(id) {
    return axios.get(`${resource}/${id}`)
  },
  update(id, payload) {
    return axios.put(`${resource}/${id}`, payload)
  },
  getAll() {
    return axios.get(resource)
  },
  activeAcadmicYear() {
    return axios.get('academic/currentActive')
  },
  AcadmicYearInfo(AcadmicYearId) {
    return axios.get(`academic/AcadmicYearInfo/${AcadmicYearId}`)
  },
  changeStatus(id, status) {
    return axios.get(`${resource}/${status}/${id}`)
  },
  // Sub Acadimic
  getDivisions(masterAcadimicId) {
    return axios.get(`${resource}/${masterAcadimicId}/sub/divisions`)
  },
  getSectionByDivision(masterAcadimicId, id) {
    return axios.get(
      `${resource}/${masterAcadimicId}/sub/divisions/${id}/sections`
    )
  },
  getGrads(masterAcadimicId, id_division, id_section) {
    return axios.get(
      `${resource}/${masterAcadimicId}/sub/divisions/${id_division}/sections/${id_section}/grades`
    )
  },
  createSub(id, payload) {
    return axios.post(`${resource}/${id}/sub`, payload)
  },

  getSingleSub(masterAcadimicId, subId) {
    return axios.get(`${resource}/${masterAcadimicId}/sub/${subId}`)
  },

  EditSub(masterAcadimicId, subId, payload) {
    return axios.post(`${resource}/${masterAcadimicId}/sub/${subId}`, payload)
  },

  delete(masterAcadimicId, subId) {
    return axios.delete(`${resource}/${masterAcadimicId}/sub/${subId}`)
  },
  deleteParentAcademicYear(id) {
    return axios.delete(`/academic/academic-years/${id}`)
  }
}
